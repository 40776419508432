import * as Immutable from "immutable"

import { BaseEntity } from "../../baseEntity"
import { ParamValueInfo } from "./"

export class ParamInfo extends BaseEntity {

  protected readonly TITLE = "title";
  protected readonly PARAMETER_TYPE = "parameterType";
  protected readonly VALUE = "value";
  protected readonly IS_NEW = "isNew";  
  protected readonly VISUAL_OBJECT_IN_TAB_ID = "visualObjectInTabId";
  protected readonly PARAM_VALUES = "paramValues";  
    
  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get parameterType(): string { return this.getInternalValue<string>(this.PARAMETER_TYPE); }
  setParameterType(parameterType: string): this { return this.setInternalValue(this.PARAMETER_TYPE, parameterType); }

  get value(): string { return this.getInternalValue<string>(this.VALUE); }
  setValue(value: string): this { return this.setInternalValue(this.VALUE, value); }

  get isNew(): boolean { return this.getInternalValue<boolean>(this.IS_NEW); }
  setIsNew(isNew: boolean): this { return this.setInternalValue(this.IS_NEW, isNew); }

  get visualObjectInTabId(): number { return this.getInternalValue<number>(this.VISUAL_OBJECT_IN_TAB_ID); }
  setVisualObjectInTabId(visualObjectInTabId: number): this { return this.setInternalValue(this.VISUAL_OBJECT_IN_TAB_ID, visualObjectInTabId); }  

  get paramValues(): Immutable.List<ParamValueInfo> { return this.getInternalValue<Immutable.List<ParamValueInfo>>(this.PARAM_VALUES); }
  setParamValues(paramValues: Immutable.List<ParamValueInfo>): this { return this.setInternalValue(this.PARAM_VALUES, paramValues); }
}