import { ApiMessage } from "./apiMessage";

export class IntegrationSettings extends ApiMessage {
	readonly IS_RUNNING_IN_IFRAME = "isRunningInIframe";
	readonly IS_AT_ROOT_LEVEL = "isAtRootLevel";

	get isRunningInIframe(): boolean { return this.getInternalValue<boolean>(this.IS_RUNNING_IN_IFRAME); }
	setIsRunningInIframe(isRunningInIframe: boolean): this { return this.setInternalValue(this.IS_RUNNING_IN_IFRAME, isRunningInIframe); }

	get isAtRootLevel(): boolean { return this.getInternalValue<boolean>(this.IS_AT_ROOT_LEVEL); }
	setIsAtRootLevel(isAtRootLevel: boolean): this { return this.setInternalValue(this.IS_AT_ROOT_LEVEL, isAtRootLevel); }
}

