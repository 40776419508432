import { InputViewModel } from "./inputViewModel";

export class DisplayCountValueModel extends InputViewModel {

  // 0 represents "All" results.
  public maxCount: number = 0;

  constructor(defaultDisplayCount: number, protected allAsString: string) {
    super();
    this.value = defaultDisplayCount.toString();
  }

  get count() {
    if (this.displayAll)
      return this.maxCount;
    else
      return parseInt(this.value);
  }

  get displayAll() {
    return this.formattedValue == this.allAsString;
  }
}