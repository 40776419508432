import * as Immutable from "immutable";
import { ImmutableObject } from "../../../../shared/immutableObject";
import { ConfInfo } from "../../models/entities/confInfo";
import { Comparison } from "../../models/entities/comparison";
import { KeyValue } from "../../models";

export class ComparisonResult extends ImmutableObject {

  public static readonly COMPARISON_SESSION_ID = "comparisonSessionId";
  public static readonly DETAIL = "detail";
  public static readonly PATH = "path";
  public static readonly CONF_IDS = "confIds";
  public static readonly DIFFERENCY_RESULT = "differencyResult";
  public static readonly BREADCRUMB_CONF_INFOS = "breadcrumbConfInfos";
  public static readonly CHILD_CONFS_ORDER = "childConfsOrder";
  
  get breadcrumbConfInfos(): Immutable.List<KeyValue<string, ConfInfo[]>> { return this.getInternalValue<Immutable.List<KeyValue<string, ConfInfo[]>>>(ComparisonResult.BREADCRUMB_CONF_INFOS); }
  setBreadcrumbConfInfos(breadcrumbConfInfos: Immutable.List<KeyValue<string, ConfInfo[]>>): this { return this.setInternalValue(ComparisonResult.BREADCRUMB_CONF_INFOS, breadcrumbConfInfos); }

  get childConfsOrder(): Immutable.List<KeyValue<string, ConfInfo[]>> { return this.getInternalValue<Immutable.List<KeyValue<string, ConfInfo[]>>>(ComparisonResult.CHILD_CONFS_ORDER); }
  setChildConfsOrder(childConfsOrder: Immutable.List<KeyValue<string, ConfInfo[]>>): this { return this.setInternalValue(ComparisonResult.CHILD_CONFS_ORDER, childConfsOrder); }

  get path(): string { return this.getInternalValue<string>(ComparisonResult.PATH); }
  setPath(path: string): this { return this.setInternalValue(ComparisonResult.PATH, path); }

  get comparisonSessionId(): number { return this.getInternalValue<number>(ComparisonResult.COMPARISON_SESSION_ID); }
  setComparisonSessionId(comparisonSessionId: number): this { return this.setInternalValue(ComparisonResult.COMPARISON_SESSION_ID, comparisonSessionId); }
  
  get detail(): Comparison { return this.getInternalValue<Comparison>(ComparisonResult.DETAIL); }
  setDetail(comparison: Comparison): this { return this.setInternalValue(ComparisonResult.DETAIL, comparison); }

  get confIds(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(ComparisonResult.CONF_IDS); }
  setConfIds(confIds: Immutable.List<number>): this { return this.setInternalValue(ComparisonResult.CONF_IDS, confIds); }

  get differencyResult(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(ComparisonResult.DIFFERENCY_RESULT); }
  setDifferencyResult(differencyResult: Immutable.List<number>): this { return this.setInternalValue(ComparisonResult.DIFFERENCY_RESULT, differencyResult); }
  
}