import { Component, ChangeDetectorRef } from "@angular/core";
import { PopperContentComponent, PopoverService, PopperConfig } from "../../../../shared/components";
import { GlobalServiceLocator } from "../../../../shared/providers/globalServiceLocator";
import { BrowserInfo } from "../../../../shared/utils/browserInfo";

@Component({
  selector: 'attribute-popper',
  templateUrl: './attributePopperComponent.html'
})
export class AttributePopperComponent extends PopperContentComponent {

  public closing = false;
  public nextData: PopperConfig;
  public growing = false;

  public contentStyle: CSSStyleDeclaration;

  private _browserInfo: BrowserInfo;

  constructor(
    public cd: ChangeDetectorRef,
    public popoverService: PopoverService) {
    super(cd, popoverService);
  }

  public get browserInfo() {
    if (!this._browserInfo)
      this._browserInfo = GlobalServiceLocator.injector.get(BrowserInfo);
    return this._browserInfo;
  }

  ngOnInit(): void {

    this.messageSubscription = this.popoverService.getMessage().subscribe((info: PopperConfig) => {

      if (this.closing && info.open && info.tag?.id != this.data.tag?.id) {
        this.nextData = info;
        return;
      }
      else
        this.open(info);
    });
  }

  open(info: PopperConfig): void {
    if (info.open && this.data.open) {
      this.close(info);
      return;
    }

    this.data = info;

    if (this.data.open) {
      this.positionChange.emit(this.data);
      this.setStyle();

      this.growing = false;
      setTimeout(() => {
        this.growing = true;
        this.cd.markForCheck();
      }, 0);
    }

    this.cd.markForCheck();
  }

  setStyle(): void {
    this.popperStyle = <CSSStyleDeclaration>{
      width: `${this.data.width}px`,
      height: "auto",
      left: `${this.data.positionX}px`,
      top: `${this.data.positionY}px`,
      maxWidth: '100%',
      maxHeight: `${this.data.height}px`
    };

    this.contentStyle = <CSSStyleDeclaration>{
      marginLeft: '15px',
      marginTop: '5px',
      marginBottom: '5px',
      marginRight: '15px'
    };
  }

  close(tag?: any): void {
    this.closing = true;
    this.nextData = tag;

    setTimeout(() => {
      this.popoverService.close();
      this.closing = false;
      if (!this.browserInfo.isMobile && this.nextData) {
        this.open(this.nextData);
      }
    }, 400);

    this.cd.markForCheck();
  }

  ngOnDestroy() {
    if (this.messageSubscription)
      this.messageSubscription.unsubscribe();
  }

}