import { Inject, Component, Optional } from "@angular/core";

import { ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { BaseDetailItemComponent } from "../shared/baseDetailItemComponent";
import { GraphicsDecoration, ConfGraphicsValue, GraphicsDisplayStyle, ConfUIItem, VisualObject, GroupConfUIItem } from "../../../shared/models";
import { ProductDataStore } from "../../../shared/providers/productData";

@Component({
  selector: 'graphics-detail',
  templateUrl: './graphicsDetailComponent.html',
})
export class GraphicsDetailComponent extends BaseDetailItemComponent {

  public actionBoxTitle: string;
  public showInlineTitle: boolean = false;

  public graphicDecorations: Array<GraphicsDecoration>;
  constructor(@Optional() @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(ProductDataStore) public productStore: ProductDataStore
  ) {
    super(confPageSessionService);
  }

  addGraphicsDecoration(x: ConfUIItem) {
    const visualObject = this.productStore.getEntity(x.id);
    // We only care about render graphics, popup graphic will be shown in side menu.
    if (visualObject instanceof GraphicsDecoration && visualObject.displayStyle === GraphicsDisplayStyle.Render) {
      // TODO check if visible, this code is copied from DecorationVisibilityService, needs to be combined or something.
      if (visualObject.showInSummary && (!visualObject.hideEmptyDecoration || this.confStore.getConfDataEntity<ConfGraphicsValue>(this.confPageSessionService.activeConfigurationId, this.confPageSessionService.confSessionId, visualObject.visualObjectId).svg))
        this.graphicDecorations.push(visualObject);
    }
  }

  onDataReady(): void {
    this.graphicDecorations = [];
    this.actionBoxTitle = this.strings.Graphics;

    if (this.confUIItem) {
      if (this.confUIItem.items) {
        if (this.confUIItem.id) {
          this.addGraphicsDecoration(this.confUIItem);
        }
        else
          this.confUIItem.items.forEach(uiItem => {
            this.addGraphicsDecoration(uiItem);
          });
      }
    }

    this.visible = this.graphicDecorations.length > 0;
    if (!this.visible)
      return;

    this.showInlineTitle = this.graphicDecorations.length > 1;

    if (!this.showInlineTitle) {
      let firstDecoration = this.graphicDecorations[0];
      if (firstDecoration.title && firstDecoration.title.trim().length > 0)
        this.actionBoxTitle = firstDecoration.title;
      // For individually displayed decoration if there is no title then hide the whole header row
      else if (typeof this.confUIItem != typeof GroupConfUIItem)
        this.showHeader = false;
    }
  }
}