import { Input, ChangeDetectorRef, Component, Directive } from "@angular/core";
import { AbstractDropdownItem } from "./abstractDropdownItem";
import { GenericDropdownGroup } from "./genericDropdownGroup";
import { InputViewModel } from "../shared";

@Component({ template: '' })
export abstract class AbstractDropdownGroup {

  @Input()
  public view: InputViewModel;

  @Input()
  public itemsView: InputViewModel[] = [];

  public emptyValueItem: InputViewModel;

  @Input()
  public disabled: boolean = false

  constructor(public parent: GenericDropdownGroup, public cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (this.parent.showEmptyValue) {
      this.emptyValueItem = new InputViewModel();
      this.emptyValueItem.id = null;      
      this.itemsView.unshift(this.emptyValueItem);
    }
  }

  isSelected(item: AbstractDropdownItem): boolean {
    return item.view.value == true;
  }

  processEvent(event, item: AbstractDropdownItem): void {
    //this.parent.processEvent(event, item);
  }

  onHelpClick($event): void {
    this.parent.helpClick($event);
  }
}