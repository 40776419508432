import { Injectable } from "@angular/core";
import { Observable, Subject } from 'rxjs';

import { ManagedSubject } from "../../../shared/managedSubject";

@Injectable()
export class ValueSubscriptionManager {
  
  public managedSubjects: Array<ManagedSubject<any>> = new Array<ManagedSubject<any>>();
    
  createSubject<T>(initialValueOrObserver: T | Observable<T>, oneTime = false, buffer = 1, completeOnZeroObserver = true): ManagedSubject<T> {

    let observer = (initialValueOrObserver instanceof Observable || initialValueOrObserver instanceof Subject) ? initialValueOrObserver : null;

    let managedSubject = this.createSubjectInternal<T>(observer, oneTime, buffer, completeOnZeroObserver, false);

    if (initialValueOrObserver != ManagedSubject.IGNORE_VALUE && !(initialValueOrObserver instanceof Observable || initialValueOrObserver instanceof Subject))
      managedSubject.nextValue(initialValueOrObserver);

    return managedSubject;
  }

  public createSubjectInternal<T>(observable: Observable<T>, oneTime = false, buffer = 1, completeOnZeroObserver = true, executeObserverOnSubscribe = true): ManagedSubject<T> {        
    let managedSubject = new ManagedSubject<T>(observable, oneTime, buffer, completeOnZeroObserver);
    this.managedSubjects.push(managedSubject);
    return managedSubject;
  }

  dispose() {
    this.managedSubjects.forEach((subject) => subject.complete());
    this.managedSubjects = new Array<ManagedSubject<any>>();
  }
}