import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { PresetItem, Range } from '../models/model';
import { ConfigDatepickerService } from '../providers/configDatepickerService';
import { RangeDatepickerService } from '../providers/rangeDatepickerService';
import { PresetService } from '../providers/presetService';
import { Subscription } from 'rxjs';

interface DropDownItem { title: string; value: any; selected?: boolean }

@Component({
  selector: 'drp-date-ranges',
  templateUrl: './dropDownDateRangesComponent.html',  
  changeDetection: ChangeDetectionStrategy.OnPush  
})
export class DropDownDateRangesComponent implements OnInit {

  @Input() presets: Array<PresetItem>;

  items: Array<DropDownItem> = [];
  
  // Triggers when default range gets changed.
  @Output() readonly presetsChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() readonly customRange: EventEmitter<any> = new EventEmitter<any>();
  
  constructor(protected rangePickerService: RangeDatepickerService,
    protected presetService: PresetService
  ) { }

  ngOnInit() {

    this.items = [];
    let selectedRange: Range = this.rangePickerService.range;
    let isPresetSelected: boolean = false;

    this.presets.forEach(preset => {

      let currentItemSelected: boolean = false;

      if (!isPresetSelected)
        currentItemSelected = isPresetSelected = this.presetService.equal(preset.range, selectedRange)


      const item: DropDownItem = <DropDownItem>{ title: preset.predefinedLabel, value: preset, selected: currentItemSelected };
      this.items.push(item);

    });

    // Add custom range
    let isCustomRangeSelected: boolean = !isPresetSelected && selectedRange.fromDate != null && selectedRange.toDate != null;

    this.items.push({ title: 'Custom range', value: null, selected: isCustomRangeSelected }); // TODO Custom range text in config file.
    if (isCustomRangeSelected)
      this.customRange.emit();

  }

  
  onItemClick(item: DropDownItem) {

    for (const itm of this.items)
      itm.selected = itm.value == item.value;

    // Is preset?
    if (item.value)
      this.presetsChanged.emit(item.value); // Value is preset.

    else this.customRange.emit(item);
  }

  
}