import * as Immutable from "immutable"
import { ImmutableObject } from "../../shared/immutableObject";
import { ValueSyncType } from "./valueSyncType";
import { BaseEntity } from "./baseEntity";

export abstract class BaseSyncObject extends BaseEntity {
  
  public static readonly SYNC_TYPE_BY_PROPERTY = "syncTypeByProperty";  

  get syncTypeByProperty(): Immutable.Map<string, number> { return this.getInternalValue<Immutable.Map<string, number>>(BaseSyncObject.SYNC_TYPE_BY_PROPERTY); }
  setSyncTypeByProperty(syncTypeByProperty: Immutable.Map<string, number>): this { return this.setInternalValue(BaseSyncObject.SYNC_TYPE_BY_PROPERTY, syncTypeByProperty); }  
  
}