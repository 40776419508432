import { Injectable, Inject } from '@angular/core';
import * as Immutable from "immutable";
import { AppStore, BaseStore } from "../../state"
import { ConfMessageProvider, ConfPageSessionService } from "../../../configurator/providers";
import { AppStoreSubscriptionManager } from "../../providers";
import { ConfDataRequest, StateActionInfoMessage, StateActionResultMessage, StateCommand, RequestViews } from "../../models";
import { StateActionCreator } from "./stateActionCreator";
import { ManagedSubscription, SubscriptionOptions } from "../../../../shared/managedSubscription";
import { ModelFactory } from "../../providers/modelFactory";
import { GlobalDataStore } from "../../providers/globalData";

@Injectable()
export class StateStore extends BaseStore {

  constructor(
    @Inject(AppStore) public appStore: AppStore,
    @Inject(StateActionCreator) public actionCreator: StateActionCreator,
    @Inject(AppStoreSubscriptionManager) public appStoreSubscriptionManager: AppStoreSubscriptionManager,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(ConfMessageProvider) public messagesProvider: ConfMessageProvider,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    public globalDataStore: GlobalDataStore
  ) {
    super(appStore, appStoreSubscriptionManager, modelFactory);
  }

  public createRequest(rawObject?: Partial<ConfDataRequest>): ConfDataRequest {
    let confDataRequest = this.modelFactory.createRequestOrCommand<ConfDataRequest>(ConfDataRequest.name, rawObject);
    confDataRequest.client = RequestViews.Summary;
    return confDataRequest;
  }

  public onStateActionInfo(subscriptionOptions: SubscriptionOptions<Immutable.List<StateActionInfoMessage>>): ManagedSubscription {
    return this.messagesProvider.onMessagesRequest<StateActionInfoMessage>(this.storeSession.confSessionId, StateActionInfoMessage.name, subscriptionOptions);
  }

  public onStateActionResult(subscriptionOptions: SubscriptionOptions<Immutable.List<StateActionResultMessage>>): ManagedSubscription {
    return this.messagesProvider.onMessagesRequest<StateActionResultMessage>(this.storeSession.confSessionId, StateActionResultMessage.name, subscriptionOptions);
  }

  public getPromoteInfo() {
    let model = this.createRequest({ confSessionId: this.storeSession.confSessionId });
    model.state = this.modelFactory.createRequestOrCommand<StateCommand>(StateCommand.name, { configurationId: this.storeSession.activeConfigurationId, promoteInfo: true });
    this.appStore.dispatch(this.actionCreator.promote(model));
  }

  public getDemoteInfo() {
    let model = this.createRequest({ confSessionId: this.storeSession.confSessionId });
    model.state = this.modelFactory.createRequestOrCommand<StateCommand>(StateCommand.name, { configurationId: this.storeSession.activeConfigurationId, demoteInfo: true });
    this.appStore.dispatch(this.actionCreator.demote(model));
  }

  public promote(promoteStateId: number, note?: string) {
    let model = this.createRequest({ confSessionId: this.storeSession.confSessionId });
    model.state = this.modelFactory.createRequestOrCommand<StateCommand>(StateCommand.name, { configurationId: this.storeSession.activeConfigurationId, setPromoteStateId: promoteStateId, note: note });    
    model.state.responseFormat = this.globalDataStore.getConfResponseFormat(RequestViews.Summary);
    this.appStore.dispatch(this.actionCreator.promote(model));
  }

  public demote(demoteStateId: number, note?: string) {
    let model = this.createRequest({ confSessionId: this.storeSession.confSessionId });
    model.state = this.modelFactory.createRequestOrCommand<StateCommand>(StateCommand.name, { configurationId: this.storeSession.activeConfigurationId, setDemoteStateId: demoteStateId, note: note });    
    model.state.responseFormat = this.globalDataStore.getConfResponseFormat(RequestViews.Summary);
    this.appStore.dispatch(this.actionCreator.demote(model));
  }
}