import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AccordionGroupComponent } from "./accordionGroupComponent";
import { AccordionComponent } from "./accordionComponent";
import { AccordionToggleComponent } from "./accordionToggleComponent";
import { AccordionHeading } from "./accordionHeadingComponent";
import { ImageModule } from "../image";

export * from "./accordionComponent";
export * from "./accordionGroupComponent";
export * from "./accordionToggleComponent";
export * from "./accordionHeadingComponent";

@NgModule({
  imports: [
    CommonModule,
    ImageModule
  ],
  declarations: [
    AccordionComponent,
    AccordionGroupComponent,
    AccordionToggleComponent,
    AccordionHeading
  ],
  exports: [
    AccordionComponent,
    AccordionGroupComponent,
    AccordionToggleComponent,
    AccordionHeading
  ]
})
export class AccordionModule {

}