import { Injectable } from "@angular/core";
import { Subject, Observable } from 'rxjs';

export interface PrintInfo {
  referenceElement: any,
  action: PrintAction
}

export enum PrintAction {
  CopyDOM = 'copy',
  RemoveDOM = 'remove',
  Print = 'print',
}

@Injectable()
export class PrintService {
  
  private subject = new Subject<any>();

  public getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
  
  public sendPrint(printInfo: PrintInfo) {
    this.subject.next(printInfo);
  }
  
}