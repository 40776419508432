import { ImmutableObject } from "../../../../shared/immutableObject";

export class ConfUIData extends ImmutableObject {

  public readonly CONF_ID = "confId";
  public readonly MIN_CALC_TABS_WIDTH = "minCalcTabsWidth";
  
  get confId(): number { return this.getInternalValue<number>(this.CONF_ID); }
  setConfId(confId: number): this { return this.setInternalValue(this.CONF_ID, confId); }

  get minCalcTabsWidth(): number { return this.getInternalValue<number>(this.MIN_CALC_TABS_WIDTH); }
  setCalcMinTabsWidth(minCalcTabsWidth: number): this { return this.setInternalValue(this.MIN_CALC_TABS_WIDTH, minCalcTabsWidth); }

}