import { ContentChildren, Component, QueryList, Input, AfterContentInit, OnDestroy, forwardRef, Inject, EventEmitter, Output } from "@angular/core";
import { BaseComponent } from "../../../pages/shared";

@Component({
  selector: "accordion-overlay",
  templateUrl: './accordionOverlayComponent.html'  
})
export class AccordionOverlayComponent extends BaseComponent {

  @Input()
  title: string;

  @Input()
  showHeader: boolean = false;


  @Output()
  public close = new EventEmitter();


  ngOnInit(): void {


  }

}