<email-form *ngIf="initProcess"
             [title]="userTitle"
             [description]="userDescription"
             [errorMessage]="errorMessage"
             isNew="true"
             [validateEmail]="validateEmail"
             [inputTitle]="userInputTitle"
             (userSubmit)="userSubmit.emit($event)"></email-form>

<code-form *ngIf="!initProcess"
             [title]="codeTitle"
             [description]="codeDescription"
             [inputTitle]="codeInputTitle"
             [errorMessage]="errorMessage"
             (codeSubmit)="codeSubmit.emit($event)"             
             ></code-form>