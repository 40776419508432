<div class="search-filter">
  <form name="form" class="search-box" (ngSubmit)="search()">
    <gridview classes="pt-2 px-0">
      <gridview-row classes="w-100">
        <search-criteria *ngFor="let criteria of confPropertyCriterias; let idx = index; trackBy: trackCriteria"
                         [criteria]="criteria"
                         [width]="width"
                         [showAdd]="idx == 0"
                         [showRemove]="idx > 0"
                         (onAdd)="addCriteria(criteria)"
                         (onRemove)="removeCriteria(criteria)"
                         (onValueChange)="onValueChange($event)"
                         (selectionChanged)="onSelectorAttributeChanged($event)"
                         class="col-auto px-0 pb-4"></search-criteria>

        <div class="w-100 pt-4" *ngIf="paramCriterias.size > 0"></div>

        <search-criteria *ngFor="let criteria of paramCriterias; let idx = index"
                         [criteria]="criteria"
                         [width]="width"
                         [showAdd]="idx == 0"
                         [showRemove]="idx > 0"
                         (onAdd)="addCriteria(criteria)"
                         (onRemove)="removeCriteria(criteria)"
                         (onValueChange)="onValueChange($event)"
                         (selectionChanged)="onSelectorAttributeChanged($event)"
                         class="col-auto px-0 pb-4"></search-criteria>
        <div [class.d-none]="!showIncludeChildren" class="col-auto pt-4 include-children">
          <check-box [value]="false" [view]="includeChildrenView"></check-box>
        </div>
        <div [class.d-none]="!showIncludePreviousRevisions" class="col-auto pt-4 include-children">
          <check-box [value]="false" [view]="includePreviousRevisionsView"></check-box>
        </div>
        <div class="col-auto pt-4">
          <div class="filter-buttons">
            <button type="submit" class="btn btn-primary text-uppercase">{{strings.Search}}</button>
            <div role="button" class="save-search align-middle" (click)="saveSearch()" *ngIf="canSaveSearch">
              <co-image width="20" height="20" type="svg" imageSet="red" [key]="saveSearchIcon" style="flex:none"></co-image>
              <span>{{saveSearchText}}</span>
            </div>
          </div>
        </div>
      </gridview-row>
    </gridview>
  </form>
</div>
<configuration-search-popup [configurationSearch]="configurationSearch"></configuration-search-popup>