import { BaseCommand } from "./baseCommand";

export class ComparisonCommand extends BaseCommand {
    
  startConfIds?: Array<number>;
  path?: string;
  calculateDifferency?: boolean;
  includeDetails?: boolean;
  childConfsOrder?: Array<Array<number>>;
  
}