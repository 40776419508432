import { Injectable, Inject } from "@angular/core";
import * as Immutable from "immutable";
import { SearchCriteriaRegion, AttributeField, SearchCriteriaModel } from "../models";
import { SearchCriteriaHelper } from "./searchCriteriaHelper";
import { Strings, GlobalDataStore } from "../../../../shared/providers/globalData";
import { InputViewModel } from "../../../../../shared/components";
import { ConfSearchCriteria } from "../../../../shared/models/entities/confSearchCriteria";

// TODO: Merge search data providers.
@Injectable()  
export class SearchCriteriaModelsProvider {

  protected criteriasByRegion: Immutable.Map<SearchCriteriaRegion, Immutable.List<SearchCriteriaModel>> = Immutable.Map<SearchCriteriaRegion, Immutable.List<SearchCriteriaModel>>();

  constructor(@Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,    
    public criteriaHelper: SearchCriteriaHelper
  ) { }

  /**
   * 
   * @param index
   * @param name
   * @param selected // TODO: remove it.
   * @param selectorAttributes
   * @param region
   * @param addAfterIdentifier
   */
  addCriteria(controlId: string, name: string, selectorAttributes: Array<AttributeField>, region: SearchCriteriaRegion, addAfterIdentifier?: string, existingValue?: Array<ConfSearchCriteria>): void {
    
    let criteria: SearchCriteriaModel = this.createCriteria(controlId, name, region, selectorAttributes, existingValue);
    this.addAfter(criteria, addAfterIdentifier);

  }


  public setValue(controlId: string, region: SearchCriteriaRegion, val: Array<ConfSearchCriteria>): void {

    let criterias: Immutable.List<SearchCriteriaModel> = this.criteriasByRegion.get(region);

    let index: number = criterias.findIndex(x => x.controlId == controlId);

    if (index < 0) {
      console.log(`Control Id ${controlId} is not found`);
      return;
    }

    let criteria = criterias.get(index);
    if (criteria) {

      // Don't set value if it is same, It prevents extra refreshes.
      if (JSON.stringify(criteria.value) == JSON.stringify(Immutable.List(val)))
        return;

      criteria = criteria.setValue(Immutable.List(val));
    }

    criterias = criterias.remove(index);
    criterias = criterias.insert(index, criteria);

    this.criteriasByRegion = this.criteriasByRegion.set(criteria.region, criterias);

  }

  protected createCriteria(controlId: string, name: string, region: SearchCriteriaRegion, selectorAttributes: Array<AttributeField>, value: Array<ConfSearchCriteria>): SearchCriteriaModel {

    if (!controlId)
      controlId = this.criteriaHelper.uniqueIdentifier(region.toString());

    let criteria: SearchCriteriaModel = new SearchCriteriaModel();

    criteria = criteria.setControlId(controlId);
    criteria = criteria.setSelectorAttributes(Immutable.List<AttributeField>(selectorAttributes));
    criteria = criteria.setRegion(region);
    criteria = criteria.setValue(Immutable.List(value));      

    let selectedAttribute = selectorAttributes.find(x => x.id === name);
    if (selectedAttribute)
      criteria = criteria.setSelectedAttribute(selectedAttribute);
    else if (selectorAttributes.length > 0) {
      criteria = criteria.setSelectedAttribute(selectorAttributes[0]); // First value would be the default one.
    }
    
    return criteria;
  }

  
  /**
   * Makes the clone from provided criteria, but it does not copy the selected values, only makes the structure clone.
   * @param from
   */
  clone(from: SearchCriteriaModel): void {

    let regionalCriterias: Immutable.List<SearchCriteriaModel> = this.getCriteriasByRegion(from.region);

    let position: number = regionalCriterias.findIndex(x => x.selectedAttribute.id == from.selectedAttribute.id) + 1;

    // Make a clone
    let cloned: SearchCriteriaModel = this.createCriteria(this.criteriaHelper.uniqueIdentifier(from.region.toString()), from.region.toString(), from.region, from.selectorAttributes.toArray(), null);

    // Insert into list
    regionalCriterias = regionalCriterias.insert(position, cloned)   

    // Update map.
    this.criteriasByRegion = this.criteriasByRegion.set(from.region, regionalCriterias);
  }

  addAfter(toBeAdded: SearchCriteriaModel, addAfterIdentifier: string) {

    if (this.criteriasByRegion.has(toBeAdded.region)) {

      let criterias = this.criteriasByRegion.get(toBeAdded.region);

      if (addAfterIdentifier) {

        let findIndex = this.criteriasByRegion.get(toBeAdded.region).findIndex(x => x.controlId === addAfterIdentifier);
        criterias = criterias.insert(findIndex, toBeAdded);

      }
      else {
        criterias = criterias.push(toBeAdded);
      }

      this.criteriasByRegion = this.criteriasByRegion.set(toBeAdded.region, criterias);
    }
    else {

      this.criteriasByRegion = this.criteriasByRegion.set(toBeAdded.region, Immutable.List<SearchCriteriaModel>([toBeAdded]));

    }

  }

  exists(controlId: string, region: SearchCriteriaRegion): boolean {

    if (!this.criteriasByRegion.has(region))
      return false;

    return this.criteriasByRegion.get(region).findIndex(x => x.controlId == controlId) > -1;
  }

  existsCriteriaAttribute(selectedAttributeName: string, region: SearchCriteriaRegion): boolean {

    if (!this.criteriasByRegion.has(region))
      return false;

    return this.criteriasByRegion.get(region).findIndex(x => x.selectedAttribute.id == selectedAttributeName) > -1;
  }

  /**   
   * Enables/Disables the selector attributes.
   * @param attributeName
   * @param except ignores the criteria when updating the enable status
   * @param status
   */
  enableAttributeExcept(attributeName: string, except: SearchCriteriaModel, status: boolean): void {

    let criterias: Immutable.List<SearchCriteriaModel> = this.criteriasByRegion.get(except.region);
    let newCriteriasList: Immutable.List<SearchCriteriaModel> = Immutable.List<SearchCriteriaModel>();
    criterias.forEach((c, index) => {

      if (c.controlId != except.controlId) {

        let index = c.selectorAttributes.findIndex(x => x.id == attributeName);
        let indexedAttribute = c.selectorAttributes.get(index);
        indexedAttribute.enabled = status;

        let newCriteria = c.setControlId(c.controlId);
        newCriteriasList = newCriteriasList.push(newCriteria);

      } else newCriteriasList = newCriteriasList.push(c);
      

    });

    this.criteriasByRegion = this.criteriasByRegion.set(except.region, newCriteriasList);

  }

  isAttributeSelected(attributeName: string, region: SearchCriteriaRegion): boolean {

    return !!this.getCriteriasByRegion(region).find(x => x.selectedAttribute.id == attributeName);

  }

  emptyCriterias(): void {
    this.criteriasByRegion = this.criteriasByRegion.clear();
  }

  hasMatchingData(confPropertiesserverCriterias: Map<string, Array<ConfSearchCriteria>>): boolean {

    if (confPropertiesserverCriterias.size == 0 && this.getCriteriasByRegion(SearchCriteriaRegion.ConfProperty).size == 0)
      return true;

    let confPropertyCriterias = this.getCriteriasByRegion(SearchCriteriaRegion.ConfProperty);
    let keys = Array.from(confPropertiesserverCriterias.keys());
    for (let key of keys) {

      if (confPropertyCriterias.findIndex(x => x.controlId == key) < 0)
        return false;
    }
      
    return true;
  }

  /**
   * TODO: Need more refactoring.
   * @param attributeName
   * @param region
   * @param selectorAttributes
   * @param controlId
   */
  updateSelectedAttributeField(attributeName: string, region: SearchCriteriaRegion, selectorAttributes?: Array<AttributeField>, controlId?: string): void {

    let criterias = this.criteriasByRegion.get(region);
    if (!criterias)
      return;

    let newCriterias: Immutable.List<SearchCriteriaModel> = Immutable.List<SearchCriteriaModel>();

    let updateCriteria: (ct: SearchCriteriaModel) => SearchCriteriaModel = (ct) => {

      // Update the selector attributes if they provided.
      if (selectorAttributes)
        ct = ct.setSelectorAttributes(Immutable.List<AttributeField>(selectorAttributes));

      let prevSelectedAttrName = attributeName ? attributeName : ct.selectedAttribute.id;

      // Fetch out the new selected attribute field and set it to criteria.
      let newSelectedAttribute = ct.selectorAttributes.find(x => x.id === prevSelectedAttrName);

      if (newSelectedAttribute)
        ct = ct.setSelectedAttribute(newSelectedAttribute);

      else ct = ct.setSelectedAttribute(selectorAttributes[0]);

      return ct;
    };

    if (controlId) {

      let index = criterias.findIndex(x => x.controlId == controlId);

      if (index < 0)
        return;

      let ct = criterias.get(index);
      ct = updateCriteria(ct);

      criterias = criterias.remove(index);
      criterias = criterias.insert(index, ct);
      this.criteriasByRegion = this.criteriasByRegion.set(region, criterias);

      return;
    }


    for (let index = 0; index < criterias.size; index++) {

      let ct = criterias.get(index);

      if (ct.selectedAttribute.id === attributeName)
        ct = updateCriteria(ct);
      
      newCriterias = newCriterias.push(ct);
    }

    this.criteriasByRegion = this.criteriasByRegion.set(region, newCriterias);
  }


  removeCriteria(criteria: SearchCriteriaModel): void {

    let region = criteria.region;
    let criterias = this.criteriasByRegion.get(criteria.region);

    let index = criterias.findIndex(x => x.controlId == criteria.controlId);

    if (index < 0)
      return;
    
    criterias = criterias.remove(index);    
    this.criteriasByRegion = this.criteriasByRegion.set(region, criterias);
    
  }

  removeParameters(): void {
    this.criteriasByRegion = this.criteriasByRegion.remove(SearchCriteriaRegion.Parameter);
  }

  updateSelectorAttributesByRegion(region: SearchCriteriaRegion, selectorAttributes: Array<AttributeField>): void {

    let criterias = this.getCriteriasByRegion(region);
    criterias.forEach(criteria => {

      this.updateSelectedAttributeField(criteria.selectedAttribute.id, region, selectorAttributes);

    });

  }

  getCriteriasByRegion(region: SearchCriteriaRegion): Immutable.List<SearchCriteriaModel> {
    return this.criteriasByRegion.get(region) || Immutable.List<SearchCriteriaModel>();
  }

 

}