import * as Immutable from "immutable";
import { ImmutableObject } from "../../../../shared/immutableObject";
import { KeyValue } from "../responses";

export class ComparisonValue extends ImmutableObject {

  protected readonly TITLE = "title";
  protected readonly UNIT = "unit";
  protected readonly TYPE = "type";
  protected readonly ICONS = "icons";
  protected readonly VALUES = "values";  
  protected readonly DIFFERENCY_LEVELS = "differencyLevels";  


  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get unit(): string { return this.getInternalValue<string>(this.UNIT); }
  setUnit(unit: string): this { return this.setInternalValue(this.UNIT, unit); }

  get type(): string { return this.getInternalValue<string>(this.TYPE); }
  setType(type: string): this { return this.setInternalValue(this.TYPE, type); }

  get icons(): Immutable.List<string> { return this.getInternalValue<Immutable.List<string>>(this.ICONS); }
  setIcons(icons: Immutable.List<string>): this { return this.setInternalValue(this.ICONS, icons); }

  get values(): Immutable.List<KeyValue<string, string>> { return this.getInternalValue<Immutable.List<KeyValue<string, string>>>(this.VALUES); }
  setValues(values: Immutable.List<KeyValue<string, string>>): this { return this.setInternalValue(this.VALUES, values); }

  get differencyLevels(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.DIFFERENCY_LEVELS); }
  setDifferencyLevels(differencyLevels: Immutable.List<number>): this { return this.setInternalValue(this.DIFFERENCY_LEVELS, differencyLevels); }
  
}