import { BaseCommand } from "./baseCommand";

export class AccountVerificationCommand extends BaseCommand {

  usernameOrEmail: string;
  initRequest: boolean;

  code: string;
  verifyRequest: boolean;

  newAccountRequest: boolean;
  accountRecoverRequest: boolean;
  
}