<div #visualObjectContainer>
  <div class="visual-object-margin dataselector">
    <data-selector-table-inline *ngIf="showInline"
                                [decoration]="decoration"
                                [confValue]="confDataSelectorValue">
    </data-selector-table-inline>

    <div *ngIf="!showInline" [style.max-width.%]="'100'" >
      <label class="static-label-inline">{{decoration.title}}</label>
      <div class="input-container">
        <icon [view]="view"></icon>

        <div class="w-100 input-field-container" (click)="openTable()">
          <input class="pl-3 flex-fill ellipsis input-field clickable" disabled [attr.data-target]="'#'+decoration.longId" placeholder="{{placeholder}}" value="{{value}}">
        </div>
        <co-image class="center align-middle" [width]="'18px'" [height]="'18px'" type="svg" key="menu-2" title="{{strings.Details}}" (click)="openTable()" [attr.data-target]="'#'+decoration.longId" role="button" [imageSet]="imageSet"></co-image>
        <co-image class="right px-2 align-middle" [ngClass]="{'disabled': !hasValue}" [width]="'24px'" [height]="'24px'" type="svg" key="delete" title="{{strings.Clear_Selection}}" (click)="clearValue()" role="button" [imageSet]="imageSet"></co-image>
      </div>
    </div>

    <data-selector-table-modal *ngIf="showTable"
                               #selectorModelComponent
                               [decoration]="decoration"
                               [confValue]="confDataSelectorValue"
                               [isSidebar]="isSidebar"
                               (onClosed)="closeTable()">
    </data-selector-table-modal>
  </div>
</div>
