import { Component, ViewChild, ElementRef, ChangeDetectorRef, Input, Host, Inject, AfterContentInit, AfterViewInit } from "@angular/core";
import { BaseLineItemComponent } from './baseLineItemComponent';
import { LineDropdownItemComponent } from '../lineDropdownItemComponent';

@Component({
  selector: 'standard-line-item',
  template: `<button title="{{view.title}}" *ngIf="!view.hideIfDisabled || !view.disabled" class="dropdown-item ellipsis" [class.disable-item]="view.disabled" type="button" (click)="onClick($event)">{{view.title}}</button>`
})
export class StandardLineItemComponent extends BaseLineItemComponent {

  constructor( @Host() @Inject(LineDropdownItemComponent) public item: LineDropdownItemComponent, public cdr: ChangeDetectorRef) {
    super(item, cdr);
  }
}