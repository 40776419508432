import { Component, Input, Host, OnInit, Inject, ContentChild, ElementRef, ChangeDetectorRef, Output, AfterViewInit, AfterContentInit, forwardRef, ChangeDetectionStrategy } from "@angular/core";
import { BrowserInfo } from "../../utils";
import { InputViewModel, DisplayStyle } from "../shared";
import { RadioGroupComponent } from "./radioGroupComponent";
import { BaseRadioStrategyComponent } from "./strategies/baseRadioStrategyComponent";

@Component({
  selector: "radio-button",
  templateUrl: './radioButtonComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioButtonComponent implements OnInit, AfterViewInit, AfterContentInit {

  template: BaseRadioStrategyComponent;

  // Note! It makes the whole Group readOnly, but the reason of defining variable in item is that It only shows the lock icon to that specific item which causes the complete group to be readonly.
  // readonly: boolean = false;


  constructor( @Host() @Inject(forwardRef(() => RadioGroupComponent)) public radioGroup: RadioGroupComponent,
    @Inject(BrowserInfo) public browserInfo: BrowserInfo,
    public cdr: ChangeDetectorRef
  ) {
  }

  public DISPLAY_STYLE_OPTIONS: typeof DisplayStyle = DisplayStyle;

  @Input()
  public view: InputViewModel;

  @Input()
  public selectedViewId: string

  @Input()
  public isDisabled: boolean;

  @Input()
  public isReadOnly: boolean;


  ngOnInit() {

  }

  ngAfterViewInit() {

  }


  ngAfterContentInit() {

  }

  updateStyle(): void {
    this.template.updateStyle();
  }
}