import { Component, Inject } from "@angular/core";

import { ConfSearchCriteria, ConfSearchProperty } from "../../../../../shared/models";
import { isNumeric } from "../../../../../../shared/utils/isNumeric";
import { BaseSearchInputComponent } from "../baseSearchInputComponent";
import { SearchTimeSpan } from "./searchTimeSpan";
import { DateRangeOptions, Range, DateValueModel, PresetItem, TimeSpanType, ConfigDatepickerService, RangeDatepickerService } from "../../../../../../shared/components/dateRangePicker";
import { GlobalServiceLocator } from "../../../../../../shared/providers";
import { SearchCriteriaHelper, DateRangeCalculator } from "../../providers";
import { ImageSets } from "../../../../../../shared/utils/imageSets";
import { GlobalDataStore } from "../../../../../shared/providers/globalData";

@Component({
  selector: 'search-calendar-input',
  templateUrl: './searchCalendarInputComponent.html',
})
// Refactor this class.
export class SearchCalendarInputComponent extends BaseSearchInputComponent {

  public defaultRanges: Array<PresetItem> = [];
  public rangeModelBySearchTimeSpan = new Map<SearchTimeSpan, PresetItem>(); // TODO remove this map.
  public chosenDate: Range = { fromDate: null, toDate: null };  
  public options: DateRangeOptions;
  public imageSet: string;

  constructor(public globalDataStore: GlobalDataStore, protected searchCriteriaHelper: SearchCriteriaHelper, protected dateRangeCalculator: DateRangeCalculator) { super(searchCriteriaHelper); }

  ngOnInit() {

    this.imageSet = this.getImageSet(ImageSets.Control);

    this.setDefaultRanges();

    const dateFormat = this.globalDataStore.globalSettings.dateFormat;
    this.options = <DateRangeOptions> {

      predefinedItems: this.defaultRanges,
      format: dateFormat ? dateFormat : 'yyyy-MM-dd',
      range: this.chosenDate, // No range defined in beginning.
      emptyValueLabel: ""     
    };

  }

  public setDefaultRanges(): void {

    // Store all predefined ranges in a map so that we could retreive them back easily.
    this.rangeModelBySearchTimeSpan.set(SearchTimeSpan.Today, this.presetItem(SearchTimeSpan.Today, this.dateRangeCalculator.todayRange));
    this.rangeModelBySearchTimeSpan.set(SearchTimeSpan.Yesterday, this.presetItem(SearchTimeSpan.Yesterday, this.dateRangeCalculator.yesterdayRange));
    this.rangeModelBySearchTimeSpan.set(SearchTimeSpan.Last7Days, this.presetItem(SearchTimeSpan.Last7Days, this.dateRangeCalculator.last7DaysRange));
    this.rangeModelBySearchTimeSpan.set(SearchTimeSpan.Last30Days, this.presetItem(SearchTimeSpan.Last30Days, this.dateRangeCalculator.last30DaysRange));
    this.rangeModelBySearchTimeSpan.set(SearchTimeSpan.ThisQuarter, this.presetItem(SearchTimeSpan.ThisQuarter, this.dateRangeCalculator.thisQuaterRange));
    this.rangeModelBySearchTimeSpan.set(SearchTimeSpan.PreviousQuarter, this.presetItem(SearchTimeSpan.PreviousQuarter, this.dateRangeCalculator.previousQuaterRange));
    this.rangeModelBySearchTimeSpan.set(SearchTimeSpan.ThisYear, this.presetItem(SearchTimeSpan.ThisYear, this.dateRangeCalculator.thisYearRange));
    this.rangeModelBySearchTimeSpan.set(SearchTimeSpan.PreviousYear, this.presetItem(SearchTimeSpan.PreviousYear, this.dateRangeCalculator.previousYearRange));

    // Default ranges in array format.
    this.defaultRanges = Array.from(this.rangeModelBySearchTimeSpan.values());

    
    // Set value from the model.
    // Try to match the current value with the defined range models.
    if (this.extractValue) {
      if (isNumeric(this.extractValue)) {
        let presetItem = this.rangeModelBySearchTimeSpan.get(<SearchTimeSpan>(+this.extractValue));
        this.chosenDate = presetItem.range;
      }
      else {

        let [fromDateStr, toDateStr] = (this.extractValue).split("...");
        if (fromDateStr && toDateStr) {

          // Model has value but not numberic -> must be custom -> just assign it.
          const from = new Date(fromDateStr);
          const to = new Date(toDateStr);
          this.chosenDate = { fromDate: from, toDate: to };
        }
      }

    }
  }

  public presetItem(searchTimeSpan: SearchTimeSpan, range: Range): PresetItem {

    return <PresetItem>{

      predefinedLabel: this.localizedTimeSpanLabel(searchTimeSpan),
      identifier: searchTimeSpan.toString(),
      range: range

    }
  }

  public localizedTimeSpanLabel(searchTimeSpan: SearchTimeSpan) {
    return this.strings.getString(SearchTimeSpan[searchTimeSpan]);
  }


  /** Returns true if the this criteria has a value, otherwise false. */
  public get hasValue(): boolean {
    return this._value != null && this._value != "";
  }

  public _value = "";
  /** Returns the current user input value. */
  public get value(): any {
    return this._value;
  }
  
  onRangeChanged(valModel: DateValueModel) {
    this._value = valModel.value;
    this.notifyValueChange(null);
  }

}