import { Component, Input, SimpleChanges } from "@angular/core";
import { BaseComponent } from "../..";
import { ParamType } from "../shared";

@Component({
  selector: 'visual-object-vault-icon',
  templateUrl: './visualObjectVaultIconComponent.html'
})
export class VisualObjectVaultIconComponent extends BaseComponent {

  @Input()
  public size: number = 40;

  @Input()
  public iconSize: number = 20;

  /** Image set for svg. Like "primary", "primary-dark" etc. */
  @Input()
  imageSet: string = 'primary';

  @Input()
  icon: string = "images/59_2.png"; //TODO

  ngOnInit() {
  }
  
  ngOnChanges(changes: SimpleChanges) {
  }
}
