import { Component, Input } from "@angular/core";

@Component({
  selector: "gridview",
  templateUrl: './gridviewComponent.html'
})
export class GridviewComponent {

  @Input()
  width: string;

  @Input()
  minWidth: string;

  @Input()
  classes: string = "";

  @Input()
  fluid: boolean = true;

  @Input()
  scrollable = false;

  styles: string;
  
  ngOnInit() {
    this.styles = `${this.fluid ? 'container-fluid' : 'container' } ${this.classes != "" ? " " + this.classes : ""}`;
  }
  
}