import { Component, EventEmitter, Input, Output, SimpleChanges } from "@angular/core";
import { InputViewModel } from "../../../../shared/components";
import { BaseComponent } from "../../../shared";
import { GlobalDataStore } from "../../../shared/providers/globalData";


export interface FormModel {
  captcha?: string;
}

@Component({
  selector: 'email-form',
  templateUrl: './emailFormComponent.html'
})
export class EmailFormComponent extends BaseComponent {

  @Input()
  public title: string;

  @Input()
  public description: string;

  @Input()
  public inputTitle: string;

  @Input()
  public isNew: boolean = false;

  @Input()
  public validateEmail: boolean = false;

  @Input()
  public errorMessage: string;

  public siteKey: string;

  public formModel: FormModel = {};  

  //public reCaptchaResolved: boolean = false;

  public userNameView: InputViewModel;

  //@ViewChild("captchaControl")
  //public captchaControl: any;

  public showAnonymousAccessLogin: boolean = false;
  public showSignUp: boolean = false;
  public showPasswordRecovery: boolean = false;

  @Output()
  public userSubmit = new EventEmitter<InputViewModel>();

  constructor(public globalDataStore: GlobalDataStore) {
    super();
  }
 
  ngOnInit() {
    this.userNameView = new InputViewModel(this.inputTitle);
    this.userNameView.name = "Email";
    this.userNameView.icon = "user";

    this.siteKey = this.globalDataStore.getGlobalData().globalSettings.googleReCaptcha;

    super.ngOnInit();
  }

  onSubmit(): void {

    // TODO: Do we need captcha resolved verification through response??
    if (!this.userNameView.value || (this.userNameView.value as string).trim() == '') {
      this.errorMessage = this.strings.EmailOrUsernameEmptyMessage;
      return;
    }

    if (this.validateEmail && !this.isValidEmail(this.userNameView.value)) {
      this.errorMessage = this.strings.InvalidEmailAddress;
      return;
    }

    if (this.siteKey && !this.formModel.captcha) {
      this.errorMessage = this.strings.ReCaptchaValidationError;
      return;
    }

    this.userSubmit.emit(this.userNameView);

  }

  resolved(captchaResponse: string) {

    //this.reCaptchaResolved = true;
    //console.log(`Resolved captcha with response ${captchaResponse}:`);

  }

  isValidEmail(email): boolean {

    // TODO: We can improve this validation by using angular built-in feature but It needs more work.
    let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);

  }  

}