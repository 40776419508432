import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserSupportComponent } from "./browserSupportComponent";
import { ImageModule } from "../image";
import { MessageBoxModule } from "../messageBox";

export * from "./browserSupportComponent";

@NgModule({
  imports: [
    CommonModule,
    ImageModule,
    MessageBoxModule
  ],
  declarations: [
    BrowserSupportComponent    
  ],
  exports: [
    BrowserSupportComponent
  ],

  providers: []
})
export class BrowserSupportModule {

}