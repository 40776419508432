import * as Immutable from "immutable";
import { Decoration } from "./decoration";
import { BaseEntity } from "../../baseEntity";
import { CodeDecorationItem } from "./codeDecorationItem";

export class CodeDecoration extends Decoration {

  protected readonly DISPLAY_STYLE = "displayStyle";
  protected readonly SUMMARY_DISPLAY_STYLE = "summaryDisplayStyle";
  protected readonly HIDE_EMPTY_DECORATION = "hideEmptyDecoration";
  protected readonly SHOW_IN_CONFIGURATOR = "showInConfigurator";
  protected readonly SHOW_IN_SUMMARY = "showInSummary";
  protected readonly ITEMS = "items";
  protected readonly HEIGHT = "height";

  get displayStyle(): string { return this.getInternalValue<string>(this.DISPLAY_STYLE); }
  setdisplayStyle(displayStyle: string): this { return this.setInternalValue(this.DISPLAY_STYLE, displayStyle); }

  get hideEmptyDecoration(): boolean { return this.getInternalValue<boolean>(this.HIDE_EMPTY_DECORATION); }
  sethideEmptyDecoration(hideEmptyDecoration: boolean): this { return this.setInternalValue(this.HIDE_EMPTY_DECORATION, hideEmptyDecoration); }

  get showInConfigurator(): boolean { return this.getInternalValue<boolean>(this.SHOW_IN_CONFIGURATOR); }
  setshowInConfigurator(showInConfigurator: boolean): this { return this.setInternalValue(this.SHOW_IN_CONFIGURATOR, showInConfigurator); }

  get showInSummary(): boolean { return this.getInternalValue<boolean>(this.SHOW_IN_SUMMARY); }
  setshowInSummary(showInSummary: boolean): this { return this.setInternalValue(this.SHOW_IN_SUMMARY, showInSummary); }

  get summaryDisplayStyle(): string { return this.getInternalValue<string>(this.SUMMARY_DISPLAY_STYLE); }
  setSummaryDisplayStyle(summaryDisplayStyle: string): this { return this.setInternalValue(this.SUMMARY_DISPLAY_STYLE, summaryDisplayStyle); }

  get items(): Immutable.List<CodeDecorationItem> { return this.getInternalValue<Immutable.List<CodeDecorationItem>>(this.ITEMS); }
  setItems(items: Immutable.List<CodeDecorationItem>): this { return this.setInternalValue(this.ITEMS, items); }

  get height(): number { return this.getInternalValue<number>(this.HEIGHT); }
  setHeight(height: number): this { return this.setInternalValue(this.HEIGHT, height); }

}

export enum CodeDisplayStyles {
  Render = 'Render',
  Popup = 'Popup',
  DownloadLink = 'DownloadLink'
}