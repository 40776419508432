import * as Immutable from "immutable";
import { BaseEntity } from "../../baseEntity";

export class ConfAttributeValue extends BaseEntity {

  protected readonly TITLE = "title";
  protected readonly VALUE = "value";
  protected readonly UNIT = "unit";
  protected readonly VALUE_TYPE = "valueType";
  protected readonly VALUE_BY_NUMERIC_FORMAT = "valueByNumericFormat";

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get value(): string { return this.getInternalValue<string>(this.VALUE); }
  setValue(value: string): this { return this.setInternalValue(this.VALUE, value); }

  get unit(): string { return this.getInternalValue<string>(this.UNIT); }
  setUnit(unit: string): this { return this.setInternalValue(this.UNIT, unit); }

  get valueType(): string { return this.getInternalValue<string>(this.VALUE_TYPE); }
  setValueType(valueType: string): this { return this.setInternalValue(this.VALUE_TYPE, valueType); }

  get valueByNumericFormat(): Immutable.Map<string, string> { return this.getInternalValue<Immutable.Map<string, string>>(this.VALUE_BY_NUMERIC_FORMAT); }
  setValueByNumericFormat(valueByNumericFormat: Immutable.Map<string, string>): this { return this.setInternalValue(this.VALUE_BY_NUMERIC_FORMAT, valueByNumericFormat); }
}