import { SimpleObject } from "./simpleObject";

export class Exception extends SimpleObject {
  constructor(propertyMap?: any) {
    super(propertyMap);
  }

  public readonly MESSAGE = "message";
  public readonly DETAIL = "detail";
  public readonly URL = "url";
  public readonly STACK_TRACE = "stackTrace";  
  public readonly ORIGINAL_ERROR = "originalError";

  public get message(): string {
    return this.getInternalValue<string>(this.MESSAGE);
  }

  public set message(message: string) {
    this.setInternalValue(this.MESSAGE, message);
  }

  public get detail(): string {
    return this.getInternalValue<string>(this.DETAIL);
  }

  public set detail(detail: string) {
    this.setInternalValue(this.DETAIL, detail);
  }

  public get url(): string {
    return this.getInternalValue<string>(this.URL);
  }

  public set url(url: string) {
    this.setInternalValue(this.URL, url);
  }

  public get stackTrace(): string {
    return this.getInternalValue<string>(this.STACK_TRACE);
  }

  public set stackTrace(stackTrace: string) {
    this.setInternalValue(this.STACK_TRACE, stackTrace);
  } 

  public get originalError(): any {
    return this.getInternalValue<any>(this.ORIGINAL_ERROR);
  }

  public set originalError(originalError: any) {
    this.setInternalValue(this.ORIGINAL_ERROR, originalError);
  }  
}