import * as Immutable from "immutable";
import { BaseEntity } from "../../baseEntity";

export class ConfMultiSetValue extends BaseEntity {

  protected readonly VALUE = "value";
  protected readonly IS_READ_ONLY = "isReadOnly";
  protected readonly IS_DISALLOWED = "isDisallowed";

  get value(): boolean { return this.getInternalValue<boolean>(this.VALUE); }
  setValue(value: boolean): this { return this.setInternalValue(this.VALUE, value); }

  get isDisallowed(): boolean { return this.getInternalValue<boolean>(this.IS_DISALLOWED); }
  setIsDisallowed(isDisallowed: boolean): this { return this.setInternalValue(this.IS_DISALLOWED, isDisallowed); }

  get isReadOnly(): boolean { return this.getInternalValue<boolean>(this.IS_READ_ONLY); }
  setIsReadOnly(isReadOnly: boolean): this { return this.setInternalValue(this.IS_READ_ONLY, isReadOnly); }
}