import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
import { ImageModule } from "../image";
import { InputViewModel } from "../shared";
import { PasswordComponent } from "./passwordComponent";
import { HelpModule } from "../help";
import { IconModule } from "../icon";
import { PanelModule } from "../panel";

export * from "./passwordComponent";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ImageModule,
    HelpModule,
    IconModule,
    PanelModule
  ],
  declarations: [
    PasswordComponent
  ],
  exports: [
    PasswordComponent
  ]
})
export class PasswordModule {

}