import { ImmutableObject } from "../../../shared/immutableObject";
import * as Immutable from "immutable";
import { VisualObject } from "../../shared/models";

export class VisualObjectGroup extends ImmutableObject {

  public readonly ID = "Id";
  public readonly NAME = "name";
  public readonly GROW_VISUAL_OBJECT = "growVisualObject";  
  public readonly CSS_CLASS = "cssClass";  
  public readonly HANGINGS = "hangings";
  public readonly VISUAL_OBJECT = "visualObject";

  // Visual object id
  get id(): number { return this.getInternalValue<number>(this.ID); }
  setId(id: number): this { return this.setInternalValue(this.ID, id); }

  // Name of the visual object
  get name(): string { return this.getInternalValue<string>(this.NAME); }
  setName(name: string): this { return this.setInternalValue(this.NAME, name); }

  // CSS style
  get cssClass(): string { return this.getInternalValue<string>(this.CSS_CLASS); }
  setCssClass(css: string): this { return this.setInternalValue(this.CSS_CLASS, css); }

  // Grows the visual object to all available space.
  get growVisualObject(): boolean { return this.getInternalValue<boolean>(this.GROW_VISUAL_OBJECT); }
  setGrowVisualObject(growVisualObject: boolean): this { return this.setInternalValue(this.GROW_VISUAL_OBJECT, growVisualObject); }

  // Hangings to this object.
  get hangings(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.HANGINGS); }
  setHangings(hangings: Immutable.List<number>): this { return this.setInternalValue(this.HANGINGS, hangings); }

  get visualObject(): VisualObject { return this.getInternalValue<VisualObject>(this.VISUAL_OBJECT); }
  setVisualObject(visualObject: VisualObject): this { return this.setInternalValue(this.VISUAL_OBJECT, visualObject); }

}