export let PopupActions = {
  
  // Opens the popup
  Open: 'open',

  // closes the popup
  Close: 'close',

  // Triggers after closing the popup
  Closed: 'closed',

  // closes the popup's extension
  CloseExtension: 'closeExtension',

  // Stops the user interaction
  Disable: 'disable',

  // Enables the user interaction
  Enable: 'enable',

  // Moves the popup near to target position.
  Move: 'move',

  Update: 'update',

  None: 'none'
}

export class PopupInfo<TagType> {

  // Unique id for component.
  id: string;
  
  // Attach any object to it. Which would be accessible to all listeners.
  tag: TagType;

  action: string = PopupActions.None;

  positionX?: number;

  positionY?: number;

  referenceElementId: string;

  containerId: string;

}