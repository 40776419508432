import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem } from "./navigation";

@Component({
  selector: 'composite-item-detail',
  templateUrl: './compositeMenuItemDetailComponent.html'
})
export class CompositeMenuItemDetailFactory  {

  @Output()
  public onClose = new EventEmitter<any>();
  
  public displayStyle: string;

  @Input()
  public item: MenuItem;

  @Input()
  public configurationId: number;
  
  public ngOnInit(): void {

  }

  public closeDetailMenu(event): void {
    
    this.onClose.emit(event);

    if (event && event.stopPropagation)
      event.stopPropagation();
  }
  
}

