import { Inject, Component, Input, ViewChildren, forwardRef, QueryList, Optional } from "@angular/core";
import * as Immutable from "immutable";
import { BaseActionBoxComponent } from "../..";
import { ConfigurationSessionManager, ConfiguratorStore } from "../../../configurator/providers";
import { RouteRedirector } from "../../providers";
import { ConfRevision, Conf } from "../../models";
import { RevisionItemComponent } from "./revisionItemComponent";
import { StateStore } from "../states/stateStore";
import { ComparisonUrlParams } from "../../../../../core/pages/configurator/comparison/components/dragDrop/models/comparisonUrlParams";
import { ComparisonHelper } from "../../../../../core/pages/configurator/comparison";
import { GlobalDataStore } from "../../providers/globalData";
import { ValueChangeEventArgs, NotificationService, NotificationInfo, NotificationType } from "../../../../shared/components";

@Component({
  selector: 'revisions-action-box',
  templateUrl: './revisionsActionBoxComponent.html',
  providers: [StateStore]
})
export class RevisionsActionBoxComponent extends BaseActionBoxComponent {

  public revisions: Immutable.List<ConfRevision>;

  public showComparison: boolean = false;

  public checkedRevisions: Array<number> = [];

  @ViewChildren(forwardRef(() => RevisionItemComponent))
  public revisionItems: QueryList<RevisionItemComponent>;

  constructor(
    @Inject(ConfiguratorStore) public configuratorStore: ConfiguratorStore,
    @Inject(ComparisonHelper) public helper: ComparisonHelper,
    @Inject(RouteRedirector) public redirector: RouteRedirector,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(NotificationService) public notificationService: NotificationService,
  ) {
    super(configuratorStore);
  }

  ngOnInit() {
    super.ngOnInit();

    this.showComparison = this.globalDataStore.getGlobalData().globalSettings.enableCompareConfigurations;

    this.configuratorStore.onConfigurationChange(this.configurationId, this.confSessionId, (conf: Conf) => {

      this.initialize();    

    }).unsubscribeOn(this.unsubscribeSubject);
  }

  public setup(): void {
    if (this.conf)
      this.revisions = this.conf.revisions;
  }

  public shouldBeVisible(): boolean {
    return this.revisions && !this.revisions.isEmpty() && this.conf.authorization.canViewRevisions;
  }

  onCompareSelectionChanged($event: ValueChangeEventArgs): void {

    const valueChangedRevionId = Number($event.actionView.id);
    if (!$event.value) {

      // Remove value from selection.      
      const index = this.checkedRevisions.indexOf(valueChangedRevionId);
      this.checkedRevisions.splice(index, 1);

    }

    else {

      if (this.checkedRevisions.length >= this.globalDataStore.getGlobalData().globalSettings.maxConfigurationsInComparison) {
        this.notifyMaxLimit();
        $event.component.cancelValue(false);
        return;
      }

      this.checkedRevisions.push(valueChangedRevionId);
    }
  }

  notifyMaxLimit() {

    this.notificationService.notify(<NotificationInfo>{
      title: this.strings.Warning,
      message: this.strings.MaximumLimitForComparingConfigurations.replace('{0}', this.globalDataStore.getGlobalData().globalSettings.maxConfigurationsInComparison.toString()),
      type: NotificationType.Warning,
      selfClose: true,
      identifier: "warning-compare",
      sync: true
    });

  }

  public onComparisonClick(): void {

    this.blockUI();

    // Define params
    let params = new ComparisonUrlParams();
    params.ids = this.helper.comparisonId(this.checkedRevisions);
    params.path = '0';

    // Redirect
    this.redirector.redirectToComparison(params);
    
    
  }

}
