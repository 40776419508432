import { Inject, Component, Input, ChangeDetectorRef, Output, EventEmitter } from "@angular/core";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import { PopupService, InputViewModel } from "../../../../shared/components";
import { PopupIdentifiers, ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { PageActionCreator } from "../../../shared/providers/page";
import { HttpService } from "../../../../shared/providers/httpService";
import { GenericMessage } from "../../../shared/models/responses/messages/genericMessage";
import { NotificationService } from "../../../../shared/components/notification/notificationService";
import { NotificationInfo, NotificationType } from "../../../../shared/components/notification/notificationInfo";
import { ConfDataController } from "../../../shared/providers/configurationData/confDataController";
import { PageStore } from "../../../shared/providers/page/pageStore";
import { ModelFactory } from "../../../shared/providers/modelFactory";
import { RequestViews } from "../../../shared/models/requests/requestViews";
import { BaseRequest } from "../../../shared/models/requests/baseRequest";
import { AbstractConfDataCommand } from "../../../shared/models/requests/commands/abstractConfDataCommand";
import { PopupClientChoiceRequest } from "../../../shared/models/requests/popupClientChoiceRequest";
import { ApiMessage } from "../../../shared/models/responses/messages/apiMessage";
import * as Immutable from "immutable";
import { CompositeActions, CompositeActionService, ICompositeDataInfo } from "../../actions/composite/compositeActionService";
import { CompositeHelper } from "../../actions/composite/compositeHelper";
import { ApiResponse, ComplexSequentialPopups } from "../../../shared/models";
import { SequencReference } from "../../../shared/models/responses/messages/popupSequence";
import { PopupObject, Purpose } from "../../../shared/models/responses/messages/popupObject";

@Component({
	selector: 'crmIntegration-popup',
	templateUrl: './crmIntegrationPopupComponent.html'
})

// customizable popup object that shows popups pushed from server
export class CrmIntegrationPopupComponent extends AbstractPopupComponent {

	public id: string = PopupIdentifiers.MsCrmIntegrationPopup;

	public title: string;
	public text: string;

	public yesButtonText: string;
	public noButtonText: string;

	public popupWidth: string;
	public popupHeight: string;

	public popupManager: PopupManager;

	constructor(
		@Inject(ConfPageSessionService) public confPageSession: ConfPageSessionService,
		@Inject(PageActionCreator) public pageActionCreator: PageActionCreator,
		@Inject(PopupService) public popupService: PopupService,
		@Inject(HttpService) public httpService: HttpService,
		@Inject(NotificationService) public notificationService: NotificationService,
		@Inject(ConfDataController) public configuratorController: ConfDataController,
		@Inject(ConfiguratorStore) public configuratorStore: ConfiguratorStore,
		@Inject(PageStore) public pageStore: PageStore,
		@Inject(ModelFactory) public modelFactory: ModelFactory,
		@Inject(CompositeActionService) public actionService: CompositeActionService,
		@Inject(CompositeHelper) public compositeHelper: CompositeHelper,
		public cdr: ChangeDetectorRef
	) {
		super(cdr, popupService);
	}

	// entry point to the popup. tag is the popup message object 
	public open(tag: ComplexSequentialPopups) {

		// create instance of PopupManager
		this.popupManager = new PopupManager(tag);

		// get the first popupObject
		let popupObject = this.popupManager.getNextPopup();

		// initiate the popup variables to be shown according to the popup object
		this.setVariablesFromPopupObject(popupObject);

		// show popup
		this.show();
	}

	// set the popup variables to be shown according to the popup object
	setVariablesFromPopupObject(actualPopup: PopupObject) {
		// initiate the yes and no buttons texts
		this.yesButtonText = actualPopup.yesButtonText;
		this.noButtonText = actualPopup.noButtonText;

		// initiate the title and the text of the popup
		this.title = actualPopup.popupMessageTitle;
		this.text = actualPopup.popupMessage;

		// initiate the width and height of the popup
		this.popupWidth = actualPopup.popupWidth;
		this.popupHeight = actualPopup.popupHeight;
	}


	ngOnInit() { super.ngOnInit(); }
	show(): void { this.showInternal(); }


	// -------------- user choice handlers -------------- //

	onYesClick() {
		// get the actual popup message object
		let actualPopup = this.popupManager.actualPopup;

		// handle user choice by server and loacally
		this.handleYesChoiceByServerAndLocally(actualPopup);

		// switch conditionally to next popup
		let nextPopup: PopupObject;
		if (actualPopup.skipNextPopupIfUserAnswersYes) {
			nextPopup = this.popupManager.skipAndPop();
		} else
			nextPopup = this.popupManager.getNextPopup();

		if (nextPopup)
			this.setVariablesFromPopupObject(nextPopup);
		else
			this.popupService.close(this.id);
	}

	onNoClick() {
		// get the actual popup message object
		let actualPopup = this.popupManager.actualPopup;

		// handle user choice by server and loacally
		this.handleNoChoiceByServerAndLocally(actualPopup);

		// switch conditionally to next popup
		let nextPopup: PopupObject;
		if (actualPopup.skipNextPopupIfUserAnswersNo) {
			nextPopup = this.popupManager.skipAndPop();
		} else
			nextPopup = this.popupManager.getNextPopup();

		if (nextPopup)
			this.setVariablesFromPopupObject(nextPopup);
		else
			this.popupService.close(this.id);
	}

	// -------------- -------------------- -------------- //




	// ----- handle user choice by server and locally ----- //

	handleYesChoiceByServerAndLocally(actualPopup: PopupObject) {
		// handle user choice by server and loacally
		let serverRequest = this.handleYesChoiceByServer(actualPopup);
		serverRequest.subscribe(serverResponse => {
			let serverResponseData: ConfPopupClientChoiceResponseDto = serverResponse.data;
			this.handleServerResponseToUserChoice(serverResponseData);
			if (serverResponseData.success)
				this.handleYesChoiceLocally(actualPopup);
		});
	}

	handleNoChoiceByServerAndLocally(actualPopup: PopupObject) {
		// handle user choice by server and loacally
		let serverRequest = this.handleNoChoiceByServer(actualPopup);
		serverRequest.subscribe(serverResponse => {
			let serverResponseData: ConfPopupClientChoiceResponseDto = serverResponse.data;
			this.handleServerResponseToUserChoice(serverResponseData);
			if (serverResponseData.success)
				this.handleNoChoiceLocally(actualPopup, serverResponseData.extraInfo);
		});
	}

	// ----- ------------------------------------------- ----- //



	// ----- handle user choice by server ----- //

	handleYesChoiceByServer(actualPopup: PopupObject) {
		const requestUrl = this.constructRequestLinkFromPopupObject(actualPopup, PopupClientResponse.Yes);
		return this.httpService.get(requestUrl);
	}


	handleNoChoiceByServer(actualPopup: PopupObject) {
		const requestUrl = this.constructRequestLinkFromPopupObject(actualPopup, PopupClientResponse.No);
		return this.httpService.get(requestUrl);
	}

	// construct server request url
	constructRequestLinkFromPopupObject(popupObject: PopupObject, popupClientResponse: PopupClientResponse, purpose: Purpose = null, subConfId: number = null) {
		let model: PopupClientChoiceRequest = this.modelFactory.createAny(PopupClientChoiceRequest.name) as any;

		model.client = this.pageStore.getActiveClientType();

		model.sequencReference = popupObject.sequencReference;
		model.purpose = purpose ?? popupObject.purpose;
		model.popupClientResponse = popupClientResponse;
		model.sessionId = popupObject.sessionId;
		model.rootConfId = popupObject.rootConfId;
		model.subConfId = subConfId ?? popupObject.subConfId;
		model.subConfIdentity = popupObject.subConfIdentity;
		model.productId = popupObject.productId;
		model.generatedProductId = popupObject.generatedProductId;
		model.quoteDetailCrmIdentity = popupObject.quoteDetailCrmIdentity;
		model.quoteDetailCombinumIdentity = popupObject.quoteDetailCombinumIdentity;
		model.args = popupObject.args;

		return this.httpService.createUrl('api/configurator/popUpClientChoice', model);
	}

	handleServerResponseToUserChoice(serverResponse: ConfPopupClientChoiceResponseDto) {
		if (serverResponse.success) {
			if (serverResponse.showNotification)
				this.notificationService.notify(<NotificationInfo>{ selfClose: false, title: serverResponse.notificationTitle, message: serverResponse.notificationMessage, type: serverResponse.notificationType });
		}
		else
			// optional further handling if not the handling of the client response succeeded
			this.notificationService.notify(<NotificationInfo>{ selfClose: false, title: serverResponse.notificationTitle, message: serverResponse.notificationMessage, type: serverResponse.notificationType });
	}

	// ----- ------------------------------------------- ----- //





	// ----- - CUSTOMIZE HERE - handle user choice locally in client depending on its purpose as popup identifier and user choice - CUSTOMIZE HERE - ----- //

	handleYesChoiceLocally(actualPopup: PopupObject) {

		// user choice -> delete sub conf
		if (actualPopup.purpose === Purpose.DeleteConfiguration) {
			this.deleteSubConfiguration(actualPopup.subConfId);
		}

		// user choice -> Yes, create new sub conf that corresponds to specific quote detail
		if (actualPopup.purpose === Purpose.QuoteDetailsExistInCrmOutOfSyncWithChildconfigurations) {

			let generatedProductId = actualPopup.generatedProductId;
			this.createSubConfiguration(Number(actualPopup.rootConfId), Number(generatedProductId), actualPopup.quoteDetailCrmIdentity);

			//.then(() => {
			// then let the server connect this subconf with the corresponding quote detail
			// get the id of the new conf (last child of the root)
			//let newConf = this.configuratorStore.getChildConfInfos(Number(actualPopup.rootConfId), Number(actualPopup.sessionId)).slice(-1)[0];
			//let newConfId = newConf.longId;

			//let requestUrl = this.constructRequestLinkFromPopupObject(actualPopup, PopupClientResponse.Yes, Purpose.LinkSubConfToQuoteDetail, newConfId);

			//// request the server to link the newly created subconf to the corresponding quotedetail
			//this.httpService.get(requestUrl).subscribe(x => this.handleServerResponseToUserChoice(x));
			//});
		}
	}


	handleNoChoiceLocally(actualPopup: PopupObject, extraInfo: string) {

	}

	// ----- ------------------------------------------- ----- //






	// -------------------- helper methods -------------------- //

	// delete sub conf
	deleteSubConfiguration(subConfId) {
		this.actionService.notify(
			<ICompositeDataInfo<number>>{
				action: CompositeActions.Delete,
				focusedConfInfoId: subConfId
			}
		);
	}

	// create new sub-configuration
	createSubConfiguration(rootConfId, productId, quotedetailCrmId) {
		return this.compositeHelper.addChildConfigurationCorrespondingToCrmQuoteDetail(rootConfId, productId, quotedetailCrmId);
	}

}

// ----- ------------------------------------------- ----- //

// reflected in server side
export enum PopupClientResponse {
	Yes,
	No
}

// reflected in server side
export interface ConfPopupClientChoiceResponseDto {
	success?: boolean;
	showNotification?: boolean;
	notificationTitle?: string;
	notificationMessage?: string;
	notificationType?: string;
	extraInfo?: string;
}

export class PopupManager {

	public allPopups: Array<PopupObject> = [];
	public actualPopup: PopupObject;

	constructor(complexSequentials: ComplexSequentialPopups) {
		// combine all popups in all sequences coming from server
		complexSequentials.popupSequences.forEach(sequence => this.allPopups = this.allPopups.concat(sequence.popupObjects.toArray()));
		this.allPopups = this.allPopups.reverse();
	}

	// get the next popup
	getNextPopup(): PopupObject {
		if (this.allPopups.length !== 0) {
			this.actualPopup = this.allPopups.pop();
			return this.actualPopup;
		} else {
			this.actualPopup = null;
			return null;
		}
	}

	skip() {
		if (this.allPopups.length !== 0)
			this.allPopups.pop();
	}

	skipAndPop(): PopupObject {
		this.skip();
		return this.getNextPopup();
	}

	// check if the message object is not a simple popup
	public isComplexPopupMessage(complexSequentials: ComplexSequentialPopups): boolean {
		return complexSequentials.popupSequences.first().popupObjects.count() > 1;
	}
}
