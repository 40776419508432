import { Inject, Component, Input, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core";
import { PopoverService, PopperConfig, PopupService, DataCellView, PopupInfo } from "../../../../shared/components";
import { ConfigurationSessionManager } from "../../providers/configurationSessionManager";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import { PopupIdentifiers, ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { EmitterComponent, IEmitDataInfo } from "../../shared";
import { Subscription } from "rxjs";
import { BaseComponent } from "../../../shared";

@Component({
  selector: 'bom-image-popup',
  templateUrl: './bomImagePopupComponent.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class BomImagePopupComponent extends EmitterComponent {

  public config: PopupInfo<DataCellView>;

  public isLoaded: boolean = false;
  public image: string;
  public referenceElementId: string;
  public visible: boolean;
  protected referencedCell: DataCellView;

  @Output() public onReady = new EventEmitter();
  
  constructor(
    public popupService: PopupService,
    public storeSession: ConfPageSessionService,
    public cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {

    this.id = PopupIdentifiers.BomImage;
  
    super.ngOnInit();
  }

  protected observer(data: IEmitDataInfo<any>): void {

    this.config = data.tag as PopupInfo<DataCellView>;
    this.referencedCell = this.config.tag as DataCellView;

    // Set public properties used in template.
    this.image = this.referencedCell.contents;
    this.referenceElementId = this.referencedCell.cellId;
    this.visible = true;
    this.isLoaded = false;    

    setTimeout(() => {
      this.popupService.open(this.id);      

    }, 100);

  }

  onImageLoaded(event): void {

    event.target.width;
    event.target.height;

    if (event.target) {

      this.isLoaded = true;
      this.popupService.update(this.referenceElementId, this.id);
      this.onReady.emit(this.config);     
    }

  }

  onPopupClosed() {
    this.visible = false;
    this.isLoaded = false;
  }

}