import { Inject, Component, Optional, Input } from "@angular/core";
import { BaseActionBoxComponent } from "../..";
import { ConfiguratorStore } from "../../../configurator/providers";
import { CodeDecorationValuesModel } from "./codeDecorationValueModel";
import { ProductDataStore } from "../../providers/productData";
import { CodeDecoration } from "../../models";


@Component({
  selector: 'code-action-box',
  templateUrl: './codeActionBoxComponent.html'
})
export class CodeActionBoxComponent extends BaseActionBoxComponent {
  

  @Input()
  valuesModel: CodeDecorationValuesModel;

  actionBoxTitle: string;

  constructor(
    @Optional() @Inject(ConfiguratorStore) configuratorStore: ConfiguratorStore,
    @Inject(ProductDataStore) private productStore: ProductDataStore) {
    super(configuratorStore);
  }

  public setup(): void {

    let decoation: CodeDecoration = this.productStore.getEntity<CodeDecoration>(this.valuesModel.decorationId);
    this.actionBoxTitle = decoation.title ? decoation.title : this.strings.Code;
    
  }

  // TODO: Need to remove this
  public shouldBeVisible(): boolean {
    return true;
  }

  

}