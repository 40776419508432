import { Component, Input, Output, EventEmitter } from "@angular/core";

// Added to remove the circular dependency warning when bundling
@Component({ template: '' })
export abstract class AbstractAccordionGroupComponent {

  @Input()
  heading: string;

  @Input()
  isOpened = false;

  @Output()
  onOpen = new EventEmitter();

  @Output()
  onClose = new EventEmitter();

  @Output()
  onToggle = new EventEmitter();

  @Input()
  disabled = false;

  @Input()
  scrollInToView = false;

  @Input()
  icon: string;

  @Input()
  iconIsVault = true;

  @Input()
  darkTheme = false;

  /**  True to use dark theme for a selected/opened group */
  @Input()
  selectedDarkTheme = false;

  @Input()
  togglable = true;

  public get useDarkTheme() {
    return this.darkTheme || (this.selectedDarkTheme && this.isOpened);
  }

  public onClick(): void {
    this.toggle();
  }

  open() {
    this.isOpened = true;
  }

  close(): void {
    this.isOpened = false;
  }

  abstract toggle();
}