import { Inject, Injectable } from "@angular/core";

import { Conf, Tab, Param, Decoration, ParamValue, LookupParam, MultiChoiceParam, LookupValue, MultiChoiceValue, VisualObject } from "../../shared/models";
import { ConfPageSessionService } from "../providers";
import { AbstractUIVisibilityService } from "./abstractUIVisibilityService";

@Injectable()
export class VisualObjectVisibilityService extends AbstractUIVisibilityService {

  constructor(@Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService) {
    super();
  }

  public isVisualObjectVisible(conf: Conf, visualObject: VisualObject) {

    if (visualObject instanceof Decoration)
      return this.isDecorationVisible(conf, visualObject as Decoration);
    else if (visualObject instanceof Param)
      return this.isParameterVisible(conf, visualObject as Param);
    
    return false;
  }

  public isDecorationVisible(conf: Conf, decoration: Decoration): boolean {

    if (!decoration)
      return false;

    return this.isVisible(decoration.longId, conf, [Tab.name], decoration.className)
  }

  public isParameterVisible(conf: Conf, parameter: Param): boolean
  public isParameterVisible(conf: Conf, parameter: number, className?: string): boolean
  public isParameterVisible(conf: Conf, parameter: any, className?: any): boolean {

    let parameterIdToUse: number;
    let parameterClassToUse: string;
    if (parameter instanceof Param) {
      parameterIdToUse = parameter.longId;
      parameterClassToUse = parameter.className;
    }
    else {
      parameterIdToUse = parameter as number;
      parameterClassToUse = className as string;
    }

    if (!parameterIdToUse)
      return false;

    return this.isVisible(parameterIdToUse, conf, [Tab.name], parameterClassToUse)
  }

  public isParamValueVisible(paramValue: ParamValue, conf: Conf): boolean {

    if (!paramValue)
      return false;

    let uiItemNamesToRecurse: string[] = [Tab.name];
        
    if (paramValue instanceof LookupValue)
      uiItemNamesToRecurse.push(LookupParam.name);
    else if (paramValue instanceof MultiChoiceValue)
      uiItemNamesToRecurse.push(MultiChoiceParam.name);

    return this.isVisible(paramValue.longId, conf, uiItemNamesToRecurse, paramValue.className);
  }

  public isVisible(longId: number, conf: Conf, uiItemNamesToRecurse: string[] = null, uiItemNameToMatch: string = null): boolean {
        
    return this.findConfUIItem(conf, longId, uiItemNamesToRecurse, uiItemNameToMatch) != null;

  }
}