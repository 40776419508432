import { ComparisonDraggableNode, ComparisonDraggableRowNodes } from "./comparisonDraggableRowNodes";

export class ComparisonDraggableNodeArgs {

  node: ComparisonDraggableNode;
 
  sourceRowId?: string;

  sourceNodeId?: string;

  nodeIndex?: number;

  /*
   * The node where it is dropped to. 
   */
  targetZoneId?: string;

  sourceRowAfterDrag?: ComparisonDraggableRowNodes;

  destinationRowAfterDrag?: ComparisonDraggableRowNodes;

}

export enum DraggableListAction {

  DragStart = 'dragStart',

  DragOver = 'dragOver',

  DragCancel = 'dragCancel',

  OrderChanged = 'orderChanged',

  ChildConfLoading = 'childConfLoading',

  ChildConfLoaded = 'childConfLoaded'

}

export interface IDraggableDataInfo<T> {

  action: DraggableListAction;

  data?: T;

}
