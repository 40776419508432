<div #dateRangeOverlay class="date-range-container row mx-0" >

  <div *ngIf="presets.length > 0" class="preset-ranges col" [style.minWidth.px]="minWidth" >   
      
      <drp-date-ranges 
                       [presets]="presets" 
                       (presetsChanged)="updateRangeByPreset($event)"
                       (customRange)="showCustomRange()"
                       >
      </drp-date-ranges>      
    
  </div>

  <ng-container *ngIf="showCustomRangeForm">
    <div class="calender-item col mt-4">
      <calendar-wrapper [prefixLabel]="'FROM:'"
                        [selectedDate]="fromDate"
                        [minDate]="fromMinDate"
                        [maxDate]="fromMaxDate"
                        (selectedDateChange)="updateFromDate($event)">
      </calendar-wrapper>
    </div>
    <div class="calender-item col mt-4">
      <calendar-wrapper [prefixLabel]="'TO:'"
                        [selectedDate]="toDate"
                        [minDate]="toMinDate"
                        [maxDate]="toMaxDate"
                        (selectedDateChange)="updateToDate($event)">
      </calendar-wrapper>
    </div>
  </ng-container>

</div>