import { Injectable, Inject } from "@angular/core";
import * as Immutable from "immutable";

import { ImmutableObject } from "../immutableObject";
import { SimpleObject } from "../simpleObject";
import { forEach } from "core-js/fn/array";

@Injectable()
export class JsonConvert {

  stringify(value: any) {

    let result = JSON.stringify(this.decycle(value));
    if (result == "{}")
      result = JSON.stringify(value, Object.getOwnPropertyNames(value));

    return result;
  }

  protected decycle(value: any) {

    let pathByObject = new Map<any, string>();
    return this.recursiveDecycle(value, "$root", pathByObject);
  }

  protected recursiveDecycle(inputObject: any, path: string, pathByObject: Map<any, string>) {

    let objectToDecycle = this.convertToObject(inputObject);

    if (typeof objectToDecycle === "object"
      && objectToDecycle !== null
      && !(objectToDecycle instanceof Boolean)
      && !(objectToDecycle instanceof Date)
      && !(objectToDecycle instanceof Number)
      && !(objectToDecycle instanceof RegExp)
      && !(objectToDecycle instanceof String)) {

      let existingPath = pathByObject.get(objectToDecycle);
      if (existingPath !== undefined) {
        return { $cirularReference: existingPath };
      }

      pathByObject.set(objectToDecycle, path);

      if (Array.isArray(objectToDecycle)) {
        let decycledArray = [];

        for (let index of Array.from(objectToDecycle.keys())) {
          decycledArray[index] = this.recursiveDecycle(objectToDecycle[index], path + "[" + index + "]", pathByObject);
        }

        return decycledArray;
      } else {

        let decycledObject = {};
        for (let key in objectToDecycle) {
          decycledObject[key] = this.recursiveDecycle(objectToDecycle[key], path + "[" + JSON.stringify(key) + "]", pathByObject);
        }

        return decycledObject;
      }
    }
    return objectToDecycle;
  }

  protected convertToObject(value: any) {

      if (value === null)
        return undefined;
      
      if (value instanceof TypeError) {
        return JSON.stringify(value, Object.getOwnPropertyNames(value));
      }

      if (value instanceof Immutable.Map) {
        return (value as Immutable.Map<any, any>).toJS();
      }

      if (value instanceof Immutable.List) {
        return (value as Immutable.List<any>).toJS();
      }

      if (value instanceof Map) {
        let objFromMap = {};
        (value as Map<any, any>).forEach((mapValue, mapKey) => { objFromMap[mapKey] = mapValue; });
        return objFromMap;
      }

      // Include class name, it is important when sending request objects to server
      if (value instanceof ImmutableObject && value["propertyMap"] instanceof Immutable.Map) {
        return Object.assign((value["propertyMap"] as Immutable.Map<any, any>).toJS(), { "className": (value as ImmutableObject).className });
      }

      // Include class name, it is important when sending request objects to server
      if (value instanceof SimpleObject && value["propertyMap"] instanceof Map) {
        let objFromMap = {};
        (value["propertyMap"] as Map<any, any>).forEach((mapValue, mapKey) => { objFromMap[mapKey] = mapValue; });
        return Object.assign(objFromMap, { "className": (value as SimpleObject).className });
      }

      return value;
    }
}