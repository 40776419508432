import * as Immutable from "immutable";

import { ImmutableObject } from "../../../../shared/immutableObject";
import { BomColumnSetting } from "./bomColumnSetting";
import { ConfDataProperty } from "./confDataProperty";


export class GlobalSettings extends ImmutableObject {

  protected readonly GOOGLE_RECAPTCHA = "googleReCaptcha";
  protected readonly DATE_FORMAT = "dateFormat";
  protected readonly COMPOSITE_SIDEBAR_DEFAULT_WIDTH = "compositeSidebarDefaultWidth";
  protected readonly COMPOSITE_SIDEBAR_COMPRESSED_WIDTH = "compositeSidebarCompressedWidth";
  protected readonly COMPOSITE_SIDEBAR_COMPRESSED_AT_WINDOW_WIDTH = "compositeSidebarCompressedAtWindowWidth";
  protected readonly COMPOSITE_SIDEBAR_COLLAPSE_AT_WINDOW_WIDTH = "compositeSidebarCollapseAtWindowWidth";
  protected readonly SHOW_COMPOSITE_SIDEBAR = "showCompositeSidebar";
  protected readonly HIGHLIGHT_SELECTED_CONFIGURATION = "highlightSelectedConfiguration";
  protected readonly SHOW_EXPAND_COLLAPSE_ICONS_COMPOSITE = "showExpandCollapseIconsComposite";
  protected readonly SHOW_EXPAND_COLLAPSE_ICONS_CREATE = "showExpandCollapseIconsCreate";
  protected readonly SHOW_COOKIE_INFORMATION = "showCookieInformation";  
  protected readonly ACCORDION_TABS_CONTROL_WIDTH = "accordionTabsControlWidth";
  protected readonly MIN_TAB_WIDTH = "minTabWidth";
  protected readonly HIGHLIGHT_MANDATORY_PARAMETERS = "highlightMandatoryParameters";
  protected readonly GRAPHICS_INITIAL_ZOOM_FACTOR = "graphicsInitialZoomFactor";
  protected readonly ENABLE_ACCOUNT_CREATION = "enableAccountCreation";
  protected readonly ENABLE_PASSWORD_RECOVERY = "enablePasswordRecovery";
  protected readonly IS_ANONYMOUS_ACCESS_ENABLED = "isAnonymousAccessEnabled";  
  protected readonly NO_OF_FILES_ALLOWED = "noOfFilesAllowed";
  protected readonly MAXIMUM_FILE_UPLOAD_SIZE = "maximumFileUploadSize";
  protected readonly ANONYMOUS_ACCESS_FROM_LOGIN_PAGE = "anonymousAccessFromLoginPage";    
  protected readonly SHOW_IMPORT_BUTTON_IN_COMPOSITE_CONTROL = "showImportButtonInCompositeControl";
  protected readonly BOM_GRAND_TOTAL_COLUMN_SETTINGS = "bomGrandTotalColumnSettings";
  protected readonly SEARCH_RESULT_ATTRIBUTES = "searchResultAttributes";  
  protected readonly SEARCH_CONFIGURATION_ATTRIBUTES = "searchConfigurationAttributes";
  protected readonly SEARCH_ATTRIBUTES_IMMEDIATELY_VISIBLE = "searchAttributesImmediatelyVisible";
  protected readonly AUTO_SELECT_CHILD = "autoSelectChild";
  protected readonly WEB_MAIN_MENU_START  = "webMainMenuStart";
  protected readonly WEB_MAIN_MENU_CREATE = "webMainMenuCreate";
  protected readonly WEB_MAIN_MENU_SEARCH = "webMainMenuSearch";
  protected readonly WEB_MAIN_MENU_SETTINGS = "webMainMenuSettings";
  protected readonly WEB_MAIN_MENU_LOGOUT = "webMainMenuLogout";
  protected readonly WEB_CONFIGURATOR_FOOTER_SAVE = "webConfiguratorFooterSave";
  protected readonly WEB_CONFIGURATOR_FOOTER_SAVE_AND_CLOSE = "webConfiguratorFooterSaveAndClose";
  protected readonly WEB_CONFIGURATOR_FOOTER_MANDATORY = "webConfiguratorFooterMandatory";
  protected readonly WEB_CONFIGURATOR_FOOTER_CLOSE = "webConfiguratorFooterClose";    
  protected readonly HELP_DOCUMENT_FILENAMES = "helpDocumentFilenames";
  protected readonly ALLOWED_LOGIN_ATTEMPTS = "allowedLoginAttempts";
  protected readonly SHOW_MY_RECENT = "showMyRecent";
  protected readonly SHOW_WORKGROUP_RECENT = "showWorkgroupRecent";
  protected readonly SUPPRESS_CHILD_CONFIGURATIONS_WITH_ZERO_PRICES = "suppressChildConfigurationsWithZeroPrices";

  protected readonly ENABLE_COMPARE_CONFIGURATIONS = "enableCompareConfigurations";
  protected readonly LIMITED_BROWSERS_SUPPORT = "limitedBrowsersSupport";
  protected readonly MAX_CONFIGURATIONS_IN_COMPARISON = "maxConfigurationsInComparison";
  protected readonly ALLOW_SEARCH_FOR_CHILD_CONFIGURATIONS = "allowSearchForChildConfigurations";
  protected readonly GROUP_PRODUCTS_BY_FAMILY_IN_FILTER_CONTROL = "groupProductsByFamilyInFilterControl";
  protected readonly AUTO_SELECT_ROOT_CONFIGURATION = "autoSelectRootConfiguration"; 
  protected readonly DATA_SELECTOR_FILTER_CONTROLS_VISIBLE = "dataSelectorFilterControlsVisible"; 
  protected readonly DISABLE_REVISION_MANAGEMENT = "disableRevisionManagement"; 
  protected readonly DISPLAY_DOCUMENTS_IN_OPERATIONS_COLUMN = "displayDocumentsInOperationsColumn";
  protected readonly SHOW_CURRENT_OR_STORED_ATTRIBUTES = "showCurrentOrStoredAttributes";
  protected readonly SUMMARY_ACTION_BOXES_DISPLAY_ORDER = "summaryActionBoxesDisplayOrder";
  protected readonly SEPARATED_TAB_COLLAPSE_AT_WINDOW_WIDTH = "separatedTabCollapseAtWindowWidth";

  protected readonly USE_AZURE_S_S_O = "useAzureSSO";
  protected readonly AZURE_S_S_O_LOGIN_URL = "azureSSOLoginUrl";
  
  // MsCrm
  protected readonly Is_In_Ms_Crm_Mode = "isInMsCrmMode";

  get googleReCaptcha(): string { return this.getInternalValue<string>(this.GOOGLE_RECAPTCHA); }
  setGoogleReCaptcha(googleReCaptcha: string): this { return this.setInternalValue(this.GOOGLE_RECAPTCHA, googleReCaptcha); }

  get dateFormat(): string { return this.getInternalValue<string>(this.DATE_FORMAT); }
  setDateFormat(dateFormat: string): this { return this.setInternalValue(this.DATE_FORMAT, dateFormat); }

  get compositeSidebarDefaultWidth(): number { return this.getInternalValue<number>(this.COMPOSITE_SIDEBAR_DEFAULT_WIDTH); }
  setCompositeSidebarDefaultWidth(compositeSidebarDefaultWidth: number): this { return this.setInternalValue(this.COMPOSITE_SIDEBAR_DEFAULT_WIDTH, compositeSidebarDefaultWidth); }

  get compositeSidebarCompressedWidth(): number { return this.getInternalValue<number>(this.COMPOSITE_SIDEBAR_COMPRESSED_WIDTH); }
  setCompositeSidebarCompressedWidth(compositeSidebarCompressedWidth: number): this { return this.setInternalValue(this.COMPOSITE_SIDEBAR_COMPRESSED_WIDTH, compositeSidebarCompressedWidth); }

  get compositeSidebarCompressedAtWindowWidth(): number { return this.getInternalValue<number>(this.COMPOSITE_SIDEBAR_COMPRESSED_AT_WINDOW_WIDTH); }
  setCompositeSidebarCompressedAtWindowWidth(compositeSidebarCompressedAtWindowWidth: number): this { return this.setInternalValue(this.COMPOSITE_SIDEBAR_COMPRESSED_AT_WINDOW_WIDTH, compositeSidebarCompressedAtWindowWidth); }

  get compositeSidebarCollapseAtWindowWidth(): number { return this.getInternalValue<number>(this.COMPOSITE_SIDEBAR_COLLAPSE_AT_WINDOW_WIDTH); }
  setCompositeSidebarCollapseAtWindowWidth(compositeSidebarCollapseAtWindowWidth: number): this { return this.setInternalValue(this.COMPOSITE_SIDEBAR_COLLAPSE_AT_WINDOW_WIDTH, compositeSidebarCollapseAtWindowWidth); }

  get showCompositeSidebar(): boolean { return this.getInternalValue<boolean>(this.SHOW_COMPOSITE_SIDEBAR); }
  setShowCompositeSidebar(showCompositeSidebar: boolean): this { return this.setInternalValue(this.SHOW_COMPOSITE_SIDEBAR, showCompositeSidebar); }

  get highlightSelectedConfiguration(): boolean { return this.getInternalValue<boolean>(this.HIGHLIGHT_SELECTED_CONFIGURATION); }
  setHighlightSelectedConfiguration(highlightSelectedConfiguration: boolean): this { return this.setInternalValue(this.HIGHLIGHT_SELECTED_CONFIGURATION, highlightSelectedConfiguration); }

  get showExpandCollapseIconsComposite(): boolean { return this.getInternalValue<boolean>(this.SHOW_EXPAND_COLLAPSE_ICONS_COMPOSITE); }
  setShowExpandCollapseIconsComposite(showExpandCollapseIconsComposite: boolean): this { return this.setInternalValue(this.SHOW_EXPAND_COLLAPSE_ICONS_COMPOSITE, showExpandCollapseIconsComposite); }

  get showExpandCollapseIconsCreate(): boolean { return this.getInternalValue<boolean>(this.SHOW_EXPAND_COLLAPSE_ICONS_CREATE); }
  setShowExpandCollapseIconsCreate(showExpandCollapseIconsCreate: boolean): this { return this.setInternalValue(this.SHOW_EXPAND_COLLAPSE_ICONS_CREATE, showExpandCollapseIconsCreate); }

  get showCookieInformation(): boolean { return this.getInternalValue<boolean>(this.SHOW_COOKIE_INFORMATION); }
  setShowCookieInformation(showCookieInformation: boolean): this { return this.setInternalValue(this.SHOW_COOKIE_INFORMATION, showCookieInformation); }

  get autoSelectChild(): boolean { return this.getInternalValue<boolean>(this.AUTO_SELECT_CHILD); }
  setAutoSelectChild(autoSelectChild: boolean): this { return this.setInternalValue(this.AUTO_SELECT_CHILD, autoSelectChild); }

  get minTabWidth(): number { return this.getInternalValue<number>(this.MIN_TAB_WIDTH); }
  setMinTabWidth(compositeControlWidth: number): this { return this.setInternalValue(this.MIN_TAB_WIDTH, compositeControlWidth); }

  get highlightMandatoryParameters(): boolean { return this.getInternalValue<boolean>(this.HIGHLIGHT_MANDATORY_PARAMETERS); }
  setHighlightMandatoryParameters(highlightMandatoryParameters: boolean): this { return this.setInternalValue(this.HIGHLIGHT_MANDATORY_PARAMETERS, highlightMandatoryParameters); }

  get graphicsInitialZoomFactor(): number { return this.getInternalValue<number>(this.GRAPHICS_INITIAL_ZOOM_FACTOR); }
  setGraphicsInitialZoomFactor(graphicsInitialZoomFactor: number): this { return this.setInternalValue(this.GRAPHICS_INITIAL_ZOOM_FACTOR, graphicsInitialZoomFactor); }

  get enableAccountCreation(): boolean { return this.getInternalValue<boolean>(this.ENABLE_ACCOUNT_CREATION); }
  setEnableAccountCreation(enableAccountCreation: boolean): this { return this.setInternalValue(this.ENABLE_ACCOUNT_CREATION, enableAccountCreation); }

  get enablePasswordRecovery(): boolean { return this.getInternalValue<boolean>(this.ENABLE_PASSWORD_RECOVERY); }
  setEnablePasswordRecovery(enablePasswordRecovery: boolean): this { return this.setInternalValue(this.ENABLE_PASSWORD_RECOVERY, enablePasswordRecovery); }

  get isAnonymousAccessEnabled(): boolean { return this.getInternalValue<boolean>(this.ANONYMOUS_ACCESS_FROM_LOGIN_PAGE); }
  setIsAnonymousAccessEnabled(isAnonymousAccessEnabled: boolean): this { return this.setInternalValue(this.ANONYMOUS_ACCESS_FROM_LOGIN_PAGE, isAnonymousAccessEnabled); }

  get anonymousAccessFromLoginPage(): boolean { return this.getInternalValue<boolean>(this.IS_ANONYMOUS_ACCESS_ENABLED); }
  setAnonymousAccessFromLoginPage(anonymousAccessFromLoginPage: boolean): this { return this.setInternalValue(this.IS_ANONYMOUS_ACCESS_ENABLED, anonymousAccessFromLoginPage); }  

  get noOfFilesAllowed(): number { return this.getInternalValue<number>(this.NO_OF_FILES_ALLOWED); }
  setNoOfFilesAllowed(noOfFilesAllowed: number): this { return this.setInternalValue(this.NO_OF_FILES_ALLOWED, noOfFilesAllowed); }

  get maximumFileUploadSize(): number { return this.getInternalValue<number>(this.MAXIMUM_FILE_UPLOAD_SIZE); }
  setMaximumFileUploadSize(maximumFileUploadSize: number): this { return this.setInternalValue(this.MAXIMUM_FILE_UPLOAD_SIZE, maximumFileUploadSize); }

  get showImportButtonInCompositeControl(): boolean { return this.getInternalValue<boolean>(this.SHOW_IMPORT_BUTTON_IN_COMPOSITE_CONTROL); }
  setShowImportButtonInCompositeControl(showImportButtonInCompositeControl: boolean): this { return this.setInternalValue(this.SHOW_IMPORT_BUTTON_IN_COMPOSITE_CONTROL, showImportButtonInCompositeControl); }  

  get bomGrandTotalColumnSettings(): Immutable.List<BomColumnSetting> { return this.getInternalValue<Immutable.List<BomColumnSetting>>(this.BOM_GRAND_TOTAL_COLUMN_SETTINGS); }
  setBomGrandTotalColumnSettings(bomGrandTotalColumnSettings: Immutable.List<BomColumnSetting>): this { return this.setInternalValue(this.BOM_GRAND_TOTAL_COLUMN_SETTINGS, bomGrandTotalColumnSettings); }

  get searchResultAttributes(): Immutable.List<ConfDataProperty> { return this.getInternalValue<Immutable.List<ConfDataProperty>>(this.SEARCH_RESULT_ATTRIBUTES); }
  setSearchResultAttributes(searchResultAttributes: Immutable.List<ConfDataProperty>): this { return this.setInternalValue(this.SEARCH_RESULT_ATTRIBUTES, searchResultAttributes); }
  
  get searchConfigurationAttributes(): Immutable.List<ConfDataProperty> { return this.getInternalValue<Immutable.List<ConfDataProperty>>(this.SEARCH_CONFIGURATION_ATTRIBUTES); }
  setSearchConfigurationAttributes(searchConfigurationAttributes: Immutable.List<ConfDataProperty>): this { return this.setInternalValue(this.SEARCH_CONFIGURATION_ATTRIBUTES, searchConfigurationAttributes); }

  get searchAttributesImmediatelyVisible(): number { return this.getInternalValue<number>(this.SEARCH_ATTRIBUTES_IMMEDIATELY_VISIBLE); }
  setSearchAttributesImmediatelyVisible(searchAttributesImmediatelyVisible: number): this { return this.setInternalValue(this.SEARCH_ATTRIBUTES_IMMEDIATELY_VISIBLE, searchAttributesImmediatelyVisible); }  

  get webMainMenuCreate(): boolean { return this.getInternalValue<boolean>(this.WEB_MAIN_MENU_CREATE); }
  setWebMainMenuCreate(webMainMenuCreate: boolean): this { return this.setInternalValue(this.WEB_MAIN_MENU_CREATE, webMainMenuCreate); }  

  get webMainMenuLogout(): boolean { return this.getInternalValue<boolean>(this.WEB_MAIN_MENU_LOGOUT); }
  setWebMainMenuLogout(webMainMenuLogout: boolean): this { return this.setInternalValue(this.WEB_MAIN_MENU_LOGOUT, webMainMenuLogout); }  

  get webMainMenuStart(): boolean { return this.getInternalValue<boolean>(this.WEB_MAIN_MENU_START); }
  setWebMainMenuStart(webMainMenuStart: boolean): this { return this.setInternalValue(this.WEB_MAIN_MENU_START, webMainMenuStart); }  

  get webMainMenuSearch(): boolean { return this.getInternalValue<boolean>(this.WEB_MAIN_MENU_SEARCH); }
  setWebMainMenuSearch(webMainMenuSearch: boolean): this { return this.setInternalValue(this.WEB_MAIN_MENU_SEARCH, webMainMenuSearch); }  

  get webMainMenuSettings(): boolean { return this.getInternalValue<boolean>(this.WEB_MAIN_MENU_SETTINGS); }
  setWebMainMenuSettings(webMainMenuSettings: boolean): this { return this.setInternalValue(this.WEB_MAIN_MENU_SETTINGS, webMainMenuSettings); }  
    
  get webConfiguratorFooterSave(): boolean { return this.getInternalValue<boolean>(this.WEB_CONFIGURATOR_FOOTER_SAVE); }
  setWebConfiguratorFooterSave(webConfiguratorFooterSave: boolean): this { return this.setInternalValue(this.WEB_CONFIGURATOR_FOOTER_SAVE, webConfiguratorFooterSave); }  

  get webConfiguratorFooterSaveAndClose(): boolean { return this.getInternalValue<boolean>(this.WEB_CONFIGURATOR_FOOTER_SAVE_AND_CLOSE); }
  setWebConfiguratorFooterSaveAndClose(webConfiguratorFooterSaveAndClose: boolean): this { return this.setInternalValue(this.WEB_CONFIGURATOR_FOOTER_SAVE_AND_CLOSE, webConfiguratorFooterSaveAndClose); }  

  get webConfiguratorFooterMandatory(): boolean { return this.getInternalValue<boolean>(this.WEB_CONFIGURATOR_FOOTER_MANDATORY); }
  setWebConfiguratorFooterMandatory(webConfiguratorFooterMandatory: boolean): this { return this.setInternalValue(this.WEB_CONFIGURATOR_FOOTER_MANDATORY, webConfiguratorFooterMandatory); }    

  get webConfiguratorFooterClose(): boolean { return this.getInternalValue<boolean>(this.WEB_CONFIGURATOR_FOOTER_CLOSE); }
  setWebConfiguratorFooterClose(webConfiguratorFooterClose: boolean): this { return this.setInternalValue(this.WEB_CONFIGURATOR_FOOTER_CLOSE, webConfiguratorFooterClose); }    

  get showMyRecent(): boolean { return this.getInternalValue<boolean>(this.SHOW_MY_RECENT); }
  setShowMyRecent(showMyRecent: boolean): this { return this.setInternalValue(this.SHOW_MY_RECENT, showMyRecent); }

  get showWorkgroupRecent(): boolean { return this.getInternalValue<boolean>(this.SHOW_WORKGROUP_RECENT); }
  setShowWorkgroupRecent(showWorkgroupRecent: boolean): this { return this.setInternalValue(this.SHOW_WORKGROUP_RECENT, showWorkgroupRecent); }

  get maxConfigurationsInComparison(): number { return this.getInternalValue<number>(this.MAX_CONFIGURATIONS_IN_COMPARISON); }
  setMaxConfigurationsInComparison(maxConfigurationsInComparison: number): this { return this.setInternalValue(this.MAX_CONFIGURATIONS_IN_COMPARISON, maxConfigurationsInComparison); }

  get helpDocumentFilenames(): Immutable.List<string> { return this.getInternalValue<Immutable.List<string>>(this.HELP_DOCUMENT_FILENAMES); }
  setHelpDocumentFilenames(helpDocumentFilenames: Immutable.List<string>): this { return this.setInternalValue(this.HELP_DOCUMENT_FILENAMES, helpDocumentFilenames); }

  get enableCompareConfigurations(): boolean { return this.getInternalValue<boolean>(this.ENABLE_COMPARE_CONFIGURATIONS); }
  setEnableCompareConfigurations(enableCompareConfigurations: boolean): this { return this.setInternalValue(this.ENABLE_COMPARE_CONFIGURATIONS, enableCompareConfigurations); }

  get limitedBrowsersSupport(): Immutable.List<string> { return this.getInternalValue<Immutable.List<string>>(this.LIMITED_BROWSERS_SUPPORT); }
  setBrowsersSupport(limitedBrowsersSupport: Immutable.List<string>): this { return this.setInternalValue(this.LIMITED_BROWSERS_SUPPORT, limitedBrowsersSupport); }

  get allowedLoginAttempts(): number { return this.getInternalValue<number>(this.ALLOWED_LOGIN_ATTEMPTS); }
  setAllowedLoginAttempts(allowedLoginAttempts: number): this { return this.setInternalValue(this.ALLOWED_LOGIN_ATTEMPTS, allowedLoginAttempts); }

  get groupProductsByFamilyInFilterControl(): boolean { return this.getInternalValue<boolean>(this.GROUP_PRODUCTS_BY_FAMILY_IN_FILTER_CONTROL); }
  setGroupProductsByFamilyInFilterControl(groupProductsByFamilyInFilterControl: boolean): this { return this.setInternalValue(this.GROUP_PRODUCTS_BY_FAMILY_IN_FILTER_CONTROL, groupProductsByFamilyInFilterControl); }

  get allowSearchForChildConfigurations(): boolean { return this.getInternalValue<boolean>(this.ALLOW_SEARCH_FOR_CHILD_CONFIGURATIONS); }
  setAllowSearchForChildConfigurations(allowSearchForChildConfigurations: boolean): this { return this.setInternalValue(this.ALLOW_SEARCH_FOR_CHILD_CONFIGURATIONS, allowSearchForChildConfigurations); }

  get autoSelectRootConfiguration(): boolean { return this.getInternalValue<boolean>(this.AUTO_SELECT_ROOT_CONFIGURATION); }
  setAutoSelectRootConfiguration(autoSelectRootConfiguration: boolean): this { return this.setInternalValue(this.AUTO_SELECT_ROOT_CONFIGURATION, autoSelectRootConfiguration); }

  get dataSelectorFilterControlsVisible(): boolean { return this.getInternalValue<boolean>(this.DATA_SELECTOR_FILTER_CONTROLS_VISIBLE); }
  setDataSelectorFilterControlsVisible(dataSelectorFilterControlsVisible: boolean): this { return this.setInternalValue(this.DATA_SELECTOR_FILTER_CONTROLS_VISIBLE, dataSelectorFilterControlsVisible); }

  get disableRevisionManagement(): boolean { return this.getInternalValue<boolean>(this.DISABLE_REVISION_MANAGEMENT); }
  setDisableRevisionManagement(disableRevisionManagement: boolean): this { return this.setInternalValue(this.DISABLE_REVISION_MANAGEMENT, disableRevisionManagement); }

  get displayDocumentsInOperationsColumn(): boolean { return this.getInternalValue<boolean>(this.DISPLAY_DOCUMENTS_IN_OPERATIONS_COLUMN); }
  setDisplayDocumentsInOperationsColumn(displayDocumentsInOperationsColumn: boolean): this { return this.setInternalValue(this.DISPLAY_DOCUMENTS_IN_OPERATIONS_COLUMN, displayDocumentsInOperationsColumn); }

  get suppressChildConfigurationsWithZeroPrices(): boolean { return this.getInternalValue<boolean>(this.SUPPRESS_CHILD_CONFIGURATIONS_WITH_ZERO_PRICES); }
  setSuppressChildConfigurationsWithZeroPrices(suppressChildConfigurationsWithZeroPrices: boolean): this { return this.setInternalValue(this.SUPPRESS_CHILD_CONFIGURATIONS_WITH_ZERO_PRICES, suppressChildConfigurationsWithZeroPrices); }

  get showCurrentOrStoredAttributes(): string { return this.getInternalValue<string>(this.SHOW_CURRENT_OR_STORED_ATTRIBUTES); }
  setShowCurrentOrStoredAttributes(showCurrentOrStoredAttributes: string): this { return this.setInternalValue(this.SHOW_CURRENT_OR_STORED_ATTRIBUTES, showCurrentOrStoredAttributes); }

  get summaryActionBoxesDisplayOrder(): Immutable.List<string> { return this.getInternalValue<Immutable.List<string>>(this.SUMMARY_ACTION_BOXES_DISPLAY_ORDER); }
  setSummaryActionBoxesDisplayOrder(summaryActionBoxesDisplayOrder: Immutable.List<string>): this { return this.setInternalValue(this.SUMMARY_ACTION_BOXES_DISPLAY_ORDER, summaryActionBoxesDisplayOrder); }

  get separatedTabCollapseAtWindowWidth(): number { return this.getInternalValue<number>(this.SEPARATED_TAB_COLLAPSE_AT_WINDOW_WIDTH); }
  setSeparatedTabCollapseAtWindowWidth(separatedTabCollapseAtWindowWidth: number): this { return this.setInternalValue(this.SEPARATED_TAB_COLLAPSE_AT_WINDOW_WIDTH, separatedTabCollapseAtWindowWidth); }

  get useAzureSSO(): boolean { return this.getInternalValue<boolean>(this.USE_AZURE_S_S_O); }
  setUseAzureSSO(useAzureSSO: boolean): this { return this.setInternalValue(this.USE_AZURE_S_S_O, useAzureSSO); }

  get azureSSOLoginUrl(): string { return this.getInternalValue<string>(this.AZURE_S_S_O_LOGIN_URL); }
  setAzureSSOLoginUrl(azureSSOLoginUrl: string): this { return this.setInternalValue(this.AZURE_S_S_O_LOGIN_URL, azureSSOLoginUrl); }

  // MsCrm
  get isInMsCrmMode(): boolean { return this.getInternalValue<boolean>(this.Is_In_Ms_Crm_Mode); }
  setIsInMsCrmMode(isInMsCrmMode: boolean): this { return this.setInternalValue(this.Is_In_Ms_Crm_Mode, isInMsCrmMode); }
} 