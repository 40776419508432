import { Injectable } from "@angular/core";

@Injectable()
export class GlobalLogger {

  public oldConsole = console;

  // Applies to general logging. Error logging is always enabled
  enabled: boolean = true;

  log(message?: any, ...optionalParams: any[]): void {    

    if (!this.enabled)
      return;

    this.oldConsole.log(message, ...optionalParams);
  }

  error(message?: any, ...optionalParams: any[]): void {
    this.oldConsole.error(message, ...optionalParams);
  }
}