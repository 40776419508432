import { Injectable, Inject } from '@angular/core';
import { HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpService, JsonConvert } from "../../../../shared/providers"
import { ApiResponse, ConfDataRequest } from "../../models";
import { ApiResponseBuilder } from "../../providers/apiResponseBuilder";

@Injectable()
export class AttachmentsDataController {

  public configuratorControllerUrl = 'api/configurator';

  constructor(
    protected httpService: HttpService,
    protected jsonConvert: JsonConvert,
    protected apiResponseBuilder: ApiResponseBuilder,
  ) { }

  public buildResponseObject = map((response, index) => this.apiResponseBuilder.build(response));

  public buildProgressResponseObject = map((response, index) => {
    // Build API response if we're done, otherwise just return it (progress event).
    if (response instanceof HttpResponse) {
      return this.apiResponseBuilder.build(response.body);
    }
    return response;
  });

  public uploadFile(confData: ConfDataRequest, file: File): Observable<any> {
    let formData = new FormData();
    formData.append('json', this.jsonConvert.stringify(confData));
    formData.append('attachment', file, file.name);

    return this.httpService.postWithProgress(this.configuratorControllerUrl, formData).pipe(this.buildProgressResponseObject);
  }

  public deleteAttachment(requestModel: ConfDataRequest) {
    return this.httpService.get(this.configuratorControllerUrl, requestModel).pipe(this.buildResponseObject);
  }
}