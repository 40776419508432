import { BaseEntity } from "../../baseEntity";

export class PropertyDecorationItem extends BaseEntity {

  protected readonly WIDTH = "width";
  protected readonly ROW_COUNT = "rowCount";
  protected readonly FLOATING_POSITION = "floatingPosition";
  protected readonly PROPERTY_NAME = "propertyName";
  protected readonly PROPERTY_TYPE = "propertyType";
  protected readonly NUMERIC_FORMAT = "numericFormat";

  get width(): number { return this.getInternalValue<number>(this.WIDTH); }
  setWidth(width: number): this { return this.setInternalValue(this.WIDTH, width); }

  get rowCount(): number { return this.getInternalValue<number>(this.ROW_COUNT); }
  setRowCount(rowCount: number): this { return this.setInternalValue(this.ROW_COUNT, rowCount); }

  get floatingPosition(): boolean { return this.getInternalValue<boolean>(this.FLOATING_POSITION); }
  setFloatingPosition(floatingPosition: boolean): this { return this.setInternalValue(this.FLOATING_POSITION, floatingPosition); }

  get propertyName(): string { return this.getInternalValue<string>(this.PROPERTY_NAME); }
  setPropertyName(propertyName: string): this { return this.setInternalValue(this.PROPERTY_NAME, propertyName); }

  get propertyType(): string { return this.getInternalValue<string>(this.PROPERTY_TYPE); }
  setPropertyType(propertyType: string): this { return this.setInternalValue(this.PROPERTY_TYPE, propertyType); }

  get numericFormat(): string { return this.getInternalValue<string>(this.NUMERIC_FORMAT); }
  setNumericFormat(numericFormat: string): this { return this.setInternalValue(this.NUMERIC_FORMAT, numericFormat); }
}