import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ImageModule } from "../image";
import { HelpModule } from "../help";
import { IconModule } from "../icon";

import { LineDropdownGroupComponent } from "./line/lineDropdownGroupComponent";
import { LineDropdownItemComponent } from "./line/lineDropdownItemComponent";

import { ImageLeftLineItemComponent } from "./line/items/imageLeftLineItemComponent";
import { StandardLineItemComponent } from "./line/items/standardLineItemComponent";

import { StandardDropdownGroupComponent } from "./standard/standardDropdownGroupComponent";
import { StandardDropdownItemComponent } from "./standard/standardDropdownItemComponent";

import { GenericDropdownGroup } from "./genericDropdownGroup";

export * from "./line/lineDropdownGroupComponent";
export * from "./line/lineDropdownItemComponent";
export * from "./line/items/baseLineItemComponent";
export * from "./line/items/imageLeftLineItemComponent";
export * from "./line/items/standardLineItemComponent";
export * from "./standard/standardDropdownGroupComponent";
export * from "./standard/standardDropdownItemComponent";
export * from "./genericDropdownGroup";

@NgModule({
  imports: [
    CommonModule,
    ImageModule,
    HelpModule,
    IconModule
  ],
  declarations: [
    LineDropdownGroupComponent,
    LineDropdownItemComponent,
    ImageLeftLineItemComponent,
    StandardLineItemComponent,

    StandardDropdownGroupComponent,
    StandardDropdownItemComponent,
    GenericDropdownGroup,
  ],
  exports: [
    LineDropdownGroupComponent,
    LineDropdownItemComponent,
    ImageLeftLineItemComponent,
    StandardLineItemComponent,

    StandardDropdownGroupComponent,
    StandardDropdownItemComponent,
    GenericDropdownGroup,
  ]
})

export class DropdownModule {

}
