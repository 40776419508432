import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Output, Renderer2, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { PrintInfo, PrintService, PrintAction } from "./printService";
import { Delay, sleep } from "../../utils";

@Component({
  selector: 'print-component',
  templateUrl: './printComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintComponent  {

  private subscription: Subscription;

  constructor(public printService: PrintService, protected cd: ChangeDetectorRef, private renderer: Renderer2) {
    
  }

  @Output()
  onSuccess = new EventEmitter();

  @Output()
  onStart = new EventEmitter();

  @ViewChild("printContainer")
  printContainer: ElementRef;

  ngOnInit() {

    this.subscription = this.printService.getMessage().subscribe((info: PrintInfo) => {

      this.executePrintAction(info);
      
    });

  }

  executePrintAction(info: PrintInfo) {

    switch (info.action) {

      case PrintAction.CopyDOM:
        this.attachViewAfter(this.printContainer.nativeElement, [info.referenceElement.cloneNode(true)]);
        break;

      case PrintAction.RemoveDOM:
        // Remove the print element from DOM after print command.
        sleep(Delay.MS_400).then(x =>
        {
          $(this.printContainer.nativeElement).next().remove();
          this.cd.markForCheck();
          this.onSuccess.emit();
        });        
        break;

      case PrintAction.Print:
        this.onStart.emit();
        /*sleep(Delay.MS_400).then(x => window.print());*/
        break;

    }

  }

  attachViewAfter(nativeElement: any, viewRootNodes: any[]) {

    const parentElement = this.renderer.parentNode(nativeElement);
    const nextSibling = this.renderer.nextSibling(nativeElement);
    for (let i = 0; i < viewRootNodes.length; i++) {
      this.renderer.insertBefore(parentElement, viewRootNodes[i], nextSibling);
    }

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}