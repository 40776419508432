import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
import { ImageModule } from "../image";
import { InputViewModel } from "../shared";
import { TextBoxComponent } from "./textBoxComponent";
import { HelpModule } from "../help/index";
import { IconModule } from "../icon/index";
import { PanelModule } from "../panel/index";

export * from "./textBoxComponent";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ImageModule,
    HelpModule,
    IconModule,
    PanelModule
  ],
  declarations: [
    TextBoxComponent    
  ],
  exports: [
    TextBoxComponent    
  ]
})
export class TextBoxModule {

}