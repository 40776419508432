import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from "../../../../shared/providers";
import { ApiResponse, ConfDataRequest } from "../../../shared/models";
import { ApiResponseBuilder } from "../../../shared/providers/apiResponseBuilder";
import { ConfDataController } from "../../../shared/providers/configurationData";
import { GlobalDataStore } from "../../../shared/providers/globalData";


@Injectable()
export class DataSelectorController extends ConfDataController {

  constructor(
    @Inject(HttpService) public httpService: HttpService,
    public apiResponseBuilder: ApiResponseBuilder,
    public globalDataStore: GlobalDataStore
  ) {
    super(httpService, apiResponseBuilder, globalDataStore);
  }

  public getDataSelectorRows(model: ConfDataRequest): Observable<ApiResponse> {
    return this.getConfigurationData(model);
  }

  public getDataSelectorKeyDetail(model: ConfDataRequest): Observable<ApiResponse> {
    return this.getConfigurationData(model);
  }

  public clearDataSelectorValue(model: ConfDataRequest): Observable<ApiResponse> {
    return this.getConfigurationData(model);
  }

  public setDataSelectorValue(model: ConfDataRequest): Observable<ApiResponse> {
    return this.getConfigurationData(model);
  }

  public searchDataSelectorValues(model: ConfDataRequest) : Observable<ApiResponse> {
    return this.getConfigurationData(model);
  }

  public clearDataSelectorSearch(model: ConfDataRequest) : Observable<ApiResponse> {
    return this.getConfigurationData(model);
  }
}