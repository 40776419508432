import { Subject, Subscription, PartialObserver, Unsubscribable } from "rxjs"
import { Subscribable, Observer, NextObserver } from "rxjs";

export interface ICallback<T> {
  (value: T): void;
}

export interface IObserverOptions<T> {  
  startOnDemand?: boolean; // Starts by calling start method.
  listenNewEventsOnly?: boolean; // Always listens new changes.
  next?: (value: T) => void;
  error?: (err: any) => void;
  complete?: () => void;
}

export type SubscriptionOptions<T> = ICallback<T> | IObserverOptions<T>;

export class ManagedSubscription implements Unsubscribable {

  protected conditionalUnsubscribe: Unsubscribable;

  protected subject = new Subject<any>();
  protected subscription: Subscription;

  protected observerOptions: IObserverOptions<any>;

  private _isActive = false;
  get isActive() { return this._isActive; }
  
  constructor(subscriptionOptions: SubscriptionOptions<any>, public addSubscriptionFn: (subject: Subject<any>, observerOptions: IObserverOptions<any>) => Subscription, public removeSubscriptionFn: (subscription: Subscription) => void) {

    this.createObserverOptions(subscriptionOptions);

    this.subject.subscribe(this.observerOptions.next, this.observerOptions.error, this.observerOptions.complete);

    if (!this.observerOptions.startOnDemand)
      this.start();
  }

  public createObserverOptions(subscriptionOptions: SubscriptionOptions<any>) {

    this.observerOptions = <IObserverOptions<any>>subscriptionOptions;
    if (!this.observerOptions.next)
      this.observerOptions.next = <ICallback<any>>subscriptionOptions;

    let originalObserver = this.observerOptions.next;

    this.observerOptions.next = (value: any) => {

      if (!this._isActive) {
        this.unsubscribe();
        return;
      }

      originalObserver(value);
    }
  }

  start() {
        
    this.stop();

    this._isActive = true;

    this.subscription = this.addSubscriptionFn(this.subject, this.observerOptions);

    return this;
  }

  stop() {

    this._isActive = false;

    if (this.subscription)
      this.removeSubscriptionFn(this.subscription);

    return this;
  }

  unsubscribeOn(unsubscribeSubject: Subscribable<any>) {
    this.conditionalUnsubscribe = unsubscribeSubject.subscribe(() => {
        this.unsubscribe();
    });
    return this;
  }

  unsubscribe() {
    if (this.conditionalUnsubscribe)
      this.conditionalUnsubscribe.unsubscribe();

    this.stop();
  }

  dispose() {
    this.subject.complete();
    this.unsubscribe();
  }
}