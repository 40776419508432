import { Inject, Component, Input, Optional } from "@angular/core";
import * as Immutable from "immutable";
import { BaseActionBoxComponent } from "../..";

import { ConfDataController } from "../../providers/configurationData";
import { ConfiguratorStore, ConfPageSessionService } from "../../../configurator/providers";
import { ModelFactory } from "../../providers";
import { PageStore } from "../../providers/page/pageStore";
import { HttpClient } from "@angular/common/http";
import { ConfDocumentValue } from "../../models/entities/confDocumentValue";
import { Conf } from "../../models/entities/conf";
import { RequestViews } from "../../models/requests/requestViews";
import { ConfDocumentRequest } from "../../models/requests/confDocumentRequest";
import { GlobalDataStore } from "../../providers/globalData/globalDataStore";
import { ConfUIItem, DocumentsDecoration, GroupConfUIItem } from "../../models";
import { ProductDataStore } from "../../providers/productData/productDataStore";

@Component({
  selector: 'documents-action-box',
  templateUrl: './documentsActionBoxComponent.html'
})
export class DocumentsActionBoxComponent extends BaseActionBoxComponent {

  @Input()
  confUIItem: ConfUIItem;

  actionBoxTitle: string;
  public documentValues: Array<ConfDocumentValue> = [];

  constructor(@Optional() @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    @Inject(ConfDataController) public configuratorController: ConfDataController,
    @Inject(ProductDataStore) public productStore: ProductDataStore
  ) {
    super(confStore);
  }

  public shouldBeVisible(): boolean {
    return this.documentValues && this.documentValues.length > 0 && this.conf != null && this.conf.authorization.canViewDocuments;
  }
  
  public setup(): void {

    if (!this.conf)
      return;

    const decoration: DocumentsDecoration = this.productStore.getEntity<DocumentsDecoration>(this.confUIItem.id);
    this.actionBoxTitle = decoration && decoration.title ? decoration.title : this.strings.Documents;

    this.subscribeDataChange();
  }

  subscribeDataChange() {

    this.confStore.onConfigurationChange(this.configurationId, this.confSessionId, (conf: Conf) => {

      this.documentValues = [];

      if (!this.storeSession.activeConfiguration.documentValues) {
        this.visible = false;
        return;
      }

      if (this.confUIItem) {
        if (this.confUIItem instanceof GroupConfUIItem)
          this.confUIItem.items.forEach(decoration => {
            this.addDocumentValues(decoration)
          });
        else
          this.addDocumentValues(this.confUIItem);
      }

      if (this.documentValues.length > 0)
        this.visible = true;

    }).unsubscribeOn(this.unsubscribeSubject);

  }

  public addDocumentValues(decorationUIItem: ConfUIItem) {
    const documentValues = this.storeSession.activeConfiguration.documentValues.toArray();

    decorationUIItem.items.forEach(item => {
      const value = documentValues.find(x => x.longId === item.id);
      if (value && this.documentValues.findIndex(x => x.longId === value.longId) === -1)
        this.documentValues.push(value);
    });
  }

  public get vaultUrl(): string {
    return this.globalDataStore.getGlobalData().webSettings.vaultUrl;
  }

  public getDocumentLink(documentId: number): string {
    let model: ConfDocumentRequest = this.modelFactory.createAny(ConfDocumentRequest.name) as any;

    model.client = RequestViews.Summary;//this.pageStore.getActiveClientType();
    model.documentId = documentId;
    model.configurationId = this.configurationId;
    model.confSessionId = this.confSessionId;
    return this.configuratorController.getConfigurationDocumentUrl(model);
  }
}