import { Component, Input } from '@angular/core';

@Component({
  selector: 'popover',
  templateUrl: './popoverComponent.html',
})
export class PopoverComponent {

  @Input()
  public isOpened = false;

  toggle() {
    this.isOpened = !this.isOpened;
  }

  close(): void {
    this.isOpened = false;
  }
}