import * as Immutable from "immutable";
import { ImmutableObject } from "../../../../shared/immutableObject";
import { ComparisonValue } from "./comparisonValue";

export class Comparison extends ImmutableObject {
  
  protected readonly TITLE = "title";
  protected readonly ICON = "icon";
  protected readonly VALUES = "values";
  protected readonly CHILDREN = "children";
  

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get icon(): string { return this.getInternalValue<string>(this.ICON); }
  setIcon(icon: string): this { return this.setInternalValue(this.ICON, icon); }

  get values(): Immutable.List<ComparisonValue> { return this.getInternalValue<Immutable.List<ComparisonValue>>(this.VALUES); }
  setValues(values: Immutable.List<ComparisonValue>): this { return this.setInternalValue(this.VALUES, values); }

  get children(): Immutable.List<Comparison> { return this.getInternalValue<Immutable.List<Comparison>>(this.CHILDREN); }
  setChildren(children: Immutable.List<Comparison>): this { return this.setInternalValue(this.CHILDREN, children); }
  
}