import * as Immutable from "immutable";

import { ConfSessionData } from "./confSessionData";
import { ImmutableObject } from "../../../../shared/immutableObject";

export class ConfDataState extends ImmutableObject {
    
  public readonly DATA_BY_SESSION_ID = "dataBySessionId";
  
  get dataBySessionId(): Immutable.Map<string, ConfSessionData> { return this.getInternalValue<Immutable.Map<string, ConfSessionData>>(this.DATA_BY_SESSION_ID); }
  setDataBySessionId(dataBySessionId: Immutable.Map<string, ConfSessionData>): this { return this.setInternalValue(this.DATA_BY_SESSION_ID, dataBySessionId); }  
}