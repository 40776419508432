<div #visualObjectContainer>
  <div class="visual-object-margin form-decoration decoration" [style.max-width.%]="'100'">

    <div *ngIf="confFormValue != null && confFormValue.formControlValues != null">
      <div *ngFor="let formControlValue of confFormValue.formControlValues">
        <button class="col-auto btn btn-primary" (click)="controlClicked($event, formControlValue)" [disabled]="!formControlValue.enabled">{{formControlValue.title}}</button>
      </div>
    </div>

  </div>
  </div>