import { Component, Input, Output, EventEmitter, ViewContainerRef, ComponentFactoryResolver, ViewChild, AfterContentInit } from "@angular/core";
import { InputViewModel } from "../shared";

@Component({
  selector: 'icon',
  template: `<div *ngIf="view?.icon" class="input-icon align-middle justify-content-center {{classes}}" [style.height]="'100%'" >
              <co-image [width]="view.iconWidth" [imageSet]="view.iconImageSet" [height]="view.iconHeight" [isVault]="view.isVaultIcon"  type="svg" [key]="view.icon"></co-image>
            </div>`
})
export class IconComponent {
  @Input()
  view: InputViewModel;

  @Input()
  classes: string;
}