import * as Immutable from "immutable"

import { AbstractConfigurationMessage } from "./abstractConfigurationMessage"
import { KeyValue } from "../../responses"
import { RefObject } from "../../entities"

export class PriceSettingMessage extends AbstractConfigurationMessage {

  protected readonly CURRENCIES = "currencies";
  protected readonly CATEGORIES = "categories";
  protected readonly PRICES = "prices";

  get currencies(): Immutable.List<RefObject> { return this.getInternalValue<Immutable.List<RefObject>>(this.CURRENCIES); }
  setQuantity(currencies: Immutable.List<RefObject>): this { return this.setInternalValue(this.CURRENCIES, currencies); }

  get categories(): Immutable.List<KeyValue<string, string>> { return this.getInternalValue<Immutable.List<KeyValue<string, string>>>(this.CATEGORIES); }
  setCategories(categories: Immutable.List<KeyValue<string, string>>): this { return this.setInternalValue(this.CATEGORIES, categories); }  

  get prices(): Immutable.Map<string, Immutable.List<KeyValue<string, string>>> { return this.getInternalValue<Immutable.Map<string, Immutable.List<KeyValue<string, string>>>>(this.PRICES); }
  setPrices(prices: Immutable.Map<string, Immutable.List<KeyValue<string, string>>>): this { return this.setInternalValue(this.PRICES, prices); }
}