import { Component, Input } from "@angular/core";

@Component({
  selector: "gridview-header",
  templateUrl: './gridviewHeaderComponent.html'
})
export class GridviewHeaderComponent {

  @Input()
  classes: string = "";

}