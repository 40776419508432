import { Exception } from "../../../../shared/exception";

export class ApiException extends Exception
{  
  protected readonly CODE = "code";

  constructor(propertyMap?: any) {
    super(propertyMap);
  }

  public get code(): number {
    return this.getInternalValue<any>(this.CODE);
  }

  public set code(code: number) {
    this.setInternalValue(this.CODE, code);
  } 
}