import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
import { IconComponent } from "./iconComponent";
import { ImageModule } from "../image";

export * from "./iconComponent";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ImageModule
  ],
  declarations: [
    IconComponent
  ],
  exports: [
    IconComponent
  ]
})
export class IconModule {

}