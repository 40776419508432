<div class="radio-item align-middle" id="{{view?.id}}" attr.name="{{view?.name}}" >  
  <!--<div class="row">
    <div class="col">
      <co-image width="500" height="500" type="svg" imageSet="warning" key="checkmark"></co-image>
    </div>
  </div>-->
  <!--<div class="row">
    <div class="col">-->
      <standard-radio *ngIf="radioGroup.displayStyle === DISPLAY_STYLE_OPTIONS.Standard" [itemView]="view" [isReadOnly]="isReadOnly" [isDisabled]="isDisabled" [selectedViewId]="selectedViewId" ></standard-radio>
      <image-radio *ngIf="radioGroup.displayStyle === DISPLAY_STYLE_OPTIONS.Image" [itemView]="view" [isReadOnly]="isReadOnly" [isDisabled]="isDisabled" [selectedViewId]="selectedViewId" ></image-radio>
    <!--</div>
  </div>-->
</div>
