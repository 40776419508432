import { Injectable, Inject } from "@angular/core";
import * as Immutable from "immutable";

import { AppAction } from "../shared/appAction";
import { GlobalDataState } from "../shared/globalDataState";
import { GlobalDataActions } from "./globalDataActions";
import { GlobalDataResponse, UISettingsResponse } from "../../models/responses";
import { GlobalSettings, WebSettingsResponse } from "../../models";
import { BaseEntity } from "../../baseEntity";
import { Actions } from "../shared/actions";

@Injectable()
export class GlobalDataReducer {

   public getReducer() {

    let defaultState = new GlobalDataState();
    defaultState = defaultState.setEntities(Immutable.Map<number, BaseEntity>());
    defaultState = defaultState.setEntityIdsByClassName(Immutable.Map<string, Immutable.List<number>>());

    return (state = defaultState, action: AppAction<any>): GlobalDataState => {

      if (action.type == Actions.CLEAR_CLIENT_CACHE)
        return defaultState;

      if (!action.payload)
        return state;

      if (action.payload.data instanceof GlobalDataResponse) {

        let globalDataResponse = action.payload.data as GlobalDataResponse;

        if (action.type == GlobalDataActions.GLOBAL_ENTITIES_LOADED) {

          if (globalDataResponse.entities && globalDataResponse.entities.size > 0) {

            globalDataResponse.entityIdsByClassName.forEach((entityIds, className) => {

              let existingIds = state.entityIdsByClassName.get(className);
              state = state.setEntityIdsByClassName(state.entityIdsByClassName.set(className, this.mergeIds(existingIds, entityIds)));

            })

            let entities: Immutable.Map<number, BaseEntity> = this.mergeEntities(state.entities, globalDataResponse.entities);
            state = state.setEntities(entities);
          }
        }
        else if (action.type == GlobalDataActions.GLOBAL_SETTINGS_LOADED) {

          state = state.setGlobalSettings(globalDataResponse.globalSettings);
        }
        else if (action.type == GlobalDataActions.LOCALIZE_DATA_LOADED) {

          state = state.setLocalizeData(globalDataResponse.localizeData);
        }
      }
      else if (action.payload.data instanceof WebSettingsResponse) {

        let webSettingsResponse = action.payload.data as WebSettingsResponse;

        if (action.type == GlobalDataActions.WEB_SETTINGS_LOADED) {
          state = state.setWebSettings(webSettingsResponse.settings);
        }

      }
      else if (action.payload.data instanceof UISettingsResponse) {

        let uiSettingsResponse = action.payload.data as UISettingsResponse;

        if (action.type == GlobalDataActions.UI_SETTINGS_LOADED) {
          state = state.setUISettings(uiSettingsResponse.settings);
        }
      }
      return state;
    }
  }

  protected mergeIds(existingIds: Immutable.List<number>, newIds: Immutable.List<number>) {
    if (existingIds && existingIds.count() > 0)
      return existingIds.merge(newIds).toSet().toList();

    return newIds;
  }

  protected mergeEntities(existingEntities: Immutable.Map<number, BaseEntity>, newEntities: Immutable.Map<number, BaseEntity>): Immutable.Map<number, BaseEntity> {

    let resultedEntities = existingEntities;

    newEntities.forEach((newEntity: BaseEntity, key: number) => {

      // Convert to number, needed to make sure the key is number in runtime
      key = parseInt(key.toString());

      if (!existingEntities.has(key)) {
        resultedEntities = resultedEntities.set(key, newEntity);
      }
      else {
        let existingEntity = existingEntities.get(key);
        resultedEntities = resultedEntities.set(key, Object.assign(existingEntity, newEntity));
      }
    });

    return resultedEntities;
  }

}


