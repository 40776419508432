import * as Immutable from "immutable"

import { BaseEntity } from "../../baseEntity"
import { ParamValueInfo } from "./"

export class AttributeInfo extends BaseEntity {

  protected readonly TITLE = "title";
  protected readonly VALUE = "value";

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get value(): string { return this.getInternalValue<string>(this.VALUE); }
  setValue(value: string): this { return this.setInternalValue(this.VALUE, value); }
}