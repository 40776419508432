<action-box *ngIf="visible" textLeft="{{strings.Revisions}}" [showHeader]="showHeader">
  <ng-container>
    <div class="revisions">
      <div *ngIf="revisions.count() > 1 && showComparison; else alone">
        <revision-item *ngFor="let revision of revisions" [revision]="revision" [conf]="conf" [confSessionId]="confSessionId" (onValueChange)="onCompareSelectionChanged($event)"></revision-item>
        <div class="text-right">
          <button class="btn btn-light mt-2 text-uppercase" [attr.disabled]="(checkedRevisions.length > 1) ? null : ''" (click)="onComparisonClick()">{{strings.Compare}}</button>
        </div>
      </div>
      <ng-template #alone>
        <revision-item *ngFor="let revision of revisions" [revision]="revision" [conf]="conf" [confSessionId]="confSessionId" [showCheckbox]="false"></revision-item>
      </ng-template>
    </div>
  </ng-container>
</action-box>