<div *ngIf="direction == 'horizontal'" class="button {{class}}" title="{{text}}">
  <a class="container btn btn-primary m-0 p-0" role="button" (click)="onClick($event)">
    <span class="row m-0 p-2">
      <span class="col-auto pr-2 icon-border">
        <co-image *ngIf="icon" [width]="iconWidth" [height]="iconHeight" type="svg" [key]="icon" [imageSet]="imageSet"></co-image>
      </span>
      <span class="col ellipsis px-3 align-middle button-text" [class.text-uppercase]="uppercase">
        {{text}}
        <span *ngIf="highlightIfMandatory && subText" class="sub-text">
          ({{subText}})
        </span>
      </span>
    </span>
  </a>
</div>

<a *ngIf="direction == 'vertical'" class="button rounded vertical-button {{class}}" role="button" (click)="onClick($event)">
  <span [class.text-uppercase]="uppercase">
    {{text}}
  </span>
</a>
