<h3 *ngIf="showInlineTitle" >{{decorationTitle}}</h3>
<ng-container *ngFor="let valueModel of valuesModel.values; let ii = index;">
  
  <div class="row codes-row" [class.alt-row]="(ii+1) % 2 == 0">

    <div *ngIf="calcVisible" class="col">

      <decoration-codes-renderer *ngIf="valueModel.calcValue"
                                 [codeValue]="valueModel.calcValue"                                 
                                 [decorationId]="valuesModel.decorationId">

      </decoration-codes-renderer>

    </div>

    <div *ngIf="storedVisible" class="col">

      <decoration-codes-renderer *ngIf="valueModel.storedValue"
                                 [codeValue]="valueModel.storedValue"                                 
                                 [decorationId]="valuesModel.decorationId"
                                 [subHeading]="calcVisible && strings.Stored"
                                 [isStored]="true">

      </decoration-codes-renderer>

    </div>

  </div>
</ng-container>
