import { Inject, Component, Input, ChangeDetectorRef, Output, EventEmitter } from "@angular/core";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import { PopupService, InputViewModel } from "../../../../shared/components";
import { PopupIdentifiers, ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { PageActionCreator } from "../../../shared/providers/page";

@Component({
  selector: 'anonymous-dialog-popup',
  templateUrl: './anonymousDialogPopupComponent.html',
})
export class AnonymousDialogPopupComponent extends AbstractPopupComponent {

  public id: string = PopupIdentifiers.AnonymousDialog;

  public emailView: InputViewModel;
  public sendEmailView: InputViewModel;
  public showSendEmailCheckbox: boolean = false;

  public title: string;
  public okButtonText: string;
  public text: string;

  @Input()
  saving: boolean = false;

  @Output()
  onClosed = new EventEmitter();

  constructor(
    @Inject(ConfPageSessionService) public confPageSession: ConfPageSessionService,
    @Inject(PageActionCreator) public pageActionCreator: PageActionCreator,
    @Inject(PopupService) public popupService: PopupService,
    public cdr: ChangeDetectorRef
  ) {
    super(cdr, popupService);
  }

  public open(tag: any) {
    this.show();
  }

  ngOnInit() {
    this.emailView = new InputViewModel(this.strings.Email);

    this.sendEmailView = new InputViewModel(this.strings.SendMeALinkToTheConfiguration);

    super.ngOnInit();
  }

  show(): void {
    let conf = this.confPageSession.activeConfiguration;
    let confInfo = this.confPageSession.confStore.getRootConfInfo(this.confPageSession.confSessionId);

    // Setup texts and variables
    if (this.saving) {
      // Text for saving configuration and maybe sending mail.
      this.title = this.strings.SaveConfiguration;
      this.okButtonText = this.strings.Save;
      this.text = this.strings.EnterYourEmailAndPressSave;

      this.showSendEmailCheckbox = !this.saving || conf.authorization.canEmailLink;

      // New configurations will preselect sendning email.
      this.sendEmailView.value = conf.authorization.canEmailLink && confInfo.isNew;
    }
    else {
      // Texts for only sending mail

      this.title = this.strings.EmailThisConfiguration;
      this.okButtonText = this.strings.SendEmail;
      this.text = this.strings.EnterYourEmailAndPressSend;

      this.showSendEmailCheckbox = false;
      this.sendEmailView.value = true;
    }

    // Try to use existing value
    this.emailView.value = this.emailView.value || conf.anonymousAccessUserEmail;

    this.showInternal();
  }

  public get sendEmail(): boolean {
    return typeof this.sendEmailView.value === "boolean" ? this.sendEmailView.value : this.sendEmailView.value === "true";
  }

  okClick() {
    let anonymousUserEmail = this.emailView.value;

    // Update the email if we're saving and the email is different
    let updateEmail = this.saving && this.confPageSession.activeConfiguration.anonymousAccessUserEmail !== anonymousUserEmail;

    this.onClosed.emit({ anonymousUserEmail: anonymousUserEmail, sendEmail: this.sendEmail, updateEmail: updateEmail });
  }
}