import * as Immutable from "immutable";
import { ImmutableObject } from "../../../../shared/immutableObject";

export class BomColumnSetting extends ImmutableObject {

  protected readonly TITLE = "title";
  protected readonly PROPERTY = "property";
  protected readonly WIDTH = "width";
  protected readonly ORDER = "order";
  protected readonly DETAILS_ONLY = "detailsOnly";
  protected readonly COLUMN_ALIGNMENT = "columnAlignment";

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get property(): string { return this.getInternalValue<string>(this.PROPERTY); }
  setProperty(property: string): this { return this.setInternalValue(this.PROPERTY, property); }

  get width(): number { return this.getInternalValue<number>(this.WIDTH); }
  setWidth(width: number): this { return this.setInternalValue(this.WIDTH, width); }

  get order(): number { return this.getInternalValue<number>(this.ORDER); }
  setOrder(order: number): this { return this.setInternalValue(this.ORDER, order); }

  get detailsOnly(): boolean { return this.getInternalValue<boolean>(this.DETAILS_ONLY); }
  setDetailsOnly(detailsOnly: boolean): this { return this.setInternalValue(this.DETAILS_ONLY, detailsOnly); }

  get columnAlignment(): string { return this.getInternalValue<string>(this.COLUMN_ALIGNMENT); }
  setColumnAlignment(columnAlignment: number): this { return this.setInternalValue(this.COLUMN_ALIGNMENT, columnAlignment); }
}