import { Routes } from '@angular/router';
import { AuthGuard, RouteNames } from "../../shared/providers";
import { ComparisonComponent } from './comparisonComponent';
import { ComparisonResultsResolver } from './comparisonResultsResolver';

export const ComparisonRoutes: Routes = [

  {
    path: RouteNames.Comparison,
    component: ComparisonComponent,
    resolve: { comparisonResult: ComparisonResultsResolver },
    runGuardsAndResolvers: "paramsOrQueryParamsChange",
    canActivate: [AuthGuard]
  }
  
];
