import * as Immutable from "immutable"
import { AbstractConfigurationMessage } from "./abstractConfigurationMessage"
import { ConfInfo } from "../..";

export class CopyDestinationMessage extends AbstractConfigurationMessage {

  protected readonly CONF_INFOS = "confInfos";

  get confInfos(): Immutable.List<ConfInfo> { return this.getInternalValue<Immutable.List<ConfInfo>>(this.CONF_INFOS); }
  setConfInfos(confInfos: Immutable.List<ConfInfo>): this { return this.setInternalValue(this.CONF_INFOS, confInfos); }
  
}