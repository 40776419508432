import { Inject, Component } from "@angular/core";
import { BaseViewComponent } from "./baseViewComponent";
import { ConfMessageHandler, ConfPageSessionService } from "./providers";
import { RequestViews } from "../shared/models";

@Component({
  selector: 'editor-view',
  templateUrl: './editorViewComponent.html',
  providers: [ConfMessageHandler]
})
export class EditorViewComponent extends BaseViewComponent {

  constructor(
    @Inject(ConfMessageHandler) public confMessageHandler: ConfMessageHandler,
    @Inject(ConfPageSessionService) public confPageSession: ConfPageSessionService
  ) {
    super();
  }

  ngOnInit() {
    this.confPageSession.activeRoute = RequestViews.Editor;
    this.updateUIStore();
    this.confMessageHandler.subscribeMessagesForEditor();
  }

  onSaveConfiguration(event): void {
  }

  updateUIStore(): void {
    this.confPageSession.selectedTabId = null;
  }

  ngOnDestroy() {
    this.confMessageHandler.onDestroy();
    super.ngOnDestroy();
  }

}