export interface ServerConfSearchCriteriaHolder {



}


export interface AttributeField {

  // Name of the search attrbute e.g OwnerId, CreationDate, ProductId etc
  // If selector attribute is EoObject then set the longId. e.g ParameterId, AttributeId.
  id: string;

  title: string;

  region: SearchCriteriaRegion;

  // Represents string, refToObject ...
  valueType?: string;

  // Represents value control's type
  valueControlType: SearchValueControlType;

  refClassName?: string;

  tag: any;

  isEmpty?: boolean;

  enabled?: boolean;

}

export enum SearchValueControlType {
  Textbox = 'textbox',
  Dropdown = 'dropdown',
  DateRange = 'daterange',
  MultiSelectDropdown = 'multiselect',
  Checkbox = 'checkbox',
  RangeTextbox = 'rangetextbox',
  None = 'none'
}

export enum SearchCriteriaRegion {
  ConfProperty,
  Parameter,
  Attribute
}