import { Output, EventEmitter, ElementRef, Directive, HostListener, ViewContainerRef, Input } from "@angular/core";

@Directive({
  selector: '[attributeBackdrop]'
})

export class AttributePopperDirective {

  @Input()
  attributeBackdrop: boolean;
  @Output()
  backdropClicked = new EventEmitter<ElementRef>();

  constructor(private referenceElement: ViewContainerRef) { }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.attributeBackdrop && !this.referenceElement.element.nativeElement.contains(event.target)) {
      this.backdropClicked.emit();
    }
  }
}