import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageSets } from '../../../../shared/utils';
import { BaseComponent } from "../../../shared";
import { MenuItem } from "./navigation";

@Component({
  selector: 'composite-menu-item',
  templateUrl: './compositeMenuItemComponent.html'
})
export class CompositeMenuItemComponent extends BaseComponent {

  public imageSet: string;

  @Input()
  public item: MenuItem;

  @Input()
  public accessible: boolean = true;

  @Output()
  public itemClick = new EventEmitter<any>();   

  ngOnInit() {
    this.imageSet = this.getImageSet(ImageSets.ConfiguratorTree);
  }

}

