<div *ngIf="visible">
  <h3>{{strings.UserSettings}}</h3>

  <dropdown *ngIf="languageView"
            [view]="languageView"
            [showEmptyValue] ="false"
            [itemsView]="languagesView">

  </dropdown>

  <dropdown *ngIf="priceListCategoryView"
            [view]="priceListCategoryView"
            [itemsView]="priceListCategoriesView">
  </dropdown>

  <check-box [view]="hightlightMandatoryParamsView"></check-box>

  <button class="btn btn-primary" (click)="updateSettings()">{{strings.Update}}</button>
</div>