import { BaseComponent } from "./baseComponent";
import { HttpLifeCycleService, IHttpDataInfo, HttpStatus } from "./providers";
import { GlobalServiceLocator } from "../../shared/providers/globalServiceLocator";
import { EmitterService } from "../configurator/shared";
import { PopupIdentifiers } from "../configurator/providers";
import { Component } from "@angular/core";

@Component({ template: '' })
export abstract class PageComponent extends BaseComponent {


  private _httpLifeCycleService: HttpLifeCycleService;
  public get httpLifeCycleService() {
    if (!this._httpLifeCycleService)
      this._httpLifeCycleService = GlobalServiceLocator.injector.get(HttpLifeCycleService);
    return this._httpLifeCycleService;
  }

  ngOnInit() {

    this.httpLifeCycleService.getMessage().subscribe((http: IHttpDataInfo) => {

      this.onHttpLifeCycleUpdate(http);

    }).unsubscribeOn(this.unsubscribeSubject);

    super.ngOnInit();
  }

  onHttpLifeCycleUpdate(http: IHttpDataInfo) {

    //prevents blocking the ui when progress bar is shown. TODO: implement on component level
    if (!http.request.reportProgress) {
      switch (http.status) {

        case HttpStatus.Started:

          break;

        case HttpStatus.WaitingState:
          this.blockUI();
          break;

        case HttpStatus.Completed:
          this.unblockUI();
          break;
      }
    }

  }

  onBeforeUnload($event, isReload?: boolean) {}

  ngOnDestroy() {
    this.unblockUI();
    super.ngOnDestroy();
  }

}