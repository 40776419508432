import { Injectable, Inject } from "@angular/core";

import { AppAsyncAction, HttpAction } from "./state/shared";

@Injectable()
export abstract class BaseActionCreator {

  constructor(){}

  public createHttpAction<T>(actionType: any, executeAsync: any, payload: T = null): HttpAction<T> {

    let httpAction: HttpAction<T> = {
      type: actionType,
      executeAsync: executeAsync,
      payload: payload
    }

    return httpAction;
  }

}