import { Injectable, Inject, InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';
import { Range, DateValueModel } from '../models/model';


export const DATE = new InjectionToken<Date>('date');

@Injectable()
export class RangeDatepickerService {

  rangeUpdateSubscription: Subject<DateValueModel> = new Subject<DateValueModel>();

  private _fromDate: Date;
  private _toDate: Date;

  constructor(
    //@Inject(DATE) private _fromDate: Date, // Default date
    //@Inject(DATE) private _toDate: Date // Default date
  ) {

  }

  /* set fromDate(fromDate:Date) {
    this._fromDate = fromDate;
  } */

  get fromDate(): Date {
    return this._fromDate;
  }

  get range(): Range {
    return <Range>{
      fromDate: this.fromDate,
      toDate: this.toDate
    }
  }

  /* set toDate(toDate:Date) {
    this._toDate = toDate;
  } */

  get toDate(): Date {
    return this._toDate;
  }
  
  updateRange(valueModel: DateValueModel) {      

    if (valueModel.range) {
      this._fromDate = valueModel.range.fromDate;
      this._toDate = valueModel.range.toDate;
    }
    
    this.rangeUpdateSubscription.next(valueModel);
  }

}