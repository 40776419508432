<nav>
  <ul class="pagination">
    <li class="page-item page-first" [class.disabled]="currentPage <= 1"><div class="page-link" (click)="onPaging(1)">First</div></li>
    <li class="page-item page-prev" [class.disabled]="currentPage <= 1">
      <div class="page-link" href="#" aria-label="Previous" (click)="onPaging(currentPage - 1)">
        <span aria-hidden="true">&laquo;</span>
        <span class="sr-only">Previous</span>
      </div>
    </li>
    <li class="page-item page-num text-center" *ngFor="let page of pages" [class.active]="currentPage == page">
      <div class="page-link" (click)="onPaging(page)">{{page}}</div>
    </li>

    <li class="page-item page-next" [class.disabled]="currentPage == noOfPages">
      <div class="page-link" aria-label="Next" (click)="onPaging(currentPage + 1)">
        <span aria-hidden="true">&raquo;</span>
        <span class="sr-only">Next</span>
      </div>
    </li>
    <li class="page-item page-last" [class.disabled]="currentPage == noOfPages"><div class="page-link" (click)="onPaging(noOfPages)">Last</div></li>
  </ul>
</nav>
