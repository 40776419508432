import { InputViewModel } from "../../../../shared/components/shared";

export class AutoCompleteModel extends InputViewModel {
  private _matchedPart: string;
  public nameParts: Array<AutoCompleteNamePart>;
  public matchOnce = false;

  public constructor(text?: string, matchOnce?: boolean) {
    super(text);

    this.matchOnce = matchOnce;
  }

  set matchedPart(match: string) {
    this._matchedPart = match.replace("*","");
    this.nameParts = this.formatName();
  }

  /**
   * Gets the formatted name for this autocomplete model.
   * Will split up matches with non-matches.
   */
  public formatName() {
    let result = new Array<AutoCompleteNamePart>();
    // Nothing to format
    if (this._matchedPart.length == 0) {
      result.push(new AutoCompleteNamePart(this.title, false));
      return result;
    }

    // Get the index of all matches
    let regex = new RegExp(this._matchedPart, "gi"), match, indices = [];
    while ((match = regex.exec(this.title))) {
      indices.push(match.index);

      if (this.matchOnce)
        break;
    }

    if (indices.length == 0)
      return result;

    // Add start to first match
    if (indices[0] > 0)
      result.push(new AutoCompleteNamePart(this.title.substr(0, indices[0]), false));

    // Add all matches
    for (let i = 0; i < indices.length; i++) {
      let lower = indices[i];
      let length = this._matchedPart.length;
      let upper = lower + length;
      // Add match
      result.push(new AutoCompleteNamePart(this.title.substr(lower, length), true));

      // We have more matches -> Add everything up to the next match
      if ((i + 1) < indices.length)
        result.push(new AutoCompleteNamePart(this.title.substr(upper, indices[i + 1] - lower - 1), false));
      // This is the last match -> Add rest
      else
        result.push(new AutoCompleteNamePart(this.title.substr(upper, this.title.length), false));
    }
    return result;
  }
}

export class AutoCompleteNamePart {
  constructor(
    public value: string,
    public isMatch: boolean) {
  }
}