import * as Immutable from "immutable"

import { AbstractConfigurationMessage } from "./abstractConfigurationMessage"

export class ConfValueErrorMessage extends AbstractConfigurationMessage {
    
  protected readonly CONF_VAL_ID = "confValueId";
  protected readonly CONF_PROPERTY_NAME = "confProprtyName";
  protected readonly MESSAGE = "message";

  get confValueId(): number { return this.getInternalValue<number>(this.CONF_VAL_ID); }
  setConfValueId(confValueId: number): this { return this.setInternalValue(this.CONF_VAL_ID, confValueId); }

  get confProprtyName(): string { return this.getInternalValue<string>(this.CONF_PROPERTY_NAME); }
  setConfProprtyName(confProprtyName: string): this { return this.setInternalValue(this.CONF_PROPERTY_NAME, confProprtyName); }

  get message(): string { return this.getInternalValue<string>(this.MESSAGE); }
  setMessage(message: string): this { return this.setInternalValue(this.MESSAGE, message); }
}