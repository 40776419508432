import { Component, Inject, Input } from '@angular/core';
import { NodeData, PopupService } from "../../../../../shared/components";
import { BaseComponent } from "../../../../shared";
import { ConfInfo } from "../../../../shared/models";
import { ProductDataStore } from "../../../../shared/providers/productData";
import { ConfiguratorStore, ConfPageSessionService } from "../../../providers";

@Component({
  selector: 'sorting-configurations',
  templateUrl: './sortConfigurationsComponent.html'
})
export class SortConfigurationsComponent extends BaseComponent {

  dragableItems: Array<NodeData> = [];

  originalOrder: Array<number> = [];

  @Input()
  public parentConfId: number;

  constructor(
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(PopupService) public popupService: PopupService,
    @Inject(ProductDataStore) public productDataStore: ProductDataStore
  ) {
    super();
  }

  ngOnInit(): void {
    let parentConfInfo: ConfInfo = this.confStore.getConfInfo(this.parentConfId, this.storeSession.confSessionId);

    this.popupService.enable("compositePopup", false);

    parentConfInfo.children.forEach(confId => {
      this.originalOrder.push(confId);
      this.dragableItems.push(this.createNode(confId));
    });
  }

  public createNode(confId: number): NodeData {
    let child: ConfInfo = this.confStore.getConfInfo(confId, this.storeSession.confSessionId);
    let iconDefined: boolean = child.iconRelativeUrl && child.iconRelativeUrl != "";
    let icon: string = iconDefined ? child.iconRelativeUrl : "product";

    let node: NodeData = new NodeData();
    node = node.setTitle(child.text);
    node = node.setId(child.longId);
    node = node.setVaultIcon(iconDefined);
    node = node.setIconKey(icon);

    return node;
  }

  public isOrderChanged(node: NodeData, index: number): boolean {
    return this.originalOrder.indexOf(node.id) == index;
  }

  public confirm(event): void {
    // TODO
  }
}