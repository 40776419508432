import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, Inject } from "@angular/core";
import { HttpEventType, HttpResponse } from "@angular/common/http";
import * as Immutable from 'immutable';

import { Strings } from "../../providers/globalData";
import { AttachmentCommand, ApiResponse, AttachmentOperationMessage } from "../../models";
import { AttachmentsStore } from "./attachmentsStore";
import { ConfMessageProvider } from "../../../configurator/providers";
import { BaseComponent } from "../..";

@Component({
  templateUrl: './uploadComponent.html',
  selector: 'upload',
})
export class UploadComponent extends BaseComponent {

  @Input()
  multiple: boolean = false;

  @Input()
  public configurationId: number;

  @Input()
  public confSessionId: number;

  @Output()
  uploadDone = new EventEmitter();

  @Output()
  selectedFilesChanged = new EventEmitter();

  @ViewChild("upload")
  private _uploadElement: ElementRef;

  public files: FileInfo[] = [];

  constructor(
    protected confMessageProvider: ConfMessageProvider,
    protected attachmentsStore: AttachmentsStore    
  ) {
    super();
  }

  protected get uploader(): HTMLInputElement {
    return this._uploadElement.nativeElement as HTMLInputElement;
  }

  add() {
    this.uploader.click();
  }

  // At the drag drop area
  // (drop)="onDropFile($event)"
  onDropFile(event: DragEvent) {
    event.preventDefault();
    // TODO
    //this.uploadFiles(event.dataTransfer.files);
  }

  // At the drag drop area
  // (dragover)="onDragOverFile($event)"
  onDragOverFile(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  // At the file input element
  // (change)="selectFile($event)"
  selectFile(event) {
    let fileList: FileList = event.target.files
    this.setupFiles(fileList);
    this.selectedFilesChanged.emit();
  }

  protected setupFiles(fileList: FileList) {
    this.files = [];
    for (let i = 0; i < fileList.length; i++) {
      let fileInfo = new FileInfo();
      fileInfo.file = fileList[i];
      fileInfo.text = this.strings.UploadNotStarted;
      this.files.push(fileInfo);
    }
  }

  protected uploadFile(file: FileInfo) {
    file.resetUpload();
    file.running = true;

    this.attachmentsStore.uploadFile(file.file, event => {
      if (event.type == HttpEventType.UploadProgress) {
        const percentDone = Math.round(100 * event.loaded / event.total);
        file.percentDone = percentDone;
        file.text = percentDone + "%";

        if (percentDone == 100) {
          file.running = false;         
          file.text = this.strings.UploadCompleted;          
        }
      }
      else if (event.type == HttpEventType.Response) {

        // Listen for success or failure event
        let managedSubscription = this.confMessageProvider.onMessagesRequest<AttachmentOperationMessage>(this.confSessionId, AttachmentOperationMessage.name, {
          next: (messages: Immutable.List<AttachmentOperationMessage>): void => {

            file.running = false;

            let message = messages.get(0);
            if (!message.success) {
              file.text = message.message;              
              file.error = true;
              file.done = false;
            }
            else {
              file.done = true;
            }

            this.afterUpload();

            managedSubscription.unsubscribe();

          }, startOnDemand: true
        });

        managedSubscription.unsubscribeOn(this.unsubscribeSubject);
        managedSubscription.start();      
      }
    },
      error => {
        console.log("Upload Error:", error);
        file.text = this.strings.UploadFailed;
        file.running = false;
        file.error = true;
      }
    );
  }

  uploadFiles() {
    if (this.files.length == 0) {
      console.log("No file selected!");
      return;
    }

    for (let i = 0; i < this.files.length; i++) {
      let file = this.files[i];
      if (!file.done)
        this.uploadFile(file);
    }
  }

  protected afterUpload() {
    let uploadSucceeded = this.files.every(x => x.done);
    if (uploadSucceeded) {
      this.uploadDone.emit();
    }
  }

  onDeleteClick(file: FileInfo) {
    this.files.splice(this.files.indexOf(file), 1);
    if (this._uploadElement)
      this._uploadElement.nativeElement.value = "";
  }
}

export class FileInfo {
  public file: File;
  public percentDone: number = 0;
  public done: boolean = false;
  public running: boolean = false;
  public error: boolean = false;
  public text: string;

  public resetUpload() {
    this.percentDone = 0;
    //this.done = false;
    this.running = false;
    this.error = false;
    this.text = "";
  }
}