import { Input, ChangeDetectorRef, Component } from "@angular/core";
import { UIComponent } from "../../../shared";
import { Tab } from "../../../shared/models";
import { GlobalServiceLocator } from "../../../../shared/providers/globalServiceLocator";
import { ConfiguratorUIStore, PopupIdentifiers } from "../../providers";
import { TabAvailabilityService } from "./tabAvailabilityService";
import { IEmitDataInfo } from "../../shared";
import { ProductDataStore } from "../../../shared/providers/productData";

@Component({ template: '' })
export abstract class TabContainerComponent extends UIComponent {

  public domReady: boolean = false;

  @Input()
  public containerId: string;
 
  constructor(public confUIStore: ConfiguratorUIStore, public cd: ChangeDetectorRef, public tabAvailableService: TabAvailabilityService) {
    super(confUIStore);
  }

  ngAfterViewInit() {
    this.tabAvailableService.onViewReady(this);
    super.ngAfterViewInit();
  }

  ngOnInit() {

    // Listen the messages from popups e.g Mandatory, ReadOnlyPopup which can trigger the tab-change
    this.emitterService.getMessage().subscribe((info: IEmitDataInfo<number>) => {

      if (info.id == PopupIdentifiers.ChangeSelectedTab && info.tag) {

        // Product store service
        let productStore = GlobalServiceLocator.injector.get(ProductDataStore)

        // Grab the tab from store.
        const tabId = Number(info.tag);
        let tab: Tab = productStore.getEntity(tabId);

        if (!tab)
          return;

        // Notify to corresponding tabs container.
        this.activateTab(tab);
        

      }

    }).unsubscribeOn(this.unsubscribeSubject);

    super.ngOnInit();
  }

  public ready(): void {
    this.domReady = true;
    this.cd.detectChanges();
  }

  public abstract activateTab(tab: Tab);


  ngOnDestroy(): void {

    this.tabAvailableService.onDestroy(this);
    super.ngOnDestroy();
  }

}