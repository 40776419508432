import { Subject, Observable } from "rxjs";
import { ManagedSubject } from "../../../shared/managedSubject";

export interface IEmitDataInfo<T> {
  id: string;
  tag: T;
}

export class EmitterService {
  
  private subject = new ManagedSubject<any>(null, false, 0, false, false); 

  public send<T>(id: string, tag?: T): void {

    let info: IEmitDataInfo<T> = <IEmitDataInfo<T>>{
      id: id,      
      tag: tag
    }

    this.subject.nextValue(info);
  }

  public getMessage(): ManagedSubject<any> {
    return this.subject;
  }

}