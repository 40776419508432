import { BaseEntity } from "../../baseEntity";
import { UserSettings, RefObject } from "..";
import { SystemAuthorization } from "./systemAuthorization";

export class User extends BaseEntity {

  static readonly NAME = "name";
  static readonly EMAIL = "email";
  static readonly COMPANY = "company";
  static readonly MOBILE_NUMBER = "mobileNumber";
  static readonly PHONE_NUMBER = "phoneNumber";
  static readonly ADMINISTRATOR = "administrator";
  static readonly SUPER_USER = "superUser";
  static readonly USERNAME = "username";
  static readonly WORK_GROUP = "workGroup";
  static readonly LANGUAGE = "language";
  static readonly DEFAULT_PRICE_LIST_CATEGORY = "defaultPriceListCategory";
  static readonly SETTINGS = "settings";
  static readonly SYSTEM_AUTHORIZATION = "systemAuthorization";
  
  get name(): string { return this.getInternalValue<string>(User.NAME); }
  setName(name: string): this { return this.setInternalValue(User.NAME, name); }

  get email(): string { return this.getInternalValue<string>(User.EMAIL); }
  setEmail(email: string): this { return this.setInternalValue(User.EMAIL, email); }

  get company(): string { return this.getInternalValue<string>(User.COMPANY); }
  setCompany(company: string): this { return this.setInternalValue(User.COMPANY, company); }

  get mobileNumber(): string { return this.getInternalValue<string>(User.MOBILE_NUMBER); }
  setMobileNumber(mobileNumber: string): this { return this.setInternalValue(User.MOBILE_NUMBER, mobileNumber); }

  get phoneNumber(): string { return this.getInternalValue<string>(User.PHONE_NUMBER); }
  setPhoneNumber(phoneNumber: string): this { return this.setInternalValue(User.PHONE_NUMBER, phoneNumber); }

  get administrator(): boolean { return this.getInternalValue<boolean>(User.ADMINISTRATOR); }
  setAdministrator(administrator: boolean): this { return this.setInternalValue(User.ADMINISTRATOR, administrator); }

  get superUser(): boolean { return this.getInternalValue<boolean>(User.SUPER_USER); }
  setSuperUser(superUser: boolean): this { return this.setInternalValue(User.SUPER_USER, superUser); }

  get username(): string { return this.getInternalValue<string>(User.USERNAME); }
  setUsername(username: string): this { return this.setInternalValue(User.USERNAME, username); }  

  get workGroup(): RefObject { return this.getInternalValue<RefObject>(User.WORK_GROUP); }
  setWorkGroup(workGroup: RefObject): this { return this.setInternalValue(User.WORK_GROUP, workGroup); } 

  get language(): RefObject { return this.getInternalValue<RefObject>(User.LANGUAGE); }
  setLanguage(language: RefObject): this { return this.setInternalValue(User.LANGUAGE, language); } 

  get defaultPriceListCategory(): RefObject { return this.getInternalValue<RefObject>(User.DEFAULT_PRICE_LIST_CATEGORY); }
  setDefaultPriceListCategory(defaultPriceListCategory: RefObject): this { return this.setInternalValue(User.DEFAULT_PRICE_LIST_CATEGORY, defaultPriceListCategory); } 

  get settings(): UserSettings { return this.getInternalValue<UserSettings>(User.SETTINGS); }
  setSettings(settings: UserSettings): this { return this.setInternalValue(User.SETTINGS, settings); }  

  get systemAuthorization(): SystemAuthorization { return this.getInternalValue<SystemAuthorization>(User.SYSTEM_AUTHORIZATION); }
  setSystemAuthorization(systemAuthorization: SystemAuthorization): this { return this.setInternalValue(User.SYSTEM_AUTHORIZATION, systemAuthorization); } 
}