import { Inject, Component, ViewEncapsulation, Injectable, Input, OnInit, AfterViewInit, Host, ChangeDetectorRef, ElementRef, ViewChild, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { TabComponent } from "./tabComponent";
import { TabsContainerComponent } from "./tabsContainerComponent";
import { BaseComponent } from "../../../pages/shared";
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { TabModel } from "./tabModel";
import { SimpleChanges } from '@angular/core';
import { Strings } from "../../../pages/shared/providers/globalData";

@Component({
  selector: "tab-links",
  templateUrl: './linksContainerComponent.html'
})
export class LinksContainerComponent {

  // Selected tab css
  private selectedTabStyle: string = "";

  @ViewChild('tabsList')
  private tabsListElement: ElementRef;

  public tabListWidth: number;

  public nativeTabContainer: any;

  public nativeTabsList: any;

  public nativeTabItems: any[];

  public displayScroller: boolean = false;

  public currentScrolledTab: number = 0;

  private oldLength: number = 0;

  private leftPos: number = 0;

  // Firefox will give a small decimal number larger than zero when calculating client bounding rects.
  private readonly MIN_DISTANCE = 0.1;

  @Output()
  public activeLink: EventEmitter<any> = new EventEmitter();

  @Input()
  public position: string;

  @Input()
  public models: TabModel[] = [];

  public activeLinkId: string;

  @Input()
  public defaultSelectedTabId: string;

  public enableRightScroller = false;

  constructor(private cdr: ChangeDetectorRef) {

  }

  ngAfterViewInit() {
    this.nativeTabContainer = this.tabsListElement.nativeElement.parentElement;
    this.nativeTabsList = this.tabsListElement.nativeElement;
    this.nativeTabItems = this.nativeTabsList.getElementsByTagName("li");

    // In a timeout so that things render first
    setTimeout(() => {
      this.setScrollableOptions();
    });
  }

  ngOnInit(): void {

    this.setDefaultTabSelected();

  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['models']) {
      // Must update the scrollbar on 'models' change.
      // Wait until contents rendered then set the scrollbar options.
      setTimeout(() => {
        this.setScrollableOptions();
      });

    }

    if (changes['defaultSelectedTabId']) {
      this.setDefaultTabSelected();      
    }

  }

  setDefaultTabSelected(): void {
    let results = this.models.filter(x => x.id == this.defaultSelectedTabId);
    this.activeLinkId = results.length > 0 ? results[0].id : this.models[0].id;    

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setScrollableOptions();
  }

  public setEnableRightScroller(): void {
    this.enableRightScroller = this.nativeTabItems[this.nativeTabItems.length - 1].getBoundingClientRect().right - this.nativeTabContainer.getBoundingClientRect().right > this.MIN_DISTANCE;
    this.cdr.markForCheck();
  }

  public widthOfTab(tabIndex: number): number {
    if (tabIndex >= 0 && tabIndex < this.nativeTabItems.length) {
      return this.nativeTabItems[tabIndex].offsetWidth;
    }
    return 0;
  }

  public leftScrollMargin(): number {
    return this.nativeTabContainer.getBoundingClientRect().left;
  }

  public onScrollLeft($event?, scrollOnTabRemove: boolean = false, tabsToScroll?: number): void {

    if (this.currentScrolledTab > 0) {
      this.currentScrolledTab--;
      let width: number = this.widthOfTab(this.currentScrolledTab);

      // Calculate the scroll distance. We don't want to scroll too far so we go "outside" the tab area.
      // Distance is either the tab's full width, or just enough to reach the start.

      let firstTabPosition: number = this.nativeTabItems[0].getBoundingClientRect().left - this.leftScrollMargin();
      let useFirstTabPosition: boolean = (width + firstTabPosition) > 0;

      let distance: number = width;

      if (useFirstTabPosition) {
        distance = Math.abs(firstTabPosition);
        this.currentScrolledTab = 0;
      }

      let distanceToScroll: number = distance;

      if (scrollOnTabRemove) 
        distanceToScroll = distance * tabsToScroll;
   
      this.leftPos += distanceToScroll;
      this.nativeTabsList.style.left = this.leftPos + 'px';
      this.setEnableRightScroller();
      
    }
  }

  public onScrollRight($event): void {
    // Don't scroll if it will put us outside the tab area.
    if (this.enableRightScroller) {
      // Calculate the scroll distance. We don't want to scroll too far.
      // Distance is either the tab's full width, or just enough to reach the end.
      let lastTabDistance = this.nativeTabItems[this.nativeTabItems.length - 1].getBoundingClientRect().right - this.nativeTabContainer.getBoundingClientRect().right;
      let distance = this.widthOfTab(this.currentScrolledTab);
      let useLastTabDistance = lastTabDistance < distance;

      if (useLastTabDistance)
        distance = lastTabDistance;

      this.leftPos -= Math.min(distance, this.widthOfTab(this.currentScrolledTab));
      this.nativeTabsList.style.left = this.leftPos + 'px';
      this.setEnableRightScroller();    

      this.currentScrolledTab++;
    }
  }

  public setScrollableOptions(): void {

    if (this.nativeTabContainer == null || this.nativeTabsList == null)
      return;

    if (this.nativeTabItems.length < this.oldLength) {
      let tabsToScroll = this.oldLength - this.nativeTabItems.length;
      this.onScrollLeft(null, true, tabsToScroll);
    }
      
    this.oldLength = this.nativeTabItems.length;

    // If the container width is smaller than tab-links width then show the scrollable options.
    let old = this.displayScroller;

    let tabsWidth: number = this.calcTabsWidth();

    this.displayScroller = this.nativeTabContainer.offsetWidth < tabsWidth;
    this.cdr.markForCheck();

    // Reset position if we're going from displaying scroller to not displaying it (to make sure all items are shown)
    if (old && !this.displayScroller) {
      console.log("swapping");
      this.nativeTabsList.style.left = "";
    }

    // Reset after toggling scroller 
    if (old != this.displayScroller)
      this.currentScrolledTab = 0;

    this.setEnableRightScroller();
  }

  private calcTabsWidth(): number {
    let tabsWidth: number = 0;
    for(let item of this.nativeTabItems) {
      tabsWidth += item.clientWidth as number;
    }
    return tabsWidth;
  }

  /**
   * Selects the current tab component and deselects others.
   * @param event
   * @param model
   */
  selectTab(event: any, model: TabModel) {

    this.activeLinkId = model.id;    
    this.activeLink.emit(model);
    // Stop default propagation.         
    event.preventDefault();
  }
  
}