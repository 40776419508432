import { Component, Input, Inject, EventEmitter, ElementRef, ViewChild, HostListener, Output, SimpleChanges } from "@angular/core";
import { BaseComponent } from "../..";
import { ConfGraphicsValue, GraphicsDecoration, ConfValue, LookupParam, ConfLookupValue, ParamValue, Tab, GraphicsDisplayStyle, ConfGraphicsRequest } from "../../models";
import { GlobalDataStore } from "../../providers/globalData";
import { ConfPageSessionService, ConfiguratorStore } from "../../../configurator/providers";
import { ProductDataStore } from "../../providers/productData";
import { GraphicsHelper } from "./graphicsHelper";
import { PanZoomConfig } from "./panZoomConfig";
import { EmitterService } from "../../../configurator/shared";
import { SvgActions } from "./svgActions";
import { VisualObjectHelper } from "../../../configurator/parameters/shared";
import { PageStore } from "../../providers/page";
import { RouteNames, ModelFactory } from "../../providers";
import { ConfDataController } from "../../providers/configurationData";
import { HttpService } from "../../../../shared/providers";

@Component({
  selector: 'graphics-renderer',
  templateUrl: './graphicsRendererComponent.html',
})
export class GraphicsRendererComponent extends BaseComponent {

  public svg: string = "";

  public containerStyle: CSSStyleDeclaration;
  public isSummary = false;

  protected previousWidth = window.innerWidth;
  protected previousScale = 1;
  protected firstCall = true;

  public config: PanZoomConfig;

  @Input()
  width: string;

  @Input()
  height: string;

  calcHeight: number;

  @Input()
  paddingLeft: number = 0;

  @Input()
  paddingTop: number = 0;

  @Input()
  decoration: GraphicsDecoration;

  @Input()
  public confId: number;

  @Input()
  public sessionId: number;

  @ViewChild('container')
  public container: ElementRef;

  constructor(
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(ProductDataStore) public productStore: ProductDataStore,
    @Inject(GraphicsHelper) public graphicsHelper: GraphicsHelper,
    @Inject(PageStore) public pageStore: PageStore,
    @Inject(VisualObjectHelper) public visualObjectHelper: VisualObjectHelper,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    @Inject(ConfDataController) public configuratorController: ConfDataController,
    @Inject(HttpService) public httpService: HttpService,
  ) {
    super();
  }

  ngOnInit(): void {

    this.isSummary = this.pageStore.activeRouteName === RouteNames.Summary;
    this.config =
      {
        identifier: this.decoration.longId,
        initialOrigoPosition: this.decoration.initialOrigoPosition,
        initialX: this.decoration.initialOffsetX + this.paddingLeft,
        initialY: this.decoration.initialOffsetY + this.paddingTop,
        initialZoomFactor: this.decoration.initialZoomFactor || 1,
        responsive: this.decoration.responsiveSize,
        realWidth: this.decoration.width,
        realHeight: this.decoration.height
      } as PanZoomConfig;

    this.updateStyles();
  }

  getURL(): string {

    let model: ConfGraphicsRequest = this.modelFactory.createAny(ConfGraphicsRequest.name) as any;
    model.client = this.pageStore.getActiveClientType();
    model.fileType = "svg";
    model.configurationId = this.confId;
    model.confSessionId = this.pageStore.activeRouteName == RouteNames.Start ? this.sessionId : this.confPageSessionService.confSessionId;
    model.graphicsDecorationId = this.decoration.visualObjectId;
    return this.configuratorController.getConfigurationGraphicUrl(model);
  }

  init(): void {

    // TODO: Need to improve this part, sessionId must be passed through arguments, this method won't work if it is called while viewing the graphics from start page.
    let value = this.confStore.getConfDataEntity<ConfGraphicsValue>(this.confId, this.confPageSessionService.confSessionId, this.decoration.visualObjectId);

    if (value)
      this.svg = value.svg;

    this.updateHtml();
    if (this.firstCall) {
      this.firstCall = false;
      setTimeout(() => { this.emitterService.send(SvgActions.Render, this.config.identifier); }, 0);
    }
    else
      this.emitterService.send(SvgActions.Render, this.config.identifier);
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateStyles();
  }

  protected updateStyles() {

    this.containerStyle = {} as CSSStyleDeclaration;

    if (this.decoration.responsiveSize) {

      if (this.decoration.height && this.visualObjectHelper.isVisualObjectInAccordion(this.decoration))
        this.containerStyle.height = `${this.decoration.height}px`;

      this.containerStyle.width = '100%';

    }
    else {

      this.containerStyle.maxWidth = this.isSummary || this.decoration.displayStyle === GraphicsDisplayStyle.Popup ? '100%' : this.visualObjectHelper.visualObjectWidth(this.decoration);
      this.containerStyle.width = '100%';

      if (this.decoration.height && this.decoration.displayStyle !== GraphicsDisplayStyle.Popup)
        this.containerStyle.height = `${this.decoration.height}px`;

    }

  }

  ngAfterViewInit() {
    if (this.decoration.displayStyle == GraphicsDisplayStyle.Render)
      this.confStore.onConfGraphicValueChange(this.confId, this.confPageSessionService.confSessionId, this.decoration.visualObjectId, (graphic => {
        this.init();
      })).unsubscribeOn(this.unsubscribeSubject)
    else if (this.decoration.displayStyle == GraphicsDisplayStyle.Popup) {
      // make call to server
      let url = this.getURL();

      this.httpService.readContents(url).then(data => {

        this.svg = data;
        this.updateHtml();
        this.emitterService.send(SvgActions.Render, this.config.identifier);
      });
    }
  }

  public updateHtml() {
    if (this.container) {

      if (this.svg) {

        // Append unique id to toplevel and composite graphic to make it function properly. (match IDs like "topLevel", "CompositeGraphic", "PG[0-9]")
        let uniquenessRegex = /(svg|use|symbol) ((xlink:href|id)=\"#?(topLevel|CompositeGraphic|PG\d[^\"]+))/g;
        let uniqueSvg = this.svg.replace(uniquenessRegex, "$&_" + this.decoration.longId /* Use longId to make sure its unique for clones */);
        this.container.nativeElement.innerHTML = uniqueSvg;

      }
      else {
        this.container.nativeElement.innerHTML = "";
      }

    }
  }

  svgControlIconsAction(event): void {
    this.emitterService.send(event, this.config.identifier);
  }
}