import { Inject, Input, SimpleChanges, Component } from "@angular/core";
import { BaseComponent } from "./baseComponent";
import { ConfigurationSessionManager, ConfiguratorStore } from "../configurator/providers";
import { Conf, RequestViews } from "./models";
import { ManagedSubscription } from "../../shared/managedSubscription";
import { ImageSets } from "../../shared/utils/imageSets";

@Component({ template: '' })
export abstract class BaseActionBoxComponent extends BaseComponent {

  @Input()
  public configurationId: number;

  @Input()
  public confSessionId: number;

  @Input()
  public searchSessionId: number;

  @Input()
  public showHeader: boolean = true;

  public imageSet: string;

  public conf: Conf;

  public visible: boolean = false;

  constructor(public configuratorStore: ConfiguratorStore) {
    super();
  }

  ngOnInit() {
    this.imageSet = this.getImageSet(ImageSets.SummaryActionDetail);

    this.initialize();
  }

  public initialize() {
    this.setConf();
    this.setup();
    this.visible = this.shouldBeVisible();
  }
  public setConf(): void {
    if (this.configurationId != null && this.confSessionId != null)
      this.conf = this.configuratorStore.getConf(this.configurationId, this.confSessionId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['configurationId']) {
      // onConfigurationChange will be called directly and do the initialization..
      if (!changes['configurationId'].firstChange)
        this.initialize();
    }
  }

  /**
   *  Sets up the action box with relevant data
   */
  public abstract setup(): void;

  /** Defines if this action box should be visible, 
      eg. by checking if it has any relevant data to display. */
  public abstract shouldBeVisible(): boolean;
}