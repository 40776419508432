<backdrop-container #backdrop *ngIf="visible">
  <side-bar-popup classes="accordion-tabs inline-floated"
                  [width]="'100%'"                  
                  position="right"
                  [okButtonText]="strings.Ok"
                  [cancelButtonText]="strings.Cancel"
                  (onOpenStart)="onOpenStart()"
                  (onClosed)="onClosed()">
    <div card-type="body" class="h-100">
      <separated-tab *ngIf="activeTab"
                     [tab]="activeTab"
                     [showClose]="true"
                     (onClose)="closeSeparatedTab($event);"
                     position="top">
      </separated-tab>
    </div>

  </side-bar-popup>
</backdrop-container>

<absolute-layout direction="topRight"
                 positionX="-5"
                 positionY="320"
                 zIndex="50">
  <button-icon-text *ngFor="let tab of floatedTabs; trackBy: trackTab"
                    [text]="handleTitle(tab)"
                    [tag]="tab"
                    direction="vertical"
                    class="side-bar-opener separated-tab-toggler mr-1"
                    rounded="true"
                    (buttonClick)="toggleVisibility($event)">
  </button-icon-text>

</absolute-layout>