
import { Injectable } from "@angular/core";
import { PanZoomConfig } from "./panZoomConfig";

@Injectable()
export class GraphicsHelper {


  getZoom(panZoom: PanZoomConfig, nativeElement: any): number {

    const containerWidth: number = nativeElement.offsetWidth;
    const containerHeight: number = nativeElement.offsetHeight;

    // Default initial zoom factor if not responsive.
    if (!panZoom.responsive || panZoom.realWidth == 0 || containerWidth == 0)
      return panZoom.initialZoomFactor;

    let factor = containerWidth / panZoom.realWidth;

    if (!isNaN(panZoom.realHeight) && panZoom.realHeight != 0 && containerHeight != 0 && !isNaN(containerHeight) && containerHeight / panZoom.realHeight < factor)
      factor = containerHeight / panZoom.realHeight;

    let level = factor * panZoom.initialZoomFactor;

    return level;
  }

  public getPositionCoordates(panZoom: PanZoomConfig, nativeElement: any) {

    let position = panZoom.initialOrigoPosition;
    let size = this.visibleHeight(nativeElement);

    if (!panZoom.responsive)
      size = nativeElement.getBoundingClientRect() as ClientRect;

    let point = { x: 0, y: 0 }; // Default position, Top left
    switch (position) {
      case 1: // Top center
        point.x = size.width / 2;
        point.y = 0;
        break;
      case 2: // Top right
        point.x = size.width
        point.y = 0;
        break;
      case 3: // Center left
        point.x = 0;
        point.y = size.height / 2;
        break;
      case 4: // Center
        point.x = size.width / 2;
        point.y = size.height / 2;
        break;
      case 5: // Center right
        point.x = size.width;
        point.y = size.height / 2;
        break;
      case 6: // Bottom left
        point.x = 0;
        point.y = size.height;
        break;
      case 7: // Bottom center
        point.x = size.width / 2;
        point.y = size.height;
        break;
      case 8: // Bottom right
        point.x = size.width;
        point.y = size.height;
        break;
    }
    return point;
  }

  visibleHeight(element: any): ClientRect {

    // Working body area height, this area starts below the major header
    let scrollableBodyArea = document.getElementsByClassName('scrollable-view')[0];

    // Normal tab, separated tab or any container height including container's header.
    let contentsOuterContainer = $(element).closest('separated-tab, normal-tabs');

    // graphics size
    let graphicsSize = element.getBoundingClientRect() as ClientRect;

    if (!scrollableBodyArea || !contentsOuterContainer) 
      return graphicsSize;    

    // If the contents exceeds the body area, It means scrollbar is visible.
    let hasScrollbar = contentsOuterContainer.outerHeight() > scrollableBodyArea.scrollHeight;
    if (!hasScrollbar)
      return graphicsSize;    

    /* 
     *             ------------------
     *            |____Top Header___|       ___      __________
     *            | |             | |        |           |
     *            | |             | |        |           |
     *            | |             | |       Body         | 
     *            | |             | |        |           |
     *            | |             | |        |       Tab contents area  
     *            | |             | |        |           |
     *             -| ------------|-        ---          |
     *              |             |                      |
     *              |             |                      |
     *              |             |                      |
     *               -------------                    ----------
     * 
     *  Note: Visible area is the part of tab contents which are only shown in a body. Graphics alignment must be respected within the visible area.
     *  There could be more separated tabs in rows, then visible area for the graphics would be with respect to their tabs container where they belong to.
     * 
     */

    // Available area for graphics, Area above the graphics.Y position is not useful for current graphics.
    let availableHeight: number = scrollableBodyArea.scrollHeight - graphicsSize.top;    
    if (hasScrollbar)
      return <ClientRect>{
        height: availableHeight,
        left: graphicsSize.left,
        top: graphicsSize.top,
        width: graphicsSize.width,
        bottom: graphicsSize.bottom,
        right: graphicsSize.right
      } 
    
    return graphicsSize;
  }

}