import { Inject, Injectable } from "@angular/core"
import { normalize, Schema, schema } from "normalizr";
import * as Immutable from "immutable"

import { BaseEntity } from "../../baseEntity"
import { ModelFactory } from "../modelFactory"
import { Ioc, Register } from "../../../../shared/ioc/iocdecorator"
import { NormalizerService } from "../../../../shared/providers/normalizer"
import { GlobalDataResponse } from "../../models/responses";
import { GlobalDataSchema } from "./globalDataSchema";
import { GlobalSettings, LocalizeData } from "../../models";
import { KeyValue } from "../../models/responses";

@Injectable()
export class GlobalDataNormalizer {

    constructor(
        @Inject(NormalizerService) public normalizerService: NormalizerService,
        @Inject(ModelFactory) public modelFactory: ModelFactory,
        @Inject(GlobalDataSchema) public globalDataSchema: GlobalDataSchema,
    ) { }

  normalizeJson(json: any): GlobalDataResponse {

    let globalDataResponse = new GlobalDataResponse();

    let entities = Immutable.Map<number, BaseEntity>();
    let entityIdsByClassName = Immutable.Map<string, Immutable.List<number>>();

    if (json.entitiesByClassName) {

      for (let key in json.entitiesByClassName) {

        let classEntities = json.entitiesByClassName[key];

        let normalizeOutput = this.normalizerService.normalizeJson(classEntities, this.globalDataSchema.getSchemaArray(key), (className, data) => this.modelFactory.createEntity(className, data));

        entityIdsByClassName = entityIdsByClassName.set(key, Immutable.List<number>(normalizeOutput.resultIds));
        entities = this.mergeEntities(entities, normalizeOutput.entities);

      }
    }

    globalDataResponse = globalDataResponse.setEntityIdsByClassName(entityIdsByClassName);
    globalDataResponse = globalDataResponse.setEntities(entities);

    if (json.globalSettings) {
      globalDataResponse = globalDataResponse.setGlobalSettings(this.modelFactory.createAny(GlobalSettings.name, json.globalSettings) as GlobalSettings);
    }

    if (json.localizeData) {
      globalDataResponse = globalDataResponse.setLocalizeData(this.modelFactory.createAny<LocalizeData>(LocalizeData.name, json.localizeData));
    }

    return globalDataResponse;
  }

  protected mergeEntities(existingEntities: Immutable.Map<number, BaseEntity>, newEntities: Map<number, BaseEntity>) {
    newEntities.forEach((newEntity: BaseEntity, key: number) => {
      existingEntities = existingEntities.set(key, newEntity);
    });

    return existingEntities;
  }
}