import { Component, Input, ViewEncapsulation } from "@angular/core";
import { BaseComponent } from "../../../../../core/pages/shared";
import { Comparison } from "../../../../../core/pages/shared/models";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'comparison-row',
  templateUrl: './comparisonRowComponent.html'  
})
export class ComparisonRowComponent extends BaseComponent {


  @Input()
  comparison: Comparison;

  @Input()
  titleClass: string;

  @Input()
  isRoot: boolean = false;

  @Input()
  valueClass: string;

  
  //ngOnInit() {
    
  //  super.ngOnInit();
  //}

}