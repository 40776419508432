import * as Immutable from "immutable"

import { FilterType } from "../../../../shared/components";
import { ImmutableObject } from "../../../../shared/immutableObject";

export class SearchInfo extends ImmutableObject {

  protected readonly FIELD_ID = "fieldId";
  protected readonly VALUE1 = "value1";
  protected readonly VALUE2 = "value2";
  protected readonly FILTER_TYPE = "filterType";

  get fieldId(): number { return this.getInternalValue<number>(this.FIELD_ID); }
  setFieldId(fieldId: number): this { return this.setInternalValue(this.FIELD_ID, fieldId); }

  get value1(): string { return this.getInternalValue<string>(this.VALUE1); }
  setValue1(value1: string): this { return this.setInternalValue(this.VALUE1, value1); }

  get value2(): string { return this.getInternalValue<string>(this.VALUE2); }
  setValue2(value2: string): this { return this.setInternalValue(this.VALUE2, value2); }

  get filterType(): FilterType { return this.getInternalValue<FilterType>(this.FILTER_TYPE); }
  setFilterType(filterType: FilterType): this { return this.setInternalValue(this.FILTER_TYPE, filterType); }
}

