import { Injectable, Inject } from "@angular/core";

import { AppStore, AppAction, Actions, HttpAction, ActionStatus } from "../state";
import { HttpService } from "../../../shared/providers";
import { AppController } from "./appController";

@Injectable()
export class AppActionCreator {

  constructor(
    public appStore: AppStore,
    public appController: AppController
  ) { }

  dispatchClearClientCache = () => this.appStore.dispatch(this.clearClientCache()) as AppAction<any>;
  clearClientCache(): AppAction<any> {

    return {
      type: Actions.CLEAR_CLIENT_CACHE
    }
  }

  dispatchReloadCaches = (userDataOnly = false) => this.appStore.dispatch(this.reloadCaches(userDataOnly)) as HttpAction<any>;
  reloadCaches(userDataOnly): HttpAction<any> {

    return {
      type: Actions.RELOAD_CACHES,
      executeAsync: (dispatch, state, callback) => {
        return this.appController.reloadCaches(userDataOnly).subscribe((apiResponse) => {

          dispatch({ type: Actions.RELOAD_CACHES_COMPLETED, payload: apiResponse });
          callback(new ActionStatus(true, apiResponse));
        });
      }
    }
  }

}