import { Component, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'expand-collapse',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './expandCollapseComponent.html',
})
export class ExpandCollapseComponent {

  @Input() expanded: boolean;

  @Input() styles: string;

}