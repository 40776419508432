import * as Immutable from "immutable";

import { ImmutableObject } from "../../../../shared/immutableObject";
import { KeyValue } from "../responses";

export class ConfPropertyValue extends ImmutableObject {

  protected readonly SELECTABLE_VALUES = "selectableValues";
  protected readonly REF_ID = "refId";
  protected readonly PROPERTY_NAME = "propertyName";
  protected readonly TITLE = "title";
  protected readonly KEY_AND_VALUE = "keyAndValue";
  protected readonly IS_READ_ONLY = "isReadOnly";
  protected readonly IS_HIDDEN = "isHidden";
  protected readonly VALUE_BY_NUMERIC_FORMAT = "valueByNumericFormat";

  get refId(): number { return this.getInternalValue<number>(this.REF_ID); }
  setRefId(refId: number): this { return this.setInternalValue(this.REF_ID, refId); }

  get selectableValues(): Immutable.List<KeyValue<string, string>> { return this.getInternalValue<Immutable.List<KeyValue<string, string>>>(this.SELECTABLE_VALUES); }
  setSelectableValues(selectableValues: Immutable.List<KeyValue<string, string>>): this { return this.setInternalValue(this.SELECTABLE_VALUES, selectableValues); }

  get propertyName(): string { return this.getInternalValue<string>(this.PROPERTY_NAME); }
  setPropertyName(propertyName: string): this { return this.setInternalValue(this.PROPERTY_NAME, propertyName); }

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get keyAndValue(): KeyValue<string, string> { return this.getInternalValue<KeyValue<string, string>>(this.KEY_AND_VALUE); }
  setKeyAndValue(keyAndValue: KeyValue<string, string>): this { return this.setInternalValue(this.KEY_AND_VALUE, keyAndValue); }

  get isReadOnly(): boolean { return this.getInternalValue<boolean>(this.IS_READ_ONLY); }
  setIsReadOnly(isReadOnly: boolean): this { return this.setInternalValue(this.IS_READ_ONLY, isReadOnly); }

  get isHidden(): boolean { return this.getInternalValue<boolean>(this.IS_HIDDEN); }
  setIsHidden(isHidden: boolean): this { return this.setInternalValue(this.IS_HIDDEN, isHidden); }

  get valueByNumericFormat(): Immutable.Map<string, string> { return this.getInternalValue<Immutable.Map<string, string>>(this.VALUE_BY_NUMERIC_FORMAT); }
  setValueByNumericFormat(valueByNumericFormat: Immutable.Map<string, string>): this { return this.setInternalValue(this.VALUE_BY_NUMERIC_FORMAT, valueByNumericFormat); }
}