import { Component, Input, Output, EventEmitter, SimpleChanges } from "@angular/core";
import { BaseComponent } from "../../../../shared";
import { SearchCriteriaModel, SearchCriteriaValueEventArgs, SearchCriteriaRegion } from "../models";
import { ConfSearchCriteria } from "../../../../shared/models";
import { SearchCriteriaHelper } from "../providers";

@Component({ template: '' })
export abstract class BaseSearchInputComponent extends BaseComponent {

  @Input()
  public criteria: SearchCriteriaModel;

  @Input()
  public width: string;

  constructor(protected searchCriteriaHelper: SearchCriteriaHelper) {
    super();
  }

  protected get region(): SearchCriteriaRegion {
    return this.criteria.selectedAttribute.region;
  }

  @Output()
  onValueChange = new EventEmitter<SearchCriteriaValueEventArgs>();
  
  /** Returns true if the this criteria has a value, otherwise false. */
  public abstract get hasValue(): boolean;

  /** Returns the current user input value. */
  public abstract get value(): any;

  // Reads server value 
  protected get extractValue(): any {

    if (!this.criteria.value || this.criteria.value.size == 0)
      return null;

    return this.criteria.value.map(x => x.value).join(',');

  }

  public notifyValueChange($event) {    
      this.onValueChange.emit(<SearchCriteriaValueEventArgs>{ criteria: this.criteria, value: this.value });
  }    
  
  public get valueAsSearchCriteria(): Array<ConfSearchCriteria> {

    switch (this.region) {

      case SearchCriteriaRegion.ConfProperty:
        {
          return [this.searchCriteriaHelper.createConfSearchProperty(this.criteria.controlId, this.criteria.selectedAttribute.id.toString(), this.value)];
        }

      case SearchCriteriaRegion.Parameter:
        {
          return [this.searchCriteriaHelper.createConfSearchParameter(this.criteria.controlId, +this.criteria.selectedAttribute.id, this.value, null)];
        }

      default:
        return [];
    }
  }

}