import { Component, Input, EventEmitter, Output } from "@angular/core";

@Component({
  selector: 'limited-browser-support',
  templateUrl: './browserSupportComponent.html'
})
export class BrowserSupportComponent {

  @Input()
  public message: string;

  public visible: boolean = true;
    
  @Output()
  okButtonClicked = new EventEmitter();

  removeWarning(event) {
    this.visible = false;
  }

}