import { Component, NgModule, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'floating',
  templateUrl: './floatingComponent.html'
})
export class FloatingComponent {

  @Input()
  floatingPosition: boolean = true;

  public getCss(): string {
    return this.floatingPosition ? 'flex-item-auto' : 'flex-item-full';
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [
    FloatingComponent
  ],
  exports: [
    FloatingComponent
  ]
})
export class FloatingModule { }