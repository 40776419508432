<backdrop-container #backdrop (onClosed)="onBackdropClosed()" >
  <side-bar-popup id="compositePopup"
                  [width]="compositeWidth"
                  [height]="'100%'"
                  classes="composite-popup {{moreStyles}}"
                  [extended]="sidebarExtended"
                  [isSticky]="ui.calcSticky"
                  [okButtonText]="strings.Ok"
                  [cancelButtonText]="strings.Cancel"
                  (onOpenStart)="onOpenStart()"
                  (onClosed)="onClosed()" >
    <div card-type="body" class="h-100">

      <div class="w-100 composite-data p-0 m-0" >
           <composite-header [iconKey]="compositeWidth == '100%' ? 'delete' : (isSafari ? '' : showStickyOption && (ui.calcSticky ? 'sticky' : 'unsticky'))" [title]="strings.Content" (onIconClick)="compositeWidth == '100%' ? closeComposite() : showStickyOption && toggleSticky()"></composite-header>
      </div>      
      <menu-tree-view></menu-tree-view>
    </div>

    <sidebar-extension *ngIf="sidebarExtended" >            
      <composite-menu-controller [configurationId]="activeConfInfoId" [nodeAction]="nodeAction" > </composite-menu-controller>
    </sidebar-extension>

  </side-bar-popup>
</backdrop-container>

<absolute-layout *ngIf="isCompositeVisible"
                 direction="topLeft"
                 positionX="-5"
                 zIndex="2">
  <button-icon class="side-bar-opener"
               icon="tree2"
               iconWidth="28px"
               iconHeight="28px"
               imageSet="primary"
               rounded="true"
               (buttonClick)="toggleVisibility($event)">
  </button-icon>
</absolute-layout>