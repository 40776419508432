import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AccordionOverlayComponent } from "./accordionOverlayComponent";
import { ImageModule } from "../image";
import { AccordionModule } from "../accordion";

export * from "./accordionOverlayComponent";

@NgModule({
  imports: [
    CommonModule,
    ImageModule,
    AccordionModule
  ],
  declarations: [
    AccordionOverlayComponent,
  ],
  exports: [
    AccordionOverlayComponent,    
  ]
})
export class AccordionOverlayModule {

}