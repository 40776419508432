import { Injectable, Inject } from "@angular/core";
import * as Immutable from "immutable";
import { GlobalDataActionCreator } from "./globalDataActionCreator";
import { AppStore, StoreResponse, HttpAction, RequestStatus } from "../../state";
import { ManagedSubscription, SubscriptionOptions } from "../../../../shared/managedSubscription";
import { ManagedSubject } from "../../../../shared/managedSubject";
import { AppStoreSubscriptionManager } from "../appStoreSubscriptionManager";
import { ValueSubscriptionManager } from "../valueSubscriptionManager";
import {
  GlobalSettings, WebSettings, GlobalSettingsCommand,
  LocalizeCommand, ConfResponseFormat, RequestViews, GlobalEntitiesCommand, LocalizeData
} from "../../models";
import { WebSettingsResponse, UISettings } from "../../models/responses";
import { BaseEntity } from "../../baseEntity";
import { BaseStore } from "../../state/baseStore";
import { GlobalDataRequest } from "../../models/requests/globalDataRequest";
import { ModelFactory } from "../modelFactory";
import { GlobalDataState } from "../../state/shared/globalDataState";

@Injectable()
export class GlobalDataStore extends BaseStore {

  constructor(
    public appStore: AppStore,
    public globalDataActionCreator: GlobalDataActionCreator,
    public appStoreSubscriptionManager: AppStoreSubscriptionManager,
    public valueSubscriptionManager: ValueSubscriptionManager,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
  ) {
    super(appStore, appStoreSubscriptionManager, modelFactory);
  }

  public getGlobalData(): GlobalDataState {
    return this.appStore.getState().globalData;
  }

  public get globalSettings(): GlobalSettings {
    return this.getGlobalData().globalSettings;
  }

  public getConfResponseFormat(requestClientType: RequestViews, isPartialRequest: boolean = true): ConfResponseFormat {

    let webSettings = this.getGlobalData().webSettings;

    switch (requestClientType) {
      case RequestViews.Editor:
        return webSettings.editorConfResponseFormat;
      case RequestViews.Summary:
        return webSettings.summaryConfResponseFormat;
      case RequestViews.Start:
        {
          if (isPartialRequest)
            return webSettings.startPartialConfResponseFormat;

         return webSettings.startConfResponseFormat;
        }
      case RequestViews.Search:
        return webSettings.searchConfResponseFormat;        
    }

    throw "ConfResponseFormat not implemented for " + requestClientType;
  }

  public createGlobalRequestModel(): GlobalDataRequest {
    return this.modelFactory.createRequestOrCommand(GlobalDataRequest.name) as GlobalDataRequest;
  }
    
  public getEntities<T extends BaseEntity>(className: string, forceReload?: boolean): ManagedSubject<StoreResponse<Immutable.OrderedMap<number, T>>> {
    let response = new StoreResponse<Immutable.Map<number, T>>();

    if (!forceReload && this.getGlobalData().entityIdsByClassName && this.getGlobalData().entityIdsByClassName.has(className)) {
      response.data = this.getEntitiesByClassNameInternal(className);
      return this.valueSubscriptionManager.createSubject(response, true);
    }

    let model = this.createGlobalRequestModel();
    model.entities = this.modelFactory.createRequestOrCommand<GlobalEntitiesCommand>(GlobalEntitiesCommand.name, { classNames: [className] });
    let action = this.globalDataActionCreator.dispatchFetchEntities(model);

    // Subscribe to store and return the results on request complete.
    return this.createAction(action, () => {
      response.data = this.getEntitiesByClassNameInternal(className);
      return response;
    });
  }

  protected getEntitiesByClassNameInternal<T extends BaseEntity>(className: string) {

    let ids = this.getGlobalData().entityIdsByClassName.get(className);
    let orderedMap = Immutable.OrderedMap<number, T>();

    // ids can be null, return empty array if no data found.
    if (!ids)
      return orderedMap;

    ids.forEach((value, key) => {
      orderedMap = orderedMap.set(value, this.getGlobalData().entities.get(value) as T);
    })

    return orderedMap;
  }

  public getWebSettings(reload?: boolean): ManagedSubject<StoreResponse<WebSettings>> {
    // Create request object
    let response: StoreResponse<WebSettings> = new StoreResponse<WebSettings>();

    if (!reload && this.getGlobalData().webSettings) {
      response.data = this.getGlobalData().webSettings;
      return this.valueSubscriptionManager.createSubject(response, true);
    }

    let action = this.globalDataActionCreator.dispatchRequestWebSettings();
    // Subscribe to store and return the results on request complete.    
    return this.createAction(action, () => {
      response.data = this.getGlobalData().webSettings;
      return response;
    });
  }

  public getUISettings(reload?: boolean): ManagedSubject<StoreResponse<UISettings>> {
    //call http service
    // Create request object
    let response: StoreResponse<UISettings> = new StoreResponse<UISettings>();

    if (!reload && this.getGlobalData().uiSettings) {
      response.data = this.getGlobalData().uiSettings;
      return this.valueSubscriptionManager.createSubject(response, true);
    }

    let action = this.globalDataActionCreator.dispatchRequestUISettings();
    // Subscribe to store and return the results on request complete.    
    return this.createAction(action, () => {
      response.data = this.getGlobalData().uiSettings;
      return response;
    });
  }

  public getGlobalSettings(reload?: boolean): ManagedSubject<StoreResponse<GlobalSettings>> {
    // Create request object
    let response: StoreResponse<GlobalSettings> = new StoreResponse<GlobalSettings>();

    if (!reload && this.getGlobalData().globalSettings) {
      response.data = this.getGlobalData().globalSettings;
      return this.valueSubscriptionManager.createSubject(response, true);
    }

    let model = this.createGlobalRequestModel();
    model.globalSettings = this.modelFactory.createRequestOrCommand<GlobalSettingsCommand>(GlobalSettingsCommand.name, { get: true });
    let action = this.globalDataActionCreator.dispatchRequestGlobalSettings(model);

    // Subscribe to store and return the results on request complete.    
    return this.createAction(action, () => {
      response.data = this.getGlobalData().globalSettings;
      return response;
    })
  }

  public getLocalizeData(locale?:string, reload?: boolean): ManagedSubject<StoreResponse<LocalizeData>> {
    // Create request object
    let response: StoreResponse<LocalizeData> = new StoreResponse<LocalizeData>();

    if (!reload && this.getGlobalData().localizeData) {
      response.data = this.getGlobalData().localizeData;
      return this.valueSubscriptionManager.createSubject(response, true);
    }

    let localeArg = locale ? locale : "";

    let model = this.createGlobalRequestModel();
    model.localize = this.modelFactory.createRequestOrCommand<LocalizeCommand>(LocalizeCommand.name, { languageCode: localeArg });
    let action = this.globalDataActionCreator.dispatchRequestLocalizeData(model);

    // Subscribe to store and return the results on request complete.    
    return this.createAction(action, () => {
      response.data = this.getGlobalData().localizeData;
      return response;
    })
  }
}