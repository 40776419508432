import { Component, Input } from "@angular/core";
import { BaseComponent } from "../..";
import { StateModel } from "./statesActionBoxComponent";

@Component({
  selector: 'state-item',
  templateUrl: './stateItemComponent.html'
})
export class StateItemComponent extends BaseComponent {

  @Input()
  state: StateModel;

  ngOnInit() {
  }
}
