import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { RouteRedirector } from '../../shared/providers';
import { ComparisonResult } from '../../shared/state';
import { ComparisonDataStore } from './providers/comparisonDataStore';
import { ComparisonHelper } from './providers/comparisonHelper';

@Injectable()
export class ComparisonResultsResolver implements Resolve<ComparisonResult> {

  //public isValidSession: boolean = false;

  constructor(
    @Inject(ComparisonDataStore) public store: ComparisonDataStore,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    @Inject(ComparisonHelper) public helper: ComparisonHelper    
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<ComparisonResult> {
    
    return new Promise<ComparisonResult>(resolver => {

      let confIds: Array<number>;
      let sessionId: number = route.params.sessionid ? Number(route.params.sessionid) : null;
      let path: string = route.params.path;

      if (route.params.ids)
        confIds = this.helper.confIdsFromComparisonId(route.params.ids);

      if (this.store.hasComparisonResult(confIds, sessionId, path))
      {
        let cachedData = this.store.getComparisonSession(path, sessionId);
        if (cachedData.detail) {
          resolver(cachedData);
          return;
        }
      }

      this.store.getComparisonResults(confIds, sessionId, path).subscribe(storeResponse => {

        resolver(storeResponse.data);

      });

    });
  }
}