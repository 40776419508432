import { InputViewModel } from "..";

export class RangeInputViewModel extends InputViewModel {

  placeHolder1: string;
  placeHolder2: string;  

  value1: string;
  value2: string;

  /**
   *  Represents the selected operator.
   *  If '<' or '>' operator is selected then must read the value from <value1> and <value2> properties. But if '=' operator selected then read from <value>.
   */
  Operator: RangeOperator;

}

export enum RangeOperator {

  Equal = '=',
  Greater = '>',
  GreaterOrEqual = '>=',
  Less = '<',
  LessOrEqual = '<=',
  Range = '<->'

}