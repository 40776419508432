import { Inject, Component, Input, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild } from "@angular/core";
import { PriceSettingMessage } from "../../../shared/models/responses/messages";
import { Conf, PriceListCategory } from "../../../shared/models";
import { VisualObjectViewModelFactory } from "../../parameters/shared";
import { ConfigurationSessionManager } from "../../providers/configurationSessionManager";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import { PopupService, InputViewModel, Positions, MessageBoxComponent, MessageBoxConfig } from "../../../../shared/components";
import { ConfiguratorStore, ConfPageSessionService, PopupIdentifiers } from "../../providers";
import { GlobalDataStore } from "../../../shared/providers/globalData";

@Component({
  selector: 'price-setting-popup',
  templateUrl: './priceSettingPopupComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceSettingPopupComponent extends AbstractPopupComponent {

  public width: string;
  public height: string;

  public plcGroupView: InputViewModel;
  public plcItemsView: InputViewModel[] = [];

  public plGroupView: InputViewModel;
  public plItemsView: InputViewModel[] = [];

  public currencyGroupView: InputViewModel;
  public currencyItemsView: InputViewModel[] = [];

  public cachedSelectedPriceListByCategory: Map<number, number> = new Map<number, number>();

  public message: PriceSettingMessage;
  public conf: Conf;

  @ViewChild(MessageBoxComponent)
  public confirmEffectiveChangeMessageBox: MessageBoxComponent;

  constructor(
    @Inject(VisualObjectViewModelFactory) public viewFactory: VisualObjectViewModelFactory,
    @Inject(ConfiguratorStore) public configuratorStore: ConfiguratorStore,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(PopupService) public popupService: PopupService,
    public cdr: ChangeDetectorRef
  ) {
    super(cdr, popupService);
  }

  ngOnInit() {
    this.width = this.uiSettings.configurator.decoration.price.minWidth;
    this.height = this.uiSettings.configurator.decoration.price.minHeight;
    this.id = PopupIdentifiers.PriceSetting;

    super.ngOnInit();
  }

  public open(tag: any) {
    this.show(tag);
  }

  show(message: PriceSettingMessage): void {
    this.message = message;

    // Get active configuration
    this.conf = this.configuratorStore.getConf(message.configurationId, this.confPageSessionService.confSessionId);

    this.createCurrencyViewModels();

    // Create price list category and price list input view models.
    this.createCategoryAndPriceViewModels();
    this.showInternal();
  }

  createCategoryAndPriceViewModels(): void {
    // Create views for price list category
    this.plcGroupView = new InputViewModel();
    this.plcGroupView.title = this.strings.PriceList;
    this.plcGroupView.icon = "pricetag";
    this.plcGroupView.iconWidth = "24px";
    this.plcGroupView.iconHeight = "24px";
    this.plcGroupView.labelPosition = Positions.Above;
    this.plcGroupView.readOnly = !this.conf.authorization.canChangePriceListCategory;

    // Empty the list.
    this.plcItemsView = [];

    this.globalDataStore.getEntities<PriceListCategory>(PriceListCategory.name).subscribe((storeResponse) => {

      let priceListCategories = storeResponse.data;

      // Children
      this.message.categories.forEach(x => {

        let priceListCategory = priceListCategories.get(+x.key);
        if (!priceListCategory)
          return;

        let itemView = new InputViewModel(priceListCategory.title);
        itemView.id = priceListCategory.longId.toString();
        itemView.title = priceListCategory.title;
        this.plcItemsView.push(itemView);
      });

      if (this.conf.priceValue) {
        // TODO: This is the temporary solution We need more refactoring on views.
        let plcView = this.viewFactory.setValue(this.plcItemsView, String(this.conf.priceValue.priceListCategoryId), String(this.conf.priceValue.priceListCategoryId));
        if (plcView) {
          this.plcGroupView.formattedValue = plcView.title.toString();
          this.plcGroupView.value = plcView.id.toString();
        }
      }

      // Create price list input view models.
      this.createPriceListViewModels();

      this.plcGroupView = { ...this.plcGroupView };      
      this.cdr.markForCheck();

    });
  }

  createPriceListViewModels(): void {
    // Create views for the price list.
    this.plGroupView = new InputViewModel();
    this.plGroupView.title = this.strings.EffectivityPeriod;
    this.plGroupView.icon = "calendar";
    this.plGroupView.iconWidth = "24px";
    this.plGroupView.iconHeight = "24px";
    this.plGroupView.labelPosition = Positions.Above;

    this.refreshPriceListItems();
    this.cdr.markForCheck();
  }

  refreshPriceListItems() {

    // Empty the list.
    this.plItemsView = [];

    if (!this.plcGroupView || !this.plcGroupView.value)
      return;

    this.message.prices.get(this.plcGroupView.value).forEach(x => {

      let itemView = new InputViewModel(x.value);
      itemView.id = x.key.toString();
      itemView.title = x.value;
      this.plItemsView.push(itemView);
    });

    if (this.conf.priceValue) {
      let plView = this.viewFactory.setValue(this.plItemsView, String(this.conf.priceValue.priceListId), String(this.conf.priceValue.priceListId));
      if (plView) {
        this.plGroupView.formattedValue = plView.title.toString();
        this.plGroupView.value = plView.id.toString();
      }
    }

    this.plGroupView = { ...this.plGroupView };
    this.cdr.markForCheck();
  }

  createCurrencyViewModels(): void {
    // Create views for currency
    this.currencyGroupView = new InputViewModel();
    this.currencyGroupView.title = this.strings.Currency;
    this.currencyGroupView.icon = "dollareuroexchange";
    this.currencyGroupView.iconWidth = "24px";
    this.currencyGroupView.iconHeight = "24px";
    this.currencyGroupView.labelPosition = Positions.Above;
    this.currencyGroupView.readOnly = !this.conf.authorization.canChangeCurrency;

    // Empty the list
    this.currencyItemsView = [];

    // Children
    this.message.currencies.forEach(x => {
      let itemView = new InputViewModel(x.text);
      itemView.title = x.text;
      itemView.id = x.longId.toString();
      this.currencyItemsView.push(itemView);
    });

    if (this.conf.priceValue) {
      // TODO: This is the temporary solution We need more refactoring on views.
      let currencyView = this.viewFactory.setValue(this.currencyItemsView, String(this.conf.priceValue.currencyId), String(this.conf.priceValue.currencyId));
      if (currencyView) {
        this.currencyGroupView.formattedValue = currencyView.title.toString();
        this.currencyGroupView.value = currencyView.id.toString();
      }
    }

    this.currencyGroupView = { ...this.currencyGroupView };
    this.cdr.markForCheck();
  }

  okClick(event: any): void {

    let priceListCategoryId: number = null;
    let priceListId: number = null;
    let currencyId: number = null;

    if (this.conf.authorization.canChangePriceListCategory)
      priceListCategoryId = this.plcGroupView ? parseInt(this.plcGroupView.value) : null;

    if (this.conf.authorization.canChangePriceListCategory)
      priceListId = this.plGroupView ? parseInt(this.plGroupView.value) : null;

    if (this.conf.authorization.canChangeCurrency)
      currencyId = this.currencyGroupView ? parseInt(this.currencyGroupView.value) : null;

    let requestType = this.confPageSessionService.confUIStore.PageStore.getActiveClientType();
    this.configuratorStore.setPriceSetting(this.conf.longId, this.confPageSessionService.confSessionId, priceListCategoryId, priceListId, currencyId, requestType);
  }

  onPriceListCategoryChange(event: any) {

    let selectedValue = event.value;

    let categoryKV = this.message.categories.find(x => x.key == selectedValue);
    if (categoryKV) {

      let currencyView = this.viewFactory.setValue(this.currencyItemsView, String(categoryKV.value), String(categoryKV.value));
      if (currencyView) {
        this.currencyGroupView.formattedValue = currencyView.title.toString();
        this.currencyGroupView.value = currencyView.id.toString();

        this.refreshPriceListItems();
        
        if (this.plItemsView && this.plItemsView.length > 0) {          
          this.updatePriceListUI();
        }
      }

      //  Update the currency in UI
      this.currencyGroupView = { ...this.currencyGroupView };
      this.cdr.markForCheck();
    }
  }

  onPriceListChange(event: any) {

    let execute = () => {
      let selectedValue = event.value;
      this.setPriceListValueByCategory(+this.plcGroupView.value, +selectedValue);
    }

    let confInfo = this.configuratorStore.getConfInfo(this.conf.longId, this.confPageSessionService.confSessionId);
    if (confInfo.rootId == confInfo.longId) {
      execute();
    }
    else {

      let isLeafNode = confInfo?.children.size == 0;
      let isIntermediateNode = confInfo?.children.size > 0 && confInfo.parentId;

      let info: MessageBoxConfig<[number, number]> = <MessageBoxConfig<[number, number]>>{
        headerText: '',
        caption: null,
        description: isLeafNode ? this.strings.EffectivityMsgWhenChangingLeafLevel : (isIntermediateNode ? this.strings.EffectivityMsgWhenChangingIntermediateLevel : ''),
        icon: null
      };

      this.confirmEffectiveChangeMessageBox.show(info, () => {
        execute();
      });

    }
  }

  changeEffective(event: any) {

  }

  setPriceListValueByCategory(priceListCategoryId: number, priceListId: number) 
  {
    // Store in cache to retreive the previous selection.
    this.cachedSelectedPriceListByCategory.set(priceListCategoryId, priceListId);
  }

  updatePriceListUI() {

    let activePlc = this.plcGroupView.value;

    // Default value would be first index.
    let selectedPl: number = this.plItemsView.length > 0 ? +this.plItemsView[0].id : null;

    // If previous selection is remembered then restore it
    if (this.cachedSelectedPriceListByCategory.has(+activePlc))
      selectedPl = this.cachedSelectedPriceListByCategory.get(+activePlc);

    else this.setPriceListValueByCategory(+this.plcGroupView.value, +selectedPl);
    
    let plItem = this.plItemsView.find(x => +x.id == selectedPl);
    if (plItem) {
      this.plGroupView.formattedValue = plItem.title;
      this.plGroupView.value = plItem.id.toString();
    }

  }

}