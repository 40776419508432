<div class="row h-100 no-gutters tabs-manager">

  <!-- Area for accordion side bar  -->
  <div class="col-auto accordion-col" *ngIf="accordionTabsLeft.length > 0">
    <tabs-sidebar [containerId]="ACCORDION_TABS_LEFT_CONTAINER_ID"
                  [tabs]="accordionTabsLeft"
                  [selectedTabId]="selectedAccordionTabLeftId"></tabs-sidebar>
  </div>

  <!-- Area for separated & normal tabs  -->
  <div class="col tabs-col scrollable order-1" >

    <!-- Top Separated tabs -->
    <div *ngIf="topRegionVisible"
         class="row no-gutters tabs-row"
         [class.last-child-auto-size]="!equalColWidthRegionTop"
         [ngStyle]="topRegionExpanded && fillStyle">

      <!-- Top tabs ->
                1. Height won't be applied If separated tab is expanded to full size.
                2. If no auto-width column exists in top-region then make the last column auto-width.
                3. If all columns contain no width, then make them equal in width.
      -->
      <ng-container *ngIf="shouldShowSeparatedTabs">
        <separated-tab *ngFor="let t of topTabs; trackBy: trackTab"
                       [tab]="t"
                       [containerId]="t.longId"
                       [minHeight]="!topRegionExpanded && topTabsRegionHeight"
                       [style.max-width.px]="t.separatedWidth > 0 && t.separatedWidth"
                       [scrollbar]="t.separatedHeight > 0"
                       class="col">
        </separated-tab>
      </ng-container>
    </div>

    <!-- Left, right and normal tabs -->
    <div *ngIf="middleRegionVisible"
         class="row no-gutters tabs-row"
         [class.last-child-auto-size]="!equalColWidthRegionMiddle"
         [ngStyle]="middleRegionExpanded && fillStyle">

      <!--Left tabs-->
      <ng-container *ngIf="shouldShowSeparatedTabs">
        <separated-tab *ngFor="let t of leftTabs; trackBy: trackTab"
                       [tab]="t"
                       [containerId]="t.longId"
                       [style.max-width.px]="t.separatedWidth == 0 ? '' : t.separatedWidth"
                       class="col">
        </separated-tab>
      </ng-container>

      <normal-tabs *ngIf="normalTabs.length > 0"
                   [containerId]="NORMAL_TABS_CONTAINER_ID"
                   [tabs]="normalTabs"
                   [mobileMode]="shouldMergeAccordionTabs"
                   class="col">
      </normal-tabs>

      <!--Right tabs-->
      <ng-container *ngIf="shouldShowSeparatedTabs">
        <separated-tab *ngFor="let t of rightTabs; trackBy: trackTab"
                       [tab]="t"
                       [containerId]="t.longId"
                       [style.max-width.px]="t.separatedWidth == 0 ? '' : t.separatedWidth"
                       class="col">
        </separated-tab>
      </ng-container>
    </div>

    <!-- Bottom tabs ->
             1. Height won't be applied If separated tab is expanded to full size.
             2. If no auto-width column exists in bottom-region then make the last column auto-width.
             3. If all columns contain no width, then make them equal in width.
    -->
    <div *ngIf="bottomRegionVisible"
         class="row no-gutters tabs-row"
         [class.last-child-auto-size]="!equalColWidthRegionBottom"
         [ngStyle]="bottomRegionExpanded && fillStyle">
      <!--Bottom tabs-->
      <separated-tab *ngFor="let t of bottomTabs; trackBy: trackTab"
                     [tab]="t"
                     [containerId]="t.longId"
                     [minHeight]="!bottomRegionExpanded && bottomTabsRegionHeight"
                     [style.max-width.px]="t.separatedWidth > 0 && t.separatedWidth"
                     class="col"
                     [scrollbar]="t.separatedHeight > 0">
      </separated-tab>
    </div>

  </div>

  <!-- Area for accordion side bar  -->
  <div class="col-auto accordion-col order-12" *ngIf="accordionTabsRight.length > 0">
    <tabs-sidebar [containerId]="ACCORDION_TABS_RIGHT_CONTAINER_ID"
                  [tabs]="accordionTabsRight"
                  [selectedTabId]="selectedAccordionTabRightId"></tabs-sidebar>
  </div>

</div>

<floated-separated-tabs *ngIf="floatedTabs.length > 0" [floatedTabs]="floatedTabs"></floated-separated-tabs>