import { Component, ChangeDetectorRef } from "@angular/core";
import { PopupService } from "../../../../shared/components";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import { PopupIdentifiers } from "../../providers";
import { SafeHtmlPipe } from "../../../../shared/utils/pipes";

@Component({
  selector: 'code-popup',
  templateUrl: './codePopupComponent.html'
})
export class CodePopupComponent extends AbstractPopupComponent {

  public id: string = PopupIdentifiers.Code;

  public title: string;
  public code: string;

  constructor(
    public cdr: ChangeDetectorRef, popupService: PopupService
  ) {
    super(cdr, popupService);
  }


  public open(tag?: any) {

    this.show(tag.code, tag.title);

  }

  show(code: string, title: string): void {
    this.title = title;
    this.code = code;

    this.showInternal();
  }

  
}