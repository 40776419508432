import { Inject, Component, Input, ViewChild, SimpleChanges } from "@angular/core";
import { AppStore } from "../../../shared/state";
import { AbstractImmutableValueMessage } from "../../../shared/models/responses/messages/abstractImmutableValueMessage";
import { ParamValue, Param } from "../../../shared/models";
import { ParamInfo, RuleInfo } from "../../../shared/models/responses";
import { BaseComponent } from "../../../shared";

@Component({ template: '' })
export abstract class BaseImmutableValuePopupComponent extends BaseComponent {

  public message: AbstractImmutableValueMessage;
  public headerText: string;
  public caption: string;
  public icon: string;
  public description: string;
  public popupIdentifier: string;

}