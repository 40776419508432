import { ErrorHandler, Injectable, Inject } from '@angular/core';
import { HttpErrorResponse } from "@angular/common/http";
import { Subject, ReplaySubject, Subscription } from "rxjs";
import * as Immutable from "immutable";

import { Exception } from "../../../shared/exception"
import { HttpException } from "../../../shared/httpException"
import { ApiException, ApiResponse } from "../models"

@Injectable()
export class ExceptionHandler extends ErrorHandler {

  public exceptionSubject: Subject<Exception> = new Subject<Exception>();
  public exceptionReplaySubject: ReplaySubject<Exception> = new ReplaySubject<Exception>();

  public httpExceptionSubject: Subject<HttpException> = new Subject<HttpException>();
  public httpExceptionReplaySubject: ReplaySubject<HttpException> = new ReplaySubject<HttpException>();
  
  constructor() {
    super();

    this.exceptionSubject.subscribe(this.exceptionReplaySubject);
    this.httpExceptionSubject.subscribe(this.httpExceptionReplaySubject);
  }

  public handleError(error) {
    this.handleException(error);
  }
  
  public handleException(orignalException: any) {
  
    if (orignalException instanceof HttpErrorResponse) {
      let apiExceptions = this.getApiExceptions(orignalException);
      if (apiExceptions && apiExceptions.size > 0) {
        apiExceptions.forEach(exception => this.handleExceptionInternal(exception));
      }
      else {
        // Handle HttpErrorResponse message for display
        this.handleExceptionInternal(orignalException);
      }

      let httpException = new HttpException();
      httpException.errorResponse = orignalException;
      httpException.exceptions = apiExceptions ? apiExceptions.toArray() : [];
      httpException.isHandled = true;

      // Handle HttpException for redirects
      this.handleHttpException(httpException);
    }
    else if (orignalException instanceof HttpException) {
      
      if (orignalException.isHandled)
        return;

      orignalException.isHandled = true;

      // Handle ApiExceptions
      if (orignalException.exceptions) {
        orignalException.exceptions.forEach(exception => this.handleExceptionInternal(exception));
      }
      // Handle HttpErrorResponse message for display
      else if (orignalException.errorResponse) {
        this.handleExceptionInternal(orignalException.errorResponse);
      }

      // Handle HttpException for redirects
      this.handleHttpException(orignalException);
    } 
    else {
      this.handleExceptionInternal(orignalException);
    }

    // Log the original exception
    this.logToConsole(orignalException);
  }

  public onException(observer: (exception: Exception) => void, listenToPastExceptions: boolean): Subscription {

    if (listenToPastExceptions)
      return this.exceptionReplaySubject.subscribe((nextException) => observer(nextException));

    return this.exceptionSubject.subscribe((nextException) => observer(nextException));
  }

  public onHttpException(observer: (exception: HttpException) => void, listenToPastExceptions: boolean): Subscription {

    if (listenToPastExceptions)
      return this.httpExceptionReplaySubject.subscribe((nextException) => observer(nextException));

    return this.httpExceptionSubject.subscribe((nextException) => observer(nextException));
  }

  protected handleExceptionInternal(exception: any): void {
    let wrappedException = this.wrapAsException(exception);

    this.exceptionSubject.next(wrappedException);    
  }

  protected getApiExceptions(errorResponse: HttpErrorResponse): Immutable.List<ApiException> {
    if (errorResponse.error && errorResponse.error.className != ApiResponse.name)
      return null;

    let apiResult = new ApiResponse(errorResponse.error);
    if (apiResult && apiResult.exceptions)
      return apiResult.exceptions;

    return null;
  }

  protected handleHttpException(exception: HttpException) {
    this.httpExceptionSubject.next(exception);
  }

  protected wrapAsException(originalError: any): Exception {

    if (originalError instanceof Exception || originalError instanceof ApiException)
      return originalError;

    let exception: Exception = new Exception();

    if (originalError instanceof HttpErrorResponse) {

      // If status = 0 and message contains "Http failure" error then it is a network error
      if (originalError.status == 0 && originalError.message && originalError.message.toLowerCase().indexOf("http failure") > -1) {
        exception.message = `${originalError.status} : Network error`;
      }
      else {
        exception.message = `${originalError.status} : ${originalError.statusText}`;
      }

      exception.url = originalError.url;
      exception.detail = originalError.message;
    }   
    else if (typeof originalError === "string" || typeof originalError === "number")
    {
      exception.message = "" + originalError;
    }
    else {
      exception.message = 'An unknown error occured during the request. See detail tab.';
      exception.detail = `${originalError.name ? originalError.name + " - " : ""} ${exception.message}`;
    }

    exception.stackTrace = originalError.stack ? originalError.stack : (originalError.stackTrace ? originalError.stackTrace : "");
    exception.originalError = originalError;

    return exception;
  }

  protected logToConsole(exception) {
    console.error(exception);
  }
}