<ng-container *ngIf="propertyModel.confPropertyValue != null">
  <ng-container [ngSwitch]="true">

    <dropdown *ngSwitchCase="propertyModel.propertyDecorationItem.propertyType == 'RefToObject'"
              showEmptyValue="true"
              [view]="viewModel"
              [itemsView]="itemsModel"
              [style.float]="viewModel"
              (onValueChange)="onValueChange($event)">
    </dropdown>

    <datepicker 
                *ngSwitchCase="propertyModel.propertyDecorationItem.propertyType == 'DateString' || propertyModel.propertyDecorationItem.propertyType ==  'Date' || propertyModel.propertyDecorationItem.propertyType ==  'DateTime'" 
                [view]="viewModel" 
                [weekNumbersEnabled]="weekNumbersEnabled"
                [dateFormat]="dateFormat"                
                [errors]="errors" 
                (onValueChange)="onValueChange($event)">
    </datepicker>

    <check-box *ngSwitchCase="propertyModel.propertyDecorationItem.propertyType == 'Boolean'" [view]="viewModel" (onValueChange)="onValueChange($event)"></check-box>
    <text-box *ngSwitchDefault [view]="viewModel" [errors]="errors" [rows]="propertyModel.propertyDecorationItem.rowCount" (onValueChange)="onValueChange($event)"></text-box>

  </ng-container>
</ng-container>