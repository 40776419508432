import { Component, Input, EventEmitter, ViewChild, ElementRef, Output, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import * as Immutable from "immutable"
import { MultiSelectItem, ItemStateType } from "./multiSelectModels";
import { DragScrollComponent } from "../dragScroll";
import { HostListener } from "@angular/core";
import { MultiSelectEventService, MultiSelectAction, MultiSelectEvent } from "./multiSelectEventService";
import { Subscription } from "rxjs";
import { SelectChangeEventArgs } from "./selectChangeEventArgs";

@Component({  
  selector: "multi-select-group",
  templateUrl: './multiSelectGroupComponent.html',
  providers: [MultiSelectEventService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectGroupComponent {

  @Input()
  width: string = "320px";

  @Input()
  emptyTitle: string;

  @Input()
  checkedIcon: string;

  @Input()
  uncheckedIcon: string;

  @Input()
  disabled: boolean = false;

  @Input()
  enableInputItemIcon: boolean = false; 

  @Input()
  iconImageSet: string;

  /**
   * @deprecated The method should not be used anymore. Use onSelectChange instead.
   */
  @Output()
  onSelect = new EventEmitter<Array<MultiSelectItem>>();

  @Output()
  onSelectChange = new EventEmitter<SelectChangeEventArgs>();

  showItemIcons: boolean = false;

  @Input()
  items: Immutable.List<MultiSelectItem> = Immutable.List<MultiSelectItem>();
  
  selectedItems: Immutable.List<MultiSelectItem> = Immutable.List<MultiSelectItem>();
  show: boolean = false;

  @Input()
  triState: boolean = false;

  subscription: Subscription;

  constructor(private elementRef: ElementRef, private eventService: MultiSelectEventService, private cd: ChangeDetectorRef) { }  

  @ViewChild('dragScroll', { read: DragScrollComponent }) ds: DragScrollComponent;

  moveLeft() {
    this.ds.moveLeft();
  }

  moveRight() {
    this.ds.moveRight();
  }

  ngOnInit() {
    this.showItemIcons = this.checkedIcon != null || this.uncheckedIcon != null;    

      this.subscription = this.eventService.get().subscribe(info => {

        switch (info.actionType) {

          case MultiSelectAction.DropDownItemClick:

            this.updateSelectedItems(info.item);
            break;

          case MultiSelectAction.DropdownOpen:

            // TODO..
            break;

        }

      });

  }

  removeItem(item: MultiSelectItem): void {

    if (this.disabled)
      return;

    item.state = this.triState ? ItemStateType.Inderminate : ItemStateType.Unchecked;
    this.updateItemIcon(item);
    this.updateSelectedItems(item);
    this.eventService.sendMessage(<MultiSelectEvent>{ item: item, actionType: MultiSelectAction.DropDownItemRemoved });

  }

  openIt() {

    if (this.disabled)
      return;

    // Parent box
    let jParentContentsBox = $(event.target).closest('drag-scroll');

    // Inner contents box
    let innerContentsBox = jParentContentsBox.find('.inner-contents');

    // Populate the menu only if mouse click is performed outside the contents.
    if (innerContentsBox.length > 0 && this.selectedItems.size > 0) {

      // In case of overflowing the inner contents, get the parent container size.
      let rect = innerContentsBox.width() < jParentContentsBox.width() ? innerContentsBox[0].getBoundingClientRect() : (<any>jParentContentsBox[0]).getBoundingClientRect();
      let mousePosX = (<any>event).clientX;
      let mousePosY = (<any>event).clientY;

      // If mouse clicked position is outside the inner contents then show the popup menu.
      if (!this.between(mousePosX, mousePosY, rect)) {
        this.show = !this.show;
      }
    }
    else this.show = !this.show;

  }

  between(mouseX: number, mouseY: number, rect: any): boolean {
    return mouseX > rect.x && mouseY > rect.y && mouseX < rect.x + rect.width && mouseY < rect.y + rect.height;
  }
  
  updateSelectedItems(targetItem: MultiSelectItem): void {

    if (this.disabled)
      return;

    this.selectedItems = Immutable.List<MultiSelectItem>(this.getSelectedItems(this.items.toArray()));

    if (this.ds)
      this.ds.update();

    this.onSelect.emit(this.selectedItems.toArray());

    if (targetItem) {
      let args = new SelectChangeEventArgs();
      args.selectedItems = this.selectedItems.toArray();
      args.targetItem = targetItem;
      this.onSelectChange.emit(args);
    }

  }

  updateItemIcon(item: MultiSelectItem): void {

    if (item.state == ItemStateType.Checked) {
      item.icon = this.checkedIcon;
    }

    else if (item.state == ItemStateType.Unchecked) {
      item.icon = this.uncheckedIcon;
    }

    else item.icon = '';
  }

  protected getSelectedItems(items: Array<MultiSelectItem>): Array<MultiSelectItem> {

    let selectedItems: Array<MultiSelectItem> = new Array<MultiSelectItem>();
    for (let item of items) {

      if (this.includeAsSelected(item))
        selectedItems.push(item);

      if (item.children)
        selectedItems.push(...this.getSelectedItems(item.children));
    }

    return selectedItems;
  }

  protected includeAsSelected(item: MultiSelectItem): boolean {

    if (this.triState)
      return item.state == ItemStateType.Checked || item.state == ItemStateType.Unchecked;

    else
      return item.state == ItemStateType.Checked;

  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['items']) {
      //this.selectedItems = this.items.filter(x => this.includeAsSelected(x)).toArray();
      this.updateSelectedItems(null);
    }

  }

  @HostListener('document:click', ['$event'])
  onClick(event) {

    // If dropdown menu is not visible then don't continue.
    if (!this.show)
      return;
    
    this.show = this.contains(event.clientX, event.clientY);
    this.cd.markForCheck();
    
  }

  contains(x, y) {

    if (this.containsElement(x, y, 'multi-select-group'))
      return true;

    return this.containsElement(x, y, 'multi-select-menu');
  }

  containsElement(x, y, element) {

    let menuDOM = this.elementRef.nativeElement.getElementsByClassName(element);
    if (menuDOM.lenth == 0)
      return false;

    let menuRect = menuDOM[0].getBoundingClientRect();

    return menuRect.x <= x && x <= menuRect.x + menuRect.width &&
      menuRect.y <= y && y <= menuRect.y + menuRect.height;
  }

  ngOnDestroy() {

    if (this.subscription)
      this.subscription.unsubscribe();

  }

  @HostListener('window:mouseup', ['$event'])
  mouseUp(event)
  {

    if (this.show && !this.contains(event.clientX, event.clientY)) {

      this.show = false;
      this.cd.markForCheck();

    } 
  }
  
}