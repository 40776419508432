<div class="h-100 row no-gutters" *ngIf="confPageSession.dataLoaded">
  <!-- Tabs -->
  <tabs-manager class="col h-100" id="tabContainer"></tabs-manager>

  <!-- NOTE! Draggable popups must be defined in configurator level  -->
  <price-list-report-popup></price-list-report-popup>
  <price-setting-popup></price-setting-popup>
  <rule-information-popup></rule-information-popup>

  <!-- TODO: Move 'bom-composite-popup' to configurator so that It could be accessible for both summary and configurator. -->
  <bom-composite-popup></bom-composite-popup>  

</div>