import * as Immutable from "immutable";

import { SearchSessionData } from "./searchSessionData";
import { ImmutableObject } from "../../../../shared/immutableObject";
import { ConfigurationSearch, AbstractSearchDataMessage } from "../../models";

export class SearchDataState extends ImmutableObject {

  public readonly DATA_BY_SESSION_ID = "dataBySessionId";
  public readonly SAVED_SEARCHES = "savedSearches";
  public readonly MESSAGES = "messages";

  get dataBySessionId(): Immutable.Map<number, SearchSessionData> { return this.getInternalValue<Immutable.Map<number, SearchSessionData>>(this.DATA_BY_SESSION_ID); }
  setDataBySessionId(dataBySessionId: Immutable.Map<number, SearchSessionData>): this { return this.setInternalValue(this.DATA_BY_SESSION_ID, dataBySessionId); }

  get savedSearches(): Immutable.List<ConfigurationSearch> { return this.getInternalValue<Immutable.List<ConfigurationSearch>>(this.SAVED_SEARCHES); }
  setSavedSearches(savedSearches: Immutable.List<ConfigurationSearch>): this { return this.setInternalValue(this.SAVED_SEARCHES, savedSearches); }

  get messages(): Immutable.Map<string, Immutable.List<AbstractSearchDataMessage>> { return this.getInternalValue<Immutable.Map<string, Immutable.List<AbstractSearchDataMessage>>>(this.MESSAGES); }
  setMessages(messages: Immutable.Map<string, Immutable.List<AbstractSearchDataMessage>>): this { return this.setInternalValue(this.MESSAGES, messages); }
}