import * as Immutable from "immutable";
import { Param } from "./param";

export class LookupParam extends Param 
{  
  protected readonly DISPLAY_STYLE = "displayStyle";
  protected readonly ORIENTATION = "orientation";
  protected readonly PARAM_VALUE_ICON_WIDTH = "paramValueIconWidth";
  protected readonly PARAM_VALUE_ICON_HEIGHT = "paramValueIconHeight";
  protected readonly DISPLAY_AS_RADIO = "displayAsRadio";
  protected readonly HIDE_VALUES_IF_DISABLED = "hideValuesIfDisabled";
  protected readonly SHOW_HELP_IMAGE_OF_SELECTED_VALUE = "showHelpImageOfSelectedValue";
  protected readonly SELECTED_VALUE_IMAGE_WIDTH = "selectedValueImageWidth";
  protected readonly SELECTED_VALUE_IMAGE_HEIGHT = "selectedValueImageHeight";
  protected readonly PLACEHOLDER = "placeholder";

  get displayStyle(): string { return this.getInternalValue<string>(this.DISPLAY_STYLE); }
  setDisplayStyle(displayStyle: string): this { return this.setInternalValue(this.DISPLAY_STYLE, displayStyle); }

  get orientation(): string { return this.getInternalValue<string>(this.ORIENTATION); }
  setOrientation(orientation: string): this { return this.setInternalValue(this.ORIENTATION, orientation); }

  get paramValueIconWidth(): number { return this.getInternalValue<number>(this.PARAM_VALUE_ICON_WIDTH); }
  setParamValueIconWidth(paramValueIconWidth: number): this { return this.setInternalValue(this.PARAM_VALUE_ICON_WIDTH, paramValueIconWidth); }

  get paramValueIconHeight(): number { return this.getInternalValue<number>(this.PARAM_VALUE_ICON_HEIGHT); }
  setParamValueIconHeight(paramValueIconHeight: number): this { return this.setInternalValue(this.PARAM_VALUE_ICON_HEIGHT, paramValueIconHeight); }

  get displayAsRadio(): boolean { return this.getInternalValue<boolean>(this.DISPLAY_AS_RADIO); }
  setDisplayAsRadio(displayAsRadio: boolean): this { return this.setInternalValue(this.DISPLAY_AS_RADIO, displayAsRadio); }

  get hideValuesIfDisabled(): boolean { return this.getInternalValue<boolean>(this.HIDE_VALUES_IF_DISABLED); }
  setHideValuesIfDisabled(hideValuesIfDisabled: boolean): this { return this.setInternalValue(this.HIDE_VALUES_IF_DISABLED, hideValuesIfDisabled); }

  get showHelpImageOfSelectedValue(): number { return this.getInternalValue<number>(this.SHOW_HELP_IMAGE_OF_SELECTED_VALUE); }
  setShowHelpImageOfSelectedValue(showHelpImageOfSelectedValue: number): this { return this.setInternalValue(this.SHOW_HELP_IMAGE_OF_SELECTED_VALUE, showHelpImageOfSelectedValue); }

  get selectedValueImageWidth(): number { return this.getInternalValue<number>(this.SELECTED_VALUE_IMAGE_WIDTH); }
  setSelectedValueImageWidth(selectedValueImageWidth: number): this { return this.setInternalValue(this.SELECTED_VALUE_IMAGE_WIDTH, selectedValueImageWidth); }

  get selectedValueImageHeight(): number { return this.getInternalValue<number>(this.SELECTED_VALUE_IMAGE_HEIGHT); }
  setSelectedValueImageHeight(selectedValueImageHeight: number): this { return this.setInternalValue(this.SELECTED_VALUE_IMAGE_HEIGHT, selectedValueImageHeight); }

  get placeholder(): string { return this.getInternalValue<string>(this.PLACEHOLDER); }
  setPlaceholder(placeholder: string): this { return this.setInternalValue(this.PLACEHOLDER, placeholder); }

}