import { Component, Input, Output, EventEmitter, ViewContainerRef, ComponentFactoryResolver, ViewChild, AfterContentInit } from "@angular/core";
import { InputViewModel } from "../shared";

@Component({
  selector: 'help-link',
  templateUrl: './helpLinkComponent.html'
})
export class HelpLinkComponent {

  @Output()
  onHelpClick = new EventEmitter<InputViewModel>();

  @Input()
  view: InputViewModel;

  @Input()
  separator: boolean = true;

  @Input()
  styles: string = ""; // Extra input styles.

  imageKey: string;

  @Input()
  imageSet: string = "primary-dark";

  infoClasses: string;

  ngOnInit() {
    this.infoClasses = this.separator == true ? 'input-info' : 'input-info no-border';
    this.infoClasses = `${this.infoClasses} ${this.styles}`; 
  }
}