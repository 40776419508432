import { Injectable, Inject } from "@angular/core";
import * as Immutable from "immutable";

import { AppAction } from "../shared/appAction";
import { ConfUIData } from "../shared/confUIData";
import { PageActions } from "./pageActions";
import { ConfiguratorPageUIData } from "../shared/configuratorPageUIData";

@Injectable()
export class ConfUIReducer {


  public getReducer() {

    return (stateById: Immutable.Map<number, ConfUIData> = Immutable.Map<number, ConfUIData>(), action: AppAction<any>): Immutable.Map<number, ConfUIData> => {

      switch (action.type) {
        
        //case PageActions.SEPARATED_UI_TAB_CHANGE:

        //  let sepratedUI: SeparatedTabUIData = action.payload as SeparatedTabUIData;

        //  // Add confUIData if not exists -> Need more work, It must be generated in beginning.
        //  //stateById = this.addConfUIDataIfNotExists(stateById, sepratedUI.confId);

        //  let confUI: ConfUIData = stateById.get(+sepratedUI.confId)
        //  //let tabById: Immutable.Map<number, SeparatedTabUIData> = confUI.separatedTabById.set(sepratedUI.tabId, sepratedUI);
        //  //stateById = stateById.set(confUI.confId, confUI.setSeparatedTabById(tabById));
        //  confUI = confUI.setHideSeparatedTabs(!sepratedUI.calcSticky);
        //  stateById = stateById.set(confUI.confId, confUI);
          
        //  break;

        case PageActions.UPDATE_CONF_UI_DATA:
          {
            let data: ConfUIData = action.payload as ConfUIData;
            stateById = stateById.set(data.confId, data);
          }
          break;

      }

      return stateById;
    }
  }

  addConfUIDataIfNotExists(stateById: Immutable.Map<number, ConfUIData> = Immutable.Map<number, ConfUIData>(), confId: number): Immutable.Map<number, ConfUIData> {
    
    if (confId && !stateById.has(+confId)) {
      let confUI = new ConfUIData();
      confUI.setConfId(confId);
      stateById = stateById.set(confId, confUI);
    }

    return stateById;
  }
}