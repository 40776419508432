import { Inject, Component, Input, ViewChild, SimpleChanges, ChangeDetectionStrategy } from "@angular/core";

import { RuleInfo, ParamInfo } from "../../../shared/models/responses";
import { ParamValue, ConfInfo, BoolParam } from "../../../shared/models";
import { BaseComponent } from "../../../shared";

@Component({
  selector: 'rule-params-info',
  templateUrl: './ruleParamsInfoComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RuleParamsInfoComponent extends BaseComponent {
  
  @Input()
  public ruleInfo: RuleInfo;

  @Input()
  public configurationId: number;  

  @Input()
  public popupId: string;  

  @Input()
  public supportNavigation: boolean = true;

  public ruleIcon: string;
  public paramIcons: Map<number, string>;
  public paramValueIcons: Map<number, string>;
  public paramValueImageSets: Map<number, string>;

  ngOnInit() {
    super.ngOnInit();

    //set the icons
    this.ruleIcon = this.getRuleIcon(this.ruleInfo);

    this.paramIcons = new Map();
    this.paramValueIcons = new Map();
    this.paramValueImageSets = new Map();

    this.ruleInfo.parameterInfos.forEach(pInfo => {
      this.paramIcons.set(pInfo.longId, this.getParameterIcon(pInfo));

      if (pInfo.parameterType == BoolParam.name) {
        this.paramValueIcons.set(pInfo.longId, pInfo.value == null ? "" : pInfo.value == this.strings.Yes ? "checkmark" : pInfo.value == this.strings.No ? "delete" : "indeterminate");
        this.paramValueImageSets.set(pInfo.longId, pInfo.value == null ? "" : pInfo.value == this.strings.Yes ? "green" : pInfo.value == this.strings.No ? "red" : "primary");
      }

      pInfo.paramValues.forEach(b => {
        this.paramValueIcons.set(b.longId, b.value == null ? "" : b.value == this.strings.Yes ? "checkmark" : b.value == this.strings.No ? "delete" : "indeterminate");
        this.paramValueImageSets.set(b.longId, b.value == null ? "" : b.value == this.strings.Yes ? "green" : b.value == this.strings.No ? "red" : "primary");
      });
    });
  }

  getDisplayableParamValues(paramValues: ParamValue[]): ParamValue[] {
    let filteredParamValues: ParamValue[] = [];
    paramValues.forEach((a) => {
      if (a.obsolete)
        return;
      filteredParamValues.push(a);
    });

    return filteredParamValues;
  }

  public getParameterIcon(pInfo: ParamInfo): string {
    return pInfo.parameterType.toLowerCase();
  }

  public getRuleIcon(ruleInfo: RuleInfo): string {
    return ruleInfo.ruleType.toLowerCase();
  }

}