import { Component, Inject } from "@angular/core";
import { ConfHeaderValue, HeaderDecoration } from "../../../shared/models";
import { ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { DecorationComponent } from "../../shared/decorationComponent";
import { VisualObjectHelper } from "../../parameters/shared";

@Component({
  selector: 'header-decoration',
  templateUrl: './headerDecorationComponent.html'
})
export class HeaderDecorationComponent extends DecorationComponent<HeaderDecoration> {

  classes: string;
  confHeaderValue: ConfHeaderValue;

  constructor(
    public confStore: ConfiguratorStore,
    public confPageSessionService: ConfPageSessionService,
    @Inject(VisualObjectHelper) public visualObjectHelper: VisualObjectHelper
  ) {
    super(confStore, confPageSessionService, visualObjectHelper);
  }

  ngOnInit(): void {
    this.confHeaderValue = this.confStore.getConfDataEntity<ConfHeaderValue>(this.configuration.longId, this.confSessionId, this.decoration.longId);    
    this.update();
    super.ngOnInit();
  }

  public listenChanges(): void {
    this.confStore.onConfigurationValueChange(this.configurationId, this.confSessionId, this.decoration.longId, (value: ConfHeaderValue): void => {
      this.confHeaderValue = value;
      this.update();
    }).unsubscribeOn(this.unsubscribeSubject);
  }

  public update() {
    if (this.decoration.minorHeader)
      this.classes = "minor-header";
    else
      this.classes = "major-header";
  }
}