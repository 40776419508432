<backdrop-container *ngIf="ui.visible else accordionOpenerButton">
  <side-bar-popup [width]="accordionTabsWidth"
                  [forceWidth]="true"
                  classes="accordion-tabs {{moreStyles}}"
                  position="right"
                  [okButtonText]="strings.Ok"
                  [cancelButtonText]="strings.Cancel"
                  (onOpenStart)="onOpenStart()"
                  (onClosed)="onClosed()">
    <div card-type="body" class="h-100">
      <nested-accordion-tabs *ngIf="tabs.length > 0"
                         [tabs]="tabs"
                         [selectedTabId]="selectedTabId"
                         [configurationId]="configurationId"
                         [containerId]="containerId"
                         ></nested-accordion-tabs>
    </div>
  </side-bar-popup>
</backdrop-container>

<ng-template #accordionOpenerButton>
  <absolute-layout *ngIf="accordionOpener"
                   direction="topRight"
                   positionX="-5"
                   positionY="120"
                   zIndex="1000">
    <button-icon class="side-bar-opener"
                 icon="menu"
                 imageSet="primary"
                 rounded="true"
                 (buttonClick)="toggleVisibility($event)">
    </button-icon>
  </absolute-layout>
</ng-template>