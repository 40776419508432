import { Inject, Component, Input } from "@angular/core";
import { BaseComponent } from "../../shared";
import { VisualObjectNavigatorService } from "./visualObjectNavigatorService";
import { ParamInfo } from "../../shared/models/responses";
import { VisualObjectVisibilityService } from "./visualObjectVisibilityService";
import { Conf, VisualObject, RequestViews } from "../../shared/models";
import { ConfiguratorStore, ConfPageSessionService, ConfRouteParams, PopupIdentifiers } from "../providers";
import { ConfVisiblityService } from "./confVisibilityService";
import { ProductDataStore } from "../../shared/providers/productData";
import { RouteRedirector } from "../../shared/providers";

@Component({
  selector: 'visual-object-link',
  templateUrl: './visualObjectLinkComponent.html'
})
export class VisualObjectLinkComponent extends BaseComponent {

  // Input is either confId + visualObjectId, or confId+paramInfo

  @Input()
  configurationId: number;

  @Input()
  visualObjectInTabId: number;

  @Input()
  paramInfo: ParamInfo;

  @Input()
  popupId: string;

  @Input()
  classes: string = "";

  public canNavigateToVisualObject = false;

  constructor(@Inject(VisualObjectNavigatorService) public visualObjectNavigatorService: VisualObjectNavigatorService,
    @Inject(VisualObjectVisibilityService) public visualObjectVisibilityService: VisualObjectVisibilityService,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(ConfVisiblityService) public confVisiblityService: ConfVisiblityService,    
    @Inject(ProductDataStore) public productStore: ProductDataStore,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector    
    )
  {
    super();
  }

  public ngOnInit() {
    this.updateCanNavigateToVisualObject();
  }

  public updateCanNavigateToVisualObject(): void {

    let conf: Conf = this.confStore.getConf(this.configurationId, this.storeSession.confSessionId);
    if (conf)
      this.updateInternalCanNavigateToVisualObject(conf);

    else {

      this.confStore.loadConf(this.configurationId, this.storeSession.confSessionId, RequestViews.Editor).then(x => {

        conf = this.confStore.getConf(this.configurationId, this.storeSession.confSessionId);
        this.updateInternalCanNavigateToVisualObject(conf);

      });

    }
    
  }

  updateInternalCanNavigateToVisualObject(conf: Conf): void {

    // If visual object is in accordion and sub configuration detail is shown over it then remove the sub configuration detail and show the requested configuration parameters in accordion.
    if (this.storeSession.showChildrenInAccordionTabs && this.storeSession.activeRoute == RequestViews.Editor) {

      // If configurationId is root configuration id and visual object is located in accordion and accordion for root configuration is not visible
      // because of accordion child configuration visiblility, then remove the child configuration visiblity and make the root configuration accordion visible
      // so that navigation could be made for mandatory popup to accordion's visual object.
      let id = this.visualObjectInTabId || (this.paramInfo ? this.paramInfo.visualObjectInTabId : null);
      if (!id)
        return;

      const isParamInAccordion = this.confVisiblityService.isParamInAccordion(id);
      const isSubConfVisible = this.confVisiblityService.isSubConfVisible();
      if (this.confVisiblityService.isRoot(this.configurationId)) {

        if (isParamInAccordion && isSubConfVisible)
          this.emitterService.send<number>(PopupIdentifiers.AccordionCompositeHideDetail, this.configurationId);
      }

      // Parameter belongs to child configuration.
      else {

        // Is sub configuration visible?
        let subConfId: number = +this.routeRedirector.searchUrlQueryParam(ConfRouteParams.SUB_CONF_ID);

        // Show child configuration if it is not visible.
        if (subConfId && subConfId != conf.longId)
          this.emitterService.send<any>(PopupIdentifiers.AccordionCompositeShowDetail, { confId: conf.longId, reload: true });

      }

      this.canNavigateToVisualObject = true;
      return;
    }

    if (this.paramInfo)
      this.canNavigateToVisualObject = this.visualObjectVisibilityService.isParameterVisible(conf, this.paramInfo.visualObjectInTabId, this.paramInfo.parameterType);
    else if (this.visualObjectInTabId)
      this.canNavigateToVisualObject = this.visualObjectVisibilityService.isParameterVisible(conf, this.visualObjectInTabId);
    else
      this.canNavigateToVisualObject = false;


  }

  public onVisualObjectClick() {

    // If we show child configurations in accordion then 
    if (this.storeSession.showChildrenInAccordionTabs && this.storeSession.activeRoute == RequestViews.Editor)
      this.updateCanNavigateToVisualObject();

    if (!this.canNavigateToVisualObject) 
      return;
    
    let id = this.visualObjectInTabId || (this.paramInfo ? this.paramInfo.visualObjectInTabId : null);
    if (!id) {
      return;
    }

    this.visualObjectNavigatorService.navigate(this.configurationId, id, this.popupId);
  }

}