import { Component, Input, Host, OnInit, Inject, ContentChild, ElementRef, ChangeDetectorRef, Output, AfterViewInit, AfterContentInit, EventEmitter } from "@angular/core";
import { AbstractDropdownItem } from "../abstractDropdownItem";
import { StandardDropdownGroupComponent } from './standardDropdownGroupComponent';

@Component({
  selector: "standard-dropdown-item",
  templateUrl: './standardDropdownItemComponent.html',
})
export class StandardDropdownItemComponent extends AbstractDropdownItem implements OnInit, AfterViewInit, AfterContentInit {

  constructor(@Host() @Inject(StandardDropdownGroupComponent) public dropdownGroup: StandardDropdownGroupComponent, public cdr: ChangeDetectorRef) {
    super(dropdownGroup, cdr);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngAfterContentInit() {
    super.ngAfterContentInit();
  }

}