import { Inject, Component, Input } from "@angular/core";
import { BaseCodeDisplayStyleComponent } from "./baseCodeDisplayStyleComponent";
import { ProductDataStore } from "../../../providers/productData";
import { ConfPageSessionService } from "../../../../configurator/providers";
import { PageStore } from "../../../providers/page";

@Component({
  selector: 'code-render-style',
  templateUrl: './codeRenderStyleComponent.html'
})
export class CodeRenderStyleComponent extends BaseCodeDisplayStyleComponent {
  
  constructor(public productStore: ProductDataStore, public pageStore: PageStore, public confPageSession: ConfPageSessionService) {
    super(productStore, pageStore, confPageSession);
  }

  ngOnInit() {

  }

}