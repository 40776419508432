import { Component, Input, Optional, ChangeDetectorRef, Output, EventEmitter, SimpleChanges, HostListener } from "@angular/core";
import { MessageBoxConfig } from "./messageBoxInfo";
import { PopupService } from "../popup/popupService";
import { BaseComponent } from "../../../pages/shared/baseComponent";

@Component({
  selector: 'message-box',
  templateUrl: './messageBoxComponent.html'
})
export class MessageBoxComponent extends BaseComponent  {

  @Input()
  public message: MessageBoxConfig<any>;

  @Input()
  public visible: boolean = false;

  @Input()
  public showBodyContents: boolean = false;

  @Input()
  public id = "message-box";

  @Input()
  public title: string;

  @Input()
  public okButtonText: string;

  @Input()
  public cancelButtonText: string;

  @Input()
  public cancelButton: boolean = true;

  @Input() public showCloseButton: boolean = true;

  @Input() public showOkButton: boolean = true;

  @Input()
  public height: string = '300px';

  @Input()
  public width: string = '500px';

  @Output()
  okClick = new EventEmitter();

  okClickedClosure: any;
  cancelClickedClosure: any;


  constructor(  
    public cdr: ChangeDetectorRef,
    @Optional() public popupService?: PopupService
  ) {
    super();
  }  

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['visible'] && this.visible && this.message) {
      this.show(this.message);
    }

  }

  public show(message: MessageBoxConfig<any>, okClickedClosure?: any, cancelClickedClosure?: any): void {

    this.visible = true;    
    this.message = message;
           
      setTimeout(() => {
        this.popupService.open(this.id);

      }, 100);    

  }

  public onOkClick($event): void {

    if (this.okClickedClosure)
      this.okClickedClosure();

    this.okClick.emit(this.message.tag);
    this.message = null;
    this.visible = false;

  }

  public onCancelClick($event): void {

    if (this.cancelClickedClosure)
      this.cancelClickedClosure();

    this.message = null;
    this.visible = false;

  }

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.popupService?.close(this.id);
      event.stopPropagation();
    }
  }

}