import { Injectable, Inject } from "@angular/core";
import * as Immutable from "immutable";
import { Subject, Subscription, BehaviorSubject, ReplaySubject } from "rxjs";

import { BaseStore } from "../../state/baseStore";
import { Product, Tab, Assortment, ConfInfo, State, GlobalEntitiesCommand, ProductCommand, TabOptions, VisualObjectOptions, AssortmentCommand, PushMessage, ServerPushMessage } from "../../models";
import { BaseEntity } from "../../baseEntity";
import { StoreResponse } from "../../state/storeResponse";
import { PushMessageActionCreator } from "./pushMessageActionCreator";
import { AppStoreSubscriptionManager } from "../appStoreSubscriptionManager";
import { ValueSubscriptionManager } from "../valueSubscriptionManager";
import { ManagedSubject } from "../../../../shared/managedSubject";
import { GlobalDataStore } from "../globalData/globalDataStore";
import { ModelFactory } from "../modelFactory";
import { AppStore } from "../../state";
import { ClientPushMessage } from "../../models/requests/clientPushMessage";
import { PushMessageSelection } from "./pushMessageSelection";

@Injectable()
export class PushMessageStore extends BaseStore {
    
  constructor(
    @Inject(AppStore) protected appStore: AppStore,
    @Inject(GlobalDataStore) protected globalDataStore: GlobalDataStore,
    @Inject(PushMessageActionCreator) protected pushMessageActionCreator: PushMessageActionCreator,
    @Inject(AppStoreSubscriptionManager) protected appStoreSubscriptionManager: AppStoreSubscriptionManager,
    @Inject(ValueSubscriptionManager) protected valueSubscriptionManager: ValueSubscriptionManager,
    @Inject(ModelFactory) protected modelFactory: ModelFactory,
  ) {
    super(appStore, appStoreSubscriptionManager, modelFactory);
  }

  /**
   * Fetches products without tabs.
   * @param productId
   */
  setData(data: any): ManagedSubject<StoreResponse<ServerPushMessage>> {
    // let model = this.createProductRequestModel();
    //model.product = this.modelFactory.createRequestOrCommand<ProductCommand>(ProductCommand.name, { ids: [productId] });
    let response: StoreResponse<ServerPushMessage> = new StoreResponse<ServerPushMessage>();

    let action = this.pushMessageActionCreator.dispatchFetchProducts(data);

    // Create request object
    return this.createAction(action, actionInfo => {
      let serverPushMessage = actionInfo.payload.data as ServerPushMessage;

      if (serverPushMessage)
        response.data = serverPushMessage;

      return response;
    });

    //return this.createAction(action, () => {
    //  data = action.payload;
    //  return response;
    //});
  }

  public getValuesById(sessionId: number): Immutable.Map<string, string> {
    return this.appStore.getState().pushMessages.valuesById.get(sessionId);
  }

  public setValue(pushMessageSelection: PushMessageSelection) {

    this.pushMessageActionCreator.dispatchValueChanged(pushMessageSelection);
  }

  public createRequest(): ClientPushMessage {
    return this.modelFactory.createRequestOrCommand<ClientPushMessage>(ClientPushMessage.name);
  }
}