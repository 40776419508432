<li class="list-group-item {{extraStyles}}" [class.highlight-selected]="highlightSelected && selected" [class.menu-opened]="menuActive || addActive" tabindex="0">
  <div class="row h-100">

    <div class="col">
      <span class="row h-100">
        <span class="col-auto px-0 align-middle {{iconMoreStyles}}">
          <span *ngFor="let _ of [].constructor(indentationSpanCount <= 0 ? 1 : indentationSpanCount)" class="node-indentation">
          </span>

          <span class="expand-collapse-arrow {{expandable ? 'add-padding py-3' : 'col-auto'}}" (click)="expandable && onExpandCollapseClick($event)">
            <co-image *ngIf="expandable && showExpandCollapseIcon" type="svg" key="{{expanded ? 'arrowdown' : 'arrowright'}}" imageSet="{{imageSet}}" [width]="'16'" [height]="'16'"></co-image>
          </span>
        </span>

        <span class="col align-middle px-0 hide-overflow" (click)="onNodeClick($event)">
          <!--TODO: Fix width height through input data-->
          <span class="px-0">
            <co-image type="svg" imageSet="{{imageSet}}" [key]="node.iconKey" [isVault]="node.vaultIcon" [width]="'32'" [height]="'32'"></co-image>
          </span>
          <span title="{{node.title}}" class="title w-100" [class.selected]="selected">
            {{node.title}}
          </span>
        </span>
      </span>
    </div>

    <div *ngIf="node.canAddChild && menuSupport" class="col-auto add-button menu-item align-middle mobile-inline-flex" role="button" [class.menu-active]="addActive" (click)="onAddClick()">
      <co-image type="svg" imageSet="{{imageSet}}" key="add" [width]="'16'" [height]="'16'"></co-image>
    </div>

    <div *ngIf="menuSupport" class="col-auto menu-item align-middle mobile-inline-flex" [class.menu-active]="menuActive" role="button" (click)="onMenuClick()">
      <co-image type="svg" imageSet="{{imageSet}}" key="menu-2" [width]="'16'" [height]="'16'"></co-image>
    </div>

  </div>
</li>
<ng-template [ngIf]="expanded && children.length > 0">
  <tree-node *ngFor="let child of children"
             [node]="child"
             [dataProvider]="dataProvider"
             [imageSet]="imageSet"
             class="child"></tree-node>
</ng-template>
