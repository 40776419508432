<div id="start" class="pl-sm-5">

  <popper-content #contextMenu
                  width="350">
    <div class="menu-contents">
      <configuration-context-menu></configuration-context-menu>
    </div>
  </popper-content>

  <gridview classes="w-100 pt-2" *ngIf="allLoaded" >
    <gridview-row>
      <columntemplate size="auto">
        <get-started class="d-block mb-2"></get-started>
      </columntemplate>
    </gridview-row>
    <gridview-row classes="main-content" *ngIf="canSearch">      
      <columntemplate *ngIf="hasUserConfs || hasWorkGroupConfs || hasUnsavedConfs">
        <div class="d-block">
          <h1 *ngIf="!showRecentConfsDropdown" class="text-uppercase start-header">{{recentTitle}}</h1>

          <!--|| (!hasUserConfs && !hasUnsavedConfs) || !hasWorkGroupConfs; else startDropdown-->
          
          <div *ngIf="showRecentConfsDropdown && recentsView.length > 0" class="start-dropdown">
            <dropdown [view]="recentView"
                      [itemsView]="recentsView"
                      (onValueChange)="updateRecents()"
                      collapseIcon="collapse"
                      expandIcon="expand">
            </dropdown>
          </div>          
        </div>

        <div class="prevent-scroll-single-col" auto-scrollbar>
          <ng-container *ngIf="includeWorkgroup; else userRecent">
            <recents class="mb-2 workgroup-recents"
                     [searchSessionId]="workGroupSearchSessionId"
                     [configurationsByInterval]="workGroupConfsByInterval"
                     [popper]="contextMenu"></recents>
          </ng-container>
          <ng-template #userRecent>
            <recents *ngIf="hasUnsavedConfs" class="mb-2 unsaved"
                     [searchSessionId]="unsavedSearchSessionId"
                     [configurationsByInterval]="unsavedConfsByInterval"
                     [popper]="contextMenu"></recents>

            <recents *ngIf="hasUserConfs" class="mb-2 my-recents"
                     [searchSessionId]="userSearchSessionId"
                     [configurationsByInterval]="userConfsByInterval"
                     [popper]="contextMenu"></recents>
          </ng-template>
        </div>

      </columntemplate>
      <!--Welcome message column if no user confs-->
      <columntemplate *ngIf="!hasUserConfs && !hasWorkGroupConfs">
        
        <start-welcome class="mb-2"
                       [configurationsByInterval]="userConfsByInterval">
        </start-welcome>

      </columntemplate>
      <!--end-->
      <columntemplate>
        <div class="prevent-scroll-single-col" auto-scrollbar>
          <system-information [systemInformations]="systemInformations" *ngIf="systemInformations.size > 0"></system-information>
          <ng-container *ngIf="showWorkGroupRecentSeparately; else quickSearches">

            <ng-container *ngIf="hasWorkGroupConfs">
              <div class="d-flex">
                <h1 class="text-uppercase start-header">{{this.strings.WorkgroupRecent}}</h1>
              </div>
              <div>
                <recents class="mb-2"
                         [searchSessionId]="workGroupSearchSessionId"
                         [configurationsByInterval]="workGroupConfsByInterval"
                         [popper]="contextMenu"></recents>
              </div>
            </ng-container>

          </ng-container>
          <ng-template #quickSearches>

        <quick-search *ngIf="configurationSearches && configurationSearches.size > 0"
                      [configurationSearches]="configurationSearches"></quick-search>
      </ng-template>

        </div>
      </columntemplate>
      <!--<columntemplate *ngIf="largeUp else newsOpenerButton">
    <news></news>
  </columntemplate>-->
    </gridview-row>
  </gridview>
</div>

<!--<backdrop-container>
    <side-bar-popup title="{{strings.News}}"
                    classes="accordion-tabs news-popup"
                    position="right"
                    [showHeader]="true"
                    [okButtonText]="strings.Ok"
                    [cancelButtonText]="strings.Cancel"
                    (onClosed)="onClosed()">
      <div card-type="body" class="m-4">
        <news></news>
        </div>
    </side-bar-popup>
  </backdrop-container>

  <ng-template #newsOpenerButton>
    <absolute-layout direction="topRight"
                     positionX="-5"
                     positionY="320"
                     zIndex="0">
      <button-icon-text text="{{strings.News}}"
                        direction="vertical"
                        class="side-bar-opener"
                        rounded="true"
                        (buttonClick)="openNews($event)">
      </button-icon-text>
    </absolute-layout>
  </ng-template>
</div>-->
