import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ImageModule } from "../image";
import { PopoverComponent } from "./popoverComponent";
import { PopperContentComponent } from "./popperContentComponent";
import { PopperDirective } from "./popperDirective";
import { PopoverService } from "./popoverService";

export * from "./popoverInfo";
export * from "./popoverService";
export * from "./popoverComponent";
export * from "./popperContentComponent";

@NgModule({
  imports: [
    CommonModule,
    ImageModule
  ],
  declarations: [
    PopoverComponent,
    PopperContentComponent,
    PopperDirective
  ],
  providers: [
  ],
  exports: [
    PopoverComponent,
    PopperContentComponent,
    PopperDirective,
    //PopoverService
  ]
})
export class PopoverModule {
}