import { Injectable, Inject } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { Reducer, combineReducers, createStore, Store, Dispatch, compose, Action } from 'redux';
import { Middleware, MiddlewareAPI, applyMiddleware, Unsubscribe } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import { AppState, HttpAction, AppAction, ActionStatus, AppAsyncAction, Actions } from "./shared";
import { ActionInfo } from "./actionInfo";
import { AppReducer } from "./appReducer";
import { RequestStatus } from "./shared/requestStatus";


class IdGenerator {
  static Id = 1;
}

@Injectable()
export class AppStore {

  public store: Store<AppState>;
  protected subject: Subject<void> = new Subject<void>();
  protected processingActionIds: number[] = [];

  constructor(
    @Inject(AppReducer) public appReducer: AppReducer
  ) {

    const rootReducer: Reducer<AppState> = this.appReducer.getReducer();

    // Create default AppState
    let defaultState = new AppState();

    // Uncomment to debug redux in Chrome
    const composeEnhancers = window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] || compose;
    this.store = createStore(rootReducer, defaultState, composeEnhancers(
      applyMiddleware(this.createLoggerMiddleware(), this.createAsyncMiddleware())
    ));

    //this.store = createStore(rootReducer, defaultState, composeWithDevTools(applyMiddleware(this.createLoggerMiddleware(), this.createAsyncMiddleware())));
    //this.store = createStore(rootReducer, defaultState, applyMiddleware(this.createLoggerMiddleware(), this.createAsyncMiddleware()));
  }

  public createAsyncMiddleware(): Middleware {
    return (api2) => (next2) => (action2) => {

      // TODO: Typescript 2.4.1 and Redux typings are incompatible
      let api = (api2 as any) as MiddlewareAPI<any>;
      let next = next2 as Dispatch<any>;
      let action = action2 as AppAction<any>;

      if (!action.id) {

        // Generate new action id.        
        action.id = IdGenerator.Id++;
        this.processingActionIds.push(action.id);

        // Update the page state for action if it async
        if ((<HttpAction<any>>action).executeAsync) {
          api.dispatch({ type: Actions.ACTION_STARTED, id: -1, payload: new ActionStatus(false, null, RequestStatus.PENDING, action) });
        }
      }

      if ((<HttpAction<any>>action).executeAsync) {

        //this.internalChange = true;
        next(action);

        (<HttpAction<any>>action).executeAsync(api.dispatch, api.getState, (status: ActionStatus) => {

          if (status) {

            // Assign action if not set
            if (!status.action)
              status.action = action;

            this.processingActionIds = this.processingActionIds.filter(x => x != action.id);
            api.dispatch({ type: Actions.UPDATE_ACTION_STATUS, payload: status })
          }

        });

        return action2;
      }

      next(action);

      this.processingActionIds = this.processingActionIds.filter(x => x != action.id);
      return action2;

    };
  }

  public createLoggerMiddleware(): Middleware {
    return (api2) => (next2) => (action2) => {

      let api = (api2 as any) as MiddlewareAPI<any>;
      let next = next2 as Dispatch<any>;
      let action = action2 as AppAction<any>;

      let actionClass = (<AppAsyncAction>action).executeAsync ? AppAsyncAction.name : AppAction.name;


      console.log(action);

      next(action);
      return action2;
    };
  }

  subscribe(callback: () => void): Subscription {
    return this.subject.subscribe(callback);
  }

  dispatch(action: AppAction<any>) {

    let resultAction = this.store.dispatch(action);

    if (this.processingActionIds.indexOf(resultAction.id) > -1) {
      let unsubscribe = this.store.subscribe(() => {

        if (this.processingActionIds.indexOf(resultAction.id) > -1)
          return;

        unsubscribe();

        // Execute listeners
        this.subject.next();
      });
    }
    else {
      // Execute listeners
      this.subject.next();
    }

    return resultAction;
  }

  getState() {
    return this.store.getState();
  }

  getActionInfo(actionId: number): ActionInfo {
    return this.getState().infoByActionId.get(+actionId);
  }
}