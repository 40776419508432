import { Subject, Observable } from 'rxjs';
import { PopupInfo, PopupActions } from "./popupInfo";

export class PopupService {

  // Subject to subscribe the Popup.
  private subject = new Subject<any>();

  public open<T>(id: string, tag?: T): void {

    let info: PopupInfo<T> = <PopupInfo<T>>{
      id: id,
      action: PopupActions.Open,
      tag: tag
    }

    this.sendMessage(info);
  }

  public close(id: string): void {

    let info: PopupInfo<any> = <PopupInfo<any>>{
      id: id,
      action: PopupActions.Close
    }

    this.sendMessage(info);
  }

  public closed(id: string): void {

    let info: PopupInfo<any> = <PopupInfo<any>>{
      id: id,
      action: PopupActions.Closed
    }

    this.sendMessage(info);
  }

  public closeExtension(id: string): void {

    let info: PopupInfo<any> = <PopupInfo<any>>{
      id: id,
      action: PopupActions.CloseExtension
    }

    this.sendMessage(info);
  }

  public getMessage(): Observable<any>  {
    return this.subject.asObservable();
  }

  /**
   * Enables/Disables the user interaction on popup
   * @param id
   * @param status
   */
  public enable(id: string, status: boolean = true): void {

    let info: PopupInfo<any> = <PopupInfo<any>>{
      id: id,
      action: status ? PopupActions.Enable : PopupActions.Disable
    }

    this.sendMessage(info);
  }

  public move(posX: number, posY: number, identifier: string) {
    let info: PopupInfo<any> = <PopupInfo<any>>{
      id: identifier,
      positionX: posX,
      positionY: posY,
      action: PopupActions.Move
    }

    this.sendMessage(info);
  }

  /**
  * Broadcasts the message to all subscribers
  * @param event
  */
  public sendMessage(info: PopupInfo<any>): void {
    this.subject.next(info);
  }

  // TODO: containerId shouldn't be optional argument. We need to define enums somewhere for html identifiers and caller must send that enum value as an argument to it.
  public applyPopper(referenceElementId: string, popupId: string, containerId: string = "wrapper") {

    let config: PopupInfo<any> = <PopupInfo<any>>{
      id: popupId,
      containerId: containerId,
      referenceElementId: referenceElementId,
      action: PopupActions.Move
    }

    this.sendMessage(config);
  }

  public update(referenceElementId: string, popupId: string, containerId: string = "wrapper") {

    let config: PopupInfo<any> = <PopupInfo<any>>{
      id: popupId,
      containerId: containerId,
      referenceElementId: referenceElementId,
      action: PopupActions.Update
    }

    this.sendMessage(config);
  }

}