<div>

  <a href="#" class="mx-4 mt-3 text-uppercase font-weight-bold d-flex" (click)="createConfiguration($event)" >
    <h3>
      {{addChildLinkTitle}}
    </h3>
  </a>
  <div class="configurations mx-4 mt-4">
    
    <div *ngFor="let conf of childConfInfos" class="row pb-3" >
      <div class="col-auto">
        <co-image type="svg" imageSet="primary" [key]="conf.iconRelativeUrl ? conf.iconRelativeUrl : 'configuration'" isVault="{{conf.iconRelativeUrl ? true : false}}" [width]="'24'" [height]="'24'"></co-image>
      </div>
      <div class="col pl-0">
        <a href="#" (click)="showConfigurationDetail($event, conf.longId)" >{{conf.text}}</a>
      </div>
      <div class="col-auto pr-0">        
        <a href="#" (click)="deleteConfiguration(conf.longId)">
          <co-image type="svg" imageSet="red" key="delete" [width]="'24'" [height]="'24'"></co-image>
        </a>
      </div>
    </div>

  </div>

</div>