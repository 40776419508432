<backdrop-container>
  <regular-popup [id]="id"
                 title="{{strings.RemoveItem}}"
                 [showHeader]="false"
                 [showFooter]="true"
                 width="380px"
                 height="380px"
                 [okButtonText]="strings.Ok"
                 [cancelButtonText]="strings.Cancel"
                 (okClick)="okClick()"
                 (onClosed)="removeDomElement()">
    <div card-type="body" class="p-4">
      <div>
        {{strings.AreYouSureYouWantToDeleteTheSelectedRow}}
      </div>
      <div *ngIf="bomItem != null" class="pt-4">
        {{bomItem.itemNo}}
      </div>
    </div>
  </regular-popup>
</backdrop-container>