import * as Immutable from "immutable";
import { BaseEntity } from "../../baseEntity";

export class ConfDocumentValue extends BaseEntity {

  protected readonly TITLE = "title";
  protected readonly FILE_URL = "fileUrl";
  protected readonly FILE_EXISTS = "fileExists";
  protected readonly FILE_TYPE = "fileType";

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get fileUrl(): string { return this.getInternalValue<string>(this.FILE_URL); }
  setFileUrl(fileUrl: string): this { return this.setInternalValue(this.FILE_URL, fileUrl); }

  get fileExists(): boolean { return this.getInternalValue<boolean>(this.FILE_EXISTS); }
  setFileExists(fileExists: boolean): this { return this.setInternalValue(this.FILE_EXISTS, fileExists); }

  get fileType(): string { return this.getInternalValue<string>(this.FILE_TYPE); }
  setFileType(fileType: string): this { return this.setInternalValue(this.FILE_TYPE, fileType); }
}