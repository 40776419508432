<div class="popper-content {{data.calcPlacement}}" [ngStyle]="popperStyle" *ngIf="data.open">
  <div class="header align-middle pm-color-darker">
    <div class="row w-100 h-100 mx-0">
      <div class="col align-middle pl-4 title text-truncate">
        <div class="title text-truncate">
          <h3 class="text-uppercase">{{ data.title }}</h3>
          <span class="font-italic pm-color-darker text-truncate">{{data.subTitle}}</span>
        </div>
      </div>
      <div class="col-auto px-3 align-middle" (click)="close()" role="button">
        <co-image width="20" height="20" type="svg" key="delete"></co-image>
      </div>
    </div>
  </div>  

  <ng-content></ng-content>
</div>
