import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ImageModule } from "../image";

import { HelpLinkComponent } from "./helpLinkComponent";

export * from "./helpLinkComponent";


@NgModule({
  imports: [
    CommonModule,
    ImageModule
  ],
  declarations: [
    HelpLinkComponent    
  ],
  exports: [
    HelpLinkComponent
  ]
 })
export class HelpModule {

}