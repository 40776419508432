<ng-container *ngFor="let name of orderList">

  <ng-container [ngSwitch]="name">

    <operations-action-box *ngSwitchCase="actionBoxNames.Operations" [configurationId]="configurationId" [confSessionId]="confSessionId"></operations-action-box>
    <states-action-box *ngSwitchCase="actionBoxNames.States" [configurationId]="configurationId" [confSessionId]="confSessionId"></states-action-box>
    <revisions-action-box *ngSwitchCase="actionBoxNames.Revisions" [configurationId]="configurationId" [confSessionId]="confSessionId"></revisions-action-box>
    <reports-action-box *ngSwitchCase="actionBoxNames.Reports" [configurationId]="configurationId" [confSessionId]="confSessionId"></reports-action-box>
    <ng-container *ngSwitchCase="actionBoxNames.Code">
      <code-action-box *ngFor="let model of decorationValuesModels" [valuesModel]="model" [configurationId]="configurationId" [confSessionId]="confSessionId"></code-action-box>
    </ng-container>
    <ng-container *ngSwitchCase="actionBoxNames.Documents">
      <documents-action-box *ngFor="let item of documentsUIItems" [confUIItem]="item" [configurationId]="configurationId" [confSessionId]="confSessionId"></documents-action-box>
    </ng-container>
    <graphics-action-box *ngSwitchCase="actionBoxNames.Graphics" [configurationId]="configurationId" [confSessionId]="confSessionId"></graphics-action-box>
    <attachments-action-box *ngSwitchCase="actionBoxNames.Attachments" [configurationId]="configurationId" [confSessionId]="confSessionId"></attachments-action-box>

  </ng-container>

</ng-container>