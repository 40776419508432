import { Injectable, Inject } from "@angular/core";
import { Subscription, Subject, ReplaySubject } from "rxjs";
import { PageActionCreator } from "./pageActionCreator";
import { AppStore, StoreResponse, PageUIData, PageState } from "../../state";
import { ReselectorResult, ReselectorService } from "../reselectorService";
import { ManagedSubscription, SubscriptionOptions } from "../../../../shared/managedSubscription";
import { ManagedSubject } from "../../../../shared/managedSubject";
import { AppStoreSubscriptionManager } from "../appStoreSubscriptionManager";
import { BaseStore } from "../../state/baseStore";
import { ModelFactory } from "../modelFactory";
import { RouteNames } from "..";
import { RequestViews } from "../../models";

@Injectable()
export class PageStore extends BaseStore {

  constructor(
    @Inject(AppStore) public appStore: AppStore,
    @Inject(PageActionCreator) public pageActionCreator: PageActionCreator,
    @Inject(AppStoreSubscriptionManager) public appStoreSubscriptionManager: AppStoreSubscriptionManager,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    @Inject(ReselectorService) public reselectorService: ReselectorService,
  ) {
    super(appStore, appStoreSubscriptionManager, modelFactory);
  }

  public getPageState(): PageState {
    return this.appStore.getState().uiData;
  }

  public get activeRouteName(): RouteNames { return <any>this.getPageState().activeRouteName; }

  public getActiveClientType(): RequestViews {
    return this.translateRouteToClientType(this.activeRouteName);
  }

  public translateRouteToClientType(routeName: string): RequestViews {
    switch (routeName) {
      case RouteNames.Editor:
      case RouteNames.Summary:
        return <any>routeName;

      case RouteNames.Search:
        return <any>routeName;

      case RouteNames.Start:
        return <any>routeName;

      default:
        return RequestViews.None;
    }
  }

  /**
   *  TODO This needs to be called to update the view! 
   * @param routeName
   */
  public updateActiveRoute(routeName: string) {
    // Dispatch the active route.
    this.pageActionCreator.dispatchUpdateActiveView(routeName).id;
  }

  public onActiveRouteNameChange(subscriptionOptions: SubscriptionOptions<string>): ManagedSubscription {
    let activeConfMemorizer: () => string = this.reselectorService.createMemorizer((state) =>
      state.uiData.activeRouteName
    );

    return this.createStoreSubscription<string>('__active_conf_view', activeConfMemorizer, subscriptionOptions);
  }

  public createStoreSubscription<T>(identifier: string, memorizer: () => T, subscriptionOptions: SubscriptionOptions<T>): ManagedSubscription {
    let managedSubject: ManagedSubject<T> = this.appStoreSubscriptionManager.getOrCreateStoreSubject(identifier, memorizer, false, 1, false);
    return managedSubject.subscribe(subscriptionOptions);
  }

  public nextPageIdentifier(): number {
    let nextPageId: number = this.getPageState().nextPageIdentifier;

    // Dispatch for increment.
    this.pageActionCreator.dispatchPageIdentifierIncrement(nextPageId);

    return nextPageId;
  }

  //public listenUIChange(pageId: number, callBackOptions: SubscriptionOptions<PageUIData>): ManagedSubscription {
  //  return this.createStoreSubscription<PageUIData>(pageId.toString(), this.getMemorizer(pageId), callBackOptions);
  //}

  //public createStoreSubscription<T>(identifier: string, memorizer: () => T, subscriptionOptions: SubscriptionOptions<T>): ManagedSubscription {
  //  let managedSubject: ManagedSubject<T> = this.appStoreSubscriptionManager.getOrCreateStoreSubject(identifier, memorizer, false, 1, false);
  //  return managedSubject.subscribe(subscriptionOptions);
  //}

  //public getMemorizer(pageId: number): () => PageUIData {
  //  return null;
  //};

}