<div class="row add-form px-0 mx-0">
  <div class="col px-0 mx-0">
    <div class="panel panel-success">
      <div class="panel-body w-100">
        <ul class="list-group">
          <li *ngFor="let product of products" class="list-group-item px-0" role="button" (click)="productClick($event, product)" >
            <div class="row mx-0" >
              <span class="col-auto align-middle select-box">
                <co-image type="svg" class="product-icon" [key]="hasProductIcon(product) ? product.iconRelativeUrl : 'configuration'" [isVault]="hasProductIcon(product)" width="32" height="32"></co-image>

                <span class="transparent-circle-back"></span>
                <span class="plus-icon">
                  <co-image type="svg" key="add" imageSet="green" [width]="'20'" [height]="'20'"></co-image>
                </span>

              </span>
              <span class="title col align-middle pl-1">
                {{product.title}}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
