import { Component, Input} from "@angular/core";

@Component({
  selector: "gridview-row",
  templateUrl: './gridviewRowComponent.html'
})
export class GridviewRowComponent {

  @Input()
  classes: string = "";

  @Input()
  height?: string = null;

  @Input()
  paddingTop?: string = null;

  @Input()
  paddingBottom?: string = null;

}