import * as Immutable from "immutable";
import { UiElement } from "./uiElement";

export class UiGroup extends UiElement{
  protected readonly UI_ELEMENTS = "uiElements";
  protected readonly ROW_COUNT = "rowCount";
  protected readonly COLUMN_COUNT = "columnCount";

  get uiElements(): Immutable.List<UiElement> { return this.getInternalValue<Immutable.List<UiElement>>(this.UI_ELEMENTS); }
  setUiElements(uiElements: Immutable.List<UiElement>): this { return this.setInternalValue(this.UI_ELEMENTS, uiElements); }

  get rowCount(): number { return this.getInternalValue<number>(this.ROW_COUNT); }
  setRowCount(rowCount: number): this { return this.setInternalValue(this.ROW_COUNT, rowCount); }

  get columnCount(): number { return this.getInternalValue<number>(this.COLUMN_COUNT); }
  setColumnCount(columnCount: number): this { return this.setInternalValue(this.COLUMN_COUNT, columnCount); }
}