import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { Notification, NotificationInfo, NotificationPositionTypes, NotificationConfig, NotificationType } from "./notificationInfo";
import { Strings } from '../../../pages/shared/providers/globalData';
import { GlobalServiceLocator } from '../../providers/globalServiceLocator';

export class NotificationService {

  public observers: Observable<Notification>;

  public notificationId: number = 0;

  public notifyObserver: any;

  public notificationConfig: NotificationConfig = new NotificationConfig();

  private _strings: Strings;

  constructor() {
    this.observers = new Observable<Notification>(observer => this.notifyObserver = observer).pipe(share());
  }

  public get strings() {
    if (!this._strings)
      this._strings = GlobalServiceLocator.injector.get(Strings);
    return this._strings;
  }

  public notify(info: NotificationInfo) {
    if (!info.message && info.rows.length == 0) {
      console.error('You must have to set the message!');
      return false;
    }

    if (!info.type) {
      console.error('You must have to set the message type!');
      return false;
    }

    if (!info.title) {
      switch (info.type) {
        case NotificationType.Success:
          info.title = this.strings.Success;
          break;
        case NotificationType.Error:
          info.title = this.strings.Error;
          break;
        case NotificationType.Warning:
          info.title = this.strings.Warning;
          break;
        default:
          info.title = this.strings.Info;
      }    
    }

    // Send the notification.
    this.notifyObserver.next(<Notification>{ info: info });
  }

  public config(config: NotificationConfig) {

    if (!config) {
      console.error('Notification configuration is missing!');
    }

    this.notificationConfig = config;
  }

}