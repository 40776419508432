import * as Immutable from "immutable"

import { AbstractConfigurationMessage } from "./abstractConfigurationMessage"
import { KeyValue } from "../keyValue";

export class ClearedPricesMessage extends AbstractConfigurationMessage {

  protected readonly TITLE_AND_VALUES = "titleAndValues";

  get titleAndValues(): Immutable.List<KeyValue<string, number>> { return this.getInternalValue<Immutable.List<KeyValue<string, number>>>(this.TITLE_AND_VALUES); }
  setTitleAndValues(titleAndValues: Immutable.List<KeyValue<string, number>>): this { return this.setInternalValue(this.TITLE_AND_VALUES, titleAndValues); }
}