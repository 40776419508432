<form name="form" (ngSubmit)="create()">  
  <div class="create-account">
    <h3 class="alignment pb-3">
      {{strings.CreateAccount}}
    </h3>
    <p class="description text-center">
      {{strings.CompleteTheFormInformation}}
    </p>
    <div class="email-style">
      <label class="pm-color-darker mr-2">{{strings.Email}}:</label> <label class="value font-italic">{{sessionKey}}</label>
    </div>
    <text-box [view]="nameView" [errors]="nameErrors" autocomplete="name"></text-box>
    <text-box [view]="userNameView" [errors]="userNameErrors" autocomplete="username"></text-box>
    <password [view]="newPasswordView" [errors]="newPasswordErrors" isNewPassword="true"></password>
    <password [view]="confirmNewPasswordView" [errors]="confirmNewPasswordErrors" isNewPassword="true"></password>
    <text-box [view]="companyView" [errors]="companyErrors" autocomplete="organization"></text-box>
    <text-box [view]="phoneNumberView" type="tel" autocomplete="tel"></text-box>
    <text-box [view]="mobileNumberView" type="tel" autocomplete="tel"></text-box>
    <button class="btn btn-primary w-100" type="submit">{{strings.CreateAccount}}</button>    
    <div *ngIf="message" class="form-control-feedback d-block pt-3 text-center">{{message}}</div>
  </div>
</form>
