import { ChangeDetectorRef, Component, Inject, Input, ViewChild } from "@angular/core";
import { Breakpoints, ImageSets } from "../../../../shared/utils";
import { ConfGraphicsRequest, GraphicsDecoration, GraphicsDisplayStyle } from "../../../shared/models";
import { ModelFactory } from "../../../shared/providers";
import { ConfDataController } from "../../../shared/providers/configurationData";
import { PageStore } from "../../../shared/providers/page/pageStore";
import { ProductDataStore } from "../../../shared/providers/productData";
import { GraphicsPopupComponent } from "../../popups";
import { ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { DecorationComponent } from "../../shared/decorationComponent";
import { VisualObjectHelper } from "../../parameters/shared";


@Component({
  selector: 'graphics-decoration',
  templateUrl: './graphicsDecorationComponent.html',
})
export class GraphicsDecorationComponent extends DecorationComponent<GraphicsDecoration> {

  public height: string;
  public ngContainerStyle: CSSStyleDeclaration;
  public visible: boolean = true;
  public isAccordion: boolean = false;

  public title: string;
  public isPopup: boolean = false;

  @Input()
  public isEditor: boolean = true;

  @Input()
  showInlineTitle: boolean = true;

  @ViewChild(GraphicsPopupComponent)
  public graphicsPopup: GraphicsPopupComponent;

  constructor(
    public productStore: ProductDataStore,
    public confStore: ConfiguratorStore,
    public confPageSessionService: ConfPageSessionService,    
    @Inject(PageStore) public pageStore: PageStore,
    @Inject(VisualObjectHelper) public visualObjectHelper: VisualObjectHelper,
    public configuratorController: ConfDataController,
    public modelFactory: ModelFactory,
    public cdr: ChangeDetectorRef    
  ) {
    super(confStore, confPageSessionService, visualObjectHelper);
  }

  ngOnInit(): void {

    this.height = this.visualObjectHelper.decorationHeight(this.decoration);
    this.width = this.visualObjectHelper.visualObjectWidth(this.decoration);
    
    if (!this.ngContainerStyle)
      this.ngContainerStyle = {} as CSSStyleDeclaration;
    
    //const tab: Tab = this.productStore.getEntity<Tab>(this.decoration.tabId)

    // Visual objects count
    //const vsCount: number = tab.visualObjects.size;

    const isAccordion: boolean = this.visualObjectHelper.isVisualObjectInAccordion(this.decoration);
    this.ngContainerStyle.height = isAccordion ? this.height : null;      

    // Fix for IE-11
    if (isAccordion && this.browserInfo.isIE && !this.browserInfo.isEdge)
      this.ngContainerStyle.flexBasis = this.height;

    // As graphics scalling is done through calculations that's why 

    this.isPopup = this.decoration.displayStyle === GraphicsDisplayStyle.Popup || this.decoration.displayStyle === GraphicsDisplayStyle.OpenInDownloadFormat;
    // Title can't be hidden for popup
    this.showTitle = this.isPopup || (this.decoration.title && this.decoration.title.trim() != '');
    // Popup must have a title, so just assign it "Graphics".
    this.title = this.decoration.title || (this.isPopup ? this.strings.Graphics : null);

    this.imageSet = this.getImageSet(ImageSets.ConfiguratorDecorationHeader);

    // This is the special case where super don't need to be called, because in graphics listening should be done after view initialization.
    //super.ngOnInit();

  }


  public openPopup() {

    if (this.decoration.displayStyle == GraphicsDisplayStyle.OpenInDownloadFormat) {
      let model: ConfGraphicsRequest = this.modelFactory.createAny(ConfGraphicsRequest.name) as any;
      model.client = this.pageStore.getActiveClientType();
      model.fileType = "SVG";
      model.configurationId = this.configurationId;
      model.confSessionId = this.confSessionId;
      model.graphicsDecorationId = this.decoration.visualObjectId;

      window.open(this.configuratorController.getConfigurationGraphicViewerUrl(model));
    }
    else
      this.graphicsPopup.show(this.decoration, this.configurationId, this.confSessionId);

    return false;
  }

  public get shouldShowSeparatedTabs() {
    return window.innerWidth > Breakpoints.sm.max;
  }

  public onScaledGraphics(event): void {

    if (this.ngContainerStyle)
      this.ngContainerStyle.height = event.height;

    // Fix for IE-11
    if (this.ngContainerStyle && this.browserInfo.isIE && !this.browserInfo.isEdge)
      this.ngContainerStyle.flexBasis = event.height;

  }

}