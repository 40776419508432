import { Inject, Component, Input, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core";
import { Conf, WorkGroup } from "../../models";
import { VisualObjectViewModelFactory } from "../../../configurator/parameters/shared";
import { ConfigurationSessionManager } from "../../../configurator/providers/configurationSessionManager";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import { PopupService, InputViewModel, Positions } from "../../../../shared/components";
import { ConfiguratorStore, ConfPageSessionService, PopupIdentifiers } from "../../../configurator/providers";
import { AccountDataStore } from "../../providers/accountData";
import { EmitterService } from "../../../../../core/pages/configurator/shared";

@Component({
  selector: 'change-ownership-popup',
  templateUrl: './changeOwnershipPopupComponent.html'  
})
export class ChangeOwnershipPopupComponent extends AbstractPopupComponent {

  public id = "change-ownership-popup";

  public workgroupView: InputViewModel;
  public workgroupItemViews: InputViewModel[] = [];

  public changeEveryRevisionView: InputViewModel;

  public conf: Conf;
  public configurationId: number;
  public confSessionId: number;

  constructor(
    @Inject(ConfiguratorStore) public configuratorStore: ConfiguratorStore,
    @Inject(PopupService) public popupService: PopupService,
    @Inject(AccountDataStore) public accountStore: AccountDataStore,    
    public cdr: ChangeDetectorRef
  ) {
    super(cdr, popupService);
  }

  public open(tag: any) {
    this.show(tag.configurationId, tag.confSessionId);
  }

  show(configurationId: number, confSessionId: number): void {
    this.configurationId = configurationId;
    this.confSessionId = confSessionId;

    // Get active configuration
    this.conf = this.configuratorStore.getConf(this.configurationId, this.confSessionId);

    // Create input view models.
    this.createModels();

    this.showInternal();
  }

  createModels(): void {
    this.strings.ChangeOwnership
    // Create views for price list category
    this.workgroupView = new InputViewModel(this.strings.ChangeOwnershipTo);
    this.workgroupView.icon = "ownership";

    // Only show if multiple revisions
    if (this.conf.revisions.count() > 1) {
      this.changeEveryRevisionView = new InputViewModel(this.strings.ChangeOwnershipOnEveryRevision);
      this.changeEveryRevisionView.value = true;
    }

    // Empty the list.
    this.workgroupItemViews = [];

    this.accountStore.getEntities<WorkGroup>(WorkGroup.name).subscribe(response => {
      this.conf.authorization.takeOwnershipToWorkGroupIds.forEach(x => {
        let workGroup = response.data.get(x);
        if (workGroup) {
          let itemView = new InputViewModel(workGroup.title);
          itemView.id = workGroup.longId.toString();
          this.workgroupItemViews.push(itemView);

          // Preselect the current workgroup
          if (workGroup.longId === this.conf.ownedBy.longId) {
            this.workgroupView.formattedValue = this.conf.ownedBy.text;
            this.workgroupView.value = this.conf.ownedBy.longId.toString();
          }
        }
      });

      this.workgroupView = { ...this.workgroupView };
      this.cdr.detectChanges();

    }).unsubscribeOn(this.unsubscribeSubject);
  }

  public get resultsInline(): boolean {
    return this.changeEveryRevisionView != null && (typeof this.changeEveryRevisionView.value === "boolean" ? this.changeEveryRevisionView.value : this.changeEveryRevisionView.value === "true");
  }

  okClick(): void {
    this.plainBlockUI();
    this.configuratorStore.changeOwnership(this.conf.longId, this.confSessionId, parseInt(this.workgroupView.value), this.resultsInline).then(response => {
      this.unblockUI();
    });
  }
}