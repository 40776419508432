import { DefaultUrlSerializer, UrlTree, UrlSegmentGroup } from '@angular/router';
import { ConfRouteParams } from '../pages/configurator/providers';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {

    const segements = url.split('?');

    let newUrl: string = '';
    if (segements.length == 1 || segements.length == 2) {

      // First segment must start with http.. and sub routings must contain ';'
      newUrl += this.toLowerCase(segements[0], ';');

      // After routing ends, It could contain ?.
      if (segements.length == 2)
        newUrl += '?' + this.toLowerCase(segements[1], '&');

    }
    else newUrl = url;

    return super.parse(newUrl);
  }

  private toLowerCase(segment: string, splitStr: string): string {

    let params = segment.split(splitStr);
    let newPath: string = '';
    params.forEach(sub => {

      let arr = sub.split('=');

      if (arr.length > 0) {

        if (this.existsInRouteParams(arr[0])) {
          arr[0] = arr[0].toLowerCase();
        }

        // Could be without '='
        let keyValue = arr[0];

        if (arr.length == 2)
          keyValue += '=' + arr[1];

        newPath += newPath == '' ? keyValue : splitStr + keyValue;
      }

    });

    return newPath;
  }

  private existsInRouteParams(name: string): boolean {

    let exists: boolean = false;
    ConfRouteParams.ALL_PROPERTIES.forEach(key => {

      if (key.toLowerCase() == name.toLowerCase()) {
        {
          exists = true;
          return;
        }
      }
    });

    return exists;

  }

}