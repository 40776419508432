import { Injectable, Inject } from '@angular/core';
import { combineReducers } from 'redux';
import * as Immutable from "immutable";

import { AppAction } from "../shared/appAction";
import { SearchSessionData } from "../shared/searchSessionData";
import { SearchDataState } from "../shared/searchDataState";
import { SearchDataActions } from "./searchDataActions";
import { Actions } from "../shared/actions";
import { SearchDataResponse } from "../../models/responses";
import { ConfigurationSearch, AbstractSearchDataMessage, ApiResponse, KeyValue } from "../../models";

@Injectable()
export class SearchDataReducer {

  public getReducer() {

    // TODO use model factory
    let defaultState = new SearchDataState();
    defaultState = defaultState.setDataBySessionId(Immutable.Map<number, SearchSessionData>());
    defaultState = defaultState.setSavedSearches(Immutable.List<ConfigurationSearch>());
    defaultState = defaultState.setMessages(Immutable.Map<string, Immutable.List<AbstractSearchDataMessage>>());

    return (state = defaultState, action: AppAction<any>): SearchDataState => {

      if (action.type == Actions.CLEAR_CLIENT_CACHE) {
        return defaultState;
      }

      // TODO need to clear previous search results
      if (action.type == Actions.SEARCH_CONFIGURATIONS) {

      }

      if (action.type == Actions.REMOVE_CONFIGURATOR_SESSIONS) { 

        // Remove all configuration search results which are part of the requested removal of ConfSessionIds

        let confSessionIds = action.payload as number[];

        let dataBySessionIdTemp = state.dataBySessionId;

        state.dataBySessionId.forEach((value, key) => {

          let filteredItems = value.confSearchResultItems.filter(confSearchResultItem => confSessionIds.indexOf(confSearchResultItem.confSessionId) < 0).toList();
          if (filteredItems.size != value.confSearchResultItems.size) {
            let newSearchSessionData = value.setConfSearchResultItems(filteredItems);
            dataBySessionIdTemp = dataBySessionIdTemp.set(key, newSearchSessionData);
          }
        });

        if (dataBySessionIdTemp != state.dataBySessionId)
          state = state.setDataBySessionId(dataBySessionIdTemp);

        return state;
      }

      if (!(action.payload instanceof ApiResponse) || !(action.payload.data instanceof SearchDataResponse))
        return state;

      let searchDataResponse = action.payload.data as SearchDataResponse;

      if (action.type == Actions.SEARCH_CONFIGURATIONS_RESULT) {

        if (searchDataResponse.searchResult) {
          let searchResult = searchDataResponse.searchResult;

          let searchSessionData = state.dataBySessionId.get(searchResult.searchSessionId);
          if (!searchSessionData) {
            // TODO use model factory
            searchSessionData = new SearchSessionData();
          }

          searchSessionData = searchSessionData.setSearchSessionId(searchResult.searchSessionId);
          searchSessionData = searchSessionData.setPaginationInfo(searchResult.paginationInfo);
          searchSessionData = searchSessionData.setSortingInfo(searchResult.sortingInfo);
          searchSessionData = searchSessionData.setConfSearchResultItems(searchResult.confSearchResultItems);

          if (JSON.stringify(searchSessionData.query) != JSON.stringify(searchResult.query))
            searchSessionData = searchSessionData.setQuery(searchResult.query);

          searchSessionData = searchSessionData.setQuerySourceType(searchResult.querySourceType);

          let dataBySessionId = state.dataBySessionId.set(searchResult.searchSessionId, searchSessionData);
          state = state.setDataBySessionId(dataBySessionId);
        }
      }

      if (action.type == SearchDataActions.SAVED_CONFIGURATION_SEARCHES_LOADED) {

        if (searchDataResponse.savedSearches) {
          state = state.setSavedSearches(searchDataResponse.savedSearches);
        }
      }

      if (searchDataResponse.messages) {
        state = state.setMessages(this.createMessagesMap(searchDataResponse.messages));
      }

      return state;
    }
  }

  createMessagesMap(messages: Immutable.List<AbstractSearchDataMessage>): Immutable.Map<string, Immutable.List<AbstractSearchDataMessage>> {
    let messagesMap = Immutable.Map<string, Immutable.List<AbstractSearchDataMessage>>();

    messages.forEach((message) => {
      if (!messagesMap.has(message.className))
        messagesMap = messagesMap.set(message.className, Immutable.List<AbstractSearchDataMessage>());

      let messagesList = messagesMap.get(message.className);
      messagesMap = messagesMap.set(message.className, messagesList.push(message));
    });

    return messagesMap;
  }
}