import { Component, ViewChild, ElementRef, ChangeDetectorRef, Input, Host, Inject, forwardRef, SimpleChanges } from "@angular/core";
import { BaseCheckboxComponent } from './baseCheckboxComponent';
import { CoCheckboxComponent } from "../coCheckboxComponent";

@Component({
  selector: 'standard-checkbox',
  templateUrl: "standardCheckboxComponent.html"
})
export class StandardCheckboxComponent extends BaseCheckboxComponent {

  constructor(
    @Host() @Inject(forwardRef(() => CoCheckboxComponent)) public checkbox: CoCheckboxComponent,
    protected cdr: ChangeDetectorRef
  ) {
    super(checkbox, cdr);
  }

  @ViewChild('chekboxButton')
  inputControlRef: ElementRef;

  ngAfterContentInit() {
    
  }

  /**
   * TODO: Need more work to make this checkbox component functional for configurator page.
   * @param $event
   */
  onClick($event): void {
    this.checkbox.notifyValueChange(this.view, $event.target.checked, this.inputControlRef.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['view'] && !changes['view'].firstChange) {

      // Note! template is not detecting the view.value that's we have set the value to control forcefully.
      this.inputControlRef.nativeElement.checked = this.view.value;      

    }

  }

}