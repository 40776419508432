import { Component, Input, ViewContainerRef, ComponentFactoryResolver, ViewChild, Inject, ElementRef } from "@angular/core";
import { DocumentsDecoration, ConfDocumentValue, Conf, RequestViews, ConfDocumentRequest, GroupConfUIItem, ConfUIItem } from "../../../shared/models";
import { DecorationComponent } from "../../shared/decorationComponent";
import { ConfigurationSessionManager } from "../../providers/configurationSessionManager";
import { ConfPageSessionService, ConfiguratorStore } from "../../providers";
import { BaseDetailItemComponent } from "../shared/baseDetailItemComponent";
import { Optional } from "@angular/core";
import { GlobalDataStore } from "../../../shared/providers/globalData";
import { ModelFactory } from "../../../shared/providers/modelFactory";
import { ConfDataController } from "../../../shared/providers/configurationData";
import { VisualObjectVisibilityService } from "../../shared";
import { ProductDataStore } from "../../../shared/providers/productData/productDataStore";

@Component({
  selector: 'documents-detail',
  templateUrl: './documentsDetailComponent.html'
})
export class DocumentsDetailComponent extends BaseDetailItemComponent {

  public detailTitle: string;
  public documentValues: Array<ConfDocumentValue> = [];

  constructor(@Optional() @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    @Inject(ConfDataController) public configuratorController: ConfDataController,
    @Inject(VisualObjectVisibilityService) public visualObjectVisibilityService: VisualObjectVisibilityService,
    @Inject(ProductDataStore) public productStore: ProductDataStore
  ) {
    super(storeSession);
  }

  onDataReady(): void {

    this.documentValues = [];

    if (!this.storeSession.activeConfiguration.documentValues || !this.confUIItem) {
      this.visible = false;
      return;
    }

    if (this.confUIItem.id == null)
      this.confUIItem.items.forEach(decoration => {
        this.addDocumentValues(decoration)
      });
    else
      this.addDocumentValues(this.confUIItem);

    if (this.documentValues.length > 0)
      this.visible = true;

    let grouped = this.confUIItem.items && this.confUIItem.items.count() > 1;
    this.detailTitle = this.strings.Documents;

    if (!grouped) {
      let firstDecorationId = this.confUIItem.id ?? this.confUIItem.items.first().id;
      let firstDecoration = this.productStore.getEntity<DocumentsDecoration>(firstDecorationId);

      if (firstDecoration.title && firstDecoration.title.trim().length > 0)
        this.detailTitle = firstDecoration.title;
      // For individually displayed decoration if there is no title then hide the whole header row
      else if (typeof this.confUIItem != typeof GroupConfUIItem)
        this.showHeader = false;
    }
  }

  public addDocumentValues(decorationUIItem: ConfUIItem)
  {
    const documentValues = this.storeSession.activeConfiguration.documentValues.toArray();

    decorationUIItem.items.forEach(item => {
      const value = documentValues.find(x => x.longId === item.id);
      if (value && this.documentValues.findIndex(x => x.longId === value.longId) === -1)
        this.documentValues.push(value);
    });
  }

  public get vaultUrl(): string {
    return this.globalDataStore.getGlobalData().webSettings.vaultUrl;
  }

  public getDocumentLink(documentId: number): string {
    let model: ConfDocumentRequest = this.modelFactory.createAny(ConfDocumentRequest.name) as any;

    model.client = RequestViews.Summary;//this.pageStore.getActiveClientType();
    model.documentId = documentId;
    model.configurationId = this.configurationId;
    model.confSessionId = this.confSessionId;
    return this.configuratorController.getConfigurationDocumentUrl(model);
  }
}