<div *ngIf="visualObject" class="grow-visual-object">

  <!--Id is added temporarily-->
  <bool-param *ngIf="visualObject.className == 'BoolParam'" [configurationId]="configurationId" [parameter]="visualObject" [id]="visualObject.longId"></bool-param>
  <int-param *ngIf="visualObject.className == 'IntParam'" [configurationId]="configurationId" [parameter]="visualObject" [id]="visualObject.longId"></int-param>
  <double-param *ngIf="visualObject.className == 'DoubleParam'" [configurationId]="configurationId" [parameter]="visualObject" [id]="visualObject.longId"></double-param>
  <lookup-param *ngIf="visualObject.className == 'LookupParam'" [configurationId]="configurationId" [parameter]="visualObject" [id]="visualObject.longId"></lookup-param>
  <string-param *ngIf="visualObject.className == 'StringParam'" [configurationId]="configurationId" [parameter]="visualObject" [id]="visualObject.longId"></string-param>
  <multi-choice-param *ngIf="visualObject.className == 'MultiChoiceParam'" [configurationId]="configurationId" [parameter]="visualObject" [id]="visualObject.longId"></multi-choice-param>

  <attribute-decoration *ngIf="visualObject.className == 'AttributeDecoration'" [configurationId]="configurationId" [decoration]="visualObject" [id]="visualObject.longId"></attribute-decoration>
  <property-decoration *ngIf="visualObject.className == 'PropertyDecoration'" [configurationId]="configurationId" [decoration]="visualObject" [id]="visualObject.longId"></property-decoration>
  <graphics-decoration *ngIf="visualObject.className == 'GraphicsDecoration'" [configurationId]="configurationId" [decoration]="visualObject" [growVisualObject]="growVisualObject" class="grow-visual-object {{visualObject.longId}}" [id]="visualObject.longId"></graphics-decoration>
  <header-decoration *ngIf="visualObject.className == 'HeaderDecoration'" [configurationId]="configurationId" [decoration]="visualObject" [id]="visualObject.longId"></header-decoration>
  <image-decoration *ngIf="visualObject.className == 'ImageDecoration'" [configurationId]="configurationId" [decoration]="visualObject" [id]="visualObject.longId"></image-decoration>
  <text-decoration *ngIf="visualObject.className == 'TextDecoration'" [configurationId]="configurationId" [decoration]="visualObject" [id]="visualObject.longId"></text-decoration>
  <documents-decoration *ngIf="visualObject.className == 'DocumentsDecoration'" [configurationId]="configurationId" [decoration]="visualObject" [id]="visualObject.longId"></documents-decoration>
  <price-list-decoration *ngIf="visualObject.className == 'PriceListDecoration'" [configurationId]="configurationId" [decoration]="visualObject" [id]="visualObject.longId"></price-list-decoration>
  <data-selector-decoration *ngIf="visualObject.className == 'DataSelector'" [configurationId]="configurationId" [decoration]="visualObject" [id]="visualObject.longId"></data-selector-decoration>
  <bom-decoration *ngIf="visualObject.className == 'BomDecoration'" [configurationId]="configurationId" [decoration]="visualObject" [id]="visualObject.longId"></bom-decoration>
  <code-decoration *ngIf="visualObject.className == 'CodeDecoration'" [configurationId]="configurationId" [decoration]="visualObject" [id]="visualObject.longId"></code-decoration>
  <visualization-decoration *ngIf="visualObject.className == 'VisualizationDecoration'" [configurationId]="configurationId" [decoration]="visualObject" [id]="visualObject.longId"></visualization-decoration>
  <form-decoration *ngIf="visualObject.className == 'FormDecoration'" [configurationId]="configurationId" [decoration]="visualObject" [id]="visualObject.longId"></form-decoration>
  
</div>