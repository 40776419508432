import { Input, Component } from "@angular/core";
import { CodeDecoration, AbstractCodeValue } from "../../../models";
import { BaseComponent } from "../../../baseComponent";
import { ProductDataStore } from "../../../providers/productData";
import { PageStore } from "../../../providers/page";
import { ConfPageSessionService } from "../../../../configurator/providers";
import { RouteNames } from "../../../providers/routeNames";
import { ImageSets } from "../../../../../shared/utils/imageSets";

@Component({ template: '' })
export abstract class BaseCodeDisplayStyleComponent extends BaseComponent {

  @Input()
  subHeading: string;

  @Input()
  codeValue: AbstractCodeValue;
  
  @Input()
  decorationId: number;

  @Input()
  isStored: boolean = false;

  decoration: CodeDecoration;
  
  isSummary: boolean = false;

  configurationId: number;

  confSessionId: number;

  public imageSet: string;

  constructor(public productStore: ProductDataStore, public pageStore: PageStore, public confPageSession: ConfPageSessionService) {
    super();
  }

  ngOnInit() {

    this.imageSet = this.getImageSet(this.pageStore.activeRouteName === RouteNames.Editor ? ImageSets.ConfiguratorDecoration : ImageSets.SummaryActionDetail);

    this.decoration = this.productStore.getEntity(this.decorationId);
    this.configurationId = this.confPageSession.activeConfigurationId;
    this.confSessionId = this.confPageSession.confSessionId;
    
    super.ngOnInit();
  }

}