import * as Immutable from "immutable"

import { BaseEntity } from "../../baseEntity"
import { ParamInfo } from "./paramInfo"
import { ConfInfo } from "../entities/confInfo"

export class RuleInfo extends BaseEntity {

  protected readonly TITLE = "title";  
  protected readonly DESCRIPTION = "description";
  protected readonly RULE_TYPE = "ruleType";
  protected readonly PARAMETER_INFOS = "parameterInfos";
  protected readonly CONF_INFO = "confInfo";

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get description(): string { return this.getInternalValue<string>(this.DESCRIPTION); }
  setDescription(description: string): this { return this.setInternalValue(this.DESCRIPTION, description); }
  
  get ruleType(): string { return this.getInternalValue<string>(this.RULE_TYPE); }
  setRuleType(ruleType: string): this { return this.setInternalValue(this.RULE_TYPE, ruleType); }

  get parameterInfos(): Immutable.List<ParamInfo> { return this.getInternalValue<Immutable.List<ParamInfo>>(this.PARAMETER_INFOS); }
  setParameterInfos(parameterInfos: Immutable.List<ParamInfo>): this { return this.setInternalValue(this.PARAMETER_INFOS, parameterInfos); }

  get confInfo(): ConfInfo { return this.getInternalValue<ConfInfo>(this.CONF_INFO); }
  setConfInfo(confInfo: ConfInfo): this { return this.setInternalValue(this.CONF_INFO, confInfo); }
}