<div #visualObjectContainer *ngIf="confLookupValue">
  <div *ngIf="itemsView.length > 0 && parameter != null" class="visual-object-margin">
   <select-option
                 [view]="viewModel"
                 [itemsView]="itemsView"
                 [displayAsRadio]="parameter.displayAsRadio"
                 [displayStyle]="displayStyle"
                 [orientation]="orientation"
                 [selectedValueId]="selectedViewId"
                 (onValueChange)="onValueChange($event)"
                 (onReadOnly)="onReadOnly($event)"
                 (onDisabled)="onDisallowed($event)"
                 (onHelpClick)="onHelpClick($event)"
                   >

    </select-option>


    <!-- TODO implement errors in dropdown and radio group -->
    <div *ngFor="let error of viewModel.errors" class="form-control-feedback d-block">{{error}}</div>
  </div>
</div>