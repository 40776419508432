import { AbstractCodeValue, CodeDecoration, ConfCodeValue, StoredCodeValue } from "../../models";
import * as Immutable from 'immutable';

// Represents the model for one decoration and its values.
export class CodeDecorationValuesModel {

  decorationId: number;

  values: Immutable.List<FileCodeValueModel>

}

export class FileCodeValueModel {

  calcValue: ConfCodeValue;

  storedValue: StoredCodeValue;

}