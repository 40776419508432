import { Component, Input, Inject } from "@angular/core";
import { VisualObject, } from "../../shared/models";
import { BaseComponent } from "../../shared";
import { ProductDataStore } from "../../shared/providers/productData";

@Component({
  selector: 'visual-object-factory',
  templateUrl: './visualObjectFactoryComponent.html'
})
export class VisualObjectFactoryComponent extends BaseComponent  {

  constructor(
    @Inject(ProductDataStore) public productStore: ProductDataStore
  ) {
    super();
  }

  @Input()
  public visualObjectId: number;

  @Input()
  public configurationId: number;

  @Input()
  public growVisualObject: boolean = false;

  public visualObject: VisualObject;

  ngOnInit() {
    this.visualObject = this.productStore.getEntity(+this.visualObjectId);    
  }  
}