import { Inject, Injectable } from '@angular/core';

import { ApiException } from "../models"
import { ErrorCodes } from "../../../shared/errorCodes";
import { RouteRedirector } from "./routeRedirector";
import { AccountDataStore } from "./accountData";
import { Exception } from "../../../shared/exception";
import { HttpException } from '../../../shared/httpException';

@Injectable()
export class HttpErrorCodeHandler {

  constructor(
    @Inject(RouteRedirector) protected routeRedirector: RouteRedirector,
    @Inject(AccountDataStore) protected accountStore: AccountDataStore,
  ) { }

  public handle(httpException: HttpException) {
    
    let exceptions: Exception[] = httpException.exceptions;
    if (!exceptions || exceptions.length == 0)
      return;

    let apiException = exceptions.find((ex) => ex instanceof ApiException);
    if (apiException instanceof ApiException) {

      if (httpException.errorResponse.status == 401) {
        // Remove user session only if authentication or work session has expired
        if (apiException.code == ErrorCodes.AUTHENTICATION_EXPIRED || apiException.code == ErrorCodes.WORK_SESSION_EXPIRED) {
          this.accountStore.endUserSession();
          return;
        }
        
        // Redirect to login with returnurl to start page
        this.routeRedirector.redirectToLogin('start');
      }      
    }
  }
}