export interface BaseMessageBoxModel {

  id?: string;

}

export class MessageBoxConfig<T> implements BaseMessageBoxModel  {

  public id?: string;
  public headerText: string;
  public caption: string;
  public description: string;
  public icon: string;
  public tag: T;

}

export enum ActionNames  {
  Ok = "Ok",
  Cancel = "Cancel",
  Close = "Close",
}


export class MessageBoxAction<T> implements BaseMessageBoxModel {

  // Unique id for component.
  id?: string;
  actionName: ActionNames;
  tag?: T;


}