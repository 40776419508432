import { Injectable, Inject } from "@angular/core";
import { NgbDateStruct, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import * as moment from 'moment';

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

	public dateFormat: string; 

	parse(value: string): NgbDateStruct | null {
		if (value) {

			let dateFormat: string = this.dateFormat;
			if (dateFormat) {
				dateFormat = dateFormat.toLocaleUpperCase();
			}

			const dt = moment(value, dateFormat);

			return {
				day: dt.date(),
				month: dt.month(),
				year: dt.year(),
			};
		}
		return null;
	}

	format(date: NgbDateStruct | null): string {

		let dateFormat: string = this.dateFormat;
		if (dateFormat) {

			dateFormat = dateFormat.toLocaleUpperCase();
    }

		if (date) {
			let dateStr = date.day + '-' + date.month + '-' + date.year;
			// Add input format and return according to global settings format.
			return moment(dateStr, 'DD-MM-YYYY').format(dateFormat);
		}
		else
			return '';
	}
}