<action-box class="detail-item data-selector-detail"
            iconLeft="filter"
            textLeft="{{actionBoxTitle}}">

        <gridview>
          <gridview-row classes="row-data align-middle">
            <columntemplate>
              {{selectedValue}}
            </columntemplate>
          </gridview-row>        
        </gridview>

    <div card-type="control" *ngIf="isPrintView">
      <print-checkbox (onValueChange)="onPrintValueChange($event)"></print-checkbox>
    </div>
</action-box>
