import * as Immutable from "immutable"

import { BaseEntity } from "../../../baseEntity";

export class PriceReportItem extends BaseEntity {

  protected readonly IS_EDITABLE = "isEditable";
  protected readonly TITLE = "title";
  protected readonly PRICE = "price";
  protected readonly RAW_PRICE = "rawPrice";
  protected readonly MANUAL_PRICE = "manualPrice";
  protected readonly RAW_MANUAL_PRICE = "rawManualPrice";
  protected readonly NOTE = "note";  
  protected readonly SUB_ITEMS = "subItems";

  get isEditable(): boolean { return this.getInternalValue<boolean>(this.IS_EDITABLE); }
  setIsEditable(isEditable: boolean): this { return this.setInternalValue(this.IS_EDITABLE, isEditable); }

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get price(): string { return this.getInternalValue<string>(this.PRICE); }
  setPrice(price: string): this { return this.setInternalValue(this.PRICE, price); }

  get rawPrice(): number { return this.getInternalValue<number>(this.RAW_PRICE); }
  setRawPrice(rawPrice: number): this { return this.setInternalValue(this.RAW_PRICE, rawPrice); }

  get manualPrice(): string { return this.getInternalValue<string>(this.MANUAL_PRICE); }
  setManualPrice(manualPrice: string): this { return this.setInternalValue(this.MANUAL_PRICE, manualPrice); }

  get rawManualPrice(): number { return this.getInternalValue<number>(this.RAW_MANUAL_PRICE); }
  setRawManualPrice(rawManualPrice: number): this { return this.setInternalValue(this.RAW_MANUAL_PRICE, rawManualPrice); }

  get note(): string { return this.getInternalValue<string>(this.NOTE); }
  setNote(note: string): this { return this.setInternalValue(this.NOTE, note); }

  get subItems(): Immutable.List<PriceReportItem> { return this.getInternalValue<Immutable.List<PriceReportItem>>(this.SUB_ITEMS); }
  setSubItems(subItems: Immutable.List<PriceReportItem>): this { return this.setInternalValue(this.SUB_ITEMS, subItems); }

}