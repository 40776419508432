import { ImmutableObject } from "../../../../shared/immutableObject";

export class MenuItem extends ImmutableObject {

  public readonly NAME = "name";
  public readonly TITLE = "title";
  public readonly SUB_TITLE = "subTitle";
  public readonly ICON = "icon";
  public readonly WIDTH = "width";
  public readonly HEIGHT = "height";  
  public readonly STYLE = "style";
  public readonly HIDDEN = "hidden";
  public readonly DISABLE = "disable";
  public readonly HAS_DETAIL = "hasDetail";
  public readonly TAG = "tag";

  get name(): string { return this.getInternalValue<string>(this.NAME); }
  setName(name: string): this { return this.setInternalValue(this.NAME, name); }

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get subTitle(): string { return this.getInternalValue<string>(this.SUB_TITLE); }
  setSubTitle(subTitle: string): this { return this.setInternalValue(this.SUB_TITLE, subTitle); }

  get icon(): string { return this.getInternalValue<string>(this.ICON); }
  setIcon(icon: string): this { return this.setInternalValue(this.ICON, icon); }

  get width(): number { return this.getInternalValue<number>(this.WIDTH); }
  setWidth(width: number): this { return this.setInternalValue(this.WIDTH, width); }

  get height(): number { return this.getInternalValue<number>(this.HEIGHT); }
  setHeight(height: number): this { return this.setInternalValue(this.HEIGHT, height); }  

  get style(): string { return this.getInternalValue<string>(this.STYLE); }
  setStyle(style: string): this { return this.setInternalValue(this.STYLE, style); }

  get hidden(): boolean { return this.getInternalValue<boolean>(this.HIDDEN); }
  setHidden(hidden: boolean): this { return this.setInternalValue(this.HIDDEN, hidden); }

  get disable(): boolean { return this.getInternalValue<boolean>(this.DISABLE); }
  setDisable(disable: boolean): this { return this.setInternalValue(this.DISABLE, disable); }

  get hasDetail(): boolean { return this.getInternalValue<boolean>(this.HAS_DETAIL); }
  setHasDetail(hasDetail: boolean): this { return this.setInternalValue(this.HAS_DETAIL, hasDetail); }

  get tag(): any { return this.getInternalValue<any>(this.TAG); }
  setTag(tag: any): this { return this.setInternalValue(this.TAG, tag); }

}