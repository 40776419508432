import { Inject, Component, OnInit, Host, AfterContentInit, ChangeDetectorRef, Input, EventEmitter, Output, ViewChild, ElementRef, ChangeDetectionStrategy } from "@angular/core";
import { GenericDropdownGroup } from "../genericDropdownGroup";
import { AbstractDropdownItem } from "../abstractDropdownItem";
import { AbstractDropdownGroup } from "../abstractDropdownGroup";
import { InputViewModel } from "../../shared/models/inputViewModel";

import {
  DOWN_ARROW,
  ENTER,
  LEFT_ARROW,
  RIGHT_ARROW,
  SPACE,
  UP_ARROW,
} from '@angular/cdk/keycodes';

@Component({
  selector: "line-dropdown",
  templateUrl: './lineDropdownGroupComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineDropdownGroupComponent extends AbstractDropdownGroup {

  @ViewChild('lineDropdown')
  public dropdownMenu: ElementRef;

  @ViewChild('selectButton')
  public selectButton: ElementRef;

  @Input()
  public view: InputViewModel;

  @Input()
  public itemsView: InputViewModel[] = [];

  @Input()
  menuHeight: string;
  selectedIconKey: string;

  public expanded: boolean = false;

  public menuWidth: string = "";

  public showPlaceholder: boolean = false;

  private SELECTOR_VISIBLE_ITEMS = '.dropdown-menu .dropdown-item';

  constructor(@Host() @Inject(GenericDropdownGroup) public parent: GenericDropdownGroup, public cdr: ChangeDetectorRef) {
    super(parent, cdr);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.parent.view.showIconAsSelected) {

      let selectedItem = this.parent.itemsView.find(x => x.id == this.parent.view.value);
      if (selectedItem)
        this.selectedIconKey = selectedItem.icon;

    }

    this.setPlaceholder();
  }

  ngAfterViewInit() {
    $(this.dropdownMenu.nativeElement).on('hide.bs.dropdown', () => {
      this.dropdownOpenedClosed({ event: false })
    });

    $(this.dropdownMenu.nativeElement).on('show.bs.dropdown', () => {
      this.dropdownOpenedClosed({ event: true })
    });
  }

  public dropdownOpenedClosed($event): void {
    this.expanded = $event.event;

    if (!this.expanded)
      this.selectButton.nativeElement.focus();

    if (this.parent && this.parent.collapseIcon)
      !this.cdr['destroyed']
    this.cdr.markForCheck();
  }

  setPlaceholder(): void {

    this.showPlaceholder = this.parent.showEmptyValue && this.parent.view.placeholder && !this.parent.view.value;

  }

  processEvent(event, item: AbstractDropdownItem, view: InputViewModel = null): void {
    if (view == null && item != null)
      view = item.view;

    if (view == null)
      return;

    if (this.parent.notifyIfReadOnly(view, view.id))
      return;

    if (this.parent.notifyIfDisabled(view, view.id))
      return;

    // Show immediate change at client.    
    this.parent.view.value = view.id;
    this.parent.view.formattedValue = view.title;    
    this.selectedIconKey = view.icon;

    // Remove highlighting now, otherwise it will wait for server response and cause flickering.
    this.parent.view.highlight = false;

    this.setPlaceholder();

    // this.updateText(view);
    this.parent.notifyValueChange(view, view.id);
  }

  // TODO: Postpond dropdown placeholder text until approval.
  // Text to be displayed on the dropdown button
  // public text: string;
  // public placeHolderStyle: boolean = false;

  //public updateText(itemView: InputViewModel) {
  //  // Update text displayed on the dropdown button. Use selected value if possible, otherwise use default.        
  //  this.text = itemView.id == null ? `Choose ${this.parent.view.title.toLowerCase()}` : itemView.title;
  //  this.placeHolderStyle = itemView.id == null;
  //}

  readOnlyClick(): void {
    this.parent.readOnlyClick();
  }

  handleKeyup(event: KeyboardEvent): void {
    if (this.disabled)
      return;

    const keyCode = event.keyCode;
    const isArrowKey = keyCode === DOWN_ARROW || keyCode === UP_ARROW ||
      keyCode === LEFT_ARROW || keyCode === RIGHT_ARROW;
    const isOpenKey = keyCode === ENTER || keyCode === SPACE;

    if (isArrowKey || isOpenKey)
      return;

    const key = event.key;
    const found = this.itemsView.filter(x => !x.disabled && x.title != null && ('' + x.title).toLowerCase().charAt(0) == '' + key);

    if (found.length > 0) {
      if (this.expanded) {

        let items = Array.prototype.slice.call(this.dropdownMenu.nativeElement.querySelectorAll(this.SELECTOR_VISIBLE_ITEMS));

        let subIndex = 0;

        if (found.length > 1) {
          let activeIndex = items.indexOf(document.activeElement);

          subIndex = found.findIndex(x => this.itemsView.indexOf(x) == activeIndex) + 1;

          if (subIndex >= found.length)
            subIndex = 0;
        }

        let index = this.itemsView.indexOf(found[subIndex]);

        if (index > -1 && items.length > index)
          items[index].focus();
      }
      else {
        let subIndex = this.parent.view ? found.findIndex(x => x.id == this.parent.view.id) + 1 : 0;

        if (subIndex >= found.length)
          subIndex = 0;

        this.processEvent(event, null, found[subIndex]);
      }

    }
  }
}