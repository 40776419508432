import { Component, Inject, Input } from "@angular/core";
import { BaseSearchInputComponent } from "../baseSearchInputComponent";
import { InputViewModel } from "../../../../../../shared/components";
import { SearchCriteriaModelsProvider, SearchCriteriaHelper } from "../../providers";
import { AttributeField, SearchValueControlType } from "../../models";
import { ConfDataProperty, ConfSearchProperty } from "../../../../../shared/models";
import { ConfSearchCriteria } from "../../../../../shared/models";

@Component({
  selector: 'search-text-input',
  templateUrl: './searchTextInputComponent.html',
})
export class SearchTextInputComponent extends BaseSearchInputComponent {

  @Input()
  public queryView: InputViewModel;

  constructor(
    @Inject(SearchCriteriaModelsProvider) public modelsProvider: SearchCriteriaModelsProvider,
    protected searchCriteriaHelper: SearchCriteriaHelper
  ) {
    super(searchCriteriaHelper);
  }

  ngOnInit() {

    let attr: AttributeField = this.criteria.selectedAttribute;

    let icon: string;    
    if (attr.tag && attr.tag instanceof ConfDataProperty) {
      let confProp: ConfDataProperty = attr.tag as ConfDataProperty;
      let type = confProp.propertyType;
      let icon: string = type === 'Integer' || type === 'Boolean' || type === 'Double' ? 'unicodestring' : type.toLowerCase();      
    }

    this.queryView = this.searchCriteriaHelper.createInputView(attr.id, attr.id, attr.id, null, icon, this.width);
    this.queryView.disabled = attr.valueControlType == SearchValueControlType.None;
    this.queryView.value = attr.valueControlType == SearchValueControlType.None ? '' : this.extractValue;

  }

  /** Returns true if the this criteria has a value, otherwise false. */
  public get hasValue(): boolean {
    return this.queryView.value;
  }

  /** Returns the current user input value. */
  public get value(): any {
    return this.queryView.value;
  }

}