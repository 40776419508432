import { Component, Input, Output, EventEmitter, ElementRef, Inject, ChangeDetectorRef, ChangeDetectionStrategy, HostListener } from "@angular/core";

import { PopperConfig, Placement } from "./popoverInfo";
import { PopoverService } from "./popoverService";
import { ManagedSubscription } from "../../managedSubscription";

// TODO: How should we merge the popover and popper?
@Component({
  selector: 'popper-content',
  templateUrl: './popperContentComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopperContentComponent {

  data: PopperConfig = <PopperConfig>{ open: false, positionX: 0, placement: Placement.Right, calcPlacement: Placement.Right };

  @Output()
  positionChange = new EventEmitter<PopperConfig>();

  public popperStyle: CSSStyleDeclaration;

  @Input()
  public width: number;

  @Input()
  public ignoreMaximize = false;

  public messageSubscription: ManagedSubscription;

  constructor(
    public cd: ChangeDetectorRef,
    @Inject(PopoverService) public popoverService: PopoverService) {
  }

  ngOnInit(): void {
    this.messageSubscription = this.popoverService.getMessage().subscribe((info: PopperConfig) => {
      this.data = info;
      if (this.data.open) {
        this.positionChange.emit(this.data);
        this.setStyle();
      }

      this.cd.markForCheck();
    });
  }

  close(tag?: any): void {
    this.popoverService.close();
  }

  ngOnDestroy() {
    if (this.messageSubscription)
      this.messageSubscription.unsubscribe();
  }

  setStyle(): void {
    this.popperStyle = <CSSStyleDeclaration>{
      width: this.data.maximize && !this.ignoreMaximize ? '100%' : `${this.width}px`,
      height: !this.ignoreMaximize ? '100%' : `auto`,
      left: this.data.maximize && !this.ignoreMaximize ? '0' : `${this.data.positionX}px`,
      top: this.data.maximize && !this.ignoreMaximize ? '0' : `${this.data.positionY}px`,
      maxWidth: '100%'
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.positionChange.emit(this.data);
    this.setStyle();
  }

}