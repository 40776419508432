import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

import { IocIgnore } from "../../ioc/iocignoredecorator";
import { NodeEvent } from './treeEvents';

@Injectable()
export class TreeViewEventService {    
  public nodeExpand$: Subject<NodeEvent> = new Subject<NodeEvent>();
  public nodeCollapse$: Subject<NodeEvent> = new Subject<NodeEvent>();
  public nodeSelect$: Subject<NodeEvent> = new Subject<NodeEvent>();
  public nodeMenuClick$: Subject<NodeEvent> = new Subject<NodeEvent>();
  public nodeAddClick$: Subject<NodeEvent> = new Subject<NodeEvent>();
}