import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TabComponent } from "./tabComponent";
import { TabsContainerComponent } from "./tabsContainerComponent";
import { LinksContainerComponent } from "./linksContainerComponent";
import { ImageModule } from "../image";

export * from "./tabsContainerComponent";
export * from "./tabComponent";
export * from "./linksContainerComponent";
export * from "./tabModel";

@NgModule({
  imports: [
    CommonModule,
    ImageModule
  ],
  declarations: [
    TabsContainerComponent,
    TabComponent,
    LinksContainerComponent
  ],
  exports: [
    TabsContainerComponent,
    TabComponent,
    LinksContainerComponent
  ]
})
export class TabsModule {

}