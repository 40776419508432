import * as Immutable from "immutable";
import { BaseEntity } from "../../baseEntity";
import { ConfInfo } from "../entities/confInfo";
import { Comparison } from "../entities/comparison";
import { AbstractComparisonDataMessage } from "./messages/abstractComparisonDataMessage";
import { KeyValue } from "./keyValue";

export class ComparisonDataResponse extends BaseEntity {

  protected readonly COMPARISON_SESSION_ID = "comparisonSessionId";
  protected readonly CONF_IDS = "confIds";
  protected readonly DIFFERENCY_RESULT = "differencyResult";
  protected readonly BREADCRUMB_CONF_INFOS = "breadcrumbConfInfos";
  protected readonly CHILD_CONFS_ORDER = "childConfsOrder";
  protected readonly PATH = "path";
  protected readonly DETAIL = "detail";
  protected readonly MESSAGES = "messages";
  
  get comparisonSessionId(): number { return this.getInternalValue<number>(this.COMPARISON_SESSION_ID); }
  setComparisonSessionId(comparisonSessionId: number): this { return this.setInternalValue(this.COMPARISON_SESSION_ID, comparisonSessionId); }

  get confIds(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.CONF_IDS); }
  setConfIds(confIds: Immutable.List<number>): this { return this.setInternalValue(this.CONF_IDS, confIds); }

  get differencyResult(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.DIFFERENCY_RESULT); }
  setDifferencyResult(differencyResult: Immutable.List<number>): this { return this.setInternalValue(this.DIFFERENCY_RESULT, differencyResult); }

  get breadcrumbConfInfos(): Immutable.List<KeyValue<string, ConfInfo[]>> { return this.getInternalValue<Immutable.List<KeyValue<string, ConfInfo[]>>>(this.BREADCRUMB_CONF_INFOS); }
  setBreadcrumbConfInfos(breadcrumbConfInfos: Immutable.List<KeyValue<string, ConfInfo[]>>): this { return this.setInternalValue(this.BREADCRUMB_CONF_INFOS, breadcrumbConfInfos); }

  get childConfsOrder(): Immutable.List<KeyValue<string, ConfInfo[]>> { return this.getInternalValue<Immutable.List<KeyValue<string, ConfInfo[]>>>(this.CHILD_CONFS_ORDER); }
  setChildConfsOrder(childConfsOrder: Immutable.List<KeyValue<string, ConfInfo[]>>): this { return this.setInternalValue(this.CHILD_CONFS_ORDER, childConfsOrder); }

  get detail(): Comparison { return this.getInternalValue<Comparison>(this.DETAIL); }
  setResult(detail: Comparison): this { return this.setInternalValue(this.DETAIL, detail); }

  get messages(): Immutable.List<AbstractComparisonDataMessage> { return this.getInternalValue<Immutable.List<AbstractComparisonDataMessage>>(this.MESSAGES); }
  setMessages(messages: Immutable.List<AbstractComparisonDataMessage>): this { return this.setInternalValue(this.MESSAGES, messages); }

  get path(): string { return this.getInternalValue<string>(this.PATH); }
  setPath(path: string): this { return this.setInternalValue(this.PATH, path); }

}