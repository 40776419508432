import * as Immutable from "immutable";

import { ImmutableObject } from "../../../../shared/immutableObject";
import { ProductDataState } from "./productDataState";
import { PageState } from "./pageState";
import { ConfDataState } from "./confDataState";
import { AccountDataState } from "./accountDataState"
import { GlobalDataState } from "./globalDataState";
import { SearchDataState } from "./searchDataState";
import { ActionInfo } from "../actionInfo";
import { ApiMessage } from "../../models/responses/messages";
import { ComparisonDataState } from "./comparisonDataState";
import { PushMessageState } from "./pushMessageState";

export class AppState extends ImmutableObject {

  public readonly PRODUCT_DATA = "productData";
  public readonly CONFIGURATION_DATA = "configurationData";
  public readonly UI_DATA = "uiData";
  public readonly ACCOUNT_DATA = "accountData";
  public readonly GLOBAL_DATA = "globalData";
  public readonly SEARCH_DATA = "searchData";
  public readonly COMPARISON_DATA = "comparisonData";
  public readonly INFO_BY_ACTION_ID = "infoByActionId";
  public readonly MESSAGES = "messages";
  public readonly PUSH_MESSAGES = "pushMessages";
    
  // Product data state
  get productData(): ProductDataState { return this.getInternalValue<ProductDataState>(this.PRODUCT_DATA); }
  setProductData(productData: ProductDataState): this { return this.setInternalValue(this.PRODUCT_DATA, productData); }  

  // Configuration data state
  get configurationData(): ConfDataState { return this.getInternalValue<ConfDataState>(this.CONFIGURATION_DATA); }
  setConfigurationData(configurationData: ConfDataState): this { return this.setInternalValue(this.CONFIGURATION_DATA, configurationData); }  

  // Search data state
  get searchData(): SearchDataState { return this.getInternalValue<SearchDataState>(this.SEARCH_DATA); }
  setSearchData(searchData: SearchDataState): this { return this.setInternalValue(this.SEARCH_DATA, searchData); }  

  get comparisonData(): ComparisonDataState { return this.getInternalValue<ComparisonDataState>(this.COMPARISON_DATA); }
  setComparisonData(comparisonData: ComparisonDataState): this { return this.setInternalValue(this.COMPARISON_DATA, comparisonData); }  

  // Page states.
  get uiData(): PageState { return this.getInternalValue<PageState>(this.UI_DATA); }
  setUiData(uiData: PageState): this { return this.setInternalValue(this.UI_DATA, uiData); }  

  // Global data state
  get globalData(): GlobalDataState { return this.getInternalValue<GlobalDataState>(this.GLOBAL_DATA); }
  setGlobalData(globalData: GlobalDataState): this { return this.setInternalValue(this.GLOBAL_DATA, globalData); }

  // User data state
  get accountData(): AccountDataState { return this.getInternalValue<AccountDataState>(this.ACCOUNT_DATA); }
  setAccountData(accountData: AccountDataState): this { return this.setInternalValue(this.ACCOUNT_DATA, accountData); }  

  // Resulted data by Action id
  get infoByActionId(): Immutable.Map<number, ActionInfo> { return this.getInternalValue<Immutable.Map<number, ActionInfo>>(this.INFO_BY_ACTION_ID); }
  setInfoByActionId(infoByActionId: Immutable.Map<number, ActionInfo>): this { return this.setInternalValue(this.INFO_BY_ACTION_ID, infoByActionId); }

  get messages(): Immutable.Map<string, Immutable.List<ApiMessage>> { return this.getInternalValue<Immutable.Map<string, Immutable.List<ApiMessage>>>(this.MESSAGES); }
  setMessages(messages: Immutable.Map<string, Immutable.List<ApiMessage>>): this { return this.setInternalValue(this.MESSAGES, messages); }

  get pushMessages(): PushMessageState { return this.getInternalValue<PushMessageState>(this.PUSH_MESSAGES); }
  setPushMessages(pushMessages: PushMessageState): this { return this.setInternalValue(this.PUSH_MESSAGES, pushMessages); }
}