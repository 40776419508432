import { ImmutableObject } from "../../../../shared/immutableObject";

export class ConfRevision extends ImmutableObject {

  protected readonly CONFIGURATION_ID = "configurationId";
  protected readonly TITLE = "title";
  protected readonly LAST_UPDATED = "lastUpdated";

  get configurationId(): number { return this.getInternalValue<number>(this.CONFIGURATION_ID); }
  setConfigurationId(configurationId: number): this { return this.setInternalValue(this.CONFIGURATION_ID, configurationId); }

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get lastUpdated(): string { return this.getInternalValue<string>(this.LAST_UPDATED); }
  setLastUpdated(lastUpdated: string): this { return this.setInternalValue(this.LAST_UPDATED, lastUpdated); }
}