import { Host, Component, Optional, ViewChild, ElementRef, Input, forwardRef, Inject, ChangeDetectionStrategy, ChangeDetectorRef, HostListener } from '@angular/core';
import { BasePopupComponent } from './basePopupComponent';
import { BackdropComponent } from './backdropComponent';
import { PopupService } from './popupService';
import { BreakPointAccessor, Breakpoints } from "../../utils";
import { ImageComponent } from "../image";

@Component({
  selector: 'lightbox-popup',
  templateUrl: './lightboxPopupComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LightboxPopupComponent extends BasePopupComponent {

  rootElement: ElementRef;

  protected readonly DEFAULT_MIN_WIDTH: number = 250;
  protected readonly DEFAULT_MIN_HEIGHT: number = 250;
  protected readonly HEADER_HEIGHT: number = 50;  
  protected readonly FULL_LENGTH: string = '100%';

  @Input()
  image: string;

  public imageRealWidth: number;
  public imageRealHeight: number;
  public contentStyle: CSSStyleDeclaration;

  @ViewChild("imageRef")
  imageRef: ImageComponent;

  constructor(
    public cd: ChangeDetectorRef,
    public backdrop: BackdropComponent,
    public breakPointAccessor: BreakPointAccessor,
    public popupService: PopupService
  ) {
    super(cd, backdrop, breakPointAccessor, popupService);
  }

  ngOnInit() {

    // Default size
    this.width = this.toPixel(this.DEFAULT_MIN_WIDTH);
    this.height = this.toPixel(this.DEFAULT_MIN_HEIGHT);

    super.ngOnInit();
  }

  calculateSize(event): void {

    this.imageRealWidth = event.target.width;
    this.imageRealHeight = event.target.height;

    this.normalizeSize();

  }


  public normalizeSize(): void {

    if (this.imageRealHeight && this.imageRealWidth) {

      this.width = this.toPixel(this.imageRealWidth);
      this.height = this.toPixel(this.imageRealHeight + this.HEADER_HEIGHT + 4);
    }
    
    if (window.innerWidth < Breakpoints.sm.min)
      this.width = this.FULL_LENGTH;

    if (window.innerHeight < Breakpoints.sm.min)
      this.height = this.FULL_LENGTH;

    if (!this.contentStyle)
      this.contentStyle = <CSSStyleDeclaration>{};

    this.contentStyle.width = this.width;    
    this.contentStyle.height = this.height;
    this.contentStyle.minWidth = this.toPixel(this.DEFAULT_MIN_WIDTH);
    this.contentStyle.minHeight = this.toPixel(this.DEFAULT_MIN_HEIGHT);
    this.contentStyle.maxWidth = this.FULL_LENGTH;
    this.contentStyle.maxHeight = this.FULL_LENGTH;
    
  }

  toPixel(value: number): string {
    return String(value) + 'px';    
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {    
    this.normalizeSize();
  }

  closePopup(event): void {
    this.close();
    event.preventDefault();
  }

}