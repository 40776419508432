<div #visualObjectContainer>
  <div class="decoration visual-object-margin code-decoration" [class.border-style]="!showTitle"

       [style.max-height]="!showTitle && height"
       [style.max-width]="!showTitle && width"
       [style.width.%]="!showTitle && '100'">

    <gridview classes="px-0">
      <gridview-header *ngIf="showTitle" classes="decoration-header mx-0">
        <header-columntemplate size="auto" classes="header-image">
          <co-image width="24" height="24" type="svg" key="code" [imageSet]="imageSet"></co-image>
        </header-columntemplate>
        <header-columntemplate classes="header-title">
          {{decoration.title}}
        </header-columntemplate>
      </gridview-header>
      <div [class.px-3]="isRender" [class.py-2]="isRender" [style.max-height]="showTitle && height" [style.max-width]="showTitle && width" [style.width.%]="'100'">

        <decoration-codes-builder [valuesModel]="valuesModel"> </decoration-codes-builder>

      </div>
    </gridview>
  </div>
</div>