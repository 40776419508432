import * as Immutable from "immutable";

import { BaseObject } from "./baseObject";
import { EnumType } from "typescript";

export abstract class PropertyMapObject extends BaseObject
{
  constructor(public propertyMap: any) {
    super();
  }

  public readonly CLASS_NAME = "className";

  get className(): string {
    let cName = this.getInternalValue<string>(this.CLASS_NAME);
    if (!cName)
      return this.constructor.name;

    return cName;
  }

  public getInternalValue<T extends any>(key: string, enumType?: any): T {
        
    let value = null;

    if (this.propertyMap.get)
      value = this.propertyMap.get(key) as T;
    else
      value = this.propertyMap[key] as T;

    if (enumType) {
      return enumType[value] as T;
    }

    return value;
  }  
}