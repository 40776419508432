import * as Immutable from "immutable"

import { AbstractConfigurationMessage } from "./abstractConfigurationMessage"
import { ClearedParamInfo } from "./clearedParamInfo";

export class ClearedParamMessage extends AbstractConfigurationMessage {

  protected readonly CLEARED_PARAM_INFOS = "clearedParamInfos";
  
  get clearedParamInfos(): Immutable.List<ClearedParamInfo> { return this.getInternalValue<Immutable.List<ClearedParamInfo>>(this.CLEARED_PARAM_INFOS); }
  setClearedValues(clearedValues: Immutable.List<ClearedParamInfo>): this { return this.setInternalValue(this.CLEARED_PARAM_INFOS, clearedValues); }
}