import { Component, EventEmitter, Input, Output, SimpleChanges } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { InputViewModel } from "../../../../shared/components";
import { BaseComponent } from "../../../shared";
import { AccountUrlParams } from "../accountUrlParams";

@Component({
  selector: 'account-verify',
  templateUrl: './accountVerificationComponent.html'
})
export class AccountVerificationComponent extends BaseComponent  {

  @Input()
  public userTitle: string;

  @Input()
  public userDescription: string;

  @Input()
  public userInputTitle: string;

  @Input()
  public codeTitle: string;

  @Input()
  public codeDescription: string;

  @Input()
  public codeInputTitle: string;

  @Input()
  public isNew: boolean = false;

  @Input()
  public errorMessage: string;

  @Input()
  public validateEmail: boolean = false;

  @Output()
  public userSubmit = new EventEmitter<InputViewModel>();

  @Output()
  public codeSubmit = new EventEmitter<InputViewModel>();

  public initProcess: boolean = true;  

  constructor(public activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit() {

    let username = this.activatedRoute.snapshot.paramMap.get(AccountUrlParams.KEY);
    this.initProcess = username == null; 

  }

}