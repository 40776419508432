import { UiAction } from "./uiAction";

export enum OperationType {
  Refresh,
  Redirect,
  ClosePopup,
  ClientPushMessage
}

export class UiEventOperation extends UiAction {

  protected readonly OPERATION_TYPE = "operationType";

  get operationType(): OperationType { return this.getInternalValue<OperationType>(this.OPERATION_TYPE); }
  setOperationType(operationType: OperationType): this { return this.setInternalValue(this.OPERATION_TYPE, operationType); }

}