import { BaseEntity } from "../../baseEntity";
import { RefObject } from "..";

export class CustomerSite extends BaseEntity {

  protected readonly NAME = "name";
  protected readonly CUSTOMER = "customer";
  
  get name(): string { return this.getInternalValue<string>(this.NAME); }
  setName(name: string): this { return this.setInternalValue(this.NAME, name); }

  get customer(): RefObject { return this.getInternalValue<RefObject>(this.CUSTOMER); }
  setCustomer(customer: RefObject): this { return this.setInternalValue(this.CUSTOMER, customer); }
}