import { Component, ContentChild, ElementRef, ChangeDetectorRef, ViewChild, ChangeDetectionStrategy } from "@angular/core";
import { AccordionToggleComponent } from "./accordionToggleComponent";
import { AccordionService, AccordionConfig } from "./accordionService";
import { AbstractAccordionGroupComponent } from "./abstractAccordionGroupComponent";

@Component({
  selector: "accordion-group",
  templateUrl: './accordionGroupComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionGroupComponent extends AbstractAccordionGroupComponent {

  @ContentChild(AccordionToggleComponent)
  toggler: ElementRef;

  @ViewChild("accordionGroup")
  accordionGroupElement: ElementRef;


  constructor(private cdr: ChangeDetectorRef, public accordionService: AccordionService) {
    super();
  }

  checkAndToggle() {
    // if custom toggle element is supplied, then do nothing, custom toggler will take care of it
    if (this.toggler)
      return;

    this.toggle();
  }

  toggle() {
    if (this.disabled)
      return;

    const isOpenedBeforeWeChange = this.isOpened;

    this.accordionService.sendMessage({ tag: this } as AccordionConfig);
    
    this.isOpened = !isOpenedBeforeWeChange;
    if (this.isOpened) {
      this.onOpen.emit({ isOpened: this.isOpened, clickedGroup: this.accordionGroupElement });
    } else {
      this.onClose.emit({ isOpened: this.isOpened, clickedGroup: this.accordionGroupElement });
    }
    this.onToggle.emit({ isOpened: this.isOpened, clickedGroup: this.accordionGroupElement });
  }

  ngAfterViewInit() {

    if (this.accordionGroupElement && this.scrollInToView && this.isOpened)
      setTimeout(() => {
        this.accordionGroupElement.nativeElement.scrollIntoView(true);
      }, 0);

  }

  open() {
    super.open();

    this.cdr.markForCheck();
  }

  close() {
    super.close();

    this.cdr.markForCheck();
  }
}