import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { DebouncedTextBoxComponent } from "./debouncedTextBoxComponent";
import { TextBoxModule } from "../textBox";

export * from "./debouncedTextBoxComponent";

@NgModule({
  imports: [
    CommonModule,
    TextBoxModule,
  ],
  declarations: [
    DebouncedTextBoxComponent
  ],
  exports: [
    DebouncedTextBoxComponent
  ]
})
export class DebouncedTextBoxModule {

}