import { Component, Input } from "@angular/core";
import { BaseComponent } from "../..";
import { CodeDecorationValuesModel } from "./codeDecorationValueModel";
import { ProductDataStore } from "../../providers/productData";
import { CodeDecoration } from "../../models";

@Component({
  selector: 'decoration-codes-builder',
  templateUrl: './decorationCodesBuilderComponent.html',
})
export class DecorationCodesBuilderComponent extends BaseComponent {

  public calcVisible: boolean = false;
  public storedVisible: boolean = false;

  @Input()
  public showInlineTitle: boolean = false;

  public decorationTitle: string;
  
  @Input()
  public valuesModel: CodeDecorationValuesModel

  constructor(public productStore: ProductDataStore) {
    super();
  }

  ngOnInit() {

    // Set the visibility

    this.storedVisible = !!this.valuesModel.values.find(x => x.storedValue != null);
    this.calcVisible = !!this.valuesModel.values.find(x => x.calcValue != null);

    let decoration = this.productStore.getEntity<CodeDecoration>(this.valuesModel.decorationId);

    this.decorationTitle = decoration.title;

  }
  
}