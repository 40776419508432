import * as Immutable from "immutable";

import { BaseMessage } from "./baseMessage";

export abstract class AbstractSearchDataMessage extends BaseMessage {

  protected readonly CONFIGURATION_SEARCH_ID = "configurationSearchId";
  protected readonly SUCCESS = "success";
  protected readonly MESSAGES = "messages";

  get configurationSearchId(): number { return this.getInternalValue<number>(this.CONFIGURATION_SEARCH_ID); }
  setConfigurationSearchId(configurationSearchId: number): this { return this.setInternalValue(this.CONFIGURATION_SEARCH_ID, configurationSearchId); }

  get success(): boolean { return this.getInternalValue<boolean>(this.SUCCESS); }
  setSuccess(success: boolean): this { return this.setInternalValue(this.SUCCESS, success); }

  get messages(): Immutable.List<string> { return this.getInternalValue<Immutable.List<string>>(this.MESSAGES); }
  setMessages(messages: Immutable.List<string>): this { return this.setInternalValue(this.MESSAGES, messages); }
}