<div *ngIf="view != null" class="datepicker" [style.min-width]="view.width" [style.max-width.%]="100">

  <label class="static-label-above">{{view.title}}</label>
  <div class="input-container text-field control-border-b" [class.has-icon]="view.icon" [class.highlight]="view.highlight" [class.has-focus]="hasFocus">

      <input class="form-control" placeholder="dateFormat"
             name="datepickerInput" 
             autocomplete="off"
             [(ngModel)]="model"
             [showWeekNumbers]="weekNumbersEnabled"
             (change)="valuechange($event)"
             (click)="openDatepicker($event, inputDatapicker)"
             [disabled]="view.readOnly"             
             [navigation]="navigation" 
             [outsideDays]="outsideDays"
             (document:click)="closeFix($event, inputDatapicker)"
             (dateSelect)="onDaySelect($event)" ngbDatepicker #inputDatapicker="ngbDatepicker">

  </div>
  <div *ngFor="let error of _errors" class="form-control-feedback d-block">{{error}}</div>


</div>
