import { Inject, Component, Input, ChangeDetectorRef } from "@angular/core";
import * as Immutable from "immutable";
import { ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { ListRowModel } from "../shared/listRowModel";
import { ListModel, ListItemModel } from "../../../shared/components/list";
import { ConfValue, Param, Tab, VisualObject, ConfIntValue, ConfBoolValue, ConfDoubleValue, ConfStringValue, ConfMultiChoiceValue, ConfLookupValue, ParamValue, ConfMultiSetValue, MultiChoiceValue, ConfBomValue, ConfUIItem, ConfAttributeValue, AttributeDecoration, AttributeDecorationItem } from "../../../shared/models";
import { ProductDataStore } from "../../../shared/providers/productData";
import { Subscription } from "rxjs";
import { ChangeDetectionStrategy } from "@angular/core";
import { ListModelCacheService } from "../providers/listModelCacheService";
import { IconTools } from "../../../shared/providers/iconTools";
import { AbstractAttributesDetailComponent } from "../attributes/abstractAttributesDetailComponent";
import { PopupService, PopoverService } from "../../../../shared/components";
import { GlobalDataStore } from "../../../shared/providers/globalData";

@Component({
  selector: 'tab-detail',
  templateUrl: './tabDetailComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabDetailComponent extends AbstractAttributesDetailComponent {

  public tab: Tab;
  public icon: string;
  public isVault: boolean = false;

  @Input() public tabId: number;
  public activeParamId: number = -1;

  public highlightChangeSubscription: Subscription;  

  constructor(
    public cdr: ChangeDetectorRef,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(ProductDataStore) public productStore: ProductDataStore,
    @Inject(IconTools) public iconTools: IconTools,
    @Inject(ListModelCacheService) public modelsService: ListModelCacheService,
    public popupService: PopupService,
    public globalDataStore: GlobalDataStore,
    public popoverService: PopoverService
  ) {
    super(cdr, confStore, confPageSessionService, productStore, modelsService, popupService, globalDataStore, popoverService);
  }

  init(): void {

    this.rows = this.rows.clear();
    this.tab = this.productStore.getEntity<Tab>(this.confUIItem.id); // Read the tab.
    this.setVisibleParamsAndAttributes();

    // Set tab icon    
    this.isVault = !!this.tab.iconRelativeUrl;
    this.icon = this.tab.iconRelativeUrl ? this.tab.iconRelativeUrl : 'folder';
  }

  setVisibleParamsAndAttributes(): void {

    // Create row and column models
    // Only one row consisting of all parameters
    let row = new ListRowModel();
    this.rows = this.rows.push(row);

    // Grab all children(params)
    this.confUIItem.items.forEach(visualObjectUIItem => {

      let visualObject: VisualObject = this.productStore.getEntity<VisualObject>(visualObjectUIItem.id);

      if (visualObject instanceof Param) {
        row.cells.push(this.getParamUIObject(visualObject))
      }

      if (visualObject instanceof AttributeDecoration) {
        row.cells.push(...this.getAttributeUIObjects(visualObject));
      }
    });

    // Add empty cell to complete the border
    if (this.shouldAddEmptyCell(row.cells.length)) {
      row.cells.push(new ListModel());
    }
  }

  public getParamUIObject(param: Param): ListModel {

      let confValue: ConfValue = this.confStore.getConfValue(this.configurationId, this.confSessionId, param.visualObjectId) as ConfValue;

      // TODO: Temporary fix. Remove after configurator refactoring.
      if (!confValue)
        return;

      let list: ListModel = this.modelsService.getCachedIfValueEqual(param.longId.toString(), confValue);
      if (list)
        return list;

      list = new ListModel();
      list = list.setTitle(param.title);
      list = param.iconRelativeUrl ? list.setIcon(param.iconRelativeUrl) : list.setIcon(param.className.toLowerCase());
      if (param.iconRelativeUrl) {
        list = list.setImageSet("primary");
        list = list.setIsVaultIcon(true);
      }
      list = list.setEmptyValueText(this.strings.NoValue);
      list = list.setIsPrintView(this.isPrintView);
      list = list.setId(param.longId.toString());
      list = list.setTag(confValue);

      let items: Immutable.List<ListItemModel> = list.items;
      if (!items)
        items = Immutable.List<ListItemModel>();

      // Special case for multi choice.
      if (confValue instanceof ConfMultiChoiceValue) {

        // Find the corresponding UIItem to check the visibility of the parameter
        let uiVisibleItem: ConfUIItem = this.confUIItem.items.find(item => item.id == param.longId);
        let confMCValue: ConfMultiChoiceValue = confValue as ConfMultiChoiceValue;

        if (confMCValue.value)
          confMCValue.value.forEach((confMultSetValueId: number) => {

            // Don't continue if the confMultiSetValue is not found in the uiVisibleItem's list.
            if (!uiVisibleItem || uiVisibleItem.items.findIndex(x => x.id == confMultSetValueId) < 0)
              return;

            // Multiset conf value
            let confMultiSetValue: ConfMultiSetValue = this.confStore.getConfValue(this.configurationId, this.confPageSessionService.confSessionId, confMultSetValueId) as ConfMultiSetValue;

            // <ParamValue>
            let paramValue: MultiChoiceValue = this.productStore.getEntity(confMultSetValueId) as MultiChoiceValue;

            let item: ListItemModel = new ListItemModel();
            item = item.setTitle(paramValue.title);

            item = item.setIcon(this.iconTools.boolValueIcon(confMultiSetValue.value));
            item = item.setImageSet(this.iconTools.boolValueTheme(confMultiSetValue.value));
            item = item.setClasses("pl-0");
            item = item.setIsPrintView(this.isPrintView);

            // Add into list.
            items = items.push(item);
            list = list.setItems(items);

          });
      }
      
      else {
        // Set conf value.
        let item: ListItemModel = new ListItemModel();
        
        if (confValue instanceof ConfBoolValue) {
          
          item = item.setIcon(this.iconTools.boolValueIcon(confValue.value));
          item = item.setImageSet(this.iconTools.boolValueTheme(confValue.value));
          item = item.setShowIconOnly(true);
          item = item.setClasses("pl-0 pt-2");

        }
        else item = item.setTitle(this.getValue(confValue));

        // Add into list.
        items = items.push(item);
        list = list.setItems(items);
      }

      // Add into cells.
      this.modelsService.addIntoCache(list);
      return list;
  }

  public getValue(confValue: ConfValue): any {
    if (confValue instanceof ConfIntValue)
      return (confValue as ConfIntValue).formattedValue;

    if (confValue instanceof ConfBoolValue)
      return (confValue as ConfBoolValue).value;

    if (confValue instanceof ConfDoubleValue)
      return (confValue as ConfDoubleValue).formattedValue;

    if (confValue instanceof ConfStringValue)
      return (confValue as ConfStringValue).value;

    if (confValue instanceof ConfLookupValue) {

      let selectedId = (confValue as ConfLookupValue).value
      if (selectedId) {
        let paramValue = this.productStore.getEntity<ParamValue>(selectedId);
        if (paramValue) 
          return paramValue.title;
        else
          return "";
      }
      return selectedId
    }

  }

  public getAttributeUIObjects(attributeDecoration: AttributeDecoration): ListModel[] {

    let conf = this.confPageSessionService.activeConfiguration;

    // check if stored or both? don't send any attribute values if only stored attribute values should be shown?

    let listModels = [];

    let visibleAttributeItemById: Map<number, AttributeDecorationItem> = new Map<number, AttributeDecorationItem>();

    attributeDecoration.items.forEach(x => {

      visibleAttributeItemById.set(x.attributeId, x);

      let listModel = this.getAttributesDetail(x.attributeId, visibleAttributeItemById);

      if (listModel)
        listModels.push(listModel);
    });

    return listModels;
  }

  subscribeDataChange(): void {

    this.confStore.onConfigurationValuesChange(this.configurationId, this.confSessionId, (propValues) => {

      this.init();
      this.cdr.markForCheck();

    }).unsubscribeOn(this.unsubscribeSubject);

    super.subscribeDataChange();
  }


}