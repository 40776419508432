import { Injectable } from "@angular/core";
import * as Immutable from "immutable";
import { ConfInfo, KeyValue } from "../../../../../../../core/pages/shared/models";
import { ManagedSubject } from "../../../../../../../core/shared";
import { IDraggableDataInfo } from "../models/comparisonDraggableNodeArgs";
import { ComparisonDraggableNode, ComparisonDraggableRowNodes } from "../models/comparisonDraggableRowNodes";

@Injectable()
export class ComparisonDraggableDataProvider {

  protected rows: Immutable.List<ComparisonDraggableRowNodes> = Immutable.List<ComparisonDraggableRowNodes>();

  private subject = new ManagedSubject<any>(null, false, 0, false, false); 
    
  createNode(id: string, icon: string, text: string, desc: string, compact: string, path: string, isEmpty: boolean = false): ComparisonDraggableNode {

    return <ComparisonDraggableNode> {

      id: id,

      icon: icon,

      text: text,

      description: desc,

      path: path,

      compact: compact,

      isEmpty: isEmpty

    };
  }

  convertToRowStructure(confsByKey: Immutable.List<KeyValue<string, ConfInfo[]>>): Immutable.List < ComparisonDraggableRowNodes > {

    // Results
    let rows: Array<ComparisonDraggableRowNodes> = new Array<ComparisonDraggableRowNodes>()

    for (let keyValueSet of confsByKey.toArray()) {

      let rowConfInfos: ConfInfo[] = keyValueSet.value;

      // Result row
      let row: ComparisonDraggableRowNodes = this.createNewRow('nodes');

      // Loop through each level configurations.
      rowConfInfos.forEach(conf => {

        let node: ComparisonDraggableNode;
        
        // Add empty node for small trees.
        if (conf) {

          // Create tree node by conf data.          
          node = this.createNode(conf.longId.toString(), 'configuration', conf.text, conf.description, conf.compact, keyValueSet.key, false);
        }
        else {

          // Create empty node          
          node = this.createEmptyNode();
        }

        row.nodes.push(node);
      });
      
      rows.push(row);
    }

    this.rows = Immutable.List(rows);
    return this.rows;
  }

  public getIds(): Array<Array<number>> {

    let idsResults = new Array<Array<number>>();

    // Loop through rows
    if (this.rows)
      this.rows.forEach(row => {

        // Read conf ids.
        let arr: Array<number> = row.nodes.map(node => node.isEmpty ? null : Number(node.id));
        idsResults.push(arr);

      });

    return idsResults;
  }

  uniqueId(prefix: string): string {
    return `${prefix}_${(new Date()).getMilliseconds()}_${Math.floor((Math.random() * 999) + 1)}`;
  }

  /**
   * Swaps the node at given index.
   * @param row1
   * @param row2
   * @param nodeIndex
   */
  swapNode(rowId1: string, rowId2: string, nodeIndex: number): void {

    let row1: ComparisonDraggableRowNodes = this.rows.find(r => r.id == rowId1);
    let row2: ComparisonDraggableRowNodes = this.rows.find(r => r.id == rowId2);

    let node1: ComparisonDraggableNode = row1.nodes[nodeIndex];
    let node2: ComparisonDraggableNode = row2.nodes[nodeIndex];

    row1.nodes.splice(nodeIndex, 1, node2);
    row2.nodes.splice(nodeIndex, 1, node1);

  }

  protected createNewRow(prefixId: string, addEmptyNodes: boolean = false, numberOfEmptyNodes?: number): ComparisonDraggableRowNodes {

    let row: ComparisonDraggableRowNodes = new ComparisonDraggableRowNodes();
    row.id = this.uniqueId(prefixId);
    row.nodes = [];

    if (addEmptyNodes && numberOfEmptyNodes) {

      for (let index = 0; index < numberOfEmptyNodes; index++) {
        row.nodes.push(this.createEmptyNode());
      }

    }

    return row;
  }

  createAndAddIntoList(prefixId: string, rowIndex: number, addEmptyNodes: boolean = false): ComparisonDraggableRowNodes {

    let row = this.createNewRow(prefixId, addEmptyNodes, this.numberOfColumns);
    this.rows = this.rows.insert(rowIndex, row);

    return row;
  }

  createEmptyNode(): ComparisonDraggableNode {

    let id = this.uniqueId('empty');
    // In I.E/Edge browsers, It shows the 'null' text if undefined. To prevent this message, set empty text to 'Title', 'Description', 'Compact'.
    let node = this.createNode(id, null, '', '', '', '', true);

    return node
  }

  removeEmptyRows(): void {

    let result: Immutable.List<ComparisonDraggableRowNodes> = Immutable.List<ComparisonDraggableRowNodes>();
    this.rows.forEach(row => {

      if (!row.nodes.every(node => node.isEmpty))        
        result = result.push(row);

    });

    this.rows = result;

  }

  getRow(rowId: string): ComparisonDraggableRowNodes {
    return this.rows.find(row => row.id == rowId);
  }

  getRowByNodeId(nodeId: string): ComparisonDraggableRowNodes {

    for (let row of this.rows.toArray()) {

      if (row.nodes.findIndex(node => node.id == nodeId) > -1)
        return row;

    }

    return null;
  }

  getNodeIndex(id: string): number {

    let row = this.getRowByNodeId(id);

    if (!row)
      return -1;

    return row.nodes.findIndex(node => node.id == id);
  }

  getRows() { return this.rows; }

  get numberOfColumns(): number {
    return this.rows.first().nodes.length;
  }

  public send<T>(info: IDraggableDataInfo<T>): void {

    this.subject.nextValue(info);

  }

  public getMessage(): ManagedSubject<any> {
    return this.subject;
  }

}