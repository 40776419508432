import * as Immutable from "immutable";
import { ConfValue } from "./confValue";

export class ConfIntValue extends ConfValue {

  protected readonly VALUE = "value";
  protected readonly FORMATTED_VALUE = "formattedValue";

  get value(): number { return this.getInternalValue<number>(this.VALUE); }
  setValue(value: number): this { return this.setInternalValue(this.VALUE, value); }

  get formattedValue(): string { return this.getInternalValue<string>(this.FORMATTED_VALUE); }
  setFormattedValue(formattedValue: string): this { return this.setInternalValue(this.FORMATTED_VALUE, formattedValue); }
}