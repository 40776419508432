import * as Immutable from "immutable";

import { ApiMessage } from "./apiMessage";

export class CacheReloadMessage extends ApiMessage {
    
  readonly USER_DATA_ONLY = "userDataOnly";
  readonly SUCCESS = "success";
  readonly MESSAGES = "messages";
  
  get userDataOnly(): boolean { return this.getInternalValue<boolean>(this.USER_DATA_ONLY); }
  setUserDataOnly(userDataOnly: boolean): this { return this.setInternalValue(this.USER_DATA_ONLY, userDataOnly); }  

  get success(): boolean { return this.getInternalValue<boolean>(this.SUCCESS); }
  setSuccess(success: boolean): this { return this.setInternalValue(this.SUCCESS, success); }

  get messages(): Immutable.List<string> { return this.getInternalValue<Immutable.List<string>>(this.MESSAGES); }
  setMessages(message: Immutable.List<string>): this { return this.setInternalValue(this.MESSAGES, message); }
}