import { Injectable, HostListener } from "@angular/core";
import { ImageSets } from "./imageSets";

@Injectable()
export class ImageSetAccessor {

  protected imageSetsMap: Map<string, string>;

  constructor() {
    this.imageSetsMap = new Map<string, string>();
    this.loadAllImageSets();
  }

  protected loadAllImageSets(): void {

    Object.keys(ImageSets).forEach(x => {

      const enumValue = ImageSets[x];
      const imageSetValue = window.getComputedStyle(document.body).getPropertyValue(enumValue);

      if (imageSetValue)
        this.imageSetsMap.set(enumValue, imageSetValue.trim());

    });

  }

  public get(key: string): string {
    return this.imageSetsMap.get(key);
  }
  
}

