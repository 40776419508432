<div id="profile" *ngIf="visible">
  <h3>{{strings.Profile}}</h3>
  <div class="row py-3">
    <div class="col-auto pm-color-darker">{{strings.Username}}:</div>
    <div class="col-auto pl-0">
      <label class="font-italic username">{{username}}</label>
    </div>
  </div>
  <!-- Put the change password directly under the username. This will help password storages to store the correct username after changing password.
    (They look at the last input field directly before the password field)-->
  <change-password-popup></change-password-popup>

  <text-box [view]="nameView" autocomplete="name"></text-box>
  <text-box [view]="emailView" type="email" autocomplete="email"></text-box>
  <text-box [view]="companyView" autocomplete="organization"></text-box>
  <text-box [view]="phoneNumberView" type="tel" autocomplete="tel"></text-box>
  <text-box [view]="mobileNumberView" type="tel" autocomplete="tel"></text-box>

  <div class="row">
    <div class="col-auto">
      <button class="btn btn-primary" (click)="updateSettings()">{{strings.Update}}</button>
    </div>
    <div id="changePassword" class="col text-right">
      <div role="button" (click)="showChangePassword()">
        <co-image width="20" height="20" type="svg" key="password"></co-image>
        <span class="pm-color-darker pl-3">{{strings.ChangePassword}}</span>
      </div>
    </div>
  </div>
</div>