import * as Immutable from "immutable";
import { BaseEntity } from "../../baseEntity";
import { Decoration } from "./decoration";
import { AttributeDecorationItem } from './attributeDecorationItem';

export class AttributeDecoration extends Decoration {

  protected readonly HIDE_ATTRIBUTES_WITH_EMPTY_VALUES = "hideAttributesWithEmptyValues";
  protected readonly HIDE_EMPTY_DECORATION = "hideEmptyDecoration";
  protected readonly ITEMS = "items";

  get hideAttributesWithEmptyValues(): boolean { return this.getInternalValue<boolean>(this.HIDE_ATTRIBUTES_WITH_EMPTY_VALUES); }
  setHideAttributesWithEmptyValues(hideAttributesWithEmptyValues: boolean): this { return this.setInternalValue(this.HIDE_ATTRIBUTES_WITH_EMPTY_VALUES, hideAttributesWithEmptyValues); }

  get hideEmptyDecoration(): boolean { return this.getInternalValue<boolean>(this.HIDE_EMPTY_DECORATION); }
  setHideEmptyDecoration(hideEmptyDecoration: boolean): this { return this.setInternalValue(this.HIDE_EMPTY_DECORATION, hideEmptyDecoration); }

  get items(): Immutable.List<AttributeDecorationItem> { return this.getInternalValue<Immutable.List<AttributeDecorationItem>>(this.ITEMS); }
  setItems(value: Immutable.List<AttributeDecorationItem>): this { return this.setInternalValue(this.ITEMS, value); }

}