import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
import { NotificationService } from "./notificationService";
import { NotificationComponent } from "./notificationComponent";
import { ImageModule } from "../image";
import { SafeHtmlModule } from "../../../shared/utils/pipes";

export * from "./notificationService";
export * from "./notificationComponent";
export * from "./notificationInfo";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ImageModule,
    SafeHtmlModule
  ],
  declarations: [
    NotificationComponent    
  ],
  providers: [
    NotificationService
  ],
  exports: [
    NotificationComponent
  ]  
})
export class NotificationModule {

}