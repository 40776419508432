<div #visualObjectContainer>
  <div class="visual-object-margin text-decoration decoration" [class.border-style]="!showTitle" [style.max-width.%]="'100'" >
    <gridview>
      <gridview-header *ngIf="showTitle" classes="decoration-header">
        <header-columntemplate size="auto" classes="header-image">
          <co-image width="24" height="24" type="svg" key="text" [imageSet]="imageSet"></co-image>
        </header-columntemplate>
        <header-columntemplate classes="header-title">
          <span  [innerHtml]="decoration.title | safeHtml"></span>
        </header-columntemplate>
      </gridview-header>
      <gridview-row classes="pt-2">
        <columntemplate>
          <p class="multiline" [innerHtml]="decoration.text | safeHtml"></p>
        </columntemplate>
      </gridview-row>
    </gridview>
  </div>
</div>