import { Component, ViewEncapsulation, Inject, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';

import { AppStoreSubscriptionManager, RouteRedirector, ModelFactory, RouteNames } from "../../shared/providers";
import { PageComponent } from "../../shared";
import { SaveSearchMessage } from "../../shared/models";
import { ConfSearchModelCacheService } from "./confSearchModelCacheService";

import { SearchDataStore, SearchDataMessageProvider } from "../../shared/providers/searchData";
import { PageStore } from "../../shared/providers/page";
import { ManagedSubscription } from "../../../shared/managedSubscription";
import { AccountDataStore } from "../../shared/providers/accountData";
import { QuerySourceTypes } from "../../shared/models/responses/searchResult";
import { SearchSelectionCacheService } from "./result/searchSelectionCacheService";
import { SearchResultsDataHandler } from "./result/searchResultsDataHandler";

@Component({
  encapsulation: ViewEncapsulation.None,
  templateUrl: './searchComponent.html',
  providers: [AppStoreSubscriptionManager, SearchSelectionCacheService, SearchResultsDataHandler]
})
export class SearchComponent extends PageComponent {

  public saveMessageSubscription: ManagedSubscription;
  public databaseMode: boolean;
  public dataLoaded: boolean = true;
  
  constructor(
    @Inject(AppStoreSubscriptionManager) public appStoreSubscriptionManager: AppStoreSubscriptionManager,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    @Inject(ConfSearchModelCacheService) public searchModelCache: ConfSearchModelCacheService,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    @Inject(SearchDataStore) public searchDataStore: SearchDataStore,
    @Inject(PageStore) public pageStore: PageStore,
    @Inject(SearchDataMessageProvider) public searchDataMesssageProvider: SearchDataMessageProvider,
    @Inject(AccountDataStore) public accountDataStore: AccountDataStore,
    @Inject(ActivatedRoute) public activatedRoute: ActivatedRoute,
    @Inject(SearchResultsDataHandler) public searchDataHandler: SearchResultsDataHandler,
    protected router: Router,
    protected cd: ChangeDetectorRef
  ) {
    super();
   
  }

  ngOnInit() {

    super.ngOnInit();
    
    // User can't search -> redirect to selector page.
    if (!this.accountDataStore.getUser().systemAuthorization.canSearchConfigurations) {
      this.routeRedirector.redirectToSelector();
      // TODO show notification or?
      return;
    }

    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {

        // If the search page is opened for unsaved configurations, then databaseMode must be false, In this case we hide the Major title & search criteria for unsaved results.
        // But if the "Search" button from top header is clicked then we need to switch the databaseMode "True" to perform the real search.
        // If the "Search" button is clicked then Routing would be like "search/".
        if (event.url.indexOf(RouteNames.Result) < 0)
          this.databaseMode = true;

      }
      
    });

    this.pageStore.updateActiveRoute(RouteNames.Search);

    this.saveMessageSubscription = this.searchDataMesssageProvider.onMessages<SaveSearchMessage>(SaveSearchMessage.name, {
      next: (messages) => {
        let configurationSearch = this.searchDataStore.getConfigurationSearch(messages.first().configurationSearchId);
        // After save perform search so that the SearchSession is updated with the latest ConfigurationSearchDto

        this.searchDataHandler.performSearch(configurationSearch);
      },
      listenNewEventsOnly: true
    });

    this.databaseMode = this.searchModelCache.querySourceType == QuerySourceTypes.Database;
  }
  
  public get subTitle() { 
    return this.isExistingSearch ? `"${this.searchModelCache.searchModel.title}"` : null;
  }
  
  public get isExistingSearch(): boolean {
    return this.searchModelCache.searchModel != null && this.searchModelCache.searchModel.longId > 0;
  }

  public filterCriteriaChanged() {
    // Filter criteria is changed -> needs new session.
    //this.searchModelCache.searchSessionId = null;
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    if (this.saveMessageSubscription)
      this.saveMessageSubscription.unsubscribe();

    this.searchModelCache.reset();

    this.appStoreSubscriptionManager.dispose();
    super.ngOnDestroy();
  }


}