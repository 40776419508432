import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from "../../../../shared/providers";
import { ApiResponse, ConfDataRequest } from "../../../shared/models";
import { ApiResponseBuilder } from "../../../shared/providers/apiResponseBuilder";
import { ConfDataController } from "../../../shared/providers/configurationData";
import { GlobalDataStore } from "../../../shared/providers/globalData";


@Injectable()
export class BomController extends ConfDataController {

  constructor(
    public httpService: HttpService,
    public apiResponseBuilder: ApiResponseBuilder,
    public globalDataStore: GlobalDataStore
  ) {
    super(httpService, apiResponseBuilder, globalDataStore);
  }

  public getBomGridData(model: ConfDataRequest): Observable<ApiResponse> {
    return this.getConfigurationData(model);
  }

  public getBomDetailGridData(model: ConfDataRequest): Observable<ApiResponse> {
    return this.getConfigurationData(model);
  }

  public searchBom(model: ConfDataRequest): Observable<ApiResponse> {
    return this.getConfigurationData(model);
  }

  public createBom(model: ConfDataRequest): Observable<ApiResponse> {
    return this.getConfigurationData(model);
  }

  public editBom(model: ConfDataRequest): Observable<ApiResponse> {
    return this.getConfigurationData(model);
  }

  public removeBom(model: ConfDataRequest): Observable<ApiResponse> {
    return this.getConfigurationData(model);
  }

  public getItem(model: ConfDataRequest): Observable<ApiResponse> {
    return this.getConfigurationData(model);
  }

}