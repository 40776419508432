export class RegisterRoute {
  path?: string;
  component?: any;
  pathMatch?: string;
  redirectTo?: string;
}

export function Routing(route: RegisterRoute) {
  return (ctor: Function) => {}
}

