import { Inject, Injectable } from "@angular/core";

import { Conf, Tab, Param } from "../../shared/models";
import { ConfPageSessionService, ConfiguratorStore, ConfRouteParams } from "../providers";
import { AbstractUIVisibilityService } from "./abstractUIVisibilityService";
import { RouteRedirector, TabDisplayStyle } from "../../shared/providers";
import { ProductDataStore } from "../../shared/providers/productData";

@Injectable()
export class ConfVisiblityService extends AbstractUIVisibilityService {
  
  constructor(private pageSession: ConfPageSessionService, private confStore: ConfiguratorStore, private routeRedirector: RouteRedirector, private productStore: ProductDataStore) {
    super();
  }

  public isVisible(confId: number): boolean {

    if (this.pageSession.activeConfigurationId == confId)
      return true;

    let subConfId = this.routeRedirector.searchUrlQueryParam(ConfRouteParams.SUB_CONF_ID);
    return +subConfId == confId;
  }

  public isRoot(confId: number): boolean {
    return this.pageSession.activeConfigurationId == confId;
  }

  public isParamInAccordion(visualObjectId: number): boolean {

    let param: Param = this.productStore.getEntity(visualObjectId);
    if (!param)
      return false;

    let tab: Tab = this.productStore.getEntity(param.tabId);
    return tab && tab.displayStyle == TabDisplayStyle.Accordion || tab.displayStyle == TabDisplayStyle.AccordionChildren || tab.displayStyle == TabDisplayStyle.AccordionHeaderTab;

  }

  isSubConfVisible(): boolean {
    let subConfId = this.routeRedirector.searchUrlQueryParam(ConfRouteParams.SUB_CONF_ID);
    return !!subConfId;
  }

}