import { BaseEntity } from "../../baseEntity";
import { RefObject } from "..";

export class CustomerContact extends BaseEntity {

  protected readonly NAME = "name";
  protected readonly CUSTOMER = "customer";
  protected readonly CUSTOMER_SITE = "customerSite";

  get name(): string { return this.getInternalValue<string>(this.NAME); }
  setName(name: string): this { return this.setInternalValue(this.NAME, name); }

  get customer(): RefObject { return this.getInternalValue<RefObject>(this.CUSTOMER); }
  setCustomer(customer: RefObject): this { return this.setInternalValue(this.CUSTOMER, customer); }

  get customerSite(): RefObject { return this.getInternalValue<RefObject>(this.CUSTOMER_SITE); }
  setCustomerSite(customerSite: RefObject): this { return this.setInternalValue(this.CUSTOMER_SITE, customerSite); }
}