import { Component, Inject, Input, SimpleChanges } from '@angular/core';
import * as Immutable from "immutable";
import { CompositeMenuService, Identifier } from "..";
import { NodeActions, NotificationInfo, NotificationService, NotificationType, PopupService } from "../../../../shared/components";
import { BaseComponent } from "../../../shared";
import { ConfInfo, ConfDataResponse, OrderOperationMessage } from "../../../shared/models";
import { RouteRedirector } from "../../../shared/providers";
import { GlobalDataStore } from '../../../shared/providers/globalData';
import { ProductDataStore } from "../../../shared/providers/productData";
import { ConfiguratorStore, ConfiguratorUIStore, ConfPageSessionService } from "../../providers";
import { CompositeActions, CompositeActionService, ICompositeDataInfo } from './compositeActionService';
import { MenuItem } from "./navigation";
import { CompositeTreeDataProvider } from "./tree/compositeTreeDataProvider";

@Component({
  selector: 'composite-menu-controller',
  templateUrl: './compositeMenuControllerComponent.html'
})
export class CompositeMenuControllerComponent extends BaseComponent {

  public title: string;
  
  public showDetailForm: boolean = false;

  public selectedMenuItem: MenuItem;

  public menuItems: Immutable.List<MenuItem> = Immutable.List<MenuItem>();

  @Input()
  public configurationId: number;

  @Input()
  public nodeAction: string;

  constructor(
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfiguratorUIStore) public confUIStore: ConfiguratorUIStore,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(ProductDataStore) public productStore: ProductDataStore,
    @Inject(CompositeMenuService) public compositeMenuService: CompositeMenuService,
    @Inject(PopupService) public popupService: PopupService,
    @Inject(NotificationService) public notificationService: NotificationService,
    @Inject(CompositeTreeDataProvider) public treeDataProvider: CompositeTreeDataProvider,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(CompositeActionService) public actionService: CompositeActionService
  ) {
    super();
  }
  
  ngOnChanges(changes: SimpleChanges): void {

    if (this.nodeAction == NodeActions.AddClicked) {
      this.showDetailForm = true;
      this.selectedMenuItem = this.compositeMenuService.menuItemByName(Identifier.Add);
    }
    else {
      this.showDetailForm = false;
      this.selectedMenuItem = null;

    }
    this.updateMenuData();

  }

  ngOnInit() {

    this.actionService.getMessage().subscribe(x => {

      if (x.action == CompositeActions.NavigationEndedAfterDataLoad) {

        this.updateMenuData();

      }

    }).unsubscribeOn(this.unsubscribeSubject);

  }

  /**
   * Triggers for Add, Delete, Import, Copy, Move up and down.
   * @param item
   */
  onMenuItemClick(item: MenuItem): void {

    this.showDetailForm = item.hasDetail;
    this.selectedMenuItem = item;


    switch (item.name) {

      case Identifier.Import:

        if (!item.hasDetail && item.tag) {

          // TODO: Import ....

        }

        break;

      case Identifier.MoveUp:

        this.confStore.moveUpConfiguration(this.storeSession.activeConfigurationId, this.storeSession.confSessionId).then(x => {

          this.refreshTreeAndMenuData(x);
        });
        break;

      case Identifier.MoveDown:
        this.confStore.moveDownConfiguration(this.storeSession.activeConfigurationId, this.storeSession.confSessionId).then(x => {

          this.refreshTreeAndMenuData(x);
        });
        break;
    }

  }

  refreshTreeAndMenuData(response: ConfDataResponse): void {

    this.updateMenuData();

    if (response.messages.size > 0) {

      // Grab the message
      const operation = response.messages.get(0) as OrderOperationMessage;      
      this.orderChangedMessage(operation);

    }
    
  }

  orderChangedMessage(operation: OrderOperationMessage): void {

    this.notificationService.notify(<NotificationInfo>{
      title: operation.title || operation.displayStyle ? operation.title : operation.success ? this.strings.Success : this.strings.Error,
      message: operation.message,
      type: operation.displayStyle ? operation.displayStyle : operation.success ? NotificationType.Success : NotificationType.Error,
      selfClose: operation.success
    });

  }

  updateMenuData(): void {

    if (!this.configurationId)
      return;

    // Update the header title.
    let confInfo: ConfInfo = this.confStore.getConfInfo(this.configurationId, this.storeSession.confSessionId);

    // In most of the cases updateMenuData is triggered by ngOnChanges and ConfInfo would be guranteed. But if this method is called because of detailMenu close, then It might be possible that
    // current configuration where we are working on, has been deleted and configurationId could be the old reference. 
    // TODO: Require more work not to let through this method until activeConfiguration updated.
    if (!confInfo)
      return;

    this.title = confInfo.text;

    let conf = this.confStore.getConf(this.configurationId, this.storeSession.confSessionId);
    if (!conf)
      return;

    this.menuItems = Immutable.List(this.compositeMenuService.updateAccessibility(confInfo));

    if (this.selectedMenuItem) {

      // Update the selected menu item.
      this.selectedMenuItem = this.compositeMenuService.itemByName.get(this.selectedMenuItem.name);

      if (!this.selectedMenuItem.hasDetail || this.selectedMenuItem.hidden) {
        this.showDetailForm = false;
        this.selectedMenuItem = null;
      }
    }
  }

  public closeSidebarExtension($event: any): void {
    this.actionService.notify(<ICompositeDataInfo<number>>{ action: CompositeActions.ExitSidebarExt });
  }
  
  public returnToMenu($event): void {

    if (this.selectedMenuItem.name == Identifier.Add) {

      this.actionService.notify(<ICompositeDataInfo<number>>
        {
          focusedConfInfoId: this.configurationId,
          action: CompositeActions.MenuClick
        });    
      return;

    }

    this.showDetailForm = false;
    this.selectedMenuItem = null;
    this.updateMenuData();

    // Enable the user interaction on composite
    this.popupService.enable("compositePopup", true);
  }

}

