// TODO: Refactor enum name
export enum PopupIdentifiers {
  Disallowed = "disallowed-popup",
  Help = "help-popup",
  ReadOnly = "read-only-popup",
  ClearValue = "clear-value-popup",
  PriceList = "price-list-popup",
  PriceSetting = "price-setting-popup",
  Mandatory = "mandatory-popup",
  RuleConfirm = "rule-confirm-popup",
  Graphics = "graphics-popup",
  Code = "code-popup",
  RuleInformation = "rule-information-popup",
  ConfigurationSearch = "configuration-search-popup",
  BomComposite = "bom-composite-popup",
  BomImage = "bom-image-popup",
  BomCreatePopup = "bom-create-popup",
  BomEditPopup = "bom-edit-popup",
  BomDeletePopup = "bom-delete-popup",
  AnonymousDialog = "anonymous-dialog-popup",
  ChangePassword = "change-password-popup",
  ChangeOwnership = "change-ownership-popup",
  WarningAttributes = "warning-attributes-popup",
  WarningPricePopup = "warning-price-popup",
  PlainUIBlock = "plain-ui-block",
  ChangeSelectedTab = "change-selected-tab",
  //MSCRM
  MsCrmIntegrationPopup = 'mscrm-integration-popup',
  AccordionCompositeShowDetail = "accordion-composite-show-detail",
  AccordionCompositeHideDetail = "accordion-composite-hide-detail",
  AccordionNodesUpdate = "accordion-nodes-update"
}