import { Inject, Injectable } from '@angular/core';
import * as Immutable from "immutable";
import { ConfInfo } from "../../../shared/models";
import { GlobalDataStore, Strings } from "../../../shared/providers/globalData";
import { ProductDataStore } from "../../../shared/providers/productData";
import { ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { MenuItem } from "./navigation";


export let Identifier = {
  Add: 'add',
  Delete: 'delete',
  Copy: 'copy',
  Import: "import",
  Sort: "sort",
  MoveUp: "moveUp",
  MoveDown: "moveDown"
}

@Injectable()
export class CompositeMenuService {

  public itemByName: Immutable.Map<string, MenuItem> = Immutable.Map<string, MenuItem>();

  public get configurationId(): number {
    return this.storeSession.activeConfigurationId;
  };

  public get confSessionId(): number {
    return this.storeSession.confSessionId;
  };

  constructor(
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(ProductDataStore) public productStore: ProductDataStore,
    public globalDataStore: GlobalDataStore,    
    @Inject(Strings) public strings: Strings,
  ) {

    let globalSettings = globalDataStore.getGlobalData().globalSettings;

    this.addMenuItem(Identifier.Add, this.strings.AddChild, "add");

    if (globalSettings.showImportButtonInCompositeControl)
      this.addMenuItem(Identifier.Import, this.strings.ImportChild, "import");

    this.addMenuItem(Identifier.Delete, this.strings.Delete, "delete");
    this.addMenuItem(Identifier.Copy, this.strings.MakeCopies, "copy");
    //this.createMenuItem(Identifier.Sort, "Sort children", "sorting", "order"); // TODO not implemented on server
    this.addMenuItem(Identifier.MoveUp, this.strings.MoveUp, "up", "order");
    this.addMenuItem(Identifier.MoveDown, this.strings.MoveDown, "down");
  }

  public addMenuItem(name: string, title: string, icon: string, style: string = "", width: number = 24, height: number = 24): void {

    let item: MenuItem = this.createMenuItem(name, title, icon, style, width, height);

    // By default all menu actions are accessible for configuration. Action could be Add, delete, import etc.
    this.itemByName = this.itemByName.set(item.name, item);
  }

  public createMenuItem(name: string, title: string, icon: string, style: string = "", width: number = 24, height: number = 24): MenuItem {

    let item: MenuItem = new MenuItem();

    item = item.setName(name);
    item = item.setTitle(title);
    item = item.setIcon(icon);
    item = item.setStyle(style);
    item = item.setWidth(width);
    item = item.setHeight(height);
    item = item.setHidden(false);
    item = item.setDisable(false);
    item = item.setHasDetail(true);

    return item;
  }

/**
 * Makes the menu item status disabled If it is no longer intractable.
 * @param product
 */
  public updateAccessibility(info: ConfInfo): Array<MenuItem> {

    let items: Array<MenuItem> = new Array<MenuItem>();
    this.itemNames.forEach(name => {

      let menuItem: MenuItem = this.itemByName.get(name);
      if (!menuItem)
        return;


      switch (name) {

        case Identifier.Add:
        case Identifier.Import:
          {

            let conf = this.confStore.getConf(info.longId, this.confSessionId);            
            // Don't let the user access Add, Import If no children.
            menuItem = menuItem.setHidden(info.allowedChildProductIds.size < 1 || !conf.authorization.canAddChild);
            menuItem = menuItem.setSubTitle(null);
            menuItem = menuItem.setHasDetail(true);
            menuItem = menuItem.setTag(null);

            break;
          }

        case Identifier.MoveUp:
          {
            // Get the siblings count.
            let parentInfo: ConfInfo = this.confStore.getConfInfo(info.parentId, this.storeSession.confSessionId);

            // Hide the MoveUp If children size less than two.
            // Parent could be null for root nodes.            
            let visible: boolean = !!parentInfo && parentInfo.children.size > 1;
            menuItem = menuItem.setHidden(!visible);

            if (visible)
              menuItem = menuItem.setDisable(info.longId == parentInfo.children.get(0));

            menuItem = menuItem.setHasDetail(false);
            break;
          }

        case Identifier.MoveDown:
          {
            // Get the siblings count.
            let parentInfo: ConfInfo = this.confStore.getConfInfo(info.parentId, this.confSessionId);

            // Hide the MoveDown If children size less than two.
            // Parent could be null for root nodes.            
            let visible: boolean = !!parentInfo && parentInfo.children.size > 1;
            menuItem = menuItem.setHidden(!visible);

            if (visible)
              menuItem = menuItem.setDisable(info.longId == parentInfo.children.get(parentInfo.children.size - 1));

            menuItem = menuItem.setHasDetail(false);
            break;
          }

        case Identifier.Sort:
          {
            // Add the <Hidden> status.        
            menuItem = menuItem.setHidden(info.children.size < 2);
            menuItem = menuItem.setHasDetail(true);

            break;
          }

        case Identifier.Copy:
          {
            let conf = this.confStore.getConf(info.longId, this.confSessionId);
            menuItem = menuItem.setHidden(!info.parentId || !conf.authorization.canMakeCopies);
            menuItem = menuItem.setHasDetail(true);
            
            break;
          }
        case Identifier.Delete:
          {
            let conf = this.confStore.getConf(info.longId, this.confSessionId);
            menuItem = menuItem.setHidden(!info.parentId || !conf.authorization.canDelete);
            menuItem = menuItem.setHasDetail(true);
            break;
          }
      }

      items.push(menuItem);

    });

    return items;
  }

  public get itemNames(): Array<string> {
    return [
      Identifier.Add,
      Identifier.Import,
      Identifier.Copy,
      Identifier.Delete,
      //Identifier.Sort, // TODO not implemented on server
      Identifier.MoveUp,
      Identifier.MoveDown
    ];
  }

  public get menuItems(): Array<MenuItem> {

    let items: Array<MenuItem> = [];

    for (let name of this.itemNames) {
      if (this.itemByName.has(name))
        items.push(this.itemByName.get(name));
    }

    return items;
  }

  public menuItemByName(identifier: string): MenuItem {

    let items = this.menuItems.filter(x => x.name == identifier);
    
    if (items.length > 0)
      return items[0];


    return null;
  }

}

