import { ContentChildren, Output, EventEmitter, Component, QueryList, Input, AfterContentInit, OnDestroy } from "@angular/core";
import { TabComponent } from "./tabComponent";
import { TabModel } from "./tabModel";

const TAB_STYLES = {
  Accordion: "nav nav-tabs tab-color flex-column",
  Tabs: "nav nav-tabs tab-color"
}

const TAB_POSITION = {
  Top: 'top',
  Bottom: 'bottom'
}

@Component({
  selector: "tabs",
  templateUrl: './tabsContainerComponent.html'
})
export class TabsContainerComponent {

  @Input()
  position: any = TAB_POSITION.Top;

  @Input()
  defaultSelectedTabId: string;


  // Contaienr css
  private tabsContainerCSS: string = TAB_STYLES.Tabs;

  private showRightScroll: boolean = false;

  private showLeftScroll: boolean = false;

  @Input()
  tabModels: TabModel[] = [];

  @Input()
  classes: string;
  
  @Output() public selected: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    
  }

}