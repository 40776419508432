import { Directive, ViewContainerRef, HostListener } from '@angular/core';

@Directive({ selector: '[auto-scrollbar]' })
export class AutoScrollbarDirective {

  constructor(private referenceElement: ViewContainerRef) { }

  ngOnInit() {
    this.updateStyles();
    this.setHidden();

    setTimeout(() => {
      this.updateStyles();
    }, 0);
  }

  updateStyles(): void {
    if (!this.referenceElement)
      return;

    let element = this.referenceElement.element.nativeElement;
    let topPosition: number = element.getBoundingClientRect().top;
    let height = `${window.innerHeight - topPosition}px`;
    element.style.height = height;
  }

  public setAuto() {
    let element = this.referenceElement.element.nativeElement;
    element.style.overflow = 'auto';
  }

  public setHidden() {
    let element = this.referenceElement.element.nativeElement;
    element.style.overflow = 'hidden';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateStyles();
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.setAuto();
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.setHidden();
  }

}
