import { Component, Input, Host, Inject, ContentChild, ElementRef, ChangeDetectorRef, Output, EventEmitter } from "@angular/core";
import { TabModel } from "./tabModel";

@Component({
  selector: "tab",
  templateUrl: './tabComponent.html'
})
export class TabComponent {

  @Input()
  model: TabModel;
  
  constructor(private cdr: ChangeDetectorRef) {

  }
    
}