import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MessageBoxComponent } from './messageBoxComponent';
import { PopupModule } from "../popup";
import { ImageModule } from "../image";
import { SafeHtmlModule } from "../../../shared/utils/pipes";
export * from "./messageBoxComponent";
export * from "./messageBoxInfo";

@NgModule({
  imports: [
    CommonModule,
    PopupModule,
    ImageModule,
    SafeHtmlModule
  ],
  declarations: [
    MessageBoxComponent
  ],
  providers: [
  ],
  exports: [
    MessageBoxComponent
  ]
})
export class MessageBoxModule {

}