import { Host, Component, Optional, ViewChild, ElementRef, Input, forwardRef, Inject, ChangeDetectionStrategy, ChangeDetectorRef, HostListener, SimpleChanges } from '@angular/core';
import { RegularPopupComponent } from './regularPopupComponent';
import { BasePopupComponent } from './basePopupComponent';
import { BackdropComponent } from './backdropComponent';
import { BreakPointAccessor, Breakpoints } from "../../utils";
import { PopupService } from "./popupService";
import { SizeUtility, SizeUnit } from "../shared/sizeUtility";
let Popper = require('popper.js').default;
import { PopupInfo } from './popupInfo';

@Component({
  selector: 'simple-popper',
  templateUrl: './simplePopperComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimplePopperComponent extends BasePopupComponent {

  rootElement: ElementRef;
  public popperInstance: any;
  public contentStyle: CSSStyleDeclaration;

  @Input() public fullHeight: boolean = false;
  @Input() public referenceContainerId: string;
  @Input() public referenceElementId: string;

  @ViewChild('regularPopupElement')
  public popupElement: ElementRef;

  constructor(
    public cd: ChangeDetectorRef,
    @Optional() @Host() @Inject(forwardRef(() => BackdropComponent)) backdrop: BackdropComponent,
    public breakPointAccessor: BreakPointAccessor,
    public popupService: PopupService
  ) {
    super(cd, backdrop, breakPointAccessor, popupService);
  }

  ngAfterViewInit() {
    this.width = '600px';
    this.height = '600px';
    this.normalizeSize();    
    this.initializePopper();
  }

  initializePopper(): void {

    let container = document.getElementById(this.referenceContainerId);
    let referenceElem = document.getElementById(this.referenceElementId);
    let popup = this.popupElement.nativeElement;

    this.popperInstance = new Popper(
      referenceElem,
      popup,
      {
        placement: 'right',
        modifiers: {
          offset:
            {
              enabled: true,
              offset: "20,20,20,20"
            },
          flip: {
            behavior: ['right', 'top', 'left', 'bottom']
          },
          arrow: {
            enabled: true
          },

          keepTogether: { enabled: true },
          preventOverflow: {
            boundariesElement: container,
            escapeWithReference: false
          }
        }
      },
    );
  }

  public update(info: PopupInfo<any>): void {

    if (!this.referenceElementId)
      this.referenceElementId = info.referenceElementId;

    if (!this.referenceContainerId)
      this.referenceContainerId = info.containerId;

    if (this.referenceElementId == null && this.referenceContainerId == null) {
      throw new Error(`Reference element (${this.referenceElementId}) or container Id (${this.referenceContainerId}) is missing.`);
    }

    let container = document.getElementById(this.referenceContainerId);
    let referenceElem = document.getElementById(this.referenceElementId);
    let popup = this.popupElement.nativeElement;

    if (this.popperInstance) {

      this.popperInstance.reference = referenceElem;
      this.popperInstance.popper = popup;
      this.popperInstance.modifiers.find(x => x.name == 'preventOverflow').boundariesElement = container;

      this.popperInstance.update();
    }

  }

  public normalizeSize(): void {

    // Calculated size after checking the screen size
    let calWidth: string;
    let calHeight: string;

    if (SizeUtility.onlyUnit(this.width) === SizeUnit.percentage) {
      // Using percentage and small screen -> maximize
      this.calcMaximize = this.maximize || this.breakPointAccessor.activeBreakPoint.max < Breakpoints.sm.max;
    }
    else {
      // Margin so that we could maximize the popup before hitting the window.width
      const margin: number = 30;
      this.calcMaximize = this.maximize || SizeUtility.sizeAsNumber(this.width) + margin > innerWidth;
    }

    // Full height does not mean that It also has 100% width. In reports we have 100% height.
    if (this.fullHeight || this.calcMaximize) {
      calHeight = '100%';
    }

    if (this.isOpened) {
      calHeight = (this.fullHeight || this.calcMaximize) ? "100%" : this.height;
    }

    // During the show/hide transitions, width must be same.
    calWidth = this.calcMaximize ? "100%" : this.width;

    if (!this.contentStyle)
      this.contentStyle = <CSSStyleDeclaration>{};

    this.contentStyle.maxHeight = "100%";
    this.contentStyle.minWidth = '250px';
    this.contentStyle.minHeight = '250px';
    //this.contentStyle.height = calHeight;
    //this.contentStyle.width = '100%';    
    
    this.cd.markForCheck();
  }

  closePopup(event): void {

    if (this.popperInstance) {
      this.popperInstance.destroy();
      delete this.popperInstance;
    }

    this.close();
    event.preventDefault();

  }
  
}