import { Component, Input, SimpleChanges, OnInit, OnChanges } from "@angular/core";
import { InputViewModel } from "../..";

@Component({
  selector: "selected-help-image",
  templateUrl: './selectedHelpImageComponent.html',
})
export class SelectedHelpImageComponent implements OnInit, OnChanges  {

  @Input() valueModel: InputViewModel;
  @Input() groupModel: InputViewModel;
  @Input() selectedValueModel: InputViewModel;

  public style: CSSStyleDeclaration;
  public coImageStyle: CSSStyleDeclaration;
  public extraClasses: string;
  public showMessageBox = false;
  public message: string;


  ngOnInit() {
    this.applyStyle();
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['valueModel']) {
      this.showMessageBox = this.valueModel ? !this.valueModel.helpImage : !this.valueModel;
      this.message = this.valueModel ? this.valueModel.title : this.groupModel.title;
    }

  }

  onImageError(event): void {

    this.showMessageBox = true;
    this.message = this.valueModel.title;

  }


  applyStyle(): void {


    if (this.groupModel.helpImagePosition == 'above') {

      this.style = <CSSStyleDeclaration>{ width: '100%', height: this.groupModel.helpImageHeight };    
      this.extraClasses = "mb-4 w-100"; 

    }

    else if (this.groupModel.helpImagePosition == 'inline-right' || this.groupModel.helpImagePosition == 'inline-left') {

      // Inline help image must be aligned middle to the radio button
      this.style = <CSSStyleDeclaration>{ width: '100%', maxHeight: this.groupModel.helpImageHeight, maxWidth: this.groupModel.helpImageWidth };

    }

    else {

      // In right case, both height & width are important.                    
      this.style = <CSSStyleDeclaration>{ width: '100%', height: this.groupModel.helpImageHeight, maxWidth: this.groupModel.helpImageWidth };

    }

    this.coImageStyle = <CSSStyleDeclaration>{ maxWidth: this.groupModel.helpImageWidth, maxHeight: this.groupModel.helpImageHeight };

  }

}