import * as Immutable from "immutable";

import { BaseEntity } from "../../baseEntity";
import { RecentConf, ConfigurationSearch } from "..";
import { ConfDataResponse } from "./confDataResponse";
import { ConfSearchResultItem } from "./confSearchResultItem";
import { PaginationInfo } from "./paginationInfo";
import { SortingInfo } from "./sortingInfo";

export enum QuerySourceTypes {
  Database = 0,
  Unsaved = 1,
  Custom = 2
}

export class SearchResult extends BaseEntity {

  protected readonly SEARCH_SESSION_ID = "searchSessionId";
  protected readonly CONF_DATA_RESPONSES = "confDataResponses";
  protected readonly PAGINATION_INFO = "paginationInfo";
  protected readonly SORTING_INFO = "sortingInfo";
  protected readonly CONF_SEARCH_RESULT_ITEMS = "confSearchResultItems";
  protected readonly QUERY = "query";
  protected readonly QUERY_SOURCE_TYPE = "querySourceType";

  get searchSessionId(): number { return this.getInternalValue<number>(this.SEARCH_SESSION_ID); }
  setSearchSessionId(searchSessionId: number): this { return this.setInternalValue(this.SEARCH_SESSION_ID, searchSessionId); }

  get confDataResponses(): Immutable.List<ConfDataResponse> { return this.getInternalValue<Immutable.List<ConfDataResponse>>(this.CONF_DATA_RESPONSES); }
  setConfDataResponses(confDataResponses: Immutable.List<ConfDataResponse>): this { return this.setInternalValue(this.CONF_DATA_RESPONSES, confDataResponses); }

  get paginationInfo(): PaginationInfo { return this.getInternalValue<PaginationInfo>(this.PAGINATION_INFO); }
  setPaginationInfo(paginationInfo: PaginationInfo): this { return this.setInternalValue(this.PAGINATION_INFO, paginationInfo); }

  get sortingInfo(): SortingInfo { return this.getInternalValue<SortingInfo>(this.SORTING_INFO); }
  setSortingInfo(sortingInfo: SortingInfo): this { return this.setInternalValue(this.SORTING_INFO, sortingInfo); }

  get confSearchResultItems(): Immutable.List<ConfSearchResultItem> { return this.getInternalValue<Immutable.List<ConfSearchResultItem>>(this.CONF_SEARCH_RESULT_ITEMS); }
  setConfSearchResultItems(confSearchResultItems: Immutable.List<ConfSearchResultItem>): this { return this.setInternalValue(this.CONF_SEARCH_RESULT_ITEMS, confSearchResultItems); }

  get query(): ConfigurationSearch { return this.getInternalValue<ConfigurationSearch>(this.QUERY); }
  setQuery(query: ConfigurationSearch): this { return this.setInternalValue(this.QUERY, query); }

  get querySourceType(): QuerySourceTypes { return this.getInternalValue<QuerySourceTypes>(this.QUERY_SOURCE_TYPE); }
  setQuerySourceType(querySourceType: QuerySourceTypes): this { return this.setInternalValue(this.QUERY_SOURCE_TYPE, querySourceType); }
}