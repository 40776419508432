import { ChangeDetectorRef, Component, Inject } from "@angular/core";
import { ProductDataStore } from "../../../shared/providers/productData";
import { ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { ListModelCacheService } from "../providers/listModelCacheService";
import { AbstractAttributesDetailComponent } from "./abstractAttributesDetailComponent";
import { PopupService } from "../../../../shared/components/popup/popupService";
import { GlobalDataStore } from "../../../shared/providers/globalData/globalDataStore";
import { PopoverService } from "../../../../shared/components";


@Component({
  selector: 'attributes-detail',
  templateUrl: './attributesDetailComponent.html'
})
export class AttributesDetailComponent extends AbstractAttributesDetailComponent {

  constructor(@Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(ProductDataStore) public productStore: ProductDataStore,
    @Inject(ChangeDetectorRef) public cdr: ChangeDetectorRef, 
    @Inject(ListModelCacheService) public modelsService: ListModelCacheService,
    public popupService: PopupService,
    public globalDataStore: GlobalDataStore,
    public popoverService: PopoverService
  ) {
    super(cdr, confStore, confPageSessionService, productStore, modelsService, popupService, globalDataStore, popoverService);
  }

}