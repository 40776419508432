<form name="form" (ngSubmit)="verify()">
  <div class="password-recover">
    <h3 class="alignment pb-3">{{title}}</h3>
    <p class="description">
      {{description}}
    </p>

    <text-box [view]="codeView"
              autofocus="true">
    </text-box>
    <button class="btn btn-primary w-100" type="submit">{{strings.Verify}}</button>
    <div *ngIf="errorMessage" class="form-control-feedback d-block pt-3 text-center">{{errorMessage}}</div>
  </div>
</form>