import { Component, Inject } from "@angular/core";
import * as Immutable from "immutable";
import { ConfDocumentValue, DocumentsDecoration } from "../../../shared/models";
import { ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { DecorationComponent } from "../../shared/decorationComponent";
import { VisualObjectVisibilityService } from "../../shared";
import { VisualObjectHelper } from "../../parameters/shared";

@Component({
  selector: 'documents-decoration',
  templateUrl: './documentsDecorationComponent.html'
})
export class DocumentsDecorationComponent extends DecorationComponent<DocumentsDecoration> {

  public documentValues: Array<ConfDocumentValue>;

  constructor(
    public confStore: ConfiguratorStore,
    public confPageSessionService: ConfPageSessionService,
    @Inject(VisualObjectHelper) public visualObjectHelper: VisualObjectHelper,
    public visualObjectVisibilityService: VisualObjectVisibilityService
  ) {
    super(confStore, confPageSessionService, visualObjectHelper);
  }

  public listenChanges(): void {

    this.confStore.onConfDocumentValuesChange(this.configurationId, this.confSessionId, this.decoration.longId,
      (documentValues: Immutable.List<ConfDocumentValue>): void => {

        this.documentValues = [];

        const confUIItem = this.visualObjectVisibilityService.findConfUIItem(this.configuration, this.decoration.longId);
        if (confUIItem) {
          confUIItem.items.forEach(item => {
            const value = documentValues.find(x => x.longId === item.id);
            if(value)
              this.documentValues.push(value);
          });
        }
      }).unsubscribeOn(this.unsubscribeSubject);
  }
}