export enum TimeSpanType {
  Day = 'day',
  Month = 'month',
  Year = 'year',
  Quarter = 'quarter',
}

export class PresetItem {
  predefinedLabel: string; // Display label in input box
  identifier: string; // Preset identifier used at backend.
  range: Range;
}

export class Range {

  constructor(public fromDate: Date, public toDate: Date)
  {}
}

export class DateValueModel {

  range?: Range;
  displayText?: string; // Shown in textbox.
  value?: string; // Value to be stored into database.
  isPreset?: boolean; // If not preset then It means it is custom range.

}

export class CalendarDialogConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  shouldCloseOnBackdropClick?: boolean;  
  minWidth?: number;
}

export class DateRangeOptions {

  predefinedItems: Array<PresetItem>;
  format: string;
  range: Range;
  excludeWeekends?: boolean;
  locale?: string;
  fromMinMax?: Range;
  toMinMax?: Range;
  applyLabel?: string;
  cancelLabel?: string;
  emptyValueLabel?: string;

}