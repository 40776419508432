import { Component } from "@angular/core";
import { BaseImmutableValuePopupComponent } from "../shared/baseImmutableValuePopupComponent";
import { AbstractImmutableValueMessage } from "../../../shared/models/responses/messages/abstractImmutableValueMessage";
import { PopupIdentifiers } from "../../providers";

@Component({
  selector: 'disallowed-popup',
  templateUrl: './disallowedPopupComponent.html'
})
export class DisallowedPopupComponent extends BaseImmutableValuePopupComponent {


  ngOnInit() {
    this.popupIdentifier = PopupIdentifiers.Disallowed;  
    this.headerText = this.strings.DisallowedValue;
    this.caption = this.strings.DisallowedPopup_CannotSet;
    this.description = this.strings.DisallowedPopup_DisallowedCombinationRuleGeneralMessage;
    this.icon = "disallowedpopup";
  }
  
}