import * as Immutable from "immutable";
import { AbstractConfDataCommand } from "./abstractConfDataCommand";
import { KeyValue } from "../../responses/keyValue";

export class PriceCommand extends AbstractConfDataCommand {

  getPriceReport?: boolean;

  getStoredPriceReport?: boolean;

  getPriceSetting?: boolean;

  setCurrency?: number;

  setPriceListCategory?: number;

  setPriceList?: number;

  manualPriceValues?: Immutable.List<KeyValue<number, number>>; 

}