import { Inject, Component, Input, Optional, ViewChild, ElementRef, HostListener } from "@angular/core";
import { BaseComponent } from "../..";
import { PopupService } from "../../../../shared/components";
import { PrintService, PrintInfo, PrintAction } from "../../../../shared/components/print";
import { sleep, Delay } from "../../../../shared/utils";

@Component({
  selector: 'summary-print',
  templateUrl: './summaryPrintComponent.html',
})
export class SummaryPrintComponent extends BaseComponent {


  id: string = "summary-print-view";
  visible: boolean = false;

  printViewOpened = false;

  @ViewChild("printElement")
  printElement: ElementRef;

  constructor(private popupService: PopupService, private printService: PrintService) {
    super();
  }

  ngOnInit() {

  }

  show(): void {
    this.visible = true;    
    setTimeout(() => {
      this.popupService.open(this.id);
    }, 100);  
  }

  ignoreBeforePrint: boolean = false;
  sendPrint(): void {
    this.printService.sendPrint(<PrintInfo>{ referenceElement: this.printElement.nativeElement, action: PrintAction.CopyDOM });
    this.printService.sendPrint(<PrintInfo>{ referenceElement: this.printElement.nativeElement, action: PrintAction.Print });
    
    this.ignoreBeforePrint = true;
    sleep(Delay.MS_400).then(x => window.print());
  }

  onPopupClosed() {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
    this.visible = false;
  }

  @HostListener('window:beforeprint', ['$event'])
  onBeforePrint(event) {

    // As print commands can be sent by pressing "Ok" button on printview page or by using keyboard shortcut keys "ctr + p".
    // If print command is sent through "Ok" click button then we don't need to copy the DOM because these actions are already executed.
    if (this.ignoreBeforePrint)
      return;

    if (this.printElement) {
      this.printService.sendPrint(<PrintInfo>{ referenceElement: this.printElement.nativeElement, action: PrintAction.CopyDOM });
      this.printService.sendPrint(<PrintInfo>{ referenceElement: this.printElement.nativeElement, action: PrintAction.Print });
    }
    else {
      this.printViewOpened = true;
      this.show();

      setTimeout(() => {
        // Wait
      }, 1000);
    }
  }

  public onPrintViewOpened() {
    if (this.printViewOpened)
    {
      this.printViewOpened = false;
      this.printService.sendPrint(<PrintInfo>{ referenceElement: this.printElement.nativeElement, action: PrintAction.CopyDOM });
      this.printService.sendPrint(<PrintInfo>{ referenceElement: this.printElement.nativeElement, action: PrintAction.Print });
    }
  }

  @HostListener('window:afterprint', ['$event'])
  onAfterPrint(event) {
    
    this.printService.sendPrint(<PrintInfo>{ referenceElement: this.printElement?.nativeElement, action: PrintAction.RemoveDOM });    
    this.ignoreBeforePrint = false;

  }

}