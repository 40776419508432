import * as Immutable from "immutable";
import { AbstractOperationMessage } from "./abstractOperationMessage"

export class StateActionResultMessage extends AbstractOperationMessage {
    
  protected readonly ERRORS = "errors";
  protected readonly WARNINGS = "warnings";
    
  get errors(): Immutable.List<string> { return this.getInternalValue<Immutable.List<string>>(this.ERRORS); }
  setErrors(errors: Immutable.List<string>): this { return this.setInternalValue(this.ERRORS, errors); }

  get warnings(): Immutable.List<string> { return this.getInternalValue<Immutable.List<string>>(this.WARNINGS); }
  setWarnings(warnings: Immutable.List<string>): this { return this.setInternalValue(this.WARNINGS, warnings); }
}
