<input #upload type="file" name="file" [attr.multiple]="multiple" (change)="selectFile($event)" class="d-none" />
<div *ngFor="let file of files" class="row pb-3 file">
  <div class="col">
    {{file.file.name}}
  </div>
  <div class="col-6">
  <div class="progress">
    <div [ngClass]="{'progress-bar progress-bar-striped progress-bar-animated': file.running}" class="text-center" role="progressbar" [style.width]="file.running ? file.percentDone + '%' : 'auto'" [class.bg-success]="file.done" [class.bg-danger]="file.error">{{file.text}}</div>
  </div>
  </div>
  <div *ngIf="!file.done" class="col-auto clickable align-middle mobile-flex delete-button" role="button" title="{{strings.Remove}}" (click)="onDeleteClick(file)">
    <co-image width="24" height="24" type="svg" key="delete" imageSet="red"></co-image>
  </div>
</div>

<button *ngIf="!files || files.length == 0" (click)="add()">{{strings.SelectFiles}}</button>