import { ChangeDetectorRef, Component, Inject, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { PopupService } from "../../../../../shared/components";
import { NotificationService } from "../../../../../shared/components/notification";
import { BaseComponent } from "../../../../shared";
import { ConfInfo, Product } from "../../../../shared/models";
import { RouteRedirector } from "../../../../shared/providers";
import { GlobalDataStore } from '../../../../shared/providers/globalData';
import { ProductDataStore } from "../../../../shared/providers/productData";
import { ConfiguratorStore, ConfMessageProvider, ConfPageSessionService } from "../../../providers";
import { CompositeActions, CompositeActionService, ICompositeDataInfo } from '../compositeActionService';
import { CompositeTreeDataProvider } from "../tree/compositeTreeDataProvider";

@Component({
  selector: 'add-configuration',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './addConfigurationComponent.html'
})
export class AddConfigurationComponent extends BaseComponent {

  public showNum = 0;
  public products: Array<Product> = [];

  @Input()
  public configurationId: number;

  // Focused conf info
  public confInfo: ConfInfo;

  constructor(
    @Inject(ProductDataStore) public productDataStore: ProductDataStore,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(NotificationService) public notificationService: NotificationService,
    @Inject(ConfMessageProvider) public confMessageProvider: ConfMessageProvider,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    @Inject(CompositeTreeDataProvider) public menuDataProvider: CompositeTreeDataProvider,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(CompositeActionService) public actionService: CompositeActionService,
    @Inject(PopupService) public popupService: PopupService,
    @Inject(ChangeDetectorRef) public cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['configurationId']) {
      this.setContents();
    }
  }

  public setContents(): void {

    this.confInfo = this.confStore.getConfInfo(this.configurationId, this.storeSession.confSessionId);

    let product: Product = this.productDataStore.getEntity<Product>(this.confInfo.productId);


    this.productDataStore.getChildProducts(product, this.confInfo.allowedChildProductIds.toArray()).subscribe((response) => {    
      this.products = response.data;

      !this.cdr['destroyed']
        this.cdr.detectChanges();

    }).unsubscribeOn(this.unsubscribeSubject);
  }

  productClick(event, product: Product): void {

    this.actionService.notify(

      <ICompositeDataInfo<number>>{
        action: CompositeActions.AddProductClick,
        tag: product.longId,
        focusedConfInfoId: this.configurationId
      }

    );

  }

  hasProductIcon(product: Product): boolean {
    return product.iconRelativeUrl && product.iconRelativeUrl != "";
  }

}