import { Injectable, Inject } from '@angular/core';
import * as Immutable from "immutable";

import { BaseEntity } from "../baseEntity"
import { AppState } from "../state";
import { ReselectorService, ReselectorResult } from "./reselectorService";

@Injectable()
export class EntityReselectorService {

  constructor(
    @Inject(ReselectorService) public reselectorService: ReselectorService) { }

  public memorizeEntity<T extends BaseEntity>(longId: number): () => ReselectorResult<T> {

    const getEntities = (appState: AppState): Immutable.Map<number, BaseEntity> => {
      return appState.productData.entities;
    }

    const getValue = (appState: AppState): BaseEntity => {
      return appState.productData.entities.get(longId) as BaseEntity;
    }

    return this.reselectorService.create2(getEntities, getValue, (entities, model) => model) as (() => ReselectorResult<T>);
  }

  public memorizeEntityList<T extends BaseEntity>(longIds: number[]): () => ReselectorResult<T[]> {

    const getEntities = (appState: AppState): Immutable.Map<number, BaseEntity> => {
      return appState.productData.entities;
    }

    const getValues = (appState: AppState): BaseEntity[] => {
      return longIds.map((value) => appState.productData.entities.get(value) as BaseEntity);
    }

    return this.reselectorService.create2(getEntities, getValues, (entities, models) => models) as (() => ReselectorResult<T[]>);
  }

  public memorizeConfEntity<T extends BaseEntity>(longId: number, confSessionId: number, confId: number): () => ReselectorResult<T> {
    const getEntities = (appState: AppState): Immutable.Map<number, BaseEntity> => {
      return appState.configurationData.dataBySessionId.get(confSessionId.toString()).entitiesByConfId.get(confId);
    }

    const getValue = (appState: AppState): BaseEntity => {
      return getEntities(appState).get(longId) as BaseEntity;
    }

    return this.reselectorService.create2(getEntities, getValue, (entities, model) => model) as (() => ReselectorResult<T>);
  }

  public memorizeConfEntityList<T extends BaseEntity>(longIds: number[], confSessionId: number, confId: number): () => ReselectorResult<T[]> {
    const getEntities = (appState: AppState): Immutable.Map<number, BaseEntity> => {
      return appState.configurationData.dataBySessionId.get(confSessionId.toString()).entitiesByConfId.get(confId);
    }

    const getValues = (appState: AppState): BaseEntity[] => {
      return longIds.map((value) => getEntities(appState).get(value) as BaseEntity);
    }

    return this.reselectorService.create2(getEntities, getValues, (entities, models) => models) as (() => ReselectorResult<T[]>);
  }

}