import { Injectable } from "@angular/core"
import { Schema, schema } from 'normalizr';

@Injectable()
export class AccountDataSchema {

  protected allSchema = new Map<string, Schema>();

  public getSchema(className: string): any {

    if (!this.allSchema.has(className))
      this.allSchema.set(className, new schema.Entity(className, {}, { idAttribute: 'longId' }));

    return this.allSchema.get(className);
  }

  public setSchema(className: string, schema: Schema) {
    this.allSchema.set(className, schema);
  }

  public getSchemaArray(className: string): schema.Array {

    let classSchema = this.getSchema(className);
    if (classSchema instanceof schema.Array)
      return classSchema;

    return new schema.Array(this.getSchema(className));
  }
}