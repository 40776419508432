import { Inject, Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { ConfigurationSessionManager, ConfPageSessionService, ConfiguratorUIStore, ConfRouteParams, PopupIdentifiers } from "../providers";
import { VisualObject, Tab, Product } from "../../shared/models";
import { ProductDataStore } from "../../shared/providers/productData";
import { PopupService } from "../../../shared/components";
import { RouteRedirector, RouteNames } from "../../shared/providers";
import { ManagedSubject } from "../../../shared/managedSubject";
import { PageStore } from "../../shared/providers/page";
import { EmitterService } from "./emitterService";
import { ConfVisiblityService } from "./confVisibilityService";

@Injectable()
export class VisualObjectNavigatorService {

  private accordionChangeSubject = new ManagedSubject<number>(null, false, 0, false);

  constructor(
    @Inject(ConfPageSessionService) public confPageSession: ConfPageSessionService,
    @Inject(ProductDataStore) public productDataStore: ProductDataStore,
    @Inject(ConfiguratorUIStore) public confUIStore: ConfiguratorUIStore,
    @Inject(PopupService) public popupService: PopupService,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    @Inject(PageStore) public pageStore: PageStore,
    @Inject(EmitterService) public emitterService: EmitterService,
    @Inject(ConfVisiblityService) public confVisiblityService: ConfVisiblityService
  ) {
  }

  public navigate(configurationId: number, visualObjectId: number, popupId: string): void {

    if (!this.confPageSession.showChildrenInAccordionTabs && configurationId != this.confPageSession.activeConfigurationId) {
      this.navigateToConfiguration(configurationId, visualObjectId, popupId);
    }
    else {
      this.navigateToTab(configurationId, visualObjectId, popupId);
    }

  }

  public navigateToConfiguration(configurationId: number, visualObjectId: number, popupId: string) {
    let confChangeSubscription = this.confUIStore.onActiveConfChange(this.confPageSession.pageId, {
      next: (x: number) => {
        if (configurationId === x) {
          // We got what we wanted -> unsubscribe.
          confChangeSubscription.unsubscribe();

          // Use timeout so the DOM is refreshed and the visual object has a position.
          setTimeout(() => {
            this.navigateToTab(configurationId, visualObjectId, popupId);
          }, 0);
        }
      },
      startOnDemand: true
    });

    confChangeSubscription.start();
    let params = <ConfRouteParams>{ id: configurationId, confSessionId: this.confPageSession.confSessionId };

    if (this.pageStore.activeRouteName === RouteNames.Summary)
      this.routeRedirector.redirectToSummary(params);
    else
      this.routeRedirector.redirectToEditor(params);
  }

  public navigateToTab(configurationId: number, visualObjectId: number, popupId: string) {

    let visualObject: VisualObject = this.productDataStore.getEntity(visualObjectId) as VisualObject;
    let visualObjectContainerTab = this.productDataStore.getEntity<Tab>(visualObject.tabId);

    // If navigation belongs to child configuratoin and children are shown in accordion...
    let showChildrenInAccordion = false;
    let confInfo = this.confPageSession.confStore.getConfInfo(configurationId, this.confPageSession.confSessionId);    
    if (confInfo.rootId != confInfo.longId) {
      showChildrenInAccordion = this.confPageSession.showChildrenInAccordionTabs
    }

    // Navigate directly to if active tab or separate tab, otherwise change active tab.
    // For accordion we send a message and hope TabsManagerComponent will change active accordion.
    if (this.pageStore.activeRouteName === RouteNames.Summary) {

      this.popupService.applyPopper(visualObjectId.toString(), popupId);
    }
    else {
      if (showChildrenInAccordion || visualObjectContainerTab.displayStyle === "Accordion") {
        this.sendMessage(visualObject.tabId);
        this.emitterService.send<number>(PopupIdentifiers.ChangeSelectedTab, visualObject.tabId);
      }
      // Otherwise we need more logic to switch to its tab and then locate it.
      else
        this.emitterService.send<number>(PopupIdentifiers.ChangeSelectedTab, visualObject.tabId);

      setTimeout(() => {
        this.popupService.applyPopper(visualObjectId.toString(), popupId);
      }, 0);
    }
  }

  /**
   * Gets the new popup position for the given visual object.
   */
  public getPopupPosition(visualObjectId: number) {
    let visualObject = document.getElementById(visualObjectId.toString());
    // Return null for undefined visual object OR if it's hidden.
    // Check hidden by offsetParent (https://stackoverflow.com/a/21696585).
    if (visualObject == null || visualObject.offsetParent == null)
      return { x: 0, y: 0 };

    const margin = 20;
    // Use getBoundingClientRect to get the "global" position for left and offsetTop to get top within its parent.
    let pos = visualObject.getBoundingClientRect();
    let x = pos.left + pos.width + margin; // X is to the right of the visualobject
    let y = visualObject.offsetTop;
    return { x: x, y: y };
  }

  /**
   * Message about selected accordion is changed,
     the accordion tab ID is passed as an argument.
   */
  public getAccordionChangeMessage(): ManagedSubject<number> {
    return this.accordionChangeSubject;
  }

  /**
  * Broadcasts the message to all subscribers
  * @param event
  */
  public sendMessage(info: number): void {
    this.accordionChangeSubject.nextValue(info);
  }
}