import { BaseEntity } from "../../baseEntity"

export abstract class Currency extends BaseEntity {

  protected readonly IDENTITY = "identity";
  protected readonly FACTOR = "factor";
  protected readonly PREFIX = "prefix";
  protected readonly SUFFIX = "suffix";

  get identity(): string { return this.getInternalValue<string>(this.IDENTITY); }
  setIdentity(identity: string): this { return this.setInternalValue(this.IDENTITY, identity); }

  get factor(): number { return this.getInternalValue<number>(this.FACTOR); }
  setFactor(factor: number): this { return this.setInternalValue(this.FACTOR, factor); }

  get prefix(): string { return this.getInternalValue<string>(this.PREFIX); }
  setPrefix(prefix: string): this { return this.setInternalValue(this.PREFIX, prefix); }

  get suffix(): string { return this.getInternalValue<string>(this.SUFFIX); }
  setSuffix(suffix: string): this { return this.setInternalValue(this.SUFFIX, suffix); }

}