import * as Immutable from "immutable";
import { ConfSearchCriteria } from "./confSearchCriteria"

export class ConfSearchProperty extends ConfSearchCriteria {

  protected readonly NAME = "name";

  get name(): string { return this.getInternalValue<string>(this.NAME); }
  setName(name: string): this { return this.setInternalValue(this.NAME, name); }

}