import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfDataResponse } from "../../../shared/models/responses";
import { ConfDataController } from "../../../shared/providers/configurationData";
import { ConfDataRequest, ApiResponse } from "../../../shared/models";

@Injectable()
export class RuleController {
    
  constructor(    
    @Inject(ConfDataController) public configuratorController: ConfDataController
  ) { }

  /**
    * Confirms the execution of the rule
    */
  public confirmRuleExecution(model: ConfDataRequest): Observable<ApiResponse> {
    return this.configuratorController.getConfigurationData(model);
  }

}