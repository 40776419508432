<div class="{{isInline ? 'is-inline row' : ''}}">
  <dropdown class="d-block {{isInline ? 'col-auto py-1' : 'pb-3'}}"
            [itemsView]="filterItems"
            [view]="filterItemsView"
            (onValueChange)="onFilterItemChanged($event)">
  </dropdown>
  <!--<div [class.col]="isInline">-->
    <text-box *ngIf="filterItemsView.type === 'String'" class="{{isInline ? 'col-auto py-1' : 'pt-3'}}"
              [view]="value1View"
              (onValueChange)="onFilterValueChanged($event)">
    </text-box>
  <!--</div>-->
  <ng-container *ngIf="filterItemsView.type === 'Double' || filterItemsView.type === 'Int'">
    <dropdown class="d-block {{isInline ? 'col-auto py-1' : 'pb-3'}}"
              [itemsView]="filterTypes"
              [view]="filterTypesView"
              (onValueChange)="onFilterTypeChanged($event)">
    </dropdown>
    <text-box class="d-block {{isInline ? 'col-auto py-1' : ''}}"
              [view]="value1View"
              type="number"
              (onValueChange)="onFilterValueChanged($event)">
    </text-box>
    <text-box *ngIf="filterType === FilterType.Range"
              class="d-block {{isInline ? 'col-auto py-1' : 'pt-3'}}"
              [view]="value2View"
              type="number"
              (onValueChange)="onFilterValueChanged($event)">
    </text-box>
  </ng-container>
  <div *ngIf="filterItemsView.type === 'Bool'" class="d-block {{isInline ? 'col-auto py-1' : ''}}">
    <dropdown [itemsView]="boolValues"
              [view]="value1View"
              (onValueChange)="onFilterValueChanged($event)">
    </dropdown>
  </div>

  <div class="{{isInline ? 'col-auto py-1' : 'mt-4'}} text-right">
    <button class="btn btn-primary" (click)="onSearchClick()">{{strings.Search}}</button>
    <button *ngIf="!isInline" class="btn btn-primary ml-2" (click)="onCloseClick()">{{strings.Close}}</button>
  </div>
</div>