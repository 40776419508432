import { Injectable, Inject } from '@angular/core';
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';

import { HttpService } from "../../../../shared/providers/httpService";
import { ApiResponseBuilder } from "../apiResponseBuilder";
import { ModelFactory } from '../modelFactory';
import { ClientPushMessage } from '../../models/requests/clientPushMessage';
import { ApiResponse } from '../../models';

@Injectable()
export class PushMessageController {

  public url = "api/pushmessage";

  constructor(
    @Inject(HttpService) public httpService: HttpService,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    public apiResponseBuilder: ApiResponseBuilder) { }

  //public normalizeData = map((response, index) => this.apiResponseBuilder.build(response));

  public send(message: ClientPushMessage) {

    return this.httpService.get(this.url, message)
      .pipe(this.buildResponseObject).subscribe(
        // If no subscribtion the data will never be sent.
        data => console.log('success', data),
        error => console.log('oops', error)
      );
  }

  
  public buildResponseObject = map((response, index) => this.apiResponseBuilder.build(response));

  public getPushMessage(data: any): Observable<ApiResponse> {

    const obsUsingCreate = Observable.create(observer => {
      observer.next(this.apiResponseBuilder.build(data));

      observer.complete()
    })

    return obsUsingCreate;
  }
}