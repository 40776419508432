import { Injectable } from "@angular/core";
import { ConfInfo } from "../../../shared/models";
import { ComparisonResult } from "../../../shared/state";

@Injectable()
export class ComparisonHelper {

  static SEPARATOR: string = '.';

  public comparisonId(confIds: number[]): string {
    return confIds.join(ComparisonHelper.SEPARATOR);
  }

  public confIdsFromComparisonId(comparisonId: string): Array<number> {
    return comparisonId.split(ComparisonHelper.SEPARATOR) as Array<any>;
  }

  public extractPath(source: ComparisonResult, confIds: number[]): string {

    if (!source.childConfsOrder || source.childConfsOrder.size == 0)
      return;

    for (let keyValue of source.childConfsOrder.toArray()) {

      let rowConfInfos: ConfInfo[] = keyValue.value;
      if (rowConfInfos.find(x => confIds.findIndex(y => x != null && y == x.longId) > -1))
        return keyValue.key;
    }

    return;
  }

}