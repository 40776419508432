import { UiElement } from "./uiElement";

export class UiInput extends UiElement {

  protected readonly PLACE_HOLDER = "placeHolder";
  protected readonly TYPE = "type";
  protected readonly VALUE = "value";
  protected readonly IS_MULTILINE = "isMultiline";
  protected readonly ROWS = "rows";
  protected readonly COLS = "cols";

  get placeHolder(): string { return this.getInternalValue<string>(this.PLACE_HOLDER); }
  setPlaceHolder(placeHolder: string): this { return this.setInternalValue(this.PLACE_HOLDER, placeHolder); }

  get type(): string { return this.getInternalValue<string>(this.TYPE); }
  setType(type: string): this { return this.setInternalValue(this.TYPE, type); }

  get value(): string { return this.getInternalValue<string>(this.VALUE); }
  setValue(value: string): this { return this.setInternalValue(this.VALUE, value); }

  get isMultiline(): boolean { return this.getInternalValue<boolean>(this.IS_MULTILINE); }
  setIsMultiline(isMultiline: boolean): this { return this.setInternalValue(this.IS_MULTILINE, isMultiline); }

  get rows(): number { return this.getInternalValue<number>(this.ROWS); }
  setRows(rows: number): this { return this.setInternalValue(this.ROWS, rows); }

  get cols(): number { return this.getInternalValue<number>(this.COLS); }
  setCols(cols: number): this { return this.setInternalValue(this.COLS, cols); }

}