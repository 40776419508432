import { Inject, Component, Input, EventEmitter, Output } from "@angular/core";
import { BaseComponent } from "../..";
import { ImageSets } from "../../../../shared/utils/imageSets";

@Component({
  selector: 'action-box',
  templateUrl: './actionBoxComponent.html'
})
export class ActionBoxComponent extends BaseComponent {

  @Input()
  public showHeader: boolean = true;

  @Input()
  public iconLeft: string;

  @Input()
  public isIconLeftVault: boolean = false;

  @Input()
  public textLeft: string;

  @Input()
  public iconRight: string;

  @Input()
  public iconRightImageSet: string;

  @Input()
  public iconLeftImageSet: string;

  @Input()
  public textRight: string;

  @Output()
  onRightIconClick = new EventEmitter();

  ngOnInit() {
    if (!this.iconRightImageSet)
      this.iconRightImageSet = this.getImageSet(ImageSets.SummaryDetailHeader);

    if (!this.iconLeftImageSet)
      this.iconLeftImageSet = this.getImageSet(ImageSets.SummaryDetailHeader);
  }

}