import { HttpErrorResponse } from "@angular/common/http";

import { SimpleObject } from "./simpleObject";
import { Exception } from "./exception";

export class HttpException extends SimpleObject {
  constructor(propertyMap?: any) {
    super(propertyMap);
  }

  public readonly ERROR_RESPONSE = "errorResponse";  
  public readonly EXCEPTIONS = "exceptions";
  public readonly IS_HANDLED = "isHandled";

  public get errorResponse(): HttpErrorResponse {
    return this.getInternalValue<HttpErrorResponse>(this.ERROR_RESPONSE);
  }

  public set errorResponse(errorResponse: HttpErrorResponse) {
    this.setInternalValue(this.ERROR_RESPONSE, errorResponse);
  }

  public get exceptions(): Exception[] {
    return this.getInternalValue<any>(this.EXCEPTIONS);
  }

  public set exceptions(exceptions: Exception[]) {
    this.setInternalValue(this.EXCEPTIONS, exceptions);
  }

  public get isHandled(): boolean {
    return this.getInternalValue<any>(this.IS_HANDLED);
  }

  public set isHandled(isHandled: boolean) {
    this.setInternalValue(this.IS_HANDLED, isHandled);
  }
}