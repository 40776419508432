import { Component, Inject, Input, ChangeDetectorRef } from "@angular/core";
import { Tab } from "../../../shared/models";
import { SeparatedTabUIData, StickableUIData } from "../../../shared/state";
import { ConfiguratorUIStore, ConfPageSessionService, PopupIdentifiers } from "../../providers";
import { StickableSideBarComponent } from "../../shared/stickableSideBarComponent";
import { IEmitDataInfo } from "../../shared/emitterService";
import { GlobalServiceLocator } from "../../../../shared/providers/globalServiceLocator";
import { ProductDataStore } from "../../../shared/providers/productData/productDataStore";


@Component({
  selector: 'floated-separated-tabs',
  templateUrl: './floatedSeparatedTabsComponent.html'
})
export class FloatedSeparatedTabsComponent extends StickableSideBarComponent {
  
  @Input()
  public floatedTabs: Tab[];
  public activeTab: Tab;
  public floatedWidth: number;
  
  // TODO: Read the visiblity from separatedUITab state.
  public visible: boolean = false;
    
  constructor(
    @Inject(ConfiguratorUIStore) public confUIStore: ConfiguratorUIStore,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    public cdr: ChangeDetectorRef) {
    super(confUIStore, storeSession);
  }

  ngOnInit() {

    // Listen the messages from popups e.g Mandatory, ReadOnlyPopup which can trigger the tab-change
    this.emitterService.getMessage().subscribe((info: IEmitDataInfo<number>) => {

      if (info.id == PopupIdentifiers.ChangeSelectedTab && info.tag && !this.ui.calcSticky) {

        // Product store service
        let productStore = GlobalServiceLocator.injector.get(ProductDataStore)

        // Grab the tab from store.
        const tabId = Number(info.tag);
        let tab: Tab = productStore.getEntity(tabId);

        if (!tab)
          return;

        // Notify to corresponding tabs container.
        this.activateTab(tab);
      }

    }).unsubscribeOn(this.unsubscribeSubject);

    super.ngOnInit();
  }

  public activateTab(tab: Tab) {
    let foundTab = this.floatedTabs.find(x => x.longId == tab.longId)
    if (foundTab != null) {
      this.toggleVisibility({ tag: foundTab });
    }
    else
      this.closeSeparatedTab(null);
  }

  /**
   * Updates the accordion visibility.
   * @param visible
   */
  updateVisibility(visible: boolean): void {
    this.visible = visible;
  }

  /**
   * Updates the sticky option set by the user.
   * @param userSticky
   */
  updateUserSticky(userSticky: boolean): void {

  }

  public trackTab(index: number, tab: Tab): any {
    return tab.longId;
  }

  // Returns corresponding UI State.  
  get uiState(): StickableUIData {
    return new SeparatedTabUIData();
  }

  /**
   * Toggles the visibility
   */
  public toggleVisibility(event): void {

    if (event.tag)
    {
      this.visible = true;
      this.activeTab = event.tag as Tab;
      this.toggleTo(true);
      this.cdr.markForCheck();
    }

  }

  closeSeparatedTab(event): void {
    this.visible = false;
    this.toggleTo(false);
  }

  public handleTitle(tab: Tab): string {

    const title: string = tab.handleTitle && tab.handleTitle.length > 0 ? tab.handleTitle : tab.title;
    const limit: number = 10;

    return title.length > limit ? title.substr(0, limit - 3) + "..." : title;
  }
  
}