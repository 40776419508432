import { Component } from '@angular/core';

let SideBarPositions = {
  Left: 'left',
  Right: 'right'
}

@Component({
  selector: 'sidebar-extension',
  templateUrl: './sideBarExtensionComponent.html'    
})
export class SideBarExtensionComponent {
  
  ngOnInit(): void {
  }

}