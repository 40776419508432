import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RadioGroupComponent } from "./radioGroupComponent";
import { RadioButtonComponent } from "./radioButtonComponent";
import { StandardRadioStrategyComponent } from "./strategies/standardRadioStrategyComponent";
import { ImageRadioStrategyComponent } from "./strategies/imageRadioStrategyComponent";
import { ImageRadioStyleComponent } from "./components/imageRadioStyleComponent";
import { ImageModule } from "../image";
import { IconModule } from "../icon";
import { HelpModule } from "../help";

export * from "./radioButtonComponent";
export * from "./radioGroupComponent";
export * from "./strategies/standardRadioStrategyComponent";
export * from "./strategies/imageRadioStrategyComponent";
export * from "./strategies/baseRadioStrategyComponent";
export * from "./components/imageRadioStyleComponent";

@NgModule({
  imports: [
    CommonModule,
    ImageModule,
    HelpModule,
    IconModule
  ],
  declarations: [
    RadioButtonComponent,
    RadioGroupComponent,
    ImageRadioStrategyComponent,
    StandardRadioStrategyComponent,
    ImageRadioStyleComponent,    
  ],
  exports: [
    RadioButtonComponent,
    RadioGroupComponent,
    ImageRadioStrategyComponent,
    StandardRadioStrategyComponent,
    ImageRadioStyleComponent,    
  ]
})
export class RadioButtonModule {

}