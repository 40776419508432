import { Injectable, Inject } from '@angular/core';
import { ConfigurationSearch, SortingArgument } from "../../shared/models";
import { PagerModel } from "../../shared/components/pager/pagerModel";
import { QuerySourceTypes } from "../../shared/models/responses/searchResult";

// Servie used to provide the data to search resolver. 
// TODO: Need more work to limit the scope of this class. Right now it registered globally, because it is used in resolver and resolver is global.
// We can't limit the scope of resolver to search component until registering search component as module.
@Injectable()
export class ConfSearchModelCacheService  {

  // Reads the models results from server response.
  // Note! If we use the same model for posting data and reading from server response then <ngOnChanges> would be triggered everytime when we set the value to this property.
  searchModel: ConfigurationSearch;

  // This model is only used when sending the data to server.
  querySearchModel: ConfigurationSearch;

  pagerModel: PagerModel;

  searchSessionId: number = null;

  sortingArgument: SortingArgument;

  // Indicates either data is loaded or not.
  dataLoaded: boolean = false;

  // Indicates either the data is in loading state.
  dataLoading: boolean = false;

  querySourceType: QuerySourceTypes = QuerySourceTypes.Database;

  /**
   * We need this method as this service is a global singleton and would otherwise never reset...
   */
  public reset() {
    this.searchModel = null;
    this.querySearchModel = null;
    this.pagerModel = null;
    this.searchSessionId = null;
    this.sortingArgument = null;
    this.dataLoaded = false;
    this.dataLoading = false;
    this.querySourceType = QuerySourceTypes.Database;
  }
}
