import { Injectable, Inject } from "@angular/core";
import { VisualObjectGroupWrapper } from "./visualObjectGroupWrapper";
import { ProductDataStore } from "../../shared/providers/productData";
import { Tab } from "../../shared/models";
import * as Immutable from "immutable";

@Injectable()
export class VisualObjectUIDataService {

  constructor(@Inject(ProductDataStore) public productStore: ProductDataStore) { }

  public groupWrappersById: Map<number, VisualObjectGroupWrapper> = new Map<number, VisualObjectGroupWrapper>();

  addOrUpdate(visualObjectWrapper: VisualObjectGroupWrapper): void {
    this.groupWrappersById.set(+visualObjectWrapper.visualObjectGroup.id, visualObjectWrapper);
  }

  delete(visualObjectId: number): void {
    this.groupWrappersById.delete(visualObjectId);
  }

  get(id: number): VisualObjectGroupWrapper
  {
    return this.groupWrappersById.get(id);
  }

  has(id: number): boolean {
    return this.groupWrappersById.has(id);
  }

  clear(): void {
    this.groupWrappersById.clear();
  }

  getAll(): Map<number, VisualObjectGroupWrapper> {
    return this.groupWrappersById;
  }

  isNextVisualObjectFloated(visualObjectId: number): boolean {
    
    let wrapper: VisualObjectGroupWrapper = this.groupWrappersById.get(visualObjectId);
    if (!wrapper)
      return false;

    let tab = this.productStore.getEntity<Tab>(wrapper.visualObjectGroup.visualObject.tabId);
    if (!tab)
      return false;

    // Get next non hanging visible object
    let startedIndex = tab.visualObjects.indexOf(visualObjectId);
    if (startedIndex < 0)
      return false;

    for (let index = startedIndex; index < tab.visualObjects.size; index++) {

      let currentVisualObjectId = tab.visualObjects.indexOf(index);

      if (!currentVisualObjectId || visualObjectId == currentVisualObjectId)
        continue;

      let wrp: VisualObjectGroupWrapper = this.groupWrappersById.get(+currentVisualObjectId);
      if (!wrp || !wrp.visible)
        continue;

      // If the current visual object is in the hanging list of requested visual object, then ignore it.
      if (wrapper.visualObjectGroup.hangings.indexOf(wrp.visualObjectGroup.id) > -1)
        continue;

      return wrp.visualObjectGroup.visualObject.floatingPosition;
    }

    return false;
  }
}