
<div class="option-group-wrapper lookup-title" [class.has-icon]="view.icon">
  <icon [view]="view"></icon>
  <div class="static-label-inline max-width align-middle">
    <label class="clear-spacing">{{view.title}}</label>
  </div>
  <help-link *ngIf="view.enableHelp" separator="false" styles="{{orientation == 'horizontal' && 'px-0'}}" (click)="helpClick(view)" [view]="view" [imageSet]="helpImageSet"></help-link>
</div>

<div class="{{displayStyle}}" [class.read-only]="readOnly" [class.highlight]="view.highlight">

  <ng-content select="[above-container]"></ng-content>

  <ng-container [ngSwitch]="orientation">

    <!-- Horizontal direction -->
    <div *ngSwitchCase="'horizontal'" class="horizontal row">
      <ng-container *ngFor="let vw of itemsView">
        <radio-button *ngIf="!vw.hideIfDisabled || !vw.disabled" class="col-auto" [view]="vw" attr.disabled-status="{{vw.disabled}}" [isReadOnly]="vw.readOnly" [isDisabled]="vw.disabled" [selectedViewId]="selectedViewId"></radio-button>
      </ng-container>
    </div>

    <!-- Default vertical direction -->
    <div class="row vertical {{view.helpImagePosition}}" *ngSwitchDefault>

      <div class="col">
        <ng-container *ngFor="let vw of itemsView">
          <radio-button *ngIf="!vw.hideIfDisabled || !vw.disabled" [view]="vw" attr.disabled-status="{{vw.disabled}}" [isReadOnly]="vw.readOnly" [isDisabled]="vw.disabled" [selectedViewId]="selectedViewId"></radio-button>
        </ng-container>
      </div>

      <ng-content select="[inline-container]"></ng-content>

    </div>
      
  </ng-container>
</div>