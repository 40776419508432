import * as Immutable from "immutable";
import { ImmutableObject } from "../../../../../shared/immutableObject";
import { InputViewModel } from "../../../../../shared/components";
import { AttributeField, SearchCriteriaRegion } from "./";
import { ConfSearchCriteria } from "../../../../shared/models";

export class SearchCriteriaModel extends ImmutableObject {

  public readonly CONTROL_ID = "controlId";
  public readonly ADD_BUTTON = "addButton";
  public readonly REMOVE_BUTTON = "removeButton";
  public readonly SELECTOR_ATTRIBUTES = "selectorAttributes";
  public readonly VALUE = "value";  
  public readonly INPUT_VIEW = "inputView";
  public readonly REGION = "region";
  public readonly SELECTED_ATTRIBUTE = "selectedAttribute";  

  get controlId(): string { return this.getInternalValue<string>(this.CONTROL_ID); }
  setControlId(controlId: string): this { return this.setInternalValue(this.CONTROL_ID, controlId); }

  get addButton(): boolean { return this.getInternalValue<boolean>(this.ADD_BUTTON); }
  setAddButton(addButton: boolean): this { return this.setInternalValue(this.ADD_BUTTON, addButton); }

  get removeButton(): boolean { return this.getInternalValue<boolean>(this.REMOVE_BUTTON); }
  setRemoveButton(removeButton: boolean): this { return this.setInternalValue(this.REMOVE_BUTTON, removeButton); }

  get region(): SearchCriteriaRegion { return this.getInternalValue<SearchCriteriaRegion>(this.REGION); }
  setRegion(region: SearchCriteriaRegion): this { return this.setInternalValue(this.REGION, region); }

  get selectedAttribute(): AttributeField { return this.getInternalValue<AttributeField>(this.SELECTED_ATTRIBUTE); }
  setSelectedAttribute(selectedAttribute: AttributeField): this { return this.setInternalValue(this.SELECTED_ATTRIBUTE, selectedAttribute); }

  get selectorAttributes(): Immutable.List<AttributeField> { return this.getInternalValue<Immutable.List<AttributeField>>(this.SELECTOR_ATTRIBUTES); }
  setSelectorAttributes(attributeSelector: Immutable.List<AttributeField>): this { return this.setInternalValue(this.SELECTOR_ATTRIBUTES, attributeSelector); }

  get value(): Immutable.List<ConfSearchCriteria> { return this.getInternalValue<Immutable.List<ConfSearchCriteria>>(this.VALUE); }
setValue(value: Immutable.List<ConfSearchCriteria>): this { return this.setInternalValue(this.VALUE, value); }

  get inputView(): InputViewModel { return this.getInternalValue<any>(this.INPUT_VIEW); }
  setInputView(inputView: InputViewModel): this { return this.setInternalValue(this.INPUT_VIEW, inputView); }


}