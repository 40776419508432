import { Component, Inject, Optional } from "@angular/core";
import * as Immutable from "immutable";
import { CodeDecorationValuesModel } from "../../../shared/components/code/codeDecorationValueModel";
import { CodeValueModelBuilder } from "../../../shared/components/code/providers/codeValueModelBuilder";
import { CodeDisplayStyles, Conf, GroupConfUIItem } from "../../../shared/models";
import { ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { BaseDetailItemComponent } from "../shared/baseDetailItemComponent";

@Component({
  selector: 'code-detail',
  templateUrl: './codeDetailComponent.html'  
})
export class CodeDetailComponent extends BaseDetailItemComponent {

  // Major title represents the section title.
  public majorTitle: string;
  public showInlineTitle: boolean = false;

  public valuesModels: Immutable.List<CodeDecorationValuesModel>;

  public calcVisible: boolean = false;
  public storedVisible: boolean = false;

  constructor(@Optional() @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,    
    @Inject(CodeValueModelBuilder) public builder: CodeValueModelBuilder,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore
  ) {
    super(storeSession);
  }

  ngOnInit() {

    super.ngOnInit();

    this.confStore.onConfigurationChange(this.configurationId, this.confSessionId, (conf: Conf) => {

      this.onDataReady();

    }).unsubscribeOn(this.unsubscribeSubject);

  }

  onDataReady(): void {

    this.visible = true;
    this.majorTitle = this.strings.Code;

    // Get values model which contain the values for each decoration belonging to current <ConfUIItem>
    this.valuesModels = this.builder.build(this.confUIItem, false, [CodeDisplayStyles.Render]);

    this.visible = this.valuesModels.size > 0;

    if (!this.visible)
      return;

    this.showInlineTitle = this.valuesModels.size > 1;

    if (!this.showInlineTitle) {

      // Move the decoration title to action-box because there is only one decoration found to be rendered.
      // Grab the visible confUIItem.
      let codeModel: CodeDecorationValuesModel = this.calcVisible ? this.valuesModels.get(0) : this.valuesModels.get(0);

      let firstDecoration = this.builder.calculatedDataProvider.getCodeDecoration(codeModel.decorationId);
      if (firstDecoration.title && firstDecoration.title.trim().length > 0)
        this.majorTitle = firstDecoration.title;
      // For individually displayed decoration if there is no title then hide the whole header row
      else if (typeof this.confUIItem != typeof GroupConfUIItem)
        this.showHeader = false;
    }

  }

}