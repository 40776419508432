import * as Immutable from "immutable";
import { ImmutableObject } from "../../../../shared/immutableObject";

export class AttributeDecorationItem extends ImmutableObject {

  protected readonly ATTRIBUTE_ID = "attributeId";
  protected readonly NUMERIC_FORMAT = "numericFormat";

  get attributeId(): number { return this.getInternalValue<number>(this.ATTRIBUTE_ID); }
  setAttributeId(attributeId: number): this { return this.setInternalValue(this.ATTRIBUTE_ID, attributeId); }

  get numericFormat(): string { return this.getInternalValue<string>(this.NUMERIC_FORMAT); }
  setNumericFormat(numericFormat: string): this { return this.setInternalValue(this.NUMERIC_FORMAT, numericFormat); }
}