import { Injectable, Inject } from '@angular/core';
import { HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as Immutable from "immutable";

import { AppStore, BaseStore } from "../../state"
import { ConfMessageProvider, ConfPageSessionService, ConfiguratorStore, ConfDataMemorizer } from "../../../configurator/providers";
import { AppStoreSubscriptionManager } from "../../providers";
import { ConfDataRequest, RequestViews, AttachmentCommand, AttachmentOperationMessage, ConfAttachmentRequest, ConfAttachment } from "../../models";
import { ManagedSubscription, SubscriptionOptions } from "../../../../shared/managedSubscription";
import { ModelFactory } from "../../providers/modelFactory";
import { GlobalDataStore } from "../../providers/globalData";
import { PageStore } from "../../providers/page";
import { AttachmentsDataActionCreator } from "./attachmentsDataActionCreator";
import { AttachmentsDataController } from "./attachmentsDataController";
import { ConfDataController } from "../../providers/configurationData";
import { ManagedSubject } from "../../../../shared/managedSubject";

@Injectable()
export class AttachmentsStore extends BaseStore {

  constructor(
    @Inject(AppStore) public appStore: AppStore,
    @Inject(AppStoreSubscriptionManager) public appStoreSubscriptionManager: AppStoreSubscriptionManager,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(ConfMessageProvider) public messagesProvider: ConfMessageProvider,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    @Inject(ConfDataController) public configuratorController: ConfDataController,
    @Inject(PageStore) public pageStore: PageStore,
    @Inject(AttachmentsDataActionCreator) public attachmentsDataActionCreator: AttachmentsDataActionCreator,
    @Inject(AttachmentsDataController) public attachmentsDataController: AttachmentsDataController,
    @Inject(ConfDataMemorizer) public memorizer: ConfDataMemorizer,
    public globalDataStore: GlobalDataStore
  ) {
    super(appStore, appStoreSubscriptionManager, modelFactory);
  }

  public getAttachmentLink(attachmentId: number): string {
    let model: ConfAttachmentRequest = this.modelFactory.createAny(ConfAttachmentRequest.name) as any;

    model.client = this.pageStore.getActiveClientType();
    model.attachmentId = attachmentId;
    model.configurationId = this.storeSession.activeConfigurationId;
    model.confSessionId = this.storeSession.confSessionId;
    return this.configuratorController.getConfigurationAttachmentUrl(model);
  }

  onConfAttachmentsChange(configurationId: number, confSessionId: number, callback: (attachmentValueList: Immutable.List<ConfAttachment>) => void): ManagedSubscription {
    let identifier = `${confSessionId}_${configurationId}_attachments`;

    return this.createStoreSubscription<Immutable.List<ConfAttachment>>(identifier, this.memorizer.getAttachmentsMemorizer(confSessionId, configurationId), callback);
  }

  public createStoreSubscription<T>(identifier: string, memorizer: () => T, callback: (data: T) => void): ManagedSubscription {
    let managedSubject: ManagedSubject<T> = this.appStoreSubscriptionManager.getOrCreateStoreSubject(identifier, memorizer, false, 1, false);

    return managedSubject.subscribe((data: T) => callback(data));
  }

  public onAttachmentOperationMessage(subscriptionOptions: SubscriptionOptions<Immutable.List<AttachmentOperationMessage>>): ManagedSubscription {
    return this.messagesProvider.onMessagesRequest<AttachmentOperationMessage>(this.storeSession.confSessionId, AttachmentOperationMessage.name, subscriptionOptions);
  }

  public uploadFile(file: File, progressCallback, errorCallback?: (error) => void) {
    return this.attachmentsDataActionCreator.dispatchUploadFile(this.createModel(), file, progressCallback, errorCallback);
  }

  public createModel() {
    let model = this.modelFactory.createAny(ConfDataRequest.name) as ConfDataRequest;
    model.client = this.pageStore.getActiveClientType();
    model.confSessionId = this.storeSession.confSessionId;
    model.attachment = this.modelFactory.createAny(AttachmentCommand.name) as AttachmentCommand;
    model.attachment.configurationId = this.storeSession.activeConfigurationId;
    model.attachment.responseFormat = this.globalDataStore.getConfResponseFormat(model.client);
    return model;
  }

  public deleteAttachment(attachmentId: number) {
    let model = this.modelFactory.createAny(ConfDataRequest.name) as ConfDataRequest;
    model.client = this.pageStore.getActiveClientType();
    model.confSessionId = this.storeSession.confSessionId;
    model.attachment = this.modelFactory.createRequestOrCommand<AttachmentCommand>(AttachmentCommand.name);
    model.attachment.delete = true;
    model.attachment.attachmentId = attachmentId;
    model.attachment.configurationId = this.storeSession.activeConfigurationId;
    model.attachment.responseFormat = this.globalDataStore.getConfResponseFormat(model.client);

    this.attachmentsDataActionCreator.dispatchDeleteAttachment(model);
  }
}