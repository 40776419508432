<div #sideBarElement class="side-bar row {{classes}}"
     id="{{id}}"     
     [class.sticky]="$any(isSticky) == 'true'"
     [ngStyle]="containerStyle">
  <div class="col modal-content px-0 h-100"
       [ngStyle]="menuStyle">
    <div *ngIf="showHeader" class="modal-header max-width">
      <div class="row justify-content-center align-self-center align-middle p-3 m-0">
        <h2 class="col modal-title justify-content-center">{{title}}</h2>
        <div class="col-auto">
          <a href="#" tabindex="-1" (click)="cancelButtonClicked($event)">
            <co-image key="delete" width="24px" height="24px"> </co-image>
          </a>
        </div>
      </div>
      <!-- TODO: Regular popup and sidebar header's visibility conditions are not consistent -->
      <div class="modal-content-title" *ngIf="showTitleBar" >
        <ng-content select="[card-type=header]"></ng-content>
      </div>
    </div>
    <div class="modal-body p-0 m-0">
      <ng-content select="[card-type=body]"></ng-content>
    </div>
    <div *ngIf="showFooter" class="modal-footer">
      <div>
        <button class="btn btn-primary" (click)="okButtonClicked($event)" type="button">{{okButtonText}}</button>
        <button class="btn btn-primary ml-2" (click)="cancelButtonClicked($event)" type="button">{{cancelButtonText}}</button>
      </div>
    </div>
  </div>

  <div *ngIf="extended" [ngStyle]="subMenuStyle" class="col-auto px-0 sidebar-extension-container">
    <ng-content select="sidebar-extension"></ng-content>
  </div>

  <div class="stop-interaction" *ngIf="stopInteraction && !calcMaximize" [ngStyle]="containerStyle">

  </div>

</div>

