import { Component, Inject, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { InputViewModel, NotificationInfo, NotificationService, NotificationType } from "../../../shared/components";
import { ImageSets } from "../../../shared/utils";
import { AccountCommandTypes, AccountDataMessage } from "../../shared/models";
import { RouteRedirector } from "../../shared/providers";
import { AccountDataMessageProvider, AccountDataStore } from "../../shared/providers/accountData";
import { BaseAccountMessageComponent } from "../shared/baseAccountMessageComponent";

@Component({
  selector: 'create-profile',
  templateUrl: './createProfileComponent.html'
})
export class CreateProfileComponent extends BaseAccountMessageComponent {
    
  public userNameView: InputViewModel;
  public nameView: InputViewModel;  
  public companyView: InputViewModel;
  public phoneNumberView: InputViewModel;
  public mobileNumberView: InputViewModel;
  public newPasswordView: InputViewModel;
  public confirmNewPasswordView: InputViewModel;

  public userNameErrors: Array<string> = [];
  public nameErrors: Array<string> = [];
  public companyErrors: Array<string> = [];  
  public newPasswordErrors: Array<string> = [];
  public confirmNewPasswordErrors: Array<string> = [];

  constructor(
    @Inject(AccountDataStore) public accountStore: AccountDataStore,
    public accountMessageProvider: AccountDataMessageProvider,
    public redirector: RouteRedirector,
    public activatedRoute: ActivatedRoute,
    public notificationService: NotificationService,
    public cd: ChangeDetectorRef
  ) {
    super(accountStore, accountMessageProvider, redirector, activatedRoute, cd);
  }


  ngOnInit(): void {

    this.user = this.activatedRoute.snapshot.paramMap.get("user");

    this.userNameView = new InputViewModel(this.strings.Username);
    this.userNameView.icon = "user";    

    this.nameView = new InputViewModel(this.strings.Name);
    this.nameView.icon = "name";        

    this.companyView = new InputViewModel(this.strings.Company);
    this.companyView.icon = "organization";

    this.phoneNumberView = new InputViewModel(this.strings.Phone);
    this.phoneNumberView.icon = "phone";

    this.mobileNumberView = new InputViewModel(this.strings.Mobile);
    this.mobileNumberView.icon = "mobile";

    this.newPasswordView = new InputViewModel(this.strings.NewPassword);
    this.confirmNewPasswordView = new InputViewModel(this.strings.ConfirmNewPassword);
    this.newPasswordView.icon = this.confirmNewPasswordView.icon = "password";
    this.newPasswordView.iconImageSet = this.getImageSet(ImageSets.Control);
    this.confirmNewPasswordView.iconImageSet = this.getImageSet(ImageSets.Control);

    super.ngOnInit();
  }

  protected get userName(): string {
    return this.userNameView.value ? this.userNameView.value.trim() : "";
  }

  public validateInfo(): boolean {
    // Perform some validation on client side. Will also be done on server side.

    // Reset any old errors    
    this.userNameErrors = [];    
    this.nameErrors = [];
    this.companyErrors = [];        
    this.newPasswordErrors = [];
    this.confirmNewPasswordErrors = [];

    let isValidForm: boolean = true;

    // Username is mandatory
    if (!this.userName) {
      this.userNameErrors.push(this.strings.UsernameIsRequired);
      isValidForm = false;
    }

    // Username cannot contain white space
    if (/\s/g.test(this.userName)) {
      this.userNameErrors.push(this.strings.UsernameCannotContainWhiteSpace);
      isValidForm = false;
    }

    // Name is mandatory
    if (!this.nameView.value) {
      this.nameErrors = [this.strings.NameIsRequired];
      isValidForm = false;
    }
    
    if (!this.companyView.value) {
      this.companyErrors = [this.strings.CompanyNameIsRequired];
      isValidForm = false;
    }

    // New password is mandatory
    if (!this.newPasswordView.value) {
      this.newPasswordErrors = [this.strings.ChangePasswordErrorMessage_SpecifyNewPassword];
      isValidForm = false;
    }

    // Confirm new password is mandatory
    if (!this.confirmNewPasswordView.value) {
      this.confirmNewPasswordErrors = [this.strings.ChangePasswordErrorMessage_SpecifyNewPassword];
      isValidForm = false;

    }
    // New passwords must match
    else if (this.newPasswordView.value !== this.confirmNewPasswordView.value) {
      this.confirmNewPasswordErrors = [this.strings.ChangePasswordErrorMessage_NewPasswordsNoMatch];
      isValidForm = false;
    }

    return isValidForm;
  }

  onSuccess(data: AccountDataMessage): void {

    if (data.commandType == AccountCommandTypes.AccountInactive) {

      this.notificationService.notify(<NotificationInfo>{
        title: this.strings.AccountCreationSuccess,
        message: data.message,
        type: NotificationType.Warning,
        selfClose: false
      });

      this.redirector.redirectToLogin();
    }
    else {

      this.notificationService.notify(<NotificationInfo>{
        title: this.strings.Success,
        message: this.strings.AccountCreatedSuccessfully,
        type: NotificationType.Success,
        selfClose: true
      });

      this.accountStore.logInUser(this.userNameView.value, this.newPasswordView.value);
    }
  }

  create(): void {

    if (this.validateInfo()) {

      this.accountStore.createAccount(
        this.sessionKey,
        this.nameView.value,
        this.userNameView.value,
        this.companyView.value,
        this.phoneNumberView.value,
        this.mobileNumberView.value,
        this.newPasswordView.value
        
      );

    }
  }
}