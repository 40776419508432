export class ProductDataActions {  

  static FETCH_ASSORTMENTS = "@FETCH_ASSORTMENTS";
  static FETCH_ROOT_ASSORTMENTS = "@FETCH_ROOT_ASSORTMENTS";
  static ROOT_ASSORTMENTS_LOADED = "@ROOT_ASSORTMENTS_LOADED";
  static FETCH_PRODUCTS = "@FETCH_PRODUCTS";
  static FETCH_PRODUCT_TABS = "@FETCH_PRODUCT_TABS";
  static PRODUCT_LINKED_DATA_LOADED = "@PRODUCT_LINKED_DATA_LOADED";  
  static FETCH_ENTITIES = "@FETCH_ENTITIES";
  static ENTITIES_LOADED = "@ENTITIES_LOADED";
}