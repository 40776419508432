import * as Immutable from "immutable"
import { AbstractAccountMessage } from "./abstractAccountMessage";

export class UserUpdateMessage extends AbstractAccountMessage {

  protected readonly SUCCESS = "success";
  protected readonly COMMAND_NAME = "commandName";
  protected readonly ERRORS = "errors";

  get success(): boolean { return this.getInternalValue<boolean>(this.SUCCESS); }
  setSuccess(success: boolean): this { return this.setInternalValue(this.SUCCESS, success); }

  get commandName(): string { return this.getInternalValue<string>(this.COMMAND_NAME); }
  setCommandName(commandName: string): this { return this.setInternalValue(this.COMMAND_NAME, commandName); }

  get errors(): Immutable.List<string> { return this.getInternalValue<Immutable.List<string>>(this.ERRORS); }
  setErrors(errors: Immutable.List<string>): this { return this.setInternalValue(this.ERRORS, errors); }

}