import { Component, ViewEncapsulation, Inject, ViewChild, Host, ChangeDetectorRef } from "@angular/core";
import * as Immutable from "immutable";
import { Routing } from "../../shared/route/routeDecorator";
import { BreakPointAccessor, Breakpoints } from "../../shared/utils";
import { BackdropComponent, PopoverService, InputViewModel, GenericDropdownGroup } from "../../shared/components";
import { AccountDataStore } from "../shared/providers/accountData";
import { ConfSearchResultItem, ConfInfo, Conf, RequestViews, ConfigurationSearch, ConfDeleteMessage, IntervalType, SystemInformation } from "../shared/models";
import { AppStoreSubscriptionManager, RouteNames, RouteRedirector } from "../shared/providers";
import { BaseComponent, PageComponent } from "../shared";
import { ConfiguratorStore, ConfMessageProvider } from "../configurator/providers";
import { SearchDataStore } from "../shared/providers/searchData";
import { SearchSessionData } from "../shared/state";
import { PageStore } from "../shared/providers/page";
import { GlobalDataStore } from "../shared/providers/globalData";
import { VisualObjectHelper } from "../configurator/parameters/shared";
import { VisualObjectUIDataService } from "../configurator/shared/visualObjectUIDataService";

@Routing({ path: 'start' })
@Routing({ path: '**' })
@Component({
  encapsulation: ViewEncapsulation.None,
  templateUrl: './startComponent.html',
  providers: [AppStoreSubscriptionManager, VisualObjectHelper, VisualObjectUIDataService]
})
export class StartComponent extends PageComponent {

  @ViewChild(BackdropComponent)
  public backdrop: BackdropComponent;

  public newsOpened = false;
  public canSearch = false;
  public recentTitle: string;
  public includeWorkgroup: boolean = false;
  public showWorkGroupRecentSeparately: boolean = false;

  public hasUserConfs: boolean = true;
  public hasWorkGroupConfs: boolean = true;
  public hasUnsavedConfs: boolean = true;

  public includeModules: Array<StartConfigurationSearch> = [];

  /** Search session id for user confs. */
  public userSearchSessionId: number;
  public userConfsByInterval: Immutable.OrderedMap<IntervalType, Immutable.OrderedMap<number, ConfInfo>>;

  public unsavedSearchSessionId: number;
  public unsavedConfsByInterval: Immutable.OrderedMap<IntervalType, Immutable.OrderedMap<number, ConfInfo>>;


  /** Search session id for workgroup confs. */
  public workGroupSearchSessionId: number;
  public workGroupConfsByInterval: Immutable.OrderedMap<IntervalType, Immutable.OrderedMap<number, ConfInfo>>;

  public configurationSearches: Immutable.List<ConfigurationSearch> = Immutable.List<ConfigurationSearch>();

  public recentView: InputViewModel;
  public recentsView: Array<InputViewModel> = [];

  public userRecentsLoaded = false;
  public workgroupRecentsLoaded = false;
  public unsavedLoaded = false;
  public showRecentConfsDropdown: boolean = false;
  
  public systemInformations: Immutable.List<SystemInformation> = Immutable.List<SystemInformation>();

  public allLoaded = false;

  @ViewChild(GenericDropdownGroup)
  public dropdown: GenericDropdownGroup;


  constructor(
    @Inject(BreakPointAccessor) public breakPointAccessor: BreakPointAccessor,
    @Inject(SearchDataStore) public searchDataStore: SearchDataStore,
    @Inject(AccountDataStore) public accountStore: AccountDataStore,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(PopoverService) public popoverService: PopoverService,
    @Inject(PageStore) public pageStore: PageStore,
    @Inject(ConfMessageProvider) public confMessageProvider: ConfMessageProvider,
    @Inject(AppStoreSubscriptionManager) public appStoreSubscriptionManager: AppStoreSubscriptionManager,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    @Inject(ChangeDetectorRef) public cdr: ChangeDetectorRef,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore
  ) {
    super();
  }

  ngOnInit() {

    super.ngOnInit();

    this.pageStore.updateActiveRoute(RouteNames.Start);
    this.canSearch = this.accountStore.getUser().systemAuthorization.canSearchConfigurations;
    
    this.loadAllModules();    

    this.recentView = new InputViewModel();
    this.recentsView = [];

    // Only show dropdown if my recents and workgroup recents both are visible in first column.
    if (this.globalDataStore.globalSettings.showWorkgroupRecent && this.globalDataStore.globalSettings.showMyRecent) {
      this.recentsView.push(<InputViewModel>{ id: StartConfigurationSearch.MyRecent, title: this.strings.MyRecent, value: this.strings.MyRecent });
      this.recentsView.push(<InputViewModel>{ id: StartConfigurationSearch.MyWorkgroup, title: this.strings.WorkgroupRecent, value: this.strings.WorkgroupRecent });
    }
    
  }

  public loadAllModules(): void {

    // Stores all promises.
    let promises: Promise<any>[] = [];

    promises.push(this.loadSystemInformation());
    promises.push(this.loadRecentConfigurations());
    promises.push(this.loadWorkgroupRecents());
    promises.push(this.loadUnsavedConfigurations());
    promises.push(this.loadSavedSearches());

    Promise.all(promises).then(responses => {

      this.allLoaded = true;      
      this.adjustLayoutSettings();
      this.listenChanges();

      if (!this.cdr['destroyed'])
        this.cdr.detectChanges();

    });
  }

  adjustLayoutSettings() {

    this.showRecentConfsDropdown = this.hasWorkGroupConfs && this.hasUserConfs && this.configurationSearches.size > 0;
    this.showWorkGroupRecentSeparately = this.configurationSearches.size == 0 && (this.hasUserConfs || this.hasUnsavedConfs);

    // Display workgroups only if It is shown on separate column or there are no users confs and unsaved confs.
    if (this.showWorkGroupRecentSeparately) {
      this.includeWorkgroup = false;
      this.recentTitle = this.strings.MyRecent;
    }
    else if (!this.hasUserConfs && !this.hasUnsavedConfs) {

      this.recentTitle = this.strings.WorkgroupRecent;
      this.includeWorkgroup = true;
    }

  }

  public listenChanges(): void {

    // Subscribe for unsaved configurations
    if (this.unsavedSearchSessionId) {
      this.searchDataStore.listenSearchSessionDataChange(this.unsavedSearchSessionId, {
        next: (searchSessionData: SearchSessionData) => {

          this.popoverService.close();
          this.setUnSavedConfs(searchSessionData);
          this.adjustLayoutSettings();
        },
        listenNewEventsOnly: true
      }).unsubscribeOn(this.unsubscribeSubject);
    }

    // Subscribe for saved searches
    this.searchDataStore.listenSavedSearchesChange(savedSearches => {

      this.configurationSearches = savedSearches;
      
    }).unsubscribeOn(this.unsubscribeSubject);

    // Send command for saved searches
    //this.searchDataStore.getSavedConfigurationSearches(RequestViews.Start);

    // My recents
    if (this.globalDataStore.globalSettings.showMyRecent && this.userSearchSessionId) {

      // Listen for remove and update results and close the popper context menu.
      this.searchDataStore.listenSearchSessionDataChange(this.userSearchSessionId, {
        next: (searchSessionData: SearchSessionData) => {

          this.popoverService.close();

          this.setRecentUserConfs(searchSessionData);

        },
        listenNewEventsOnly: true
      }).unsubscribeOn(this.unsubscribeSubject);

    }

    // Workgroup change
    if (this.globalDataStore.globalSettings.showWorkgroupRecent && this.workGroupSearchSessionId) {
      
      this.searchDataStore.listenSearchSessionDataChange(this.workGroupSearchSessionId, {
        next: (searchSessionData: SearchSessionData) => {

          this.popoverService.close();
          this.setWorkgroups(searchSessionData);

        },
        listenNewEventsOnly: true
      }).unsubscribeOn(this.unsubscribeSubject);

    }

  }

  public loadSystemInformation(): Promise<boolean> {

    return new Promise<boolean>(resolve => {

      this.globalDataStore.getEntities<SystemInformation>(SystemInformation.name).subscribe((storeResponse) => {

        storeResponse.data.toArray().forEach((systemInfo) => {

          if (!systemInfo.isWelcomeMessage)
            this.systemInformations = this.systemInformations.push(systemInfo);

        });

        resolve(true);

      }).unsubscribeOn(this.unsubscribeSubject);

    });
  }

  public loadWorkgroupRecents(): Promise<boolean> {

    return new Promise<boolean>(resolve => {

      if (!this.canSearch || !this.globalDataStore.globalSettings.showWorkgroupRecent) {

        this.hasWorkGroupConfs = false;
        resolve(true);
        return;

      }

      let workGroupId = this.accountStore.getUser().workGroup.longId;
      this.searchDataStore.getRecentWorkGroupConf(workGroupId, RequestViews.Start).subscribe(storeResponse => {

        this.setWorkgroups(storeResponse.data);
        resolve(true);

      });
    });
  }

  public loadRecentConfigurations(): Promise<boolean> {

    return new Promise<boolean>(resolve => {

      if (!this.canSearch || !this.globalDataStore.globalSettings.showMyRecent) {

        this.hasUserConfs = false;
        resolve(true);
        return;       

      }

      this.recentTitle = this.strings.MyRecent;

      let userId = this.accountStore.getUser().longId;
      this.searchDataStore.getRecentUserConf(userId, RequestViews.Start).subscribe(storeResponse => {

        this.setRecentUserConfs(storeResponse.data);
        resolve(true);

      });

    });
  }

  public loadUnsavedConfigurations(): Promise<boolean> {

    return new Promise<boolean>(resolve => {

      if (!this.canSearch) {
        resolve(true);
        return;
      }

      let userId = this.accountStore.getUser().longId;
      this.searchDataStore.getUnsavedUserConf(userId, RequestViews.Start).subscribe(storeResponse => {

        this.setUnSavedConfs(storeResponse.data);
        resolve(true);

      });

    });
  }

  public loadSavedSearches(): Promise<boolean> {

    return new Promise<boolean>(resolve => {

      if (!this.canSearch) {
        resolve(true);
        return;
      }

      // Send command for saved searches
      this.searchDataStore.getSavedConfigurationSearches(RequestViews.Start).subscribe(storeResponse => {

        this.configurationSearches = storeResponse.data;
        resolve(true);

      });

    });

  }

  public setWorkgroups(data: SearchSessionData): void {

    this.workGroupSearchSessionId = data.searchSessionId;
    this.workGroupConfsByInterval = this.getInteralMap(data);
    this.hasWorkGroupConfs = this.workGroupConfsByInterval.size > 0;

  }

  public setRecentUserConfs(data: SearchSessionData): void {

    this.userSearchSessionId = data.searchSessionId;
    this.userConfsByInterval = this.getInteralMap(data);
    this.userRecentsLoaded = true;
    this.hasUserConfs = this.userConfsByInterval.size > 0;

  }

  public setUnSavedConfs(data: SearchSessionData): void {

    this.unsavedSearchSessionId = data.searchSessionId;
    this.unsavedConfsByInterval = this.getInteralMapFirst(data);
    this.hasUnsavedConfs = this.unsavedConfsByInterval.size > 0;
    this.unsavedLoaded = true;

  }

  public getInteralMap(searchSessionData: SearchSessionData): Immutable.OrderedMap<IntervalType, Immutable.Map<number, ConfInfo>> {

    if (!searchSessionData.confSearchResultItems)
      return Immutable.OrderedMap<number, Immutable.Map<number, ConfInfo>>();

    return searchSessionData.confSearchResultItems.reduce((map, confSearchResultItem) =>
      map.set(confSearchResultItem.intervalType, (map.get(confSearchResultItem.intervalType) || Immutable.OrderedMap<number, ConfInfo>()).set(confSearchResultItem.confSessionId, this.confStore.getConfInfo(confSearchResultItem.configurationId, confSearchResultItem.confSessionId))),
      Immutable.Map<IntervalType, Immutable.OrderedMap<number, ConfInfo>>()).sortBy((v, k) => k).toMap();
  }

  public getInteralMapFirst(searchSessionData: SearchSessionData): Immutable.OrderedMap<IntervalType, Immutable.Map<number, ConfInfo>> {

    if (!searchSessionData.confSearchResultItems)
      return Immutable.OrderedMap<number, Immutable.Map<number, ConfInfo>>();

    let maxConfBySessionId: ConfSearchResultItem = searchSessionData.confSearchResultItems.max(x => x.confSessionId);
    let maxConfSessionId: number = maxConfBySessionId ? maxConfBySessionId.confSessionId : -1;

    return searchSessionData.confSearchResultItems.filter(x => x.confSessionId == maxConfSessionId).reduce((map, confSearchResultItem) =>
      map.set(confSearchResultItem.intervalType, (map.get(confSearchResultItem.intervalType) || Immutable.OrderedMap<number, ConfInfo>()).set(confSearchResultItem.confSessionId, this.confStore.getConfInfo(confSearchResultItem.configurationId, confSearchResultItem.confSessionId))),
      Immutable.Map<number, Immutable.OrderedMap<number, ConfInfo>>());
  }
    
  

  public updateRecents() {
    switch (this.dropdown.view.value) {
      case StartConfigurationSearch.MyRecent:
        this.includeWorkgroup = false;
        break;
      case StartConfigurationSearch.MyWorkgroup:
        this.includeWorkgroup = true;
        break;
    }
  }

  public openNews() {
    this.newsOpened = true;
    this.backdrop.popup.open();
  }

  public onClosed(): void {
    this.newsOpened = false;
    if (this.backdrop.visible)
      this.backdrop.popup.close();
  }

  public get largeUp() {
    return this.breakPointAccessor.activeBreakPoint.min > Breakpoints.lg.max;
  }

  public get mediumUp() {
    return this.breakPointAccessor.activeBreakPoint.min > Breakpoints.sm.max;
  }

  public get smallUp() {
    return this.breakPointAccessor.activeBreakPoint.min > Breakpoints.sm.min;
  }

  ngOnDestroy() {
    this.appStoreSubscriptionManager.dispose();

    super.ngOnDestroy();
  }
}

export enum StartConfigurationSearch {
  MyWorkgroup = "My workgroup",
  MyRecent = "My recent",
  SavedSearches = "Saved searches"
}