export class GlobalDataActions {

  static FETCH_GLOBAL_ENTITIES = "@FETCH_GLOBAL_ENTITIES";
  static GLOBAL_ENTITIES_LOADED = "@GLOBAL_ENTITIES_LOADED";

  static REQUEST_GLOBAL_SETTINGS = "@REQUEST_GLOBAL_SETTINGS";
  static GLOBAL_SETTINGS_LOADED = "@GLOBAL_SETTINGS_LOADED";

  static REQUEST_LOCALIZE_DATA = "@REQUEST_LOCALIZE_DATA";
  static LOCALIZE_DATA_LOADED = "@LOCALIZE_DATA_LOADED";

  static REQUEST_WEB_SETTINGS = "@REQUEST_WEB_SETTINGS";
  static WEB_SETTINGS_LOADED = "@WEB_SETTINGS_LOADED";

  static REQUEST_UI_SETTINGS = "@REQUEST_UI_SETTINGS";
  static UI_SETTINGS_LOADED = "@UI_SETTINGS_LOADED";
}