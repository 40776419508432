<div #visualObjectContainer>
  <div class="visual-object-margin bom-decoration decoration" [class.border-style]="!showTitle" [style.max-width.%]="'100'" >
    <gridview>
      <gridview-header classes="decoration-header" *ngIf="showTitle">
        <header-columntemplate classes="header-image" size="auto">
          <co-image width="24" height="24" type="svg" key="bom" [imageSet]="imageSet"></co-image>
        </header-columntemplate>
        <header-columntemplate classes="header-title">
          {{decoration.title}}
        </header-columntemplate>
        <header-columntemplate classes="text-right details-icon header-image" size="auto" title="{{strings.Details}}">
          <co-image width="32" height="32" type="svg" key="viewdetails" role="button" (click)="onBomCompositeClick()" [imageSet]="imageSet"></co-image>
        </header-columntemplate>
      </gridview-header>
    </gridview>

    <bom-decoration-contents [decoration]="decoration" [confBomValue]="confBomValue" ></bom-decoration-contents>
  </div>
</div>