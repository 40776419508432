import * as Immutable from "immutable";

import { ImmutableObject } from "../../../../shared/immutableObject";
import { GridRow } from "./gridRow";
import { PaginationInfo } from "./paginationInfo";
import { SortingInfo } from "./sortingInfo";
import { SearchInfo } from "./searchInfo";

export class Grid extends ImmutableObject {

  protected readonly ID = "id";
  protected readonly ROWS = "rows";
  protected readonly ADDITIONAL_DATA = "additionalData";
  protected readonly PAGINATION = "pagination";
  protected readonly SORTING = "sorting";
  protected readonly SEARCH = "search";

  get id(): string { return this.getInternalValue<string>(this.ID); }
  setId(id: string): this { return this.setInternalValue(this.ID, id); }

  get rows(): Immutable.List<GridRow> { return this.getInternalValue<Immutable.List<GridRow>>(this.ROWS); }
  setRows(rows: Immutable.List<GridRow>): this { return this.setInternalValue(this.ROWS, rows); }

  get additionalData(): Immutable.Map<string, string> { return this.getInternalValue<Immutable.Map<string, string>>(this.ADDITIONAL_DATA); }
  setAdditionalData(additionalData: Immutable.Map<string, string>): this { return this.setInternalValue(this.ADDITIONAL_DATA, additionalData); }

  get pagination(): PaginationInfo { return this.getInternalValue<PaginationInfo>(this.PAGINATION); }
  setPagination(pagination: PaginationInfo): this { return this.setInternalValue(this.PAGINATION, pagination); }

  get sorting(): SortingInfo { return this.getInternalValue<SortingInfo>(this.SORTING); }
  setSorting(sorting: SortingInfo): this { return this.setInternalValue(this.SORTING, sorting); }

  get search(): SearchInfo { return this.getInternalValue<SearchInfo>(this.SEARCH); }
  setSearch(search: SearchInfo): this { return this.setInternalValue(this.SEARCH, search); }
}