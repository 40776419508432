<div class="action-box">
  <div class="row header align-middle mx-0" *ngIf="showHeader == true">
    <div *ngIf="iconLeft" class="col-auto px-0">
      <co-image width="32" height="32" type="svg" [imageSet]="iconLeftImageSet" [isVault]="isIconLeftVault" [key]="iconLeft"></co-image>
    </div>
    <div class="col text-uppercase" [class.pl-0]="!iconLeft">
      <h3>{{textLeft}}</h3>
    </div>

    <div *ngIf="iconRight" class="col-auto pr-2 text-right clickable icon-text" (click)="onRightIconClick.emit()">
      <span class="pr-2">{{textRight}}</span>
      <co-image [width]="'32'" [height]="'32'" [key]="iconRight" [imageSet]="iconRightImageSet"></co-image>
    </div>    
    <ng-content select="[card-type=control]" ></ng-content>    
  </div>
  <ng-content></ng-content>
</div>
