
import { BaseEntity } from "../../baseEntity"

export abstract class ConfAutoCompleteItem extends BaseEntity {

  protected readonly IDENTITY = "identity";
  protected readonly TEXT = "text";  
  protected readonly REVISION = "revision";

  get identity(): string { return this.getInternalValue<string>(this.IDENTITY); }
  setIdentity(identity: string): this { return this.setInternalValue(this.IDENTITY, identity); }

  get text(): string { return this.getInternalValue<string>(this.TEXT); }
  setText(text: string): this { return this.setInternalValue(this.TEXT, text); }
  
  get revision(): string { return this.getInternalValue<string>(this.REVISION); }
  setRevision(revision: string): this { return this.setInternalValue(this.REVISION, revision); }
}