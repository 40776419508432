import { Injectable, HostListener } from "@angular/core";
import { BreakpointOperator, Breakpoints, BreakpointRange } from "./breakPoints";

@Injectable()
export class BreakPointAccessor {

  // Stores all breakpoint sizes e.g xs, sm, md, lg etc It helps to get lower or higher breakpoint from active one.
  sizes: BreakpointRange[] = [];

  constructor() {
    this.sizes = [Breakpoints.xs, Breakpoints.sm, Breakpoints.md, Breakpoints.lg, Breakpoints.xl];    
  }

  private _activeBreakPoint: BreakpointRange;
  public get activeBreakPoint() {
    
    return this._activeBreakPoint;
  }

  /**
   * Sets the current break point.
   * @param width
   */
  public initialize(width: number = null): void {

    let windowWidth = width ? width : window.innerWidth;

    if (windowWidth >= Breakpoints.xs.min && windowWidth <= Breakpoints.xs.max) {
      this._activeBreakPoint = Breakpoints.xs;
    }

    else if (windowWidth >= Breakpoints.sm.min && windowWidth <= Breakpoints.sm.max) {
      this._activeBreakPoint = Breakpoints.sm;
    }

    else if (windowWidth >= Breakpoints.md.min && windowWidth <= Breakpoints.md.max) {
      this._activeBreakPoint = Breakpoints.md;
    }

    else if (windowWidth >= Breakpoints.lg.min && windowWidth <= Breakpoints.lg.max) {
      this._activeBreakPoint = Breakpoints.lg;
    }

    else if (windowWidth >= Breakpoints.xl.min && windowWidth <= Breakpoints.xl.max) {
      this._activeBreakPoint = Breakpoints.xl;
    }
  }

  //public current(): string {

  //}

  /**
   * Returns true if break point matches with active one.
   * @param breakPoint e.g xs, lg
   */
  public equal(breakPoint: BreakpointRange): boolean {

    return breakPoint === this._activeBreakPoint;
  }

  /**   
   * Media of at least the minimum breakpoint width.
   * Returns true If active breakpoint is equal or less than the given breakpoint.
   * @param breakPoint
   */
  public equalOrDown(breakPoint: BreakpointRange): boolean {

    // The index of the breakPoint
    let index = this.sizes.indexOf(breakPoint);

    if (index < 0)
      return false; // Index not found.
    
    // Active breakpoint index for current window.
    let activeIndex = this.sizes.indexOf(this._activeBreakPoint);

    return activeIndex <= index;
  }

  /**
   * Media of at most the maximum breakpoint width.
   * Returns true If active breakpoint is equal or above the given breakpoint.
   * @param breakPoint
   */
  public equalOrUp(breakPoint: BreakpointRange): boolean {

    // The index of the breakPoint
    let index = this.sizes.indexOf(breakPoint);

    if (index < 0)
      return false; // Index not found.

    // Active breakpoint index for current window.
    let activeIndex = this.sizes.indexOf(this._activeBreakPoint);

    return activeIndex >= index;
  }

  public up(breakPoint: BreakpointRange): boolean {

    // The index of the breakPoint
    let index = this.sizes.indexOf(breakPoint);

    if (index < 0)
      return false; // Index not found.

    // Active breakpoint index for current window.
    let activeIndex = this.sizes.indexOf(this._activeBreakPoint);

    return activeIndex > index;

  }

  public down(breakPoint: BreakpointRange): boolean {
    // The index of the breakPoint
    let index = this.sizes.indexOf(breakPoint);

    if (index < 0)
      return false; // Index not found.

    // Active breakpoint index for current window.
    let activeIndex = this.sizes.indexOf(this._activeBreakPoint);

    return activeIndex < index;
  }

  public isActive(breakpoint: BreakpointRange, op: BreakpointOperator): boolean {

    switch (op) {

      case BreakpointOperator.Up:
        return this.up(breakpoint);

      case BreakpointOperator.Down:
        return this.down(breakpoint);

      case BreakpointOperator.EqualOrUp:
        return this.equalOrUp(breakpoint);

      case BreakpointOperator.EqualOrDown:
        return this.equalOrDown(breakpoint);

      default:
        return this.equal(breakpoint);

    }

  }


}

