import { BaseCommand } from "./baseCommand";

export class SignUpCommand extends BaseCommand {

  username: string;
  name: string;
  email: string;
  password: string;
  company: string;
  phoneNumber: string;
  mobileNumber: string;

}