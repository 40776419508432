import { Component, Input, ElementRef, Directive } from "@angular/core";

@Directive({
  selector: "columntemplate"
})

//@Component({
//  selector: "columntemplate",
//  templateUrl: './columntemplate.html'
//})
export class ColumntemplateComponent {

  /** Bootstrap column sizes from smallest (1 -> 1/12~8.3%) to largest (12 -> 12/12=100%),
  or auto. */
  @Input()
  size: number | string;

  @Input()
  classes: string = "";

  @Input()
  autoCell: boolean = false;

  constructor(public element: ElementRef) {
  }

  ngOnInit() {
    if (this.classes)
      this.element.nativeElement.className += this.classes;

    if (this.autoCell) {
      this.element.nativeElement.className += ' col-auto';
    }
    else {
      this.element.nativeElement.className += ` col${this.size == null ? '' : '-' + this.size}`;
    }
  }

}