import { Component, Input, ChangeDetectorRef, Host, Inject, AfterContentInit, forwardRef } from "@angular/core";
import { RadioButtonComponent } from "../radioButtonComponent";
import { BaseRadioStrategyComponent } from './baseRadioStrategyComponent';

@Component({
  selector: 'image-radio',
  templateUrl: './imageRadioStrategyComponent.html'
})
export class ImageRadioStrategyComponent extends BaseRadioStrategyComponent {

  constructor(
    @Host() @Inject(forwardRef(() => RadioButtonComponent)) public button: RadioButtonComponent,
    public cdr: ChangeDetectorRef
  ) {
    super(button, cdr);
  }

  readOnlyClick(): void {
    this.button.radioGroup.readOnlyClick();
  }
}