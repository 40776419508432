import { Injectable } from '@angular/core';
import { CalendarDialogConfig, DateRangeOptions, PresetItem, DateValueModel } from '../models/model';
import { DatePipe } from '@angular/common';

@Injectable()
export class ConfigDatepickerService {

  private _calendarDialogConfig: CalendarDialogConfig;
  private _dateRangeOptions: DateRangeOptions;
  private defaultOptions = {
    excludeWeekends: false,
    locale: 'en-US',
    format: 'yyyy-MM-dd',
    fromMinMax: { fromDate: null, toDate: null },
    toMinMax: { fromDate: null, toDate: null }
  };

  constructor(private datePipe: DatePipe) { }

  get calendarDialogConfig(): CalendarDialogConfig {
    return this._calendarDialogConfig;
  }

  get dateRangeOptions(): DateRangeOptions {
    return this._dateRangeOptions;
  }

  set calendarDialogConfig(config: CalendarDialogConfig) {
    this._calendarDialogConfig = config;
  }

  set dateRangeOptions(options: DateRangeOptions) {
    this._dateRangeOptions = { ...this.defaultOptions, ...options };
  }

  /**
   * Returns the matched preset item. It returns null If no match found.
   * @param fromDate
   * @param toDate
   */
  getPreset(fromDate: Date, toDate: Date): PresetItem {

    // TODO Move this method to somewhere else

    for (let p of this._dateRangeOptions.predefinedItems)
        if (p.range.fromDate.valueOf() == fromDate.valueOf() && p.range.toDate.valueOf() == toDate.valueOf())
          return p;

    return null;
  } 


  /**
   * Creates the date value model from provided range.
   * @param fromDate
   * @param toDate
   */
  createDateValueModel(fromDate: Date, toDate: Date): DateValueModel {

    // TODO Move this method to somewhere else

    const valueModel: DateValueModel = {};

    if (!fromDate || !toDate) {

      // No range selected.
      valueModel.displayText = this._dateRangeOptions.emptyValueLabel;       
      return valueModel;
    }

    // If preset value selected
    const preset = this.getPreset(fromDate, toDate);
    if (preset) {
      valueModel.displayText = preset.predefinedLabel;
      valueModel.range = preset.range;
      valueModel.isPreset = true;
      valueModel.value = preset.identifier;
    }
    else {

      const from: string = this.formatToDateString(fromDate, this._dateRangeOptions.format);
      const to: string = this.formatToDateString(toDate, this._dateRangeOptions.format);

      valueModel.range = { fromDate: fromDate, toDate: toDate };
      valueModel.isPreset = false;
      valueModel.displayText = `${from} - ${to}`;
      valueModel.value = `${from}...${to}`;;
    }

    return valueModel;
  }

  public formatToDateString(date: Date, format: string): string {

    return this.datePipe.transform(date, format);

  }


}