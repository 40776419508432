import { Component, Input, Inject, SimpleChanges, ChangeDetectorRef, ViewChild } from "@angular/core";
import * as Immutable from "immutable";

import { BaseComponent } from "../../shared";
import { ConfigurationSearch, ConfInfo, RequestViews, ConfSearchResultItem, DeleteSearchMessage } from "../../shared/models";
import { QuickSearchItemUIModel } from "./quickSearchUIModel";
import { SearchDataStore, SearchDataMessageProvider } from "../../shared/providers/searchData";
import { ConfiguratorStore } from "../../configurator/providers";
import { RouteRedirector } from "../../shared/providers";
import { NotificationInfo, NotificationType, NotificationService, MessageBoxComponent, MessageBoxConfig } from "../../../shared/components";

@Component({
  selector: 'quick-search',
  templateUrl: './quickSearchComponent.html'
})
export class QuickSearchComponent extends BaseComponent {

  @Input() configurationSearches: Immutable.List<ConfigurationSearch>;
  public timer: any;

  // UI Model by <ConfigurationSearch.longId>
  quickSearches: Immutable.List<QuickSearchItemUIModel> = Immutable.List<QuickSearchItemUIModel>();

  @ViewChild(MessageBoxComponent)
  public confirmDeleteMessageBox: MessageBoxComponent;

  constructor(
    @Inject(SearchDataStore) public searchDataStore: SearchDataStore,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    public searchDataMesssageProvider: SearchDataMessageProvider,
    public notificationService: NotificationService,
    public cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.searchDataMesssageProvider.onMessages<DeleteSearchMessage>(DeleteSearchMessage.name, {
      next: (messages) => {
        let message = messages.first();

        this.notificationService.notify(<NotificationInfo>{
          title: message.success ? this.strings.Success : this.strings.Error,
          message: message.success ? this.strings.SuccessfullyDeletedSearch : this.strings.ErrorDeleting + message.messages.join(", "),
          type: message.success ? NotificationType.Success : NotificationType.Error,
          selfClose: true
        });

        this.unblockUI();
      },
      listenNewEventsOnly: true
    }).unsubscribeOn(this.unsubscribeSubject);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['configurationSearches']) {
      this.makeQuickSearchModels();

      this.timer = setTimeout(() => {
        if (!this.timer)
          return;

        this.makeLazyLoadingInlineContents();
      }, 100);
    }
  }

  makeQuickSearchModels(): void {

    this.quickSearches = Immutable.List<QuickSearchItemUIModel>();

    this.configurationSearches.forEach(confSearch => {
      let quickSearch: QuickSearchItemUIModel = new QuickSearchItemUIModel();
      quickSearch.search = confSearch;
      quickSearch.showInline = confSearch.inlineResults; // Helps in template to access the property easily      
      this.quickSearches = this.quickSearches.push(quickSearch);
    });
  }

  /**
   * Loads the data sequentially.
   */
  makeLazyLoadingInlineContents(): void {
    for (let index = 0; index < this.quickSearches.size; index++) {

      let quickSearch: QuickSearchItemUIModel = this.quickSearches.get(index);
      if (quickSearch.resultRequestCompleted || !quickSearch.showInline)
        continue;

      this.searchDataStore.queryConfs(quickSearch.search, RequestViews.Start, quickSearch.search.maxInlineResults).subscribe(storeResponse => {
        if (!this.timer)
          return;

        // Create new model otherwise change will not be detected in InlineSearchResultComponent
        let newQuickSearch: QuickSearchItemUIModel = new QuickSearchItemUIModel();
        newQuickSearch.search = quickSearch.search;
        newQuickSearch.showInline = quickSearch.showInline;

        newQuickSearch.resultSessionData = storeResponse.data;
        newQuickSearch.resultRequestCompleted = true;
        newQuickSearch.resultConfInfos = this.retreiveConfInfos(storeResponse.data.confSearchResultItems);
        this.quickSearches = this.quickSearches.set(index, newQuickSearch);

        this.cdr.detectChanges();
        this.makeLazyLoadingInlineContents(); // Next request

      });
      break;
    }
  }

  retreiveConfInfos(items: Immutable.List<ConfSearchResultItem>): Immutable.OrderedMap<number, ConfInfo> {
    let confBySessionId: Immutable.OrderedMap<number, ConfInfo> = Immutable.OrderedMap<number, ConfInfo>();
    items.forEach(
      item => {
        confBySessionId = confBySessionId.set(item.confSessionId, this.confStore.getConfInfo(item.configurationId, item.confSessionId))
      });

    return confBySessionId;
  }

  showSearchResults(search: ConfigurationSearch): void {
    this.routeRedirector.redirectToSearchResult({ confSearchId: search.longId });
  }

  showConfirmMessage(quickSearch: QuickSearchItemUIModel): void {

    let info: MessageBoxConfig<QuickSearchItemUIModel> = <MessageBoxConfig<QuickSearchItemUIModel>>{
      headerText: this.strings.AreYouSureYouWantToDelete,
      caption: this.strings.Search,
      description: quickSearch.search.title,
      icon: "error",
      tag: quickSearch
    }

    this.confirmDeleteMessageBox.show(info);
    //this.confirmDeleteMessageBox.show(this.strings.AreYouSureYouWantToDelete, this.strings.Search, quickSearch.search);
  }

  deleteSearch(model: QuickSearchItemUIModel) {

    this.searchDataStore.deleteConfigurationSearch(RequestViews.Start, model.search.longId);

    this.blockUI();
  }

  ngOnDestroy() {
    this.timer = null;
    super.ngOnDestroy();
  }

}