import { Inject, Injectable } from '@angular/core';
import * as Immutable from "immutable";
import { ComparisonDataResponse } from '../../../../../core/pages/shared/models/responses/comparisonDataResponse';
import { Actions } from '../../../../../core/pages/shared/state/shared/actions';
import { AppAction } from '../../../../../core/pages/shared/state/shared/appAction';
import { ComparisonDataState } from '../../../../../core/pages/shared/state/shared/comparisonDataState';
import { ComparisonResult } from '../../../../../core/pages/shared/state/shared/comparisonSession';
import { ComparisonSessionData } from '../../../../../core/pages/shared/state/shared/comparisonSessionData';
import { ComparisonHelper } from './comparisonHelper';

@Injectable()
export class ComparisonDataReducer {

  constructor(@Inject(ComparisonHelper) public helper: ComparisonHelper) { }

  public getReducer() {

    // Default state.    
    let defaultState = new ComparisonDataState();
    defaultState = defaultState.setDataBySessionId(Immutable.Map<number, ComparisonSessionData>());

    return (state = defaultState, action: AppAction<any>): ComparisonDataState => {

      if (action.type == Actions.CLEAR_CLIENT_CACHE) {
        return state;
      }


      if (action.type == Actions.COMPARISON_CONFIGURATIONS_RESULT) {

        let comparisonDataResponse = action.payload.data as ComparisonDataResponse;

        if (!comparisonDataResponse)
          return state;

        // Create session if it is the first request and session does not exists.
        state = this.createIfNoSession(state, comparisonDataResponse);

        // Grab the old or empty comparison result.
        let result: ComparisonResult = this.getComparisonResultOrDefault(state, comparisonDataResponse);

        // Synchronize the comparison results.
        result = this.updateComparisonResult(result, comparisonDataResponse);

        // Update the state.
        state = this.updateState(state, result);
      }

      return state;
    }
  }


  getComparisonSession(response: ComparisonDataResponse): ComparisonResult {

    let comparisonSession = new ComparisonResult();
    comparisonSession = comparisonSession.setComparisonSessionId(response.comparisonSessionId);
    comparisonSession = comparisonSession.setDetail(response.detail);
    comparisonSession = comparisonSession.setConfIds(response.confIds);
    comparisonSession = comparisonSession.setBreadcrumbConfInfos(response.breadcrumbConfInfos);
    comparisonSession = comparisonSession.setChildConfsOrder(response.childConfsOrder);
    comparisonSession = comparisonSession.setDifferencyResult(response.differencyResult);
    return comparisonSession;

  }

  createIfNoSession(state: ComparisonDataState, response: ComparisonDataResponse): ComparisonDataState {

    if (state.dataBySessionId.has(+response.comparisonSessionId))
      return state;

    let sessionData = new ComparisonSessionData();
    sessionData = sessionData.setComparisonResultByPath(Immutable.Map<string, ComparisonResult>());
    
    let comparisonSessionDataById = state.dataBySessionId.set(+response.comparisonSessionId, sessionData);
    
    state = state.setDataBySessionId(comparisonSessionDataById);


    return state;
  }

  getComparisonResultOrDefault(state: ComparisonDataState, response: ComparisonDataResponse): ComparisonResult {

    let dataBySessionId = state.dataBySessionId.get(response.comparisonSessionId);

    if (dataBySessionId.comparisonResultByPath &&  dataBySessionId.comparisonResultByPath.has(response.path)) {
      return dataBySessionId.comparisonResultByPath.get(response.path);
    }


    return new ComparisonResult();
  }

  updateComparisonResult(result: ComparisonResult, response: ComparisonDataResponse): ComparisonResult {

    result = this.merge(result, response.breadcrumbConfInfos, ComparisonResult.BREADCRUMB_CONF_INFOS);
    result = this.merge(result, response.comparisonSessionId, ComparisonResult.COMPARISON_SESSION_ID);
    result = this.merge(result, response.detail, ComparisonResult.DETAIL);
    result = this.merge(result, response.childConfsOrder, ComparisonResult.CHILD_CONFS_ORDER);
    result = this.merge(result, response.differencyResult, ComparisonResult.DIFFERENCY_RESULT);
    result = this.merge(result, response.path, ComparisonResult.PATH);
    result = this.merge(result, response.confIds, ComparisonResult.CONF_IDS);

    return result;
  }

  merge(result: ComparisonResult, newValue: any, whatProperty: string): ComparisonResult {

    if (!newValue)
      return result;

    let oldValue = result.getInternalValue(whatProperty);

    if (JSON.stringify(oldValue) == newValue)
      return result;

    // Update the value
    result = result.setInternalValue(whatProperty, newValue);

    return result;
  }

  updateState(state: ComparisonDataState, result: ComparisonResult): ComparisonDataState {

    let dataBySession = state.dataBySessionId;
    let session: ComparisonSessionData = dataBySession.get(+result.comparisonSessionId);

    let resultByPath: Immutable.Map<string, ComparisonResult> = session.comparisonResultByPath.set(result.path, result);
    session = session.setComparisonResultByPath(resultByPath);

    dataBySession = dataBySession.set(result.comparisonSessionId, session);

    return state.setDataBySessionId(dataBySession);
  
  }

}