import { Inject, Injectable } from "@angular/core";

import { Conf, Tab } from "../../shared/models";
import { ConfPageSessionService } from "../providers";
import { AbstractUIVisibilityService } from "./abstractUIVisibilityService";

@Injectable()
export class TabVisibilityService extends AbstractUIVisibilityService {

  constructor() {
    super();        
  }

  public isVisible(tab: Tab, conf: Conf): boolean;
  public isVisible(tabLongId: number, conf: Conf): boolean;
  public isVisible(tabObj: any, conf: Conf): boolean {

    if (!tabObj)
      return false;

    let longId: number;
    if (tabObj instanceof Tab)
      longId = tabObj.longId;
    else
      longId = tabObj;
   
    return this.findConfUIItem(conf, longId, [], Tab.name) != null;
  }
}