<div *ngIf="view != null" [class.disabled]="view.disabled" [style.width]="view.width">
  <label class="static-label-above" for="{{view.id}}">{{view.title}}</label>
  <div class="input-container control-border-b {{getMoreStyles()}}">

    <icon [view]="view"></icon>
    <!-- <input name=.. doesn't work with ngModel! It's not set for some reason.. but name is needed for chrome autocomplete! -->
    <input class="input-title"
           (change)="processEvent($event)"
           [(ngModel)]="view.value"
           [readonly]="view.readOnly"
           [autocomplete]="isNewPassword ? 'new-password' : 'current-password'"
           type="password"
           name="password"
           placeholder="{{view.placeholder}}">
  </div>

  <!-- TODO: Remove the first loop and set the errors through input argument because reading the errors through model are not detectable in many cases. -->
  <div *ngFor="let error of view.errors" class="form-control-feedback d-block">{{error}}</div>
  <div *ngFor="let error of _errors" class="form-control-feedback d-block">{{error}}</div>
</div>