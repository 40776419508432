import { Component, OnInit, ViewChild, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { PresetItem, DateRangeOptions, DateValueModel } from '../models/model';
import { RangeDatepickerService } from '../providers/rangeDatepickerService';
import { OverlayRef } from '@angular/cdk/overlay';
import { ConfigDatepickerService } from '../providers/configDatepickerService';
declare var jQuery: any;

@Component({
  selector: 'mat-drp-range',
  templateUrl: './dateRangeOverlayComponent.html'  
})
export class DateRangePickerComponent implements OnInit {

  fromDate: Date;
  toDate: Date;
  fromMinDate: Date;
  fromMaxDate: Date;
  toMinDate: Date;
  toMaxDate: Date;
  presets: Array<PresetItem> = [];
  applyLabel: string;
  cancelLabel: string;
  showCustomRangeForm: boolean = false;

  // As window.click is triggered right after calling the showCustomRange method and we have written the logic to close the date range picker in window.click.
  // This new field helps to prevent closing the window when opening it first time.
  isIntermediateStateWhileOpening: boolean = false;
  minWidth: number;

  @ViewChild('dateRangeOverlay')
  dateRangeOverlay: ElementRef;

  constructor(
    private rangeStoreService: RangeDatepickerService,
    private configStoreService: ConfigDatepickerService,
    private overlayRef: OverlayRef,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.minWidth = this.configStoreService.calendarDialogConfig.minWidth;
    this.fromDate = this.rangeStoreService.fromDate;
    this.toDate = this.rangeStoreService.toDate;
    this.applyLabel = this.configStoreService.dateRangeOptions.applyLabel || "Apply";
    this.cancelLabel = this.configStoreService.dateRangeOptions.cancelLabel || "Cancel";
    this.presets = this.configStoreService.dateRangeOptions.predefinedItems;
    ({ fromDate: this.fromMinDate, toDate: this.fromMaxDate } = this.configStoreService.dateRangeOptions.fromMinMax);
    ({ fromDate: this.toMinDate, toDate: this.toMaxDate } = this.configStoreService.dateRangeOptions.toMinMax);    

    if (this.presets.length == 0)
      this.showCustomRange();
  }

  updateFromDate(date) {
    this.fromDate = date;
    //this.overlayRef.getConfig();
  }

  updateToDate(date) {
    this.toDate = date;
    this.applyNewDates();
  }

  updateRangeByPreset(presetItem: PresetItem) { // Need a better way to expose API for setting presets externally
    //this.rangeStoreService.updateRange(presetItem.range.fromDate, presetItem.range.toDate);
    this.updateFromDate(presetItem.range.fromDate);
    this.updateToDate(presetItem.range.toDate);
  }

  applyNewDates() {

    this.rangeStoreService.updateRange(this.configStoreService.createDateValueModel(this.fromDate, this.toDate));
    this.disposeOverLay();
  }

  showCustomRange() {
    this.showCustomRangeForm = true;
    this.isIntermediateStateWhileOpening = true;
    this.cdr.detectChanges();
  }

  private disposeOverLay() {
    this.overlayRef.dispose();
  }

  @HostListener('window:click', ['$event'])
  close(c): void {

    if (this.isIntermediateStateWhileOpening) {
      this.isIntermediateStateWhileOpening = false;
      return;
    }

    var clickedOn = jQuery(event.target);
    var current = jQuery(this.dateRangeOverlay.nativeElement);

    // If clicked outside the calender, then close it. 
    // As DateRangePicker closes automatically when we select the end date but if we reopen the date range picker and only change the start date and ignore end date 
    // then date range picker needs to be closed on click outside.
    if (this.showCustomRangeForm && !clickedOn.parents().is(current)) {
      this.applyNewDates();
      this.showCustomRangeForm = false;
    }

  }

}