import { Component, Input, ChangeDetectorRef, Host, Inject, AfterContentInit } from "@angular/core";
import { BaseLineItemComponent } from './baseLineItemComponent';
import { LineDropdownItemComponent } from '../lineDropdownItemComponent';

@Component({
  selector: 'image-left-line-item',
  templateUrl: './imageLeftLineItemComponent.html',
})
export class ImageLeftLineItemComponent extends BaseLineItemComponent {

  constructor( @Host() @Inject(LineDropdownItemComponent) public item: LineDropdownItemComponent, public cdr: ChangeDetectorRef) {
    super(item, cdr);
  }

}