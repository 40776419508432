import { NgModule } from "@angular/core";
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

/*
*
* Bypasses security checks for safe HTML.
* WARNING! Please only use this if we're sure the HTML is 100% safe and cannot contain malicious code.
* Please see http://g.co/ng/security#xss
* Calling this pipe with untrusted user data exposes XSS security risks.
* Usage:
*   value | safeHtml
*/
@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(value): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@NgModule({
  imports: [

  ],
  declarations: [
    SafeHtmlPipe
  ],
  providers: [

  ],
  exports: [
    SafeHtmlPipe
  ]
})
export class SafeHtmlModule {

};