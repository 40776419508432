import * as Immutable from "immutable";
import { ListModel } from "../../../shared/components";

export class ListModelCacheService {

  /**
   * Each listModel represents one single visual object, or entity
   */
  public listModelById = Immutable.Map<string, ListModel>();

  constructor() {

  }

  /**
   * Returns true if list model exists otherwise false.
   * @param id
   */
  public has(id: string): boolean {
    return this.listModelById.has(id);
  }

  public getCachedIfValueEqual(id: string, value: any): ListModel {

    if (!this.has(id))
      return null;

    if (this.listModelById.get(id).tag === value)    
      return this.listModelById.get(id);

    return null;

  }

  public addIntoCache(list: ListModel): void {
    this.listModelById = this.listModelById.set(list.id, list);
  }


  //public get(visual: VisualObject): ListModel {

  //  let id = visual.visualObjectId.toString();
  //  let confValue: ConfValue = this.confStore.getConfValue(confId, sessionId, visual.visualObjectId) as ConfValue;

  //  if (this.has(id)) {

  //    // Comparison the old and new reference if equal then return the previous model otherwise create a new one.
  //    let listModel = this.listModelById.get(id);

  //    // ListModel.tag contains the old visual object reference.
  //    if (confValue === listModel.tag)
  //      return listModel;

  //    // If hidden remove from repository.
  //    if (confValue.isHidden) {
  //      this.listModelById = this.listModelById.remove(id);
  //      return null;
  //    }
  //  }
        
  //}

  //create(title: string, helpIcon: string, backgroundImage: string, id: string, isPrint: boolean, emptyValueText?: string,  tag?: any): ListModel {

  //  let list: ListModel = new ListModel();
  //  list = list.setTitle(title);
  //  list = list.setEmptyValueText(emptyValueText);
  //  list = list.setIsPrintView(isPrint);
  //  list = list.setId(id);

  //}

  ////addChildItems(id: string, children: Immutable.List<ListItemModel>): ListModel {



  //}

  

}