import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ImageModule } from "../image";
import { MultiSelectGroupComponent } from "./multiSelectGroupComponent";
import { DragScrollModule } from "../dragScroll";
import { MultiSelectDropdownSelectedValues } from "./multiSelectDropdownSelectedValues";
import { MultiSelectItemComponent } from "./MultiSelectItemComponent";

export * from "./multiSelectGroupComponent";
export * from "./multiSelectModels";


@NgModule({
  imports: [
    CommonModule,
    ImageModule,    
    DragScrollModule
    
  ],
  declarations: [
    MultiSelectGroupComponent,
    MultiSelectItemComponent,
    MultiSelectDropdownSelectedValues
  ],
  exports: [
    MultiSelectGroupComponent,
    MultiSelectItemComponent,
    MultiSelectDropdownSelectedValues
  ]
})

export class MultiSelectDropDownModule {

}
