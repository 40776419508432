import { Component, Input, Inject, SimpleChanges, ChangeDetectorRef } from "@angular/core";
import { BaseComponent } from "../../../pages/shared";

@Component({
  selector: 'inline-spinner',
  templateUrl: './inlineSpinnerComponent.html'
})
export class InlineSpinnerComponent extends BaseComponent {

  @Input() text: string;

  @Input() classes: string;

}