<div class="row" *ngIf="comparison">
  
  <div class="col" *ngIf="isRoot">

    <!-- This section renders the tab's titles and configuration properties main titles. -->
    <div class="section-head">      
      <span *ngIf="comparison.title">{{comparison.title}}</span>
    </div>

    <div class="root-section">
      <comparison-row *ngFor="let child of comparison.children" [comparison]="child"></comparison-row>
    </div>

  </div>
  
  <div class="col compare-values" *ngIf="comparison.values && comparison.values.size > 0">

    <div class="row" *ngFor="let comparisonValue of comparison.values; let comValueIndex = index;">

      <div class="col ellipsis-wrap value-container" *ngFor="let titleAndValue of comparisonValue.values; let i = index">

        <div class="value-cell">
          <div class="h-100">
            <div class="d-flex title px-0" *ngIf="!comValueIndex">

              <!-- Parameters or configuration property names -->
              <span *ngIf="comparison.icon">{{comparison.icon}}</span>
              <span>{{comparison.title}}</span>

            </div>
            <div class="row value mx-0 diff-back-{{comparisonValue.differencyLevels.get(i)}}">

              <!-- Multi choice value title at left side if available -->
              <div class="col px-2 comparison-value " *ngIf="comparisonValue.title">

                <co-image *ngIf="comparisonValue.icons.size > i && comparisonValue.icons.get(i)" class="value-icon" [key]="comparisonValue.icons.get(i)" isVault="true" width="32px"> </co-image>
                <span *ngIf="comparisonValue.title">{{comparisonValue.title}}</span>

              </div>
              <!--  if title available, sytem renders the multichoie value at second column -->
              <div class="col-auto px-2 comparison-value" [class.text-right]="comparisonValue.title">
                <comparison-value [titleAndValue]="titleAndValue" [valueType]="comparisonValue.type"></comparison-value>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>

</div>