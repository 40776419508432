import { InputViewModel, Positions } from "../../../../shared/components/shared";
import { DisplayCountValueModel } from "../../../../shared/components/shared/models/displayCountValueModel";
import { PaginationInfo } from "../../models";
import { PagerModel } from "./pagerModel";
import { Injectable } from "@angular/core";
import { Strings } from "../../providers/globalData";

@Injectable()
export class PagerService {

  constructor(public strings: Strings) {}

  create(    
    defaultDisplayCount: number = 20,
    currentPage?: number,
    _noOfPages: number = 1,
    _resultCount: number = 0,
    pageSizes?: Array<string> /* Page sizes, default 20-50-200-all*/,
    displayCountView?: DisplayCountValueModel,    
  ) {

    let pager = new PagerModel();

    if (pager.displayCountView == null) {
      pager.displayCountView = new DisplayCountValueModel(defaultDisplayCount, this.strings.All);
      pager.displayCountView.title = this.strings.Search + ":";
      pager.displayCountView.width = "100%";
      pager.displayCountView.labelPosition = Positions.Left;
    }

    pager.pageSizeViews = new Array<InputViewModel>();
    pageSizes = pageSizes || ["20", "50", "200", this.strings.All];
    for (let pageSize of pageSizes) {
      let item = new InputViewModel(pageSize);
      item.id = pageSize == this.strings.All ? "0" : pageSize;
      pager.pageSizeViews.push(item);
    }

    return pager;
  }

  setResultCount(resultCount: number, pager: PagerModel) {
    pager.resultCount = resultCount;

    // Set max count to number of results
    pager.displayCountView.maxCount = pager.resultCount;
  } 

  setNoOfPages(noOfPages: number, pager: PagerModel) {
    pager.noOfPages = noOfPages;

    if (pager.noOfPages == 0)
      pager.currentPage = 0;
    else
      // Reset to last page if we're beyond limit, but don't set it below 1.
      pager.currentPage = Math.max(1, Math.min(pager.currentPage, pager.noOfPages));
  } 

  updatePageSizes(pageSize: number, pager: PagerModel) {
    // add missing pagination value and sort
    if (pageSize !== 0 && !pager.pageSizeViews.find(x => x.title === "" + pageSize)) {
      pager.pageSizeViews.push(new InputViewModel("" + pageSize));
      pager.pageSizeViews = pager.pageSizeViews.sort((n1, n2) => { if (+n1.title > +n2.title || isNaN(+n1.title)) { return 1 } else { return -1 } });
    }
  }

  update(pagination: PaginationInfo, pager: PagerModel) {
    pager.displayCountView.value = pagination.pageSize;
    pager.displayCountView.formattedValue = pagination.pageSize === 0 ? this.strings.All : pagination.pageSize;

    pager.resultCount = pagination.resultCount;
    pager.currentPage = pagination.pageIndex;
    pager.noOfPages = pagination.noOfPages;
  }
}