import { Injectable, Component, ViewEncapsulation, OnInit, Inject } from "@angular/core";
import { GlobalDataStore } from "./globalData";
import { AbstractImageService } from "../../../shared/providers";

const SIZE = {
  OneX: '1x',
  TwoX: '2x',
  ThreeX: '3x'
}

const IMAGE_TYPE = {
  Svg: 'svg',
  Png: 'png'
}

@Injectable()
export class ImageService extends AbstractImageService {

  private activeTheme = 'base';
  private source = `themes/${this.activeTheme}/images`;

  // TODO: Read from settings.
  private get vaultSource(): string {
    return this.globalDataStore.getGlobalData().webSettings.vaultUrl;
  }

  constructor( @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore) {
    super();
  }

  public ngOnInit(): void {
  }

  public getSource(isVault: boolean, imageSet: string = "primary"): string {
    return isVault ? this.vaultSource : `${this.source}${imageSet ? "/" + imageSet : ""}`;
  }

  getSvgUrl(imageName: string, isVault: boolean = false, imageSet: string = "primary"): string {
    return `${this.getSource(isVault, imageSet)}/${imageName}.${IMAGE_TYPE.Svg}`;
  }

  getOtherTypeUrl(imageName: string, extension: string, isVault: boolean = false, sizes?: string): string[] {
    if (isVault) {
      return [`${this.getSource(true)}/${imageName}.${extension}`];
    }

    let availableSizes = sizes != null ? sizes.split(',') : [
      SIZE.OneX,
      SIZE.TwoX,
      SIZE.ThreeX
    ];

    return [...availableSizes].map((x) => `${this.getSource(false, "")}/${imageName}-${x}.${extension}`);
  }

  /**
   * Returns the url of the page. It does not support different image sizes, It is recommended to access only svgs otherwise resolution will not be good for different devices.
   * @param imageName
   * @param sizes
   * @param isVault
   * @param imageSet image set for svg
   */
  getImageUrl(imageName: string, type: string, sizes?: string, isVault: boolean = false, imageSet: string = "primary"): string[] {

    let key: string = imageName;
    let extension: string = type;

    // Extract the extension to build up the srcSet.
    if (imageName.indexOf(".") > 0) {

      let arr: string[] = imageName.split('.');

      // Last index would be the extension of image
      extension = arr[arr.length - 1].toLowerCase();

      // Image key.
      key = arr[arr.length - 2];

    }

    if (extension == IMAGE_TYPE.Svg) {
      // Return svg url
      return [this.getSvgUrl(key, isVault, imageSet)];
    }

    return this.getOtherTypeUrl(key, extension, isVault);
  }

}