import { Component, Inject, ComponentFactoryResolver, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core";
import * as Immutable from "immutable";
import { BaseEntity } from "../../../shared/baseEntity";
import { MultiChoiceValue, ConfMultiChoiceValue, ConfMultiSetValue, Conf, MultiChoiceParam } from "../../../shared/models";
import { PopupService } from "../../../../shared/components";
import { VisualObjectViewModelFactory, VisualObjectHelper } from "../shared";
import { ParamComponent } from "../../shared/paramComponent";
import { ProductDataStore } from "../../../shared/providers/productData";
import { ConfiguratorStore } from "../../providers/configuratorStore";
import { ManagedSubscription } from "../../../../shared/managedSubscription";
import { VisualObjectContainerService } from "../../shared/visualObjectContainerService";
import { ParameterMandatoryService } from "../shared/parameterMandatoryService";
import { ConfPageSessionService, ConfMessageProvider } from "../../providers";
import { VisualObjectVisibilityService } from "../../shared/visualObjectVisibilityService";
import { MultiChoiceItemViewModel } from "../../../../shared/components/multiChoice";

@Component({
  selector: 'multi-choice-param',
  templateUrl: './multiChoiceParamComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiChoiceParamComponent extends ParamComponent<MultiChoiceParam> {

  public confMultiChoiceValue: ConfMultiChoiceValue;

  // Stores the view model by Id so that we could reuse them to improve the performance.
  public multiChoiceItemViewById: Immutable.Map<number, MultiChoiceItemViewModel> = Immutable.Map<number, MultiChoiceItemViewModel>();
  public multiChoiceItemsViews: Immutable.List<MultiChoiceItemViewModel> = Immutable.List<MultiChoiceItemViewModel>();
    
  public confSubscription: ManagedSubscription;

  public multiChoiceParamId: number;

  constructor(
    @Inject(ProductDataStore) public productStore: ProductDataStore,    
    @Inject(ComponentFactoryResolver) public resolver: ComponentFactoryResolver,
    @Inject(VisualObjectViewModelFactory) public viewFactory: VisualObjectViewModelFactory,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(VisualObjectContainerService) public visualObjectContainerService: VisualObjectContainerService,
    @Inject(PopupService) public popupService: PopupService,
    @Inject(ParameterMandatoryService) public parameterMandatoryService: ParameterMandatoryService,
    @Inject(ConfMessageProvider) public messagesProvider: ConfMessageProvider,
    @Inject(VisualObjectVisibilityService) public visualObjectVisibilityService: VisualObjectVisibilityService,
    @Inject(VisualObjectHelper) public visualObjectHelper: VisualObjectHelper,
    public cd: ChangeDetectorRef
  ) {
    super(confStore, confPageSessionService, popupService, visualObjectHelper, parameterMandatoryService, messagesProvider, cd);
  }

  ngOnInit() {
    this.createViewModels();

    this.multiChoiceParamId = this.parameter.visualObjectId;
    
    // Set value on load.
    this.setValue(this.confStore.getConfValue(this.configurationId, this.confSessionId, this.multiChoiceParamId));

    // Initialize value models on load.
    this.updateMultiSetValueModels();

    super.ngOnInit();
    
    this.confSubscription = this.confStore.onConfigurationChange(this.configurationId, this.confSessionId, (conf: Conf) => {
      // Update models on value change.
      this.updateMultiSetValueModels();
      this.cd.markForCheck();
    });
  }

  public setValue(confValue: BaseEntity): void {
    this.confMultiChoiceValue = confValue as ConfMultiChoiceValue;
  }

  createViewModels(): void {
    // Create group view
    this.viewModel = this.viewFactory.create(this.parameter);
  }

  updateMultiSetValueModels(): void {

    // Clear the cache.
    this.multiChoiceItemsViews = this.multiChoiceItemsViews.clear();

    // Go through all values, check the visibilty and update the list.
    let multiChoiceParam: MultiChoiceParam = this.parameter as MultiChoiceParam;
    multiChoiceParam.paramValues.forEach(multiChoiceValueId => {

      // <ParamValue>
      let multiChoiceItem: MultiChoiceValue = this.productStore.getEntity(multiChoiceValueId) as MultiChoiceValue;
      let isVisible = this.visualObjectVisibilityService.isParamValueVisible(multiChoiceItem, this.configuration);
      if (!isVisible) {

        this.multiChoiceItemViewById = this.multiChoiceItemViewById.remove(+multiChoiceItem.longId);
        return;

      }

      // If view model does not exist or it is changed.
      if (!this.exists(multiChoiceItem)) {
        this.multiChoiceItemViewById = this.multiChoiceItemViewById.set(+multiChoiceItem.longId, this.createItemViewModel(multiChoiceItem));
      }

      // Copy the new values if server response is changed.
      if (!this.equal(multiChoiceItem))
        this.copyValues(multiChoiceItem);

      // Update the cache.
      this.multiChoiceItemsViews = this.multiChoiceItemsViews.push(this.multiChoiceItemViewById.get(+multiChoiceItem.longId));

      // Enable help if any of the param value has HelpText or HelpImage.
      if ((multiChoiceItem.helpText && multiChoiceItem.helpText.trim() != "") || (multiChoiceItem.helpImageRelativeUrl && multiChoiceItem.helpImageRelativeUrl.trim() != "") || (multiChoiceItem.externLink && multiChoiceItem.externLink.trim() != "")) {
        this.viewModel.enableHelp = true;
        this.viewModel.enableExternalLink = false;
      }
    });

  }

  /**
   * Creates the multiChoiceItem model.
   * @param valueModel
   */
  createItemViewModel(item: MultiChoiceValue): MultiChoiceItemViewModel {

    // Create view for children
    let multiChoiceValue: MultiChoiceValue = this.getMultiChoiceValue(item.longId);
    if (multiChoiceValue.obsolete)
      return;

    let itemView: MultiChoiceItemViewModel = this.viewFactory.create(multiChoiceValue) as MultiChoiceItemViewModel;

    // Factory returns the default width, make it empty, component's width is set at group level view.
    itemView.width = "";
    itemView.triState = multiChoiceValue.threeState;

    // Don't use help for items, parent will show it.
    itemView.enableHelp = false;
    itemView.id = multiChoiceValue.longId.toString();

    let confMultiSetValue: ConfMultiSetValue = this.getConfMultiSetValue(item);

    if (!confMultiSetValue)
      return itemView;

    // Set value.
    itemView.value = confMultiSetValue.value;

    // Is disabled?
    itemView.disabled = confMultiSetValue.isDisallowed;

    // Is readOnly?
    itemView.readOnly = confMultiSetValue.isReadOnly;
    
    return itemView;
  }

  getMultiChoiceValue(id: number): MultiChoiceValue {
    return this.productStore.getEntity(id) as MultiChoiceValue;
  }

  /**
   * Returns the confMultiSetValue
   * @param valueModel
   */
  getConfMultiSetValue(item: MultiChoiceValue): ConfMultiSetValue {
    return this.confStore.getConfValue(this.configuration.longId, this.confPageSessionService.confSessionId, item.longId) as ConfMultiSetValue;
  }

  exists(multiChoiceItem: MultiChoiceValue): boolean {
    return this.multiChoiceItemViewById.has(+multiChoiceItem.longId);      
  }

  /**
   * Returns true if old and new values for ConfMultiChoiceValue are same.
   * @param multiChoiceItem
   */
  equal(multiChoiceItem: MultiChoiceValue): boolean {

    let oldItemView: MultiChoiceItemViewModel = this.multiChoiceItemViewById.get(multiChoiceItem.longId);

    let newConfMultisetValue = this.getConfMultiSetValue(multiChoiceItem);

    // First old object
    let firstObj = { id: oldItemView.id, value: oldItemView.value, readonly: oldItemView.readOnly, disabled: oldItemView.disabled };
    let secondObj = { id: newConfMultisetValue.longId.toString(), value: newConfMultisetValue.value, readonly: newConfMultisetValue.isReadOnly, disabled: newConfMultisetValue.isDisallowed };

    return JSON.stringify(firstObj) == JSON.stringify(secondObj);

  }

  /**
   * Copies the new values.
   * @param multiChoiceItem
   */
  copyValues(multiChoiceItem: MultiChoiceValue): void {

    let oldItemView: MultiChoiceItemViewModel = this.multiChoiceItemViewById.get(multiChoiceItem.longId);
    let newConfMultisetValue = this.getConfMultiSetValue(multiChoiceItem);

    oldItemView.value = newConfMultisetValue.value;
    oldItemView.disabled = newConfMultisetValue.isDisallowed;
    oldItemView.readOnly = newConfMultisetValue.isReadOnly;

  }

  ngOnDestroy() {
    if (this.confSubscription)
      this.confSubscription.unsubscribe();

    super.ngOnDestroy();
  }

  onItemViewChanged(itemView: MultiChoiceItemViewModel) {
    this.updateItemView(itemView);
  }

  updateItemView(itemView: MultiChoiceItemViewModel) {

    // Update map
    this.multiChoiceItemViewById = this.multiChoiceItemViewById.set(Number(itemView.id), itemView);

    let tempList = new Array<MultiChoiceItemViewModel>();

    // Push into temp list and then assign this temp list to multiChoiceItemsViews.
    this.multiChoiceItemsViews.forEach(x => {

      let v = this.multiChoiceItemViewById.get(Number(x.id));
      tempList.push(v);

    });

    this.multiChoiceItemsViews = Immutable.List(tempList);

  }

  public getCurrentValue(): any {
    return null;
  }

  public setError(errors: Array<string>) {
    this.viewModel.errors = errors;
  }
}