import { Component } from "@angular/core";
import { RuleInformationMessage } from "../../../shared/models/responses/messages";
import { RuleInfo, ParamInfo } from "../../../shared/models/responses";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import { PopupIdentifiers } from "../../providers";

@Component({
  selector: 'rule-information-popup',
  templateUrl: './ruleInformationPopupComponent.html'
})
export class RuleInformationPopupComponent extends AbstractPopupComponent {

  public messages: RuleInformationMessage[];

  public id: string = PopupIdentifiers.RuleInformation;

  public open(tag: any) {
    this.messages = tag;
    this.showInternal();
  }

  getParameterIcon(pInfo: ParamInfo): string {
    return pInfo.parameterType.toLowerCase();
  }

  getRuleIcon(ruleInfo: RuleInfo): string {
    return ruleInfo.ruleType.toLowerCase();
  }
}