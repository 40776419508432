import { Injectable, Inject } from '@angular/core';
import * as Immutable from "immutable";

import { AbstractAccountMessage } from "../../models/responses/messages";
import { AccountDataMemorizer } from "./accountDataMemorizer";
import { ManagedSubscription, SubscriptionOptions } from "../../../../shared/managedSubscription";
import { ManagedSubject } from "../../../../shared/managedSubject";
import { AppStoreSubscriptionManager } from "../appStoreSubscriptionManager";

@Injectable()
export class AccountDataMessageProvider {

  constructor(
    public appStoreSubscriptionManager: AppStoreSubscriptionManager,
    @Inject(AccountDataMemorizer) public accountMemorizer: AccountDataMemorizer
  ) {
  }

  public onMessages<T extends AbstractAccountMessage>(messageClassName: string, subscriptionOptions: SubscriptionOptions<Immutable.List<T>>): ManagedSubscription {
    return this.createStoreSubscription<Immutable.List<T>>(messageClassName, this.accountMemorizer.getMessagesMemorizer<T>(messageClassName), subscriptionOptions);
  }

  public createStoreSubscription<T>(identifier: string, memorizer: () => T, subscriptionOptions: SubscriptionOptions<T>): ManagedSubscription {

    let managedSubject: ManagedSubject<T> = this.appStoreSubscriptionManager.getOrCreateStoreSubject<T>(identifier, memorizer, false, 1, false);
    return managedSubject.subscribe(subscriptionOptions);
  }

}