import { Injectable, Inject } from "@angular/core";
import * as Immutable from "immutable";

import { AppAction } from "../shared/appAction";
import { UIAction } from "../shared/uiAction";
import { PageActions } from "./pageActions";
import { SelectorPageUIData } from "../shared/selectorPageUIData";

@Injectable()
export class SelectorUIReducer {

  public getReducer() {

    return (stateById: Immutable.Map<string, SelectorPageUIData> = Immutable.Map<string, SelectorPageUIData>(), action: AppAction<any>): Immutable.Map<string, SelectorPageUIData> => {

      let uiAction = action as UIAction<any>;

      switch (action.type) {

        case PageActions.CREATE_SELECTOR_UI_DATA:

          if (!stateById.has(uiAction.pageId.toString())) {

            return stateById.set(uiAction.pageId.toString(), new SelectorPageUIData());            
          }

          return stateById;

        case PageActions.SET_SELECTED_ASSORTMENTS:

          if (stateById.has(uiAction.pageId.toString())) {

            let selectorUI: SelectorPageUIData = stateById.get(uiAction.pageId.toString());
            selectorUI.selectedAssortments = uiAction.payload;
            return stateById.set(uiAction.pageId.toString(), selectorUI);

          }
          
        default:
          return stateById;
      }          
    }

  }
}