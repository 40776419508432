import { Input, OnInit, ChangeDetectorRef, Output, AfterViewInit, AfterContentInit, EventEmitter, Component } from "@angular/core";
import { AbstractDropdownGroup } from './abstractDropdownGroup';
import { InputViewModel } from "../shared";

@Component({ template: '' })
export abstract class AbstractDropdownItem implements OnInit, AfterViewInit, AfterContentInit {

  constructor(public dropdownGroup: AbstractDropdownGroup, public cdr: ChangeDetectorRef) {
  }

  @Input()
  public view: InputViewModel;

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  ngAfterContentInit() {
  }

  public onClick($event): void {
    this.dropdownGroup.processEvent($event, this);
  }
}