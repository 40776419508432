import { AbstractConfigurationMessage } from "./abstractConfigurationMessage";

export abstract class AbstractOperationMessage extends AbstractConfigurationMessage {
    
  protected readonly NAME = "name";
  protected readonly SUCCESS = "success";
  protected readonly MESSAGE = "message";
    
  get name(): string { return this.getInternalValue<string>(this.NAME); }
  setName(name: string): this { return this.setInternalValue(this.NAME, name); }

  get success(): boolean { return this.getInternalValue<boolean>(this.SUCCESS); }
  setSuccess(success: boolean): this { return this.setInternalValue(this.SUCCESS, success); }

  get message(): string { return this.getInternalValue<string>(this.MESSAGE); }
  setMessage(message: string): this { return this.setInternalValue(this.MESSAGE, message); }
}