<div *ngIf="quickSearches">
  <h1 class="text-uppercase start-header">{{strings.QuickSearch}}</h1>
  <div class="pr-0 prevent-scroll-single-col quick-search-wrapper">
    <div *ngFor="let quickSearch of quickSearches" class="quick-search">

      <!-- Move title template to separate component -->
      <div class="row saved-search-title border-box">
        <div class="col">
          <h4 role="button" (click)="showSearchResults(quickSearch.search)" class="text-uppercase start-search-header">{{quickSearch.search.title}}</h4>
        </div>
        <div class="col-auto px-0" style="width:40px">
          <div (click)="showConfirmMessage(quickSearch)" class="delete-button mobile-flex" role="button" *ngIf="quickSearch.search.authorization.canDelete">
            <co-image width="20" height="18.89" type="svg" imageSet="red" key="delete"></co-image>
          </div>
        </div>
      </div>

      <inline-search-results [quickSearch]="quickSearch"></inline-search-results>
      <search-navigator [quickSearch]="quickSearch"></search-navigator>

    </div>
  </div>
</div>

<message-box (okClick)="deleteSearch($event)"
             [title]="strings.Confirm"
             [okButtonText]="strings.Delete"
                       ></message-box>
