import { Inject, Injectable } from "@angular/core"
import { normalize, Schema, schema } from "normalizr";
import * as Immutable from "immutable"

import { BaseEntity } from "../../baseEntity"
import { ModelFactory } from "../modelFactory"
import { Ioc, Register } from "../../../../shared/ioc/iocdecorator"
import { NormalizerService } from "../../../../shared/providers/normalizer"
import { ProductDataResponse } from "../../models/responses";
import { PushMessageSchema } from "./pushMessageSchema";

@Injectable()
export class ProductDataNormalizer {

  constructor(    
    @Inject(NormalizerService) public normalizerService: NormalizerService,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    @Inject(PushMessageSchema) public pushMessageSchema: PushMessageSchema
  ) { }

  normalizeJson(json: any): ProductDataResponse {

    let productResponse = new ProductDataResponse();
    let entities = Immutable.Map<number, BaseEntity>();
    let entityIdsByClassName = Immutable.Map<string, Immutable.List<number>>();

    if (json.entitiesByClassName) {

      for (let key in json.entitiesByClassName) {

        let classEntities = json.entitiesByClassName[key];

        let normalizeOutput = this.normalizerService.normalizeJson(classEntities, this.pushMessageSchema.getSchemaArray(key), (className, data) => this.modelFactory.createEntity(className, data));

        entityIdsByClassName = entityIdsByClassName.set(key, Immutable.List<number>(normalizeOutput.resultIds));
        entities = this.mergeEntities(entities, normalizeOutput.entities);

      }
    }

    productResponse = productResponse.setEntityIdsByClassName(entityIdsByClassName);
    productResponse = productResponse.setEntities(entities);

    return productResponse;
  }

  protected mergeEntities(existingEntities: Immutable.Map<number, BaseEntity>, newEntities: Map<number, BaseEntity>)
  {
    newEntities.forEach((newEntity: BaseEntity, key: number) => {
      existingEntities = existingEntities.set(key, newEntity);     
    });

    return existingEntities;
  }
}