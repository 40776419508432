import { Inject, Component, Injectable, Input, OnInit, EventEmitter, Output, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from "rxjs";

import { NodeData } from './nodeData';
import { TreeDataProvider } from './treeDataProvider';
import { TreeViewEventService } from './treeViewEventService';
import { TreeNodeComponent } from "./treeNodeComponent";
import { NodeEvent, NodeActions } from "./treeEvents";

@Component({
  selector: 'tree-view',
  templateUrl: './treeViewComponent.html',
  providers: [TreeViewEventService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeViewComponent implements OnInit {

  @Input() public treeDataProvider: TreeDataProvider;
  @Input() public imageSet = "primary";
  

  @Output() public nodeSelected: EventEmitter<any> = new EventEmitter();
  @Output() public menuClick: EventEmitter<any> = new EventEmitter();
  @Output() public addClick: EventEmitter<any> = new EventEmitter();
  @Output() public nodeExpanded: EventEmitter<any> = new EventEmitter();

  public messageSubscription: Subscription;

  roots: NodeData[] = [];

  public constructor(
    @Inject(TreeViewEventService) public treeViewEventService: TreeViewEventService,
    private cd: ChangeDetectorRef
  ) {
  }

  public ngOnInit(): void {

    this.treeViewEventService.nodeSelect$.subscribe((e: NodeEvent) => {
      this.nodeSelected.emit(e);
    });

    this.treeViewEventService.nodeExpand$.subscribe((e: NodeEvent) => {

      if (+this.treeDataProvider.singleExpand)
        this.collapseAllSiblings(e.node);

      this.nodeExpanded.emit(e);

    });

    this.treeViewEventService.nodeCollapse$.subscribe((e: NodeEvent) => {

      this.nodeExpanded.emit(e);

    });

    this.treeViewEventService.nodeMenuClick$.subscribe((e: NodeEvent) => {
      this.menuClick.emit(e);
    });

    this.treeViewEventService.nodeAddClick$.subscribe((e: NodeEvent) => {
      this.addClick.emit(e);
    });

    this.messageSubscription = this.treeDataProvider.getMessage().subscribe((event: NodeEvent) => {

      switch (event.action) {

        case NodeActions.TreeRefresh:
          {
            this.init();
            break;
          }
      }

    });

    this.init();
  }

  init(): void {
    // Load all roots first time on load.
    this.treeDataProvider.loadRoots((roots: NodeData[]) => {
      this.roots = roots;
      this.cd.markForCheck(); // Mark the path checked
    });

  }

  collapseAllSiblings(node: NodeData): void {
    let siblings: NodeData[];

    //if (node.isRoot)
    //  siblings = this.roots;

    //else siblings = node.parent.children;

    //siblings.forEach((sibling) => { if (sibling !== node) sibling.collapse() });    
  }

  ngOnDestroy() {
    if (this.messageSubscription)
      this.messageSubscription.unsubscribe();
  }
}