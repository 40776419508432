<gridview *ngIf="rows.length > 0" classes="bom-table useBorder text-center mt-3">
  <gridview-header classes="input-font-style text-center">
    <header-columntemplate *ngFor="let column of columns"
                           title="{{column.contents}}"
                           [classes]="column.classes"
                           [useSorting]="false">
      <span class="ellipsis-wrap">
        {{column.contents}}
      </span>
    </header-columntemplate>
  </gridview-header>
  <gridview-row *ngFor="let row of rows"
                [classes]="row.classes">
    <columntemplate *ngFor="let cell of row.cells" [classes]="cell.classes" [size]="cell.size" title="{{cell.contents}}">
      {{cell.contents}}
    </columntemplate>
  </gridview-row>
</gridview>