import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { Range } from "immutable";

@Component({
  selector: 'pagination',
  templateUrl: './paginationComponent.html'
})
export class PaginationComponent implements OnInit {
  @Input()
  currentPage: number;

  @Input()
  noOfPages: number;

  @Input()
  maxPages: number = 5;

  @Output()
  pageChanged = new EventEmitter();

  ngOnInit() {
  }

  public get pages() {
    // Create a simple array containing the page numbers so we can iterate them
    // Make the current page centered, and show last/next pages on each side.

    // number of pages to show on each side of center
    let step = Math.floor(this.maxPages / 2);
    // lower bounds is first page or current page minus step
    let lower = Math.max(1, this.currentPage - step);
    // upper bounds is last page or lower bounds plus the number of pages to display
    let upper = Math.min(Math.min(this.noOfPages, this.maxPages) + lower, this.noOfPages + 1);

    return Range(lower, upper).toArray();    
  }

  public onPaging(page: number) {
    this.currentPage = page;
    this.pageChanged.emit({ page: page });
  }
}