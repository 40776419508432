import { Component, OnInit, AfterContentInit, Inject, Input, EventEmitter, Output } from "@angular/core";

@Component({ template: '' })
export abstract class BaseButtonComponent {

  @Input() class: string;

  @Input() tag: any;

  @Output() buttonClick = new EventEmitter();

  onClick($event): void {
    // TODO: We might need proper event class.
    this.buttonClick.emit({ event: $event, tag: this.tag });
    $event.stopPropagation();
  }

}