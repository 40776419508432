import { Injectable, Inject } from "@angular/core";

import { AppStore, AppAction, UIAction, PageActions, GlobalDataActions } from "../../state";
import { RequestViews } from "../../models";
import { ImmutableObject } from "../../../../shared/immutableObject";

@Injectable()
export class PageActionCreator {

  constructor(
    @Inject(AppStore) public appStore: AppStore
  ) {

  }

  dispatchPageIdentifierIncrement = (pageId: number) => this.appStore.dispatch(this.pageIdentifierIncrement(pageId)) as UIAction<any>;
  pageIdentifierIncrement(pageId: number): UIAction<any> {

    return {
      type: PageActions.INCREMENT_PAGE_IDENTITY, id: -1, executeAsync: null, pageId: pageId
    }
  }

  dispatchAddConfiguratorPageUIData = (pageIdentifier) => this.appStore.dispatch(this.addConfiguratorPageUIData(pageIdentifier)) as AppAction<any>;
  addConfiguratorPageUIData(pageIdentifier: number): UIAction<any> {
    return {
      type: PageActions.CREATE_CONFIGURATOR_PAGE_UI_DATA, pageId: pageIdentifier, executeAsync: null
    }
  }

  dispatchAddConfUIData = (pageId, confId) => this.appStore.dispatch(this.addConfUIData(pageId, confId)) as UIAction<any>;
  addConfUIData(pageId: number, confId: number): UIAction<any> {
    return {
      type: PageActions.CREATE_CONF_UI_DATA, pageId: pageId, executeAsync: null, payload: confId
    }
  }

  dispatchUpdateState = (pageId: number, actionType: string, data: ImmutableObject) => this.appStore.dispatch(this.updateState(pageId, actionType, data)) as AppAction<ImmutableObject>;
  updateState(pageId: number, actionType: string, data: ImmutableObject): UIAction<ImmutableObject> {
    return {
      type: actionType, pageId: pageId, executeAsync: null, payload: data
    }
  }

  dispatchUpdateActiveView = (activeRouteName: string) => this.appStore.dispatch(this.updateActiveView(activeRouteName)) as AppAction<string>;
  updateActiveView(activeRouteName: string): UIAction<string> {
    return {
      type: PageActions.CONF_ACTIVE_ROUTE_NAME, executeAsync: null, payload: activeRouteName
    }
  }

  dispatchUpdateIncludeMandatoryInfo = (pageId: number, includeMandatoryInfo: boolean) => this.appStore.dispatch(this.updateIncludeMandatoryInfo(pageId, includeMandatoryInfo)) as AppAction<boolean>;
  updateIncludeMandatoryInfo(pageId: number, includeMandatoryInfo: boolean): UIAction<boolean> {
    return {
      type: PageActions.MANDATORY_INCLUDE_INFO, pageId: pageId, executeAsync: null, payload: includeMandatoryInfo
    }
  }  

  dispatchUpdateIsNextStateIncluded = (pageId: number, isNextStateIncluded: boolean) => this.appStore.dispatch(this.updateIsNextStateIncluded(pageId, isNextStateIncluded)) as AppAction<boolean>;
  updateIsNextStateIncluded(pageId: number, isNextStateIncluded: boolean): UIAction<boolean> {
    return {
      type: PageActions.MANDATORY_IS_NEXT_STATE_INCLUDED, pageId: pageId, executeAsync: null, payload: isNextStateIncluded
    }
  }
}