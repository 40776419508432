import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PropertyDecorationComponent } from "./propertyDecorationComponent";
import { PropertyDecorationItemComponent } from "./propertyDecorationItemComponent";
import { ImageModule, DropdownModule, TextBoxModule, GridviewModule, FloatingModule, DatepickerModule, CheckboxModule } from "../../../../shared/components";

export * from "./propertyDecorationComponent";
export * from "./propertyDecorationItemComponent";

@NgModule({
  imports: [
    CommonModule,
    DropdownModule,
    TextBoxModule,
    CheckboxModule,
    FloatingModule,
    ImageModule,
    GridviewModule,
    DatepickerModule
  ],
  declarations: [
    PropertyDecorationComponent,
    PropertyDecorationItemComponent    
  ],
  exports: [
    PropertyDecorationComponent,
    PropertyDecorationItemComponent
  ]
})
export class PropertyDecorationModule {

}