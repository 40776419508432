import * as Immutable from "immutable";
import { ImmutableObject } from "../../../../shared/immutableObject";

export class CodeDecorationItem extends ImmutableObject {

  protected readonly CODE_FILE_ID = "codeFileId";
  protected readonly CODE_FILE_TITLE = "codeFileTitle";  

  get codeFileId(): number { return this.getInternalValue<number>(this.CODE_FILE_ID); }
  setCodeFileId(codeFileId: number): this { return this.setInternalValue(this.CODE_FILE_ID, codeFileId); }

  get codeFileTitle(): string { return this.getInternalValue<string>(this.CODE_FILE_TITLE); }
  setCodeFileTitle(codeFileTitle: string): this { return this.setInternalValue(this.CODE_FILE_TITLE, codeFileTitle); }

}