import { Component, ViewChild, ElementRef, ChangeDetectorRef, Input, Host, Inject, forwardRef, SimpleChanges } from "@angular/core";
import { BaseCheckboxComponent } from './baseCheckboxComponent';
import { CoCheckboxComponent } from "../coCheckboxComponent";

@Component({
  selector: 'classic-checkbox',
  templateUrl: './classicCheckboxComponent.html'
})
export class ClassicCheckboxComponent extends BaseCheckboxComponent {

  @Input()
  public viewValue;

  public stateString: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['viewValue']) {
      this.stateString = this.getState();
    }
  }

  constructor(
    @Host() @Inject(forwardRef(() => CoCheckboxComponent)) public checkbox: CoCheckboxComponent,
    protected cdr: ChangeDetectorRef
  ) {
    super(checkbox, cdr);
  }

  getState(): any {
    return this.checkbox.extractState(this.view.value);
  }

  @ViewChild('chekBoxButton')
  inputControlRef: ElementRef;

  
  readOnlyClick(): void {
    this.checkbox.notifyIfReadOnly(this.view, null);
  }

}