import { Injector } from "@angular/core";

/**
 *  Creates a static service locator for GLOBAL services.
    NOTE! Do not use this for non-global/non-constant services,
          only use it for services like "strings.ts"
 */
export class GlobalServiceLocator {

  /** Injected when boostraping the application (appModule.ts) */
  static injector: Injector = null;

}