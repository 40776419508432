import * as Immutable from "immutable"

import { AbstractConfigurationMessage } from "./abstractConfigurationMessage"
import { RuleInfo } from ".."

export class RuleInformationMessage extends AbstractConfigurationMessage {
    
  protected readonly RULE_INFOS = "ruleInfos";

  get ruleInfos(): Immutable.List<RuleInfo> { return this.getInternalValue<Immutable.List<RuleInfo>>(this.RULE_INFOS); }
  setRuleInfos(ruleInfos: Immutable.List<RuleInfo>): this { return this.setInternalValue(this.RULE_INFOS, ruleInfos); }
}