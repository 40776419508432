import { Component, EventEmitter, Inject, Input, Output, SimpleChanges } from '@angular/core';
import { ImageSets } from '../../../../shared/utils/imageSets';
import { BaseComponent } from "../../../shared";
import { Conf, ConfInfo } from '../../../shared/models';
import { RouteNames } from "../../../shared/providers";
import { GlobalDataStore } from '../../../shared/providers/globalData';
import { ConfiguratorStore, ConfPageSessionService } from '../../providers';
import { ParameterMandatoryService } from "../../parameters/shared/parameterMandatoryService";
import { Subscription } from 'rxjs';
import { ManagedSubscription } from '../../../../shared';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'control-panel',
  templateUrl: './controlPanelComponent.html'
})
export class ControlPanelComponent extends BaseComponent {

  @Output()
  save = new EventEmitter();

  @Output()
  saveExit = new EventEmitter();

  @Output()
  close = new EventEmitter();

  @Output()
  mandatory = new EventEmitter();

  @Output()
  modify = new EventEmitter();

  @Output()
  startOver = new EventEmitter();

  @Output()
  reset = new EventEmitter();

  iconWidth: string = "24px";

  iconHeight: string = "24px";

  public iconsImageSet: string;

  public showSave: boolean = false;
  public showSaveAndClose: boolean = false;
  public showMandatory: boolean = false;
  public showClose: boolean = false;
  public showModify: boolean = false;
  public startOverBtn: boolean = false;
  public disableSaveBtn: boolean = false;
  public highlightIfMandatory: boolean = false;
  public notSetMandatoryParamsCount: string;
  public highlightChangeSubscription: Subscription;
  public mandatorySubscription: ManagedSubscription;
  public structureChangeSubscription: ManagedSubscription;
  public confInfo: ConfInfo;

  @Input()
  public isAnonymousMode: boolean = false;

  @Input()
  public activeRoute: string = RouteNames.Editor;
    
  constructor(
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(ParameterMandatoryService) public parameterMandatoryService: ParameterMandatoryService,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(Router) public router: Router,
    @Inject(ConfPageSessionService) public confPageSession: ConfPageSessionService) { super(); }

  ngOnInit() {
    this.iconsImageSet = this.getImageSet(ImageSets.MainMenu);

    this.notSetMandatoryParamsCount = this.confStore.getConfInfo(this.confPageSession.activeConfigurationId, this.confPageSession.confSessionId).notSetMandatoryParamsCount.toString();
    this.highlightIfMandatory = this.globalDataStore.globalSettings.highlightMandatoryParameters;

    this.startSubscriptions();
  }

  startSubscriptions(): void {

    this.mandatorySubscription = this.confStore.onConfigurationChange(this.confPageSession.activeConfigurationId, this.confPageSession.confSessionId, (conf: Conf) => {
      this.updateNotSetMandatoryParamsCount();        
    });

    this.structureChangeSubscription = this.confStore.onCompositeStructureChange(this.confPageSession.confSessionId, (): void => {
      this.updateNotSetMandatoryParamsCount();        
    });

    // Update on delete node. As navigation would be made after delete action
    // TODO: Prevent extra listening, It might be good if we listen node select operation here, In this way after delete and selecting new node,
    // mandatory counts can be updated.
    this.router.events.forEach((event) => {

      if (event instanceof NavigationEnd && this.confPageSession.activeRoute == RouteNames.Editor) {
        this.updateNotSetMandatoryParamsCount();        
      }
      
    });

    this.highlightChangeSubscription = this.parameterMandatoryService.getMessage().subscribe(() => {
      this.highlightIfMandatory = this.parameterMandatoryService.isHighlighting;
    });
  }

  protected updateNotSetMandatoryParamsCount(): void {

    let activeConfInfo: ConfInfo = this.confStore.getConfInfo(this.confPageSession.activeConfigurationId, this.confPageSession.confSessionId);

    if (activeConfInfo) {
      let rootConf: ConfInfo = this.confStore.getConfInfo(activeConfInfo.rootId, this.confPageSession.confSessionId);
      const count = this.confStore.getConfInfo(rootConf.longId, this.confPageSession.confSessionId).notSetMandatoryParamsCount;
      this.notSetMandatoryParamsCount = count > 0 ? count.toString() : null;
    }

  }
   

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['activeRoute']) {
      this.applySettings();
    }
  }

  applySettings(): void {

    let globalSettings = this.globalDataStore.getGlobalData().globalSettings;
    let conf: Conf = this.confPageSession.activeConfiguration;

    if (this.activeRoute == RouteNames.Editor) {
      this.showSave = !this.isAnonymousMode && globalSettings.webConfiguratorFooterSave && conf.authorization.canSave;
      this.showSaveAndClose = globalSettings.webConfiguratorFooterSaveAndClose && conf.authorization.canSave;
      this.showMandatory = !this.isAnonymousMode && globalSettings.webConfiguratorFooterMandatory;
      this.showClose = !this.isAnonymousMode && globalSettings.webConfiguratorFooterClose;
      this.disableSaveBtn = conf.authorization.isSaveOperationDisallowed;
      this.startOverBtn = this.isAnonymousMode;
      this.showModify = false;
    }
    else {
      this.showModify = this.isAnonymousMode && conf.authorization.canEdit;
      this.showSave = this.showSaveAndClose = this.showMandatory = this.showClose = this.startOverBtn = false;
    }
  }

  ngOnDestroy() {
    this.stopSubscriptions();
  }

  stopSubscriptions(): void {

    if (this.highlightChangeSubscription)
      this.highlightChangeSubscription.unsubscribe();

    if (this.mandatorySubscription)
      this.mandatorySubscription.unsubscribe();

    if (this.structureChangeSubscription)
      this.structureChangeSubscription.unsubscribe();

  }
}