import { Inject, Component, Input, Optional, ViewChild, Output, EventEmitter } from "@angular/core";
import { BaseComponent } from "../..";
import { PopupService, ValueChangeEventArgs, InputViewModel } from "../../../../shared/components";

@Component({
  selector: 'print-checkbox',
  templateUrl: './printCheckboxComponent.html',
})
export class PrintCheckboxComponent extends BaseComponent {

  @Input()
  defaultValue: boolean = true;

  @Output()
  onValueChange = new EventEmitter<ValueChangeEventArgs>();

  viewModel: InputViewModel;

  ngOnInit() {

    this.viewModel = new InputViewModel();
    this.viewModel.value = this.defaultValue;
    this.viewModel.width = "50px";


  }

  valueChanged(event: ValueChangeEventArgs): void {

    this.onValueChange.emit(event);
  }

}