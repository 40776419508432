import { Injectable, Inject } from '@angular/core';
import * as Immutable from "immutable";

import { ReselectorService } from "./reselectorService";
import { ApiMessage } from "../models/responses/messages";
import { ManagedSubscription, SubscriptionOptions } from "../../../shared/managedSubscription";
import { ManagedSubject } from "../../../shared/managedSubject";
import { AppStoreSubscriptionManager } from "./appStoreSubscriptionManager";

@Injectable()
export class ApiMessageProvider {

  constructor(
    public appStoreSubscriptionManager: AppStoreSubscriptionManager,
    public reselectorService: ReselectorService) {
  }

  public onMessages<T extends ApiMessage>(messageClassName: string, subscriptionOptions: SubscriptionOptions<Immutable.List<T>>): ManagedSubscription {
    return this.createStoreSubscription<Immutable.List<T>>(messageClassName, this.getMessagesMemorizer<T>(messageClassName), subscriptionOptions);
  }

  public createStoreSubscription<T>(identifier: string, memorizer: () => T, subscriptionOptions: SubscriptionOptions<T>): ManagedSubscription {

    let managedSubject: ManagedSubject<T> = this.appStoreSubscriptionManager.getOrCreateStoreSubject<T>(identifier, memorizer, false, 1, false);
    return managedSubject.subscribe(subscriptionOptions);
  }

  public getMessagesMemorizer<T extends ApiMessage>(className: string): () => Immutable.List<T> {

    return this.reselectorService.createMemorizer((state) => {

      let messages = state.messages;
      if (messages && messages.size > 0)
        return messages.get(className) as Immutable.List<T>;

      return null;
    });
  } 
}