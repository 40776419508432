import { Component, Input, OnInit } from "@angular/core";

const LayoutDirection = {
  TopLeft: 'topLeft',
  TopRight: 'topRight',
  BottomLeft: 'bottomLeft',
  BottomRight: 'bottomRight'
}

@Component({
  selector: 'absolute-layout',
  template: `<div #floatingLayout               
                  [style.left.px]="left"
                  [style.right.px]="right"
                  [style.top.px]="top"     
                  [style.bottom.px]="bottom"     
                  [style.z-index]="zIndex"
                  class="layout-{{visibleStyle}} {{transitionStyle}}"                      
              >
                <ng-content></ng-content>
            </div>`
})
export class AbsoluteLayoutComponent implements OnInit {

  @Input() direction: string = LayoutDirection.TopLeft;

  @Input() gutter: string;

  @Input() positionX: number;

  @Input() positionY: number;

  @Input() zIndex: string;

  @Input() alwaysShow: boolean = true;

  public transitionStyle: string;
  public visibleStyle: string;
  public left: string;
  public right: string;
  public top: string;
  public bottom: string;

  ngOnInit() {
    // Set the position if the positionX is provided through template input.
    if (this.positionX != null)
      this.setPosition()

    if (this.alwaysShow)
      this.show();
  }

  adjust(positionX: number, positionY: number): void {
    this.positionX = positionX;
    this.positionY = positionY;

    // Explicit call to set the position.
    this.setPosition();
  }

  public addTransition(transition: boolean): void {
    // Set no transition to have abrupt change
    this.transitionStyle = "transition-layout";

    // Remove no-transition css.
    //setTimeout(() =>
    //{
    //  this.transitionStyle = ''
    //}, 5000);

    if (!transition) {
      this.transitionStyle += ".none";
    }
  }

  hide(transition: boolean = true): void {
    if (transition) {
      this.addTransition(transition);
    }

    this.visibleStyle = "hide";
  }

  show(transition: boolean = true): void {
    if (transition) {
      this.addTransition(transition);
    }

    this.visibleStyle = "show";
  }

  public setPosition(): void {
    switch (this.direction) {
      case LayoutDirection.TopLeft:
        this.right = "";
        this.left = String(this.positionX);
        this.top = String(this.positionY);
        this.bottom = "";
        break;

      case LayoutDirection.TopRight:
        this.left = "";
        this.right = String(this.positionX);

        this.top = String(this.positionY);
        this.bottom = "";

        break;

      case LayoutDirection.BottomLeft:
        this.right = "";
        this.left = String(this.positionX);

        this.top = "";
        this.bottom = String(this.positionY);

        break;

      case LayoutDirection.BottomRight:
        this.right = String(this.positionX);
        this.left = "";
        this.top = "";
        this.bottom = String(this.positionY);

        break;
    }

  }
}