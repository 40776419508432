import { ChangeDetectorRef, Component, Inject, Input, SimpleChanges, ChangeDetectionStrategy } from "@angular/core";
import { TabModel } from "../../../../shared/components/tab";
import { Tab } from "../../../shared/models";
import { ProductDataStore } from "../../../shared/providers/productData";
import { PageUIData } from "../../../shared/state";
import { ConfigurationSessionManager, ConfiguratorUIStore, ConfPageSessionService } from "../../providers";
import { TabAvailabilityService } from "./tabAvailabilityService";
import { TabContainerComponent } from "./tabContainerComponent";
import { TabDisplayStyle } from "../../../shared/providers/tabDisplayStyle";


@Component({
  selector: 'normal-tabs',
  templateUrl: './normalTabsContainerComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NormalTabsContainerComponent extends TabContainerComponent {
  
  @Input()
  tabs: Tab[] = [];

  tabModels: TabModel[] = [];

  @Input()
  public mobileMode: boolean = false; // In mobile mode, we switch the normal tabs to accordion.

  public configurationId: number;

  selectedTabId: number;

  public tabDisplayStyle = TabDisplayStyle;

  activeTab: Tab;

  constructor(
    @Inject(ConfiguratorUIStore) public confUIStore: ConfiguratorUIStore,
    @Inject(ConfPageSessionService) public confPageSession: ConfPageSessionService,
    @Inject(ProductDataStore) public productDataStore: ProductDataStore,
    @Inject(ChangeDetectorRef) public cd: ChangeDetectorRef,
    public tabAvailableService: TabAvailabilityService,
    @Inject(ConfigurationSessionManager) public configurationSessionManager: ConfigurationSessionManager) {

    super(confUIStore, cd, tabAvailableService);
  }

  ngOnInit() {    
    this.configurationId = this.confPageSession.activeConfigurationId;
    // Set the remembered tab.
    if (this.tabs.findIndex(x => x.longId == this.confPageSession.selectedTabId) > -1) {      
      this.setActiveOrDefaultTab(this.confPageSession.selectedTabId);
    }
    else this.setActiveOrDefaultTab(this.tabs[0].longId);

    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tabs']) {
      this.setTabUIModels();      
      this.setActiveOrDefaultTab(+this.selectedTabId);
    }   
  }

  setTabUIModels(): void {

    this.tabModels = [];
    this.tabs.forEach(x => {

      this.tabModels.push(<TabModel>{
        id: x.longId.toString(),
        icon: x.iconRelativeUrl,
        title: x.title
      });

    });
  }

  public trackTab(index: number, tab: Tab): any {
    return tab.longId;
  }

  get pageId(): number {
    return this.confPageSession.pageId;
  }

  onUIDataChange(uiData: PageUIData): void {

  }

  public selectedTab(model: TabModel): void {
    // Remember the selected tabId.
    this.confPageSession.selectedTabId = +model.id;
    this.setActiveOrDefaultTab(+model.id);
  }

  public onAccordionOpen(tabId: number) {
    this.setActiveOrDefaultTab(tabId);
    this.cd.markForCheck();
  }


  /**
   * Sets the active tab, if matching tab.longId is not found then It sets the first 
   * @param id
   */
  setActiveOrDefaultTab(id: number): void {
    let result = this.tabs.filter(x => x.longId == id);
    this.activeTab = result.length > 0 ? result[0] : this.tabs[0];
    this.selectedTabId = this.activeTab.longId;
    this.domReady = true;
    this.cd.markForCheck();
  }


  public activateTab(tab: Tab) {

    if (this.tabs.findIndex(x => x.longId == tab.longId) > -1)
      this.setActiveOrDefaultTab(tab.longId);

  }

}