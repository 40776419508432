import * as Immutable from "immutable";

import { ImmutableObject } from "../../../../shared/immutableObject";

export class ConfAuthorization extends ImmutableObject {

  readonly CAN_VIEW_STATE = "canViewState";
  readonly CAN_PROMOTE_STATE = "canPromoteState";
  readonly CAN_DEMOTE_STATE = "canDemoteState";
  readonly CAN_VIEW_HISTORY = "canViewHistory";
  readonly CAN_EDIT = "canEdit";
  readonly CAN_RECOVER = "canRecover";
  readonly CAN_REVISE = "canRevise";
  readonly CAN_VIEW_REVISIONS = "canViewRevisions";
  readonly CAN_REUSE = "canReuse";
  readonly CAN_SAVE = "canSave";  
  readonly CAN_DELETE = "canDelete";
  readonly CAN_CHANGE_OWNERSHIP = "canChangeOwnership";
  readonly TAKE_OWNERSHIP_TO_WORKGROUP_IDS = "takeOwnershipToWorkGroupIds";
  readonly CAN_PRINT = "canPrint";
  readonly CAN_VIEW_DOCUMENTS = "canViewDocuments";
  readonly CAN_VIEW_ATTACHMENTS = "canViewAttachments";
  readonly CAN_ADD_ATTACHMENT = "canAddAttachment";
  readonly CAN_DELETE_ATTACHMENT = "canDeleteAttachment";
  readonly CAN_VIEW_REPORTS = "canViewReports";
  readonly CAN_CHANGE_PRICE_LIST_CATEGORY = "canChangePriceListCategory";
  readonly CAN_CHANGE_CURRENCY = "canChangeCurrency";
  readonly CAN_EMAIL_LINK = "canEmailLink";
  readonly CAN_MAKE_COPIES = "canMakeCopies";
  readonly CAN_ADD_CHILD = "canAddChild";
  readonly IS_SAVE_OPERATION_DISALLOWED = "isSaveOperationDisallowed";
  readonly IS_REVISE_OPERATION_DISALLOWED = "isReviseOperationDisallowed";

  get canViewState(): boolean { return this.getInternalValue<boolean>(this.CAN_VIEW_STATE); }
  setCanViewState(canViewState: boolean): this { return this.setInternalValue(this.CAN_VIEW_STATE, canViewState); }

  get canPromoteState(): boolean { return this.getInternalValue<boolean>(this.CAN_PROMOTE_STATE); }
  setCanPromoteState(canPromoteState: boolean): this { return this.setInternalValue(this.CAN_PROMOTE_STATE, canPromoteState); }
 
  get canDemoteState(): boolean { return this.getInternalValue<boolean>(this.CAN_DEMOTE_STATE); }
  setCanDemoteState(canDemoteState: boolean): this { return this.setInternalValue(this.CAN_DEMOTE_STATE, canDemoteState); }
    
  get canViewHistory(): boolean { return this.getInternalValue<boolean>(this.CAN_VIEW_HISTORY); }
  setCanViewHistory(canViewHistory: boolean): this { return this.setInternalValue(this.CAN_VIEW_HISTORY, canViewHistory); }

  get canEdit(): boolean { return this.getInternalValue<boolean>(this.CAN_EDIT); }
  setCanEdit(canEdit: boolean): this { return this.setInternalValue(this.CAN_EDIT, canEdit); }

  get canRecover(): boolean { return this.getInternalValue<boolean>(this.CAN_RECOVER); }
  setCanRecover(canRecover: boolean): this { return this.setInternalValue(this.CAN_RECOVER, canRecover); }

  get canRevise(): boolean { return this.getInternalValue<boolean>(this.CAN_REVISE); }
  setCanRevise(canRevise: boolean): this { return this.setInternalValue(this.CAN_REVISE, canRevise); }

  get canViewRevisions(): boolean { return this.getInternalValue<boolean>(this.CAN_VIEW_REVISIONS); }
  setCanViewRevisions(canViewRevisions: boolean): this { return this.setInternalValue(this.CAN_VIEW_REVISIONS, canViewRevisions); }

  get canReuse(): boolean { return this.getInternalValue<boolean>(this.CAN_REUSE); }
  setCanReuse(canReuse: boolean): this { return this.setInternalValue(this.CAN_REUSE, canReuse); }

  get canMakeCopies(): boolean { return this.getInternalValue<boolean>(this.CAN_MAKE_COPIES); }
  setCanMakeCopies(canMakeCopies: boolean): this { return this.setInternalValue(this.CAN_MAKE_COPIES, canMakeCopies); }

  get canAddChild(): boolean { return this.getInternalValue<boolean>(this.CAN_ADD_CHILD); }
  setCanAddChild(canAddChild: boolean): this { return this.setInternalValue(this.CAN_ADD_CHILD, canAddChild); }

  get canDelete(): boolean { return this.getInternalValue<boolean>(this.CAN_DELETE); }
  setCanDelete(canDelete: boolean): this { return this.setInternalValue(this.CAN_DELETE, canDelete); }

  get canChangeOwnership(): boolean { return this.getInternalValue<boolean>(this.CAN_CHANGE_OWNERSHIP); }
  setCanChangeOwnership(canChangeOwnership: boolean): this { return this.setInternalValue(this.CAN_CHANGE_OWNERSHIP, canChangeOwnership); }

  get takeOwnershipToWorkGroupIds(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.TAKE_OWNERSHIP_TO_WORKGROUP_IDS); }
  setTakeOwnershipToWorkGroupIds(takeOwnershipToWorkGroupIds: Immutable.List<number>): this { return this.setInternalValue(this.TAKE_OWNERSHIP_TO_WORKGROUP_IDS, takeOwnershipToWorkGroupIds); }

  get canPrint(): boolean { return this.getInternalValue<boolean>(this.CAN_PRINT); }
  setCanPrint(canPrint: boolean): this { return this.setInternalValue(this.CAN_PRINT, canPrint); }

  get canViewDocuments(): boolean { return this.getInternalValue<boolean>(this.CAN_VIEW_DOCUMENTS); }
  setCanViewDocuments(canViewDocuments: boolean): this { return this.setInternalValue(this.CAN_VIEW_DOCUMENTS, canViewDocuments); }

  get canViewAttachments(): boolean { return this.getInternalValue<boolean>(this.CAN_VIEW_ATTACHMENTS); }
  setCanViewAttachments(canViewAttachments: boolean): this { return this.setInternalValue(this.CAN_VIEW_ATTACHMENTS, canViewAttachments); }

  get canAddAttachment(): boolean { return this.getInternalValue<boolean>(this.CAN_ADD_ATTACHMENT); }
  setCanAddAttachment(canAddAttachment: boolean): this { return this.setInternalValue(this.CAN_ADD_ATTACHMENT, canAddAttachment); }

  get canDeleteAttachment(): boolean { return this.getInternalValue<boolean>(this.CAN_DELETE_ATTACHMENT); }
  setCanDeleteAttachment(canDeleteAttachment: boolean): this { return this.setInternalValue(this.CAN_DELETE_ATTACHMENT, canDeleteAttachment); }

  get canViewReports(): boolean { return this.getInternalValue<boolean>(this.CAN_VIEW_REPORTS); }
  setCanViewReports(canViewReports: boolean): this { return this.setInternalValue(this.CAN_VIEW_REPORTS, canViewReports); }

  get canChangePriceListCategory(): boolean { return this.getInternalValue<boolean>(this.CAN_CHANGE_PRICE_LIST_CATEGORY); }
  setCanChangePriceListCategory(canChangePriceListCategory: boolean): this { return this.setInternalValue(this.CAN_CHANGE_PRICE_LIST_CATEGORY, canChangePriceListCategory); }

  get canChangeCurrency(): boolean { return this.getInternalValue<boolean>(this.CAN_CHANGE_CURRENCY); }
  setCanChangeCurrency(canChangeCurrency: boolean): this { return this.setInternalValue(this.CAN_CHANGE_CURRENCY, canChangeCurrency); }

  get canEmailLink(): boolean { return this.getInternalValue<boolean>(this.CAN_EMAIL_LINK); }
  setCanEmailLink(canEmailLink: boolean): this { return this.setInternalValue(this.CAN_EMAIL_LINK, canEmailLink); }

  get canSave(): boolean { return this.getInternalValue<boolean>(this.CAN_SAVE); }
  setSave(canSave: boolean): this { return this.setInternalValue(this.CAN_SAVE, canSave); }

  get isSaveOperationDisallowed(): boolean { return this.getInternalValue<boolean>(this.IS_SAVE_OPERATION_DISALLOWED); }
  setIsSaveOperationDisallowed(isSaveOperationDisallowed: boolean): this { return this.setInternalValue(this.IS_SAVE_OPERATION_DISALLOWED, isSaveOperationDisallowed); }

  get isReviseOperationDisallowed(): boolean { return this.getInternalValue<boolean>(this.IS_REVISE_OPERATION_DISALLOWED); }
  setIsReviseOperationDisallowed(isReviseOperationDisallowed: boolean): this { return this.setInternalValue(this.IS_REVISE_OPERATION_DISALLOWED, isReviseOperationDisallowed); }


}