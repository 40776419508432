export class AccountDataActions {

  static REQUEST_LOGGED_IN_USER = "@REQUEST_LOGGED_IN_USER";
  static LOGGED_IN_USER_LOADED = "@LOGGED_IN_USER_LOADED";

  static USER_LOGGING_IN = "@USER_LOGGING_IN";  
  static USER_LOGIN_RESULT = "@USER_LOGIN_RESULT";

  static USER_LOGGING_OUT = "@USER_LOGGING_OUT";
  static USER_LOGOUT_RESULT = "@USER_LOGOUT_RESULT";
  
  static USER_CHANGE_PASSWORD = "@USER_CHANGE_PASSWORD";
  static USER_CHANGE_PASSWORD_RESULT = "@USER_CHANGE_PASSWORD_RESULT";

  static ACCOUNT_VERIFY_REQEUEST = "@ACCOUNT_VERIFY_REQEUEST";
  static ACCOUNT_VERIFY_REQUEST_RESULT = "@ACCOUNT_VERIFY_REQUEST_RESULT";

  static ACCOUNT_CREATE_ACCOUNT_REQUEST = "@ACCOUNT_CREATE_ACCOUNT_REQEUEST";
  static ACCOUNT_CREATE_ACCOUNT_REQUEST_RESULT = "@ACCOUNT_CREATE_ACCOUNT_RESULT";

  static ACCOUNT_VERIFY_CODE = "@ACCOUNT_VERIFY_CODE";
  static ACCOUNT_VERIFY_CODE_RESULT = "@ACCOUNT_VERIFY_CODE_RESULT";

  static USER_UPDATE_PROFILE = "@USER_UPDATE_PROFILE";
  static USER_UPDATE_PROFILE_RESULT = "@USER_UPDATE_PROFILE_RESULT";

  static USER_UPDATE_SETTINGS = "@USER_UPDATE_SETTINGS";
  static USER_UPDATE_SETTINGS_RESULT = "@USER_UPDATE_SETTINGS_RESULT";

  static ACCOUNT_FETCH_ENTITIES = "@ACCOUNT_FETCH_ENTITIES";
  static ACCOUNT_ENTITIES_LOADED = "@ACCOUNT_ENTITIES_LOADED";


}