import { Component, ViewEncapsulation, Inject } from "@angular/core";

import { Routing } from "../../shared/route/routeDecorator";
import { AppStoreSubscriptionManager, ApplicationLifeCycleService, ApiMessageProvider, AppActionCreator, RouteNames, IHttpDataInfo } from "../shared/providers";
import { PageComponent } from "../shared";
import { GlobalDataStore } from "../shared/providers/globalData";
import { AccountDataStore } from "../shared/providers/accountData";
import { NotificationService, NotificationInfo, NotificationType } from "../../shared/components";
import { CacheReloadMessage } from "../shared/models";
import { PageStore } from "../shared/providers/page";

@Routing({ path: 'settings' })
@Routing({ path: '**' })
@Component({
  encapsulation: ViewEncapsulation.None,
  templateUrl: './settingsComponent.html',
  providers: [AppStoreSubscriptionManager]
})
export class SettingsComponent extends PageComponent {

  public databaseConnectionInfo: string = "";

  public showAdminSection: boolean = false;

  private enableDefaultLifeCycleUpdate = true;

  datePickerConfig = {
    firstDayOfWeek: 'su',
    monthFormat: 'MMM, YYYY',
    disableKeypress: false,
    allowMultiSelect: false,
    closeOnSelect: undefined,
    closeOnSelectDelay: 100,
    openOnFocus: true,
    openOnClick: true,
    onOpenDelay: 0,
    closeOnEnter: true,
    weekDayFormat: 'ddd',
    appendTo: document.body,
    showNearMonthDays: true,
    showWeekNumbers: true,
    enableMonthSelector: true,
    yearFormat: 'YYYY',
    showGoToCurrent: true,
    dayBtnFormat: 'DD',
    monthBtnFormat: 'MMM',
    hours12Format: 'hh',
    hours24Format: 'HH',
    meridiemFormat: 'A',
    minutesFormat: 'mm',
    minutesInterval: 1,
    secondsFormat: 'ss',
    secondsInterval: 1,
    showSeconds: false,
    showTwentyFourHours: false,
    timeSeparator: ':',
    multipleYearsNavigateBy: 10,
    showMultipleYearsNavigation: false,
    //locale: moment.locale(),
    hideInputContainer: false,
    //returnedValueType: ECalendarValue.String,
    unSelectOnClick: true,
    hideOnOutsideClick: true,
    numOfMonthRows: 3
  };

  constructor(
    public appActionCreator: AppActionCreator,
    public appLifeCycleService: ApplicationLifeCycleService,
    public appStoreSubscriptionManager: AppStoreSubscriptionManager,
    public globalDataStore: GlobalDataStore,
    public accountStore: AccountDataStore,
    public notificationService: NotificationService,
    @Inject(ApiMessageProvider) public apiMessageProvider: ApiMessageProvider,
    public pageStore: PageStore,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.pageStore.updateActiveRoute(RouteNames.Settings);

    this.setInfo();

    this.apiMessageProvider.onMessages<CacheReloadMessage>(CacheReloadMessage.name, {
      next: (messages) => {
        let cacheReloadMessage = messages.first();
        if (cacheReloadMessage.success) {

          this.appLifeCycleService.cacheReloaded(() => {
            this.notificationService.notify(<NotificationInfo>{
              title: this.strings.Success,
              message: this.strings.ReloadCaches_Message,
              type: NotificationType.Success,
              selfClose: true
            });

            this.setInfo();
            this.enableDefaultLifeCycleUpdate = true;
            this.unblockUI();
          });
        }
        else {
          // Handle server errors in message
          this.notificationService.notify(<NotificationInfo>{
            title: this.strings.Error,
            message: cacheReloadMessage.messages.join(". "), // TODO handle in better way
            type: NotificationType.Error,
            selfClose: true
          });

          this.enableDefaultLifeCycleUpdate = true;
          this.unblockUI();
        }
      }, listenNewEventsOnly: true
    }).unsubscribeOn(this.unsubscribeSubject);
  }

  public reloadCaches() {
    this.reload(false);
  }

  public reloadUserCaches() {
    this.reload(true);
  }

  public reload(userDataOnly: boolean) {
    this.blockUI();
    this.enableDefaultLifeCycleUpdate = false;
    this.appActionCreator.dispatchReloadCaches(userDataOnly);
  }

  setInfo() {
    let webSettings = this.globalDataStore.getGlobalData().webSettings;
    this.databaseConnectionInfo = webSettings.databaseConnectionInfo;

    let user = this.accountStore.getUser();

    // TODO authorization
    this.showAdminSection = user.systemAuthorization.canReloadCache;
  }

  ngOnDestroy() {
    this.appStoreSubscriptionManager.dispose();
    super.ngOnDestroy();
  }

  onHttpLifeCycleUpdate(http: IHttpDataInfo) {
    if (this.enableDefaultLifeCycleUpdate)
      super.onHttpLifeCycleUpdate(http);
  }
}