<div class="row sort-form">
  <div class="col">
    <div class="panel panel-success">
      <div class="panel-body w-100">
        <ul class="list-group" dnd-sortable-container [sortableData]="dragableItems"  >
          <li *ngFor="let node of dragableItems; let i = index" class="list-group-item px-0" draggable dnd-sortable [sortableIndex]="i">
            <div class="row mx-0">
              <span class="col-auto align-middle">
                <co-image type="svg" [key]="node.iconKey" [isVault]="node.vaultIcon" [width]="'32'" [height]="'32'"></co-image>
              </span>
              <span class="title col align-middle pl-1">
                {{node.title}}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="row return-to-menu align-middle mx-0" role="button" droppable (onDrop)="confirm($event)" (click)="confirm($event)">
  <div class="col text-right">
    <span class="pm-color-darker text-uppercase">{{strings.ApplyChanges}}</span>
  </div>
</div>
