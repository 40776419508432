<action-box *ngIf="visible"
            class="detail-item {{extraStyles}}"
            iconLeft="bom"
            textLeft="{{mainTitle}}"            
            [iconRight]="!isInnerTitleVisible && 'viewdetails'"
            [iconRightImageSet]="!isInnerTitleVisible && 'primary'"
            (onRightIconClick)="onDecorationHeaderIconClicked()"
            [showHeader]="showHeader">
  <div *ngIf="contentVisible" >
    <ng-container *ngFor="let model of models">
      <div *ngIf="isInnerTitleVisible" class="row bom-decoration-header">
        <div class="col">{{model.decoration.title}}</div>
        <div class="col-auto mx-4" role="button" (click)="onDetailsClick(model.decoration)" ><co-image [width]="'32'" [height]="'32'" key="viewdetails" imageSet="primary"></co-image></div>
      </div>

      <bom-decoration-contents [decoration]="model.decoration"
                               [confBomValue]="model.confBomValue"
                               [showActionControls]="false"></bom-decoration-contents>

    </ng-container>
  </div>

  <bom-composite-popup *ngIf="compositeVisible" (closedEvent)="compositeVisible=false"></bom-composite-popup>
  <div card-type="control" *ngIf="isPrintView">
    <print-checkbox (onValueChange)="onPrintValueChange($event)"></print-checkbox>
  </div>
</action-box>