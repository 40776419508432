import { Component, Input, Host, OnInit, Inject, ChangeDetectorRef, AfterViewInit, AfterContentInit, EventEmitter } from "@angular/core";
import { AbstractDropdownItem } from "../abstractDropdownItem";
import { LineDropdownGroupComponent } from './lineDropdownGroupComponent';
import { BaseLineItemComponent } from './items/baseLineItemComponent';

@Component({
  selector: "line-dropdown-item",
  template: `<standard-line-item *ngIf="!view.icon"></standard-line-item>
             <image-left-line-item *ngIf="view.icon"></image-left-line-item>`
})

export class LineDropdownItemComponent extends AbstractDropdownItem implements OnInit, AfterViewInit, AfterContentInit {

  constructor( @Host() @Inject(LineDropdownGroupComponent) public dropdownGroup: LineDropdownGroupComponent, public cdr: ChangeDetectorRef) {
    super(dropdownGroup, cdr);
  }

  public template: BaseLineItemComponent;

  ngOnInit() {
    super.ngOnInit();    
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngAfterContentInit() {
    super.ngAfterContentInit();
  }
}