import * as Immutable from "immutable";

import { AbstractConfigurationMessage } from "./abstractConfigurationMessage"

export class StateActionInfoMessage extends AbstractConfigurationMessage {

  protected readonly NAME = "name";
  protected readonly FROM_STATE = "fromState";
  protected readonly TO_STATES = "toStates";
  protected readonly MESSAGE = "message";
  protected readonly ERRORS = "errors";
  protected readonly WARNINGS = "warnings";
  protected readonly IS_ALLOWED = "isAllowed";

  get name(): string { return this.getInternalValue<string>(this.NAME); }
  setName(name: string): this { return this.setInternalValue(this.NAME, name); }

  get fromState(): number { return this.getInternalValue<number>(this.FROM_STATE); }
  setFromState(fromState: number): this { return this.setInternalValue(this.FROM_STATE, fromState); }

  get toStates(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.TO_STATES); }
  setToStates(toStates: Immutable.List<number>): this { return this.setInternalValue(this.TO_STATES, toStates); }

  get message(): string { return this.getInternalValue<string>(this.MESSAGE); }
  setMessage(message: string): this { return this.setInternalValue(this.MESSAGE, message); }

  get errors(): Immutable.List<string> { return this.getInternalValue<Immutable.List<string>>(this.ERRORS); }
  setErrors(errors: Immutable.List<string>): this { return this.setInternalValue(this.ERRORS, errors); }

  get warnings(): Immutable.List<string> { return this.getInternalValue<Immutable.List<string>>(this.WARNINGS); }
  setWarnings(warnings: Immutable.List<string>): this { return this.setInternalValue(this.WARNINGS, warnings); }

  get isAllowed(): boolean { return this.getInternalValue<boolean>(this.IS_ALLOWED); }
  setIsAllowed(isAllowed: boolean): this { return this.setInternalValue(this.IS_ALLOWED, isAllowed); }
}