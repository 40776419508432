import { SafeStyle } from "@angular/platform-browser/";

export class DataRowView {
  
  id?: string;

  cells: DataCellView[] = [];

  // Css classes
  classes?: string;

  tag?: any;
}


export class DataCellView {

  id?: string;

  contents?: string;

  /** gridview column bootstrap size (col-x) */
  size?: number;

  /** gridview column exact pixel width */
  width?: number;

  classes?: string;

  /** safe css-style for this cell. */
  safeStyle?: {};

  href?: string;

  image?: string;

  tag?: any;

  cellId?: string;

  selected?: boolean = false;

  autoCell?: boolean = false;    

  alignment?: string;
}