import { ChangeDetectorRef, Component, Inject, ViewChild, ChangeDetectionStrategy } from "@angular/core";
import { InputViewModel } from "../../../../shared/components";
import { ConfDataSelectorValue, DataSelector, Tab } from "../../../shared/models";
import { TabDisplayStyle } from "../../../shared/providers";
import { ProductDataStore } from "../../../shared/providers/productData";
import { ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { DecorationComponent } from "../../shared/decorationComponent";
import { DataSelectorActionCreator } from "./dataSelectorActionCreator";
import { DataSelectorController } from "./dataSelectorController";
import { DataSelectorStore } from "./dataSelectorStore";
import { DataSelectorTableModalComponent } from "./dataSelectorTableModalComponent";
import { Delay, sleep } from "../../../../shared/utils";
import { VisualObjectHelper } from "../../parameters/shared";

@Component({
  selector: 'data-selector-decoration',
  templateUrl: './dataSelectorComponent.html',
  providers: [DataSelectorStore, DataSelectorActionCreator, DataSelectorController],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DataSelectorComponent extends DecorationComponent<DataSelector> {

  public confDataSelectorValue: ConfDataSelectorValue;
  public view: InputViewModel;

  public value: string = "Empty";
  public placeholder: string = "";
  public isSidebar: boolean;
  public showInline: boolean = false;
  public showTable: boolean = false;
  public activeConfId: number;

  @ViewChild("selectorModelComponent")
  selectorModelComponent: DataSelectorTableModalComponent;

  constructor(
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(DataSelectorStore) public dataSelectorStore: DataSelectorStore,
    @Inject(ProductDataStore) public productDataStore: ProductDataStore,
    @Inject(VisualObjectHelper) public visualObjectHelper: VisualObjectHelper,
    public cdr: ChangeDetectorRef,
  ) {
    super(confStore, confPageSessionService, visualObjectHelper);
  }

  ngOnInit() {

    this.view = new InputViewModel();
    this.view.icon = "product"; //TODO dataselector
    this.view.iconHeight = this.view.iconWidth = "24px";

    this.showInline = this.decoration.showInline;

    this.updateValue(this.confStore.getConfDataEntity<ConfDataSelectorValue>(this.configuration.longId, this.confPageSessionService.confSessionId, this.decoration.visualObjectId));
    super.ngOnInit();

    this.view.iconImageSet = this.imageSet;
  }

  public clearValue() {
    this.dataSelectorStore.clearValue(this.confPageSessionService.activeConfigurationId, this.confPageSessionService.confSessionId, this.decoration.visualObjectId);
  }

  public listenChanges(): void {
    this.confStore.onConfigurationValueChange(this.confPageSessionService.activeConfigurationId, this.confPageSessionService.confSessionId, this.decoration.visualObjectId, (value: ConfDataSelectorValue) => {
      this.updateValue(value);
    }).unsubscribeOn(this.unsubscribeSubject);
  }

  public openTable() {

    let tab = this.productDataStore.getEntity(this.decoration.tabId) as Tab;

    this.isSidebar = tab.displayStyle == TabDisplayStyle.Accordion;

    this.showTable = true;

    // ChangeDectectionStrategy.Default is used in this component because this component would be loaded on demand when user clicks on
    // dataselector icon. It won't cause any big performance penalty.
    //this.cdr.detectChanges();
    sleep(Delay.MS_0).then(x => {
      this.selectorModelComponent.show();
    });
  }

  public closeTable() {
    this.showTable = false;
  }

  updateValue(confValue: ConfDataSelectorValue): void {
    this.confDataSelectorValue = confValue;

    if (this.hasValue) {
      this.value = this.confDataSelectorValue.value;
    }
    else {
      this.value = "";
      this.placeholder = (this.decoration.placeholder && this.decoration.placeholder.trim() != "") ? this.decoration.placeholder : this.strings.Empty;
    }         
  }

  public get hasValue() {
    return this.confDataSelectorValue != null && this.confDataSelectorValue.value != null;
  }
}