import * as Immutable from "immutable";
import { BaseEntity } from "../../baseEntity";

export class ConfInfo extends BaseEntity {

  protected readonly TEXT = "text";
  protected readonly DESCRIPTION = "description";
  protected readonly COMPACT = "compact";
  protected readonly PRODUCT_ID = "productId";
  protected readonly PARENT_ID = "parentId";
  protected readonly PARENT_TAB_ID = "parentTabId";
  protected readonly ROOT_ORDER = "rootOrder";  
  protected readonly IS_NEW = "isNew";  
  protected readonly ROOT_ID = "rootId";  
  protected readonly ICON_RELATIVE_URL = "iconRelativeUrl";
  protected readonly ALLOWED_CHILD_PRODUCT_IDS = "allowedChildProductIds";
  protected readonly IS_EFFECTIVE_PRODUCT = "isEffectiveProduct";
  protected readonly CHILDREN = "children";
  protected readonly SHOW_CHILDREN_IN_ACCORDION_TABS = "showChildrenInAccordionTabs";
  protected readonly NOT_SET_MANDATORY_PARAMS_COUNT = "notSetMandatoryParamsCount";

  get text(): string { return this.getInternalValue<string>(this.TEXT); }
  setText(text: string): this { return this.setInternalValue(this.TEXT, text); }

  get description(): string { return this.getInternalValue<string>(this.DESCRIPTION); }
  setDescription(description: string): this { return this.setInternalValue(this.DESCRIPTION, description); }

  get compact(): string { return this.getInternalValue<string>(this.COMPACT); }
  setCompact(compact: string): this { return this.setInternalValue(this.COMPACT, compact); }

  get productId(): number { return this.getInternalValue<number>(this.PRODUCT_ID); }
  setProductId(productId: number): this { return this.setInternalValue(this.PRODUCT_ID, productId); }

  get parentId(): number { return this.getInternalValue<number>(this.PARENT_ID); }
  setParentId(parentId: number): this { return this.setInternalValue(this.PARENT_ID, parentId); }

  get parentTabId(): number { return this.getInternalValue<number>(this.PARENT_TAB_ID); }
  setParentTabId(parentTabId: number): this { return this.setInternalValue(this.PARENT_TAB_ID, parentTabId); }

  get rootId(): number { return this.getInternalValue<number>(this.ROOT_ID); }
  setRootId(rootId: number): this { return this.setInternalValue(this.ROOT_ID, rootId); }

  get iconRelativeUrl(): string { return this.getInternalValue<string>(this.ICON_RELATIVE_URL); }
  setIconRelativeUrl(iconRelativeUrl: string): this { return this.setInternalValue(this.ICON_RELATIVE_URL, iconRelativeUrl); }

  get allowedChildProductIds(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.ALLOWED_CHILD_PRODUCT_IDS); }
  setAllowedChildProductIds(allowedChildProductIds: Immutable.List<number>): this { return this.setInternalValue(this.ALLOWED_CHILD_PRODUCT_IDS, allowedChildProductIds); }

  get isEffectiveProduct(): boolean { return this.getInternalValue<boolean>(this.IS_EFFECTIVE_PRODUCT); }
  setIsEffectiveProduct(isEffectiveProduct: boolean): this { return this.setInternalValue(this.IS_EFFECTIVE_PRODUCT, isEffectiveProduct); }
  
  // This property is introduced to take the early decisions about composite configurations. 
  // As product data is not available on client when configuration data is fetched, To avoid extra trips to server, server always
  // calculates this information on server.
  get showChildrenInAccordionTabs(): boolean { return this.getInternalValue<boolean>(this.SHOW_CHILDREN_IN_ACCORDION_TABS); }
  setShowChildrenInAccordionTabs(showChildrenInAccordionTabs: boolean): this { return this.setInternalValue(this.SHOW_CHILDREN_IN_ACCORDION_TABS, showChildrenInAccordionTabs); }

  get rootOrder(): number { return this.getInternalValue<number>(this.ROOT_ORDER); }
  setRootOrder(rootOrder: number): this { return this.setInternalValue(this.ROOT_ORDER, rootOrder); }

  get isNew(): boolean { return this.getInternalValue<boolean>(this.IS_NEW); }
  setIsNew(isNew: boolean): this { return this.setInternalValue(this.IS_NEW, isNew); }

  get children(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.CHILDREN); }
  setChildren(children: Immutable.List<number>): this { return this.setInternalValue(this.CHILDREN, children); }

  get notSetMandatoryParamsCount(): number { return this.getInternalValue<number>(this.NOT_SET_MANDATORY_PARAMS_COUNT); }
  setNotSetMandatoryParamsCount(notSetMandatoryParamsCount: number): this { return this.setInternalValue(this.NOT_SET_MANDATORY_PARAMS_COUNT, notSetMandatoryParamsCount); }

}