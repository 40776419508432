import { Inject, Component, Input, Optional, ViewChild } from "@angular/core";
import * as Immutable from 'immutable';

import { BaseActionBoxComponent } from "../..";
import { ConfiguratorStore } from "../../../configurator/providers";
import { AttachmentsUploadPopupComponent } from "./attachmentsUploadPopupComponent";
import { DeleteAttachmentPopupComponent } from "./deleteAttachmentPopupComponent";
import { NotificationInfo, NotificationType, NotificationService } from "../../../../shared/components";
import { ManagedSubscription } from "../../../../shared/managedSubscription";
import { AttachmentsStore } from "./attachmentsStore";
import { ConfAttachment, AttachmentOperationMessage } from "../../models";

@Component({
  selector: 'attachments-action-box',
  templateUrl: './attachmentsActionBoxComponent.html'
})
export class AttachmentsActionBoxComponent extends BaseActionBoxComponent {

  public canAddFiles = false;
  public canDelete = false;
  public attachments: Array<AttachmentLinkView>;

  protected deleteAttachmentSubscription: ManagedSubscription;
  //protected confChangeSubscription: ManagedSubscription;

  @ViewChild(AttachmentsUploadPopupComponent)
  protected uploadPopup: AttachmentsUploadPopupComponent;

  @ViewChild(DeleteAttachmentPopupComponent)
  protected deleteAttachmentPopup: DeleteAttachmentPopupComponent;

  constructor(
    @Inject(AttachmentsStore) public attachmentsStore: AttachmentsStore,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    public notificationService: NotificationService,
  ) {
    super(confStore);
  }

  ngOnInit() {
    super.ngOnInit();

    this.configuratorStore.onConfigurationChange(this.configurationId, this.confSessionId, conf => {
      this.initialize();
    }).unsubscribeOn(this.unsubscribeSubject);
  }

  public setup(): void {
    this.attachments = [];
    this.canAddFiles = this.conf != null && this.conf.authorization.canAddAttachment;
    this.canDelete = this.conf != null && this.conf.authorization.canDeleteAttachment;

    if (!this.conf || !this.conf.attachments)
      return;

    if (this.conf.authorization.canViewAttachments) {
      this.conf.attachments.forEach(attachment => {
        this.attachments.push({ link: this.attachmentsStore.getAttachmentLink(attachment.longId), attachment: attachment });
      });
    }
  }

  public shouldBeVisible(): boolean {
    return this.conf && this.conf.authorization.canViewAttachments;
  }

  public onUploadClick() {
    this.uploadPopup.show();
  }

  public onDeleteClick(attachment: ConfAttachment) {

    if (this.deleteAttachmentSubscription)
      this.deleteAttachmentSubscription.unsubscribe();

    this.deleteAttachmentSubscription = this.attachmentsStore.onAttachmentOperationMessage({
      next: (messages: Immutable.List<AttachmentOperationMessage>): void => {
        let message = messages.first();
        if (message.name === "Delete") {
          this.notificationService.notify(<NotificationInfo>{
            title: message.success ? this.strings.Success : this.strings.Error,
            message: message.success ? "Attachment was deleted" /* TODO localize */ : message.message,
            type: message.success ? NotificationType.Info : NotificationType.Error,
            selfClose: true
          });
        }
      },
      listenNewEventsOnly: true
    }).unsubscribeOn(this.unsubscribeSubject);

    this.deleteAttachmentPopup.show(attachment, this.configurationId, this.confSessionId);
  }

  unsubscribe() {
    if (this.deleteAttachmentSubscription)
      this.deleteAttachmentSubscription.unsubscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();

    super.ngOnDestroy();
  }
}

export class AttachmentLinkView {
  public attachment: ConfAttachment;
  public link: string;
}