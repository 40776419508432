import { Component, Input, Inject, SimpleChanges, ChangeDetectorRef } from "@angular/core";
import * as Immutable from "immutable";

import { BaseComponent } from "../../shared";
import { QuickSearchItemUIModel } from "./quickSearchUIModel";
import { RouteRedirector } from "../../shared/providers";
import { SearchDataStore } from "../../shared/providers/searchData";
import { SearchSessionData } from "../../shared/state";
import { ConfSearchResultItem, ConfInfo } from "../../shared/models";
import { ConfiguratorStore } from "../../configurator/providers";
import { ManagedSubscription } from "../../../shared/managedSubscription";

@Component({
  selector: 'inline-search-results',
  templateUrl: './inlineSearchResultComponent.html'
})
export class InlineSearchResultComponent extends BaseComponent {

  public searchResultChangeSubscription: ManagedSubscription = null;

  @Input() public quickSearch: QuickSearchItemUIModel;

  constructor(
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    public searchDataStore: SearchDataStore,
    public configuratorStore: ConfiguratorStore
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['quickSearch']) {
      this.listenForChanges();
    }
  }

  listenForChanges() {

    if (!this.quickSearch.resultSessionData)
      return;

    if (this.searchResultChangeSubscription)
      this.searchResultChangeSubscription.unsubscribe();

    // Listen for remove and update of configuration search results
    // TODO hide inline results if search count reaches to 0
    this.searchResultChangeSubscription = this.searchDataStore.listenSearchSessionDataChange(this.quickSearch.resultSessionData.searchSessionId, {
      next: (searchSessionData: SearchSessionData) => {

        this.quickSearch.resultConfInfos = this.retreiveConfInfos(searchSessionData.confSearchResultItems);
      },
      listenNewEventsOnly: true
    });

    this.searchResultChangeSubscription.unsubscribeOn(this.unsubscribeSubject);
  }

  retreiveConfInfos(items: Immutable.List<ConfSearchResultItem>): Immutable.OrderedMap<number, ConfInfo> {
    let confBySessionId: Immutable.OrderedMap<number, ConfInfo> = Immutable.OrderedMap<number, ConfInfo>();
    items.forEach(
      item => {
        confBySessionId = confBySessionId.set(item.confSessionId, this.configuratorStore.getConfInfo(item.configurationId, item.confSessionId))
      });

    return confBySessionId;
  }

  public trackConf(index: number, kvp: {}): any {
    // kvp is an object with the confInfo longId in index 0 and its ConfInfo in index 1
    return kvp[0];
  }

  public showMore() {
    this.routeRedirector.redirectToSearchResult({ confSearchId: this.quickSearch.search.longId });
  }
}