import * as Immutable from "immutable"

import { PropertyMapObject } from "./propertyMapObject";

export abstract class ImmutableObject extends PropertyMapObject {
  
  constructor(propertyMap?: Immutable.Map<string, any>) {
    super(propertyMap ? propertyMap : Immutable.Map<string, any>());    
  }
    
  public setInternalValue(key: string, value: any): this {
    return new (<any>this).constructor(this.propertyMap.set(key, value));
  }
}