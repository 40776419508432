<div *ngIf="isOpened" #regularPopupElement class="modal {{classes}}" id="{{id}}" [class.show]="isOpened" [class.popup-draggable]="draggable" [class.maximize]="calcMaximize" [ngStyle]="contentStyle" tabindex="-1" role="dialog">
  <div class="modal-content">
    <div *ngIf="showTitleBar" class="modal-header max-width">
      <div class="moveable row justify-content-center align-self-center align-middle p-3 m-0"
           (mousedown)="draggable && onMouseDown($event)">

        <h2 class="col modal-title justify-content-center">{{title}}</h2>
        <div *ngIf="showCloseButton" class="col-auto popup-close-button">
          <a href="#" tabindex="-1" (click)="cancelButtonClicked($event)">
            <co-image key="delete" width="24px" height="24px"> </co-image>
          </a>
        </div>
      </div>

      <ng-content select="[card-type=breadcrumb]"></ng-content>
      <div class="modal-content-title w-100" *ngIf="showHeader">
        <ng-content select="[card-type=header]"></ng-content>
      </div>
    </div>
    <div class="modal-body p-0 m-0">
      <ng-content select="[card-type=body]"></ng-content>
    </div>
    <div *ngIf="showFooter">
      <div class="modal-footer">
        <ng-content select="[card-type=footer]"></ng-content>
        <button *ngIf="okButton" class="btn btn-primary {{okButtonClasses}}" (click)="okButtonClicked($event)" type="button">{{okButtonText||defaultOkButtonText}}</button>
        <button *ngIf="cancelButton" class="btn btn-primary mr-2 {{cancelButtonClasses}}" (click)="cancelButtonClicked($event)" type="button">{{cancelButtonText||defaultCancelButtonText}}</button>
      </div>
    </div>
  </div>

  <div *ngIf="!showTitleBar && showCloseButton" class="popup-close-button absolute pr-4 pt-3">
    <a href="#" tabindex="-1" (click)="cancelButtonClicked($event)">
      <co-image key="delete" width="24px" height="24px"> </co-image>
    </a>
  </div>
</div>

<ng-content></ng-content>