import { Pipe, PipeTransform } from '@angular/core';

/*
*
* Returns true if the given value is null, empty, or consists only of white-space characters.
* Usage:
*   value | isNullOrWhiteSpace
*   object?.value | isNullOrWhiteSpace
*/
@Pipe({ name: 'isNullOrWhiteSpace' })
export class IsNullOrWhiteSpacePipe implements PipeTransform {
  transform(value: string) {
    if (typeof value === "string") {
      return value == null || value.trim() == "";
    }
    return false;
  }
}
