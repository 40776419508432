import { Inject, Component, Input, Optional } from "@angular/core";
import * as Immutable from "immutable";
import { BaseActionBoxComponent } from "../..";
import { ConfigurationSessionManager } from "../../../configurator/providers/configurationSessionManager";
import { ConfReport, RequestViews } from "../../models";
import { ConfReportRequest } from "../../models/requests/confReportRequest";

// MOVE ConfiguratorController TO PAGES/SHARED/PROVIDERS
import { ConfDataController } from "../../providers/configurationData";
import { ConfiguratorStore } from "../../../configurator/providers";
import { ModelFactory } from "../../providers";
import { PageStore } from "../../providers/page/pageStore";
import { HttpClient } from "@angular/common/http";
import { ManagedSubscription } from "../../../../shared";

@Component({
  selector: 'reports-action-box',
  templateUrl: './reportsActionBoxComponent.html'
})
export class ReportsActionBoxComponent extends BaseActionBoxComponent {

  public reportValues: Array<ReportValueView>;

  protected subscription: ManagedSubscription;

  constructor(
    @Inject(ConfDataController) public configuratorController: ConfDataController,
    @Inject(ConfiguratorStore) public configuratorStore: ConfiguratorStore,
    @Inject(PageStore) public pageStore: PageStore,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    @Inject(HttpClient) public httpClient: HttpClient  ) {
    super(configuratorStore);
  }

  public shouldBeVisible(): boolean {
    return this.reportValues && this.reportValues.length > 0 && this.conf != null && this.conf.authorization.canViewReports;
  }
  
  public setup(): void {

    if (!this.conf)
      return;

    if (this.subscription)
      this.subscription.unsubscribe();

    this.subscription = this.configuratorStore.onConfReportsChange(this.configurationId, this.confSessionId, (reports) => {

      if (!reports)         
        return;
           
      this.reportValues = [];
      reports.forEach(report => {
        this.reportValues.push({ title: report.title, icon: report.icon, link: this.getReportLink(report.longId) });
      });

      this.visible = this.shouldBeVisible();

    });
  
  }

  public getReportLink(reportId: number): string {
    let model: ConfReportRequest = this.modelFactory.createAny(ConfReportRequest.name) as any;

    model.client = this.pageStore.getActiveClientType();
    model.reportId = reportId;
    model.configurationId = this.configurationId;
    model.confSessionId = this.confSessionId;
    return this.configuratorController.getConfigurationReportUrl(model);
  }

  ngOnDestroy() {

    if (this.subscription)
      this.subscription.unsubscribe();

    super.ngOnDestroy();
  }

}

export class ReportValueView {
  public title: string;
  public icon: string;
  public link: string;
}