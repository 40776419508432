import * as Immutable from "immutable"
import { AbstractOperationMessage } from "./abstractOperationMessage"
import { ConfAutoCompleteItem } from "../../entities/confAutoCompleteItem";

export class ConfAutoCompleteMessage extends AbstractOperationMessage {

  protected readonly AUTO_COMPLETE_ITEMS = "autoCompleteItems";

  get autoCompleteItems(): Immutable.List<ConfAutoCompleteItem> { return this.getInternalValue<Immutable.List<ConfAutoCompleteItem>>(this.AUTO_COMPLETE_ITEMS); }
  setAutoCompleteItems(autoCompleteItems: Immutable.List<ConfAutoCompleteItem>): this { return this.setInternalValue(this.AUTO_COMPLETE_ITEMS, autoCompleteItems); }

}