import * as Immutable from "immutable"
import { BaseEntity } from "../../baseEntity"
import { AbstractConfigurationMessage } from "./messages"
import { ImmutableObject } from "../../../../shared/immutableObject";
import { ConfInfoResult } from "./confInfoResult";

export class ConfDataResponse extends ImmutableObject
{
  protected readonly CONF_SESSION_ID = "confSessionId";
  protected readonly IS_CLOSED = "isClosed";
  protected readonly HAS_UNSAVED_CHANGES = "hasUnsavedChanges";
  protected readonly ROOT_CONFIGURATION_ID = "rootConfId";
  protected readonly REFERRAL_LINK = "referralLink";
  protected readonly CONFIGURATION_IDS = "configurationIds";
  protected readonly CONF_INFOS = "confInfos";
  protected readonly MESSAGES = "messages";
  protected readonly ENTITIES = "entities";

  get confSessionId(): number { return this.getInternalValue<number>(this.CONF_SESSION_ID); }
  setConfSessionId(confSessionId: number): this { return this.setInternalValue(this.CONF_SESSION_ID, confSessionId); }

  get isClosed(): boolean { return this.getInternalValue<boolean>(this.IS_CLOSED); }
  setIsClosed(isClosed: boolean): this { return this.setInternalValue(this.IS_CLOSED, isClosed); }

  get hasUnsavedChanges(): boolean { return this.getInternalValue<boolean>(this.HAS_UNSAVED_CHANGES); }
  setHasUnsavedChanges(hasUnsavedChanges: boolean): this { return this.setInternalValue(this.HAS_UNSAVED_CHANGES, hasUnsavedChanges); }

  get rootConfId(): number { return this.getInternalValue<number>(this.ROOT_CONFIGURATION_ID); }
  setRootConfId(rootConfId: number): this { return this.setInternalValue(this.ROOT_CONFIGURATION_ID, rootConfId); }

  get referralLink(): string { return this.getInternalValue<string>(this.REFERRAL_LINK); }
  setReferralLink(referralLink: string): this { return this.setInternalValue(this.REFERRAL_LINK, referralLink); }
  
  get configurationIds(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.CONFIGURATION_IDS); }
  setConfigurationIds(configurationIds: Immutable.List<number>): this { return this.setInternalValue(this.CONFIGURATION_IDS, configurationIds); }

  get confInfos(): ConfInfoResult { return this.getInternalValue<ConfInfoResult>(this.CONF_INFOS); }
  setConfInfos(confInfoResult: ConfInfoResult): this { return this.setInternalValue(this.CONF_INFOS, confInfoResult); }
  
  get messages(): Immutable.List<AbstractConfigurationMessage> { return this.getInternalValue<Immutable.List<AbstractConfigurationMessage>>(this.MESSAGES); }
  setMessages(messages: Immutable.List<AbstractConfigurationMessage>): this { return this.setInternalValue(this.MESSAGES, messages); }

  get entities(): Immutable.Map<number, Immutable.Map<number, BaseEntity>> { return this.getInternalValue<Immutable.Map<number, Immutable.Map<number, BaseEntity>>>(this.ENTITIES); }
  setEntities(entities: Immutable.Map<number, Immutable.Map<number, BaseEntity>>): this { return this.setInternalValue(this.ENTITIES, entities); }  
}