import { Injectable } from "@angular/core";
import { Reducer, ReducersMapObject, AnyAction } from "redux";

import { ImmutableObject } from "../immutableObject";

@Injectable()
export class ReducersCombiner {

  combine<T extends ImmutableObject>(reducersMap: ReducersMapObject): Reducer<T> {

    return (state: T, action: AnyAction): T => {
         
      Object.keys(reducersMap).forEach(key => {
                
        let reducer = reducersMap[key];
        let oldState = state[key];

        let newState = reducer(oldState, action);
        if (newState == undefined)
          console.error(`Reducer with key(${key}) returned undefined for action(${action.type})`);

        if (newState !== oldState)
          state = this.setStateValue(state, key, newState);

      });
            
      return state;
    }
  }

  public setStateValue<T extends ImmutableObject>(state: T, propertyName: string, value: any): T {
    return state["setInternalValue"](propertyName, value);
  }
}