import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
import { ImageModule } from "../image";
import { InputViewModel } from "../shared";
import { TextBoxModule } from "../textBox";
import { DropdownModule } from "../dropdown";
import { HelpModule } from "../help";
import { IconModule } from "../icon";
import { TextboxRangeComponent } from "./textboxRangeComponent";


export * from "./textboxRangeComponent";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ImageModule,
    HelpModule,
    IconModule,    
    TextBoxModule,
    DropdownModule
  ],
  declarations: [
    TextboxRangeComponent    
  ],
  exports: [
    TextboxRangeComponent    
  ]
})
export class TextBoxRangeModule {

}