import { Injectable, Inject } from '@angular/core';
import * as Immutable from "immutable";

import { AppStore, HttpAction, ActionStatus, AppAction, UIAction } from "../../state";
import { GlobalDataActions } from "../../state/globalData";
import { WebSettingsResponse, GlobalDataResponse } from "../../models/responses";
import { GlobalDataController } from "./globalDataController";
import { BaseActionCreator } from "../../baseActionCreator";
import { GlobalSettings, ApiResponse } from "../../models";
import { KeyValue } from "../../models/responses";
import { GlobalDataRequest } from "../../models/requests/globalDataRequest";

@Injectable()
export class GlobalDataActionCreator extends BaseActionCreator {

  constructor(
    @Inject(AppStore) public appStore: AppStore,
    @Inject(GlobalDataController) public globalDataController: GlobalDataController,
  ) {
    super();
  }

  dispatchFetchEntities = (requestModel: GlobalDataRequest) => this.appStore.dispatch(this.fetchEntities(requestModel)) as HttpAction<ApiResponse>;
  fetchEntities(requestModel: GlobalDataRequest): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(

      GlobalDataActions.FETCH_GLOBAL_ENTITIES,

      (dispatch, state, callback) => {
        return this.globalDataController.getGlobalData(requestModel).subscribe((globalDataResult) => {

          dispatch({ type: GlobalDataActions.GLOBAL_ENTITIES_LOADED, payload: globalDataResult });

          callback(new ActionStatus(true, globalDataResult));
        })
      }
    );
  }

  dispatchRequestGlobalSettings = (requestModel: GlobalDataRequest) => this.appStore.dispatch(this.requestGlobalSettings(requestModel)) as HttpAction<ApiResponse>;
  requestGlobalSettings(requestModel: GlobalDataRequest): HttpAction<ApiResponse> {
    return this.createHttpAction<ApiResponse>(

      GlobalDataActions.REQUEST_GLOBAL_SETTINGS,

      (dispatch, state, callback) => {

        return this.globalDataController.getGlobalData(requestModel).subscribe((globalDataResponse) => {

          dispatch({ type: GlobalDataActions.GLOBAL_SETTINGS_LOADED, payload: globalDataResponse });

          // Update complete request status
          callback(new ActionStatus(true, globalDataResponse));
        })
      });
  }

  dispatchRequestLocalizeData = (requestModel: GlobalDataRequest) => this.appStore.dispatch(this.requestLocalizeData(requestModel)) as HttpAction<ApiResponse>;
  requestLocalizeData(requestModel: GlobalDataRequest): HttpAction<ApiResponse> {
    return this.createHttpAction<ApiResponse>(

      GlobalDataActions.REQUEST_LOCALIZE_DATA,

      (dispatch, state, callback) => {

        return this.globalDataController.getGlobalData(requestModel).subscribe((globalDataResponse) => {

          dispatch({ type: GlobalDataActions.LOCALIZE_DATA_LOADED, payload: globalDataResponse });

          // Update complete request status
          callback(new ActionStatus(true, globalDataResponse));
        })
      });
  }

  dispatchRequestWebSettings = () => this.appStore.dispatch(this.requestWebSettings()) as HttpAction<ApiResponse>;
  requestWebSettings(): HttpAction<ApiResponse> {
    return this.createHttpAction<ApiResponse>(

      GlobalDataActions.REQUEST_WEB_SETTINGS,

      (dispatch, state, callback) => {

        return this.globalDataController.getWebSettings().subscribe((webSettingsResponse) => {

          dispatch({ type: GlobalDataActions.WEB_SETTINGS_LOADED, payload: webSettingsResponse });

          // Update complete request status
          callback(new ActionStatus(true, webSettingsResponse));
        })
      });
  }

  dispatchRequestUISettings = () => this.appStore.dispatch(this.requestUISettings()) as HttpAction<ApiResponse>;
  requestUISettings(): HttpAction<ApiResponse> {
    return this.createHttpAction<ApiResponse>(

      GlobalDataActions.REQUEST_UI_SETTINGS,

      (dispatch, state, callback) => {

        return this.globalDataController.getUISettings().subscribe((uiSettingsResponse) => {

          dispatch({ type: GlobalDataActions.UI_SETTINGS_LOADED, payload: uiSettingsResponse });

          // Update complete request status
          callback(new ActionStatus(true, uiSettingsResponse));
        })
      });
  }
}