<div *ngIf="item" class="multi-select-item {{item.state}} w-100" [class.no-select]="!item.selectable" (click)="item.selectable && onItemClick(item)">
  <div class="row">
    <div class="col-auto px-0 text-center item-icon" *ngIf="showItemIcons">
      <co-image *ngIf="item.icon" [width]="'16px'" [height]="'16px'" [imageSet]="iconImageSet" type="svg" key="{{item.icon}}"></co-image>
    </div>
    <div class="col pl-0">
      {{item.title}}
    </div>
  </div>
</div>

<ng-container *ngIf="item && item.children && item.children.length > 0">

  <multi-select-item *ngFor="let child of item.children"
                     [item]="child"                     
                     class="multi-select-item {{child.state}} row child-indent w-100"
                     [showItemIcons]="showItemIcons"
                     [iconImageSet]="iconImageSet"
                     [checkedIcon]="checkedIcon"
                     [uncheckedIcon]="uncheckedIcon"
                     role="button">
  </multi-select-item>

</ng-container>
