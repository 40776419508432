import { Component, Inject, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core";
import * as Immutable from "immutable";
import { ConfPropertyValue, ConfUIItem, PropertyDecoration, PropertyDecorationItem } from "../../../shared/models";
import { ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { VisualObjectVisibilityService } from "../../shared";
import { DecorationComponent } from "../../shared/decorationComponent";
import { ConfPropertyModel } from "./confPropertyModel";
import { VisualObjectHelper } from "../../parameters/shared";


@Component({
  selector: 'property-decoration',
  templateUrl: './propertyDecorationComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyDecorationComponent extends DecorationComponent<PropertyDecoration> {

  protected propertyModelById: Immutable.Map<string, ConfPropertyModel> = Immutable.Map<string, ConfPropertyModel>();
  public propertyModels: Immutable.List<ConfPropertyModel> = Immutable.List<ConfPropertyModel>();
  public enableParamStyle: boolean = false;

  constructor(    
    public confStore: ConfiguratorStore,
    public confPageSessionService: ConfPageSessionService,
    public visualObjectVisibilityService: VisualObjectVisibilityService,    
    public cd: ChangeDetectorRef,
    public visualObjectHelper: VisualObjectHelper
  ) {
    super(confStore, confPageSessionService, visualObjectHelper);
  }

  ngOnInit() {
    
    this.setPropertyModels();

    this.enableParamStyle = !(this.decoration.title && this.decoration.title.length > 0);

    super.ngOnInit();
  }

  setValue(itemValues: Map<number, any>): void {
    this.setPropertyModels();
    this.cd.markForCheck();
  }

  public setPropertyModels(): void {

    // Clear the old models.
    this.propertyModelById = this.propertyModelById.clear();
    this.propertyModels = this.propertyModels.clear();

    // Define the models.
    this.definePropertyModels();

    // Calculate line break for each item.
    this.calculateLineBreak();

    let confUIItem = this.visualObjectVisibilityService.findConfUIItem(this.configuration, this.decoration.longId);
    // Make the a list so that we could loop through the property models in template.
    this.decoration.items.forEach(item => {

      if (this.isHidden(item, confUIItem))
        return;

      this.propertyModels = this.propertyModels.push(this.propertyModelById.get(item.propertyName));

    });
  }

  protected definePropertyModels(): void {

    let confUIItem = this.visualObjectVisibilityService.findConfUIItem(this.configuration, this.decoration.longId);
    // Make all items by default floated and break the floating if they are meant to be in a separate row.
    this.decoration.items.forEach((item: PropertyDecorationItem, index) => {

      if (this.isHidden(item, confUIItem))
        return;

      let model: ConfPropertyModel = new ConfPropertyModel();
      model = model.setPropertyDecorationItem(item);
      model = model.setLineBreak(true);
      model = model.setFloatingPosition(item.floatingPosition);
      model = model.setAutoWidth(item.width == 0 || item.width == undefined); // Final auto width decision would be made after calculating the line break.
      model = model.setConfPropertyValue(this.confStore.getConfPropertyValue(this.configurationId, this.confPageSessionService.confSessionId, item.propertyName));
      let colStyle: string = model.autoWidth ? 'col' : 'col-auto';
      let styles: string = `conf-property-item-wrapper ${colStyle} pr-0`;

      model = model.setStyles(styles); // Default styles.

      if (item.width > 0) 

        model = model.setMaxWidth(`${item.width + 40}px`); 
      else 
        model = model.setMaxWidth(model.autoWidth ? '100%' : `${item.width}px`);
 
      this.propertyModelById = this.propertyModelById.set(item.propertyName, model);

    });

  }

  protected calculateLineBreak(): void {

    let confUIItem = this.visualObjectVisibilityService.findConfUIItem(this.configuration, this.decoration.longId);
    this.decoration.items.forEach((item: PropertyDecorationItem, index) => {

      if (this.isHidden(item, confUIItem))
        return;

      // Grab the corresponding model
      let currentModel = this.propertyModelById.get(item.propertyName);

      if (currentModel.floatingPosition && index > 0 && item.width > 0) {

        // To set the floating, first remove the line-break from previous item.
        let prevItem: PropertyDecorationItem = this.decoration.items.get(index - 1);
        let prevModel = this.propertyModelById.get(prevItem.propertyName);

        // Fix exception if previous item(s) are hidden
        for (let j = index - 2; j > 0 && prevModel == null; j--) {
          prevItem = this.decoration.items.get(j);
          prevModel = this.propertyModelById.get(prevItem.propertyName);
        }

        if (prevModel == null)
          return;

        prevModel = prevModel.setLineBreak(false);

        // Update the dictionary
        this.propertyModelById = this.propertyModelById.set(prevItem.propertyName, prevModel);

        // Set the line break for current model which was set to be floated.
        // Note! Line-break is applied right after the model if it is set 'True'
        currentModel = currentModel.setLineBreak(true);
        this.propertyModelById = this.propertyModelById.set(item.propertyName, currentModel);
      } 

      

    });
  }
 
  protected isHidden(item: PropertyDecorationItem, confUIItem: ConfUIItem): boolean {

    if (!confUIItem)
      return true;

    if (!confUIItem.items.find(x => x.id == item.longId))
      return true;

    let propertyValue: ConfPropertyValue = this.confStore.getConfPropertyValue(this.configurationId, this.confPageSessionService.confSessionId, item.propertyName);
    return propertyValue == null;
  }

  public identify(model: ConfPropertyModel): PropertyDecorationItem {
    return model.propertyDecorationItem;
  }
}