import { Conf, ApiResponse } from "../../shared/models";
import { RouteNames } from "../../shared/providers";

export class ConfDataResolveResponse {

  confSessionId: number;

  conf: Conf;

  // Represents the target/next page view.
  view: RouteNames

  confData: ApiResponse;

  // Products 
  productData: Array<ApiResponse> = [];

  /*
   * Reloads the page again with corrected url parameters.
   * e.g If child configuration is requested as a main active configuration then system redirects to root configuration.
   */
  invalidateUrl: boolean = false;

  isFirstLoad: boolean = false;
}