
export class PriceReportRowView {

  cells: PriceReportCellView[] = [];

  // Css classes
  classes: string;

  id: number;

  tag?: any;

  // If any cell contains editable data
  allowEdit: boolean = false;

  selected: boolean = false;

}

export class PriceReportCellView {

  contents: string;

  size: number;

  classes: string;

  level: number;

  isEditorCell?: boolean = true;

  isEditorReadOnly?: boolean = false;
}