import { Inject, Component, ViewChild, Input, Output, EventEmitter, ElementRef, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core";
import * as Immutable from 'immutable';
import { ConfInfo, ParamInfo } from "../../../shared/models";
import { RegularPopupComponent, PopupInfo } from "../../../../shared/components/popup";
import { PageActionCreator, PageStore } from "../../../shared/providers/page";
import { RouteRedirector, RouteNames } from "../../../shared/providers";
import { InputViewModel, ValueChangeEventArgs } from "../../../../shared/components/shared";
import { MandatoryParamsMessage } from "../../../shared/models/responses/messages";
import { ConfigurationSessionManager, ConfPageSessionService, ConfiguratorStore, PopupIdentifiers, ConfRouteParams } from "../../providers";
import { AccordionComponent, PopupService } from "../../../../shared/components";
import { ParameterMandatoryService } from "../../parameters/shared/parameterMandatoryService";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import { EmitterService } from "../../shared";
import { GlobalDataStore } from "../../../shared/providers/globalData";
import { AccountDataStore } from "../../../shared/providers/accountData";
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'mandatory-popup',
  templateUrl: './mandatoryPopupComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MandatoryPopupComponent extends AbstractPopupComponent {
  
  public values: Array<MandatoryConfigurationModel>;
  public highlightView: InputViewModel;

  @ViewChild(AccordionComponent)
  accordionComponent: AccordionComponent;

  public hasValue: boolean = false;

  @Output()
  onClosed = new EventEmitter();

  constructor(
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(PageActionCreator) public pageActionCreator: PageActionCreator,
    @Inject(ParameterMandatoryService) public parameterMandatoryService: ParameterMandatoryService,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    @Inject(PopupService) public popupService: PopupService,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(AccountDataStore) public accountDataStore: AccountDataStore,
    @Inject(PageStore) public pageStore: PageStore,
    public cdr: ChangeDetectorRef,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) {
    super(cdr, popupService);

    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {


      }

      else if (event instanceof NavigationEnd) {

        if (this.isValidRouteEnded(event)) {

        }

      }
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });

  }

  ngOnInit() {
    this.highlightView = new InputViewModel(this.strings.HighlightMandatoryParameters);
    this.highlightView.value = this.accountDataStore.getUser().settings.highlightMandatoryParameters || this.globalDataStore.getGlobalData().globalSettings.highlightMandatoryParameters;
    this.id = PopupIdentifiers.Mandatory;

    super.ngOnInit();
  }

  public open(tag: any) {    
    this.setup(tag);
    this.showInternal();
    this.onHighlightParametersChanged(null);
  }

  setup(messages: Immutable.List<MandatoryParamsMessage>): void {

    this.values = [];

    this.hasValue = false;

    // Dispatching includeMandatoryInfo
    if (!this.storeSession.includeMandatoryInfo)
      this.pageActionCreator.dispatchUpdateIncludeMandatoryInfo(this.storeSession.pageId, true);

    messages.forEach(message => {
      // Only add configurations if it contains any mandatory params.
      if (message.mandatoryParams.size > 0) {
        let mandatoryModel = new MandatoryConfigurationModel();
        mandatoryModel.conf = this.confStore.getConfInfo(message.configurationId, this.storeSession.confSessionId);
        mandatoryModel.isActiveConf = this.storeSession.activeConfigurationId === message.configurationId;

        message.mandatoryParams.forEach((param: ParamInfo) => {
          let paramModel = new InputViewModel(param.title);
          paramModel.icon = param.parameterType.toLowerCase();
          paramModel.iconHeight = paramModel.iconWidth = "24px";
          paramModel.value = param;
          mandatoryModel.parameters.push(paramModel);
        });
        this.values.push(mandatoryModel);
        this.hasValue = true;
      }
    });

    this.cdr.markForCheck();
  }

  // Is this method needed?
  public trackValue(index: number, model: MandatoryConfigurationModel): any {
    return model.conf.longId;
  }

  public trackParam(index: number, param: InputViewModel): any {
    return (<ParamInfo>param.value).visualObjectInTabId;
  }

  public get isHighlighting(): boolean {
    return typeof this.highlightView.value === "boolean" ? this.highlightView.value : this.highlightView.value === "true";
  }

  public onHighlightParametersChanged($event: ValueChangeEventArgs) {
    this.parameterMandatoryService.sendHighlightMessage(this.isHighlighting);
  }

  public onPopupClosed() {
    this.values = null;

    // Dispatching includeMandatoryInfo unless if we're highlighting
    if (!this.isHighlighting && this.storeSession.includeMandatoryInfo) {
      this.pageActionCreator.dispatchUpdateIncludeMandatoryInfo(this.storeSession.pageId, false);
      this.pageActionCreator.dispatchUpdateIsNextStateIncluded(this.storeSession.pageId, false);
    }

    this.storeSession.isMandatoryOpened = false;
    super.onPopupClosed();
    this.onClosed.emit();
  }

  //
  public onAccordionOpen(configurationId: number) {
    // Accordion expand will route to the clicked configuration 
    // changing configuration causes popper to get wrong position
    this.regularPopup.detachPopper();
    
    if (this.storeSession.showChildrenInAccordionTabs) {
      this.emitterService.send<any>(PopupIdentifiers.AccordionCompositeShowDetail, { confId: configurationId, reload: true });
      return;
    }

    let params = <ConfRouteParams>{ id: configurationId, confSessionId: this.storeSession.confSessionId };
    if (this.pageStore.activeRouteName === RouteNames.Summary)
      this.routeRedirector.redirectToSummary(params);
    else
      this.routeRedirector.redirectToEditor(params);
  }

  public sendHighlightId(id: number) {
    if (this.parameterMandatoryService.isHighlighting)
      return;

    this.emitterService.send<number>(PopupIdentifiers.Mandatory, id);
  }

  public onPopupOpen() {
    this.storeSession.isMandatoryOpened = true;
  }

  public onAccordionToggle($event) {
    // TODO, I want it to scroll to the selected conf

    //let opened = $event.isOpened;
    //let clickedGroup = $event.clickedGroup;

    //// Remove margins for all groups
    //this.accordionComponent.groups.forEach(x => {
    //  x.accordionGroupElement.nativeElement.style.marginBottom = "";
    //});

    //if (opened) {
    //  // Perform in timeout so it will be opened first.
    //  setTimeout(() => {
    //    let scrollTop = this.body.nativeElement.parentElement.scrollTop;
    //    let clickedOffset = clickedGroup.nativeElement.offsetTop;
    //    let leftToScroll = this.body.nativeElement.parentElement.scrollHeight - this.body.nativeElement.parentElement.offsetHeight;

    //    // We might need extra room to scroll to top -> add extra margin
    //    if (clickedOffset > leftToScroll) {
    //      clickedGroup.nativeElement.style.marginBottom = (clickedOffset - leftToScroll) + "px";
    //    }
    //    this.scrollToTop(clickedOffset);
    //  }, 0);
    //}
  }

  //public get leftToScroll() {
  //  return this.body.nativeElement.parentElement.scrollHeight - this.body.nativeElement.parentElement.offsetHeight;
  //}

  //public scrollToTop(scrollTo: number) {
  //  let scrollStep = 5;

  //  let scrollInterval = setInterval(() => {
  //    let canScrollMore = this.body.nativeElement.parentElement.scrollTop < this.leftToScroll;
  //    if (canScrollMore && (scrollTo > this.body.nativeElement.parentElement.scrollTop))
  //      this.body.nativeElement.parentElement.scrollBy(0, scrollStep);
  //    else
  //      clearInterval(scrollInterval);
  //  }, 4);
  //}

  isValidRouteEnded(event: NavigationEnd) {
    let urlParts = event.url.split(';');
    let routeName = urlParts.pop().toLowerCase();
    return routeName.indexOf(RouteNames.Editor) || routeName.indexOf(RouteNames.Summary);      
  }

}

export class MandatoryConfigurationModel {
  conf: ConfInfo;
  parameters: Array<InputViewModel> = [];
  isActiveConf = false;
}