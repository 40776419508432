<div class="col">
  <div class="criterias row">
    <dropdown class="col selector" 
              [view]="selectorGroupView"
              [itemsView]="selectorAttributeViews"
              (onValueChange)="onCriteriaChanged($event)">
    </dropdown>
    <div class="col-auto pl-0  pr-3" style="width:40px;"></div>
  </div>

  <div class="row">
    <!-- Switch case here for input type. -->
    <ng-container [ngSwitch]="true">
      <div class="col query" [attr.input-control]="controlType" [attr.selector-attribute]="criteria.selectedAttribute?.title">

        <search-text-input *ngSwitchCase="controlType === 'textbox' || controlType === 'none'"
                           #input
                           [criteria]="criteria"
                           [width]="width"
                           (onValueChange)="onValueChange.emit($event)"></search-text-input>

        <multi-select-input *ngSwitchCase="controlType == 'multiselect'"
                            #input
                            [criteria]="criteria"
                            [width]="width"
                            (onValueChange)="onValueChange.emit($event)"></multi-select-input>

        <search-dropdown-input *ngSwitchCase="controlType === 'dropdown'"
                               #input
                               [criteria]="criteria"
                               [width]="width"
                               (onValueChange)="onValueChange.emit($event)"></search-dropdown-input>

        <search-checkbox-input *ngSwitchCase="controlType === 'checkbox'"
                               #input
                               [criteria]="criteria"
                               [width]="width"
                               (onValueChange)="onValueChange.emit($event)"></search-checkbox-input>

        <search-calendar-input *ngSwitchCase="controlType === 'daterange'"
                               #input
                               [criteria]="criteria"
                               [width]="width"
                               (onValueChange)="onValueChange.emit($event)"></search-calendar-input>

        <search-range-textbox *ngSwitchCase="controlType === 'rangetextbox'"
                              #input
                              [criteria]="criteria"
                              [width]="width"
                              (onValueChange)="onValueChange.emit($event)"></search-range-textbox>

      </div>
    </ng-container>
    <div class="col-auto pl-0  pr-3 text-center" style="width:40px">
        <co-image *ngIf="showAdd" key="add" width="24px" height="24px" imageSet="primary-darker" role="button" (click)="onAdd.emit(criteria)"></co-image>
        <co-image *ngIf="showRemove" key="delete" width="20px" height="20px" imageSet="red" role="button" (click)="onRemove.emit(criteria)"></co-image>
    </div>
  </div>

</div>