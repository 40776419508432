import { Component, Input, SimpleChanges, OnInit, AfterViewInit, AfterContentInit, Inject, ChangeDetectorRef } from "@angular/core";
import { BaseInputComponent, InputViewModel, ValueChangeEventArgs, SizeUtility } from "../shared";

@Component({
  selector: "select-option",
  templateUrl: './selectComponent.html',
})
export class SelectComponent extends BaseInputComponent implements OnInit, AfterViewInit, AfterContentInit {
  
  @Input()
  public itemsView: InputViewModel[] = [];

  @Input()
  public displayAsRadio: boolean = true;

  @Input()
  public displayStyle: string;

  // Orientation only works for radio buttons.
  @Input()
  public orientation: string = 'vertical';

  @Input()
  public selectedValueId: string;

  public helpImageStyle: CSSStyleDeclaration;  

  public helpImageExtraClasses: string;  

  public controlStyle: CSSStyleDeclaration;  

  public selectedValueModel: InputViewModel;

  /**
    True to auto add a empty value at the top of the items.
  */
  @Input()
  public showEmptyValue: boolean = false;

  constructor(@Inject(ChangeDetectorRef) public cdr: ChangeDetectorRef,) {
    super();

  }

  ngOnInit() {
    this.setStyle();

  }

  ngAfterViewInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['selectedValueId']) {

      if (this.selectedValueId) {

        this.selectedValueModel = { ...this.itemsView.filter(x => x.id == this.selectedValueId)[0] };
        this.helpImageExtraClasses = 'has-value';
      }
      else {

        this.selectedValueModel = null;
        this.helpImageExtraClasses = 'has-no-value';

      }


    }

  }

  onValueChanged(event: ValueChangeEventArgs) {

    this.selectedValueModel = event.actionView;

    this.notifyValueChange(event.actionView, event.value, event.element);    

  }

  private setStyle(): void {

    //this.containerStyle = <CSSStyleDeclaration>{ width: '100%', maxWidth: '100%', minWidth: this.view.minWidth };

    if (this.view.helpImagePosition == "right") {

      // Total width of the control       
      this.controlStyle = <CSSStyleDeclaration>{ width: '100%', maxWidth: this.view.widthIfSelectedImage };
      this.helpImageStyle = <CSSStyleDeclaration>{};

    }
    else if (this.view.helpImagePosition == "above") {

      // Total width of the control       
      this.controlStyle = <CSSStyleDeclaration>{ width: '100%', maxWidth: '100%' };
      this.helpImageStyle = <CSSStyleDeclaration>{ width: '100%', height: this.view.helpImageHeight  };

    }
    else if (this.view.helpImagePosition == "inline") {

      // Total width of the control       
      this.controlStyle = null;
      this.helpImageStyle = <CSSStyleDeclaration>{ width: this.view.helpImageWidth, height: this.view.helpImageHeight };

    }

  }

}