
<div class="input-container text-field control-border-b has-icon date-range-field" #matFormField (click)="openCalendar($event)" [style.width]="width">
  <div class="input-icon align-middle justify-content-center ">
    <co-image key="datetime" width="24px" height="24px" [imageSet]="imageSet"></co-image>
  </div>
  <div class="input-title align-middle row mx-0 w-100">
    <span class="uneditable-input col px-0" name="daterange">{{displayText}}</span>
    <span *ngIf="showClearOption" class="clear-date col-auto" (click)="clearDate($event)" role="button">
      <co-image key="delete" imageSet="red" width="16px" height="16px"></co-image>
    </span>
  </div>
</div>
