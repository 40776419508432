import { Component, EventEmitter, Input, Output, SimpleChange, SimpleChanges } from "@angular/core";
import { FilterType, InputViewModel, ValueChangeEventArgs } from "../../../../shared/components";
import { ImageSets } from "../../../../shared/utils";
import { EnumEx } from "../../../../shared/utils/extensions";
import { BaseComponent } from "../../../shared";
import { FilterItemViewModel } from "./filterItemViewModel";
import { SearchInfo } from "../../../shared/models";

@Component({
  selector: 'filter',
  templateUrl: './filterComponent.html',
})
export class FilterComponent extends BaseComponent {
  @Input()
  public filterItems: Array<FilterItemViewModel> = [];
  @Input()
  public isInline = false;

  @Input()
  public searchInfo: SearchInfo;

  @Output()
  public filterItemChanged = new EventEmitter();
  @Output()
  public filterTextChanged = new EventEmitter();
  @Output()
  public onFilter = new EventEmitter();
  @Output()
  public onCancel = new EventEmitter();

  public filterItemsView: FilterItemViewModel;
  public value1View: InputViewModel;
  public value2View: InputViewModel;

  public isFiltered: boolean = false;

  public filterType = FilterType.SingleValue;
  public FilterType: any = FilterType;

  public filterTypes: Array<InputViewModel> = [];
  public filterTypesView: InputViewModel;

  public boolValues: Array<InputViewModel> = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["searchInfo"] && !changes["searchInfo"].firstChange) {
      //set view model values... else reset???
      this.readSearchInfo();
    }
  }

  readSearchInfo(): void {
    if (this.searchInfo && this.searchInfo.fieldId) {
      let item = this.filterItems.find(x => +x.id == this.searchInfo.fieldId);

      if (!item)
        return;

      this.filterItemsView.value = item.id;
      this.filterItemsView.formattedValue = item.title;
      this.filterItemsView.type = item.type;

      // handle bools, intervals etc...
      if (this.filterItemsView.type == "Int" || this.filterItemsView.type == "Double") {
        let type = this.filterTypes.find(x => +x.id == this.searchInfo.filterType);
        if (type != null) {
          this.filterTypesView.value = type.id;
          this.filterTypesView.formattedValue = type.title;
        }
      }

      if (this.filterItemsView.type == "Bool") {
        let index = this.searchInfo.value1 == null ? 1 : this.searchInfo.value1 == 'false' || this.searchInfo.value1 == '0' ? 2 : 0;
        this.value1View.value = this.boolValues[index].name;
        this.value1View.id = this.boolValues[index].id;
      }
      else {
        this.value1View.value = this.searchInfo.value1;
        this.value2View.value = this.searchInfo.value2;
      }

      this.setFilterIcon();
    }
    else {
      if (this.filterItems.length > 0) {
        this.filterItemsView.value = this.filterItems[0].id;
        this.filterItemsView.formattedValue = this.filterItems[0].title;

        this.value1View.value = null;
        this.value2View.value = null;

        let evt = new ValueChangeEventArgs()
        evt.actionView = this.filterItems[0];
        this.onFilterItemChanged(evt);
      }
    }
  }

  ngOnInit() {
    this.filterItemsView = new FilterItemViewModel(this.strings.Filter);
    let viewWidth = this.isInline ? this.uiSettings.configurator.decoration.dataSelector.filterInlineWidth : this.uiSettings.configurator.decoration.dataSelector.filterPopoverWidth;
    this.filterItemsView.width = viewWidth;

    this.value1View = new InputViewModel(this.strings.Value);
    this.value1View.width = viewWidth;
    this.value1View.placeholder = this.strings.Value;

    this.value2View = new InputViewModel(this.strings.Value2);
    this.value2View.width = viewWidth;
    this.value2View.placeholder = this.strings.Value2;

    this.filterTypesView = new InputViewModel();
    this.filterTypesView.width = viewWidth;

    for (let kvp of EnumEx.getNamesAndValues(FilterType)) {
      let view = new InputViewModel(this.strings.getString(kvp.name));
      view.id = kvp.value.toString();
      this.filterTypes.push(view);
    }

    let boolPairs = [{ name: "True", value: "true" }, { name: "Indeterminate", value: "null" }, { name: "False", value: "false" }];
    for (let boolPair of boolPairs) {
      let view = new InputViewModel(boolPair.name);
      view.value = view.id = boolPair.value;
      this.boolValues.push(view);
    }

    if (this.filterItems.length > 0) {
      this.filterItemsView.type = this.filterItems[0].type;
      this.setFilterIcon();
    }

    this.resetFilter();

    this.readSearchInfo();
  }

  public resetFilter() {
    this.isFiltered = false;

    // Set default Indeterminate
    if (this.filterItemsView.type == "Bool") {
      this.value1View.formattedValue = this.boolValues[1].name;
      this.value1View.value = this.boolValues[1].id;
    }

    this.setFilterIcon();
  }

  public setFilterIcon() {
    let icon = (this.filterItemsView.type + "param").toLowerCase();
    this.filterItemsView.icon = icon;
    this.filterItemsView.iconImageSet = this.getImageSet(ImageSets.Control);
  }

  public get value1() {
    return this.filterItemsView.type == "Bool" ? this.value1View.id : this.value1View.value;
  }

  public onFilterItemChanged($event: ValueChangeEventArgs) {
    let oldType = this.filterItemsView.type;
    this.filterItemsView.type = ($event.actionView as FilterItemViewModel).type;

    // Reset values and types if we're changing types.
    if (this.filterItemsView.type !== oldType) {
      this.filterTypesView.formattedValue = this.strings.getString(this.filterTypes[0].name);
      this.filterTypesView.value = this.filterTypes[0].id;
      this.filterType = FilterType.SingleValue;

      this.value1View.value = this.value2View.value = "";
      this.value1View.name = this.value1View.title = this.strings.Value;
    }

    this.resetFilter();

    this.filterItemChanged.emit({ filterItem: this.filterItemsView, value1: this.value1, value2: this.value2View.value, filterType: this.filterType })
  }

  public onFilterValueChanged($event: ValueChangeEventArgs) {
    this.filterTextChanged.emit({ filterItem: this.filterItemsView, value1: this.value1, value2: this.value2View.value, filterType: this.filterType })
  }

  public onFilterTypeChanged($event: ValueChangeEventArgs) {
    this.filterType = parseInt(this.filterTypesView.value);

    // Update labels. 2 values => display "Value 1" & "Value 2".
    if (this.filterType == FilterType.Range)
      this.value1View.name = this.strings.Value1;
    else
      this.value1View.name = this.strings.Value;

    this.value1View.title = this.value1View.name;
  }

  public onSearchClick() {
    this.isFiltered = true;
    this.onFilter.emit({ filterItem: this.filterItemsView, value1: this.value1, value2: this.value2View.value, filterType: this.filterType })
  }

  public onCloseClick() {
    this.resetFilter();
    this.onCancel.emit();
  }
}