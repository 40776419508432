import { Injectable, Inject } from "@angular/core";
import * as Immutable from 'immutable';
import { AppState, AppStore } from "../state"
import { ManagedSubject } from "../../../shared/managedSubject";
import { Array } from "core-js";

@Injectable()
export class ReselectorService {

  constructor(
    @Inject(AppStore) public appStore: AppStore
  )
  {}

  createMemorizer<T1>(stateSlicer: (AppState: AppState) => T1, ignoreNull:boolean = true): () => T1 {

    let memorizerFn: () => ReselectorResult<T1> = this.create1<T1, T1>(stateSlicer, (result: T1): T1 => result);

    return () => {

      let result: ReselectorResult<T1> = memorizerFn();      
      if (result.isChanged) {
        if ((result.Output == null || result.Output == undefined) && ignoreNull)
          return ManagedSubject.IGNORE_VALUE;
                
        return result.Output;
      }

      return ManagedSubject.IGNORE_VALUE;
    }
  }

  createMemorizer1<TOutput, T1>(stateSlicer: (AppState: AppState) => T1, resultFunc: (arg1: T1) => TOutput, ignoreNull: boolean = true): () => TOutput {

    let memorizerFn: () => ReselectorResult<TOutput> = this.create1<TOutput, T1>(stateSlicer, resultFunc);

    return () => {

      let result: ReselectorResult<TOutput> = memorizerFn();
      if (result.isChanged) {
        if ((result.Output == null || result.Output == undefined) && ignoreNull)
          return ManagedSubject.IGNORE_VALUE;

        return result.Output;
      }

      return ManagedSubject.IGNORE_VALUE;
    }
  }

  createMemorizer2<TOutput, T1, T2>(stateSlicer1: (AppState: AppState) => T1, stateSlicer2: (AppState: AppState) => T2, resultFunc: (arg1: T1, arg2: T2) => TOutput, ignoreNull: boolean = true): () => TOutput {

    let memorizerFn: () => ReselectorResult<TOutput> = this.create2<TOutput, T1, T2>(stateSlicer1, stateSlicer2, resultFunc);

    return () => {

      let result: ReselectorResult<TOutput> = memorizerFn();
      if (result.isChanged) {
        if ((result.Output == null || result.Output == undefined) && ignoreNull)
          return ManagedSubject.IGNORE_VALUE;

        return result.Output;
      }

      return ManagedSubject.IGNORE_VALUE;
    }
  }
  
  create1<TOutput, T1>(stateSlicer1: (AppState: AppState) => T1, resultFunc: (arg1: T1) => TOutput): () => ReselectorResult<TOutput> {
    return this.create<TOutput>(resultFunc, stateSlicer1);
  }

  create2<TOutput, T1, T2>(stateSlicer1: (AppState: AppState) => T1, stateSlicer2: (AppState: AppState) => T2, resultFunc: (arg1: T1, arg2: T2) => TOutput): () => ReselectorResult<TOutput> {
    return this.create<TOutput>(resultFunc, stateSlicer1, stateSlicer2);
  }

  create3<TOutput, T1, T2, T3>(stateSlicer1: (AppState: AppState) => T1, stateSlicer2: (AppState: AppState) => T2, stateSlicer3: (AppState: AppState) => T3, resultFunc: (arg1: T1, arg2: T2, arg3: T3) => TOutput): () => ReselectorResult<TOutput> {
    return this.create<TOutput>(resultFunc, stateSlicer1, stateSlicer2, stateSlicer3);
  }

  create4<TOutput, T1, T2, T3, T4>(stateSlicer1: (AppState: AppState) => T1, stateSlicer2: (AppState: AppState) => T2, stateSlicer3: (AppState: AppState) => T3, stateSlicer4: (AppState: AppState) => T4, resultFunc: (arg1: T1, arg2: T2, arg3: T3, arg4: T4) => TOutput): () => ReselectorResult<TOutput> {
    return this.create<TOutput>(resultFunc, stateSlicer1, stateSlicer2, stateSlicer3, stateSlicer4);
  }

  create5<TOutput, T1, T2, T3, T4, T5>(stateSlicer1: (AppState: AppState) => T1, stateSlicer2: (AppState: AppState) => T2, stateSlicer3: (AppState: AppState) => T3, stateSlicer4: (AppState: AppState) => T4, stateSlicer5: (AppState: AppState) => T5, resultFunc: (arg1: T1, arg2: T2, arg3: T3, arg4: T4, arg5: T5) => TOutput): () => ReselectorResult<TOutput> {
    return this.create<TOutput>(resultFunc, stateSlicer1, stateSlicer2, stateSlicer3, stateSlicer4, stateSlicer5);
  }

  public create<TOutput>(resultFunc: Function, ...stateSlicers: Function[]): () => ReselectorResult<TOutput> {

    const memoizedResultFunc = this.memorizer<TOutput>((...args) => {
      return resultFunc(...args)
    })

    const reselector = () => {
      let state: AppState = this.appStore.getState();
      const params = stateSlicers.map(func => func(state))
      return memoizedResultFunc(...params)
    }

    return reselector;
  }

  public memorizer<TOutput>(reselector: Function): (...args) => ReselectorResult<TOutput> {
    let lastArgs = null
    let lastResult = null
    let firstTime: boolean = true;

    const isEqualToLastArg = (value, index) => {

      if (value === lastArgs[index])
        return true;
      else {

        if ((value instanceof Immutable.List && lastArgs[index] instanceof Immutable.List)) {

          let list1 = value as Immutable.List<any>;
          let list2 = lastArgs[index] as Immutable.List<any>;

          if (list1.size != list2.size)
            return false;

          for (let ii = 0; ii < list1.size; ii++)
            if (list1.get(ii) !== list2.get(ii))
              return false;

          return true;
        }

      }

      return false;
     };

    return (...args): ReselectorResult<TOutput> =>  {
    
      let result = new ReselectorResult<TOutput>();
      result.isChanged = firstTime;
      firstTime = false;

      if (lastArgs === null || lastArgs.length !== args.length || !args.every(isEqualToLastArg)) {
        lastResult = reselector(...args)
        result.isChanged = true;
      }

      result.Output = lastResult;      
      lastArgs = args;

      return result;
    }
  }
}

export class ReselectorResult<TOutput> {
  isChanged: boolean;
  Output: TOutput;
}