export enum Delay {
  MS_0 = 0,
  MS_50 = 50,
  MS_100 = 100,
  MS_200 = 200,
  MS_400 = 400,
  MS_600 = 600,
  MS_800 = 800,
  MS_1000 = 1000,
  MS_2000 = 2000
}

export async function sleep(duration: Delay) {
  await _sleep(duration);
}

function _sleep(duration: Delay) {
  return new Promise((resolve) => setTimeout(resolve, Number(duration)));
}