import * as Immutable from "immutable"

import { Decoration } from "./decoration";

export class DataSelector extends Decoration {

  protected readonly COLUMNS = "columns";
  protected readonly DIALOG_WIDTH = "dialogWidth";
  protected readonly HYPER_LINK_DIRECTORY = "hyperLinkDirectory";
  protected readonly NAME = "name";
  protected readonly SHOW_INLINE = "showInline";
  protected readonly PAGE_SIZE = "pageSize";
  protected readonly PLACEHOLDER = "placeholder";

  get columns(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.COLUMNS); }
  setColumns(columns: Immutable.List<number>): this { return this.setInternalValue(this.COLUMNS, columns); }

  get dialogWidth(): number { return this.getInternalValue<number>(this.DIALOG_WIDTH); }
  setDialogWidth(dialogWidth: number): this { return this.setInternalValue(this.DIALOG_WIDTH, dialogWidth); }

  get hyperLinkDirectory(): string { return this.getInternalValue<string>(this.HYPER_LINK_DIRECTORY); }
  setHyperLinkDirectory(hyperLinkDirectory: string): this { return this.setInternalValue(this.HYPER_LINK_DIRECTORY, hyperLinkDirectory); }

  get name(): string { return this.getInternalValue<string>(this.NAME); }
  setName(name: string): this { return this.setInternalValue(this.NAME, name); }

  get showInline(): boolean { return this.getInternalValue<boolean>(this.SHOW_INLINE); }
  setShowInline(showInline: boolean): this { return this.setInternalValue(this.SHOW_INLINE, showInline); }

  get pageSize(): number { return this.getInternalValue<number>(this.PAGE_SIZE); }
  setPageSize(pageSize: number): this { return this.setInternalValue(this.PAGE_SIZE, pageSize); }

  get placeholder(): string { return this.getInternalValue<string>(this.PLACEHOLDER); }
  setPlaceholder(placeholder: string): this { return this.setInternalValue(this.PLACEHOLDER, placeholder); }

}