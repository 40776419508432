import { RouteNames } from "../../shared/providers";

export class ConfRouteParams {
  
  public static readonly ID = "id";
  public static readonly CONF_SESSION_ID = "confSessionId";
  public static readonly PRODUCT_ID = "productId";
  public static readonly VIEW = "view";
  public static readonly REVISE = "revise";
  public static readonly REUSE = "reuse";
  public static readonly SUB_CONF_ID = "subConfId";
  public static readonly CONF_PARAMS = "confParams";
  public static readonly EXTRA_ARGS = "extraArgs";

  // As nullable properties cannot be read, that's why properties names are added into list.
  public static readonly ALL_PROPERTIES: string[] =
  [
      ConfRouteParams.ID,
      ConfRouteParams.CONF_SESSION_ID,
      ConfRouteParams.PRODUCT_ID,
      ConfRouteParams.VIEW,
      ConfRouteParams.REVISE,
      ConfRouteParams.REUSE,
      ConfRouteParams.SUB_CONF_ID,
      ConfRouteParams.CONF_PARAMS,
      ConfRouteParams.EXTRA_ARGS,
  ]

  public id?: number;
  public confSessionId?: number;
  public subConfId?: number;
  public productId?: number;
  public view?: RouteNames;
  public reuse?: number;
  public revise?: number;
  public confParams?: Map<string, string>;
  public extraArgs?: Map<string, string>;
}

