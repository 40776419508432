import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DraggableComponent, DraggableHandleComponent } from "./draggableComponent";
import { DroppableComponent } from "./droppableComponent";
import { SortableComponent, SortableContainer, SortableHandleComponent } from "./sortableComponent";
import { DragDropService, DragDropSortableService } from "./dragDropService";
import { DragDropConfig } from "./dragDropConfig";

export * from "./draggableComponent";
export * from "./droppableComponent";
export * from "./sortableComponent";

@NgModule({
  imports: [
    CommonModule,
    
  ],
  declarations: [
    SortableContainer,
    DroppableComponent,
    DraggableComponent,
    DraggableHandleComponent,
    SortableComponent,    
    SortableHandleComponent
  ],
  exports: [
    DraggableComponent,
    DraggableHandleComponent,
    SortableComponent,
    SortableHandleComponent,
    SortableContainer,
    DroppableComponent
  ],
  providers: [
    DragDropService,
    DragDropSortableService,
    DragDropConfig,
    SortableContainer
  ]
})

export class DragDropCustomModule {

}
