import { Component, Input, SimpleChanges } from "@angular/core";
import { BaseComponent } from "../../shared";
import { ConfPageSessionService } from "../providers";

@Component({ template: '' })
export abstract class SummaryBaseComponent extends BaseComponent {

  @Input()
  public configurationId: number;

  @Input()
  public confSessionId: number;

  constructor(public confPageSessionService: ConfPageSessionService) {
    super();
  }

  ngOnInit(): void {

    this.configurationId = this.confPageSessionService.activeConfigurationId;
    this.confSessionId = this.confPageSessionService.confSessionId;
    this.onDataReady();  
    this.subscribeDataChange();
    this.setPrintItemDetail();
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['configurationId'] || changes['confSessionId']) {
      this.onDataReady();
      this.setPrintItemDetail();      
    }

  }

  abstract onDataReady(): void;

  subscribeDataChange(): void {}

  setPrintItemDetail(): void { }

}