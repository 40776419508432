import { Component, ViewChild } from "@angular/core";
import { BaseCodeDisplayStyleComponent } from "./baseCodeDisplayStyleComponent";
import { ProductDataStore } from "../../../providers/productData";
import { PageStore } from "../../../providers/page";
import { ConfPageSessionService, ConfiguratorStore, PopupIdentifiers } from "../../../../configurator/providers";
import { ModelFactory } from "../../../providers";
import { ConfDataController } from "../../../providers/configurationData";
import { ConfCodeRequest } from "../../../models";
import { HttpService } from "../../../../../shared/providers";
import { CodePopupComponent } from "../../../../configurator/popups";
import { PopupService } from "../../../../../shared/components";


@Component({
  selector: 'code-popup-style',
  templateUrl: './codePopupStyleComponent.html'
})
export class CodePopupStyleComponent extends BaseCodeDisplayStyleComponent {

  public data: string;
  public icon = "code";

  @ViewChild(CodePopupComponent)
  protected codePopup: CodePopupComponent;

  constructor(public productStore: ProductDataStore,
    public modelFactory: ModelFactory,
    public controller: ConfDataController,
    public pageStore: PageStore,
    public popupService: PopupService,
    public httpService: HttpService,
    public confPageSession: ConfPageSessionService) {
    super(productStore, pageStore, confPageSession);
  }

  ngOnInit() {
    this.icon = this.codeValue.icon;

    super.ngOnInit();
  }

  showPopup(event): void {

    let url = this.getURL();

    this.httpService.readContents(url, "UTF-16").then(data => {

      this.popupService.open(PopupIdentifiers.Code, { code: data, title: this.codeValue.title });

    });

  }

  getURL(): string {

    let model: ConfCodeRequest = this.modelFactory.createAny(ConfCodeRequest.name) as any;

    model.getStored = this.isStored;
    model.client = this.pageStore.getActiveClientType();
    model.codeFileId = this.codeValue.longId;
    model.configurationId = this.configurationId;
    model.confSessionId = this.confSessionId;
    model.getStored = this.isStored;

    return this.controller.getConfigurationCodeUrl(model);

  }

}