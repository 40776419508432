<backdrop-container [class.d-none]="!languagesLoaded">
  <regular-popup *ngIf="visible"
                 [id]="id"
                 title="Login anonymously"
                 [showHeader]="false"
                 [showFooter]="true"
                 [okButtonText]="strings.Ok"
                 [cancelButtonText]="strings.Cancel"
                 (okClick)="okClick()"
                 (onClosed)="onPopupClosed()">

    <div card-type="body" class="p-4">
      <div>
        {{strings.SelectLangugageAndPressOk}}
      </div>
      <div class="pt-4">
        <dropdown *ngIf="languageView"
                  [view]="languageView"
                  [itemsView]="languagesView">
        </dropdown>
      </div>
    </div>
  </regular-popup>
</backdrop-container>