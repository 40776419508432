import { Component, Output, EventEmitter, Input } from "@angular/core";
import { MultiSelectItem } from ".";
import * as Immutable from "immutable";

@Component({
  selector: "dropdown-selected-values",
  templateUrl: './multiSelectDropdownSelectedValues.html'
})
export class MultiSelectDropdownSelectedValues {

  @Output()
  openIt = new EventEmitter();

  @Output()
  itemRemoved = new EventEmitter<MultiSelectItem>();

  @Input()
  items: Immutable.List<MultiSelectItem> = Immutable.List<MultiSelectItem>();

  @Input()
  selectedItems: Immutable.List<MultiSelectItem> = Immutable.List<MultiSelectItem>();

  @Input()
  show = false;

  @Input()
  emptyTitle: string;

  openMenu($event): void {
    $event.stopPropagation();

    this.openIt.emit();
  }

  removeItem($event, item: MultiSelectItem): void {
    $event.stopPropagation();

    this.itemRemoved.emit(item);
  }

}