import * as Immutable from "immutable";
import { Subject, Observable } from "rxjs";

import { MandatoryParamsMessage, ParamInfo } from "../../../shared/models";
import { Injectable } from "@angular/core";

@Injectable()
export class ParameterMandatoryService {

  //public mandatoryParamsByConfId = Immutable.Map<number, Immutable.List<number>>();
  public mandatoryParamsByConfId = new Map<number, Set<number>>();

  public _isHighlighting = false;

  public get isHighlighting(): boolean {
    return this._isHighlighting;
  }

  // Subject to subscribe the tree nodes.  
  private subject = new Subject();

  public getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  /**
   * Broadcasts the message to all subscribers
   * @param event
   */
  public sendMessage(event): void {
    this.subject.next(event);
  }

  public sendHighlightMessage(highlight: boolean): void {
    // Remember highlighting state.
    this._isHighlighting = highlight;
    this.sendMessage({ highlight: this._isHighlighting });
  }

  public setMandatoryParamsById(messages: Immutable.List<MandatoryParamsMessage>, isSummary: boolean = false): void {
    this.mandatoryParamsByConfId.clear();

    messages.forEach(message => {
      this.mandatoryParamsByConfId = this.mandatoryParamsByConfId.set(message.configurationId, new Set<number>());
      // Add all parameters and ParamValues to indicate that they're mandatory.
      message.mandatoryParams.forEach((param: ParamInfo) => {

        param.paramValues.forEach(paramValue => {
          this.mandatoryParamsByConfId.get(message.configurationId).add(paramValue.longId);
        });

        if (isSummary)
          this.mandatoryParamsByConfId.get(message.configurationId).add(param.visualObjectInTabId);
        else
          this.mandatoryParamsByConfId.get(message.configurationId).add(param.longId);
      });
    });
  }

  /**
   * Returns the mandatory highlighting state of a parameter.
     Returns true if the parameter is mandatory and we're currently highlighting.
   */
  public shouldHighlight(confId: number, longId: number): boolean {
    // Highlight if the param is in the in the mandatory params.
    return this._isHighlighting && this.isMandatory(confId, longId);
  }

  /**
   * Returns the mandatory state of a parameter.
   */
  public isMandatory(confId: number, longId: number): boolean {
    // Highlight if the param is in the in the mandatory params.
    return this.mandatoryParamsByConfId.has(confId) && this.mandatoryParamsByConfId.get(confId).has(longId);
  }
}