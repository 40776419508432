import { Component, Input, Inject } from "@angular/core";

import { DocumentsDecoration, ConfDocumentValue, Conf, RequestViews, ConfDocumentRequest  } from "../../shared/models";
import { WebSettings } from "../../shared/models";
import { GlobalDataStore } from "../../shared/providers/globalData";
import { BaseComponent } from "../../shared";
import { ConfPageSessionService, ConfiguratorStore } from "../providers";
import { ModelFactory } from "../../shared/providers/modelFactory";
import { ConfDataController } from "../../shared/providers/configurationData";
import { PageStore } from "../../shared/providers/page";
import { ImageSets } from "../../../shared/utils/imageSets";
import { RouteNames } from "../../shared/providers/routeNames";

@Component({
  selector: 'documents-link',
  templateUrl: './documentsLinkComponent.html'
})
export class DocumentsLinkComponent extends BaseComponent {

  @Input()
  public document: ConfDocumentValue;

  @Input()
  public activeConfigurationId: number;

  @Input()
  public confSessionId: number;

  public fileType: string = "";

  public documentLink: string = "";

  public clickable: boolean  = true;

  public icon: string = "";

  public isRemote = false;

  public imageSet: string;

  constructor(@Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    @Inject(ConfDataController) public configuratorController: ConfDataController,
    @Inject(PageStore) public pageStore: PageStore
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.isRemote = this.document.fileUrl != null;
    this.clickable = this.isRemote || this.document.fileExists;
    this.icon = this.document.fileType ? this.document.fileType : "document";
    this.documentLink = this.document.fileUrl ? this.document.fileUrl : this.getDocumentLink(this.document.longId);

    this.imageSet = this.getImageSet(this.pageStore.activeRouteName === RouteNames.Editor ? ImageSets.ConfiguratorDecoration : ImageSets.SummaryActionDetail);
  }
  
  public getDocumentLink(documentId: number): string {
    let model: ConfDocumentRequest = this.modelFactory.createAny(ConfDocumentRequest.name) as any;

    model.client = this.pageStore.getActiveClientType();
    model.documentId = documentId;
    model.configurationId = this.activeConfigurationId;
    model.confSessionId = this.confSessionId;
    return this.configuratorController.getConfigurationDocumentUrl(model);
  }

}