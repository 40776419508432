import { ChangeDetectorRef, Component, Inject, Input, OnInit, SimpleChanges, ChangeDetectionStrategy } from "@angular/core";
import { BaseInputComponent, InputViewModel } from "../shared";
import { RadioButtonComponent } from "./radioButtonComponent";

@Component({
  selector: 'radio-group',
  templateUrl: './radioGroupComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioGroupComponent extends BaseInputComponent implements OnInit {
  
  @Input()
  public itemsView: InputViewModel[] = [];  

  @Input()
  public displayStyle: any;
  
  @Input()
  public orientation: any;

  public sizeStyle: CSSStyleDeclaration;  

  @Input()
  public selectedViewId: string;

  constructor(    
    @Inject(ChangeDetectorRef) public cdr: ChangeDetectorRef
  ) {
    super();
  }

  onClick(event, view) {
    this.processEvent(event, view);
  }

  /**
   * Is provided button selected ?
   * @param button
   */
  isSelected(button: RadioButtonComponent): boolean {

    if (button.view) {

      return button.view.value == null ? false : button.view.value == button.view.id;
    }

    return false;
  }

  processEvent(event: any, view: InputViewModel): void {
    if (this.notifyIfReadOnly(view, event))       
      return;
    

    if (this.notifyIfDisabled(view, event))
      return;

    // If user clicks on checked option then uncheck it.
    //let newValue = view.value == null ? view.id : null;         
    this.selectedViewId = event;


    //this.cdr.detectChanges();
    this.notifyValueChange(view, event);    
  }

  /**
   * Returns true If any item view is readOnly,
   */
  public isReadOnly(newValue?: any): boolean {

    return this.itemsView.filter(x => x.readOnly).length > 0;
  }

  public isDisabled(newValue: any): boolean {

    let itemView = this.getItemViewById(newValue);
    return itemView && itemView.disabled;

  }

  public currentValue(): any {

    for (let view of this.itemsView) {

      // Return value.
      if (view.value != null)
        return view.value;

    }

    // This lines only would be executed if no value is set.
    return null;
  }

  public get selectedView(): InputViewModel {

    for (let view of this.itemsView)       
      if (view.value != null)
        return view;

    return null;
  }
  
  ngOnInit() {    
    this.setStyle();
  }


  private setStyle(): void {

    this.sizeStyle = <CSSStyleDeclaration>{ width: '100%', maxWidth: this.view.width, minWidth: this.view.minWidth }

  }

  public removeCssStyles(button: RadioButtonComponent): void {
    button.updateStyle();
  }
  
  private getItemViewById(id: string): InputViewModel {

    for (let view of this.itemsView) {
      if (view.id == id)
        return view;
    }

    return null;
  }


  
} 