import { Injectable, Inject } from "@angular/core";
import * as Immutable from "immutable";

import { PageActionCreator } from "../../shared/providers/page";
import { AppStore, StoreResponse, AppAction, ActionInfo, RequestStatus } from "../../shared/state";
import { SelectorActionCreator } from "./selectorActionCreator";
import { PageUIStore } from "../../shared/providers/page";
import { ReselectorService, ReselectorResult } from "../../shared/providers/reselectorService";
import { PageUIData, SelectorPageUIData } from "../../shared/state";
import { AppStoreSubscriptionManager } from "../../shared/providers/appStoreSubscriptionManager";
import { ManagedSubject } from "../../../shared/managedSubject";
import { GlobalDataStore } from "../../shared/providers/globalData/globalDataStore";
import { ModelFactory } from "../../shared/providers/modelFactory";

@Injectable()
export class SelectorStore extends PageUIStore {

  constructor(
    @Inject(AppStore) appStore: AppStore,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(PageActionCreator) pageActionCreator: PageActionCreator,
    @Inject(SelectorActionCreator) public selectorActionCreator: SelectorActionCreator,
    @Inject(ReselectorService) public reselectorService: ReselectorService,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    public appStoreSubscriptionManager: AppStoreSubscriptionManager
  ) {
    super(appStore, pageActionCreator, appStoreSubscriptionManager, modelFactory);
  }

  public createSelectorUI(): ManagedSubject<StoreResponse<any>> {

    let nextPageId: number = this.nextPageIdentifier()
    this.selectorActionCreator.dispatcAddSelectorUIData(nextPageId);

    // Create request object
    let response: StoreResponse<any> = new StoreResponse<any>();

    // Subscribe to store and return the results on request complete.    
    return this.appStoreSubscriptionManager.createSubject(() => {

      if (this.appStore.getState().uiData.selectorUI.has(nextPageId.toString())) {

        response.requestId = nextPageId;
        return response;
      }

      return ManagedSubject.IGNORE_VALUE;
    }, true);
  }

  public getSelectorUIState(pageId: number): SelectorPageUIData {
    return this.appStore.getState().uiData.selectorUI.get(pageId.toString());
  }

  public getMemorizer(pageId: number): () => PageUIData {
    return this.reselectorService.createMemorizer((state) => state.uiData.selectorUI.get(pageId.toString()));
  }

  public getPageUIState(pageId: number): PageUIData {
    return this.getSelectorUIState(pageId);
  }

}