<div #accordionGroup class="card" [class.disabled]="disabled" [class.dark]="useDarkTheme" [class.expanded]="isOpened">
  
  <a *ngIf="togglable" role="button" data-toggle="collapse" (click)="onClick()" [attr.aria-expanded]="isOpened">
    <ng-container *ngTemplateOutlet="cardTemplate"></ng-container>
  </a>

  <ng-container *ngTemplateOutlet="!togglable && cardTemplate"></ng-container>

  <ng-template #cardTemplate>
    <div *ngIf="togglable || !(heading == '' || heading == null)" class="card-header row mx-0 px-0" role="tab">
      <span class="col align-middle">
        <span class="icon" *ngIf="icon">
          <co-image width="24" height="24" [key]="icon" [imageSet]="useDarkTheme ? 'white' : 'primary'" [isVault]="iconIsVault"></co-image>
        </span>
        <span class="title-container">
          <span class="title">{{heading}}</span>
        </span>
      </span>
      <span *ngIf="togglable" class="col-auto align-middle">
        <co-image width="16" height="16" type="svg" [key]="isOpened ? 'collapse' : 'expand'" [imageSet]="useDarkTheme ? 'white' : 'primary'"></co-image>
      </span>
      <ng-content select="accordion-heading"></ng-content>
    </div>
  </ng-template>

  <div [class.d-none]="!isOpened" class="panel-collapse collapse.show" role="tabpanel" [attr.aria-labelledby]="heading" aria-expanded="true">
    <div *ngIf="isOpened" class="accordion-body panel-body inner-spacing-accordion">
      <ng-content></ng-content>
    </div>
  </div>
</div>