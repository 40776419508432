import { Inject, Component, Input, ChangeDetectorRef } from "@angular/core";
import { GlobalSettings, GraphicsDecoration } from "../../../shared/models";
import { GlobalDataStore } from "../../../shared/providers/globalData";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import { PopupIdentifiers } from "../../providers";

@Component({
  selector: 'graphics-popup',
  templateUrl: './graphicsPopupComponent.html'
})
export class GraphicsPopupComponent extends AbstractPopupComponent {

  public id: string = PopupIdentifiers.Graphics;

  public decoration: GraphicsDecoration;

  public configurationId: number;
  public confSessionId: number;

  constructor(
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    public cdr: ChangeDetectorRef
  ) {
    super(cdr);
  }

  show(decoration: GraphicsDecoration, configurationId = null, confSessionId = null): void {
    this.decoration = decoration;

    this.configurationId = configurationId;
    this.confSessionId = confSessionId;

    this.showInternal();
  }

  update(): void {
  }

  public get globalSettings(): GlobalSettings {
    return this.globalDataStore.getGlobalData().globalSettings;
  }

}