import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';

import { RouteRedirector } from "./routeRedirector";
import { AccountDataStore } from "./accountData/accountDataStore";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(public routerRedirector: RouteRedirector,
    public accountStore: AccountDataStore,
    )
  {}

  public redirect(state: RouterStateSnapshot) {
    this.routerRedirector.redirectToLogin(state.url);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        
    if (this.accountStore.hasUserSession()) {
      return this.accountStore.hasLoggedInUser().toPromise().then((value) => {

        if (value)
          return true;
        else {
          this.redirect(state);
          return false
        }
      },
        (error) => {
          this.redirect(state);
          return false
        });
    }

    this.redirect(state);
    return Promise.resolve(false);
  }
}