import { ImmutableObject } from "../../../../shared/immutableObject";

// Used for persisting client specific data
export class LocalStorageData extends ImmutableObject {

  protected readonly USERNAME = "username";
  protected readonly RELOAD_CACHE_COUNTER = "reloadCacheCounter";
  protected readonly TIME_STAMP = "TIME_STAMP";
  protected readonly CACHED_REDIRECT_URL = "CACHED_REDIRECT_URL";

  get username(): string { return this.getInternalValue<string>(this.USERNAME); }
  setUsername(username: string) { return this.setInternalValue(this.USERNAME, username); }

  get reloadCacheCounter(): number { return this.getInternalValue<number>(this.RELOAD_CACHE_COUNTER); }
  setReloadCacheCounter(reloadCacheCounter: number) { return this.setInternalValue(this.RELOAD_CACHE_COUNTER, reloadCacheCounter); }
    
  get timeStamp(): number { return this.getInternalValue<number>(this.TIME_STAMP); }
  setTimeStamp(timeStamp: number) { return this.setInternalValue(this.TIME_STAMP, timeStamp); }

}