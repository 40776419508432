<div>
  <multi-select-group 
                      emptyTitle="{{emptyTitle}}" 
                      [checkedIcon]="checkedIcon" 
                      [uncheckedIcon]="uncheckedIcon" 
                      [enableInputItemIcon]="enableInputItemIcon"
                      [triState]="triState"
                      [iconImageSet]="iconImageSet"
                      [items]="items"                      
                      (onSelect)="onSelectionChange($event)"
                      [width]="width"
                      ></multi-select-group>
</div>