import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpService } from "../../../shared/providers"
import { ApiResponse } from "../models";
import { ApiResponseBuilder } from "./apiResponseBuilder";

@Injectable()
export class AppController {

  public reloadCacheUrl = "api/settings/reloadCaches";
  public reloadUserCacheUrl = "api/settings/reloadUserCaches";
    
  constructor(
    public httpService: HttpService,
    public apiResponseBuilder: ApiResponseBuilder,
  ) { }

  public normalizeData = map((response, index) => this.apiResponseBuilder.build(response));

  reloadCaches(userDataOnly: boolean = false) {
    let url = this.reloadCacheUrl;
    if (userDataOnly)
      url = this.reloadUserCacheUrl;

    return this.getData(url, null);
  }

  public getData(url: string, params: any): Observable<ApiResponse> {
    return this.httpService.get(url, params).pipe(this.normalizeData);
  }

  public postData(url: string, params: any): Observable<ApiResponse> {
    return this.httpService.post(url, params).pipe(this.normalizeData);
  }
}