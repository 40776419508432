import { Component, Input, ViewChild, Output, EventEmitter, ElementRef, SimpleChanges } from "@angular/core";
declare var jQuery: any;
import { AutoCompleteModel } from "./autoCompleteModel";
import { InputViewModel, ValueChangeEventArgs } from "../../../../shared/components/shared";
import { DebouncedTextBoxComponent } from "../../../../shared/components/debouncedTextBox";
import { BaseComponent } from "../..";

@Component({
  selector: 'autocomplete',
  templateUrl: './autocompleteComponent.html'
})
export class AutoCompleteComponent extends BaseComponent {
  // TODO should this be combined with filterComponent in some way?

  @Input()
  public autoCompleteView: InputViewModel;
  @Input()
  public clientSideFiltering = false;

  @Input()
  public items: Array<InputViewModel>;
  public filteredItems: Array<AutoCompleteModel> = [];
  public selectedItem: AutoCompleteModel;

  @Input()
  public minimumLength = 0;

  @Input()
  public matchOnce = false;

  @Output()
  public selectionChanged = new EventEmitter();
  @Output()
  public filterChanged = new EventEmitter();

  @ViewChild('dropdown')
  public dropdownElement: ElementRef;
  @ViewChild('inputQuery')
  public textBoxElement: DebouncedTextBoxComponent;

  @ViewChild('inputQueryNative')
  public nativeTextBoxElement: ElementRef;

  public showNoMatches = false;
  public previousFilter = "";

  public dropdownWidth = "";

  ngOnInit() {
    this.showNoMatches = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['items'] && this.items != null) {
      // We recieved new items -> setup new result list
      this.showNoMatches = this.items.length == 0;
      this.previousFilter = this.textBoxElement && this.textBoxElement.view ? this.textBoxElement.view.value : "";

      this.filteredItems = [];

      // Go through all given items, set the matched part, and add them to the result.
      this.items.forEach((model: InputViewModel, key) => {
        let rowModel = new AutoCompleteModel(model.title, this.matchOnce);
        rowModel.value = model.value;
        rowModel.name = model.name;
        rowModel.matchedPart = this.textBoxElement && this.textBoxElement.view ? this.textBoxElement.view.value : "";
        this.filteredItems.push(rowModel);
      });
    }
  }

  public showMenu() {
    // Make sure the dropdown menu is opened.
    // It requires jquery to open, but it's not a hard requirement but it's useful if the user closes the menu while searching.
    if (this.dropdownElement.nativeElement.className.indexOf("show") == -1)
      jQuery(".autocomplete .toggler").dropdown("toggle")
  }

  public reset() {
    this.previousFilter = "";
    this.textBoxElement.view.value = "";
    this.filteredItems = [];
    this.items = [];
    this.showNoMatches = false;
  }

  select(item: AutoCompleteModel) {
    this.selectedItem = item;
    this.textBoxElement.view.value = item.name;
    //this.textBoxElement.view.displayValueText = item.name;
    //this.filteredItems = [];

    this.selectionChanged.emit({ item: this.selectedItem });
  }

  public filter($event: ValueChangeEventArgs) {
    this.dropdownWidth = this.nativeTextBoxElement.nativeElement.clientWidth + "px";

    // Make sure the dropdown menu is opened.
    this.showMenu();

    let query = $event.value;
    if (this.filterOnClientSide)
      this.filterOnClientSide(query);
    else
      this.filterOnCaller(query);
  }

  public filterOnCaller(query: string) {
    // Filtering will be done on caller side -> raise filterChanged.
    if (query !== "" && query.length >= this.minimumLength) {
      this.filterChanged.emit({ query: query });
    }
    else {
      this.filteredItems = [];
    }
  }

  public filterOnClientSide(query: string) {
    this.filteredItems = [];

    if (query !== "" && query.length >= this.minimumLength) {
      this.items.forEach((model: InputViewModel, key) => {
        // TODO should it search from start or search anywhere? Old web used the latter approach. Maybe support both and chose from input param?
        //if (el.toUpperCase().startsWith(searchText) {
        if (model.title.toUpperCase().indexOf(query.toUpperCase()) > -1) {
          let rowModel = new AutoCompleteModel(model.title, this.matchOnce);
          rowModel.matchedPart = query;
          rowModel.name = model.name;
          this.filteredItems.push(rowModel);
        }
      });
    }
    else {
      this.showNoMatches = false;
    }

    this.filterChanged.emit({ query: query });
  }

  trackByName(index: number, item: AutoCompleteModel): any {
    // Help angular tracking the items so the whole dropdown isn't re-rendered.
    // NOTE! This requires unique names.
    return item.name;
  }
}