import * as Immutable from "immutable"

import { ImmutableObject } from "../../shared/immutableObject";

export abstract class BaseEntity extends ImmutableObject {

  public readonly LONG_ID = "longId";  
   
  get longId(): number { return this.getInternalValue<number>(this.LONG_ID); }
  setLongId(longId: number): this { return this.setInternalValue(this.LONG_ID, longId); }  
}