import { BaseRequest } from "./baseRequest";
import { LoginCommand } from "./commands/loginCommand";
import { LogoutCommand } from "./commands/logoutCommand";
import { CurrentUserCommand } from "./commands/currentUserCommand";
import { AccountEntitiesCommand } from "./commands/accountEntitiesCommand";
import { PasswordChangeCommand } from "./commands/passwordChangeCommand";
import { PasswordRecoverCommand } from "./commands/passwordRecoverCommand"
import { AccountVerificationCommand, SignUpCommand } from "..";


export class AccountDataRequest extends BaseRequest {

  login?: LoginCommand;

  logout?: LogoutCommand;

  passwordChange?: PasswordChangeCommand;

  passwordRecover?: PasswordRecoverCommand;

  verify?: AccountVerificationCommand;

  create?: SignUpCommand;

  currentUser?: CurrentUserCommand;

  entities?: AccountEntitiesCommand;
}