<action-box *ngIf="visible"
            class="detail-item {{extraStyles}} image-detail"
            iconLeft="image"
            textLeft="{{detailTitle}}"
            [showHeader]="showHeader">
  <ng-container *ngIf="contentVisible">
    <ng-container *ngFor="let imageDecoration of imageDecorations">
      <gridview>
        <gridview-header *ngIf="showInlineTitle && !(imageDecoration?.title | isNullOrWhiteSpace)" classes="decoration-header flex-nowrap pb-2">
          <header-columntemplate size="auto" classes="header-image">
            <co-image width="24" height="24" type="svg" key="image" [imageSet]="imageDecoration.imageSet"></co-image>
          </header-columntemplate>
          <header-columntemplate classes="header-title">
            {{imageDecoration.title}}
          </header-columntemplate>
        </gridview-header>
        <gridview-row>
          <columntemplate>
            <co-image isVault="true" key="{{imageDecoration.imageFileName}}" [width]="'auto'"></co-image>
          </columntemplate>
        </gridview-row>
      </gridview>
    </ng-container>
  </ng-container>

  <div card-type="control" *ngIf="isPrintView">
    <print-checkbox (onValueChange)="onPrintValueChange($event)"></print-checkbox>
  </div>
</action-box>
