import { Injectable } from "@angular/core"
import { Schema, schema } from 'normalizr';

import * as EntityTypes from "../../models/entities";

@Injectable()
export class PushMessageSchema {

  public allSchema = new Map<string, Schema>();

  constructor() {
    this.setupProductSchema();
  }

  public setupProductSchema() {

    let productSchema: schema.Entity = this.getSchema(EntityTypes.Product.name);
    let productFamilySchema: schema.Entity = this.getSchema(EntityTypes.ProductFamily.name);

    let assortmentSchemaType = { Product: productSchema, ProductFamily: productFamilySchema };
    // An array of product and product families.
    let assortmentSchemaArray = new schema.Array(assortmentSchemaType, 'className');

    // product family has children of products and product families.
    productFamilySchema.define({
      children: assortmentSchemaArray
    })

    let tabSchema = this.getSchema(EntityTypes.Tab.name);
    
    let visualObjectSchemaType = {
      BoolParam: this.getSchema(EntityTypes.BoolParam.name),
      IntParam: this.getSchema(EntityTypes.IntParam.name),
      StringParam: this.getSchema(EntityTypes.StringParam.name),
      LookupParam: this.getSchema(EntityTypes.LookupParam.name),
      DoubleParam: this.getSchema(EntityTypes.DoubleParam.name),
      MultiChoiceParam: this.getSchema(EntityTypes.MultiChoiceParam.name),
      DataSelector: this.getSchema(EntityTypes.DataSelector.name),
      PropertyDecoration: this.getSchema(EntityTypes.PropertyDecoration.name),
      AttributeDecoration: this.getSchema(EntityTypes.AttributeDecoration.name),
      HeaderDecoration: this.getSchema(EntityTypes.HeaderDecoration.name),
      DocumentsDecoration: this.getSchema(EntityTypes.DocumentsDecoration.name),
      PriceListDecoration: this.getSchema(EntityTypes.PriceListDecoration.name),
      TextDecoration: this.getSchema(EntityTypes.TextDecoration.name),
      ImageDecoration: this.getSchema(EntityTypes.ImageDecoration.name),
      GraphicsDecoration: this.getSchema(EntityTypes.GraphicsDecoration.name),
      BomDecoration: this.getSchema(EntityTypes.BomDecoration.name),
      CodeDecoration: this.getSchema(EntityTypes.CodeDecoration.name),
      VisualizationDecoration: this.getSchema(EntityTypes.VisualizationDecoration.name),
      FormDecoration: this.getSchema(EntityTypes.FormDecoration.name)
    };

    // A Tab has an array of visual objects.
    tabSchema.define({
      visualObjects: new schema.Array(visualObjectSchemaType, 'className')
    });

    let multiChoiceParamSchema = this.getSchema(EntityTypes.MultiChoiceParam.name);
    // A MultiChoiceParam has an array of param values.
    multiChoiceParamSchema.define({
      paramValues: new schema.Array(this.getSchema(EntityTypes.MultiChoiceValue.name))
    });

    let lookupParamSchema = this.getSchema(EntityTypes.LookupParam.name);
    // A LookupParam has an array of param values.
    lookupParamSchema.define({
      paramValues: new schema.Array(this.getSchema(EntityTypes.LookupValue.name))
    });

    let dataSelectorSchema = this.getSchema(EntityTypes.DataSelector.name);  
    // DataSelector has columns
    dataSelectorSchema.define({
      columns: new schema.Array(this.getSchema(EntityTypes.DataSelectColumn.name))
    });
    
    // Product has children and tabs.
    productSchema.define({
      children: new schema.Array(productSchema),
      tabs: new schema.Array(tabSchema)
    });

    this.setSchema(EntityTypes.Assortment.name, assortmentSchemaArray);
  } 

  public getSchema(className: string): any {

    if (!this.allSchema.has(className))
      this.allSchema.set(className, new schema.Entity(className, {}, { idAttribute: 'longId' }));

    return this.allSchema.get(className);
  }

  public setSchema(className: string, schema: Schema) {
    this.allSchema.set(className, schema);
  }

  public getSchemaArray(className: string): schema.Array {

    let classSchema = this.getSchema(className);
    if (classSchema instanceof schema.Array)
      return classSchema;

    return new schema.Array(this.getSchema(className));
  }
}