export class TreeNode {

  id: string;

  title: string;

  icon: string;

  isVault: boolean;

  children: Array<TreeNode> = [];

  level: number = 0;

}