import * as Immutable from "immutable";

import { ImmutableObject } from "../../../../shared/immutableObject";

export class ConfSearchAuthorization extends ImmutableObject {

  readonly CAN_EDIT = "canEdit";
  readonly CAN_DELETE = "canDelete";

  get canEdit(): boolean { return this.getInternalValue<boolean>(this.CAN_EDIT); }
  setCanEdit(canEdit: boolean): this { return this.setInternalValue(this.CAN_EDIT, canEdit); }

  get canDelete(): boolean { return this.getInternalValue<boolean>(this.CAN_DELETE); }
  setCanDelete(canDelete: boolean): this { return this.setInternalValue(this.CAN_DELETE, canDelete); }
}