import * as Immutable from "immutable";

import { BaseEntity } from "../../baseEntity";
import { Grid } from "../responses/grid";

export class ConfDataSelectorValue extends BaseEntity {

  protected readonly VALUE = "value";
  protected readonly KEY_ID = "keyId";
  protected readonly GRID_DATA = "gridData";
  protected readonly IS_HIDDEN = "isHidden";

  get value(): string { return this.getInternalValue<string>(this.VALUE); }
  setValue(value: string): this { return this.setInternalValue(this.VALUE, value); }

  get keyId(): number { return this.getInternalValue<number>(this.KEY_ID); }
  setKeyId(keyId: number): this { return this.setInternalValue(this.KEY_ID, keyId); }

  get gridData(): Grid { return this.getInternalValue<Grid>(this.GRID_DATA); }
  setGridData(gridData: Grid): this { return this.setInternalValue(this.GRID_DATA, gridData); }

  get isHidden(): boolean { return this.getInternalValue<boolean>(this.IS_HIDDEN); }
  setIsHidden(isHidden: boolean): this { return this.setInternalValue(this.IS_HIDDEN, isHidden); }
}