<div #visualObjectContainer *ngIf="confStringValue">
  <div class="visual-object-margin">
    <text-box [view]="viewModel"
              [rows]="parameter.rowCount"
              (onValueChange)="onValueChange($event)"
              (onReadOnly)="onReadOnly($event)"
              (onDisabled)="onDisallowed($event)"
              (onHelpClick)="onHelpClick($event)">
    </text-box>
  </div>
</div>