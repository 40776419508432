<regular-popup *ngIf="visible"
               [id]="id"
               [title]="strings.Warning"
               [draggable]="true"
               referenceContainerId="configuratorContainer"
               [showHeader]="true"
               [okButtonText]="strings.Ok"
               [cancelButtonText]="strings.Cancel"
               (onClosed)="onPopupClosed()">

  <div card-type="header" *ngIf="clearedValueModels != null">
    <div class="row p-0 m-0">
      <co-image class="col-auto px-0" key="warning" imageSet="white" width="48px" height="48px"> </co-image>
      <div class="col modal-text-right align-middle">
        <p><em>{{strings.ParametersClearedDueToRules}}</em></p>
      </div>
    </div>
  </div>

  <div *ngIf="clearedValueModels != null" card-type="body" #body class="pt-4">
    <accordion [openFirst]="false" classes="accordion">
      <accordion-group *ngFor="let clearedValueModel of clearedValueModels"
                       icon="configuration"
                       [isOpened]="clearedValueModel.isActiveConf"
                       [heading]="clearedValueModel.conf.text"
                       [iconIsVault]="false"
                       [selectedDarkTheme]="true"
                       (onOpen)="onAccordionOpen(clearedValueModel.conf.longId)">
        <div *ngFor="let clearedParamInfo of clearedValueModel.clearedParamInfos" class="mr-3 mt-3">
          <visual-object-link [configurationId]="clearedValueModel.conf.longId" [paramInfo]="clearedParamInfo.paramInfo">
            <div class="row p-0 ml-2">
              <co-image *ngIf="clearedParamInfo.ruleReason != null" key="{{clearedParamInfo.ruleReason.ruleType}}" class="col-auto" width="24px" height="24px"> </co-image>
              <span class="col ml-2 px-0">
                {{clearedParamInfo.ruleReason ? clearedParamInfo.ruleReason.title : clearedParamInfo.messageReason}}
              </span>
            </div>
            <div class="row ml-5 mt-2">
              <co-image [key]="clearedParamInfo.paramInfo.parameterType" class="col-auto" width="24px" height="24px"> </co-image>
              <div class="col">
                <div class="static-label">{{clearedParamInfo.paramInfo.title}}</div>
                <div class="row py-1 ml-3" *ngIf="clearedParamInfo.paramInfo.value">{{clearedParamInfo.paramInfo.value}}</div>
                <div class="ml-3" *ngIf="clearedParamInfo.paramInfo.paramValues && clearedParamInfo.paramInfo.paramValues.size > 0">
                  <div *ngFor="let paramValue of clearedParamInfo.paramInfo.paramValues" class="row py-1">
                    {{paramValue.title}}
                  </div>
                </div>
              </div>
            </div>
            <hr class="mt-0" />
          </visual-object-link>
        </div>
      </accordion-group>
    </accordion>
  </div>
</regular-popup>
