import { Injectable, Inject } from '@angular/core';
import { SearchDataStore } from '../../../shared/providers/searchData';
import { ConfigurationSearch, SortingArgument } from '../../../shared/models';
import { ConfSearchModelCacheService } from '../confSearchModelCacheService';
import { PagerModel } from '../../../shared/components/pager/pagerModel';
import { Strings } from '../../../shared/providers/globalData';
import { ModelFactory, RouteRedirector } from '../../../shared/providers';
import { EmitterService } from '../../shared';
import { PopupIdentifiers } from '../../providers';
import { BlockUIService } from '../../../../shared/components/blockUI';
import { PagerService } from '../../../shared/components/pager/pagerService';

@Injectable()
export class SearchResultsDataHandler {

  constructor(
    @Inject(SearchDataStore) public searchDataStore: SearchDataStore,
    @Inject(Strings) public strings: Strings,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    @Inject(ConfSearchModelCacheService) public searchModelCache: ConfSearchModelCacheService,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    @Inject(EmitterService) public emitterService: EmitterService,
    @Inject(BlockUIService) public blockUIService: BlockUIService,
    @Inject(PagerService) public pagerService: PagerService
  ) { }

  public performSearch(configurationSearch: ConfigurationSearch) {
    this.emitterService.send(PopupIdentifiers.PlainUIBlock, false);
    this.blockUIService.start("blockui");

    if (this.searchModelCache.dataLoading)
      return;

    // Set the data to use it in resolver.
    this.searchModelCache.querySearchModel = configurationSearch;

    if (!this.searchModelCache.pagerModel)
      this.searchModelCache.pagerModel = this.pagerService.create();

    if (!this.searchModelCache.sortingArgument)
      this.searchModelCache.sortingArgument = this.modelFactory.createAny<SortingArgument>(SortingArgument.name);

    this.searchModelCache.dataLoaded = false;
    this.searchModelCache.dataLoading = true;

    this.routeRedirector.redirectToSearch().then(x => {

      this.routeRedirector.redirectToSearchResult({ searchSessionId: this.searchModelCache.searchSessionId });

    });

  }

}
