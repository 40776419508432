import { Component, Inject, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { InputViewModel } from "../../../shared/components";
import { AccountCommandTypes, AccountDataMessage } from "../../shared/models";
import { RouteRedirector } from "../../shared/providers";
import { AccountDataMessageProvider, AccountDataStore } from "../../shared/providers/accountData";
import { GlobalDataStore } from "../../shared/providers/globalData";
import { BaseAccountMessageComponent } from "../shared/baseAccountMessageComponent";


@Component({
  selector: 'new-email-verify',
  templateUrl: './signUpConfirmEmailComponent.html'
})
export class SignUpConfirmEmailComponent extends BaseAccountMessageComponent {

  user: string;

  constructor(
    @Inject(AccountDataStore) public accountStore: AccountDataStore,
    public accountMessageProvider: AccountDataMessageProvider,
    public globalDataStore: GlobalDataStore,
    public redirector: RouteRedirector,
    public activatedRoute: ActivatedRoute,
    public cd: ChangeDetectorRef
  ) {
    super(accountStore, accountMessageProvider, redirector, activatedRoute, cd);
  }

  onSuccess(message: AccountDataMessage): void {

    if (message.commandType == AccountCommandTypes.InitEmailVerification)
      this.redirector.redirectToSignUpVerify(this.createParamUrls(message));

    else if (message.commandType == AccountCommandTypes.VerifyCode)
      this.redirector.redirectToSignUp(this.createParamUrls(message));

  }

  public onUserSubmit(input: InputViewModel): void {

    // TODO: Stop the event propogation. It is being called twice.
    if (input.value) {
      this.sessionKey = input.value;
      this.accountStore.initAccountVerifyRequest(input.value);
    }

  }

  public onCodeSubmit(input: InputViewModel): void {

    // TODO: Stop the event propogation. It is being called twice.
    if (!input.value) 
      return;

    this.accountStore.accountVerifyCode(this.sessionKey, input.value);
    

  }

}