<ul class="{{config.corner}}" #notificationElement>
  <li *ngFor="let notification of notifications" id="{{notification.timestamp}}" class="px-4 {{notification.info.type?.toLowerCase() || 'default' }}" [class.animate]="notification.notify" [style.width.px]="config.width">
    <div class="row">
      <div class="col-2 align-top px-0 pt-3">
        <co-image width="64" height="64" type="svg" imageSet="white" key="{{notification.info.type?.toLowerCase()}}"></co-image>
      </div>
      <div class="col">
        <div class="row pt-3">
          <div class="col info-title">
            <h4 class="text-uppercase">
              {{ notification.info.title }}
            </h4>
          </div>
          <div class="col-auto">
            <a role="button" class="close-self d-flex justify-content-end pt-1" (click)="closeIt(notification)">
              <co-image *ngIf="!showFallbackIcon" width="16" height="16" type="svg" key="delete" (imageError)="onImageLoadError($event)" imageSet="{{notification.info.type?.toLowerCase()}}"></co-image>
              <span *ngIf="showFallbackIcon">
                <i class="fas fa-times"></i>
              </span>
            </a>
          </div>
        </div>
        <div *ngIf="notification.info.message" class="row pb-2">
          <div class="col align-middle pr-0">
            <p class="p-0 m-0 pt-2 info-message text-break" [innerHtml]="notification.info.message | safeHtml"></p>
          </div>
        </div>
      </div>
    </div>

    <div class="row p-2 mt-2 notificaton-table" *ngIf="notification.info.rows && notification.info.rows.length > 0">
      <div class="col">
        <div class="row">
          <div class="col p-2 mb-0 text-break" *ngIf="notification.info.rows.length>0">
            <div *ngFor="let notificationRow of notification.info.rows" class="row {{notificationRow.classes}}">
              <div *ngFor="let notificationCell of notificationRow.cells" class="col {{notificationCell.classes}}">{{notificationCell.message}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pb-2" *ngIf="notification.info.detail || notification.info.rawInfo">
      <div class="col">

        <!-- Menu -->
        <div class="row links py-1">
          <a role="button"
             class="col-auto"
             [class.selected]="notification.detailSelected"
             (click)="detailSelect($event, notification)">
            {{ messageButtonText }}
          </a>
          <span class="col-auto mx-4 separator"></span>
          <a role="button"
             class="col-auto"
             [class.selected]="notification.rawSelected"
             (click)="rawSelect($event, notification)">
            {{ detailButtonText }}
          </a>
        </div>
        <div class="row">
          <p class="col detail-message p-2 mb-0 text-break">{{ notification.detailSelected ? notification.info.detail : notification.info.rawInfo }}</p>
        </div>
      </div>
    </div>
  </li>
</ul>
