import { Injectable, Inject } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Conf } from "../../../shared/models";
import { ConfigurationSessionManager } from "../configurationSessionManager";
import { ConfDataResolveResponse } from "../confDataResolveResponse";
import { ConfRouteParams } from "../confRouteParams";
import { RouteRedirector } from '../../../shared/providers';
import { ConfPageSessionService } from '../confPageSessionService';
import { ConfiguratorUIStore } from '..';
import { setRouteConfParamValue, extractRouteParams } from '../../../../shared/utils/routeParamsUtils';

@Injectable()
export class ConfDataResolver implements Resolve<ConfDataResolveResponse> {

  constructor(
    @Inject(ConfigurationSessionManager) public sessesionManager: ConfigurationSessionManager,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    @Inject(ConfPageSessionService) public confPageSession: ConfPageSessionService,
    @Inject(ConfiguratorUIStore) public configuratorUIStore: ConfiguratorUIStore,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<ConfDataResolveResponse> {
        
    let confParams: ConfRouteParams = new ConfRouteParams();

    // Copy the route params to confParams
    extractRouteParams(confParams, route);
    
    this.configuratorUIStore.setConfParams(confParams);
    return new Promise<ConfDataResolveResponse>(resolve => {

      this.sessesionManager.setupLazyLoadingData(confParams).then(response => {

        if (!response || !response.conf) {
          this.routeRedirector.redirectToStart();
          return;
        }
        else {
          // If active configuration in URL is not correct then redirect it to root configuration. This scenaio only works when ShowChildrenInAccordion is enabled for root product.
          if (response.invalidateUrl) {
            let subConfId = confParams.id;
            confParams.id = response.conf.rootId;
            this.routeRedirector.redirectToDataLoaderComponent(confParams, null, subConfId);
            return;
          }

          resolve(response);
        }

      });

    });
  }

}