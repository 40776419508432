import { ParamInfo, RuleInfo } from ".."
import { ImmutableObject } from "../../../../../shared/immutableObject";

export class ClearedParamInfo extends ImmutableObject {

  protected readonly PARAM_INFO = "paramInfo";
  protected readonly RULE_REASON = "ruleReason";
  protected readonly MESSAGE_REASON = "messageReason";
  
  get paramInfo(): ParamInfo { return this.getInternalValue<ParamInfo>(this.PARAM_INFO); }
  setParamInfo(paramInfo: ParamInfo): this { return this.setInternalValue(this.PARAM_INFO, paramInfo); }

  get ruleReason(): RuleInfo { return this.getInternalValue<RuleInfo>(this.RULE_REASON); }
  setRuleReason(ruleReason: RuleInfo): this { return this.setInternalValue(this.RULE_REASON, ruleReason); }

  get messageReason(): string { return this.getInternalValue<string>(this.MESSAGE_REASON); }
  setMessageReason(messageReason: string): this { return this.setInternalValue(this.MESSAGE_REASON, messageReason); }
}