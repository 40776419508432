<backdrop-container>
  <regular-popup *ngIf="visible"
                 [id]="id"
                 [title]="strings.PriceSettings"
                 [showHeader]="false"
                 [showFooter]="true"
                 [height]="height"
                 [width]="width"
                 [okButtonText]="strings.Ok"
                 [cancelButtonText]="strings.Cancel"
                 (okClick)="okClick($event)"
                 (onClosed)="onPopupClosed()">

    <div card-type="body" class="p-4" *ngIf="message != null">
      <div>
        <dropdown class="pricelist-categories" [itemsView]="plcItemsView"
                  [view]="plcGroupView" (onValueChange)="onPriceListCategoryChange($event)">
        </dropdown>
      </div>
      <div *ngIf="plItemsView.length > 1" class="pt-4">
        <dropdown class="pricelist" [itemsView]="plItemsView"
                  [view]="plGroupView"
                  (onValueChange)="onPriceListChange($event)"
                  >
        </dropdown>
      </div>
      <div class="pt-4">
        <dropdown class="currency" [itemsView]="currencyItemsView"
                  [view]="currencyGroupView">
        </dropdown>
      </div>
    </div>
  </regular-popup>
</backdrop-container>
<message-box (okClick)="changeEffective($event)" [title]="strings.Note" [cancelButton]="false" [showCloseButton]="false"  [okButtonText]="strings.Ok"></message-box>