import { Injectable, Inject } from "@angular/core";
import * as Immutable from "immutable";
import { Strings } from "../../../../shared/providers/globalData";
import { SearchValueControlType, SearchCriteriaRegion, AttributeField } from "../models";
import { LookupParam, MultiChoiceParam, StringParam, IntParam, DoubleParam, BoolParam, ConfSearchProperty, ConfSearchCriteria, ConfSearchParameter } from "../../../../shared/models";
import { ModelFactory } from "../../../../shared/providers";
import { InputViewModel } from "../../../../../shared/components";

@Injectable()
export class SearchCriteriaHelper {

  constructor(@Inject(ModelFactory) protected modelFactory: ModelFactory, protected strings: Strings) { }

  getValueControlType(type: string, attributeName: string): SearchValueControlType {

    if (attributeName == 'ProductId' || attributeName == 'StateId')
      return SearchValueControlType.MultiSelectDropdown;

    switch (type) {

      case 'UnicodeString':
        return SearchValueControlType.Textbox;

      case 'RefToObject':
        return SearchValueControlType.Dropdown;

      case 'DateTime':
      case 'Date':
        return SearchValueControlType.DateRange;

      case 'Parameter':
        return SearchValueControlType.MultiSelectDropdown;

      case LookupParam.name:
        return SearchValueControlType.Dropdown;

      case BoolParam.name:
        return SearchValueControlType.Checkbox;

      case MultiChoiceParam.name:
        return SearchValueControlType.MultiSelectDropdown;

      case StringParam.name:
        return SearchValueControlType.Textbox;

      case DoubleParam.name:
      case IntParam.name:
      case "Integer":   // Integer configuration property
        return SearchValueControlType.RangeTextbox;

      default:
        return SearchValueControlType.None;
    }
  }

  uniqueIdentifier(name: string): string {
    return `${name}_${(new Date()).getMilliseconds()}_${Math.floor((Math.random() * 999) + 1)}`;
  }

  /**
   * Returns the selector attribute field
   * @param id
   * @param longId
   * @param region
   * @param type ReferenceClassName e.g Product, LookupParam or primitive data type  integar, string, datetime etc.
   * @param tag
   */
  getAttributeField(id: string, title: string, region: SearchCriteriaRegion, type?: string, tag?: any, emptyField: boolean = false, refClassName?: string): AttributeField {
    return <AttributeField>{ id: id, title: title, region: region, valueControlType: this.getValueControlType(type, id), tag: tag, valueType: type, isEmpty: emptyField, enabled: true, refClassName: refClassName };
  }

  public createConfSearchProperty(controlId: string, name: string, value: any): ConfSearchProperty {

    let confSearchProperty = this.modelFactory.createAny<ConfSearchProperty>(ConfSearchProperty.name);
    confSearchProperty = confSearchProperty.setName(name);    
    confSearchProperty = confSearchProperty.setValue(value);
    confSearchProperty = confSearchProperty.setControlId(controlId);
    
    return confSearchProperty;
  }

  /**
   * Creates the confSearchParameter model.
   * @param longId parameter longId
   * @param value value If parameter type is StringParam, IntParam or BoolParam.
   * @param paramValueId If parameter is lookup or multichoice.
   */
  public createConfSearchParameter(controlId: string, parameterId: number, value: any, paramValueId: number): ConfSearchParameter {

    let confSearchParam = this.modelFactory.createAny<ConfSearchParameter>(ConfSearchParameter.name);    
    confSearchParam = confSearchParam.setParameterId(parameterId);
    confSearchParam = confSearchParam.setValue(value);
    confSearchParam = confSearchParam.setControlId(controlId);
    confSearchParam = confSearchParam.setParamValueId(paramValueId);

    return confSearchParam;
  }

  /**
   * Groups the confSearchCriterias by region and inner data is grouped by client criterias.
   * Example date: {confProperty: { [Identify], [Name], [ProductId, ProductId]}}
   * @param confSearchCriterias
   */
  public groupConfSearchCriteria(confSearchCriterias: Immutable.List<ConfSearchCriteria>): Map<SearchCriteriaRegion, Map<string, Array<ConfSearchCriteria>>> {

    if (!confSearchCriterias || confSearchCriterias.size == 0)
      return new Map<SearchCriteriaRegion, Map<string, Array<ConfSearchCriteria>>>();

    let result = new Map<SearchCriteriaRegion, Map<string, Array<ConfSearchCriteria>>>();  

    // common control ids for grouping products and states
    let newGroupedControlIds = new Map<string, string>();

    confSearchCriterias.forEach((x, index) => {

      let region: SearchCriteriaRegion = x instanceof ConfSearchProperty ? SearchCriteriaRegion.ConfProperty : SearchCriteriaRegion.Parameter;

      if (!result.has(region))
        result.set(region, new Map<string, Array<ConfSearchCriteria>>());

      // group product, state and multi choice search criterias (has a paramvalueid) if their control ids aren't set
      if ((x.name == 'ProductId' || x.name == 'StateId') && !x.controlId && !newGroupedControlIds.has(x.name)) {
        newGroupedControlIds.set(x.name, this.uniqueIdentifier(x.name));
      }
      else if (x instanceof ConfSearchParameter && x.paramValueId && !newGroupedControlIds.has("" + x.parameterId)) {
        newGroupedControlIds.set("" + x.parameterId, this.uniqueIdentifier("" + x.parameterId));
      }

      // If saved search is opened then controlId would be null, is assigned here and stored in the key of the map belonging to a region
      let controlId: string = x.controlId ? x.controlId : (x.name == 'ProductId' || x.name == 'StateId') ? newGroupedControlIds.get(x.name) :
        x instanceof ConfSearchParameter && newGroupedControlIds.has("" + x.parameterId) ? newGroupedControlIds.get("" + x.parameterId) : this.uniqueIdentifier("" + x.longId);       

      if (!result.get(region).has(controlId))
        result.get(region).set(controlId, []); // Empty array

      result.get(region).get(controlId).push(x);
      
    });

    return result;
  }

  public createInputView(id: string, name: string, title: string, value: any, icon?: string, width?: string): InputViewModel {
    let view = new InputViewModel();
    view.name = name;
    view.title = this.strings.getString(title);
    view.value = value;
    view.icon = icon;
    view.width = width;
    view.iconImageSet = 'primary-darker';
    view.id = id;
    return view;
  }

}