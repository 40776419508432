<div class="accordion-overlay container-fluid" >
  <div *ngIf="showHeader" class="row header align-middle">
    <div class="col title" >
      {{title}}
    </div>    
    <div class="col-auto close hide">
      <a href="#" tabindex="-1" (click)="close.emit($event)">
        <co-image key="delete" width="24px" height="24px"> </co-image>
      </a>
    </div>
  </div>
  <div class="row">
    <div class="col px-0">
      <ng-content></ng-content>
    </div>
  </div>
</div>