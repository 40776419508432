<div class="price-value-container row {{state}}">

  <!-- Show if editable -->
  <div class="actions col-auto edit-container" *ngIf="!isReadOnly">
    <div class="row">
      <div class="col-auto edit-actions">
        <co-image key="delete" width="20px" height="20px" imageSet="red" role="button" (click)="removeEditMode($event)"></co-image>
        <co-image key="checkmark" width="24px" height="24px" imageSet="primary" role="button" (click)="onEditorValueChange($event)"></co-image>
      </div>
      
      <div class="col d-flex">
        <text-box [view]="inputView" [useFormatting]="true" #textbox> </text-box>

        <div class="value-wrapper" >
          <span class="real-price">{{price}}</span>
          <span *ngIf="rawManualPrice != null" class="manual-price">{{manualPrice}}</span>
        </div>
      </div>

    </div>
  </div>

  <!-- Show manual price -->
  <div class="actions col-auto manual-container" *ngIf="rawManualPrice != null && !isReadOnly">

    <div class="actions col-auto px-0 manual-actions">

      <co-image key="edit" width="20px" height="20px" imageSet="primary" role="button" (click)="showEditModel()"></co-image>
      <co-image key="revise" width="20px" height="20px" imageSet="primary" role="button" (click)="undoValue()"></co-image>

    </div>

    <span class="real-price">{{price}}</span>
    <span class="manual-price">{{manualPrice}}</span>

  </div>

  <div class="d-flex col-auto" *ngIf="isReadOnly">    
      <span class="real-price">{{price}}</span>
      <span *ngIf="rawManualPrice != null" class="manual-price">{{manualPrice}}</span>    
  </div>

</div>
