import * as Immutable from "immutable";
import { BaseEntity } from "../../baseEntity";

export class Customer extends BaseEntity {

  protected readonly NAME = "name";
  protected readonly CUSTOMER_NO = "customerNo";
  protected readonly CUSTOMER_SITE_IDS = "customerSiteIds";
  protected readonly CUSTOMER_CONTACT_IDS = "customerContactIds";  

  get name(): string { return this.getInternalValue<string>(this.NAME); }
  setName(name: string): this { return this.setInternalValue(this.NAME, name); }

  get customerNo(): string { return this.getInternalValue<string>(this.CUSTOMER_NO); }
  setCustomerNo(customerNo: string): this { return this.setInternalValue(this.CUSTOMER_NO, customerNo); }

  get customerSiteIds(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.CUSTOMER_SITE_IDS); }
  setCustomerSiteIds(customerSiteIds: Immutable.List<number>): this { return this.setInternalValue(this.CUSTOMER_SITE_IDS, customerSiteIds); }

  get customerContactIds(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.CUSTOMER_CONTACT_IDS); }
  setCustomerContactIds(customerContactIds: Immutable.List<number>): this { return this.setInternalValue(this.CUSTOMER_CONTACT_IDS, customerContactIds); }
}