import * as Immutable from "immutable"
import { AbstractComparisonDataMessage } from "./abstractComparisonDataMessage";

export class ComparisonOrderChangeMessage extends AbstractComparisonDataMessage {

  protected readonly SUCCESS = "success";

  get success(): boolean { return this.getInternalValue<boolean>(this.SUCCESS); }
  setSuccess(success: boolean): this { return this.setInternalValue(this.SUCCESS, success); }

}