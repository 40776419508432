<div class="menu-item" title="{{revision.title}}">
  <div class="row w-100 m-0 pt-2 align-middle" [class.active]="revision.configurationId === conf.longId">
    <div class="col-auto pr-0 hover" role="button" (click)="onClick()">
      <co-image width="32" height="32" type="svg" key="configuration" imageSet="{{imageSet}}"></co-image>
    </div>
    <div class="col pr-0 hover" role="button" (click)="onClick()">
      <div class="title label">{{revision.title}}</div>
      <div>
        <small>{{strings.CreationDate}}: </small>
        <small>{{revision.lastUpdated}}</small>
      </div>
    </div>
    <div *ngIf="showCheckbox" class="col-2 px-0">
      <check-box type="standard" [view]="checkboxView" (onValueChange)="onValueChange.emit($event)"></check-box>
    </div>
  </div>
</div>
