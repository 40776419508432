import { Component, Inject } from "@angular/core";
import { CodeDecorationValuesModel } from "../../../shared/components/code/codeDecorationValueModel";
import { CodeValueModelBuilder } from "../../../shared/components/code/providers/codeValueModelBuilder";
import { CodeDecoration, CodeDisplayStyles, Conf } from "../../../shared/models";
import { ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { VisualObjectVisibilityService } from "../../shared";
import { DecorationComponent } from "../../shared/decorationComponent";
import { VisualObjectHelper } from "../../parameters/shared";

@Component({
  selector: 'code-decoration',
  templateUrl: './codeDecorationComponent.html'
})
export class CodeDecorationComponent extends DecorationComponent<CodeDecoration> {

  public height: string;
  
  public valuesModel: CodeDecorationValuesModel;

  public isRender = true;

  constructor(public confStore: ConfiguratorStore,
    public confPageSessionService: ConfPageSessionService,
    public visibilityService: VisualObjectVisibilityService,
    @Inject(VisualObjectHelper) public visualObjectHelper: VisualObjectHelper,
    public builder: CodeValueModelBuilder) {

    super(confStore, confPageSessionService, visualObjectHelper);

  }

  ngOnInit() {

    this.height = this.visualObjectHelper.decorationHeight(this.decoration);
    this.width = this.visualObjectHelper.visualObjectWidth(this.decoration);
    this.isRender = this.decoration.displayStyle === CodeDisplayStyles.Render;

    super.ngOnInit();
  }  

  public listenChanges(): void {

    this.confStore.onConfCodeValuesChange(this.configurationId, this.confSessionId,
      (): void => {
        const uiItem = this.visibilityService.findConfUIItem(this.confPageSessionService.activeConfiguration, +this.id);

        // Get values model which contain the values for each decoration belonging to current <ConfUIItem>
        if (uiItem !== null)
          this.valuesModel = this.builder.build(uiItem, true, [CodeDisplayStyles.Render, CodeDisplayStyles.Popup, CodeDisplayStyles.DownloadLink]).get(0);

      }).unsubscribeOn(this.unsubscribeSubject);
  }
}
