import { Injectable, Inject, Component } from "@angular/core";
import { InputViewModel } from "../../../../shared/components";
import { MultiChoiceItemViewModel } from "../../../../shared/components/multiChoice";
import { VisualObject, Param, MultiChoiceValue, LookupParam, LookupValue, MultiChoiceParam } from "../../../shared/models";
import { BaseEntity } from "../../../shared/baseEntity";
import { VisualObjectHelper } from "./visualObjectHelper";
import { GlobalDataStore } from "../../../shared/providers/globalData";

/**
 * TODO: Refactor class signature.
 */
@Injectable()
export class VisualObjectViewModelFactory {

  constructor( @Inject(GlobalDataStore) private settings: GlobalDataStore    
  ) { }

  create(entity: BaseEntity): InputViewModel {
    let view = this.getInstance(entity);

    // Get param
    let param = entity as Param;
    view.id = param.longId.toString();
    // TODO FIX issue 41 in issue list to get rid of this if check
    if (param instanceof Param) {
      view.id = param.visualObjectId.toString();     
    }
    
    view.name = param.name;
    view.title = param.title;

    let paramUISettings = this.settings.getGlobalData().uiSettings.configurator.parameter;
    view.iconWidth = paramUISettings.iconSize;
    view.iconHeight = paramUISettings.iconSize;
    view.helpIconWidth = paramUISettings.helpIconSize;
    view.helpIconHeight = paramUISettings.helpIconSize;

    let hasHelpImageOrHelpText: boolean = (param.helpImageRelativeUrl || param.helpText) ? true : false;
    let hasExternalLink: boolean = param.externLink && param.externLink.trim() != '';
    view.enableHelp = hasHelpImageOrHelpText || hasExternalLink;

    // Only show external link if help image or text not found.        
    view.enableExternalLink = !hasHelpImageOrHelpText && hasExternalLink;
    if (view.enableExternalLink)
      view.externalLink = param.externLink;

    view.icon = param.iconRelativeUrl;
    view.isVaultIcon = true;

    return view;
  }

  private getInstance(visualObject: BaseEntity): InputViewModel {
    // TODO: It requires more work. Use generics.

    //if (visualObject instanceof LookupParam)
    //  return new RadioGroupViewModel();

    //if (visualObject instanceof LookupValue)
    //  return new RadioButtonViewModel();

    //if (visualObject instanceof MultiChoiceParam)
    //  return new MultiChoiceGroupViewModel();

    //if (visualObject instanceof MultiChoiceValue)
    //  return new MultiChoiceItemViewModel();

    return new InputViewModel();
  }

  public setNormalState(views: InputViewModel[]): void {

    views.forEach(x => {
      x.disabled = false;
      x.readOnly = false;
      x.value = null;
    });
  }

  public setValue(views: InputViewModel[], id: string, value: any): InputViewModel {

    for (let v of views) {
      if (v.id != id)
        continue;

      v.value = value;
      return v;
    }
  }

  public setDisabled(views: InputViewModel[], id: string, status: boolean): InputViewModel[] {

    let newViews: InputViewModel[] = [];
    for (let v of views) {
      if (v.id != id) {
        newViews.push(v);
        continue;
      }

      if (v.disabled == status) {
        newViews.push(v);
      }
      else {
        v.disabled = status;
        newViews.push({ ...v });
      }
    }

    return newViews;
  }

  // Returns immutable result to handle change detection.
  public setReadOnly(views: InputViewModel[], id: string, status: boolean): InputViewModel[] {

    let newViews: InputViewModel[] = [];
    for (let v of views) {

      if (v.id != id) {
        newViews.push(v);
        continue;
      }

      if (v.readOnly == status) {
        newViews.push(v);
      }
      else {
        v.readOnly = status;
        newViews.push({ ...v });       
      }
      
    }

    return newViews;
  }

}