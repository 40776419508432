import * as Immutable from "immutable";

import { ConfValue } from "./confValue";

export class ConfLookupValue extends ConfValue {

  protected readonly VALUE = "value";
  protected readonly DISALLOWED_PARAM_VALUES = "disallowedParamValues";  

  constructor(data?: Immutable.Map<string, any>) {
    super(data);
  }

  get value(): number { return this.getInternalValue<number>(this.VALUE); }
  setValue(value: number): this { return this.setInternalValue(this.VALUE, value); }

  get disallowedParamValues(): Immutable.Map<number, boolean> { return this.getInternalValue<Immutable.Map<number, boolean>>(this.DISALLOWED_PARAM_VALUES); }
  setDisallowedParamValues(disallowedParamValues: Immutable.Map<number, boolean>): this { return this.setInternalValue(this.DISALLOWED_PARAM_VALUES, disallowedParamValues); }
}