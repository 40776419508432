<div *ngIf="roots.length > 0" class="treeview">  
    <ul class="list-group">       
      <tree-node 
                 *ngFor="let r of roots" 
                 [node]="r" 
                 [dataProvider]="treeDataProvider"                  
                 [imageSet]="imageSet"
                 class="root"
                 ></tree-node>                                      
    </ul>
</div>