import { ChangeDetectorRef, Component, EventEmitter, Inject, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { InputViewModel, NotificationInfo, NotificationService, NotificationType } from "../../../shared/components";
import { AccountDataMessage } from "../../shared/models";
import { RouteRedirector } from "../../shared/providers";
import { AccountDataMessageProvider, AccountDataStore } from "../../shared/providers/accountData";
import { BaseAccountMessageComponent } from "../shared/baseAccountMessageComponent";

@Component({
  selector: 'password-change',
  templateUrl: './passwordChangeComponent.html'
})
export class PasswordChangeComponent extends BaseAccountMessageComponent {
  
  public newPasswordView: InputViewModel;
  public confirmNewPasswordView: InputViewModel;
  
  @Output()
  passwordChanged = new EventEmitter();
  
  constructor(
    @Inject(AccountDataStore) public accountStore: AccountDataStore,
    public accountMessageProvider: AccountDataMessageProvider,
    public redirector: RouteRedirector,
    public activatedRoute: ActivatedRoute,
    public cdr: ChangeDetectorRef,    
    public notificationService: NotificationService
  ) {
    super(accountStore, accountMessageProvider, redirector, activatedRoute, cdr);
  }

  ngOnInit() {
    
    this.newPasswordView = new InputViewModel(this.strings.NewPassword);
    this.confirmNewPasswordView = new InputViewModel(this.strings.ConfirmNewPassword);
    this.newPasswordView.icon = this.confirmNewPasswordView.icon = "password";

    super.ngOnInit();
  }

  public validatePassword(): boolean {

    // New password is mandatory
    if (!this.newPasswordView.value) {
      this.newPasswordView.errors = [this.strings.ChangePasswordErrorMessage_SpecifyNewPassword];
      return false;
    }

    // Confirm new password is mandatory
    if (!this.confirmNewPasswordView.value) {
      this.confirmNewPasswordView.errors = [this.strings.ChangePasswordErrorMessage_SpecifyNewPassword];
      return false;
    }

    // New passwords must match
    if (this.newPasswordView.value !== this.confirmNewPasswordView.value) {
      this.confirmNewPasswordView.errors = [this.strings.ChangePasswordErrorMessage_NewPasswordsNoMatch];
      return false;
    }

    return true;
  }

  onSuccess(message: AccountDataMessage): void {

    // Success message
    this.notificationService.notify(<NotificationInfo>{
      title: this.strings.Success,
      message: this.strings.PasswordsChangedSuccessMessage,
      type: NotificationType.Success,
      selfClose: true
    });

    this.accountStore.logInUser(this.user, this.newPasswordView.value);

  }

  public changePassword() {
    
    if (this.validatePassword())
      this.accountStore.recoverPassword(this.sessionKey, this.newPasswordView.value);    

  }

}