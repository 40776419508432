import { Inject, Component, EventEmitter, Output, Input, HostListener } from '@angular/core';
import { BasePopupComponent } from './basePopupComponent';

/**
 * <backdrop> component.
 *
 * This is the parent component to <popup> that validates the state internally
 * and coordinates the backdrop and content styling.
 */
@Component({
  selector: 'backdrop-container',
  templateUrl: './backdropComponent.html'
})
export class BackdropComponent {

  // Backdrop visibility.
  // Note! Don't need to wrap popups with <backdrop> component If backdrop is not intended.
  // In some cases If you need backdrop conditionally then you can wrap it and set the visibility.
  // In combinum we have some scenarios where popups are floated with backdrop. But when they are docked inline we don't show the backdrop.
  _visible: boolean = true;
  set visible(value: boolean) {
    this._visible = value;

    // Normalize the position again. Sticky/UnSticky adds/removes the backdrop. We need to recalculate the z-index style;
    this.popup.normalizeSize();
    this.updateBackDropStyle();
  }

  get visible(): boolean {
    return this._visible;
  }

  @Input() contentClass: string;
  @Input() backdropClass: string;
  @Output() onClosed = new EventEmitter();

  contentStyle: CSSStyleDeclaration;
  popup: BasePopupComponent;

  add(popup: BasePopupComponent): void {
    this.popup = popup;
  }

  /**
  * @internal
  *
  * Computes `margin` value to push page contents to accommodate open sidebars as needed.
  *
  * @return margin styles for the page content.
  */
  updateBackDropStyle(): void {

    let transformStyle: string = null;
    let heightStyle: string = null;
    let widthStyle: string = null;


    //padding: `${top}px ${right}px ${bottom}px ${left}px`,
    this.contentStyle = <CSSStyleDeclaration>{
      left: '0px',
      right: '0px',
      webkitTransform: transformStyle,
      transform: transformStyle,
      height: heightStyle,
      width: widthStyle,
      zIndex: this.visible ? "99" : null // Backdrop must have less value than popups/sidebars.
    };
  }

  public close() {
    if (this.popup && this.popup.isOpened && this.visible) {

      if (new String(this.popup.closeOnOutSideClick) == "false")
        return;

      this.popup.close();

      this.onClosed.emit();
    }
  }

  onBackdropClicked(event): void {
    this.close();

    event.stopPropagation();
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(evt: KeyboardEvent) {
    // Make popups closable on escape
    this.close();
  }
}