import { Inject, Injectable } from "@angular/core";
import { ManagedSubject } from "../../../../../shared";

export enum PriceEditorActions {

  RowSelected = 'row-selected',
  Undo = 'undo',
  Update = 'update',  
  Cancel = 'cancel',
  Edit = 'edit',
  None = 'none'
  
}

/**
 * Three states of price report row.
 * Normal : Readonly state
 * 
 * */
export enum PriceReportRowState {
  Normal = 'normal',
  Editted = 'editted',
  Edit = 'edit'
}

export interface IPriceEditorCommandArgs {
  action: PriceEditorActions;
  id: number;
  value?: number;
}

@Injectable()
export class PriceEditorService {

  private subject = new ManagedSubject<IPriceEditorCommandArgs>(null, false, 0, false, false);

  public send<T>(action: PriceEditorActions, id: number): void {

    let info: IPriceEditorCommandArgs = <IPriceEditorCommandArgs>{
      action: action,
      id: id
    }

    this.subject.nextValue(info);
  }

  public getMessage(): ManagedSubject<IPriceEditorCommandArgs> {
    return this.subject;
  }

  public notify<T>(info: IPriceEditorCommandArgs): void {
    this.getMessage().nextValue(info);
  }

}