<div class="h-100 flex-column d-flex" role="tablist" aria-multiselectable="true">
  <tab-links 
             *ngIf="tabModels.length > 0 && position == 'top'" 
             [models]="tabModels" 
             position="top" 
             (activeLink)="selected.emit($event)" 
             [defaultSelectedTabId]="defaultSelectedTabId" ></tab-links>
  
  <ng-content></ng-content>

  <tab-links *ngIf="tabModels.length > 0 && position == 'bottom'" [models]="tabModels" position="bottom" (activeLink)="selected.emit($event)" [defaultSelectedTabId]="defaultSelectedTabId" ></tab-links>
</div>