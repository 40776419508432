import * as Immutable from "immutable";
import { ImmutableObject } from "../../../../shared/immutableObject";

export class GridRow extends ImmutableObject {

  protected readonly ID = "id";
  protected readonly NAME = "name";
  protected readonly READ_ONLY = "readOnly";
  protected readonly VALUES = "values";

  get id(): number { return this.getInternalValue<number>(this.ID); }
  setId(id: number): this { return this.setInternalValue(this.ID, id); }

  get name(): string { return this.getInternalValue<string>(this.NAME); }
  setName(name: string): this { return this.setInternalValue(this.NAME, name); }

  get readOnly(): boolean { return this.getInternalValue<boolean>(this.READ_ONLY); }
  setReadOnly(readOnly: boolean): this { return this.setInternalValue(this.READ_ONLY, readOnly); }

  get values(): Immutable.Map<string, string> { return this.getInternalValue<Immutable.Map<string, string>>(this.VALUES); }
  setValues(columnValues: Immutable.Map<string, string>): this { return this.setInternalValue(this.VALUES, columnValues); }
}