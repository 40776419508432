import { Inject, Component, Input, ViewChild, forwardRef, ElementRef, HostListener, ChangeDetectorRef, SimpleChanges } from "@angular/core";

import { ConfInfo, Conf } from "../../shared/models";
import { BaseComponent } from "../../shared";
import { ConfigurationLinkComponent } from "../../configurator/shared/configurationLinkComponent";
import { PopperConfig } from "../../../shared/components/popover/popoverInfo";
import { PopoverService } from "../../../shared/components/popover/popoverService";
import { ManagedSubscription } from "../../../shared/managedSubscription";
import { Breakpoints, ImageSets } from "../../../shared/utils";

// This needs to be moved from start

@Component({
  selector: 'configuration-link-control',
  templateUrl: './configurationLinkControlComponent.html'
})
export class ConfigurationLinkControlComponent extends BaseComponent {

  public readonly DEFAULT_IMAGE = "configuration";
  public icon: string = "";
  public isVault: boolean = false;
  public menuSelected: boolean = false;
  public messageSubscription: ManagedSubscription;
  public confImageSet: string;
  
  @ViewChild('menuButton')
  public menuButton: ElementRef;

  _conf: ConfInfo;
  @Input() set conf(value: ConfInfo) {
    this._conf = value;
    this.icon = this.hasImage ? this._conf.iconRelativeUrl : this.DEFAULT_IMAGE;
    this.isVault = this.hasImage;    
  };

  @Input()
  confSessionId: number;

  @Input()
  searchSessionId: number;

  @Input()
  menuSupport: boolean = true;

  @Input()
  public compactMode: boolean = false;

  constructor(@Inject(PopoverService) public popoverService: PopoverService, @Inject(ChangeDetectorRef) public cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {

    this.confImageSet = this.getImageSet(ImageSets.StartConfiguration);

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['compactMode']) {
      this.cd.detectChanges();
    }
  }

  public subscribe() {
    this.unsubscribe();

    this.messageSubscription = this.popoverService.getMessage().subscribe((info: PopperConfig) => {
      // Deselect the configuration
      if (!info.open || info.tag.configurationId != this._conf.longId) {
        this.menuSelected = false;
        this.unsubscribe();
      }
    });
  }

  get hasImage(): boolean {
    return this._conf.iconRelativeUrl != "";
  }

  onMenuClick(event: any): void {
    this.menuSelected = !this.menuSelected;

    // Prepare context menu Info.
    let info = <PopperConfig>{
      title: this.strings.PerformActionOn,
      subTitle: this._conf.text,
      open: this.menuSelected,
      refElement: this.menuButton.nativeElement,
      tag: {
        configurationId: this._conf.longId,
        confSessionId: this.confSessionId,
        searchSessionId: this.searchSessionId,
      }
    };

    this.popoverService.show(info);
    event.stopPropagation();

    this.subscribe();
  }

  public unsubscribe() {
    if (this.messageSubscription)
      this.messageSubscription.unsubscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();

    super.ngOnDestroy();
  }
}
