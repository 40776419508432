<div [attr.name]="group.name" class="grow-visual-object w-100">
  <!--<div *ngIf="child.lineBreak" class="visual-object-line-break" name="line-break"></div>-->
  <visual-object-factory [visualObjectId]="group.id" [configurationId]="configurationId" class="grow-visual-object"></visual-object-factory>
  
  <!-- Loop through all hangings -->
  <ng-container *ngFor="let child of hangings">

    <visual-object-group attr.id="{{child.id}}"
                         [group]="child"
                         class="{{child.cssClass}}"
                         attr.name="{{child.name}}"
                         [service]="service"
                         [configurationId]="configurationId"
                         [visualStyle]="child.visualObject"></visual-object-group>

    <!-- Line break If not floating in hanging objects -->
    <div class="visual-object-line-break" [style.width.px]="100" name="line-break"></div>
  </ng-container>
</div>