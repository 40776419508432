<configuration-link [configurationId]="_conf.longId" [sessionId]="confSessionId" view="summary">
<!--<configuration-link [configurationId]="_conf.longId" [sessionId]="confSessionId" view="summary">-->
  <div class="row m-0 hide-overflow w-100 configuration-link" [class.selected]="menuSelected" [class.compact]="compactMode">
    <span *ngIf="!compactMode" class="col-auto p-1 align-middle" [style.min-width.px]="'40'">
      <co-image [isVault]="isVault" [imageSet]="confImageSet" key="{{icon}}" type="svg" class="d-block" width="40" height="40"></co-image>
    </span>
    <div class="col px-3 hide-overflow link-detail">
      <div>
        <span class="title">{{compactMode ? _conf.compact : _conf.text}}</span>
      </div>
      <div *ngIf="!compactMode" class="description" [innerHtml]="_conf.description"></div>
    </div>
    <div *ngIf="menuSupport" class="col-auto menu-item align-middle mobile-flex" [class.menu-selected]="menuSelected" role="button" (click)="onMenuClick($event)" #menuButton>
      <co-image type="svg" key="menu-2" [imageSet]="confImageSet" [width]="'16'" [height]="'16'"></co-image>
    </div>
  </div>
</configuration-link>