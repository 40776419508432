import { NgModule } from '@angular/core';
import { DragScrollComponent } from './dragScrollComponent';
import { DragScrollItemDirective } from './dragScrollItemDirective';

export { DragScrollItemDirective } from './dragScrollItemDirective';
export { DragScrollComponent } from './dragScrollComponent';


@NgModule({
  exports: [
    DragScrollComponent,
    DragScrollItemDirective
  ],
  declarations: [
    DragScrollComponent,
    DragScrollItemDirective
  ]
})
export class DragScrollModule { }
