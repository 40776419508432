import { Subject, Observable } from 'rxjs';
import { MultiSelectItem } from './multiSelectModels';

export interface MultiSelectEvent {
  item: MultiSelectItem;
  actionType: MultiSelectAction
}

export enum MultiSelectAction {
  DropDownItemClick = 'clcik',
  DropDownItemRemoved = 'removed',
  DropdownOpen = 'dropdownOpen'
}

export class MultiSelectEventService {

  // Subject to subscribe the Popup.
  private subject = new Subject<MultiSelectEvent>();

  /**
  * Broadcasts the message to all subscribers
  * @param event
  */
  public sendMessage(info: MultiSelectEvent): void {
    this.subject.next(info);
  }

  public get() {
    return this.subject;
  }

}