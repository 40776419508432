import { Dispatch } from "redux";

import { AppAction } from "./appAction"
import { AppState } from "./appState"

export class UIAction<T> extends AppAction<T> {

  executeAsync: (dispatch?: Dispatch<any>, getState?: () => AppState, callback?: (ActionStatus) => void) => void;

  /** TODO is PageID needed? It's probably not needed any more and can be removed. */
  pageId?: number = 0;

  //status?: string;
}