import { Input, Inject, Component } from "@angular/core";
import { HttpService } from "../../providers";
import { GlobalDataStore } from "../../../pages/shared/providers/globalData";

@Component({
  selector: 'custom-link',
  templateUrl: './customLinkComponent.html',
})
export class CustomLinkComponent {

  @Input()
  public targetSelf: boolean = false;

  @Input()
  public clickable: boolean = true;

  @Input()
  public url: string;

  @Input()
  public title: string;

  @Input()
  public extraStyles: string;

  @Input()
  public icon: string;

  @Input()
  public defaultIcon = 'document';

  @Input()
  public showIcon: boolean = true;

  @Input()
  public isRemote: boolean;

  @Input()
  public imageSet = 'primary';

  public linkTarget: string = "_blank";

  constructor(@Inject(HttpService) public httpService: HttpService,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore
  ) { }

  ngOnInit() {
    if (this.isRemote == null)
      this.isRemote = this.globalDataStore.getGlobalData().webSettings.isVaultDirectoryRemote;

    this.linkTarget = this.targetSelf ? "_self" : "_blank";
  }

  public openLink() {
    this.httpService.downloadFile(this.url);
  }

}