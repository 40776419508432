import * as Immutable from "immutable";
import { BaseEntity } from "../../baseEntity";
import { ConfValue } from "./confValue";

export class ConfStringValue extends ConfValue {

  protected readonly VALUE = "value";

  get value(): string { return this.getInternalValue<string>(this.VALUE); }
  setValue(value: string): this { return this.setInternalValue(this.VALUE, value); }
}