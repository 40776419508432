import * as Immutable from "immutable";
import { NodeData } from "./nodeData";

export abstract class TreeCacheService {

  // Stores object by Id.
  private objectById: Immutable.Map<number, NodeData> = Immutable.Map<number, NodeData>();

  /**
   * Adds node to the cache
   * @param node
   */
  public addToCache(node: NodeData): void {

    this.objectById = this.objectById.set(node.id, node);
  }

  /**
   * Removes the node from cache.
   * @param node
   */
  public removeFromCache(node: NodeData): void {

    this.objectById = this.objectById.remove(node.id);
  }

  /**
   * Returns the node data.
   * @param id
   */
  public get(id: number): NodeData { return this.objectById.get(+id); }

  /**
   * Returns 'true' if node exists in cache.
   * @param id
   */
  public has(id: number): boolean { return this.objectById.has(+id); }

}