import { Component, Input } from "@angular/core";
import { BaseInputComponent } from "../shared";

@Component({
  selector: 'password',
  templateUrl: './passwordComponent.html'
})
export class PasswordComponent extends BaseInputComponent {

  @Input()
  isNewPassword: boolean = false;

  processEvent($event): void {
    // Clear any errors.
    this.errors = [];
    if (this.view)
      this.view.errors = [];

    this.notifyValueChange(this.view, $event.target.value);
  }

  getMoreStyles(): string {
    return this.view.icon ? "has-icon" : "";
  }

}