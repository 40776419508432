import { Inject, Component } from "@angular/core";
import { Subscription } from "rxjs";

import { BaseComponent } from "../../shared";
import { PopperConfig } from "../../../shared/components/popover/popoverInfo";
import { PopperContentComponent } from "../../../shared/components/popover/popperContentComponent";
import { PopoverService } from "../../../shared/components/popover/popoverService";
import { Conf, RequestViews } from "../../shared/models";
import { ManagedSubscription } from "../../../shared/managedSubscription";
import { ConfiguratorStore } from "../../configurator/providers";


@Component({
  selector: 'configuration-context-menu',
  templateUrl: './configurationContextMenuComponent.html'
})
export class ConfigurationContextMenuComponent extends BaseComponent {

  public configurationId: number;
  public confSessionId: number;
  public searchSessionId: number;
  public showRevision: boolean = false;
  public waitingState: boolean = false;
  public disableRevision: boolean = false;

  public messageSubscription: ManagedSubscription;

  constructor(@Inject(PopoverService) public popoverService: PopoverService,
              @Inject(ConfiguratorStore) public confStore: ConfiguratorStore
) {
    super();
  }

  ngOnInit(): void {
    this.messageSubscription = this.popoverService.getMessage().subscribe((info: PopperConfig) => {
      if (info.open) {
        this.configurationId = info.tag.configurationId;
        this.confSessionId = info.tag.confSessionId;
        this.searchSessionId = info.tag.searchSessionId;


        let conf: Conf = this.confStore.getConf(this.configurationId, this.confSessionId);
        this.disableRevision = conf.authorization.isReviseOperationDisallowed;

        this.showRevision = conf.revisions && conf.revisions.size > 0;
        this.waitingState = true;
        this.loadFullConfiguration();
      }
      else {
        this.configurationId = this.confSessionId = this.searchSessionId = null;
        this.showRevision = false;
        this.waitingState = true;
        // this.loadFullConfiguration();
      }
    });
  }

  loadFullConfiguration(): void {

    this.confStore.loadConf(this.configurationId, this.confSessionId, RequestViews.Start).then(response => {

      this.waitingState = false;

    });

  }

  ngOnDestroy() {
    if (this.messageSubscription)
      this.messageSubscription.unsubscribe();

    super.ngOnDestroy();
  }
}
