import * as Immutable from "immutable";
import { Subscription, Subject, BehaviorSubject } from "rxjs";
import { Observable } from "rxjs";
import { NodeData } from "./nodeData";
import { TreeCacheService } from "./treeCacheService";
import { NodeEvent, NodeActions, EventSource } from "./treeEvents";

export abstract class TreeDataProvider extends TreeCacheService {

  // Subject to subscribe the tree nodes.  
  private subject = new Subject<NodeEvent>();

  /**
   * Loads the root nodes.
   * @param callback
   */
  abstract loadRoots(callback: (children: NodeData[]) => void): void;


  /**
   * Loads the children.
   * @param parent
   * @param callback
   */
  abstract loadChildren(parent: NodeData, callback: (children: NodeData[]) => void): void;

  /**
   * Returns true if node is expandable and vice versa.
   * @param node
   */
  abstract isNodeExpandable(node: NodeData): boolean;

  /**
   * Activates the expand/collapse behvaior when making click on title.
   * @param node
   */
  enableExpandCollapseOnTitleClick(node: NodeData): boolean { return false; }

  /**
   * Shows/hides the expand collapse icon.
   */
  get showExpandCollapseIcon(): boolean { return true; }

  get highlightSelectedNode(): boolean { return false; }

  /**
   * Makes the tree node selected.
   * @param id
   */
  selectNode(event: NodeEvent): void {

    if (event.node) {
      this.defaultSelectedId = event.node.id;
      event.action = NodeActions.BeforeSelect;
      this.sendMessage(event);
    }

  }

  selectNodeById(id: number): void {

    this.defaultSelectedId = id;
    let args = <NodeEvent>{ action: NodeActions.BeforeSelect, eventSource: EventSource.Unknown, node: this.get(id) };
    this.sendMessage(args);

  }

  /**
   * Expands the complete tree on load. But It could be expansive call Tree nodes are in big size.
   */
  expandOnLoad(): boolean { return false; }

  /**
   * Expands only one node at a time.
   */
  singleExpand(): boolean { return true; }

  menuSupport(node: NodeData): boolean { return false; }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  /**
   * Broadcasts the message to all subscribers
   * @param event
   */
  sendMessage(event: NodeEvent): void {
    this.subject.next(event);
  }
  
  /**
   * Releases all the resources.
   */
  onDestroy() { }

  /**
   * Creates the new tree node data model.
   * @param id
   * @param title
   * @param imageKey
   * @param parentId
   * @param children
   * @param tag
   */
  public createNode(id: number, title: string, imageKey: string, isVault: boolean, parentId: number, order?: number, canAddChild?: boolean, styles?: string): NodeData {

    let data: NodeData;
    if (this.has(id)) {

      data = this.get(id);

      // Don't need to compare the complete object because of different children ids during add/remove actions. 
      // We don't want to refresh the parent on add/remove actions.       
      if (JSON.stringify({ title: data.title, id: data.id, parentId: data.parentId, order: data.order, canAddChild: data.canAddChild }) == JSON.stringify({ title: title, id: id, parentId: parentId, order: data.order, canAddChild: canAddChild })) 
        return data;
      
    }
    
    data = new NodeData();
    data = data.setId(id);                  
    data = data.setId(id);
    data = data.setTitle(title);
    data = data.setIconKey(imageKey);
    data = data.setVaultIcon(isVault);
    data = data.setParentId(parentId);
    data = data.setOrder(order);
    data = data.setCanAddChild(canAddChild);
    data = data.setStyles(styles);

    // Add into cache.
    this.addToCache(data);
    return data;
  }

  /**
   * Calculates the distance from root.
   * @param id
   * @param nodeDepth
   */
  public distanceFromRoot(id: number, nodeDepth: number = 0): number {
    
    if (!this.has(id))
      return nodeDepth;

    let node: NodeData = this.get(id);

    // Before increment, return the node depth If no parent exits.
    if (!node.parentId)
      return nodeDepth;
    
    return this.distanceFromRoot(node.parentId, ++nodeDepth);
  }

  /**
   * Default selected node. It works only when the new node(s) are created.
   */
  public defaultSelectedId: number;

  /**
   * Applies the styles e.g highlight the button on click.
   * @param nodeAction
   */
  public applyButtonStyle(node: NodeData, nodeAction: string, eventSource?: string): void {
    this.sendMessage(<NodeEvent>{ node: node, action: nodeAction, eventSource: eventSource });    
  }
}