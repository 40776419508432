import * as Immutable from "immutable";
import { BaseEntity } from "../../baseEntity";
import { ConfValue } from "./confValue";

export class ConfBoolValue extends ConfValue {

  protected readonly VALUE = "value";
  protected readonly IS_DISALLOWED = "isDisallowed";

  get value(): boolean { return this.getInternalValue<boolean>(this.VALUE); }
  setValue(value: boolean): this { return this.setInternalValue(this.VALUE, value); }

  get isDisallowed(): boolean { return this.getInternalValue<boolean>(this.IS_DISALLOWED); }
  setIsDisallowed(isDisallowed: boolean): this { return this.setInternalValue(this.IS_DISALLOWED, isDisallowed); }
}