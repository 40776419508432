import * as Immutable from "immutable";
import { BaseEntity } from "../../baseEntity";
import { GraphicsDecoration } from "./graphicsDecoration";

export class ConfGraphicsValue extends BaseEntity {

  protected readonly SVG = "svg";
  protected readonly GRAPHICS_DECORATION = "graphicsDecoration";

  get svg(): string { return this.getInternalValue<string>(this.SVG); }
  setSvg(svg: string): this { return this.setInternalValue(this.SVG, svg); }

  get graphicsDecoration(): GraphicsDecoration { return this.getInternalValue<GraphicsDecoration>(this.GRAPHICS_DECORATION); }
  setGraphicsDecoration(graphicsDecoration: GraphicsDecoration): this { return this.setInternalValue(this.GRAPHICS_DECORATION, graphicsDecoration); }
}