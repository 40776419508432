import { Injectable, ElementRef, Injector } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { DateRangePickerComponent } from '../range/dateRangeOverlayComponent';
import { DateRangeOptions, CalendarDialogConfig } from '../models/model';
import { takeWhile } from 'rxjs/operators';



const DEFAULT_CONFIG: CalendarDialogConfig = {
  panelClass: 'drp-overlay', // DateRangePicker-overlay
  hasBackdrop: true,
  backdropClass: 'drp-overlay-backdrop', // Backdrop
  shouldCloseOnBackdropClick: true
}

@Injectable()
export class OverlayDatepickerService {

  private hostElemRef: ElementRef;

  constructor(private overlay: Overlay, private injector: Injector) { }

  open(config: CalendarDialogConfig = {}, hostElemRef: ElementRef): OverlayRef {
    this.hostElemRef = hostElemRef;
    const overlayConfig = { ...DEFAULT_CONFIG, ...config };
    const overlayRef = this.createOverlay(overlayConfig);
    const portalInjector = this.createInjector(overlayRef);
    const calendarPortal = new ComponentPortal(DateRangePickerComponent, null, portalInjector);
    overlayRef.attach(calendarPortal);

    overlayRef.backdropClick()
      .pipe(takeWhile(() => overlayConfig.shouldCloseOnBackdropClick))
      .subscribe(
      () => overlayRef.dispose()
      );

    return overlayRef;
  }

  private createOverlay(config: CalendarDialogConfig): OverlayRef {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig(dialogConfig: CalendarDialogConfig): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .connectedTo(
      this.hostElemRef,
      { originX: 'start', originY: 'bottom' },
      { overlayX: 'start', overlayY: 'top' }
    ).withOffsetY(0).withOffsetX(2);

    const overlayConfig = new OverlayConfig({
      hasBackdrop: dialogConfig.hasBackdrop,
      backdropClass: dialogConfig.backdropClass,
      panelClass: dialogConfig.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),      
      positionStrategy,
      height: `calc(100% - ${this.hostElemRef.nativeElement.getBoundingClientRect().top + 30}px)`,
      minWidth: "320px"
    });

    return overlayConfig;
  }

  private createInjector(overlayRef: OverlayRef): PortalInjector {
    const injectionTokens = new WeakMap();
    injectionTokens.set(OverlayRef, overlayRef);

    return new PortalInjector(this.injector, injectionTokens);
  }

}