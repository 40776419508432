import { Component, Inject, Input, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { Conf, Product, Tab, RequestViews } from "../../../shared/models";
import { ProductDataStore } from "../../../shared/providers/productData";
import { StickableUIData } from "../../../shared/state";
import { ConfiguratorUIStore, ConfPageSessionService, PopupIdentifiers } from "../../providers";
import { StickableSideBarComponent } from "../../shared/stickableSideBarComponent";
import { ManagedSubscription } from "../../../../shared";
import { IEmitDataInfo, TabVisibilityService } from "../../shared";
import { NotificationService, NotificationInfo, NotificationType } from "../../../../shared/components";
import { GlobalServiceLocator } from "../../../../shared/providers/globalServiceLocator";

@Component({
  selector: 'tabs-sidebar',
  templateUrl: './tabsSidebarComponent.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class TabsSidebarComponent extends StickableSideBarComponent {

  @Input()
  public tabs: Tab[] = [];

  @Input()
  public containerId: string;

  @Input()
  public selectedTabId: number;

  @Input()
  public configurationId: number;

  public accordionOpener: boolean = true;

  public accordionTabsWidth: string;

  public showOverlay: boolean = false;
  public showAccordionTabs: boolean = false;
  public subConfigurationTabs: Tab[] = [];
  public activeSubConfigurationId: number;
  public subConfTitle: string;

  // selectedTreeNodeId can be different than activeSubConfigurationId, e.g If root node is selected then activeSubConfiguration would be null and
  // selectedTreeNodeId would refer to root node.
  public selectedTreeNodeId: number;

  constructor(
    @Inject(ConfiguratorUIStore) public confUIStore: ConfiguratorUIStore,
    @Inject(ProductDataStore) public productDataStore: ProductDataStore,
    @Inject(TabVisibilityService) public tabVisibilityService: TabVisibilityService,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(NotificationService) public notificationService: NotificationService,
    public cd: ChangeDetectorRef
  ) {
    super(confUIStore, storeSession);
  }

  ngOnInit() {

    // Note! If accordion configurations mode is activated then accordion's width would be picked up from root configuration.    
    const conf: Conf = this.storeSession.activeConfiguration;
    const product: Product = this.productDataStore.getEntity<Product>(conf.productId);
    this.accordionTabsWidth = product.accordionTabsWidth ? `${product.accordionTabsWidth}px` : this.uiSettings.configurator.tab.minAccordionTabWidth;

    // Listen the messages from popups e.g Mandatory, ReadOnlyPopup which can trigger the tab-change
    this.emitterService.getMessage().subscribe((info: IEmitDataInfo<any>) => {
      if (!this.ui.calcSticky) {
        if (info.id == PopupIdentifiers.ChangeSelectedTab && info.tag) {

          // Product store service
          let productStore = GlobalServiceLocator.injector.get(ProductDataStore)

          // Grab the tab from store.
          const tabId = Number(info.tag);
          let tab: Tab = productStore.getEntity(tabId);

          if (!tab)
            return;

          // Notify to corresponding tabs container.
          this.activateTab(tab);
        }
        else if (!this.uiState.visible && info.id == PopupIdentifiers.AccordionCompositeShowDetail) {
          this.updateVisibility(true);
          this.cd.markForCheck();
          setTimeout(() => {
            let confId = +info.tag.confId;
            this.emitterService.send(PopupIdentifiers.AccordionCompositeShowDetail, { confId: confId, reload: true });
          }, 0);         
        }
      }
    }).unsubscribeOn(this.unsubscribeSubject);

    super.ngOnInit();
  }

  public activateTab(tab: Tab) {

    if (this.tabs.findIndex(x => x.longId == tab.longId) > -1) {
      this.selectedTabId = tab.longId;
      this.updateVisibility(true);
    }
  }

  // Returns the accordion data state.
  // Note! Don't use this getter property directly in html template. Its better to use local ui data member instead, otherwise you might have small performance penalty.
  get uiState(): StickableUIData {
    return this.confUIStore.getConfiguratorPageUIState(this.pageId).accordionUI;
  }

  /**
   * Updates the accordion visibility.
   * @param visible
   */
  updateVisibility(visible: boolean): void {
    this.confUIStore.updateAccordionUIState(this.pageId, this.ui.calcSticky, visible, this.tabs.length);
  }

  /**
   * Updates the sticky option set by the user.
   * @param userSticky
   */
  updateUserSticky(userSticky: boolean): void {
    this.confUIStore.updateAccordionUIState(this.pageId, userSticky, this.ui.visible, this.tabs.length);
  }

  updateExtraStyles(): void {
    this.moreStyles = this.ui.calcSticky ? "inline-dock h-100" : "inline-floated has-footer";
  }

}