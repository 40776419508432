import { UiElement } from "./uiElement";

export class UiLink extends UiElement {

  protected readonly TITLE = "title";
  protected readonly HREF = "href";
  protected readonly TARGET = "target";

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get href(): string { return this.getInternalValue<string>(this.HREF); }
  setHref(href: string): this { return this.setInternalValue(this.HREF, href); }

  get target(): string { return this.getInternalValue<string>(this.TARGET); }
  setTarget(target: string): this { return this.setInternalValue(this.TARGET, target); }

}