import * as Immutable from "immutable";
import { ImmutableObject } from "../../../../shared/immutableObject";

export class BomAutoCompleteRow extends ImmutableObject {
  protected readonly ITEM_NO = "itemNo";
  protected readonly DESCRIPTION = "description";
  protected readonly REVISIONS = "revisions";
  protected readonly QUANTITY_UNIT_ID = "quantityUnitId";

  constructor(data?: Immutable.Map<string, any>) {
    super(data);
  }

  get itemNo(): string { return this.getInternalValue<string>(this.ITEM_NO); }
  setItemNo(itemNo: string): this { return this.setInternalValue(this.ITEM_NO, itemNo); }

  get description(): string { return this.getInternalValue<string>(this.DESCRIPTION); }
  setDescription(description: string): this { return this.setInternalValue(this.DESCRIPTION, description); }

  get revisions(): string[] { return this.getInternalValue<string[]>(this.REVISIONS); }
  setRevisions(revisions: string[]): this { return this.setInternalValue(this.REVISIONS, revisions); }

  get quantityUnitId(): number { return this.getInternalValue<number>(this.QUANTITY_UNIT_ID); }
  setQuantityUnitId(quantityUnitId: number): this { return this.setInternalValue(this.QUANTITY_UNIT_ID, quantityUnitId); }
}