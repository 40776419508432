import { Component, Input, Inject } from "@angular/core";

import { BaseComponent } from "../..";
import { ConfGraphicsRequest, GraphicsDecoration } from "../../models";
import { ConfPageSessionService } from "../../../configurator/providers";
import { ConfDataController } from "../../providers/configurationData";
import { ModelFactory, RouteNames } from "../../providers";
import { PageStore } from "../../providers/page/pageStore";

@Component({
  selector: 'graphics-download',
  templateUrl: './graphicsDownloadComponent.html',
})
export class GraphicsDownloadComponent extends BaseComponent {

  graphics = [];

  @Input()
  decoration: GraphicsDecoration;

  @Input()
  public imageSet: string;

  @Input()
  public confId: number;

  @Input()
  public sessionId: number;

  constructor(
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(ConfDataController) public configuratorController: ConfDataController,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    @Inject(PageStore) public pageStore: PageStore,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setup();
  }

  public setup(): void {
    this.graphics.push({ name: "SVG", url: this.getGraphicLink("SVG") });
    this.graphics.push({ name: "PNG", url: this.getGraphicLink("PNG") });
    this.graphics.push({ name: "PDF", url: this.getGraphicLink("PDF") });
    this.graphics.push({ name: "DXF", url: this.getGraphicLink("DXF") });
  }

  public getGraphicLink(format: string): string {
    let model: ConfGraphicsRequest = this.modelFactory.createAny(ConfGraphicsRequest.name) as any;
    model.client = this.pageStore.getActiveClientType();
    model.fileType = format;
    model.configurationId = this.pageStore.activeRouteName == RouteNames.Start ? this.confId : this.confPageSessionService.activeConfigurationId;
    model.confSessionId = this.pageStore.activeRouteName == RouteNames.Start ? this.sessionId : this.confPageSessionService.confSessionId;
    model.graphicsDecorationId = this.decoration.visualObjectId;
    return this.configuratorController.getConfigurationGraphicUrl(model);
  }
}