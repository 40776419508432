import { Inject, Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler, HttpErrorResponse, HttpResponse, HttpEventType } from '@angular/common/http';
import { Observable, Subject, Subscription, throwError } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';
import { Ioc, Register } from "../../../shared/ioc/iocdecorator";
import { ApiResponse, ApiException } from "../models"
import { ExceptionHandler } from "./exceptionHandler"
import { Exception } from "../../../shared/exception";
import { HttpException } from "../../../shared/httpException";
import { ModelFactory } from "./modelFactory";
import { HttpLifeCycleService, HttpStatus } from './httpLifeCycleService';

@Injectable()
export class HttpInterceptService implements HttpInterceptor {

  constructor(public exceptionHandler: ExceptionHandler,
    public httpLifeCycle: HttpLifeCycleService,
    public modelFactory: ModelFactory) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let requestToUse = request;

    if (!requestToUse.headers.has("Combinum-Client-Version")) {

      let headersToUse = requestToUse.headers;
      headersToUse = headersToUse.append("Combinum-Client-Version", window.combinumApp.version);
      // Include header value only if available
      if (window.combinumApp.visualizationVersion && window.combinumApp.visualizationVersion.length > 0)
        headersToUse = headersToUse.append("Combinum-Visualization-Version", window.combinumApp.visualizationVersion);
      requestToUse = requestToUse.clone({ headers: headersToUse });
    }

    let requestEnded = false;
    this.httpLifeCycle.send(HttpStatus.Started, requestToUse);

    // If request takes longer than 2 seconds then show the blocker ui.
    setTimeout(() => {

      if (!requestEnded)
        this.httpLifeCycle.send(HttpStatus.WaitingState, requestToUse);

    }, 2000);

    let httpException: HttpException = null;

    return next.handle(requestToUse).pipe(
      // Extract ApiException from response.
      // Server can add non-fatal exception even though the response is ok.
      tap((response) => {

        if (response instanceof HttpResponse) {
          requestEnded = true;
          this.httpLifeCycle.send(HttpStatus.Completed, requestToUse);

          // Read and store the visualization plugin version in the client
          let headerValue = response.headers.get("Combinum-Visualization-Version");
          if (headerValue && headerValue.length > 0)
            window.combinumApp.visualizationVersion = headerValue;
        }

        this.handleApiException(response);

        //when array buffer returns a json containing an error cancel the request, otherwise it will be downloaded
        if ((response as any).containsHttpError)
          throw new HttpErrorResponse({});
      }),
      catchError((errorResponse) => {
        // Bug in Angular 6, which treats json error as blob so we need to read it and parse it back as json
        // TOOD, investigate if fixed in version 7 then remove this code
        if (errorResponse instanceof HttpErrorResponse) {
          if (errorResponse.error instanceof Blob && errorResponse.error.size > 0) {

            let blobReader: FileReader = new FileReader();
            let fileReadObservable: Observable<any> = Observable.create((observer: any) => {
              blobReader.onloadend = (e) => {

                (errorResponse as any).error = JSON.parse(blobReader.result as string);
                observer.error(errorResponse);
                observer.complete();
              }
            });
            blobReader.readAsText(errorResponse.error);
            return fileReadObservable;
          }
        }

        // If error is not blob then just throw the originalerror, it will be caught in next catch
        return throwError(errorResponse);
      }),
      catchError((errorResponse) => {
        // Unblock the UI
        requestEnded = true;
        this.httpLifeCycle.send(HttpStatus.Completed, requestToUse);

        if (errorResponse.error instanceof ArrayBuffer && errorResponse.status == 401) {
          //parse the error
          let jsonString = String.fromCharCode.apply(null, new Uint8Array(errorResponse.error));

          var json;
          try {
            json = JSON.parse(jsonString);
          } catch (e) {
            json = new ApiException({ code: 4000, message: "ERROR"});
          }
          let convertedErrorResponse = new HttpErrorResponse({ error: json, headers: errorResponse.headers, status: errorResponse.status, statusText: errorResponse.statusText, url: errorResponse.url });

          // Convert to HttpException
          httpException = this.getHttpException(convertedErrorResponse);

          // Inform all subscribers that error has occurred
          return new Observable<any>((subscriber) => subscriber.error(httpException));
        }

        requestEnded = true;
        this.httpLifeCycle.send(HttpStatus.Completed, requestToUse);
        if (errorResponse.error instanceof ArrayBuffer) {
          //parse the error
          let jsonString = String.fromCharCode.apply(null, new Uint8Array(errorResponse.error));

          let json = JSON.parse(jsonString);

          let convertedErrorResponse = new HttpErrorResponse({ error: json, headers: errorResponse.headers, status: errorResponse.status, statusText: errorResponse.statusText, url: errorResponse.url });

          // Convert to HttpException
          httpException = this.getHttpException(convertedErrorResponse);

          // Inform all subscribers that error has occurred
          return new Observable<any>((subscriber) => subscriber.error(httpException));
        }

        // Convert to HttpException
        httpException = this.getHttpException(errorResponse);

        // Inform all subscribers that error has occurred
        return new Observable<any>((subscriber) => subscriber.error(httpException));
      }),
      finalize(() => {
        // Handle error rather than throwing to allow the application to remain stable
        if (httpException && !httpException.isHandled)
          this.exceptionHandler.handleException(httpException);        
      })
    );
  }

  public getHttpException(errorResponse: any) {
    let httpException = new HttpException();

    httpException.errorResponse = errorResponse;
    httpException.exceptions = this.extractApiExceptions(errorResponse);

    return httpException;
  }

  public extractApiExceptions(response: any) {

    let body = null;
    if (response instanceof HttpResponse)
      body = response.body;
    if (response instanceof HttpErrorResponse)
      body = response.error;

    //if it's an arraybuffer and contains a json then parse it
    if (body instanceof ArrayBuffer && response.headers.get("Content-Type") == "application/json; charset=utf-8") {
      let jsonString = String.fromCharCode.apply(null, new Uint8Array(body));

      let parsedBody = JSON.parse(jsonString);

      if (parsedBody && parsedBody.className == ApiResponse.name && parsedBody.exceptions && parsedBody.exceptions.length > 0) {
        //used to cancel downloads when they contain an error
        response.containsHttpError = true;
        return this.modelFactory.createArray<ApiException>(parsedBody.exceptions).toArray();
      }
    }

    // Check if response has ApiException then return it
    if (body && body.className == ApiResponse.name && body.exceptions && body.exceptions.length > 0) {
      return this.modelFactory.createArray<ApiException>(body.exceptions).toArray();
    }

    return null;
  }

  public handleApiException(response: any) {

    let apiExceptions = this.extractApiExceptions(response);
    if (apiExceptions) {
      apiExceptions.forEach((exception) => {
        this.exceptionHandler.handleException(exception);
      });
    }

    return response;
  }
}