import { Observable } from "rxjs";
import { Subscription, Subject, BehaviorSubject } from "rxjs";

export class AccordionConfig {

  // Attach any object to it. Which would be accessible to all listeners.
  tag?: any;

}

export class AccordionService {

  // Subject to subscribe the PopoverInfo.
  // Subject to subscribe the tree nodes.  
  private subject = new Subject<AccordionConfig>();

  /**
  * Broadcasts the message to all subscribers
  * @param event
  */
  public sendMessage(info: AccordionConfig): void {
    this.subject.next(info);
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

}