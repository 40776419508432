import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from "../../../../shared/providers";
import { ConfDataController } from "../../providers/configurationData";
import { ConfDataRequest, ApiResponse } from "../../models";
import { ApiResponseBuilder } from "../../providers/apiResponseBuilder";
import { GlobalDataStore } from "../../providers/globalData";

@Injectable()
export class StateController extends ConfDataController {

  constructor(
    @Inject(HttpService) public httpService: HttpService,
    public apiResponseBuilder: ApiResponseBuilder,
    public globalDataStore: GlobalDataStore
  ) {
    super(httpService, apiResponseBuilder, globalDataStore);
  }

  public promote(model: ConfDataRequest): Observable<ApiResponse> {
    return this.getConfigurationData(model);
  }

  public demote(model: ConfDataRequest): Observable<ApiResponse> {
    return this.getConfigurationData(model);
  }

}