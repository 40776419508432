import { Injectable, Inject } from "@angular/core";
import * as Immutable from "immutable";

import { ReselectorService } from "../reselectorService";
import { AbstractSearchDataMessage } from "../../models/responses/messages";

@Injectable()
export class SearchDataMemorizer {

  constructor( @Inject(ReselectorService) public reselectorService: ReselectorService) {
  }

  public getMessagesMemorizer<T extends AbstractSearchDataMessage>(className: string): () => Immutable.List<T> {

    return this.reselectorService.createMemorizer((state) => {

      let messages = state.searchData.messages;
      if (messages && messages.size > 0)
        return messages.get(className) as Immutable.List<T>;

      return null;
    });
  }
}