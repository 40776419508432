import { Injectable, Inject } from "@angular/core";
import { AppStore, AppAction, HttpAction, StoreResponse, ActionInfo, RequestStatus } from "../../shared/state";
import { AppStoreSubscriptionManager } from "../../shared/providers/appStoreSubscriptionManager";
import { BaseStore } from "../../shared/state";
import { ModelFactory } from "../../shared/providers/modelFactory";
import { AppActionCreator } from "../../shared/providers";
import { ManagedSubject } from "../../../shared";

@Injectable()
export class SettingsStore extends BaseStore {

  constructor(
    @Inject(AppStore) public appStore: AppStore,
    @Inject(AppActionCreator) public appActionCreator: AppActionCreator,
    @Inject(AppStoreSubscriptionManager) public appStoreSubscriptionManager: AppStoreSubscriptionManager,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
  ) {
    super(appStore, appStoreSubscriptionManager, modelFactory);
  }

  reloadCache(userDataOnly: any): Promise<any> {
    let requestId: number = this.appActionCreator.dispatchReloadCaches(userDataOnly).id;

    return this.listenRequestCompletion(requestId).toPromise();
  }

  public listenRequestCompletion(requestId: number): ManagedSubject<any> {
    return this.appStoreSubscriptionManager.createSubject<any>((appStore) => {

      let actionInfo: ActionInfo = appStore.getActionInfo(requestId);
      if (actionInfo.status == RequestStatus.SUCCESS) {
        if (actionInfo.payload) {
          return actionInfo.payload.data;
        }
      }
      return ManagedSubject.IGNORE_VALUE;
    }, true);
  }
}