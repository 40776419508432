import { Injectable, Inject } from "@angular/core"
import { normalize, Schema, schema } from "normalizr";

import { NormalizeResult } from "./normalizeResult"

@Injectable()
export class NormalizerService {

  normalizeJson<T>(json: any, schema: Schema, createObject: (className: string, data: any) => T): NormalizeResult<T> {

    let normalizeOutput = normalize(json, schema);

    let entitiesMap = new Map<number, T>();

    for (let key in normalizeOutput.entities) {
      if (!normalizeOutput.entities.hasOwnProperty(key)) {
        continue;
      }

      for (let id in normalizeOutput.entities[key]) {

        let dto = normalizeOutput.entities[key][id];
        entitiesMap.set(parseInt(id), createObject(dto.className, dto))
      }
    }

    let result = new NormalizeResult<T>();
    result.resultIds = this.getNumericArray(schema, normalizeOutput);
    result.entities = entitiesMap;

    return result;
  }

  public getNumericArray(schema: Schema, normalizeOutput: { entities: any, result: any }) {

    let result = new Array<number>();

    if (normalizeOutput.result.constructor == Array) {
      
      for (let item of normalizeOutput.result) {
        if (item.schema)
          result.push(item.id);
        else
          result.push(item);
      }
    }
    else {
      result.push(normalizeOutput.result);
    }

    return result;
  }
}