<regular-popup *ngIf="visible"
               [title]="strings.Information"
               [id]="id"
               [showHeader]="true"
               [okButtonText]="strings.Ok"
               [cancelButtonText]="strings.Cancel"
               (onClosed)="onPopupClosed()">
  <!--Header part-->
  <div card-type="header" *ngIf="messages != null">
    <div class="row flex-nowrap">
      <div class="d-flex align-middle px-2">
        <co-image key="info" width="32px" height="32px" imageSet="primary-light"></co-image>
      </div>
      <div class="d-flex modal-text-right align-middle ml-0 pl-2">
        <p><em>{{strings.RulesSetOrTriedToSetValuesBelow}}</em></p>
      </div>
    </div>
  </div>

  <!--Body part-->
  <div card-type="body" class="disallowed-body" *ngIf="messages != null">

    <ng-container *ngFor="let message of messages">

      <!-- Show all rule infos -->
      <ng-container *ngFor="let rule of message.ruleInfos">

        <rule-params-info [ruleInfo]="rule" [popupId]="id" [configurationId]="message.configurationId"></rule-params-info>

      </ng-container>

    </ng-container>
  </div>
</regular-popup>