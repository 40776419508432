import * as Immutable from "immutable";
import { ConfSearchCriteria } from "./confSearchCriteria"

export class ConfSearchParameter extends ConfSearchCriteria {

  protected readonly PARAM_VALUE_ID = "paramValueId";
  protected readonly PARAMETER_ID = "parameterId";
  
  // Only used for multichoice value.
  get paramValueId(): number { return this.getInternalValue<number>(this.PARAM_VALUE_ID); }
  setParamValueId(paramValueId: number): this { return this.setInternalValue(this.PARAM_VALUE_ID, paramValueId); }

  get parameterId(): number { return this.getInternalValue<number>(this.PARAMETER_ID); }
  setParameterId(paramValueId: number): this { return this.setInternalValue(this.PARAMETER_ID, paramValueId); }

}