import * as Immutable from "immutable";

import { ImmutableObject } from "../../../../shared/immutableObject";
import { GlobalSettings } from "./globalSettings";
import { KeyValue } from "./keyValue";
import { LocalizeData } from "./localizeData";
import { BaseEntity } from "../../baseEntity";

export class GlobalDataResponse extends ImmutableObject {

  protected readonly GLOBAL_SETTINGS = "globalSettings";
  protected readonly LOCALIZE_DATA = "localizeData";
  protected readonly ENTITIES = "entities";
  protected readonly ENTITY_IDS_BY_CLASS_NAME = "entityIdsByClassName";

  get globalSettings(): GlobalSettings { return this.getInternalValue<GlobalSettings>(this.GLOBAL_SETTINGS); }
  setGlobalSettings(globalSettings: GlobalSettings): this { return this.setInternalValue(this.GLOBAL_SETTINGS, globalSettings); }

  get localizeData(): LocalizeData { return this.getInternalValue<LocalizeData>(this.LOCALIZE_DATA); }
  setLocalizeData(localizeData: LocalizeData): this { return this.setInternalValue(this.LOCALIZE_DATA, localizeData); }

  get entities(): Immutable.Map<number, BaseEntity> { return this.getInternalValue<Immutable.Map<number, BaseEntity>>(this.ENTITIES); }
  setEntities(entities: Immutable.Map<number, BaseEntity>): this { return this.setInternalValue(this.ENTITIES, entities); }

  get entityIdsByClassName(): Immutable.Map<string, Immutable.List<number>> { return this.getInternalValue<Immutable.Map<string, Immutable.List<number>>>(this.ENTITY_IDS_BY_CLASS_NAME); }
  setEntityIdsByClassName(entityIdsByClassName: Immutable.Map<string, Immutable.List<number>>): this { return this.setInternalValue(this.ENTITY_IDS_BY_CLASS_NAME, entityIdsByClassName); }    
}