import { InputViewModel } from "./models";
import { BaseInputComponent } from "./baseInputComponent";

export class ValueChangeEventArgs {

  // Native element
  element: any;

  // Component reference
  component: BaseInputComponent;

  // Value supposed to be set.
  // Note! This value is a temporary value and will not be set to view until server validates it.
  value: any = null;

  // Current view for the actioned item.
  actionView: InputViewModel;

  // View before action.
  prevValue: any;
}


export class RestrictValueChangeEventArgs {
    
  // Invalid value
  inValidValue: any = null;

  // Current value.
  currentValue: any = null;

  // View associated with control where the action was performed. 
  // In Radio/Multi choice items, it represents the actioned item's view.
  actionView: InputViewModel;  
}
