<backdrop-container>
  <regular-popup *ngIf="visible"
                 [id]="id"
                 [title]="strings.Confirm"
                 [showHeader]="false"
                 [showFooter]="true"
                 [okButtonText]="strings.Delete"
                 [cancelButtonText]="strings.Cancel"
                 (okClick)="okClick()"
                 (onClosed)="onPopupClosed()">

        <div card-type="body" class="p-4">
      <div>
        {{strings.AreYouSureYouWantToDelete}}
      </div>
      <div>
        {{attachment.title}}
      </div>
    </div>
  </regular-popup>
</backdrop-container>