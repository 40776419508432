import { Injectable, EventEmitter } from "@angular/core";
import { HubConnection, HubConnectionBuilder, LogLevel, HubConnectionState } from '@microsoft/signalr';
import { ServerPushMessage } from "../../models";

@Injectable({
  providedIn: "root"
})
export class SignalRService {
  private hubConnection: signalR.HubConnection;
  signalReceived = new EventEmitter<ServerPushMessage>();

  constructor() {
    //this.buildConnection();
    //this.startConnection();
  }

  private buildConnection() {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl("/pushMessageHub")
      .withAutomaticReconnect()
      .build();
  }

  public isConnected() {

    return this.hubConnection && (this.hubConnection.state != HubConnectionState.Disconnected && this.hubConnection.state != HubConnectionState.Disconnecting)

  }

  public stopConnection() {
    //console.log("???????????????????????????????????????????????????????????????? stop.");
    if (this.hubConnection && this.hubConnection.state != HubConnectionState.Disconnected)
      this.hubConnection.stop();
  }

  public startConnection() {
    //console.log("???????????????????????????????????????????????????????????????? start.");
    if (this.hubConnection && this.hubConnection.state != HubConnectionState.Disconnected)
      this.hubConnection.stop().then(() => this.startConnectionInternal());
    else {
      this.buildConnection();
      this.startConnectionInternal();
    }
  }

  private startConnectionInternal() {
    //console.log("???????????????????????????????????????????????????????????????? start internal.");
    this.hubConnection
      .start()
      .then(() => {
        console.log("SignalR Connection Started.");
        this.registerSignalEvents();
      })
      .catch(err => {
        console.log("Error while starting the SignalR connection: " + err);

        // Try again if there was an error.
        setTimeout(function () {
          () => {
            this.startConnection();
          };
        }
        , 3000);
      });
  }

  private registerSignalEvents() {
    this.hubConnection.on("ServerPushMessage", (data: ServerPushMessage) => {
      this.signalReceived.emit(data);
    });
  }
}