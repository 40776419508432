import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import * as Immutable from "immutable";
import { Subscription } from "rxjs";
import { BreadcrumbItem } from "../../../../core/shared/components/breadcrumb";
import { Breakpoints } from "../../../../core/shared/utils";
import { PageComponent } from "../../shared";
import { Comparison, ConfInfo, KeyValue } from "../../shared/models";
import { IHttpDataInfo, RouteRedirector } from "../../shared/providers";
import { ComparisonResult } from "../../shared/state";
import { ComparisonDraggableNodeArgs, DraggableListAction } from "./components/dragDrop/models/comparisonDraggableNodeArgs";
import { ComparisonDraggableNode, ComparisonDraggableRowNodes } from "./components/dragDrop/models/comparisonDraggableRowNodes";
import { ComparisonUrlParams } from "./components/dragDrop/models/comparisonUrlParams";
import { ComparisonDraggableDataProvider } from "./components/dragDrop/providers/comparisonDraggableDataProvider";
import { ComparisonDataStore } from "./providers/comparisonDataStore";
import { ComparisonHelper } from "./providers/comparisonHelper";

@Component({
  encapsulation: ViewEncapsulation.None,
  templateUrl: './comparisonComponent.html',
  providers: [ComparisonDraggableDataProvider],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComparisonComponent extends PageComponent {

  comparisons: Array<Comparison> = [];

  selectedConfs: Array<ConfInfo> = [];

  startConfIds: number[];

  comparisonSessionId: number;

  multiConfs: Array<Array<ConfInfo>> = [];

  breadcrumbItems: Array<BreadcrumbItem> = [];

  childConfsOrder: Immutable.List<KeyValue<string, ConfInfo[]>>;

  routeSubscription: Subscription;

  public isFloating: boolean = false;
  public enableScrolling: boolean = false;

  @ViewChild('confsDiv')
  public confsDiv: ElementRef;

  public treeRows: Immutable.List<ComparisonDraggableRowNodes>

  compactMode: boolean = false;
  minWidth: number;
  isUpdating: boolean = false;  

  constructor(
    protected comparisonDataStore: ComparisonDataStore, protected activatedRoute: ActivatedRoute,
    protected treeUIDataProvider: ComparisonDraggableDataProvider, protected router: Router,
    protected routeRedirector: RouteRedirector,
    protected helper: ComparisonHelper,
    protected cd: ChangeDetectorRef
  ) { super(); }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setCompactModeMinWidth();
    this.setScrollWidth();
  }

  ngOnInit() {

    this.routeSubscription = this.activatedRoute.data.subscribe((resolversData: any) => {

      // is now triggered at each queryParams change
      let data: ComparisonResult = resolversData.comparisonResult;

      if (data) {
        this.makeBreadcrumbItems(data.breadcrumbConfInfos);

        // First index contains the starting confs and this breadcrumb list won't be null.
        this.startConfIds = this.breadcrumbItems[0].confIds;
      }

      if (data && !this.activatedRoute.snapshot.params.sessionid) {

        this.navigateTo(this.startConfIds, data.comparisonSessionId);
      }

      if (data) {

        // Convert all confs to rows and cols.
        this.treeRows = this.treeUIDataProvider.convertToRowStructure(data.childConfsOrder);

        this.comparisonSessionId = data.comparisonSessionId;
        this.selectedConfs = data.breadcrumbConfInfos.last().value;

        this.comparisons = data.detail.children.toArray();

        this.childConfsOrder = data.childConfsOrder;

        this.setCompactModeMinWidth();

        // Don't write this logic inside ngAfterViewInit. The reason is that component is not instantiated when going deeper at child level and
        // ngAfterViewInit is only called first time onload.        
        this.updateDifferncyLevelForGrid();
        this.cd.markForCheck();

      }

    });

    super.ngOnInit();
  }

  ngAfterViewInit() {
    //this.updateDifferncyLevelForGrid();

    this.enableScrolling = true;
  }

  updateDifferncyLevelForGrid(): void {

    // Send requests for child configurations comparison.    
    for (let keyValue of this.childConfsOrder.toArray()) {

      //let confs = this.childConfsOrder.get(key);
      //let confsIds: number[] = confs.filter(conf => conf != null).map(x => x.longId);
      this.updateDifferncyLevelForRow(keyValue.key);

    }

  }

  updateDifferncyLevelForRow(path: string): void {

    // Only update the differency level for more than one.
    if (this.startConfIds.length < 2)
      return;

    let session: ComparisonResult = new ComparisonResult();
    session = session.setConfIds(Immutable.List(this.startConfIds));
    this.treeUIDataProvider.send<ComparisonResult>({ action: DraggableListAction.ChildConfLoading, data: session });
    this.isUpdating = true;    
    this.comparisonDataStore.loadConfsDifferncyLevel(this.startConfIds, path, this.comparisonSessionId).subscribe(response => {

      if (response.data) {
        this.treeUIDataProvider.send<ComparisonResult>({ action: DraggableListAction.ChildConfLoaded, data: response.data });
      }

      this.isUpdating = false;      
    });

  }

  onOrderChanged(event: ComparisonDraggableNodeArgs): void {
    
    this.treeRows = this.treeUIDataProvider.getRows();

    // Read current path
    let currentPath: string = this.breadcrumbItems[this.breadcrumbItems.length - 1].path;

    // Update the child configurations order.
    this.comparisonDataStore.updateChildConfsOrder(this.treeUIDataProvider.getIds(), this.startConfIds, currentPath, this.comparisonSessionId).subscribe(response => {

      // Source confIds
      let sourceConfIds: number[] = event.sourceRowAfterDrag ? event.sourceRowAfterDrag.nodes.filter(node => !node.isEmpty).map(node => Number(node.id)) : [];

      // Destination confIds
      let destConfIds: number[] = event.destinationRowAfterDrag.nodes.filter(node => !node.isEmpty).map(node => Number(node.id));


      // Update differncy levels for affected rows.
      let sourcePath = this.helper.extractPath(response.data, sourceConfIds);
      this.updateDifferncyLevelForRow(sourcePath);

      let destPath = this.helper.extractPath(response.data, destConfIds);
      this.updateDifferncyLevelForRow(destPath);          

    });

  }

  onHttpLifeCycleUpdate(http: IHttpDataInfo) {

    if (!this.isUpdating)
      super.onHttpLifeCycleUpdate(http);

  }

  get selectedConfIds(): Array<number> {
    return this.selectedConfs.map(conf => conf.longId);
  }

  /**
   * Fires when drilling down the configurations.
   * @param row
   */
  onSelect(row: ComparisonDraggableRowNodes): void {

    let nodes: ComparisonDraggableNode[] = row.nodes.filter(node => !node.isEmpty);
    this.navigateTo(this.startConfIds, this.comparisonSessionId, nodes[0].path);

  }

  onBreadcrumbClick(item: BreadcrumbItem): void {

    // this.breadcrumbItems = [];
    // this.updateBreadcrumbItems(item.id);

    this.navigateTo(this.startConfIds, this.comparisonSessionId, item.path);

  }

  navigateTo(ids: number[], sessionId?: number, path?: string): void {

    let params = new ComparisonUrlParams();
    params.ids = this.helper.comparisonId(ids);
    params.sessionid = sessionId;
    params.path = path ? path : '0';

    this.routeRedirector.redirectToComparison(params);

  }

  /**
   * Makes the breadcrumbs
   * @param confs confs belonging to same row.
   */
  makeBreadcrumbItems(breadcrumbInfos: Immutable.List<KeyValue<string, ConfInfo[]>>): void {

    this.breadcrumbItems = [];
    for (let keyValue of breadcrumbInfos.toArray()) {

      let infos = keyValue.value;
      let drivingConf: ConfInfo = infos[0];

      let item: BreadcrumbItem = <BreadcrumbItem>{
        id: drivingConf.longId,
        title: drivingConf.text,
        path: keyValue.key,
        confIds: infos.map(x => x.longId)
      };

      this.breadcrumbItems.push(item);

    }

  }

  setCompactModeMinWidth(): void {
    this.compactMode = window.innerWidth < Breakpoints.sm.min ? true : false;
    this.minWidth = this.selectedConfIds.length * 125;
  }

  setScrollWidth(): void {
    let parent = document.getElementById("compare");

    // null checks
    if (!(parent && this.confsDiv && this.confsDiv.nativeElement && this.confsDiv.nativeElement.firstElementChild))
      return;

    if (parent.scrollWidth > parent.clientWidth) {
      this.confsDiv.nativeElement.firstElementChild.style.position = "relative";
      this.confsDiv.nativeElement.firstElementChild.style.width = this.minWidth + "px";
    }
    else {
      this.confsDiv.nativeElement.firstElementChild.style.position = "unset";
      this.confsDiv.nativeElement.firstElementChild.style.width = "";
    }
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler($event) {
    if (this.enableScrolling) {
      let firstRow = document.getElementById("comparerow");
      let header = document.querySelector(".navbar-brand");
      if (firstRow && header)
        this.isFloating = firstRow.getBoundingClientRect().top < header.clientHeight;
      else
        this.isFloating = false;

      // null checks
      if (!(this.confsDiv && this.confsDiv.nativeElement && this.confsDiv.nativeElement.firstElementChild && firstRow && firstRow.parentElement))
        return;

      if (this.isFloating)
        this.confsDiv.nativeElement.firstElementChild.style.left = (firstRow.parentElement.getBoundingClientRect().left - firstRow.parentElement.offsetLeft) + "px";
      else
        this.confsDiv.nativeElement.firstElementChild.style.left = 0;

      this.setScrollWidth();
    }
  }
  
  refreshList(event): void {

    if (this.treeRows && this.treeRows.size == 0)
      return;

    // Below code improves the performance, prevents the re-rendering of draggable tree nodes and 
    // forces the change dectection on affected areas.
    let newArray: Array<ComparisonDraggableRowNodes> = new Array<ComparisonDraggableRowNodes>();
    this.treeRows.forEach(row => {

      let nodes = row.nodes;

      // Clear the old nodes list and create and new instance if node belongs the the affected column.
      row.nodes = [];      
      for (let index = 0; index < nodes.length; index++) {

        let n: ComparisonDraggableNode = event.index == index ? nodes[index] : nodes[index];
        row.nodes.push(n);

      }

      // Push the row into list.
      newArray.push(row);
      
    });

    this.treeRows = Immutable.List<ComparisonDraggableRowNodes>(newArray);
    this.cd.markForCheck();

  }

}