import * as Immutable from "immutable";
import { Param } from "./param";

export class StringParam extends Param {

  protected readonly ROW_COUNT = "rowCount";
  protected readonly PLACEHOLDER = "placeholder";
  
  get rowCount(): number { return this.getInternalValue<number>(this.ROW_COUNT); }
  setRowCount(rowCount: number): this { return this.setInternalValue(this.ROW_COUNT, rowCount); }
  
  get placeholder(): string { return this.getInternalValue<string>(this.PLACEHOLDER); }
  setPlaceholder(placeholder: string): this { return this.setInternalValue(this.PLACEHOLDER, placeholder); }

}