import { Positions } from "../enums";

export class InputViewModel {

  id: string;

  title: string;

  name: string;

  icon: string;

  isVaultIcon = false;

  helpImage: string;

  helpImageHeight: string;
  
  helpImageWidth: string;

  minWidth: string;

  width: string;

  height: string;

  iconWidth = '24px'; // Temporarily set

  iconHeight = '24px'; // Temporarily set

  iconImageSet = 'primary';

  helpIconWidth: string;

  helpIconHeight: string;

  enableHelp: boolean;

  enableExternalLink: boolean;

  externalLink: string;

  floatableLabel = false;    

  disabled = false;

  readOnly = false;

  hideIfDisabled = false;

  immediate = false;

  highlight = false;

  value: any = null;  

  formattedValue: any = null;  

  tag: any = null;

  // Only for debugging.
  //val: any;
  //set value(val: any) {
  //  this.val = val;
  //}

  //get value(): any {
  //  return this.val;
  //}
  
  // Shows icon as a selected text. --> TODO: Make separate model for dropdowns
  showIconAsSelected? = false;

  // Shows the dropdown arrow below of control -> TODO: Make separate model for dropdowns
  arrawAtBottom = false;

  // Placeholder attribute only works with textBox and dropDown.
  placeholder = "";
  
  public labelPosition = Positions.Above;

  // Supported only for radio buttons.
  helpImagePosition = Positions.Above;

  // Stores only parameter width
  widthIfSelectedImage: string;

  errors: Array<string>;

  public constructor();
  public constructor(text: string);
  public constructor(text?: string) {
    this.name = this.title = text;
  }

}