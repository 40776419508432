import { ChangeDetectorRef, Component, Inject } from "@angular/core";
import * as Immutable from "immutable";
import { PopupService } from "../../../../shared/components/popup";
import { Conf, ConfPriceValue, PriceListDecoration, PriceReportMessage } from "../../../shared/models";
import { ConfDataActionCreator } from "../../../shared/providers/configurationData";
import { ConfiguratorStore, ConfMessageProvider, ConfPageSessionService, PopupIdentifiers } from "../../providers";
import { DecorationComponent } from "../../shared/decorationComponent";
import { VisualObjectHelper } from "../../parameters/shared";

export enum PriceListStatusEnum {

  Effective = "effective",
  Expired = "expired",
  Empty = "empty",

}

@Component({
  selector: 'price-list-decoration',
  templateUrl: './priceListDecorationComponent.html'
})
export class PriceListDecorationComponent extends DecorationComponent<PriceListDecoration> {

  confPriceValue: ConfPriceValue;
  showSettings: boolean = false;

  PriceListStatus: PriceListStatusEnum;

  // Set the enum as an object to variable to access the enum values in html template.
  StatusEnumObject = PriceListStatusEnum;

  constructor(
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(ConfMessageProvider) public confMessageProvider: ConfMessageProvider,
    @Inject(ConfDataActionCreator) public confActionCreator: ConfDataActionCreator,
    @Inject(PopupService) public popupService: PopupService,
    @Inject(VisualObjectHelper) public visualObjectHelper: VisualObjectHelper,
    @Inject(ChangeDetectorRef) public cd: ChangeDetectorRef
  ) {
    super(confStore, confPageSessionService, visualObjectHelper);
  }

  ngOnInit() {

    // Initial value on load.
    this.confPriceValue = this.configuration.priceValue;
    this.showSettings = this.configuration.authorization.canChangeCurrency || this.configuration.authorization.canChangePriceListCategory;
    this.UpdatePriceListStatus();

    // Price report message
    this.confMessageProvider.onMessagesRequest<PriceReportMessage>(this.confPageSessionService.confSessionId, PriceReportMessage.name, {
      next: (messages: Immutable.List<PriceReportMessage>): void => {
        this.popupService.open<Immutable.List<PriceReportMessage>>(PopupIdentifiers.PriceList, messages);
      },
      listenNewEventsOnly: true
    }).unsubscribeOn(this.unsubscribeSubject);


    super.ngOnInit();
  }
  
  public listenChanges(): void {

    this.confStore.onConfigurationChange(this.configurationId, this.confSessionId, (configuration: Conf): void => {

      this.confPriceValue = configuration.priceValue;
      this.showSettings = this.configuration.authorization.canChangeCurrency || this.configuration.authorization.canChangePriceListCategory;
      this.UpdatePriceListStatus();

    }).unsubscribeOn(this.unsubscribeSubject);
  }

  public UpdatePriceListStatus() {

    if (!this.confPriceValue)
      return;

    if (this.confPriceValue.effective === true)
      this.PriceListStatus = PriceListStatusEnum.Effective;

    else if (this.confPriceValue.effective === false)
      this.PriceListStatus = PriceListStatusEnum.Expired;

    else if (this.confPriceValue.effective == null)
      this.PriceListStatus = PriceListStatusEnum.Empty;

  }
  
  onPriceDetailClick($event): void {
    let requestType = this.confPageSessionService.confUIStore.PageStore.getActiveClientType();
    this.confStore.getPriceReport(this.confSessionId, this.configurationId, requestType);
  }

  onPriceSettingsClick($event): void {
    let requestType = this.confPageSessionService.confUIStore.PageStore.getActiveClientType();
    this.confStore.getPriceSetting(this.confSessionId, this.configurationId, requestType);
  }

}