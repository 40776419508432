import { Inject, Component, Input, ChangeDetectorRef, Output, EventEmitter } from "@angular/core";
import * as Immutable from "immutable";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import { State, StateActionInfoMessage, StateActionResultMessage } from "../../models";
import { GlobalDataStore } from "../../providers/globalData";
import { StateStore } from "./stateStore";
import { InputViewModel, NotificationService, NotificationInfo, NotificationType } from "../../../../shared/components";
import { ConfPageSessionService } from "../../../configurator/providers";

@Component({
  selector: 'state-action-popup',
  templateUrl: './stateActionPopupComponent.html'
})
export class StateActionPopupComponent extends AbstractPopupComponent {

  public id: string = "state-action-popup";
  public width: string;
  public height: string;

  public noteView: InputViewModel;

  public okButtonText: string;
  public titleText: string;
  public errorText: string;

  public states: Immutable.Iterable<number, State>;
  public fromState: State;
  public toState: State;

  public toStateView: InputViewModel;
  public toStateViews: Array<InputViewModel>;

  /** Either promote or demote */
  public isPromote: boolean;

  public infoMessage: StateActionInfoMessage;
  public resultMessage: StateActionResultMessage;

  @Output()
  public onClosed = new EventEmitter();

  @Output()
  public preventShow = new EventEmitter();

  constructor(
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(StateStore) public stateStore: StateStore,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(NotificationService) public notificationService: NotificationService,
    public cdr: ChangeDetectorRef
  ) {
    super(cdr);
  }

  ngOnInit() {
    this.width = this.uiSettings.configurator.state.minPopupWidth;
    this.height = this.uiSettings.configurator.state.minPopupHeight;
    //this.states = this.globalDataStore.getStates().sortBy(x => x.order); // sort by order

    this.noteView = new InputViewModel(this.strings.Note);

    this.visible = true;
    super.ngOnInit();
  }

  /**
   * Shows the state action popup, either as promote or demote.
   * @param isPromote true for promote, false for demote
   */
  show(isPromote: boolean) {
    this.isPromote = isPromote;

    // First fetch states
    this.globalDataStore.getEntities<State>(State.name).subscribe((storeResponse) => {
      this.states = storeResponse.data.sortBy(x => x.order); // sort by order                  

      // Listen on info and setup
      this.stateStore.onStateActionInfo({
        next: (messages: Immutable.List<StateActionInfoMessage>) => {
          this.infoMessage = messages.first();

          this.unblockUI();

          if (this.infoMessage.isAllowed)
            this.showInternal();
          else
            this.preventShow.emit();

          this.titleText = this.isPromote ? (this.infoMessage.isAllowed ? this.strings.ConfirmPromotion : this.strings.CannotPromote) : (this.infoMessage.isAllowed ? this.strings.ConfirmDemotion : this.strings.CannotDemote);
          this.fromState = this.states.find(x => x.longId === this.infoMessage.fromState);

          // Setup ToState. Show single state as label but create dropdown if multiple.
          let toStates: Array<State> = [];
          this.infoMessage.toStates.forEach(state => {
            toStates.push(this.states.find(x => x.longId === state));
          });

          // Multiple states -> show dropdown, otherwise just regular label
          if (toStates.length > 1) {
            this.toStateView = new InputViewModel();
            this.toStateView.width = "300px";
            this.toStateViews = [];
            toStates.forEach(state => {
              let stateView = new InputViewModel(state.name);
              stateView.id = state.longId.toString();
              this.toStateViews.push(stateView);
            });
          }
          else {
            this.toState = toStates[0];
            this.toStateView = this.toStateViews = null;
          }
        },
        listenNewEventsOnly: true
      }).unsubscribeOn(this.unsubscribeSubject);

      // Setup strings and ask for info about promote/demote
      if (this.isPromote) {
        // Make a request for promote info, will return info if the user can continue or if something is preventing the action
        this.stateStore.getPromoteInfo();

        this.okButtonText = this.strings.Promote;
        this.errorText = this.strings.CouldNotPromote;
      }
      else {
        // Make a request for demote info, will return info if the user can continue or if something is preventing the action
        this.stateStore.getDemoteInfo();

        this.okButtonText = this.strings.Demote;
        this.errorText = this.strings.CouldNotDemote;
      }

      this.blockUI();
    });

    this.listenChanges();
  }

  public listenChanges() {

    this.stateStore.onStateActionResult({
      next: (messages) => {
        this.unblockUI();

        // Handle result. Either update the states or show error.
        let message = messages.first();

        if (message.warnings.size > 0) {

          this.notificationService.notify(<NotificationInfo>{
            title: this.strings.Warning,
            message: message.warnings.get(0),
            type: NotificationType.Warning,
            selfClose: false
          });
        }

        if (message.success) {
          this.regularPopup.close();
          this.removeDomElement();
        }
        else {

          this.resultMessage = message;
        }

      },
      listenNewEventsOnly: true
    }).unsubscribeOn(this.unsubscribeSubject);

  }

  showError(resultMessage: StateActionResultMessage) {
    this.resultMessage = resultMessage;
    this.showInternal();
  }

  public okClick() {
    this.plainBlockUI();

    // get correct tostate
    let toStateId = this.toStateView ? parseInt(this.toStateView.value) : this.toState.longId;
    let note = this.noteView.value || "";

    // send to server
    if (this.isPromote)
      this.stateStore.promote(toStateId, note);
    else
      this.stateStore.demote(toStateId, note);
  }
}