<ng-container *ngIf="quickSearch && quickSearch.showInline">

  <ng-container *ngIf="quickSearch.resultRequestCompleted">
    <div *ngIf="!quickSearch.resultSessionData || quickSearch.resultConfInfos.size == 0; else resultTemplate">
      {{strings.NoResultsWereFound}}
    </div>

    <ng-template #resultTemplate>
      <configuration-link-control *ngFor="let kv of quickSearch.resultConfInfos; trackBy: trackConf" [confSessionId]="kv[0]" [conf]="kv[1]"></configuration-link-control>
      <div class="d-block text-right">
        <a role="button" class="text-uppercase mt-3" (click)="showMore()">{{strings.More}}...</a>
      </div>
    </ng-template>
        
  </ng-container>

  <inline-spinner classes="py-3 pl-2" *ngIf="!quickSearch.resultRequestCompleted" text="{{strings.Loading}}"></inline-spinner>

</ng-container>
