import * as Immutable from "immutable";
import { BaseEntity } from "../../baseEntity";
import { Param } from "./param";

export class BoolParam extends Param 
{
  protected readonly THREE_STATE = "threeState";

  get threeState(): boolean { return this.getInternalValue<boolean>(this.THREE_STATE); }
  setThreeState(threeState: boolean): this { return this.setInternalValue(this.THREE_STATE, threeState); }
}