import { Component, ViewEncapsulation, Inject, HostListener, OnInit } from '@angular/core';
import { Routing } from "../shared/route/routeDecorator";
import { BaseComponent } from "../pages/shared";

/*
 * App Component
 * Top Level Component
 */
@Routing({ path: '', redirectTo: '/start', pathMatch: 'full' })
@Component({
  selector: 'browsers',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './browsersComponent.html'
})
export class BrowsersComponent extends BaseComponent  {



}