import { Inject, Component, Optional, ViewChild, ChangeDetectorRef } from "@angular/core";
import { BaseActionBoxComponent } from "../..";
import { GraphicsDecoration, ConfGraphicsValue, ConfGraphicsRequest, GraphicsDisplayStyle, ConfUIItem } from "../../models";
import { GraphicsPopupComponent } from "../../../configurator/popups";

// MOVE ConfiguratorController TO PAGES/SHARED/PROVIDERS
import { ConfDataController } from "../../providers/configurationData";
import { ConfiguratorStore, ConfPageSessionService } from "../../../configurator/providers";
import { ProductDataStore } from "../../providers/productData";
import { ModelFactory, RouteNames } from "../../providers";
import { PageStore } from "../../providers/page/pageStore";
import { ImageSets } from "../../../../shared/utils";
import { VisualObjectHelper } from "../../../configurator/parameters/shared/visualObjectHelper";
//import { window } from "d3";

@Component({
  selector: 'graphics-action-box',
  templateUrl: './graphicsActionBoxComponent.html',
  providers: [VisualObjectHelper]
})
export class GraphicsActionBoxComponent extends BaseActionBoxComponent {

  public graphicDecorations: Array<GraphicsDecoration>;

  @ViewChild(GraphicsPopupComponent)
  public graphicsPopup: GraphicsPopupComponent;

  public imageSet: string;

  constructor(
    @Optional() @Inject(ConfiguratorStore) configuratorStore: ConfiguratorStore,
    @Inject(ProductDataStore) public productStore: ProductDataStore,
    @Inject(ConfDataController) public configuratorController: ConfDataController,
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(ChangeDetectorRef) public cdr: ChangeDetectorRef,
    public modelFactory: ModelFactory,
    public pageStore: PageStore
  ) {
    super(configuratorStore);
  }

  public shouldBeVisible(): boolean {
    return this.graphicDecorations.length > 0;
  }

  public setup(): void {

    this.imageSet = this.getImageSet(ImageSets.SummaryActionDetail);

    this.graphicDecorations = [];

    if (!this.conf)
      return;

    if (this.pageStore.activeRouteName == RouteNames.Summary) {
      const uiItems: Array<ConfUIItem> = this.confPageSessionService.activeConfiguration.uiItems.filter(uiItem => uiItem.itemName === GraphicsDecoration.name).toArray();

      uiItems.forEach(x => {

        // Note! Grouping is not supported yet for action boxes. We need more work on this part.
        const visualObject = this.productStore.getEntity(x.id);
        // We only care about render graphics, popup graphic will be shown in side menu.
        if (visualObject instanceof GraphicsDecoration && (visualObject.displayStyle === GraphicsDisplayStyle.Popup || visualObject.displayStyle === GraphicsDisplayStyle.OpenInDownloadFormat)) {
          // TODO check if visible, this code is copied from DecorationVisibilityService, needs to be combined or something.
          if (!visualObject.hideEmptyDecoration || this.configuratorStore.getConfDataEntity<ConfGraphicsValue>(this.configurationId, this.confSessionId, visualObject.visualObjectId).svg)
            this.graphicDecorations.push(visualObject);
        }
      });
    }
    else if (this.pageStore.activeRouteName == RouteNames.Start) {

      this.configuratorStore.onConfigurationChange(this.configurationId, this.confSessionId, (conf) => {

        if (!conf.graphicsValues)
          return;

        this.conf.graphicsValues.forEach(x => {
          let graphicValue = this.configuratorStore.getConfDataEntity<ConfGraphicsValue>(this.configurationId, this.confSessionId, x);

          if (graphicValue && graphicValue.graphicsDecoration)
            this.graphicDecorations.push(graphicValue.graphicsDecoration);
        });

        this.visible = this.graphicDecorations.length > 0;
      }).unsubscribeOn(this.unsubscribeSubject);
    }
  }

  public openPopup(decoration: GraphicsDecoration) {

    if (decoration.displayStyle == GraphicsDisplayStyle.OpenInDownloadFormat) {
      let model: ConfGraphicsRequest = this.modelFactory.createAny(ConfGraphicsRequest.name) as any;
      model.client = this.pageStore.getActiveClientType();
      model.fileType = "SVG";
      model.configurationId = this.configurationId;
      model.confSessionId = this.confSessionId;
      model.graphicsDecorationId = decoration.visualObjectId;

      window.open(this.configuratorController.getConfigurationGraphicViewerUrl(model));
    }
    else
      this.graphicsPopup.show(decoration, this.configurationId, this.confSessionId);

    return false;
  }

}