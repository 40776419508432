import { Routes } from '@angular/router';
import { AuthGuard, RouteNames } from "../../shared/providers"
import { SearchResultsResolver } from "./searchResultsResolver";
import { SearchComponent } from "./searchComponent";
import { SearchResultComponent } from "./result/searchResultComponent";
import { SearchInfoComponent } from "./searchInfoComponent";

export const SearchRoutes: Routes = [
  {
    path: RouteNames.Search,
    component: SearchComponent,
    children: [
      {
        path: '', component: SearchInfoComponent, pathMatch: 'prefix', canActivate: [AuthGuard]
      },          
      {
        path: RouteNames.Result, component: SearchResultComponent
        , resolve: { searchResult: SearchResultsResolver }, runGuardsAndResolvers: "always",        
      }      
    ]
  }
];
