import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, UrlSerializer } from '@angular/router';

import { PropertyDecorationModule } from "../pages/configurator/decorations/property";
import { ActionBoxModule, AutoCompleteModule, ListModule, PagerModule, VisualObjectIconModule } from "../pages/shared/components";
import { ApplicationLifeCycleService, StartupArguments } from "../pages/shared/providers";
import {
    AccordionModule,
    AutoScrollbarModule,
    BreadcrumbModule, ButtonModule,

    DateRangePickerModule, DebouncedTextBoxModule, DragDropCustomModule,
    DropdownModule, FloatingModule, GridviewModule, HelpModule, IconModule, ImageModule, LayoutModule,

    MessageBoxModule, NotificationModule, PaginationModule, PanelModule, PasswordModule, PopoverModule,
    PopupModule, RadioButtonModule, TabsModule,

    TextBoxModule
} from "../shared/components";
import { BlockUIModule } from '../shared/components/blockUI';
import { BrowserSupportModule } from '../shared/components/browserSupport';
import { CheckboxModule } from "../shared/components/checkbox";
import { DatepickerModule } from "../shared/components/datepicker";
import { DragScrollModule } from "../shared/components/dragScroll";
import { MultiChoiceModule } from "../shared/components/multiChoice";
import { MultiSelectDropDownModule } from "../shared/components/multiSelectDropdown";
import { PrintModule } from "../shared/components/print";
import { RecaptchaFormsModule } from '../shared/components/recaptcha/recaptcha-forms.module';
import { RecaptchaModule } from '../shared/components/recaptcha/recaptcha.module';
import { TextBoxRangeModule } from '../shared/components/textboxRange';
import { GlobalServiceLocator } from "../shared/providers";
import { SafeHtmlModule } from "../shared/utils/pipes";
import { AppComponent } from "./appComponent";
import { declarationList } from "./declarationList";
import { providerList } from "./providerList";
import { routeList } from "./routelist";
import { SelectModule } from '../shared/components/select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AccordionOverlayModule } from '../shared/components/accordionOverlay';
import { PushMessageModule } from '../pages/shared/components/push';
import { TreeViewSimpleModule } from '../shared/components/treeViewSimple';
import { LowerCaseUrlSerializer } from '../shared/lowerCaseUrlSerializer';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,    
    HttpClientModule,
    NgbModule,
    RouterModule.forRoot(routeList, { useHash: true }),
    TabsModule,
    AccordionModule,
    AccordionOverlayModule,
    ImageModule,
    AutoCompleteModule,
    DebouncedTextBoxModule,
    RadioButtonModule,
    CheckboxModule,
    TextBoxModule,
    IconModule,
    HelpModule,
    MultiChoiceModule,
    DropdownModule,
    GridviewModule, 
    LayoutModule,
    ButtonModule,
    PaginationModule,
    PagerModule,
    PopupModule,
    PopoverModule,
    PanelModule,
    PasswordModule,
    PropertyDecorationModule,
    FloatingModule,
    NotificationModule,
    PushMessageModule,
    DragDropCustomModule,
    SafeHtmlModule,
    VisualObjectIconModule,
    ActionBoxModule,
    ListModule,
    DateRangePickerModule,
    BlockUIModule.forRoot(),
    DatepickerModule,
    PrintModule,
    AutoScrollbarModule,    
    MatDatepickerModule,
    MatNativeDateModule,
    MultiSelectDropDownModule,
    MatButtonModule, MatFormFieldModule, MatInputModule, MessageBoxModule,
    RecaptchaModule.forRoot(),    
    RecaptchaFormsModule,    
    DragScrollModule,
    TextBoxRangeModule,
    BreadcrumbModule,
    BrowserSupportModule,    
    SelectModule,
    TreeViewSimpleModule
  ],
  exports: [MatDatepickerModule, MatNativeDateModule, MatButtonModule, MatFormFieldModule, MatInputModule],
  declarations: declarationList,
  providers: [
    providerList,
    {
      provide: APP_INITIALIZER,
      useFactory: (startupArguments: StartupArguments) => () => Promise.resolve<boolean>(true),
      deps: [StartupArguments],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appLifeCycleService: ApplicationLifeCycleService) => () => appLifeCycleService.beforeApplicationInitialize().toPromise(),
      deps: [ApplicationLifeCycleService],
      multi: true
    },
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(injector: Injector) {
    GlobalServiceLocator.injector = injector;
  }

}