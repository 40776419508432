import { Component, ChangeDetectorRef, Input, Host, Inject, AfterContentInit } from "@angular/core";
import { CoCheckboxComponent } from '../coCheckboxComponent';
import { InputViewModel } from "../../shared";

@Component({ template: '' })
export class BaseCheckboxComponent {

  @Input()
  view: InputViewModel;

  constructor(public checkbox: CoCheckboxComponent, protected cdr: ChangeDetectorRef) {
  }

  public updateStyle(): void { }

  onClick($event): void {
    this.checkbox.processEvent($event);
  }

  onHelpClick($event): void {
    this.checkbox.helpClick($event);
  }

}