<div #visualObjectContainer *ngIf="confBoolValue">
  <div class="visual-object-margin">
    <check-box [triState]="parameter?.threeState"
               [view]="viewModel"
               (onValueChange)="onValueChange($event)"
               (onReadOnly)="onReadOnly($event)"
               (onDisabled)="onDisallowed($event)"
               (onHelpClick)="onHelpClick($event)">
    </check-box>
  </div>
</div>