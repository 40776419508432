import * as Immutable from "immutable";

import { ImmutableObject } from "../../../../shared/immutableObject";
import { ApiException } from "./apiException";
import { ApiMessage } from "./messages";

export class ApiResponse extends ImmutableObject
{
  protected readonly DATA = "data";
  protected readonly EXCEPTIONS = "exceptions";
  protected readonly MESSAGES = "messages";

  get data(): any { return this.getInternalValue<any>(this.DATA); }
  setData(data: any): this { return this.setInternalValue(this.DATA, data); }

  get exceptions(): Immutable.List<ApiException> { return this.getInternalValue<Immutable.List<ApiException>>(this.EXCEPTIONS); }
  setExceptions(exceptions: Immutable.List<ApiException>): this { return this.setInternalValue(this.EXCEPTIONS, exceptions); }

  get messages(): Immutable.List<ApiMessage> { return this.getInternalValue<Immutable.List<ApiMessage>>(this.MESSAGES); }
  setMessages(messages: Immutable.List<ApiMessage>): this { return this.setInternalValue(this.MESSAGES, messages); }
}