import { Input, Component } from "@angular/core";
import { Decoration } from "../../shared/models";
import { VisualObjectComponent } from "./visualObjectComponent";
import { ConfigurationSessionManager } from "../providers/configurationSessionManager";
import { BaseEntity } from "../../shared/baseEntity";
import { DataRowView, DataCellView } from "../../../shared/components/shared";
import { ManagedSubscription } from "../../../shared/managedSubscription";
import { ConfiguratorStore, ConfPageSessionService } from "../providers";
import { ImageSets } from "../../../shared/utils/imageSets";
import { VisualObjectHelper } from "../parameters/shared";

@Component({ template: '' })
export abstract class DecorationComponent<T extends Decoration> extends VisualObjectComponent {

  @Input()
  public decoration: T;

  // Note! Move it to visualObjectComponent if parameters are also expandable.
  @Input()
  public growVisualObject: boolean = false;

  public width: string;
  public minWidth: string;
  public rows: Array<DataRowView> = [];

  @Input()
  public showTitle?: boolean;

  public confDecorationSubscription: ManagedSubscription;

  public imageSet: string;

  constructor(public confStore: ConfiguratorStore,
    public confPageSessionService: ConfPageSessionService,
    public visualObjectHelper: VisualObjectHelper
) {
    super(confStore, confPageSessionService, visualObjectHelper);
  }

  ngOnInit() {
    this.width = this.visualObjectHelper.visualObjectWidth(this.decoration);
    this.minWidth = this.visualObjectHelper.decorationsMinWidth(this.decoration);

    this.imageSet = this.getImageSet(ImageSets.ConfiguratorDecorationHeader);

    if (this.showTitle == null)
      this.showTitle = this.decoration.title != null && this.decoration.title.trim() != '';

    super.ngOnInit();
  }

  public listenChanges(): void {

    this.confStore.onConfDecorationValuesChange(this.configurationId, this.confPageSessionService.confSessionId, this.decoration.visualObjectId, this.itemIds,
      (valuesById: Map<number, BaseEntity>): void => {
        this.setValue(valuesById);
      }).unsubscribeOn(this.unsubscribeSubject);
  }

  ngOnDestroy() {    
    super.ngOnDestroy();
  }
    
  public get itemIds(): number[] {
    return [];
  }

  setValue(itemValues: Map<number, any>): void {

  }
}