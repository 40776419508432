import { BaseEntity } from "../../baseEntity";

export class State extends BaseEntity {

    protected readonly NAME = "name";    
    protected readonly PROMOTE_MESSAGE = "promoteMessage";
    protected readonly DEMOTE_MESSAGE = "demoteMessage";
    protected readonly DEFAULT_STATE = "defaultState";
    protected readonly COLOR = "color";
    protected readonly ORDER = "order";
    protected readonly ICON_RELATIVE_URL = "iconRelativeUrl";
    
    get name(): string { return this.getInternalValue<string>(this.NAME); }
    setName(name: string): this { return this.setInternalValue(this.NAME, name); }

    get promoteMessage(): string { return this.getInternalValue<string>(this.PROMOTE_MESSAGE); }
    setPromoteMessage(promoteMessage: string): this { return this.setInternalValue(this.PROMOTE_MESSAGE, promoteMessage); }

    get demoteMessage(): string { return this.getInternalValue<string>(this.DEMOTE_MESSAGE); }
    setDemoteMessage(demoteMessage: string): this { return this.setInternalValue(this.DEMOTE_MESSAGE, demoteMessage); }

  get defaultState(): boolean { return this.getInternalValue<boolean>(this.DEFAULT_STATE); }
  setDefaultState(defaultState: boolean): this { return this.setInternalValue(this.DEFAULT_STATE, defaultState); }

    get color(): string { return this.getInternalValue<string>(this.COLOR); }
    setColor(color: string): this { return this.setInternalValue(this.COLOR, color); }

    get order(): number { return this.getInternalValue<number>(this.ORDER); }
    setOrder(order: number): this { return this.setInternalValue(this.ORDER, order); }

    get iconRelativeUrl(): string { return this.getInternalValue<string>(this.ICON_RELATIVE_URL); }
    setIconRelativeUrl(iconRelativeUrl: string): this { return this.setInternalValue(this.ICON_RELATIVE_URL, iconRelativeUrl); }
}