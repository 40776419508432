import { Component, ViewEncapsulation, Inject, ViewChild, ElementRef, ChangeDetectorRef, HostListener, ChangeDetectionStrategy, Output } from "@angular/core";
import * as Immutable from "immutable";
import { Subscription } from "rxjs";
import { RouteRedirector } from "../../../shared/providers";
import { DataRowView, DataCellView, ValueChangeEventArgs, InputViewModel, SizeUtility } from "../../../../shared/components/shared";
import { BaseComponent } from "../../../shared";
import { GlobalSettings, ConfDataProperty, ConfSearchResultItem } from "../../../shared/models";
import { ConfiguratorStore, ConfRouteParams } from "../../providers";
import { SearchDataStore } from "../../../shared/providers/searchData";
import { GlobalDataStore } from "../../../shared/providers/globalData";
import { ActivatedRoute } from "@angular/router";
import { SearchSessionData } from "../../../shared/state";
import { ConfSearchModelCacheService } from "../confSearchModelCacheService";
import { QuerySourceTypes } from "../../../shared/models/responses/searchResult";
import { ComparisonUrlParams } from "../../comparison/components/dragDrop/models/comparisonUrlParams";
import { ComparisonHelper } from "../../comparison";
import { NotificationService, NotificationInfo, NotificationType } from "../../../../../core/shared/components";
import { SearchSelectionCacheService } from "./searchSelectionCacheService";
import { SearchDataRowView } from "./searchDataRowView";
import { SearchResultsDataHandler } from "./searchResultsDataHandler";
import { PagerModel } from "../../../shared/components/pager/pagerModel";
import { PagerService } from "../../../shared/components/pager/pagerService";

@Component({
  selector: 'search-result',
  templateUrl: './searchResultComponent.html'
})
export class SearchResultComponent extends BaseComponent {

  public rows: Immutable.List<SearchDataRowView> = Immutable.List<SearchDataRowView>();
  public columns: Array<DataCellView> = [];
  public databaseMode: boolean;
  public searchSessionId: number = null;
  public showCompareButton: boolean = false;
  public enableComparison: boolean = false;

  public pagerModel: PagerModel;

  public messageSubscription: Subscription;

  constructor(
    @Inject(ActivatedRoute) public activatedRoute: ActivatedRoute,    
    @Inject(SearchDataStore) public searchDataStore: SearchDataStore,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ComparisonHelper) public helper: ComparisonHelper,
    @Inject(SearchResultsDataHandler) public searchDataHandler: SearchResultsDataHandler,
    @Inject(NotificationService) public notificationService: NotificationService,
    public routeRedirector: RouteRedirector,
    public searchModelCache: ConfSearchModelCacheService,
    public comparisonSelection: SearchSelectionCacheService,
    public cd: ChangeDetectorRef,
    public pagerService: PagerService
  ) {
    super();
    this.pagerModel = this.pagerService.create();
  }

  ngOnInit() {
    let result: SearchSessionData;

    let searchSessionId = this.activatedRoute.snapshot.params["searchSessionId"];

    this.unblockUI();

    if (isNaN(parseInt(searchSessionId))) {
      result = this.activatedRoute.snapshot.data['searchResult'] as SearchSessionData;
      if (result === null || result.searchSessionId == null)
        return;

      searchSessionId = result.searchSessionId;
    }
    else {
      // TODO: Do we need to redirect, because resolver won't let it through this fuction.
      if (this.searchModelCache && !this.searchModelCache.dataLoaded)
        return;

      result = this.searchDataStore.getSearchDataSession(parseInt(searchSessionId));

      if (result === null)
        return;
    }

    this.loadData(result);
    this.databaseMode = this.searchModelCache.querySourceType == QuerySourceTypes.Database;

    this.enableComparison = this.globalSettings.enableCompareConfigurations;
  }

  /**  Calculate the column width */
  protected columnStyle(colIndex: number) {
    let totalWidth = 0;
    let noOfZeroWidthColumns = 0;

    let columnWidth = this.uiSettings.search.columnSizes.length > colIndex ? this.uiSettings.search.columnSizes[colIndex] : null;

    for (let i = 0; i < this.globalSettings.searchResultAttributes.count(); i++) {
      let colSize = this.uiSettings.search.columnSizes.length > i ? this.uiSettings.search.columnSizes[i] : 0;
      totalWidth += colSize;
      if (colSize <= 0)
        noOfZeroWidthColumns++;
    }

    if (totalWidth == 0 && noOfZeroWidthColumns > 0)
      return { "width": 100 / noOfZeroWidthColumns + "%" };

    let defaultWidth = totalWidth / this.globalSettings.searchResultAttributes.count();

    if (columnWidth == null || columnWidth <= 0)
      columnWidth = defaultWidth;

    totalWidth = totalWidth + (noOfZeroWidthColumns * defaultWidth);
    columnWidth = (columnWidth / totalWidth) * 100;

    return { "width": columnWidth + "%" };
  }

  loadData(searchSessionData: SearchSessionData): void {
    this.columns = [];

    this.globalSettings.searchResultAttributes.forEach((property, index: number) => {
      let classes = "clickable";
      if (property.name === this.searchModelCache.sortingArgument.field) {
        classes += this.searchModelCache.sortingArgument.descending ? " caret-down" : " caret-up"
      }

      let columnView: DataCellView = {
        contents: this.strings.getString(property.title),
        classes: classes,
        safeStyle: this.columnStyle(index),
        tag: property,
      };

      this.columns.push(columnView);
    });
 
    this.rows = this.rows.clear();

    this.searchModelCache.sortingArgument.descending = searchSessionData.sortingInfo.descending;
    this.searchModelCache.sortingArgument.field = searchSessionData.sortingInfo.key;
    this.searchSessionId = searchSessionData.searchSessionId;

    this.pagerService.update(searchSessionData.paginationInfo, this.pagerModel);

    if (searchSessionData.confSearchResultItems) {

      // Show comparison buttons if results found.
      this.showCompareButton = searchSessionData.confSearchResultItems.size > 0;

      searchSessionData.confSearchResultItems.forEach(confSearchResultItem => {
        let conf = this.confStore.getConf(confSearchResultItem.configurationId, confSearchResultItem.confSessionId);
        let rowView: SearchDataRowView;
        rowView = { cells: [], classes: "configuration-link" };
        rowView.tag = confSearchResultItem;
        rowView.id = confSearchResultItem.configurationId.toString();
        rowView.view = { id: rowView.id, value: this.comparisonSelection.selected(Number(rowView.id))} as InputViewModel;

        this.globalSettings.searchResultAttributes.forEach(searchResultProperty => {

          let value = "";
          let confPropertyValue = conf.confPropertyValues.find(confPropertyValue => confPropertyValue.propertyName == searchResultProperty.name);
          if (confPropertyValue)
            value = confPropertyValue.keyAndValue.value;

          rowView.cells.push({ contents: value, classes: "ellipsis-wrap link-detail" } as DataCellView)
        });

        this.rows = this.rows.push(rowView);
      });

      this.setCompareButtonVisibility();
    }

    if (!this.cd['destroyed'])
      this.cd.markForCheck();
  }

  public sort(property: ConfDataProperty) {

    // Sort on the property.
    this.searchModelCache.sortingArgument.field = property.name;
    this.searchModelCache.sortingArgument.descending = !this.searchModelCache.sortingArgument.descending;

    this.searchDataHandler.performSearch(this.searchModelCache.searchModel);

      
  }

  public pagerUpdated() {
    this.searchModelCache.pagerModel = this.pagerModel;
    this.comparisonSelection.pagingScope = true;
    this.searchDataHandler.performSearch(this.searchModelCache.searchModel);

  }

  public trackHeader(index: number, headerColumn: DataCellView): any {
    // contents + classes is an unique pair.
    return headerColumn.contents + headerColumn.classes;
  }  

  public trackRow(index: number, row: DataRowView): any {
    return (row.tag as ConfSearchResultItem).configurationId;
  }

  public get globalSettings(): GlobalSettings {
    return this.globalDataStore.getGlobalData().globalSettings;
  }

  /**
   * Triggers on configuration selection change for comparison.
   * @param event
   */
  onCompareSelectionChanged(event: ValueChangeEventArgs, row: SearchDataRowView): void {

    if (Boolean(event.value) && this.hasReachedTheLimit()) {

      this.notifyMaxLimit();      
      event.component.cancelValue(false);
      
      return;
    }

    // Update the checked status.
    row.view.value = Boolean(event.value);

    // Add into cache
    this.comparisonSelection.add(Number(event.actionView.id), Boolean(event.value));

    // Sets the visibility for compare button
    this.setCompareButtonVisibility();
  }

  /**
   * Sets the visibility of compare button.
   * */
  setCompareButtonVisibility() {

    this.showCompareButton = this.comparisonSelection.selectedIds.length > 1;

  }

  /**
   * Redirects to comparison page.
   * @param event
   */
  onCompareConfigurations(event): void {

    this.blockUI();

    let selectedConfIds: Array<number> = this.comparisonSelection.selectedIds;

    // Define params
    let params = new ComparisonUrlParams();
    params.ids = this.helper.comparisonId(selectedConfIds);
    params.path = '0';
    
    // Redirect
    this.routeRedirector.redirectToComparison(params);

  }

  /**
   * Notifies if the length of selected configurations does not meet the requirements.
   * @param selectedConfs
   */
  hasReachedTheLimit(): boolean {
    return this.comparisonSelection.selectedIds.length == this.globalSettings.maxConfigurationsInComparison;    
  }

  notifyMaxLimit() {

    this.notificationService.notify(<NotificationInfo> {
      title: this.strings.Warning,
      message: this.strings.MaximumLimitForComparingConfigurations.replace('{0}', this.globalSettings.maxConfigurationsInComparison.toString()),
      type: NotificationType.Warning,
      selfClose: true,
      identifier: "warning-compare",
      sync: true
    });

  }

  onConfigurationClick($event, row: ConfSearchResultItem) {
    // Avoid navigation on checkbox click
    if($event && $event.target && $event.target.nodeName == 'TD')
      this.routeRedirector.redirectToSummary(<ConfRouteParams>{ id: row.configurationId, confSessionId: row.confSessionId });
  }

  ngOnDestroy() {
    if (this.messageSubscription)
      this.messageSubscription.unsubscribe();

    // Clear the cache only if user quits the search results.
    if (!this.comparisonSelection.pagingScope)
      this.comparisonSelection.clear();

    else this.comparisonSelection.pagingScope = false;

  }

}