<div class="input-container multi-select-group text-field control-border-b" [class.disabled]="disabled" >

  <dropdown-selected-values 
                            (openIt)="openIt()" 
                            (itemRemoved)="removeItem($event)" 
                            [show]="show" 
                            [items]="items" 
                            [selectedItems]="selectedItems" 
                            [emptyTitle]="emptyTitle"
                            class="w-100"
                            >
  </dropdown-selected-values>

  <!--drop-down-tree (click)="onClick()"><drop-down-tree-->

  <div class="dropdown-menu multi-select-menu" [style.width]="width" [class.show]="show">

    <!-- Dropdown menu items -->
    <multi-select-item 
                       *ngFor="let item of items" 
                       class="multi-select-item {{item.state}} row root-indent" 
                       [showItemIcons]="showItemIcons"
                       [iconImageSet]="iconImageSet"
                       [checkedIcon]="checkedIcon"
                       [uncheckedIcon]="uncheckedIcon"                       
                       [item]="item"                       
                       >
    </multi-select-item>

  </div>

  <!--<div class="scroll-buttons justify-content-center align-middle">
    <a role="button" class="scroll-left" (click)="moveLeft()">
      <co-image [width]="'16px'" [height]="'16px'" type="svg" key="sortLeft"></co-image>
    </a>

    <a role="button" class="scroll-right" (click)="moveRight()">
      <co-image [width]="'16px'" [height]="'16px'" type="svg" key="sortRight"></co-image>
    </a>
  </div>-->

</div>