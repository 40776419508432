import { Component, ViewChild, Output, Input, EventEmitter, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { ConfigDatepickerService} from '../providers/configDatepickerService';

@Component({
  selector: 'calendar-wrapper',
  templateUrl: './decoratedCalendarComponent.html',  
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DecoratedCalenderComponent implements OnChanges {

  @ViewChild(MatCalendar) matCalendar: MatCalendar<Date>;

  @Output() readonly selectedDateChange: EventEmitter<Date> = new EventEmitter<Date>();

  @Input() selectedDate: Date;
  @Input() prefixLabel: string;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  weekendFilter = (d: Date) => true;
  dateFormat: string;

  constructor(private config: ConfigDatepickerService) {
    this.dateFormat = config.dateRangeOptions.format;
    if (config.dateRangeOptions.excludeWeekends) {
      this.weekendFilter = (d: Date): boolean => {
        const day = d.getDay();
        return day !== 0 && day !== 6;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // Necessary to force view refresh
    //this.matCalendar._activeDate = changes.selectedDate.currentValue;
  }

  onSelectedChange(date) {
    this.selectedDateChange.emit(date);
  }

  onYearSelected(e) { }

  onUserSelection(e) { }

}