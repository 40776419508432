import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ImageModule } from "../../../../shared/components/image";

import { VisualObjectIconComponent } from "./visualObjectIconComponent";
import { VisualObjectVaultIconComponent } from "./visualObjectVaultIconComponent";

export * from "./visualObjectIconComponent";
export * from "./visualObjectVaultIconComponent";

@NgModule({
  imports: [
    CommonModule,
    ImageModule
  ],
  declarations: [
    VisualObjectIconComponent,
    VisualObjectVaultIconComponent
  ],
  exports: [
    VisualObjectIconComponent,
    VisualObjectVaultIconComponent
  ]
})
export class VisualObjectIconModule {
}