import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import * as Immutable from "immutable";
import { InputViewModel, PopupService } from "../../../../shared/components";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import { ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { BomStore } from "./bomStore";


@Component({ template: '' })
export abstract class BomControlComponent extends AbstractPopupComponent {
  public bomDecorationId: number;

  public itemNoView: InputViewModel;
  public quantityView: InputViewModel;
  public revisionView: InputViewModel;
  public revisionItemViews: InputViewModel[] = [];
  public quantityUnitView: InputViewModel;
  public quantityUnitItemViews: InputViewModel[] = [];
  public operationSequenceView: InputViewModel;
  public operationSequenceItemViews: InputViewModel[] = [];

  @Input()
  isAllowOperationSequenceEdit = false;

  @Output()
  closedEvent = new EventEmitter();

  constructor(
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(BomStore) public bomStore: BomStore,
    public cdr: ChangeDetectorRef,
    public popupService: PopupService,
  ) {
    super(cdr, popupService);
  }

  ngOnInit() {
    this.itemNoView = new InputViewModel(this.strings.ItemNumber);
    this.itemNoView.readOnly = true;

    this.revisionView = new InputViewModel(this.strings.Revision);

    this.quantityView = new InputViewModel(this.strings.Quantity);
    this.quantityView.value = 1;

    this.quantityUnitView = new InputViewModel(this.strings.QuantityUnit);

    this.operationSequenceView = new InputViewModel(this.strings.OperationSequence);

    super.ngOnInit();
  }

  public setupQuantityUnits(quantityUnits: Immutable.Map<string, string>, quantityUnitId?: number) {
    this.quantityUnitItemViews = [];
    this.quantityUnitItemViews.push(new InputViewModel(""));

    let selectedQuantityUnitView: InputViewModel;
    quantityUnits.forEach((quantityUnit: string, id: string) => {
      let quantityUnitChildView = new InputViewModel(quantityUnit);
      quantityUnitChildView.id = id;
      this.quantityUnitItemViews.push(quantityUnitChildView);

      if (quantityUnitId && id == quantityUnitId.toString()) {
        this.quantityUnitView.value = id;
        this.quantityUnitView.formattedValue = quantityUnit;
      }
    });
    if (quantityUnitId == null)
      this.quantityUnitView.formattedValue = this.quantityUnitView.value = null;
  }

  public setupOperationSequence(operationSequences: Immutable.Map<string, string>, operationSequenceId?: number) {
    if (!this.isAllowOperationSequenceEdit)
      return;

    this.operationSequenceItemViews = [];
    this.operationSequenceItemViews.push(new InputViewModel(""));

    let selectedQuantityUnitView: InputViewModel;
    operationSequences.forEach((operationSequence: string, id: string) => {
      let operationSequenceView = new InputViewModel(operationSequence);
      operationSequenceView.id = id;
      this.operationSequenceItemViews.push(operationSequenceView);

      if (operationSequenceId && id == operationSequenceId.toString()) {
        this.operationSequenceView.value = id;
        this.operationSequenceView.formattedValue = operationSequence;
      }
    });
    if (operationSequenceId == null)
      this.operationSequenceView.formattedValue = this.operationSequenceView.value = null;
  }

  public setupRevisions(revisions: string[], selectedRevision?: string) {
    this.revisionItemViews = [];

    let selectedRevisionView: InputViewModel;
    revisions.forEach((revision: string) => {
      let revisionView = new InputViewModel(revision);
      revisionView.id = revision;
      if (selectedRevision === revision)
        selectedRevisionView = revisionView;

      this.revisionItemViews.push(revisionView);
    });

    this.revisionView.value = this.revisionView.formattedValue = selectedRevisionView ? selectedRevisionView.id : this.revisionItemViews.length > 0 ? this.revisionItemViews[0].id : "";
  }

  public show(bomDecorationId: number): void {
    this.bomDecorationId = bomDecorationId;
    this.showInternal();
  }

  public onOkClick() {
    this.removeDomElement();
  }

  public onCancelClick() {
    this.removeDomElement();
  }

  removeDomElement() {
    super.removeDomElement();

    this.closedEvent.emit();
  }

}