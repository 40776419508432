import { Injectable, Inject } from '@angular/core';
import * as Immutable from "immutable";

import { AppStoreSubscriptionManager } from "../providers/appStoreSubscriptionManager";
import { ManagedSubject } from "../../../shared/managedSubject";
import { AppStore } from "./appStore";
import { AccountDataState, ProductDataState, RequestStatus, AppAction, HttpAction } from "./shared";
import { BaseEntity } from "../baseEntity";
import { ActionInfo } from "./actionInfo";
import { BaseRequest } from "../models";
import { ModelFactory } from "../providers/modelFactory";

@Injectable()
export abstract class BaseStore {

  constructor(
    protected appStore: AppStore,
    protected appStoreSubscriptionManager: AppStoreSubscriptionManager,
    protected modelFactory: ModelFactory, // TODO remove model factory
  ) { }
      
  public dispatch<T>(action: AppAction<T>): void {
    this.appStore.dispatch(action);
  }

  protected getActionInfo(actionId: number) {
    return this.appStore.getActionInfo(actionId);
  }

  /** Subscribe to store and return its status (successful/rejected.*/
  protected createAction(action: HttpAction<any>, onfulfilled: (actionInfo: ActionInfo) => void, onrejected?: (actionInfo: ActionInfo) => void): ManagedSubject<any> {
    return this.appStoreSubscriptionManager.createSubject((appStore) => {

      let actionInfo = this.getActionInfo(action.id);
      if (actionInfo && actionInfo.status == RequestStatus.SUCCESS) {
        return onfulfilled(actionInfo);
      }

      if (actionInfo && (actionInfo.status == RequestStatus.ERROR || actionInfo.status == RequestStatus.ABORT)) {
        return onrejected ? onrejected(actionInfo) : ManagedSubject.ErrorValue(actionInfo.payload);
      }

      return ManagedSubject.IGNORE_VALUE;
    }, true);
  }

  getObjectsFromResultId<T extends BaseEntity>(data: Immutable.List<number>): Array<T> {
    let list: T[] = [];

    data.forEach(resultId => {
      list.push(this.getEntity<T>(resultId) as T);
    });

    return list;
  }

  getObjects<T extends BaseEntity>(ids: number[], constructor: { new(): T }): Array<T> {
    let list: T[] = [];

    ids.forEach(id => {
      let entity: T = this.getEntity<T>(id);

      if (entity instanceof constructor)
        list.push(this.getEntity<T>(id) as T);
    });

    return list;
  }

  public getEntity<T extends BaseEntity>(id: number) { return null; }
}