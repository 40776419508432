<action-box *ngIf="visible"
            class="prices-detail detail-item {{extraStyles}}"
            iconLeft="price"
            textLeft="{{strings.Price}}">
  <gridview *ngIf="contentVisible" >
    <gridview-row classes="row-data align-middle">
      <columntemplate size="auto">
        <div class="price-category" > {{confPriceValue?.priceListCategoryName || storedPriceValue?.priceListCategoryName}}</div>
      </columntemplate>
      <columntemplate class="border-left-0 p-0">
        <gridview-row>
      <columntemplate *ngIf="isStoredPriceVisible" classes="stored mx-3" size="auto">
        <div *ngIf="showPriceType" class="price-type">{{strings.Stored}}</div>
        <div>          
          <a class="price-detail" role="button" title="{{strings.Details}}" (click)="onDetailsClick($event, true)">{{storedPriceValue.price}}</a>
        </div>
      </columntemplate>
      <columntemplate *ngIf="isCurrentPriceVisible" classes="mx-3" size="auto">
        <div *ngIf="showPriceType" class="price-type">{{strings.Current}}</div>
        <div>
          <a *ngIf="confPriceValue.effective else expired" class="price-detail" role="button" title="{{strings.Details}}" (click)="onDetailsClick($event)">{{confPriceValue.price}}</a>
          <ng-template #expired><div><span class="text-danger">{{strings.Expired}}</span></div></ng-template>
        </div>
      </columntemplate>
        </gridview-row>
      </columntemplate>
    </gridview-row>
  </gridview>
  <div card-type="control" *ngIf="isPrintView">
    <print-checkbox (onValueChange)="onPrintValueChange($event)"></print-checkbox>
  </div>
</action-box>
