import { Injectable } from "@angular/core";

import { LocalStorageData } from "../models";
import { ModelFactory } from "./modelFactory";
import { JsonConvert } from "../../../shared/providers/jsonConvert";
import { ManagedSubject } from "../../../shared/managedSubject";
import { ValueSubscriptionManager } from "./valueSubscriptionManager";
import { SubscriptionOptions, ManagedSubscription } from "../../../shared/managedSubscription";


@Injectable()
export class LocalStorageService {

  public localStorageData: LocalStorageData;
  
  public constructor(
    public modelFactory: ModelFactory,
    public jsonConvert: JsonConvert,
    public valueSubscriptionManager: ValueSubscriptionManager
  ) { }

  private _localStorageSubject: ManagedSubject<LocalStorageData>;
  public get localStorageSubject(): ManagedSubject<LocalStorageData> {

    if (!this._localStorageSubject) {
      this._localStorageSubject = this.valueSubscriptionManager.createSubject<LocalStorageData>(ManagedSubject.IGNORE_VALUE, false, 1, false);
    }

    return this._localStorageSubject;
  }

  public get key() {
    return `${location.origin}${location.pathname}`;
  }

  public createLocalStorageData(value: string) {
    
      if (value == null || value == undefined || value.trim().length == 0)
        return this.modelFactory.createAny<LocalStorageData>(LocalStorageData.name, { reloadCacheCounter: 0 });
      else
        return this.modelFactory.createAny<LocalStorageData>(LocalStorageData.name, JSON.parse(value)); 
  }

  public onLocalStorageChanged(subscriptionOptions: SubscriptionOptions<LocalStorageData>): ManagedSubscription {
    return this.localStorageSubject.subscribe(subscriptionOptions);
  }

  public storageChange(event: StorageEvent) {

    if (event.key != this.key) {
      return;
    }

    let newLocalStorageData = this.createLocalStorageData(event.newValue);
    if (this.localStorageData && this.localStorageData.timeStamp == newLocalStorageData.timeStamp)
      return;

    this.storeAndTrigger(newLocalStorageData);
  }

  public setData(localStorageData: LocalStorageData) {

    if (localStorageData == null || localStorageData == undefined)
      localStorageData = this.createLocalStorageData(null);

    localStorageData = localStorageData.setTimeStamp(new Date().getTime());

    this.storeAndTrigger(localStorageData);
  }
  
  protected storeAndTrigger(localStorageData: LocalStorageData) {

    this.localStorageData = localStorageData;
    
    localStorage.setItem(this.key, this.jsonConvert.stringify(this.localStorageData));

    this.localStorageSubject.nextValue(this.getData());
  }

  public getData() {
    if (!this.localStorageData)
      this.localStorageData = this.createLocalStorageData(localStorage.getItem(this.key));

    return this.localStorageData;
  }

}