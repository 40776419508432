import { Component, Input, ElementRef, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { NotificationService } from './notificationService';
import { Notification, NotificationInfo, NotificationPositionTypes, NotificationConfig } from "./notificationInfo";
import { JsonConvert } from '../../providers';

@Component({
  selector: 'notification',
  templateUrl: './notificationComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent {
  public notifications: Notification[] = [];

  // Default settings
  public config: NotificationConfig = new NotificationConfig();

  public showFallbackIcon: boolean = false;

  /**
   * Raw text
   */
  @Input() public detailButtonText: string = "Detail";

  /**
   * Message text
   */
  @Input() public messageButtonText: string = "Message";


  constructor(
    public cd: ChangeDetectorRef,
    public notficationService: NotificationService,
    public jsonConvert: JsonConvert) {

    this.notficationService.observers.subscribe(notification => {
      this.setNotification(notification);
    });

    this.config = this.notficationService.notificationConfig;
  }

  public createTimeout(notification: Notification, ignoreNotifyUpdate: boolean = false) {
    notification.timeout = setTimeout(() => {
      // Update notify status, It helps to remove the dom element when system sets it to false.

      if (!ignoreNotifyUpdate)
        notification.notify = !notification.notify;

      setTimeout(() => {

        // Update existing notification
        if (notification.timestamp && notification.timestamp > Date.now() - (notification.info.delay || this.config.delay))
          this.createTimeout(notification, true);
        else {
          let index = this.notifications.indexOf(notification);
          if (index > -1)
            this.notifications.splice(index, 1);

          this.cd.markForCheck();
        }
      }, 1000);
    }, notification.info.delay || this.config.delay);

  }

  public alreadyExists(info1: NotificationInfo, info2: NotificationInfo) {
    return this.jsonConvert.stringify(info1) === this.jsonConvert.stringify(info2);
  }

  public setNotification(notification: Notification) {

    let existingNotification = notification.info.sync ? this.notifications.find(x => x.info.identifier == notification.info.identifier) :
      this.notifications.find(x => this.alreadyExists(x.info, notification.info));

    if (existingNotification) {
      // Replace the contents in existing one.
      existingNotification.timestamp = Date.now() - (notification.info.delay || this.config.delay) / 2;
      existingNotification.info = notification.info;
      this.cd.markForCheck();
      return;
    }

    notification.notify = true;
    notification.detailSelected = true; // By default detail tab shall be selected.
    notification.rawSelected = false;

    this.notifications.push(notification);
    this.cd.markForCheck();
    if (notification.info.selfClose)
      this.createTimeout(notification);
  }

  public closeIt(notification: Notification): void {
    // Update notify status, It helps to remove the dom element when system sets it to false.
    notification.notify = !notification.notify;
    let index: number = this.notifications.indexOf(notification);
    if (index > -1) {
      this.notifications.splice(index, 1);
      this.cd.markForCheck();
      this.cd.detectChanges();
    }
  }

  detailSelect(event, notification: Notification): void {
    notification.rawSelected = false;
    notification.detailSelected = true;
    this.cd.markForCheck();
  }

  rawSelect(event, notification: Notification): void {
    notification.detailSelected = false;
    notification.rawSelected = true;
    this.cd.markForCheck();
  }

  onImageLoadError(event): void {
    this.showFallbackIcon = true;
  }

}