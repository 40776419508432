import { UiElement } from "./uiElement";
import { UiAction } from "./uiAction";

export class UiPopup extends UiAction {

  protected readonly HEADER = "header";
  protected readonly ICON = "icon";
  protected readonly MODAL = "modal";
  protected readonly WIDTH = "width";
  protected readonly HEIGHT = "height";
  protected readonly UI_ELEMENT = "uiElement";
  protected readonly LEAD_PARAGRAPH = "leadParagraph";
  protected readonly PARAGRAPH = "paragraph";
  protected readonly STYLE = "style";
  protected readonly CSS_CLASS = "cssClass";

  get header(): string { return this.getInternalValue<string>(this.HEADER); }
  setHeader(header: string): this { return this.setInternalValue(this.HEADER, header); }

  get icon(): string { return this.getInternalValue<string>(this.ICON); }
  setIcon(icon: string): this { return this.setInternalValue(this.ICON, icon); }

  get modal(): boolean { return this.getInternalValue<boolean>(this.MODAL); }
  setModal(modal: boolean): this { return this.setInternalValue(this.MODAL, modal); }

  get width(): number { return this.getInternalValue<number>(this.WIDTH); }
  setWidth(width: number): this { return this.setInternalValue(this.WIDTH, width); }

  get height(): number { return this.getInternalValue<number>(this.HEIGHT); }
  setHeight(height: number): this { return this.setInternalValue(this.HEIGHT, height); }

  get uiElement(): UiElement { return this.getInternalValue<UiElement>(this.UI_ELEMENT); }
  setUiElement(uiElement: UiElement): this { return this.setInternalValue(this.UI_ELEMENT, uiElement); }

  get leadParagraph(): string { return this.getInternalValue<string>(this.LEAD_PARAGRAPH); }
  setLeadParagraph(leadParagraph: string): this { return this.setInternalValue(this.LEAD_PARAGRAPH, leadParagraph); }

  get paragraph(): string { return this.getInternalValue<string>(this.PARAGRAPH); }
  setParagraph(paragraph: string): this { return this.setInternalValue(this.PARAGRAPH, paragraph); }

  get style(): string { return this.getInternalValue<string>(this.STYLE); }
  setStyle(style: string): this { return this.setInternalValue(this.STYLE, style); }

  get cssClass(): string { return this.getInternalValue<string>(this.CSS_CLASS); }
  setCssClass(cssClass: string): this { return this.setInternalValue(this.CSS_CLASS, cssClass); }
}