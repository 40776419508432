import { AbstractOperationMessage } from "./abstractOperationMessage"

export enum SaveErrorReason {
  None = "None",
  MandatoryParameters = "MandatoryParameters",
  WarningAttributes = "WarningAttributes",
  AnonymousUserEmail = "AnonymousUserEmail",
  Other = "Other"
}

export class ConfSaveMessage extends AbstractOperationMessage {

  protected readonly SAVE_ERROR_REASON = "saveErrorReason";

  get saveErrorReason(): string { return this.getInternalValue<string>(this.SAVE_ERROR_REASON, SaveErrorReason); }
  setSaveErrorReason(saveErrorReason: string): this { return this.setInternalValue(this.SAVE_ERROR_REASON, saveErrorReason); }
}