<div class="m-3">

  <div class="my-2">
    <dropdown [itemsView]="itemsView"
              [view]="dropdownView"
              (onValueChange)="onValueChange($event)"
              >
    </dropdown>

  </div>

  <div class="my-4">
    <text-box [view]="noOfCopiesView" [errors]="errorMessages" icon="count" type="number"></text-box>
  </div>

  <div class="mt-2 text-right">
    <button class="btn btn-primary text-uppercase" [style.width.px]="'100'" (click)="makeCopies()" type="button">Yes</button>
    <button class="btn btn-primary ml-2 text-uppercase" [style.width.px]="'100'" (click)="onCancel.emit($event)" type="button">No</button>
  </div>
</div>
