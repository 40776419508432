import * as Immutable from "immutable";

import { ApiMessage } from "./apiMessage";

export class RedirectMessage extends ApiMessage {

  readonly VIEW = "view";
  readonly PARAMETERS = "parameters";
  readonly QUERY_STRING = "queryString";
  readonly MESSAGE = "message";

  // MsCrm ->
  readonly EXTERNAL_URL = "externalUrl";
  readonly EXTRA_ARGS = "extraArgs";
  // MsCrm <-

  get view(): string { return this.getInternalValue<string>(this.VIEW); }
  setView(view: string): this { return this.setInternalValue(this.VIEW, view); }

  get message(): string { return this.getInternalValue<string>(this.MESSAGE); }
  setMessage(message: string): this { return this.setInternalValue(this.MESSAGE, message); }

  get parameters(): Immutable.Map<string, string> { return this.getInternalValue<Immutable.Map<string, string>>(this.PARAMETERS); }
  setParameters(parameters: Immutable.Map<string, string>): this { return this.setInternalValue(this.PARAMETERS, parameters); }

  get queryString(): Immutable.List<string> { return this.getInternalValue<Immutable.List<string>>(this.QUERY_STRING); }
  setQueryString(queryString: Immutable.List<string>): this { return this.setInternalValue(this.QUERY_STRING, queryString); }

  // MsCrm ->
  get externalUrl(): string { return this.getInternalValue<string>(this.EXTERNAL_URL); }
  setExternalUrl(externalUrl: string): this { return this.setInternalValue(this.EXTERNAL_URL, externalUrl); }

  get extraArgs(): Immutable.Map<string, string> { return this.getInternalValue<Immutable.Map<string, string>>(this.EXTRA_ARGS); }
  setExtraArgs(extraArgs: Immutable.Map<string, string>): this { return this.setInternalValue(this.EXTRA_ARGS, extraArgs); }
  // MsCrm <-
}