<div>

  <!-- <mat-divider></mat-divider> -->
  <span class="datepicker-label">
    <label>{{prefixLabel}}</label>
    <label class="selected">{{selectedDate | date:dateFormat}}</label>
  </span>
  <!-- <mat-divider></mat-divider> -->
  <mat-calendar [startAt]="selectedDate"
                [selected]="selectedDate"
                [minDate]="minDate"
                [maxDate]="maxDate"
                (selectedChange)="onSelectedChange($event)"
                (yearSelected)="onYearSelected($event)"
                (_userSelection)="onUserSelection($event)"
                [dateFilter]="weekendFilter" >
  </mat-calendar>

  <!--<datepicker [(date)]="minDate" (onSelect)="onSelectedChange($event)" dateFormat="YYYY-MM-DD" [startDate]="minDate" [endDate]="maxDate"></datepicker>-->

</div>