import { Component, Input, SimpleChanges } from "@angular/core";
import { BaseComponent } from "../shared";

@Component({ template: '' })
export abstract class BaseViewComponent extends BaseComponent {

  @Input()
  public configurationId: number;

  @Input()
  public confSessionId: number;

  // TODO: Need more investigation, It seems no input params(configurationId, confSessionId) are provided.
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['configurationId'] || changes['confSessionId']) {
      this.onConfigurationChange();
    }
  }

  onConfigurationChange(): void {
    
  }

}