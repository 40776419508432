import * as Immutable from "immutable";
import { ConfValue } from "./confValue";

export class ConfMultiChoiceValue extends ConfValue {

  protected readonly VALUE = "value";

  get value(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.VALUE); }
  setValue(value: Immutable.List<number>): this { return this.setInternalValue(this.VALUE, value); }

}