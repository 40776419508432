import { BaseCommand } from "./baseCommand";

export class PasswordRecoverCommand extends BaseCommand {

  username: string;
  passwordRecoverRequest: boolean;

  varificationCode: string;
  varificationCodeRequest: boolean;

  newPassword: string;
  changePasswordRequest: boolean;

  ValidateSessionRequest: boolean;
  
}