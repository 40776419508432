import * as Immutable from "immutable";

import { ImmutableObject } from "../../../../shared/immutableObject";
import { ConfInfo } from "../entities/confInfo";

export class ConfInfoResult extends ImmutableObject {

  protected readonly CONF_INFO_IDS = "confInfoIds";
  protected readonly ENTITIES = "entities";

  get confInfoIds(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.CONF_INFO_IDS); }
  setConfInfoIds(confInfoIds: Immutable.List<number>): this { return this.setInternalValue(this.CONF_INFO_IDS, confInfoIds); }

  get entities(): Immutable.Map<number, ConfInfo> { return this.getInternalValue<Immutable.Map<number, ConfInfo>>(this.ENTITIES); }
  setEntities(entities: Immutable.Map<number, ConfInfo>): this { return this.setInternalValue(this.ENTITIES, entities); }
}