import { Component, OnInit, AfterContentInit, Inject, Input, EventEmitter, Output } from "@angular/core";
import { BaseButtonComponent } from "./baseButtonComponent";

@Component({
  selector: 'button-circle',
  templateUrl: './circleButtonComponent.html'
})
export class CircleButtonComponent extends BaseButtonComponent {

  @Input() icon: string;

  @Input() imageSet: string;

  @Input() iconWidth: string = "32px";

  @Input() iconHeight: string = "32px";

  @Input() tooltip: string;

}