import { Injectable } from "@angular/core";
import { ConfUIItem, AbstractCodeValue } from "../../../models";
import { ProductDataStore } from "../../../providers/productData";
import { SummaryDisplayStyles } from "../../../../configurator/summary/prices/SummaryDisplayStyle";
import { ConfPageSessionService } from "../../../../configurator/providers";
import { BaseCodeValuesProvider } from "./baseCodeValuesProvider";

@Injectable()
export class StoredCodeValuesProvider extends BaseCodeValuesProvider {

  constructor(public productStore: ProductDataStore, public storeSession: ConfPageSessionService) {
    super(productStore, storeSession);
  }

  isValid(codeUIItem: ConfUIItem, isEditor: boolean, summaryDisplayStyle: SummaryDisplayStyles): boolean {

    // StoredCodeValues are not supported for Editor.
    if (isEditor)
      return false;

    return super.isValidForSummary(codeUIItem, summaryDisplayStyle);
  }


  /**
   * Returns the <StoreCodeValue>
   * @param fileId
   */
  getCodeValue(fileId: number): AbstractCodeValue {

    let conf = this.storeSession.activeConfiguration;

    return conf.storedData.codeValues.find(x => x.longId == fileId);
  }

}