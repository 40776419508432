import { ChangeDetectorRef, Component, Inject, Input, ViewEncapsulation, ViewChild, SimpleChanges, NgZone } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { FormModel } from "..";
import { InputViewModel, NotificationInfo, NotificationService, NotificationType, PopupService } from "../../../shared/components";
import { Routing } from "../../../shared/route/routeDecorator";
import { ImageSets, Delay } from "../../../shared/utils";
import { PopupIdentifiers } from "../../configurator/providers";
import { PageComponent } from "../../shared";
import { LoginMessage, User } from "../../shared/models";
import { ApplicationLifeCycleService, AppStoreSubscriptionManager, LocalStorageService, RouteNames, RouteRedirector, IHttpDataInfo } from "../../shared/providers";
import { AccountDataMessageProvider, AccountDataStore } from "../../shared/providers/accountData";
import { GlobalDataStore } from "../../shared/providers/globalData";
import { PageStore } from "../../shared/providers/page";
import { HttpService } from "../../../shared/providers/httpService";
import { RecaptchaComponent } from "../../../shared/components/recaptcha/recaptcha.component";

@Routing({ path: 'account/login' })
@Component({
  encapsulation: ViewEncapsulation.None,
  templateUrl: './loginComponent.html',
  providers: [AppStoreSubscriptionManager]
})
export class LoginComponent extends PageComponent {

  protected returnUrl: string;
  protected changeWindowLocation: boolean = false;

  public errorMessages: Array<string>;

  public userNameView: InputViewModel;
  public passwordView: InputViewModel;

  @Input()
  public errorMessage: string;
    
  public showRecaptcha: boolean = false;
  public recaptchaResolved: boolean = false;
  public siteKey: string;
  public formModel: FormModel = {};
  public localStorageData = this.localStorageService.getData();

  public useAzureSSO: boolean = false;
  public azureSSOButtonText: string = "";
  public azureSSOButtonColor: string = "";
  public azureSSOLoginUrl: string = "";
  public azureSSOUpn: string = "";
  private AUTOLOGIN_UPN_PARAM: string = "ssoupn=";
  
  // MsCrm
  public isInMsCrmMode: boolean = false;

  @ViewChild(RecaptchaComponent)
  protected recaptchaComponent: RecaptchaComponent;
  protected recaptchaKey: string;

  constructor(
    @Inject(ActivatedRoute) public activatedRoute: ActivatedRoute,
    @Inject(AccountDataStore) public accountStore: AccountDataStore,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    @Inject(PageStore) public pageStore: PageStore,
    @Inject(PopupService) public popupService: PopupService,
    @Inject(HttpService) public httpService: HttpService,
    public globalDataStore: GlobalDataStore,
    public appStoreSubscriptionManager: AppStoreSubscriptionManager,
    public appLifeCycleService: ApplicationLifeCycleService,
    public accountMessageProvider: AccountDataMessageProvider,
    public localStorageService: LocalStorageService,
    public notificationService: NotificationService,
    public cdr: ChangeDetectorRef,
    private ngZone: NgZone,

  ) {
    super();
  }

  ngOnInit() {
    this.userNameView = new InputViewModel(this.strings.Username);
    this.userNameView.name = "identity";
    this.userNameView.icon = "user";

    this.passwordView = new InputViewModel(this.strings.Password);
    this.passwordView.name = "password";
    this.passwordView.icon = "password";
    this.passwordView.iconImageSet = this.getImageSet(ImageSets.Control);

    this.showRecaptcha = this.accountStore.showRecaptcha();

    this.pageStore.updateActiveRoute(RouteNames.Login);    
    this.useAzureSSO = this.globalDataStore.getGlobalData().globalSettings.useAzureSSO;
    this.azureSSOLoginUrl = this.globalDataStore.getGlobalData().globalSettings.azureSSOLoginUrl;

    // MsCrm
    this.isInMsCrmMode = this.globalDataStore.getGlobalData().globalSettings.isInMsCrmMode;

    this.activatedRoute.params.subscribe(params => {
      // Remove the return url argument from the arguments, and the rest of the arguments should be forwarded
      // this.returnUrl = JSON.stringify(params).replace("{", "").replace("}", "").replace(/,/g, ";").replace(/['"]+/g, '').replace(/:/g, "=");

      // Grab returnurl directly from params object without stringifying the entire object and trimming it.
      if (params['returnurl'])
        this.returnUrl = params['returnurl'];
      
      if (this.returnUrl && this.returnUrl?.includes(this.AUTOLOGIN_UPN_PARAM) && this.useAzureSSO) {      
        this.setAzureUpn(this.returnUrl);
        this.azureSso(null);
      }

    });
    this.activatedRoute.queryParams.subscribe(queryParams => {

      if (queryParams['returnurl']) {
        this.changeWindowLocation = true;
        this.returnUrl = queryParams['returnurl'];
      }
    });

    if (this.globalDataStore.getGlobalData().globalSettings.showCookieInformation) {
      this.notificationService.notify(<NotificationInfo>{
        title: this.strings.CookieInformation,
        message: " ",
        detail: this.strings.CookieInformationMessage,
        rawInfo: this.strings.CookieInformationMessageDetails,
        type: NotificationType.Info,
        identifier: "cookieInformation",
        selfClose: false
      });

      sessionStorage.setItem("cookieConsent", "true");
    }

    this.formModel.captcha = '';
    this.siteKey = this.globalDataStore.getGlobalData().globalSettings.googleReCaptcha;

    super.ngOnInit();
  }

  // MsCrm ->
  public sso(event) {
    let url = '/mscrm/getSSOUrl#';
    this.httpService.get(url).subscribe(data => {
      window.location.href = data;
    });
  }
  // MsCrm <-

  public setAzureUpn(returnUrl: string) {
    this.azureSSOUpn = this.returnUrl?.split(';')?.find(x => x.includes(this.AUTOLOGIN_UPN_PARAM))?.replace(this.AUTOLOGIN_UPN_PARAM, '')?.replace(';', '');
  }

  public azureSso(event) {
    let url = this.azureSSOLoginUrl;
    if (this.azureSSOUpn)
      url += "&login_hint=" + this.azureSSOUpn;
    else
      url += "&prompt=select_account";

    let titel = "Single Sign On";
    var w = 600; var h = 600;
    this.popupwindow(url, titel, w, h);
  }

  public popupwindow(url, title, w, h) {
    var left = (screen.width / 2) - (w / 2);
    var top = (screen.height / 2) - (h / 2);
    var SSO_login_window = window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
    var timer = setInterval(() => {
      try {
        if (SSO_login_window.closed) {
          clearInterval(timer);
          this.accountStore.logInUser(null, null, null);
        }
      } catch (e) {
        alert("Please enable using popups for this site in the browser so that you can be authenticated with SSO.");
        clearInterval(timer);
      }
    }, 1000);
    return SSO_login_window;
  }


  public redirect() {
    if (this.returnUrl) {

      // If the return url is outside the signle page application, then router redirector cannot navigate outside the single page application.
      if (this.changeWindowLocation)
        window.location.href = this.returnUrl;
      else {
        this.routeRedirector.redirectByUrl(this.returnUrl);
      }
    }    
    else
      this.routeRedirector.redirectToStart();
  }

  public redirectIfLoggedIn() {
    if (this.accountStore.hasUserSession()) {

      let user: User = this.accountStore.getUser();
      if (user && user.systemAuthorization.hasAnonymousAccess)
        this.routeRedirector.redirectToSelector();

      // Default redirect.
      else
        this.redirect();
    }
  }

  ngAfterContentInit() {
    // Redirect if user is already logged in
    // this.redirectIfLoggedIn(); Shouldn't be in AuthGuard ?
    if (!this.accountStore.isUserLoggedIn()) {
      console.log("Login component - end user session");
      this.accountStore.endUserSession();
    }

    // Redirect if user has logged in from another tab
    this.localStorageService.onLocalStorageChanged(locationStorageData => {
      this.redirectIfLoggedIn();
    }).unsubscribeOn(this.unsubscribeSubject);

    // Listen for login message
    this.accountMessageProvider.onMessages<LoginMessage>(LoginMessage.name, {
      next: (messages) => {

        let loginMessage = messages.get(0);
        if (loginMessage.success) {
          this.appLifeCycleService.userUpdated((user) => {
            this.accountStore.startUserSession();

            // show password expiry warning
            if (loginMessage.passwordExpiryWarning) {
              this.notificationService.notify(<NotificationInfo>{
                title: this.strings.Warning,
                message: loginMessage.passwordExpiryWarning,
                type: NotificationType.Warning,
                selfClose: false
              });
            }

          });
        }
        else if (loginMessage.isPasswordExpired) {
          // password is expired -> force user to update it
          this.unblockUI();
          this.popupService.open(PopupIdentifiers.ChangePassword, this.userNameView.value);
        }
        else {
          this.showRecaptcha = this.accountStore.showRecaptcha();
          this.unblockUI();
          this.errorMessages = loginMessage.errors.toArray();
          this.cdr.detectChanges();
        }
      },
      listenNewEventsOnly: true
    }).unsubscribeOn(this.unsubscribeSubject);
  }

  ngOnDestroy() {
    this.appStoreSubscriptionManager.dispose();
    super.ngOnDestroy();
  }

  public login(): void {
    // Remove white space from username.
    if (this.userNameView.value)
      this.userNameView.value = (this.userNameView.value as string).trim();

    if (!this.showRecaptcha || (this.showRecaptcha && this.recaptchaResolved)) {
      this.accountStore.logInUser(this.userNameView.value, this.passwordView.value, this.recaptchaKey);
      this.plainBlockUI();
    }

    if (this.showRecaptcha && !this.recaptchaResolved) {
      if (!this.recaptchaResolved) {
        this.errorMessages = [];
        this.errorMessages.push(this.strings.TheFormMustBeFilledOut);
        this.errorMessages[0];
      }
      return;
    }

    if (this.recaptchaComponent) {
      this.recaptchaComponent.reset();
      this.recaptchaResolved = false;
    }
  }

  public resolved(event: any): boolean {
    this.recaptchaKey = event;
    return this.recaptchaResolved = true;
  }

  onHttpLifeCycleUpdate(http: IHttpDataInfo) {
    // Prevents unblocking UI before login is completed
  }

}