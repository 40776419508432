<form name="form" (ngSubmit)="login()">
  <h3 class="alignment pb-3">{{strings.Login}}</h3>
  <text-box [view]="userNameView"
            [autocomplete]="'username'"
            autofocus="true">
  </text-box>

  <password [view]="passwordView"></password>
  <div *ngIf="siteKey && showRecaptcha" class="pb-3 d-flex justify-content-center">
    <re-captcha [(ngModel)]="formModel.captcha"
                (resolved)="resolved($event)"
                [siteKey]="siteKey"
                #captchaControl="ngModel"
                name="captcha"></re-captcha>
  </div>
  <button id="loginButton" class="btn btn-primary w-100" type="submit" [ngClass]="{'mt-4': showRecaptcha == false}">{{strings.Login}}</button>
  <div class="mt-3 text-danger alignment mb-0" *ngIf="errorMessages != null">
    <p *ngFor="let msg of errorMessages">{{msg}}</p>
  </div>
</form>
<button *ngIf="useAzureSSO" class="btn btn-primary w-100 mt-4 sso-button-color" (click)="azureSso($event)">Single Sign On</button>

<!--MsCrm-->
<button *ngIf="isInMsCrmMode" class="btn btn-primary w-100 mt-4 sso-button-color" (click)="sso($event)">Dynamics Sales - SSO</button>
<!--MsCrm-->