<div #lightboxPopupElement class="lightbox modal {{classes}}" id="{{id}}" [class.show]="isOpened" [ngStyle]="contentStyle" tabindex="-1" role="dialog">
  <div class="container flex-column d-flex" >
    <div class="row">
      <div class="col text-right pr-1 pt-1" >
        <a href="#" class="d-block" tabindex="-1" (click)="closePopup($event)">
          <co-image key="delete" width="24px" height="24px"> </co-image>
        </a>
      </div>
    </div>

    <div class="row image-body">
      <div class="col align-middle justify-content-center">
        <div>
          <co-image #imageRef [key]="image" isVault="true" (imageLoaded)="calculateSize($event)" > </co-image>
        </div>
      </div>
    </div>
  </div>
</div>