<div #visualObjectContainer>
  <div class="visual-object-margin attribute-decoration decoration {{attributeStyle}}" [class.border-style]="!showTitle" [style.max-width.%]="'100'" >
    <gridview>
      <gridview-header *ngIf="showTitle" classes="decoration-header">
        <header-columntemplate size="auto" classes="header-image">
          <co-image width="24" height="24" type="svg" key="attributedecoration" [imageSet]="imageSet"></co-image>
        </header-columntemplate>
        <header-columntemplate classes="header-title">
          {{decoration.title}}
        </header-columntemplate>
      </gridview-header>
      <div class="py-2 attribute-decoration-rows" >
        <gridview-row *ngFor="let row of rows" [classes]="row.classes" [paddingBottom]="attributeSpacing">
          <ng-container *ngFor="let cell of row.cells">
            <columntemplate *ngIf="cell.contents" [classes]="cell.classes" [size]="cell.size" [innerHtml]="cell.contents | safeHtml"></columntemplate>
          </ng-container>              
        </gridview-row> 
      </div>
    </gridview>
  </div>
</div>
