import { forwardRef, Inject, Injectable } from '@angular/core';
import { ApiResponse, ConfDataRequest } from "../../../shared/models";
import { ConfDataActionCreator } from "../../../shared/providers/configurationData";
import { ActionStatus, AppAsyncAction, HttpAction } from "../../../shared/state";
import { BomController } from "./bomController";

@Injectable()
export class BomActions {
  static GET_BOM_ROWS = "@BOM_GET_BOM_ROWS";
  static SEARCH_BOM = "@BOM_SEARCH_BOM";
  static CREATE_BOM = "@BOM_CREATE_BOM";
  static EDIT_BOM = "@BOM_EDIT_BOM";
  static REMOVE_BOM = "@BOM_REMOVE_BOM";
  static GET_ITEM = "@BOM_GET_ITEM";
}

@Injectable()
export class BomActionCreator {

  constructor(
    @Inject(forwardRef(() => BomController)) public bomController: BomController,
    @Inject(forwardRef(() => ConfDataActionCreator)) public configurationActionCreator: ConfDataActionCreator
  ) {
  }

  getBomGridData(model: ConfDataRequest): HttpAction<ApiResponse> {
    let asyncAction = <HttpAction<ApiResponse>>{
      type: BomActions.GET_BOM_ROWS,
      executeAsync: (dispatch, state, callback) => {
        return this.bomController.getBomGridData(model)
          .subscribe(configurationDataResult => {
            dispatch(this.configurationActionCreator.configurationLoaded(configurationDataResult));

            callback(new ActionStatus(true, configurationDataResult));
          });
      }
    }
    return asyncAction;
  }

  getBomDetailGridData(model: ConfDataRequest): HttpAction<ApiResponse> {
    let asyncAction = <HttpAction<ApiResponse>>{
      type: BomActions.GET_BOM_ROWS,
      executeAsync: (dispatch, state, callback) => {
        return this.bomController.getBomDetailGridData(model)
          .subscribe(configurationDataResult => {
            dispatch(this.configurationActionCreator.configurationLoaded(configurationDataResult));

            callback(new ActionStatus(true, configurationDataResult));
          });
      }
    }
    return asyncAction;
  }

  searchBom(model: ConfDataRequest): AppAsyncAction {
    let asyncAction = <AppAsyncAction>{
      type: BomActions.SEARCH_BOM,
      executeAsync: (dispatch, state, callback) => {
        this.bomController.searchBom(model)
          .subscribe(x => {
            dispatch(this.configurationActionCreator.configurationLoaded(x));

            callback(new ActionStatus(true, x));
          });
      }
    }
    return asyncAction;
  }

  createBom(model: ConfDataRequest): AppAsyncAction {
    let asyncAction = <AppAsyncAction>{
      type: BomActions.CREATE_BOM,
      executeAsync: (dispatch, state, callback) => {
        this.bomController.createBom(model)
          .subscribe(x => {
            dispatch(this.configurationActionCreator.configurationLoaded(x));

            callback(new ActionStatus(true, x));
          });
      }
    }
    return asyncAction;
  }

  editBom(model: ConfDataRequest): AppAsyncAction {
    let asyncAction = <AppAsyncAction>{
      type: BomActions.EDIT_BOM,
      executeAsync: (dispatch, state, callback) => {
        this.bomController.editBom(model)
          .subscribe(x => {
            dispatch(this.configurationActionCreator.configurationLoaded(x));

            callback(new ActionStatus(true, x));
          });
      }
    }
    return asyncAction;
  }

  removeBom(model: ConfDataRequest): AppAsyncAction {
    let asyncAction = <AppAsyncAction>{
      type: BomActions.REMOVE_BOM,
      executeAsync: (dispatch, state, callback) => {
        this.bomController.removeBom(model)
          .subscribe(x => {
            dispatch(this.configurationActionCreator.configurationLoaded(x));

            callback(new ActionStatus(true, x));
          });
      }
    }
    return asyncAction;
  }

  getItem(model: ConfDataRequest): AppAsyncAction {
    let asyncAction = <AppAsyncAction>{
      type: BomActions.GET_ITEM,
      executeAsync: (dispatch, state, callback) => {
        this.bomController.getItem(model)
          .subscribe(x => {
            dispatch(this.configurationActionCreator.configurationLoaded(x));

            callback(new ActionStatus(true, x));
          });
      }
    }
    return asyncAction;
  }
}
