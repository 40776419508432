import { Component, Input } from '@angular/core';
import { BaseComponent } from "../..";
import { ListItemModel } from "./listItemModel";
import { ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'list-item',
  templateUrl: './listItemComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListItemComponent extends BaseComponent {

  @Input()
  public item: ListItemModel;

  @Input()
  public emptyValueText: string;

  public displayText: string;
  public hasValue: boolean = false;

  ngOnInit() {

    if (this.item.showIconOnly) {

      this.hasValue = false;
      this.displayText = '';

    } else {
      // As bool value in title returns false when comparing, that's why title is converted to string.
      this.hasValue = this.item.title != null && this.item.title.toString() != "";
      this.displayText = this.hasValue ? this.item.title : this.emptyValueText;
    }
  }

}