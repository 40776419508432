import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpService } from "../../../../shared/providers/httpService";
import { SearchDataResponse } from "../../models/responses";
import { SearchDataRequest } from "../../models/requests/searchDataRequest";
import { SearchDataNormalizer } from "./searchDataNormalizer";
import { ApiResponse } from "../../models";
import { ApiResponseBuilder } from "../apiResponseBuilder";

@Injectable()
export class SearchDataController {

  public url = 'api/search';

  constructor(
    @Inject(HttpService) public httpService: HttpService,
    public apiResponseBuilder: ApiResponseBuilder    
  ) { }
    
  public getSearchData(requestModel: SearchDataRequest): Observable<ApiResponse> {
    return this.httpService.post(this.url, requestModel).pipe(this.buildResponseObject);
  }

  public buildResponseObject = map((response, index) => this.apiResponseBuilder.build(response));
}