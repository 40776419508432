import { Component, Inject } from "@angular/core";
import { Assortment } from "../shared/models";
import { NodeEvent } from "../../shared/components/treeView";
import { SelectorTreeDataProvider } from './providers/selectorTreeDataProvider';
import { Routing } from "../../shared/route/routeDecorator";
import { Breakpoints, BreakPointAccessor, ImageSets } from "../../shared/utils";
import { PageComponent } from "../shared";
import { StoreResponse } from "../shared/state";
import { SelectorStore } from "./providers/selectorStore";
import { ProductDataStore } from "../shared/providers/productData";
import { ManagedSubject } from "../../shared/managedSubject";
import { AppStoreSubscriptionManager, RouteNames } from "../shared/providers";
import { PageStore } from "../shared/providers/page";

// TODO add resolver to load root assortments and create selector ui data/assign page id

@Routing({ path: 'selector' })
@Component({
  selector: 'selector-view',
  templateUrl: './selectorComponent.html',
  providers: [AppStoreSubscriptionManager]
})
export class SelectorComponent extends PageComponent {

  public BREAK_POINTS: typeof Breakpoints = Breakpoints;
  public selectedAssortment: Assortment = null;

  public imageSet: string = "primary";

  constructor(
    @Inject(SelectorTreeDataProvider) public treeDataProvider: SelectorTreeDataProvider,
    @Inject(BreakPointAccessor) public breakPointAccessor: BreakPointAccessor,
    @Inject(SelectorStore) public selectorStore: SelectorStore,
    @Inject(ProductDataStore) public productStore: ProductDataStore,
    @Inject(PageStore) public pageStore: PageStore,
    public appStoreSubscriptionManager: AppStoreSubscriptionManager
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.imageSet = this.getImageSet(ImageSets.SelectorTree);

    this.pageStore.updateActiveRoute(RouteNames.Selector);    
  }

  public onNodeSelected(e: NodeEvent): void {
    let assortment: Assortment = this.productStore.getEntity<Assortment>(e.node.id);

    if (!assortment)
      return;

    this.selectedAssortment = assortment;

  }

  public onNodeExpanded(e: NodeEvent): void {
    // this.logEvent(e, 'Expanded');
  }

  public returnToSelector() {
    this.selectedAssortment = null;
  }

  public pathToArray(path: string) {
    if (path === undefined || path === null)
      return [];

    return path.split('/').map<number>((str) => +str);
  }

  public logEvent(e: NodeEvent, message: string): void {
    console.log(e);
  }

  ngOnDestroy() {
    this.appStoreSubscriptionManager.dispose();
    super.ngOnDestroy();
  }

}