import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NodeEvent } from "../../../../../shared/components/treeView";
import { ImageSets } from '../../../../../shared/utils/imageSets';
import { BaseComponent } from "../../../../shared";
import { CompositeActions, CompositeActionService } from '../compositeActionService';
import { CompositeHelper } from '../compositeHelper';
import { CompositeTreeDataProvider } from './compositeTreeDataProvider';

@Component({
  selector: 'menu-tree-view',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './menuTreeViewComponent.html'
})
export class MenuTreeViewComponent extends BaseComponent {

  public imageSet = "primary";

  constructor( @Inject(CompositeTreeDataProvider) public menuDataProvider: CompositeTreeDataProvider,
    @Inject(CompositeActionService) public actionService: CompositeActionService,
    @Inject(CompositeHelper) public compositeHelper: CompositeHelper
  ) {
    super();
  }

  ngOnInit() {
    this.imageSet = this.getImageSet(ImageSets.ConfiguratorTree);

    this.menuDataProvider.subscribeSizeAndViewChange();   
  }
  
  public onMenuClick(event: NodeEvent): void {
    this.actionService.notify({ action: CompositeActions.MenuClick, focusedConfInfoId: event.node.id });
  }

  public onNodeSelected(event: NodeEvent): void {    
    this.actionService.notify({ action: CompositeActions.SelectNode, focusedConfInfoId: event.node.id });
  }
  
  onAddClick(event: NodeEvent): void {

    this.actionService.notify({ action: CompositeActions.AddClick, focusedConfInfoId: event.node.id });
        
  }

}