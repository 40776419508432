import { Inject, Component, Input, SimpleChanges } from "@angular/core";
import * as Immutable from "immutable";
import { SummaryBaseComponent } from "../summaryBaseComponent";
import { ListRowModel } from "./listRowModel";
import { ConfPageSessionService } from "../../providers";
import { ValueChangeEventArgs } from "../../../../shared/components";
import { ConfUIItem } from "../../../shared/models";

@Component({ template: '' })
export abstract class BaseDetailItemComponent extends SummaryBaseComponent {

  public rows: Immutable.List<ListRowModel> = Immutable.List<ListRowModel>();
  public visible: boolean = false; // Controls the visiblity for entire detail item.
  public contentVisible: boolean = true; // Controls the visiblity only for contents, header would be shown if the value is set 'false'.
  public extraStyles: string = "";

  public showHeader: boolean = true;

  @Input()
  public isPrintView: boolean = false;

  @Input()
  public confUIItem: ConfUIItem;

  public includedInPrint: boolean = true;

  constructor(public confPageSessionService: ConfPageSessionService    
  ) {
    super(confPageSessionService);
  }

  /**
   * Returns true if the given cell count needs to add an empty cell.
     Should add a cell if its an odd number of cells.
   * @param cellCount
   */
  public shouldAddEmptyCell(cellCount: number) {
    return cellCount > 1 && cellCount % 2 == 1;
  }

  onPrintValueChange(event: ValueChangeEventArgs) {
    this.includedInPrint = event.value == 'true';
    this.setPrintItemDetail();
  }

  setPrintItemDetail(): void {

    if (!this.isPrintView)
      return;

    this.contentVisible = !this.isPrintView || (this.isPrintView && this.includedInPrint);
    this.extraStyles = this.isPrintView && this.includedInPrint? 'print-detail-item-show' : 'print-detail-item-hide';
  }

}