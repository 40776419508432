<div #regularPopupElement [class.show]="isOpened" id="{{id}}" [class.maximize]="calcMaximize" tabindex="-1" role="dialog">

  <div class="container inner-modal {{classes}}" [ngStyle]="contentStyle" >
    <div class="row">
      <div class="col"></div>
      <div class="col-auto text-right pr-1 ">
        <a href="#" class="d-block p-2" tabindex="-1" (click)="closePopup($event)">
          <co-image key="delete" width="24px" height="24px"> </co-image>
        </a>
      </div>
    </div>

    <div class="row align-middle justify-content-center ">
      <div class="col-auto px-0 p-1" [style.max-width.%]="100">
        <ng-content select="[card-type=body]"></ng-content>
      </div>
    </div>
  </div>

</div>