import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from "../../../shared";

// Composite header.
// Note! Its much better not communicate with store here in this component. All store related code is written in <CompositeComponent>.
// Add events in this component, listen them in <CompositeComponent> and make all communication there only in one place.
@Component({
  selector: 'composite-header',
  templateUrl: './compositeHeaderComponent.html'  
})
export class CompositeHeaderComponent extends BaseComponent {
  
  @Output()
  public onIconClick = new EventEmitter();

  @Input()
  public iconKey: string;

  @Input()
  public title: string;
  
}