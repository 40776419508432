import * as Immutable from "immutable";

import { SelectorPageUIData } from "./selectorPageUIData";
import { ConfiguratorPageUIData } from "./configuratorPageUIData";
import { ActionInfo } from "../actionInfo"
import { ImmutableObject } from "../../../../shared/immutableObject";
import { WebSettings } from "../../models"

export class PageState extends ImmutableObject {

  public readonly SELECTOR_UI = "selectorUI";
  public readonly CONFIGURATOR_UI = "configuratorUI";
  public readonly NEXT_PAGE_IDENTIFIER = "nextPageIdentifier";
  public readonly ACTIVE_ROUTE_NAME = "activeRouteName";

  // Selector UI data by page identifier.
  get selectorUI(): Immutable.Map<string, SelectorPageUIData> { return this.getInternalValue<Immutable.Map<string, SelectorPageUIData>>(this.SELECTOR_UI); }
  setSelectorUI(selectorUI: Immutable.Map<string, SelectorPageUIData>): this { return this.setInternalValue(this.SELECTOR_UI, selectorUI); }

  // Configurator UI data by page identifier.
  get configuratorUI(): Immutable.Map<string, ConfiguratorPageUIData> { return this.getInternalValue<Immutable.Map<string, ConfiguratorPageUIData>>(this.CONFIGURATOR_UI); }
  setConfiguratorUI(configuratorUI: Immutable.Map<string, ConfiguratorPageUIData>): this { return this.setInternalValue(this.CONFIGURATOR_UI, configuratorUI); }

  get nextPageIdentifier(): number { return this.getInternalValue<number>(this.NEXT_PAGE_IDENTIFIER); }
  setNextPageIdentifier(nextPageIdentifier: number): this { return this.setInternalValue(this.NEXT_PAGE_IDENTIFIER, nextPageIdentifier); }

  get activeRouteName(): string { return this.getInternalValue<string>(this.ACTIVE_ROUTE_NAME); }
  setActiveRouteName(activeRouteName: string): this { return this.setInternalValue(this.ACTIVE_ROUTE_NAME, activeRouteName); }
}