import * as Immutable from "immutable";
import { ImmutableObject } from "../../../../shared/immutableObject";
import { ComparisonSessionData } from "./comparisonSessionData";

export class ComparisonDataState extends ImmutableObject {

  public readonly DATA_BY_SESSION_ID = "dataBySessionId";
  
  get dataBySessionId(): Immutable.Map<number, ComparisonSessionData> { return this.getInternalValue<Immutable.Map<number, ComparisonSessionData>>(this.DATA_BY_SESSION_ID); }
  setDataBySessionId(dataBySessionId: Immutable.Map<number, ComparisonSessionData>): this { return this.setInternalValue(this.DATA_BY_SESSION_ID, dataBySessionId); }

}