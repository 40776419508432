import { Component, Input } from "@angular/core";
import { BaseComponent } from "../..";
import { CodeDecorationValuesModel } from "./codeDecorationValueModel";
import { CodeDisplayStyles, CodeDecoration, AbstractCodeValue } from "../../models";
import { ProductDataStore } from "../../providers/productData";

@Component({
  selector: 'decoration-codes-renderer',
  templateUrl: './decorationCodesRendererComponent.html',
})
export class DecorationCodesRendererComponent extends BaseComponent {

  constructor(public productStore: ProductDataStore) {
    super();
  }

  @Input()
  codeValue: AbstractCodeValue;

  @Input()
  subHeading: string;

  decoration: CodeDecoration;

  displayStyles = CodeDisplayStyles;

  displayStyle: string;

  @Input()
  decorationId: number;

  @Input()
  isStored: boolean;

  ngOnInit() {
        
    this.decoration = this.productStore.getEntity<CodeDecoration>(this.decorationId);

    this.codeValue.isBinary == true ? this.displayStyle = this.displayStyles.DownloadLink : this.displayStyle = this.decoration.displayStyle;

  }
  
}