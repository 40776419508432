<action-box *ngIf="visible"
            class="graphics-detail detail-item {{extraStyles}}"
            iconLeft="graphics"
            [textLeft]="actionBoxTitle"
            [showHeader]="showHeader">
  <ng-container *ngIf="contentVisible" >
    <graphics-decoration *ngFor="let graphics of graphicDecorations"
                         [showInlineTitle]="showInlineTitle"
                         [isEditor]="false"
                         [configurationId]="configurationId"
                         [decoration]="graphics"
                         class="{{''+graphics.longId}}"
                         [id]="graphics.longId"></graphics-decoration>
  </ng-container>
  <div card-type="control" *ngIf="isPrintView">
    <print-checkbox (onValueChange)="onPrintValueChange($event)"></print-checkbox>
  </div>
</action-box>