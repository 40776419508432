<backdrop-container>
  <regular-popup 
                 *ngIf="visible"
                 [title]="strings.Summary"
                 [id]="id"
                 [width]="'1500px'"
                 [height]="'100%'"
                 [showHeader]="false"
                 [showFooter]="true"
                 showTitleBar="true"
                 position="right"
                 [okButtonText]="strings.Ok"
                 [cancelButtonText]="strings.Cancel"
                 (okClick)="sendPrint()"
                 (onClosed)="onPopupClosed()"
                 (onOpened)="onPrintViewOpened()">

    <div card-type="body">

      <div class="summary-print-view mx-4 mb-4" #printElement>
        <h1 class="print-modal-title justify-content-center text-uppercase pm-color-darker">{{strings.Summary}}</h1>
        <summary-detail [isPrintView]="true" class="col mt-5 p-0"></summary-detail>
      </div>

    </div>

  </regular-popup>
</backdrop-container>