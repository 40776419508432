import * as Immutable from "immutable";
import { Decoration } from "./decoration";

export class PriceListDecoration extends Decoration {

  protected readonly SHOW_IN_SUMMARY = "showInSummary";
  protected readonly SUMMARY_DISPLAY_STYLE = "summaryDisplayStyle";

  constructor(data?: Immutable.Map<string, any>) {
    super(data);
  }

  get showInSummary(): boolean { return this.getInternalValue<boolean>(this.SHOW_IN_SUMMARY); }
  setShowInSummary(showInSummary: boolean): this { return this.setInternalValue(this.SHOW_IN_SUMMARY, showInSummary); }

  get summaryDisplayStyle(): string { return this.getInternalValue<string>(this.SUMMARY_DISPLAY_STYLE); }
  setSummaryDisplayStyle(summaryDisplayStyle: string): this { return this.setInternalValue(this.SUMMARY_DISPLAY_STYLE, summaryDisplayStyle); }

}