import { Injectable, Inject } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';
import { Dispatch } from "redux";

import { AppStore, HttpAction, ActionStatus } from "../../../shared/state";
import { ConfDataResponse } from "../../../shared/models/responses";
import { RuleActions } from "./ruleActions"
import { ConfDataActionCreator } from "../../../shared/providers/configurationData";
import { RuleController } from "./ruleController";
import { ConfDataRequest } from "../../../shared/models";

@Injectable()
export class RuleActionCreator {

  constructor(    
    @Inject(AppStore) public appStore: AppStore,
    @Inject(RuleController) public ruleController: RuleController,
    @Inject(ConfDataActionCreator) public configurationActionCreator: ConfDataActionCreator    
  ) { }


  dispatchConfirmRuleExecution = (model: ConfDataRequest) => this.appStore.dispatch(this.confirmRuleExecution(model)) as HttpAction<ConfDataResponse>;
  confirmRuleExecution(model: ConfDataRequest): HttpAction<ConfDataResponse> {

    return this.createHttpAction<ConfDataResponse>(

      RuleActions.CONFIRM_RULE_EXECUTION,

      (dispatch, state, callback) => {

        this.ruleController.confirmRuleExecution(model).subscribe((confResponse) => {

          // Dispatch configuration ready/loaded action.
          dispatch(this.configurationActionCreator.dispatchConfigurationLoaded(confResponse));

          callback(new ActionStatus(true, confResponse));
        })
      }
    );
  }

  public createHttpAction<T>(actionType: any, executeAsync: any, payload?: T): HttpAction<T> {

    let httpAction: HttpAction<T> = {
      type: actionType,
      executeAsync: executeAsync,
      payload: payload
    }

    return httpAction;
  }  

}