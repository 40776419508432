import { Input, ViewChild, ElementRef, Component, Inject } from "@angular/core";
import { Conf, } from "../../shared/models"
import { ConfPageSessionService } from "../providers";
import { ConfigurationSessionManager } from "../providers/configurationSessionManager";
import { BaseComponent } from "../../shared";
import { ConfiguratorStore } from "../providers";
import { VisualObjectHelper } from "../parameters/shared";
import { GlobalServiceLocator } from "../../../shared/providers";

@Component({ template: '' })
export abstract class VisualObjectComponent extends BaseComponent {

  @Input()
  public id: string;

  @Input()
  public configurationId: number;

  @ViewChild('visualObjectContainer')
  public visualObjectContainer: ElementRef;

  ngOnInit() {
    this.listenChanges();  
  }

  public get configuration(): Conf {
    return this.confStore.getConf(this.configurationId, this.confPageSessionService.confSessionId);
  }

  public get confSessionId(): number {
    return this.confPageSessionService.confSessionId;
  }

  constructor(public confStore: ConfiguratorStore, public confPageSessionService: ConfPageSessionService, @Inject(VisualObjectHelper) public visualObjectHelper: VisualObjectHelper) {
    super();
  }

  get pageId(): number {
    return this.confPageSessionService.pageId;
  }

  public listenChanges(): void {
  }
}