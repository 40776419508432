import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from "../..";
import { ListModel } from "./listModel";
import { ChangeDetectionStrategy } from '@angular/core';
import { ImageSets } from '../../../../shared/utils/imageSets';
import { PopoverService } from '../../../../shared/components/popover/popoverService';
import { PopperConfig } from '../../../../shared/components';

@Component({
  selector: 'list',
  templateUrl: './listComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListComponent extends BaseComponent {

  @ViewChild("listDiv") listDiv: ElementRef;

  @Output()
  helpIconClicked = new EventEmitter<ElementRef>();

  @Input()
  public list: ListModel;

  public imageSet: string;
  public isOpened = false;
  public helpIconWidth = null;
  public helpIconHeight = null;

  constructor(public popoverService: PopoverService, public cdr: ChangeDetectorRef) {
    super();

    let paramUISettings = this.uiSettings.configurator.parameter;
    this.helpIconWidth = paramUISettings.helpIconSize;
    this.helpIconHeight = paramUISettings.helpIconSize;
  }

  ngOnInit() {
    this.imageSet = this.getImageSet(ImageSets.SummaryDetailIcon);
  }

  public onClick(): void {

    this.helpIconClicked.emit(this.listDiv);
  } 

}