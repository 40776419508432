import { Component, Input, ViewContainerRef, ComponentFactoryResolver, ViewChild, Inject, ElementRef } from "@angular/core";
import { ConfPageSessionService, ConfiguratorStore } from "../../providers";
import { BaseDetailItemComponent } from "../shared/baseDetailItemComponent";
import { Optional } from "@angular/core";
import { GlobalDataStore } from "../../../shared/providers/globalData";
import { ModelFactory } from "../../../shared/providers/modelFactory";
import { ConfDataController } from "../../../shared/providers/configurationData";
import { VisualObjectVisibilityService } from "../../shared";
import { ProductDataStore } from "../../../shared/providers/productData/productDataStore";
import { ImageDecoration } from "../../../shared/models/entities/imageDecoration";
import { ConfUIItem } from "../../../shared/models/entities/confUIItem";
import { GroupConfUIItem } from "../../../shared/models/entities/groupConfUIItem";
import { Conf } from "../../../shared/models";

@Component({
  selector: 'image-detail',
  templateUrl: './imageDetailComponent.html'
})
export class ImageDetailComponent extends BaseDetailItemComponent {

  public detailTitle: string;
  public showInlineTitle = false;
  public vaultImageFilePath: string;

  public imageDecorations: Array<ImageDecoration>;
  constructor(@Optional() @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    @Inject(ConfDataController) public configuratorController: ConfDataController,
    @Inject(VisualObjectVisibilityService) public visualObjectVisibilityService: VisualObjectVisibilityService,
    @Inject(ProductDataStore) public productStore: ProductDataStore
  ) {
    super(storeSession);
  }

  addImageDecoration(x: ConfUIItem) {
    const visualObject = this.productStore.getEntity(x.id);
    if (visualObject instanceof ImageDecoration) {

      let decoration = visualObject as ImageDecoration;
      let conf: Conf = this.confStore.getConf(this.configurationId, this.confSessionId);
      if (conf) {

        // Read conf image value
        let confImageVal = conf.imageValues.find((x) => x.longId == decoration.longId);

        // Set the image.
        if (confImageVal)
          decoration = decoration.setImageFileName(confImageVal.value);
      }


      this.imageDecorations.push(decoration);
    }
  }

  onDataReady(): void {
    this.imageDecorations = [];
    this.detailTitle = this.strings.Images;

    if (this.confUIItem) {
      if (this.confUIItem.items) {
        if (this.confUIItem.id) {
          this.addImageDecoration(this.confUIItem);
        }
        else
          this.confUIItem.items.forEach(uiItem => {
            this.addImageDecoration(uiItem);
          });
      }
    }

    this.visible = this.imageDecorations.length > 0;
    if (!this.visible)
      return;

    this.showInlineTitle = this.imageDecorations.length > 1;
    if (this.showInlineTitle)
      return;
    else {
      let firstDecoration = this.imageDecorations[0];
      if (firstDecoration.title && firstDecoration.title.trim().length > 0)
        this.detailTitle = firstDecoration.title;
      // For individually displayed decoration if there is no title then hide the whole header row
      else if (typeof this.confUIItem != typeof GroupConfUIItem)
        this.showHeader = false;
    }
  }

}