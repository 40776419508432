import { BaseEntity } from "../../baseEntity";

export class AbstractCodeValue extends BaseEntity {

  protected readonly ICON = "icon";
  protected readonly TITLE = "title";
  protected readonly VALUE = "value";
  protected readonly IS_BINARY = "isBinary";

  get icon(): string { return this.getInternalValue<string>(this.ICON); }
  setIcon(icon: string): this { return this.setInternalValue(this.ICON, icon); }

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get value(): string { return this.getInternalValue<string>(this.VALUE); }
  setValue(value: string): this { return this.setInternalValue(this.VALUE, value); }

  get isBinary(): boolean { return this.getInternalValue<boolean>(this.IS_BINARY); }
  setIsBinary(isBinary: boolean): this { return this.setInternalValue(this.IS_BINARY, isBinary); }
}