import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AutoCompleteComponent } from "./autoCompleteComponent";
import { DebouncedTextBoxModule } from "../../../../shared/components/debouncedTextBox";

export * from "./autoCompleteComponent";

@NgModule({
  imports: [
    CommonModule,
    DebouncedTextBoxModule,
  ],
  declarations: [
    AutoCompleteComponent,
  ],
  exports: [
    AutoCompleteComponent
  ]
})
export class AutoCompleteModule {

}