import { ImmutableObject } from "../../../../shared/immutableObject";

export class PageConfIdentifier extends ImmutableObject {

  public readonly SELECTED_CONF_ID = "selectedConfId";
  public readonly CONF_SESSION_ID = "confSessionId";  

  get selectedConfId(): number { return this.getInternalValue<number>(this.SELECTED_CONF_ID); }
  setSelectedConfId(selectedConfId: number): this { return this.setInternalValue(this.SELECTED_CONF_ID, selectedConfId); }

  get confSessionId(): number { return this.getInternalValue<number>(this.CONF_SESSION_ID); }
  setConfSessionId(confSessionId: number): this { return this.setInternalValue(this.CONF_SESSION_ID, confSessionId); }

}