<div class="control-panel h-100 pl-2">

  <button-icon-text *ngIf="startOverBtn"
                    [text]="strings.StartOver"
                    class="button-style start-over"
                    icon="configuration"
                    imageSet="{{iconsImageSet}}"
                    iconWidth="{{iconWidth}}"
                    iconHeight="{{iconHeight}}"
                    (buttonClick)="startOver.emit($event)">
  </button-icon-text>

  <button-icon-text *ngIf="showModify"
                    [text]="strings.Modify"
                    class="button-style modify"
                    icon="edit"
                    imageSet="{{iconsImageSet}}"
                    iconWidth="{{iconWidth}}"
                    iconHeight="{{iconHeight}}"
                    (buttonClick)="modify.emit($event)">
  </button-icon-text>

  <button-icon-text *ngIf="showMandatory"
                    [text]="strings.Mandatory"
                    [subText]="notSetMandatoryParamsCount"
                    [highlightIfMandatory]="highlightIfMandatory"
                    class="button-style mandatory"
                    icon="mandatory"
                    imageSet="{{iconsImageSet}}"
                    iconWidth="{{iconWidth}}"
                    iconHeight="{{iconHeight}}"
                    (buttonClick)="mandatory.emit($event)">
  </button-icon-text>

  <button-icon-text *ngIf="showSave && !showModify"
                    [text]="strings.Save"
                    class="button-style save"
                    icon="save"
                    [class.disabled-button]="disableSaveBtn"
                    imageSet="{{iconsImageSet}}"
                    iconWidth="{{iconWidth}}"
                    iconHeight="{{iconHeight}}"
                    (buttonClick)="save.emit($event)">
  </button-icon-text>

  <button-icon-text *ngIf="showSaveAndClose"
                    [text]="strings.SaveAndClose"
                    class="button-style save-close"
                    icon="saveandexit"
                    [class.disabled-button]="disableSaveBtn"
                    imageSet="{{iconsImageSet}}"
                    iconWidth="{{iconWidth}}"
                    iconHeight="{{iconHeight}}"
                    (buttonClick)="saveExit.emit($event)">
  </button-icon-text>

  <!-- Note! "close" class name is already defined in bootstrap that's why "exit" class name is used in this button to prevent the style conflicts with bootstrap. -->
  <button-icon-text *ngIf="showClose"
                    [text]="strings.Close"
                    class="button-style exit"
                    icon="close"
                    imageSet="{{iconsImageSet}}"
                    iconWidth="{{iconWidth}}"
                    iconHeight="{{iconHeight}}"
                    (buttonClick)="close.emit($event)">
  </button-icon-text>

  <!-- TODO reset is not imlemented. It should also be renamed to Restart -->
  <!--<button-icon-text [text]="strings.Reset"
                    class="button-style"
                    icon="reset"
                    imageSet="{{iconsImageSet}}"
                    iconWidth="{{iconWidth}}"
                    iconHeight="{{iconHeight}}"
                    (buttonClick)="reset.emit($event)">
  </button-icon-text>-->

</div>