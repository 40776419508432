import * as Immutable from "immutable"

import { AbstractConfigurationMessage } from "./abstractConfigurationMessage";
import { Grid } from "../grid";

export class DataSelectorGridMessage extends AbstractConfigurationMessage {

  protected readonly CONFIGURATION_ID = "configurationId";
  protected readonly DATA_SELECTOR_ID = "dataSelectorId";
  protected readonly GRID_DATA = "gridData";

  get configurationId(): number { return this.getInternalValue<number>(this.CONFIGURATION_ID); }
  setConfigurationId(configurationId: number): this { return this.setInternalValue(this.CONFIGURATION_ID, configurationId); }

  get dataSelectorId(): number { return this.getInternalValue<number>(this.DATA_SELECTOR_ID); }
  setDataSelectorId(dataSelectorId: number): this { return this.setInternalValue(this.DATA_SELECTOR_ID, dataSelectorId); }

  get gridData(): Grid { return this.getInternalValue<Grid>(this.GRID_DATA); }
  setGridData(gridData: Grid): this { return this.setInternalValue(this.GRID_DATA, gridData); }
}