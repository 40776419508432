import { Input, Inject, Component, ViewChild, ElementRef, SimpleChanges, ViewEncapsulation, OnInit, AfterViewInit, Output, EventEmitter } from "@angular/core";
import { AbstractImageService } from "../../providers";
import { isString } from "util";

@Component({
  selector: 'co-image',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './imageComponent.html'
})
export class ImageComponent implements OnInit, AfterViewInit {

  private _height: string;
  private _width: string;
  public keepSameWidthIfImageNotFound: boolean = true;
  public imageFound: boolean = true;
  
  @Input() id: string;

  @Output()
  imageLoaded = new EventEmitter()

  @Output()
  imageError = new EventEmitter()

  _key: string;

  @Input()
  set key(key: string) {
    this._key = key;
    this.defineSources();
  };

  get key(): string {
    return this._key;
  };


  @Input()
  customUrl: string;

  @Input() cssClass: string;
  @Input() style: string;

  // Sizes separated by comma ','
  // Example : 1x, 2x, 3x, 4x  
  // or 0.5x, 1x, 1.5x
  @Input()
  sizes: string;

  @Input()
  maxHeight: string;

  @Input()
  set height(height: string) {

    if (!height || height == null)
      return;

    // Append the 'px' to height If It is not provided.
    if (!height.endsWith('px') && !isNaN(Number(height))) {
      height = height + 'px';
    }

    this._height = height;
  }

  get height(): string {
    return this._height;
  }

  @Input()
  set width(width: string) {

    if (width == null)
      return;

    // Append the 'px' to width If It is not provided.
    if (!width.endsWith('px') && !isNaN(Number(width))) {
      width = width + 'px';
    }

    this._width = width;
  }

  get width(): string {
    return this._width;
  }

  // Only svg and png types are supported.
  @Input()
  type = 'svg';

  /** Image set for svg. Like "primary", "primary-dark" etc. */
  @Input()
  imageSet = 'primary';

  @Input()
  isVault = false;

  srcSet: string[];

  defaultSrc: string;

  @ViewChild('image') imageElement: ElementRef;

  constructor(
    public imageService: AbstractImageService) {
  }

  public ngOnInit(): void {
    if (this.customUrl)
      this.initCustomUrlSource();
    else
      this.defineSources();
  }

  public ngAfterViewInit(): void {

    // let expression = /[^\d\.\-]/g;
    // let _width: number = 0;        
    // let _height: number = 0;
    // this.width.replace(expression, '')

    //if (this.width != null) {
    //  this.imageElement.nativeElement.width = this.width;
    //}

    //if (this.height != null) {
    //  this.imageElement.nativeElement.height = this.height;
    //}

    //if (this.style != null) {
    //  // Note! I.E throws exception If we set the style from template
    //  // Exception: Assignment to read- only properties is not allowed in strict mode
    //  this.imageElement.nativeElement.style = this.style;
    //}

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.customUrl)
      return;

    if (changes['isVault'] || changes['imageSet']) {
      this.defineSources();
    }
  }

  public initCustomUrlSource(): void {
    // see if url is remote or not
    if (this.customUrl.indexOf('://') > 0 || this.customUrl.indexOf('//') === 0) {
      this.defaultSrc = `${this.customUrl}.${this.type}`;
    } else {
      this.srcSet = this.imageService.getImageUrl(this.customUrl, this.type, this.sizes, true, this.imageSet);
      this.defaultSrc = this.srcSet.length > 0 ? this.srcSet[0] : '';
    }
  }

  public defineSources(): void {
    if (this.key == null)
      return;

    // If the input parameter is string.
    if (isString(this.isVault))
      this.isVault = this.isVault == 'true';

    this.srcSet = this.imageService.getImageUrl(this.key, this.type, this.sizes, this.isVault, this.imageSet);

    this.defaultSrc = this.srcSet.length > 0 ? this.srcSet[0] : '';
  }

  // TODO: Handle loading events.

  getType(): string {
    return `image/${this.type}`;
  }

  triggerEvent(event): void {

    this.imageFound = true;
    this.imageLoaded.emit(event);
  }

  onError(event): void {
    this.imageFound = false;
    this.imageError.emit(event);
  }

}