import { Injectable } from "@angular/core";
import * as Immutable from "immutable";
import { Reducer } from "redux";
import { ReducersCombiner } from "../../../shared/providers/reducersCombiner";
import { AccountDataReducer } from "./accountData";
import { ProductDataReducer } from "./productData";
import { ConfDataReducer } from "./configurationData";
import { PageReducer } from "./page";
import { GlobalDataReducer } from "./globalData";
import { SearchDataReducer } from "./searchData";
import { AppState } from "./shared";
import { ActionInfo } from "./actionInfo";
import { Actions } from "./shared/actions";
import { AppAction } from "./shared/appAction";
import { ActionStatus } from "./shared/actionStatus";
import { ApiResponse, ApiMessage } from "../models/responses";
import { ComparisonDataReducer } from "../../configurator/comparison/providers/comparisonDataReducer";
import { PushMessageReducer } from "./pushMessage/pushMessageReducer";

@Injectable()
export class AppReducer {

  constructor(
    public reducersCombiner: ReducersCombiner,
    public productDataReducer: ProductDataReducer,
    public configurationDataReducer: ConfDataReducer,
    public pageReducer: PageReducer,
    public globalDataReducer: GlobalDataReducer,
    public accountReducer: AccountDataReducer,
    public searchDataReducer: SearchDataReducer,
    public comparisonDataReducer: ComparisonDataReducer,
    public pushMessageReducer: PushMessageReducer,
  ) { }

  getReducer(): Reducer<AppState> {
    return this.reducersCombiner.combine<AppState>({
      productData: this.productDataReducer.getReducer(),
      configurationData: this.configurationDataReducer.getReducer(),
      searchData: this.searchDataReducer.getReducer(),
      comparisonData: this.comparisonDataReducer.getReducer(),
      uiData: this.pageReducer.getReducer(),
      globalData: this.globalDataReducer.getReducer(),
      accountData: this.accountReducer.getReducer(),
      infoByActionId: this.getInfoByActionIdReducer(),
      messages: this.getMessagesReducer(),
      pushMessages: this.pushMessageReducer.getReducer(),
    });
  }

  public getMessagesReducer() {

    let defaultState = Immutable.Map<string, Immutable.List<ApiMessage>>();

    return (state = defaultState, action: AppAction<any>): Immutable.Map<string, Immutable.List<ApiMessage>> => {

      if (action.type == Actions.CLEAR_CLIENT_CACHE)
        return defaultState;

      if (!action.payload || !(action.payload instanceof ApiResponse))
        return state;
      
      if (action.payload.messages && action.payload.messages.size > 0)
        state = this.createMessagesMap(action.payload.messages);

      return state;
    };
  }

  public getInfoByActionIdReducer() {

    let defaultState = Immutable.Map<number, ActionInfo>();

    return (actionInfoById: Immutable.Map<number, ActionInfo> = defaultState, action: AppAction<any>): Immutable.Map<number, ActionInfo> => {

      let status: ActionStatus = action.payload as ActionStatus;

      let actionInfo;

      switch (action.type) {


        case Actions.ACTION_STARTED:

          actionInfo = actionInfoById.get(status.action.id);

          if (!actionInfo) {
            actionInfo = new ActionInfo();
            actionInfo.id = status.action.id;
            actionInfo.action = status.action; // It helps in debugging.
            actionInfoById = actionInfoById.set(status.action.id, actionInfo);
          }

          actionInfo.status = status.requestStatus;
          return actionInfoById;

        case Actions.UPDATE_ACTION_STATUS:

          if (!action.payload)
            return actionInfoById;
                    
          actionInfo = actionInfoById.get(status.action.id);

          if (!actionInfo)
            return actionInfoById;
                    
          actionInfo.completed = status.completed;
          actionInfo.status = status.requestStatus;
          actionInfo.payload = status.payload;

          return actionInfoById;

        default:
          return actionInfoById;
      }

    }
  }

  createMessagesMap(messages: Immutable.List<ApiMessage>): Immutable.Map<string, Immutable.List<ApiMessage>> {

    let messagesMap = Immutable.Map<string, Immutable.List<ApiMessage>>();

    messages.forEach((message) => {

      if (!messagesMap.has(message.className))
        messagesMap = messagesMap.set(message.className, Immutable.List<ApiMessage>());

      let messagesList = messagesMap.get(message.className);
      messagesMap = messagesMap.set(message.className, messagesList.push(message));
    });

    return messagesMap;
  }
}