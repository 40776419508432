import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CoCheckboxComponent } from "./coCheckboxComponent";
import { SelectDirective } from "./selectDirective";
import { StandardCheckboxComponent } from "./templates/standardCheckboxComponent";
import { ClassicCheckboxComponent } from "./templates/classicCheckboxComponent";
import { ImageModule } from "../image";
import { HelpModule } from "../help";
import { IconModule } from "../icon/index";

export * from "./coCheckboxComponent";
export * from "./selectDirective";

@NgModule({
  imports: [
    CommonModule,
    ImageModule,
    HelpModule,
    IconModule
  ],  
  declarations: [
    CoCheckboxComponent,
    StandardCheckboxComponent,
    ClassicCheckboxComponent,
    SelectDirective
  ],  
  exports: [
    CoCheckboxComponent,
    StandardCheckboxComponent,
    ClassicCheckboxComponent,
    SelectDirective
  ]
})
export class CheckboxModule {

}