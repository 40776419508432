import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { PrintComponent } from './printComponent';
import { PrintService } from './printService';

export * from "./printService";
export * from "./printComponent";


@NgModule({
  imports: [
    CommonModule,
    FormsModule    
  ],
  declarations: [
    PrintComponent    
  ],
  providers: [
    PrintService  
  ],
  exports: [
    PrintComponent    
  ]
})
export class PrintModule {

}