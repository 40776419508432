<div class="justify-content-start" #container>  
  <gridview [style.width]="'100%'">
    <gridview-row>
      <columntemplate class="p-0">
        <pagination [currentPage]="pagerModel.currentPage"
                    [noOfPages]="pagerModel.noOfPages"
                    [maxPages]="maxPages"
                    (pageChanged)="onPageChanged($event)"></pagination>
      </columntemplate>
      <columntemplate class="align-middle pagination-label">
        <span>{{pagingInfoLabel}}</span>
      </columntemplate>
      <columntemplate [size]="'auto'" class="pager-displaycount" [class.no-label]="extraSmallDown">
        <dropdown [itemsView]="pagerModel.pageSizeViews"
                  [view]="pagerModel.displayCountView"
                  (onValueChange)="onValueChange($event)">
        </dropdown>
      </columntemplate>
    </gridview-row>
  </gridview>
</div>