<div id="compare" (scroll)="scrollHandler($event)" [class.floating-header]="isFloating">
  <breadcrumb [items]="breadcrumbItems" (itemClick)="onBreadcrumbClick($event)"></breadcrumb>

  <div #comparisonDiv>
    <div [style.minWidth.px]="minWidth">
      <div class="head">
        <h1 class="text-uppercase text-primary">{{strings.Compare}}</h1>
      </div>
      <div class="confsdiv container-fluid hide-overflow" #confsDiv>
        <div>
          <div class="row">
            <configuration-link-control class="col ellipsis-wrap" *ngFor="let c of selectedConfs" [menuSupport]="false" [conf]="c" [compactMode]="compactMode"></configuration-link-control>
          </div>
        </div>
      </div>
      <div id="comparerow" class="row" *ngIf="treeRows.size">
        <div class="col">
          <div class="row">
            <div class="section-head w-100 mx-3 mt-3">
              <!--<co-image key="configuration" width="32px"> </co-image>-->
              <span>{{strings.ChildConfigurations}}</span>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col">
              <comparison-draggable-list 
                                         (cancelDrop)="refreshList($event)"
                                         (orderChanged)="onOrderChanged($event)"
                                         (select)="onSelect($event)"
                                         [isUpdating]="isUpdating"
                                         [rows]="treeRows"
                                         [compactMode]="compactMode"></comparison-draggable-list>
            </div>
          </div>
        </div>
      </div>
      <comparison-row id="comparerow" *ngFor="let c of comparisons" isRoot="true" [comparison]="c"></comparison-row>
    </div>
  </div>
</div>
