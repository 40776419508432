import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ImageModule } from "../../../../shared/components/image";
import { ListComponent } from "./listComponent";
import { ListItemComponent } from "./listItemComponent";
import { VisualObjectIconModule } from "../visualObjectIcon";
import { SafeHtmlModule } from "../../../../shared/utils/pipes";

export * from "./listItemModel";
export * from "./listModel";
export * from "./listComponent";
export * from "./listItemComponent";

@NgModule({
  imports: [
    CommonModule,    
    ImageModule,
    VisualObjectIconModule,
    SafeHtmlModule
    
  ],
  declarations: [
    ListComponent,
    ListItemComponent
  ],
  providers: [    

  ],
  exports: [
    ListComponent,
    ListItemComponent
  ]
})
export class ListModule {

}