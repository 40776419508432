import { UiElement } from "./uiElement";

export class UiLabel extends UiElement {

  protected readonly TITLE = "title";
  protected readonly FOR_ID = "forId";

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get forId(): string { return this.getInternalValue<string>(this.FOR_ID); }
  setForId(forId: string): this { return this.setInternalValue(this.FOR_ID, forId); }

}