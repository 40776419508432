import { ConfRouteParams } from "../../pages/configurator/providers";
import { ActivatedRouteSnapshot } from "@angular/router";

export function setRouteConfParamValue(params: ConfRouteParams, propertyName: string, value: string) {

  let handled: boolean = false;
  ConfRouteParams.ALL_PROPERTIES.forEach(key => {

    if (key.toLowerCase() == propertyName.toLowerCase()) {
      {
        params[key] = value;
        handled = true;
        return;
      }
    }
  });  

  if (!handled) {
    params[propertyName] = value;
  }

}

export function extractRouteParams(destination: ConfRouteParams, route: ActivatedRouteSnapshot) {

  const confParamsName = ConfRouteParams.CONF_PARAMS.toLowerCase();
  const extraArgsName = ConfRouteParams.EXTRA_ARGS.toLowerCase();
  let params = route.params;
  Object.keys(params).forEach(key => {

    // Ignore confParams and extraArgs as they are handled outside the loop
    if (key == confParamsName || key == extraArgsName)
      return;

    setRouteConfParamValue(destination, key, params[key]);
  });

  if (route.paramMap.has(confParamsName) && route.paramMap.get(confParamsName).length > 0)
    destination.confParams = JSON.parse(route.paramMap.get(confParamsName));

  if (route.paramMap.has(extraArgsName) && route.paramMap.get(extraArgsName).length > 0)
    destination.extraArgs = JSON.parse(route.paramMap.get(extraArgsName));
  
}

