import * as Immutable from "immutable"
import { AbstractConfigurationMessage } from "./abstractConfigurationMessage"
import { PriceReportCategory } from "./priceReportCategory"
import { PriceReportChildTotal } from "./";
import { RefObject } from "../../entities/refObject";
import { KeyValue } from "../keyValue";

export class PriceReportMessage extends AbstractConfigurationMessage {

  protected readonly CATEGORIES = "categories";
  protected readonly TOTAL = "total";
  protected readonly CHILDREN_TOTAL = "childrenTotal";
  protected readonly CHILDREN = "children";
  protected readonly GRAND_TOTAL = "grandTotal";
  protected readonly CURRENCY = "currency";
  protected readonly PRICE_LIST_CATEGORY = "priceListCategory";
  protected readonly HAS_ISSUES = "hasIssues";
  protected readonly WARNINGS = "warnings";
  protected readonly CLEARED_PRICES = "clearedPrices";

  get categories(): Immutable.List<PriceReportCategory> { return this.getInternalValue<Immutable.List<PriceReportCategory>>(this.CATEGORIES); }
  setCategories(categories: Immutable.List<PriceReportCategory>): this { return this.setInternalValue(this.CATEGORIES, categories); }

  get total(): string { return this.getInternalValue<string>(this.TOTAL); }
  setTotal(total: string): this { return this.setInternalValue(this.TOTAL, total); }

  get childrenTotal(): string { return this.getInternalValue<string>(this.CHILDREN_TOTAL); }
  setChildrenTotal(childrenTotal: string): this { return this.setInternalValue(this.CHILDREN_TOTAL, childrenTotal); }

  get children(): Array<PriceReportChildTotal> { return this.getInternalValue<Array<PriceReportChildTotal>>(this.CHILDREN); }
  setChildren(children: Array<PriceReportChildTotal>): this { return this.setInternalValue(this.CHILDREN, children); }

  get grandTotal(): string { return this.getInternalValue<string>(this.GRAND_TOTAL); }
  setGrandTotal(grandTotal: string): this { return this.setInternalValue(this.GRAND_TOTAL, grandTotal); }

  get currency(): RefObject { return this.getInternalValue<RefObject>(this.CURRENCY); }
  setCurrency(currency: RefObject): this { return this.setInternalValue(this.CURRENCY, currency); }

  get priceListCategory(): RefObject { return this.getInternalValue<RefObject>(this.PRICE_LIST_CATEGORY); }
  setPriceListCategory(priceListCategory: RefObject): this { return this.setInternalValue(this.PRICE_LIST_CATEGORY, priceListCategory); }

  get hasIssues(): boolean { return this.getInternalValue<boolean>(this.HAS_ISSUES); }
  setHasIssues(hasIssues: boolean): this { return this.setInternalValue(this.HAS_ISSUES, hasIssues); }

  get warnings(): Immutable.List<string> { return this.getInternalValue<Immutable.List<string>>(this.WARNINGS); }
  setWarnings(warnings: Immutable.List<string>): this { return this.setInternalValue(this.WARNINGS, warnings); }

  get clearedPrices(): Immutable.List<KeyValue<string, number>> { return this.getInternalValue<Immutable.List<KeyValue<string, number>>>(this.CLEARED_PRICES); }
  setClearedPrices(clearedPrices: Immutable.List<KeyValue<string, number>>): this { return this.setInternalValue(this.CLEARED_PRICES, clearedPrices); }
}