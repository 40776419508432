import * as Immutable from "immutable";

import { BaseEntity } from "../../baseEntity";
import { ConfFormControlValue } from "./confFormControlValue";

export class ConfFormValue extends BaseEntity {

  protected readonly CONTAINER_STYLE = "containerStyle";
  protected readonly TITLE = "title";
  protected readonly WIDTH = "width";
  protected readonly FORM_CONTROL_VALUES = "formControlValues";

  get containerStyle(): string { return this.getInternalValue<string>(this.CONTAINER_STYLE); }
  setContainerStyle(containerStyle: string): this { return this.setInternalValue(this.CONTAINER_STYLE, containerStyle); }

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get width(): number { return this.getInternalValue<number>(this.WIDTH); }
  setWidth(width: number): this { return this.setInternalValue(this.WIDTH, width); }

  get formControlValues(): Immutable.List<ConfFormControlValue> { return this.getInternalValue<Immutable.List<ConfFormControlValue>>(this.FORM_CONTROL_VALUES); }
  setFormControlValues(formControlValues: Immutable.List<ConfFormControlValue>): this { return this.setInternalValue(this.FORM_CONTROL_VALUES, formControlValues); }
}