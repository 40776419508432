import { Injectable, Inject } from "@angular/core";
import { ConfiguratorUIStore } from "./configuratorUIStore";
import { Breakpoints, BreakPointAccessor } from "../../../shared/utils";
import { SizeUtility } from "../../../shared/components/shared";
import { ConfiguratorPageUIData, CompositeUIData } from "../../shared/state";
import { ConfUIData } from "../../shared/state/shared/confUIData";
import { ProductDataStore } from "../../shared/providers/productData";
import { Product, Tab, ConfInfo, VisualObject, RequestViews } from "../../shared/models";
import { Subject } from "rxjs";
import { Observable } from "rxjs";
import { ConfiguratorStore } from "./configuratorStore";
import { GlobalDataStore } from "../../shared/providers/globalData";
import { TabDisplayStyle } from "../../shared/providers";
import { AccountDataStore } from "../../shared/providers/accountData";
import { ActivatedRoute } from "@angular/router";

// Normalizes the configurator UI in following ways.
// 1. Composite manager is collapsed by default unless the user has it set to auto sticky,
// 2. Accordion tabs should be sticky by default in large screens.
// 3. Sticky option for composite manager shall only be visible If there is enough room available to accomodate it.
// 4. If there is only room for one side bar (composite or accordion tabs) then Accordion sticky option wins over composite manger and kicks off the composite.

@Injectable()
export class ConfiguratorLayoutManager {

  constructor(
    @Inject(ConfiguratorUIStore) public confUIStore: ConfiguratorUIStore,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ProductDataStore) public productStore: ProductDataStore,        
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(BreakPointAccessor) public breakPointAccessor: BreakPointAccessor,
    @Inject(AccountDataStore) private accountDataStore: AccountDataStore,
    @Inject(ActivatedRoute) public activatedRoute: ActivatedRoute,
  ) {

  }


  initLayoutSettings(configurationId: number, confSessionId: number, pageId: number): void {
    
    this.updateCompositeSettings(configurationId, confSessionId, pageId);
    this.setDefaultAccordionSettings(pageId, configurationId, confSessionId);

  }

  /**
   * Sets the default settings on load.
   * @param accordionTabsCount
   */
  setDefaultAccordionSettings(pageId: number, configurationId: number, confSessionId: number): void {

    // As the behavior of accordion is changed. Now system handles the sticky/unsticky by itself. If there is some room left after rendering the composite, normal tabs and separated
    // tabs then we show the accordion otherise It must be collapsed.
    
    let activeConfInfo: ConfInfo = this.confStore.getConfInfo(configurationId, confSessionId);

    let product: Product = this.productStore.getEntity<Product>(activeConfInfo.productId);
    let tabs: Tab[] = [];

    if (!product)
      return;

    product.tabs.forEach(tabId => { tabs.push(this.productStore.getEntity<Tab>(tabId)); });
    let accordionTabsLength: number = tabs.filter(x => x.displayStyle === "Accordion").length;

    let collapseAccordionAtWindowWidth: number = SizeUtility.sizeAsNumber(this.globalDataStore.getGlobalData().uiSettings.configurator.tab.collapseAccordionAtWindowWidth);
    if (accordionTabsLength > 0 && collapseAccordionAtWindowWidth) {
      // Keep accordion opened until threshold value.
      let calcSticky: boolean = window.innerWidth > collapseAccordionAtWindowWidth;
      // Set accordion default state, It must be sticky by default. Visibility must be same as calcSticky.
      this.confUIStore.updateAccordionUIState(pageId, calcSticky, calcSticky, accordionTabsLength);
      return;
    }

    let separatedTabs: Tab[] = tabs.filter(x => x.displayStyle === "Separated" && (x.separatedTabPosition === 'Left' || x.separatedTabPosition === 'Right'));
    let totalWidths: number = 0;

    separatedTabs.forEach(x => {

      totalWidths += x.separatedWidth ? x.separatedWidth : SizeUtility.sizeAsNumber(this.globalDataStore.getGlobalData().uiSettings.configurator.tab.minSeparatedTabWidth);

    });

    let hasNormalTabs: boolean = tabs.filter(x => x.displayStyle === "Normal").length > 0;
    if (hasNormalTabs)
      totalWidths += this.globalDataStore.getGlobalData().globalSettings.minTabWidth;

    let compositeUI: CompositeUIData = this.confUIStore.getConfiguratorPageUIState(pageId).compositeUI;
    if (compositeUI && compositeUI.calcSticky != null && compositeUI.calcSticky)
      totalWidths += SizeUtility.sizeAsNumber(compositeUI.calcWidth);

    // Add accordion width
    totalWidths += SizeUtility.sizeAsNumber(this.globalDataStore.getGlobalData().uiSettings.configurator.tab.minAccordionTabWidth);
    let calcSticky: boolean = totalWidths < window.innerWidth;
    
    // Set accordion default state, It must be sticky by default. Visibility must be same as calcSticky.
    this.confUIStore.updateAccordionUIState(pageId, calcSticky, calcSticky, accordionTabsLength);
  }
  
  /**
   * Calculates the min width for <TabsManager>.
   * @param tabs
   */
  calculateMiniumWidthForTabsManager(configurationId: number, confSessionId: number): number {

    let confInfo: ConfInfo = this.confStore.getConfInfo(configurationId, confSessionId);
    let tabs: Tab[] = this.productStore.getLoadedTabs(confInfo.productId);

    // If the normal tabs exist then read the minimum width from global settings.
    let normalTabs: Tab[] = tabs.filter(x => x.displayStyle == "Normal");
    if (normalTabs.length > 0)
      return this.globalDataStore.getGlobalData().globalSettings.minTabWidth;

    // If no normal tab then get the width of first separated tab. 
    let separateTabs: Tab[] = tabs.filter(x => x.displayStyle == "Separate");
    if (separateTabs.length > 0) {

      if (separateTabs[0].separatedWidth != null && separateTabs[0].separatedWidth > 0)
        return separateTabs[0].separatedWidth;

      // Else return the default mininum width for separated tab.
      return SizeUtility.sizeAsNumber(this.globalDataStore.getGlobalData().uiSettings.configurator.tab.minSeparatedTabWidth);

    }
  }

  updateCompositeSettings(configurationId, confSessionId, pageId, reusePrevSettings: boolean = false): void {

    // Read the active view i.e editor/summary.
    let isEditor: boolean = true;
    let firstChild = this.activatedRoute.snapshot.firstChild;
    if (firstChild && firstChild.params)       
      isEditor = this.activatedRoute.snapshot.firstChild.params.view == RequestViews.Editor;

    //See the support for composite structure.
    let activeConfInfo: ConfInfo = this.confStore.getConfInfo(configurationId, confSessionId);
    let rootConfInfo: ConfInfo = this.confStore.getConfInfo(activeConfInfo.rootId, confSessionId);
    let hasCompositeProductStructure: boolean = this.productStore.hasCompositeStructure(rootConfInfo.productId);
    let showComposite: boolean = isEditor ? hasCompositeProductStructure : hasCompositeProductStructure || rootConfInfo.children.size > 0;
    if (!showComposite) {

      // Don't show composite.
      this.confUIStore.updateCompositeUIState(pageId, "0px", false, false);
      return;

    }

    let calcSticky: boolean = this.accountDataStore.getUser().settings.compositeControlSticky;
    let visible: boolean = calcSticky;
    let sidebarExtended: boolean = false;

    if (reusePrevSettings) {

      // Get previous state If exists otherwise calculate new calcSticky
      let prevConfPageData: ConfiguratorPageUIData = this.confUIStore.getConfiguratorPageUIState(pageId);
      let composite: CompositeUIData = prevConfPageData.compositeUI;
      if (composite && composite.calcSticky != null) {
        calcSticky = composite.calcSticky;
        visible = composite.visible;
        sidebarExtended = composite.sidebarExtended;
      }
    }

    let compositeCalcWidth: string = `${this.globalDataStore.getGlobalData().globalSettings.compositeSidebarDefaultWidth}px`;

    // Calculate the range where composite would remain sticky.
    if (this.accountDataStore.getUser().settings.compositeControlSticky) {

      let minBreakpoint: number = this.globalDataStore.getGlobalData().globalSettings.compositeSidebarCollapseAtWindowWidth;
      let maxBreakpoint: number = this.globalDataStore.getGlobalData().globalSettings.compositeSidebarCompressedAtWindowWidth;

      if (window.innerWidth > minBreakpoint && window.innerWidth < maxBreakpoint)
        compositeCalcWidth = `${this.globalDataStore.getGlobalData().globalSettings.compositeSidebarCompressedWidth}px`;

      else if (window.innerWidth < minBreakpoint) {

        compositeCalcWidth = "0px";

        // Don't toggle visibility if the sidebar is extended in mobile mode after clicking on a menu item of another configuration
        visible = sidebarExtended;
        calcSticky = false;

      }

    }
    else if (window.innerWidth < Breakpoints.sm.min) 
        compositeCalcWidth = "100%";
    
    this.confUIStore.updateCompositeUIState(pageId, compositeCalcWidth, calcSticky, visible);
    
  }

}