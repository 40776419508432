import * as Immutable from "immutable";
import { ImmutableObject } from "../../../shared/immutableObject";
import { ConfigurationSearch, ConfInfo } from "../../shared/models";
import { SearchSessionData } from "../../shared/state";

export class QuickSearchItemUIModel {
  
  search: ConfigurationSearch;

  showInline: boolean = false;

  resultSessionData: SearchSessionData;

  resultRequestCompleted: boolean = false;

  resultConfInfos: Immutable.OrderedMap<number, ConfInfo>;
  
}