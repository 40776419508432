import { Injectable, Inject } from "@angular/core";
import * as Immutable from "immutable";

import { GlobalDataStore } from "./globalDataStore";

@Injectable()
export class Strings {

constructor(@Inject(GlobalDataStore) protected globalDataStore: GlobalDataStore) { }
  
  protected get stringMap(): Immutable.Map<string, string> {
    let localizeData = this.globalDataStore.getGlobalData().localizeData;
    if (localizeData)
      return this.globalDataStore.getGlobalData().localizeData.strings;

    return Immutable.Map<string, string>();
  }

  getString(key: string): string {
    let lowerKey = key.toLowerCase();

    if (this.stringMap.has(lowerKey)) {

      let value = this.stringMap.get(lowerKey);
      if(value)
        return value;
    }

    console.log('Error: Did not find key: ' + key + ' in Strings.');

    return key;
  }
  get Add(): string { return this.getString('add');}
  get AccountCreatedSuccessfully(): string { return this.getString('accountcreatedsuccessfully');}
  get Amount(): string { return this.getString('amount');}
  get AnonymousAccess(): string { return this.getString('anonymousaccess');}
  get Attributes(): string { return this.getString('attributes');}
  get AlreadyExistsWithUsername(): string { return this.getString('alreadyexistswithusername');}
  get AlreadyExistsWithEmail(): string { return this.getString('alreadyexistswithemail');}
  get BasePrice(): string { return this.getString('baseprice');}
  get BelowValues(): string { return this.getString('belowvalues');}
  get Bool_Indeterminate(): string { return this.getString('bool_indeterminate');}
  get Cancel(): string { return this.getString('cancel');}
  get Code(): string { return this.getString('code');}
  get ChildConfigurations(): string { return this.getString('childconfigurations');}
  get Clone(): string { return this.getString('clone');}
  get Close(): string { return this.getString('close');}
  get Company(): string { return this.getString('company');}
  get Confirm(): string { return this.getString('confirm');}
  get ConfirmEmail(): string { return this.getString('confirmemail');}
  get ConfirmNewPassword(): string { return this.getString('confirmnewpassword');}
  get Context(): string { return this.getString('context');}
  get Copy(): string { return this.getString('copy');}
  get Cost(): string { return this.getString('cost');}
  get CreatedBy(): string { return this.getString('createdby');}
  get CreationDate(): string { return this.getString('creationdate');}
  get Cut(): string { return this.getString('cut');}
  get Current(): string { return this.getString('current');}
  get DataSelector(): string { return this.getString('dataselector');}
  get Delete(): string { return this.getString('delete');}
  get Demote(): string { return this.getString('demote');}
  get Description(): string { return this.getString('description');}
  get Documents(): string { return this.getString('documents');}
  get Download(): string { return this.getString('download');}
  get DownloadLink(): string { return this.getString('downloadlink');}
  get Edit(): string { return this.getString('edit');}
  get EndDate(): string { return this.getString('enddate');}
  get Email(): string { return this.getString('email');}
  get Export(): string { return this.getString('export');}
  get ExportingData(): string { return this.getString('exportingdata');}
  get ExternLink(): string { return this.getString('externlink');}
  get ErrorDuplicateKeyInTranslationFile(): string { return this.getString('errorduplicatekeyintranslationfile');}
  get ErrorRowCountShouldBePositiveAndRange(): string { return this.getString('errorrowcountshouldbepositiveandrange');}
  get Functions(): string { return this.getString('functions');}
  get Graphics(): string { return this.getString('graphics');}
  get GoogleReCaptcha(): string { return this.getString('googlerecaptcha');}
  get GoogleReCaptchaDescription(): string { return this.getString('googlerecaptchadescription');}
  get GoogleReCaptchaSecret(): string { return this.getString('googlerecaptchasecret');}
  get GoogleReCaptchaSecretDescription(): string { return this.getString('googlerecaptchasecretdescription');}
  get HelpImage(): string { return this.getString('helpimage');}
  get HelpText(): string { return this.getString('helptext');}
  get Identity(): string { return this.getString('identity');}
  get ItemType(): string { return this.getString('itemtype');}
  get InlineLeft(): string { return this.getString('inlineleft');}
  get InlineRight(): string { return this.getString('inlineright');}
  get InlineHelpImageNotSupportedIfNoDisplayAsRadio(): string { return this.getString('inlinehelpimagenotsupportedifnodisplayasradio');}
  get Language(): string { return this.getString('language');}
  get Languages(): string { return this.getString('languages');}
  get LastLogin(): string { return this.getString('lastlogin');}
  get Links(): string { return this.getString('links');}
  get Loading(): string { return this.getString('loading');}
  get Login(): string { return this.getString('login');}
  get ManualValue(): string { return this.getString('manualvalue');}
  get Message(): string { return this.getString('message');}
  get Mobile(): string { return this.getString('mobile');}
  get Modify(): string { return this.getString('modify');}
  get ModificationDate(): string { return this.getString('modificationdate');}
  get ModifiedBy(): string { return this.getString('modifiedby');}
  get Name(): string { return this.getString('name');}
  get Namespace(): string { return this.getString('namespace');}
  get New(): string { return this.getString('new');}
  get NewPassword(): string { return this.getString('newpassword');}
  get No(): string { return this.getString('no');}
  get Note(): string { return this.getString('note');}
  get Ok(): string { return this.getString('ok');}
  get OnlyAdministratorsAreAllowedToTheAdministrationSite(): string { return this.getString('onlyadministratorsareallowedtotheadministrationsite');}
  get Orientation(): string { return this.getString('orientation');}
  get Other(): string { return this.getString('other');}
  get OwnedBy(): string { return this.getString('ownedby');}
  get Parameter(): string { return this.getString('parameter');}
  get Parameters(): string { return this.getString('parameters');}
  get Parent(): string { return this.getString('parent');}
  get PartOf(): string { return this.getString('partof');}
  get Password(): string { return this.getString('password');}
  get Phone(): string { return this.getString('phone');}
  get Price(): string { return this.getString('price');}
  get Print(): string { return this.getString('print');}
  get Product(): string { return this.getString('product');}
  get Products(): string { return this.getString('products');}
  get Promote(): string { return this.getString('promote');}
  get Quantity(): string { return this.getString('quantity');}
  get Reports(): string { return this.getString('reports');}
  get Revision(): string { return this.getString('revision');}
  get RenameTo(): string { return this.getString('renameto');}
  get Save(): string { return this.getString('save');}
  get Search(): string { return this.getString('search');}
  get Settings(): string { return this.getString('settings');}
  get StartDate(): string { return this.getString('startdate');}
  get StartOver(): string { return this.getString('startover');}
  get State(): string { return this.getString('state');}
  get Stored(): string { return this.getString('stored');}
  get Structure(): string { return this.getString('structure');}
  get Success(): string { return this.getString('success');}
  get SystemInformation(): string { return this.getString('systeminformation');}
  get SystemCouldNotLogYouOnMessage(): string { return this.getString('systemcouldnotlogyouonmessage');}
  get Title(): string { return this.getString('title');}
  get TriggerContinously(): string { return this.getString('triggercontinously');}
  get Type(): string { return this.getString('type');}
  get Username(): string { return this.getString('username');}
  get VaultOperationCancelledCircularRequests(): string { return this.getString('vaultoperationcancelledcircularrequests');}
  get View(): string { return this.getString('view');}
  get Visualization(): string { return this.getString('visualization');}
  get VisualizationDecoration(): string { return this.getString('visualizationdecoration');}
  get XLoggedInOnY(): string { return this.getString('xloggedinony');}
  get Yes(): string { return this.getString('yes');}
  get AccessDenied(): string { return this.getString('accessdenied');}
  get AccountCreationDisabled(): string { return this.getString('accountcreationdisabled');}
  get AccountCreationSuccess(): string { return this.getString('accountcreationsuccess');}
  get AccountCreatedAwaitsActivation(): string { return this.getString('accountcreatedawaitsactivation');}
  get AccountCreatedAwaitsActivationDescription(): string { return this.getString('accountcreatedawaitsactivationdescription');}
  get AccordionChildren(): string { return this.getString('accordionchildren');}
  get AccordionTabsWidth(): string { return this.getString('accordiontabswidth');}
  get AccordionTabsControlWidth(): string { return this.getString('accordiontabscontrolwidth');}
  get AccordionTabsControlWidthDescription(): string { return this.getString('accordiontabscontrolwidthdescription');}
  get Actions(): string { return this.getString('actions');}
  get AddFiles(): string { return this.getString('addfiles');}
  get AddLookupValue(): string { return this.getString('addlookupvalue');}
  get AddMultichoiceValue(): string { return this.getString('addmultichoicevalue');}
  get AddParameter(): string { return this.getString('addparameter');}
  get AddParameterSearchCriteria(): string { return this.getString('addparametersearchcriteria');}
  get Administration(): string { return this.getString('administration');}
  get Alert(): string { return this.getString('alert');}
  get AllListedGroups(): string { return this.getString('alllistedgroups');}
  get AllListedUsers(): string { return this.getString('alllistedusers');}
  get AnErrorWasEncountered(): string { return this.getString('anerrorwasencountered');}
  get AnonymousAccessNotSetup(): string { return this.getString('anonymousaccessnotsetup');}
  get AnOperationThatRequiresALoggedInUserCouldntBePerformedSinceUserXwasNotFound(): string { return this.getString('anoperationthatrequiresaloggedinusercouldntbeperformedsinceuserxwasnotfound');}
  get ApplyChanges(): string { return this.getString('applychanges');}
  get AreYouSureYouWantToDelete(): string { return this.getString('areyousureyouwanttodelete');}
  get AreYouSureYouWantToDeleteConfiguration(): string { return this.getString('areyousureyouwanttodeleteconfiguration');}
  get AreYouSureYouWantToDeleteTheSelectedRow(): string { return this.getString('areyousureyouwanttodeletetheselectedrow');}
  get AreYouSureYouWantToDeleteThisConfiguration(): string { return this.getString('areyousureyouwanttodeletethisconfiguration');}
  get AreYouSureYouWantToResetConfiguration(): string { return this.getString('areyousureyouwanttoresetconfiguration');}
  get AttachmentWasNotFound(): string { return this.getString('attachmentwasnotfound');}
  get Attachments(): string { return this.getString('attachments');}
  get AnonymousUrls(): string { return this.getString('anonymousurls');}
  get AuthorizationGroup(): string { return this.getString('authorizationgroup');}
  get AuthenticationTimeout(): string { return this.getString('authenticationtimeout');}
  get AutomaticExpandAndCollapse(): string { return this.getString('automaticexpandandcollapse');}
  get AutoSelectChild(): string { return this.getString('autoselectchild');}
  get AutoSelectChildDescription(): string { return this.getString('autoselectchilddescription');}
  get BadRequest(): string { return this.getString('badrequest');}
  get BadRequestInvalidPath(): string { return this.getString('badrequestinvalidpath');}
  get BillOfMaterials(): string { return this.getString('billofmaterials');}
  get Bool_False(): string { return this.getString('bool_false');}
  get Bool_True(): string { return this.getString('bool_true');}
  get By(): string { return this.getString('by');}
  get CalculatedData(): string { return this.getString('calculateddata');}
  get CannotImportAConfigurationIfIdentityIsLeftBlank(): string { return this.getString('cannotimportaconfigurationifidentityisleftblank');}
  get CannotImportConfigurationBelongingToSameRoot(): string { return this.getString('cannotimportconfigurationbelongingtosameroot');}
  get CannotCloseTheFileDialog(): string { return this.getString('cannotclosethefiledialog');}
  get CannotDemote(): string { return this.getString('cannotdemote');}
  get CannotPromote(): string { return this.getString('cannotpromote');}
  get ComposeAnonymousUrls(): string { return this.getString('composeanonymousurls');}
  get Change(): string { return this.getString('change');}
  get ChangeBrowserMode(): string { return this.getString('changebrowsermode');}
  get ChangeDocumentMode(): string { return this.getString('changedocumentmode');}
  get ChangingPriceAthorization(): string { return this.getString('changingpriceathorization');}
  get ChangeOwnership(): string { return this.getString('changeownership');}
  get ChangeOwnershipOnEveryRevision(): string { return this.getString('changeownershiponeveryrevision');}
  get ChangeOwnershipTo(): string { return this.getString('changeownershipto');}
  get ChangePassword_SaveSuccess(): string { return this.getString('changepassword_savesuccess');}
  get ChangePasswordErrorMessage_ConfirmNewPassword(): string { return this.getString('changepassworderrormessage_confirmnewpassword');}
  get ChangePasswordErrorMessage_NewPasswordsNoMatch(): string { return this.getString('changepassworderrormessage_newpasswordsnomatch');}
  get ChangePasswordErrorMessage_SpecifyNewPassword(): string { return this.getString('changepassworderrormessage_specifynewpassword');}
  get ChangePasswordErrorMessage_SpecifyOldPassword(): string { return this.getString('changepassworderrormessage_specifyoldpassword');}
  get ChangePasswordErrorMessage_WrongOldPassword(): string { return this.getString('changepassworderrormessage_wrongoldpassword');}
  get ChangePasswordNow(): string { return this.getString('changepasswordnow');}
  get ChooseFilterAndClickSearch(): string { return this.getString('choosefilterandclicksearch');}
  get ChooseOperation(): string { return this.getString('chooseoperation');}
  get ClearParameterValues(): string { return this.getString('clearparametervalues');}
  get Clear_Selection(): string { return this.getString('clear_selection');}
  get ClearValue(): string { return this.getString('clearvalue');}
  get CodeFileWebServiceNameXNotFound(): string { return this.getString('codefilewebservicenamexnotfound');}
  get ClientAppIncompatible(): string { return this.getString('clientappincompatible');}
  get CompanyNameIsRequired(): string { return this.getString('companynameisrequired');}
  get Compare(): string { return this.getString('compare');}
  get ComparisonSessionDoesNotBelongToTheUser(): string { return this.getString('comparisonsessiondoesnotbelongtotheuser');}
  get CompleteTheFormInformation(): string { return this.getString('completetheforminformation');}
  get Compare_Result(): string { return this.getString('compare_result');}
  get CompositeSidebar(): string { return this.getString('compositesidebar');}
  get CompositeSidebarDefaultWidthDescription(): string { return this.getString('compositesidebardefaultwidthdescription');}
  get CompositeSidebarCompressedWidthDescription(): string { return this.getString('compositesidebarcompressedwidthdescription');}
  get CompositeSidebarCompressedAtWindowWidthDescription(): string { return this.getString('compositesidebarcompressedatwindowwidthdescription');}
  get CompositeSidebarCollapseAtWindowWidthDescription(): string { return this.getString('compositesidebarcollapseatwindowwidthdescription');}
  get CompressedWidth(): string { return this.getString('compressedwidth');}
  get CompressedAtWindowWidth(): string { return this.getString('compressedatwindowwidth');}
  get CompositeStructure(): string { return this.getString('compositestructure');}
  get CompositeTreeExpandBehaviour(): string { return this.getString('compositetreeexpandbehaviour');}
  get CompositeControlSticky(): string { return this.getString('compositecontrolsticky');}
  get CompositeControlStickyDescription(): string { return this.getString('compositecontrolstickydescription');}
  get CompositionCopyControl_CopyMessage(): string { return this.getString('compositioncopycontrol_copymessage');}
  get CompositionDeleteControl_DeleteMessage(): string { return this.getString('compositiondeletecontrol_deletemessage');}
  get CombinumWebAppUrl(): string { return this.getString('combinumwebappurl');}
  get ConfigurationError(): string { return this.getString('configurationerror');}
  get ConfigurationExpired(): string { return this.getString('configurationexpired');}
  get ConfigurationHistory(): string { return this.getString('configurationhistory');}
  get ConfigurationWithIdentityXAndRevisionYNotFound(): string { return this.getString('configurationwithidentityxandrevisionynotfound');}
  get ConfigurationsFound(): string { return this.getString('configurationsfound');}
  get ConfigurationIsSaved(): string { return this.getString('configurationissaved');}
  get ConfigurationIsDeleted(): string { return this.getString('configurationisdeleted');}
  get ConfigurationLinkWasSuccessfullySentToX(): string { return this.getString('configurationlinkwassuccessfullysenttox');}
  get ConfigurationRemovedFromSession(): string { return this.getString('configurationremovedfromsession');}
  get ConfigurationSearch(): string { return this.getString('configurationsearch');}
  get ConfigurationSearchCriteria(): string { return this.getString('configurationsearchcriteria');}
  get ConfigurationSearchWasNotFound(): string { return this.getString('configurationsearchwasnotfound');}
  get ConfigurationSummaryLink(): string { return this.getString('configurationsummarylink');}
  get ConfiguratorAlreadyOpened(): string { return this.getString('configuratoralreadyopened');}
  get ConfiguratorAlreadyOpenedGeneral(): string { return this.getString('configuratoralreadyopenedgeneral');}
  get ConfiguratorSessionDoesNotBelongToTheUser(): string { return this.getString('configuratorsessiondoesnotbelongtotheuser');}
  get Configure(): string { return this.getString('configure');}
  get ConfigurationTemplate(): string { return this.getString('configurationtemplate');}
  get ConfigurationDoesNotExist(): string { return this.getString('configurationdoesnotexist');}
  get ConfirmDataSelectAction(): string { return this.getString('confirmdataselectaction');}
  get ConfirmPromotion(): string { return this.getString('confirmpromotion');}
  get ConfirmDemotion(): string { return this.getString('confirmdemotion');}
  get ConnectedTo(): string { return this.getString('connectedto');}
  get Content(): string { return this.getString('content');}
  get CookieInformation(): string { return this.getString('cookieinformation');}
  get CookieInformationMessage(): string { return this.getString('cookieinformationmessage');}
  get CookieInformationMessageDetails(): string { return this.getString('cookieinformationmessagedetails');}
  get Copies(): string { return this.getString('copies');}
  get CopySuccessful(): string { return this.getString('copysuccessful');}
  get CouldNotFindItemWithItemNumberXandRevisionY(): string { return this.getString('couldnotfinditemwithitemnumberxandrevisiony');}
  get CouldNotDemote(): string { return this.getString('couldnotdemote');}
  get CouldNotPromote(): string { return this.getString('couldnotpromote');}
  get Count(): string { return this.getString('count');}
  get Create(): string { return this.getString('create');}
  get CreateAccount(): string { return this.getString('createaccount');}
  get CreateYourCombinumAccount(): string { return this.getString('createyourcombinumaccount');}
  get CreateBOM(): string { return this.getString('createbom');}
  get EditBOM(): string { return this.getString('editbom');}
  get EditSearch(): string { return this.getString('editsearch');}
  get CreateConfiguration(): string { return this.getString('createconfiguration');}
  get CreateNewItem(): string { return this.getString('createnewitem');}
  get CreateMultiplePriceItems(): string { return this.getString('createmultiplepriceitems');}
  get CreateUserAccount(): string { return this.getString('createuseraccount');}
  get CollapseAtWindowWidth(): string { return this.getString('collapseatwindowwidth');}
  get CurrentProgress(): string { return this.getString('currentprogress');}
  get CustomRange(): string { return this.getString('customrange');}
  get Database(): string { return this.getString('database');}
  get DefaultCurrency(): string { return this.getString('defaultcurrency');}
  get DefaultWelcomeTitle(): string { return this.getString('defaultwelcometitle');}
  get DefaultWelcomeMessage(): string { return this.getString('defaultwelcomemessage');}
  get DefaultWidth(): string { return this.getString('defaultwidth');}
  get DeleteToolTip(): string { return this.getString('deletetooltip');}
  get DemoteFrom(): string { return this.getString('demotefrom');}
  get DemoteMessage(): string { return this.getString('demotemessage');}
  get DeselectAll(): string { return this.getString('deselectall');}
  get Details(): string { return this.getString('details');}
  get Detail(): string { return this.getString('detail');}
  get DialogWidth(): string { return this.getString('dialogwidth');}
  get DidNotFindAMatchingConfiguration(): string { return this.getString('didnotfindamatchingconfiguration');}
  get Disabled(): string { return this.getString('disabled');}
  get DisallowedPopup_CannotSet(): string { return this.getString('disallowedpopup_cannotset');}
  get DisallowedPopup_DisallowedCombinationRuleGeneralMessage(): string { return this.getString('disallowedpopup_disallowedcombinationrulegeneralmessage');}
  get DisallowedPopup_NoRulesMessage(): string { return this.getString('disallowedpopup_norulesmessage');}
  get DisallowedPopup_RestrictedByChildConfigurationModel(): string { return this.getString('disallowedpopup_restrictedbychildconfigurationmodel');}
  get DisallowedPopup_RestrictedByParentConfigurationModel(): string { return this.getString('disallowedpopup_restrictedbyparentconfigurationmodel');}
  get DisallowedPopup_TemplateRuleGeneralMessage(): string { return this.getString('disallowedpopup_templaterulegeneralmessage');}
  get DisallowedValue(): string { return this.getString('disallowedvalue');}
  get DontHaveAnAccount(): string { return this.getString('donthaveanaccount');}
  get ea(): string { return this.getString('ea');}
  get EditItem(): string { return this.getString('edititem');}
  get EditThisSearch(): string { return this.getString('editthissearch');}
  get EditToolTip(): string { return this.getString('edittooltip');}
  get EmailOrUsername(): string { return this.getString('emailorusername');}
  get EmailThisConfiguration(): string { return this.getString('emailthisconfiguration');}
  get EmailVerificationLink(): string { return this.getString('emailverificationlink');}
  get EmailVerificationMessageX(): string { return this.getString('emailverificationmessagex');}
  get Empty(): string { return this.getString('empty');}
  get EnterYourEmailAndPressSave(): string { return this.getString('enteryouremailandpresssave');}
  get EnterYourEmailAndPressSend(): string { return this.getString('enteryouremailandpresssend');}
  get EqualsString(): string { return this.getString('equalsstring');}
  get ErrorDeleting(): string { return this.getString('errordeleting');}
  get ErrorGeneratingCodeFile(): string { return this.getString('errorgeneratingcodefile');}
  get ErrorInconsistentSingletonPriceSum(): string { return this.getString('errorinconsistentsingletonpricesum');}
  get ErrorInvalidView(): string { return this.getString('errorinvalidview');}
  get ErrorPage_ErrorMessage(): string { return this.getString('errorpage_errormessage');}
  get ErrorMaxNoOfCopiesValueInvalid(): string { return this.getString('errormaxnoofcopiesvalueinvalid');}
  get ErrorMaxNoOfCopiesOutOfRange(): string { return this.getString('errormaxnoofcopiesoutofrange');}
  get ErrorMisssingRightsForAttachment(): string { return this.getString('errormisssingrightsforattachment');}
  get ErrorNoConfigurationSelectedComparison(): string { return this.getString('errornoconfigurationselectedcomparison');}
  get ErrorNoOfUploadedFiles(): string { return this.getString('errornoofuploadedfiles');}
  get ErrorSendingEmail(): string { return this.getString('errorsendingemail');}
  get ErrorFileSizeIsGreaterThanAllowed(): string { return this.getString('errorfilesizeisgreaterthanallowed');}
  get ErrorValueCannotBeGreaterThanMaxLengthX(): string { return this.getString('errorvaluecannotbegreaterthanmaxlengthx');}
  get ErrorValueWasTrimmedExceededMaxLengthX(): string { return this.getString('errorvaluewastrimmedexceededmaxlengthx');}
  get Expired(): string { return this.getString('expired');}
  get ExportToExcel(): string { return this.getString('exporttoexcel');}
  get ExportToPDF(): string { return this.getString('exporttopdf');}
  get ExportToWord(): string { return this.getString('exporttoword');}
  get FailedToClearReadOnlyParamDueToRuleX(): string { return this.getString('failedtoclearreadonlyparamduetorulex');}
  get FailedToCreateX(): string { return this.getString('failedtocreatex');}
  get FailedToLoadConfigurationX(): string { return this.getString('failedtoloadconfigurationx');}
  get FileNotFound(): string { return this.getString('filenotfound');}
  get FileUploadDialog(): string { return this.getString('fileuploaddialog');}
  get Filter(): string { return this.getString('filter');}
  get FixBrowserCompatibility(): string { return this.getString('fixbrowsercompatibility');}
  get FoundConfiguredProductIsNotLinkableToThisParentConfiguration(): string { return this.getString('foundconfiguredproductisnotlinkabletothisparentconfiguration');}
  get FoundMoreThanOneConfiguration(): string { return this.getString('foundmorethanoneconfiguration');}
  get FollowingRulesDisallowedX(): string { return this.getString('followingrulesdisallowedx');}
  get FollowingRulesReadOnlyX(): string { return this.getString('followingrulesreadonlyx');}
  get ForgotPassword(): string { return this.getString('forgotpassword');}
  get FromTemplate(): string { return this.getString('fromtemplate');}
  get GeneratedPassword(): string { return this.getString('generatedpassword');}
  get GetStarted(): string { return this.getString('getstarted');}
  get GeneratedUrl(): string { return this.getString('generatedurl');}
  get Generate(): string { return this.getString('generate');}
  get GlobalQuery(): string { return this.getString('globalquery');}
  get GracePeriodTill(): string { return this.getString('graceperiodtill');}
  get GrandTotal(): string { return this.getString('grandtotal');}
  get GreaterThan(): string { return this.getString('greaterthan');}
  get ReadHelpGuide(): string { return this.getString('readhelpguide');}
  get HelpFile(): string { return this.getString('helpfile');}
  get HelpFileWeb(): string { return this.getString('helpfileweb');}
  get HiddenParameterSymbol(): string { return this.getString('hiddenparametersymbol');}
  get HiddenParameterExplanation(): string { return this.getString('hiddenparameterexplanation');}
  get HideCurrentValue(): string { return this.getString('hidecurrentvalue');}
  get HighlightDifferences(): string { return this.getString('highlightdifferences');}
  get HighlightMandatoryParameters(): string { return this.getString('highlightmandatoryparameters');}
  get History(): string { return this.getString('history');}
  get Home(): string { return this.getString('home');}
  get HTMLid(): string { return this.getString('htmlid');}
  get IECompatIssue(): string { return this.getString('iecompatissue');}
  get IENotSupported(): string { return this.getString('ienotsupported');}
  get IEWarning(): string { return this.getString('iewarning');}
  get IgnoreContextWhenSummarizingBOM(): string { return this.getString('ignorecontextwhensummarizingbom');}
  get IgnoreContextWhenSummarizingBOMValueClearedForConflicts(): string { return this.getString('ignorecontextwhensummarizingbomvalueclearedforconflicts');}
  get ImportAnExistingConfigurationByEnteringIdentityAndRevision(): string { return this.getString('importanexistingconfigurationbyenteringidentityandrevision');}
  get ImportChild(): string { return this.getString('importchild');}
  get Info(): string { return this.getString('info');}
  get Information(): string { return this.getString('information');}
  get Inherited(): string { return this.getString('inherited');}
  get InvalidData(): string { return this.getString('invaliddata');}
  get InvalidComparisonRequestPathIsNotFound(): string { return this.getString('invalidcomparisonrequestpathisnotfound');}
  get InvalidComparisonRequestStartingConfigurationIdsAreRequired(): string { return this.getString('invalidcomparisonrequeststartingconfigurationidsarerequired');}
  get InvalidComparisonRequestStartingConfigurationIdsCannotBeChanged(): string { return this.getString('invalidcomparisonrequeststartingconfigurationidscannotbechanged');}
  get InvalidComparisonRequestTryingToReorderChildConfigurationsInTheContextOfWrongParent(): string { return this.getString('invalidcomparisonrequesttryingtoreorderchildconfigurationsinthecontextofwrongparent');}
  get InvalidSessionId(): string { return this.getString('invalidsessionid');}
  get InvalidEmailAddress(): string { return this.getString('invalidemailaddress');}
  get InvalidValueForConfigurationSearchScope(): string { return this.getString('invalidvalueforconfigurationsearchscope');}
  get InvalidUserNameOrEmail(): string { return this.getString('invalidusernameoremail');}
  get IssuesMustBeResolved(): string { return this.getString('issuesmustberesolved');}
  get IssuesToResolve(): string { return this.getString('issuestoresolve');}
  get CouldNotSendPasswordNoValidEmail(): string { return this.getString('couldnotsendpasswordnovalidemail');}
  get LanguageCodeMissing(): string { return this.getString('languagecodemissing');}
  get Last7Days(): string { return this.getString('last7days');}
  get Last30Days(): string { return this.getString('last30days');}
  get LastMonth(): string { return this.getString('lastmonth');}
  get LessThan(): string { return this.getString('lessthan');}
  get LimitedBrowserSupportInfo(): string { return this.getString('limitedbrowsersupportinfo');}
  get LinkIsExpired(): string { return this.getString('linkisexpired');}
  get LoadingApost(): string { return this.getString('loadingapost');}
  get Login_Details(): string { return this.getString('login_details');}
  get Login_WelcomeMessage(): string { return this.getString('login_welcomemessage');}
  get LoginAnonymously(): string { return this.getString('loginanonymously');}
  get LoginErrorMessage_EmptyUsernameOrPassword(): string { return this.getString('loginerrormessage_emptyusernameorpassword');}
  get LoginErrorMessage_ErrorLogin(): string { return this.getString('loginerrormessage_errorlogin');}
  get LoginErrorMessage_NoUserFound(): string { return this.getString('loginerrormessage_nouserfound');}
  get LoginErrorMessage_UserNotActive(): string { return this.getString('loginerrormessage_usernotactive');}
  get LoginErrorMessage_WrongPassword(): string { return this.getString('loginerrormessage_wrongpassword');}
  get Logout(): string { return this.getString('logout');}
  get LogoutReasonUsingMultipleSession(): string { return this.getString('logoutreasonusingmultiplesession');}
  get MandatoryParameters(): string { return this.getString('mandatoryparameters');}
  get MandatoryParametersNotSet(): string { return this.getString('mandatoryparametersnotset');}
  get MandatoryPopup_NoMoreParametersMessage(): string { return this.getString('mandatorypopup_nomoreparametersmessage');}
  get ManualModificationOfBOMItemsIsNotAllowed(): string { return this.getString('manualmodificationofbomitemsisnotallowed');}
  get MaximumLimitOfFiles(): string { return this.getString('maximumlimitoffiles');}
  get MaximumLimitForComparingConfigurations(): string { return this.getString('maximumlimitforcomparingconfigurations');}
  get MaxInlineResults(): string { return this.getString('maxinlineresults');}
  get MessageFromServer(): string { return this.getString('messagefromserver');}
  get Misc(): string { return this.getString('misc');}
  get MissingReadRightsMessage(): string { return this.getString('missingreadrightsmessage');}
  get MissingUpdateRightsMessage(): string { return this.getString('missingupdaterightsmessage');}
  get MissingPromoteRightsMessage(): string { return this.getString('missingpromoterightsmessage');}
  get MissingDemoteRightsMessage(): string { return this.getString('missingdemoterightsmessage');}
  get More(): string { return this.getString('more');}
  get Move(): string { return this.getString('move');}
  get MoveTheContentsOfTheClipboardToTheCurrentLocation(): string { return this.getString('movethecontentsoftheclipboardtothecurrentlocation');}
  get MoveBefore(): string { return this.getString('movebefore');}
  get MyRecent(): string { return this.getString('myrecent');}
  get MultipleConfigurationsWithIdentityXAndRevisionYFound(): string { return this.getString('multipleconfigurationswithidentityxandrevisionyfound');}
  get MultipleUserAccountsMatches(): string { return this.getString('multipleuseraccountsmatches');}
  get NameIsRequired(): string { return this.getString('nameisrequired');}
  get NameShouldBeOfTypeX(): string { return this.getString('nameshouldbeoftypex');}
  get Navigate(): string { return this.getString('navigate');}
  get NewConfiguration(): string { return this.getString('newconfiguration');}
  get NewParameterExplanation(): string { return this.getString('newparameterexplanation');}
  get NewParameters(): string { return this.getString('newparameters');}
  get NewParameters_Message(): string { return this.getString('newparameters_message');}
  get NewParameterSymbol(): string { return this.getString('newparametersymbol');}
  get News(): string { return this.getString('news');}
  get Next(): string { return this.getString('next');}
  get NoActiveRulesWithInformationToDisplay(): string { return this.getString('noactiveruleswithinformationtodisplay');}
  get NoConfigurationFoundWithTheIdOfX(): string { return this.getString('noconfigurationfoundwiththeidofx');}
  get NoConfigurationsSelectedForComparison(): string { return this.getString('noconfigurationsselectedforcomparison');}
  get NoExceptionToErrorPage(): string { return this.getString('noexceptiontoerrorpage');}
  get NoFileSelected(): string { return this.getString('nofileselected');}
  get NoneffectiveProductsXInformation(): string { return this.getString('noneffectiveproductsxinformation');}
  get NoResultsWereFound(): string { return this.getString('noresultswerefound');}
  get NotAValidBool(): string { return this.getString('notavalidbool');}
  get NoStateSet(): string { return this.getString('nostateset');}
  get NoMatchesFoundForX(): string { return this.getString('nomatchesfoundforx');}
  get NotAuthorizedToImportConfiguration(): string { return this.getString('notauthorizedtoimportconfiguration');}
  get NotAValidDate(): string { return this.getString('notavaliddate');}
  get NotAValidDouble(): string { return this.getString('notavaliddouble');}
  get NotAValidInteger(): string { return this.getString('notavalidinteger');}
  get NoValue(): string { return this.getString('novalue');}
  get Older(): string { return this.getString('older');}
  get OrderChangedSuccessfully(): string { return this.getString('orderchangedsuccessfully');}
  get OldParameters(): string { return this.getString('oldparameters');}
  get OldParametersMessage(): string { return this.getString('oldparametersmessage');}
  get OldPassword(): string { return this.getString('oldpassword');}
  get OperationWasCancelledCurrentlyBeingEditedOrReadonly(): string { return this.getString('operationwascancelledcurrentlybeingeditedorreadonly');}
  get OperationIsValidOnlyIfTheConfiguratorSessionIsBeingEdited(): string { return this.getString('operationisvalidonlyiftheconfiguratorsessionisbeingedited');}
  get OperationNotAllowedForUnsavedConfiguration(): string { return this.getString('operationnotallowedforunsavedconfiguration');}
  get Operations(): string { return this.getString('operations');}
  get OperationSeq(): string { return this.getString('operationseq');}
  get OperationSeqId(): string { return this.getString('operationseqid');}
  get Optimized(): string { return this.getString('optimized');}
  get OptimizedAutoCheck(): string { return this.getString('optimizedautocheck');}
  get Options(): string { return this.getString('options');}
  get OwnershipChangedFrom(): string { return this.getString('ownershipchangedfrom');}
  get ParametersClearedDueToRules(): string { return this.getString('parametersclearedduetorules');}
  get PasswordAboutToExpireMessage(): string { return this.getString('passwordabouttoexpiremessage');}
  get PasswordExpired(): string { return this.getString('passwordexpired');}
  get PasswordExpiredMessage(): string { return this.getString('passwordexpiredmessage');}
  get PasswordRecovered(): string { return this.getString('passwordrecovered');}
  get PasswordRecoveryLink(): string { return this.getString('passwordrecoverylink');}
  get PasswordRecoveryMessageX(): string { return this.getString('passwordrecoverymessagex');}
  get PasswordRecoveryBodyText(): string { return this.getString('passwordrecoverybodytext');}
  get PasswordRecoveryBodyTextDescription(): string { return this.getString('passwordrecoverybodytextdescription');}
  get PasswordRecoverySubjectText(): string { return this.getString('passwordrecoverysubjecttext');}
  get PasswordRecoverySubjectTextDescription(): string { return this.getString('passwordrecoverysubjecttextdescription');}
  get PerformActionOn(): string { return this.getString('performactionon');}
  get PleaseSelectARowBeforeClickingOnEdit(): string { return this.getString('pleaseselectarowbeforeclickingonedit');}
  get PleaseSelectARowBeforeClickingOnRemove(): string { return this.getString('pleaseselectarowbeforeclickingonremove');}
  get PluralString(): string { return this.getString('pluralstring');}
  get PositionNo(): string { return this.getString('positionno');}
  get Previous(): string { return this.getString('previous');}
  get PreviousQuarter(): string { return this.getString('previousquarter');}
  get PreviousYear(): string { return this.getString('previousyear');}
  get Prices(): string { return this.getString('prices');}
  get Pricing(): string { return this.getString('pricing');}
  get PriceItemXAmountIsInConsistent(): string { return this.getString('priceitemxamountisinconsistent');}
  get PriceItemValueCleared(): string { return this.getString('priceitemvaluecleared');}
  get PriceItemXOperationIsInConsistent(): string { return this.getString('priceitemxoperationisinconsistent');}
  get PriceListNotification(): string { return this.getString('pricelistnotification');}
  get PrintToolTip(): string { return this.getString('printtooltip');}
  get Private(): string { return this.getString('private');}
  get Production(): string { return this.getString('production');}
  get ProductDataUpdatedAndVerifyConfiguration(): string { return this.getString('productdataupdatedandverifyconfiguration');}
  get Profile(): string { return this.getString('profile');}
  get PromoteFrom(): string { return this.getString('promotefrom');}
  get PromoteIsNotAllowed(): string { return this.getString('promoteisnotallowed');}
  get PromoteMessage(): string { return this.getString('promotemessage');}
  get Properties(): string { return this.getString('properties');}
  get ProvidedKeyDoesNotExist(): string { return this.getString('providedkeydoesnotexist');}
  get Public(): string { return this.getString('public');}
  get QuickSearch(): string { return this.getString('quicksearch');}
  get Range(): string { return this.getString('range');}
  get ReactivateUser(): string { return this.getString('reactivateuser');}
  get ReadOnlyParameter(): string { return this.getString('readonlyparameter');}
  get RecentFromWorkgroup(): string { return this.getString('recentfromworkgroup');}
  get Recent(): string { return this.getString('recent');}
  get RecoverConfigurationPopupDescription(): string { return this.getString('recoverconfigurationpopupdescription');}
  get RecoverForgottenPassword(): string { return this.getString('recoverforgottenpassword');}
  get RecoverPassword(): string { return this.getString('recoverpassword');}
  get RefreshTheSummaryPage(): string { return this.getString('refreshthesummarypage');}
  get ReloadCaches(): string { return this.getString('reloadcaches');}
  get ReloadCaches_Message(): string { return this.getString('reloadcaches_message');}
  get ReloadUserData(): string { return this.getString('reloaduserdata');}
  get Remove(): string { return this.getString('remove');}
  get RemoveItem(): string { return this.getString('removeitem');}
  get ReportImagePathXReadError(): string { return this.getString('reportimagepathxreaderror');}
  get Reset(): string { return this.getString('reset');}
  get ResetPassword(): string { return this.getString('resetpassword');}
  get ResetYourPassword(): string { return this.getString('resetyourpassword');}
  get Recover(): string { return this.getString('recover');}
  get ResultsPerPage(): string { return this.getString('resultsperpage');}
  get Reuse(): string { return this.getString('reuse');}
  get ReuseToolTip(): string { return this.getString('reusetooltip');}
  get Revise(): string { return this.getString('revise');}
  get ReviseToolTip(): string { return this.getString('revisetooltip');}
  get Revisions(): string { return this.getString('revisions');}
  get RulesSetOrTriedToSetValuesBelow(): string { return this.getString('rulessetortriedtosetvaluesbelow');}
  get RulesWillSetOrTriedToSetValuesBelow(): string { return this.getString('ruleswillsetortriedtosetvaluesbelow');}
  get RuleConfirmPopup_ConfirmMessage(): string { return this.getString('ruleconfirmpopup_confirmmessage');}
  get RunAsAdministrator(): string { return this.getString('runasadministrator');}
  get SaveAndClose(): string { return this.getString('saveandclose');}
  get SaveAsNew(): string { return this.getString('saveasnew');}
  get SaveConfiguration(): string { return this.getString('saveconfiguration');}
  get SaveIsNotAllowed(): string { return this.getString('saveisnotallowed');}
  get SaveSearch(): string { return this.getString('savesearch');}
  get SaveThisSearch(): string { return this.getString('savethissearch');}
  get SaveQueryForAllUsers(): string { return this.getString('savequeryforallusers');}
  get Scope(): string { return this.getString('scope');}
  get SearchChildren(): string { return this.getString('searchchildren');}
  get SearchConfiguration(): string { return this.getString('searchconfiguration');}
  get SearchResultFirstLine(): string { return this.getString('searchresultfirstline');}
  get SearchResultFirstLineDescription(): string { return this.getString('searchresultfirstlinedescription');}
  get SearchResults(): string { return this.getString('searchresults');}
  get SearchResultSecondLine(): string { return this.getString('searchresultsecondline');}
  get SearchResultSecondLineDescription(): string { return this.getString('searchresultsecondlinedescription');}
  get SearchSessionDoesNotBelongToTheUser(): string { return this.getString('searchsessiondoesnotbelongtotheuser');}
  get SelectAll(): string { return this.getString('selectall');}
  get SelectConfigurationsWhenNavigatingMap(): string { return this.getString('selectconfigurationswhennavigatingmap');}
  get SelectedValueImageWidth(): string { return this.getString('selectedvalueimagewidth');}
  get SelectedValueImageHeight(): string { return this.getString('selectedvalueimageheight');}
  get SelectFiles(): string { return this.getString('selectfiles');}
  get SelectLangugageAndPressOk(): string { return this.getString('selectlangugageandpressok');}
  get SelectProduct(): string { return this.getString('selectproduct');}
  get SelectValue(): string { return this.getString('selectvalue');}
  get SendVerificationCode(): string { return this.getString('sendverificationcode');}
  get SessionExpiredMessage(): string { return this.getString('sessionexpiredmessage');}
  get SetCurrency(): string { return this.getString('setcurrency');}
  get ShipToStreet(): string { return this.getString('shiptostreet');}
  get ShipToPostalCode(): string { return this.getString('shiptopostalcode');}
  get ShipToCity(): string { return this.getString('shiptocity');}
  get ShipToProvince(): string { return this.getString('shiptoprovince');}
  get ShipToCountry(): string { return this.getString('shiptocountry');}
  get Show(): string { return this.getString('show');}
  get ShowChildrenInAccordionTabs(): string { return this.getString('showchildreninaccordiontabs');}
  get ShowForSelected(): string { return this.getString('showforselected');}
  get ShowCompositeSidebarLabel(): string { return this.getString('showcompositesidebarlabel');}
  get ShowCompositeSidebarDescription(): string { return this.getString('showcompositesidebardescription');}
  get ShowCurrentValue(): string { return this.getString('showcurrentvalue');}
  get ShowDeveloperTools(): string { return this.getString('showdevelopertools');}
  get ShowGraphicAxis(): string { return this.getString('showgraphicaxis');}
  get ShowMandatoryParameters(): string { return this.getString('showmandatoryparameters');}
  get ShowResultsInline(): string { return this.getString('showresultsinline');}
  get ShowBlankParameters(): string { return this.getString('showblankparameters');}
  get ShowBlankParametersDesc(): string { return this.getString('showblankparametersdesc');}
  get ShowFalseParameters(): string { return this.getString('showfalseparameters');}
  get ShowFalseParametersDesc(): string { return this.getString('showfalseparametersdesc');}
  get ShowMyRecent(): string { return this.getString('showmyrecent');}
  get ShowMyRecentDescription(): string { return this.getString('showmyrecentdescription');}
  get ShowWorkgroupRecent(): string { return this.getString('showworkgrouprecent');}
  get ShowWorkgroupRecentDescription(): string { return this.getString('showworkgrouprecentdescription');}
  get SignUp(): string { return this.getString('signup');}
  get SingleValue(): string { return this.getString('singlevalue');}
  get SorryNoResultsPleaseTryAgain(): string { return this.getString('sorrynoresultspleasetryagain');}
  get Start(): string { return this.getString('start');}
  get Starting(): string { return this.getString('starting');}
  get StartPage_UsersLastConfigurationsLink(): string { return this.getString('startpage_userslastconfigurationslink');}
  get StartPage_UsersLastConfigurationsMessage(): string { return this.getString('startpage_userslastconfigurationsmessage');}
  get StartPage_WorkGroupLastConfigurationsLink(): string { return this.getString('startpage_workgrouplastconfigurationslink');}
  get StartPage_WorkGroupLastConfigurationsMessage(): string { return this.getString('startpage_workgrouplastconfigurationsmessage');}
  get StateUpdate_ErrorMessage(): string { return this.getString('stateupdate_errormessage');}
  get StateChangedFrom(): string { return this.getString('statechangedfrom');}
  get StoredAttributes(): string { return this.getString('storedattributes');}
  get StoredBillOfMaterials(): string { return this.getString('storedbillofmaterials');}
  get StoredData(): string { return this.getString('storeddata');}
  get StoredItem(): string { return this.getString('storeditem');}
  get StoreCode(): string { return this.getString('storecode');}
  get StoredCode(): string { return this.getString('storedcode');}
  get StoredPriceItem(): string { return this.getString('storedpriceitem');}
  get StoredPrices(): string { return this.getString('storedprices');}
  get StoredQueries(): string { return this.getString('storedqueries');}
  get StoredStructureAttributes(): string { return this.getString('storedstructureattributes');}
  get StoredValues(): string { return this.getString('storedvalues');}
  get StoreQuery(): string { return this.getString('storequery');}
  get StoreQuery_NameErrorMessage(): string { return this.getString('storequery_nameerrormessage');}
  get StoreQuery_StoreMessage(): string { return this.getString('storequery_storemessage');}
  get StructureAddDialogNoProductsToAddMessage(): string { return this.getString('structureadddialognoproductstoaddmessage');}
  get SuccessfullyDeletedSearch(): string { return this.getString('successfullydeletedsearch');}
  get SummarizeAfterConsolidatingBOM(): string { return this.getString('summarizeafterconsolidatingbom');}
  get Summary(): string { return this.getString('summary');}
  get SymbolExplanation(): string { return this.getString('symbolexplanation');}
  get ThisMonth(): string { return this.getString('thismonth');}
  get ThisQuarter(): string { return this.getString('thisquarter');}
  get ThisWeek(): string { return this.getString('thisweek');}
  get ThisYear(): string { return this.getString('thisyear');}
  get TheFollowingValuesWereSet(): string { return this.getString('thefollowingvalueswereset');}
  get TheFormMustBeFilledOut(): string { return this.getString('theformmustbefilledout');}
  get TheValueMustBeBetweenXAndY(): string { return this.getString('thevaluemustbebetweenxandy');}
  get TheValueMustBeGreaterOrEqualToX(): string { return this.getString('thevaluemustbegreaterorequaltox');}
  get TheValueMustBeLesserOrEqualToX(): string { return this.getString('thevaluemustbelesserorequaltox');}
  get TheValueIsLockedDueToTheFollowingRules(): string { return this.getString('thevalueislockedduetothefollowingrules');}
  get TitleIsMissing(): string { return this.getString('titleismissing');}
  get To(): string { return this.getString('to');}
  get Today(): string { return this.getString('today');}
  get ToggleDropdown(): string { return this.getString('toggledropdown');}
  get Total(): string { return this.getString('total');}
  get TreeExpandBehaviour(): string { return this.getString('treeexpandbehaviour');}
  get TreeViewExpandLevels(): string { return this.getString('treeviewexpandlevels');}
  get UnauthorizedAccessVault(): string { return this.getString('unauthorizedaccessvault');}
  get Unsaved(): string { return this.getString('unsaved');}
  get Unspecified(): string { return this.getString('unspecified');}
  get Upload(): string { return this.getString('upload');}
  get Uploading(): string { return this.getString('uploading');}
  get UploadCompleted(): string { return this.getString('uploadcompleted');}
  get UploadFailed(): string { return this.getString('uploadfailed');}
  get UploadNotStarted(): string { return this.getString('uploadnotstarted');}
  get UnitCost(): string { return this.getString('unitcost');}
  get UnitPrice(): string { return this.getString('unitprice');}
  get UnsavedChangesConfirmation(): string { return this.getString('unsavedchangesconfirmation');}
  get UsernameOrEmail(): string { return this.getString('usernameoremail');}
  get UserAccountIsInactive(): string { return this.getString('useraccountisinactive');}
  get UserControlled(): string { return this.getString('usercontrolled');}
  get UsernameAlreadyExists(): string { return this.getString('usernamealreadyexists');}
  get UsernameIsRequired(): string { return this.getString('usernameisrequired');}
  get UsernameNotFound(): string { return this.getString('usernamenotfound');}
  get UserProfile(): string { return this.getString('userprofile');}
  get UserProfile_SaveSuccess(): string { return this.getString('userprofile_savesuccess');}
  get UserSettings(): string { return this.getString('usersettings');}
  get UserIsNotAuthorizedToSaveTheSearch(): string { return this.getString('userisnotauthorizedtosavethesearch');}
  get Value1(): string { return this.getString('value1');}
  get Value2(): string { return this.getString('value2');}
  get ValueForAuthorizationGroupMissing(): string { return this.getString('valueforauthorizationgroupmissing');}
  get ValueIsLocked(): string { return this.getString('valueislocked');}
  get ValueRemoved(): string { return this.getString('valueremoved');}
  get Welcome(): string { return this.getString('welcome');}
  get ValidEmailRequiredForConfiguration(): string { return this.getString('validemailrequiredforconfiguration');}
  get ValueXForArgumentYHasInvalidFormat(): string { return this.getString('valuexforargumentyhasinvalidformat');}
  get Viewer(): string { return this.getString('viewer');}
  get ViewErrorDetails(): string { return this.getString('viewerrordetails');}
  get ViewErrormessage(): string { return this.getString('viewerrormessage');}
  get ViewHistory(): string { return this.getString('viewhistory');}
  get ViewXYOfZ(): string { return this.getString('viewxyofz');}
  get Visualizations(): string { return this.getString('visualizations');}
  get Warning(): string { return this.getString('warning');}
  get WebConfiguratorFooterClose(): string { return this.getString('webconfiguratorfooterclose');}
  get WebConfiguratorFooterCloseDescription(): string { return this.getString('webconfiguratorfooterclosedescription');}
  get WebConfiguratorFooterMandatory(): string { return this.getString('webconfiguratorfootermandatory');}
  get WebConfiguratorFooterMandatoryDescription(): string { return this.getString('webconfiguratorfootermandatorydescription');}
  get WebConfiguratorFooterSave(): string { return this.getString('webconfiguratorfootersave');}
  get WebConfiguratorFooterSaveDescription(): string { return this.getString('webconfiguratorfootersavedescription');}
  get WebConfiguratorFooterSaveAndClose(): string { return this.getString('webconfiguratorfootersaveandclose');}
  get WebConfiguratorFooterSaveAndCloseDescription(): string { return this.getString('webconfiguratorfootersaveandclosedescription');}
  get WebMainMenuCreate(): string { return this.getString('webmainmenucreate');}
  get WebMainMenuCreateDescription(): string { return this.getString('webmainmenucreatedescription');}
  get WebMainMenuLogout(): string { return this.getString('webmainmenulogout');}
  get WebMainMenuLogoutDescription(): string { return this.getString('webmainmenulogoutdescription');}
  get WebMainMenuSettings(): string { return this.getString('webmainmenusettings');}
  get WebMainMenuSettingsDescription(): string { return this.getString('webmainmenusettingsdescription');}
  get WebMainMenuSearch(): string { return this.getString('webmainmenusearch');}
  get WebMainMenuSearchDescription(): string { return this.getString('webmainmenusearchdescription');}
  get WebMainMenuStart(): string { return this.getString('webmainmenustart');}
  get WebMainMenuStartDescription(): string { return this.getString('webmainmenustartdescription');}
  get WebSessionTimeout(): string { return this.getString('websessiontimeout');}
  get WorkGroup(): string { return this.getString('workgroup');}
  get WorkgroupRecent(): string { return this.getString('workgrouprecent');}
  get WorkgroupSettingMissing(): string { return this.getString('workgroupsettingmissing');}
  get XCannotBeSetToY(): string { return this.getString('xcannotbesettoy');}
  get IsLocked(): string { return this.getString('islocked');}
  get XIsNotAValidDecimal(): string { return this.getString('xisnotavaliddecimal');}
  get XSaved(): string { return this.getString('xsaved');}
  get XConfigurationsCreatedSuccessfully(): string { return this.getString('xconfigurationscreatedsuccessfully');}
  get Yesterday(): string { return this.getString('yesterday');}
  get YourConfigurationHasUnsavedChanges(): string { return this.getString('yourconfigurationhasunsavedchanges');}
  get YourLoginIsNotYetInTheCache(): string { return this.getString('yourloginisnotyetinthecache');}
  get YourEmailAddressIsNotVerifiedYet(): string { return this.getString('youremailaddressisnotverifiedyet');}
  get YouAreNotAuthorizedToAccessModifySavedSearches(): string { return this.getString('youarenotauthorizedtoaccessmodifysavedsearches');}
  get YouAreNotAuthorizedToPerformSearchOperation(): string { return this.getString('youarenotauthorizedtoperformsearchoperation');}
  get YouDontHaveTheAuthorizationToAddAttachment(): string { return this.getString('youdonthavetheauthorizationtoaddattachment');}
  get YouDontHaveTheAuthorizationToAddChildConfiguration(): string { return this.getString('youdonthavetheauthorizationtoaddchildconfiguration');}
  get YouDontHaveTheAuthorizationToCopyConfiguration(): string { return this.getString('youdonthavetheauthorizationtocopyconfiguration');}
  get YouDontHaveTheAuthorizationToDeleteAttachment(): string { return this.getString('youdonthavetheauthorizationtodeleteattachment');}
  get YouDontHaveTheAuthorizationToDeleteConfigurationSearch(): string { return this.getString('youdonthavetheauthorizationtodeleteconfigurationsearch');}
  get YouDontHaveTheAuthorizationToSaveConfigurationSearch(): string { return this.getString('youdonthavetheauthorizationtosaveconfigurationsearch');}
  get YouDontHaveTheAuthorizationToDeleteConfiguration(): string { return this.getString('youdonthavetheauthorizationtodeleteconfiguration');}
  get YouDontHaveTheAuthorizationToChangeCurrency(): string { return this.getString('youdonthavetheauthorizationtochangecurrency');}
  get YouDontHaveTheAuthorizationToChangeOwnership(): string { return this.getString('youdonthavetheauthorizationtochangeownership');}
  get YouDontHaveTheAuthorizationToChangePriceList(): string { return this.getString('youdonthavetheauthorizationtochangepricelist');}
  get YouDontHaveTheAuthorizationToChangePriceListCategory(): string { return this.getString('youdonthavetheauthorizationtochangepricelistcategory');}
  get YouDontHaveTheAuthorizationToConfigureProduct(): string { return this.getString('youdonthavetheauthorizationtoconfigureproduct');}
  get YouDontHaveTheAuthorizationToReadConfiguration(): string { return this.getString('youdonthavetheauthorizationtoreadconfiguration');}
  get YouDontHaveTheAuthorizationToReuseConfiguration(): string { return this.getString('youdonthavetheauthorizationtoreuseconfiguration');}
  get YouDontHaveTheAuthorizationToReviseConfiguration(): string { return this.getString('youdonthavetheauthorizationtoreviseconfiguration');}
  get YouDontHaveTheAuthorizationToSaveConfiguration(): string { return this.getString('youdonthavetheauthorizationtosaveconfiguration');}
  get YouDontHaveTheAuthorizationToUpdateConfiguration(): string { return this.getString('youdonthavetheauthorizationtoupdateconfiguration');}
  get YouDontHaveTheAuthorizationToUpdateUser(): string { return this.getString('youdonthavetheauthorizationtoupdateuser');}
  get YouDontHaveTheAuthorizationToUpdateUserSettings(): string { return this.getString('youdonthavetheauthorizationtoupdateusersettings');}
  get YouDontHaveTheAuthorizationToViewAttachment(): string { return this.getString('youdonthavetheauthorizationtoviewattachment');}
  get YouDontHaveTheAuthorizationToViewDocument(): string { return this.getString('youdonthavetheauthorizationtoviewdocument');}
  get YouDontHaveTheAuthorizationToImportNonEffectiveConfigurations(): string { return this.getString('youdonthavetheauthorizationtoimportnoneffectiveconfigurations');}
  get ProdConfCannotBeSavedInAdminSite(): string { return this.getString('prodconfcannotbesavedinadminsite');}
  get ProdConfCannotBeRevisedInAdminSite(): string { return this.getString('prodconfcannotberevisedinadminsite');}
  get About(): string { return this.getString('about');}
  get AboutCombinum(): string { return this.getString('aboutcombinum');}
  get AboveValues(): string { return this.getString('abovevalues');}
  get Accent1(): string { return this.getString('accent1');}
  get Accent2(): string { return this.getString('accent2');}
  get Accent3(): string { return this.getString('accent3');}
  get Accent4(): string { return this.getString('accent4');}
  get Accent5(): string { return this.getString('accent5');}
  get Accent6(): string { return this.getString('accent6');}
  get AccordionHeaderTab(): string { return this.getString('accordionheadertab');}
  get AccountRecovery(): string { return this.getString('accountrecovery');}
  get Action(): string { return this.getString('action');}
  get ActionArea(): string { return this.getString('actionarea');}
  get ActionBoxes(): string { return this.getString('actionboxes');}
  get ActionBoxesDescription(): string { return this.getString('actionboxesdescription');}
  get ActionItems(): string { return this.getString('actionitems');}
  get Activate(): string { return this.getString('activate');}
  get ActivateNewAccount(): string { return this.getString('activatenewaccount');}
  get ActivateNewAccountDescription(): string { return this.getString('activatenewaccountdescription');}
  get ActivationExpression(): string { return this.getString('activationexpression');}
  get ActivationExpressionIsNotValid(): string { return this.getString('activationexpressionisnotvalid');}
  get Active(): string { return this.getString('active');}
  get ActiveStructureModes(): string { return this.getString('activestructuremodes');}
  get ActiveUsers(): string { return this.getString('activeusers');}
  get ActiveCustomerUsers(): string { return this.getString('activecustomerusers');}
  get AddChild(): string { return this.getString('addchild');}
  get AddFolder(): string { return this.getString('addfolder');}
  get AddIconMargin(): string { return this.getString('addiconmargin');}
  get Addition(): string { return this.getString('addition');}
  get AdditionSubtraction(): string { return this.getString('additionsubtraction');}
  get AddMultiple(): string { return this.getString('addmultiple');}
  get AddMultiplePriceItems(): string { return this.getString('addmultiplepriceitems');}
  get AddMultipleValues(): string { return this.getString('addmultiplevalues');}
  get AddRow(): string { return this.getString('addrow');}
  get AdjustForImageSize(): string { return this.getString('adjustforimagesize');}
  get Administrator(): string { return this.getString('administrator');}
  get AdvancedPricing(): string { return this.getString('advancedpricing');}
  get ProductData(): string { return this.getString('productdata');}
  get AdvancedRule(): string { return this.getString('advancedrule');}
  get All(): string { return this.getString('all');}
  get AllAlphabeticalAttributes(): string { return this.getString('allalphabeticalattributes');}
  get AllContentsExceptFoldersWillAlsoBeDeleted(): string { return this.getString('allcontentsexceptfolderswillalsobedeleted');}
  get AllFiles(): string { return this.getString('allfiles');}
  get AllObjectsCouldNotBeDeleted(): string { return this.getString('allobjectscouldnotbedeleted');}
  get AllowEditOperationSequence(): string { return this.getString('alloweditoperationsequence');}
  get AllowEditOperationSequenceDescription(): string { return this.getString('alloweditoperationsequencedescription');}
  get AllowedLoginAttempts(): string { return this.getString('allowedloginattempts');}
  get AllowManuallyAddedItems(): string { return this.getString('allowmanuallyaddeditems');}
  get AllowOnlyAdminUsersLoginToAdminSite(): string { return this.getString('allowonlyadminuserslogintoadminsite');}
  get AllowSave(): string { return this.getString('allowsave');}
  get AllowSaveDescription(): string { return this.getString('allowsavedescription');}
  get AllowSearchForChildConfigurations(): string { return this.getString('allowsearchforchildconfigurations');}
  get AllowSearchForChildConfigurationsDescription(): string { return this.getString('allowsearchforchildconfigurationsdescription');}
  get AlternativeValueExpression(): string { return this.getString('alternativevalueexpression');}
  get AlternativeValueExpressionIsNotValid(): string { return this.getString('alternativevalueexpressionisnotvalid');}
  get AnItemWithItemNumberXAndRevisionYAlreadyExists(): string { return this.getString('anitemwithitemnumberxandrevisionyalreadyexists');}
  get AnonymousAccessDisableUploadItem(): string { return this.getString('anonymousaccessdisableuploaditem');}
  get AnonymousAccessDisableUploadItemDescription(): string { return this.getString('anonymousaccessdisableuploaditemdescription');}
  get AnonymousAccessEmailBodyText(): string { return this.getString('anonymousaccessemailbodytext');}
  get AnonymousAccessEmailBodyTextDescription(): string { return this.getString('anonymousaccessemailbodytextdescription');}
  get AnonymousAccessEmailSubjectText(): string { return this.getString('anonymousaccessemailsubjecttext');}
  get AnonymousAccessEmailSubjectTextDescription(): string { return this.getString('anonymousaccessemailsubjecttextdescription');}
  get AnonymousAccessGuestUserDescription(): string { return this.getString('anonymousaccessguestuserdescription');}
  get AnonymousAccessGuestUser(): string { return this.getString('anonymousaccessguestuser');}
  get AnonymousAccessSenderEmailAdress(): string { return this.getString('anonymousaccesssenderemailadress');}
  get AnonymousAccessSenderEmailAdressDescription(): string { return this.getString('anonymousaccesssenderemailadressdescription');}
  get And(): string { return this.getString('and');}
  get AnErrorOccuredWhenConnectingToTheDatabase(): string { return this.getString('anerroroccuredwhenconnectingtothedatabase');}
  get ApplicationAndDatabaseVersionNotCompatible(): string { return this.getString('applicationanddatabaseversionnotcompatible');}
  get AppliedIndividually(): string { return this.getString('appliedindividually');}
  get AppliedIndividuallyShort(): string { return this.getString('appliedindividuallyshort');}
  get AppliedIndividuallyToolTip(): string { return this.getString('appliedindividuallytooltip');}
  get ApplyToSelectedWorkGroups(): string { return this.getString('applytoselectedworkgroups');}
  get ArchitectVersion(): string { return this.getString('architectversion');}
  get AreYouSure(): string { return this.getString('areyousure');}
  get AreYouSureYouWantToChangeTheDataType(): string { return this.getString('areyousureyouwanttochangethedatatype');}
  get AreYouSureYouWantToDeleteAllNotUsedKeyTexts(): string { return this.getString('areyousureyouwanttodeleteallnotusedkeytexts');}
  get AreYouSureYouWantToDeleteX(): string { return this.getString('areyousureyouwanttodeletex');}
  get AreYouSureYouWantToExitWithoutSaving(): string { return this.getString('areyousureyouwanttoexitwithoutsaving');}
  get AreYouSureYouWantToModifyItemsFromSearchedResult(): string { return this.getString('areyousureyouwanttomodifyitemsfromsearchedresult');}
  get AreYouSureYouWantToUnlinkTheRootItemFromTheProduct(): string { return this.getString('areyousureyouwanttounlinktherootitemfromtheproduct');}
  get Argument(): string { return this.getString('argument');}
  get AsACopyTo(): string { return this.getString('asacopyto');}
  get AsALinkTo(): string { return this.getString('asalinkto');}
  get AsChildTo(): string { return this.getString('aschildto');}
  get AsEnteredWithUnderscores(): string { return this.getString('asenteredwithunderscores');}
  get AsMoveToTab(): string { return this.getString('asmovetotab');}
  get Assembly(): string { return this.getString('assembly');}
  get Attribute(): string { return this.getString('attribute');}
  get AttributeDecoration(): string { return this.getString('attributedecoration');}
  get AttributeDecorationItem(): string { return this.getString('attributedecorationitem');}
  get AttributeDecorationItemDoNotHaveAnAttributeReference(): string { return this.getString('attributedecorationitemdonothaveanattributereference');}
  get AttributeDisplayStyle(): string { return this.getString('attributedisplaystyle');}
  get AttributeLinks(): string { return this.getString('attributelinks');}
  get AttributeMatrix(): string { return this.getString('attributematrix');}
  get AttributesForXY(): string { return this.getString('attributesforxy');}
  get AttributeValue(): string { return this.getString('attributevalue');}
  get Authorization(): string { return this.getString('authorization');}
  get AuthorizationGroupFolder(): string { return this.getString('authorizationgroupfolder');}
  get AutoCheckChildren(): string { return this.getString('autocheckchildren');}
  get AutoCheckSiblings(): string { return this.getString('autochecksiblings');}
  get Automatic(): string { return this.getString('automatic');}
  get AutomaticNaming(): string { return this.getString('automaticnaming');}
  get AutomaticNamingStyle(): string { return this.getString('automaticnamingstyle');}
  get AutoSelectRootConfiguration(): string { return this.getString('autoselectrootconfiguration');}
  get AutoSelectRootConfigurationDesc(): string { return this.getString('autoselectrootconfigurationdesc');}
  get AvailableProperties(): string { return this.getString('availableproperties');}
  get BadUsernameOrPassword(): string { return this.getString('badusernameorpassword');}
  get BandedColumns(): string { return this.getString('bandedcolumns');}
  get BandedRows(): string { return this.getString('bandedrows');}
  get BasePriceIsNotAValidDouble(): string { return this.getString('basepriceisnotavaliddouble');}
  get BillingSite(): string { return this.getString('billingsite');}
  get BillToStreet(): string { return this.getString('billtostreet');}
  get BillToPostalCode(): string { return this.getString('billtopostalcode');}
  get BillToCity(): string { return this.getString('billtocity');}
  get BillToProvince(): string { return this.getString('billtoprovince');}
  get BillToCountry(): string { return this.getString('billtocountry');}
  get BinaryData(): string { return this.getString('binarydata');}
  get BinaryDataCouldNotBeReplaced(): string { return this.getString('binarydatacouldnotbereplaced');}
  get BinaryDataCouldNotBeDownloaded(): string { return this.getString('binarydatacouldnotbedownloaded');}
  get BitMaskPosition(): string { return this.getString('bitmaskposition');}
  get Black(): string { return this.getString('black');}
  get Blue(): string { return this.getString('blue');}
  get BlueCustomerWithMaxNumberOfUsers(): string { return this.getString('bluecustomerwithmaxnumberofusers');}
  get Bold(): string { return this.getString('bold');}
  get BOMColumnSettings(): string { return this.getString('bomcolumnsettings');}
  get BooleanParameter(): string { return this.getString('booleanparameter');}
  get Both(): string { return this.getString('both');}
  get BothAdjustsWordSpacing(): string { return this.getString('bothadjustswordspacing');}
  get Bottom(): string { return this.getString('bottom');}
  get BottomCenter(): string { return this.getString('bottomcenter');}
  get BottomLeft(): string { return this.getString('bottomleft');}
  get BottomRight(): string { return this.getString('bottomright');}
  get BreakAfter(): string { return this.getString('breakafter');}
  get BreakBefore(): string { return this.getString('breakbefore');}
  get BrightGreen(): string { return this.getString('brightgreen');}
  get Browse(): string { return this.getString('browse');}
  get BrowserInfo(): string { return this.getString('browserinfo');}
  get BulletPositionCm(): string { return this.getString('bulletpositioncm');}
  get Calculated(): string { return this.getString('calculated');}
  get CanChangeCurrency(): string { return this.getString('canchangecurrency');}
  get CanNotAddRepeaterBecauseOfUnderlayingRules(): string { return this.getString('cannotaddrepeaterbecauseofunderlayingrules');}
  get CanNotChangeValueTypeDueToExistingExpressionRef(): string { return this.getString('cannotchangevaluetypeduetoexistingexpressionref');}
  get CanNotChangeValueTypeToXWhenTheAttributeHaveAttributeValues(): string { return this.getString('cannotchangevaluetypetoxwhentheattributehaveattributevalues');}
  get CannotChangeValueTypeValuesCannotBeConverted(): string { return this.getString('cannotchangevaluetypevaluescannotbeconverted');}
  get CanNotChangeRepeaterProductBecauseOfUnderlayingRules(): string { return this.getString('cannotchangerepeaterproductbecauseofunderlayingrules');}
  get CanNotCheckEveryValueWhenUncheckedTriggering(): string { return this.getString('cannotcheckeveryvaluewhenuncheckedtriggering');}
  get CannotCloseCSharpEditorUnsavedChanges(): string { return this.getString('cannotclosecsharpeditorunsavedchanges');}
  get CanNotDeleteAuthorizationGroupWithUsers(): string { return this.getString('cannotdeleteauthorizationgroupwithusers');}
  get CannotPerformOperationsOnVaultOrSpecialSubDirectories(): string { return this.getString('cannotperformoperationsonvaultorspecialsubdirectories');}
  get CanNotInitalizeTheTabsProduct(): string { return this.getString('cannotinitalizethetabsproduct');}
  get CanNotLinkAttributeXBecauseNotLinkedToDecorationsProducts(): string { return this.getString('cannotlinkattributexbecausenotlinkedtodecorationsproducts');}
  get CanNotLinkPriceItemAsChildSinceItHasCategory(): string { return this.getString('cannotlinkpriceitemaschildsinceithascategory');}
  get CanNotLinkTheFolderOnItself(): string { return this.getString('cannotlinkthefolderonitself');}
  get CanNotLinkXSinceItHasContents(): string { return this.getString('cannotlinkxsinceithascontents');}
  get CanNotLinkXToItself(): string { return this.getString('cannotlinkxtoitself');}
  get CanNotLinkXToYThatIsChildOfZ(): string { return this.getString('cannotlinkxtoythatischildofz');}
  get CannotLoginContactSystemAdministrator(): string { return this.getString('cannotlogincontactsystemadministrator');}
  get CannotMoveToChildParagraph(): string { return this.getString('cannotmovetochildparagraph');}
  get CannotMoveXToY(): string { return this.getString('cannotmovextoy');}
  get CannotPasteAsLink(): string { return this.getString('cannotpasteaslink');}
  get CannotPasteAsLinkXOnY(): string { return this.getString('cannotpasteaslinkxony');}
  get CannotPasteXOnY(): string { return this.getString('cannotpastexony');}
  get CannotPasteXBecauseTheExpressionForYIsNotCompatibleWithProduct(): string { return this.getString('cannotpastexbecausetheexpressionforyisnotcompatiblewithproduct');}
  get CanNotProcessStructuresGreaterThanXLevels(): string { return this.getString('cannotprocessstructuresgreaterthanxlevels');}
  get CanNotRemoveRepeaterBecauseOfUnderlayingRules(): string { return this.getString('cannotremoverepeaterbecauseofunderlayingrules');}
  get CanNotRemoveRepeaterBecauseOfUnderlayingStructure(): string { return this.getString('cannotremoverepeaterbecauseofunderlayingstructure');}
  get CannotPublishFromThisSite(): string { return this.getString('cannotpublishfromthissite');}
  get CanNotSetDefaultValueToAnObsoleteLookupValue(): string { return this.getString('cannotsetdefaultvaluetoanobsoletelookupvalue');}
  get CanNotSetLookupValueAsObsoleteBecauseDefaultValue(): string { return this.getString('cannotsetlookupvalueasobsoletebecausedefaultvalue');}
  get CanNotSetValueAsObsoleteBecauseOfRules(): string { return this.getString('cannotsetvalueasobsoletebecauseofrules');}
  get CanNotSetValueTypeDueToExistingLookupValueTypes(): string { return this.getString('cannotsetvaluetypeduetoexistinglookupvaluetypes');}
  get CannotUnlinkTheAttributeFromTheProduct(): string { return this.getString('cannotunlinktheattributefromtheproduct');}
  get CannotUpdateObjectBecause(): string { return this.getString('cannotupdateobjectbecause');}
  get Cascade(): string { return this.getString('cascade');}
  get Category(): string { return this.getString('category');}
  get CategoryOfTheConfiguration(): string { return this.getString('categoryoftheconfiguration');}
  get Cell(): string { return this.getString('cell');}
  get Center(): string { return this.getString('center');}
  get CenterLeft(): string { return this.getString('centerleft');}
  get CenterRight(): string { return this.getString('centerright');}
  get ChangeCurrency(): string { return this.getString('changecurrency');}
  get ChangePassword(): string { return this.getString('changepassword');}
  get ChangeProductionConnection(): string { return this.getString('changeproductionconnection');}
  get ChangesArePermanent(): string { return this.getString('changesarepermanent');}
  get ChangeTheBorderColor(): string { return this.getString('changethebordercolor');}
  get ChangeTheBorderWidth(): string { return this.getString('changetheborderwidth');}
  get ChangeTheTextColor(): string { return this.getString('changethetextcolor');}
  get Checked(): string { return this.getString('checked');}
  get CheckBehaviorForTree(): string { return this.getString('checkbehaviorfortree');}
  get CheckedInDateTime(): string { return this.getString('checkedindatetime');}
  get CheckedOutSourceCode(): string { return this.getString('checkedoutsourcecode');}
  get CheckExpressionDependenciesForAttributes(): string { return this.getString('checkexpressiondependenciesforattributes');}
  get CheckExpressionDependenciesForDocuments(): string { return this.getString('checkexpressiondependenciesfordocuments');}
  get CheckExpressionDependenciesForGraphics(): string { return this.getString('checkexpressiondependenciesforgraphics');}
  get CheckExpressionDependenciesForPrices(): string { return this.getString('checkexpressiondependenciesforprices');}
  get CheckExpressionDependenciesForReport(): string { return this.getString('checkexpressiondependenciesforreport');}
  get CheckExpressionDependenciesForStructure(): string { return this.getString('checkexpressiondependenciesforstructure');}
  get CheckExpressionDependenciesForTemplateExpressionRule(): string { return this.getString('checkexpressiondependenciesfortemplateexpressionrule');}
  get CheckYourEmail(): string { return this.getString('checkyouremail');}
  get CheckingCompatibility(): string { return this.getString('checkingcompatibility');}
  get CheckingObjectserverCompatibility(): string { return this.getString('checkingobjectservercompatibility');}
  get CheckNodesToCalculateThePriceIfRulesIsFullfilled(): string { return this.getString('checknodestocalculatethepriceifrulesisfullfilled');}
  get Child(): string { return this.getString('child');}
  get ChildConfigurationSuccessfullyImported(): string { return this.getString('childconfigurationsuccessfullyimported');}
  get ChildConfigurationsColumnSettings(): string { return this.getString('childconfigurationscolumnsettings');}
  get ChildProduct(): string { return this.getString('childproduct');}
  get ChildUnit(): string { return this.getString('childunit');}
  get ChildRootAdjustForImageSize(): string { return this.getString('childrootadjustforimagesize');}
  get ChildRootDeltaX(): string { return this.getString('childrootdeltax');}
  get ChildSettings(): string { return this.getString('childsettings');}
  get ChoosenCurrency(): string { return this.getString('choosencurrency');}
  get ChunkSizeUsedStreamingDatabase(): string { return this.getString('chunksizeusedstreamingdatabase');}
  get City(): string { return this.getString('city');}
  get Class(): string { return this.getString('class');}
  get Clear(): string { return this.getString('clear');}
  get ClickHereForMoreInformation(): string { return this.getString('clickhereformoreinformation');}
  get Clipboard(): string { return this.getString('clipboard');}
  get CloneOfMultiChoiceValueFailedCouldNotGetAUniqueName(): string { return this.getString('cloneofmultichoicevaluefailedcouldnotgetauniquename');}
  get CloseWindows(): string { return this.getString('closewindows');}
  get CloneColumnAndItsValues(): string { return this.getString('clonecolumnanditsvalues');}
  get Cm(): string { return this.getString('cm');}
  get CodeDecoration(): string { return this.getString('codedecoration');}
  get CodeDecorationItem(): string { return this.getString('codedecorationitem');}
  get CodeDecorationHasNoCodeFileReference(): string { return this.getString('codedecorationhasnocodefilereference');}
  get CodeEngine(): string { return this.getString('codeengine');}
  get CodeEngineArguments(): string { return this.getString('codeenginearguments');}
  get CodeEngineHelp(): string { return this.getString('codeenginehelp');}
  get CodeEngineOverride(): string { return this.getString('codeengineoverride');}
  get CodeFile(): string { return this.getString('codefile');}
  get CodeFiles(): string { return this.getString('codefiles');}
  get CodeFileType(): string { return this.getString('codefiletype');}
  get CodeEngineRequired(): string { return this.getString('codeenginerequired');}
  get CodeFileTypeRequired(): string { return this.getString('codefiletyperequired');}
  get CodeFileWebServiceMethodNameXExists(): string { return this.getString('codefilewebservicemethodnamexexists');}
  get CodeItem(): string { return this.getString('codeitem');}
  get CodeItemsWithChildrenExistPreventingChangeOfIsGroupProperty(): string { return this.getString('codeitemswithchildrenexistpreventingchangeofisgroupproperty');}
  get CodeItemsWithInvalidRules(): string { return this.getString('codeitemswithinvalidrules');}
  get CodeItemType(): string { return this.getString('codeitemtype');}
  get CodeRepeater(): string { return this.getString('coderepeater');}
  get CodeValueType(): string { return this.getString('codevaluetype');}
  get CollapseAll(): string { return this.getString('collapseall');}
  get Color(): string { return this.getString('color');}
  get ColorScheme(): string { return this.getString('colorscheme');}
  get ColorMustBeASixCharactersHex(): string { return this.getString('colormustbeasixcharactershex');}
  get Column(): string { return this.getString('column');}
  get ColumnName(): string { return this.getString('columnname');}
  get ColumnNameKeyNotAllowed(): string { return this.getString('columnnamekeynotallowed');}
  get ColumnNameAlreadyExists(): string { return this.getString('columnnamealreadyexists');}
  get ColumnXNotFound(): string { return this.getString('columnxnotfound');}
  get ColumnSettings(): string { return this.getString('columnsettings');}
  get ColumnIndex(): string { return this.getString('columnindex');}
  get Columns(): string { return this.getString('columns');}
  get Combinum(): string { return this.getString('combinum');}
  get CombinumArchitect(): string { return this.getString('combinumarchitect');}
  get COMBINUMArchitectLanguage(): string { return this.getString('combinumarchitectlanguage');}
  get CombinumError(): string { return this.getString('combinumerror');}
  get CombinumExportingTranslations(): string { return this.getString('combinumexportingtranslations');}
  get CombinumImportingTranslations(): string { return this.getString('combinumimportingtranslations');}
  get CombinumInformation(): string { return this.getString('combinuminformation');}
  get CombinumQuestion(): string { return this.getString('combinumquestion');}
  get CombinumTranslationImport(): string { return this.getString('combinumtranslationimport');}
  get CombinumWarning(): string { return this.getString('combinumwarning');}
  get CommaSeparated(): string { return this.getString('commaseparated');}
  get Comments(): string { return this.getString('comments');}
  get CompareConfigurationsInSearchResult(): string { return this.getString('compareconfigurationsinsearchresult');}
  get CompareParametersSharingName(): string { return this.getString('compareparameterssharingname');}
  get CompareParametersSharingNameDesc(): string { return this.getString('compareparameterssharingnamedesc');}
  get Composite(): string { return this.getString('composite');}
  get CompoundInterest(): string { return this.getString('compoundinterest');}
  get CompositePlacement(): string { return this.getString('compositeplacement');}
  get ConfirmDraggingMove(): string { return this.getString('confirmdraggingmove');}
  get ConfirmDeleteLastLinkToRootItem(): string { return this.getString('confirmdeletelastlinktorootitem');}
  get ConfirmDeleteLastParentLinkToItemX(): string { return this.getString('confirmdeletelastparentlinktoitemx');}
  get ConsolidateChildBoms(): string { return this.getString('consolidatechildboms');}
  get Configuration(): string { return this.getString('configuration');}
  get ConfigurationAttributes(): string { return this.getString('configurationattributes');}
  get ConfigurationCard(): string { return this.getString('configurationcard');}
  get ConfigurationCardCompact(): string { return this.getString('configurationcardcompact');}
  get ConfigurationCardCompactDescription(): string { return this.getString('configurationcardcompactdescription');}
  get ConfigurationCardFirstLine(): string { return this.getString('configurationcardfirstline');}
  get ConfigurationCardFirstLineDescription(): string { return this.getString('configurationcardfirstlinedescription');}
  get ConfigurationCardSecondLine(): string { return this.getString('configurationcardsecondline');}
  get ConfigurationCardSecondLineDescription(): string { return this.getString('configurationcardsecondlinedescription');}
  get ConfigurationColumnSettings(): string { return this.getString('configurationcolumnsettings');}
  get ConfigurationIdentity(): string { return this.getString('configurationidentity');}
  get ConfigurationIdentityExpression(): string { return this.getString('configurationidentityexpression');}
  get ConfigurationIncludingChildren(): string { return this.getString('configurationincludingchildren');}
  get ConfigurationIsNotLoadedConfiguratorSessionIsNotInitialized(): string { return this.getString('configurationisnotloadedconfiguratorsessionisnotinitialized');}
  get ConfigurationLabel(): string { return this.getString('configurationlabel');}
  get ConfigurationLabelDescription(): string { return this.getString('configurationlabeldescription');}
  get ConfigurationMultisetValue(): string { return this.getString('configurationmultisetvalue');}
  get ConfigurationNameExpression(): string { return this.getString('configurationnameexpression');}
  get ConfigurationProperties(): string { return this.getString('configurationproperties');}
  get ConfigurationPropertiesAndParametersShownInSearchResult(): string { return this.getString('configurationpropertiesandparametersshowninsearchresult');}
  get ConfigurationPropertiesShownOnSummaryPage(): string { return this.getString('configurationpropertiesshownonsummarypage');}
  get ConfigurationPropertyDecoration(): string { return this.getString('configurationpropertydecoration');}
  get ConfigurationPropertyDecorationItem(): string { return this.getString('configurationpropertydecorationitem');}
  get ConfigurationRevision(): string { return this.getString('configurationrevision');}
  get Configurations(): string { return this.getString('configurations');}
  get ConfigurationsAreAboutToBeDeletedAreYouSureYouWantToContinue(): string { return this.getString('configurationsareabouttobedeletedareyousureyouwanttocontinue');}
  get ConfigurationUpload(): string { return this.getString('configurationupload');}
  get ConfigurationValue(): string { return this.getString('configurationvalue');}
  get ConfigurationValues(): string { return this.getString('configurationvalues');}
  get ConfigurationValueBool(): string { return this.getString('configurationvaluebool');}
  get ConfigurationValueDouble(): string { return this.getString('configurationvaluedouble');}
  get ConfigurationValueInt(): string { return this.getString('configurationvalueint');}
  get ConfigurationValueLookup(): string { return this.getString('configurationvaluelookup');}
  get ConfigurationValueMultiChoice(): string { return this.getString('configurationvaluemultichoice');}
  get ConfigurationValueString(): string { return this.getString('configurationvaluestring');}
  get ConfiguratorFooter(): string { return this.getString('configuratorfooter');}
  get ConfiguratorPageHasExpired(): string { return this.getString('configuratorpagehasexpired');}
  get ConfirmCancel(): string { return this.getString('confirmcancel');}
  get ConfirmClone(): string { return this.getString('confirmclone');}
  get ConfirmDeletion(): string { return this.getString('confirmdeletion');}
  get ConfirmLinkCreation(): string { return this.getString('confirmlinkcreation');}
  get ConfirmObjectDeletion(): string { return this.getString('confirmobjectdeletion');}
  get ConfirmPaste(): string { return this.getString('confirmpaste');}
  get ConfirmPasteAsLink(): string { return this.getString('confirmpasteaslink');}
  get ConfirmModificationOnSearchedResult(): string { return this.getString('confirmmodificationonsearchedresult');}
  get ConfirmMoveToTab(): string { return this.getString('confirmmovetotab');}
  get ConfirmSave(): string { return this.getString('confirmsave');}
  get ConnectingToDatabase(): string { return this.getString('connectingtodatabase');}
  get ConnectionError(): string { return this.getString('connectionerror');}
  get ConnectionInformation(): string { return this.getString('connectioninformation');}
  get ConnectionString(): string { return this.getString('connectionstring');}
  get ConnectionToTheProductionSite(): string { return this.getString('connectiontotheproductionsite');}
  get ConnectTo(): string { return this.getString('connectto');}
  get ConnectToADatabase(): string { return this.getString('connecttoadatabase');}
  get ConsistOf(): string { return this.getString('consistof');}
  get Containers(): string { return this.getString('containers');}
  get ContainmentAssemblyFile(): string { return this.getString('containmentassemblyfile');}
  get ContainmentClassPrefix(): string { return this.getString('containmentclassprefix');}
  get ContainerStyle(): string { return this.getString('containerstyle');}
  get ControlType(): string { return this.getString('controltype');}
  get ConvertNames(): string { return this.getString('convertnames');}
  get ConvertPricesToCurrency(): string { return this.getString('convertpricestocurrency');}
  get CopyAuthorizationFrom(): string { return this.getString('copyauthorizationfrom');}
  get CopyLinkInformation(): string { return this.getString('copylinkinformation');}
  get Copyright(): string { return this.getString('copyright');}
  get CopyRow(): string { return this.getString('copyrow');}
  get CopyrightUnauthorizedReproductionText(): string { return this.getString('copyrightunauthorizedreproductiontext');}
  get CopyTheSelectedItemsToTheClipboard(): string { return this.getString('copytheselecteditemstotheclipboard');}
  get CopyTheTranslationsInAllLanguagesFromTheKeyText(): string { return this.getString('copythetranslationsinalllanguagesfromthekeytext');}
  get CopyToClipboard(): string { return this.getString('copytoclipboard');}
  get CopyTranslationsTo(): string { return this.getString('copytranslationsto');}
  get CouldNotCloneAttributeIsNotUnique(): string { return this.getString('couldnotcloneattributeisnotunique');}
  get CouldNotCloneDictionaryIsNotUnique(): string { return this.getString('couldnotclonedictionaryisnotunique');}
  get CouldNotCloneCalcColumnIsNotUnique(): string { return this.getString('couldnotclonecalccolumnisnotunique');}
  get CouldNotCloneObjectIsNotUnique(): string { return this.getString('couldnotcloneobjectisnotunique');}
  get CouldNotCloneParameterNameIsNotUnique(): string { return this.getString('couldnotcloneparameternameisnotunique');}
  get CouldNotCloneTheLookupValueBecauseAUniqueNameCouldNotBeCreated(): string { return this.getString('couldnotclonethelookupvaluebecauseauniquenamecouldnotbecreated');}
  get CouldNotLinkSymbolXToGraphicsOfProductY(): string { return this.getString('couldnotlinksymbolxtographicsofproducty');}
  get Country(): string { return this.getString('country');}
  get CreateRepeater(): string { return this.getString('createrepeater');}
  get CreateIndexedInstances(): string { return this.getString('createindexedinstances');}
  get CreatesConnectionToMicrosoftSQLServer(): string { return this.getString('createsconnectiontomicrosoftsqlserver');}
  get CreatesConnectionToOracleServer(): string { return this.getString('createsconnectiontooracleserver');}
  get CustomerManagement(): string { return this.getString('customermanagement');}
  get Currency(): string { return this.getString('currency');}
  get CurrencyFactor(): string { return this.getString('currencyfactor');}
  get CurrentDestinationObjectId(): string { return this.getString('currentdestinationobjectid');}
  get CurrentRowNumber(): string { return this.getString('currentrownumber');}
  get CurrentSourceObjectId(): string { return this.getString('currentsourceobjectid');}
  get CurrentStoredAsPopup(): string { return this.getString('currentstoredaspopup');}
  get CurrentTable(): string { return this.getString('currenttable');}
  get CurrentVersion(): string { return this.getString('currentversion');}
  get Custom(): string { return this.getString('custom');}
  get CustomColorScheme(): string { return this.getString('customcolorscheme');}
  get CustomColorSchemeName(): string { return this.getString('customcolorschemename');}
  get Customer(): string { return this.getString('customer');}
  get Customers(): string { return this.getString('customers');}
  get CustomerContact(): string { return this.getString('customercontact');}
  get CustomerLinkedToActiveUser(): string { return this.getString('customerlinkedtoactiveuser');}
  get CustomerUsersLicenseRestriction(): string { return this.getString('customeruserslicenserestriction');}
  get CustomerNo(): string { return this.getString('customerno');}
  get CustomerReference(): string { return this.getString('customerreference');}
  get CustomerSite(): string { return this.getString('customersite');}
  get DarkBlue(): string { return this.getString('darkblue');}
  get DarkRed(): string { return this.getString('darkred');}
  get DarkYellow(): string { return this.getString('darkyellow');}
  get Data(): string { return this.getString('data');}
  get DatabaseConnection(): string { return this.getString('databaseconnection');}
  get DatabaseVersion(): string { return this.getString('databaseversion');}
  get DatabaseUpgrade(): string { return this.getString('databaseupgrade');}
  get DatabaseUpgradeError(): string { return this.getString('databaseupgradeerror');}
  get DataSelectorXAlreadyExists(): string { return this.getString('dataselectorxalreadyexists');}
  get DataSelectAction(): string { return this.getString('dataselectaction');}
  get DataSelectCalcColumn(): string { return this.getString('dataselectcalccolumn');}
  get DataSelectColumn(): string { return this.getString('dataselectcolumn');}
  get DataSelectColumnNameXAlreadyExists(): string { return this.getString('dataselectcolumnnamexalreadyexists');}
  get DataSelectFilter(): string { return this.getString('dataselectfilter');}
  get DataSelectorFilterControlsVisible(): string { return this.getString('dataselectorfiltercontrolsvisible');}
  get DataSelectorFilterControlsVisibleDesc(): string { return this.getString('dataselectorfiltercontrolsvisibledesc');}
  get DataSelectSorter(): string { return this.getString('dataselectsorter');}
  get DataSource(): string { return this.getString('datasource');}
  get DateFormat(): string { return this.getString('dateformat');}
  get DateTimeFormat(): string { return this.getString('datetimeformat');}
  get Decibel(): string { return this.getString('decibel');}
  get DecimalSymbol(): string { return this.getString('decimalsymbol');}
  get Decoration(): string { return this.getString('decoration');}
  get Decorations(): string { return this.getString('decorations');}
  get DecreaseIndent(): string { return this.getString('decreaseindent');}
  get Default(): string { return this.getString('default');}
  get DefaultCellMarginTopCm(): string { return this.getString('defaultcellmargintopcm');}
  get DefaultCellMarginBottomCm(): string { return this.getString('defaultcellmarginbottomcm');}
  get DefaultCellMarginLeftCm(): string { return this.getString('defaultcellmarginleftcm');}
  get DefaultCellMarginRightCm(): string { return this.getString('defaultcellmarginrightcm');}
  get DefaultMandatoryState(): string { return this.getString('defaultmandatorystate');}
  get DefaultPriceListCategory(): string { return this.getString('defaultpricelistcategory');}
  get DefaultPriceListCategoryNeedsAuthorization(): string { return this.getString('defaultpricelistcategoryneedsauthorization');}
  get DefaultState(): string { return this.getString('defaultstate');}
  get DefaultStateFromWhichMandatoryParametersAreApplied(): string { return this.getString('defaultstatefromwhichmandatoryparametersareapplied');}
  get DefaultTabStops(): string { return this.getString('defaulttabstops');}
  get DefaultValue(): string { return this.getString('defaultvalue');}
  get DefaultValueIsGreaterThanMaxValue(): string { return this.getString('defaultvalueisgreaterthanmaxvalue');}
  get DefaultValueIsLesserThanMinValue(): string { return this.getString('defaultvalueislesserthanminvalue');}
  get DeleteAllNotUsedKeyTexts(): string { return this.getString('deleteallnotusedkeytexts');}
  get DeleteLink(): string { return this.getString('deletelink');}
  get DeleteLinks(): string { return this.getString('deletelinks');}
  get DeleteRepeater(): string { return this.getString('deleterepeater');}
  get DeliverySite(): string { return this.getString('deliverysite');}
  get DeliveryTerms(): string { return this.getString('deliveryterms');}
  get RemoveRow(): string { return this.getString('removerow');}
  get DeltaX(): string { return this.getString('deltax');}
  get DeltaXIsNotAValidInteger(): string { return this.getString('deltaxisnotavalidinteger');}
  get DeltaY(): string { return this.getString('deltay');}
  get DeltaYIsNotAValidInteger(): string { return this.getString('deltayisnotavalidinteger');}
  get DescendingOrder(): string { return this.getString('descendingorder');}
  get Destination(): string { return this.getString('destination');}
  get DestinationObjectAlreadyExists(): string { return this.getString('destinationobjectalreadyexists');}
  get DestinationPriceItemProductsAreMissingSomeParameters(): string { return this.getString('destinationpriceitemproductsaremissingsomeparameters');}
  get DestinationPriceListsProductAreMissingParameters(): string { return this.getString('destinationpricelistsproductaremissingparameters');}
  get DestinationProductAlreadyExistsOnThisLevel(): string { return this.getString('destinationproductalreadyexistsonthislevel');}
  get DestinationProductHasMissingParametersInRule(): string { return this.getString('destinationproducthasmissingparametersinrule');}
  get DestinationTabDoesNotBelongToProduct(): string { return this.getString('destinationtabdoesnotbelongtoproduct');}
  get DetailArea(): string { return this.getString('detailarea');}
  get DetailBoxes(): string { return this.getString('detailboxes');}
  get DetailBoxesDescription(): string { return this.getString('detailboxesdescription');}
  get DetailedBOMColumnSettings(): string { return this.getString('detailedbomcolumnsettings');}
  get DetailsOnly(): string { return this.getString('detailsonly');}
  get Development(): string { return this.getString('development');}
  get DevelopmentMode(): string { return this.getString('developmentmode');}
  get DevelopmentModeDesc(): string { return this.getString('developmentmodedesc');}
  get DirectoryURL(): string { return this.getString('directoryurl');}
  get DirectoryNameXAlreadyExists(): string { return this.getString('directorynamexalreadyexists');}
  get Dictionary(): string { return this.getString('dictionary');}
  get DictionaryKeyXAlreadyExists(): string { return this.getString('dictionarykeyxalreadyexists');}
  get Dictionaries(): string { return this.getString('dictionaries');}
  get DictionaryEntry(): string { return this.getString('dictionaryentry');}
  get DictionaryColumn(): string { return this.getString('dictionarycolumn');}
  get DictionaryKey(): string { return this.getString('dictionarykey');}
  get DictionarySelectionCanNotBeEmpty(): string { return this.getString('dictionaryselectioncannotbeempty');}
  get DictionaryValueInvalidData(): string { return this.getString('dictionaryvalueinvaliddata');}
  get DictionaryValue(): string { return this.getString('dictionaryvalue');}
  get DisableRevisionManagement(): string { return this.getString('disablerevisionmanagement');}
  get DisableRevisionManagementOneRevisionAllowed(): string { return this.getString('disablerevisionmanagementonerevisionallowed');}
  get DisallowedCharactersXYWasFound(): string { return this.getString('disallowedcharactersxywasfound');}
  get DisallowedCharacterXWasFound(): string { return this.getString('disallowedcharacterxwasfound');}
  get DisallowedCharactersXWereFound(): string { return this.getString('disallowedcharactersxwerefound');}
  get DisallowedCombinationRule(): string { return this.getString('disallowedcombinationrule');}
  get DiscardChanges(): string { return this.getString('discardchanges');}
  get DisplayAsRadio(): string { return this.getString('displayasradio');}
  get DisplayOrder(): string { return this.getString('displayorder');}
  get DisplayAsRadioButtons(): string { return this.getString('displayasradiobuttons');}
  get DisplayDocumentsInOperationsColumn(): string { return this.getString('displaydocumentsinoperationscolumn');}
  get DisplayStyle(): string { return this.getString('displaystyle');}
  get DisplayStyles(): string { return this.getString('displaystyles');}
  get DistributeAdjustsCharacterSpacing(): string { return this.getString('distributeadjustscharacterspacing');}
  get DocxToPdfConversionTimeOut(): string { return this.getString('docxtopdfconversiontimeout');}
  get DocxToPdfConversionTimeOutDesc(): string { return this.getString('docxtopdfconversiontimeoutdesc');}
  get DocxToPdfConversionUrl(): string { return this.getString('docxtopdfconversionurl');}
  get DocxToPdfConversionUrlDesc(): string { return this.getString('docxtopdfconversionurldesc');}
  get DocxToPdfDualLinks(): string { return this.getString('docxtopdfduallinks');}
  get DocxToPdfDualLinksDesc(): string { return this.getString('docxtopdfduallinksdesc');}
  get Document(): string { return this.getString('document');}
  get DocumentLinks(): string { return this.getString('documentlinks');}
  get DocumentsDecoration(): string { return this.getString('documentsdecoration');}
  get DocumentsDecorationItem(): string { return this.getString('documentsdecorationitem');}
  get DocumentsDecorationItemDoesNotHaveADocumentReference(): string { return this.getString('documentsdecorationitemdoesnothaveadocumentreference');}
  get DoubleParameter(): string { return this.getString('doubleparameter');}
  get DoubleValue(): string { return this.getString('doublevalue');}
  get Down(): string { return this.getString('down');}
  get DownloadBinaryData(): string { return this.getString('downloadbinarydata');}
  get DoYouWantToAddItAnyway(): string { return this.getString('doyouwanttoadditanyway');}
  get DoYouWantToContinue(): string { return this.getString('doyouwanttocontinue');}
  get DoYouWantToDeleteTheRepeater(): string { return this.getString('doyouwanttodeletetherepeater');}
  get DoYouWantToDeleteLinksToDynamicInstances(): string { return this.getString('doyouwanttodeletelinkstodynamicinstances');}
  get DoYouWantToDeleteLinksToNumberSeries(): string { return this.getString('doyouwanttodeletelinkstonumberseries');}
  get DoYouWantToPushUrgentMessage(): string { return this.getString('doyouwanttopushurgentmessage');}
  get DoYouWantToRemoveCalculateStop(): string { return this.getString('doyouwanttoremovecalculatestop');}
  get DoYouWantToSetCalculateStop(): string { return this.getString('doyouwanttosetcalculatestop');}
  get DoYouWantToSaveAndClose(): string { return this.getString('doyouwanttosaveandclose');}
  get DXFLineWeightRatio(): string { return this.getString('dxflineweightratio');}
  get DXFLineWeightRatioInHundredsOfAnMmPerPixel(): string { return this.getString('dxflineweightratioinhundredsofanmmperpixel');}
  get DynamicInstance(): string { return this.getString('dynamicinstance');}
  get DynamicInstances(): string { return this.getString('dynamicinstances');}
  get EditingRestrictions(): string { return this.getString('editingrestrictions');}
  get EditorsValueIsNotValid(): string { return this.getString('editorsvalueisnotvalid');}
  get EffectiveFrom(): string { return this.getString('effectivefrom');}
  get EffectiveUntil(): string { return this.getString('effectiveuntil');}
  get EffectivityPeriod(): string { return this.getString('effectivityperiod');}
  get EffectivityMsgWhenChangingIntermediateLevel(): string { return this.getString('effectivitymsgwhenchangingintermediatelevel');}
  get EffectivityMsgWhenChangingLeafLevel(): string { return this.getString('effectivitymsgwhenchangingleaflevel');}
  get EmailAccessLink(): string { return this.getString('emailaccesslink');}
  get EmailAddress(): string { return this.getString('emailaddress');}
  get EmailAddressAssociatedToThisUserNotValid(): string { return this.getString('emailaddressassociatedtothisusernotvalid');}
  get EmailAccessLinkDescription(): string { return this.getString('emailaccesslinkdescription');}
  get EmailVerificationBodyText(): string { return this.getString('emailverificationbodytext');}
  get EmailVerificationBodyTextDescription(): string { return this.getString('emailverificationbodytextdescription');}
  get EmailVerificationSubjectText(): string { return this.getString('emailverificationsubjecttext');}
  get EmailVerificationSubjectTextDescription(): string { return this.getString('emailverificationsubjecttextdescription');}
  get EmailOrUsernameEmptyMessage(): string { return this.getString('emailorusernameemptymessage');}
  get EmptyKeyIsNotAllowedAsIdentifier(): string { return this.getString('emptykeyisnotallowedasidentifier');}
  get EmptySelectedValueHelpImageHeightNotAllowed(): string { return this.getString('emptyselectedvaluehelpimageheightnotallowed');}
  get EmptySelectedValueHelpImageWidthNotAllowed(): string { return this.getString('emptyselectedvaluehelpimagewidthnotallowed');}
  get EmptyNameIsNotAllowedAsIdentifier(): string { return this.getString('emptynameisnotallowedasidentifier');}
  get EmptyColumnTypeIsNotAllowed(): string { return this.getString('emptycolumntypeisnotallowed');}
  get EmptyValueIsNotAllowed(): string { return this.getString('emptyvalueisnotallowed');}
  get EmptyStringIsNotAllowedWhenValueRequired(): string { return this.getString('emptystringisnotallowedwhenvaluerequired');}
  get EnableAccountCreation(): string { return this.getString('enableaccountcreation');}
  get EnableAnonymousAccessFromLoginPage(): string { return this.getString('enableanonymousaccessfromloginpage');}
  get EnableAccountCreationDescription(): string { return this.getString('enableaccountcreationdescription');}
  get EnableCompareConfigurations(): string { return this.getString('enablecompareconfigurations');}
  get EnableExpression(): string { return this.getString('enableexpression');}
  get EnableInternetExplorerSupport(): string { return this.getString('enableinternetexplorersupport');}
  get EnableNamingForParameterValues(): string { return this.getString('enablenamingforparametervalues');}
  get EnableNamingForParameterValuesDescription(): string { return this.getString('enablenamingforparametervaluesdescription');}
  get EnablePasswordRecovery(): string { return this.getString('enablepasswordrecovery');}
  get EnablePasswordRecoveryDescription(): string { return this.getString('enablepasswordrecoverydescription');}
  get EnableReplacementControl(): string { return this.getString('enablereplacementcontrol');}
  get EnableReplacementControlDescription(): string { return this.getString('enablereplacementcontroldescription');}
  get EnableSSL(): string { return this.getString('enablessl');}
  get EnableSSLDescription(): string { return this.getString('enablessldescription');}
  get EnableUserToAdjustTheStructureItemsDecorationResult(): string { return this.getString('enableusertoadjustthestructureitemsdecorationresult');}
  get EndCircleRadius(): string { return this.getString('endcircleradius');}
  get EndCircleX(): string { return this.getString('endcirclex');}
  get EndCircleY(): string { return this.getString('endcircley');}
  get EqualTo(): string { return this.getString('equalto');}
  get Error(): string { return this.getString('error');}
  get ErrorCalculatingNextBitMaskPositionForTheState(): string { return this.getString('errorcalculatingnextbitmaskpositionforthestate');}
  get ErrorClass(): string { return this.getString('errorclass');}
  get ErrorDuringTheTranslationImport(): string { return this.getString('errorduringthetranslationimport');}
  get ErrorEditLimit(): string { return this.getString('erroreditlimit');}
  get ErrorExpressionForXIsNotValid(): string { return this.getString('errorexpressionforxisnotvalid');}
  get ErrorInEditorFor(): string { return this.getString('errorineditorfor');}
  get ErrorInitializingRootObject(): string { return this.getString('errorinitializingrootobject');}
  get ErrorInValue(): string { return this.getString('errorinvalue');}
  get ErrorMinValueIsGreatherThanMaxValue(): string { return this.getString('errorminvalueisgreatherthanmaxvalue');}
  get ErrorObjectXYNotFound(): string { return this.getString('errorobjectxynotfound');}
  get ErrorObjectXNotFoundForY(): string { return this.getString('errorobjectxnotfoundfory');}
  get ErrorRecordLimit(): string { return this.getString('errorrecordlimit');}
  get ErrorValidatingPassword(): string { return this.getString('errorvalidatingpassword');}
  get ErrorXValueRequired(): string { return this.getString('errorxvaluerequired');}
  get ErrorWhenPrinting(): string { return this.getString('errorwhenprinting');}
  get ErrorWhileFetchingDimensionsOfImage(): string { return this.getString('errorwhilefetchingdimensionsofimage');}
  get ErrorWith(): string { return this.getString('errorwith');}
  get EternalLoopDetected(): string { return this.getString('eternalloopdetected');}
  get ExcelLanguageTranslationExportFinishedSuccessful(): string { return this.getString('excellanguagetranslationexportfinishedsuccessful');}
  get Exception(): string { return this.getString('exception');}
  get ExceptionNoEditorRegisteredForType(): string { return this.getString('exceptionnoeditorregisteredfortype');}
  get ExceptionNoObjectServerFound(): string { return this.getString('exceptionnoobjectserverfound');}
  get ExceptionUnexpectedIndexForThemeColor(): string { return this.getString('exceptionunexpectedindexforthemecolor');}
  get ExceptionUnexpectedThemeColor(): string { return this.getString('exceptionunexpectedthemecolor');}
  get ExceptionWhenInitializing(): string { return this.getString('exceptionwheninitializing');}
  get ExceptionWhileReadingFromDatabase(): string { return this.getString('exceptionwhilereadingfromdatabase');}
  get ExcludeGraphicsElementFillInDXF(): string { return this.getString('excludegraphicselementfillindxf');}
  get ExcludeNewMandatoryParametersWhenPromoting(): string { return this.getString('excludenewmandatoryparameterswhenpromoting');}
  get ExecutionTime(): string { return this.getString('executiontime');}
  get Exit(): string { return this.getString('exit');}
  get ExecuteAllSatisfiedRows(): string { return this.getString('executeallsatisfiedrows');}
  get ExpandAll(): string { return this.getString('expandall');}
  get ExportingLanguageTranslationsToExcel(): string { return this.getString('exportinglanguagetranslationstoexcel');}
  get ExportTranslationsToAnExcelfile(): string { return this.getString('exporttranslationstoanexcelfile');}
  get ExposeThroughWebService(): string { return this.getString('exposethroughwebservice');}
  get ExpressionXinFieldYLocatedInZobjectOfSymbolZZ(): string { return this.getString('expressionxinfieldylocatedinzobjectofsymbolzz');}
  get ExpressionsForPriceItemWasNotClonedDueToMissingParametersInTheDestinationProduct(): string { return this.getString('expressionsforpriceitemwasnotclonedduetomissingparametersinthedestinationproduct');}
  get ExpressionValueTypeIsIncorrectShouldBeOneOfTheTypes(): string { return this.getString('expressionvaluetypeisincorrectshouldbeoneofthetypes');}
  get ExternalLink(): string { return this.getString('externallink');}
  get Factor(): string { return this.getString('factor');}
  get FailedToPublish(): string { return this.getString('failedtopublish');}
  get FailedToSetLookupParameterXSinceTheValueYIsNotDefined(): string { return this.getString('failedtosetlookupparameterxsincethevalueyisnotdefined');}
  get false(): string { return this.getString('false');}
  get FieldCodes(): string { return this.getString('fieldcodes');}
  get FieldType(): string { return this.getString('fieldtype');}
  get File(): string { return this.getString('file');}
  get Files(): string { return this.getString('files');}
  get FileService(): string { return this.getString('fileservice');}
  get FileDoesNotExist(): string { return this.getString('filedoesnotexist');}
  get FileExplorer(): string { return this.getString('fileexplorer');}
  get FileExtension(): string { return this.getString('fileextension');}
  get FileName(): string { return this.getString('filename');}
  get FileNameXAlreadyExists(): string { return this.getString('filenamexalreadyexists');}
  get FileNameExpression(): string { return this.getString('filenameexpression');}
  get FileNameExpressionIsNotValid(): string { return this.getString('filenameexpressionisnotvalid');}
  get FileNotFoundInVaultDocumentView(): string { return this.getString('filenotfoundinvaultdocumentview');}
  get FillingInForms(): string { return this.getString('fillinginforms');}
  get FinalCurrencyOnly(): string { return this.getString('finalcurrencyonly');}
  get FirstCharacterMustBeALetterOrAnUnderscore(): string { return this.getString('firstcharactermustbealetteroranunderscore');}
  get FirstChildRootX(): string { return this.getString('firstchildrootx');}
  get FirstColumn(): string { return this.getString('firstcolumn');}
  get FirstServed(): string { return this.getString('firstserved');}
  get FloatingPointPrecision(): string { return this.getString('floatingpointprecision');}
  get FloatingPointPrecisionForAdditionSubtraction(): string { return this.getString('floatingpointprecisionforadditionsubtraction');}
  get FloatingPointPrecisionForBasePrice(): string { return this.getString('floatingpointprecisionforbaseprice');}
  get FloatingPointPrecisionForChildConfigurations(): string { return this.getString('floatingpointprecisionforchildconfigurations');}
  get FloatingPointPrecisionForCosts(): string { return this.getString('floatingpointprecisionforcosts');}
  get FloatingPointPrecisionForMultiplicationDivision(): string { return this.getString('floatingpointprecisionformultiplicationdivision');}
  get FloatingPointPrecisionForPriceCategories(): string { return this.getString('floatingpointprecisionforpricecategories');}
  get FloatingPointPrecisionForTotalCosts(): string { return this.getString('floatingpointprecisionfortotalcosts');}
  get FloatingPointPrecisionForTotalPrices(): string { return this.getString('floatingpointprecisionfortotalprices');}
  get FloatingPointPrecisionInTheSystem(): string { return this.getString('floatingpointprecisioninthesystem');}
  get FloatingPosition(): string { return this.getString('floatingposition');}
  get FlowAction(): string { return this.getString('flowaction');}
  get FlowActionType(): string { return this.getString('flowactiontype');}
  get FlowTrigger(): string { return this.getString('flowtrigger');}
  get FlowTriggerType(): string { return this.getString('flowtriggertype');}
  get FocalPointX(): string { return this.getString('focalpointx');}
  get FocalPointY(): string { return this.getString('focalpointy');}
  get FollowedHyperlink(): string { return this.getString('followedhyperlink');}
  get Font(): string { return this.getString('font');}
  get FontSize(): string { return this.getString('fontsize');}
  get FooterType(): string { return this.getString('footertype');}
  get Forced(): string { return this.getString('forced');}
  get ForcedTooltip(): string { return this.getString('forcedtooltip');}
  get ForcedTemplateValues(): string { return this.getString('forcedtemplatevalues');}
  get FormControl(): string { return this.getString('formcontrol');}
  get FormDecoration(): string { return this.getString('formdecoration');}
  get Format(): string { return this.getString('format');}
  get Formula(): string { return this.getString('formula');}
  get FullAccess(): string { return this.getString('fullaccess');}
  get General(): string { return this.getString('general');}
  get GiveUpOwnership(): string { return this.getString('giveupownership');}
  get GlobalData(): string { return this.getString('globaldata');}
  get GlobalForm(): string { return this.getString('globalform');}
  get GraceUntil(): string { return this.getString('graceuntil');}
  get Gradient(): string { return this.getString('gradient');}
  get GradientType(): string { return this.getString('gradienttype');}
  get GrandGrandParent(): string { return this.getString('grandgrandparent');}
  get GrandParent(): string { return this.getString('grandparent');}
  get GraphicsDecoration(): string { return this.getString('graphicsdecoration');}
  get GraphicsDisplayStyle(): string { return this.getString('graphicsdisplaystyle');}
  get GraphicsFilter(): string { return this.getString('graphicsfilter');}
  get GraphicMode(): string { return this.getString('graphicmode');}
  get GraphicModeInDecoration(): string { return this.getString('graphicmodeindecoration');}
  get GraphicType(): string { return this.getString('graphictype');}
  get GraphicTypeCannotBeChangedForThisProduct(): string { return this.getString('graphictypecannotbechangedforthisproduct');}
  get GraphicTypeId(): string { return this.getString('graphictypeid');}
  get Gray25Percent(): string { return this.getString('gray25percent');}
  get Gray50Percent(): string { return this.getString('gray50percent');}
  get Green(): string { return this.getString('green');}
  get GroupCategory(): string { return this.getString('groupcategory');}
  get Grouping(): string { return this.getString('grouping');}
  get GroupingOfStructureDecorations(): string { return this.getString('groupingofstructuredecorations');}
  get GroupingOfAttributeDecorations(): string { return this.getString('groupingofattributedecorations');}
  get GroupingOfCodeDecorations(): string { return this.getString('groupingofcodedecorations');}
  get GroupingOfDocumentsDecorations(): string { return this.getString('groupingofdocumentsdecorations');}
  get GroupingOfGraphicsDecorations(): string { return this.getString('groupingofgraphicsdecorations');}
  get GroupingOfImageDecorations(): string { return this.getString('groupingofimagedecorations');}
  get GroupingOfStructureDecorationsDesc(): string { return this.getString('groupingofstructuredecorationsdesc');}
  get GroupingOfAttributeDecorationsDesc(): string { return this.getString('groupingofattributedecorationsdesc');}
  get GroupingOfCodeDecorationsDesc(): string { return this.getString('groupingofcodedecorationsdesc');}
  get GroupingOfDocumentsDecorationsDesc(): string { return this.getString('groupingofdocumentsdecorationsdesc');}
  get GroupingOfGraphicsDecorationsDesc(): string { return this.getString('groupingofgraphicsdecorationsdesc');}
  get GroupingOfImageDecorationsDesc(): string { return this.getString('groupingofimagedecorationsdesc');}
  get GroupingOfVisualizationDecorations(): string { return this.getString('groupingofvisualizationdecorations');}
  get GroupingOfVisualizationDecorationsDesc(): string { return this.getString('groupingofvisualizationdecorationsdesc');}
  get GroupDecorationsOfThisType(): string { return this.getString('groupdecorationsofthistype');}
  get GroupProductsByFamilyInFilterControl(): string { return this.getString('groupproductsbyfamilyinfiltercontrol');}
  get GroupProductsByFamilyInFilterControlDescription(): string { return this.getString('groupproductsbyfamilyinfiltercontroldescription');}
  get DisplayIndividuallyBelowItsTab(): string { return this.getString('displayindividuallybelowitstab');}
  get HalfPixelTranslate(): string { return this.getString('halfpixeltranslate');}
  get HalfPixelTranslateDescription(): string { return this.getString('halfpixeltranslatedescription');}
  get Hanging(): string { return this.getString('hanging');}
  get HandleTitle(): string { return this.getString('handletitle');}
  get HangingPosition(): string { return this.getString('hangingposition');}
  get HasCodeValueType(): string { return this.getString('hascodevaluetype');}
  get HasTitleExpression(): string { return this.getString('hastitleexpression');}
  get HasValueExpression(): string { return this.getString('hasvalueexpression');}
  get HeaderDecoration(): string { return this.getString('headerdecoration');}
  get HeaderRow(): string { return this.getString('headerrow');}
  get HeaderType(): string { return this.getString('headertype');}
  get Height(): string { return this.getString('height');}
  get HeightCm(): string { return this.getString('heightcm');}
  get HeightIsAtLeast(): string { return this.getString('heightisatleast');}
  get HeightIsNotAValidDouble(): string { return this.getString('heightisnotavaliddouble');}
  get HeightIsNotAValidInteger(): string { return this.getString('heightisnotavalidinteger');}
  get Help(): string { return this.getString('help');}
  get HideAttributesWithEmptyValues(): string { return this.getString('hideattributeswithemptyvalues');}
  get Hidden(): string { return this.getString('hidden');}
  get HideEmptyDecoration(): string { return this.getString('hideemptydecoration');}
  get HideGrandTotalInNonCompositeMode(): string { return this.getString('hidegrandtotalinnoncompositemode');}
  get HideGraphicsControls(): string { return this.getString('hidegraphicscontrols');}
  get HideIfDisabled(): string { return this.getString('hideifdisabled');}
  get HideIfEmpty(): string { return this.getString('hideifempty');}
  get HideNavigationPanel(): string { return this.getString('hidenavigationpanel');}
  get HideQuantityPropertyForRootConfigurations(): string { return this.getString('hidequantitypropertyforrootconfigurations');}
  get HideQuantityPropertyForRootConfigurationsDescription(): string { return this.getString('hidequantitypropertyforrootconfigurationsdescription');}
  get HideValuesIfDisabled(): string { return this.getString('hidevaluesifdisabled');}
  get HighestPrio(): string { return this.getString('highestprio');}
  get HighlightIfMandatoryPopupIsShown(): string { return this.getString('highlightifmandatorypopupisshown');}
  get HighlightSelectedConfiguration(): string { return this.getString('highlightselectedconfiguration');}
  get HighlightSelectedGraphic(): string { return this.getString('highlightselectedgraphic');}
  get Horizontal(): string { return this.getString('horizontal');}
  get HorizontalWithIcons(): string { return this.getString('horizontalwithicons');}
  get HorizontalWithSelectedHelpImageAbove(): string { return this.getString('horizontalwithselectedhelpimageabove');}
  get HostNameSMTPServer(): string { return this.getString('hostnamesmtpserver');}
  get HowAutomaticNameIsGenerated(): string { return this.getString('howautomaticnameisgenerated');}
  get Hyperlink(): string { return this.getString('hyperlink');}
  get Icon(): string { return this.getString('icon');}
  get IconManager(): string { return this.getString('iconmanager');}
  get Icons(): string { return this.getString('icons');}
  get IconTypes(): string { return this.getString('icontypes');}
  get IconTypeXContainsIconsConfirmRemove(): string { return this.getString('icontypexcontainsiconsconfirmremove');}
  get IdentityExpression(): string { return this.getString('identityexpression');}
  get IfAllowed(): string { return this.getString('ifallowed');}
  get IfAllowedTooltip(): string { return this.getString('ifallowedtooltip');}
  get IfBlank(): string { return this.getString('ifblank');}
  get IfBlankTooltip(): string { return this.getString('ifblanktooltip');}
  get IfNotItWillBeTheIconForTheParameter(): string { return this.getString('ifnotitwillbetheiconfortheparameter');}
  get Image(): string { return this.getString('image');}
  get Images(): string { return this.getString('images');}
  get ImageFolder(): string { return this.getString('imagefolder');}
  get ImageDecoration(): string { return this.getString('imagedecoration');}
  get Import(): string { return this.getString('import');}
  get Imported(): string { return this.getString('imported');}
  get ImportingData(): string { return this.getString('importingdata');}
  get ImportingLanguageTranslationsFromExcel(): string { return this.getString('importinglanguagetranslationsfromexcel');}
  get ImportStyleXml(): string { return this.getString('importstylexml');}
  get ImportTranslationsFromAnExcelfile(): string { return this.getString('importtranslationsfromanexcelfile');}
  get IncludeChildren(): string { return this.getString('includechildren');}
  get IncludePreviousRevisions(): string { return this.getString('includepreviousrevisions');}
  get InclusionExpression(): string { return this.getString('inclusionexpression');}
  get InclusionExpressionIsNotValid(): string { return this.getString('inclusionexpressionisnotvalid');}
  get IncompatibleDictionaryKeys(): string { return this.getString('incompatibledictionarykeys');}
  get IncreaseIndent(): string { return this.getString('increaseindent');}
  get Indeterminate(): string { return this.getString('indeterminate');}
  get InfoAndValidation(): string { return this.getString('infoandvalidation');}
  get InitialOffsetX(): string { return this.getString('initialoffsetx');}
  get InitialOffsetY(): string { return this.getString('initialoffsety');}
  get InitialOrigoPosition(): string { return this.getString('initialorigoposition');}
  get InitializingObjects(): string { return this.getString('initializingobjects');}
  get InitialZoomFactor(): string { return this.getString('initialzoomfactor');}
  get InitialZoomFactorInGraphicViewer(): string { return this.getString('initialzoomfactoringraphicviewer');}
  get InitializationMethod(): string { return this.getString('initializationmethod');}
  get InstallNewLicense(): string { return this.getString('installnewlicense');}
  get InsertRow(): string { return this.getString('insertrow');}
  get IntegerParameter(): string { return this.getString('integerparameter');}
  get InvalidRequest(): string { return this.getString('invalidrequest');}
  get InvalidPriceListCategoryNameXValueY(): string { return this.getString('invalidpricelistcategorynamexvaluey');}
  get IntegerValue(): string { return this.getString('integervalue');}
  get InternetExplorerIsNotSupportedDescription(): string { return this.getString('internetexplorerisnotsupporteddescription');}
  get InvalidStatesImportData(): string { return this.getString('invalidstatesimportdata');}
  get IsExpressionFunction(): string { return this.getString('isexpressionfunction');}
  get IsAdvanced(): string { return this.getString('isadvanced');}
  get IsCustom(): string { return this.getString('iscustom');}
  get IsGroupHasChildren(): string { return this.getString('isgrouphaschildren');}
  get IsGroup(): string { return this.getString('isgroup');}
  get IsWelcomeMessage(): string { return this.getString('iswelcomemessage');}
  get IsWarning(): string { return this.getString('iswarning');}
  get IsTemplate(): string { return this.getString('istemplate');}
  get Italic(): string { return this.getString('italic');}
  get ItalicizeTheText(): string { return this.getString('italicizethetext');}
  get ItemBehavior(): string { return this.getString('itembehavior');}
  get Items(): string { return this.getString('items');}
  get ItWontCopy(): string { return this.getString('itwontcopy');}
  get ItWontDelete(): string { return this.getString('itwontdelete');}
  get JobTitle(): string { return this.getString('jobtitle');}
  get Justification(): string { return this.getString('justification');}
  get KeepLinesTogether(): string { return this.getString('keeplinestogether');}
  get KeepStateOnRevise(): string { return this.getString('keepstateonrevise');}
  get KeepStateWhenAConfigurationIsRevised(): string { return this.getString('keepstatewhenaconfigurationisrevised');}
  get KeepWithNext(): string { return this.getString('keepwithnext');}
  get Key(): string { return this.getString('key');}
  get KeyText(): string { return this.getString('keytext');}
  get KeyTextId(): string { return this.getString('keytextid');}
  get KeyTextsNotFound(): string { return this.getString('keytextsnotfound');}
  get KeyTextsWereSuccssefullyImportedFor(): string { return this.getString('keytextsweresuccssefullyimportedfor');}
  get KeyTitle(): string { return this.getString('keytitle');}
  get KeyDataType(): string { return this.getString('keydatatype');}
  get KeyXExistsInDictionary(): string { return this.getString('keyxexistsindictionary');}
  get LandscapeOrientation(): string { return this.getString('landscapeorientation');}
  get LanguageAlreadyExist(): string { return this.getString('languagealreadyexist');}
  get LanguageCode(): string { return this.getString('languagecode');}
  get LanguageCodeAlreadyExist(): string { return this.getString('languagecodealreadyexist');}
  get LanguageCodeIsEmpty(): string { return this.getString('languagecodeisempty');}
  get LanguageHaveReferencingLanguageCodes(): string { return this.getString('languagehavereferencinglanguagecodes');}
  get LanguageUserInterfacePresented(): string { return this.getString('languageuserinterfacepresented');}
  get LanguagesNotFound(): string { return this.getString('languagesnotfound');}
  get LanguageTitle(): string { return this.getString('languagetitle');}
  get LastColumn(): string { return this.getString('lastcolumn');}
  get LastPasswordChange(): string { return this.getString('lastpasswordchange');}
  get LastServed(): string { return this.getString('lastserved');}
  get Layout(): string { return this.getString('layout');}
  get Left(): string { return this.getString('left');}
  get LeftAndDown(): string { return this.getString('leftanddown');}
  get LeftAndUp(): string { return this.getString('leftandup');}
  get Level(): string { return this.getString('level');}
  get License(): string { return this.getString('license');}
  get LicensedModules(): string { return this.getString('licensedmodules');}
  get LicenseInformation(): string { return this.getString('licenseinformation');}
  get LicenseIsMissingForXModule(): string { return this.getString('licenseismissingforxmodule');}
  get LicenseNotValidContactSystemAdministrator(): string { return this.getString('licensenotvalidcontactsystemadministrator');}
  get Linear(): string { return this.getString('linear');}
  get LinkAlreadyExists(): string { return this.getString('linkalreadyexists');}
  get LinkBetweenXAndY(): string { return this.getString('linkbetweenxandy');}
  get LinkToWebService(): string { return this.getString('linktowebservice');}
  get List(): string { return this.getString('list');}
  get ListType(): string { return this.getString('listtype');}
  get LoadingGraphics(): string { return this.getString('loadinggraphics');}
  get LoadingTranslations(): string { return this.getString('loadingtranslations');}
  get Local(): string { return this.getString('local');}
  get LocalizeKey(): string { return this.getString('localizekey');}
  get LogOnToServer(): string { return this.getString('logontoserver');}
  get LookupParameter(): string { return this.getString('lookupparameter');}
  get LookupValue(): string { return this.getString('lookupvalue');}
  get LoopWhileIncluded(): string { return this.getString('loopwhileincluded');}
  get LowerCaseWithUnderscore(): string { return this.getString('lowercasewithunderscore');}
  get LowestPrio(): string { return this.getString('lowestprio');}
  get MainMenu(): string { return this.getString('mainmenu');}
  get MakeChanges(): string { return this.getString('makechanges');}
  get MakeCopies(): string { return this.getString('makecopies');}
  get MakeCurrentlyLoggedInUsersWorkgroupAsOwnerUponRevise(): string { return this.getString('makecurrentlyloggedinusersworkgroupasowneruponrevise');}
  get MinimumLengthOfPassword(): string { return this.getString('minimumlengthofpassword');}
  get MakeTheTextBold(): string { return this.getString('makethetextbold');}
  get MakeTheTextLookLikeItWasMarkedWithAHighlighterPen(): string { return this.getString('makethetextlooklikeitwasmarkedwithahighlighterpen');}
  get Manage(): string { return this.getString('manage');}
  get ManageActiveUsersPerLicenseType(): string { return this.getString('manageactiveusersperlicensetype');}
  get Mandatory(): string { return this.getString('mandatory');}
  get MandatoryFieldNotSet(): string { return this.getString('mandatoryfieldnotset');}
  get MandatoryFromState(): string { return this.getString('mandatoryfromstate');}
  get Manual(): string { return this.getString('manual');}
  get ManualOverride(): string { return this.getString('manualoverride');}
  get MarginBottom(): string { return this.getString('marginbottom');}
  get MarginBottomCm(): string { return this.getString('marginbottomcm');}
  get MarginFooter(): string { return this.getString('marginfooter');}
  get MarginFooterCm(): string { return this.getString('marginfootercm');}
  get MarginHeader(): string { return this.getString('marginheader');}
  get MarginHeaderCm(): string { return this.getString('marginheadercm');}
  get MarginLeft(): string { return this.getString('marginleft');}
  get MarginLeftCm(): string { return this.getString('marginleftcm');}
  get MarginRight(): string { return this.getString('marginright');}
  get MarginRightCm(): string { return this.getString('marginrightcm');}
  get MarginTop(): string { return this.getString('margintop');}
  get MarginTopCm(): string { return this.getString('margintopcm');}
  get MaxArchitectUsers(): string { return this.getString('maxarchitectusers');}
  get MaxConfigurationsInComparison(): string { return this.getString('maxconfigurationsincomparison');}
  get MaxConfigurationsInComparisonDescription(): string { return this.getString('maxconfigurationsincomparisondescription');}
  get MaxConfigurationsPerYear(): string { return this.getString('maxconfigurationsperyear');}
  get MaxConfiguratorUsers(): string { return this.getString('maxconfiguratorusers');}
  get MaxCustomerUsers(): string { return this.getString('maxcustomerusers');}
  get MaxCustomerUsersPerCustomerOrganization(): string { return this.getString('maxcustomeruserspercustomerorganization');}
  get MaxIdentifierLengthIsXCharacters(): string { return this.getString('maxidentifierlengthisxcharacters');}
  get Maximum(): string { return this.getString('maximum');}
  get MaximumLength(): string { return this.getString('maximumlength');}
  get MaximumFileUploadSize(): string { return this.getString('maximumfileuploadsize');}
  get MaximumFileUploadSizeDesc(): string { return this.getString('maximumfileuploadsizedesc');}
  get MaximumNumberOfWindows(): string { return this.getString('maximumnumberofwindows');}
  get MaxDepthOfItems(): string { return this.getString('maxdepthofitems');}
  get MaxNumberOfAdministratorsReached(): string { return this.getString('maxnumberofadministratorsreached');}
  get MaxNumberOfConfiguratorUsersReached(): string { return this.getString('maxnumberofconfiguratorusersreached');}
  get MaxNumberOfCustomerUsersReached(): string { return this.getString('maxnumberofcustomerusersreached');}
  get MaxNumberOfUsersReachedSuggestion(): string { return this.getString('maxnumberofusersreachedsuggestion');}
  get MaxNumbersOfStatesAdded(): string { return this.getString('maxnumbersofstatesadded');}
  get MaxDepthOfPriceItems(): string { return this.getString('maxdepthofpriceitems');}
  get MaxRecentResults(): string { return this.getString('maxrecentresults');}
  get MaxRecentResultsDescription(): string { return this.getString('maxrecentresultsdescription');}
  get MaxValue(): string { return this.getString('maxvalue');}
  get Member(): string { return this.getString('member');}
  get MergeCellsSpan(): string { return this.getString('mergecellsspan');}
  get Minimum(): string { return this.getString('minimum');}
  get MinimumTabWidth(): string { return this.getString('minimumtabwidth');}
  get MinimumTabWidthForSeparatedTabs(): string { return this.getString('minimumtabwidthforseparatedtabs');}
  get MinimumTabWidthForSeparatedTabsConfigurator(): string { return this.getString('minimumtabwidthforseparatedtabsconfigurator');}
  get MinimumTabWidthTabsConfigurator(): string { return this.getString('minimumtabwidthtabsconfigurator');}
  get MinimumValueOfXIsY(): string { return this.getString('minimumvalueofxisy');}
  get MinorHeader(): string { return this.getString('minorheader');}
  get MinValue(): string { return this.getString('minvalue');}
  get MissingCompositeExpressionModule(): string { return this.getString('missingcompositeexpressionmodule');}
  get MissingStructureAssembly(): string { return this.getString('missingstructureassembly');}
  get MissingLicenseForAdvancedPricing(): string { return this.getString('missinglicenseforadvancedpricing');}
  get MissingLocalizeKeys(): string { return this.getString('missinglocalizekeys');}
  get MissingParameters(): string { return this.getString('missingparameters');}
  get MissingAttributes(): string { return this.getString('missingattributes');}
  get MoreColors(): string { return this.getString('morecolors');}
  get MoveDown(): string { return this.getString('movedown');}
  get MoveDownRoot(): string { return this.getString('movedownroot');}
  get MoveLeft(): string { return this.getString('moveleft');}
  get MoveRight(): string { return this.getString('moveright');}
  get MoreThan100000EntriesFoundResultIsLimited(): string { return this.getString('morethan100000entriesfoundresultislimited');}
  get MoveToBottom(): string { return this.getString('movetobottom');}
  get MoveToTab(): string { return this.getString('movetotab');}
  get MoveToTop(): string { return this.getString('movetotop');}
  get MoveUp(): string { return this.getString('moveup');}
  get MoveUpRoot(): string { return this.getString('moveuproot');}
  get MultiChoiceParameter(): string { return this.getString('multichoiceparameter');}
  get MultiChoiceValue(): string { return this.getString('multichoicevalue');}
  get MultiLanguage(): string { return this.getString('multilanguage');}
  get MultiLevelBOM(): string { return this.getString('multilevelbom');}
  get MultipleValueTabSetup(): string { return this.getString('multiplevaluetabsetup');}
  get MultiplicationDivision(): string { return this.getString('multiplicationdivision');}
  get MultipleAccountsWithSameUsername(): string { return this.getString('multipleaccountswithsameusername');}
  get MultipleAccountsWithSameEmail(): string { return this.getString('multipleaccountswithsameemail');}
  get MultipleAccountsWithSameEmailPleaseEnterUsername(): string { return this.getString('multipleaccountswithsameemailpleaseenterusername');}
  get MustHaveAPropertyName(): string { return this.getString('musthaveapropertyname');}
  get NameNotRecognizedByCodeEngine(): string { return this.getString('namenotrecognizedbycodeengine');}
  get NameXAlreadyExistsInDataSelectorDictionaryColumns(): string { return this.getString('namexalreadyexistsindataselectordictionarycolumns');}
  get NameXDictionaryAlreadyExists(): string { return this.getString('namexdictionaryalreadyexists');}
  get NameXStructureModeAlreadyExists(): string { return this.getString('namexstructuremodealreadyexists');}
  get NameXGraphicsModeAlreadyExists(): string { return this.getString('namexgraphicsmodealreadyexists');}
  get NameXConflictsWithOtherParameters(): string { return this.getString('namexconflictswithotherparameters');}
  get NameXConflictWithAParameterAtSameScope(): string { return this.getString('namexconflictwithaparameteratsamescope');}
  get NewLineSeparated(): string { return this.getString('newlineseparated');}
  get NewPasswordsDoesNotMatch(): string { return this.getString('newpasswordsdoesnotmatch');}
  get NewWindow(): string { return this.getString('newwindow');}
  get NextValue(): string { return this.getString('nextvalue');}
  get NoActiveUserAccountWasFound(): string { return this.getString('noactiveuseraccountwasfound');}
  get NoColor(): string { return this.getString('nocolor');}
  get NoConfigurationValueSelected(): string { return this.getString('noconfigurationvalueselected');}
  get NoChangesReadOnly(): string { return this.getString('nochangesreadonly');}
  get NoEventXFoundInClassY(): string { return this.getString('noeventxfoundinclassy');}
  get NoGraphicTypeIsSelected(): string { return this.getString('nographictypeisselected');}
  get NoOfFilesAllowed(): string { return this.getString('nooffilesallowed');}
  get NoOfFilesAllowedDesc(): string { return this.getString('nooffilesalloweddesc');}
  get NoAutomaticNaming(): string { return this.getString('noautomaticnaming');}
  get NoCodeItemTypeSelected(): string { return this.getString('nocodeitemtypeselected');}
  get None(): string { return this.getString('none');}
  get NonPositionalOnly(): string { return this.getString('nonpositionalonly');}
  get NoOfDigitsAfterDecimal(): string { return this.getString('noofdigitsafterdecimal');}
  get NoOfDigitsAfterDecimalForAdditionSubtraction(): string { return this.getString('noofdigitsafterdecimalforadditionsubtraction');}
  get NoOfDigitsAfterDecimalForBasePrice(): string { return this.getString('noofdigitsafterdecimalforbaseprice');}
  get NoOfDigitsAfterDecimalForChildConfigurations(): string { return this.getString('noofdigitsafterdecimalforchildconfigurations');}
  get NoOfDigitsAfterDecimalForCosts(): string { return this.getString('noofdigitsafterdecimalforcosts');}
  get NoOfDigitsAfterDecimalForMultiplicationDivision(): string { return this.getString('noofdigitsafterdecimalformultiplicationdivision');}
  get NoOfDigitsAfterDecimalForPriceCategories(): string { return this.getString('noofdigitsafterdecimalforpricecategories');}
  get NoOfDigitsAfterDecimalForPriceItemsWithOperationsAdditionOrSubtration(): string { return this.getString('noofdigitsafterdecimalforpriceitemswithoperationsadditionorsubtration');}
  get NoOfDigitsAfterDecimalForPriceItemsWithOperationsMultiplicationDivision(): string { return this.getString('noofdigitsafterdecimalforpriceitemswithoperationsmultiplicationdivision');}
  get NoOfDigitsAfterDecimalForTotalCosts(): string { return this.getString('noofdigitsafterdecimalfortotalcosts');}
  get NoOfDigitsAfterDecimalForTotalPrices(): string { return this.getString('noofdigitsafterdecimalfortotalprices');}
  get NoParameterSelected(): string { return this.getString('noparameterselected');}
  get NoParamValueSelected(): string { return this.getString('noparamvalueselected');}
  get NoPrimaryWorkGroupSelected(): string { return this.getString('noprimaryworkgroupselected');}
  get Normal(): string { return this.getString('normal');}
  get NormalFont(): string { return this.getString('normalfont');}
  get NotAValidLicenseFile(): string { return this.getString('notavalidlicensefile');}
  get NoteCodeStructureCopiedButInvalidRulesCleared(): string { return this.getString('notecodestructurecopiedbutinvalidrulescleared');}
  get NoteReportStructureCopiedButInvalidProductsCleared(): string { return this.getString('notereportstructurecopiedbutinvalidproductscleared');}
  get NoteReportStructureCopiedButInvalidRulesCleared(): string { return this.getString('notereportstructurecopiedbutinvalidrulescleared');}
  get Notes(): string { return this.getString('notes');}
  get NotSet(): string { return this.getString('notset');}
  get NotUsed(): string { return this.getString('notused');}
  get NotUsedObjectsOfClass(): string { return this.getString('notusedobjectsofclass');}
  get NoValidDataForPasteInClipBoard(): string { return this.getString('novaliddataforpasteinclipboard');}
  get NoValueIsChoosen(): string { return this.getString('novalueischoosen');}
  get NoValuesChoosen(): string { return this.getString('novalueschoosen');}
  get NoValuesChoosenForX(): string { return this.getString('novalueschoosenforx');}
  get Number(): string { return this.getString('number');}
  get NumberOfDaysBeforeThePasswordExpiresToShowAWarningWhenLoggingIn(): string { return this.getString('numberofdaysbeforethepasswordexpirestoshowawarningwhenloggingin');}
  get NumberOfDaysBetweenMandatoryPasswordChanges(): string { return this.getString('numberofdaysbetweenmandatorypasswordchanges');}
  get NumberOfTimesThatTheUserCanNotUseAnAlreadyUsedPassword(): string { return this.getString('numberoftimesthattheusercannotuseanalreadyusedpassword');}
  get NumberSeries(): string { return this.getString('numberseries');}
  get NumberSeriesValue(): string { return this.getString('numberseriesvalue');}
  get NumericFormat(): string { return this.getString('numericformat');}
  get ObjectsCanNotBePastedAsLinkHere(): string { return this.getString('objectscannotbepastedaslinkhere');}
  get ObjectsCanNotBePastedHere(): string { return this.getString('objectscannotbepastedhere');}
  get ObjectId(): string { return this.getString('objectid');}
  get ObjectServerCompabilityError(): string { return this.getString('objectservercompabilityerror');}
  get ObjectsPreventingChangeOfX(): string { return this.getString('objectspreventingchangeofx');}
  get ObjectsPreventingDeletionOfX(): string { return this.getString('objectspreventingdeletionofx');}
  get ObjectsThatCouldNotBeDeleted(): string { return this.getString('objectsthatcouldnotbedeleted');}
  get ObjectsCanOnlyBeMovedWithinTheSameProduct(): string { return this.getString('objectscanonlybemovedwithinthesameproduct');}
  get ObjectXWitnNameYAlreadyExists(): string { return this.getString('objectxwitnnameyalreadyexists');}
  get Obsolete(): string { return this.getString('obsolete');}
  get Office(): string { return this.getString('office');}
  get Office2007_2010(): string { return this.getString('office2007_2010');}
  get OldPasswordIsWrong(): string { return this.getString('oldpasswordiswrong');}
  get OnEventCSharpType(): string { return this.getString('oneventcsharptype');}
  get OnFailureFlowAction(): string { return this.getString('onfailureflowaction');}
  get OnlyOneStateCanBeTheDefaultState(): string { return this.getString('onlyonestatecanbethedefaultstate');}
  get OnlyShowLastRevisionOnStartPage(): string { return this.getString('onlyshowlastrevisiononstartpage');}
  get OnSuccessFlowAction(): string { return this.getString('onsuccessflowaction');}
  get OpenArchitectObject(): string { return this.getString('openarchitectobject');}
  get OpenExportedExcelFile(): string { return this.getString('openexportedexcelfile');}
  get OpenInDownloadFormat(): string { return this.getString('openindownloadformat');}
  get OpenWindows(): string { return this.getString('openwindows');}
  get Operation(): string { return this.getString('operation');}
  get OperationSequence(): string { return this.getString('operationsequence');}
  get Or(): string { return this.getString('or');}
  get OracleServerProvider(): string { return this.getString('oracleserverprovider');}
  get Order(): string { return this.getString('order');}
  get OrderBomByPositionNo(): string { return this.getString('orderbombypositionno');}
  get OrderBomByPositionNoDescription(): string { return this.getString('orderbombypositionnodescription');}
  get OrderBy(): string { return this.getString('orderby');}
  get OrderedBy(): string { return this.getString('orderedby');}
  get OrderProperty(): string { return this.getString('orderproperty');}
  get OrderSearchResultByConfigurationProperty(): string { return this.getString('ordersearchresultbyconfigurationproperty');}
  get Organization(): string { return this.getString('organization');}
  get OriginalCurrency(): string { return this.getString('originalcurrency');}
  get OriginalAndFinalCurrency(): string { return this.getString('originalandfinalcurrency');}
  get OtherObjectsArePreventingDeletionOfX(): string { return this.getString('otherobjectsarepreventingdeletionofx');}
  get Output(): string { return this.getString('output');}
  get Overloads(): string { return this.getString('overloads');}
  get OverridePriceListCurrencyInRepeaters(): string { return this.getString('overridepricelistcurrencyinrepeaters');}
  get OverridePriceListCurrencyInRepeatersDesc(): string { return this.getString('overridepricelistcurrencyinrepeatersdesc');}
  get OwnershipChangedFromXToY(): string { return this.getString('ownershipchangedfromxtoy');}
  get ParentCodeItemIsNotAGroup(): string { return this.getString('parentcodeitemisnotagroup');}
  get ParentCodeItemMustBeSameFileType(): string { return this.getString('parentcodeitemmustbesamefiletype');}
  get ParentStoredPriceItem(): string { return this.getString('parentstoredpriceitem');}
  get Pad(): string { return this.getString('pad');}
  get Page(): string { return this.getString('page');}
  get PageHasExpired(): string { return this.getString('pagehasexpired');}
  get PageExpired(): string { return this.getString('pageexpired');}
  get PageHeight(): string { return this.getString('pageheight');}
  get PageHeightCm(): string { return this.getString('pageheightcm');}
  get PageLocation(): string { return this.getString('pagelocation');}
  get PageSetup(): string { return this.getString('pagesetup');}
  get PageSize(): string { return this.getString('pagesize');}
  get PageWidth(): string { return this.getString('pagewidth');}
  get PageWidthCm(): string { return this.getString('pagewidthcm');}
  get Paragraph(): string { return this.getString('paragraph');}
  get Paragraphs(): string { return this.getString('paragraphs');}
  get ParagraphStyle(): string { return this.getString('paragraphstyle');}
  get ParameterExpression(): string { return this.getString('parameterexpression');}
  get ParameterExpressionIsNotValid(): string { return this.getString('parameterexpressionisnotvalid');}
  get ParameterPropagation(): string { return this.getString('parameterpropagation');}
  get ParametersAndAttributes(): string { return this.getString('parametersandattributes');}
  get ParametersWithSameNameAlreadyExistsInFollowingProducts(): string { return this.getString('parameterswithsamenamealreadyexistsinfollowingproducts');}
  get ParameterValue(): string { return this.getString('parametervalue');}
  get ParameterValues(): string { return this.getString('parametervalues');}
  get ParamNameTaken(): string { return this.getString('paramnametaken');}
  get ParamValue(): string { return this.getString('paramvalue');}
  get PartOfExpressions(): string { return this.getString('partofexpressions');}
  get PascalStyle(): string { return this.getString('pascalstyle');}
  get PasswordCanNotContainAccountInfoOrName(): string { return this.getString('passwordcannotcontainaccountinfoorname');}
  get PasswordCanNotContainAUsedPassword(): string { return this.getString('passwordcannotcontainausedpassword');}
  get PasswordChangeDays(): string { return this.getString('passwordchangedays');}
  get PasswordChangeDaysWarning(): string { return this.getString('passwordchangedayswarning');}
  get PasswordForEditingRestrictions(): string { return this.getString('passwordforeditingrestrictions');}
  get PasswordForSMTPHost(): string { return this.getString('passwordforsmtphost');}
  get PasswordMinimumLength(): string { return this.getString('passwordminimumlength');}
  get PasswordNotReuseOldTimes(): string { return this.getString('passwordnotreuseoldtimes');}
  get PasswordIsOK(): string { return this.getString('passwordisok');}
  get PasswordMustBeAtLeastXCharacters(): string { return this.getString('passwordmustbeatleastxcharacters');}
  get PasswordMustContainThreeCharacterGroups(): string { return this.getString('passwordmustcontainthreecharactergroups');}
  get PasswordsDoesNotMatch(): string { return this.getString('passwordsdoesnotmatch');}
  get PasswordsChangedSuccessMessage(): string { return this.getString('passwordschangedsuccessmessage');}
  get Paste(): string { return this.getString('paste');}
  get PasteAsLink(): string { return this.getString('pasteaslink');}
  get PasteAsLinkWhenPossibleAndMoveRest(): string { return this.getString('pasteaslinkwhenpossibleandmoverest');}
  get PasteTheContentsOfTheClipboardToTheCurrentLocation(): string { return this.getString('pastethecontentsoftheclipboardtothecurrentlocation');}
  get PasteTheContentsOfTheClipboardToTheCurrentLocationAsLink(): string { return this.getString('pastethecontentsoftheclipboardtothecurrentlocationaslink');}
  get PathXDoesNotExists(): string { return this.getString('pathxdoesnotexists');}
  get PaymentTerms(): string { return this.getString('paymentterms');}
  get PermitPriceListGracePeriodForNewConfigurations(): string { return this.getString('permitpricelistgraceperiodfornewconfigurations');}
  get Pink(): string { return this.getString('pink');}
  get Placeholder(): string { return this.getString('placeholder');}
  get PleaseChangeTheSortOrderEntryOfXToAPositiveNumber(): string { return this.getString('pleasechangethesortorderentryofxtoapositivenumber');}
  get ThereAreMoreThanOneSortOrderXPleaseChangeOrRemoveTheDuplicates(): string { return this.getString('therearemorethanonesortorderxpleasechangeorremovetheduplicates');}
  get PleaseChooseAUniqueAttributeValueName(): string { return this.getString('pleasechooseauniqueattributevaluename');}
  get PleaseContactSystemAdministrator(): string { return this.getString('pleasecontactsystemadministrator');}
  get PleaseExitEditModeBeforeClosingWindow(): string { return this.getString('pleaseexiteditmodebeforeclosingwindow');}
  get PleaseSelectACurrency(): string { return this.getString('pleaseselectacurrency');}
  get PleaseSelectALanguage(): string { return this.getString('pleaseselectalanguage');}
  get PleaseSelectAPriceListCategory(): string { return this.getString('pleaseselectapricelistcategory');}
  get PleaseSelectPriceListCategory(): string { return this.getString('pleaseselectpricelistcategory');}
  get PleaseSelectProduct(): string { return this.getString('pleaseselectproduct');}
  get pngConverterToolInformation(): string { return this.getString('pngconvertertoolinformation');}
  get Popup(): string { return this.getString('popup');}
  get PortOfTheSMTPServer(): string { return this.getString('portofthesmtpserver');}
  get PositionalOnly(): string { return this.getString('positionalonly');}
  get PostalCode(): string { return this.getString('postalcode');}
  get Precision(): string { return this.getString('precision');}
  get PrecisionForPresentation(): string { return this.getString('precisionforpresentation');}
  get PreferredWidth(): string { return this.getString('preferredwidth');}
  get PreferredWidthCm(): string { return this.getString('preferredwidthcm');}
  get Prefix(): string { return this.getString('prefix');}
  get PressLeftMouseButtonToResizeTable(): string { return this.getString('pressleftmousebuttontoresizetable');}
  get Preview(): string { return this.getString('preview');}
  get PriceAuthorizations(): string { return this.getString('priceauthorizations');}
  get PriceCannotBeCalculatedBecauseOfTypeNumericExpression(): string { return this.getString('pricecannotbecalculatedbecauseoftypenumericexpression');}
  get PriceCategory(): string { return this.getString('pricecategory');}
  get PriceCategories(): string { return this.getString('pricecategories');}
  get PriceFactorOverrideAllowed(): string { return this.getString('pricefactoroverrideallowed');}
  get PriceFactorOverrideAllowedDesc(): string { return this.getString('pricefactoroverridealloweddesc');}
  get PriceItem(): string { return this.getString('priceitem');}
  get PriceItemPriceItemLink(): string { return this.getString('priceitempriceitemlink');}
  get PriceItems(): string { return this.getString('priceitems');}
  get PriceItemsWithOperationsAdditionOrSubtration(): string { return this.getString('priceitemswithoperationsadditionorsubtration');}
  get PriceItemsWithOperationsMultiplicationDivision(): string { return this.getString('priceitemswithoperationsmultiplicationdivision');}
  get PriceItemXAlreadyExistsAsAChildToY(): string { return this.getString('priceitemxalreadyexistsasachildtoy');}
  get PriceList(): string { return this.getString('pricelist');}
  get PriceListCategoriesOfTheUser(): string { return this.getString('pricelistcategoriesoftheuser');}
  get PriceListCategory(): string { return this.getString('pricelistcategory');}
  get PriceListCopy(): string { return this.getString('pricelistcopy');}
  get PriceListCopyMessage(): string { return this.getString('pricelistcopymessage');}
  get PriceListDecoration(): string { return this.getString('pricelistdecoration');}
  get PriceListPriceItemLink(): string { return this.getString('pricelistpriceitemlink');}
  get PriceLists(): string { return this.getString('pricelists');}
  get PriceSettings(): string { return this.getString('pricesettings');}
  get PriceListXIsNotPartOfAnyOfDecorationsProducts(): string { return this.getString('pricelistxisnotpartofanyofdecorationsproducts');}
  get PriceOverrideAllowed(): string { return this.getString('priceoverrideallowed');}
  get PriceOverrideAllowedDesc(): string { return this.getString('priceoverridealloweddesc');}
  get PricePrecisionForPresentationEnabled(): string { return this.getString('priceprecisionforpresentationenabled');}
  get PricePrecisionForPresentationEnabledDesc(): string { return this.getString('priceprecisionforpresentationenableddesc');}
  get PrimaryBillingSite(): string { return this.getString('primarybillingsite');}
  get PrimaryContact(): string { return this.getString('primarycontact');}
  get PrimaryContactShouldBeActive(): string { return this.getString('primarycontactshouldbeactive');}
  get PrimarySite(): string { return this.getString('primarysite');}
  get PrimarySiteShouldBeActive(): string { return this.getString('primarysiteshouldbeactive');}
  get PrimaryWorkGroup(): string { return this.getString('primaryworkgroup');}
  get PrintPreview(): string { return this.getString('printpreview');}
  get ProceedWithCopyAnyway(): string { return this.getString('proceedwithcopyanyway');}
  get ProceedWithDeletionAnyway(): string { return this.getString('proceedwithdeletionanyway');}
  get ProductAttributeLink(): string { return this.getString('productattributelink');}
  get ProductCodeFileLink(): string { return this.getString('productcodefilelink');}
  get ProductDocumentLink(): string { return this.getString('productdocumentlink');}
  get ProductFamily(): string { return this.getString('productfamily');}
  get ProductHasAParameterNamedXcannotAddAttributeX(): string { return this.getString('producthasaparameternamedxcannotaddattributex');}
  get ProductMustBeSelectedForAccordionChildren(): string { return this.getString('productmustbeselectedforaccordionchildren');}
  get ProductProductLink(): string { return this.getString('productproductlink');}
  get ProductRuleLink(): string { return this.getString('productrulelink');}
  get ProductStructure(): string { return this.getString('productstructure');}
  get ProductTitlePrefix(): string { return this.getString('producttitleprefix');}
  get ProductTitleSuffix(): string { return this.getString('producttitlesuffix');}
  get ProductXDoesNotHaveProductGraphicWithGraphicTypeY(): string { return this.getString('productxdoesnothaveproductgraphicwithgraphictypey');}
  get PropagationBehavior(): string { return this.getString('propagationbehavior');}
  get PropertiesInComparison(): string { return this.getString('propertiesincomparison');}
  get PropertiesInComparisonDescription(): string { return this.getString('propertiesincomparisondescription');}
  get PropertiesThatAreSearchable(): string { return this.getString('propertiesthataresearchable');}
  get Property(): string { return this.getString('property');}
  get PropertyDecoration(): string { return this.getString('propertydecoration');}
  get PropertyDecorationItem(): string { return this.getString('propertydecorationitem');}
  get PropertyName(): string { return this.getString('propertyname');}
  get Province(): string { return this.getString('province');}
  get PublishedBy(): string { return this.getString('publishedby');}
  get PublishedDate(): string { return this.getString('publisheddate');}
  get PublishStartDate(): string { return this.getString('publishstartdate');}
  get PublishInformation(): string { return this.getString('publishinformation');}
  get Publishing(): string { return this.getString('publishing');}
  get PublishingErrorReceiver(): string { return this.getString('publishingerrorreceiver');}
  get PublishingErrorReceiverDesc(): string { return this.getString('publishingerrorreceiverdesc');}
  get PublishingInProgress(): string { return this.getString('publishinginprogress');}
  get PublishingPendingFilesDeleteSucceeded(): string { return this.getString('publishingpendingfilesdeletesucceeded');}
  get PublishingPendingFilesDeleteFailed(): string { return this.getString('publishingpendingfilesdeletefailed');}
  get PublishingResult(): string { return this.getString('publishingresult');}
  get PublishingRows(): string { return this.getString('publishingrows');}
  get PublishingSucceeded(): string { return this.getString('publishingsucceeded');}
  get PublishingTableX(): string { return this.getString('publishingtablex');}
  get PublishingUser(): string { return this.getString('publishinguser');}
  get PublishingUserInformation(): string { return this.getString('publishinguserinformation');}
  get PublishNow(): string { return this.getString('publishnow');}
  get PublishSettings(): string { return this.getString('publishsettings');}
  get QuantityExpression(): string { return this.getString('quantityexpression');}
  get QuantityExpressionIsNotValid(): string { return this.getString('quantityexpressionisnotvalid');}
  get R(): string { return this.getString('r');}
  get Radial(): string { return this.getString('radial');}
  get Read(): string { return this.getString('read');}
  get ReadOnly(): string { return this.getString('readonly');}
  get ReCaptchaValidationError(): string { return this.getString('recaptchavalidationerror');}
  get RedCustomerWithTooManyUsers(): string { return this.getString('redcustomerwithtoomanyusers');}
  get Reflect(): string { return this.getString('reflect');}
  get RemoveCalculateStop(): string { return this.getString('removecalculatestop');}
  get Reason(): string { return this.getString('reason');}
  get RecognizedItemTypeNames(): string { return this.getString('recognizeditemtypenames');}
  get RenderedStyles(): string { return this.getString('renderedstyles');}
  get Red(): string { return this.getString('red');}
  get Refresh(): string { return this.getString('refresh');}
  get RegionalSettings(): string { return this.getString('regionalsettings');}
  get RegulatingItems(): string { return this.getString('regulatingitems');}
  get RegulatingParameters(): string { return this.getString('regulatingparameters');}
  get RelationType(): string { return this.getString('relationtype');}
  get RelativePathVaultRootImages(): string { return this.getString('relativepathvaultrootimages');}
  get RelativePathVaultRootDocument(): string { return this.getString('relativepathvaultrootdocument');}
  get RelativePathVaultRootUploads(): string { return this.getString('relativepathvaultrootuploads');}
  get RelativePathVaultRootVisualization(): string { return this.getString('relativepathvaultrootvisualization');}
  get Reload(): string { return this.getString('reload');}
  get Rename(): string { return this.getString('rename');}
  get Render(): string { return this.getString('render');}
  get ReOrderValues(): string { return this.getString('reordervalues');}
  get RepeatAsHeaderRowOnEachPage(): string { return this.getString('repeatasheaderrowoneachpage');}
  get Repeat(): string { return this.getString('repeat');}
  get RepeaterUsesStoredPrices(): string { return this.getString('repeaterusesstoredprices');}
  get RepeaterUsesStoredPricesDesc(): string { return this.getString('repeaterusesstoredpricesdesc');}
  get ReplaceBinaryData(): string { return this.getString('replacebinarydata');}
  get ReplacementCharEnd(): string { return this.getString('replacementcharend');}
  get ReplacementCharStart(): string { return this.getString('replacementcharstart');}
  get Report(): string { return this.getString('report');}
  get ReportBase(): string { return this.getString('reportbase');}
  get ReportDocument(): string { return this.getString('reportdocument');}
  get ReportDocuments(): string { return this.getString('reportdocuments');}
  get ReportField(): string { return this.getString('reportfield');}
  get ReportFolder(): string { return this.getString('reportfolder');}
  get ReportFolderContainerLink(): string { return this.getString('reportfoldercontainerlink');}
  get ReportFooter(): string { return this.getString('reportfooter');}
  get ReportHeader(): string { return this.getString('reportheader');}
  get ReportImage(): string { return this.getString('reportimage');}
  get ReportImagesAreIncompatibleWithDestinationProducts(): string { return this.getString('reportimagesareincompatiblewithdestinationproducts');}
  get ReportItemsWithInvalidRules(): string { return this.getString('reportitemswithinvalidrules');}
  get ReportItemsWithInvalidProducts(): string { return this.getString('reportitemswithinvalidproducts');}
  get ReportList(): string { return this.getString('reportlist');}
  get ReportListItem(): string { return this.getString('reportlistitem');}
  get ReportRepeater(): string { return this.getString('reportrepeater');}
  get ReportStyle(): string { return this.getString('reportstyle');}
  get ReportTable(): string { return this.getString('reporttable');}
  get ReportTableCell(): string { return this.getString('reporttablecell');}
  get ReportTableColumnProperties(): string { return this.getString('reporttablecolumnproperties');}
  get ReportTableRow(): string { return this.getString('reporttablerow');}
  get ReportText(): string { return this.getString('reporttext');}
  get ReportVariableText(): string { return this.getString('reportvariabletext');}
  get ReportVariant(): string { return this.getString('reportvariant');}
  get RequiresParameter(): string { return this.getString('requiresparameter');}
  get RequiresAttribute(): string { return this.getString('requiresattribute');}
  get ResponsiveSize(): string { return this.getString('responsivesize');}
  get RestrictAccessToCustomerConfigurations(): string { return this.getString('restrictaccesstocustomerconfigurations');}
  get RestartArchitectForNewLicense(): string { return this.getString('restartarchitectfornewlicense');}
  get RestorePreviousValues(): string { return this.getString('restorepreviousvalues');}
  get RestrictAccessToConfigurations(): string { return this.getString('restrictaccesstoconfigurations');}
  get RestrictAccessToConfigurationsDesc(): string { return this.getString('restrictaccesstoconfigurationsdesc');}
  get ReturnType(): string { return this.getString('returntype');}
  get ReturnDescription(): string { return this.getString('returndescription');}
  get Right(): string { return this.getString('right');}
  get Root(): string { return this.getString('root');}
  get RightAndUp(): string { return this.getString('rightandup');}
  get RightAndDown(): string { return this.getString('rightanddown');}
  get RightOfValues(): string { return this.getString('rightofvalues');}
  get Rights(): string { return this.getString('rights');}
  get RoundHalfAwayFromZero(): string { return this.getString('roundhalfawayfromzero');}
  get RoundingDigitsPriceItems(): string { return this.getString('roundingdigitspriceitems');}
  get RoundingDigitsTotalPrice(): string { return this.getString('roundingdigitstotalprice');}
  get RoundDown(): string { return this.getString('rounddown');}
  get RoundingBehavior(): string { return this.getString('roundingbehavior');}
  get RoundingMethod(): string { return this.getString('roundingmethod');}
  get RoundPriceItems(): string { return this.getString('roundpriceitems');}
  get RoundHalfToEven(): string { return this.getString('roundhalftoeven');}
  get RoundTotalPrice(): string { return this.getString('roundtotalprice');}
  get RoundUp(): string { return this.getString('roundup');}
  get Row(): string { return this.getString('row');}
  get RowCount(): string { return this.getString('rowcount');}
  get RowCountIsNotAValidInteger(): string { return this.getString('rowcountisnotavalidinteger');}
  get RowHeightIsAtLeast(): string { return this.getString('rowheightisatleast');}
  get RowIndex(): string { return this.getString('rowindex');}
  get Rows(): string { return this.getString('rows');}
  get RuleCategory(): string { return this.getString('rulecategory');}
  get RuleEditor(): string { return this.getString('ruleeditor');}
  get RuleEffective(): string { return this.getString('ruleeffective');}
  get RuleEffectiveOnAdminSite(): string { return this.getString('ruleeffectiveonadminsite');}
  get Rules(): string { return this.getString('rules');}
  get RulesInCopiedCodeFileIncludesParametersOrAttributesThatAreMissingInTheDestinationProducts(): string { return this.getString('rulesincopiedcodefileincludesparametersorattributesthataremissinginthedestinationproducts');}
  get RulesInCopiedReportStructureIncludesParametersOrAttributesThatAreMissingInTheDestinationProducts(): string { return this.getString('rulesincopiedreportstructureincludesparametersorattributesthataremissinginthedestinationproducts');}
  get RulesInCopiedPriceListIncludesParametersOrAttributesThatAreMissingInTheDestinationProducts(): string { return this.getString('rulesincopiedpricelistincludesparametersorattributesthataremissinginthedestinationproducts');}
  get SaveAndEmail(): string { return this.getString('saveandemail');}
  get SaveThisConfigurationAndSendItToYourEmail(): string { return this.getString('savethisconfigurationandsendittoyouremail');}
  get Scheduled(): string { return this.getString('scheduled');}
  get ScheduledPublishing(): string { return this.getString('scheduledpublishing');}
  get SearchProperties(): string { return this.getString('searchproperties');}
  get SearchPropertiesImmediatelyShown(): string { return this.getString('searchpropertiesimmediatelyshown');}
  get SearchPropertiesImmediatelyShownDesc(): string { return this.getString('searchpropertiesimmediatelyshowndesc');}
  get SearchResultDefaultDescendingOrder(): string { return this.getString('searchresultdefaultdescendingorder');}
  get SearchResultDefaultDescendingOrderDescription(): string { return this.getString('searchresultdefaultdescendingorderdescription');}
  get SearchIncremental(): string { return this.getString('searchincremental');}
  get SearchNormal(): string { return this.getString('searchnormal');}
  get SearchParameter(): string { return this.getString('searchparameter');}
  get SearchResult(): string { return this.getString('searchresult');}
  get SearchResultConfigurationProperties(): string { return this.getString('searchresultconfigurationproperties');}
  get Sec(): string { return this.getString('sec');}
  get SelectColor(): string { return this.getString('selectcolor');}
  get SelectColumns(): string { return this.getString('selectcolumns');}
  get SelectDeselectAll(): string { return this.getString('selectdeselectall');}
  get SelectedDictionaryKey(): string { return this.getString('selecteddictionarykey');}
  get SelectedGraphicDecorationNotBelongToDestinationProduct(): string { return this.getString('selectedgraphicdecorationnotbelongtodestinationproduct');}
  get SelectMultiChoiceValue(): string { return this.getString('selectmultichoicevalue');}
  get SelectParagraphBorders(): string { return this.getString('selectparagraphborders');}
  get SelectParameter(): string { return this.getString('selectparameter');}
  get SelectedImageAbove(): string { return this.getString('selectedimageabove');}
  get SelectedImageRight(): string { return this.getString('selectedimageright');}
  get SelectedProperties(): string { return this.getString('selectedproperties');}
  get SelectLanguages(): string { return this.getString('selectlanguages');}
  get SelectLanguagesToViewInTheTranslationTable(): string { return this.getString('selectlanguagestoviewinthetranslationtable');}
  get SelectOrEnterDatabaseName(): string { return this.getString('selectorenterdatabasename');}
  get SelectedObjectNullOrDataTypeMismatch(): string { return this.getString('selectedobjectnullordatatypemismatch');}
  get SelectStyleToImport(): string { return this.getString('selectstyletoimport');}
  get SelectTheTextAttributesThatCanBeTranslated(): string { return this.getString('selectthetextattributesthatcanbetranslated');}
  get SelectedParameterOrMultiChoiceValueAlreadyExists(): string { return this.getString('selectedparameterormultichoicevaluealreadyexists');}
  get SendEmail(): string { return this.getString('sendemail');}
  get SenderEmailAddress(): string { return this.getString('senderemailaddress');}
  get SenderEmailAddressDescription(): string { return this.getString('senderemailaddressdescription');}
  get SendThisConfigurationToYourEmail(): string { return this.getString('sendthisconfigurationtoyouremail');}
  get SendMeALinkToTheConfiguration(): string { return this.getString('sendmealinktotheconfiguration');}
  get SearchAndNavigateAmongHelpTopics(): string { return this.getString('searchandnavigateamonghelptopics');}
  get SearchFilter(): string { return this.getString('searchfilter');}
  get Select(): string { return this.getString('select');}
  get Separate(): string { return this.getString('separate');}
  get SeparatedHeight(): string { return this.getString('separatedheight');}
  get SeparatedTabCollapseAtWindowWidth(): string { return this.getString('separatedtabcollapseatwindowwidth');}
  get SeparatedTabCollapseAtWindowWidthDescription(): string { return this.getString('separatedtabcollapseatwindowwidthdescription');}
  get SeparatedTabPosition(): string { return this.getString('separatedtabposition');}
  get SeparatedWidth(): string { return this.getString('separatedwidth');}
  get Separator(): string { return this.getString('separator');}
  get ServerName(): string { return this.getString('servername');}
  get Set(): string { return this.getString('set');}
  get SetCalculateStop(): string { return this.getString('setcalculatestop');}
  get SetsHowGraphicsShouldBeFiltered(): string { return this.getString('setshowgraphicsshouldbefiltered');}
  get SetsIfTheRuleShouldTriggerByCheckedOrUncheckedValues(): string { return this.getString('setsiftheruleshouldtriggerbycheckedoruncheckedvalues');}
  get Setting(): string { return this.getString('setting');}
  get SettingsGroup(): string { return this.getString('settingsgroup');}
  get ShouldTheNewImageBeAHelpImageForTheParameter(): string { return this.getString('shouldthenewimagebeahelpimagefortheparameter');}
  get ShowAll(): string { return this.getString('showall');}
  get ShowCurrentOrStoredAttributes(): string { return this.getString('showcurrentorstoredattributes');}
  get ShowCurrentOrStoredAttributesDesc(): string { return this.getString('showcurrentorstoredattributesdesc');}
  get ShowDownloadLink(): string { return this.getString('showdownloadlink');}
  get ShowAttributes(): string { return this.getString('showattributes');}
  get ShowAttributesOnSummaryPage(): string { return this.getString('showattributesonsummarypage');}
  get ShowingPageXToPageSizeYOfTotalPagesZ(): string { return this.getString('showingpagextopagesizeyoftotalpagesz');}
  get ShowBOM(): string { return this.getString('showbom');}
  get ShowBOMOnSummaryPage(): string { return this.getString('showbomonsummarypage');}
  get ShowChildren(): string { return this.getString('showchildren');}
  get ShowChildConfigurations(): string { return this.getString('showchildconfigurations');}
  get ShowConfirmBox(): string { return this.getString('showconfirmbox');}
  get ShowCookieInformation(): string { return this.getString('showcookieinformation');}
  get ShowExpandCollapseIcons(): string { return this.getString('showexpandcollapseicons');}
  get ShowGrandTotal(): string { return this.getString('showgrandtotal');}
  get ShowGrandTotalInStructureDetails(): string { return this.getString('showgrandtotalinstructuredetails');}
  get ShowHelpImageOfSelectedValue(): string { return this.getString('showhelpimageofselectedvalue');}
  get ShowImage(): string { return this.getString('showimage');}
  get ShowImages(): string { return this.getString('showimages');}
  get ShowImportButtonInCompositeControl(): string { return this.getString('showimportbuttonincompositecontrol');}
  get ShowInConfigurator(): string { return this.getString('showinconfigurator');}
  get ShowInformation(): string { return this.getString('showinformation');}
  get ShowInformationBox(): string { return this.getString('showinformationbox');}
  get ShowInline(): string { return this.getString('showinline');}
  get ShowInSummary(): string { return this.getString('showinsummary');}
  get ShowInSummaryAsCalculated(): string { return this.getString('showinsummaryascalculated');}
  get ShowInSummaryAsStored(): string { return this.getString('showinsummaryasstored');}
  get ShowNavigationPanel(): string { return this.getString('shownavigationpanel');}
  get ShowNewParameters(): string { return this.getString('shownewparameters');}
  get ShowNewParametersOnSummaryPage(): string { return this.getString('shownewparametersonsummarypage');}
  get ShowNonEffectiveProducts(): string { return this.getString('shownoneffectiveproducts');}
  get ShowsNonEffectiveProductsDescription(): string { return this.getString('showsnoneffectiveproductsdescription');}
  get ShowsWarningMessageWhenCreatingParameterWithNameUsedInAnotherProduct(): string { return this.getString('showswarningmessagewhencreatingparameterwithnameusedinanotherproduct');}
  get ShowOldParameters(): string { return this.getString('showoldparameters');}
  get ShowOldParametersOnSummaryPage(): string { return this.getString('showoldparametersonsummarypage');}
  get ShowParentGraphics(): string { return this.getString('showparentgraphics');}
  get ShowPrices(): string { return this.getString('showprices');}
  get ShowStoredAttributes(): string { return this.getString('showstoredattributes');}
  get ShowStoredAttributesOnSummaryPage(): string { return this.getString('showstoredattributesonsummarypage');}
  get ShowStoredItems(): string { return this.getString('showstoreditems');}
  get ShowStoredItemsOnSummaryPage(): string { return this.getString('showstoreditemsonsummarypage');}
  get ShowStoredPrices(): string { return this.getString('showstoredprices');}
  get ShowStoredPricesOnSummaryPage(): string { return this.getString('showstoredpricesonsummarypage');}
  get Sibling(): string { return this.getString('sibling');}
  get SimpleRule(): string { return this.getString('simplerule');}
  get SimulatedPrice(): string { return this.getString('simulatedprice');}
  get Simulator(): string { return this.getString('simulator');}
  get SingleLevelBom(): string { return this.getString('singlelevelbom');}
  get SingleLevelConsolidatedBOM(): string { return this.getString('singlelevelconsolidatedbom');}
  get Singleton(): string { return this.getString('singleton');}
  get SiteSize(): string { return this.getString('sitesize');}
  get Size(): string { return this.getString('size');}
  get SMTPPort(): string { return this.getString('smtpport');}
  get SMTPHost(): string { return this.getString('smtphost');}
  get SMTPUserName(): string { return this.getString('smtpusername');}
  get SMTPPassword(): string { return this.getString('smtppassword');}
  get SomethingWentWrongInTheHTTPRequest(): string { return this.getString('somethingwentwronginthehttprequest');}
  get Sort(): string { return this.getString('sort');}
  get SortExpression(): string { return this.getString('sortexpression');}
  get SortExpressionIsNotValid(): string { return this.getString('sortexpressionisnotvalid');}
  get SortOrder(): string { return this.getString('sortorder');}
  get SortValuesByKey(): string { return this.getString('sortvaluesbykey');}
  get SortValuesByTitle(): string { return this.getString('sortvaluesbytitle');}
  get Source(): string { return this.getString('source');}
  get SourceCode(): string { return this.getString('sourcecode');}
  get SourceFile(): string { return this.getString('sourcefile');}
  get SourceFolder(): string { return this.getString('sourcefolder');}
  get SourceOrTargetDirectoryDoesNotExist(): string { return this.getString('sourceortargetdirectorydoesnotexist');}
  get SourceProductEqualsDestinationProduct(): string { return this.getString('sourceproductequalsdestinationproduct');}
  get SourceProductFamilyEqualsDestinationProductFamily(): string { return this.getString('sourceproductfamilyequalsdestinationproductfamily');}
  get SpreadMethod(): string { return this.getString('spreadmethod');}
  get Stop1Color(): string { return this.getString('stop1color');}
  get Stop2Color(): string { return this.getString('stop2color');}
  get Stop3Color(): string { return this.getString('stop3color');}
  get Stop1Offset(): string { return this.getString('stop1offset');}
  get Stop2Offset(): string { return this.getString('stop2offset');}
  get Stop3Offset(): string { return this.getString('stop3offset');}
  get StoredCurrentAsPopup(): string { return this.getString('storedcurrentaspopup');}
  get SumWithPreviousValue(): string { return this.getString('sumwithpreviousvalue');}
  get SumWithPreviousValues(): string { return this.getString('sumwithpreviousvalues');}
  get SuppressFictitiousness(): string { return this.getString('suppressfictitiousness');}
  get SuppressChildConfigurationsWithZeroPrices(): string { return this.getString('suppresschildconfigurationswithzeroprices');}
  get SuppressChildConfigurationReportsWithZeroPrices(): string { return this.getString('suppresschildconfigurationreportswithzeroprices');}
  get SuppressFictitiousnessRuleIsNotValid(): string { return this.getString('suppressfictitiousnessruleisnotvalid');}
  get SupportedBrowsersInfo(): string { return this.getString('supportedbrowsersinfo');}
  get SpacesAreNotAllowedInTheIdentifier(): string { return this.getString('spacesarenotallowedintheidentifier');}
  get SpaceSeparated(): string { return this.getString('spaceseparated');}
  get SpecifiesTheFloatingPointPrecisionForBasePrice(): string { return this.getString('specifiesthefloatingpointprecisionforbaseprice');}
  get SpecifiesTheFloatingPointPrecisionForChildConfigurations(): string { return this.getString('specifiesthefloatingpointprecisionforchildconfigurations');}
  get SpecifiesTheFloatingPointPrecisionForCosts(): string { return this.getString('specifiesthefloatingpointprecisionforcosts');}
  get SpecifiesTheFloatingPointPrecisionForPriceCategories(): string { return this.getString('specifiesthefloatingpointprecisionforpricecategories');}
  get SpecifiesTheFloatingPointPrecisionForPriceItemsWithOperationsAdditionOrSubtration(): string { return this.getString('specifiesthefloatingpointprecisionforpriceitemswithoperationsadditionorsubtration');}
  get SpecifiesTheFloatingPointPrecisionForPriceItemsWithOperationsMultiplicationDivision(): string { return this.getString('specifiesthefloatingpointprecisionforpriceitemswithoperationsmultiplicationdivision');}
  get SpecifiesTheFloatingPointPrecisionForTotalCosts(): string { return this.getString('specifiesthefloatingpointprecisionfortotalcosts');}
  get SpecifiesTheFloatingPointPrecisionForTotalPrices(): string { return this.getString('specifiesthefloatingpointprecisionfortotalprices');}
  get SqlStatemenAttachedSearchConfigurations(): string { return this.getString('sqlstatemenattachedsearchconfigurations');}
  get SQLServerProvider(): string { return this.getString('sqlserverprovider');}
  get SqlToRunOnAdministrationBeforePublishing(): string { return this.getString('sqltorunonadministrationbeforepublishing');}
  get SqlToRunOnProductionBeforePublishing(): string { return this.getString('sqltorunonproductionbeforepublishing');}
  get SslIsOffloaded(): string { return this.getString('sslisoffloaded');}
  get SslIsOffloadedDesc(): string { return this.getString('sslisoffloadeddesc');}
  get StandardColors(): string { return this.getString('standardcolors');}
  get States(): string { return this.getString('states');}
  get StepValue(): string { return this.getString('stepvalue');}
  get StepRounding(): string { return this.getString('steprounding');}
  get StoreBom(): string { return this.getString('storebom');}
  get StoreBomAsSingleLevel(): string { return this.getString('storebomassinglelevel');}
  get StoredDataSelection(): string { return this.getString('storeddataselection');}
  get StoreItems(): string { return this.getString('storeitems');}
  get StreamingChunkSize(): string { return this.getString('streamingchunksize');}
  get Stretch(): string { return this.getString('stretch');}
  get StringParameter(): string { return this.getString('stringparameter');}
  get StructureDecoration(): string { return this.getString('structuredecoration');}
  get StructureDecorationsWithStoredItemsBelongingToAProductMustHaveUniqueStructureModes(): string { return this.getString('structuredecorationswithstoreditemsbelongingtoaproductmusthaveuniquestructuremodes');}
  get StructureMode(): string { return this.getString('structuremode');}
  get Style(): string { return this.getString('style');}
  get StyleNotFoundInTheWordDocument(): string { return this.getString('stylenotfoundintheworddocument');}
  get StyleOrigin(): string { return this.getString('styleorigin');}
  get StyleSuccessfullyImported(): string { return this.getString('stylesuccessfullyimported');}
  get StyleXml(): string { return this.getString('stylexml');}
  get StyleType(): string { return this.getString('styletype');}
  get SubDirectory(): string { return this.getString('subdirectory');}
  get SubParameterRule(): string { return this.getString('subparameterrule');}
  get SubParameters(): string { return this.getString('subparameters');}
  get SubTotal(): string { return this.getString('subtotal');}
  get Suffix(): string { return this.getString('suffix');}
  get SumBehavior(): string { return this.getString('sumbehavior');}
  get SummaryChildConfigurationsColumnSettings(): string { return this.getString('summarychildconfigurationscolumnsettings');}
  get SummaryDisableOptionItems(): string { return this.getString('summarydisableoptionitems');}
  get SummaryDisableOptionItemsDescription(): string { return this.getString('summarydisableoptionitemsdescription');}
  get SummaryDisableStateItem(): string { return this.getString('summarydisablestateitem');}
  get SummaryDisableStateItemDescription(): string { return this.getString('summarydisablestateitemdescription');}
  get SummaryDisableUploadItem(): string { return this.getString('summarydisableuploaditem');}
  get SummaryDisableUploadItemDescription(): string { return this.getString('summarydisableuploaditemdescription');}
  get SummaryDisplayStyle(): string { return this.getString('summarydisplaystyle');}
  get SumType(): string { return this.getString('sumtype');}
  get Street(): string { return this.getString('street');}
  get SuperUser(): string { return this.getString('superuser');}
  get SuppressChildren(): string { return this.getString('suppresschildren');}
  get SuppressItemsWithZeroQuantityInBom(): string { return this.getString('suppressitemswithzeroquantityinbom');}
  get SuppressZeroQuantityItems(): string { return this.getString('suppresszeroquantityitems');}
  get SuppressZeroPrices(): string { return this.getString('suppresszeroprices');}
  get Switches(): string { return this.getString('switches');}
  get SymbolFolder(): string { return this.getString('symbolfolder');}
  get Tab(): string { return this.getString('tab');}
  get TabAfter(): string { return this.getString('tabafter');}
  get TabBefore(): string { return this.getString('tabbefore');}
  get TabContents(): string { return this.getString('tabcontents');}
  get TabDecorationLink(): string { return this.getString('tabdecorationlink');}
  get TabOrder(): string { return this.getString('taborder');}
  get TabTitle(): string { return this.getString('tabtitle');}
  get Table(): string { return this.getString('table');}
  get TableEdit(): string { return this.getString('tableedit');}
  get TableStyleOptions(): string { return this.getString('tablestyleoptions');}
  get TabParameterLink(): string { return this.getString('tabparameterlink');}
  get Tabs(): string { return this.getString('tabs');}
  get TabVisualObjectLink(): string { return this.getString('tabvisualobjectlink');}
  get TabXContainsErrors(): string { return this.getString('tabxcontainserrors');}
  get TakeOwnership(): string { return this.getString('takeownership');}
  get TargetFolder(): string { return this.getString('targetfolder');}
  get Teal(): string { return this.getString('teal');}
  get TemplateParameters(): string { return this.getString('templateparameters');}
  get TemplateRule(): string { return this.getString('templaterule');}
  get Terms(): string { return this.getString('terms');}
  get TestCompability(): string { return this.getString('testcompability');}
  get TestConnection(): string { return this.getString('testconnection');}
  get TestConnectionError(): string { return this.getString('testconnectionerror');}
  get TestConnectionSucceded(): string { return this.getString('testconnectionsucceded');}
  get Text(): string { return this.getString('text');}
  get TextBackgroundDark1(): string { return this.getString('textbackgrounddark1');}
  get TextBackgroundLight1(): string { return this.getString('textbackgroundlight1');}
  get TextBackgroundDark2(): string { return this.getString('textbackgrounddark2');}
  get TextBackgroundLight2(): string { return this.getString('textbackgroundlight2');}
  get TextDecoration(): string { return this.getString('textdecoration');}
  get TextExpression(): string { return this.getString('textexpression');}
  get TextExpressionIsNotValid(): string { return this.getString('textexpressionisnotvalid');}
  get TextIndentCm(): string { return this.getString('textindentcm');}
  get TextWrapping(): string { return this.getString('textwrapping');}
  get ThemeColors(): string { return this.getString('themecolors');}
  get TheAttributeXCannotBePartOfDecoration(): string { return this.getString('theattributexcannotbepartofdecoration');}
  get TheCopiedCodeStructureIncludesProductsThatAreInvalidForTheDestinationStructure(): string { return this.getString('thecopiedcodestructureincludesproductsthatareinvalidforthedestinationstructure');}
  get TheCopiedReportStructureIncludesProductsThatAreInvalidForTheDestinationStructure(): string { return this.getString('thecopiedreportstructureincludesproductsthatareinvalidforthedestinationstructure');}
  get TheFirstConfigurationIsLinkedTo(): string { return this.getString('thefirstconfigurationislinkedto');}
  get TheFollowingXDictionaryKeysAreIncompatibleWithTheSelectedDataType(): string { return this.getString('thefollowingxdictionarykeysareincompatiblewiththeselecteddatatype');}
  get TheItemNumberXIsUsedByAnotherPriceElementDoYouWantToSaveThisItemNumberAnyway(): string { return this.getString('theitemnumberxisusedbyanotherpriceelementdoyouwanttosavethisitemnumberanyway');}
  get TheLanguageXHasTranslatedPhrases(): string { return this.getString('thelanguagexhastranslatedphrases');}
  get TheNameXIsInConflictWithOtherAttributes(): string { return this.getString('thenamexisinconflictwithotherattributes');}
  get ThePriceListCategoryXIsReferencedByARepeaterButDoesNotExistsInTheTargetY(): string { return this.getString('thepricelistcategoryxisreferencedbyarepeaterbutdoesnotexistsinthetargety');}
  get TheProductDoesNotExistInTheDestinationProductStructure(): string { return this.getString('theproductdoesnotexistinthedestinationproductstructure');}
  get TheProductXIsReferencedByARepeaterButNotAChildOfTargetProductY(): string { return this.getString('theproductxisreferencedbyarepeaterbutnotachildoftargetproducty');}
  get ThePreviousRowsXWereSuccessfullyImported(): string { return this.getString('thepreviousrowsxweresuccessfullyimported');}
  get ThereAlreadyExistsAnDecorationItemThatLinksTheAttrubuteX(): string { return this.getString('therealreadyexistsandecorationitemthatlinkstheattrubutex');}
  get ThereAlreadyExistsAnParameterWithNameXInProductY(): string { return this.getString('therealreadyexistsanparameterwithnamexinproducty');}
  get ThereAreEmptyXInTheGridConfirmRemoveIfYouWantToStore(): string { return this.getString('thereareemptyxinthegridconfirmremoveifyouwanttostore');}
  get ThereCanOnlyBeOneDefaultStateCurrentIsX(): string { return this.getString('therecanonlybeonedefaultstatecurrentisx');}
  get TheSourcePriceItemIsTheSameAsTheDestinationPriceItem(): string { return this.getString('thesourcepriceitemisthesameasthedestinationpriceitem');}
  get TheSystemIsInComplianceWithTheLicense(): string { return this.getString('thesystemisincompliancewiththelicense');}
  get ThisIsAProductionSite(): string { return this.getString('thisisaproductionsite');}
  get ThisWindowCanNotBeClosed(): string { return this.getString('thiswindowcannotbeclosed');}
  get ThousandsSeparator(): string { return this.getString('thousandsseparator');}
  get ThreeState(): string { return this.getString('threestate');}
  get TileHorizontally(): string { return this.getString('tilehorizontally');}
  get TileVertically(): string { return this.getString('tilevertically');}
  get TitleExpression(): string { return this.getString('titleexpression');}
  get TitleExpressionIsNotValid(): string { return this.getString('titleexpressionisnotvalid');}
  get TitleIsEmpty(): string { return this.getString('titleisempty');}
  get Tools(): string { return this.getString('tools');}
  get ToolTip(): string { return this.getString('tooltip');}
  get Top(): string { return this.getString('top');}
  get TopCenter(): string { return this.getString('topcenter');}
  get TopLeft(): string { return this.getString('topleft');}
  get TopRight(): string { return this.getString('topright');}
  get TotalCosts(): string { return this.getString('totalcosts');}
  get TotalNumberOfDELETEsPerformed(): string { return this.getString('totalnumberofdeletesperformed');}
  get TotalNumberOfINSERTsPerformed(): string { return this.getString('totalnumberofinsertsperformed');}
  get TotalNumberOfRowsProcessed(): string { return this.getString('totalnumberofrowsprocessed');}
  get TotalNumberOfTablesProcessed(): string { return this.getString('totalnumberoftablesprocessed');}
  get TotalNumberOfUPDATEsPerformed(): string { return this.getString('totalnumberofupdatesperformed');}
  get TotalPrices(): string { return this.getString('totalprices');}
  get TotalRow(): string { return this.getString('totalrow');}
  get ToTheKeyText(): string { return this.getString('tothekeytext');}
  get TrackedChanges(): string { return this.getString('trackedchanges');}
  get Translatable(): string { return this.getString('translatable');}
  get TranslatableAttributes(): string { return this.getString('translatableattributes');}
  get TranslateIfNoReplacementMarkers(): string { return this.getString('translateifnoreplacementmarkers');}
  get TranslateIfNoReplacementMarkersDescription(): string { return this.getString('translateifnoreplacementmarkersdescription');}
  get TranslationCreated(): string { return this.getString('translationcreated');}
  get TranslationDeleteConfirmation(): string { return this.getString('translationdeleteconfirmation');}
  get TranslationExists(): string { return this.getString('translationexists');}
  get TranslationFileReadonly(): string { return this.getString('translationfilereadonly');}
  get TranslationFiles(): string { return this.getString('translationfiles');}
  get TranslationFilesExport(): string { return this.getString('translationfilesexport');}
  get TranslationFilesFolderPath(): string { return this.getString('translationfilesfolderpath');}
  get TranslationFilesGridColKeys(): string { return this.getString('translationfilesgridcolkeys');}
  get TranslationFilesImport(): string { return this.getString('translationfilesimport');}
  get TranslationFilesImportSuccess(): string { return this.getString('translationfilesimportsuccess');}
  get TranslationFilesInvalidPath(): string { return this.getString('translationfilesinvalidpath');}
  get TranslationFolderAccessError(): string { return this.getString('translationfolderaccesserror');}
  get TranslationFolderOpen(): string { return this.getString('translationfolderopen');}
  get TranslationFolderOpenClick(): string { return this.getString('translationfolderopenclick');}
  get TranslationImportSuccessfullyFinished(): string { return this.getString('translationimportsuccessfullyfinished');}
  get Translations(): string { return this.getString('translations');}
  get TranslationsHasMaximumLengthOfXCharactersForTheFollowingStringsY(): string { return this.getString('translationshasmaximumlengthofxcharactersforthefollowingstringsy');}
  get Trigger(): string { return this.getString('trigger');}
  get TriggeringParametersAttributes(): string { return this.getString('triggeringparametersattributes');}
  get TriggerParameters(): string { return this.getString('triggerparameters');}
  get TriggerValues(): string { return this.getString('triggervalues');}
  get Turquoise(): string { return this.getString('turquoise');}
  get TypeRequiredForItems(): string { return this.getString('typerequiredforitems');}
  get TypeRequiredForRepeater(): string { return this.getString('typerequiredforrepeater');}
  get TypeXDoesNotImplementInterfaceISettingEditor(): string { return this.getString('typexdoesnotimplementinterfaceisettingeditor');}
  get UnableToConnectToTheWebServer(): string { return this.getString('unabletoconnecttothewebserver');}
  get Unchecked(): string { return this.getString('unchecked');}
  get Underline(): string { return this.getString('underline');}
  get Unit(): string { return this.getString('unit');}
  get UnitAngleTrigonometricFunctions(): string { return this.getString('unitangletrigonometricfunctions');}
  get UnitAngleTrigonometricFunctionsDescription(): string { return this.getString('unitangletrigonometricfunctionsdescription');}
  get Unlimited(): string { return this.getString('unlimited');}
  get UnlinkFromFolder(): string { return this.getString('unlinkfromfolder');}
  get UnlinkRootItem(): string { return this.getString('unlinkrootitem');}
  get Update(): string { return this.getString('update');}
  get UpdateMethod(): string { return this.getString('updatemethod');}
  get UnderlineTheText(): string { return this.getString('underlinethetext');}
  get Up(): string { return this.getString('up');}
  get UpgradeMessage(): string { return this.getString('upgrademessage');}
  get UpgradeConfirmationMessage(): string { return this.getString('upgradeconfirmationmessage');}
  get UpgradeConfirmationMessageInDataModelManager(): string { return this.getString('upgradeconfirmationmessageindatamodelmanager');}
  get UpgradeProductionConnectionEOClassTableDifferentFromX(): string { return this.getString('upgradeproductionconnectioneoclasstabledifferentfromx');}
  get UpgradeProductionConnectionInvalid(): string { return this.getString('upgradeproductionconnectioninvalid');}
  get UpgradeProductionConnectionSynonymDifferentFromX(): string { return this.getString('upgradeproductionconnectionsynonymdifferentfromx');}
  get UploadDocument(): string { return this.getString('uploaddocument');}
  get UploadFile(): string { return this.getString('uploadfile');}
  get UploadImage(): string { return this.getString('uploadimage');}
  get UpperCaseWithUnderscore(): string { return this.getString('uppercasewithunderscore');}
  get Url(): string { return this.getString('url');}
  get UrlVaultSvgImagesAndArchitectPreviewing(): string { return this.getString('urlvaultsvgimagesandarchitectpreviewing');}
  get User(): string { return this.getString('user');}
  get UserAccounts(): string { return this.getString('useraccounts');}
  get UserAbort(): string { return this.getString('userabort');}
  get UserAuthorizationGroupLink(): string { return this.getString('userauthorizationgrouplink');}
  get UsernameAlreadyTakenPleaseTryAnotherOne(): string { return this.getString('usernamealreadytakenpleasetryanotherone');}
  get UsernameCannotContainWhiteSpace(): string { return this.getString('usernamecannotcontainwhitespace');}
  get UserNameForSMTPHost(): string { return this.getString('usernameforsmtphost');}
  get UserLicenseOverview(): string { return this.getString('userlicenseoverview');}
  get UseSQLServerAuthentication(): string { return this.getString('usesqlserverauthentication');}
  get UseUsersWorkGroupWhenRevise(): string { return this.getString('useusersworkgroupwhenrevise');}
  get ValidCharactersMayAlsoBeDigits(): string { return this.getString('validcharactersmayalsobedigits');}
  get ValidFrom(): string { return this.getString('validfrom');}
  get ValidUntil(): string { return this.getString('validuntil');}
  get Value(): string { return this.getString('value');}
  get Values(): string { return this.getString('values');}
  get ValueCannotBeBiggerThan(): string { return this.getString('valuecannotbebiggerthan');}
  get ValueCannotBeLesserThan(): string { return this.getString('valuecannotbelesserthan');}
  get ValueDoesNotContainAnyValue(): string { return this.getString('valuedoesnotcontainanyvalue');}
  get ValueExpression(): string { return this.getString('valueexpression');}
  get ValueExpressionIsNotValid(): string { return this.getString('valueexpressionisnotvalid');}
  get ValueIconWidth(): string { return this.getString('valueiconwidth');}
  get ValueIconHeight(): string { return this.getString('valueiconheight');}
  get ValueIsNotAValidBoolean(): string { return this.getString('valueisnotavalidboolean');}
  get ValueIsNotAValidDouble(): string { return this.getString('valueisnotavaliddouble');}
  get ValueIsNotAValidInteger(): string { return this.getString('valueisnotavalidinteger');}
  get ValueIsNotDouble(): string { return this.getString('valueisnotdouble');}
  get ValueRequiredForItems(): string { return this.getString('valuerequiredforitems');}
  get ValueType(): string { return this.getString('valuetype');}
  get ValueXDoesNotContainAnyValue(): string { return this.getString('valuexdoesnotcontainanyvalue');}
  get ValueXIsNotAValidDouble(): string { return this.getString('valuexisnotavaliddouble');}
  get ValueXIsNotAValidInteger(): string { return this.getString('valuexisnotavalidinteger');}
  get ValueXIsNotWithinParametersBoundsValueMustBeBetweenYAndZ(): string { return this.getString('valuexisnotwithinparametersboundsvaluemustbebetweenyandz');}
  get ValueXIsNotWithinParametersBoundsValueMustBeGreaterOrEqualToY(): string { return this.getString('valuexisnotwithinparametersboundsvaluemustbegreaterorequaltoy');}
  get ValueXIsNotWithinParametersBoundsValueMustBeLesserOrEqualToY(): string { return this.getString('valuexisnotwithinparametersboundsvaluemustbelesserorequaltoy');}
  get Variants(): string { return this.getString('variants');}
  get VatNumber(): string { return this.getString('vatnumber');}
  get VaultDocumentsPath(): string { return this.getString('vaultdocumentspath');}
  get VaultImagesPath(): string { return this.getString('vaultimagespath');}
  get VaultOperationTimeout(): string { return this.getString('vaultoperationtimeout');}
  get VaultOperationTimeoutDesc(): string { return this.getString('vaultoperationtimeoutdesc');}
  get VaultVisualizationsPath(): string { return this.getString('vaultvisualizationspath');}
  get VaultUploadsPath(): string { return this.getString('vaultuploadspath');}
  get VaultUrl(): string { return this.getString('vaulturl');}
  get VaultUrlNotDefined(): string { return this.getString('vaulturlnotdefined');}
  get VerificationCode(): string { return this.getString('verificationcode');}
  get VerificationCodeRequestSignUpDescription(): string { return this.getString('verificationcoderequestsignupdescription');}
  get VerificationCodeRequestRecoverDescription(): string { return this.getString('verificationcoderequestrecoverdescription');}
  get VerificationCodeResponseDescription(): string { return this.getString('verificationcoderesponsedescription');}
  get VerificationCodeIsExpired(): string { return this.getString('verificationcodeisexpired');}
  get VerificationCodeInvalid(): string { return this.getString('verificationcodeinvalid');}
  get VerificationCodeEmailTemplate(): string { return this.getString('verificationcodeemailtemplate');}
  get Verify(): string { return this.getString('verify');}
  get Version(): string { return this.getString('version');}
  get Vertical(): string { return this.getString('vertical');}
  get VerticalAlignment(): string { return this.getString('verticalalignment');}
  get VerticalWithIcons(): string { return this.getString('verticalwithicons');}
  get VerticalWithSelectedHelpImageRight(): string { return this.getString('verticalwithselectedhelpimageright');}
  get VersionInformation(): string { return this.getString('versioninformation');}
  get ViewAttachment(): string { return this.getString('viewattachment');}
  get ViewLicenseInformationOrInstall(): string { return this.getString('viewlicenseinformationorinstall');}
  get Violet(): string { return this.getString('violet');}
  get Visible(): string { return this.getString('visible');}
  get WarnIfParameterWithSameNameExists(): string { return this.getString('warnifparameterwithsamenameexists');}
  get WarningDataSelectorDataLoss(): string { return this.getString('warningdataselectordataloss');}
  get WebAddress(): string { return this.getString('webaddress');}
  get WebAppDefaultLanguage(): string { return this.getString('webappdefaultlanguage');}
  get WebServiceUrl(): string { return this.getString('webserviceurl');}
  get WhenSetToTrueZeroGrandTotalWillBeHiddenInNonCompositeModeWhenQuantityEquals(): string { return this.getString('whensettotruezerograndtotalwillbehiddeninnoncompositemodewhenquantityequals');}
  get WhenSetToTrueZeroPricesItemsCategoriesWillBeSuppressedFromReport(): string { return this.getString('whensettotruezeropricesitemscategorieswillbesuppressedfromreport');}
  get WhenSetToTrueZeroPricesIitemsCategoriesWillBeHiddenInReport(): string { return this.getString('whensettotruezeropricesiitemscategorieswillbehiddeninreport');}
  get WhenSetToTrueExpressionDependenciesAreCheckedBeforeEvaluation(): string { return this.getString('whensettotrueexpressiondependenciesarecheckedbeforeevaluation');}
  get WebServiceMethodName(): string { return this.getString('webservicemethodname');}
  get WelcomeMessageAlreadyExists(): string { return this.getString('welcomemessagealreadyexists');}
  get Width(): string { return this.getString('width');}
  get WidthCm(): string { return this.getString('widthcm');}
  get WidthIsNotAValidDouble(): string { return this.getString('widthisnotavaliddouble');}
  get WidthIsNotAValidInteger(): string { return this.getString('widthisnotavalidinteger');}
  get ViewDocument(): string { return this.getString('viewdocument');}
  get Wide(): string { return this.getString('wide');}
  get WillOverwriteHelpImageOrIcon(): string { return this.getString('willoverwritehelpimageoricon');}
  get Windows(): string { return this.getString('windows');}
  get Word2007(): string { return this.getString('word2007');}
  get Word2013(): string { return this.getString('word2013');}
  get Word2013_2007(): string { return this.getString('word2013_2007');}
  get WorkGroupDescription(): string { return this.getString('workgroupdescription');}
  get WorkGroupFolder(): string { return this.getString('workgroupfolder');}
  get WorkGroups(): string { return this.getString('workgroups');}
  get WouldYouLikeToLinkObject(): string { return this.getString('wouldyouliketolinkobject');}
  get WouldYouLikeToPasteObject(): string { return this.getString('wouldyouliketopasteobject');}
  get WouldYouLikeToCloneObject(): string { return this.getString('wouldyouliketocloneobject');}
  get WouldYouLikeMoveObject(): string { return this.getString('wouldyoulikemoveobject');}
  get WouldYouLikeToRemoveObject(): string { return this.getString('wouldyouliketoremoveobject');}
  get WrongNumberOfStatesInImportData(): string { return this.getString('wrongnumberofstatesinimportdata');}
  get X1(): string { return this.getString('x1');}
  get X2(): string { return this.getString('x2');}
  get XAlreadyExistsInThisAuthorizationGroup(): string { return this.getString('xalreadyexistsinthisauthorizationgroup');}
  get XAlreadyExistsInThisProduct(): string { return this.getString('xalreadyexistsinthisproduct');}
  get XAlreadyExistsInThisTab(): string { return this.getString('xalreadyexistsinthistab');}
  get XCouldNotBeCopiedBecauseTheseAreMissingInTheDestinationProduct(): string { return this.getString('xcouldnotbecopiedbecausethesearemissinginthedestinationproduct');}
  get XCouldNotBeCopiedDueToMissingYInTheDestinationProduct(): string { return this.getString('xcouldnotbecopiedduetomissingyinthedestinationproduct');}
  get XFound(): string { return this.getString('xfound');}
  get XIsAlreadyReferenced(): string { return this.getString('xisalreadyreferenced');}
  get XIsNotAChildOfDestinationParagraphsRepeaterProductY(): string { return this.getString('xisnotachildofdestinationparagraphsrepeaterproducty');}
  get XIsNotAValidDateTime(): string { return this.getString('xisnotavaliddatetime');}
  get XIsNotAValidDouble(): string { return this.getString('xisnotavaliddouble');}
  get XIsNotAValidInteger(): string { return this.getString('xisnotavalidinteger');}
  get XOfY(): string { return this.getString('xofy');}
  get Y1(): string { return this.getString('y1');}
  get Y2(): string { return this.getString('y2');}
  get Yellow(): string { return this.getString('yellow');}
  get YesForAdministrators(): string { return this.getString('yesforadministrators');}
  get YesForEveryone(): string { return this.getString('yesforeveryone');}
  get YesForSuperUsers(): string { return this.getString('yesforsuperusers');}
  get YesForSuperUsersAndAdministrators(): string { return this.getString('yesforsuperusersandadministrators');}
  get YesToAll(): string { return this.getString('yestoall');}
  get Alignment(): string { return this.getString('alignment');}
  get AlwaysStoreBOM(): string { return this.getString('alwaysstorebom');}
  get AlwaysStoreBOMDescription(): string { return this.getString('alwaysstorebomdescription');}
  get AreYouSureYouWantToPasteSelectedItemsToX(): string { return this.getString('areyousureyouwanttopasteselecteditemstox');}
  get AttributeRule(): string { return this.getString('attributerule');}
  get AttributeRules(): string { return this.getString('attributerules');}
  get ClearProductStructureCaches(): string { return this.getString('clearproductstructurecaches');}
  get ConfiguredItemStructureOfX(): string { return this.getString('configureditemstructureofx');}
  get ConfigureItemStructure(): string { return this.getString('configureitemstructure');}
  get CostExpressionIsNotValid(): string { return this.getString('costexpressionisnotvalid');}
  get ErrorWritingXMLToFile(): string { return this.getString('errorwritingxmltofile');}
  get ExpressionIsNotValidCorrectBeforeClosing(): string { return this.getString('expressionisnotvalidcorrectbeforeclosing');}
  get ExpressionNotValidCorrectBeforeClosing(): string { return this.getString('expressionnotvalidcorrectbeforeclosing');}
  get ExceptionChildrenWithPriceCategories(): string { return this.getString('exceptionchildrenwithpricecategories');}
  get ExceptionContainsItem(): string { return this.getString('exceptioncontainsitem');}
  get ExceptionContainsItems(): string { return this.getString('exceptioncontainsitems');}
  get ValidExpressionExceptionDetails(): string { return this.getString('validexpressionexceptiondetails');}
  get ExceptionUpdateValidExpressions(): string { return this.getString('exceptionupdatevalidexpressions');}
  get ExceptionUpdateValidExpression(): string { return this.getString('exceptionupdatevalidexpression');}
  get ImportExistingConfigurationInfo(): string { return this.getString('importexistingconfigurationinfo');}
  get ImportPriceItems(): string { return this.getString('importpriceitems');}
  get InclusionRule(): string { return this.getString('inclusionrule');}
  get IsConfigurable(): string { return this.getString('isconfigurable');}
  get IsFictitious(): string { return this.getString('isfictitious');}
  get Item(): string { return this.getString('item');}
  get ItemNo(): string { return this.getString('itemno');}
  get ItemNumber(): string { return this.getString('itemnumber');}
  get ItemStructure(): string { return this.getString('itemstructure');}
  get Keywords(): string { return this.getString('keywords');}
  get LinkItems(): string { return this.getString('linkitems');}
  get ManuallyAdded(): string { return this.getString('manuallyadded');}
  get MouseClickEdit(): string { return this.getString('mouseclickedit');}
  get MouseDragEdit(): string { return this.getString('mousedragedit');}
  get MousePressExpressionEditor(): string { return this.getString('mousepressexpressioneditor');}
  get MustEnterAnExpressionForAQuantityRule(): string { return this.getString('mustenteranexpressionforaquantityrule');}
  get MustEnterAnExpressionForAStructureAttributeRule(): string { return this.getString('mustenteranexpressionforastructureattributerule');}
  get NoConfiguredItemStructureToExport(): string { return this.getString('noconfigureditemstructuretoexport');}
  get NoActiveUserAccountFound(): string { return this.getString('noactiveuseraccountfound');}
  get NumericRule(): string { return this.getString('numericrule');}
  get PastingItems(): string { return this.getString('pastingitems');}
  get PleaseEnterANumericValueForX(): string { return this.getString('pleaseenteranumericvalueforx');}
  get PleaseSelectAnAttribute(): string { return this.getString('pleaseselectanattribute');}
  get PriceExpressionIsNotValid(): string { return this.getString('priceexpressionisnotvalid');}
  get PleaseChooseNewPasswordDescription(): string { return this.getString('pleasechoosenewpassworddescription');}
  get QuantityChange(): string { return this.getString('quantitychange');}
  get QuantityRule(): string { return this.getString('quantityrule');}
  get QuantityRules(): string { return this.getString('quantityrules');}
  get QuantityUnit(): string { return this.getString('quantityunit');}
  get Rev(): string { return this.getString('rev');}
  get RenderForParent(): string { return this.getString('renderforparent');}
  get RootItem(): string { return this.getString('rootitem');}
  get RootItemWillBeReplaced(): string { return this.getString('rootitemwillbereplaced');}
  get Rule(): string { return this.getString('rule');}
  get SameAsShipping(): string { return this.getString('sameasshipping');}
  get SaveExportResult(): string { return this.getString('saveexportresult');}
  get SelectAnAttributeRule(): string { return this.getString('selectanattributerule');}
  get SelectAQuantityRule(): string { return this.getString('selectaquantityrule');}
  get StructureAttribute(): string { return this.getString('structureattribute');}
  get StructureTree(): string { return this.getString('structuretree');}
  get StructureTreeTooltip(): string { return this.getString('structuretreetooltip');}
  get ValueMustBeADouble(): string { return this.getString('valuemustbeadouble');}
  get XItemsSelected(): string { return this.getString('xitemsselected');}
  get YesMultiLevel(): string { return this.getString('yesmultilevel');}
  get YesSingleLevel(): string { return this.getString('yessinglelevel');}
  get YouAreAboutToOverrideStoreBOMSettingX(): string { return this.getString('youareabouttooverridestorebomsettingx');}
  get CannotDeleteSymbolBecaseOfReferences(): string { return this.getString('cannotdeletesymbolbecaseofreferences');}
  get CenterX(): string { return this.getString('centerx');}
  get CenterY(): string { return this.getString('centery');}
  get CircularReferencesOfSymbolsNotAllowed(): string { return this.getString('circularreferencesofsymbolsnotallowed');}
  get SvgDefaulLine(): string { return this.getString('svgdefaulline');}
  get SvgHorizontal(): string { return this.getString('svghorizontal');}
  get SvgVertical(): string { return this.getString('svgvertical');}
  get SvgDefaultCurve(): string { return this.getString('svgdefaultcurve');}
  get SvgSmooth(): string { return this.getString('svgsmooth');}
  get SvgQuadratic(): string { return this.getString('svgquadratic');}
  get SvgQuadraticSmooth(): string { return this.getString('svgquadraticsmooth');}
  get SvgEllipticalArc(): string { return this.getString('svgellipticalarc');}
  get SvgEllipticalArcSweep(): string { return this.getString('svgellipticalarcsweep');}
  get SvgEllipticalArcLarge(): string { return this.getString('svgellipticalarclarge');}
  get SvgEllipticalArcLargeSweep(): string { return this.getString('svgellipticalarclargesweep');}
  get SvgDescDefaulLine(): string { return this.getString('svgdescdefaulline');}
  get SvgDescDefaultCurve(): string { return this.getString('svgdescdefaultcurve');}
  get SvgDescHorizontal(): string { return this.getString('svgdeschorizontal');}
  get SvgDescVertical(): string { return this.getString('svgdescvertical');}
  get SvgDescSmooth(): string { return this.getString('svgdescsmooth');}
  get SvgDescQuadratic(): string { return this.getString('svgdescquadratic');}
  get SvgDescQuadraticSmooth(): string { return this.getString('svgdescquadraticsmooth');}
  get SvgDescEllipticalArc(): string { return this.getString('svgdescellipticalarc');}
  get SvgDescEllipticalArcSweep(): string { return this.getString('svgdescellipticalarcsweep');}
  get SvgDescEllipticalArcLarge(): string { return this.getString('svgdescellipticalarclarge');}
  get SvgDescEllipticalArcLargeSweep(): string { return this.getString('svgdescellipticalarclargesweep');}
  get DashLength(): string { return this.getString('dashlength');}
  get DashOffset(): string { return this.getString('dashoffset');}
  get DeltaAngle(): string { return this.getString('deltaangle');}
  get DistanceX(): string { return this.getString('distancex');}
  get DistanceY(): string { return this.getString('distancey');}
  get DotLength(): string { return this.getString('dotlength');}
  get EndAngle(): string { return this.getString('endangle');}
  get Expression(): string { return this.getString('expression');}
  get ExpressionAdd(): string { return this.getString('expressionadd');}
  get ExpressionAddRightClick(): string { return this.getString('expressionaddrightclick');}
  get ExpressionEdit(): string { return this.getString('expressionedit');}
  get ExpressionClear(): string { return this.getString('expressionclear');}
  get FillColor(): string { return this.getString('fillcolor');}
  get FlipX(): string { return this.getString('flipx');}
  get FlipY(): string { return this.getString('flipy');}
  get FontType(): string { return this.getString('fonttype');}
  get GapLength(): string { return this.getString('gaplength');}
  get GraphicPattern(): string { return this.getString('graphicpattern');}
  get GraphicsComposition(): string { return this.getString('graphicscomposition');}
  get GraphicObjectsWithInvalidRules(): string { return this.getString('graphicobjectswithinvalidrules');}
  get IsMandatory(): string { return this.getString('ismandatory');}
  get Layer(): string { return this.getString('layer');}
  get LineCap(): string { return this.getString('linecap');}
  get LineColor(): string { return this.getString('linecolor');}
  get LineType(): string { return this.getString('linetype');}
  get NoExpression(): string { return this.getString('noexpression');}
  get NoteGraphicWillBeCopiedButInvalidRulesCleared(): string { return this.getString('notegraphicwillbecopiedbutinvalidrulescleared');}
  get NoteItWillDeleteTheObjectAndThePreventingObjects(): string { return this.getString('noteitwilldeletetheobjectandthepreventingobjects');}
  get OffsetX(): string { return this.getString('offsetx');}
  get OffsetY(): string { return this.getString('offsety');}
  get OffsetNextX(): string { return this.getString('offsetnextx');}
  get OffsetNextY(): string { return this.getString('offsetnexty');}
  get PathInstructions(): string { return this.getString('pathinstructions');}
  get Pattern(): string { return this.getString('pattern');}
  get PatternFill(): string { return this.getString('patternfill');}
  get PatternStroke(): string { return this.getString('patternstroke');}
  get PatternContentUnit(): string { return this.getString('patterncontentunit');}
  get PatternUnit(): string { return this.getString('patternunit');}
  get PatternUnitUserSpaceOnUse(): string { return this.getString('patternunituserspaceonuse');}
  get PatternUnitObjectBoundingBox(): string { return this.getString('patternunitobjectboundingbox');}
  get PatternUnitDescUserSpaceOnUse(): string { return this.getString('patternunitdescuserspaceonuse');}
  get PatternUnitDescObjectBoundingBox(): string { return this.getString('patternunitdescobjectboundingbox');}
  get PhysicalWidthMM(): string { return this.getString('physicalwidthmm');}
  get PhysicalHeightMM(): string { return this.getString('physicalheightmm');}
  get PNGFormat(): string { return this.getString('pngformat');}
  get Placements(): string { return this.getString('placements');}
  get ProductName(): string { return this.getString('productname');}
  get ProductGraphic(): string { return this.getString('productgraphic');}
  get ProductGraphicExists(): string { return this.getString('productgraphicexists');}
  get Radius(): string { return this.getString('radius');}
  get RadiusX(): string { return this.getString('radiusx');}
  get RadiusY(): string { return this.getString('radiusy');}
  get RasterWidth(): string { return this.getString('rasterwidth');}
  get RasterHeight(): string { return this.getString('rasterheight');}
  get ReferenceSymbol(): string { return this.getString('referencesymbol');}
  get Repetitions(): string { return this.getString('repetitions');}
  get Rotate(): string { return this.getString('rotate');}
  get RotateToPath(): string { return this.getString('rotatetopath');}
  get RulesInCopiedGraphicObjectsIncludesParametersOrAttributesThatAreMissingInTheDestinationProducts(): string { return this.getString('rulesincopiedgraphicobjectsincludesparametersorattributesthataremissinginthedestinationproducts');}
  get RX(): string { return this.getString('rx');}
  get RY(): string { return this.getString('ry');}
  get ShowFolderStructure(): string { return this.getString('showfolderstructure');}
  get ShowSymbolsOnly(): string { return this.getString('showsymbolsonly');}
  get SliceOrMeet(): string { return this.getString('sliceormeet');}
  get StartAngle(): string { return this.getString('startangle');}
  get Stroke(): string { return this.getString('stroke');}
  get StrokeDashDotLength(): string { return this.getString('strokedashdotlength');}
  get StrokeDashGapLength(): string { return this.getString('strokedashgaplength');}
  get StrokeDashLineLength(): string { return this.getString('strokedashlinelength');}
  get StrokeDashOffset(): string { return this.getString('strokedashoffset');}
  get StrokeWidth(): string { return this.getString('strokewidth');}
  get SVGFormat(): string { return this.getString('svgformat');}
  get Symbol(): string { return this.getString('symbol');}
  get SymbolPlacement(): string { return this.getString('symbolplacement');}
  get ViewBox(): string { return this.getString('viewbox');}
  get ViewBoxHeight(): string { return this.getString('viewboxheight');}
  get ViewBoxMinX(): string { return this.getString('viewboxminx');}
  get ViewBoxMinY(): string { return this.getString('viewboxminy');}
  get ViewBoxWidth(): string { return this.getString('viewboxwidth');}
  get ViewPort(): string { return this.getString('viewport');}
  get TextAlignment(): string { return this.getString('textalignment');}
  get TextColor(): string { return this.getString('textcolor');}
  get X(): string { return this.getString('x');}
  get Y(): string { return this.getString('y');}
}