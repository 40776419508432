import { Component, Inject, ElementRef } from "@angular/core";
import { DataCellView, DataRowView, SizeUtility } from "../../../../shared/components/shared";
import { AttributeDecoration, AttributeDecorationItem, ConfAttributeValue, ConfUIItem, AttributeUISettings } from "../../../shared/models";
import { ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { VisualObjectVisibilityService } from "../../shared";
import { DecorationComponent } from "../../shared/decorationComponent";
import { AttributeColumnType } from "./attributeColumnType";
import { VisualObjectHelper } from "../../parameters/shared";


@Component({
  selector: 'attribute-decoration',
  templateUrl: './attributeDecorationComponent.html'
})
export class AttributeDecorationComponent extends DecorationComponent<AttributeDecoration> {
  public activeAttributeUISetting: AttributeUISettings;
  public attributeSpacing: string;
  public attributeStyle: string;
  confValueById: Map<number, ConfAttributeValue>;

  constructor(
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(VisualObjectHelper) public visualObjectHelper: VisualObjectHelper,
    public visualObjectVisibilityService: VisualObjectVisibilityService, private ref: ElementRef
  ) {
    super(confStore, confPageSessionService, visualObjectHelper);
  }

  ngOnInit() {
    
    this.width = this.visualObjectHelper.visualObjectWidth(this.decoration);      

    this.activeAttributeUISetting = new AttributeUISettings();

    let confValueById: Map<number, ConfAttributeValue> = this.confStore.getConfValues<ConfAttributeValue>(this.configuration.longId, this.confSessionId, this.itemIds);

    //Object.keys(confValueById).map(key => confValueById.get(+key))
    // Set values on load.    
    this.setValue(confValueById);
    super.ngOnInit();
  }

  updateActiveAttributeUISetting(): void {

    // Percentage width means most likely small screen
    let widthAsNumber = SizeUtility.sizeAsNumber(this.width);

    if (this.ref && this.ref.nativeElement) {
      let rect = this.ref.nativeElement.getBoundingClientRect();

      if (widthAsNumber > rect.width)
        widthAsNumber = rect.width;
    }

    this.activeAttributeUISetting = new AttributeUISettings();

    // Make a shallow copy by using slice method and then reverse the array, If we make direct reverse then It would change the orignal array data.
    let attributeuiSettings = this.uiSettings.configurator.decoration.attribute.slice().reverse();
    for (let x of attributeuiSettings) {

      let minWdithAsNumber = SizeUtility.sizeAsNumber(x.minWidth);
      let maxWdithAsNumber = SizeUtility.sizeAsNumber(x.maxWidth);

      // Condtions to get active UISetting
      // 1. decoration width is undefined 
      // 2. or minWidth of decoration is defined in UISettings but not maxWidth
      // 3. or both minWidth, maxWidth  are defined.      
      if (!widthAsNumber || (widthAsNumber >= minWdithAsNumber && !maxWdithAsNumber) || (widthAsNumber >= minWdithAsNumber && widthAsNumber <= maxWdithAsNumber)) {
        if (x.decorationIds == null || x.decorationIds.length < 1 || x.decorationIds != null && x.decorationIds.indexOf(this.decoration.longId) > -1) {
          this.activeAttributeUISetting = x;
          break;
        }
      }
    };

    this.attributeSpacing = this.activeAttributeUISetting.itemVerticalSpacing;
    this.attributeStyle = this.activeAttributeUISetting.cssStyle;
  }

  setValue(itemValues: Map<number, any>): void {

    this.rows = [];

    let confUIItem = this.visualObjectVisibilityService.findConfUIItem(this.configuration, this.decoration.longId);
    // Decoration
    let attrDecoration: AttributeDecoration = this.decoration as AttributeDecoration;

    // Go through all items and see if we need to display title/value/unit. 
    // It will display only the relevant columns, but if any value has a column then all rows must add it.
    let showTitle = false, showValue = false, showUnit = false;
    attrDecoration.items.forEach(item => {

      if (this.isHidden(item, confUIItem))
        return;

      let confAttrValue: ConfAttributeValue = itemValues.get(+item.attributeId);

      if (confAttrValue == null)
        return;

      showTitle = showTitle || confAttrValue.title != null && confAttrValue.title.length > 0;
      showUnit = showUnit || confAttrValue.unit != null && confAttrValue.unit.length > 0;
      showValue = showValue || confAttrValue.value != null && confAttrValue.value.length > 0;
    });

    attrDecoration.items.forEach(item => {

      if (this.isHidden(item, confUIItem))
        return;

      let confAttrValue: ConfAttributeValue = itemValues.get(+item.attributeId);

      if (confAttrValue == null)
        return;

      // Read formatted value
      let value: string = (item.numericFormat && item.numericFormat.length > 0) ? confAttrValue.valueByNumericFormat.get(item.numericFormat) : confAttrValue.value;

      // Create row model
      let row: DataRowView = { cells: []};

      // Special case: If title is missing and value type is string then merge three columns.
      if (confAttrValue.valueType == "String" && value != "" && (confAttrValue.title == null || confAttrValue.title == '')) {

        // Fills the entire width
        let fillCell: DataCellView = { contents: value, classes: "text-break" };
        fillCell.tag = AttributeColumnType.Value;
        row.cells.push(fillCell);

        // Add row to list.
        this.rows.push(row);

        return;
      }

      // Add title
      if (showTitle) {

        let titleCell: DataCellView = {
          contents: confAttrValue.title,
          classes: "title text-break ",
          tag: AttributeColumnType.Title
        };

        row.cells.push(titleCell);
      }
      // Add value & unit
      if (showValue) {

        // Set the value to empty string if it is undefined, because in I.E it shows null.
        if (!value)
          value = "";

        // Value
        let extraPaddingStyle: string = showUnit ? 'pr-2' : ''; // If unit is shown then try to move the value near to unit.
        let valueCell: DataCellView = { contents: value, classes: `col-value text-break ${extraPaddingStyle} text-right`, tag: AttributeColumnType.Value };        
        row.cells.push(valueCell);

        // Unit, show its value only for non-empty value    
        if (showUnit && value) {

          let unit: string = confAttrValue.unit;

          // Set the value to empty string if it is undefined, because in I.E it shows null.
          if (!unit)
            unit = '&nbsp;';

          let unitCell: DataCellView = { contents: unit, classes: 'pl-0 pr-3 col-unit', tag: AttributeColumnType.Unit };
          row.cells.push(unitCell);
        }
      }

      // Add row to list.
      this.rows.push(row);
    });

    this.updateActiveAttributeUISetting();

    // Set the size classes
    this.setcolumnSizes();      
  }

  setcolumnSizes() {

      for (let rowIndex = 0; rowIndex < this.rows.length; rowIndex++) {

        let row = this.rows[rowIndex];
        for (let colIndex = 0; colIndex < row.cells.length; colIndex++) {

          // Don't set any style.
          if (row.cells.length == 1)
            continue;
         
          row.cells[colIndex].size = this.getColumnSize(row, colIndex);        
        }
      }    
  }

  getColumnSizeByType(colType: string) {

    switch (colType) {

      case AttributeColumnType.Title:
        return this.activeAttributeUISetting.titleSize;

      case AttributeColumnType.Value:
        return this.activeAttributeUISetting.valueSize;

      case AttributeColumnType.Unit:
        return this.activeAttributeUISetting.unitSize;

      default:
        return 0;

    }

  }

  getColumnSize(row: DataRowView, colIndex: number): number {

    let columnType = row.cells[colIndex].tag as AttributeColumnType;
    if (!columnType)
      return null;

    let colSizeAsNumber = this.getColumnSizeByType(columnType);    
    if (!colSizeAsNumber)
      return null;

    return colSizeAsNumber;

  }

  public get itemIds(): number[] {

    return this.decoration.items.map(x => x.attributeId).toArray();
  }

  protected isHidden(item: AttributeDecorationItem, confUIItem: ConfUIItem): boolean {

    if (!confUIItem)
      return false;

    return !confUIItem.items.find(x => x.id == item.attributeId);
  }
}