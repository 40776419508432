import { Injectable } from "@angular/core";
import { TabContainerComponent } from "./tabContainerComponent";


/** It keeps the tab contents in waiting untill all tabs/areas are calculated.  **/
@Injectable()
export class TabAvailabilityService {

  private readyStatus: Map<string, boolean> = new Map<string, boolean>();
  private containers: Array<TabContainerComponent> = new Array<TabContainerComponent>(); 

  public addContainerId(id: string): void {

    for (let c of this.containers)
      // If id already exists It means container is alive, don't need to add it again.
      if (c.containerId == id)
        return;

    this.readyStatus.set(id, false);
  }
  
  notifyToAllIfSatisfy(): void {

    // All containers are not added yet, wait for next requests.
    let array = Array.from(this.readyStatus.values());
    if (!array.every(value => value))
      return;
    
    this.containers.forEach(x => x.ready());
        
  }

  onViewReady(container: TabContainerComponent): void {
    this.containers.push(container);
    this.readyStatus.set(String(container.containerId), true);
    this.notifyToAllIfSatisfy();
  }

  //destroy(): void {   
  //  this.containers = [];
  //}

  onDestroy(container: TabContainerComponent): void {

    let newMap = new Map<string, boolean>();
    Array.from(this.readyStatus.keys()).forEach(
      key =>
    {

      if (container.containerId != key)
        newMap.set(key, this.readyStatus.get(key))

    });

    this.readyStatus = newMap;
    this.containers = this.containers.filter(x => x != container);

  }

}