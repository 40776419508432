import { Inject, Component } from "@angular/core";
import { ConfigurationSessionManager, ConfiguratorStore, ConfPageSessionService } from "../providers";
import * as Immutable from 'immutable';
import { SummaryBaseComponent } from "./summaryBaseComponent";
import { ConfUIItem, CodeDisplayStyles, CodeDecoration, GroupConfUIItem, DocumentsDecoration } from "../../shared/models";
import { ProductDataStore } from "../../shared/providers/productData";
import { CodeValueModelBuilder } from "../../shared/components/code/providers/codeValueModelBuilder";
import { CodeDecorationValuesModel } from "../../shared/components/code/codeDecorationValueModel";
import { GlobalDataStore } from "../../shared/providers/globalData/globalDataStore";
import { VisualObjectUIDataService } from "../shared/visualObjectUIDataService";


export enum ActionBoxNames {
  Operations = 'operations',
  States = 'states',
  Revisions = 'revisions',
  Reports = 'reports',
  Code = 'code',
  Documents = 'documents',
  Graphics = 'graphics',
  Attachments = 'attachments'
}

@Component({
  selector: 'summary-action-boxes',
  templateUrl: './summaryActionsBoxesComponent.html',
  providers: [VisualObjectUIDataService]
})
export class SummaryActionsBoxesComponent extends SummaryBaseComponent {
  actionBoxNames = ActionBoxNames;

  codeUIItems: Immutable.List<ConfUIItem> = Immutable.List<ConfUIItem>();
  documentsUIItems: Immutable.List<ConfUIItem> = Immutable.List<ConfUIItem>();
  orderList: Immutable.List<string> = Immutable.List<string>();

  constructor(@Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(CodeValueModelBuilder) public builder: CodeValueModelBuilder,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore) {
    super(confPageSessionService);
  }

  // List of each decoration values.
  decorationValuesModels: Immutable.List<CodeDecorationValuesModel> = Immutable.List<CodeDecorationValuesModel>();

  onDataReady(): void {

    this.orderList = this.globalDataStore.globalSettings.summaryActionBoxesDisplayOrder;

    // TODO: This part requires more improvement.
    const uiItems: Array<ConfUIItem> = this.confPageSessionService.activeConfiguration.uiItems.filter(uiItem => uiItem.itemName == CodeDecoration.name).toArray();

    if (this.globalDataStore.globalSettings.displayDocumentsInOperationsColumn)
      this.documentsUIItems = this.confPageSessionService.activeConfiguration.uiItems.filter(uiItem => uiItem.itemName == DocumentsDecoration.name).toList();

    const codeFilteredStyles = [CodeDisplayStyles.DownloadLink, CodeDisplayStyles.Popup];

    this.decorationValuesModels = this.decorationValuesModels.clear();

    uiItems.forEach(x => {

      // Note! Grouping is not supported yet for action boxes. We need more work on this part.
      let ValuesModels = this.builder.build(x, false, codeFilteredStyles).toArray();
      this.decorationValuesModels = this.decorationValuesModels.push(...ValuesModels);


    });

      
    


  }



}