<div *ngIf="!backgroundImage" class="visual-object-icon rounded-circle {{imageSet}}" [style.width.px]="size" [style.height.px]="size">
  <co-image [width]="iconSize + 'px'" [height]="iconSize + 'px'" [key]="paramType" [imageSet]="imageSet" class="image"></co-image>
</div>

<div *ngIf="backgroundImage" class="visual-object-icon {{imageSet}}">
  <div style="position:relative;">
    <co-image [width]="size + 'px'" [height]="size + 'px'" [key]="backgroundImage" [imageSet]="imageSet" class="image"></co-image>
    <div style="position:absolute; top: 14px; left: 14px;line-height:0">
      <co-image [width]="'12'" [height]="'12'" [key]="paramType" [imageSet]="imageSet" ></co-image>
    </div>
  </div>
</div>