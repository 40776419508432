import * as Immutable from "immutable";
import { Decoration } from "./decoration";

export class TextDecoration extends Decoration {

  protected readonly TEXT = "text";

  get text(): string { return this.getInternalValue<string>(this.TEXT); }
  settext(text: string): this { return this.setInternalValue(this.TEXT, text); }

}