import { Inject, Injectable } from "@angular/core"
import { normalize } from "normalizr";
import * as Immutable from "immutable"

import { BaseEntity } from "../../baseEntity"
import { AbstractConfigurationMessage } from "../../models/responses/messages"
import { ModelFactory } from "../modelFactory"
import { Ioc, Register } from "../../../../shared/ioc/iocdecorator"
import { ConfDataResponse } from "../../models/responses"
import { ConfInfoResult } from "../../models/responses"
import { NormalizerService } from "../../../../shared/providers/normalizer"
import {
  ConfInfo, SearchDataResponse, PaginationInfo, SortingInfo,
  ConfSearchResultItem, SearchResult, ConfigurationSearch, AbstractSearchDataMessage
} from "../../models";
import { ConfDataNormalizer } from "../configurationData/confDataNormalizer";

@Injectable()
export class SearchDataNormalizer {

  constructor(
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    @Inject(ConfDataNormalizer) public confDataNormalizer: ConfDataNormalizer,
    @Inject(NormalizerService) public normalizerService: NormalizerService
  ) { }

  normalizeJson(json: any): SearchDataResponse {

    let searchDataResponse = new SearchDataResponse();

    if (json.searchResult) {

      // save it for later use
      let jsonConfDataResponses = json.searchResult.confDataResponses;
      // set to null, we dont want to create models for it, it will be normalized later on
      json.searchResult.confDataResponses = null;

      let searchResult = this.modelFactory.createObject<SearchResult>(json.searchResult);

      if (jsonConfDataResponses && jsonConfDataResponses.length > 0) {

        let confDataResponses: ConfDataResponse[] = [];

        for (let i = 0; i < jsonConfDataResponses.length; i++) {
          let normalizedResponse = this.confDataNormalizer.normalizeJson(jsonConfDataResponses[i])
          confDataResponses.push(normalizedResponse);
        }

        searchResult = searchResult.setConfDataResponses(Immutable.List(confDataResponses));
      }

      searchDataResponse = searchDataResponse.setSearchResult(searchResult);
    }

    if (json.savedSearches) {
      searchDataResponse = searchDataResponse.setSavedSearches(this.modelFactory.createArray<ConfigurationSearch>(json.savedSearches));
    }

    if (json.messages) {
      searchDataResponse = searchDataResponse.setMessages(this.modelFactory.createArray<AbstractSearchDataMessage>(json.messages));
    }

    return searchDataResponse;
  }  
}