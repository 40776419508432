import { PropertyMapObject } from "./propertyMapObject";

export abstract class SimpleObject extends PropertyMapObject
{
  constructor(propertyMap?: any) {
    super(propertyMap ? propertyMap : new Map<any, any>());
  }

  public getInternalValue<T extends any>(key: string, classType?: any): T {

    let value = super.getInternalValue<T>(key);
    if (value && classType && !(value instanceof classType)) {
      value = new classType(value);
      this.setInternalValue(key, value);
    }

    return value;
  }

  public getInternalArray<T extends any>(key: string, classType?: any): T[] {

    let value = super.getInternalValue<T[]>(key);
    if (value && Array.isArray(value) && classType) {

      let isUpdate = false;

      for (let i = 0; i < value.length; i++)
      {
        let item = value[i];
        if (!(item instanceof classType)) {
          value[i] = new classType(item);
          isUpdate = true;
        }
      }

      if (isUpdate)
        this.setInternalValue(key, value);
    }

    return value;
  }

  public setInternalValue(key: string, value: any) {
    if (this.propertyMap.set)
      this.propertyMap.set(key, value);
    else
      this.propertyMap[key] = value;
  }
}