//import { ImmutableObject } from "../../../../shared/immutableObject";
//import * as Immutable from "immutable";

export abstract class BaseListModel /*extends ImmutableObject */{

  public static readonly TITLE = "title";
  public static readonly ICON = "icon";
  public static readonly CLASSES = "classes";
  public static readonly IMAGE_SET = "imageSet";
  public static readonly EMPTY_VALUE_TEXT = "emptyValueText";
  public static readonly IS_PRINT_VIEW = "isPrintView";  
  public static readonly IS_VAULT_ICON = "isVaultIcon";  
  public static readonly SHOW_ICON_ONLY = "showIconOnly";  

  public readonly title: string;
  setTitle(title: string): this {
    return this.setInternalProperty(BaseListModel.TITLE, title);  
  }

  public readonly icon: string;
  setIcon(icon: string): this {
    return this.setInternalProperty(BaseListModel.ICON, icon);  
  }

  public readonly classes: string;
  setClasses(classes: string): this {
    return this.setInternalProperty(BaseListModel.CLASSES, classes);   
  }

  public readonly imageSet: string;
  setImageSet(imageSet: string): this {
    return this.setInternalProperty(BaseListModel.IMAGE_SET, imageSet);   
  }

  public readonly emptyValueText: string;
  setEmptyValueText(emptyValueText: string): this {
    return this.setInternalProperty(BaseListModel.EMPTY_VALUE_TEXT, emptyValueText);    
  }  

  public readonly isPrintView: boolean;
  setIsPrintView(isPrintView: boolean): this {
    return this.setInternalProperty(BaseListModel.IS_PRINT_VIEW, isPrintView);
  }    

  public readonly isVaultIcon: boolean;
  setIsVaultIcon(isVaultIcon: boolean): this {
    return this.setInternalProperty(BaseListModel.IS_VAULT_ICON, isVaultIcon);
  } 

  public readonly showIconOnly: boolean;
  setShowIconOnly(showIconOnly: boolean): this {
    return this.setInternalProperty(BaseListModel.SHOW_ICON_ONLY, showIconOnly);
  } 

  protected setInternalProperty(propertyName: string, propertyValue: any) {

    let newObject =  new (<any>this).constructor();
    newObject = Object.assign(this, newObject);

    delete newObject[propertyName];

    Object.defineProperty(newObject, propertyName, {
      value: propertyValue,
      writable: false
    });

    return newObject;

  }
}