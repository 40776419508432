<div [style.width]="view.width" [style.max-width.%]="100" [style.min-width]="view.minWidth ? view.minWidth : '100%'" >
  <div class="option-group-wrapper" [class.has-icon]="view.icon" [class.highlight]="view.highlight">
    <icon [view]="view"></icon>
    <div class="static-label-inline max-width align-middle ">
      <label class="clear-spacing">{{view.title}}</label>
    </div>
    <help-link *ngIf="view.enableHelp" separator="false" [view]="view" (click)="helpClick($event)" [imageSet]="helpImageSet"></help-link>
  </div>
  
  <ng-content></ng-content>
</div>