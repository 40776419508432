import * as Immutable from "immutable";
import { BaseEntity } from "../../baseEntity";

export class Attribute extends BaseEntity {

  protected readonly TITLE = "title";
  protected readonly UNIT = "unit";
  protected readonly VALUE_TYPE = "valueType";

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get unit(): string { return this.getInternalValue<string>(this.UNIT); }
  setUnit(unit: string): this { return this.setInternalValue(this.UNIT, unit); }

  get valueType(): string { return this.getInternalValue<string>(this.VALUE_TYPE); }
  setValueType(valueType: string): this { return this.setInternalValue(this.VALUE_TYPE, valueType); }
}