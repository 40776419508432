export enum Placement {
  Left = 'left',
  Right = 'right',
  Top = 'top',
  Bottom = 'bottom'
}

export class PopperConfig {

  // Attach any object to it. Which would be accessible to all listeners.
  tag?: any;

  open: boolean = false;

  positionX?: number;

  positionY?: number;

  width: number;

  height: number;

  title?: string;

  subTitle?: string;

  refElement: any;

  // Default placement
  placement: Placement;

  // Calculated placement, only used internally. If the <Placement> is 'right' and there is not enough room to have it on right side, then calculate placement must be 'left'.
  calcPlacement: Placement;

  maximize: boolean = false;

}