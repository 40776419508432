import * as Immutable from "immutable"

import { AbstractConfigurationMessage } from "./abstractConfigurationMessage"
import { RuleInfo } from "../ruleInfo"
import { ConfInfo } from "../../entities/confInfo"

export class ConfErrorMessage extends AbstractConfigurationMessage {
    
  protected readonly MESSAGE = "message";
  protected readonly DETAILS = "details";
  protected readonly CONF_INFO = "confInfo";
  protected readonly RULE_INFOS = "ruleInfos";
  protected readonly STACK_TRACE = "stackTrace";
    
  get message(): string { return this.getInternalValue<string>(this.MESSAGE); }
  setMessage(message: string): this { return this.setInternalValue(this.MESSAGE, message); }

  get details(): Immutable.List<string> { return this.getInternalValue<Immutable.List<string>>(this.DETAILS); }
  setDetails(details: Immutable.List<string>): this { return this.setInternalValue(this.DETAILS, details); }

  get confInfo(): ConfInfo { return this.getInternalValue<ConfInfo>(this.CONF_INFO); }
  setConfInfo(confInfo: ConfInfo): this { return this.setInternalValue(this.CONF_INFO, confInfo); }

  get ruleInfos(): Immutable.List<RuleInfo> { return this.getInternalValue<Immutable.List<RuleInfo>>(this.RULE_INFOS); }
  setRuleInfos(ruleInfos: Immutable.List<RuleInfo>): this { return this.setInternalValue(this.RULE_INFOS, ruleInfos); }
    
  get stackTrace(): string { return this.getInternalValue<string>(this.STACK_TRACE); }
  setStackTrace(stackTrace: string): this { return this.setInternalValue(this.STACK_TRACE, stackTrace); }
}