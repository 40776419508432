import { Inject, Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import * as Immutable from "immutable"
import { ConfPageSessionService, ConfiguratorStore } from "../providers";
import { SummaryBaseComponent } from "./summaryBaseComponent";
import { Conf, ConfUIItem, DocumentsDecoration } from "../../shared/models";
import { ListModelCacheService } from "./providers/listModelCacheService";
import { GlobalDataStore } from "../../shared/providers/globalData";
import { VisualObjectHelper } from "../parameters/shared/visualObjectHelper";
import { VisualObjectUIDataService } from "../shared/visualObjectUIDataService";

@Component({
  selector: 'summary-detail',
  templateUrl: './summaryDetailComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ListModelCacheService, VisualObjectHelper, VisualObjectUIDataService]
})
export class SummaryDetailComponent extends SummaryBaseComponent {
  
  public tabIds: Immutable.List<number>;

  @Input()
  public isPrintView: boolean = false;

  public showPrices: boolean = true;

  public items: Immutable.List<ConfUIItem> = Immutable.List<ConfUIItem>();

  constructor( @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,    
    @Inject(ChangeDetectorRef) public cdr: ChangeDetectorRef,
    @Inject(GlobalDataStore) public globalDataStore) {
    super(confPageSessionService);
  }

  onDataReady(): void {
    
    let conf = this.confPageSessionService.activeConfiguration;
    this.items = conf.uiItems;


    // Don't display documents decorations if displayed as action boxes
    if (this.globalDataStore.globalSettings.displayDocumentsInOperationsColumn)
      this.items = this.items.filter(x => x.itemName != DocumentsDecoration.name).toList();

  }

  subscribeDataChange(): void {

    this.confStore.onConfigurationChange(this.configurationId, this.confPageSessionService.confSessionId, (configuration: Conf) => {

      this.onDataReady();      

    }).unsubscribeOn(this.unsubscribeSubject);

  }

}