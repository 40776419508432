import { ImmutableObject } from "../../../../shared/immutableObject";

export class RefObject extends ImmutableObject {

  protected readonly LONG_ID = "longId";
  protected readonly TEXT = "text";
  protected readonly REF_CLASS_NAME = "refClassName";

  get longId(): number { return this.getInternalValue<number>(this.LONG_ID); }
  setLongId(longId: number): this { return this.setInternalValue(this.LONG_ID, longId); }  

  get text(): string { return this.getInternalValue<string>(this.TEXT); }
  setText(text: string): this { return this.setInternalValue(this.TEXT, text); }

  get refClassName(): string { return this.getInternalValue<string>(this.REF_CLASS_NAME); }
  setRefClassName(refClassName: string): this { return this.setInternalValue(this.REF_CLASS_NAME, refClassName); }
}