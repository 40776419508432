import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpService } from "../../../../shared/providers"
import { AccountDataRequest, ApiResponse } from "../../models";
import { ApiResponseBuilder } from "../apiResponseBuilder";

@Injectable()
export class AccountDataController {

  public url = 'api/account';
  public loginUrl = 'api/account/login';
  public logoutUrl = 'api/account/logout';
  public loggedInUserUrl = 'api/account/loggedinuser';
  public hasLoggedInUserUrl = 'api/account/hasLoggedInUser';
  public passwordChangeUrl = 'api/account/passwordchange';
  public passwordRecoverUrl = 'api/account/passwordrecover';

  public verifyAccountUrl = 'api/account/verifyrequest';
  public verifyCodeUrl = 'api/account/verify';
  public createAccountUrl = 'api/account/createaccount';

  constructor(
    @Inject(HttpService) public httpService: HttpService,
    public apiResponseBuilder: ApiResponseBuilder,
  )
  {}

  public normalizeData = map((response, index) => this.apiResponseBuilder.build(response));

  protected get(url: string, accountRequest: AccountDataRequest): Observable<ApiResponse> {
    return this.httpService.get(url, accountRequest).pipe(this.normalizeData);
  }

  protected post(url: string, accountRequest: AccountDataRequest): Observable<ApiResponse> {
    return this.httpService.post(url, accountRequest).pipe(this.normalizeData);
  }

  public getAccountData(accountRequest: AccountDataRequest) {
    return this.get(this.url, accountRequest);
  }

  public postAccountData(accountRequest: AccountDataRequest) {
    return this.post(this.url, accountRequest);
  }

  public changePassword(accountRequest: AccountDataRequest) {
    return this.post(this.passwordChangeUrl, accountRequest);
  }

  public login(accountRequest: AccountDataRequest): Observable<ApiResponse> {
    return this.post(this.loginUrl, accountRequest);
  }

  public hasLoggedInUser(): Observable<boolean> {
    return this.httpService.get(this.hasLoggedInUserUrl);    
  }

  public getLoggedInUser(): Observable<ApiResponse> {
    return this.get(this.loggedInUserUrl, null);
  }

  public logout(accountRequest: AccountDataRequest): Observable<ApiResponse> {        
    return this.get(this.logoutUrl, accountRequest);
  }

  public passwordRecover(accountRequest: AccountDataRequest): Observable<ApiResponse> {
    return this.post(this.passwordRecoverUrl, accountRequest);
  }

  public verifyCode(accountRequest: AccountDataRequest): Observable<ApiResponse> {
    return this.post(this.passwordRecoverUrl, accountRequest);
  }

  public postAccountRequest(accountRequest: AccountDataRequest): Observable<ApiResponse> {

    if (accountRequest.verify)
      return this.post(this.verifyAccountUrl, accountRequest);

    else if (accountRequest.create)
      return this.post(this.createAccountUrl, accountRequest);

  }

}