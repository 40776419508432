<form name="form" (ngSubmit)="changePassword()">
  <div class="password-recover">
    <h3 class="alignment pb-3">{{strings.ResetYourPassword}}</h3>
    <p class="description">
      {{strings.PleaseChooseNewPasswordDescription}}
    </p>
    <div>
      <label class="pm-color-darker mr-2" >{{strings.Username}}:</label> <label class="font-italic">{{user}}</label>
    </div>
    
    <password [view]="newPasswordView" isNewPassword="true"></password>
    <password [view]="confirmNewPasswordView" isNewPassword="true"></password>
    <button class="btn btn-primary w-100" type="submit">{{strings.ResetPassword}}</button>
  </div>  
  <div *ngIf="message" class="form-control-feedback d-block pt-3 text-center">{{message}}</div>  
</form>