import { Injectable, Inject } from "@angular/core";

import { VisualizationPlugin } from "./visualizationPluginInterfaces";
import { HttpService } from "../httpService";
import { ManagedSubject, ManagedError } from "../../managedSubject";

@Injectable()
export class VisualizationPluginManager {

  protected globalDepsPath = "api/configurator/visualization/globaldeps/";
  protected baseFilePath = "api/configurator/visualization/file/";
  protected pluginObjectByUrl = new Map<string, any>();

  protected managedSubjectByUrl = new Map<string, ManagedSubject<any>>();
  protected globalDepsLoaded: boolean = false;

  constructor(@Inject(HttpService) public httpService: HttpService) {
  }

  public register(url: string, pluginObject: any) {

    this.pluginObjectByUrl.set(url, pluginObject);
  }

  loadPlugin(pluginFilePath: string, onLoad: (visualizationPlugin: VisualizationPlugin, url: string) => void, onError: (error: any, url: string) => void) {

    this.loadGlobalDependencies(() => this.loadPluginInternally(pluginFilePath, onLoad, onError), onError);
  }

  protected loadPluginInternally(pluginFilePath: string, onLoad: (visualizationPlugin: VisualizationPlugin, url: string) => void, onError: (error: any, url: string) => void) {

    let url = this.generateDownloadUrl(pluginFilePath);
    let visualizationPlugin = this.getVisualizationPlugin(url);
    if (visualizationPlugin) {
      onLoad(visualizationPlugin, url);
    }
    else {
      this.fetchJavaScriptFile(url).subscribe({
        next: (event) => {

          visualizationPlugin = this.getVisualizationPlugin(url);
          if (visualizationPlugin)
            onLoad(visualizationPlugin, url);
          else
            onError("Visualization plugin couldn't be loaded.", url);
        },
        error: (error: any) => onError(error, url)
      });
    }
  }

  protected loadGlobalDependencies(onLoad: () => void, onError: (error: any, url: string) => void) {

    if (this.globalDepsLoaded) {
      onLoad();
      return;
    }

    let managedSubject: ManagedSubject<string[]> = this.managedSubjectByUrl.get(this.globalDepsPath);
    if (!managedSubject) {
      managedSubject = new ManagedSubject<string[]>(null, true);
      this.managedSubjectByUrl.set(this.globalDepsPath, managedSubject);

      this.httpService.get(this.globalDepsPath).subscribe((result: string[]) => {

        if (result) {

          result.sort(); // Sort by name
          // Run in series
          result.reduce((promise: Promise<Event>, fileName) => {
            let url = this.generateDownloadUrl(fileName);
            return promise.then(() => this.fetchJavaScriptFile(url).toPromise(), (error) => onError(error, url));
          }, Promise.resolve<Event>(null))
            .then((event) => {
            
            this.globalDepsLoaded = true;
            managedSubject.nextValue(result);
          },
          (error) => {

            this.globalDepsLoaded = true;
            managedSubject.error(error);
          });          
        }
        else {

          this.globalDepsLoaded = true;
          managedSubject.nextValue(result);
        }
      }, (error) => {

        this.globalDepsLoaded = true;
        managedSubject.error(error)
      });
    }

    managedSubject.subscribe({
      next: (result) => onLoad(),
      error: (error) => onError(error, this.globalDepsPath)
    });
  }

  protected fetchJavaScriptFile(url: string) {

    let managedSubject: ManagedSubject<Event> = this.managedSubjectByUrl.get(url);
    if (managedSubject)
      return managedSubject;

    managedSubject = new ManagedSubject<Event>(null, true);
    this.managedSubjectByUrl.set(url, managedSubject);
    
    let scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.src = this.httpService.createUrl(url, null, false);

    scriptElement.onload = (event) => managedSubject.nextValue(event);
    scriptElement.onerror = (error) => managedSubject.error(new ManagedError(error));

    document.head.appendChild(scriptElement);

    return managedSubject;
  }

  protected getVisualizationPlugin(url: string): VisualizationPlugin {

    let pluginObject = this.pluginObjectByUrl.get(url);
    if (pluginObject)
      return (pluginObject.default || pluginObject) as VisualizationPlugin;

    return null;
  }

  protected generateDownloadUrl(pluginFilePath: string) {

    let origin = window.location.origin;
    let pathname = window.location.pathname;

    if (!pathname || pathname.length == 0)
      pathname = "/";

    return `${origin}${pathname}${this.baseFilePath}${pluginFilePath}`;
  }  
}