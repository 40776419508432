import { Injectable, Inject, Component } from "@angular/core";
import { BreakPointAccessor, Breakpoints, BreakpointRange, BreakpointOperator } from "../../../../shared/utils";
import { VisualObject, Tab, GraphicsDecoration, Param, DataSelector, BomDecoration, PropertyDecorationItem, PropertyDecoration, Decoration, HeaderDecoration, LookupParam, Conf, ConfUIItem, CodeDecoration, VisualizationDecoration } from "../../../shared/models";
import { TabDisplayStyle } from "../../../shared/providers";
import { ProductDataStore } from "../../../shared/providers/productData";
import { GlobalDataStore } from "../../../shared/providers/globalData";
import { SizeUtility } from "../../../../shared/components";
import { VisualObjectContainerService } from "../../shared/visualObjectContainerService";
import { GlobalServiceLocator } from "../../../../shared/providers";
import { VisualObjectUIDataService } from "../../shared/visualObjectUIDataService";
import { VisualObjectVisibilityService } from "../../shared/visualObjectVisibilityService";

@Injectable()
export class VisualObjectHelper {

  // TODO: Read from UISettings.
  public readonly VISUAL_OBJECT_LEFT_MARGIN: number = 40;

  constructor (
    @Inject(BreakPointAccessor) private breakpointAccessor: BreakPointAccessor,
    @Inject(ProductDataStore) public productStore: ProductDataStore,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(VisualObjectUIDataService) public visualObjectUIDataService: VisualObjectUIDataService,
    public visualObjectVisibilityService: VisualObjectVisibilityService
  ) { }


  hasWidth(visual: VisualObject): boolean {
    return visual.width && visual.width > 0
  }

  isVisualObjectInAccordion(visual: VisualObject): boolean {

    // Tab linked to visual object.
    const tab: Tab = this.productStore.getEntity<Tab>(visual.tabId);

    // For extra small screen, we move the normal tab objects to accordion.
    const accordionOrSmallScreen = tab && (tab.displayStyle == TabDisplayStyle.Accordion || tab.displayStyle == TabDisplayStyle.AccordionHeaderTab) || this.isVisualObjectInSmallScreen;

    return accordionOrSmallScreen;
  }

  get isVisualObjectInSmallScreen(): boolean {
    return this.breakpointAccessor.equal(Breakpoints.xs);
  }

  get smallSreenSize(): number {
    return Breakpoints.xs.max;
  }

  /**
   * If accordion and no width defined or extra small screen, then make it 100%.   
   * @param visualObject
   */
  public visualObjectWidth(visualObject: VisualObject): string {

    if ((this.isVisualObjectInSmallScreen || this.isVisualObjectInAccordion(visualObject)) && !(visualObject instanceof BomDecoration))
      return '100%';
    
    if (this.hasWidth(visualObject))
      return `${visualObject.width}px`;

    // Default widths.
    let configuratorUISettings = this.globalDataStore.getGlobalData().uiSettings.configurator;
    if (visualObject instanceof Param)
      return configuratorUISettings.parameter.minWidth;

    else if (visualObject instanceof BomDecoration)
      return configuratorUISettings.decoration.bom.minWidth;

    else if (visualObject instanceof HeaderDecoration) {

      const isWidthDefined = this.hasWidth(visualObject);
      if (isWidthDefined)
        return `${visualObject.width}px`;

      // If width is not defined but next visual object is floated then set the default width
      else if (this.visualObjectUIDataService.isNextVisualObjectFloated(visualObject.longId)  || visualObject.floatingPosition)
        return configuratorUISettings.decoration.header.minWidth;

      return "100%";

    }

    else if (visualObject instanceof PropertyDecoration)
      return "100%";

    else return configuratorUISettings.decoration.minWidth;
  }

  public decorationsMinWidth(decoration: Decoration): string {

    if (!decoration.width || (this.isVisualObjectInSmallScreen && decoration.width > this.smallSreenSize))
      return this.globalDataStore.getGlobalData().uiSettings.configurator.decoration.minWidth;

    return '100%';
  }


  /**
   * Returns the width of configuration properties.   
   * @param confPropertyItem
   */
  public confPropertyWidth(confPropertyItem: PropertyDecorationItem): string {

    if (confPropertyItem.width && confPropertyItem.width > 0)
      return `${confPropertyItem.width}px`;

    // As this is the part of visual object, must be 100% to its parent.
    return '100%';
  }

  public decorationHeight(decoration: Decoration): string {

    if ((decoration instanceof GraphicsDecoration || decoration instanceof CodeDecoration || decoration instanceof VisualizationDecoration) && decoration.height)
      return `${decoration.height}px`;

    return null;
  }

  // Returns true if flex item(visual object) is allowed to grow to fill entire available space?
  public growVisualObject(visual: VisualObject, conf: Conf): boolean {

    if (visual instanceof GraphicsDecoration || visual instanceof VisualizationDecoration) {

      // If height is not defined and respnsive option is set to true then visual object must grow 100%      
      const tab: Tab = this.productStore.getEntity<Tab>(visual.tabId)
      return tab.visualObjects.count(x => this.visualObjectVisibilityService.isVisualObjectVisible(conf, this.productStore.getEntity(x))) == 1 && visual.responsiveSize;
    }

    return false; // Default value.
  }

 
  getVisualObjectDefaultWidth(visualObject: VisualObject): number {

    if (visualObject.width && visualObject.width > 0)
      return visualObject.width;

    return this.fallbackWidth(visualObject);

  }

  fallbackWidth(visualObject: VisualObject): number {

    let configuratorUISettings = this.globalDataStore.getGlobalData().uiSettings.configurator;

    if (visualObject instanceof Param)
      return SizeUtility.sizeAsNumber(configuratorUISettings.parameter.minWidth);

    else if (visualObject instanceof Decoration)
      return SizeUtility.sizeAsNumber(configuratorUISettings.decoration.minWidth);

    return 0;

  }

  private isFullWidth(visualObject: VisualObject): boolean {

    if ((visualObject instanceof VisualizationDecoration || visualObject instanceof GraphicsDecoration) && visualObject.responsiveSize)
      return true;

    return visualObject instanceof BomDecoration || visualObject instanceof DataSelector && visualObject.showInline;

  }

  getLookupWidthIfSelectedHelpImage(visualObject: VisualObject) {
    
    if (visualObject.width && visualObject.width > 0)
      return visualObject.width + this.VISUAL_OBJECT_LEFT_MARGIN;

    let width = this.fallbackWidth(visualObject);
    return width + this.VISUAL_OBJECT_LEFT_MARGIN;

  }

  getMaxWidth(visualObject: VisualObject): string {
    
    if (visualObject instanceof HeaderDecoration && !this.hasWidth(visualObject)) {

      let configuratorUISettings = this.globalDataStore.getGlobalData().uiSettings.configurator;
      if (this.visualObjectUIDataService.isNextVisualObjectFloated(visualObject.longId) || visualObject.floatingPosition)
        return configuratorUISettings.decoration.header.minWidth;

      return '100%';
    }

    let fallbackWidth = this.fallbackWidth(visualObject);
    if (visualObject instanceof LookupParam && visualObject.showHelpImageOfSelectedValue == 2) // 2 represents 'Right'
    {
      let selectedHelpImageWidth = visualObject.selectedValueImageWidth;
      if (!selectedHelpImageWidth)
        selectedHelpImageWidth = fallbackWidth;

      // If the width is zero then fallback width must be respected.
      // The total width of the visual object would be the width of visualObject
      return (visualObject.width > 0 ? visualObject.width : fallbackWidth) + selectedHelpImageWidth + this.VISUAL_OBJECT_LEFT_MARGIN * 2 + 'px';
    }
    
    if (visualObject.width && visualObject.width > 0)
      return visualObject.width + this.VISUAL_OBJECT_LEFT_MARGIN + 'px';

    if (this.isFullWidth(visualObject))
      return '100%';
    
    return fallbackWidth ? fallbackWidth + this.VISUAL_OBJECT_LEFT_MARGIN + 'px' : '100%'; 
  }

  public getMinWidth(visualObject: VisualObject): string {

    let visualObjectContainer = $('#' + visualObject.tabId);
    let containerWidth = visualObjectContainer.outerWidth();

    const leftMargin: number = 40; // TODO: This margin must go to UISettings file.
    // Available width for visual object must be visualObject width + left margin.

    let defaultVisualObjectWidth = this.fallbackWidth(visualObject);
    const availableInnerWidth = containerWidth - leftMargin;

    // If width is undefined or zero, make it equal to containerWidth minus leftMargin.
    if (!defaultVisualObjectWidth)
      defaultVisualObjectWidth = availableInnerWidth;

    // If default width + left margin exceeds by the parent container then limit its size.
    const visualWidthAfterMargin = defaultVisualObjectWidth + leftMargin;
    let calculatedWidth = visualWidthAfterMargin > containerWidth ? availableInnerWidth : defaultVisualObjectWidth;

    // Min width must not be greater than the default visualObject width.
    calculatedWidth = calculatedWidth > defaultVisualObjectWidth ? defaultVisualObjectWidth : calculatedWidth;

    if (calculatedWidth > containerWidth)
      return '100%';

    return calculatedWidth + 'px';

  }

  getCssStyle(visualObject: VisualObject, includeMinWidth: boolean = false): CSSStyleDeclaration {

    let styles = new CSSStyleDeclaration();

    if (includeMinWidth) {
      let availableRoom = this.getMinWidth(visualObject);

      if (availableRoom)
        styles.minWidth = availableRoom + 'px';

      styles.maxWidth = this.visualObjectWidth(visualObject);
      styles.width = '100%';

    }

    return styles;
  }

}