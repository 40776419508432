import * as Immutable from "immutable";
import { BaseEntity } from "../../baseEntity";

/**
 * Weird class. This object is used differently depending on its context.
    Product context = combination of visualobject and visualobjectintab and its longId is the visualobjectintab ID,
    otherwise it might only be visualobject.
 */
export class VisualObject extends BaseEntity 
{
  protected readonly TITLE = "title";
  protected readonly ORDER = "order";
  protected readonly FLOATINGPOSITION = "floatingPosition";
  protected readonly HANGING = "hanging";
  protected readonly VISUAL_OBJECT_ID = "visualObjectId";
  protected readonly WIDTH = "width";
  protected readonly TAB_ID = "tabId";

  get width(): number { return this.getInternalValue<number>(this.WIDTH); }
  setWidth(width: number): this { return this.setInternalValue(this.WIDTH, width); }

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get order(): number { return this.getInternalValue<number>(this.ORDER); }
  setOrder(order: number): this { return this.setInternalValue(this.ORDER, order); }

  get floatingPosition(): boolean { return this.getInternalValue<boolean>(this.FLOATINGPOSITION); }
  setFloatingPosition(floatingPosition: boolean): this { return this.setInternalValue(this.FLOATINGPOSITION, floatingPosition); }

  get hanging(): boolean { return this.getInternalValue<boolean>(this.HANGING); }
  setHanging(hanging: boolean): this { return this.setInternalValue(this.HANGING, hanging); }

  get visualObjectId(): number { return this.getInternalValue<number>(this.VISUAL_OBJECT_ID); }
  setVisualObjectId(visualObjectId: number): this { return this.setInternalValue(this.VISUAL_OBJECT_ID, visualObjectId); }

  get tabId(): number { return this.getInternalValue<number>(this.TAB_ID); }
  setTabId(tabId: number): this { return this.setInternalValue(this.TAB_ID, tabId); }
}