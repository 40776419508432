import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PaginationComponent } from "./paginationComponent";
import { GridviewModule } from "../gridview";

export * from "./paginationComponent";

@NgModule({
  imports: [
    CommonModule,
    GridviewModule
  ],
  declarations: [
    PaginationComponent,
  ],
  exports: [
    PaginationComponent,
  ]
})
export class PaginationModule {

}