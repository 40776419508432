import { Injectable, Inject } from '@angular/core';
import { combineReducers } from 'redux';
import * as Immutable from "immutable";

import { ProductDataState } from "../shared/productDataState";
import { ProductDataActions } from "./productDataActions";
import { ProductDataResponse } from "../../models/responses";
import { BaseEntity } from "../../baseEntity";
import { Assortment } from "../../models/entities";
import { AppAction } from "../shared/appAction";
import { Actions } from "../shared/actions";
import { RequestStatus } from "../shared/requestStatus";

@Injectable()
export class ProductDataReducer {
    
  public getReducer() {

    let defaultState = new ProductDataState();
    defaultState = defaultState.setEntities(Immutable.Map<number, BaseEntity>());
    defaultState = defaultState.setEntityIdsByClassName(Immutable.Map<string, Immutable.List<number>>());
    defaultState = defaultState.setRootAssortments(Immutable.List<number>());

    return (state = defaultState, action: AppAction<any>): ProductDataState => {

      if (action.type == Actions.CLEAR_CLIENT_CACHE)
        return defaultState;

      if (!action.payload || !(action.payload.data instanceof ProductDataResponse))
        return state;

      let productResponse = action.payload.data as ProductDataResponse;
      
      if (productResponse.entities && productResponse.entities.size > 0) {
                
        productResponse.entityIdsByClassName.forEach((entityIds, className) => {

          let existingIds = state.entityIdsByClassName.get(className);
          state = state.setEntityIdsByClassName(state.entityIdsByClassName.set(className, this.mergeIds(existingIds, entityIds)));

        })
        
        let entities: Immutable.Map<number, BaseEntity> = this.mergeEntities(state.entities, productResponse.entities);
        state = state.setEntities(entities);
      }

      switch (action.type) {

        case ProductDataActions.PRODUCT_LINKED_DATA_LOADED:
          return state;
          
        case ProductDataActions.ROOT_ASSORTMENTS_LOADED:
          state = state.setRootAssortments(productResponse.entityIdsByClassName.get(Assortment.name));
          return state;

        default:
          return state;
      }
    }
  }

  public mergeIds(existingIds: Immutable.List<number>, newIds: Immutable.List<number>) {
    if (existingIds && existingIds.count() > 0)
      return existingIds.merge(newIds).toSet().toList();

    return Immutable.List(newIds);
  }

  public mergeEntities(existingEntities: Immutable.Map<number, BaseEntity>, newEntities: Immutable.Map<number, BaseEntity>): Immutable.Map<number, BaseEntity> {

    let resultedEntities = existingEntities;

    newEntities.forEach((newEntity: BaseEntity, key: number) => {

      // Convert to number, needed to make sure the key is number in runtime
      key = parseInt(key.toString());

      if (!existingEntities.has(key)) {
        resultedEntities = resultedEntities.set(key, newEntity);
      }
      else {
        let existingEntity = existingEntities.get(key);
        resultedEntities = resultedEntities.set(key, Object.assign(existingEntity, newEntity));
      }
    });

    return resultedEntities;
  }

}