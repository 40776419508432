import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GridviewModule } from "../../../../shared/components/gridview";
import { PagerComponent } from "./pagerComponent";
import { PaginationModule } from "../../../../shared/components/pagination";
import { DropdownModule } from "../../../../shared/components";
import { PagerService } from "./pagerService";

export * from "./pagerComponent";

@NgModule({
  imports: [
    CommonModule,
    GridviewModule,
    DropdownModule,
    PaginationModule
  ],
  declarations: [
    PagerComponent,
  ],
  exports: [
    PagerComponent,
  ],
  providers: [
    PagerService
  ]

})
export class PagerModule {
}