<div class="h-100 summary-view mr-0 mb-5" *ngIf="pageSession.dataLoaded" [popper]="attributeMenu">
  <div class="row no-gutters">
    <div class="spacer"></div>
    <summary-detail class="col mt-md-5 mt-lg-5 mt-xl-5 p-0"></summary-detail>
    <div class="spacer"></div>
    <summary-action-boxes *ngIf="largeUp else actionsOpenerButton" class="col-auto mt-5 mr-5 px-0"></summary-action-boxes>
  </div>
  <backdrop-container *ngIf="showSideBar">
    <side-bar-popup title="{{strings.Actions}}"
                    classes="accordion-tabs actions-popup"
                    position="right"
                    [showHeader]="true"
                    [okButtonText]="strings.Ok"
                    [cancelButtonText]="strings.Cancel"
                    (cancelClick)="onActionsClosed()">
      <div card-type="body" class="m-4">
        <summary-action-boxes [configurationId]="configurationId" [confSessionId]="confSessionId"></summary-action-boxes>
      </div>
    </side-bar-popup>
  </backdrop-container>

  <ng-template #actionsOpenerButton>
    <absolute-layout direction="topRight"
                     positionX="-5"
                     positionY="320"
                     zIndex="1">
      <button-icon-text text="{{strings.Actions}}"
                        direction="vertical"
                        class="side-bar-opener"
                        rounded="true"
                        (buttonClick)="openActions()">
      </button-icon-text>
    </absolute-layout>
  </ng-template>
  <price-list-report-popup></price-list-report-popup>
</div>

<message-box [title]="strings.Recover" [cancelButton]="false"></message-box>
<attribute-popper #attributeMenu width="320" [ignoreMaximize]="true">
</attribute-popper>