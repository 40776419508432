import { Component, Inject } from "@angular/core";
import { TextDecoration } from "../../../shared/models";
import { ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { DecorationComponent } from "../../shared/decorationComponent";
import { VisualObjectHelper } from "../../parameters/shared";

@Component({
  selector: 'text-decoration',
  templateUrl: './textDecorationComponent.html'
})
export class TextDecorationComponent extends DecorationComponent<TextDecoration> {

  constructor(
    public confStore: ConfiguratorStore,
    public confPageSessionService: ConfPageSessionService,
    @Inject(VisualObjectHelper) public visualObjectHelper: VisualObjectHelper
  ) {
    super(confStore, confPageSessionService, visualObjectHelper);
  }

}