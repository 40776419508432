<regular-popup *ngIf="visible"
               [id]="id"
               title="{{strings.IssuesToResolve}}"
               [showFooter]="true"
               [draggable]="false"
               referenceContainerId="configuratorContainer"
               [okButton]="false"
               [cancelButton]="false"
               (onCloseStart)="onPopupClosed()">
  <div card-type="body" #body>
    <ng-container *ngIf="values != null && values.length > 0">
      <accordion classes="accordion"
                 [openFirst]="true">
        <accordion-group *ngFor="let value of values;"
                         icon="configuration"
                         [isOpened]="value.isActiveConf"
                         [heading]="value.conf.text"
                         [iconIsVault]="false"
                         [selectedDarkTheme]="true"
                         [togglable]="values.length > 1"
                         (onOpen)="onAccordionOpen(value.conf.longId)"
                         (onToggle)="onAccordionToggle($event)">
          <div *ngFor="let attributeViewModel of value.attributeViewModels;">
            <ng-container>
              <div class="row py-2 ml-3">
                <div class="col-auto px-0 text-center" style="width:24px;">
                  <co-image width="16" height="16" type="svg" [key]="'forward'" imageSet="primary"></co-image>
                </div>
                <div class="col attribute-warning-message">
                  <span [innerHtml]="attributeViewModel.value | safeHtml"></span>
                </div>
              </div>
            </ng-container>
          </div>
        </accordion-group>
      </accordion>
    </ng-container>
  </div>

</regular-popup>
