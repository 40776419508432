import { Inject, Component, Input, SimpleChanges } from "@angular/core";
import { Subscription } from "rxjs";
import { VisualObjectGroup } from "./visualObjectGroup";
import { VisualObjectContainerService } from "./visualObjectContainerService";
import { BaseComponent } from "../../shared";

@Component({
  selector: 'visual-object-group',
  templateUrl: './visualObjectGroupComponent.html'
})
export class VisualObjectGroupComponent extends BaseComponent {

  @Input() public group: VisualObjectGroup;
  @Input() public service: VisualObjectContainerService;
  @Input() public configurationId: number;

  public hangings: VisualObjectGroup[] = [];

  public activeConfSubscription: Subscription;

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['group']) {
      this.hangings = this.service.hangings(this.group.id);
    }
  }

}