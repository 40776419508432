import { Inject, Component } from "@angular/core";
import { ReplaySubject, Subscription } from "rxjs";
import { ConfiguratorUIStore } from "../configurator/providers";
import { ConfiguratorPageUIData, PageUIData } from "./state";
import { PageUIStore } from "./providers/page";
import { BaseComponent } from "./baseComponent";
import { ManagedSubscription } from "../../shared/managedSubscription";

@Component({ template: '' })
export abstract class UIComponent extends BaseComponent {

  constructor(
    public uiStore: PageUIStore
  ) {
    super();
  }

  ngOnInit() {
    
    // Subscribe the ui changes.
    // Note! UI changes won't be subscribed until pageId available.
    this.subscribePageUIChanges();

    // This call is only made to read the default settings. It might happen sometimes that another component has registered the change before loading this component that's why 
    // we have made this call explicitly.
    this.beforeUILoad(this.uiStore.getPageUIState(this.pageId));
  }

  abstract get pageId(): number;

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  /**
   * Listens the page data change.
   * 
   * @param uiData
   */
  onUIDataChange(uiData: PageUIData): void {

  }

  beforeUILoad(uiData: PageUIData): void {

  }

  public subscribePageUIChanges(): void {

    if (this.uiStore)
      this.uiStore.listenUIChange(this.pageId, (data: PageUIData) => {

        this.onUIDataChange(data);

      }).unsubscribeOn(this.unsubscribeSubject);
  }

}