<backdrop-container>
  <regular-popup *ngIf="visible"
                 [id]="id"
                 [showFooter]="true"
                 [closeOnOk]="false"
                 width="700px"
                 [title]="strings.FileUploadDialog"
                 [okButtonText]="strings.Upload"
                 [cancelButton]="false"
                 (okClick)="okClick()"
                 (onClosed)="onPopupClosed()"
                 [okButtonClasses] ="uploadButtonDisabled ? 'disabled' : ''">
    <div card-type="body" class="p-4">
      <upload [multiple]="true"
              [configurationId]="configurationId"
              [confSessionId]="confSessionId"
              (uploadDone)="uploadDone()"
              (selectedFilesChanged)="uploadButtonDisabled = false"></upload>
    </div>
  </regular-popup>
</backdrop-container> 