import { Input, Component } from "@angular/core";
import { VisualObject } from "../../shared/models";
import { GlobalServiceLocator } from "../../../shared/providers/globalServiceLocator";
import { BaseComponent } from "../../shared";
import { EmitterService, IEmitDataInfo } from "./emitterService";
import { Subscription } from "rxjs";
import { ManagedSubscription } from "../../../shared";

@Component({ template: '' })
export abstract class EmitterComponent extends BaseComponent {

  private messageSubscription: ManagedSubscription;
  
  @Input()
  public id: string;

  ngOnInit() {

    this.messageSubscription = this.emitterService.getMessage().subscribe((info: IEmitDataInfo<any>) => {
      if (info.id == this.id) {
        this.observer(info);
      }
    });

    super.ngOnInit();
  }

  protected abstract observer(data: IEmitDataInfo<any>): void;

  ngOnDestroy() {

    if (this.messageSubscription)
      this.messageSubscription.unsubscribe();

  }

}