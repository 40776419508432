<div #listDiv class="list {{list.classes}}">
  <div class="row" [class.selected]="isOpened">
    <div class="col-auto pt-1" *ngIf="list.icon && !list.isPrintView">
      <visual-object-icon *ngIf="!list.isVaultIcon" backgroundImage="{{list.backgroundImage}}" paramType="{{list.icon}}" imageSet="{{imageSet}}" ></visual-object-icon>
      <visual-object-vault-icon *ngIf="list.isVaultIcon" icon="{{list.icon}}" ></visual-object-vault-icon>
    </div>
    <div class="col list-description">
      <h4 *ngIf="list.title" class="d-flex title" [innerHTML]="list.title | safeHtml" ></h4>
      <list-item *ngFor="let item of list.items" [emptyValueText]="list.emptyValueText" [item]="item"></list-item>
    </div>
    <div *ngIf="list.helpIcon && !list.isPrintView" class="col-auto align-middle" role="button" (click)="onClick()">
      <!--Help icon-->
      <co-image [width]="helpIconWidth" [height]="helpIconHeight" type="svg" [key]="list.helpIcon" imageSet="{{imageSet}}"></co-image>
    </div>
  </div>
</div>