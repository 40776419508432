import * as Immutable from "immutable"

import { ImmutableObject } from "../../../../shared/immutableObject";

export class PaginationInfo extends ImmutableObject {

    protected readonly PAGE_INDEX = "pageIndex";
    protected readonly PAGE_SIZE = "pageSize";
    protected readonly NO_OF_PAGES = "noOfPages";
    protected readonly RESULT_COUNT = "resultCount";

    get pageIndex(): number { return this.getInternalValue<number>(this.PAGE_INDEX); }
    setPageIndex(pageIndex: number): this { return this.setInternalValue(this.PAGE_INDEX, pageIndex); }

    get pageSize(): number { return this.getInternalValue<number>(this.PAGE_SIZE); }
    setPageSize(pageSize: number): this { return this.setInternalValue(this.PAGE_SIZE, pageSize); }

    get noOfPages(): number { return this.getInternalValue<number>(this.NO_OF_PAGES); }
    setNoOfPages(noOfPages: number): this { return this.setInternalValue(this.NO_OF_PAGES, noOfPages); }

    get resultCount(): number { return this.getInternalValue<number>(this.RESULT_COUNT); }
    setResultCount(resultCount: number): this { return this.setInternalValue(this.RESULT_COUNT, resultCount); }
}