import { Component, OnInit, AfterContentInit, Inject, Input, EventEmitter, Output } from "@angular/core";
import { IconButtonComponent } from "./iconButtonComponent";

@Component({
  selector: 'button-icon-text',
  templateUrl: './iconTextButtonComponent.html'
})
export class IconTextButtonComponent extends IconButtonComponent {
  
  @Input() text: string;

  // horizontal or vertical
  @Input() direction: string = 'horizontal';

  @Input() uppercase: boolean = true;

  @Input() highlightIfMandatory: boolean = false;

  @Input() subText: string;
}