<action-box class="conf-properties detail-item {{extraStyles}}" iconLeft="confproperties" textLeft="{{name}}">
  <gridview *ngIf="contentVisible">
    <gridview-row *ngFor="let row of rows"
                  classes="row-data">
      <columntemplate *ngFor="let list of row.cells" classes="py-2 pl-4 min-width ellipsis-wrap text-left col-12 col-sm-6 col-xl-4">
        <list [list]="list"></list>
      </columntemplate>
    </gridview-row>
  </gridview>
  <div card-type="control" *ngIf="isPrintView">
    <print-checkbox (onValueChange)="onPrintValueChange($event)"></print-checkbox>
  </div>
</action-box>