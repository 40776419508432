import * as Immutable from "immutable";
import { ImmutableObject } from "../../../../shared/immutableObject";
import { ComparisonResult } from "./comparisonSession";

export class ComparisonSessionData extends ImmutableObject {

  public readonly COMPARISON_SESSION_BY_ID = "comparisonSessionById";
 
  get comparisonResultByPath(): Immutable.Map<string, ComparisonResult> { return this.getInternalValue<Immutable.Map<string, ComparisonResult>>(this.COMPARISON_SESSION_BY_ID); }
  setComparisonResultByPath(comparisonResultByPath: Immutable.Map<string, ComparisonResult>): this { return this.setInternalValue(this.COMPARISON_SESSION_BY_ID, comparisonResultByPath); }

}