import { Inject, Injectable } from '@angular/core';
import { ApiResponse, ConfDataRequest } from "../../../shared/models";
import { ConfDataResponse } from "../../../shared/models/responses";
import { ConfDataActionCreator } from "../../../shared/providers/configurationData";
import { ActionStatus, AppAsyncAction, AppStore, HttpAction } from "../../../shared/state";
import { DataSelectorController } from "./dataSelectorController";


@Injectable()
export class DataSelectorActions {
  static GET_DATASELECTOR_ROWS = "@DATASELECTOR_GET_DATASELECTOR_ROWS";
  static GET_DATASELECTOR_KEY_DETAIL = "@DATASELECTOR_GET_DATASELECTOR_KEY_DETAIL";
  static SET_DATASELECTOR_VALUE = "@DATASELECTOR_DATASELECTOR_VALUE";
  static CLEAR_DATASELECTOR_VALUE = "@DATASELECTOR_CLEAR_DATASELECTOR_VALUE";
  static SEARCH_DATASELECTOR_VALUES = "@DATASELECTOR_SEARCH_DATASELECTOR_VALUES";
  static CLEAR_DATASELECTOR_SEARCH = "@DATASELECTOR_CLEAR_DATASELECTOR_SEARCH";
}

@Injectable()
export class DataSelectorActionCreator {

  constructor(
    @Inject(DataSelectorController) public selectorController: DataSelectorController,
    @Inject(ConfDataActionCreator) public configurationActionCreator: ConfDataActionCreator,
    @Inject(AppStore) public appStore: AppStore
  ) {
  }

  getDataSelectorRows(model: ConfDataRequest): HttpAction<ApiResponse> {
    let asyncAction = <HttpAction<ApiResponse>>{
      type: DataSelectorActions.GET_DATASELECTOR_ROWS,
      executeAsync: (dispatch, state, callback) => {
        return this.selectorController.getDataSelectorRows(model)
          .subscribe(configurationDataResult => {
            dispatch(this.configurationActionCreator.configurationLoaded(configurationDataResult));

            callback(new ActionStatus(true, configurationDataResult));
          });
      }
    }
    return asyncAction;
  }

  getDataSelectorKeyDetail(model: ConfDataRequest): AppAsyncAction {
    let asyncAction = <AppAsyncAction>{
      type: DataSelectorActions.GET_DATASELECTOR_KEY_DETAIL,
      executeAsync: (dispatch, state, callback) => {
        this.selectorController.getDataSelectorKeyDetail(model)
          .subscribe(x => {
            dispatch(this.configurationActionCreator.configurationLoaded(x));

            callback(new ActionStatus(true, x));
          });
      }
    }
    return asyncAction;
  }

  dispatchSetDataSelectorValue = (model: ConfDataRequest) => this.appStore.dispatch(this.setDataSelectorValue(model)) as HttpAction<any>;
  setDataSelectorValue(model: ConfDataRequest): HttpAction<ConfDataResponse> {

    return <HttpAction<ConfDataResponse>>{

      type: DataSelectorActions.SET_DATASELECTOR_VALUE,
      executeAsync: (dispatch, state, callback) => {
        return this.selectorController.setDataSelectorValue(model).subscribe(configurationDataResult => {

          this.configurationActionCreator.dispatchConfigurationLoaded(configurationDataResult);

          // Notify result complete.
          callback(new ActionStatus(true, configurationDataResult));
        })
      }
    }
  }

  dispatchClearSelectorValue = (model: ConfDataRequest) => this.appStore.dispatch(this.clearDataSelectorValue(model)) as HttpAction<any>;
  clearDataSelectorValue(model: ConfDataRequest): HttpAction<ConfDataResponse> {

    return <HttpAction<ConfDataResponse>>{

      type: DataSelectorActions.CLEAR_DATASELECTOR_VALUE,
      executeAsync: (dispatch, state, callback) => {
        return this.selectorController.clearDataSelectorValue(model).subscribe(configurationDataResult => {

          this.configurationActionCreator.dispatchConfigurationLoaded(configurationDataResult);

          // Notify result complete.
          callback(new ActionStatus(true, configurationDataResult));
        })
      }
    }
  }

  dispatchSearchDataSelectorValues = (model: ConfDataRequest) => this.appStore.dispatch(this.searchDataSelectorValues(model)) as HttpAction<any>;
  searchDataSelectorValues(model: ConfDataRequest): AppAsyncAction {
    let asyncAction = <AppAsyncAction>{
      type: DataSelectorActions.SEARCH_DATASELECTOR_VALUES,
      executeAsync: (dispatch, state, callback) => {
        return this.selectorController.searchDataSelectorValues(model).subscribe(x => {
          dispatch(this.configurationActionCreator.configurationLoaded(x));

          callback(new ActionStatus(true, x));
        });
      }
    }
    return asyncAction;
  }

  dispatchClearDataSelectorSearch = (model: ConfDataRequest) => this.appStore.dispatch(this.clearDataSelectorSearch(model)) as HttpAction<any>;
  clearDataSelectorSearch(model: ConfDataRequest): AppAsyncAction {
    let asyncAction = <AppAsyncAction>{
      type: DataSelectorActions.CLEAR_DATASELECTOR_SEARCH,
      executeAsync: (dispatch, state, callback) => {
        return this.selectorController.clearDataSelectorSearch(model).subscribe(x => {
          dispatch(this.configurationActionCreator.configurationLoaded(x));

          callback(new ActionStatus(true, x));
        });
      }
    }
    return asyncAction;
  }
}
