import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output, ChangeDetectionStrategy } from "@angular/core";
import { Tab } from "../../../shared/models";
import { ConfiguratorUIStore, ConfPageSessionService } from "../../providers";
import { TabAvailabilityService } from "./tabAvailabilityService";
import { TabContainerComponent } from "./tabContainerComponent";

let TabPosition = {
  Left: 'left',
  Right: 'right',
  Top: 'top',
  Bottom: 'bottom'
}

@Component({
  selector: 'separated-tab',
  templateUrl: './separatedTabComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SeparatedTabComponent extends TabContainerComponent {
    
  @Input() public position: string = TabPosition.Right;
  @Input() public tab: Tab;
  @Input() public minHeight: string;
  @Input() public maxWidth: string;
  @Input() public scrollbar: boolean = false;
  @Input() public configurationId: number;
    
  @Input()
  public showClose: boolean = false;

  public extraStyles: string = "";
  public isIE: boolean = false;

  @Output()
  public onClose = new EventEmitter();

  constructor(
    @Inject(ConfiguratorUIStore) public confUIStore: ConfiguratorUIStore,
    @Inject(ConfPageSessionService) public confPageSession: ConfPageSessionService,    
    public tabAvailableService: TabAvailabilityService,
    public cd: ChangeDetectorRef) {
    super(confUIStore, cd, tabAvailableService);
  }

  ngOnInit(): void {

    super.ngOnInit();
    this.confUIStore.onActiveConfChange(this.confPageSession.pageId, (confId: number) => { this.configurationId = confId; }).unsubscribeOn(this.unsubscribeSubject);
    this.isIE = this.browserInfo.isIE;
    if (this.isIE)
      this.extraStyles = 'tab-body-ie';
    
  }


  get pageId(): number {
    return this.confPageSession.pageId;
  }

  public activateTab(tab: Tab) {

    // Do nothing

  }
}