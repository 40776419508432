import { Inject, Component, ViewChild, Input, ChangeDetectorRef, SimpleChanges, ChangeDetectionStrategy, Output, EventEmitter, HostListener } from "@angular/core";
import { BaseComponent } from "../../..";
import { ConfPageSessionService, ConfiguratorStore } from "../../../../configurator/providers";
import { InputViewModel, TextBoxComponent } from "../../../../../shared/components";
import { PriceEditorService, PriceEditorActions, IPriceEditorCommandArgs } from "./priceEditorService";
import { PriceReportRowView } from "../priceReportRowView";
import { PriceReportItem, PriceReportCategory } from "../../../models";

export enum PriceEditorState {
  Edit = 'edit-mode',
  Manual = 'manual-mode'
}

@Component({
  selector: 'price-editor',
  templateUrl: './priceEditorComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceEditorComponent extends BaseComponent {

  @Input()
  isReadOnly: boolean = true;

  @Input()
  selected: boolean = false;

  @Input()
  row: PriceReportRowView;

  itemId: number;

  manualPrice: string;

  rawManualPrice: number;

  price: string;

  rawPrice: number;

  isEditMode: boolean = false;

  cancelValue: boolean = false;

  // Editor state
  state: string = PriceEditorState.Edit;

  action: PriceEditorActions;

  inputView: InputViewModel;

  @Output()
  onRemove = new EventEmitter();

  @Output()
  onSubmit = new EventEmitter();

  // If cancel button is clicked on hover/row-selected mode.
  @Output()
  onCancel = new EventEmitter();

  @ViewChild('textbox')
  protected textbox: TextBoxComponent;

  constructor(    
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(PriceEditorService) public priceEditorService: PriceEditorService,    
    public cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes["selected"] && !changes["selected"].firstChange) {

      this.createInputModel();
      if (!this.selected) {
        this.isEditMode = false;
        this.state = this.manualPrice ? PriceEditorState.Manual : PriceEditorState.Edit;
      }
    }

    if (changes["rawManualPrice"] && !changes["rawManualPrice"].firstChange) {
      this.setLocalFields();      
    }
  }

  ngOnInit(): void {        

    this.setLocalFields();
    this.createInputModel();

    // Listen the changes.
    this.priceEditorService.getMessage().subscribe(x => {

      // Don't do anything if itemPath is undefined.
      if (!x.id)
        return;

      // Set the current action performed by user on selected row.
      if (x.id == this.itemId) {
        this.action = x.action;                
        this.cdr.markForCheck();
      }
      else this.action = PriceEditorActions.None;
            
    }).unsubscribeOn(this.unsubscribeSubject);
  }

  public createInputModel() {
    
    this.inputView = new InputViewModel();
    this.inputView.value = this.rawManualPrice || this.rawManualPrice === 0 ? this.rawManualPrice : this.rawPrice;
    this.inputView.id = "edit-price";
    this.inputView.formattedValue = this.manualPrice ? this.manualPrice : this.price;
    if (this.cancelValue) {
      this.textbox.update();
      this.cancelValue = false;
      this.cdr.markForCheck();
    }

  } 

  public setLocalFields() {
    
    if (this.row.tag) {
      if (this.row.tag instanceof PriceReportItem) {

        let item = this.row.tag as PriceReportItem;
        this.rawManualPrice = item.rawManualPrice;
        this.manualPrice = item.manualPrice;
        this.price = item.price;
        this.rawPrice = item.rawPrice;
        this.itemId = item.longId;

        // If manual price exists then surround the value with parenthesis
        if (item.manualPrice)
          this.price = "(" + this.price + ")"

      }

      else if (this.row.tag instanceof PriceReportCategory) {

        let category = this.row.tag as PriceReportCategory;
        //this.rawManualPrice = category.rawManualPrice;
        //this.manualPrice = category.manualPrice;
        this.price = category.price;
        //this.rawPrice = category.rawPrice;
        this.itemId = category.longId;

      }
    }

    this.state = this.manualPrice ? PriceEditorState.Manual : PriceEditorState.Edit;
  }

  addValue() {

    let args = <IPriceEditorCommandArgs>{
      action: PriceEditorActions.Update,
      id: this.itemId,
      value: this.rawManualPrice
    };

    this.onSubmit.emit(args);
  }

  undoValue() {

    let args = <IPriceEditorCommandArgs>{
      action: PriceEditorActions.Undo,
      id: this.itemId,
      value: null
    };

    this.onSubmit.emit(args);
  }

  onEditorValueChange($event) {

    if (!this.textbox)
      return;

    let originalValue = this.rawManualPrice ? this.rawManualPrice : this.rawPrice;
    
    if (this.textbox.nativeElementValue() != originalValue.toString()) {
      this.rawManualPrice = parseFloat(this.textbox.nativeElementValue());       
      this.addValue();
    }

  }

  public showEditModel(): void {

    this.isEditMode = true;
    this.state = PriceEditorState.Edit;
  }

  public removeEditMode(event): void {   
    
    this.isEditMode = false;
    this.state = this.manualPrice ? PriceEditorState.Manual : PriceEditorState.Edit;    
    this.cancelValue = true;
    this.createInputModel();
    this.onCancel.emit();
    event.stopPropagation();

  }

}