<div class="m-3 import-form">

  <dropdown class="my-2" *ngIf="itemsView != null"
            [itemsView]="itemsView"
            [showEmptyValue]="false"
            [view]="productsGroupView">
  </dropdown>

  <autocomplete #autocomplete
                [items]="confItemsView"
                [autoCompleteView]="identityView"
                [matchOnce]="true"
                (filterChanged)="onPopulateAutoComplete($event)"
                (selectionChanged)="onAutoCompleteItemSelection($event)"></autocomplete>

  <text-box *ngIf="!disableRevision" class="my-4" [view]="revisionView"
            (change)="onValueChange($event)">
  </text-box>

  <label *ngIf="errorMessage" class="mb-4 error">{{errorMessage}}</label>

  <div class="mt-2 text-right">
    <button class="btn btn-primary text-uppercase" [style.min-width.px]="'100'" [style.max-width.px]="'200'" (click)="importClick($event)" type="button">{{strings.Import}}</button>
  </div>
</div>