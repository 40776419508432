import * as Immutable from "immutable";
import { BaseEntity } from "../../baseEntity";
import { VisualObject } from "./visualObject";

export abstract class ParamValue extends BaseEntity {
  protected readonly PARAMETER_ID = "parameterId";
  protected readonly NAME = "name";
  protected readonly TITLE = "title";
  protected readonly HELP_TEXT = "helpText";
  protected readonly EXTERNAL_LINK = "externalLink";
  protected readonly ICON_RELATIVE_URL = "iconRelativeUrl";
  protected readonly HELP_IMAGE_RELATIVE_URL = "helpImageRelativeUrl";
  protected readonly HELP_IMAGE_WIDTH = "helpImageWidth";
  protected readonly HELP_IMAGE_HEIGHT = "helpImageHeight";
  protected readonly HIDE_IF_DISABLED = "hideIfDisabled";
  protected readonly OBSOLETE = "obsolete";
  
  get parameterId(): number { return this.getInternalValue<number>(this.PARAMETER_ID); }
  setParameterId(parameterId: number): this { return this.setInternalValue(this.PARAMETER_ID, parameterId); }

  get name(): string { return this.getInternalValue<string>(this.NAME); }
  setName(name: string): this { return this.setInternalValue(this.NAME, name); }

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get helpText(): string { return this.getInternalValue<string>(this.HELP_TEXT); }
  setHelpText(helpText: string): this { return this.setInternalValue(this.HELP_TEXT, helpText); }

  get externLink(): string { return this.getInternalValue<string>(this.EXTERNAL_LINK); }
  setExternLink(externalLink: string): this { return this.setInternalValue(this.EXTERNAL_LINK, externalLink); }

  get iconRelativeUrl(): string { return this.getInternalValue<string>(this.ICON_RELATIVE_URL); }
  setIconRelativeUrl(iconRelativeUrl: string): this { return this.setInternalValue(this.ICON_RELATIVE_URL, iconRelativeUrl); }

  get helpImageRelativeUrl(): string { return this.getInternalValue<string>(this.HELP_IMAGE_RELATIVE_URL); }
  setHelpImageRelativeUrl(helpImageRelativeUrl: string): this { return this.setInternalValue(this.HELP_IMAGE_RELATIVE_URL, helpImageRelativeUrl); }

  get helpImageWidth(): number { return this.getInternalValue<number>(this.HELP_IMAGE_WIDTH); }
  setHelpImageWidth(helpImageWidth: number): this { return this.setInternalValue(this.HELP_IMAGE_WIDTH, helpImageWidth); }

  get helpImageHeight(): number { return this.getInternalValue<number>(this.HELP_IMAGE_HEIGHT); }
  setHelpImageHeight(helpImageHeight: number): this { return this.setInternalValue(this.HELP_IMAGE_HEIGHT, helpImageHeight); }

  get hideIfDisabled(): boolean { return this.getInternalValue<boolean>(this.HIDE_IF_DISABLED); }
  setHideIfDisabled(hideIfDisabled: boolean): this { return this.setInternalValue(this.HIDE_IF_DISABLED, hideIfDisabled); }

  get obsolete(): boolean { return this.getInternalValue<boolean>(this.OBSOLETE); }
  setObsolete(obsolete: boolean): this { return this.setInternalValue(this.OBSOLETE, obsolete); }
}