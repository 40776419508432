import * as Immutable from "immutable"

import { PriceReportItem } from "./"
import { BaseEntity } from "../../../baseEntity";

export class PriceReportCategory extends BaseEntity {

  protected readonly TITLE = "title";
  protected readonly PRICE = "price";
  protected readonly ITEMS = "items";
  protected readonly IS_TRANSIENT = "isTransient";

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get price(): string { return this.getInternalValue<string>(this.PRICE); }
  setPrice(price: string): this { return this.setInternalValue(this.PRICE, price); }

  get items(): Immutable.List<PriceReportItem> { return this.getInternalValue<Immutable.List<PriceReportItem>>(this.ITEMS); }
  setItems(items: Immutable.List<PriceReportItem>): this { return this.setInternalValue(this.ITEMS, items); }

  get isTransient(): string { return this.getInternalValue<string>(this.IS_TRANSIENT); }
  setIsTransient(isTransient: string): this { return this.setInternalValue(this.IS_TRANSIENT, isTransient); }
}   