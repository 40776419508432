import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MultiChoiceGroupComponent } from "./multiChoiceGroupComponent";
import { MultiChoiceItemComponent } from "./multiChoiceItemComponent";

import { MultiChoiceItemViewModel } from "./multiChoiceItemViewModel";
import { ImageModule } from "../image";
import { CheckboxModule } from "../checkbox";
import { HelpModule } from "../help";
import { IconModule } from "../icon";

export * from "./multiChoiceGroupComponent";
export * from "./multiChoiceItemComponent";
export * from "./multiChoiceItemViewModel";

@NgModule({
  imports: [
    CommonModule,
    ImageModule,
    CheckboxModule,
    HelpModule,
    IconModule
  ],
  declarations: [
    MultiChoiceGroupComponent,
    MultiChoiceItemComponent
  ],
  exports: [
    MultiChoiceGroupComponent,
    MultiChoiceItemComponent
  ]
})
export class MultiChoiceModule {

}