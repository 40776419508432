import { ImmutableObject } from "../../../../shared/immutableObject";

export abstract class StickableUIData extends ImmutableObject {

  public readonly USER_STICKY = "userSticky";
  public readonly CALC_STICKY = "calcSticky";
  public readonly VISIBLE = "visible";
  public readonly CALC_WIDTH = "calcWidth";

  get calcSticky(): boolean { return this.getInternalValue<boolean>(this.CALC_STICKY); }
  setCalcSticky(calcSticky: boolean): this { return this.setInternalValue(this.CALC_STICKY, calcSticky); }

  get visible(): boolean { return this.getInternalValue<boolean>(this.VISIBLE); }
  setVisible(visible: boolean): this { return this.setInternalValue(this.VISIBLE, visible); }

  // Note! We also supports shrinking the sidebars that's why we have defined the calcWidth based on available room.
  get calcWidth(): string { return this.getInternalValue<string>(this.CALC_WIDTH); }
  setCalcWidth(calcWidth: string): this { return this.setInternalValue(this.CALC_WIDTH, calcWidth); }

}
