import { Injectable, Inject } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { RequestViews, SortingArgument, ConfigurationSearch } from "../../shared/models";
import { SearchDataStore } from "../../shared/providers/searchData";
import { ConfSearchModelCacheService } from "./confSearchModelCacheService";
import { SearchSessionData } from "../../shared/state";
import { PagerModel } from "../../shared/components/pager/pagerModel";
import { Strings } from "../../shared/providers/globalData";
import { ModelFactory, RouteRedirector } from "../../shared/providers";
import { PagerService } from '../../shared/components/pager/pagerService';

@Injectable()
export class SearchResultsResolver implements Resolve<SearchSessionData> {

  constructor(
    @Inject(SearchDataStore) public searchDataStore: SearchDataStore,
    @Inject(Strings) public strings: Strings,
    @Inject(ConfSearchModelCacheService) public searchModelCache: ConfSearchModelCacheService,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    @Inject(PagerService) public pagerService: PagerService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<SearchSessionData> {

    return new Promise<SearchSessionData>(resolver => {

      let returnEmpty = () => { return resolver(new SearchSessionData()); }

      // Return if data is already loaded
      if (this.searchModelCache && this.searchModelCache.dataLoaded) {
        returnEmpty();
        return;
      }

      // Read the configurationSearch from server if the cache is not available. eg. after refreshing.
      if (!this.searchModelCache || !this.searchModelCache.pagerModel) {
        if (!route || !route.params) {
          returnEmpty();
          return;
        }

        if (route.params.searchSessionId) {
          // Get the conf search from server.
          let searchSessionId = parseInt(route.params.searchSessionId);

          this.query(resolver, null, searchSessionId);
        }
        else if (route.params.confSearchId) {
          // Get the conf search from server.
          let confSearchId = parseInt(route.params.confSearchId);
          let confSearch = this.searchDataStore.getConfigurationSearch(confSearchId);

          this.query(resolver, confSearch, null);
        }
        else {
          returnEmpty();
        }
      }
      else {

        let sessionId: number = this.searchModelCache.searchSessionId;
        if (!sessionId && route.params.searchSessionId) {
          sessionId = parseInt(route.params.searchSessionId);
        }

        this.query(resolver, this.searchModelCache.querySearchModel, sessionId);
      }
    });
  }

  public query(resolver, configurationSearch: ConfigurationSearch, searchSessionId: number) {
    this.searchDataStore.queryConfs(
      configurationSearch,
      RequestViews.Search,
      this.searchModelCache.pagerModel ? this.searchModelCache.pagerModel.displayCountView.count : null,
      this.searchModelCache.pagerModel ? this.searchModelCache.pagerModel.currentPage : null,
      this.searchModelCache.sortingArgument,
      searchSessionId).subscribe(storeResponse => {

        this.searchModelCache.dataLoaded = true;
        this.searchModelCache.dataLoading = false;
        this.searchModelCache.pagerModel = null;
        this.searchModelCache.sortingArgument = null;

        if (!storeResponse.data) {
          resolver();
          return;
        }

        this.setData(storeResponse.data.query, storeResponse.data.searchSessionId);

        this.searchModelCache.querySourceType = storeResponse.data.querySourceType;
        // TODO: Remove the sortingArgument from cache or change the datatype to sortingInfo in cache.
        this.searchModelCache.sortingArgument = <SortingArgument>{ field: storeResponse.data.sortingInfo.key, descending: storeResponse.data.sortingInfo.descending };

        resolver(storeResponse.data);

        // Redirect to update route data with correct search session id if it is not set
        if (searchSessionId === null || isNaN(searchSessionId))
          this.routeRedirector.redirectToSearchResult({ searchSessionId: storeResponse.data.searchSessionId });
      });
  }

  public setData(configurationSearch: ConfigurationSearch, searchSessionId: number) {
    // Set the data to use it in resolver.
    this.searchModelCache.searchModel = configurationSearch;
    this.searchModelCache.searchSessionId = searchSessionId;

    if (!this.searchModelCache.pagerModel)
      this.searchModelCache.pagerModel = this.pagerService.create();

    if (!this.searchModelCache.sortingArgument)
      this.searchModelCache.sortingArgument = this.modelFactory.createAny<SortingArgument>(SortingArgument.name);
  }
}