import { BaseObject } from "../../../../../shared/baseObject";

export class BomItem extends BaseObject {
  id?: number;
  itemNo?: string;
  description?: string;
  revision?: string;
  quantity?: number;
  quantityUnitId?: number;
  operationSequenceId?: number;
}