import * as Immutable from "immutable";

import {
  ConfPriceValue, ConfDocumentValue, ConfPropertyValue, ConfBomValue,
  ConfReport, ConfRevision, ConfHistory, StoredConf, RefObject, ConfAuthorization,
  ConfAttachment, ConfUIItem, ConfFormValue
} from "..";
import { ParamInfo } from "../responses";
import { BaseSyncObject } from "../../baseSyncObject";
import { ConfImageValue } from "./confImageValue";

export class Conf extends BaseSyncObject {

  protected readonly NAME = "name";
  protected readonly IDENTITY = "identity";
  protected readonly PRODUCT_ID = "productId";
  protected readonly ROOT_ID = "rootId";
  protected readonly COPIED_FROM_ID = "copiedFromId";
  protected readonly STATE = "state";
  protected readonly OWNED_BY = "ownedBy";
  protected readonly IMAGE_KEY = "imageKey";
  protected readonly ANONYMOUS_ACCESS_USER_EMAIL = "anonymousAccessUserEmail";
  protected readonly AUTHORIZATION = "authorization";
  protected readonly CHILDREN = "children";
  protected readonly CONF_VALUES = "confValues";
  protected readonly CODE_VALUES = "codeValues";  
  protected readonly VISUALIZATION_VALUES = "visualizationValues";
  protected readonly CONF_PROPERTY_VALUES = "confPropertyValues";
  protected readonly ATTRIBUTE_VALUES = "attributeValues";
  protected readonly IMAGE_VALUES = "imageValues";
  protected readonly BOM_VALUES = "bomValues";  
  protected readonly GRAPHIC_VALUES = "graphicsValues";
  protected readonly DATA_SELECTOR_VALUES = "dataSelectorValues";
  protected readonly FORM_VALUES = "formValues";
  protected readonly PRICE_VALUE = "priceValue";
  protected readonly DOCUMENT_VALUES = "documentValues";
  protected readonly REPORTS = "reports";  
  protected readonly ATTACHMENTS = "attachments";
  protected readonly REVISIONS = "revisions";
  protected readonly HISTORIES = "histories";
  protected readonly NEW_PARAMETERS = "newParameters";
  protected readonly OLD_PARAMETERS = "oldParameters";  
  protected readonly STORED_DATA = "storedData";
  protected readonly UI_ITEMS = "uiItems";

  get name(): string { return this.getInternalValue<string>(this.NAME); }
  setName(name: string): this { return this.setInternalValue(this.NAME, name); }

  get identity(): string { return this.getInternalValue<string>(this.IDENTITY); }
  setIdentity(identity: string): this { return this.setInternalValue(this.IDENTITY, identity); }

  get productId(): number { return this.getInternalValue<number>(this.PRODUCT_ID); }
  setProductId(productId: number): this { return this.setInternalValue(this.PRODUCT_ID, productId); }

  get rootId(): number { return this.getInternalValue<number>(this.ROOT_ID); }
  setRootId(rootId: number): this { return this.setInternalValue(this.ROOT_ID, rootId); }

  get copiedFromId(): number { return this.getInternalValue<number>(this.COPIED_FROM_ID); }
  setCopiedFromId(copiedFromId: number): this { return this.setInternalValue(this.COPIED_FROM_ID, copiedFromId); }

  get state(): RefObject { return this.getInternalValue<RefObject>(this.STATE); }
  setState(state: RefObject): this { return this.setInternalValue(this.STATE, state); }

  get ownedBy(): RefObject { return this.getInternalValue<RefObject>(this.OWNED_BY); }
  setownedBy(ownedBy: RefObject): this { return this.setInternalValue(this.OWNED_BY, ownedBy); }

  get imageKey(): boolean { return this.getInternalValue<boolean>(this.IMAGE_KEY); }
  setImageKey(imageKey: boolean): this { return this.setInternalValue(this.IMAGE_KEY, imageKey); }

  get anonymousAccessUserEmail(): string { return this.getInternalValue<string>(this.ANONYMOUS_ACCESS_USER_EMAIL); }
  setAnonymousAccessUserEmail(anonymousAccessUserEmail: string): this { return this.setInternalValue(this.ANONYMOUS_ACCESS_USER_EMAIL, anonymousAccessUserEmail); }

  get authorization(): ConfAuthorization { return this.getInternalValue<ConfAuthorization>(this.AUTHORIZATION); }
  setAuthorization(authorization: ConfAuthorization): this { return this.setInternalValue(this.AUTHORIZATION, authorization); }

  get children(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.CHILDREN); }
  setChildren(children: Immutable.List<number>): this { return this.setInternalValue(this.CHILDREN, children); }

  get confValues(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.CONF_VALUES); }
  setConfValues(confValues: Immutable.List<number>): this { return this.setInternalValue(this.CONF_VALUES, confValues); }

  get priceValue(): ConfPriceValue { return this.getInternalValue<ConfPriceValue>(this.PRICE_VALUE); }
  setPriceValue(priceValue: ConfPriceValue): this { return this.setInternalValue(this.PRICE_VALUE, priceValue); }

  get documentValues(): Immutable.List<ConfDocumentValue> { return this.getInternalValue<Immutable.List<ConfDocumentValue>>(this.DOCUMENT_VALUES); }
  setDocumentValues(documentValues: Immutable.List<ConfDocumentValue>): this { return this.setInternalValue(this.DOCUMENT_VALUES, documentValues); }

  get graphicsValues(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.GRAPHIC_VALUES); }
  setGraphicsValues(graphicsValues: Immutable.List<number>): this { return this.setInternalValue(this.GRAPHIC_VALUES, graphicsValues); }

  get codeValues(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.CODE_VALUES); }
  setCodeValues(codeValues: Immutable.List<number>): this { return this.setInternalValue(this.CODE_VALUES, codeValues); }

  get visualizationValues(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.VISUALIZATION_VALUES); }
  setVisualizationValues(visualizationValues: Immutable.List<number>): this { return this.setInternalValue(this.VISUALIZATION_VALUES, visualizationValues); }
    
  get confPropertyValues(): Immutable.List<ConfPropertyValue> { return this.getInternalValue<Immutable.List<ConfPropertyValue>>(this.CONF_PROPERTY_VALUES); }
  setConfPropertyValues(confPropertyValues: Immutable.List<ConfPropertyValue>): this { return this.setInternalValue(this.CONF_PROPERTY_VALUES, confPropertyValues); }

  get attributeValues(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.ATTRIBUTE_VALUES); }
  setAttributeValues(attributeValues: Immutable.List<number>): this { return this.setInternalValue(this.ATTRIBUTE_VALUES, attributeValues); }

  get dataSelectorValues(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.DATA_SELECTOR_VALUES); }
  setDataSelectorValues(dataSelectorValues: Immutable.List<number>): this { return this.setInternalValue(this.DATA_SELECTOR_VALUES, dataSelectorValues); }

  get formValues(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.FORM_VALUES); }
  setFormValues(bomValues: Immutable.List<number>): this { return this.setInternalValue(this.FORM_VALUES, bomValues); }

  get bomValues(): Immutable.List<ConfBomValue> { return this.getInternalValue<Immutable.List<ConfBomValue>>(this.BOM_VALUES); }
  setBomValues(bomValues: Immutable.List<ConfBomValue>): this { return this.setInternalValue(this.BOM_VALUES, bomValues); }

  get imageValues(): Immutable.List<ConfImageValue> { return this.getInternalValue<Immutable.List<ConfImageValue>>(this.IMAGE_VALUES); }
  setImageValues(attachments: Immutable.List<ConfImageValue>): this { return this.setInternalValue(this.IMAGE_VALUES, attachments); }

  get reports(): Immutable.List<ConfReport> { return this.getInternalValue<Immutable.List<ConfReport>>(this.REPORTS); }
  setReports(reports: Immutable.List<ConfReport>): this { return this.setInternalValue(this.REPORTS, reports); }

  get attachments(): Immutable.List<ConfAttachment> { return this.getInternalValue<Immutable.List<ConfAttachment>>(this.ATTACHMENTS); }
  setAttachments(attachments: Immutable.List<ConfAttachment>): this { return this.setInternalValue(this.ATTACHMENTS, attachments); }

  get revisions(): Immutable.List<ConfRevision> { return this.getInternalValue<Immutable.List<ConfRevision>>(this.REVISIONS); }
  setRevisions(revisions: Immutable.List<ConfRevision>): this { return this.setInternalValue(this.REVISIONS, revisions); }

  get histories(): Immutable.List<ConfHistory> { return this.getInternalValue<Immutable.List<ConfHistory>>(this.HISTORIES); }
  setHistories(histories: Immutable.List<ConfHistory>): this { return this.setInternalValue(this.HISTORIES, histories); }

  get newParameters(): Immutable.List<ParamInfo> { return this.getInternalValue<Immutable.List<ParamInfo>>(this.NEW_PARAMETERS); }
  setNewParameters(newParameters: Immutable.List<ParamInfo>): this { return this.setInternalValue(this.NEW_PARAMETERS, newParameters); }

  get oldParameters(): Immutable.List<ParamInfo> { return this.getInternalValue<Immutable.List<ParamInfo>>(this.OLD_PARAMETERS); }
  setOldParameters(oldParameters: Immutable.List<ParamInfo>): this { return this.setInternalValue(this.OLD_PARAMETERS, oldParameters); }

  get uiItems(): Immutable.List<ConfUIItem> { return this.getInternalValue<Immutable.List<ConfUIItem>>(this.UI_ITEMS); }
  setUIItems(uiItems: Immutable.List<ConfUIItem>): this { return this.setInternalValue(this.UI_ITEMS, uiItems); }

  get storedData(): StoredConf { return this.getInternalValue<StoredConf>(this.STORED_DATA); }
  setStoredData(storedData: StoredConf): this { return this.setInternalValue(this.STORED_DATA, storedData); }  

}