import * as Immutable from "immutable";
import { Decoration } from "./decoration";

export class ImageDecoration extends Decoration {

  protected readonly IMAGE_FILE_NAME = "imageFileName";
  protected readonly IMAGE_TITLE = "imageTitle";
  protected readonly IMAGE_SUB_DIRECTORY = "imageSubDirectory";

  get imageFileName(): string { return this.getInternalValue<string>(this.IMAGE_FILE_NAME); }
  setImageFileName(imageFileName: string): this { return this.setInternalValue(this.IMAGE_FILE_NAME, imageFileName); }

  get imageTitle(): string { return this.getInternalValue<string>(this.IMAGE_TITLE); }
  setImageTitle(imageTitle: string): this { return this.setInternalValue(this.IMAGE_TITLE, imageTitle); }

  get imageSubDirectory(): string { return this.getInternalValue<string>(this.IMAGE_SUB_DIRECTORY); }
  setImageSubDirectory(imageSubDirectory: string): this { return this.setInternalValue(this.IMAGE_SUB_DIRECTORY, imageSubDirectory); }
}