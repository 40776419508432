import { Inject, OnDestroy, SimpleChanges, Component } from "@angular/core";
import { ManagedSubject } from "../../shared/managedSubject";
import { Strings, GlobalDataStore } from "./providers/globalData";
import { GlobalServiceLocator } from "../../shared/providers/globalServiceLocator";
import { BlockUIService } from "../../shared/components/blockUI";
import { BrowserInfo } from "../../shared/utils";
import { UISettings } from "./models";
import { PopupIdentifiers } from "../configurator/providers/popupIdentifiers";
import { EmitterService } from "../configurator/shared/emitterService";
import { ImageSetAccessor } from "../../shared/utils/imageSetAccessor";
import { Timer } from "../../shared/utils/Timer";

@Component({ template: '' })
export abstract class BaseComponent implements OnDestroy {

  public unsubscribeSubject: ManagedSubject<any> = new ManagedSubject<any>(null, true);
  private _strings: Strings;

  private _blockUIService: BlockUIService;
  private _browserInfo: BrowserInfo;
  private _uiSettings: UISettings;

  /*private t: Timer;*/

  /** Gets the string instance. Is lazy loaded so it's not injected unless it's needed. */
  public get strings() {
    if (!this._strings)
      this._strings = GlobalServiceLocator.injector.get(Strings);
    return this._strings;
  }

  /** Gets the string instance. Is lazy loaded so it's not injected unless it's needed. */
  public get blockUIService() {
    if (!this._blockUIService)
      this._blockUIService = GlobalServiceLocator.injector.get(BlockUIService);
    return this._blockUIService;
  }

  public get browserInfo() {
    if (!this._browserInfo)
      this._browserInfo = GlobalServiceLocator.injector.get(BrowserInfo);
    return this._browserInfo;
  }

  public get uiSettings() {
    if (!this._uiSettings)
      this._uiSettings = GlobalServiceLocator.injector.get(GlobalDataStore).getGlobalData().uiSettings;
    return this._uiSettings;
  }

  private _emitterService: EmitterService;
  public get emitterService() {
    if (!this._emitterService)
      this._emitterService = GlobalServiceLocator.injector.get(EmitterService);
    return this._emitterService;
  }

  private _imageSetAccessor: ImageSetAccessor;
  public get imageSetAccessor() {
    if (!this._imageSetAccessor)
      this._imageSetAccessor = GlobalServiceLocator.injector.get(ImageSetAccessor);
    return this._imageSetAccessor;
  }

  protected getImageSet(key: string): string {
    return this.imageSetAccessor.get(key);
  }

  /**
   * Formats the input string, like
     format("{0} is {1}", "a", "b") => "a is b"
     From https://stackoverflow.com/a/4673436
   * @param string
   * @param args
   */
  format(string, ...args): string {
    if (!string)
      return "";
    return string.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != 'undefined'
        ? args[number]
        : match
        ;
    });
  }

  blockUI(): void {
    // Apply the block UI and remove plain UI CSS
    this.emitterService.send(PopupIdentifiers.PlainUIBlock, false);
    this.blockUIService.start("blockui");
  }

  unblockUI(): void {
    // Remove plain UI CSS
    this.emitterService.send(PopupIdentifiers.PlainUIBlock, false);
    this.blockUIService.stop("blockui");
  }

  plainBlockUI(): void {
    // Apply only plain UI blocker css.
    this.emitterService.send(PopupIdentifiers.PlainUIBlock, true);

    // Start blocking
    this.blockUIService.start("blockui");
  }

  /**
   * Respond when Angular (re)sets data-bound input properties. The method receives a SimpleChanges object of current and previous property values.
   * Called before ngOnInit() and whenever one or more data-bound input properties change.
   */
  ngOnChanges(changes: SimpleChanges) {
  }

  /**
   * Initialize the directive/component after Angular first displays the data-bound properties and sets the directive/component's input properties.
   * Called once, after the first ngOnChanges().
   */
  ngOnInit() {
  }

  /**
   * Detect and act upon changes that Angular can't or won't detect on its own.
   * Called during every change detection run, immediately after ngOnChanges() and ngOnInit().
   */
  ngDoCheck() {
    /*this.t = new Timer('component render ' + this.constructor.name);*/
  }

  /** 
   * Respond after Angular projects external content into the component's view / the view that a directive is in.
   * Called once after the first ngDoCheck().
   */
  ngAfterContentInit() {
  }

  /**
   * Respond after Angular checks the content projected into the directive/component. 
   * Called after the ngAfterContentInit() and every subsequent ngDoCheck().
   */
  ngAfterContentChecked() {
  }

  /**
   * Respond after Angular initializes the component's views and child views / the view that a directive is in.
   * Called once after the first ngAfterContentChecked().
   */
  ngAfterViewInit() {
  }

  /**
   * Respond after Angular checks the component's views and child views / the view that a directive is in.
   * Called after the ngAfterViewInit and every subsequent ngAfterContentChecked().
   */
  ngAfterViewChecked() {
    //this.t.stop();  // Prints the time elapsed to the JS console.
  }

  /**
   * Cleanup just before Angular destroys the directive/component. Unsubscribe Observables and detach event handlers to avoid memory leaks.
   * Called just before Angular destroys the directive/component.
   */
  ngOnDestroy() {
    this.unsubscribeSubject.nextValue(null);
  }

}