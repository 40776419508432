import { Component, EventEmitter, Output, Input } from "@angular/core";
import { BreadcrumbItem } from ".";

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumbComponent.html'
})
export class BreadcrumbComponent {

  @Input() items: Array<BreadcrumbItem> = [];

  @Output() itemClick: EventEmitter<BreadcrumbItem> = new EventEmitter<BreadcrumbItem>();

  onClick(event, item: BreadcrumbItem): void {

    event.preventDefault();
    this.itemClick.emit(item);

  }

}