import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { InputViewModel } from "../../../../shared/components";
import { BaseComponent } from "../../../shared";
import { AccountDataMessage } from "../../../shared/models";
import { RouteRedirector } from "../../../shared/providers";
import { GlobalDataStore } from "../../../shared/providers/globalData";
import { AccountUrlParams } from "../accountUrlParams";


@Component({
  selector: 'code-form',
  templateUrl: './verifyFormComponent.html'
})
export class VerifyFormComponent extends BaseComponent {
  
  public codeView: InputViewModel;

  @Input()
  public title: string;

  @Input()
  public description: string;

  @Input()
  public inputTitle: string;

  @Input()
  public errorMessage: string;

  @Output()
  public codeSubmit = new EventEmitter<InputViewModel>();

  constructor(
    public globalDataStore: GlobalDataStore,
    public redirector: RouteRedirector,
    public activatedRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {

    this.codeView = new InputViewModel(this.strings.VerificationCode);
    this.codeView.name = "Code";
    this.codeView.icon = "user";

    if (!this.title)
      this.title = this.strings.CheckYourEmail;

    if (!this.description)
      this.description = this.strings.VerificationCodeRequestRecoverDescription;   

    super.ngOnInit();
  }

  onSuccess(message: AccountDataMessage): void {
    //this.redirector.redirectToPasswordChange({ user: this.username, code: this.codeView.value });
  }

  verify() {

    let sessionKey = this.activatedRoute.snapshot.paramMap.get(AccountUrlParams.KEY);

    if (!sessionKey)
      this.redirector.redirectToLogin();

    //this.blockUI();
    // Remove white space from code.
    //if (this.codeView.value)
    //  this.codeView.value = (this.codeView.value as string).trim();

    //this.accountStore.recoverPasswordVerifyCode(username, this.codeView.value);

    this.codeSubmit.emit(this.codeView);
  }
 
  get validateSession(): boolean { return true; }

}