/**
 * Abstract ImageService.
    The implementation needs combinum data and cannot be made global, but we want ImageComponent to be global so we create this middleman.
 */
export abstract class AbstractImageService {

  /**
 * Returns the url of the page. It does not support different image sizes, It is recommended to access only svgs otherwise resolution will not be good for different devices.
 * @param imageName
 * @param sizes
 * @param isVault
 * @param imageSet image set for svg
 */
  abstract getImageUrl(imageName: string, type: string, sizes?: string, isVault?: boolean, imageSet?: string): string[];
}