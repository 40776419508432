import { Injectable } from "@angular/core";

@Injectable()
export class IconTools {

  public boolValueIcon(value?: boolean): string {

    if (value == null)
      return 'indeterminate';

    else if (value)
      return 'checkmark';

    return 'delete';
  }

  public boolValueTheme(value?: boolean): string {

    if (value == null)
      return 'primary';

    else if (value)
      return 'green';

    return 'error';
  }

}