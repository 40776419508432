import * as Immutable from "immutable";
import { BaseEntity } from "../../baseEntity";
import { BaseSyncObject } from "../../baseSyncObject";

export class ConfValue extends BaseSyncObject {

  protected readonly CONFIGURATION_ID = "configurationId";
  protected readonly IS_READ_ONLY = "isReadOnly";    

  get configurationId(): number { return this.getInternalValue<number>(this.CONFIGURATION_ID); }
  setConfigurationId(configurationId: number): this { return this.setInternalValue(this.CONFIGURATION_ID, configurationId); }

  get isReadOnly(): boolean { return this.getInternalValue<boolean>(this.IS_READ_ONLY); }
  setIsReadOnly(isReadOnly: boolean): this { return this.setInternalValue(this.IS_READ_ONLY, isReadOnly); }      
}