import * as Immutable from "immutable"

import { AbstractConfigurationMessage } from "./abstractConfigurationMessage"
import { AttributeInfo } from ".."

export class WarningAttributesMessage extends AbstractConfigurationMessage {

  protected readonly ATTRIBUTE_INFOS = "attributeInfos";

  get attributeInfos(): Immutable.List<AttributeInfo> { return this.getInternalValue<Immutable.List<AttributeInfo>>(this.ATTRIBUTE_INFOS); }
  setAttributeInfos(attributeInfos: Immutable.List<AttributeInfo>): this { return this.setInternalValue(this.ATTRIBUTE_INFOS, attributeInfos); }
}