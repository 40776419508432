import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { BaseComponent } from "../../../../../../core/pages/shared";
import { ComparisonResult } from "../../../../../../core/pages/shared/state";
import { ImageSets } from "../../../../../../core/shared/utils";
import { ComparisonDraggableNodeArgs, DraggableListAction, IDraggableDataInfo } from "./models/comparisonDraggableNodeArgs";
import { ComparisonDraggableNode } from "./models/comparisonDraggableRowNodes";
import { ComparisonDraggableDataProvider } from "./providers/comparisonDraggableDataProvider";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'comparison-draggable-node',
  templateUrl: './comparisonDraggableItemComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComparisonDraggableItemComponent extends BaseComponent {

  @Input()
  node: ComparisonDraggableNode;

  templateNode: ComparisonDraggableNode;

  @Input()
  index: number;

  @Input()
  loading = false;

  @Input()
  compactMode = false;

  differncyLevel: number;
  
  @Output() select: EventEmitter<ComparisonDraggableNode> = new EventEmitter<ComparisonDraggableNode>();

  public imageSet: string;

  constructor(protected draggableDataProvider: ComparisonDraggableDataProvider, protected cd: ChangeDetectorRef) { super(); }

  ngOnInit() {
    this.templateNode = this.node;

    this.imageSet = this.getImageSet(ImageSets.StartConfiguration);

    this.draggableDataProvider.getMessage().subscribe((info: IDraggableDataInfo<any>) => {

      switch (info.action) {

        case DraggableListAction.ChildConfLoading:

          if (this.isMatched((info.data as ComparisonResult).confIds.toArray())) {
              this.loading = true;                            
              this.cd.markForCheck();            
          }

          break;

        case DraggableListAction.ChildConfLoaded:

          if (this.isMatched((info.data as ComparisonResult).confIds.toArray())) {
            
            //sleep(Delay.MS_1000).then(x => {

            this.loading = false;              
            this.differncyLevel = (info.data as ComparisonResult).differencyResult.get(this.index);
            this.cd.markForCheck();

            //});
            
          }

          break;

        case DraggableListAction.DragCancel:

          if ((info.data as ComparisonDraggableNodeArgs).node.id !== this.node.id) {
            this.templateNode = { ...this.node };
            this.cd.markForCheck();
          }

          break;
      }
      
    }).unsubscribeOn(this.unsubscribeSubject);

    super.ngOnInit();
  }

  onMouseLeave(event): void {
    this.templateNode = this.node;    
    this.cd.markForCheck();    
  }

  isMatched(ids: number[]): boolean {

    // As an empty node does not contain any persitent id, that's why we find here the matching by grabbing sibling nodes which have 
    // persistent ids.
    if (this.node.isEmpty) {

      let row = this.draggableDataProvider.getRowByNodeId(this.node.id);

      if (!row)
        return false;

      // Non empty nodes
      let realNodes = this.draggableDataProvider.getRowByNodeId(this.node.id).nodes.filter(node => !node.isEmpty);

      // Do these current 'ids' belong to the empty node's row?
      return realNodes.findIndex(x => x.id == ids[0].toString()) > -1;

    }

    let matched = ids.findIndex(id => id.toString() == this.node.id) > -1;
    return matched;
  }

}
