import { Component, Input, ViewEncapsulation, Inject, Output, EventEmitter } from "@angular/core";
import { BaseComponent } from "../shared";

@Component({
  selector: 'selector-header',  
  templateUrl: './SelectorHeaderComponent.html',  
})
export class SelectorHeaderComponent extends BaseComponent  {
  
  @Input()
  displayReturnButton: boolean = false;

  @Output()
  returnButtonClicked = new EventEmitter();

  public returnToSelector() {
    this.returnButtonClicked.emit();
  }
}