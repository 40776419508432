import { Component, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { InputViewModel } from "../../shared/components";
import { Routing } from "../../shared/route/routeDecorator";
import { PageComponent } from "../shared";
import { ChangePasswordPopupComponent } from "../shared/components";
import { RouteNames, RouteRedirector, IHttpDataInfo } from "../shared/providers";
import { GlobalDataStore } from "../shared/providers/globalData";
import { AnonymousAccessEntryComponent } from "./login";


@Routing({ path: 'account' })
@Component({
  encapsulation: ViewEncapsulation.None,
  templateUrl: './accountComponent.html'  
})
export class AccountComponent extends PageComponent {

  public rootStyles: string; 
  public returnUrl: string;
  public accountTitle: string;
  public userNameView: InputViewModel;
  public showAnonymousAccessLoginLink: boolean = false;
  public showSignUpLink: boolean = false;
  public showPasswordRecoveryLink: boolean = false;  
  public showLoginLink: boolean = false;
  public logoType: string;
  public imageSet: string;
  public maxWidth: string;

  @ViewChild(AnonymousAccessEntryComponent)
  protected anonymousAccessEntry: AnonymousAccessEntryComponent;

  @ViewChild(ChangePasswordPopupComponent)
  protected changePasswordPopup: ChangePasswordPopupComponent;

  protected subscription: Subscription;

  constructor(
    public globalDataStore: GlobalDataStore,
    protected router: Router,
    private activatedRoute: ActivatedRoute,
    private routeRedirector: RouteRedirector
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    // Read logo type & image set.
    this.logoType = this.uiSettings.account.logoType;    
    this.imageSet = this.uiSettings.account.imageSet;
    this.maxWidth = this.uiSettings.account.maxWidth;

    this.userNameView = new InputViewModel(this.strings.Username);
    this.userNameView.name = "identity";
    this.userNameView.icon = "user";

    let globalSettings = this.globalDataStore.getGlobalData().globalSettings;
    this.showAnonymousAccessLoginLink = globalSettings.isAnonymousAccessEnabled && globalSettings.anonymousAccessFromLoginPage;
    this.showSignUpLink = globalSettings.enableAccountCreation;

    this.setStyles();
    
    this.subscription = this.activatedRoute.url.subscribe(url => {

      this.setStyles();

      // Extract second level route name e.g Login, RecoverPassword, ChangePassword.
      let subRoute: string = this.router.routerState.snapshot.root.firstChild.children[0].url[0].path;
      switch (subRoute) {

        case RouteNames.Login:

          this.accountTitle = this.strings.Login;
          this.showPasswordRecoveryLink = globalSettings.enablePasswordRecovery;
          this.showLoginLink = false;
          this.showSignUpLink = globalSettings.enableAccountCreation;
          this.showAnonymousAccessLoginLink = globalSettings.isAnonymousAccessEnabled && globalSettings.anonymousAccessFromLoginPage;

          break;

        case RouteNames.Recover:

          this.accountTitle = this.strings.RecoverPassword;
          this.showLoginLink = false;
          this.showPasswordRecoveryLink = false;
          this.showSignUpLink = false;
          this.showAnonymousAccessLoginLink = false;

          break;

        case RouteNames.PasswordChange:

          this.accountTitle = this.strings.ChangePassword;

          break;

        case RouteNames.SignUp:
          this.showLoginLink = false;
          this.showPasswordRecoveryLink = false;
          this.showSignUpLink = false;
          this.showAnonymousAccessLoginLink = false;

          break;


      }

    });
  }

  setStyles(): void {

    this.rootStyles = "";
    // Add route names as css selectors to control the child component styles.
    this.router.url.split(';')[0].split('/').forEach(routeName => {

      if (routeName != '')
        this.rootStyles += routeName + " ";

    });

  }

  loginAnonymously() {
    // TODO set returnUrl so anonymous access user is taken to selector page. Should maybe be handled in a different way.
    this.returnUrl = "selector";
    this.anonymousAccessEntry.show();
  }


  passwordChangedSuccess(event) {    
    
  }

  ngOnDestroy() {

    this.subscription.unsubscribe();
    super.ngOnDestroy();

  }

  signUpClick() {
    this.routeRedirector.redirectToSignUpEmail({});
  }

  accountRecoverClick() {
    this.routeRedirector.redirectToAccountRecoverEmail({});
  }

  onHttpLifeCycleUpdate(http: IHttpDataInfo) {
    // Prevents unblocking UI before login is completed
  }

}