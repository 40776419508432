import * as Immutable from "immutable";
import { ImmutableObject } from "../../../../shared/immutableObject";

export class StoredPriceValue extends ImmutableObject {
    
  protected readonly PRICE_LIST_CATEGORY_NAME = "priceListCategoryName";
  protected readonly PRICE_LIST_CATEGORY_ID = "priceListCategoryId";
  protected readonly PRICE = "price";
  protected readonly CURRENCY_ID = "currencyId";
  protected readonly CURRENCY_NAME = "currencyName";
    
  get priceListCategoryName(): string { return this.getInternalValue<string>(this.PRICE_LIST_CATEGORY_NAME); }
  setPriceListCategoryName(priceListCategoryName: string): this { return this.setInternalValue(this.PRICE_LIST_CATEGORY_NAME, priceListCategoryName); }

  get priceListCategoryId(): number { return this.getInternalValue<number>(this.PRICE_LIST_CATEGORY_ID); }
  setPriceListCategoryId(priceListCategoryId: number): this { return this.setInternalValue(this.PRICE_LIST_CATEGORY_ID, priceListCategoryId); }

  get price(): string { return this.getInternalValue<string>(this.PRICE); }
  setPrice(price: string): this { return this.setInternalValue(this.PRICE, price); }

  get currencyId(): number { return this.getInternalValue<number>(this.CURRENCY_ID); }
  setCurrencyId(currencyId: number): this { return this.setInternalValue(this.CURRENCY_ID, currencyId); }

  get currencyName(): string { return this.getInternalValue<string>(this.CURRENCY_NAME); }
  setCurrencyName(currencyName: string): this { return this.setInternalValue(this.CURRENCY_NAME, currencyName); }
}