import { Injectable, Inject } from "@angular/core";
import { Subscription, Subject, ReplaySubject } from "rxjs";
import { PageActionCreator } from "./pageActionCreator";
import { AppStore, StoreResponse, PageUIData } from "../../state";
import { ReselectorResult } from "../reselectorService";
import { ManagedSubscription, SubscriptionOptions } from "../../../../shared/managedSubscription";
import { ManagedSubject } from "../../../../shared/managedSubject";
import { AppStoreSubscriptionManager } from "../appStoreSubscriptionManager";
import { BaseStore } from "../../state/baseStore";
import { ModelFactory } from "../modelFactory";

class IdGenerator {
  static Id = 1;
}

@Injectable()
export class PageUIStore extends BaseStore {

  constructor(
    public appStore: AppStore,
    public pageActionCreator: PageActionCreator,
    public appStoreSubscriptionManager: AppStoreSubscriptionManager,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
  ) {
    super(appStore, appStoreSubscriptionManager, modelFactory);
  }
 
  public nextPageIdentifier(): number {
    return IdGenerator.Id++;
  }

  public listenUIChange(pageId: number, callBackOptions: SubscriptionOptions<PageUIData>): ManagedSubscription {
    return this.createStoreSubscription<PageUIData>("__pageUiData_" + pageId.toString(), this.getMemorizer(pageId), callBackOptions);
  }

  public createStoreSubscription<T>(identifier: string, memorizer: () => T, subscriptionOptions: SubscriptionOptions<T>): ManagedSubscription {
    let managedSubject: ManagedSubject<T> = this.appStoreSubscriptionManager.getOrCreateStoreSubject(identifier, memorizer, false, 1, false);
    return managedSubject.subscribe(subscriptionOptions);
  }

  public getMemorizer(pageId: number): () => PageUIData {
    throw "getMemorizer must be implemented";
  };

  public getPageUIState(pageId: number): PageUIData {
    throw "getPageUIState must be implemented";
  };
  
}