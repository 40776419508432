import { Inject, Component, ViewChild, Input, ChangeDetectorRef, SimpleChanges, ChangeDetectionStrategy } from "@angular/core";
import { ParamValue, Param, ConfLookupValue, ConfValue, ConfMultiChoiceValue, ConfMultiSetValue, LookupParam, MultiChoiceParam } from "../../../shared/models";
import { PopupService } from "../../../../shared/components/popup";
import { ProductDataStore } from "../../../shared/providers/productData";
import { AccordionComponent } from "../../../../shared/components";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import { PopupIdentifiers, ConfiguratorStore, ConfPageSessionService } from "../../providers";

@Component({
  selector: 'help-popup',
  templateUrl: './helpPopupComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpPopupComponent extends AbstractPopupComponent {

  public id: string = PopupIdentifiers.Help;

  public parameter: Param;

  public values: Array<ParamValue> = [];

  public defaultValue: number;

  public helpWidth: string;

  public isIE = false;

  @ViewChild(AccordionComponent)
  public helpAccordion: AccordionComponent;

  constructor(    
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public pageSession: ConfPageSessionService,
    @Inject(ProductDataStore) public productStore: ProductDataStore,
    @Inject(PopupService) public popupService: PopupService,
    public cdr: ChangeDetectorRef
  ) {
    super(cdr, popupService);
  }

  public open(tag: any) {
    this.parameter = this.productStore.getEntity<Param>(tag);

    // Set default chosen accordion group
    let confVal = this.confStore.getConfValue(this.pageSession.activeConfigurationId, this.pageSession.confSessionId, this.parameter.visualObjectId);

    if (confVal && confVal instanceof ConfLookupValue) {
      let lookupVal = confVal as ConfLookupValue;
      this.defaultValue = lookupVal.value;
    }
    else
      this.defaultValue = null;
    
    this.showInternal();
    this.update();

    // if the default value doesn't have a help entry open the parameter accordion tab
    if (this.values && this.values.findIndex(x => x.longId == this.defaultValue) == -1)
      this.defaultValue = null;

    // If the help popup is already opened and we click on some other parameter's help then forcefully open the first group.
    if (this.helpAccordion)
      this.helpAccordion.openFirstGroup();

    this.isIE = this.browserInfo.isIE;
  }

  update(): void {
    this.values = [];
    this.parameter.paramValues.forEach((x) => {
      let paramValue: ParamValue = this.getParamValue(x);

      if (this.parameter instanceof MultiChoiceParam) {

        let confVal: ConfMultiSetValue = this.confStore.getConfValue(this.pageSession.activeConfigurationId, this.pageSession.confSessionId, paramValue.longId) as ConfMultiSetValue;
        if (!confVal || confVal.isDisallowed && paramValue.hideIfDisabled)
          return;

      }
      else if (this.parameter instanceof LookupParam) {

        let conflookUpValue: ConfLookupValue = this.confStore.getConfValue(this.pageSession.activeConfigurationId, this.pageSession.confSessionId, this.parameter.visualObjectId) as ConfLookupValue;

        let isDisallowedValue: boolean = false;

        if (conflookUpValue) {
          let disallowedValuesByKey = conflookUpValue.disallowedParamValues.entrySeq();

          disallowedValuesByKey.forEach(e => {

            // TODO: Make map strongly typed in model classes.
            let key: string = e[0];
            let value: boolean = Boolean(e[1]);

            if (key == paramValue.longId.toString()) {
              isDisallowedValue = value;
              return; // break the loop
            }

          });
        }

        // Ignore If disallowed value & hideIfDisabled
        if (isDisallowedValue && (paramValue.hideIfDisabled || this.parameter.hideValuesIfDisabled))
          return;

      }

      // Don't display values without any help info
      if (!paramValue.obsolete && (paramValue.helpText || paramValue.helpImageRelativeUrl || paramValue.externLink))
        this.values.push(paramValue);
    });

    this.setPopupSize();

  }

  setPopupSize(): void {

    let maxWidth: number = 0;
    this.values.forEach(x => {

      if (x.helpImageWidth > maxWidth)
        maxWidth = x.helpImageWidth;

    });

    if (this.parameter.helpImageWidth > maxWidth)
      maxWidth = this.parameter.helpImageWidth;

    this.helpWidth = `${maxWidth}px`;

  }

  getParamValue(id: number): ParamValue {
    return this.productStore.getEntity(id) as ParamValue;
  }

  get getParamIcon(): string {
    return this.parameter.className.toLowerCase();
  }

  getExternLink(parameter: Param) {
    // Get extern link as either http link or file link
    let result;
    if (parameter.externLink != null && parameter.externLink.trim().length > 0) {
      // Simple test for http(s) in string
      if (parameter.externLink.indexOf('http://') === 0 || parameter.externLink.indexOf('https://') === 0)
        result = parameter.externLink;
      else
        result = `file://${parameter.externLink}`;
    }
    return result;
  }
}