import { Injectable, Inject } from "@angular/core";
import * as Immutable from "immutable";

import { ReselectorService } from "../reselectorService";
import { AbstractAccountMessage } from "../../models/responses/messages";

@Injectable()
export class AccountDataMemorizer {

  constructor(public reselectorService: ReselectorService) {
  }

  public getMessagesMemorizer<T extends AbstractAccountMessage>(className: string): () => Immutable.List<T> {

    return this.reselectorService.createMemorizer((state) => {

      let messages = state.accountData.messages;
      if (messages && messages.size > 0)
        return messages.get(className) as Immutable.List<T>;

      return null;
    });
  }
}