<regular-popup *ngIf="visible"
               [title]="headerText"
               [id]="id"
               [draggable]="true"
               referenceContainerId="configuratorContainer"
               [showHeader]="true"
               [okButtonText]="strings.Ok"
               [cancelButtonText]="strings.Cancel"
               (onCloseStart)="onPopupClosed()">
  <!--Header part-->
  <div card-type="header" *ngIf="message != null">
    <div class="text-uppercase">
      {{caption}}
    </div>
    <div class="row py-1 m-0">
      <visual-object-link [configurationId]="message.configurationId" [paramInfo]="message.paramInfo" [popupId]="id">
        <co-image [key]="icon" imageSet="primary-light" width="48px" height="48px"></co-image>
      </visual-object-link>
      <div class="col modal-text-right">
        <span *ngIf="message.paramInfo.paramValues && message.paramInfo.paramValues.size > 0 else parameterValue">
          <p><em>{{message.paramInfo.title}}.{{message.paramInfo.paramValues.get(0).title}}</em></p>
          <p>
            <em>{{message.paramInfo.paramValues.get(0).value}}</em>
          </p>
        </span>
        <ng-template #parameterValue>
          <p><em>{{message.paramInfo.title}}</em></p>
          <p>
            <em>{{message.paramInfo.value}}</em>
          </p>         
        </ng-template>
      </div>
    </div>
    <div class="more-info">
      {{description}}
    </div>
  </div>

  <!--Body part-->
  <div card-type="body" class="pt-4 disallowed-body" *ngIf="message != null">

    <!-- Show all preventing conf infos (propagated parameters) -->
    <ng-container *ngIf="restrictingParent">
      <div class="row p-0 mx-0 mb-3">
        <span class="col ml-2 px-0" [innerHtml]="format(strings.DisallowedPopup_RestrictedByParentConfigurationModel, '<b>'+message.paramInfo.title+'</b>', restrictingParent.text)">
        </span>
      </div>
    </ng-container>

    <ng-container *ngIf="restrictingChildren">
      <div class="row p-0 mx-0 mb-3">
        <span class="ml-2 px-0" [innerHtml]="format(strings.DisallowedPopup_RestrictedByChildConfigurationModel, '<b>'+message.paramInfo.title+'</b>')"></span>
        <div *ngFor="let child of restrictingChildren" class="row p-0 mx-0">
          <span class="col ml-2 font-weight-bold">
            {{child.text}}
          </span>
        </div>
      </div>
    </ng-container>

    <!-- Show all preventing rule infos -->
    <ng-container *ngFor="let rule of message.ruleInfos">
          
      <rule-params-info [ruleInfo]="rule" [popupId]="id" [configurationId]="message.configurationId" ></rule-params-info>

    </ng-container>

  </div>
</regular-popup>