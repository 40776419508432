import { Injectable, Inject } from "@angular/core";
import * as Immutable from "immutable";
import { NodeData, TreeDataProvider } from "../../../shared/components/treeView";
import { Assortment, Product } from "../../shared/models";
import { ProductDataStore } from "../../shared/providers/productData";
import { GlobalDataStore } from "../../shared/providers/globalData";

@Injectable()
export class SelectorTreeDataProvider extends TreeDataProvider {

  constructor
    (
    @Inject(ProductDataStore) public productStore: ProductDataStore,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore
  ) {
    super();
  }

  /**
   * Loads the root nodes.
   * @param callback
   */
  loadRoots(callback: (children: NodeData[]) => void): void {

    this.productStore.getRootAssortments().subscribe(request => {

      let roots: NodeData[] = [];
      request.data.forEach(assortment => {

        // Create node data.
        let node: NodeData = this.setupNode(assortment);
        roots.push(node);

      });

      // Call back to tree component.
      callback(roots);
    });

  }

  /**
   * Loads the children.
   * @param parent
   * @param callback
   */
  loadChildren(parent: NodeData, callback: (children: NodeData[]) => void): void {

    let assortment = this.productStore.getEntity<Assortment>(parent.id);
    if (assortment && assortment instanceof Product) {
      return;
    }

    let children: NodeData[] = [];
    this.productStore.getAssortments(parent.id).subscribe(request => {

      request.data.forEach(assortment => {

        let node = this.setupNode(assortment, parent.id);
        children.push(node);

      });

      callback(children);

    });
  }

  /**
   * Sets up the node.
   * @param assortment
   * @param parentId
   */
  public setupNode(assortment: Assortment, parentId?: number) {

    let imageKey: string;
    let isVaultIcon: boolean = false;
    imageKey = assortment.iconRelativeUrl || (assortment instanceof Product ? "configuration" : "folder");
    isVaultIcon = assortment.iconRelativeUrl ? true : false;

    return this.createNode(assortment.longId, assortment.title, imageKey, isVaultIcon, parentId);
  }

  /**
   * Returns tree If node contains any child/children.
   * @param node
   */
  isNodeExpandable(node: NodeData): boolean {

    let assortment = this.productStore.getEntity<Assortment>(node.id);

    // Don't show the product's children.
    return !(assortment instanceof Product);
  }

  /**
   * Should tree node be collapse when making click on node title.
   * @param node
   */
  enableExpandCollapseOnTitleClick(node: NodeData): boolean { return true; }

  get showExpandCollapseIcon(): boolean {
    return this.globalDataStore.getGlobalData().globalSettings.showExpandCollapseIconsCreate;
  }

}