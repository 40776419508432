import * as Immutable from "immutable";

import { BaseEntity } from "../../baseEntity";

export class Language extends BaseEntity {

  protected readonly TITLE = "title";
  protected readonly CODES = "codes";

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get codes(): Immutable.List<string> { return this.getInternalValue<Immutable.List<string>>(this.CODES); }
  setCodes(codes: Immutable.List<string>): this { return this.setInternalValue(this.CODES, codes); }
}