<regular-popup *ngIf="visible"
               [id]="id"
               title="{{strings.MandatoryParameters}}"
               [showHeader]="!hasValue"
               [showFooter]="true"
               minHeight="250px"
               [draggable]="true"
               referenceContainerId="configuratorContainer"
               [okButton]="false"
               [cancelButton]="false"
               (onOpenStart)="onPopupOpen()"
               (onCloseStart)="onPopupClosed()">
  <div card-type="header">
    <div class="d-flex py-1 m-0">
      <co-image key="Info" imageSet="primary-light" width="48px" height="48px"></co-image>
      <div class="flex-fill modal-text-right align-middle">
        <p>
          {{strings.MandatoryPopup_NoMoreParametersMessage}}
        </p>
      </div>
    </div>
  </div>
  <div *ngIf="hasValue" card-type="body">
    <accordion classes="accordion">
      <accordion-group *ngFor="let value of values; trackBy: trackValue"
                       icon="configuration"
                       [isOpened]="value.isActiveConf"
                       [heading]="value.conf.text"
                       [iconIsVault]="false"
                       [selectedDarkTheme]="true"
                       (onOpen)="onAccordionOpen(value.conf.longId)"
                       (onToggle)="onAccordionToggle($event)">
        <div *ngFor="let parameter of value.parameters; trackBy: trackParam" class="mt-4">
          <visual-object-link [configurationId]="value.conf.longId" [visualObjectInTabId]="parameter.value.visualObjectInTabId" [popupId]="id" (click)="sendHighlightId(parameter.value.visualObjectInTabId)">
            <div class="d-flex control-border-b">
              <icon classes="pb-2" [view]="parameter"></icon>
              <span class="pl-3">{{parameter.title}}</span>
              <co-image class="parameter-show-icon" key="show" width="24px" height="24px" imageSet="success"></co-image>
            </div>
          </visual-object-link>
        </div>
      </accordion-group>
    </accordion>
  </div>
  <div card-type="footer" [style.width]="'100%'">
    <check-box [view]="highlightView"
               (onValueChange)="onHighlightParametersChanged($event)">
    </check-box>
  </div>
</regular-popup>
