import { BaseEntity } from "../../baseEntity";

export class RecentConf extends BaseEntity {

  protected readonly ICON_RELATIVE_URL = "iconRelativeUrl";
  protected readonly TITLE = "title";
  protected readonly DESCRIPTION = "description";
  protected readonly CONF_SESSION_ID = "confSessionId";

  get iconRelativeUrl(): string { return this.getInternalValue<string>(this.ICON_RELATIVE_URL); }
  setIconRelativeUrl(iconRelativeUrl: string): this { return this.setInternalValue(this.ICON_RELATIVE_URL, iconRelativeUrl); }

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get description(): string { return this.getInternalValue<string>(this.DESCRIPTION); }
  setDescription(description: string): this { return this.setInternalValue(this.DESCRIPTION, description); }
  
  get confSessionId(): number { return this.getInternalValue<number>(this.CONF_SESSION_ID); }
  setConfSessionId(confSessionId: number): this { return this.setInternalValue(this.CONF_SESSION_ID, confSessionId); }
}