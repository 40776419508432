import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ImageModule } from "../image";

import { GridviewComponent } from "./gridviewComponent";
import { GridviewHeaderComponent } from "./gridviewHeaderComponent";
import { GridviewFooterComponent } from "./gridviewFooterComponent";
import { GridviewRowComponent } from "./gridviewRowComponent";
import { ColumntemplateComponent } from "./columntemplate";
import { HeaderColumnTemplate } from "./headerColumnTemplate";

export * from "./gridviewComponent";
export * from "./gridviewHeaderComponent";
export * from "./gridviewRowComponent";
export * from "./columntemplate";
export * from "./headerColumnTemplate";

@NgModule({
  imports: [
    CommonModule,
    ImageModule
  ],
  declarations: [
    GridviewComponent,
    GridviewHeaderComponent,
    GridviewFooterComponent,
    GridviewRowComponent,
    ColumntemplateComponent,
    HeaderColumnTemplate,
  ],
  exports: [
    GridviewComponent,
    GridviewHeaderComponent,
    GridviewFooterComponent,
    GridviewRowComponent,
    ColumntemplateComponent,
    HeaderColumnTemplate
  ]
})
export class GridviewModule {

}