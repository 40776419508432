import { Component, Inject } from "@angular/core";
import { ImageDecoration, ConfImageValue, Conf } from "../../../shared/models";
import { ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { DecorationComponent } from "../../shared/decorationComponent";
import { VisualObjectHelper } from "../../parameters/shared";

@Component({
  selector: 'image-decoration',
  templateUrl: './imageDecorationComponent.html'
})
export class ImageDecorationComponent extends DecorationComponent<ImageDecoration> {

  public confImageValue: ConfImageValue;

  constructor(
    public confStore: ConfiguratorStore,
    public confPageSessionService: ConfPageSessionService,
    @Inject(VisualObjectHelper) public visualObjectHelper: VisualObjectHelper
  ) {
    super(confStore, confPageSessionService, visualObjectHelper);
  }

  ngOnInit() {

    // Initial value on load.
    this.confImageValue = this.configuration.imageValues.find((x) => x.longId == this.decoration.longId);
    super.ngOnInit();

  }

  public listenChanges(): void {
    this.confStore.onConfigurationChange(this.configurationId, this.confPageSessionService.confSessionId, (configuration: Conf): void => {
      this.confImageValue = this.configuration.imageValues.find((x) => x.longId == this.decoration.longId);
    }).unsubscribeOn(this.unsubscribeSubject);
  }

}