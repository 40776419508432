import { ImmutableObject } from "../../../../shared/immutableObject";

export class ConfDataProperty extends ImmutableObject {

  protected readonly NAME = "name";
  protected readonly TITLE = "title";
  protected readonly PROPERTY_TYPE = "propertyType";
  protected readonly ATTRIBUTE_TYPE = "attributeType";
  protected readonly REF_ID = "refId";
  protected readonly REF_CLASS_NAME = "refClassName";

  get name(): string { return this.getInternalValue<string>(this.NAME); }
  setName(name: string): this { return this.setInternalValue(this.NAME, name); }

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }
    
  get propertyType(): string { return this.getInternalValue<string>(this.PROPERTY_TYPE); }
  setPropertyType(propertyType: string): this { return this.setInternalValue(this.PROPERTY_TYPE, propertyType); }

  get attributeType(): string { return this.getInternalValue<string>(this.ATTRIBUTE_TYPE); }
  setAttibuteType(attributeType: string): this { return this.setInternalValue(this.ATTRIBUTE_TYPE, attributeType); }

  get refId(): number { return this.getInternalValue<number>(this.REF_ID); }
  setRefId(refId: number): this { return this.setInternalValue(this.REF_ID, refId); }

  get refClassName(): string { return this.getInternalValue<string>(this.REF_CLASS_NAME); }
  setRefClassName(refClassName: string): this { return this.setInternalValue(this.REF_CLASS_NAME, refClassName); }
}