import { Component, ViewEncapsulation, Inject } from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";

import { Routing } from "../../shared/route/routeDecorator";
import { RouteRedirector } from "../shared/providers";
import { BaseComponent } from "../shared";
import { ManagedSubject } from "../../shared/managedSubject";

@Routing({ path: 'redirector' })
@Component({
  encapsulation: ViewEncapsulation.None,
  templateUrl: './redirectorComponent.html',
})
export class RedirectorComponent extends BaseComponent {

  constructor(
    @Inject(Router) public router: Router,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    @Inject(ActivatedRoute) public activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    new ManagedSubject<any>(this.activatedRoute.params).subscribe(params => {
      // Redirect to url
      let paramObj = { ...params };
      let url = paramObj["url"];
      if (url) {
        delete paramObj["url"];

        // Start over the request
        if (paramObj['type'] == 'new')
          window.location.href = url;      

        else
          this.routeRedirector.redirect([url, paramObj]);
      }
    }).unsubscribeOn(this.unsubscribeSubject);
  }
}