import { Component, Inject } from "@angular/core";
import * as Immutable from "immutable"

import { InputViewModel, NotificationService, NotificationType, NotificationInfo } from "../../../shared/components";
import { BaseComponent } from "../../shared";
import { AccountDataStore, AccountDataMessageProvider } from "../../shared/providers/accountData";
import { UserUpdateMessage, User, AccountDataResponse } from "../../shared/models";
import { ManagedSubscription } from "../../../shared/managedSubscription";
import { ApplicationLifeCycleService } from "../../shared/providers";

@Component({ template: '' })
export abstract class AbstractUserSettingsComponent extends BaseComponent {

  public user: User;
  public visible: boolean = false;
  public messageSubscription: ManagedSubscription;

  constructor(
    public accountStore: AccountDataStore,
    public notificationService: NotificationService,
    public accountMessageProvider: AccountDataMessageProvider,
    public applicationLifeCycleService: ApplicationLifeCycleService
  ) {
    super();
  }

  ngOnInit() {
    this.setValues();
    this.visible = this.shouldBeVisible();
  }

  protected abstract shouldBeVisible(): boolean;

  public setValues() {
    this.user = this.accountStore.getUser();

    this.unblockUI();
  }

  public isChecked(view: InputViewModel): boolean {
    return typeof view.value === "boolean" ? view.value : view.value === "true";
  }

  public listenOnChange() {
    this.unsubscribe();

    this.messageSubscription = this.accountMessageProvider.onMessages<UserUpdateMessage>(UserUpdateMessage.name, {
      next: (messages) => {

        this.unsubscribe();

        this.applicationLifeCycleService.userUpdated(() => {

          this.setValues();

          let errors: Array<string>;
          let success = true;
          messages.forEach(message => {
            success = success && message.success;
            errors = message.errors.toArray().concat(errors);
          });

          this.notificationService.notify(<NotificationInfo>{
            title: success ? this.strings.Success : this.strings.Error,
            message: success ? "User updated successfully." : errors.join(', '),  // TODO: localize.
            type: success ? NotificationType.Success : NotificationType.Error,
            selfClose: true
          });
        });

      },
      listenNewEventsOnly: true
    });
  }

  public unsubscribe() {
    if (this.messageSubscription)
      this.messageSubscription.unsubscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();

    super.ngOnDestroy();
  }

  public updateSettings() {
    this.blockUI();
    this.listenOnChange();
  }

}