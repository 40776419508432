import { ImmutableObject } from "../../immutableObject";
import * as Immutable from "immutable";

/**
 * Represents the node data for TreeView.
 * Note! Don't add any such type of data which gets change very frequently. That would affect the performance. e.g Selected, expanded. Its better to add them into
 * <TreeNodeComponent>.
 */
export class NodeData extends ImmutableObject {

  public readonly ID = "Id";
  public readonly PARENT_ID = "parentId";  
  public readonly TITLE = "title";
  public readonly ICON_KEY = "iconKey";
  public readonly VAULT_ICON = "vaultIcon";
  public readonly ORDER = "order";
  public readonly CAN_ADD_CHILD = "canAddChild";
  public readonly STYLES = "styles";
  
  get id(): number { return this.getInternalValue<number>(this.ID); }
  setId(id: number): this { return this.setInternalValue(this.ID, id); }

  get parentId(): number { return this.getInternalValue<number>(this.PARENT_ID); }
  setParentId(parentId: number): this { return this.setInternalValue(this.PARENT_ID, parentId); }
  
  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get iconKey(): string { return this.getInternalValue<string>(this.ICON_KEY); }
  setIconKey(iconKey: string): this { return this.setInternalValue(this.ICON_KEY, iconKey); }

  get vaultIcon(): boolean { return this.getInternalValue<boolean>(this.VAULT_ICON); }
  setVaultIcon(vaultIcon: boolean): this { return this.setInternalValue(this.VAULT_ICON, vaultIcon); }

  get order(): number { return this.getInternalValue<number>(this.ORDER); }
  setOrder(order: number): this { return this.setInternalValue(this.ORDER, order); }

  get canAddChild(): boolean { return this.getInternalValue<boolean>(this.CAN_ADD_CHILD); }
  setCanAddChild(canAddChild: boolean): this { return this.setInternalValue(this.CAN_ADD_CHILD, canAddChild); }

  get styles(): string { return this.getInternalValue<string>(this.STYLES); }
  setStyles(styles: string): this { return this.setInternalValue(this.STYLES, styles); }

}