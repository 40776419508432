import * as Immutable from "immutable";

import { ImmutableObject } from "../../../../shared/immutableObject";

export class SystemAuthorization extends ImmutableObject {
    
  static readonly CAN_CREATE_PRIVATE_SEARCH = "canCreatePrivateSearch";
  static readonly CAN_CREATE_OWN_WORKGROUP_SEARCH = "canCreateOwnWorkGroupSearch";
  static readonly CAN_CREATE_ANY_WORKGROUP_SEARCH = "canCreateAnyWorkGroupSearch";
  static readonly CAN_CREATE_AUTHORIZATION_GROUP_SEARCH = "canCreateAuthorizationGroupSearch";
  static readonly CAN_CREATE_PUBLIC_SEARCH = "canCreatePublicSearch";
  static readonly CAN_RELOAD_CACHE = "canReloadCache";
  static readonly CAN_VIEW_APP_HEADER = "canViewWebAppHeader";
  static readonly CAN_SEARCH_CHILDREN = "canSearchChildren";
  static readonly CAN_SEARCH_CONFIGURATIONS = "canSearchConfigurations";
  static readonly CAN_CHANGE_USER_PROFILE = "canChangeUserProfile";
  static readonly CAN_CHANGE_USER_SETTINGS = "canChangeUserSettings";
  static readonly HAS_ANONYMOUS_ACCESS = "hasAnonymousAccess";
  
  get canCreatePrivateSearch(): boolean { return this.getInternalValue<boolean>(SystemAuthorization.CAN_CREATE_PRIVATE_SEARCH); }
  setCanCreatePrivateSearch(canCreatePrivateSearch: boolean): this { return this.setInternalValue(SystemAuthorization.CAN_CREATE_PRIVATE_SEARCH, canCreatePrivateSearch); }

  get canCreateOwnWorkGroupSearch(): boolean { return this.getInternalValue<boolean>(SystemAuthorization.CAN_CREATE_OWN_WORKGROUP_SEARCH); }
  setCanCreateOwnWorkGroupSearch(canCreateWorkGroupSearch: boolean): this { return this.setInternalValue(SystemAuthorization.CAN_CREATE_OWN_WORKGROUP_SEARCH, canCreateWorkGroupSearch); }

  get canCreateAnyWorkGroupSearch(): boolean { return this.getInternalValue<boolean>(SystemAuthorization.CAN_CREATE_ANY_WORKGROUP_SEARCH); }
  setCanCreateAnyWorkGroupSearch(canCreateAnyWorkGroupSearch: boolean): this { return this.setInternalValue(SystemAuthorization.CAN_CREATE_ANY_WORKGROUP_SEARCH, canCreateAnyWorkGroupSearch); }

  get canCreateAuthorizationGroupSearch(): boolean { return this.getInternalValue<boolean>(SystemAuthorization.CAN_CREATE_AUTHORIZATION_GROUP_SEARCH); }
  setCanCreateAuthorizationGroupSearch(canCreateAuthorizationGroupSearch: boolean): this { return this.setInternalValue(SystemAuthorization.CAN_CREATE_AUTHORIZATION_GROUP_SEARCH, canCreateAuthorizationGroupSearch); }

  get canCreatePublicSearch(): boolean { return this.getInternalValue<boolean>(SystemAuthorization.CAN_CREATE_PUBLIC_SEARCH); }
  setCanCreatePublicSearch(canCreatePublicSearch: boolean): this { return this.setInternalValue(SystemAuthorization.CAN_CREATE_PUBLIC_SEARCH, canCreatePublicSearch); }

  get canReloadCache(): boolean { return this.getInternalValue<boolean>(SystemAuthorization.CAN_RELOAD_CACHE); }
  setCanReloadCache(canReloadCache: boolean): this { return this.setInternalValue(SystemAuthorization.CAN_RELOAD_CACHE, canReloadCache); }

  get canViewWebAppHeader(): boolean { return this.getInternalValue<boolean>(SystemAuthorization.CAN_VIEW_APP_HEADER); }
  setCanViewWebAppHeader(canViewWebAppHeader: boolean): this { return this.setInternalValue(SystemAuthorization.CAN_VIEW_APP_HEADER, canViewWebAppHeader); }

  get canSearchChildren(): boolean { return this.getInternalValue<boolean>(SystemAuthorization.CAN_SEARCH_CHILDREN); }
  setCanSearchChildren(canSearchChildren: boolean): this { return this.setInternalValue(SystemAuthorization.CAN_SEARCH_CHILDREN, canSearchChildren); }

  get canSearchConfigurations(): boolean { return this.getInternalValue<boolean>(SystemAuthorization.CAN_SEARCH_CONFIGURATIONS); }
  setCanSearchConfigurations(canSearchConfigurations: boolean): this { return this.setInternalValue(SystemAuthorization.CAN_SEARCH_CONFIGURATIONS, canSearchConfigurations); }
  
  get canChangeUserProfile(): boolean { return this.getInternalValue<boolean>(SystemAuthorization.CAN_CHANGE_USER_PROFILE); }
  setCanChangeUserProfile(canChangeUserProfile: boolean): this { return this.setInternalValue(SystemAuthorization.CAN_CHANGE_USER_PROFILE, canChangeUserProfile); }

  get canChangeUserSettings(): boolean { return this.getInternalValue<boolean>(SystemAuthorization.CAN_CHANGE_USER_SETTINGS); }
  setCanChangeUserSettings(canChangeUserSettings: boolean): this { return this.setInternalValue(SystemAuthorization.CAN_CHANGE_USER_SETTINGS, canChangeUserSettings); }

  get hasAnonymousAccess(): boolean { return this.getInternalValue<boolean>(SystemAuthorization.HAS_ANONYMOUS_ACCESS); }
  setHasAnonymousAccess(hasAnonymousAccess: boolean): this { return this.setInternalValue(SystemAuthorization.HAS_ANONYMOUS_ACCESS, hasAnonymousAccess); }

}