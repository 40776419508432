import { BaseRequest } from "./baseRequest";
import * as Immutable from "immutable";
import { SequencReference } from "../responses/messages/popupSequence";
import { Purpose } from "../responses/messages/popupObject";
import { PopupClientResponse } from "../../../configurator/popups/msCrmIntegrationInteraction/crmIntegrationPopupComponent";

export class PopupClientChoiceRequest extends BaseRequest {
	sequencReference?: SequencReference;
	purpose?: Purpose;
	popupClientResponse?: PopupClientResponse;
	sessionId?: number;
	rootConfId?: number;
	subConfId?: number;
	subConfIdentity?: string;
	productId?: number;
	generatedProductId?: number;
	quoteDetailCrmIdentity?: string;
	quoteDetailCombinumIdentity?: string;
	args?: Immutable.Map<string, string>;
}