<div id="searchConfigurationResult">

  <popper-content width="350">
    <configuration-context-menu></configuration-context-menu>
  </popper-content>

  <div class="row mb-2">
    <div class="col">
      <h1 class="text-uppercase pb-2">{{strings.SearchResult}}</h1>
    </div>
    <div *ngIf="enableComparison" class="col text-right comparison-margin">
      <button type="button" [attr.disabled]="showCompareButton ? null : ''" (click)="onCompareConfigurations($event)" class="compare-btn btn btn-primary text-uppercase">{{strings.Compare}}</button>
    </div>
  </div>

  <div [style.position]="'relative'" class="comparison-margin" *ngIf="searchModelCache.dataLoaded; else loading">

    <ng-container *ngIf="rows.size > 0; else empty">
      <div class="table-wrapper">
        <table class="useBorder w-100" [class.comparison-margin]="enableComparison">
          <thead class="input-font-style text-center search-columns">
            <tr>
              <th *ngFor="let column of columns; trackBy: trackHeader"
                  title="{{column.contents}}"
                  class="{{column.classes}}"
                  [ngStyle]="column.safeStyle"
                  (click)="sort(column.tag)">
                <div class="d-flex align-middle justify-content-center">
                  <span>
                    {{column.contents}}
                  </span>
                </div>
              </th>
              <th class="comparison-column"></th>
            </tr>
          </thead>
          <tbody>
            <tr class="configuration-row {{row.classes}}" *ngFor="let row of rows; trackBy: trackRow" (click)="onConfigurationClick($event, row.tag)" role="button">
              <td *ngFor="let cell of row.cells"
                  class="{{cell.classes}}"
                  title="{{cell.contents}}">
                {{cell.contents}}
              </td>
              <td class="comparison-column">
                <div *ngIf="enableComparison" class="absolute-column-right">
                  <check-box type="standard" [view]="row.view" (onValueChange)="onCompareSelectionChanged($event, row)"></check-box>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pager [pagerModel]="pagerModel"
             (pagerUpdated)="pagerUpdated()"
             [class.comparison-margin]="enableComparison"></pager>
    </ng-container>
    <ng-template #empty>
      <div class="no-result font-italic">{{this.strings.SorryNoResultsPleaseTryAgain}}.</div>
    </ng-template>
  </div>
  <!-- TODO this loader is only shown on first request as dataLoaded is never reset. Should it reset it on filter change? -->
  <ng-template #loading>
    <div class="no-result font-italic">{{this.strings.Loading}}</div>
  </ng-template>

</div>  