import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AbsoluteLayoutComponent } from "./absoluteLayoutComponent";

export * from "./absoluteLayoutComponent";

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AbsoluteLayoutComponent,    
  ],
  exports: [
    AbsoluteLayoutComponent,    
  ]
})
export class LayoutModule {

}