import { Component, Inject, Input } from "@angular/core";
import { BaseSearchInputComponent } from "../baseSearchInputComponent";
import { InputViewModel } from "../../../../../../shared/components";
import { SearchCriteriaModelsProvider, SearchCriteriaHelper } from "../../providers";
import { AttributeField, SearchValueControlType } from "../../models";
import { RangeOperator, RangeInputViewModel } from "../../../../../../shared/components/textboxRange/rangeInputViewModel";
import { ConfSearchCriteria, ConfSearchProperty } from "../../../../../shared/models";

@Component({
  selector: 'search-range-textbox',
  templateUrl: './searchRangeTextboxComponent.html',
})
export class SearchRangeTextboxComponent extends BaseSearchInputComponent {

  public rangeView: RangeInputViewModel;

  constructor(
    @Inject(SearchCriteriaModelsProvider) public modelsProvider: SearchCriteriaModelsProvider,
    protected searchCriteriaHelper: SearchCriteriaHelper
  ) {
    super(searchCriteriaHelper);
  }

  ngOnInit() {

    let attr: AttributeField = this.criteria.selectedAttribute;

    this.rangeView = new RangeInputViewModel();
    this.rangeView.name = 'Range';
    this.rangeView.placeHolder1 = 'Min value'; // TODO: localize it
    this.rangeView.placeHolder2 = 'Max value'; // // TODO: localize it    
    this.rangeView.placeholder = this.strings.Value;
    this.rangeView.width = this.width;
    this.rangeView.id = this.criteria.selectedAttribute.id;
    this.rangeView.Operator = RangeOperator.Range; // Default operator.

    let value: string = this.extractValue;
    if (value) {

      // Set the value and operator
      if (value.indexOf('..') >= 0) {
        let arr: string[] = value.split('..');
        this.rangeView.value1 = arr[0];
        if (arr.length > 1)
          this.rangeView.value2 = arr[1];

      }
      else {

        let operator = this.extractOperator(value);

        // Remove operator from value.
        let arr = value.split(operator);

        // Second index contains the value
        if (arr.length > 1)
          this.rangeView.value = arr[1];

        this.rangeView.Operator = operator;        
      }
    }

  }

  extractOperator(value: string): RangeOperator {

    if (value.indexOf(RangeOperator.Greater) >= 0)
      return RangeOperator.Greater;

    else if (value.indexOf(RangeOperator.GreaterOrEqual) >= 0)
      return RangeOperator.GreaterOrEqual

    else if (value.indexOf(RangeOperator.Less) >= 0)
      return RangeOperator.Less;

    else if (value.indexOf(RangeOperator.LessOrEqual) >= 0)
      return RangeOperator.LessOrEqual;

    return RangeOperator.Equal;
  }

  /** Returns true if the this criteria has a value, otherwise false. */
  public get hasValue(): boolean {
    return this.value;
  }

  /** Returns the current user input value. */
  public get value(): any {

    switch (this.rangeView.Operator) {

      case RangeOperator.Range:

        if (this.rangeView.value1 && this.rangeView.value2)
          return `${this.rangeView.value1}..${this.rangeView.value2}`;

      case RangeOperator.Less:
      case RangeOperator.LessOrEqual:
      case RangeOperator.Greater:
      case RangeOperator.GreaterOrEqual:
      case RangeOperator.Equal:

        if (this.rangeView.value)
          return `${this.rangeView.Operator} ${this.rangeView.value}`;

    }

    return this.rangeView.value;
  }


}