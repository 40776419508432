<div class="textbox-range" [style.width]="view.width" >

  <div class="row">

    <div class="col-auto text-center operators px-0" [style.width]="'90px'">
      <dropdown [view]="internalOperatorGroup"
                [itemsView]="internalOperatorItems"
                (onValueChange)="onOperatorChange($event)">
      </dropdown>
    </div>

    <div class="col value" *ngIf="internalView">
      <text-box [view]="internalView"
                (onValueChange)="onSingleValueChange($event)"></text-box>
    </div>

    <div class="col value1" *ngIf="internalView1">
      <text-box [view]="internalView1"
                (onValueChange)="onValueChange1($event)"></text-box>
    </div>

    <div class="col value2" *ngIf="internalView2">
      <text-box [view]="internalView2"
                (onValueChange)="onValueChange2($event)"></text-box>
    </div>
  </div>
</div>
