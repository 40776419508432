import { Component, Input, Output, EventEmitter, OnInit, Inject, ViewChild, ElementRef, HostListener, ChangeDetectorRef, SimpleChanges } from "@angular/core";
import { InputViewModel, Positions } from "../../../../shared/components/shared";
import { PagerModel } from "./pagerModel";
import { BaseComponent } from "../..";
import { PagerService } from "./pagerService";

@Component({
  selector: 'pager',
  templateUrl: './pagerComponent.html'
})
export class PagerComponent extends BaseComponent implements OnInit {

  @Input() pagerModel: PagerModel;
  @Input() initialWidth: number | string;

  // manually trigger change detection
  @Input() refresh = false;

  @Output() pagerUpdated = new EventEmitter();

  @ViewChild("container")
  public container: ElementRef
  public containerWidth: number = null;

  public largeUp = false;
  public mediumUp = false;
  public smallDown = false;  
  public extraSmallDown = false;
  public maxPages = 5;

  public pagingInfoLabel = "";

  constructor(
    @Inject(ChangeDetectorRef) public cd: ChangeDetectorRef,
    @Inject(PagerService) public pagerService: PagerService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {    
    if (changes['refresh'])      
      this.updatePagingInfoLabel();

  }

  ngOnInit() {
    this.pagerModel = this.pagerModel || this.pagerService.create();

    this.updatePagingInfoLabel();

    if (this.containerWidth == null)
      setTimeout(() => {
        if (this.cd['destroyed'])
          return;

        this.setWidth();
        this.setSizes();
        this.cd.detectChanges();
      }, 0);
    else {
      this.setWidth();
      this.setSizes();
    }
  }

  ngAfterViewInit() {

    this.setWidth();
    this.setSizes();

  }

  /**
  * Get paging info label.
    Like 'Showing 11 to 20 of 50'.
  */
  public updatePagingInfoLabel() {
    let first = this.pagerModel.resultCount > 0 ? this.pagerModel.displayCountView.count * (this.pagerModel.currentPage - 1) : -1;
    let second = this.pagerModel.displayCountView.displayAll ? this.pagerModel.resultCount : Math.min(first + this.pagerModel.displayCountView.count, this.pagerModel.resultCount);
    this.pagingInfoLabel = this.format(this.strings.ViewXYOfZ, Math.max(0, first + 1), second, this.pagerModel.resultCount);    
  }

  public setWidth() {
    this.containerWidth = this.container.nativeElement.getBoundingClientRect().width;
    if (this.containerWidth <= 0)
      this.containerWidth = this.initialWidth ? (parseInt(this.initialWidth.toString().replace(/(px|%)/g, ""))) : window.innerWidth;
  }

  public setSizes() {
    this.largeUp = this.getLargeUp();
    this.mediumUp = this.getMediumUp();
    this.smallDown = this.getSmallDown();    
    this.extraSmallDown = this.getExtraSmallDown();

    this.maxPages = this.getMaxPages();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setWidth();
    this.setSizes();
  }

  public getLargeUp() {
    return this.containerWidth > bomSizes.lg;
  }

  public getMediumUp() {
    return this.containerWidth > bomSizes.md;
  }

  public getSmallDown() {
    return this.containerWidth < bomSizes.sm;
  }

  public getExtraSmallDown() {
    return this.containerWidth < bomSizes.xs;
  }

  public getMaxPages() {
    return this.mediumUp ? 5 : this.smallDown ? 1 : 3;
  }

  public updatePager() {
    // Reset current page if its out of bounds
    if ((this.pagerModel.currentPage - 1) * this.pagerModel.displayCountView.count >= this.pagerModel.resultCount)
      this.pagerModel.currentPage = 1;

    this.pagerUpdated.emit(this.pagerModel);

    this.updatePagingInfoLabel();
  }

  onPageChanged($event) {
    this.pagerModel.currentPage = $event.page;
    this.updatePager();
  }

  onValueChange($event): void {
    this.updatePager();
  }

}

let bomSizes: any = {
  xs: 330,
  sm: 410,
  md: 495,
  lg: 580,
};