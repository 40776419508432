import { ChangeDetectorRef, Component, EventEmitter, Inject, Output } from "@angular/core";
import * as Immutable from "immutable";
import { PopupService } from "../../../../shared/components";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import { BomItemMessage } from "../../../shared/models/responses/messages";
import { ConfiguratorStore, ConfPageSessionService, PopupIdentifiers } from "../../providers";
import { BomActionArgs } from "./bomActionArgs";
import { BomStore } from "./bomStore";


@Component({
  selector: 'bom-delete-popup',
  templateUrl: './bomDeletePopupComponent.html',
})
export class BomDeletePopupComponent extends AbstractPopupComponent {

  public bomDecorationId: number;
  public bomItem: BomItemMessage;

  @Output()
  onItemDeleted = new EventEmitter();
  @Output()
  closedEvent = new EventEmitter();

  constructor(
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(BomStore) public bomStore: BomStore,
    @Inject(PopupService) public popupService: PopupService,
    public cdr: ChangeDetectorRef
  ) {
    super(cdr, popupService);
  }

  ngOnInit() {
    this.id = PopupIdentifiers.BomDeletePopup;

    super.ngOnInit();

    this.bomStore.onGetItemRequest(this.storeSession.confSessionId, (bomItemMessages: Immutable.List<BomItemMessage>): void => {
      this.bomItem = bomItemMessages.first();
    }).unsubscribeOn(this.unsubscribeSubject);
  }

  public open(tag: BomActionArgs) {
    this.show(tag.decorationId, tag.selectedRowId);
  }

  show(bomDecorationId: number, bomItemId: number): void {
    this.bomDecorationId = bomDecorationId;

    this.bomStore.dispatchGetItem(this.storeSession.activeConfigurationId, this.storeSession.confSessionId, this.bomDecorationId, bomItemId);

    this.showInternal();
  }

  okClick(): void {
    this.bomStore.dispatchRemoveBom(this.storeSession.activeConfigurationId, this.storeSession.confSessionId, this.bomDecorationId, this.bomItem.id);
    this.onItemDeleted.emit();
  }

  public removeDomElement() {
    super.removeDomElement();

    this.closedEvent.emit();
  }
}