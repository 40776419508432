import * as Immutable from "immutable";

import { VisualObject } from "../../shared/models";
import { ImmutableObject } from "../../../shared/immutableObject";
import { VisualObjectGroup } from "./visualObjectGroup";

/**
 * This class contains volatile data where the changes occur very frequently.
 */
export class VisualObjectGroupWrapper extends ImmutableObject {

  public readonly LINE_BREAK = "lineBreak";
  public readonly VISIBLE = "visible";
  public readonly HANGING = "hanging";
  public readonly VISUAL_OBJECT_GROUP = "visualObjectGroup";
  public readonly HANGINGS = "hangings";
  public readonly NEW_HANGINGS = "newHangings";

  // Line break for visual object
  get lineBreak(): boolean { return this.getInternalValue<boolean>(this.LINE_BREAK); }
  setLineBreak(lineBreak: boolean): this { return this.setInternalValue(this.LINE_BREAK, lineBreak); }

  // Visibility of visual object
  get visible(): boolean { return this.getInternalValue<boolean>(this.VISIBLE); }
  setVisible(visible: boolean): this { return this.setInternalValue(this.VISIBLE, visible); }

  // It is hanged? 
  // Note! It has the calculated hanging value. It sometimes won't be same as visual object value. e.g If the first visual object is set 'hanged' then hanging won't work. In this case calculated hanging would be false.
  get hanging(): boolean { return this.getInternalValue<boolean>(this.HANGING); }
  setHanging(hanging: boolean): this { return this.setInternalValue(this.HANGING, hanging); }

  // New hangings to current visual object.
  // Reason: To avoid the unnecessary <VisualObjectGroup> updates, first we store the new hangings in this list, later we make the comparison with <VisualObjectGroup.hangings> if both hangings are not same then we update the immutable <VisualObjectGroup>.
  get newHangings(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.NEW_HANGINGS); }
  setNewHangings(newHangings: Immutable.List<number>): this { return this.setInternalValue(this.NEW_HANGINGS, newHangings); }

  // Visual object
  get visualObjectGroup(): VisualObjectGroup { return this.getInternalValue<VisualObjectGroup>(this.VISUAL_OBJECT_GROUP); }
  setVisualObjectGroup(visualObjectGroup: VisualObjectGroup): this { return this.setInternalValue(this.VISUAL_OBJECT_GROUP, visualObjectGroup); }

}