import { Param } from "./param";

export class IntParam extends Param {

  protected readonly STEP_VALUE = "stepValue";
  protected readonly PLACEHOLDER = "placeholder";

  get stepValue(): number { return this.getInternalValue<number>(this.STEP_VALUE); }
  setStepValue(stepValue: number): this { return this.setInternalValue(this.STEP_VALUE, stepValue); }

  get placeholder(): string { return this.getInternalValue<string>(this.PLACEHOLDER); }
  setPlaceholder(placeholder: string): this { return this.setInternalValue(this.PLACEHOLDER, placeholder); }

}