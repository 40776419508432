import { Component, Inject, Input, ViewContainerRef, ComponentFactoryResolver, ViewChild, OnDestroy } from "@angular/core";
import { Unsubscribe } from 'redux';
import { StringParam, ConfStringValue, Conf, Tab, ConfValue } from "../../../shared/models";
import { BaseEntity } from "../../../shared/baseEntity";
import { InputViewModel, ValueChangeEventArgs, RestrictValueChangeEventArgs, PopupService } from "../../../../shared/components";
import { VisualObjectViewModelFactory } from "../shared/visualObjectViewModelFactory";
import { ParamComponent } from "../../shared/paramComponent";
import { ProductDataStore } from "../../../shared/providers/productData";
import { ConfiguratorStore } from "../../providers/configuratorStore";
import { ConfigurationSessionManager } from "../../providers/configurationSessionManager";
import { ParameterMandatoryService } from "../shared/parameterMandatoryService";
import { ConfPageSessionService, ConfMessageProvider } from "../../providers";
import { ChangeDetectorRef } from "@angular/core";
import { VisualObjectHelper } from "../shared";

@Component({
  selector: 'string-param',
  templateUrl: './stringParamComponent.html'
})
export class StringParamComponent extends ParamComponent<StringParam> {

  confStringValue: ConfStringValue;
  viewModel: InputViewModel;
  unsubscribeListener: Unsubscribe;

  constructor(
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(ComponentFactoryResolver) private resolver: ComponentFactoryResolver,
    @Inject(VisualObjectViewModelFactory) private viewFactory: VisualObjectViewModelFactory,
    @Inject(PopupService) public popupService: PopupService,
    @Inject(ParameterMandatoryService) public parameterMandatoryService: ParameterMandatoryService,
    @Inject(ConfMessageProvider) public messagesProvider: ConfMessageProvider,
    @Inject(VisualObjectHelper) public visualObjectHelper: VisualObjectHelper,
    public cd: ChangeDetectorRef
  ) {
    super(confStore, confPageSessionService, popupService, visualObjectHelper, parameterMandatoryService, messagesProvider, cd);
  }

  ngOnInit() {

    this.viewModel = this.viewFactory.create(this.parameter);

    if (this.parameter.placeholder)
      this.viewModel.placeholder = this.parameter.placeholder;

    // Set value on load.
    this.setValue(this.confStore.getConfValue(this.configuration.longId, this.confPageSessionService.confSessionId, this.parameter.visualObjectId));

    super.ngOnInit();
  }

  public setValue(confValue: BaseEntity) {
    this.confStringValue = confValue as ConfStringValue;

    if (!this.confStringValue)
      return;

    // Set value to view
    this.viewModel.value = this.confStringValue.value;

    // Set readonly status to view.
    this.viewModel.readOnly = this.confStringValue.isReadOnly;
  }

  onValueChange($event: ValueChangeEventArgs): void {
    super.onValueChange($event);

    // Update value
    this.confStore.setConfValue(this.confStringValue, this.confPageSessionService.confSessionId, $event.value, this.confPageSessionService.includeMandatoryInfo, this.confPageSessionService.isNextStateIncluded);
  }

  onReadOnly($event: RestrictValueChangeEventArgs): void {
    this.confStore.getReadOnlyInfo(this.configurationId, this.confSessionId, this.confStringValue, +$event.inValidValue);
  }

  onDisallowed($event: RestrictValueChangeEventArgs): void {    
  }

  public getCurrentValue(): any {

    // If value is different then set it to ConfValue and return the change ConfValue to cause a value change trigger
    if (this.confStringValue && this.confStringValue.value != this.viewModel.value)
      return this.confStringValue.setValue(this.viewModel.value);

    return this.confStringValue;
  }

  public setError(errors: Array<string>) {
    this.viewModel.errors = errors;
  }
}