<ng-container *ngIf="uiElement" [ngSwitch]="uiElement.className">
  <!--<span>Hej{{uiElement.className}}</span>-->
  <label *ngSwitchCase="'UiLabel'" [class]="uiElement.cssClass" [style]="style" [for]="uiElement.forId">{{uiElement.title}}</label>
  <div *ngSwitchCase="'UiGroup'" [class]="uiElement.cssClass" [style]="style">
    <ui-element *ngFor="let uiElement of uiElement.uiElements" [uiElement]="uiElement" [sessionId]="sessionId" (action)="onAction($event)"></ui-element>
  </div>

  <ng-container *ngSwitchCase="'UiInput'">
    <text-box *ngIf="uiElement.type=='text'" [class]="uiElement.cssClass" [style]="style" [view]="textBoxView" (onValueChange)="onTextValueChange($event)">
    </text-box>
    <check-box *ngIf="uiElement.type=='text'" [view]="checkboxView" (onValueChange)="onCheckChanged($event)">
    </check-box>
    <input *ngIf="uiElement.type=='submit'" [id]="uiElement.id" [class]="uiElement.cssClass" [style]="style" [value]="uiElement.value" [type]="uiElement.type" (click)="onClick(uiElement.id)">
  </ng-container>
  <ng-container *ngSwitchCase="'UiText'" (click)="onClick(uiElement.id)">
    <p *ngIf="uiElement.textType==TextType.Paragraph" [class]="uiElement.cssClass" [style]="style" [ngSwitch]="uiElement.className">
      {{
uiElement.title
      }}
    </p>
    <span *ngIf="uiElement.textType==TextType.Span" [class]="uiElement.cssClass" [style]="style" [ngSwitch]="uiElement.className">{{uiElement.title}}</span>
  </ng-container>
  <a *ngSwitchCase="'UiLink'" [class]="uiElement.cssClass" [style]="style" [href]="uiElement.href" [target]="uiElement.target">{{uiElement.title}}</a>
  <div *ngSwitchCase="'UiSelect'" [class]="uiElement.cssClass" [style]="style">
    <select-option [view]="viewModel"
                   [itemsView]="itemsView"
                   [displayAsRadio]="uiElement.isRadioButton"
                   [displayStyle]="DISPLAY_STYLE_OPTIONS.Standard"
                   [selectedValueId]="selectedViewId"
                   (onValueChange)="onSelectValueChange($event)"
                   [enableHelp]="false">
    </select-option>
  </div>
  <div *ngSwitchDefault>
    No implementation for: {{uiElement.className}}
  </div>
</ng-container>
