import { Component, Input, Inject, Output, EventEmitter, ViewChild } from "@angular/core";

import { BaseComponent } from "../..";
import { ConfRevision, Conf } from "../../models";
import { InputViewModel, ValueChangeEventArgs } from "../../../../shared/components/shared";
import { RouteRedirector, RouteNames } from "../../providers";
import { ConfRouteParams } from "../../../configurator/providers";
import { CoCheckboxComponent } from "../../../../shared/components/checkbox";
import { ImageSets } from "../../../../shared/utils";

@Component({
  selector: 'revision-item',
  templateUrl: './revisionItemComponent.html'
})
export class RevisionItemComponent extends BaseComponent {

  @Input()
  conf: Conf;

  @Input()
  confSessionId: number;

  @Input()
  showCheckbox: boolean = true;

  @Input()
  revision: ConfRevision;

  @Output()
  onValueChange = new EventEmitter<ValueChangeEventArgs>();

  checkboxView: InputViewModel;

  public imageSet: string;

  constructor(
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector
  ) {
    super();
  }

  ngOnInit() {

    this.checkboxView = { id: this.revision.configurationId.toString() } as InputViewModel;
    this.imageSet = this.getImageSet(ImageSets.SummaryActionDetail);

    super.ngOnInit();
  }

  public onClick() {
    // Redirect to the clicked configuration
    // TODO go through the redirector for now.. Should maybe be updated to swap configuration directly?    
    this.routeRedirector.redirectToRedirector(`${RouteNames.Configurator}/${RouteNames.Summary}`, { id: this.revision.configurationId });
  }

}
