import { ApiMessage } from "./apiMessage";
import * as Immutable from "immutable";
import { PopupSequence } from "./popupSequence";

export class ComplexSequentialPopups extends ApiMessage {
	readonly POPUP_SQUENCES = "popupSequences";
	
	get popupSequences(): Immutable.List<PopupSequence> { return this.getInternalValue<Immutable.List<PopupSequence>>(this.POPUP_SQUENCES); }
	setPopupSequences(popupSequences: Immutable.List<PopupSequence>): this { return this.setInternalValue(this.POPUP_SQUENCES, popupSequences); }
}

