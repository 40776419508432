import { ChangeDetectorRef, Component, Inject, ViewChild } from "@angular/core";
import * as Immutable from "immutable";
import { PopupService } from "../../../../shared/components";
import { InputViewModel } from "../../../../shared/components/shared";
import { ManagedSubscription } from "../../../../shared/managedSubscription";
import { AutoCompleteComponent } from "../../../shared/components/autoComplete";
import { BomAutoCompleteRow } from "../../../shared/models";
import { BomAutoCompleteMessage } from "../../../shared/models/responses/messages";
import { ConfiguratorStore, ConfPageSessionService, PopupIdentifiers } from "../../providers";
import { BomActionArgs } from "./bomActionArgs";
import { BomControlComponent } from "./bomControlComponent";
import { BomStore } from "./bomStore";


@Component({
  selector: 'bom-create-control',
  templateUrl: './bomCreateControlComponent.html',
})
export class BomCreateControlComponent extends BomControlComponent {
  public searchBomSubscription: ManagedSubscription;
  public items: Array<InputViewModel> = [];
  public autoCompleteView: InputViewModel;

  @ViewChild(AutoCompleteComponent)
  public autoCompleteComponent: AutoCompleteComponent;

  constructor(
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(BomStore) public bomStore: BomStore,
    @Inject(PopupService) public popupService: PopupService,
    public cdr: ChangeDetectorRef

  ) {
    super(confStore, storeSession, bomStore, cdr, popupService);
  }

  public open(tag: BomActionArgs) {
    this.createItem(tag.decorationId);
  }

  ngOnInit() {
    this.autoCompleteView = new InputViewModel(this.strings.Search);
    this.id = PopupIdentifiers.BomCreatePopup;

    super.ngOnInit();
  }

  ngOnDestroy() {
    if (this.searchBomSubscription)
      this.searchBomSubscription.unsubscribe();

    super.ngOnDestroy();
  }

  public createItem(bomDecorationId: number): void {
    this.searchBomSubscription = this.bomStore.onSearchBom(this.storeSession.confSessionId, {
      next: (messages: Immutable.List<BomAutoCompleteMessage>) => {
        let message = messages.first();

        this.items = [];

        this.setupQuantityUnits(message.quantityUnits);
        this.setupOperationSequence(message.operationSequences);

        message.autoCompleteRowModels.forEach((row: BomAutoCompleteRow, key) => {
          // Description will be the item's formatted name
          let rowModel = new InputViewModel(row.description);
          rowModel.value = row;
          this.items.push(rowModel);
        });

        this.cdr.detectChanges();
      }, listenNewEventsOnly: true
    });

    super.show(bomDecorationId);
  }

  public removeDomElement() {
    if (this.searchBomSubscription)
      this.searchBomSubscription.unsubscribe();

    super.removeDomElement();
  }

  public onOkClick() {
    this.bomStore.dispatchCreateBom(this.storeSession.activeConfigurationId, this.storeSession.confSessionId, this.bomDecorationId, this.itemNoView.value, this.revisionView.value, this.quantityView.value, parseInt(this.quantityUnitView.value), parseInt(this.operationSequenceView.value));

    this.autoCompleteComponent.reset();
    
    super.onOkClick();
  }

  onFilterChanged($event): void {
    if ($event.query !== "")
      this.bomStore.dispatchSearchBom(this.storeSession.activeConfigurationId, this.storeSession.confSessionId, this.bomDecorationId, $event.query);
  }

  onSelectionChanged($event): void {
    let bom: BomAutoCompleteRow = $event.item.value;

    this.itemNoView.value = bom.itemNo;

    this.setupRevisions(bom.revisions);

    let quantityView = this.quantityUnitItemViews.find((x) => parseInt(x.id) == bom.quantityUnitId);
    this.quantityUnitView.formattedValue = quantityView ? quantityView.name : "";
    this.quantityUnitView.value = quantityView ? quantityView.id : null;
  }
}