import * as Immutable from "immutable"

import { BaseEntity } from "../../baseEntity"

export class DataSelectColumn extends BaseEntity {

  protected readonly TITLE = "title";
  protected readonly WIDTH = "width";
  protected readonly ORDER = "order";
  protected readonly HYPERLINK = "hyperLink";
  protected readonly IMAGE = "image";
  protected readonly TYPE = "type";
  protected readonly IS_FILTER_SUPPORTED = "isFilterSupported";
  protected readonly ALIGNMENT = "alignment";

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get width(): number { return this.getInternalValue<number>(this.WIDTH); }
  setWidth(width: number): this { return this.setInternalValue(this.WIDTH, width); }

  get order(): number { return this.getInternalValue<number>(this.ORDER); }
  setOrder(order: number): this { return this.setInternalValue(this.ORDER, order); }

  get hyperLink(): boolean { return this.getInternalValue<boolean>(this.HYPERLINK); }
  setHyperLink(hyperLink: boolean): this { return this.setInternalValue(this.HYPERLINK, hyperLink); }

  get image(): boolean { return this.getInternalValue<boolean>(this.IMAGE); }
  setImage(image: boolean): this { return this.setInternalValue(this.IMAGE, image); }

  get type(): string { return this.getInternalValue<string>(this.TYPE); }
  setType(type: string): this { return this.setInternalValue(this.TYPE, type); }  

  get isFilterSupported(): boolean { return this.getInternalValue<boolean>(this.IS_FILTER_SUPPORTED); }
  setIsFilterSupported(isFilterSupported: boolean): this { return this.setInternalValue(this.IS_FILTER_SUPPORTED, isFilterSupported); }

  get alignment(): string { return this.getInternalValue<string>(this.ALIGNMENT); }
  setAlignment(alignment: string): this { return this.setInternalValue(this.ALIGNMENT, alignment); }
}