<form name="form" #captchaProtectedForm="ngForm" (ngSubmit)="onSubmit()">
  <div class="password-recover">
    <h3 class="alignment pb-3">
      {{title}}
    </h3>
    <p class="description">
      {{description}}
    </p>

    <text-box [view]="userNameView"                                          
              autofocus="true">
    </text-box>

    <div *ngIf="siteKey" class="pb-3 d-flex justify-content-center">
      <re-captcha [(ngModel)]="formModel.captcha"
                  (resolved)="resolved($event)"
                  [siteKey]="siteKey"
                  #captchaControl="ngModel"
                  name="captcha"></re-captcha>
    </div>

    <button class="btn btn-primary w-100" type="submit">{{strings.SendVerificationCode}}</button>
    <div *ngIf="errorMessage" class="form-control-feedback d-block text-center pt-3">{{errorMessage}}</div>
    <!--<div [hidden]="captchaControl.valid || captchaControl.pristine" class="form-control-feedback d-block text-center">Captcha must be solved</div>-->
    <!--<div [hidden]="captchaProtectedForm.form.valid" class="form-control-feedback d-block text-center">The form must be filled out</div>-->
    <!--<button (click)="formModel.captcha = ''">Reset Captcha</button>-->

  </div>
</form>

