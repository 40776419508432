import { Component, Inject } from "@angular/core";
import { Conf, DataSelector } from "../../../shared/models";
import { ProductDataStore } from "../../../shared/providers/productData";
import { ConfPageSessionService } from "../../providers";
import { BaseDetailItemComponent } from "../shared/baseDetailItemComponent";

@Component({
  selector: 'data-selector-detail',
  templateUrl: './dataSelectorDetailComponent.html'
})
export class DataSelectorDetailComponent extends BaseDetailItemComponent {

  public selectedValue: string;
  public actionBoxTitle: string;
  
  constructor(@Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,        
    @Inject(ProductDataStore) public productStore: ProductDataStore
  ) {
    super(storeSession);
  }

  onDataReady(): void {

    let decoration = this.productStore.getEntity<DataSelector>(this.confUIItem.id);

    this.actionBoxTitle = decoration.title ? decoration.title : this.strings.DataSelector;

    let conf: Conf = this.storeSession.activeConfiguration;
    
    if (!conf.storedData.dataSelectorValues) 
      return;
    
    const storedDataSelectorValue = conf.storedData.dataSelectorValues.find(x => x.longId == decoration.visualObjectId);

    if (storedDataSelectorValue)
      this.selectedValue = storedDataSelectorValue.value;

  }
}