import { Component, Output, Optional, Host, Inject, forwardRef, EventEmitter, ChangeDetectionStrategy, ViewChild, ElementRef, Input, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { BasePopupComponent } from './basePopupComponent';
import { BackdropComponent } from './backdropComponent';
import { PopupService } from './popupService';
import { BreakPointAccessor, Breakpoints, BrowserInfo } from "../../utils";
import { SizeUtility, SizeUnit } from "../shared/sizeUtility";
import { PopupInfo, PopupActions } from "./popupInfo";
import { SideBarExtensionComponent } from "./sidebarExtensionComponent";

let SideBarPositions = {
  Left: 'left',
  Right: 'right'
}

@Component({
  selector: 'side-bar-popup',
  templateUrl: './sidebarPopupComponent.html',
  providers: [SizeUtility],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarPopupComponent extends BasePopupComponent {

  @Output()
  transitionEnd = new EventEmitter();

  // Position: left, right
  @Input()
  public position: string = SideBarPositions.Left;

  @Input()
  public extensionWidth: string = "350px";

  @Input()
  public isSticky: boolean = false;

  @Input()
  public stopInteraction: boolean = false;

  // Styles for sidebar's root container
  public containerStyle: CSSStyleDeclaration;

  // Styles for sidebar's menu
  public menuStyle: CSSStyleDeclaration;

  // Styles for sidebar's sub menu
  public subMenuStyle: CSSStyleDeclaration;

  @ViewChild('sideBarElement')
  public sideBarElement: ElementRef;

  @Input()
  public extended: boolean = false;

  // At a time only one position can be set, either positionLeft or positionRight. Our system only supports two positions. If the positionLeft is defined other position would be undefined. In this case template 
  // won't handle the undefined position.
  public positionLeft: string;
  public positionRight: string;

  constructor(
    public cd: ChangeDetectorRef,
    @Optional() @Host() @Inject(forwardRef(() => BackdropComponent))
    public backdrop: BackdropComponent,
    public breakPointAccessor: BreakPointAccessor,
    public popupService: PopupService,
    public browser: BrowserInfo

  ) {
    super(cd, backdrop, breakPointAccessor, popupService);
  }

  get rootElement(): ElementRef {
    return this.sideBarElement;
  }

  ngOnInit(): void {
    // Default height for sidebars.
    if (!this.height)
      this.height = '100%';

    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes['extended']) {

      this.normalizeSize();

      // If the sidebar is sticky and extended then display the backdrop to listen click events outside the sidebar so that we could close the 
      // extended menu.
      if (this.isSticky) {
        this.backdrop.visible = this.extended;
      }
      // TODO temporary solution to show sidebar for small sizes. Asif this needs to be improved.
      else if (this.extended && this.subMenuStyle.width === "0") {
        this.backdrop.popup.normalizeSize();
      }
            
    }

  }

  public executeAction(info: PopupInfo<any>): void {
    if (info.action == PopupActions.CloseExtension) {
      this.stopInteraction = false;
      this.extended = false;
    }

    super.executeAction(info);
  }

  close(): void {
    // Don't close the sidebar if user interaction is prevent.  
    // Turn on the user interaction If <close> action is intended.
    if (this.stopInteraction)
      return;

    if (this.extended) {
      this.extended = false;
      this.stopInteraction = false;
    
    }
    else super.close();
  }

  /**
   * Sets the side bar position.
   */
  public normalizeSize(): void {

    this.calculateMaximizeState();

    // Container which encapsulates both menu and sub-menu
    this.updateContainerStyle();

    // Menu style
    this.updateMenuStyle();

    // Sub menu style
    this.updateSubMenuStyle();

    this.cd.markForCheck();
  }
  
  public updateContainerStyle(): void {
    if (this.calcMaximize && this.isOpened) {
      this.positionRight = this.positionLeft = '0px';
    }
    else if (this.position == SideBarPositions.Left) {
      // Hide the side bar If it is not opened.
      this.positionLeft = this.isOpened == true ? '0px' : `-100%`;
      this.positionRight = null;
    }
    else if (this.position == SideBarPositions.Right) {
      this.positionRight = this.isOpened == true ? '0px' : `-100%`;
      this.positionLeft = null;
    }

    const $zindexModal = 1050; // bootstrap $zindex-modal
    this.containerStyle = <CSSStyleDeclaration>{
      right: this.positionRight,
      left: this.positionLeft,
      zIndex: this.backdrop && this.backdrop.visible == true ? $zindexModal : "", // Backdrop component must have z-index less than this value.                   
      minWidth: this.calcMaximize ? '100%' : this.width
    }
  }

  public updateMenuStyle(): void {
    this.menuStyle = <CSSStyleDeclaration>{
      width: '100%',
      minWidth: this.calcMaximize ? '100%' : this.width,
      maxWidth: this.calcMaximize ? '100%' : this.width,
      display: this.calcMaximize && this.extended ? 'none' : ''
    }
  }

  updateSubMenuStyle(): void {
    let calWidth: string = this.extensionWidth;

    // If it is closed then set the width 0.
    if (!this.extended)
      calWidth = '0';

    if (this.calcMaximize && this.extended)
      calWidth = '100%';

    this.subMenuStyle = <CSSStyleDeclaration>{
      width: calWidth,
      minWidth: calWidth,
      maxWidth: '100%',
      left: this.isSticky && this.width != "100%" ? this.width : null
    }    

    if (this.browser.isIE)
      this.subMenuStyle.left = null;

  }

  enable(status: boolean): void {
    this.stopInteraction = !status;
  }
}