import { Inject, Component, ViewChild, Input, Output, EventEmitter, ElementRef, ChangeDetectorRef } from "@angular/core";
import * as Immutable from 'immutable';
import { ConfInfo, AttributeInfo, WarningAttributesMessage } from "../../../shared/models";
import { RegularPopupComponent } from "../../../../shared/components/popup";
import { PageActionCreator, PageStore } from "../../../shared/providers/page";
import { RouteRedirector, RouteNames } from "../../../shared/providers";
import { InputViewModel, ValueChangeEventArgs } from "../../../../shared/components/shared";
import { ConfPageSessionService, ConfiguratorStore, PopupIdentifiers, ConfRouteParams } from "../../providers";
import { AccordionComponent, PopupService } from "../../../../shared/components";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";

@Component({
  selector: 'warning-attributes-popup',
  templateUrl: './warningAttributesPopupComponent.html'
})
export class WarningAttributesPopupComponent extends AbstractPopupComponent {

  public values: Array<WarningAttributesModel>;
 
  @ViewChild(AccordionComponent)
  accordionComponent: AccordionComponent;

  @Output()
  onClosed = new EventEmitter();

  constructor(
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,    
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    @Inject(PopupService) public popupService: PopupService,
    @Inject(PageStore) public pageStore: PageStore,
    public cdr: ChangeDetectorRef
  ) {
    super(cdr, popupService);
  }

  ngOnInit() {
    this.id = PopupIdentifiers.WarningAttributes;
    super.ngOnInit();
  }

  public open(tag: any) {
    this.setup(tag);
    this.showInternal();    
  }

  setup(messages: Immutable.List<WarningAttributesMessage>): void {

    this.values = [];

    messages.forEach(message => {

      let warningAttributeModel = new WarningAttributesModel();
      warningAttributeModel.conf = this.confStore.getConfInfo(message.configurationId, this.storeSession.confSessionId);
      warningAttributeModel.isActiveConf = this.storeSession.activeConfigurationId === message.configurationId;
      
      message.attributeInfos.forEach((attributeInfo: AttributeInfo) => {
        let attributeViewModel = new InputViewModel(attributeInfo.title);
        attributeViewModel.icon = "attribute";
        attributeViewModel.iconHeight = attributeViewModel.iconWidth = "24px";
        attributeViewModel.value = attributeInfo.value;
        warningAttributeModel.attributeViewModels.push(attributeViewModel);
      });

      this.values.push(warningAttributeModel);

    });
  }

  public onPopupClosed() {
    this.values = null;
        
    super.onPopupClosed();
    this.onClosed.emit();
  }

  public onAccordionOpen(configurationId: number) {
    // Accordion expand will route to the clicked configuration 
    let params = <ConfRouteParams>{ id: configurationId, confSessionId: this.storeSession.confSessionId };
    if (this.pageStore.activeRouteName === RouteNames.Summary)
      this.routeRedirector.redirectToSummary(params);
    else
      this.routeRedirector.redirectToEditor(params)
  }

  public onAccordionToggle($event) {    
  }  
}

export class WarningAttributesModel {
  conf: ConfInfo;
  attributeViewModels: Array<InputViewModel> = [];
  isActiveConf = false;
}