// Actions which can be called from any where in the application
export class Actions {

  static CLEAR_CLIENT_CACHE = "@CLEAR_CLIENT_CACHE";
  static RELOAD_CACHES = "@RELOAD_CACHES";
  static RELOAD_CACHES_COMPLETED = "@RELOAD_CACHES_COMPLETED";

  static CONFIGURATION_LOADED = "@CONFIGURATION_LOADED";
  static CONFIGURATION_DELETED = "@CONFIGURATION_DELETED";

  static DELETE_CONFIGURATION = "@DELETE_CONFIGURATION";
  static SEARCH_CONFIGURATIONS = "@SEARCH_CONFIGURATIONS";
  static SEARCH_CONFIGURATIONS_RESULT = "@SEARCH_CONFIGURATIONS_RESULT";

  static REMOVE_CONFIGURATOR_SESSIONS = "@REMOVE_CONFIGURATOR_SESSIONS";

  static ACTION_STARTED = "@ACTION_STARTED";
  static UPDATE_ACTION_STATUS = "@UPDATE_ACTION_STATUS";

  static COMPARISON_CONFIGURATIONS_RESULT = "@COMPARISON_CONFIGURATIONS_RESULT";

  static PUSH_MESSAGE_SELECTION = "@PUSH_MESSAGE_SELECTION";
}