import { ImmutableObject } from "../../../../../shared/immutableObject";
import { NotificationType } from "../../../../../shared/components";

export abstract class BaseMessage extends ImmutableObject {

  protected readonly DISPLAY_STYLE = "displayStyle";
  protected readonly TITLE = "title";

  get displayStyle(): NotificationType { return this.getInternalValue<NotificationType>(this.DISPLAY_STYLE); }
  setDisplayStyle(displayStyle: NotificationType): this { return this.setInternalValue(this.DISPLAY_STYLE, displayStyle); }

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

}