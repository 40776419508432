import { Component, Input, Output, EventEmitter, OnInit, Inject, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core";
import * as Immutable from "immutable";

import { ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { Conf, PropertyDecoration, ConfPropertyValue, PropertyDecorationItem, GroupConfUIItem, ConfInfo } from "../../../shared/models";
import { ListRowModel } from "../shared/listRowModel";
import { ListModel, ListItemModel } from "../../../shared/components/list";
import { BaseDetailItemComponent } from "../shared/baseDetailItemComponent";
import { ListModelCacheService } from "../providers/listModelCacheService";
import { ProductDataStore } from "../../../shared/providers/productData";

@Component({
  selector: 'conf-properties-detail',
  templateUrl: './confPropertiesDetailComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfPropertiesDetailComponent extends BaseDetailItemComponent {

  public name: string = "";

  constructor(@Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ProductDataStore) public productDataStore: ProductDataStore,
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(ListModelCacheService) public modelsService: ListModelCacheService,
    public cdr: ChangeDetectorRef,
  ) {
    super(confPageSessionService);
  }
  
  onDataReady(): void {
    //this.rows = this.rows.clear();
    //let conf = this.confStore.getConf(this.confPageSessionService.activeConfigurationId, this.confPageSessionService.confSessionId);
    //this.setup(conf);
  }
  
  public setup(conf: Conf) {

    if (!conf || !conf.confPropertyValues)
      return;

    this.rows = this.rows.clear();
    this.name = null;

    let availableConfPropertyByNames = conf.confPropertyValues.reduce((map, value) => map.set(value.propertyName, value), Immutable.Map<string, ConfPropertyValue>());    
    let selectedConfProperties: ConfPropertyValue[] = [];
    let selectedFormattedValueByConfProperty = new Map<ConfPropertyValue, string>();
        
    // If Id is available then we are working with PropertyDecoration
    // Otherwise we are displaying properties defined by global settings
    if (this.confUIItem.id && !(this.confUIItem instanceof GroupConfUIItem)) {
      let propertyDecoration = this.productDataStore.getEntity<PropertyDecoration>(this.confUIItem.id);
      if (propertyDecoration) {

        if (propertyDecoration.title && propertyDecoration.title.trim().length > 0)
          this.name = propertyDecoration.title;

        // Get the visible
        if (this.confUIItem.items && propertyDecoration.items) {

          let visibleItemIds = this.confUIItem.items.map(x => x.id).toArray();
          let visiblePropertyItems = propertyDecoration.items.filter(x => visibleItemIds.indexOf(x.longId) > -1);
                    
          visiblePropertyItems.forEach(x => {

            if (availableConfPropertyByNames.has(x.propertyName)) {
              let confProperty = availableConfPropertyByNames.get(x.propertyName);
              selectedConfProperties.push(confProperty);

              // Read formatted value
              if (confProperty.valueByNumericFormat.has(x.numericFormat))
                selectedFormattedValueByConfProperty.set(confProperty, confProperty.valueByNumericFormat.get(x.numericFormat));
            }
          });
        }
      }      
    }
    else {
      // Properties coming from global settings
      // Display it in the same order as it came from server

      this.confUIItem.items.forEach(uiItem => {

        if (availableConfPropertyByNames.has(uiItem.itemName))
          selectedConfProperties.push(availableConfPropertyByNames.get(uiItem.itemName));            

      });

    }    

    if (!this.name) {
      let confInfo: ConfInfo = this.confStore.getConfInfo(conf.longId, this.confSessionId);
      this.name = confInfo ? confInfo.compact : conf.name && conf.name.trim().length > 0 ? conf.name : conf.identity;
    }

    // Create row and column models
    // Only one row consisting of all properties

    let row = new ListRowModel();
    selectedConfProperties.forEach((confProperty) => {

      let keyForConfProperty = `${confProperty.keyAndValue.key}_kv_${confProperty.keyAndValue.value}`;

      let list: ListModel = this.modelsService.getCachedIfValueEqual(confProperty.propertyName, keyForConfProperty);

      if (list) {
        row.cells.push(list);        
        return;
      }
      else
        list = new ListModel();

      list = list.setTitle(confProperty.title);
      list = list.setEmptyValueText(this.strings.NoValue);
      list = list.setTag(keyForConfProperty);

      let item: ListItemModel = new ListItemModel();

      // See if there's a formatted value
      if (selectedFormattedValueByConfProperty.has(confProperty))
        item = item.setTitle(selectedFormattedValueByConfProperty.get(confProperty));      
      else
        item = item.setTitle(confProperty.keyAndValue.value);      

      let items: Immutable.List<ListItemModel> = list.items;

      if (!items)
        items = Immutable.List<ListItemModel>();

      items = items.push(item);
      list = list.setItems(items);

      row.cells.push(list);

    });

    // Add empty cell(s) to complete the border
    for (var i = 0; i < row.cells.length % 3; i++) {
      row.cells.push(new ListModel());
    }

    this.rows = this.rows.push(row);

  }

  subscribeDataChange(): void {

    this.confStore.onConfigurationChange(this.configurationId, this.confSessionId, (conf: Conf) => {

      this.setup(conf);
      this.cdr.markForCheck();

    }).unsubscribeOn(this.unsubscribeSubject);

  }

}