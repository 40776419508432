import { Inject, Component, Input, ViewContainerRef, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { BaseComponent } from "../../shared";
import { RouteRedirector, RouteNames } from "../../shared/providers";
import { ConfRouteParams, PopupIdentifiers, ConfiguratorStore, ConfPageSessionService } from "../providers";
import { ManagedSubscription } from "../../../shared/managedSubscription";
import { IEmitDataInfo } from "./emitterService";
import * as Immutable from "immutable";
import { ConfInfo, Product, ConfDataResponse, Tab, RequestViews, ConfDeleteMessage } from "../../shared/models";
import { ProductDataStore } from "../../shared/providers/productData";
import { NotificationInfo, NotificationService, NotificationType } from "../../../shared/components";

@Component({
  selector: 'tab-composite-configurations',
  templateUrl: './tabCompositeConfigurationsComponent.html'  
})
export class TabCompositeConfigurationsComponent extends BaseComponent {

  @Input()
  configurationId: number;

  @Input()
  tab: Tab;

  sessionId: number;
  isVaultIcon: boolean = false;
  iconKey: string;
  addChildLinkTitle: string;

  private messageSubscription: ManagedSubscription;

  childConfInfos: Immutable.List<ConfInfo> = Immutable.List<ConfInfo>();

  constructor(
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ProductDataStore) public productDataStore: ProductDataStore,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(NotificationService) public notificationService: NotificationService,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,    
    public cd: ChangeDetectorRef
  ) {
    super();
    this.sessionId = this.storeSession.confSessionId;
  }

  ngOnInit(): void {

    this.messageSubscription = this.emitterService.getMessage().subscribe((info: IEmitDataInfo<any>) => {

      if (info.id == PopupIdentifiers.AccordionNodesUpdate) {
        this.updateChildConfigurations();
      }

    });

    this.updateChildConfigurations();

    this.productDataStore.getProduct(this.tab.productStructureId, (product: Product) => {
      this.addChildLinkTitle = `${this.strings.Add} ${product.title}`;

      // To prevent the error message "Attempt to use a destroyed view: detectChanges message" on page reload.
      if(!this.cd['destroyed'])
      {
        this.cd.detectChanges();
      }      
    });

    super.ngOnInit();
  }

  updateChildConfigurations() {

    let parentConfInfo = this.confStore.getConfInfo(this.configurationId, this.sessionId);
    if (!parentConfInfo)
      return;    

    let confInfos = this.confStore.getChildConfInfos(parentConfInfo.longId, this.sessionId).filter(x => x.parentTabId == this.tab.longId || (x.parentTabId == null && x.productId == this.tab.productStructureId));
    this.childConfInfos = Immutable.List<ConfInfo>(confInfos);
    this.cd.detectChanges();
    this.unblockUI();
  }

  createConfiguration(event: any): void {
    
    if (!this.tab)
      return;

    this.confStore.createChildConfiguration(this.configurationId, this.tab.productStructureId, this.sessionId, this.tab.longId)
      .then((data: ConfDataResponse) => {

        // TODO: rejection here if needed.
        this.updateChildConfigurations();
      });

    event.preventDefault();
  }

  deleteConfiguration(confId: number): void {

    this.confStore.deleteConfiguration(confId, this.sessionId, RequestViews.Editor).
      then(x => {

        let displayStyle = (x.messages.get(0) as ConfDeleteMessage)?.displayStyle;
        let title = (x.messages.get(0) as ConfDeleteMessage)?.title;
        let message = (x.messages.get(0) as ConfDeleteMessage)?.message;

        this.routeRedirector.changeParamValue(ConfRouteParams.SUB_CONF_ID, null);
        // Notify delete action.
        this.notificationService.notify(<NotificationInfo>{ title: title || displayStyle ? title : this.strings.Delete, message: message ? message : this.strings.ConfigurationIsDeleted, type: displayStyle ? displayStyle : NotificationType.Warning, selfClose: true });

        // TODO: Load the root configuration in same delete request.
        this.confStore.loadConf(this.storeSession.activeConfigurationId, this.storeSession.confSessionId, RequestViews.Editor).then(x => this.updateChildConfigurations());

      });

    event.preventDefault()
  }

  showConfigurationDetail(event, confId: number): void {
    
    this.routeRedirector.changeParamValue(ConfRouteParams.SUB_CONF_ID, confId.toString());    
    this.emitterService.send(PopupIdentifiers.AccordionCompositeShowDetail, { confId: confId, reload: true });
    event.preventDefault();
  }

  ngOnDestroy() {

    if (this.messageSubscription)
      this.messageSubscription.unsubscribe();

    super.ngOnDestroy();
  }

}