import * as Immutable from "immutable";

import { BaseEntity } from "../../baseEntity";
import { SearchResult } from "./searchResult";
import { ConfigurationSearch } from "../entities/configurationSearch";
import { AbstractSearchDataMessage } from "./messages/abstractSearchDataMessage";

export class SearchDataResponse extends BaseEntity {

  protected readonly SEARCH_RESULT = "searchResult";
  protected readonly SAVED_SEARCHES = "savedSearches";
  protected readonly MESSAGES = "messages";

  get searchResult(): SearchResult { return this.getInternalValue<SearchResult>(this.SEARCH_RESULT); }
  setSearchResult(searchResult: SearchResult): this { return this.setInternalValue(this.SEARCH_RESULT, searchResult); }

  get savedSearches(): Immutable.List<ConfigurationSearch> { return this.getInternalValue<Immutable.List<ConfigurationSearch>>(this.SAVED_SEARCHES); }
  setSavedSearches(savedSearches: Immutable.List<ConfigurationSearch>): this { return this.setInternalValue(this.SAVED_SEARCHES, savedSearches); }

  get messages(): Immutable.List<AbstractSearchDataMessage> { return this.getInternalValue<Immutable.List<AbstractSearchDataMessage>>(this.MESSAGES); }
  setMessages(messages: Immutable.List<AbstractSearchDataMessage>): this { return this.setInternalValue(this.MESSAGES, messages); }
}