import * as Immutable from "immutable";

import { AbstractConfigurationMessage } from "./abstractConfigurationMessage"
import { Grid } from "..";

export class BomDetailMessage extends AbstractConfigurationMessage {

  protected readonly GRID_DATA = "gridData";
  protected readonly CHILDREN_GRID_DATA = "childrenGridData";
  protected readonly GRAND_TOTAL = "grandTotal";
  
  get gridData(): Grid { return this.getInternalValue<Grid>(this.GRID_DATA); }
  setGridData(gridData: Grid): this { return this.setInternalValue(this.GRID_DATA, gridData); }

  get childrenGridData(): Grid { return this.getInternalValue<Grid>(this.CHILDREN_GRID_DATA); }
  setChildrenGridData(childrenGridData: Grid): this { return this.setInternalValue(this.CHILDREN_GRID_DATA, childrenGridData); }

  get grandTotal(): Grid { return this.getInternalValue<Grid>(this.GRAND_TOTAL); }
  setGrandTotal(grandTotal: Grid): this { return this.setInternalValue(this.GRAND_TOTAL, grandTotal); }
}