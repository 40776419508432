import { ChangeDetectorRef, Component, Inject } from "@angular/core";
import { InputViewModel, PopupService } from "../../../shared/components";
import { AbstractPopupComponent } from "../../../shared/components/popup/abstractPopupComponent";
import { ImageSets } from "../../../shared/utils";
import { Language } from "../../shared/models";
import { AccountDataStore } from "../../shared/providers/accountData";
import { GlobalDataStore } from "../../shared/providers/globalData";
import { SearchDataStore } from "../../shared/providers/searchData";

@Component({
  selector: 'anonymous-access-entry',
  templateUrl: './anonymousAccessEntryComponent.html'
})
export class AnonymousAccessEntryComponent extends AbstractPopupComponent {

  public id = "anonymous-access-entry-popup";

  public languageView: InputViewModel;
  public languagesView: Array<InputViewModel> = [];

  public languagesLoaded = false;

  protected languagesById: Map<string, Language>;

  constructor(
    @Inject(PopupService) public popupService: PopupService,
    @Inject(AccountDataStore) public accountStore: AccountDataStore,
    @Inject(SearchDataStore) public searchDataStore: SearchDataStore,
    public globalDataStore: GlobalDataStore,
    public cdr: ChangeDetectorRef
  ) {
    super(cdr, popupService);
  }

  public open(tag: any) {
    this.show();
  }

  show(): void {
    this.blockUI();
    this.languagesLoaded = false;
    this.languageView = new InputViewModel();
    this.languagesView = [];
    this.languagesById = new Map<string, Language>();

    this.languageView.icon = "worldmap";
    this.languageView.title = this.strings.Language;
    this.languageView.iconImageSet = this.getImageSet(ImageSets.Control);

    this.globalDataStore.getEntities<Language>(Language.name).subscribe((storeResponse) => {
      storeResponse.data.forEach(x => {

        this.languagesById.set(x.longId.toString(), x);

        let viewModel = new InputViewModel(x.title);
        viewModel.id = x.longId.toString();
        this.languagesView.push(viewModel);
      });

      // Don't show the popup if there are no languages to choose from
      this.unblockUI();
      if (this.languagesView.length > 1) {
        this.languagesLoaded = true;
      }
      else
        this.okClick();

    }).unsubscribeOn(this.unsubscribeSubject);

    this.showInternal();
  }

  okClick(): void {
    this.blockUI();
    
    let languageTitle = null;
    let languageCode = null;

    let selectedLanguage = this.languagesById.get(this.languageView.value);
    if (selectedLanguage) {

      languageTitle = selectedLanguage.title;

      if(selectedLanguage.codes && selectedLanguage.codes.size > 0) {
        languageCode = selectedLanguage.codes.first();
      }
    }

    this.accountStore.logInAnonymous(languageTitle, languageCode);
  }
}