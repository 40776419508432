import { Inject, Component, Input, ChangeDetectorRef, ViewChild } from "@angular/core";

import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import {  NotificationService, NotificationInfo, NotificationType, BackdropComponent } from "../../../../shared/components";
import { ConfPageSessionService } from "../../../configurator/providers";
import { AttachmentsStore } from "./attachmentsStore";
import { UploadComponent } from "./uploadComponent";

@Component({
  selector: 'attachments-upload-popup',
  templateUrl: './attachmentsUploadPopupComponent.html'
})
export class AttachmentsUploadPopupComponent extends AbstractPopupComponent {

  public id: string = "attachments-upload-popup";
  public configurationId: number;
  public confSessionId: number;

  @ViewChild(UploadComponent)
  protected uploader: UploadComponent;

  @ViewChild(BackdropComponent)
  protected backdropComponent: BackdropComponent;

  public uploadButtonDisabled = true;

  constructor(
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    public notificationService: NotificationService,
    public cdr: ChangeDetectorRef
  ) {
    super(cdr);
  }

  show() {
    this.uploadButtonDisabled = true;
    this.configurationId = this.storeSession.activeConfigurationId;
    this.confSessionId = this.storeSession.confSessionId;
    this.showInternal();
  }

  public uploadDone() {
    this.backdropComponent.close();
    this.onPopupClosed();

    this.notificationService.notify(<NotificationInfo>{
      title: this.strings.Success,
      message: this.strings.UploadCompleted,
      type: NotificationType.Success,
      selfClose: true
    });
  }

  public okClick() {
    this.uploadButtonDisabled = true;

    this.uploader.uploadFiles();
  }

}