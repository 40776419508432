<backdrop-container>
  <regular-popup *ngIf="visible"
                 [id]="id"
                 [title]="strings.ChangePassword"
                 [closeOnOk]="false"
                 [showHeader]="false"
                 [showFooter]="true"
                 [height]="'500px'"
                 [width]="'450px'"
                 [okButtonText]="strings.Ok"
                 [cancelButtonText]="strings.Cancel"
                 (okClick)="changePassword()"
                 (onClosed)="onPopupClosed()">
    <div card-type="body" class="p-4">
      <div>
        {{message}}
      </div>
      <div class="pt-4">
        <password [view]="currentPasswordView"></password>
        <password [view]="newPasswordView" isNewPassword="true"></password>
        <password [view]="confirmNewPasswordView" isNewPassword="true"></password>
      </div>
      <div *ngFor="let error of serverErrors" class="form-control-feedback d-block">{{error}}</div>
    </div>
  </regular-popup>
</backdrop-container>