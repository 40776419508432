import * as Immutable from "immutable";
import { BaseEntity } from "../../baseEntity";

export let SeparatedTabPosition = {
  left: 'left',
  right: 'right',
  top: 'top',
  bottom: 'bottom'
}

export class Tab extends BaseEntity {
  protected readonly TITLE = "title";
  protected readonly HANDLE_TITLE = "handleTitle";
  protected readonly ORDER = "order";
  protected readonly DISPLAY_STYLE = "displayStyle";
  protected readonly ICON_RELATIVE_URL = "iconRelativeUrl";
  protected readonly VISUAL_OBJECTS = "visualObjects";
  protected readonly FLOATING_POSITION = "floatingPosition";
  protected readonly SEPARATED_TAB_POSITION = "separatedTabPosition";
  protected readonly SEPARATED_WIDTH = "separatedWidth";
  protected readonly SEPARATED_HEIGHT = "separatedHeight";
  protected readonly PRODUCT_STRUCTURE_ID = "productStructureId";

  position: string;

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get handleTitle(): string { return this.getInternalValue<string>(this.HANDLE_TITLE); }
  setHandleTitle(handleTitle: string): this { return this.setInternalValue(this.HANDLE_TITLE, handleTitle); }

  get order(): number { return this.getInternalValue<number>(this.ORDER); }
  setOrder(order: number): this { return this.setInternalValue(this.ORDER, order); }

  get displayStyle(): string { return this.getInternalValue<string>(this.DISPLAY_STYLE); }
  setDisplayStyle(displayStyle: string): this { return this.setInternalValue(this.DISPLAY_STYLE, displayStyle); }

  get iconRelativeUrl(): string { return this.getInternalValue<string>(this.ICON_RELATIVE_URL); }
  setIconRelativeUrl(iconRelativeUrl: string): this { return this.setInternalValue(this.ICON_RELATIVE_URL, iconRelativeUrl); }

  get floatingPosition(): boolean { return this.getInternalValue<boolean>(this.FLOATING_POSITION); }
  setFloatingPosition(floatingPosition: boolean): this { return this.setInternalValue(this.FLOATING_POSITION, floatingPosition); }

  get separatedTabPosition(): string { return this.getInternalValue<string>(this.SEPARATED_TAB_POSITION); }
  setSeparatedTabPosition(separatedTabPosition: string): this { return this.setInternalValue(this.SEPARATED_TAB_POSITION, separatedTabPosition); }

  get separatedWidth(): number { return this.getInternalValue<number>(this.SEPARATED_WIDTH); }
  setSeparatedWidth(separatedWidth: number): this { return this.setInternalValue(this.SEPARATED_WIDTH, separatedWidth); }

  get separatedHeight(): number { return this.getInternalValue<number>(this.SEPARATED_HEIGHT); }
  setSeparatedHeight(separatedHeight: number): this { return this.setInternalValue(this.SEPARATED_HEIGHT, separatedHeight); }
    
  get visualObjects(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.VISUAL_OBJECTS); }
  setVisualObjects(visualObjects: Immutable.List<number>): this { return this.setInternalValue(this.VISUAL_OBJECTS, visualObjects); }

  get productStructureId(): number { return this.getInternalValue<number>(this.PRODUCT_STRUCTURE_ID); }
  setProductStructureId(productStructureId: number): this { return this.setInternalValue(this.PRODUCT_STRUCTURE_ID, productStructureId); }

}