<div class="menu-item justify-content-between" title="{{item.title}}">

  <div class="row w-100 m-0 menu-item" [class.disabled]="item.disable"  (click)="!item.disable && itemClick.emit()" role="button">
    <div class="col-auto p-3" >
      <co-image width="{{item.width}}" height="{{item.height}}" type="svg" key="{{item.icon}}" imageSet="{{imageSet}}"></co-image>      
    </div>
    <div class="col  py-3">
      <span class="text-uppercase">{{item.title}}</span>
      <span *ngIf="item.subTitle" class="sub-text">{{item.subTitle}}</span>
    </div>
  </div>  
</div>