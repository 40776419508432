import { AbstractAccountMessage } from "./abstractAccountMessage";

export enum AccountCommandTypes {
  InitEmailVerification = 0,
  VerifyCode = 1,
  SessionValidation = 2,
  Password = 3,
  AccountInactive = 4,
  None = -1
}

export class AccountDataMessage extends AbstractAccountMessage {

  protected readonly USER_NAME = "username";
  protected readonly SUCCESS = "success";  
  protected readonly MESSAGE = "message";
  protected readonly COMMAND_TYPE = "commandType";

  get username(): string { return this.getInternalValue<string>(this.USER_NAME); }
  setUsername(username: string): this { return this.setInternalValue(this.USER_NAME, username); }

  get success(): boolean { return this.getInternalValue<boolean>(this.SUCCESS); }
  setSuccess(success: boolean): this { return this.setInternalValue(this.SUCCESS, success); }

  get message(): string { return this.getInternalValue<string>(this.MESSAGE); }
  setMessage(message: string): this { return this.setInternalValue(this.MESSAGE, message); }
  
  get commandType(): AccountCommandTypes { return this.getInternalValue<AccountCommandTypes>(this.COMMAND_TYPE); }
  setCommandType(commandType: AccountCommandTypes): this { return this.setInternalValue(this.COMMAND_TYPE, commandType); }

}