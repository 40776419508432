<div id="configurator">
  <div class="row no-gutters {{activeRouteStyle}} {{isAnonymousMode ? 'anonymous' : ''}}" id="configuratorContainer">
    <!-- Composite manager -->
    <div *ngIf="showComposite" class="{{compositeStyles}}">
      <!-- TODO: Do we need 'padding-right' ? -->
      <composite-menu [isAnonymous]="isAnonymousMode" [activeRoute]="activeRouteStyle" [showStickyOption]="isEditor"></composite-menu>
    </div>
    <div class="col scrollable-view">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="row configurator-footer mx-0" *ngIf="confPageSession.dataLoaded && (isEditor || isAnonymousMode)">
    <div class="col">
      <control-panel [activeRoute]="activeRouteStyle"
                     [isAnonymousMode]="isAnonymousMode"
                     (save)="onSave()"
                     (modify)="onModify()"
                     (startOver)="onStartOver()"
                     (saveExit)="onSaveAndExitEditor()"
                     (mandatory)="onMandatoryClick()"
                     (close)="onCloseConf()"></control-panel>
    </div>
    <div class="col-auto align-middle copyright">
      Powered by ®COMBINUM
    </div>
  </div>
</div>

<!-- NOTE! Draggable popups must be defined here  -->
<disallowed-popup></disallowed-popup>
<read-only-popup></read-only-popup>
<cleared-popup></cleared-popup>
<mandatory-popup (onClosed)="onMandatoryClosed()"></mandatory-popup>
<warning-attributes-popup></warning-attributes-popup>
<help-popup></help-popup>
<rule-confirmation-popup></rule-confirmation-popup>
<anonymous-dialog-popup [saving]="isEditor" (onClosed)="onAnonymousClosed($event)"></anonymous-dialog-popup>
<bom-image-popup></bom-image-popup>
<code-popup></code-popup>
<warning-price-popup></warning-price-popup>