// These imageSets values are same as sass imagesets
export enum ImageSets {
  StartConfiguration = '--start-configuration-image-set',
  MainMenu = '--main-menu-image-set',
  Control = '--control-image-set',
  ConfiguratorDecoration = "--configurator-decoration-image-set",
  ConfiguratorDecorationHeader = "--configurator-decoration-header-image-set",
  SummaryDetailHeader = "--summary-detail-header-image-set",
  SummaryDetailIcon = "--summary-detail-icon-image-set",
  SummaryActionDetail = "--summary-action-detail-image-set",
  SummaryActionHeader = "--summary-action-header-image-set",
  ConfiguratorTree = "--configurator-tree-image-set",
  SelectorTree = "--selector-tree-image-set",
  Help = "--control-help-image-set",
}