import { Decoration } from "./decoration";

export class VisualizationDecoration extends Decoration {

  protected readonly CODE_FILE_ID = "codeFileId";
  protected readonly INDEX_FILE_PATH = "indexFilePath";
  protected readonly INDEX_FILE_DIR_PATH = "indexFileDirPath";
  protected readonly RESPONSIVE_SIZE = "responsiveSize";
  protected readonly HEIGHT = "height";

  get codeFileId(): number { return this.getInternalValue<number>(this.CODE_FILE_ID); }
  setCodeFileId(codeFileId: number): this { return this.setInternalValue(this.CODE_FILE_ID, codeFileId); }

  get indexFilePath(): string { return this.getInternalValue<string>(this.INDEX_FILE_PATH); }
  setIndexFilePath(indexFilePath: string): this { return this.setInternalValue(this.INDEX_FILE_PATH, indexFilePath); }

  get indexFileDirPath(): string { return this.getInternalValue<string>(this.INDEX_FILE_DIR_PATH); }
  setIndexFileDirPath(indexFileDirPath: string): this { return this.setInternalValue(this.INDEX_FILE_DIR_PATH, indexFileDirPath); }

  get responsiveSize(): boolean { return this.getInternalValue<boolean>(this.RESPONSIVE_SIZE); }
  setResponsiveSize(responsiveSize: boolean): this { return this.setInternalValue(this.RESPONSIVE_SIZE, responsiveSize); }

  get height(): number { return this.getInternalValue<number>(this.HEIGHT); }
  setheight(height: number): this { return this.setInternalValue(this.HEIGHT, height); }
}