import { Component, Inject, Input, Output, EventEmitter, SimpleChanges, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { BaseComponent } from "../../../../shared";
import { InputViewModel, ValueChangeEventArgs } from "../../../../../shared/components";
import { SearchCriteriaModel } from "../models/searchCriteriaModel";
import { BaseSearchInputComponent } from "./baseSearchInputComponent";
import { SearchCriteriaModelsProvider, SearchCriteriaHelper } from "../providers";
import { SearchValueControlType } from "../models";
import { ConfSearchCriteria } from "../../../../shared/models";
import { EmitterService, IEmitDataInfo } from "../../../shared";
import { PopupIdentifiers } from "../../../providers";

@Component({
  selector: 'search-criteria',
  templateUrl: './searchCriteriaComponent.html',  
})
export class SearchCriteriaComponent extends BaseComponent {

  @Input()
  public criteria: SearchCriteriaModel;

  @Input()
  public width: string;

  @Input()
  public showAdd: boolean;

  @Input()
  public showRemove: boolean;

  @Output()
  selectionChanged = new EventEmitter();

  @Output()
  onAdd = new EventEmitter();

  @Output()
  onRemove = new EventEmitter();

  @Output()
  onValueChange = new EventEmitter();

  controlType: SearchValueControlType;

  public selectorGroupView: InputViewModel;
  public selectorAttributeViews: Array<InputViewModel> = [];

  @ViewChild("input")
  public inputComponent: BaseSearchInputComponent;

  constructor(
    @Inject(SearchCriteriaHelper) public searchCriteriaHelper: SearchCriteriaHelper,
  ) {
    super();
  }

  ngOnInit() {

    this.createView();

    // Listen the Criteria changes.
    //this.emitterService.getMessage().subscribe((info: IEmitDataInfo<SearchCriteriaModel>) => {

    //  if (PopupIdentifiers.SearchPageCriteria == info.id && info.tag.identifier == this.criteria.identifier) {

    //    this.createView();
    //    this.cd.detectChanges();

    //  }

    //}).unsubscribeOn(this.unsubscribeSubject);
    
  }


  ngOnChanges(changes: SimpleChanges): void {

    if (changes['criteria']) {
      this.setSelectorAttributes();      
    }
  }

  public createView(): void {

    this.setSelectorAttributes();
    this.setValueControlType();

    this.selectorGroupView = new InputViewModel();
    this.selectorGroupView.width = this.width;
    this.selectorGroupView.formattedValue = this.criteria.selectedAttribute.title;
    this.selectorGroupView.value = this.criteria.selectedAttribute.title;

  }

  public setSelectorAttributes() {

    this.selectorAttributeViews = [];

    this.criteria.selectorAttributes.forEach(attributeField => {

      let attributeView = this.searchCriteriaHelper.createInputView(attributeField.id, attributeField.id, attributeField.title, null, null, this.width);

      attributeView.disabled = !attributeField.enabled;
      this.selectorAttributeViews.push(attributeView);

    });

    // Translate the title
    //this.criteriaView.value = this.strings.getString(this.model.dataProperty.title);
  }
  
  public setValueControlType(): void {
    if (this.criteria.selectedAttribute.valueType == "LookupParam") 
      this.criteria.selectedAttribute.valueControlType = SearchValueControlType.MultiSelectDropdown;

    this.controlType = this.criteria.selectedAttribute.valueControlType;
  }

  /** Returns true if the this criteria has a value, otherwise false. */
  public get hasValue(): boolean {
    return this.inputComponent.hasValue;
  }

  public get value(): Array<ConfSearchCriteria> {
    return this.inputComponent.valueAsSearchCriteria;    
  }

  public onCriteriaChanged($event: ValueChangeEventArgs) {
    this.selectionChanged.emit({ criteria: this.criteria, attributeName: $event.actionView.name });
  }
}