import { BaseEntity } from "../../baseEntity";

export class SystemInformation extends BaseEntity {
  protected readonly TITLE = "title";
  protected readonly MESSAGE = "message";
  protected readonly PUBLISHED_DATE = "publishedDate";
  protected readonly IS_WELCOMEMESSAGE = "isWelcomeMessage";

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get message(): string { return this.getInternalValue<string>(this.MESSAGE); }
  setMessage(message: string): this { return this.setInternalValue(this.MESSAGE, message); }

  get publishedDate(): string { return this.getInternalValue<string>(this.PUBLISHED_DATE); }
  setPublishedDate(publishedDate: string): this { return this.setInternalValue(this.PUBLISHED_DATE, publishedDate); }

  get isWelcomeMessage(): boolean { return this.getInternalValue<boolean>(this.IS_WELCOMEMESSAGE); }
  setIsWelcomeMessage(isWelcomeMessage: boolean): this { return this.setInternalValue(this.IS_WELCOMEMESSAGE, isWelcomeMessage); }

}