import * as Immutable from "immutable"

import { AbstractConfigurationMessage } from "./abstractConfigurationMessage"
import { RuleInfo } from ".."

export class RuleConfirmationMessage extends AbstractConfigurationMessage {

  protected readonly RULE_INFO = "ruleInfo";

  get ruleInfo(): RuleInfo { return this.getInternalValue<RuleInfo>(this.RULE_INFO); }
  setRuleInfo(ruleInfo: RuleInfo): this { return this.setInternalValue(this.RULE_INFO, ruleInfo); }
}