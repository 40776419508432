import { Component, Inject, Input, ViewContainerRef, ComponentFactoryResolver, ViewChild, OnDestroy } from "@angular/core";
import { IntParam, ConfIntValue, ConfValue, ConfValueErrorMessage } from "../../../shared/models";
import { BaseEntity } from "../../../shared/baseEntity";
import { Unsubscribe } from 'redux';
import { InputViewModel, ValueChangeEventArgs, RestrictValueChangeEventArgs, PopupService } from "../../../../shared/components";
import { VisualObjectViewModelFactory } from "../shared/visualObjectViewModelFactory";
import { ParamComponent } from "../../shared/paramComponent";
import { ConfigurationSessionManager } from "../../providers/configurationSessionManager";
import { ConfiguratorStore } from "../../providers/configuratorStore";
import { ParameterMandatoryService } from "../shared/parameterMandatoryService";
import { ConfPageSessionService, ConfMessageProvider } from "../../providers";
import { ChangeDetectorRef } from "@angular/core";
import { VisualObjectHelper } from "../shared";

@Component({
  selector: 'int-param',
  templateUrl: './intParamComponent.html'
})
export class IntParamComponent extends ParamComponent<IntParam> {

  confIntValue: ConfIntValue;
  unsubscribeListener: Unsubscribe;
  errors: Array<string>;

  constructor(
    @Inject(ComponentFactoryResolver) public resolver: ComponentFactoryResolver,
    @Inject(VisualObjectViewModelFactory) public viewFactory: VisualObjectViewModelFactory,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(PopupService) public popupService: PopupService,
    @Inject(ParameterMandatoryService) public parameterMandatoryService: ParameterMandatoryService,
    @Inject(ConfMessageProvider) public messagesProvider: ConfMessageProvider,
    @Inject(VisualObjectHelper) public visualObjectHelper: VisualObjectHelper,
    public cd: ChangeDetectorRef
  ) {
    super(confStore, confPageSessionService, popupService, visualObjectHelper, parameterMandatoryService, messagesProvider, cd);
  }

  ngOnInit() {

    this.viewModel = this.viewFactory.create(this.parameter);

    if (this.parameter.placeholder)
      this.viewModel.placeholder = this.parameter.placeholder;

    // Set value on load.
    this.setValue(this.confStore.getConfValue(this.configuration.longId, this.confSessionId, this.parameter.visualObjectId));

    super.ngOnInit();
  }

  public setValue(confValue: BaseEntity) {
    this.confIntValue = confValue as ConfIntValue;

    if (!this.confIntValue)
      return;

    // Update the value when coming from server.
    this.viewModel.value = this.confIntValue.value;

    // Set readonly status to view.
    this.viewModel.readOnly = this.confIntValue.isReadOnly;    
  }

  onValueChange($event: ValueChangeEventArgs): void {
    super.onValueChange($event);

    // Update value
    this.confStore.setConfValue(this.confIntValue, this.confSessionId, $event.value, this.confPageSessionService.includeMandatoryInfo, this.confPageSessionService.isNextStateIncluded);
  }

  onReadOnly($event: RestrictValueChangeEventArgs): void {
    this.confStore.getReadOnlyInfo(this.configurationId, this.confSessionId, this.confIntValue, +$event.inValidValue);
  }

  onDisallowed($event: RestrictValueChangeEventArgs): void {
  }

  public getCurrentValue(): any {    

    // If value is different then set it to ConfValue and return the change ConfValue to cause a value change trigger
    if (this.confIntValue && this.confIntValue.value != this.viewModel.value)
      return this.confIntValue.setValue(this.viewModel.value);

    return this.confIntValue;
  }
  
  public setError(errors: Array<string>) {

    // Note! If we pass errors as an argument to the component then system updates them. View.errors is not working in this case. We might need immutable InputViewModels to prevent this issue.
    this.errors = errors;    
  }
}