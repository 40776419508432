import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { ConfPageSessionService, ConfRouteParams } from "./";
import { RouteRedirector } from "../../shared/providers";
import { PageStore } from "../../shared/providers/page";

@Injectable()
export class ConfDataLoadedGuard implements CanActivate {

  constructor(public router: Router, public activeRoute: ActivatedRoute,  public confPageSession: ConfPageSessionService, public pageStore: PageStore, public redirector: RouteRedirector) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    //let paramMap: any = route.firstChild.paramMap;
    let params: ConfRouteParams = { ...route.params };

    // Set the current view
    params.view = <any>route.url[0].path;

    let loaded: boolean = this.confPageSession.dataLoaded;

    if (loaded && this.pageStore.activeRouteName == params.view)
      return true;
    
    this.redirector.redirectToDataLoaderComponent(params);

    /*else */

    return false;
  }
}