import { Component, Input, Output, EventEmitter, ViewContainerRef, ComponentFactoryResolver, ViewChild, AfterContentInit } from "@angular/core";

@Component({
  selector: 'panel',
  templateUrl: './panelComponent.html'
})
// Note! this class is not being used right now. Later we might use this structure to make complete container clickable.
export class PanelComponent {

  // Makes the complete panel clickable
  @Input()
  button: boolean = true;

  @Output()
  onPanelClick = new EventEmitter();

}