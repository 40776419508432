import { Component, Inject } from "@angular/core";
import { FormDecoration, ConfFormValue, ConfFormControlValue } from "../../../shared/models";
import { ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { DecorationComponent } from "../../shared/decorationComponent";
import { VisualObjectHelper } from "../../parameters/shared";

@Component({
  selector: 'form-decoration',
  templateUrl: './formDecorationComponent.html'
})
export class FormDecorationComponent extends DecorationComponent<FormDecoration> {

  public confFormValue: ConfFormValue;
  
  constructor(
    public confStore: ConfiguratorStore,
    public confPageSessionService: ConfPageSessionService,
    @Inject(VisualObjectHelper) public visualObjectHelper: VisualObjectHelper
  ) {
    super(confStore, confPageSessionService, visualObjectHelper);
  }

  ngOnInit(): void {

    this.confFormValue = this.confStore.getConfDataEntity<ConfFormValue>(this.configuration.longId, this.confSessionId, this.decoration.longId);

    this.update();
    super.ngOnInit();
  }

  public listenChanges(): void {
    this.confStore.onConfigurationValueChange(this.configurationId, this.confSessionId, this.decoration.longId, (value: ConfFormValue): void => {

      this.confFormValue = value;
      this.update();

    }).unsubscribeOn(this.unsubscribeSubject);
  }

  public update() {
        

  }

  controlClicked(event, formControlValue: ConfFormControlValue): void {

    this.confStore.formControlSubmit(this.configurationId, this.confSessionId, this.decoration.visualObjectId, formControlValue.longId);
  }
}