import { Component, HostListener, Inject, Host, forwardRef } from "@angular/core";
import { AbstractAccordionGroupComponent } from "./abstractAccordionGroupComponent";

@Component({
  selector: "accordion-toggle",
  template: `<ng-content></ng-content>`
})
export class AccordionToggleComponent {

  constructor( @Host() @Inject(forwardRef(() => AbstractAccordionGroupComponent)) private accordionGroup: AbstractAccordionGroupComponent) {
  }

  @HostListener("click")
  onClick() {
    this.accordionGroup.toggle();
  }
}