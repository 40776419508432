<div id="separatorFirst"
     class="row draggable-row-separator-first"
     [class.highlight]="activeDragOver == DRAG_OVER_OPTIONS.FirstSeparator && activeDragOverRowId == null"
     (dragover)="onDragOver($event, null, DRAG_OVER_OPTIONS.FirstSeparator)"
     (mouseleave)="onHighlightRemove($event)"
     (drop)="onDrop($event, null, -1, DRAG_OVER_OPTIONS.FirstSeparator)"></div>

<ng-container *ngFor="let row of rows; let rowIndex = index">

  <div class="row" [id]="row.id">

    <comparison-draggable-node *ngFor="let node of row.nodes; let nodeIndex = index"
                          id="{{node.id}}"
                          [index]="nodeIndex"
                          class="col node-box ellipsis-wrap"
                          [class.highlight]="activeDragOver == DRAG_OVER_OPTIONS.Node && activeDragOverRowId == row.id && sourceNodeId != node.id && dragOverNodeId == node.id "
                          (dragstart)="onDragStart($event, node, nodeIndex, row)"
                          (dragover)="onDragOver($event, row, DRAG_OVER_OPTIONS.Node, node)"
                          (drop)="onDrop($event, row, rowIndex, DRAG_OVER_OPTIONS.Node)"
                          (select)="select.emit(row)"                          
                          (mouseleave)="onHighlightRemove($event)"
                          draggable="true"
                          [node]="node"
                          [loading]="isUpdating"  
                          [class.wait]="isUpdating"                          
                          [compactMode]="compactMode">
    </comparison-draggable-node>

  </div>

  <div [id]="row.id"
       class="row draggable-row-separator"
       [class.highlight]="activeDragOver == DRAG_OVER_OPTIONS.NodeSeparator && activeDragOverRowId == row.id"
       (dragover)="onDragOver($event, row, DRAG_OVER_OPTIONS.NodeSeparator)"
       (mouseleave)="onHighlightRemove($event)"
       (drop)="onDrop($event, row, rowIndex, DRAG_OVER_OPTIONS.NodeSeparator)">
    <div *ngFor="let node of row.nodes" class="col separator-node-style"></div>
  </div>

</ng-container>