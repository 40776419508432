
// TODO: Rename this enum because Calculated is used in both editor and summary. we can use some abstract name
export enum SummaryDisplayStyles {

  Stored = "Stored",

  Calculated = "Calculated",

  Both = "Both"

}