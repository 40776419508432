<action-box *ngIf="visible" textLeft="{{strings.Graphics}}" [showHeader]="showHeader">
  <ng-container *ngFor="let graphic of graphicDecorations">
    <div class="menu-item justify-content-between" title="{{graphic.title}}">
      <div class="row w-100 m-0">
        <a class="col-auto py-2 pr-0" href="#" role="button" title="{{strings.Viewer}}" (click)="openPopup(graphic)">
          <co-image width="24" height="24" type="svg" key="graphics"  [imageSet]="imageSet"></co-image>
        </a>
        <a class="col py-2" href="#" role="button" title="{{strings.Viewer}}" (click)="openPopup(graphic)">
          {{graphic.title || strings.Graphics}}
        </a>
        <div class="col-auto text-right" *ngIf="graphic.showDownloadLink">
          <graphics-download [confId]="configurationId" [sessionId]="confSessionId" [decoration]="graphic" [imageSet]="imageSet"></graphics-download>
        </div>
      </div>
    </div>
  </ng-container>
  <graphics-popup></graphics-popup>
</action-box>