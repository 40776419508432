import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StandardButtonComponent } from "./standardButtonComponent";
import { IconButtonComponent } from "./iconButtonComponent";
import { IconTextButtonComponent } from "./iconTextButtonComponent";
import { CircleButtonComponent } from "./circleButtonComponent";
import { ImageModule } from "../image";

export * from "./standardButtonComponent";
export * from "./iconButtonComponent";
export * from "./iconTextButtonComponent";
export * from "./circleButtonComponent";

@NgModule({
  imports: [
    CommonModule,
    ImageModule    
  ],
  declarations: [
    StandardButtonComponent, 
    IconButtonComponent,
    IconTextButtonComponent,
    CircleButtonComponent    
  ],
  exports: [
    StandardButtonComponent,
    IconButtonComponent,
    IconTextButtonComponent,
    CircleButtonComponent    
  ],

  providers: [ ]
})
export class ButtonModule {

}