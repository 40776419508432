import { Subject, ReplaySubject, Subscription, Subscribable, Observable } from "rxjs";

import { StoreResponse } from "../pages/shared/state/storeResponse";
import { ManagedSubscription, SubscriptionOptions, IObserverOptions } from "./managedSubscription"

export class ManagedError {
  constructor(public error: any) { }
}

export class ManagedSubject<T> implements Subscribable<T> {

  public static readonly IGNORE_VALUE: any = {};

  public static ErrorValue(error: any): any { return new ManagedError(error); }

  protected replaySubject: ReplaySubject<T>;
  protected subject: Subject<T>;

  // Used when observer needs to be stopped after triggerring only once
  protected isStopped: boolean;

  protected observable: any;
  protected observableSubscription: Subscription;

  constructor(observable: Observable<T> | (() => T), public oneTime = false, protected buffer: number = 1, public completeOnZeroObserver = true, public triggerNextOnSubscribe = true) {
    this.observable = observable;
    this.replaySubject = new ReplaySubject<T>(buffer);
    this.subject = new Subject();
    this.subject.subscribe(this.replaySubject);

    if (this.observable instanceof Observable || this.observable instanceof Subject) {
      this.observableSubscription = this.observable.subscribe((value) => this.nextValue(value));
    }
  }

  subscribe(subscriptionOptions: SubscriptionOptions<T>): ManagedSubscription {
    return new ManagedSubscription(subscriptionOptions, this.getAddSubscriptionFn(), this.getRemoveSubscriptionFn());
  }

  public getAddSubscriptionFn() {

    return (subject: Subject<T>, observerOptions: IObserverOptions<any>) => {

      let subscription: Subscription;

      if (observerOptions.listenNewEventsOnly || !this.buffer)
        subscription = this.subject.subscribe(subject);
      else
        subscription = this.replaySubject.subscribe(subject);

      if (this.triggerNextOnSubscribe)
        this.triggerNext();

      return subscription;
    }
  }

  public getRemoveSubscriptionFn() {

    return (subscription: Subscription) => {
      subscription.unsubscribe();

      // If auto close and subscriber count = 0 then complete the subject stream
      if (this.completeOnZeroObserver && this.subject.observers.length == 1 && this.replaySubject.observers.length == 0)
        this.complete();
    }
  }

  error(error: ManagedError) {
    this.subject.error(error);
  }

  nextValue(value: T) {

    if (value instanceof ManagedError)
      this.error(value);

    if (this.isComplete)
      return;

    if (this.oneTime)
      this.isStopped = true;

    this.subject.next(value);

    if (this.isStopped)
      this.complete();
  }

  triggerNext(): void {

    if (this.isComplete)
      return;

    if (!this.observable || this.observable instanceof Observable || this.observable instanceof Subject)
      return;

    if (this.subject.observers.length == 1 && this.replaySubject.observers.length == 0)
      return;

    let value = this.observable();
    if (value == ManagedSubject.IGNORE_VALUE)
      return;

    this.nextValue(value);
  }

  toPromise(): Promise<T> {
    return this.subject.toPromise();
  }

  get isComplete() {
    return this.isStopped || this.subject.isStopped;
  }

  complete(): void {
    if (this.observableSubscription)
      this.observableSubscription.unsubscribe();

    this.subject.complete();
  }
}