import { Injectable, Inject } from '@angular/core';

import { AppStore } from "../../state/appStore";
import { AppAction, AppAsyncAction, HttpAction, ActionStatus, Actions } from "../../state";
import { PushMessageController } from "./pushMessageController";
import { PushMessageActions } from "../../state/pushMessage/pushMessageActions";
import { BaseActionCreator } from "../../baseActionCreator";
import { ApiResponse } from "../../models";
import { PushMessageSelection } from './pushMessageSelection';

@Injectable()
export class PushMessageActionCreator extends BaseActionCreator {

  constructor(    
    @Inject(AppStore) public appStore: AppStore,
    @Inject(PushMessageController) public pushMessageController: PushMessageController
    ) {
    super();
  }
  
  dispatchFetchProducts = (data: any) => this.appStore.dispatch(this.fetchProducts(data)) as HttpAction<ApiResponse>;
  fetchProducts(data: any): HttpAction<ApiResponse> {

    return this.createHttpAction<ApiResponse>(

      PushMessageActions.PUSH_MESSAGE_LOADED_TEST,

      (dispatch, state, callback) => {

        return this.pushMessageController.getPushMessage(data).subscribe((pushMessageResult) => {

          //return (pushMessageResult) => {

          // Dispatch action type and results.
          dispatch({ type: PushMessageActions.PUSH_MESSAGE_LOADED, payload: pushMessageResult });

          // Update complete request status
          callback(new ActionStatus(true, pushMessageResult));
        })
      },
    );
  }

  dispatchValueChanged = (pushMessageSelection: PushMessageSelection) => this.appStore.dispatch(this.valueChanged(pushMessageSelection)) as AppAction<number[]>;
  valueChanged(pushMessageSelection): AppAction<PushMessageSelection> {
    return {
      type: Actions.PUSH_MESSAGE_SELECTION,
      payload: pushMessageSelection
    };
  }
}