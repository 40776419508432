import { PopperConfig } from './popoverInfo';
import { ManagedSubject } from "../../managedSubject";

export class PopoverService {

  // Subject to subscribe the PopoverInfo.
  private subject = new ManagedSubject<PopperConfig>(null, false, 0, false);
  
  show(info: PopperConfig): void {
    this.sendMessage(info);
  }

  close(): void {

    this.sendMessage(<PopperConfig>{ open: false });
  }

  public getMessage(): ManagedSubject<PopperConfig> {
    return this.subject;
  }

  /**
  * Broadcasts the message to all subscribers
  * @param event
  */
  public sendMessage(info: PopperConfig): void {
    this.subject.nextValue(info);
  }

}