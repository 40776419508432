import * as Immutable from "immutable";

import { SearchSessionData } from "./searchSessionData";
import { ImmutableObject } from "../../../../shared/immutableObject";
import { ConfigurationSearch, AbstractSearchDataMessage, ServerPushMessage } from "../../models";

export class PushMessageState extends ImmutableObject {

  protected readonly VALUES_BY_ID = "valuesById";
  protected readonly SERVER_PUSH_MESSAGES = "serverPushMessages";

  get valuesById(): Immutable.Map<number, Immutable.Map<string, string>> { return this.getInternalValue<Immutable.Map<number, Immutable.Map<string, string>>>(this.VALUES_BY_ID); }
  setValuesById(valuesById: Immutable.Map<number, Immutable.Map<string, string>>): this { return this.setInternalValue(this.VALUES_BY_ID, valuesById); }

  get serverPushMessages(): Immutable.Map<number, ServerPushMessage> { return this.getInternalValue<Immutable.Map<number, ServerPushMessage>>(this.SERVER_PUSH_MESSAGES); }
  setServerPushMessages(serverPushMessages: Immutable.Map<number, ServerPushMessage>): this { return this.setInternalValue(this.SERVER_PUSH_MESSAGES, serverPushMessages); }

}