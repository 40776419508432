import { Injectable } from "@angular/core";

export let SizeUnit = {
  pixel: 'px',
  percentage: '%'  
}

export class SizeUtility {

  public static sizeAsNumber(str: string): number {

    if (!str)
      return 0;

    let sizeNoUnit: string = str;

    if (str.endsWith('px'))
      sizeNoUnit = str.replace('px', '');

    if (str.endsWith('%'))
      sizeNoUnit = str.replace('%', '');

    return Number(sizeNoUnit);

  }

  public static onlyUnit(str: string): string {

    if (!str)
      return SizeUnit.pixel;
    
    if (str.endsWith('px'))
      return SizeUnit.pixel;

    if (str.endsWith('%'))
      return SizeUnit.percentage;

    return SizeUnit.pixel;

  }

  public static fullSize(str: string): boolean {
    return str == '100%';
  }
  
}