<div class="row h-100" >
  <div class="col h-100">

    <!-- Composite sub menu header -->
    <div class="header align-middle pm-color-darker">
      <div class="row w-100 h-100 mx-0">
        <div *ngIf="showDetailForm" class="col-auto px-3 justify-content-center align-middle" (click)="returnToMenu($event)" role="button">
          <co-image width="20" height="20" type="svg" key="left"></co-image>
        </div>
        <div class="col align-middle pl-2 title" [class.pl-4]="!showDetailForm">          
          <div class="title">            
            <h3 class="text-uppercase">{{ showDetailForm ? selectedMenuItem.title : this.strings.PerformActionOn}}</h3>
            <span class="font-italic pm-color-darker">{{title}}</span>
          </div>
        </div>
        <div class="col-auto px-3 align-middle" (click)="closeSidebarExtension($event)" role="button" >          
            <co-image width="20" height="20" type="svg" key="delete"></co-image>          
        </div>
      </div>
    </div>

    <!-- Compsite sub menu body -->
    <div *ngIf="!showDetailForm && menuItems" class="sub-menu-body" >

      <ng-container *ngFor="let item of menuItems">
        
        <composite-menu-item *ngIf="!item.hidden && item.name != 'add'" class="{{item.style}}"
                             [item]="item"
                             (itemClick)="onMenuItemClick(item)">

        </composite-menu-item>
      </ng-container>

    </div>

    <composite-item-detail *ngIf="showDetailForm" [item]="selectedMenuItem" class="sub-menu-body"                           
                           [configurationId]="configurationId"
                           (onClose)="returnToMenu($event)">
    </composite-item-detail>

  </div>
</div>