export enum RouteNames {
  Configurator = "configurator",
  Summary = "summary", // Child route
  Editor = "editor", // Child route
  Selector = "selector",
  Search = "search",
  Comparison = "comparison",
  Result = "result", // Child route of search
  Redirector = "redirector",
  Account = "account",
  Login = "login",
  Logout = "logout",
  Recover = "recover",
  PasswordChange = "passwordchange",
  SignUp = "signup",
  Email = "email",
  Verify = "verify",
  CreateProfile = "createprofile",
  Start = "start",
  Settings = "settings",
}