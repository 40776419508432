import * as Immutable from "immutable";
import { BaseEntity } from "../../baseEntity";
import { ConfSearchCriteria } from "./confSearchCriteria"
import { ConfSearchAuthorization } from "./confSearchAuthorization"
import { RefObject } from "./refObject";

export class ConfigurationSearch extends BaseEntity {

  protected readonly TITLE = "title";
  protected readonly LAST_UPDATED = "lastUpdated";
  protected readonly INLINE_RESULTS = "inlineResults";
  protected readonly MAX_INLINE_RESULTS = "maxInlineResults";
  protected readonly SEARCH_CHILDREN = "searchChildren";
  protected readonly INCLUDE_PREVIOUS_REVISIONS = "includePreviousRevisions";
  protected readonly SCOPE = "scope";
  protected readonly WORK_GROUP = "workGroup";
  protected readonly AUTHORIZATION_GROUP = "authorizationGroup";
  protected readonly SEARCH_CRITERIAS = "searchCriterias";
  protected readonly AUTHORIZATION = "authorization";
  
  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get lastUpdated(): string { return this.getInternalValue<string>(this.LAST_UPDATED); }
  setLastUpdated(lastUpdated: string): this { return this.setInternalValue(this.LAST_UPDATED, lastUpdated); }

  get inlineResults(): boolean { return this.getInternalValue<boolean>(this.INLINE_RESULTS); }
  setInlineResults(inlineResults: boolean): this { return this.setInternalValue(this.INLINE_RESULTS, inlineResults); }

  get maxInlineResults(): number { return this.getInternalValue<number>(this.MAX_INLINE_RESULTS); }
  setMaxInlineResults(maxInlineResults: number): this { return this.setInternalValue(this.MAX_INLINE_RESULTS, maxInlineResults); }

  get searchChildren(): boolean { return this.getInternalValue<boolean>(this.SEARCH_CHILDREN); }
  setSearchChildren(searchChildren: boolean): this { return this.setInternalValue(this.SEARCH_CHILDREN, searchChildren); }

  get includePreviousRevisions(): boolean { return this.getInternalValue<boolean>(this.INCLUDE_PREVIOUS_REVISIONS); }
  setIncludePreviousRevisions(includePreviousRevisions: boolean): this { return this.setInternalValue(this.INCLUDE_PREVIOUS_REVISIONS, includePreviousRevisions); }

  get scope(): string { return this.getInternalValue<string>(this.SCOPE); }
  setScope(scope: string): this { return this.setInternalValue(this.SCOPE, scope); }

  get workGroup(): RefObject { return this.getInternalValue<RefObject>(this.WORK_GROUP); }
  setWorkGroup(workGroup: RefObject): this { return this.setInternalValue(this.WORK_GROUP, workGroup); }

  get authorizationGroup(): RefObject { return this.getInternalValue<RefObject>(this.AUTHORIZATION_GROUP); }
  setAuthorizationGroup(authorizationGroup: RefObject): this { return this.setInternalValue(this.AUTHORIZATION_GROUP, authorizationGroup); }

  get searchCriterias(): Immutable.List<ConfSearchCriteria> { return this.getInternalValue<Immutable.List<ConfSearchCriteria>>(this.SEARCH_CRITERIAS); }
  setSearchCriterias(searchCriterias: Immutable.List<ConfSearchCriteria>): this { return this.setInternalValue(this.SEARCH_CRITERIAS, searchCriterias); }

  get authorization(): ConfSearchAuthorization { return this.getInternalValue<ConfSearchAuthorization>(this.AUTHORIZATION); }
  setAuthorization(authorization: ConfSearchAuthorization): this { return this.setInternalValue(this.AUTHORIZATION, ConfSearchAuthorization); }
}