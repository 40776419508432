<backdrop-container>
  <simple-popper *ngIf="visible"
                 [id]="id"
                 [draggable]="true"
                 referenceContainerId="configuratorContainer"
                 [referenceElementId]="referenceElementId"
                 (onCloseStart)="onPopupClosed()">

    <div card-type="body" [class.loaded]="isLoaded" [style.width.%]="100">
      <co-image #imageRef [key]="image" class="fadein" isVault="true" (imageLoaded)="onImageLoaded($event)" width="100%"> </co-image>
    </div>

  </simple-popper>
</backdrop-container>