import { Inject, Injectable } from "@angular/core";
import * as Immutable from "immutable";
import { ConfDataResponse, ConfInfo, Product } from "../../../../../core/pages/shared/models";
import { ProductDataStore } from "../../../../../core/pages/shared/providers/productData";
import { ConfiguratorStore, ConfPageSessionService } from "../../providers";

@Injectable()
export class CompositeHelper {

  constructor(@Inject(ProductDataStore) public productDataStore: ProductDataStore,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
  ) {

  }


  findChildren(productId: number): Promise<Immutable.List<number>> {

    return new Promise<Immutable.List<number>>((resolve) => {

      // Read product
      this.productDataStore.getProduct(productId, (product: Product) => {
        resolve(product.children)
      });

    });
  }

  /**
   * Adds new configuration for corresponding product. If @createChild is true and product has only one child then it creates
   * the configuration for that child product.
   * @param productId
   * @param createChild
   */
  addChildConfigurations(focusedConfId: number, productId: number): Promise<number[]> {

    let focusedConfInfo = this.confStore.getConfInfo(focusedConfId, this.storeSession.confSessionId);

    // Read product
    return new Promise<number[]>((resolve) => {

      // Read product
      this.productDataStore.getProduct(productId, (product: Product) => {

        this.confStore.createChildConfiguration(focusedConfInfo.longId, productId, this.storeSession.confSessionId)
          .then((data: ConfDataResponse) => {

            // TODO: rejection here if needed.

            // Resolve the promise.
            resolve(data.configurationIds.toArray());

          });

      });

    });
  }

  // Crm customization - begin
  addChildConfigurationCorrespondingToCrmQuoteDetail(focusedConfId: number, productId: number, quoteDetailCrmId: string): Promise<number[]> {

    let focusedConfInfo = this.confStore.getConfInfo(focusedConfId, this.storeSession.confSessionId);

    // Read product
    return new Promise<number[]>((resolve) => {

      // Read product
      this.productDataStore.getProduct(productId, (product: Product) => {

        this.confStore.createChildConfigurationCorrespondingToCrmQuoteDetail(focusedConfInfo.longId, productId, this.storeSession.confSessionId, quoteDetailCrmId)
          .then((data: ConfDataResponse) => {

            // TODO: rejection here if needed.

            // Resolve the promise.
            resolve(data.configurationIds.toArray());

          });

      });

    });
  }
  // Crm customization - end


  public prevConfigurationId(confId: number, sessionId: number): number {

    // Empty it
    let confInfos: Array<number> = [];

    let confInfo: ConfInfo = this.confStore.getConfInfo(confId, sessionId);

    if (!confInfo)
      return;

    let parent: ConfInfo = this.confStore.getConfInfo(confInfo.parentId, sessionId);

    if (!parent)
      return;

    confInfos.push(parent.longId);
    confInfos.push(...parent.children.toArray());

    let index: number = confInfos.indexOf(confId);

    return index > 0 ? confInfos[index - 1] : null;
  }

}