import { Component, EventEmitter, Inject, Input, Output, ViewEncapsulation } from '@angular/core';
import { InputViewModel, NotificationService } from "../../../../../shared/components";
import { BaseComponent } from "../../../../shared";
import { RouteRedirector } from "../../../../shared/providers";
import { ConfiguratorStore, ConfPageSessionService } from "../../../providers";
import { ConfMessageProvider } from "../../../providers/confMessageProvider";
import { CompositeActions, CompositeActionService, ICompositeDataInfo } from '../compositeActionService';
import { CompositeTreeDataProvider } from "../tree/compositeTreeDataProvider";

@Component({
  selector: 'delete-configuration',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './deleteConfigurationComponent.html'
})
export class DeleteConfigurationComponent extends BaseComponent {
  @Input() public entity: any;
  showNum = 0;
  itemsView: Array<InputViewModel> = [];
  dropdownView: InputViewModel;
  
  @Output()
  public onCancel = new EventEmitter<any>();

  constructor(    
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(ConfMessageProvider) public confMessageProvider: ConfMessageProvider,
    @Inject(NotificationService) public notificationService: NotificationService,
    @Inject(CompositeTreeDataProvider) public menuDataProvider: CompositeTreeDataProvider,
    @Inject(CompositeActionService) public actionService: CompositeActionService,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector
  ) {
    super();
  }
  
  deleteConfigurationClick(event): void {
    // move navigation to compositecomponent
    this.actionService.notify(<ICompositeDataInfo<number>>
      {
        action: CompositeActions.Delete,
        focusedConfInfoId: this.storeSession.activeConfigurationId
      }
    );
  }

}