<regular-popup *ngIf="visible"
			   [id]="id"
			   [title]="title"
			   [showFooter]="true"
			   [okButton]="false"
			   [cancelButton]="false"
			   minWidth="popupWidth"
			   minHeight="popupHeight"
			   >

	<div card-type="body" class="p-4">
		<div [innerHtml]="text | safeHtml"></div>
	</div>

	<div card-type="footer">
		<button class="btn btn-primary px-4 mr-2" (click)="onYesClick()" type="button">{{yesButtonText}}</button>
		<button class="btn btn-primary px-4 mr-2" (click)="onNoClick()" type="button">{{noButtonText}}</button>
	</div>

</regular-popup>
