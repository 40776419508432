import { EventEmitter, Inject, ChangeDetectorRef, OnInit, Output, ViewChild, ElementRef, Component, Input, SimpleChanges } from "@angular/core";
import { BaseInputComponent } from "../shared";

export enum CheckboxType {
  standard = 'standard',
  classic = 'classic'
}

// In templates we require these keywords to apply the css, that's why we have defined the states.
const STATES = {
  Checked: 'true',
  Unchecked: 'false',
  Indeterminate: 'null'
}

// Note! Multiple modules have same 'CheckboxComponent' name. Its better to define the prefix to prevent the conflicts with others.
@Component({
  selector: 'check-box',
  templateUrl: './coCheckboxComponent.html'
})
export class CoCheckboxComponent extends BaseInputComponent implements OnInit {

  @Input()
  styleNames: string;

  states: Array<any> = [];
  
  @Input()
  triState: boolean; 

  @Input()
  type = CheckboxType.classic;

  constructor( @Inject(ChangeDetectorRef) protected cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    
    // Checked state
    this.states.push(STATES.Checked);

    // Unchecked state
    this.states.push(STATES.Unchecked);

    if (this.triState)
      this.states.push(STATES.Indeterminate);

    // Add Inderminate state in setter
  }

  get activeState(): any {
    if (this.view) {
      return this.extractState(this.view.value);
    }

    return STATES.Indeterminate;
  }

  processEvent($event): void {
    // Get next bool state.
    let nextState = this.nextState(this.activeState);

    if (this.notifyIfReadOnly(this.view, nextState))
      return;

    if (this.notifyIfDisabled(this.view, nextState))
      return;

    // If user clicks on checked option then uncheck it.    
    this.notifyValueChange(this.view, nextState);
  }

  public nextState(state: any): any {
    // Current state
    let index = this.states.findIndex(x => x == state)

    if (index == this.states.length - 1) {
      // Restart from first state
      return this.states[0];
    }

    // Next state
    return this.states[index + 1];
  }

  extractState(value: any): string {
    if (value == null || value.toString() == STATES.Indeterminate)
      return STATES.Indeterminate;

    return value.toString() == STATES.Checked ? STATES.Checked : STATES.Unchecked;
  }

}