import { ContentChildren, Component, QueryList, Input, AfterContentInit, OnDestroy, forwardRef, Inject } from "@angular/core";
import { AccordionGroupComponent } from "./accordionGroupComponent";
import { AccordionService, AccordionConfig } from "./accordionService";
import { Subscription } from "rxjs";

@Component({
  selector: "accordion",
  templateUrl: './accordionComponent.html',
  providers: [
    AccordionService
  ]
})
export class AccordionComponent implements AfterContentInit {

  @Input()
  closeOthers = true;

  @Input()
  expandAll = false;

  // Opens the first tab on load.
  @Input()
  openFirst: boolean = false;

  @ContentChildren(forwardRef(() => AccordionGroupComponent))
  groups: QueryList<AccordionGroupComponent>;

  @Input()
  classes: string;

  public subscription: Subscription;

  constructor(
    @Inject(AccordionService) public accordionService: AccordionService
  ) { }

  ngAfterContentInit() {

    if (this.openFirst && this.groups.find(x => x.isOpened) == undefined)
      this.openFirstGroup();

    this.subscription = this.accordionService.getMessage().subscribe((info: AccordionConfig) => {

      this.groups.forEach(group => {

        if (group === info.tag || !group.togglable)
          return;

        group.close();
      });

    });
  }

  closeAll() {
    this.groups.forEach(group => {
      group.isOpened = false;
    });
  }

  openFirstGroup() {

    if (this.groups.length == 1)
      this.openFirst = true;

    if (this.openFirst && this.groups.length > 0) 
      this.groups.first.open();

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}