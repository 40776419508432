import { BaseRequest } from "./baseRequest";
import { RuleCommand } from "./commands/ruleCommand";
import { GetCommand } from "./commands/getCommand";
import { CreateCommand } from "./commands/createCommand";
import { ConfDeleteCommand } from "./commands/confDeleteCommand";
import { CopyCommand } from "./commands/copyCommand";
import { UpdateCommand } from "./commands/updateCommand";
import { BomCommand } from "./commands/bomCommand";
import { MandatoryParamsCommand } from "./commands/mandatoryParamsCommand";
import { ReadOnlyParamCommand } from "./commands/readOnlyParamCommand";
import { DisallowedValueCommand } from "./commands/disallowedValueCommand";
import { DataSelectorCommand } from "./commands/dataSelectorCommand";
import { PriceCommand } from "./commands/priceCommand";
import { ConfSessionCommand } from "./commands/confSessionCommand";
import { StateCommand } from "./commands/stateCommand";
import { OrderCommand } from "./commands/orderCommand";
import { ChangeOwnershipCommand } from "./commands/changeOwnershipCommand";
import { AttachmentCommand } from "./commands/attachmentCommand";
import { ImportCommand } from "./commands/importCommand";
import { CodeCommand } from "./commands/codeCommand";
import { FormCommand } from "./commands/formCommand";

export class ConfDataRequest extends BaseRequest {

  confSessionId?: number;

  activeConfigurationIds: number[] = [];

  includeNextStateMandatoryParams?: boolean;

  rule?: RuleCommand;

  get?: GetCommand;

  create?: CreateCommand;

  import?: ImportCommand;
  
  delete?: ConfDeleteCommand;

  copy?: CopyCommand;

  update?: UpdateCommand;

  form?: FormCommand;

  state?: StateCommand;

  changeOwnership?: ChangeOwnershipCommand;

  bom?: BomCommand;

  mandatoryParam?: MandatoryParamsCommand;

  readOnly?: ReadOnlyParamCommand;

  disallowed?: DisallowedValueCommand;

  dataSelector?: DataSelectorCommand;

  code?: CodeCommand;

  price?: PriceCommand;

  order?: OrderCommand;

  attachment?: AttachmentCommand;

  session?: ConfSessionCommand;

}