import { Injectable, Inject } from '@angular/core';
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';

import { HttpService } from "../../../../shared/providers/httpService";
import { ProductDataResponse } from "../../models/responses";
import { ProductDataNormalizer } from "./productDataNormalizer";
import { ProductDataRequest } from "../../models/requests/productDataRequest";
import { ApiResponseBuilder } from "../apiResponseBuilder";
import { ApiResponse } from "../../models";

@Injectable()
export class ProductDataController {

  public productDataControllerUrl = "api/productdata";

  constructor(
    @Inject(HttpService) public httpService: HttpService,
    public apiResponseBuilder: ApiResponseBuilder) { }

  public normalizeData = map((response, index) => this.apiResponseBuilder.build(response));

  public getProductData(requestModel: ProductDataRequest): Observable<ApiResponse> {
    return this.httpService.get(this.productDataControllerUrl, requestModel).pipe(this.normalizeData);
  }
}