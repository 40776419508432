import * as Immutable from "immutable";

import { Component, Inject, Input } from "@angular/core";
import { ConfSearchResultItem, ConfInfo, IntervalType } from "../../shared/models";
import { BaseComponent } from "../../shared";
import { RouteRedirector } from "../../shared/providers";

@Component({
  selector: 'recents',
  templateUrl: './recentsComponent.html'
})
export class RecentsComponent extends BaseComponent {

  @Input()
  title: string;

  @Input()
  searchSessionId: number;

  @Input()
  configurationsByInterval: Immutable.OrderedMap<IntervalType, Immutable.OrderedMap<number, ConfInfo>>;

  public translatedIntervals: Map<IntervalType, string>;

  constructor(
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.translatedIntervals = new Map();

    for (let interval in IntervalType) {
      if (!isNaN(+interval))
        this.translatedIntervals.set(+interval, this.getTranslation(IntervalType[interval].toString()));
    }
  }

  public trackInterval(index: number, kvp: {}): any {
    // kvp is an object with the interval in index 0 and its OrderedMap in index 1
    return kvp[0];
  }

  public trackConf(index: number, kvp: {}): any {
    // kvp is an object with the confInfo longId in index 0 and its ConfInfo in index 1
    return kvp[0];
  }

  public showMore() {
    this.routeRedirector.redirectToSearchResult({ searchSessionId: this.searchSessionId });
  }

  public getTranslation(key) {
    let translation = this.strings.getString(key);
    if (translation === this.strings.Unspecified)
      return this.strings.Unsaved;

    return translation;
  }
}