import { Injectable } from "@angular/core";
import { IEditor } from "./editor";

@Injectable()
export class HTMLTemplateBuilder {

    public get(editor: IEditor) {

        let properties = Object.keys(editor.entity);
        let template = "<div>";
        let editorName = editor.selector;

        properties.forEach((propertyName) => {
            template += `
          <${editorName}></${editorName}>`;
        });

        return template + "</div>";
    }
}