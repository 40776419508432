import { Injectable, Inject } from '@angular/core';
import { Subscription, ReplaySubject } from 'rxjs';

import { Conf } from "../../shared/models";

export class ConfEvent
{
  confSessionId: number;
  conf: Conf;
}

@Injectable()
export class ConfEventService {

  public confSubject: ReplaySubject<ConfEvent> = new ReplaySubject<ConfEvent>(1);

  public registerConfChangeListener(callBack: (confEvent: ConfEvent) => void): Subscription {
    return this.confSubject.subscribe(callBack);
  }

  public triggerConfigurationChange(confSessionId: number, conf: Conf)
  {
    let event = new ConfEvent();
    event.confSessionId = confSessionId;
    event.conf = conf;

    this.confSubject.next(event);
  }

  public destroy() {
    this.confSubject.complete();
  }
}