import * as Immutable from "immutable"
import { AbstractOperationMessage } from "./abstractOperationMessage"

export class CopyOperationMessage extends AbstractOperationMessage {

  protected readonly NEW_CONF_IDS = "newConfIds";

  get newConfIds(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.NEW_CONF_IDS); }
  setNewConfIds(newConfIds: Immutable.List<number>): this { return this.setInternalValue(this.NEW_CONF_IDS, newConfIds); }

}