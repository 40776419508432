
<ng-container [ngSwitch]="displayStyle">

  <code-render-style *ngSwitchCase="displayStyles.Render"
                     [codeValue]="codeValue"
                     [decorationId]="decorationId"
                     [subHeading]="subHeading"
                     [isStored]="isStored">
  </code-render-style>

  <code-popup-style *ngSwitchCase="displayStyles.Popup"
                    [codeValue]="codeValue "
                    [decorationId]="decorationId"
                    [subHeading]="subHeading"
                    [isStored]="isStored">

  </code-popup-style>

  <code-download-link-style *ngSwitchCase="displayStyles.DownloadLink"
                            [codeValue]="codeValue"
                            [decorationId]="decorationId"
                            [subHeading]="subHeading"
                            [isStored]="isStored">

  </code-download-link-style>

</ng-container>