import { Inject, Component, ChangeDetectorRef } from "@angular/core";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import { PopupService } from "../../../../shared/components";
import { AttachmentsStore } from "./attachmentsStore";
import { ConfAttachment } from "../../models";

@Component({
  selector: 'delete-attachment-popup',
  templateUrl: './deleteAttachmentPopupComponent.html',
})
export class DeleteAttachmentPopupComponent extends AbstractPopupComponent {

  public id = "delete-attachment-popup";

  public configurationId: number;
  public confSessionId: number;
  public attachment: ConfAttachment;

  constructor(
    @Inject(PopupService) public popupService: PopupService,
    @Inject(AttachmentsStore) public attachmentsStore: AttachmentsStore,
    public cdr: ChangeDetectorRef
  ) {
    super(cdr, popupService);
  }

  show(attachment: ConfAttachment, configurationId: number, confSessionId: number): void {
    this.configurationId = configurationId;
    this.confSessionId = confSessionId;
    this.attachment = attachment;

    this.showInternal();
  }

  public okClick() {
    this.attachmentsStore.deleteAttachment(this.attachment.longId);
  }
}