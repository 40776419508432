import { Component, Input } from "@angular/core";

@Component({
  selector: "gridview-footer",
  templateUrl: './gridviewFooterComponent.html'
})
export class GridviewFooterComponent {

  @Input()
  classes: string = "";
}