import { BaseCommand } from "./baseCommand";
import { RecentOptions } from "../arguments/recentOptions";
import { PaginationArgument } from "../arguments/paginationArgument";
import { SortingArgument } from "../arguments/sortingArgument";
import { ConfResponseFormat } from "../confResponseFormat";
import { ConfigurationSearch } from "../../entities/configurationSearch";
import { QuerySourceTypes } from "../../responses/searchResult";

export class SearchCommand extends BaseCommand {

  responseFormat: ConfResponseFormat;
  searchSessionId?: number;
  clearSearchSession?: boolean;
  querySourceType: QuerySourceTypes;
  query?: ConfigurationSearch;
  pagination?: PaginationArgument;
  sorting?: SortingArgument;
}