import * as Immutable from "immutable";
import { AbstractAccountMessage } from "./abstractAccountMessage";

export class LoginMessage extends AbstractAccountMessage {

  protected readonly SUCCESS = "success";
  protected readonly ERRORS = "errors";
  protected readonly IS_PASSWORD_EXPIRED = "isPasswordExpired";
  protected readonly PASSWORD_EXPIRY_WARNING = "passwordExpiryWarning";

  get success(): boolean { return this.getInternalValue<boolean>(this.SUCCESS); }
  setSuccess(success: boolean): this { return this.setInternalValue(this.SUCCESS, success); }

  get isPasswordExpired(): boolean { return this.getInternalValue<boolean>(this.IS_PASSWORD_EXPIRED); }
  setIsPasswordExpired(isPasswordExpired: boolean): this { return this.setInternalValue(this.IS_PASSWORD_EXPIRED, isPasswordExpired); }

  get passwordExpiryWarning(): string { return this.getInternalValue<string>(this.PASSWORD_EXPIRY_WARNING); }
  setPasswordExpiryWarning(passwordExpiryWarning: string): this { return this.setInternalValue(this.PASSWORD_EXPIRY_WARNING, passwordExpiryWarning); }

  get errors(): Immutable.List<string> { return this.getInternalValue<Immutable.List<string>>(this.ERRORS); }
  setErrors(errors: Immutable.List<string>): this { return this.setInternalValue(this.ERRORS, errors); }
}