import { Injectable } from "@angular/core";

import { Range } from "../../../../../shared/components/dateRangePicker";

@Injectable()
export class DateRangeCalculator {

  protected thisYearQuaterRanges: Range[] = [];
  protected lastYearQuarter4Range: Range;
  protected todayDate = new Date();

  public todayRange: Range;
  public yesterdayRange: Range;
  public last7DaysRange: Range;
  public last30DaysRange: Range;
  public thisYearRange: Range;
  public previousYearRange: Range;
  public thisQuaterRange: Range;
  public previousQuaterRange: Range;

  constructor() {
    this.initialize();
  }

  protected initialize() {

    let currentYear = new Date().getFullYear();
        
    let yesterdayDate = new Date(new Date().setDate(this.todayDate.getDate() - 1));
    let last7DaysStart = new Date(new Date().setDate(this.todayDate.getDate() - 6));
    let last30DaysStart = new Date(new Date().setDate(this.todayDate.getDate() - 29));
    let thisYearStart = new Date(currentYear, 0, 1);
    let lastYearStart = new Date(currentYear - 1, 0, 1);
    let lastYearEnd = new Date(currentYear - 1, 11, 31);

    let quarter1 = new Range(new Date(currentYear, 0, 1), new Date(currentYear, 2, 31));
    let quarter2 = new Range(new Date(currentYear, 3, 1), new Date(currentYear, 5, 30));
    let quarter3 = new Range(new Date(currentYear, 6, 1), new Date(currentYear, 8, 30));
    let quarter4 = new Range(new Date(currentYear, 9, 1), new Date(currentYear, 11, 31));
    this.thisYearQuaterRanges = [quarter1, quarter2, quarter3, quarter4];
    this.lastYearQuarter4Range = new Range(new Date(currentYear - 1, 9, 1), new Date(currentYear - 1, 11, 31));

    this.todayRange = new Range(this.todayDate, this.todayDate);
    this.yesterdayRange = new Range(yesterdayDate, yesterdayDate);
    this.last7DaysRange = new Range(last7DaysStart, this.todayDate);
    this.last30DaysRange = new Range(last30DaysStart, this.todayDate);
    this.thisYearRange = new Range(thisYearStart, this.todayDate);
    this.previousYearRange = new Range(lastYearStart, lastYearEnd);
    this.thisQuaterRange = this.getCurrentQuarterRange();
    this.previousQuaterRange = this.getLastQuarterRange();

  }

  getCurrentQuarterRange() {        
    return this.thisYearQuaterRanges[Math.floor(this.todayDate.getMonth() / 3)];
  }
  
  getLastQuarterRange() {

    let currentQuaterIndex = Math.floor(this.todayDate.getMonth() / 3);
    if (currentQuaterIndex == 0)
      return this.lastYearQuarter4Range;

    return this.thisYearQuaterRanges[currentQuaterIndex - 1];
  }

}