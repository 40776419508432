import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpService } from "../../../../shared/providers/httpService";
import { GlobalDataRequest } from "../../models/requests/globalDataRequest";
import { ApiResponse } from "../../models";
import { ApiResponseBuilder } from "../apiResponseBuilder";

@Injectable()
export class GlobalDataController {

  public url = "api/globalData";
  public webSettingsUrl = 'api/globaldata/websettings';
  public uiSettingsUrl = 'api/globaldata/uisettings';

  constructor(
    @Inject(HttpService) public httpService: HttpService,    
    public apiResponseBuilder: ApiResponseBuilder
  ) { }

  public getGlobalData(requestModel: GlobalDataRequest): Observable<ApiResponse> {
    return this.httpService.get(this.url, requestModel).pipe(this.buildResponseObject);
  }

  public getWebSettings(): Observable<ApiResponse> {
    return this.httpService.get(this.webSettingsUrl, null).pipe(this.buildResponseObject);
  }

  public getUISettings(): Observable<ApiResponse> {
    return this.httpService.get(this.uiSettingsUrl, null).pipe(this.buildResponseObject);
  }

  public buildResponseObject = map((response, index) => this.apiResponseBuilder.build(response));
}