import { Inject, Component, Optional } from "@angular/core";

import { ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { BaseDetailItemComponent } from "../shared/baseDetailItemComponent";
import { VisualizationDecoration, GroupConfUIItem } from "../../../shared/models";
import { ProductDataStore } from "../../../shared/providers/productData";
import { type } from "jquery";

@Component({
  selector: 'visualization-detail',
  templateUrl: './visualizationDetailComponent.html',
})
export class VisualizationDetailComponent extends BaseDetailItemComponent {

  public showDetailBoxHeader: boolean = true;
  public detailBoxTitle: string;
  public showDecorationTitle: boolean = false;
  public visualizationDecorations: VisualizationDecoration[] = [];

  constructor(@Optional() @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(ProductDataStore) public productStore: ProductDataStore
  ) {
    super(confPageSessionService);
  }

  onDataReady(): void {

    this.visualizationDecorations = this.getDecorations();
    if (this.visualizationDecorations && this.visualizationDecorations.length > 0) {

      this.visible = true;
      this.detailBoxTitle = this.strings.Visualizations;
      if (this.visualizationDecorations.length > 1)
        this.showDecorationTitle = true;
      else {

        this.showDecorationTitle = false;

        let firstDecoration = this.visualizationDecorations[0];
        if (firstDecoration.title && firstDecoration.title.trim().length > 0)
          this.detailBoxTitle = firstDecoration.title;
        // For individually displayed decoration if there is no title then hide the whole header row
        else if (typeof this.confUIItem != typeof GroupConfUIItem)
          this.showDetailBoxHeader = false;
      }
    }
  }

  getDecorations() {

    let decorations: VisualizationDecoration[] = [];

    if (this.confUIItem && this.confUIItem.id) {

      let decoration = this.productStore.getEntity<VisualizationDecoration>(this.confUIItem.id);
      if (decoration)
        decorations.push(decoration);
    }
    else if (this.confUIItem.items && this.confUIItem.items.size > 0) {

      if (this.confUIItem.items && this.confUIItem.items.size > 0) {
        this.confUIItem.items.forEach(x => {

          let decoration = this.productStore.getEntity<VisualizationDecoration>(x.id);
          if (decoration)
            decorations.push(decoration);
        });
      }

    }

    return decorations;
  }
}