import { Injectable, Inject } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { ConfiguratorUIStore } from "../configuratorUIStore";
import { ConfigurationSessionManager } from "../configurationSessionManager";

@Injectable()
export class ConfPageIdResolver implements Resolve<number> {

  constructor(
    @Inject(ConfiguratorUIStore) public confUIStore: ConfiguratorUIStore,
    @Inject(ConfigurationSessionManager) public sessesionManager: ConfigurationSessionManager
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<number> {
    return new Promise<number>(resolve => {
      this.confUIStore.createConfiguratorUI().then(response => {
        // Send the page id
        resolve(response.requestId);
      });
    });
  }
}