import { Injectable } from '@angular/core';
import { RouterModule, DefaultUrlSerializer, UrlTree, UrlSerializer, Router, ParamMap } from '@angular/router';

@Injectable()
export class StartupArguments {

  protected localeKey = "locale";
  protected allParams: ParamMap;  

  public locale: string;  

  constructor() {

    let urlSerializer = new DefaultUrlSerializer();
    let urlTree = urlSerializer.parse(window.location.href.replace(window.location.origin, ""));

    this.allParams = urlTree.queryParamMap;    
    this.locale = this.allParams.has(this.localeKey) ? this.allParams.get(this.localeKey) : null;
  }

  getArgument(key: string)
  {
    if (this.allParams.has(key))
      return this.allParams.get(key);

    return null;
  }
}