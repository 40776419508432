import { ImmutableObject } from "../../../../shared/immutableObject";
import { PropertyDecorationItem, ConfPropertyValue } from "../../../shared/models";

export class ConfPropertyModel extends ImmutableObject {

  public readonly LINE_BREAK = "lineBreak";
  public readonly WIDTH = "width";
  protected readonly MIN_WIDTH = "minWidth";
  protected readonly MAX_WIDTH = "maxWidth";
  public readonly STYLES = "styles";
  public readonly AUTO_WIDTH = "autoWidth";
  protected readonly FLOATING_POSITION = "floatingPosition";
  public readonly PROPERTY_DECORATION_ITEM = "propertyDecorationItem";
  public readonly CONF_PROPERTY_VALUE = "confPropertyValue";

  // Line break for visual object
  get lineBreak(): boolean { return this.getInternalValue<boolean>(this.LINE_BREAK); }
  setLineBreak(lineBreak: boolean): this { return this.setInternalValue(this.LINE_BREAK, lineBreak); }

  get width(): string { return this.getInternalValue<string>(this.WIDTH); }
  setWidth(width: string): this { return this.setInternalValue(this.WIDTH, width); }

  get minWidth(): string { return this.getInternalValue<string>(this.MIN_WIDTH); }
  setMinWidth(minWidth: string): this { return this.setInternalValue(this.MIN_WIDTH, minWidth); }

  get maxWidth(): string { return this.getInternalValue<string>(this.MAX_WIDTH); }
  setMaxWidth(maxWidth: string): this { return this.setInternalValue(this.MAX_WIDTH, maxWidth); }

  get styles(): string { return this.getInternalValue<string>(this.STYLES); }
  setStyles(styles: string): this { return this.setInternalValue(this.STYLES, styles); }

  get autoWidth(): boolean { return this.getInternalValue<boolean>(this.AUTO_WIDTH); }
  setAutoWidth(autoWidth: boolean): this { return this.setInternalValue(this.AUTO_WIDTH, autoWidth); }

  get floatingPosition(): boolean { return this.getInternalValue<boolean>(this.FLOATING_POSITION); }
  setFloatingPosition(floatingPosition: boolean): this { return this.setInternalValue(this.FLOATING_POSITION, floatingPosition); }

  get propertyDecorationItem(): PropertyDecorationItem { return this.getInternalValue<PropertyDecorationItem>(this.PROPERTY_DECORATION_ITEM); }
  setPropertyDecorationItem(propertyDecorationItem: PropertyDecorationItem): this { return this.setInternalValue(this.PROPERTY_DECORATION_ITEM, propertyDecorationItem); }

  get confPropertyValue(): ConfPropertyValue { return this.getInternalValue<ConfPropertyValue>(this.CONF_PROPERTY_VALUE); }
  setConfPropertyValue(confPropertyValue: ConfPropertyValue): this { return this.setInternalValue(this.CONF_PROPERTY_VALUE, confPropertyValue); }

}