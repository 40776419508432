<action-box *ngIf="visible"
            class="visualization-detail detail-item {{extraStyles}}"
            iconLeft="visualization"
            [textLeft]="detailBoxTitle"
            [showHeader]="showDetailBoxHeader">
  <ng-container *ngIf="contentVisible">

    <visualization-decoration *ngFor="let visualizationDecoration of visualizationDecorations"
                         [isEditor]="false"
                         [showTitle]="showDecorationTitle"
                         [configurationId]="configurationId"
                         [decoration]="visualizationDecoration"
                         [id]="visualizationDecoration.longId"></visualization-decoration>

  </ng-container>
  <div card-type="control" *ngIf="isPrintView">
    <print-checkbox (onValueChange)="onPrintValueChange($event)"></print-checkbox>
  </div>
</action-box>