import { Component, Inject } from "@angular/core";

import { AccountDataStore, AccountDataMessageProvider } from "../../shared/providers/accountData";
import { User, UserUpdateMessage } from "../../shared/models";
import { NotificationService, NotificationInfo, NotificationType, PopupService, InputViewModel } from "../../../shared/components";
import { AbstractUserSettingsComponent } from "./abstractUserSettingsComponent";
import { ApplicationLifeCycleService } from "../../shared/providers";

@Component({
  selector: 'user-profile',
  templateUrl: './userProfileComponent.html',
})
export class UserProfileComponent extends AbstractUserSettingsComponent {

  public dateReady: boolean = false;

  public username: string;
  public nameView: InputViewModel;
  public emailView: InputViewModel;
  public companyView: InputViewModel;
  public phoneNumberView: InputViewModel;
  public mobileNumberView: InputViewModel;

  constructor(
    @Inject(AccountDataStore) public accountStore: AccountDataStore,
    public accountMessageProvider: AccountDataMessageProvider,
    public notificationService: NotificationService,
    public popupService: PopupService,
    public applicationLifeCycleService: ApplicationLifeCycleService
  ) {
    super(accountStore, notificationService, accountMessageProvider, applicationLifeCycleService);
  }

  ngOnInit() {
    this.nameView = new InputViewModel(this.strings.Name);
    this.nameView.icon = "user";

    this.emailView = new InputViewModel(this.strings.Email);
    this.emailView.icon = "email";

    this.companyView = new InputViewModel(this.strings.Company);
    this.companyView.icon = "organization";

    this.phoneNumberView = new InputViewModel(this.strings.Phone);
    this.phoneNumberView.icon = "phone";

    this.mobileNumberView = new InputViewModel(this.strings.Mobile);
    this.mobileNumberView.icon = "mobile";

    super.ngOnInit();
  }

  protected shouldBeVisible(): boolean {
    return this.user.systemAuthorization.canChangeUserProfile;
  }

  public setValues() {
    super.setValues();

    this.username = this.user.username;
    this.nameView.value = this.user.name;
    this.emailView.value = this.user.email;
    this.companyView.value = this.user.company;
    this.phoneNumberView.value = this.user.phoneNumber;
    this.mobileNumberView.value = this.user.mobileNumber;

    this.dateReady = true;
  }

  public validateSettings(): boolean {
    // Perform some validation on client side. Will also be done on server side.

    // Name, email and company are required.
    if (!this.nameView.value) {
      this.nameView.errors = [this.strings.NameIsRequired];
      return false;
    }

    if (!this.emailView.value) {
      this.emailView.errors = [this.strings.InvalidEmailAddress];
      return false;
    }

    if (!this.companyView.value) {
      this.companyView.errors = [this.strings.CompanyNameIsRequired];
      return false;
    }

    return true;
  }

  public updateSettings() {
    // Validate on client
    if (this.validateSettings()) {
      super.updateSettings();
      this.accountStore.updateProfile(this.nameView.value, this.emailView.value, this.companyView.value, this.phoneNumberView.value, this.mobileNumberView.value);
    }
  }

  public showChangePassword(): void {
    this.popupService.open("change-password-popup");
  }

}