import * as Immutable from "immutable";
import { Decoration } from "./decoration";

export class GraphicsDecoration extends Decoration {

  protected readonly HEIGHT = "height";  
  protected readonly RESPONSIVE_SIZE = "responsiveSize";  
  protected readonly DISPLAY_STYLE = "displayStyle";
  protected readonly SHOW_IN_CONFIGURATOR = "showInConfigurator";
  protected readonly SHOW_IN_SUMMARY = "showInSummary";
  protected readonly SHOW_DOWNLOAD_LINK = "showDownloadLink";  
  protected readonly SHOW_PARENT_GRAPHICS = "showParentGraphics";
  protected readonly INITIAL_ZOOM_FACTOR = "initialZoomFactor";
  protected readonly INITIAL_ORIGO_POSITION = "initialOrigoPosition";
  protected readonly INITIAL_OFFSET_X = "initialOffsetX";
  protected readonly INITIAL_OFFSET_Y = "initialOffsetY";
  protected readonly HIDE_EMPTY_DECORATION = "hideEmptyDecoration";
  protected readonly HIDE_GRAPHICS_CONTROLS = "hideGraphicsControls";

  get height(): number { return this.getInternalValue<number>(this.HEIGHT); }
  setHeight(height: number): this { return this.setInternalValue(this.HEIGHT, height); }

  get responsiveSize(): boolean { return this.getInternalValue<boolean>(this.RESPONSIVE_SIZE); }
  setResponsiveSize(responsiveSize: boolean): this { return this.setInternalValue(this.RESPONSIVE_SIZE, responsiveSize); }

  get displayStyle(): string { return this.getInternalValue<string>(this.DISPLAY_STYLE, GraphicsDisplayStyle); }
  setDisplayStyle(displayStyle: string): this { return this.setInternalValue(this.DISPLAY_STYLE, displayStyle); }

  get showInConfigurator(): boolean { return this.getInternalValue<boolean>(this.SHOW_IN_CONFIGURATOR); }
  setShowInConfigurator(showInConfigurator: boolean): this { return this.setInternalValue(this.SHOW_IN_CONFIGURATOR, showInConfigurator); }

  get showInSummary(): boolean { return this.getInternalValue<boolean>(this.SHOW_IN_SUMMARY); }
  setShowInSummary(showInSummary: boolean): this { return this.setInternalValue(this.SHOW_IN_SUMMARY, showInSummary); }

  get showDownloadLink(): boolean { return this.getInternalValue<boolean>(this.SHOW_DOWNLOAD_LINK); }
  setShowDownloadLink(showDownloadLink: boolean): this { return this.setInternalValue(this.SHOW_DOWNLOAD_LINK, showDownloadLink); }

  get showParentGraphics(): number { return this.getInternalValue<number>(this.SHOW_PARENT_GRAPHICS); }
  setShowParentGraphics(showParentGraphics: number): this { return this.setInternalValue(this.SHOW_PARENT_GRAPHICS, showParentGraphics); }

  get initialZoomFactor(): number { return this.getInternalValue<number>(this.INITIAL_ZOOM_FACTOR); }
  setInitialZoomFactor(initialZoomFactor: number): this { return this.setInternalValue(this.INITIAL_ZOOM_FACTOR, initialZoomFactor); }

  get initialOrigoPosition(): number { return this.getInternalValue<number>(this.INITIAL_ORIGO_POSITION); }
  setInitialOrigoPosition(initialOrigoPosition: number): this { return this.setInternalValue(this.INITIAL_ORIGO_POSITION, initialOrigoPosition); }

  get initialOffsetX(): number { return this.getInternalValue<number>(this.INITIAL_OFFSET_X); }
  setInitialOffsetX(initialOffsetX: number): this { return this.setInternalValue(this.INITIAL_OFFSET_X, initialOffsetX); }

  get initialOffsetY(): number { return this.getInternalValue<number>(this.INITIAL_OFFSET_Y); }
  setInitialOffsetY(initialOffsetY: number): this { return this.setInternalValue(this.INITIAL_OFFSET_Y, initialOffsetY); }

  get hideEmptyDecoration(): boolean { return this.getInternalValue<boolean>(this.HIDE_EMPTY_DECORATION); }
  setHideEmptyDecoration(hideEmptyDecoration: boolean): this { return this.setInternalValue(this.HIDE_EMPTY_DECORATION, hideEmptyDecoration); }

  get hideGraphicsControls(): boolean { return this.getInternalValue<boolean>(this.HIDE_GRAPHICS_CONTROLS); }
  setHideGraphicsControls(hideGraphicsControls: boolean): this { return this.setInternalValue(this.HIDE_GRAPHICS_CONTROLS, hideGraphicsControls); }
}

export enum GraphicsDisplayStyle {
  Render = "Render",
  Popup = "Popup",
  OpenInDownloadFormat = "OpenInDownloadFormat"
}