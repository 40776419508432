import { Component, Inject, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core";
import * as Immutable from "immutable"
import { InputViewModel, NotificationService, NotificationType, NotificationInfo } from "../../../shared/components";
import { AccountDataStore, AccountDataMessageProvider } from "../../shared/providers/accountData";
import { UserUpdateMessage, Language, User, AccountDataResponse, PriceListCategory } from "../../shared/models";
import { GlobalDataStore } from "../../shared/providers/globalData";
import { ModelLabelService } from "../../shared/providers/modelLabelService";
import { ManagedSubscription } from "../../../shared/managedSubscription";
import { AbstractUserSettingsComponent } from "./abstractUserSettingsComponent";
import { ApplicationLifeCycleService } from "../../shared/providers";
import { ImageSets } from "../../../shared/utils";

@Component({
  selector: 'user-settings',
  templateUrl: './userSettingsComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSettingsComponent extends AbstractUserSettingsComponent {

  public hightlightMandatoryParamsView: InputViewModel;

  public languageView: InputViewModel;
  public languagesView: Array<InputViewModel> = [];

  public priceListCategoryView: InputViewModel;
  public priceListCategoriesView: Array<InputViewModel> = [];

  constructor(
    @Inject(AccountDataStore) public accountStore: AccountDataStore,
    @Inject(ChangeDetectorRef) public cdr: ChangeDetectorRef,
    public globalDataStore: GlobalDataStore,
    public notificationService: NotificationService,
    public accountMessageProvider: AccountDataMessageProvider,
    public applicationLifeCycleService: ApplicationLifeCycleService
  ) {
    super(accountStore, notificationService, accountMessageProvider, applicationLifeCycleService);
  }

  ngOnInit() {
    this.hightlightMandatoryParamsView = new InputViewModel(this.strings.HighlightMandatoryParameters);

    super.ngOnInit();

    // Language and DefaultPriceListCategory is actually in userProfile, so need to check its systemAuth here.
    if (this.user.systemAuthorization.canChangeUserProfile) {
      this.setupLanguageUI();
      this.setupPriceListCategoryUI();
    }
  }

  protected setupLanguageUI() {
    this.languageView = new InputViewModel();
    this.languagesView = [];

    this.languageView.iconImageSet = this.getImageSet(ImageSets.Control);
    this.languageView.icon = "worldmap";
    this.languageView.title = this.strings.Language;

    this.globalDataStore.getEntities<Language>(Language.name).subscribe((storeResponse) => {
      storeResponse.data.forEach(x => {
        let viewModel = new InputViewModel(x.title);
        viewModel.id = x.longId.toString();        
        this.languagesView.push(viewModel);
      });

      this.setUserLanguage();
    }).unsubscribeOn(this.unsubscribeSubject);
  }

  protected setupPriceListCategoryUI() {
    this.priceListCategoryView = new InputViewModel();
    this.priceListCategoriesView = [];

    this.priceListCategoryView.icon = "pricelistcategory";
    this.priceListCategoryView.iconImageSet = this.getImageSet(ImageSets.Control);
    this.priceListCategoryView.title = this.strings.DefaultPriceListCategory;

    this.globalDataStore.getEntities<PriceListCategory>(PriceListCategory.name).subscribe((storeResponse) => {
      storeResponse.data.forEach(x => {
        let viewModel = new InputViewModel(x.title);
        viewModel.id = x.longId.toString();
        this.priceListCategoriesView.push(viewModel);
      });

      this.setUserDefaultPriceListCategory();

      if(!this.cdr['destroyed'])
        this.cdr.detectChanges();
    }).unsubscribeOn(this.unsubscribeSubject);
  }

  protected shouldBeVisible(): boolean {
    return this.user.systemAuthorization.canChangeUserSettings;
  }

  public setValues() {
    super.setValues();

    this.hightlightMandatoryParamsView.value = this.user.settings.highlightMandatoryParameters || false;
    this.setUserLanguage();
  }

  public setUserLanguage() {

    let localizedData = this.globalDataStore.getGlobalData().localizeData;

    if (localizedData && this.languagesView.length > 0) {

      // Language codes
      //let codes: Array<string> = 

      for (let itemView of this.languagesView) {

        if (itemView.title == localizedData.languageTitle) {

          this.languageView.formattedValue = itemView.title;
          this.languageView.value = itemView.id;
          this.languageView = { ...this.languageView };
          this.cdr.markForCheck();
          break;
          
        }
      }

      if(!this.cdr['destroyed'])
        this.cdr.detectChanges();
    }
  }

  public setUserDefaultPriceListCategory() {
    if (this.user && this.user.defaultPriceListCategory && this.priceListCategoriesView.length > 0) {
      let userPlcView = this.priceListCategoriesView.find(x => x.id === this.user.defaultPriceListCategory.longId.toString());
      this.priceListCategoryView.formattedValue = userPlcView.title;
      this.priceListCategoryView.value = userPlcView.id;
      this.priceListCategoryView = { ... this.priceListCategoryView };
      this.cdr.markForCheck();
    }
  }

  public updateSettings() {
    super.updateSettings();
    let languageId = this.languageView ? this.languageView.value : null;
    let defaultPriceListCategoryId = this.priceListCategoryView ? this.priceListCategoryView.value : null;
    this.accountStore.updateUserSettings(this.isChecked(this.hightlightMandatoryParamsView), languageId, defaultPriceListCategoryId);
    // Note: Small trick to fix this issue, the better option is to listen the language change 
    // in settings and header component and update them without page reload.     
    // In future we might need to go to that direction.
    location.reload();
  }

}