import * as Immutable from "immutable";

import { BaseEntity } from "../../baseEntity";

export class ConfFormControlValue extends BaseEntity {

  protected readonly FORM_CONTROL_TYPE = "formControlType";
  protected readonly TITLE = "title";
  protected readonly ICON = "icon";
  protected readonly ENABLED = "enabled";
  protected readonly SUB_FORM_CONTROL_VALUES = "subFormControlValues";

  get formControlType(): string { return this.getInternalValue<string>(this.FORM_CONTROL_TYPE); }
  setFormControlType(formControlType: string): this { return this.setInternalValue(this.FORM_CONTROL_TYPE, formControlType); }

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get icon(): string { return this.getInternalValue<string>(this.TITLE); }
  setIcon(icon: string): this { return this.setInternalValue(this.TITLE, icon); }

  get enabled(): number { return this.getInternalValue<number>(this.ENABLED); }
  setEnabled(enabled: number): this { return this.setInternalValue(this.ENABLED, enabled); }

  get subFormControlValues(): Immutable.List<ConfFormControlValue> { return this.getInternalValue<Immutable.List<ConfFormControlValue>>(this.SUB_FORM_CONTROL_VALUES); }
  setSubFormControlValues(subFormControlValues: Immutable.List<ConfFormControlValue>): this { return this.setInternalValue(this.SUB_FORM_CONTROL_VALUES, subFormControlValues); }
}