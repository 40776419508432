<backdrop-container>
  <regular-popup *ngIf="visible"
                 [title]="title"
                 [id]="id"
                 [showFooter]="true"
                 [closeOnOk]="false"
                 [okButtonText]="strings.Ok"
                 [cancelButtonText]="strings.Cancel"
                 (okClick)="storeSearch()"
                 (onClosed)="onPopupClosed()">
    <!--TODO localize-->
    <div card-type="body" class="p-4 h-100">
      <text-box [errors]="titleView.errors" [view]="titleView"></text-box>      
      <check-box class="d-block pt-4" [view]="resultsInlineView"></check-box>
      <dropdown class="d-block pt-4"
                [itemsView]="maxInlineResultsViews"
                [view]="maxInlineResultsView"></dropdown>
      <dropdown class="d-block pt-4"
                [itemsView]="scopeItemViews"
                [view]="scopeView"
                (onValueChange)="setScopeRelatedViews()"></dropdown>

      <dropdown *ngIf="workgroupView"
                class="d-block pt-4"
                [itemsView]="workgroupItemViews"
                [view]="workgroupView"></dropdown>

      <dropdown *ngIf="authorizationGroupView"
                class="d-block pt-4"
                [itemsView]="authorizationGroupItemViews"
                [view]="authorizationGroupView"></dropdown>

      <check-box *ngIf="saveAsNewView" class="d-block pt-5" [view]="saveAsNewView"></check-box>

      <div *ngFor="let error of serverErrors" class="form-control-feedback d-block">{{error}}</div>
    </div>
  </regular-popup>
</backdrop-container>