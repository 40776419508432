import { Dispatch } from "redux";

import { AppAction } from "./appAction"
import { AppState } from "./appState"

export class HttpAction<T> extends AppAction<T> {

  executeAsync: (dispatch?: Dispatch<any>, getState?: () => AppState, callback?: (ActionStatus) => void) => void;

  status?: string;
}