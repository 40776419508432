import { Component, Inject, Optional } from "@angular/core";
import * as Immutable from "immutable";
import { PopupService } from "../../../../shared/components";
import { Conf, ConfPriceValue, PriceListDecoration, PriceReportMessage, StoredPriceValue, StoredPriceReportMessage } from "../../../shared/models";
import { ProductDataStore } from "../../../shared/providers/productData";
import { ConfiguratorStore, ConfMessageProvider, ConfPageSessionService, PopupIdentifiers } from "../../providers";
import { BaseDetailItemComponent } from "../shared/baseDetailItemComponent";
import { SummaryDisplayStyles } from "./SummaryDisplayStyle";

@Component({
  selector: 'prices-detail',
  templateUrl: './pricesDetailComponent.html',
})
export class PricesDetailComponent extends BaseDetailItemComponent {

  public confPriceValue: ConfPriceValue;
  public storedPriceValue: StoredPriceValue;
  public showPriceType = false;
  public isCurrentPriceVisible = false;
  public isStoredPriceVisible = false;
  
  constructor( @Optional() @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfMessageProvider) public confMessageProvider: ConfMessageProvider,
    @Inject(PopupService) public popupService: PopupService,
    @Inject(ProductDataStore) public productStore: ProductDataStore,
  ) {
    super(storeSession);
  }

  onDataReady(): void {
    
  }


  init(): void {

    if (!this.configurationId || !this.confSessionId)
      return;

    let conf = this.storeSession.activeConfiguration;
    this.confPriceValue = conf.priceValue;
    this.storedPriceValue = conf.storedData ? conf.storedData.priceValue : null;

    let decoration = this.productStore.getEntity<PriceListDecoration>(this.confUIItem.id);

    if (!decoration || !decoration.showInSummary || !decoration.summaryDisplayStyle)
      return;

    this.isCurrentPriceVisible = (decoration.summaryDisplayStyle == SummaryDisplayStyles.Both || decoration.summaryDisplayStyle == SummaryDisplayStyles.Calculated) &&
      this.confPriceValue != null &&
      this.confPriceValue.priceListCategoryId != null;

    this.isStoredPriceVisible = (decoration.summaryDisplayStyle == SummaryDisplayStyles.Both || decoration.summaryDisplayStyle == SummaryDisplayStyles.Stored) &&
      this.storedPriceValue != null &&
      this.storedPriceValue.priceListCategoryId != null;

    this.visible = this.isCurrentPriceVisible || this.isStoredPriceVisible;

    this.showPriceType = this.isCurrentPriceVisible && this.isStoredPriceVisible;

  }

  public onDetailsClick($event, isStoredReport: boolean = false): void {
    // TODO this will crash, price popup is not included in summary.
    let requestType = this.confPageSessionService.confUIStore.PageStore.getActiveClientType();
    this.confStore.getPriceReport(this.confSessionId, this.configurationId, requestType, isStoredReport);
  }

  subscribeDataChange(): void {

    // Price report message
    this.confMessageProvider.onMessagesRequest<PriceReportMessage>(this.confPageSessionService.confSessionId, PriceReportMessage.name, {
      next: (messages: Immutable.List<PriceReportMessage>): void => {

        this.popupService.open<Immutable.List<PriceReportMessage>>(PopupIdentifiers.PriceList, messages);
      },
      listenNewEventsOnly: true
    }).unsubscribeOn(this.unsubscribeSubject);

    // Stored price report message
    this.confMessageProvider.onMessagesRequest<StoredPriceReportMessage>(this.confPageSessionService.confSessionId, StoredPriceReportMessage.name, {
      next: (messages: Immutable.List<PriceReportMessage>): void => {

        this.popupService.open<Immutable.List<StoredPriceReportMessage>>(PopupIdentifiers.PriceList, messages);
      },
      listenNewEventsOnly: true
    }).unsubscribeOn(this.unsubscribeSubject);

    this.confStore.onConfigurationChange(this.configurationId, this.confSessionId, (conf: Conf) => {

      this.init();

    }).unsubscribeOn(this.unsubscribeSubject);

  }
  
}