import { Injectable, HostListener } from "@angular/core";

// Define the minimum dimensions at which your layout will change, these settings are same as in sass.
export let Breakpoints: BreakpointsHolder = {
  // Extra small devices (phones)
  xs: {
    min: 0,
    max: 575
  },
  // Small devices (tablets)
  sm: {
    min: 576,
    max: 767
  },
  // Medium devices (desktops)
  md: {
    min: 768,
    max: 991,
  },
  // Large devices (large desktops)
  lg: {
    min: 992,
    max: 1199
  },

  xl: {
    min: 1200,
    max: Infinity
  }
};


export interface BreakpointsHolder {


  xs: BreakpointRange;

  sm: BreakpointRange;

  md: BreakpointRange;

  lg: BreakpointRange;

  xl: BreakpointRange;

}

export interface BreakpointRange {
  min: number;
  max: number;
}

export enum BreakpointOperator {
  Equal = 'equal',
  EqualOrUp = 'equalOrUp',
  EqualOrDown = 'equalOrDown',
  Up = "up",
  Down = "down"
}