import { Component, Inject, Input, ChangeDetectorRef, SimpleChanges, ChangeDetectionStrategy } from "@angular/core";
import { InputViewModel, TextBoxComponent } from "..";
import { RangeInputViewModel, RangeOperator } from "./rangeInputViewModel";

@Component({
  selector: 'textbox-range',
  templateUrl: './textboxRangeComponent.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class TextboxRangeComponent extends TextBoxComponent {

  @Input()
  public view: RangeInputViewModel;

  @Input()
  public showInput2: boolean = false;
  
  public internalView: InputViewModel;
  public internalView1: InputViewModel;
  public internalView2: InputViewModel;  
  public internalOperatorGroup: InputViewModel;

  @Input()
  public internalOperatorItems: Array<InputViewModel> = [];

  constructor(public cd: ChangeDetectorRef) {
    super(cd);
  }

  ngOnInit() {
    this.initInternalViews();
  }

  initInternalViews(): void {
    
    if (this.internalOperatorItems.length == 0)
      this.internalOperatorItems.push(
        this.getInputViewModel(RangeOperator.Equal, 'Equal', null, 'equal', true),
        this.getInputViewModel(RangeOperator.Greater, 'Greater', null, 'greater', true),
        this.getInputViewModel(RangeOperator.GreaterOrEqual, 'Greater or equal', null, 'greaterorequal', true),
        this.getInputViewModel(RangeOperator.Less, 'Less than', null, 'less', true),
        this.getInputViewModel(RangeOperator.LessOrEqual, 'Less than or equal', null, 'lessorequal', true),
        this.getInputViewModel(RangeOperator.Range, 'Between', null, 'between', true)
      );

    if (this.view.Operator == RangeOperator.Range) {
      this.internalView1 = this.getInputViewModel('textbox1', null, this.view.placeHolder1, null, null, this.view.value1);
      this.internalView2 = this.getInputViewModel('textbox2', null, this.view.placeHolder2, null, null, this.view.value2);
      this.internalView = null;
    }
    else {

      this.internalView = this.getInputViewModel('textbox', null, this.view.placeholder, null, null, this.view.value);
      this.internalView1 = null;
      this.internalView2 = null;
    }


    this.internalOperatorGroup = this.getInputViewModel('operators');
    this.internalOperatorGroup.showIconAsSelected = true;

    // Make the operator selected.
    this.internalOperatorGroup.value = this.view.Operator as RangeOperator;
    this.internalOperatorGroup.id = this.view.Operator as RangeOperator;
    
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (!changes['view'].firstChange) {      
      this.initInternalViews();
    }

  }

  getInputViewModel(id: string, title?: string, placeHolder?: string, icon?: string, showIconAsValue?: boolean, value?: string): InputViewModel {

    let view: InputViewModel = new InputViewModel();
    view.placeholder = placeHolder;
    view.id = id;        
    view.title = title;
    view.icon = icon;
    view.showIconAsSelected = showIconAsValue;
    view.value = value;

    return view;
  }

  onOperatorChange(event): void {

    this.view.Operator = event.value;
    this.initInternalViews();
    
  }

  onSingleValueChange(event): void {
    this.view.value = event.value;
  }

  onValueChange1(event): void {
    this.view.value1 = event.value;
  }

  onValueChange2(event): void {
    this.view.value2 = event.value;
  }

}