import { Inject, Component, Input, ViewChild, SimpleChanges, ChangeDetectorRef, Optional } from "@angular/core";
import { Subscription } from "rxjs";
import { BaseComponent } from "../../../pages/shared";
import { SidebarPopupComponent } from "./sidebarPopupComponent";
import { RegularPopupComponent } from "./regularPopupComponent";
import { PopupInfo, PopupActions } from "./popupInfo";
import { PopupService } from "./popupService";
import { BasePopupComponent } from "./basePopupComponent";

/**
 * This class is used to share logic between every popup component, and helps with visibilty logic.
   The popups can be very heavy and this component helps with only rendering it when it's needed.
 */
@Component({ template: '' })
export abstract class AbstractPopupComponent extends BaseComponent {

  public visible = false;

  @Input()
  public id: string;

  public messageSubscription: Subscription;

  @ViewChild(SidebarPopupComponent)
  protected sideBarPopup: SidebarPopupComponent;

  @ViewChild(RegularPopupComponent)
  protected regularPopup: RegularPopupComponent;

  constructor(
    public cdr: ChangeDetectorRef,
    @Optional() public popupService?: PopupService
  ) {
    super();
  }

  ngOnInit(): void {
    // Listen for open from popupservice if available.
    if (this.popupService) {
      this.messageSubscription = this.popupService.getMessage().subscribe((info: PopupInfo<any>) => {
        if (info.id === this.id) {
          this.executeAction(info);
        }
      });
    }
  }

  public executeAction(info: PopupInfo<any>): void {
    switch (info.action) {
      case PopupActions.Open:
        this.open(info.tag);
        break;

      case PopupActions.Close:
        this.removeDomElement();
        break;
    }

    this.cdr.markForCheck();
  }

  /**
   * Opens this popup from the popup service.
   * @param tag any given data, eg. messages
   */
  public open(tag?: any) {    
    this.showInternal();
  }

  public get popup(): BasePopupComponent {
    let popupComponent = this.sideBarPopup || this.regularPopup;
    if (popupComponent == null) {
      this.visible = true;
      popupComponent = this.sideBarPopup || this.regularPopup;
      this.cdr.markForCheck();
    }
    return popupComponent;
  }

  public initializeContents(tag: any): void {

  }

  protected showInternal(): void {
    this.visible = true;

    setTimeout(() => {
      this.popup.open();
    }, 0);
  }

  public removeDomElement() {
    this.visible = false;
  }

  public onPopupClosed(): void {
    this.removeDomElement();
  }

  ngOnDestroy() {
    if (this.messageSubscription)
      this.messageSubscription.unsubscribe();

    super.ngOnDestroy();
  }
}