import * as Immutable from "immutable";
import { BaseMessage } from "./baseMessage";
import { PopupObject } from "./popupObject";

export class PopupSequence extends BaseMessage {
	readonly POPUP_OBJECTS = "popupObjects";
	readonly SEQUENCE_REFERENCE = "sequencReference";

	get popupObjects(): Immutable.List<PopupObject> { return this.getInternalValue<Immutable.List<PopupObject>>(this.POPUP_OBJECTS); }
	setPopupObjects(popupObjects: Immutable.List<PopupObject>): this { return this.setInternalValue(this.POPUP_OBJECTS, popupObjects); }

	get sequencReference(): SequencReference { return this.getInternalValue<SequencReference>(this.SEQUENCE_REFERENCE); }
	setSequencReference(sequencReference: SequencReference): this { return this.setInternalValue(this.SEQUENCE_REFERENCE, sequencReference); }
}

// reflected in server side
export enum SequencReference {
	unlinkedQuoteDetails,
	unlikedConfigurationModels
}