<div *ngIf="visible" class="row browser-support-info mx-0" >
  <div class="col-auto" [style.width.px]="24" >

  </div>
  <div class="col title">
    {{message}}
  </div>
  <div class="col-auto close-button" (click)="removeWarning($event)" >
    <co-image width="18" height="18" type="svg" imageSet="white" key="delete"></co-image>
  </div>
</div>