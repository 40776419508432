import * as Immutable from "immutable";
import { BaseMessage } from "./baseMessage";
import { SequencReference } from "./popupSequence";

export class PopupObject extends BaseMessage {

	readonly SEQUENCE_REFERENCE = "sequencReference";
	readonly PURPOSE = "purpose";
	readonly SESSION_ID = "sessionId";
	readonly ROOT_CONF_ID = "rootConfId";
	readonly SUB_CONF_ID = "subConfId";
	readonly SUB_CONF_IDENTITY = "subConfIdentity";
	readonly PRODUCT_ID = "productId";
	readonly GENERATED_PRODUCT_ID = "generatedProductId";
	readonly QUOTEDETAIL_CRM_IDENTITY = "quoteDetailCrmIdentity";
	readonly QUOTEDETAIL_COMBINUM_IDENTITY = "quoteDetailCombinumIdentity";
	readonly POPUP_MESSAGE_TITLE = "popupMessageTitle";
	readonly POPUP_MESSAGE = "popupMessage";
	readonly SKIP_NEXT_POPUP_IF_USER_ANSWERS_YES = "skipNextPopupIfUserAnswersYes";
	readonly SKIP_NEXT_POPUP_IF_USER_ANSWERS_NO = "skipNextPopupIfUserAnswersNo";
	readonly YES_BUTTON_TEXT = "yesButtonText";
	readonly NO_BUTTON_TEXT = "noButtonText";
	readonly POPUP_WIDTH = "popupWidth";
	readonly POPUP_HEIGHT = "popupHeight";

	readonly ARGS = "args";

	get sequencReference(): SequencReference { return this.getInternalValue<SequencReference>(this.SEQUENCE_REFERENCE); }
	setSequencReference(sequencReference: SequencReference): this { return this.setInternalValue(this.SEQUENCE_REFERENCE, sequencReference); }

	get purpose(): Purpose { return this.getInternalValue<Purpose>(this.PURPOSE); }
	setPurpose(purpose: Purpose): this { return this.setInternalValue(this.PURPOSE, purpose); }

	get sessionId(): number { return this.getInternalValue<number>(this.SESSION_ID); }
	setSessionId(sessionId: number): this { return this.setInternalValue(this.SESSION_ID, sessionId); }

	get rootConfId(): number { return this.getInternalValue<number>(this.ROOT_CONF_ID); }
	setRootConfId(rootConfId: number): this { return this.setInternalValue(this.ROOT_CONF_ID, rootConfId); }

	get subConfId(): number { return this.getInternalValue<number>(this.SUB_CONF_ID); }
	setSubConfId(subConfId: number): this { return this.setInternalValue(this.SUB_CONF_ID, subConfId); }

	get subConfIdentity(): string { return this.getInternalValue<string>(this.SUB_CONF_IDENTITY); }
	setSubConfIdentity(subConfIdentity: string): this { return this.setInternalValue(this.SUB_CONF_IDENTITY, subConfIdentity); }

	get productId(): number { return this.getInternalValue<number>(this.PRODUCT_ID); }
	setProductId(productId: number): this { return this.setInternalValue(this.PRODUCT_ID, productId); }

	get generatedProductId(): number { return this.getInternalValue<number>(this.GENERATED_PRODUCT_ID); }
	setGeneratedProductId(generatedProductId: number): this { return this.setInternalValue(this.GENERATED_PRODUCT_ID, generatedProductId); }

	get quoteDetailCrmIdentity(): string { return this.getInternalValue<string>(this.QUOTEDETAIL_CRM_IDENTITY); }
	setQuoteDetailCrmIdentity(quoteDetailCrmIdentity: string): this { return this.setInternalValue(this.QUOTEDETAIL_CRM_IDENTITY, quoteDetailCrmIdentity); }

	get quoteDetailCombinumIdentity(): string { return this.getInternalValue<string>(this.QUOTEDETAIL_COMBINUM_IDENTITY); }
	setQuoteDetailCombinumIdentity(quoteDetailCombinumIdentity: string): this { return this.setInternalValue(this.QUOTEDETAIL_COMBINUM_IDENTITY, quoteDetailCombinumIdentity); }

	get popupMessageTitle(): string { return this.getInternalValue<string>(this.POPUP_MESSAGE_TITLE); }
	setPopupMessageTitle(popupMessageTitle: string): this { return this.setInternalValue(this.POPUP_MESSAGE_TITLE, popupMessageTitle); }

	get popupMessage(): string { return this.getInternalValue<string>(this.POPUP_MESSAGE); }
	setPopupMessage(popupMessage: string): this { return this.setInternalValue(this.POPUP_MESSAGE, popupMessage); }

	get skipNextPopupIfUserAnswersYes(): boolean { return this.getInternalValue<boolean>(this.SKIP_NEXT_POPUP_IF_USER_ANSWERS_YES); }
	setSkipNextPopupIfUserAnswersYes(skipNextPopupIfUserAnswersYes: boolean): this { return this.setInternalValue(this.SKIP_NEXT_POPUP_IF_USER_ANSWERS_YES, skipNextPopupIfUserAnswersYes); }

	get skipNextPopupIfUserAnswersNo(): boolean { return this.getInternalValue<boolean>(this.SKIP_NEXT_POPUP_IF_USER_ANSWERS_NO); }
	setSkipNextPopupIfUserAnswersNo(skipNextPopupIfUserAnswersNo: boolean): this { return this.setInternalValue(this.SKIP_NEXT_POPUP_IF_USER_ANSWERS_NO, skipNextPopupIfUserAnswersNo); }

	get yesButtonText(): string { return this.getInternalValue<string>(this.YES_BUTTON_TEXT); }
	setYesButtonText(yesButtonText: string): this { return this.setInternalValue(this.YES_BUTTON_TEXT, yesButtonText); }

	get noButtonText(): string { return this.getInternalValue<string>(this.NO_BUTTON_TEXT); }
	setNoButtonText(noButtonText: string): this { return this.setInternalValue(this.NO_BUTTON_TEXT, noButtonText); }

	get popupWidth(): string { return this.getInternalValue<string>(this.POPUP_WIDTH); }
	setPopupWidth(popupWidth: string): this { return this.setInternalValue(this.POPUP_WIDTH, popupWidth); }

	get popupHeight(): string { return this.getInternalValue<string>(this.POPUP_HEIGHT); }
	setPopupHeight(popupHeight: string): this { return this.setInternalValue(this.POPUP_HEIGHT, popupHeight); }

	get args(): Immutable.Map<string, string> { return this.getInternalValue<Immutable.Map<string, string>>(this.ARGS); }
	setArgs(args: Immutable.Map<string, string>): this { return this.setInternalValue(this.ARGS, args); }
}

// reflected in server side
export enum Purpose {
	ChildconfigurationsExistInCombinumOutOfSyncWithQuoteDetails,
	QuoteDetailsExistInCrmOutOfSyncWithChildconfigurations,
	DeleteConfiguration,
	DeleteQuoteDetail,
	LinkSubConfToQuoteDetail
}

