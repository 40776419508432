import * as Immutable from "immutable"
import { BaseEntity } from "../../baseEntity"
import { ImmutableObject } from "../../../../shared/immutableObject";

export class ProductDataResponse extends ImmutableObject
{
  protected readonly ASSORTMENT_IDS = "assortmentIds";
  protected readonly PRODUCT_IDS = "productIds";
  protected readonly TAB_IDS = "tabIds";
  protected readonly PARAMETER_IDS = "parameterIds"; 
  protected readonly ENTITIES = "entities";
  protected readonly ENTITY_IDS_BY_CLASS_NAME = "entityIdsByClassName";
    
  get entities(): Immutable.Map<number, BaseEntity> { return this.getInternalValue<Immutable.Map<number, BaseEntity>>(this.ENTITIES); }
  setEntities(entities: Immutable.Map<number, BaseEntity>): this { return this.setInternalValue(this.ENTITIES, entities); }    

  get entityIdsByClassName(): Immutable.Map<string, Immutable.List<number>> { return this.getInternalValue<Immutable.Map<string, Immutable.List<number>>>(this.ENTITY_IDS_BY_CLASS_NAME); }
  setEntityIdsByClassName(entityIdsByClassName: Immutable.Map<string, Immutable.List<number>>): this { return this.setInternalValue(this.ENTITY_IDS_BY_CLASS_NAME, entityIdsByClassName); }    
}