import * as Immutable from "immutable";
import { VisualObject } from "./visualObject";

export class Param extends VisualObject {

  protected readonly NAME = "name";
  protected readonly HELP_TEXT = "helpText";
  protected readonly EXTERN_LINK = "externLink";
  protected readonly HELP_IMAGE = "helpImage";
  protected readonly WIDTH = "width";
  protected readonly HEIGHT = "height";
  protected readonly ICON_RELATIVE_URL = "iconRelativeUrl";
  protected readonly HELP_IMAGE_RELATIVE_URL = "helpImageRelativeUrl";
  protected readonly HELP_IMAGE_WIDTH = "helpImageWidth";
  protected readonly HELP_IMAGE_HEIGHT = "helpImageHeight";
  protected readonly PARAM_VALUES = "paramValues";

  get name(): string { return this.getInternalValue<string>(this.NAME); }
  setName(name: string): this { return this.setInternalValue(this.NAME, name); }

  get helpText(): string { return this.getInternalValue<string>(this.HELP_TEXT); }
  setHelpText(helpText: string): this { return this.setInternalValue(this.HELP_TEXT, helpText); }

  get externLink(): string { return this.getInternalValue<string>(this.EXTERN_LINK); }
  setExternLink(externLink: string): this { return this.setInternalValue(this.EXTERN_LINK, externLink); }

  get width(): number { return this.getInternalValue<number>(this.WIDTH); }
  setWidth(width: number): this { return this.setInternalValue(this.WIDTH, width); }

  get height(): number { return this.getInternalValue<number>(this.HEIGHT); }
  setHeight(height: number): this { return this.setInternalValue(this.HEIGHT, height); }

  get iconRelativeUrl(): string { return this.getInternalValue<string>(this.ICON_RELATIVE_URL); }
  setIconRelativeUrl(iconRelativeUrl: string): this { return this.setInternalValue(this.ICON_RELATIVE_URL, iconRelativeUrl); }

  get helpImageRelativeUrl(): string { return this.getInternalValue<string>(this.HELP_IMAGE_RELATIVE_URL); }
  setHelpImageRelativeUrl(helpImage: string): this { return this.setInternalValue(this.HELP_IMAGE_RELATIVE_URL, helpImage); }

  get helpImageWidth(): number { return this.getInternalValue<number>(this.HELP_IMAGE_WIDTH); }
  setHelpImageWidth(helpImageWidth: number): this { return this.setInternalValue(this.HELP_IMAGE_WIDTH, helpImageWidth); }

  get helpImageHeight(): number { return this.getInternalValue<number>(this.HELP_IMAGE_HEIGHT); }
  setHelpImageHeight(helpImageHeight: number): this { return this.setInternalValue(this.HELP_IMAGE_HEIGHT, helpImageHeight); }

  get paramValues(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.PARAM_VALUES); }
  setParamValues(paramValues: Immutable.List<number>): this { return this.setInternalValue(this.PARAM_VALUES, paramValues); }
}