export class AccountUrlParams {

  public static KEY: string = "key"
  public static USER: string = "user"

  // Identifier to store the session.
  key: string;

  // Username
  user?: string; 

}