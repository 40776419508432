import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AutoScrollbarDirective } from "./autoScrollbarDirective";

export * from "./autoScrollbarDirective";


@NgModule({
  imports: [
    CommonModule    
  ],
  declarations: [
    AutoScrollbarDirective
  ],
  exports: [
    AutoScrollbarDirective
  ],
  providers: [
    AutoScrollbarDirective
  ]
})

export class AutoScrollbarModule {

}