import * as Immutable from "immutable";
import { BaseEntity } from "../../baseEntity";

export class StoredAttributeValue extends BaseEntity {

  protected readonly VALUE = "value";
  protected readonly UNIT = "unit";

  get value(): string { return this.getInternalValue<string>(this.VALUE); }
  setValue(value: string): this { return this.setInternalValue(this.VALUE, value); }

  get unit(): string { return this.getInternalValue<string>(this.UNIT); }
  setUnit(unit: string): this { return this.setInternalValue(this.UNIT, unit); }
}