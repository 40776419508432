import { Component, Input} from "@angular/core";
import { BaseInputComponent } from "../shared";
import { MultiChoiceItemViewModel } from "./multiChoiceItemViewModel";

@Component({
  selector: 'multi-choice-item',
  template: `<check-box 
                      [triState]="view.triState"
                      [view]="view"                                             
                      styleNames="input-value-color"
                      (onValueChange)="onValueChange.emit($event)"
                      (onReadOnly)="onReadOnly.emit($event)"
                      (onDisabled)="onDisabled.emit($event)"
                      >
            </check-box>`
})
export class MultiChoiceItemComponent extends BaseInputComponent {
  @Input()
  view: MultiChoiceItemViewModel;
}