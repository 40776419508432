import * as Immutable from "immutable"

import { AbstractConfigurationMessage } from "./abstractConfigurationMessage"
import { BomAutoCompleteRow } from "../bomAutoCompleteRow";

export class BomItemMessage extends AbstractConfigurationMessage {

  protected readonly BOM_DECORATION_ID = "bomDecorationId";
  protected readonly ID = "id";
  protected readonly ITEM_NO = "itemNo";
  protected readonly DESCRIPTION = "description";
  protected readonly REVISION = "revision";
  protected readonly REVISIONS = "revisions";
  protected readonly QUANTITY = "quantity";
  protected readonly QUANTITY_UNIT_ID = "quantityUnitId";
  protected readonly QUANTITY_UNITS = "quantityUnits";
  protected readonly OPERATION_SEQUENCE_ID = "operationSequenceId";
  protected readonly OPERATION_SEQUENCES = "operationSequences";

  constructor(data?: Immutable.Map<string, any>) {
    super(data);
  }

  get bomDecorationId(): number { return this.getInternalValue<number>(this.BOM_DECORATION_ID); }
  setBomDecorationId(bomDecorationId: number): this { return this.setInternalValue(this.BOM_DECORATION_ID, bomDecorationId); }

  get id(): number { return this.getInternalValue<number>(this.ID); }
  setId(id: number): this { return this.setInternalValue(this.ID, id); }

  get itemNo(): string { return this.getInternalValue<string>(this.ITEM_NO); }
  setItemNo(itemNo: string): this { return this.setInternalValue(this.ITEM_NO, itemNo); }

  get description(): string { return this.getInternalValue<string>(this.DESCRIPTION); }
  setDescription(description: string): this { return this.setInternalValue(this.DESCRIPTION, description); }

  get revision(): string { return this.getInternalValue<string>(this.REVISION); }
  setRevision(revision: string): this { return this.setInternalValue(this.REVISION, revision); }

  get revisions(): string[] { return this.getInternalValue<string[]>(this.REVISIONS); }
  setRevisions(revisions: string[]): this { return this.setInternalValue(this.REVISIONS, revisions); }

  get quantity(): number { return this.getInternalValue<number>(this.QUANTITY); }
  setQuantity(quantity: number): this { return this.setInternalValue(this.QUANTITY, quantity); }

  get quantityUnitId(): number { return this.getInternalValue<number>(this.QUANTITY_UNIT_ID); }
  setQuantityUnitId(quantityUnitId: number): this { return this.setInternalValue(this.QUANTITY_UNIT_ID, quantityUnitId); }

  get quantityUnits(): Immutable.Map<string, string> { return this.getInternalValue<Immutable.Map<string, string>>(this.QUANTITY_UNITS); }
  setQuantityUnits(quantityUnits: Immutable.Map<string, string>): this { return this.setInternalValue(this.QUANTITY_UNITS, quantityUnits); }

  get operationSequenceId(): number { return this.getInternalValue<number>(this.OPERATION_SEQUENCE_ID); }
  setOperationSequenceId(operationSequenceId: number): this { return this.setInternalValue(this.OPERATION_SEQUENCE_ID, operationSequenceId); }

  get operationSequences(): Immutable.Map<string, string> { return this.getInternalValue<Immutable.Map<string, string>>(this.OPERATION_SEQUENCES); }
  setOperationSequences(operationSequences: Immutable.Map<string, string>): this { return this.setInternalValue(this.OPERATION_SEQUENCES, operationSequences); }
}