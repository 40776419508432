import { Component, Inject, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core";
import { BaseSearchInputComponent } from "../baseSearchInputComponent";
import { InputViewModel } from "../../../../../../shared/components";
import { GlobalDataStore } from "../../../../../shared/providers/globalData";
import { AccountDataStore } from "../../../../../shared/providers/accountData";
import { ModelLabelService } from "../../../../../shared/providers/modelLabelService";
import { ProductDataStore } from "../../../../../shared/providers/productData";
import { Product, Assortment, ConfSearchProperty, LookupParam, ParamValue, ConfDataProperty } from "../../../../../shared/models";
import { ConfSearchCriteria } from "../../../../../shared/models";
import { SearchCriteriaHelper } from "../../providers";
import { SearchCriteriaDataProvider } from "../../providers/searchCriteriaDataProvider";

@Component({
  selector: 'search-dropdown-input',
  templateUrl: './searchDropDownInputComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchDropDownInputComponent extends BaseSearchInputComponent {

  public groupView: InputViewModel;
  public itemsView: Array<InputViewModel> = [];

  constructor(
    protected cdr: ChangeDetectorRef,
    protected globalDataStore: GlobalDataStore,
    protected accountDataStore: AccountDataStore,
    protected productDataStore: ProductDataStore,
    protected searchCriteriaHelper: SearchCriteriaHelper,            
    @Inject(SearchCriteriaDataProvider) protected searchCriteriaDataProvider: SearchCriteriaDataProvider,
    @Inject(ModelLabelService) protected modelLabelService: ModelLabelService,
  ) {
    super(searchCriteriaHelper);
  }

  ngOnInit() {

    // Loop ref objects here and set up views.
    let refClassName = this.criteria.selectedAttribute.refClassName;
    
    if (refClassName) {
      this.groupView = new InputViewModel();
      this.groupView.width = this.width;
      
      this.itemsView = [];
      
      if (refClassName == LookupParam.name) {

        let param = this.criteria.selectedAttribute.tag as LookupParam;
        this.itemsView = [];

        this.groupView.value = null; // Setting to null
        
        param.paramValues.forEach(valueId =>
        {
          
          let lookupValue: ParamValue = this.productDataStore.getEntity(valueId) as ParamValue;

          let itemView: InputViewModel = this.searchCriteriaHelper.createInputView(lookupValue.longId.toString(), lookupValue.name, lookupValue.title, lookupValue.longId == this.extractValue);

          this.itemsView.push(itemView);          

          if (itemView.id == this.extractValue) {

            // Set the group view to show the selected value title.
            this.groupView.formattedValue = itemView.title; // Selected value
            this.groupView.value = itemView.id; // Selected value

          }          
        });
        
      }
      else if (this.accountDataStore.isAccountData(refClassName)) {

        this.accountDataStore.getEntities(refClassName).subscribe((storeResponse) => {
          storeResponse.data.forEach(x => {
            let viewModel = new InputViewModel(this.modelLabelService.getLabel(x));
            viewModel.id = x.longId.toString();
            this.itemsView.push(viewModel);

            // If model contains a value (longId) then try to get its label.
            if (this.criteria.value && this.extractValue == x.longId) {
              this.groupView.value = this.extractValue;
              this.groupView.formattedValue = viewModel.title;
            }
          });

          if (!this.cdr['destroyed'])
            this.cdr.markForCheck();
        });
      }      
      else {
        this.globalDataStore.getEntities(refClassName).subscribe((storeResponse) => {
          storeResponse.data.forEach(x => {
            let viewModel = new InputViewModel(this.modelLabelService.getLabel(x));
            viewModel.id = x.longId.toString();
            this.itemsView.push(viewModel);

            // If model contains a value (longId) then try to get its label.
            if (this.criteria.value && this.extractValue == x.longId) {
              this.groupView.value = this.extractValue;
              this.groupView.formattedValue = viewModel.title;
            }
          });          

          if (!this.cdr['destroyed'])
            this.cdr.markForCheck();
        });
      }
    }
  }


  /** Returns true if the this criteria has a value, otherwise false. */
  public get hasValue(): boolean {
    return !!this.groupView.value;
  }

  /** Returns the current user input value. */
  public get value(): any {
    return this.groupView.value;
  }
  
}