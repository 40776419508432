import * as Immutable from "immutable";

import { ImmutableObject } from "../../../../shared/immutableObject";
import { BaseEntity } from "../../baseEntity";
import { RequestStatus } from "./requestStatus";

export class ProductDataState extends ImmutableObject {

  public readonly ENTITIES = "entities";
  public readonly ENTITY_IDS_BY_CLASS_NAME = "entityIdsByClassName"; 
  public readonly ROOT_ASSORTMENTS = "rootAssortments";

  get entities(): Immutable.Map<number, BaseEntity> { return this.getInternalValue<Immutable.Map<number, BaseEntity>>(this.ENTITIES); }
  setEntities(entities: Immutable.Map<number, BaseEntity>): this { return this.setInternalValue(this.ENTITIES, entities); }

  get entityIdsByClassName(): Immutable.Map<string, Immutable.List<number>> { return this.getInternalValue<Immutable.Map<string, Immutable.List<number>>>(this.ENTITY_IDS_BY_CLASS_NAME); }
  setEntityIdsByClassName(entityIdsByClassName: Immutable.Map<string, Immutable.List<number>>): this { return this.setInternalValue(this.ENTITY_IDS_BY_CLASS_NAME, entityIdsByClassName); }

  get rootAssortments(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.ROOT_ASSORTMENTS); }
  setRootAssortments(rootAssortments: Immutable.List<number>): this { return this.setInternalValue(this.ROOT_ASSORTMENTS, rootAssortments); }
}