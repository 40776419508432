import { Component, Input, EventEmitter, ViewChild, ElementRef, Output, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { MultiSelectItem, ItemStateType } from "./multiSelectModels";
import { MultiSelectEventService, MultiSelectEvent, MultiSelectAction } from "./multiSelectEventService";
import { Subscription } from "rxjs";

@Component({
  selector: "multi-select-item",
  templateUrl: './multiSelectItemComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectItemComponent {

  @Input()
  item: MultiSelectItem;

  @Input()
  showItemIcons: boolean = false;

  @Input()
  iconImageSet: string;

  @Input()
  triState: boolean = false;

  @Input()
  checkedIcon: string;

  @Input()
  uncheckedIcon: string;

  subscription: Subscription;

  constructor(private eventService: MultiSelectEventService, private cd: ChangeDetectorRef) {

  }

  ngOnInit() {

    this.subscription = this.eventService.get().subscribe(info => {

      switch (info.actionType) {

        case MultiSelectAction.DropDownItemRemoved:

          this.cd.markForCheck();

          break;

        case MultiSelectAction.DropdownOpen:

          // TODO..
          break;
      }

    });

  }

  onItemClick(item: MultiSelectItem) {

    this.setNextState(this.item);
    this.updateItemIcon(this.item);

    // Notify to the listeners.
    let event = <MultiSelectEvent>{
      actionType: MultiSelectAction.DropDownItemClick,
      item: item
    };

    this.eventService.sendMessage(event);
    this.cd.markForCheck();
  }

  setNextState(item: MultiSelectItem): void {

    // Comparison before state update.
    if (item.state == ItemStateType.Unchecked) {

      // Set new state.
      item.state = ItemStateType.Checked;
    }

    else if (item.state == ItemStateType.Checked && this.triState) {
      item.state = ItemStateType.Inderminate;
    }

    else {
      item.state = ItemStateType.Unchecked;
    }

    this.updateItemIcon(item);
  }

  updateItemIcon(item: MultiSelectItem): void {

    if (item.state == ItemStateType.Checked) {
      item.icon = this.checkedIcon;
    }

    else if (item.state == ItemStateType.Unchecked) {
      item.icon = this.uncheckedIcon;
    }

    else item.icon = '';
  }

  ngOnDestroy() {

    if (this.subscription)
      this.subscription.unsubscribe();

  }

}