<div id="search">
  
    <div class="d-flex bottom" *ngIf="databaseMode">
      <h1 class="text-uppercase">{{strings.Search}}</h1>
      <span *ngIf="subTitle" class="align-self-end font-italic small pl-2">{{subTitle}}</span>
    </div>
    <div>
      <ng-container *ngIf="databaseMode">
        <search-filter *ngIf="dataLoaded"
                       [configurationSearch]="searchModelCache.searchModel"
                       [searchSessionId]="searchModelCache.searchSessionId"
                       (filterChanged)="filterCriteriaChanged()"
                       (performSearch)="searchDataHandler.performSearch($event)"></search-filter>
      </ng-container>

      <router-outlet></router-outlet>
    </div>

  <!--<div style="width:600px;" >
    <datepicker
                 [(date)]="startDate" (onSelect)="onSelectStartDate($event)" dateFormat="YYYY-MM-DD" [endDate]="endDate" [startDate]="startDate"
                ></datepicker>
  </div>-->
</div>
