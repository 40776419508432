import { Input, Inject, ViewChild, Component } from "@angular/core";
import { UIComponent } from "../../shared";
import { StickableUIData, PageUIData } from "../../shared/state";
import { ConfiguratorUIStore, ConfigurationSessionManager, ConfPageSessionService } from "../providers";
import { BackdropComponent } from "../../../shared/components";

@Component({ template: '' })
export abstract class StickableSideBarComponent extends UIComponent {

  // More styles
  public moreStyles: string = "";

  public ui: StickableUIData;

  @ViewChild(BackdropComponent)
  public backdrop: BackdropComponent;

  constructor(
    public confUIStore: ConfiguratorUIStore,
    public storeSession: ConfPageSessionService) {
    super(confUIStore);
  }

  public ngOnInit(): void {

    // Get the store ui state, so that we could set the default settings.
    this.ui = this.uiState;

    super.ngOnInit();
  }

  public onClosed(): void {
    this.updateVisibility(false);
  }

  public onOpenStart(): void {
    // this.expanded = true;
  }

  public show(): void {
    this.setBackdrop();
    this.backdrop.popup.open();
  }

  public hide(): void {
    this.setBackdrop();
    this.backdrop.popup.close();
  }

  beforeUILoad(uiData: PageUIData): void {
    this.onUIDataChange(uiData);
  }

  onUIDataChange(uiData: PageUIData): void {

    // Update the local data member.
    this.ui = this.uiState;

    this.updateExtraStyles();
    this.toggleTo(this.ui.visible);
  }

  updateExtraStyles(): void {
    this.moreStyles = this.ui.calcSticky ? "inline-dock h-100" : "";
  }

  /**
   * Sets the backdrop only If composite manager is docked inline.
   * TODO: Need more refactoring
   */
  setBackdrop(): void {

    if (this.backdrop) {

      // TODO: Need more investigation -> why it is coming undefined.
      if (this.ui.calcSticky == undefined)
        return;

      // If not sticky (i.e floated) and visible then show the backdrop.
      this.backdrop.visible = !this.ui.calcSticky && this.ui.visible;
    }
  }

  /**
   * Toggles the sticky option
   */
  public toggleSticky(): void {
  }


  /**
   * Toggles the visibility
   */
  public toggleVisibility(event): void {

    // Toggle the visibility
    // TODO: Need more refactoring
    this.updateVisibility(!this.ui.visible);
  }

  /**
   * Toggles the visibility to target value.
   * @param targetValue
   */
  toggleTo(targetValue: boolean): void {

    // Temporary timeout check.
    // TODO: Need more refactoring
    setTimeout(() => {

      if (targetValue == undefined || this.backdrop == undefined)
        return;

      this.setBackdrop();
      this.backdrop.popup.toggleTo(targetValue);

    }, 100);
  }

  get pageId(): number {
    return this.storeSession.pageId;
  }

  // Returns corresponding UI State.  
  abstract get uiState(): StickableUIData;

  /**
   * Updates the ui state.
   * @param sticky
   * @param visible
   */
  abstract updateVisibility(visible: boolean): void;

  abstract updateUserSticky(userSticky: boolean): void;

}