import { ImmutableObject } from "../../../../shared/immutableObject"

export class UserSettings extends ImmutableObject {

  protected readonly HIGHLIGHT_MANDATORY_PARAMETERS = "highlightMandatoryParameters";
  protected readonly COMPOSITE_CONTROL_STICKY = "compositeControlSticky";
  protected readonly IS_CONF_EMAIL_MANDATORY = "isConfEmailMandatory";

  get highlightMandatoryParameters(): boolean { return this.getInternalValue<boolean>(this.HIGHLIGHT_MANDATORY_PARAMETERS); }
  setHighlightMandatoryParameters(highlightMandatoryParameters: boolean): this { return this.setInternalValue(this.HIGHLIGHT_MANDATORY_PARAMETERS, highlightMandatoryParameters); }

  get compositeControlSticky(): boolean { return this.getInternalValue<boolean>(this.COMPOSITE_CONTROL_STICKY); }
  setCompositeControlSticky(compositeControlSticky: boolean): this { return this.setInternalValue(this.COMPOSITE_CONTROL_STICKY, compositeControlSticky); }

  get isConfEmailMandatory(): boolean { return this.getInternalValue<boolean>(this.IS_CONF_EMAIL_MANDATORY); }
  setIsConfEmailMandatory(isConfEmailMandatory: boolean): this { return this.setInternalValue(this.IS_CONF_EMAIL_MANDATORY, isConfEmailMandatory); }
}