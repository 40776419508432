import { ImmutableObject } from "../../../../shared";
import * as Immutable from "immutable";

export class ConfUIItem extends ImmutableObject {
    
  protected readonly ID = "id";
  protected readonly ITEM_NAME = "itemName";
  protected readonly ITEMS = "items";

  get id(): number { return this.getInternalValue<number>(this.ID); }
  setId(id: number): this { return this.setInternalValue(this.ID, id); }

  get itemName(): string { return this.getInternalValue<string>(this.ITEM_NAME); }
  setItemName(itemName: string): this { return this.setInternalValue(this.ITEM_NAME, itemName); }

  get items(): Immutable.List<ConfUIItem> { return this.getInternalValue<Immutable.List<ConfUIItem>>(this.ITEMS); }
  setItems(items: Immutable.List<ConfUIItem>): this { return this.setInternalValue(this.ITEMS, items); }
}