import * as Immutable from "immutable";

import { ImmutableObject } from "../../../../shared/immutableObject";

export class LocalizeData extends ImmutableObject {

  protected readonly LANGUAGE_TITLE = "languageTitle";
  protected readonly LANGUAGE_CODE = "languageCode";
  protected readonly STRINGS = "strings";

  get languageTitle(): string { return this.getInternalValue<string>(this.LANGUAGE_TITLE); }
  setLanguageTitle(languageTitle: string): this { return this.setInternalValue(this.LANGUAGE_TITLE, languageTitle); }

  get languageCode(): string { return this.getInternalValue<string>(this.LANGUAGE_CODE); }
  setLanguageCode(languageCode: string): this { return this.setInternalValue(this.LANGUAGE_CODE, languageCode); }

  get strings(): Immutable.Map<string, string> { return this.getInternalValue<Immutable.Map<string, string>>(this.STRINGS); }
  setStrings(strings: Immutable.Map<string, string>): this { return this.setInternalValue(this.STRINGS, strings); }
}