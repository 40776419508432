import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import * as Immutable from "immutable";
import { InputViewModel, ValueChangeEventArgs } from "../../../../../shared/components";
import { NotificationInfo, NotificationService, NotificationType } from "../../../../../shared/components/notification";
import { BaseComponent } from "../../../../shared";
import { CopyDestinationMessage, CopyOperationMessage } from "../../../../shared/models/responses/messages";
import { ConfDataActionCreator } from "../../../../shared/providers/configurationData";
import { ConfiguratorStore, ConfMessageProvider, ConfPageSessionService } from "../../../providers";

@Component({
  selector: 'copy-configuration',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './copyConfigurationComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyConfigurationComponent extends BaseComponent {

  public itemsView: Array<InputViewModel> = [];
  public dropdownView: InputViewModel;
  public errorMessages: Array<string>;

  @Output()
  public onCancel = new EventEmitter<any>();

  @Input()
  public configurationId: number;

  public noOfCopiesView: InputViewModel;

  public destinationId: number;

  constructor(
    @Inject(ConfPageSessionService) public confPageSession: ConfPageSessionService,
    @Inject(ConfMessageProvider) public confMessageProvider: ConfMessageProvider,
    @Inject(ConfDataActionCreator) public confActionCreator: ConfDataActionCreator,
    @Inject(NotificationService) public notificationService: NotificationService,
    @Inject(ConfiguratorStore) public configuratorStore: ConfiguratorStore,
    @Inject(ChangeDetectorRef) public cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.dropdownView = new InputViewModel(this.strings.Configuration);
    this.dropdownView.icon = "product";

    this.noOfCopiesView = new InputViewModel(this.strings.Count);
    this.noOfCopiesView.value = 1;

    // Copy configuration message
    this.confMessageProvider.onMessagesRequest<CopyOperationMessage>(this.confPageSession.confSessionId, CopyOperationMessage.name, {
      listenNewEventsOnly: true, next: (messages: Immutable.List<CopyOperationMessage>) => {

        if (messages.size > 0) {
          let copyMessage = messages.get(0);
          if (copyMessage.success && copyMessage.newConfIds != null && copyMessage.newConfIds.size > 0) {
            this.notificationService.notify(<NotificationInfo>{
              title: copyMessage.title || copyMessage.displayStyle ? copyMessage.title : this.strings.CopySuccessful,
              message: this.format(this.strings.XConfigurationsCreatedSuccessfully, copyMessage.newConfIds.size),
              type: copyMessage.displayStyle ? copyMessage.displayStyle : NotificationType.Success,
              selfClose: true
            });

            // Close the copy form.
            this.onCancel.emit();
          }
          else if (!copyMessage.success) {
            this.notificationService.notify(<NotificationInfo>{
              title: copyMessage.title || copyMessage.displayStyle ? copyMessage.title : this.strings.Error,
              message: copyMessage.message,
              type: copyMessage.displayStyle ? copyMessage.displayStyle : NotificationType.Error,
              selfClose: false
            });
          }
        }
      }
    }).unsubscribeOn(this.unsubscribeSubject);
    this.loadDestinations();
  }

  loadDestinations(): void {
    // Dispatch request for destinations   
    this.configuratorStore.requestCopyDestinations(this.confPageSession.activeConfigurationId, this.confPageSession.confSessionId);

    // Listen the message for destinations
    this.confMessageProvider.onMessagesRequest<CopyDestinationMessage>(this.confPageSession.confSessionId, CopyDestinationMessage.name, {
      listenNewEventsOnly: true, next: (messages: Immutable.List<CopyDestinationMessage>) => {
        messages.get(0).confInfos.forEach(x => {
          const itemView = new InputViewModel();
          itemView.title = x.text;
          itemView.value = x.longId;
          itemView.id = x.longId.toString();
          this.itemsView.push(itemView);
          this.dropdownView.formattedValue = x.text;
          this.dropdownView.value = '' + x.longId;
        });

        this.itemsView = [...this.itemsView];
        this.cdr.markForCheck();
      }
    }).unsubscribeOn(this.unsubscribeSubject);


  }

  makeCopies(): void {
    this.errorMessages = [];
    if (this.noOfCopiesView.value === null || parseInt(this.noOfCopiesView.value) === 0) {
      this.errorMessages.push(this.strings.TheFormMustBeFilledOut);
    } else {
      const copies = parseInt(this.noOfCopiesView.value);

      // TODO: Need more work on dropdownlist component it does not trigger the default set value that's why
      // we are reading value through component. 
      this.destinationId = !this.destinationId ? Number(this.dropdownView.value) : this.destinationId;

      this.configuratorStore.copyConfiguration(this.configurationId, this.destinationId, copies, this.confPageSession.confSessionId);
    }
  }

  onValueChange(event: ValueChangeEventArgs) {

    this.destinationId = Number(event.actionView.id);

  }

}