import { Component, Input, ViewChild, Inject, SimpleChanges, Output, EventEmitter } from "@angular/core";
import * as Immutable from "immutable";

import { BomColumnSetting } from "../../../shared/models";
import { BaseComponent } from "../../../shared";
import { Grid } from "../../../shared/models/responses";
import { GlobalDataStore } from "../../../shared/providers/globalData";
import { DataRowView, DataCellView } from "../../../../shared/components";

@Component({
  selector: 'bom-grand-total',
  templateUrl: './bomGrandTotalComponent.html',
})
export class BomGrandTotalComponent extends BaseComponent {

  @Input()
  public grid: Grid;

  public columnSettings: Immutable.List<BomColumnSetting>;

  public rows: Array<DataRowView> = [];
  public columns: Array<DataCellView> = [];

  constructor(
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {        
    if (changes['grid'] && this.grid) {
        this.createTable()
    }
  }

  createTable(): void {
    this.rows = [];
    this.columns = [];

    if (!this.columnSettings)
      this.columnSettings = this.globalDataStore.getGlobalData().globalSettings.bomGrandTotalColumnSettings;

    // GrandTotal is sent as one row containing multiple values.
    let grandTotal = this.grid.rows.first();
    if (grandTotal) {
      // Create a row for each grandtotal value
      grandTotal.values.forEach((value: string, property: string) => {
        let rowView: DataRowView = { cells: [], classes: "" };
        let titleCell: DataCellView = {
          contents: this.columnSettings.find(x => x.property == property).title,
          classes: 'ellipsis-wrap text-left'
        };
        rowView.cells.push(titleCell);

        let valueCell: DataCellView = {
          contents: value,
          classes: 'ellipsis-wrap text-left'
        };
        rowView.cells.push(valueCell);
        this.rows.push(rowView);
      });

      let headerColumn: DataCellView = {
        contents: this.strings.GrandTotal,
        classes: '',
      };
      this.columns.push(headerColumn);
    }
  }
}