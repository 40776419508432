import { ImmutableObject } from "../../../../shared/immutableObject";
import { StickableUIData } from "./stickableUIData";

export class SeparatedTabUIData extends StickableUIData {

  public readonly TAB_ID = "tabId";
  public readonly CONF_ID = "confId";

  get tabId(): number { return this.getInternalValue<number>(this.TAB_ID); }
  setTabId(tabId: number): this { return this.setInternalValue(this.TAB_ID, tabId); }

  get confId(): number { return this.getInternalValue<number>(this.CONF_ID); }
  setConfId(confId: number): this { return this.setInternalValue(this.CONF_ID, confId); }

}