import { BaseRequest } from "./baseRequest";
import { LocalizeCommand } from "./commands/localizeCommand";
import { GlobalSettingsCommand } from "./commands/globalSettingsCommand";
import { GlobalEntitiesCommand } from "./commands/globalEntitiesCommand";

export class GlobalDataRequest extends BaseRequest {

  globalSettings?: GlobalSettingsCommand;
  localize?: LocalizeCommand;
  entities?: GlobalEntitiesCommand;
}