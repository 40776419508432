import { Injectable, Inject } from '@angular/core';
import { combineReducers } from 'redux';
import * as Immutable from "immutable";

import { AppAction } from "../shared/appAction";
import { SearchSessionData } from "../shared/searchSessionData";
import { PushMessageState } from "../shared/pushMessageState";
import { PushMessageActions } from "./pushMessageActions";
import { Actions } from "../shared/actions";
import { ConfigurationSearch, ApiResponse, KeyValue, ServerPushMessage } from "../../models";
import { PushMessageSelection } from '../../providers/pushMessage/pushMessageSelection';

@Injectable()
export class PushMessageReducer {

  public getReducer() {

    let defaultState = new PushMessageState();
    defaultState = defaultState.setValuesById(Immutable.Map<number, Immutable.Map<string, string>>());
    defaultState = defaultState.setServerPushMessages(Immutable.Map<number, ServerPushMessage>());

    return (state = defaultState, action: AppAction<any>): PushMessageState => {

      if (action.type == Actions.CLEAR_CLIENT_CACHE) {
        return defaultState;
      }

      if (!(action.payload instanceof PushMessageSelection))
        return state;

      let pushMessageSelection = action.payload as PushMessageSelection;

      // Don't set a value without a key.
      if (!pushMessageSelection.key)
        return state;

      if (action.type == Actions.PUSH_MESSAGE_SELECTION) {

        let pushMessageSessionData = state.valuesById.get(pushMessageSelection.sessionId);
        if (!pushMessageSessionData) {

          pushMessageSessionData = Immutable.Map<string, string>();
        }

        pushMessageSessionData = pushMessageSessionData.set(pushMessageSelection.key, pushMessageSelection.value);

        let newValues = state.valuesById.set(pushMessageSelection.sessionId, pushMessageSessionData);

        state = state.setValuesById(newValues);
      }

      return state;
    }
  }
}