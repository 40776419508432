import { Component, Input, OnInit } from "@angular/core";
import * as Immutable from "immutable";
import { DataCellView, DataRowView, InputViewModel } from "../../../../shared/components/shared";
import { BaseComponent } from "../../../shared";
import { PagerModel } from "../../../shared/components/pager/pagerModel";
import { ConfDataSelectorValue, DataSelectColumn, DataSelector, Grid, SearchInfo} from "../../../shared/models";
import { GridRow } from "../../../shared/models/responses";
import { ProductDataStore } from "../../../shared/providers/productData";
import { FilterItemViewModel } from "../../components/filter/filterItemViewModel";
import { VisualObjectHelper } from "../../parameters/shared";
import { ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { ConfMessageProvider } from "../../providers/confMessageProvider";
import { DataSelectorStore } from "./dataSelectorStore";
import { GlobalDataStore } from "../../../shared/providers/globalData";
import { PagerService } from "../../../shared/components/pager/pagerService";

// TODO maybe combine this with BomTable.
@Component({ template: '' })
export abstract class AbstractDataSelectorGridComponent extends BaseComponent implements OnInit {

  @Input()
  decoration: DataSelector;

  @Input()   
  confValue: ConfDataSelectorValue;

  public width: string;

  public pagerModel: PagerModel;
  public readonly defaultDisplayCount = 10;

  public filterItems: Array<InputViewModel> = [];

  /** UI rows */
  public rows: Array<DataRowView> = [];
  /** UI columns */
  public columns: Array<DataCellView> = [];
  public selectedValue: GridRow;

  /** All grid rows, stored as reference */
  public gridRows: Immutable.List<GridRow>;

  public showFilter = false;
  public isFiltered = false;
  public filterVisible = false;

  public refreshPager = false;

  public searchInfo: SearchInfo;

  public descending = false;
  public sortKey = null;

  constructor(
    public confMessageProvider: ConfMessageProvider,
    public dataSelectorStore: DataSelectorStore,
    public productStore: ProductDataStore,
    public confStore: ConfiguratorStore,
    public storeSession: ConfPageSessionService,
    public visualObjectHelper: VisualObjectHelper,
    public globalDataStore: GlobalDataStore,
    public pagerService: PagerService
  ) {
    super();

    this.pagerModel = this.pagerService.create(10, 1, null, null, ["5", "10", "20", "50", "100", "200", this.strings.All]);
  }

  ngOnInit() {

    this.filterVisible = this.globalDataStore.globalSettings.dataSelectorFilterControlsVisible;

    if (this.visualObjectHelper.isVisualObjectInAccordion(this.decoration)) {
      this.width = "100%";
    }
    else {
      if (!this.decoration.showInline) {
        this.width = this.decoration.dialogWidth ? this.decoration.dialogWidth + 'px' : this.uiSettings.configurator.decoration.dataSelector.dialogWidth;
      }
    }

    if (this.decoration && this.decoration.pageSize)
      this.pagerService.updatePageSizes(this.decoration.pageSize, this.pagerModel);
  }

  protected setGridData(gridData: Grid) {
    this.createTable(gridData);

    this.pagerService.update(gridData.pagination, this.pagerModel);
    this.refreshPager = !this.refreshPager;

    // Update search info. Could be changed from server.
    this.searchInfo = gridData.search;
    this.isFiltered = this.searchInfo != null;
    this.showFilter = this.showFilter && this.searchInfo != null;
  }

  createTable(gridData: Grid): void {
    this.rows = [];
    this.columns = [];
    this.filterItems = [];
    this.gridRows = gridData.rows;


    // First set up columns
    let dataSelectColumns: Array<DataSelectColumn> = [];

    this.productStore.getEntity<DataSelector>(this.decoration.longId).columns.forEach(columnId => {
      let column = this.productStore.getEntity<DataSelectColumn>(columnId);
      dataSelectColumns.push(column);

      if (!column.isFilterSupported)
        return;

      let filterColumn = new FilterItemViewModel(column.title);
      filterColumn.id = column.longId.toString();
      filterColumn.type = column.type;
      this.filterItems.push(filterColumn);
    });

    if (gridData.rows.first() == null)
      return;

    // Get the columns in the correct order
    let sortedColumns = dataSelectColumns.sort((x, y) => x.order - y.order);

    let sortedKey = gridData.sorting?.key;
    let descending = gridData.sorting?.descending;

    sortedColumns.forEach((column: DataSelectColumn) => {
      let columnView: DataCellView = {
        id: ''+column.longId,
        contents: column.title,
        classes: `text-center ellipsis clickable ${sortedKey == ''+column.longId ? descending ? 'caret-down' : 'caret-up' : ''}`,
        width: column.width > 0 ? column.width : null,
        safeStyle: this.columnStyle(column.width, dataSelectColumns)
      };
      this.columns.push(columnView);
    });

    // Create a row model for each gridrow
    gridData.rows.forEach(rowData => {
      let setValue = this.confValue.keyId === rowData.id;
      let rowView: DataRowView = {
        cells: [],
        classes: `useHover clickable ${setValue ? 'setValue' : ''}`,
        id: rowData.id.toString()
      };

      sortedColumns.forEach((column: DataSelectColumn) => {
        let cellValue = rowData.values.get(column.longId.toString());

        // link columns have different style and behaviour.
        let valueCell: DataCellView = {
          id: column.longId.toString(),
          classes: `ellipsis py-2 text-${column.alignment} justify-content-${column.alignment.replace("left","start").replace("right","end")}`,
          width: column.width > 0 ? column.width : null,
          safeStyle: this.columnStyle(column.width, dataSelectColumns),
          contents: column.hyperLink && !column.image ? null : cellValue,
          image: column.image ? cellValue : null,
          href: column.hyperLink ? cellValue : null,
        };
        rowView.cells.push(valueCell);
      });

      this.rows.push(rowView);
    });
  }

  /**  Calculate the column width */
  protected columnStyle(columnWidth: number, dataSelectColumns: Array<DataSelectColumn>) {
    let totalWidth = 0;
    let noOfZeroWidthColumns = 0;
    dataSelectColumns.forEach((column: DataSelectColumn, index: number) => {
      if (column.width <= 0)
        noOfZeroWidthColumns++;
      else
        totalWidth += column.width;
    });

    if (totalWidth == 0 && noOfZeroWidthColumns > 0)
      return { "width": 100 / noOfZeroWidthColumns + "%" };

    let defaultWidth = totalWidth / dataSelectColumns.length;

    if (columnWidth == null || columnWidth <= 0)
      columnWidth = defaultWidth;

    totalWidth = totalWidth + (noOfZeroWidthColumns * defaultWidth);
    columnWidth = (columnWidth / totalWidth) * 100;

    return { "width": columnWidth + "%" };
  }

  public trackRow(index: number, row: DataRowView): any {
    return row.id;
  }

  public abstract handleSetSelectedValueError();
  public abstract handleSetSelectedValueSuccess();

  public setSelectedValue() {
    if (this.selectedValue != null) {
      this.dataSelectorStore.setDataSelectorValue(this.storeSession.activeConfigurationId, this.storeSession.confSessionId, this.decoration.visualObjectId, this.selectedValue.id);
    }
  }

  public refreshPage(clearSearch: boolean = false) {
    this.dataSelectorStore.dispatchGetRows(this.storeSession.activeConfigurationId, this.storeSession.confSessionId, this.decoration.visualObjectId, clearSearch ? null : this.pagerModel.displayCountView.displayAll ? 0 : this.pagerModel.displayCountView.count,
      clearSearch, this.pagerModel.currentPage, this.sortKey, this.descending);
  }

  public columnClick(column: DataCellView) {
    let key = column.id;

    if (this.sortKey == key)
      this.descending = !this.descending;
    else
      this.descending = false;

    this.sortKey = key;

    this.dataSelectorStore.dispatchGetRows(this.storeSession.activeConfigurationId, this.storeSession.confSessionId, this.decoration.visualObjectId, this.pagerModel.displayCountView.displayAll ? 0 : this.pagerModel.displayCountView.count,
      false, this.pagerModel.currentPage, this.sortKey, this.descending);
  }

  public onValueClick(value: DataRowView) {
    let id = parseInt(value.id);
    this.selectedValue = this.gridRows.find(x => x.id === id);
  }

  public toggleShowFilter() {
    this.showFilter = !this.showFilter;
  }

  public onFilter(event) {
    let column = parseInt(event.filterItem.value);
    let value1 = event.value1;
    let value2 = event.value2;
    let filterType = event.filterType;

    this.dataSelectorStore.searchDataSelectorValues(this.storeSession.activeConfigurationId, this.storeSession.confSessionId, this.decoration.visualObjectId, column, value1, value2, filterType)

    this.isFiltered = true;
  }

  public onCancelFilter() {
    this.showFilter = false;
  }

  public clearFilter() {
    this.showFilter = false;
    this.dataSelectorStore.clearDataSelectorSearch(this.storeSession.activeConfigurationId, this.storeSession.confSessionId, this.decoration.visualObjectId);
    this.pagerModel.currentPage = 1;
    this.isFiltered = false;
  }
}