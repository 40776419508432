<regular-popup *ngIf="visible"
               [id]="id"
               tabindex="-1"
               [width]="helpWidth"
               [draggable]="true"               
               referenceContainerId="configuratorContainer"
               [title]="strings.Help"
               [okButtonText]="strings.Ok"
               [cancelButtonText]="strings.Cancel"
               (onClosed)="onPopupClosed()"
               [classes]="isIE ? 'is-ie' : ''">
  <!--Body part-->
  <div card-type="body" *ngIf="parameter != null">
    <accordion [openFirst]="defaultValue == null" #helpAccordion
               classes="accordion">
      <accordion-group id="helpParameter"
                       [heading]="parameter.title"                       
                       icon="info"                       
                       [darkTheme]="true"
                       [iconIsVault]="false"
                       [togglable]="values.length > 0">
        <div class="m-4">
          <span class="multiline" [innerHtml]="parameter.helpText | safeHtml"></span>
          <div class="mt-4 text-center w-100">
            <co-image *ngIf="parameter.helpImageRelativeUrl" [key]="parameter.helpImageRelativeUrl" isVault="true"></co-image>
          </div>
          <div class="mt-4">
            <a *ngIf="parameter.externLink" href="{{getExternLink(parameter)}}" target="_blank">{{getExternLink(parameter)}}</a>
          </div>
        </div>
      </accordion-group>
      <accordion-group *ngFor="let value of values"
                       id="helpValues"
                       [scrollInToView]="true"
                       [heading]="value.title"
                       [isOpened]="value.longId == defaultValue">
        <div class="m-4">
          <span class="multiline" [innerHtml]="value.helpText | safeHtml"></span>
          <div class="mt-4 text-center w-100">
            <co-image *ngIf="value.helpImageRelativeUrl" [key]="value.helpImageRelativeUrl" isVault="true"></co-image>
          </div>
          <div class="mt-4">
            <a *ngIf="value.externLink" href="{{getExternLink(value)}}" target="_blank">{{getExternLink(value)}}</a>
          </div>
        </div>
      </accordion-group>
    </accordion>
  </div>
</regular-popup>