import * as Immutable from "immutable";

import { ConfUIItem, Conf } from "../../shared/models";
import { Injectable } from "@angular/core";

@Injectable()
export abstract class AbstractUIVisibilityService {

  public findConfUIItem(conf: Conf, longId: number): ConfUIItem;  
  public findConfUIItem(conf: Conf, longId: number, uiItemNamesToRecurse: string[]): ConfUIItem;  
  public findConfUIItem(conf: Conf, longId: number, uiItemNamesToRecurse: string[], uiItemNameToMatch: string): ConfUIItem
  public findConfUIItem(conf: Conf, longId: number, uiItemNamesToRecurse?: string[], uiItemNameToMatch?: string): ConfUIItem
  {
    if (!conf || !longId)
      return null;
    
    let ignoreUIItemNameCheck: boolean = false;
    if (!uiItemNameToMatch)
      ignoreUIItemNameCheck = true;
        
    return this.recursivelyFindConfUIItem(conf.uiItems, longId, uiItemNamesToRecurse, ignoreUIItemNameCheck, uiItemNameToMatch);
  }
  
  private recursivelyFindConfUIItem(confUIItems: Immutable.List<ConfUIItem>, longId: number, uiItemNamesToRecurse: string[], ignoreUIItemNameCheck: boolean, uiItemNameToMatch: string): ConfUIItem
  {
    for (let i = 0; i < confUIItems.size; i++)
    {
      let confUIItem: ConfUIItem = confUIItems.get(i);
      let uiItemName = confUIItem.itemName;
      
      if ((ignoreUIItemNameCheck || uiItemName === uiItemNameToMatch) && confUIItem.id == longId)
        return confUIItem;      

      if (!uiItemNamesToRecurse || uiItemNamesToRecurse.indexOf(uiItemName) > -1) {
        let result = this.recursivelyFindConfUIItem(confUIItem.items, longId, uiItemNamesToRecurse, ignoreUIItemNameCheck, uiItemNameToMatch);
        if (result)
          return result;
      }      
    }

    return null;
  }
}