import * as Immutable from "immutable";

import { AbstractConfigurationMessage } from "./abstractConfigurationMessage";
import { BomAutoCompleteRow } from "../bomAutoCompleteRow";

export class BomAutoCompleteMessage extends AbstractConfigurationMessage {

  protected readonly AUTOCOMPLETE_MODEL = "data";
  protected readonly ROWS = "rows";
  protected readonly QUANTITY_UNITS = "quantityUnits";
  protected readonly OPERATION_SEQUENCES = "operationSequences";

  constructor(data?: Immutable.Map<string, any>) {
    super(data);
  }

  get autoCompleteRowModels(): Immutable.List<BomAutoCompleteRow> { return this.getInternalValue<Immutable.List<BomAutoCompleteRow>>(this.ROWS); }
  setAutoCompleteRowModels(autoCompleteRowModels: Immutable.List<BomAutoCompleteRow>): this { return this.setInternalValue(this.ROWS, autoCompleteRowModels); }

  get quantityUnits(): Immutable.Map<string, string> { return this.getInternalValue<Immutable.Map<string, string>>(this.QUANTITY_UNITS); }
  setQuantityUnits(quantityUnits: Immutable.Map<string, string>): this { return this.setInternalValue(this.QUANTITY_UNITS, quantityUnits); }

  get operationSequences(): Immutable.Map<string, string> { return this.getInternalValue<Immutable.Map<string, string>>(this.OPERATION_SEQUENCES); }
  setOperationSequences(operationSequences: Immutable.Map<string, string>): this { return this.setInternalValue(this.OPERATION_SEQUENCES, operationSequences); }
}