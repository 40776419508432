import { Component, OnInit, ViewChild, Output, ElementRef, EventEmitter, Input, OnDestroy, AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { OverlayRef } from '@angular/cdk/overlay';
import { OverlayDatepickerService } from '../providers/overlayDatepickerService';
import { RangeDatepickerService, DATE } from '../providers/rangeDatepickerService';
import { Range, DateRangeOptions, CalendarDialogConfig } from '../models/model';
import { Subscription } from 'rxjs';

import { ConfigDatepickerService } from '../providers/configDatepickerService';
import { PresetService } from '../providers/presetService';
import { Element } from '@angular/compiler';
import { DateValueModel } from "../..";

@Component({
  selector: 'date-range-picker',
  templateUrl: './inputDatepickerFieldComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ OverlayDatepickerService,
    RangeDatepickerService,
    PresetService,
    ConfigDatepickerService,
    DatePipe,
      { provide: DATE, useValue: new Date() }]
})
export class InputDatepickerFieldComponent implements OnInit, OnDestroy, AfterViewChecked {
  
  @ViewChild('matFormField')
  protected matFormField: ElementRef;  

  @Output()
  protected readonly selectedDateRangeChanged: EventEmitter<DateValueModel> = new EventEmitter<DateValueModel>();

  @Input()
  protected options: DateRangeOptions;

  @Input()
  public width: string;

  @Input()
  protected showPresetLabel: boolean = true;

  @Input()
  public imageSet: string;

  protected rangeUpdateSubscription: Subscription;
  public displayText: string = "";
  public showClearOption: boolean = false;

  constructor(
    protected changeDetectionRef: ChangeDetectorRef,
    protected calendarOverlayService: OverlayDatepickerService,
    protected rangeDatepickerService: RangeDatepickerService,
    protected configStoreService: ConfigDatepickerService,        
    protected datePipe: DatePipe
  ) {
    
  }

  ngOnInit() {
    this.configStoreService.dateRangeOptions = this.options;
    
    this.rangeUpdateSubscription = this.rangeDatepickerService.rangeUpdateSubscription.subscribe(
      (valueModel) => {
        
        this.displayText = valueModel.displayText;
        this.showClearOption = valueModel.range != null; // If range is defined then show the 'clear value' icon.
        this.selectedDateRangeChanged.emit(valueModel);
      }
    );

    this.rangeDatepickerService.updateRange(this.valueModel(this.options.range.fromDate, this.options.range.toDate));
    this.changeDetectionRef.detectChanges();
  }

  ngOnDestroy() {
    this.rangeUpdateSubscription.unsubscribe();
  }

  private formatToDateString(date: Date, format: string): string {
    return this.datePipe.transform(date, format);
  }

  ngAfterViewChecked() {

  }

  openCalendar(event) {

    let calenderOptions = <CalendarDialogConfig>{ minWidth: this.matFormField.nativeElement.offsetWidth };

    this.configStoreService.calendarDialogConfig = calenderOptions;
    const overlayRef: OverlayRef = this.calendarOverlayService.open(calenderOptions, this.matFormField);

  }

  /**
   * TODO: This method is not used right now, but later we will use this for input field when showing simple datepicker.
   * @param event
   */
  valueChanged(event) {

    let [fromDateStr, toDateStr] = event.target.value.split("-");
    if (fromDateStr && toDateStr) {
      const from = new Date(fromDateStr);
      const to = new Date(toDateStr);      
      this.rangeDatepickerService.updateRange(this.valueModel(from, to));
    }
    else {      
      this.rangeDatepickerService.updateRange(this.valueModel());      
    }

  }

  clearDate(event: Event) {
    event.stopImmediatePropagation();
    this.rangeDatepickerService.updateRange(this.valueModel());
  }

  valueModel(from?: Date, to?: Date): DateValueModel {
    return this.configStoreService.createDateValueModel(from, to);
  }

}