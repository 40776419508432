import { Component, Input, ViewEncapsulation } from "@angular/core";
import { BaseComponent } from "../../../../../core/pages/shared";
import { KeyValue } from "../../../../../core/pages/shared/models";
import { IconTools } from "../../../../../core/pages/shared/providers";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'comparison-value',
  templateUrl: './comparisonValueComponent.html'
})
export class ComparisonValueComponent extends BaseComponent {


  @Input()
  valueType: string

  @Input()
  titleAndValue: KeyValue<string, string>;

  @Input()
  valueClass: string;

  imageKey: string;
  imageSet: string;

  constructor(protected iconTools: IconTools) {
    super();
  }

  ngOnInit() {

    if (this.valueType == 'Boolean') {
      let value: boolean = this.titleAndValue.value == null ? null : this.titleAndValue.value == 'True';
      this.imageKey = this.iconTools.boolValueIcon(value);
      this.imageSet = this.iconTools.boolValueTheme(value);
    }

  }

}