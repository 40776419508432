<action-box *ngIf="visible"
            class="code-detail detail-item {{extraStyles}}"
            iconLeft="code"
            textLeft="{{majorTitle}}"
            [showHeader]="showHeader">
  <gridview *ngIf="contentVisible">
    <gridview-row>
      <columntemplate>

        <decoration-codes-builder *ngFor="let model of valuesModels" [valuesModel]="model" [showInlineTitle]="showInlineTitle" ></decoration-codes-builder>

      </columntemplate>
    </gridview-row>
  </gridview>
  <div card-type="control" *ngIf="isPrintView">
    <print-checkbox (onValueChange)="onPrintValueChange($event)"></print-checkbox>
  </div>
</action-box>