import { Inject, Component, ViewChild, Input, ChangeDetectorRef, SimpleChanges, ChangeDetectionStrategy, Output, EventEmitter } from "@angular/core";
import * as Immutable from "immutable";
import { PriceReportMessage, PriceReportCategory, PriceReportItem, PriceReportChildTotal } from "../../models/responses/messages";
import { PopupService } from "../../../../shared/components/popup";
import { PriceReportRowView, PriceReportCellView } from "./priceReportRowView";
import { ConfigurationSessionManager } from "../../../configurator/providers/configurationSessionManager";
import { ConfInfo, RequestViews, KeyValue } from "../../models";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import { ConfiguratorStore, ConfPageSessionService, PopupIdentifiers } from "../../../configurator/providers";
import { BaseComponent } from "../..";
import { PriceEditorService, IPriceEditorCommandArgs, PriceEditorActions } from "./components/priceEditorService";
import { GlobalDataStore } from "../../providers/globalData";

@Component({
  selector: 'price-list-report',
  templateUrl: './priceListReportComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PriceEditorService]
})
export class PriceListReportComponent extends BaseComponent {

  @Input()
  public report: PriceReportMessage;

  public clearedPrices: Immutable.List<KeyValue<string, number>> = Immutable.List<KeyValue<string, number>>();

  @Input()
  public errorReportsConfIds: Immutable.List<number> = Immutable.List<number>();

  public rowViews: PriceReportRowView[] = [];

  public childrenRowViews: PriceReportRowView[] = [];
  public grandTotalView = new PriceReportRowView();

  public reportTitle: string;
  public reportCategoryName: string;
  public currencyName: string;
  public showErrorMessage: boolean = false;

  // It helps in css to prevent hover effect for all other rows if the gridview is already in edit mode.
  public isGridViewInActionMode: boolean = false;
 
  readonly CATEGORY_STYLE: string = "category align-middle ";
  readonly TRANSIENT_CATEGORY_STYLE: string = "transient-category ";
  readonly ITEM_ROW_STYLE: string = "item-row";
  readonly CHILD_STYLE: string = "child clickable align-middle ";
  readonly ITEM_TITLE_STYLE: string = "item align-middle ";
  readonly ITEM_PRICE_MANUAL_STYLE: string = "item-price text-right col-auto pr-3";
  readonly ITEM_PRICE_STYLE: string = "text-right col-auto pr-3";
  readonly CHILD_ITEM_STYLE: string = "pr-4 justify-content-end text-right text-break";
  readonly CHILD_COLUMN_SIZE: number = 3;
  readonly CHILD_COLUMN_SIZE_LG: string = "col-lg-2";
  readonly SUB_TOTAL_VALUE_STYLE: string = "item-price text-right pr-3 col-auto ";
  readonly SUB_TOTAL: string = "sub-total align-middle ";
  readonly TOTAL_STYLE: string = "total align-middle ";
  readonly GRAND_TOTAL_STYLE: string = "grand-total align-middle ";
  readonly GRAND_TOTAL_ITEM_STYLE: string = "align-middle text-right col-auto pr-3";
  readonly HAS_ITEMS: string = "has-items ";

  @Output()
  public childReportClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    @Inject(PopupService) public popupService: PopupService,
    @Inject(PriceEditorService) public priceEditorService: PriceEditorService,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    public cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (!changes['report'].firstChange) {
      this.update(this.storeSession.activeConfigurationId);
    }

  }

  ngOnInit(): void {        
    this.update(this.storeSession.activeConfigurationId);
    super.ngOnInit();
  }
 
  update(confId: number): void {
    let confInfo: ConfInfo = this.confStore.getConfInfo(confId, this.storeSession.confSessionId);
    
    // Set titles
    this.currencyName = this.report.currency.text;
    this.reportCategoryName = this.report.priceListCategory.text;
    this.reportTitle = confInfo.text;

    // Remove the old contents
    this.rowViews = [];
    this.childrenRowViews = [];
    this.grandTotalView = new PriceReportRowView();

    // Prepare the views and display categories, items.
    this.report.categories.forEach(x => this.addCategoryAndLinkedItems(x));

    // Total
    this.rowViews.push(this.createTotal(this.report.total));

    // Go through the children and show their prices. Should be able to click on them to go further to their details
    if (this.report.children) {

      this.report.children.forEach(childReport => {

        if (this.globalDataStore.globalSettings.suppressChildConfigurationsWithZeroPrices && childReport.rawTotal == 0)
          return;

        this.addChild(childReport)

      });

      // Create child total If child reports available.
      if (this.childrenRowViews.length > 0)
        this.childrenRowViews.push(this.createTotal(this.report.childrenTotal));

    }

    this.clearedPrices = this.report.clearedPrices;
    this.showErrorMessage = (this.clearedPrices && this.clearedPrices.size > 0) || (this.report.warnings && this.report.warnings.size > 0)

    // Grand total
    if (this.report.grandTotal)
      this.createGrandTotal(this.report.grandTotal);

    // To trigger the changes make a new instance
    this.rowViews = [ ...this.rowViews ];
    this.cdr.markForCheck();
  }

  addCategoryAndLinkedItems(category: PriceReportCategory): void {

    if (!category.isTransient) {
      let rv: PriceReportRowView = new PriceReportRowView();

      rv.classes = this.CATEGORY_STYLE;

      // Add title
      rv.cells.push(this.createCell(category.title, null, this.ITEM_TITLE_STYLE));
      
      // Add price      

      let price = category.price;
      let priceStyle = this.ITEM_PRICE_STYLE;

      if (category.items.size == 0) {
        price = `${category.price}`;
        priceStyle = this.ITEM_PRICE_MANUAL_STYLE;
      }      
      
      rv.cells.push(this.createCell(category.items.size > 0 ? "" : price, null, priceStyle, null, false, false));

      // Add into list
      this.rowViews.push(rv);
      this.addItems(category.items, null, 1);

      if (category.items.size > 0)
        this.addSubTotal(category);
    }

    else {

      let showSubTotal: boolean = category.items.size > 1 || category.items.size == 1 && category.items.get(0).subItems.size > 0;

      let transientStyle: string = this.TRANSIENT_CATEGORY_STYLE;
      if (showSubTotal)
        transientStyle = transientStyle + this.HAS_ITEMS;

      this.addItems(category.items, transientStyle);

      if (showSubTotal)
        this.addSubTotal(category);

    }
  }

  addSubTotal(category: PriceReportCategory): void {

    let rv: PriceReportRowView = new PriceReportRowView();
    rv.classes = this.SUB_TOTAL;

    // Add title
    rv.cells.push(this.createCell(this.strings.SubTotal, null, this.ITEM_TITLE_STYLE));

    // Add price    
    rv.cells.push(this.createCell(`${category.price}`, null, this.SUB_TOTAL_VALUE_STYLE, null, false, false));

    // If cell is not readOnly then mark row as editable.
    rv.allowEdit = true;
    rv.tag = category;

    // Add into list
    this.rowViews.push(rv);

  }

  addItems(items: Immutable.List<PriceReportItem>, category: string, level: number = 0): void {
    items.forEach(item => {
      let rv: PriceReportRowView = new PriceReportRowView();

      // Add title
      rv.cells.push(this.createCell(item.title, null, this.ITEM_TITLE_STYLE, level));

      // Add price
      rv.cells.push(this.createCell(item.price, null, this.ITEM_PRICE_STYLE, null, true, !item.isEditable));

      // If cell is not readOnly then mark row as editable.
      rv.allowEdit = true;
      rv.classes = category ? category : "";

      if (item.isEditable)
        rv.classes += this.ITEM_ROW_STYLE;

      rv.id = item.longId;
      rv.tag = item;

      // Add into list
      this.rowViews.push(rv);

      // Add children recursively
      this.addItems(item.subItems, null, level + 1);
    });
  }

  addChild(childReport: PriceReportChildTotal) {
    let child = this.confStore.getConfInfo(childReport.configurationId, this.storeSession.confSessionId);

    let childView: PriceReportRowView = new PriceReportRowView();
    childView.id = childReport.configurationId;
    childView.classes = this.CHILD_STYLE;

    if (this.errorReportsConfIds.has(childReport.configurationId)) {
      childView.classes = " has-error";
    }
  
    let title = child.text;
    childView.cells.push(this.createCell(title, null, this.ITEM_TITLE_STYLE));
    childView.cells.push(this.createCell(childReport.quantity + "", this.CHILD_COLUMN_SIZE, this.CHILD_ITEM_STYLE + " " + this.CHILD_COLUMN_SIZE_LG));
    childView.cells.push(this.createCell(childReport.unitPrice, this.CHILD_COLUMN_SIZE, this.CHILD_ITEM_STYLE + " " + this.CHILD_COLUMN_SIZE_LG));
    childView.cells.push(this.createCell(childReport.grandTotal, this.CHILD_COLUMN_SIZE, this.CHILD_ITEM_STYLE + " " + this.CHILD_COLUMN_SIZE_LG));

    this.childrenRowViews.push(childView);
  }

  createTotal(total: string): PriceReportRowView {
    let rv: PriceReportRowView = new PriceReportRowView();
    rv.classes = this.TOTAL_STYLE;

    // Add total title
    rv.cells.push(this.createCell(this.strings.Total, null, this.ITEM_TITLE_STYLE));

    // Add total value
    rv.cells.push(this.createCell(total, null, this.ITEM_PRICE_STYLE));
    return rv;
  }

  createGrandTotal(grandTotal: string): void {
    this.grandTotalView = new PriceReportRowView();
    this.grandTotalView.classes = this.GRAND_TOTAL_STYLE;

    // Add total title
    this.grandTotalView.cells.push(this.createCell(this.strings.GrandTotal, null, this.ITEM_TITLE_STYLE));

    // Add grand total
    this.grandTotalView.cells.push(this.createCell(grandTotal, null, this.GRAND_TOTAL_ITEM_STYLE));
  }

  createCell(contents: string, size?: number, classes?: string, level?: number, isEditorCell: boolean = false, isEditorReadOnly = true): PriceReportCellView {
    let view: PriceReportCellView = { contents: contents, size: size, classes: classes, level: level, isEditorCell: isEditorCell, isEditorReadOnly: isEditorReadOnly };
    return view;
  }

  onPriceRowClick(row: PriceReportRowView) {

    this.markAsSelected(row);
    let args = <IPriceEditorCommandArgs> {

      action: PriceEditorActions.RowSelected,
      id: row.id
    };

    this.priceEditorService.notify(args);

  }

  markAsSelected(activeRow: PriceReportRowView) {

    // Un-select all except the active.
    for (let row of this.rowViews) {
      row.selected = false;      
    }

    if (activeRow) {
      activeRow.selected = true;
      this.isGridViewInActionMode = true;      
    }
    else {
      this.isGridViewInActionMode = false;      
    }

    this.cdr.markForCheck();
  }

  onCancelEdit(activeRow: PriceReportRowView) {

    this.markAsSelected(null);

    let args = <IPriceEditorCommandArgs>{
      action: PriceEditorActions.None, 
      id: activeRow.id
    };

    this.rowViews = [...this.rowViews];
    //this.cdr.markForCheck();

    this.priceEditorService.notify(args);
  }

  saveChanges(id?: number, value?: number) {

    let kv = new KeyValue<number, number>();    
    kv.key = id;
    kv.value = value;
    let manualPriceValues = Immutable.List<KeyValue<number, number>>([kv]);    

    this.confStore.updatePriceReport(this.storeSession.confSessionId, this.storeSession.activeConfigurationId, this.report.configurationId, RequestViews.Editor, manualPriceValues);
    
    this.isGridViewInActionMode = false;
    this.cdr.markForCheck();

  }

  onSubmitClick(args: IPriceEditorCommandArgs) {
    this.saveChanges(args.id, args.value);
  }

}