import { BaseMessage } from "./baseMessage";
import { GenericMessage } from "./genericMessage";

export class ApiMessage extends BaseMessage {

  readonly SERVER_TRIGGERED = "serverTriggered";
  readonly SCOPE = "scope";
  readonly INNER_MESSAGE = "innerMessage";

  get serverTriggered(): boolean { return this.getInternalValue<boolean>(this.SERVER_TRIGGERED); }
  setServerTriggered(serverTriggered: boolean): this { return this.setInternalValue(this.SERVER_TRIGGERED, serverTriggered); }

  get scope(): ApiMessageScope { return this.getInternalValue<ApiMessageScope>(this.SCOPE); }
  setScope(scope: ApiMessageScope): this { return this.setInternalValue(this.SCOPE, scope); }

  get innerMessage(): GenericMessage { return this.getInternalValue<GenericMessage>(this.INNER_MESSAGE); }
  setInnerMessage(innerMessage: GenericMessage): this { return this.setInternalValue(this.INNER_MESSAGE, innerMessage); }

}

export enum ApiMessageScope {
  Temp = 'Temp',
  Application = 'Application',
  Page = 'Page',
  Modal = 'Modal'
}  