import { Injectable, Inject } from '@angular/core';
import * as Immutable from "immutable";

import { AbstractConfigurationMessage } from "../../shared/models/responses/messages";
import { ConfDataMemorizer } from "./confDataMemorizer";
import { ManagedSubscription, SubscriptionOptions } from "../../../shared/managedSubscription";
import { ManagedSubject } from "../../../shared/managedSubject";
import { AppStoreSubscriptionManager } from "../../shared/providers/appStoreSubscriptionManager";

@Injectable()
export class ConfMessageProvider {

  constructor(
    public appStoreSubscriptionManager: AppStoreSubscriptionManager,
    @Inject(ConfDataMemorizer) public confDatamemorizer: ConfDataMemorizer
  ) {
  }

  public onMessagesRequest<T extends AbstractConfigurationMessage>(confSessionId: number, messageClassName: string, subscriptionOptions: SubscriptionOptions<Immutable.List<T>>, ignoreNull: boolean = true): ManagedSubscription {
    return this.createStoreSubscription<Immutable.List<T>>(messageClassName + confSessionId, this.confDatamemorizer.getMessagesMemorizer<T>(messageClassName, confSessionId, ignoreNull), subscriptionOptions);
  }

  public createStoreSubscription<T>(identifier: string, memorizer: () => T, subscriptionOptions: SubscriptionOptions<T>): ManagedSubscription {
    let managedSubject: ManagedSubject<T> = this.appStoreSubscriptionManager.getOrCreateStoreSubject<T>(identifier, memorizer, false, 1, false);
    return managedSubject.subscribe(subscriptionOptions);
  }

}