<div class="menu-item" title="{{state.title}}" [class.active]="state.active">
  <div class="row w-100 m-0 py-1 align-middle hover">
    <div class="col-auto pr-0">
      <div *ngIf="state.lineTop" class="line line-top" [class.active-line]="state.lineTopActive"></div>
      <div class="circle" [class.locked]="state.locked"></div>
      <div *ngIf="state.lineBottom" class="line line-bottom" [class.active-line]="state.lineBottomActive"></div>
    </div>
    <div class="col pr-0">
      <div class="label">{{state.title}}</div>
      <small>{{state.date}}</small>
    </div>
    <div *ngIf="state.icon" class="col-auto pr-2">
      <co-image width="24" height="24" type="png" isVault="true" key="{{state.icon}}" imageSet="primary-dark"></co-image>
    </div>
  </div>
</div>
