import { Injectable } from "@angular/core";
import { BaseEntity } from "../baseEntity";
import { User, State, WorkGroup, Language, Product, ParamValue, Customer, CustomerSite, CustomerContact, Currency, ProductFamily, NumberSeries } from "../models";

@Injectable()
export class ModelLabelService {

  /**
   *  Gets the label for the given entity.
      For example name/title.
   */
  public getLabel(entity: BaseEntity): string {
    let label = "";
    if (entity instanceof User)
      label = entity.name;
    else if (entity instanceof State)
      label = entity.name;
    else if (entity instanceof WorkGroup)
      label = entity.title;
    else if (entity instanceof Language)
      label = entity.title;
    else if (entity instanceof Product)
      label = entity.title;
    else if (entity instanceof ProductFamily)
      label = entity.title;
    else if (entity instanceof ParamValue)
      label = entity.title;
    else if (entity instanceof Customer)
      label = entity.name;
    else if (entity instanceof CustomerSite)
      label = entity.name;
    else if (entity instanceof CustomerContact)
      label = entity.name;
    else if (entity instanceof Currency)
      label = entity.identity;
    else if (entity instanceof NumberSeries)
      label = entity.name;

    return label;
  }
}