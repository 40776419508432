import * as Immutable from "immutable";
import { Decoration } from "./decoration";
import { PropertyDecorationItem } from "./propertyDecorationItem";

export class PropertyDecoration extends Decoration {

  protected readonly Items = "items";

  get items(): Immutable.List<PropertyDecorationItem> { return this.getInternalValue<Immutable.List<PropertyDecorationItem>>(this.Items); }
  setItems(items: Immutable.List<PropertyDecorationItem>): this { return this.setInternalValue(this.Items, items); }
}