import * as Immutable from "immutable";

import { AbstractConfigurationMessage } from "./abstractConfigurationMessage";
import { ParamInfo, RuleInfo } from "..";
import { ConfInfo } from "../../entities/confInfo";

export class AbstractImmutableValueMessage extends AbstractConfigurationMessage {

  protected readonly PARAM_INFO = "paramInfo";
  protected readonly RULE_INFOS = "ruleInfos";
  protected readonly CONF_INFOS = "confInfos";

  get paramInfo(): ParamInfo { return this.getInternalValue<ParamInfo>(this.PARAM_INFO); }
  setParamInfo(paramInfo: ParamInfo): this { return this.setInternalValue(this.PARAM_INFO, paramInfo); }

  get ruleInfos(): Immutable.List<RuleInfo> { return this.getInternalValue<Immutable.List<RuleInfo>>(this.RULE_INFOS); }
  setRuleInfos(ruleInfos: Immutable.List<RuleInfo>): this { return this.setInternalValue(this.RULE_INFOS, ruleInfos); }

  get confInfos(): Immutable.List<ConfInfo> { return this.getInternalValue<Immutable.List<ConfInfo>>(this.CONF_INFOS); }
  setConfInfos(confInfos: Immutable.List<ConfInfo>): this { return this.setInternalValue(this.CONF_INFOS, confInfos); }
}