import { Inject, Injectable } from "@angular/core"
import { normalize } from "normalizr";
import * as Immutable from "immutable";

import { User } from "../../models"
import { BaseEntity } from "../../baseEntity";
import { Ioc, Register } from "../../../../shared/ioc/iocdecorator"
import { AccountDataResponse, AbstractAccountMessage } from "../../models/responses"
import { AccountDataSchema } from "./accountDataSchema";
import { ModelFactory } from "../modelFactory";
import { NormalizerService } from "../../../../shared/providers/normalizer"

@Injectable()
export class AccountDataNormalizer {

  constructor(
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    @Inject(AccountDataSchema) public accountSchema: AccountDataSchema,
    @Inject(NormalizerService) public normalizerService: NormalizerService
  ) { }

  normalizeJson(json: any): AccountDataResponse {

    let accountResponse = new AccountDataResponse();
    
    if (json.user) {
      let normalizeOutput = this.normalizerService.normalizeJson(json.user, this.accountSchema.getSchema(User.name), (className, data) => this.modelFactory.createEntity(className, data));
      let userId = normalizeOutput.resultIds[0];
      accountResponse = accountResponse.setUser(normalizeOutput.entities.get(userId) as User);
    }

    accountResponse = accountResponse.setShowRecaptcha(json.showRecaptcha);     

    let entities = Immutable.Map<number, BaseEntity>();
    let entityIdsByClassName = Immutable.Map<string, Immutable.List<number>>();
    if (json.entitiesByClassName) {

      for (let key in json.entitiesByClassName) {

        let classEntities = json.entitiesByClassName[key];

        let normalizeOutput = this.normalizerService.normalizeJson(classEntities, this.accountSchema.getSchemaArray(key), (className, data) => this.modelFactory.createEntity(className, data));

        entityIdsByClassName = entityIdsByClassName.set(key, Immutable.List<number>(normalizeOutput.resultIds));
        entities = this.mergeEntities(entities, normalizeOutput.entities);

      }
    }

    accountResponse = accountResponse.setEntityIdsByClassName(entityIdsByClassName);
    accountResponse = accountResponse.setEntities(entities);

    if (json.messages && json.messages.length > 0) {
      accountResponse = accountResponse.setMessages(this.modelFactory.createArray<AbstractAccountMessage>(json.messages));
    }

    return accountResponse;
  }  

  mergeEntities(existingEntities: Immutable.Map<number, BaseEntity>, newEntities: Map<number, BaseEntity>) {
    newEntities.forEach((newEntity: BaseEntity, key: number) => {
      existingEntities = existingEntities.set(key, newEntity);
    });

    return existingEntities;
  }
}