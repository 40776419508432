import { Component, Input } from "@angular/core";
import {  InputViewModel } from "../../shared";
import { RadioButtonComponent } from "../radioButtonComponent";

@Component({
  selector: 'image-radio-style',
  templateUrl: './imageRadioStyleComponent.html'
})
export class ImageRadioStyleComponent {

  @Input()
  view: InputViewModel;

  @Input()
  selected: boolean = false;

  public get imageStyle(): string {
    let imageStateCss: string = "rounded-image-corner radio-border";

    if (this.selected) {
      imageStateCss += " checked";
    }
    return imageStateCss;
  }
}