<div class="m-3">
  
  <div class="mt-2 mb-2">
      <label class="text-danger">{{strings.AreYouSureYouWantToDeleteThisConfiguration}}</label>
  </div>

  <div class="mt-2 text-right">
    <button class="btn btn-primary text-uppercase" [style.width.px]="'100'" (click)="deleteConfigurationClick($event);" type="button">{{strings.Yes}}</button>
    <button class="btn btn-primary ml-2 text-uppercase" [style.width.px]="'100'" (click)="onCancel.emit($event)" type="button">{{strings.No}}</button>
  </div>
</div>
