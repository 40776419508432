import { ChangeDetectorRef, Component, Host, Inject, Input } from '@angular/core';
import { ActivatedRoute, ResolveEnd, Router } from '@angular/router';
import { RouteNames, RouteRedirector } from '../../../../../core/pages/shared/providers';
import { GlobalDataStore } from '../../../../../core/pages/shared/providers/globalData';
import { NodeActions, NotificationInfo, NotificationService, NotificationType } from "../../../../shared/components";
import { ConfInfo, RequestViews, ImportOperationMessage, ConfDeleteMessage } from "../../../shared/models";
import { AccountDataStore } from "../../../shared/providers/accountData";
import { ProductDataStore } from "../../../shared/providers/productData";
import { PageUIData, StickableUIData } from "../../../shared/state";
import { CompositeMenuService } from '../../actions';
import { ConfiguratorStore, ConfiguratorUIStore, ConfPageSessionService, ConfRouteParams } from "../../providers";
import { StickableSideBarComponent } from "../../shared/stickableSideBarComponent";
import { CompositeActions, CompositeActionService, ICompositeDataInfo } from './compositeActionService';
import { CompositeHelper } from './compositeHelper';
import { MenuItem } from "./navigation";
import { CompositeTreeDataProvider } from './tree/compositeTreeDataProvider';

@Component({
  selector: 'composite-menu',
  templateUrl: './compositeComponent.html',
  providers: [CompositeMenuService]
})
export class CompositeComponent extends StickableSideBarComponent {

  public selectedItem: MenuItem;
  public sidebarExtended: boolean = false;
  public menuClicked: boolean = false;

  // Active conf info  
  public activeConfInfoId: number;

  public compositeWidth: string;

  public nodeAction: string;

  public changeConfOnAdd: boolean = false;

  public focusedConfigurationId: number;

  @Input()
  public isAnonymous: boolean = false;

  @Input()
  public activeRoute: string = RouteNames.Editor;

  @Input()
  public showStickyOption: boolean = true;

  // System does not support Pin/Unpin feature for safari browser.
  // It shows weird behaviour when making the composite sticky/unsticky.
  isSafari: boolean = false;  

  constructor(
    @Host() @Inject(CompositeMenuService) public menuService: CompositeMenuService,
    @Inject(CompositeTreeDataProvider) public menuDataProvider: CompositeTreeDataProvider,
    @Inject(ConfiguratorUIStore) public confUIStore: ConfiguratorUIStore,
    @Inject(ProductDataStore) public productDataStore: ProductDataStore,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(AccountDataStore) public accountStore: AccountDataStore,
    @Inject(ChangeDetectorRef) public cd: ChangeDetectorRef,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    @Inject(CompositeHelper) public compositeHelper: CompositeHelper,
    @Inject(NotificationService) public notificationService: NotificationService,
    @Inject(CompositeActionService) public actionService: CompositeActionService,
    @Inject(CompositeTreeDataProvider) public treeDataProvider: CompositeTreeDataProvider,
    @Inject(ActivatedRoute) public activatedRoute: ActivatedRoute,
    @Inject(Router) public router: Router
  ) {
    super(confUIStore, storeSession);
  }

  public ngOnInit(): void {

    this.isSafari = this.browserInfo.isSafari;
    this.activeConfInfoId = this.storeSession.activeConfigurationId;
    this.treeDataProvider.defaultSelectedId = this.activeConfInfoId;
    let confInfo: ConfInfo = this.confStore.getConfInfo(this.storeSession.activeConfigurationId, this.storeSession.confSessionId);
    let rootConfInfo: ConfInfo = this.confStore.getConfInfo(confInfo.rootId, this.storeSession.confSessionId);

    // Listening composite actions.
    this.actionService.getMessage().subscribe(info => {

      this.handleActions(info);

    }).unsubscribeOn(this.unsubscribeSubject);

    super.ngOnInit();
  }

  /**
   * Executes composite actions
   * @param info
   */
  protected handleActions(info: ICompositeDataInfo<any>): void {

    let focusedConfinfo: ConfInfo = this.confStore.getConfInfo(info.focusedConfInfoId, this.storeSession.confSessionId);
    
    switch (info.action) {

      case CompositeActions.AddProductClick:
        {
          this.addChildConfiguration(info.tag, info.focusedConfInfoId);
          this.sidebarExtended = false;
          this.updateVisibility(this.uiState.visible);

          break;
        }

      case CompositeActions.AddClick:
        {

          // Two directions. 1- add child directly if only single child product, 2- If multiple products then let user choose the the product.
          this.compositeHelper.findChildren(focusedConfinfo.productId).then(children => {

            // For single child
            if (children.size == 1) {
              this.closeExtension();
              this.addChildConfiguration(children.first(), info.focusedConfInfoId);
            }

            // For multiple child products, open the menu and let user choose the product.
            else {

              this.nodeAction = NodeActions.AddClicked;
              this.sidebarExtended = true;
              this.updateVisibility(this.uiState.visible);
              this.navigateTo(info.focusedConfInfoId);

            }
          });

          break;
        }

      case CompositeActions.Delete:
        {
          this.plainBlockUI();
          let nextConfId: number = this.compositeHelper.prevConfigurationId(info.focusedConfInfoId, this.storeSession.confSessionId);
          this.confStore.deleteConfiguration(info.focusedConfInfoId, this.storeSession.confSessionId, RequestViews.Editor).then(x => {
                        
            // Close sidebar
            this.sidebarExtended = false;
            this.updateVisibility(this.uiState.visible);

            // Navigate to previous configuration after delete
            this.navigateTo(nextConfId);

            let displayStyle = (x.messages.get(0) as ConfDeleteMessage)?.displayStyle;
            let title = (x.messages.get(0) as ConfDeleteMessage)?.title;
            let message = (x.messages.get(0) as ConfDeleteMessage)?.message;

            // Notify delete action.
            this.notificationService.notify(<NotificationInfo>{ title: title || displayStyle ? title : this.strings.Delete, message: message ? message : this.strings.ConfigurationIsDeleted, type: displayStyle ? displayStyle : NotificationType.Warning, selfClose: true });
            this.unblockUI();
          });
          break;
        }

      case CompositeActions.Import:
        {
          let displayStyle = (info.tag as ImportOperationMessage)?.displayStyle;
          let title = (info.tag as ImportOperationMessage)?.title;
          let message = (info.tag as ConfDeleteMessage)?.message;

          this.notificationService.notify(<NotificationInfo>{ title: title || displayStyle ? title : this.strings.Import, message: message ? message : this.strings.ChildConfigurationSuccessfullyImported, type: displayStyle ? displayStyle : NotificationType.Success, selfClose: true });
          
          break;
        }

      case CompositeActions.MenuClick:
        {
          this.nodeAction = NodeActions.ActivateMenu;
          this.sidebarExtended = true;
          this.updateVisibility(this.uiState.visible);
          this.navigateTo(info.focusedConfInfoId);
          break;
        }
      case CompositeActions.SelectNode:
        {
          this.navigateTo(info.focusedConfInfoId);
          break;
        }
      case CompositeActions.NavigationEndedAfterDataLoad:
        {
          // If the navigation is made through popups e.g Mandatory popup then
          // we have to trigger the treeNode select event to make it selected.
          if (this.storeSession.activeConfigurationId != this.activeConfInfoId) {

            this.activeConfInfoId = this.storeSession.activeConfigurationId;
            this.treeDataProvider.selectNodeById(this.storeSession.activeConfigurationId);
            
          }
          break;
        }
      case CompositeActions.ExitSidebarExt:
        {
          this.sidebarExtended = false;
          this.updateVisibility(this.uiState.visible);
          break;
        }
      //case CompositeActions.NavigationEndedAfterDataLoad:
      //  {
      //    if (this.menuClicked) {
      //      this.sidebarExtended = true;
      //      this.activeConfInfoId = this.focusedConfigurationId;
      //      this.menuClicked = false;
      //    }
      //    break;
      //  }
    }
  }

  addChildConfiguration(productId: number, focusedConfInfoId: number): void {

    this.compositeHelper.addChildConfigurations(focusedConfInfoId, productId).
      then(newConfIds => {

        // Read global settings
        let selectNewNodeAfterAdd = this.globalDataStore.getGlobalData().globalSettings.autoSelectChild;
        
        // Auto select the newly added configuration.
        let confIdsExceptSelectedConf: number[] = newConfIds.filter(x => x != focusedConfInfoId);
        if (confIdsExceptSelectedConf.length == 1 && selectNewNodeAfterAdd) {
          this.navigateTo(confIdsExceptSelectedConf[0]);
        }
      });
  }


  public navigateTo(id: number): Promise<boolean> {

    return new Promise<boolean>(resolver => {

      if (id == this.storeSession.activeConfigurationId) {
        resolver(true);
        return;
      }

      // Read the sub routing, it could be editor, summary.
      let viewName: string = this.activatedRoute.firstChild.snapshot.url[0].path;

      let routeArgs = <ConfRouteParams>{ id: id, confSessionId: this.storeSession.confSessionId, view: viewName };

      this.routeRedirector.redirectToConfigurator(routeArgs);

    });
  }

  public onClosed(): void {
    this.sidebarExtended = false;
    super.onClosed();
  }

  beforeUILoad(uiData: PageUIData): void {
    this.onUIDataChange(uiData);
  }

  // Returns the composite data state.
  // Note! Don't use this getter property directly in html template. Its better to use local ui data member instead, otherwise you might have small performance penalty.
  get uiState(): StickableUIData {
    return this.confUIStore.getConfiguratorPageUIState(this.pageId).compositeUI;
  }

  /**
   * Updates the composite visibility.
   * @param visible
   */
  updateVisibility(visible: boolean): void {

    this.confUIStore.updateCompositeUIState(this.pageId, this.ui.calcWidth, this.ui.calcSticky, visible, this.sidebarExtended);

    // Update toggler button visiblity, Show the toggler only If composite is collapsed.
    // this.compositeTogglerVisible = !visible;
  }

  /**
   * Updates the sticky option set by the user.
   * @param userSticky
   */
  updateUserSticky(userSticky: boolean): void {
    this.confUIStore.updateCompositeUIState(this.pageId, this.ui.calcWidth, userSticky, this.ui.visible, this.sidebarExtended);
  }

  updateExtraStyles(): void {

    const hasFooter = (this.isAnonymous && this.activeRoute == RouteNames.Summary) || this.activeRoute == RouteNames.Editor;
    const heightStyle = hasFooter ? ' has-footer' : '';

    this.moreStyles = this.ui.calcSticky ? "inline-dock" : "inline-floated" + heightStyle;

    // TODO: Need more investigation, would calcWidth become 0px on collapse or would it be triggered when ui.visible is switched to true and calWidth is not set at that time.
    if (this.ui.visible && this.ui.calcWidth === "0px") {
      this.compositeWidth = "100%"
    }
    else {
      this.compositeWidth = this.ui.calcWidth;
    }

    if (!this.cd['destroyed'])
      this.cd.detectChanges();
  }

  /**
   * Overrides the subscription and only listen the composite ui change.
   */
  public subscribePageUIChanges(): void {
    if (this.uiStore)
      this.confUIStore.compositeUIChange(this.pageId, (data: PageUIData) => {
        this.onUIDataChange(data);
      });
  }

  closeExtension(): void {

    if (this.sidebarExtended) {
      this.sidebarExtended = false;
      this.updateVisibility(this.uiState.visible);
      this.menuDataProvider.applyButtonStyle(null, NodeActions.DeactivateMenu);
    }
  }

  ngOnDestroy() {
    if (this.menuDataProvider)
      this.menuDataProvider.onDestroy();

    super.ngOnDestroy();
  }

  // TODO: This is a temporary fix to hide the composite toggler, we need more work on this part.
  get isCompositeVisible(): boolean {
    return !this.uiState.visible;
  }

  closeComposite(): void {
    this.backdrop.popup.close();
  }

  onBackdropClosed(): void {
    this.closeExtension();
  }

  setBackdrop(): void {

    if (this.backdrop) {

      if (this.ui.calcSticky == undefined)
        return;

      // If not sticky (i.e floated) and visible then show the backdrop.
      this.backdrop.visible = (!this.ui.calcSticky && this.ui.visible) || this.sidebarExtended;
    }

  }

  /**
   * Toggles the sticky option
   */
  public toggleSticky(): void {

    let newStickyState: boolean = !this.accountStore.getUser().settings.compositeControlSticky;
    this.accountStore.updateCompositeControlSticky(newStickyState);
    this.updateUserSticky(newStickyState);

  }

}