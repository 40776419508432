<backdrop-container>
  <regular-popup *ngIf="visible"
                 [id]="id"
                 [title]="title"
                 [showFooter]="true"
                 [closeOnOk]="false"
                 [okButtonText]="strings.Ok"
                 [cancelButtonText]="strings.Cancel"
                 (okClick)="okClick()"
                 (onClosed)="onPopupClosed()">
    <div card-type="body" class="p-4">
      <div>
        {{text}}
      </div>
      <text-box class="d-block pt-3"
                [view]="emailView"
                type="email">
      </text-box>
      <check-box *ngIf="showSendEmailCheckbox"
                 class="d-block pt-3"
                 [view]="sendEmailView"></check-box>
    </div>
  </regular-popup>
</backdrop-container>
