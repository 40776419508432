import * as Immutable from "immutable";

import { BaseEntity } from "../../baseEntity";
import { RefObject } from "./refObject";

export class ConfHistory extends BaseEntity {

  protected readonly CREATED_BY = "createdBy";
  protected readonly CREATION_DATE = "creationDate";
  protected readonly FROM_STATE = "fromState";
  protected readonly TO_STATE = "toState";
  protected readonly NOTE = "note";
  protected readonly HISTORY_TYPE = "historyType";
  protected readonly FROM_OWNERSHIP = "fromOwnership";
  protected readonly TO_OWNERSHIP = "toOwnership";

  get createdBy(): RefObject { return this.getInternalValue<RefObject>(this.CREATED_BY); }
  setCreatedBy(createdBy: RefObject): this { return this.setInternalValue(this.CREATED_BY, createdBy); }

  get creationDate(): string { return this.getInternalValue<string>(this.CREATION_DATE); }
  setCreationDate(creationDate: string): this { return this.setInternalValue(this.CREATION_DATE, creationDate); }

  get fromState(): RefObject { return this.getInternalValue<RefObject>(this.FROM_STATE); }
  setFromState(fromState: RefObject): this { return this.setInternalValue(this.FROM_STATE, fromState); }

  get toState(): RefObject { return this.getInternalValue<RefObject>(this.TO_STATE); }
  setToState(toState: RefObject): this { return this.setInternalValue(this.TO_STATE, toState); }

  get note(): string { return this.getInternalValue<string>(this.NOTE); }
  setNote(note: string): this { return this.setInternalValue(this.NOTE, note); }

  get historyType(): string { return this.getInternalValue<string>(this.HISTORY_TYPE); }
  setHistoryType(historyType: string): this { return this.setInternalValue(this.HISTORY_TYPE, historyType); }

  get fromOwnership(): RefObject { return this.getInternalValue<RefObject>(this.FROM_OWNERSHIP); }
  setFromOwnership(fromOwnership: RefObject): this { return this.setInternalValue(this.FROM_OWNERSHIP, fromOwnership); }

  get toOwnership(): RefObject { return this.getInternalValue<RefObject>(this.TO_OWNERSHIP); }
  setToOwnership(toOwnership: RefObject): this { return this.setInternalValue(this.TO_OWNERSHIP, toOwnership); }
}