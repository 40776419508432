import { ChangeDetectorRef, Component, Inject } from "@angular/core";
import * as Immutable from "immutable";
import { PopupService } from "../../../../shared/components";
import { ManagedSubscription } from "../../../../shared/managedSubscription";
import { BomItemMessage } from "../../../shared/models/responses/messages";
import { ConfiguratorStore, ConfPageSessionService, PopupIdentifiers } from "../../providers";
import { BomActionArgs } from "./bomActionArgs";
import { BomControlComponent } from "./bomControlComponent";
import { BomStore } from "./bomStore";


@Component({
  selector: 'bom-edit-control',
  templateUrl: './bomEditControlComponent.html',
})
export class BomEditControlComponent extends BomControlComponent {
  public bomItem: BomItemMessage;
  public getItemSubscription: ManagedSubscription;

  constructor(
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(BomStore) public bomStore: BomStore,
    @Inject(PopupService) public popupService: PopupService,
    public cdr: ChangeDetectorRef
  ) {
    super(confStore, storeSession, bomStore, cdr, popupService);
  }

  ngOnInit() {
    this.id = PopupIdentifiers.BomEditPopup;
    super.ngOnInit();

    this.getItemSubscription = this.bomStore.onGetItemRequest(this.storeSession.confSessionId, (bomItemMessages: Immutable.List<BomItemMessage>): void => {
      this.bomItem = bomItemMessages.first();
      this.itemNoView.value = this.bomItem.itemNo;
      this.quantityView.value = this.bomItem.quantity;

      this.setupRevisions(this.bomItem.revisions, this.bomItem.revision);
      this.setupQuantityUnits(this.bomItem.quantityUnits, this.bomItem.quantityUnitId);
      this.setupOperationSequence(this.bomItem.operationSequences, this.bomItem.operationSequenceId);
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    if (this.getItemSubscription)
      this.getItemSubscription.unsubscribe();

    super.ngOnDestroy();
  }

  public open(tag: BomActionArgs) {
    this.editItem(tag.decorationId, tag.selectedRowId);
  }

  public editItem(bomDecorationId: number, bomItemId: number): void {
    this.bomDecorationId = bomDecorationId;
    this.bomStore.dispatchGetItem(this.storeSession.activeConfigurationId, this.storeSession.confSessionId, this.bomDecorationId, bomItemId);

    super.show(bomDecorationId);
  }

  public onOkClick() {
    this.bomStore.dispatchEditBom(this.storeSession.activeConfigurationId, this.storeSession.confSessionId, this.bomDecorationId, this.bomItem.id, this.itemNoView.value, this.revisionView.value, this.quantityView.value, parseInt(this.quantityUnitView.value), parseInt(this.operationSequenceView.value));

    super.onOkClick();
  }
}