
export class ComparisonDraggableRowNodes {

  id: string;

  nodes: Array<ComparisonDraggableNode>;  
}

export interface ComparisonDraggableNode {

  id: string;

  icon: string;

  text: string;

  path: string;

  description: string;

  compact: string;

  isEmpty: boolean;
}
