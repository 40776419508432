import { Injectable, Inject } from '@angular/core';
import * as Immutable from "immutable";

@Injectable()
export class SearchSelectionCacheService {

  public confsMap: Immutable.Map<number, boolean> = Immutable.Map<number, boolean>();

  public pagingScope = false;

  public add(id: number, value: boolean): void {
    this.confsMap = this.confsMap.set(id, value);
  }

  public remove(id: number) {
    this.confsMap = this.confsMap.remove(id);
  }

  public get selectedIds(): Array<number> {
    return this.confsMap.filter((value, key) => value).keySeq().toArray();    
  }

  public selected(id: number): boolean {
    return this.confsMap.has(id) && this.confsMap.get(id);
  }

  public clear(): void {
    this.confsMap = this.confsMap.clear();
  }

}
