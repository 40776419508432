<div class="d-flex w-100 accordion-nodes-container mx-0" *ngIf="startingNodes.length > 0">  
    <tree-view-simple
                      [roots]="startingNodes"
                      [activeNodeId]="activeNodeId"
                      (selected)="onNodeSelected($event)"
                      [imageSet]="imageSet">
    </tree-view-simple>    
    <a class="tree-nodes-close" href="#" (click)="closeConfigurationDetail($event)">
      <co-image type="svg" imageSet="primary-darker" key="delete" [width]="'24'" [height]="'24'"></co-image>
    </a>  
</div>