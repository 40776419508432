<div #visualObjectContainer>
  <div class="visual-object-margin documents-decoration decoration" [class.border-style]="!showTitle" [style.max-width.%]="'100'" >
    <gridview>
      <gridview-header *ngIf="showTitle" classes="decoration-header">
        <header-columntemplate size="auto" classes="header-image">
          <co-image width="24" height="24" type="svg" key="document" [imageSet]="imageSet"></co-image>
        </header-columntemplate>
        <header-columntemplate classes="header-title ">
          {{decoration.title}}
        </header-columntemplate>
      </gridview-header>
      <gridview-row *ngFor="let document of documentValues" classes="py-2">
        <columntemplate classes="static-label" title="{{document.title}}">
          <documents-link [document]="document" [confSessionId]="confSessionId" [activeConfigurationId]="configurationId"></documents-link>
        </columntemplate>
      </gridview-row>
    </gridview>
  </div>
</div>