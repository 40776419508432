import { BaseEntity } from "../../baseEntity";

export class ConfAttachment extends BaseEntity {

  protected readonly TITLE = "title";
  protected readonly ICON = "icon";

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get icon(): string { return this.getInternalValue<string>(this.ICON); }
  setIcon(icon: string): this { return this.setInternalValue(this.ICON, icon); }
}