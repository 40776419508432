<backdrop-container>
  <regular-popup *ngIf="visible"
                 [id]="id"
                 [title]="strings.ConfigurationHistory"
                 [okButton]="false"
                 [cancelButton]="false"
                 [height]="height"
                 [width]="width"
                 (onClosed)="onClosed.emit()">
    <div card-type="body" class="p-4">
      <gridview>
        <gridview-row *ngFor="let history of histories" classes="pt-2 control-border-b">
          <columntemplate>
            <span>{{history.fromText}}</span>
            <co-image key="arrowright" width="15px" height="15px"></co-image>
            <span>{{history.toText}}</span>
            <div>
              <span class="text-primary">{{strings.By}}</span>:
              <span>{{history.createdBy==null?'?':history.createdBy.text}}, {{history.creationDate}}</span>
            </div>
          </columntemplate>
          <div class="w-100"></div>
          <columntemplate>
            <span class="text-primary">{{strings.Notes}}</span>:
            <span>{{history.note}}</span>
          </columntemplate>
        </gridview-row>
      </gridview>
    </div>
  </regular-popup>
</backdrop-container>