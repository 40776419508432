import { Injectable } from '@angular/core';
import { PresetItem, Range } from "../..";

@Injectable()
export class PresetService {
  
  public presets: Array<PresetItem> = [];

  isPreset(range: Range): boolean {

    if (range.fromDate && range.toDate) {

      for (let p of this.presets)
        if (this.equal(p.range, range))
          return true;      

    }

    return false;
  }

  equal(range1: Range, range2: Range): boolean {

    if (!range1.toDate || !range1.toDate || !range2.toDate || !range2.toDate)
      return false;

    return (range1.fromDate.valueOf() == range2.fromDate.valueOf() && range1.toDate.valueOf() == range2.toDate.valueOf())
  }

}