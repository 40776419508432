import { Inject, Injectable } from "@angular/core";
import { ProductDataStore } from "../../../../../core/pages/shared/providers/productData";
import { ManagedSubject } from "../../../../../core/shared";
import { ConfiguratorStore, ConfPageSessionService } from "../../providers";
import { CompositeHelper } from "./compositeHelper";
import { CompositeTreeDataProvider } from "./tree/compositeTreeDataProvider";


export enum CompositeActions {

  Add = 'add',  
  AddProductClick = 'addProductClick',  
  Delete = 'delete',  
  Import = 'import',  
  AddClick = 'addClick',  
  MenuClick = 'menuClick',  
  SelectNode = 'selectNode',  
  ExitSidebarExt = 'exitSidebarExt',  
  NavigationEndedAfterDataLoad = 'navigationEndedAfterDataLoad'

}

export interface ICompositeDataInfo<T> {
  focusedConfInfoId: number;
  action: CompositeActions;
  tag?: T;
}

@Injectable()
export class CompositeActionService {

  constructor(
    @Inject(ProductDataStore) public productDataStore: ProductDataStore,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(CompositeTreeDataProvider) public treeDataProvider: CompositeTreeDataProvider,
    @Inject(ConfPageSessionService) public storeSession: ConfPageSessionService,
    @Inject(CompositeHelper) public compositeHelper: CompositeHelper
  ) { }

  private subject = new ManagedSubject<ICompositeDataInfo<any>>(null, false, 0, false, false);

  public send<T>(action: CompositeActions, tag?: T): void {

    let info: ICompositeDataInfo<T> = <ICompositeDataInfo<T>>{
      action: action,
      tag: tag
    }

    this.subject.nextValue(info);
  }

  public getMessage(): ManagedSubject<ICompositeDataInfo<any>> {
    return this.subject;
  }

  public notify<T>(info: ICompositeDataInfo<T>): void {
    this.getMessage().nextValue(info);
  }
  
  selectNode(confId: number): void {
    this.treeDataProvider.defaultSelectedId = confId;
  }

  //deleteConfiguration(): Promise<ConfDataResponse> {

  //}

  //importConfiguration(): Promise<ConfDataResponse> {

  //}

  //copyConfiguration(): Promise<ConfDataResponse> {

  //}

  closeCompositeExtension(): void
  {

  }

  openComposite(): void {

  }

  closeComposite(): void {

  }

}