import { Directive, ViewContainerRef, SimpleChange, Output, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { PopperContentComponent } from './popperContentComponent';
import { Placement, PopperConfig } from './popoverInfo';

@Directive({
  selector: '[popper]',
  exportAs: 'popper'
})
export class PopperDirective {

  // Margin around the popper
  public margin: number = 20;
  public marginX: number = 15;
  public marginY: number = 5;

  constructor(private referenceElement: ViewContainerRef) { }

  @Input('popper')
  content: PopperContentComponent;

  popperSubscription: Subscription;

  ngOnInit() {

    this.popperSubscription = this.content.positionChange.subscribe((config: PopperConfig) => {

      // Get element reference's bounds.      
      let bounds = this.referenceElementBounds(config);


      if (this.content.ignoreMaximize) {
        let refElementBounds = this.referenceElement.element.nativeElement.getBoundingClientRect();

        config.positionX = bounds.left - refElementBounds.left - this.marginX;
        config.positionY = bounds.top - refElementBounds.top - this.marginY;

        config.width = bounds.width + 2 * this.marginX;
        config.height = bounds.height + 2 * this.marginY;
      }
      // Popper settings at right side.
      else if (this.hasRoomAtRightSide(bounds)) {

        // Set the positionX.
        config.positionX = bounds.right + this.margin;

        config.calcPlacement = Placement.Right;
        config.maximize = false;
      }
      // Popper settings at left side.
      else if (this.hasRoomAtLeftSide(bounds)) {

        // Calculate positionX
        config.positionX = bounds.left - (+this.content.width) - this.margin;

        // Placement
        config.calcPlacement = Placement.Left;

        // Don't maximize as enough room available
        config.maximize = false;
      }
      else
        // Maximize it as not enough room available.
        config.maximize = true;
    });
  }

  /**
   * Returns 'True' If sufficient room available at right side.
   * @param bounds
   */
  public hasRoomAtRightSide(bounds: any): boolean {
    return bounds.right + (+this.content.width + 2 * this.margin) < window.innerWidth;
  }

  /**
   * Returns 'True' If sufficient room available at left side.
   * @param bounds
   */
  public hasRoomAtLeftSide(bounds: any): boolean {
    return bounds.left - this.margin > +this.content.width + 2 * this.margin;
  }

  public referenceElementBounds(config: PopperConfig) {

    // Respect the reference element defined in <PopperConfig> over the reference element passed through constructor.
    // If the popper and reference element both exist in same component, then don't need to set the reference element in <PopperConfig>, just pass the popper reference
    // to any container where you want the popper to be shown. 
    // Example: 
    // <popper-content #popperReference ></popper-content>
    // <div [popper]='popperReference'></div>

    if (config.refElement)
      return config.refElement.getBoundingClientRect();

    let element = this.referenceElement.element.nativeElement;
    return element.getBoundingClientRect();
  }

  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
  }

  ngOnDestroy() {
    if (this.popperSubscription)
      this.popperSubscription.unsubscribe();

  }

}