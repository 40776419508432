import * as Immutable from "immutable"

import { AbstractOperationMessage } from "./abstractOperationMessage"

export class BomOperationMessage extends AbstractOperationMessage {

  protected readonly BOM_DECORATION_ID = "bomDecorationId";

  get bomDecorationId(): number { return this.getInternalValue<number>(this.BOM_DECORATION_ID); }
  setBomDecorationId(bomDecorationId: number): this { return this.setInternalValue(this.BOM_DECORATION_ID, bomDecorationId); }
  
}