<div *ngIf="visible" #visualObjectContainer class="grow-visual-object" >
  <div class="visual-object-margin graphics-decoration grow-visual-object decoration" [class.border-style]="!showTitle" [style.width]="isPopup ? width : ''" [style.max-width.%]="'100'" >
    <gridview [classes]="!showTitle && 'px-0'">
      <gridview-header *ngIf="showTitle" classes="decoration-header">

        <header-columntemplate *ngIf="isEditor" size="auto" classes="header-image">
          <co-image width="24" height="24" type="svg" key="graphics" [imageSet]="imageSet"></co-image>
        </header-columntemplate>

        <header-columntemplate *ngIf="showInlineTitle" classes="header-title">

          <ng-container *ngIf="isPopup; else render">
            <a href="#" title="{{strings.Viewer}}" role="button" (click)="openPopup()">{{title}}</a>
            <graphics-popup></graphics-popup>
          </ng-container>
          <ng-template #render>
            {{title}}
          </ng-template>

        </header-columntemplate>
        <header-columntemplate size="auto" classes="text-right download-icon header-image" *ngIf="decoration.showDownloadLink">
          <graphics-download [decoration]="decoration" [imageSet]="imageSet"></graphics-download>
        </header-columntemplate>

      </gridview-header>

      <div class="grow-visual-object" [ngStyle]="ngContainerStyle" [class.hide-overflow]="shouldShowSeparatedTabs" *ngIf="!isPopup">        
        <graphics-renderer [confId]="configurationId" [decoration]="decoration" [height]="height" [width]="width"></graphics-renderer>
      </div>
    </gridview>
  </div>
</div>
