<!--  Create a debounced textbox with data-toggle=dropdown and a dropdown-menu below it.
      Having data-toggle on the textbox will enable bootstrap to open/close the menu when clicking on the input.
  -->
<div class="autocomplete dropdown" #inputQueryNative>
  <debounced-text-box #inputQuery
                      class="d-block toggler"
                      data-toggle="dropdown"
                      data-flip="false"
                      [view]="autoCompleteView"
                      (onDebounceEnd)="filter($event)">
  </debounced-text-box>
  <div class="dropdown-menu" role="menu" #dropdown [class.empty]="!showNoMatches && filteredItems.length == 0" [ngStyle]="{'max-width': dropdownWidth}">
    <div *ngIf="filteredItems.length > 0; else empty">
      <button *ngFor="let item of filteredItems; trackBy: trackByName" class="dropdown-item text-truncate" [class.selected]="item == selectedItem" type="button" (click)="select(item)">
        <span *ngFor="let namePart of item.nameParts" [class.match]="namePart.isMatch">{{namePart.value}}</span>
      </button>
    </div>
    <ng-template #empty>
      <button *ngIf="showNoMatches" class="dropdown-item">
        <span>{{format(strings.NoMatchesFoundForX, previousFilter)}}</span>
      </button>
    </ng-template>
  </div>
</div>