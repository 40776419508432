import { SimpleChanges, Output, Component, ViewEncapsulation, Injectable, ElementRef, Input, Inject, OnChanges, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, EventEmitter, ViewContainerRef } from '@angular/core';
import { TreeNode } from './treeNode';
import { TreeViewSimpleComponent } from './treeViewSimpleComponent';

@Component({
  selector: 'tree-node-simple',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './treeNodeSimpleComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeNodeSimpleComponent {

  @Input() public node: TreeNode;
  @Input() public selected: boolean = false;
  @Input() public activeNodeId: string;

  @Input() public imageSet = "primary";
   
  public highlightSelected: boolean = false;
  public indentation: any;
  private _parent: TreeViewSimpleComponent;

  public constructor(    
    private cd: ChangeDetectorRef, private viewContainerRef: ViewContainerRef
  ) {

    const _injector = this.viewContainerRef.parentInjector;
    this._parent = _injector.get<TreeViewSimpleComponent>(TreeViewSimpleComponent);

  }

  ngOnInit() {
    this.indentation = Array.apply(null, Array(this.node.level));    
  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes["activeNodeId"]) {
      this.highlightSelected = this.node.id == this.activeNodeId;
    }
  }

  onNodeClick(e: MouseEvent): void {

    if (this._parent) {
      let event = { source: this, node: this.node };
      this._parent.notfiyToAllListeners(event);
    }
    
  }

}