import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ImageModule } from "../image";
import { HelpModule } from "../help";
import { IconModule } from "../icon";

import { SelectComponent } from "./selectComponent";
import { RadioButtonModule } from "../radioButton";
import { DropdownModule } from "../dropdown";
import { SelectedHelpImageComponent } from "./components/selectedHelpImageComponent";


@NgModule({
  imports: [
    CommonModule,
    ImageModule,
    HelpModule,
    DropdownModule,
    IconModule,    
    RadioButtonModule
  ],
  declarations: [
    SelectComponent,        
    SelectedHelpImageComponent    
  ],
  exports: [
    SelectComponent,    
    SelectedHelpImageComponent    
  ]
})

export class SelectModule {

}
