<backdrop-container>
  <regular-popup *ngIf="visible"
                 minHeight="40%"
                 width="50%"
                 [id]="id"
                 [title]="title"
                 (onClosed)="onPopupClosed()">
    <div card-type="body" class="p-4 h-100">
      <p class="multiline" [innerText]="code"></p>
    </div>
</regular-popup>
</backdrop-container>