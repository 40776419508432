<action-box *ngIf="visible" class="detail-item {{extraStyles}}" iconLeft="attributeDecoration" textLeft="{{detailTitle}}" >
  <gridview *ngIf="contentVisible">
    <gridview-row *ngFor="let row of rows" classes="row-data">
      <columntemplate *ngFor="let list of row.cells" classes="min-width col-sm-6  " [class.p-0]="!list.items">
        <list [list]="list" (mouseenter)="openAttributePopup(list)" (click)="openAttributePopup(list)"></list>
      </columntemplate>
    </gridview-row>
  </gridview>
  <div card-type="control" *ngIf="isPrintView">
    <print-checkbox (onValueChange)="onPrintValueChange($event)"></print-checkbox>
  </div>
</action-box>