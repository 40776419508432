import { Component, Input, ViewChild, Output, EventEmitter, ElementRef } from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { BaseComponent } from "../../../pages/shared";
import { InputViewModel, ValueChangeEventArgs } from "../shared";
import { TextBoxComponent } from "../textBox";

@Component({
  selector: 'debounced-text-box',
  templateUrl: './debouncedTextBoxComponent.html'
})
export class DebouncedTextBoxComponent {
  // Use a "smart" obserable search input to make it less wasteful. From https://angular.io/guide/http#more-fun-with-observables

  @Input()
  public view: InputViewModel;

  @Input()
  public delayMs = 300;

  @Input()
  public width = "100%";

  @Output()
  public onDebounceStart = new EventEmitter();

  @Output()
  public onDebounceEnd = new EventEmitter<ValueChangeEventArgs>();

  @ViewChild('inputQuery')
  public textBoxElement: TextBoxComponent;

  public searchTermStream = new Subject<string>();

  ngOnInit() {
    // Use a smart search stream to debounce keys and wait before updating the values for unique inputs.
    this.searchTermStream.pipe(debounceTime(this.delayMs), distinctUntilChanged())      
      .subscribe((query) => {
        this.debounceEnd(query);
      });
  }

  ngOnDestroy() {
    if (this.searchTermStream)
      this.searchTermStream.unsubscribe();
  }

  debounceStart(query: string) {
    this.searchTermStream.next(query);

    this.onDebounceStart.emit({ value: query });
  }

  debounceEnd(query: string) {
    let args = new ValueChangeEventArgs();
    args.element = this.textBoxElement;
    args.value = query;
    args.actionView = this.view;
    this.onDebounceEnd.emit(args);
  }
}