import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input } from "@angular/core";
import * as Immutable from "immutable";
import { Subscription } from "rxjs";
import { PopupInfo } from "../../../../shared/components";
import { Delay, sleep } from "../../../../shared/utils";
import { BaseComponent } from "../../../shared";
import { ListModel } from "../../../shared/components";
import { ParameterMandatoryService } from "../../parameters/shared/parameterMandatoryService";
import { ConfiguratorStore, ConfPageSessionService, PopupIdentifiers } from "../../providers";
import { ListRowModel } from "../shared/listRowModel";

@Component({
  selector: 'summary-param-detail',
  templateUrl: './summaryParamDetailComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummaryParamDetailComponent extends BaseComponent {

  @Input() public list: ListModel;

  readonly HighlighFadeIn: string = 'highlight fade-in';
  readonly HighlighFadeOut: string = 'highlight fade-out';

  public highlight: boolean = false;
  public highlightStyles: string;

  public highlightChangeSubscription: Subscription;
  public emitterSubscription: Subscription;

  public rows: Immutable.List<ListRowModel> = Immutable.List<ListRowModel>();
  public configurationId: number;

  constructor(
    public confPageSessionService: ConfPageSessionService,
    public confStore: ConfiguratorStore,
    @Inject(ChangeDetectorRef) public cd: ChangeDetectorRef,    
    @Inject(ParameterMandatoryService) public parameterMandatoryService: ParameterMandatoryService) {
    super();
  }

  ngOnInit() {
    this.configurationId = this.confPageSessionService.activeConfigurationId;
    this.listenChanges();
  }

  public listenChanges(): void {

    // Unsubscribe the old subscription.
    this.unsubscribe();

    this.highlightChangeSubscription = this.parameterMandatoryService.getMessage().subscribe(() => {
      
      this.highlight = this.parameterMandatoryService.shouldHighlight(this.configurationId, Number(this.list.id));      

      if (this.highlight) {
        this.setHighlight(this.HighlighFadeIn);
      }
      else {
        this.setHighlight(this.HighlighFadeOut);
      }

    });
    
    this.emitterService.getMessage().subscribe((info: PopupInfo<number>) => {

      if (info.id == PopupIdentifiers.Mandatory && info.tag == Number(this.list.id)) {

        // Flash highligh
        this.setHighlight(this.HighlighFadeIn); // Fades in
        sleep(Delay.MS_600).then(x => this.setHighlight(this.HighlighFadeOut));    // Fades out
        sleep(Delay.MS_600).then(x => this.setHighlight("")); // Removes the css from DOM    

      }
    }).unsubscribeOn(this.unsubscribeSubject);
  }

  setHighlight(style) {
      this.highlightStyles = style;
      this.cd.markForCheck();          
  }
  
  ngOnDestroy() {
    this.unsubscribe();
  }

  unsubscribe() {
    if (this.highlightChangeSubscription)
      this.highlightChangeSubscription.unsubscribe();

    if (this.emitterSubscription)
      this.emitterSubscription.unsubscribe();

  }

}