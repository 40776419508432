import "hammerjs";
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode, NgModuleRef } from '@angular/core';

import { AppModule } from './core/boot/appModule';
import { environment } from './environments/environment';
import { VisualizationPluginManager, VisualizationPlugin } from "./core/shared/providers";

class ClientInfo {
  isBrowserCompatible: boolean = null;
  version: string;
}

export class App {

  public NgModuleRef: NgModuleRef<AppModule>;
  public version: string;
  public visualizationVersion: string;

  browserInfo() {

    let ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: tem[1] || '' };
    }

    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge?)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera').replace('Edg ', 'Edge ');
    }

    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null)
      M.splice(1, 1, tem[1]);

    return { name: M[0], version: M[1] };
  }

  getClientInfoFromServer() {

    let webRequest = new XMLHttpRequest();
    webRequest.open("POST", `api/clientcompatible?t=${new Date().getTime()}`, false);

    let data = new FormData();
    let browser = this.browserInfo();
    data.append('BrowserName', browser.name);
    data.append('BrowserVersion', browser.version);

    webRequest.send(data);
    let response = JSON.parse(webRequest.responseText);

    let clienfInfo = new ClientInfo();
    clienfInfo.isBrowserCompatible = (response as any).browserCompatible;
    clienfInfo.version = (response as any).message;
    return clienfInfo;
  } catch(ex) {
    console.error(ex);
  }

  getClientVersionFromJSFile() {
    return $('html').html().match(/main.(\w)*.js/g)[0].match(/[ \w-]+?(?=\.)/g)[1];
  }

  searchUrlQueryParam(name: string, url: string = window.location.href) {

    name = name.replace(/[\[\]]/g, '\\$&').toLowerCase();
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url.toLowerCase());
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));

  }

  addStylesheet(cssFile: string) {
    var ss = document.createElement("link");
    ss.type = "text/css";
    ss.rel = "stylesheet";
    ss.href = cssFile;
    document.getElementsByTagName("head")[0].appendChild(ss);
  }

  boot() {

    // Workaround code for chrome based browsers
    // This allows modification of the url in browser history
    let hash = location.hash;
    if (hash && hash.length > 0) {
      location.replace("#/");
      location.replace(hash);
    }

    let mode = this.searchUrlQueryParam('mode');
    if (mode == 'headerless') {
      this.addStylesheet('css/main-headerless.css');
      document.body.classList.add('headerless'); // Helps for customization.

    }
    else {
      this.addStylesheet('css/main.css');
    }
    this.addStylesheet('css/custom.css');

    let clienfInfo = this.getClientInfoFromServer();

    if (!clienfInfo || !clienfInfo.version || clienfInfo.version.trim().length == 0) {
      const message = "Combinum could not fetch the client app version. Application could not be started. Check console logs fro more info.";
      alert(message);
      throw new Error(message);
    }
        
    if (!clienfInfo.isBrowserCompatible) {

      alert("Browser is incompatible");
      document.body.innerHTML = "<div class='compatible-error'>" + clienfInfo.version + "</div>";
      throw new Error(clienfInfo.version);
    }

    if (clienfInfo.version != this.getClientVersionFromJSFile()) {
      const message = "Client app is incompatible with the server. Reloading the page will fetch a compatible client app. Confirm to reload the page?";
      if (confirm(message))
        window.location.reload(true);

      throw new Error(message);
    }

    window.combinumApp = this;
    this.version = clienfInfo.version;
    this.defineVisualizationHookups();

    // depending on the env mode, enable prod mode
    if (environment.production) {
      enableProdMode();
    }

    platformBrowserDynamic().bootstrapModule(AppModule, { preserveWhitespaces: false }).then(ngModuleRef => {

      this.NgModuleRef = ngModuleRef;      
      
      // Otherwise, log the boot error
    }).catch((err) => {
      console.error(err);
      alert("Unhandled application error. Check console logs for details.");
    });
  }

  getService(token: any) {
    return this.NgModuleRef.injector.get(token, null);
  }

  private _visualizationPluginManager: VisualizationPluginManager;
  private get visualizationPluginManager() {
    if (!this._visualizationPluginManager)
      this._visualizationPluginManager = this.getService(VisualizationPluginManager);

    return this._visualizationPluginManager;
  }

  defineVisualizationHookups() {

    let outerThis: App = this;
    Object.defineProperty(window, 'registerVisualizationPlugin', {
   
      get() {

        let tempPluginObject = {};
        let scriptElement: HTMLScriptElement = document.currentScript as HTMLScriptElement;
        outerThis.visualizationPluginManager.register(scriptElement.src, tempPluginObject);
        return tempPluginObject;
      },

      set(pluginObject: any) {

        let scriptElement: HTMLScriptElement = document.currentScript as HTMLScriptElement;

        let url = scriptElement.src;
        let queryIndex = url.indexOf("?");
        if (queryIndex > -1)
          url = url.substring(0, queryIndex);

        outerThis.visualizationPluginManager.register(url, pluginObject);
      },
      enumerable: true,
      configurable: true
    });
  }
}