import { Component, OnInit, AfterContentInit, Inject, Input, EventEmitter, Output } from "@angular/core";
import { BaseButtonComponent } from "./baseButtonComponent";

@Component({
  selector: 'button-standard',
  templateUrl: './standardButtonComponent.html'
})
export class StandardButtonComponent extends BaseButtonComponent {

  @Input() text: string;

  @Input() icon: string;

  @Input() imageSet: string;

  @Input() iconWidth: string = "32px";

  @Input() iconHeight: string = "32px";
}