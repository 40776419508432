import { Injectable } from "@angular/core";
import { AbstractCodeValue, ConfUIItem, CodeDecoration } from "../../../models";
import { ProductDataStore } from "../../../providers/productData";
import { ConfPageSessionService } from "../../../../configurator/providers";
import { BaseCodeValuesProvider } from "./baseCodeValuesProvider";


@Injectable()
export class CalculatedCodeValuesProvider extends BaseCodeValuesProvider {
    
  constructor(public productStore: ProductDataStore, public storeSession: ConfPageSessionService) {
    super(productStore, storeSession);
  }

  /**
   * Returns the <ConfCodeValue> by fileId
   * @param fileId
   */
  getCodeValue(fileId: number): AbstractCodeValue {

    let codeValue = this.storeSession.confStore.getConfValue(this.configurationId, this.sessionId, fileId);
    return codeValue as AbstractCodeValue;

  }

}