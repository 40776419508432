import { Injectable, Inject } from '@angular/core';

import { AppStore, AppAction, UIAction, StoreResponse, ActionInfo, PageActions, GlobalDataActions, Actions } from "../../shared/state";

@Injectable()
export class SelectorActionCreator {

  constructor(    
    @Inject(AppStore) public appStore: AppStore
  ) {
  }

  dispatcAddSelectorUIData = (pageIdentifier) => this.appStore.dispatch(this.addSelectorUIData(pageIdentifier)) as AppAction<any>;
  addSelectorUIData(pageIdentifier: number): UIAction<any> {
    return {
      type: PageActions.CREATE_SELECTOR_UI_DATA, pageId: pageIdentifier, executeAsync: null
      //executeAsync: (dispatch) => {
      //  dispatch({ type: PageActions.CREATE_SELECTOR_UI_DATA, requestId: pageIdentifier });
      //}
    }
  }
  
  dispatchSetSelectedAssortments = (assortmentIds: number[], pageIdentifier: number, requestId: number) => this.appStore.dispatch(this.setSelectedAssortments(assortmentIds, pageIdentifier, requestId)) as AppAction<number[]>;
  setSelectedAssortments(assortmentIds: number[], pageIdentifier: number, requestId: number): UIAction<number[]> {
    return {

      type: PageActions.SET_SELECTED_ASSORTMENTS, id: requestId, payload: assortmentIds, pageId: pageIdentifier, 
      executeAsync: (dispatch) => {
        dispatch({ type: Actions.UPDATE_ACTION_STATUS, requestId: requestId });
      }

    }
  }
}