import { BaseListModel } from "./baseListModel";
import * as Immutable from "immutable";
import { ListItemModel } from "./listItemModel";

export class ListModel extends BaseListModel {
  
  public static readonly HELP_ICON = "helpIcon";  
  public static readonly BACKGROUND_IMAGE = "backgroundImage";  
  public static readonly ITEMS = "items";
  public static readonly ID = "id";
  public static readonly TAG = "tag";

  public readonly helpIcon: string;
  setHelpIcon(helpIcon: string): this {
    return this.setInternalProperty(ListModel.HELP_ICON, helpIcon); 
  }    

  public readonly backgroundImage: string;
  setBackgroundImage(backgroundImage: string): this {
    return this.setInternalProperty(ListModel.BACKGROUND_IMAGE, backgroundImage); 
  }    

  public readonly items: Immutable.List<ListItemModel>;
  setItems(items: Immutable.List<ListItemModel>): this {
    return this.setInternalProperty(ListModel.ITEMS, items); 
  }  

  public readonly id: string;
  setId(id: string): this {
    return this.setInternalProperty(ListModel.ID, id); 
  }    

  public readonly tag: any;
  setTag(tag: any): this {
    return this.setInternalProperty(ListModel.TAG, tag);
  }    

}