<backdrop-container>
  <regular-popup *ngIf="visible"
                 [id]="id"
                 [title]="strings.ChangeOwnership"
                 [showHeader]="false"
                 [showFooter]="true"  
                 [height]="'300px'"
                 (okClick)="okClick()"
                 [okButtonText]="strings.Ok"
                 [cancelButtonText]="strings.Cancel"
                 (onClosed)="onPopupClosed()">

    <div card-type="body" class="p-4">
      <div>
        <dropdown [itemsView]="workgroupItemViews"
                  [view]="workgroupView"
                  menuHeight="200px"
                  >
        </dropdown>
        <check-box *ngIf="changeEveryRevisionView" class="d-block pt-4" [view]="changeEveryRevisionView"></check-box>
      </div>
    </div>
  </regular-popup>
</backdrop-container>