import { Component, Input, Inject, EventEmitter, Output } from "@angular/core";
import { BaseComponent } from "../..";
import { SvgActions } from "./svgActions";

@Component({
  selector: 'graphics-icons',
  templateUrl: './graphicsControlIconsComponent.html',
})
export class GraphicsControlIconsComponent extends BaseComponent {

  @Output()
  action = new EventEmitter();

  zoomIn(): void {
    this.action.emit(SvgActions.Plus);
  }

  reset(): void {
    this.action.emit(SvgActions.Reset);
  }

  zoomOut(): void {
    this.action.emit(SvgActions.Minus);
  }

}