import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BreadcrumbComponent } from "./breadcrumbComponent";

export * from "./breadcrumbComponent";
export * from "./breadcrumbItem";

@NgModule({
  imports: [
    CommonModule    
  ],
  declarations: [
    BreadcrumbComponent
  ],
  exports: [
    BreadcrumbComponent
  ],

  providers: []
})
export class BreadcrumbModule {

}