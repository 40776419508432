<div class="header align-middle pm-color-darker text-uppercase">
  <div class="row w-100 h-100 pl-4 mx-0">
    <div class="col align-middle pl-2 title">
      <h3>{{title}}</h3>
    </div>
    <div *ngIf="iconKey" class="col-auto align-middle px-0" role="button" (click)="onIconClick.emit($event)">
      <a class="align-middle h-100 px-3">
        <co-image width="20" height="20" type="svg" [key]="iconKey"></co-image>
      </a>
    </div>
  </div>
</div>