import { Inject, Component, ViewChild, Input, ChangeDetectorRef } from "@angular/core";
import { ConfInfo, GlobalSettings, ConfigurationSearch, ConfSearchCriteria, RequestViews, SaveSearchMessage, WorkGroup, AuthorizationGroup, RefObject } from "../../../shared/models";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import { PopupService, InputViewModel, NotificationService, NotificationInfo, NotificationType, BackdropComponent } from "../../../../shared/components";
import { SearchDataMessageProvider, SearchDataStore } from "../../../shared/providers/searchData";
import { AccountDataStore } from "../../../shared/providers/accountData";
import { ManagedSubscription } from "../../../../shared/managedSubscription";
import { ModelFactory } from "../../../shared/providers";

@Component({
  selector: 'configuration-search-popup',
  templateUrl: './configurationSearchPopupComponent.html'
})
export class ConfigurationSearchPopupComponent extends AbstractPopupComponent {

  @Input()
  public configurationSearch: ConfigurationSearch;

  public id: string = "configuration-search-popup";

  public titleView: InputViewModel;
  public resultsInlineView: InputViewModel;

  public maxInlineResultsView: InputViewModel;
  public maxInlineResultsViews: Array<InputViewModel>;

  public scopeView: InputViewModel;
  public scopeItemViews: Array<InputViewModel>;

  public workgroupView: InputViewModel;
  public workgroupItemViews: Array<InputViewModel>;

  public authorizationGroupView: InputViewModel;
  public authorizationGroupItemViews: Array<InputViewModel>;

  public saveMessageSubscription: ManagedSubscription;
  public serverErrors: Array<string> = [];

  public title: string;

  public saveAsNewView: InputViewModel;

  @ViewChild(BackdropComponent)
  public backdropComponent: BackdropComponent;

  constructor(
    @Inject(PopupService) public popupService: PopupService,
    @Inject(SearchDataStore) public searchDataStore: SearchDataStore,
    @Inject(AccountDataStore) public accountStore: AccountDataStore,
    public searchDataMesssageProvider: SearchDataMessageProvider,
    public notificationService: NotificationService,
    public modelFactory: ModelFactory,
    public cdr: ChangeDetectorRef
  ) {
    super(cdr, popupService);
  }

  public open(tag: any) {
    this.configurationSearch = tag;
    this.show();
  }

  show(): void {
    this.title = this.isExistingSearch ? this.strings.EditSearch : this.strings.SaveSearch;

    this.titleView = new InputViewModel(this.strings.Title);
    this.titleView.value = this.configurationSearch.title;

    this.resultsInlineView = new InputViewModel(this.strings.ShowResultsInline);
    this.resultsInlineView.value = this.configurationSearch.inlineResults || false;

    if (this.isExistingSearch) {
      this.saveAsNewView = new InputViewModel(this.strings.SaveAsNew);
      this.saveAsNewView.value = false;
    }

    this.maxInlineResultsViews = Array<InputViewModel>();
    let resultCounts = ["5", "10", "15", "20"];
    for (let i = 0; i < resultCounts.length; i++) {
      let view = new InputViewModel(resultCounts[i]);
      view.id = resultCounts[i];
      this.maxInlineResultsViews.push(view);
    }
    this.maxInlineResultsView = new InputViewModel(this.strings.MaxInlineResults);
    this.maxInlineResultsView.formattedValue = this.maxInlineResultsView.value = this.configurationSearch.maxInlineResults ? this.configurationSearch.maxInlineResults.toString() : "10";
    
    this.scopeItemViews = Array<InputViewModel>();
    let user = this.accountStore.getUser();

    // Set up scopes. Superuser has more scopes.
    let scopes: { title: string, id: string }[] = [];

    if (user.systemAuthorization.canCreatePrivateSearch) {
      scopes.push({ title: this.strings.Private, id: "Private" });
    }

    if (user.systemAuthorization.canCreatePublicSearch) {
      scopes.push({ title: this.strings.Public, id: "Public" });
    }

    if (user.systemAuthorization.canCreateAnyWorkGroupSearch || user.systemAuthorization.canCreateOwnWorkGroupSearch) {
      scopes.push({ title: this.strings.WorkGroup, id: "WorkGroup" });
    }

    if (user.systemAuthorization.canCreateAuthorizationGroupSearch) {
      scopes.push({ title: this.strings.AuthorizationGroup, id: "AuthorizationGroup" });
    }

    for (let i = 0; i < scopes.length; i++) {
      let view = new InputViewModel(scopes[i].title);
      view.id = scopes[i].id;
      this.scopeItemViews.push(view);
    }

    this.scopeView = new InputViewModel(this.strings.Scope);
    let existingScope = scopes.find(x => x.id === this.configurationSearch.scope) || scopes[0];
    this.scopeView.value = existingScope.id;
    this.scopeView.formattedValue = existingScope.title;

    this.setScopeRelatedViews();

    this.showInternal();
  }

  public get resultsInline(): boolean {
    return typeof this.resultsInlineView.value === "boolean" ? this.resultsInlineView.value : this.resultsInlineView.value === "true";
  }

  public get saveAsNew(): boolean {
    return this.saveAsNewView != null && (typeof this.saveAsNewView.value === "boolean" ? this.saveAsNewView.value : this.saveAsNewView.value === "true");
  }

  public get isExistingSearch(): boolean {
    return this.configurationSearch && this.configurationSearch.longId > 0 && !this.saveAsNew;
  }

  public setScopeRelatedViews() {
    // Update related dropdown depending on current scope.
    this.workgroupView = null;
    this.workgroupItemViews = [];
    this.authorizationGroupView = null;
    this.authorizationGroupItemViews = [];

    let user = this.accountStore.getUser();

    if (this.scopeView.value === "WorkGroup") {

      if (user.systemAuthorization.canCreateAnyWorkGroupSearch) {
        this.accountStore.getEntities<WorkGroup>(WorkGroup.name).subscribe(response => {
          response.data.forEach(workgroup => {
            let view = new InputViewModel(workgroup.title);
            view.id = workgroup.longId.toString();
            this.workgroupItemViews.push(view);
          });
        });
      }
      else if (user.systemAuthorization.canCreateOwnWorkGroupSearch) {

        let userWorkGroup = user.workGroup;

        let view = new InputViewModel(userWorkGroup.text);
        view.id = userWorkGroup.longId.toString();
        this.workgroupItemViews.push(view);
      }

      this.workgroupView = new InputViewModel(this.strings.WorkGroup);

      let selectedWorkgroup = this.configurationSearch.workGroup ? this.configurationSearch.workGroup : user.workGroup;
      this.workgroupView.value = selectedWorkgroup.text;
      this.workgroupView.id = selectedWorkgroup.longId.toString();
    }
    else if (this.scopeView.value === "AuthorizationGroup") {
      this.accountStore.getEntities<AuthorizationGroup>(AuthorizationGroup.name).subscribe(response => {
        response.data.forEach(authGroup => {
          let view = new InputViewModel(authGroup.title);
          view.id = authGroup.longId.toString();
          this.authorizationGroupItemViews.push(view);
        });
        this.authorizationGroupView = new InputViewModel(this.strings.AuthorizationGroup);
        this.authorizationGroupView.formattedValue = this.configurationSearch.authorizationGroup ? this.configurationSearch.authorizationGroup.text : null;
        this.authorizationGroupView.value = this.configurationSearch.authorizationGroup ? this.configurationSearch.authorizationGroup.longId.toString() : null;
      });
    }
  }

    public updateConfSearch() {
    this.configurationSearch = this.configurationSearch.setTitle(this.titleView.value || "");
    this.configurationSearch = this.configurationSearch.setInlineResults(this.resultsInline);
      this.configurationSearch = this.configurationSearch.setMaxInlineResults(this.maxInlineResultsView.value);
      this.configurationSearch = this.configurationSearch.setScope(this.scopeView.value);

    if (this.workgroupView) {
      let workgroup = this.configurationSearch.workGroup || this.modelFactory.createAny<RefObject>(RefObject.name);
      workgroup = workgroup.setLongId(parseInt(this.workgroupView.value));
      this.configurationSearch = this.configurationSearch.setWorkGroup(workgroup);
    }

    if (this.authorizationGroupView) {
      let authGroup = this.configurationSearch.authorizationGroup || this.modelFactory.createAny<RefObject>(RefObject.name);
      authGroup = authGroup.setLongId(parseInt(this.authorizationGroupView.value));
      this.configurationSearch = this.configurationSearch.setAuthorizationGroup(authGroup);
    }

    // Remove longId if saving as new.
    this.configurationSearch = this.configurationSearch.setLongId(this.saveAsNew ? null : this.configurationSearch.longId);
  }

  public listenOnChange() {
    this.unsubscribe();

    this.saveMessageSubscription = this.searchDataMesssageProvider.onMessages<SaveSearchMessage>(SaveSearchMessage.name, {
      next: (messages) => {
        let errors: Array<string>;
        let success = true;
        messages.forEach(message => {
          success = success && message.success;
          errors = message.messages.toArray().concat(errors);
        });

        this.configurationSearch = this.searchDataStore.getConfigurationSearch(messages.first().configurationSearchId);

        if (success) {
          this.backdropComponent.close();
          this.onPopupClosed();

          let title = this.configurationSearch.title ? `'${this.configurationSearch.title}'` : this.strings.Search;

          this.notificationService.notify(<NotificationInfo>{
            title: this.strings.Success,
            message: title + " saved successfully.", // TODO localize
            type: NotificationType.Success,
            selfClose: true
          });
        }
        else {
          this.serverErrors = errors;
        }

        this.unblockUI();

        this.unsubscribe();
      },
      listenNewEventsOnly: true
    });
  }

  public storeSearch() {
    if (!this.titleView.value) {
      this.titleView.errors = [this.strings.TitleIsMissing];
      return false;
    }

    this.blockUI();

    this.titleView.errors = [];

    this.serverErrors = [];

    this.updateConfSearch();

    this.listenOnChange();

    this.searchDataStore.saveConfigurationSearch(this.configurationSearch, RequestViews.Search);
  }

  public unsubscribe() {
    if (this.saveMessageSubscription)
      this.saveMessageSubscription.unsubscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();

    super.ngOnDestroy();
  }
}