import { Injectable } from "@angular/core";
import { expand } from "rxjs/operators";

@Injectable()
export class BrowserInfo {
  
  public info: any;

  public isFirefox: boolean;

  public isIE: boolean;
  
  public isMobile: boolean;

  public isChrome: boolean;

  public isSafari: boolean;

  public isEdge: boolean;

  public isOpera: boolean;

  public name: string;

  protected readonly Unknown: string = 'Unknown'

  // More optimized way to access the value
  protected readonly BrowserFullNameMap = new Map<string, string>([
    [BrowserType[BrowserType.IE], 'Internet explorer'],
    [BrowserType[BrowserType.Edge], 'Edge'],
    [BrowserType[BrowserType.Chrome], 'Google chrome'],
    [BrowserType[BrowserType.Safari], 'Safari'],
    [BrowserType[BrowserType.Firefox], 'Firefox'],
    [BrowserType[BrowserType.Opera], 'Opera']
  ]);

  constructor() {

    this.info = this.browserInfo;

    this.name = this.info.name
    this.isIE = this.name == BrowserType[BrowserType.IE];
    this.isEdge = this.name == BrowserType[BrowserType.Edge];
    this.isFirefox = this.name == BrowserType[BrowserType.Firefox];
    this.isChrome = this.name == BrowserType[BrowserType.Chrome];
    this.isOpera = this.name == BrowserType[BrowserType.Opera];
    this.isSafari = this.name == BrowserType[BrowserType.Safari];
    this.isMobile = (/android|webos|iphone|ipad|ipod|blackberry|windows phone/).test(navigator.userAgent.toLowerCase());

  }

  public get fullName(): string
  {
    return this.getFullName(this.name);
  }

  protected getFullName(name: string): string {
    return this.BrowserFullNameMap.has(name) ? this.BrowserFullNameMap.get(name) : this.Unknown;
  }

  public getSupportedBrowsersInfo(ignoreBrowsers: Array<string>): string {

    let supportedBrowsers = new Array<string>();
    Array.from(this.BrowserFullNameMap.keys()).forEach(browser => {
      ;
      if (ignoreBrowsers.indexOf(browser) > -1)
        return;

      const fullName = this.getFullName(browser);
      supportedBrowsers.push(fullName);
      
    });

    return supportedBrowsers.join(', ');
  }


  public get browserInfo(): any {

    let ua = navigator.userAgent, tem;
    let matches: any = navigator.userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(matches[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];      
      return { name: BrowserType[BrowserType.IE], version: tem[1] || '' };
    }

    if (matches[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge?)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', BrowserType[BrowserType.Opera]).replace('Edg ', BrowserType[BrowserType.Edge]);
    }

    matches = matches[2] ? [matches[1], matches[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null)
      matches.splice(1, 1, tem[1]);

    return { name: matches[0], version: matches[1] };

  }

}

export enum BrowserType {

  IE,

  Edge,

  Chrome,

  Safari,

  Firefox,

  Opera

}