import * as Immutable from "immutable";

import { ImmutableObject } from "../../../../shared/immutableObject";
import { User } from "../entities/user";
import { AbstractAccountMessage } from "./messages/abstractAccountMessage";
import { BaseEntity } from "../../baseEntity";

export class AccountDataResponse extends ImmutableObject {

  protected readonly USER = "user";
  protected readonly MESSAGES = "messages";
  protected readonly ENTITIES = "entities";
  protected readonly ENTITY_IDS_BY_CLASS_NAME = "entityIdsByClassName";
  protected readonly SHOW_RECAPTCHA = "showRecaptcha";
  
  get user(): User { return this.getInternalValue<User>(this.USER); }
  setUser(user: User): this { return this.setInternalValue(this.USER, user); }

  get messages(): Immutable.List<AbstractAccountMessage> { return this.getInternalValue<Immutable.List<AbstractAccountMessage>>(this.MESSAGES); }
  setMessages(messages: Immutable.List<AbstractAccountMessage>): this { return this.setInternalValue(this.MESSAGES, messages); } 

  get entities(): Immutable.Map<number, BaseEntity> { return this.getInternalValue<Immutable.Map<number, BaseEntity>>(this.ENTITIES); }
  setEntities(entities: Immutable.Map<number, BaseEntity>): this { return this.setInternalValue(this.ENTITIES, entities); }

  get entityIdsByClassName(): Immutable.Map<string, Immutable.List<number>> { return this.getInternalValue<Immutable.Map<string, Immutable.List<number>>>(this.ENTITY_IDS_BY_CLASS_NAME); }
  setEntityIdsByClassName(entityIdsByClassName: Immutable.Map<string, Immutable.List<number>>): this { return this.setInternalValue(this.ENTITY_IDS_BY_CLASS_NAME, entityIdsByClassName); }    

  get showRecaptcha(): boolean { return this.getInternalValue<boolean>(this.SHOW_RECAPTCHA); }
  setShowRecaptcha(showRecaptcha: boolean): this { return this.setInternalValue(this.SHOW_RECAPTCHA, showRecaptcha); }
}