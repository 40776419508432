import { Component, OnInit, AfterContentInit, Inject, Input, EventEmitter, Output } from "@angular/core";
import { BaseButtonComponent } from "./baseButtonComponent";

@Component({
  selector: 'button-icon',
  templateUrl: './iconButtonComponent.html'
})
export class IconButtonComponent extends BaseButtonComponent {

  @Input() icon: string;

  @Input() imageSet: string;

  @Input() iconWidth: string = "32px";

  @Input() iconHeight: string = "32px";

  @Input() tooltip: string;

  // Makes rounded background If It is set to true, otherwise background would be ignored.
  @Input() rounded: boolean = false;

}