import { BaseObject } from "../../../../shared/baseObject";
import { StoredConfResponseFormat } from "./";

export class ConfResponseFormat extends BaseObject {

  children?: boolean;

  configurationValues?: boolean;

  attributes?: boolean;

  confProperties?: boolean;

  codeValues?: boolean;

  visualizationValues?: boolean;

  formValues?: boolean;

  graphics?: boolean;

  documents?: boolean;

  price?: boolean;

  bom?: boolean;

  dataSelectors?: boolean;

  headers?: boolean;

  reports?: boolean;

  revisions?: boolean;

  history?: boolean;

  newParameters?: boolean;

  oldParameters?: boolean;

  attachments?: boolean;

  storedData?: StoredConfResponseFormat;
}