import * as Immutable from "immutable"

import { AbstractConfigurationMessage } from "./abstractConfigurationMessage"
import { ParamInfo } from ".."

export class MandatoryParamsMessage extends AbstractConfigurationMessage {

  protected readonly MANDATORY_PARAMS = "mandatoryParams";
  protected readonly IS_NEXT_STATE_INCLUDED = "isNextStateIncluded";

  get mandatoryParams(): Immutable.List<ParamInfo> { return this.getInternalValue<Immutable.List<ParamInfo>>(this.MANDATORY_PARAMS); }
  setMandatoryParams(mandatoryParams: Immutable.List<ParamInfo>): this { return this.setInternalValue(this.MANDATORY_PARAMS, mandatoryParams); }

  get isNextStateIncluded(): boolean { return this.getInternalValue<boolean>(this.IS_NEXT_STATE_INCLUDED); }
  setIsNextStateIncluded(isNextStateIncluded: boolean): this { return this.setInternalValue(this.IS_NEXT_STATE_INCLUDED, isNextStateIncluded); }
}