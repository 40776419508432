import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output, ViewEncapsulation } from '@angular/core';
import { InputViewModel } from "../../../../../shared/components";
import { ImageSets } from '../../../../../shared/utils';
import { BaseComponent } from "../../../../shared";
import { ConfAutoCompleteItem, ConfAutoCompleteMessage, ConfDataResponse, ConfInfo, ImportOperationMessage, Product } from "../../../../shared/models";
import { ProductDataStore } from '../../../../shared/providers/productData';
import { ConfiguratorStore, ConfPageSessionService } from "../../../providers";
import { CompositeActions, CompositeActionService, ICompositeDataInfo } from '../compositeActionService';
import { GlobalDataStore } from '../../../../shared/providers/globalData';


@Component({
  selector: 'import-configuration',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './importConfigurationComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportConfigurationComponent extends BaseComponent {

  @Input() public errorMessage: any;

  public disableRevision = false;

  @Output()
  public onCancel = new EventEmitter<any>();  
  public itemsView: Array<InputViewModel> = [];
  public productsGroupView: InputViewModel;
  public identityView: InputViewModel;
  public revisionView: InputViewModel;  
  public configurationIdView: InputViewModel;
  public confInfo: ConfInfo;
  public confItemsView: Array<InputViewModel> = [];
  constructor(
    @Inject(ConfPageSessionService) public confPageSession: ConfPageSessionService,
    @Inject(ProductDataStore) public productDataStore: ProductDataStore,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(CompositeActionService) public actionService: CompositeActionService,
    @Inject(ChangeDetectorRef) public cd: ChangeDetectorRef,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore
  ) {
    super();
  }

  ngOnInit() {
    this.confInfo = this.confStore.getConfInfo(this.confPageSession.activeConfiguration.longId, this.confPageSession.confSessionId);
    this.disableRevision = this.globalDataStore.globalSettings.disableRevisionManagement;
    this.createViewModels();    
  }

  createViewModels(): void {

    // Create product dropdownlist
    this.productsGroupView = new InputViewModel(this.strings.Product);    
    this.productsGroupView.icon = "product";
    this.productsGroupView.iconImageSet = this.getImageSet(ImageSets.ConfiguratorTree);

    this.productDataStore.getProduct(this.confInfo.productId, (parentProduct) => {
      this.populateChildProducts(parentProduct);
    });

    this.identityView = new InputViewModel(this.strings.Identity);
    this.identityView.icon = "identity";

    this.revisionView = new InputViewModel(this.strings.Revision);
    this.revisionView.icon = "revision";

    this.configurationIdView = new InputViewModel(this.strings.Configuration);
    this.configurationIdView.icon = "configuration";

    this.productsGroupView.iconHeight = this.productsGroupView.iconWidth = this.identityView.iconWidth = this.identityView.iconHeight = this.revisionView.iconHeight = this.revisionView.iconWidth = "24px";
  }

  /**
   * Triggers when the item is selected from autoComplete list.
   * @param event
   */
  onAutoCompleteItemSelection(event): void {

    const confIdentity = event.item.value as ConfAutoCompleteItem;
    this.updateViews(confIdentity);

  }

  /**
   * Triggers to retreive the items for autoComplete dropdown
   * @param event
   */
  onPopulateAutoComplete(event): void {

    // TODO: Dropdownlist does not support default value selected that's why this is condition is added.
    // We can remove it after fixing the drodownlist bug.
    if (!this.productsGroupView.value) 
      return;
   
    // Retreive matching configuration show in autoComplete list.
    this.confStore.getImportIdentitiesHint(event.query, Number(this.productsGroupView.value), this.confPageSession.confSessionId).then(response => {

      // Clear the list before populating it again.
      this.confItemsView = [];
      if (response.messages.size > 0) {

        // Read the auto complete message and grab available configuration identities.
        const message = response.messages.get(0) as ConfAutoCompleteMessage
        // Loop through all identities and create model for each.
        message.autoCompleteItems.forEach(confIdentity => {

          let itemView = new InputViewModel();
          itemView.id = confIdentity.longId.toString();
          itemView.name = confIdentity.identity.toString();
          itemView.title = confIdentity.text;
          itemView.value = confIdentity;

          this.confItemsView.push(itemView);          

        })
      }

      // Create the new instance of array to trigger the change.
      this.confItemsView = [...this.confItemsView];

      // Sets the import button interaction based on auto complete result.
      //this.enableImportButton = this.confItemsView.length > 0;
      this.cd.markForCheck();

    });

  }

  populateChildProducts(parentProduct: Product): void {

    this.productDataStore.getChildProducts(parentProduct).subscribe((response) => {

      let products = response.data;

      for (let product of products) {

        let itemView = new InputViewModel();
        itemView.title = product.title;
        itemView.id = product.longId.toString();
        this.itemsView.push(itemView)
      }

      this.productsGroupView.formattedValue = this.itemsView.length > 0 ? this.itemsView[0].title : null;
      this.productsGroupView.value = this.itemsView.length > 0 ? this.itemsView[0].id : null;
      this.cd.markForCheck();

    }).unsubscribeOn(this.unsubscribeSubject);

  }

  onValueChange($event): void {

  }

  updateViews(confIdentity: ConfAutoCompleteItem): void {
    
    this.revisionView.value = confIdentity.revision;

    // Create new instance to detect the changes.    
    this.revisionView = { ...this.revisionView };

    // TODO: This is a temporary solution to set the selected value to AutoComplete. This issue must be 
    // addressed in AutoComplete component.
    this.identityView.tag = confIdentity;

  }

  importClick($event): void {

    // Grab required values
    const identity = this.identityView.value;
    const revision = this.revisionView.value;    
    
    // Send the import command to import the configuration
    this.confStore.importConfiguration(
      this.confInfo.longId,
      null,
      identity,
      revision,
      this.confPageSession.confSessionId

    ).then((response: ConfDataResponse) => {

      if (response.messages.size > 0) {

        const message = response.messages.get(0) as ImportOperationMessage;

        // Imported successfully?
        if (!message.success) {
          this.errorMessage = message.message;
          this.cd.markForCheck();
        }
        else {

          // Notify composite and execute UI node actions, e.g selecting the newly added configuration if required.
          this.actionService.notify(<ICompositeDataInfo<any>>{ action: CompositeActions.Import, tag: response.messages.get(0) });

          this.errorMessage = null;
          this.cd.markForCheck();
        }
      }

    });
    
  }

}