import { Component, Input, ElementRef, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { PushMessageSelection } from '../../providers/pushMessage/pushMessageSelection';
import { UiPopup } from '../../models';
import { RegularPopupComponent, BackdropComponent } from '../../../../shared/components';

@Component({
  selector: 'push-message',
  templateUrl: './pushMessageComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PushMessageComponent {

  @ViewChild(BackdropComponent) protected backdropComponent: BackdropComponent;

  @Input()
  public uiPopup: UiPopup;

  @Input()
  public sessionId: number;

  @Output()
  action = new EventEmitter<PushMessageSelection>();

  @ViewChild(RegularPopupComponent)
  private _popup: RegularPopupComponent;

  public visible = false;

  public title = "";

  public icon = "";

  public width: string;

  public height: string;

  public actions = [];

  public leadParagraph = "";

  public paragraph = "";

  public cssClass = "";

  public style = "";

  public modal = true;

  constructor(
    public cd: ChangeDetectorRef) {

    if (this.backdropComponent)
      this.backdropComponent.add(this._popup);
  }

  ngOnChanges(changes: SimpleChanges) {

    if (this.uiPopup) {

      this.title = this.uiPopup.header;
      this.icon = this.uiPopup.icon;
      this.leadParagraph = this.uiPopup.leadParagraph;
      this.paragraph = this.uiPopup.paragraph;
      this.cssClass = this.uiPopup.cssClass;
      this.style = this.uiPopup.style;
      this.modal = this.uiPopup.modal;

      if (this.uiPopup.width > 0)
        this.width = this.uiPopup.width + "px";
      else
        this.width = null;

      if (this.uiPopup.height > 0)
        this.height = this.uiPopup.height + "px";
      else
        this.height = null;
      
      this.visible = true;
    }

  }

  public show(): void {

    if (this.modal) {
      this.backdropComponent.add(this.popup);
      this.backdropComponent.visible = true;
      this.backdropComponent.updateBackDropStyle();
    }

    setTimeout(() => {
      this.popup.open();
      this.cd.detectChanges();
    }, 0);
  }

  public close(): void {
    this.popup.close();
    this.visible = false;
    this.cd.detectChanges();
  }

  public get popup(): RegularPopupComponent {
    let popupComponent = this._popup;
    if (popupComponent == null) {
      this.visible = true;
      this.cd.detectChanges();
      popupComponent = this._popup;
    }
    return popupComponent;
  }

  public onAction(event: PushMessageSelection) {
    this.action.emit(event);
  }

}