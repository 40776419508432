<ng-container *ngIf="ruleInfo">

  <div class="row p-0 mx-0">
    <co-image key="{{ruleIcon}}" class="col-auto" width="24px" height="24px" style="width:54px;"> </co-image>
    <span class="col ml-2 px-0">
      {{ruleInfo.description ? ruleInfo.description : ruleInfo.title}}
    </span>
  </div>

  <ng-container *ngFor="let pInfo of ruleInfo.parameterInfos">

    <visual-object-link *ngIf="supportNavigation" [configurationId]="configurationId" [paramInfo]="pInfo" [popupId]="popupId">
      <ng-container *ngTemplateOutlet="ruleInfoTemplate"></ng-container>
    </visual-object-link>

    <ng-container *ngTemplateOutlet="!supportNavigation && ruleInfoTemplate"></ng-container>

    <ng-template #ruleInfoTemplate>
      <div class="row mx-0 pt-2">
        <div class="col-auto" style="width:54px;"></div>
        <div class="col-auto px-2">
          <visual-object-icon [paramType]="paramIcons.get(pInfo.longId)"
                              size=30
                              iconSize=12></visual-object-icon>
        </div>
        <div class="col px-0">
          <div class="static-label">{{pInfo.title}}</div>

          <div *ngIf="pInfo.value">
            <div class="d-flex py-1 align-middle" *ngIf="paramValueIcons.get(pInfo.longId); else parameterValueText">
              <co-image [width]="'16'" [height]="'16'" [key]="paramValueIcons.get(pInfo.longId)" [imageSet]="paramValueImageSets.get(pInfo.longId)"></co-image>
            </div>
            <ng-template #parameterValueText>
              <div class="d-flex py-1">
                {{pInfo.value}}
              </div>
            </ng-template>
          </div>

          <div *ngIf="pInfo.paramValues && pInfo.paramValues.size > 0">
            <div *ngFor="let paramValue of getDisplayableParamValues(pInfo.paramValues)" class="d-flex py-1 row m-0">
              <div class="col-auto align-middle pl-0" *ngIf="paramValueIcons.get(paramValue.longId)">
                <co-image [width]="'16'" [height]="'16'" [key]="paramValueIcons.get(paramValue.longId)" [imageSet]="paramValueImageSets.get(paramValue.longId)"></co-image>
              </div>
              <div class="col pl-0">{{paramValue.title}}</div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

  </ng-container>

  <div class="row mb-2">
  </div>

</ng-container>