<action-box *ngIf="visible"
            class="detail-item {{extraStyles}} documents-detail"
            iconLeft="document"
            textLeft="{{detailTitle}}"
            [showHeader]="showHeader">
  <gridview *ngIf="contentVisible">
    <gridview-row *ngFor="let document of documentValues" classes="row-data">
      <columntemplate title="{{document.title}}">
        <documents-link [document]="document" [confSessionId]="storeSession.confSessionId" [activeConfigurationId]="storeSession.activeConfigurationId"></documents-link>
      </columntemplate>
    </gridview-row>
  </gridview>
  <div card-type="control" *ngIf="isPrintView">
    <print-checkbox (onValueChange)="onPrintValueChange($event)"></print-checkbox>
  </div>
</action-box>
