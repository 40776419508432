import * as Immutable from "immutable"

import { ImmutableObject } from "../../../../../shared/immutableObject";

export class PriceReportChildTotal extends ImmutableObject {

  protected readonly CONFIGURATION_ID = "configurationId";
  protected readonly QUANTITY = "quantity";
  protected readonly UNIT_PRICE = "unitPrice";
  protected readonly GRAND_TOTAL = "grandTotal";
  protected readonly RAW_TOTAL = "rawTotal";

  get configurationId(): number { return this.getInternalValue<number>(this.CONFIGURATION_ID); }
  setConfigurationId(configurationId: number): this { return this.setInternalValue(this.CONFIGURATION_ID, configurationId); }

  get quantity(): number { return this.getInternalValue<number>(this.QUANTITY); }
  setQuantity(quantity: number): this { return this.setInternalValue(this.QUANTITY, quantity); }

  get unitPrice(): string { return this.getInternalValue<string>(this.UNIT_PRICE); }
  setUnitPrice(unitPrice: string): this { return this.setInternalValue(this.UNIT_PRICE, unitPrice); }

  get grandTotal(): string { return this.getInternalValue<string>(this.GRAND_TOTAL); }
  setGrandTotal(grandTotal: string): this { return this.setInternalValue(this.GRAND_TOTAL, grandTotal); }  

  get rawTotal(): number { return this.getInternalValue<number>(this.RAW_TOTAL); }
  setRawTotal(rawTotal: number): this { return this.setInternalValue(this.RAW_TOTAL, rawTotal); }  

}