import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, Output, ViewEncapsulation } from "@angular/core";
import * as Immutable from "immutable";
import { BaseComponent } from "../../../../../../core/pages/shared";
import { ComparisonDraggableNodeArgs, DraggableListAction } from "./models/comparisonDraggableNodeArgs";
import { ComparisonDraggableNode, ComparisonDraggableRowNodes } from "./models/comparisonDraggableRowNodes";
import { ComparisonDraggableDataProvider } from "./providers/comparisonDraggableDataProvider";

export enum DragOverPositions {

  // Upper most separator
  FirstSeparator = 'first-separator',

  // Below separator after each node.
  NodeSeparator = 'node-separator',

  // Node
  Node = 'swap-node',

  None = '',

}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'comparison-draggable-list',
  templateUrl: './comparisonDraggableListComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComparisonTreeComponent extends BaseComponent {

  @Input()
  rows: Immutable.List<ComparisonDraggableRowNodes> = Immutable.List<ComparisonDraggableRowNodes>();

  DRAG_OVER_OPTIONS: typeof DragOverPositions = DragOverPositions;

  activeDragOver: DragOverPositions;

  activeDragOverRowId: string;

  sourceNodeId: string;

  dragOverNodeId: string;

  sourceRowId: string;

  rowSeparatorFirstStyle: string;
  rowSeparatorStyle: string;
  nodeStyle: string;

  @Input() compactMode: boolean = false;

  @Output() orderChanged: EventEmitter<ComparisonDraggableNodeArgs> = new EventEmitter<ComparisonDraggableNodeArgs>();

  @Output() select: EventEmitter<ComparisonDraggableRowNodes> = new EventEmitter<ComparisonDraggableRowNodes>();
  
  @Output() cancelDrop: EventEmitter<any> = new EventEmitter<any>();

  dragStarted: boolean = false;

  nodeIndex: number = -1;

  @Input()
  isUpdating: boolean = false;
 
  constructor(protected dataService: ComparisonDraggableDataProvider, protected cd: ChangeDetectorRef) { super(); }

  onDrop(event, row: ComparisonDraggableRowNodes, targetRowIndex: number, position: DragOverPositions) {

    this.dragStarted = false;
    event.preventDefault();
    let rawData = event.dataTransfer.getData("text");
    let args = JSON.parse(rawData) as ComparisonDraggableNodeArgs;

    let targetRow: ComparisonDraggableRowNodes;
    switch (position) {

      case DragOverPositions.FirstSeparator:

        // Create the target zone where node must be dropped.
        targetRow = this.dataService.createAndAddIntoList('nodes', 0, true);                
        break;

      case DragOverPositions.NodeSeparator:

        // Create the target zone where node must be dropped.
        targetRow = this.dataService.createAndAddIntoList('separator', targetRowIndex + 1, true)
        this.activeDragOver = DragOverPositions.None;
        break;

      case DragOverPositions.Node:
        targetRow = row;
        break;
    }
    
    this.activeDragOver = DragOverPositions.None;
    this.activeDragOverRowId = null;
    this.dragOverNodeId = null;    

    // Swap the source node with target zone.
    this.dataService.swapNode(targetRow.id, args.sourceRowId, args.nodeIndex);
    this.dataService.removeEmptyRows();

    args.sourceRowAfterDrag = this.dataService.getRow(args.sourceRowId);
    args.destinationRowAfterDrag = targetRow;

    // Notify to caller on order change.
    this.orderChanged.emit(args);

  }

  onDragStart(event, node: ComparisonDraggableNode, nodeIndex: number, row: ComparisonDraggableRowNodes) {

    
    if (!node || node.isEmpty) {
      event.dataTransfer.effectAllowed = 'none';
      return;
    }

    // Clear the drag data cache (for all formats/types)
    // https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/clearData
    // Note: This method can only be used in the handler for the dragstart event, because that's the only time the drag operation's data store is writeable.
    event.dataTransfer.clearData();  

    this.dragStarted = true;
    this.nodeIndex = row.nodes.findIndex(x => x.id == node.id);

    // Set the dropEffect to move    
    event.dataTransfer.effectAllowed = 'all';

    let args: ComparisonDraggableNodeArgs = new ComparisonDraggableNodeArgs();
    args.node = node;
    args.nodeIndex = nodeIndex;
    args.sourceRowId = row.id;
    let json = JSON.stringify(args);

    this.sourceNodeId = node.id;
    
    // Set the drag's format and data. Use the event target's id for the data 
    event.dataTransfer.setData("text/plain", json);

  }

  onDragOver(event, draggedOverRow: ComparisonDraggableRowNodes, position: DragOverPositions, draggedOverNode?: ComparisonDraggableNode) {

    event.preventDefault();
    
    this.activeDragOverRowId = draggedOverRow ? draggedOverRow.id : null;
    this.dragOverNodeId = draggedOverNode ? draggedOverNode.id : null;

    let args: ComparisonDraggableNodeArgs = {
      node: draggedOverNode,
      sourceNodeId: this.sourceNodeId,
    };

    // Prevent drag & drop if dragging node is same as source or dragging and dragged over node don't have the same index.
    if (draggedOverNode && (draggedOverNode.id == this.sourceNodeId || !this.belongsToIndex(this.sourceNodeId, draggedOverNode.id))) {

      this.activeDragOver = DragOverPositions.None;
      this.activeDragOverRowId = null;
      event.dataTransfer.dropEffect = "none";    
      this.dataService.send({ action: DraggableListAction.DragCancel, data: args });
      
      this.cancelDrop.emit(draggedOverNode);

      return;

    }
    
    this.activeDragOver = position;

    if (draggedOverNode) 
      this.dataService.send({ action: DraggableListAction.DragOver, data: args });

    // Set the dropEffect to move
    event.dataTransfer.dropEffect = "move";
    
  }

  belongsToIndex(nodeId1: string, nodeId2: string): boolean {

    let index1 = this.dataService.getNodeIndex(nodeId1);
    let index2 = this.dataService.getNodeIndex(nodeId2);

    return index1 == index2;

  }

  @HostListener('document:mousemove', ['$event'])
  onHighlightRemove(event) {

    this.activeDragOver = DragOverPositions.None;
    this.activeDragOverRowId = null;    

    
    if (this.dragStarted) {
      this.dragStarted = false;      
      this.cancelDrop.emit({ index: this.nodeIndex });
      this.cd.markForCheck();
    }

  }

}