import { Component, ChangeDetectorRef, Input, Host, Inject, AfterContentInit } from "@angular/core";
import { LineDropdownItemComponent } from '../lineDropdownItemComponent';
import { InputViewModel } from "../../../shared";

@Component({ template: '' })
export abstract class BaseLineItemComponent implements AfterContentInit {

  constructor(public item: LineDropdownItemComponent, public cdr: ChangeDetectorRef) {
    item.template = this;
  }

  public view: InputViewModel;

  ngAfterContentInit() {
    this.view = this.item.view;
  }

  get selected() {
    return this.item.dropdownGroup.isSelected(this.item);
  }

  public onClick($event): void {
    console.log("onclick BaseLineItemComponent");

    // Send click upward to the item
    this.item.onClick($event);
  }
}