import { Inject, Component, Injectable, Input, OnInit, EventEmitter, Output, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { TreeNode } from './treeNode';

@Component({
  selector: 'tree-view-simple',
  templateUrl: './treeViewSimpleComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeViewSimpleComponent implements OnInit {
  
  @Input() public imageSet = "primary";
  @Input() public roots: Array<TreeNode> = [];
  @Input() public activeNodeId: string;

  @Output() public selected: EventEmitter<any> = new EventEmitter();

  public constructor(    
    private cd: ChangeDetectorRef
  ) {
  }

  public ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    // Only for debugging
    if (changes["activeNodeId"]) {

    }
  }

  notfiyToAllListeners(event: any) {
    this.selected.emit(event);
  }

}