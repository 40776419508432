import { Component, Input, ElementRef, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { UiElement, TextType, UiGroup, UiSelect, UiInput } from '../../models';
import { PushMessageSelection } from '../../providers/pushMessage/pushMessageSelection';
import { InputViewModel, ValueChangeEventArgs, DisplayStyle, Positions } from '../../../../shared/components';

@Component({
  selector: 'ui-element',
  templateUrl: './uiElementComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiElementComponent {

  // The view can not work with the base class.
  @Input()
  public uiElement: any = {};

  @Input()
  public sessionId: number;

  @Output()
  action = new EventEmitter<PushMessageSelection>();

  public style = "";

  // Export enum to view.
  public TextType = TextType;

  viewModel: InputViewModel;
  itemsView: InputViewModel[] = [];
  selectedViewId: string;

  textBoxView: InputViewModel;

  checkboxView: InputViewModel;

  constructor(
    public cd: ChangeDetectorRef
  ) {
  }

  public DISPLAY_STYLE_OPTIONS: typeof DisplayStyle = DisplayStyle;

  ngOnChanges(changes: SimpleChanges) {

    this.style = "";

    if (this.uiElement.className == "UiSelect")
      this.createViewModels();
    else if (this.uiElement.className == "UiInput" && this.uiElement.type == "text") {

      let uiInput = this.uiElement as UiInput;

      this.textBoxView = new InputViewModel(this.uiElement.id);
      this.textBoxView.title = "";

      // Set default value, if any.
      if (uiInput.value) {

        this.textBoxView.value = uiInput.value;

        let pushMessageSelection = new PushMessageSelection();

        pushMessageSelection.sessionId = this.sessionId;
        pushMessageSelection.key = this.uiElement.id;
        pushMessageSelection.value = this.textBoxView.value;

        this.action.emit(pushMessageSelection);
      }

      // Set width and height.
      if (this.uiElement.width > 0)
        this.textBoxView.width = this.uiElement.width + "px";
      if (this.uiElement.height > 0)
        this.textBoxView.height = this.uiElement.height + "px";
    }
    else if (this.uiElement.className == "UiInput" && this.uiElement.type == "checkbox") {

      let uiInput = this.uiElement as UiInput;

      this.checkboxView = new InputViewModel(this.uiElement.id);
      this.checkboxView.title = "";

      // Set default value, if any.
      if (uiInput.value) {

        this.checkboxView.value = uiInput.value;

        let pushMessageSelection = new PushMessageSelection();

        pushMessageSelection.sessionId = this.sessionId;
        pushMessageSelection.key = this.uiElement.id;
        pushMessageSelection.value = this.checkboxView.value;

        this.action.emit(pushMessageSelection);
      }

      if (this.uiElement.width > 0)
        this.checkboxView.width = this.uiElement.width + "px";

      this.checkboxView.labelPosition = Positions.Above;
    }
    else {
      if (this.uiElement.width > 0)
        this.style += "width:" + this.uiElement.width + "px;";

      if (this.uiElement.height > 0)
        this.style += "height:" + this.uiElement.height + "px;";

      if (this.uiElement.className == "UiGroup") {
        let uiGroup = this.uiElement as UiGroup;

        // Width has higher prio.
        if (!this.uiElement.width && uiGroup.columnCount > 0)
          this.style += "width:" + uiGroup.columnCount * 10 + "px;";

        // Height has higher prio.
        if (!this.uiElement.height && uiGroup.rowCount > 0)
          this.style += "height:" + uiGroup.rowCount * 10 + "px;";
      }
    }

    if (this.uiElement.style)
      this.style += this.uiElement.style;
  }

  onCheckChanged(event: ValueChangeEventArgs): void {

    let pushMessageSelection = new PushMessageSelection();

    pushMessageSelection.sessionId = this.sessionId;
    pushMessageSelection.key = this.uiElement.id;
    pushMessageSelection.value = event.value;

    this.action.emit(pushMessageSelection);

    this.cd.detectChanges();
  }

  onTextValueChange(event: ValueChangeEventArgs): void {

    let pushMessageSelection = new PushMessageSelection();

    pushMessageSelection.key = this.uiElement.id;
    pushMessageSelection.value = event.value;
    pushMessageSelection.sessionId = this.sessionId;

    this.action.emit(pushMessageSelection);
  }

  public onAction(event:PushMessageSelection) {
    // Notify server.
    this.action.emit(event);
  }

  public onClick(id: string) {
    // Notify server.

    let pushMessageSelection = new PushMessageSelection();

    pushMessageSelection.sessionId = this.sessionId;
    pushMessageSelection.id = id;
    pushMessageSelection.sessionId = this.sessionId;

    this.action.emit(pushMessageSelection);
  }

  createViewModels(): void {

    let select = this.uiElement as UiSelect;

    this.viewModel = new InputViewModel(select.id);
    this.viewModel.title = "";
    this.viewModel.helpImagePosition = null;

    // Set width and height.
    if (this.uiElement.width > 0)
      this.viewModel.width = this.uiElement.width + "px";
    if (this.uiElement.height > 0)
      this.viewModel.height = this.uiElement.height + "px";

    this.itemsView = Array<InputViewModel>();

    // Pre selected value.
    if (select.value) {
      this.selectedViewId = select.value;

      let pushMessageSelection = new PushMessageSelection();

      pushMessageSelection.key = select.id;
      pushMessageSelection.value = select.value;
      pushMessageSelection.sessionId = this.sessionId;

      this.action.emit(pushMessageSelection);
    }

    select.titleByValue.forEach((keyValuePair) => {

      let itemModel = new InputViewModel(keyValuePair.value);
      itemModel.id = keyValuePair.key;

      if (itemModel.id == this.selectedViewId) {
        this.viewModel.value = itemModel.id;
        this.selectedViewId = itemModel.id;
      }

      this.itemsView.push(itemModel);
    })

  }

  public onSelectValueChange($event: ValueChangeEventArgs): void {

    let select = this.uiElement as UiSelect;

    let pushMessageSelection = new PushMessageSelection();

    pushMessageSelection.key = select.id;
    pushMessageSelection.value = this.viewModel.value;
    pushMessageSelection.sessionId = this.sessionId;

    this.action.emit(pushMessageSelection);

    this.cd.detectChanges();
  }

}