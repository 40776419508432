import { BaseEntity } from "../../baseEntity";

export class UserInfo extends BaseEntity {

  protected readonly NAME = "name";
  protected readonly USERNAME = "username";
    
  get name(): string { return this.getInternalValue<string>(this.NAME); }
  setName(name: string): this { return this.setInternalValue(this.NAME, name); }

  get userName(): string { return this.getInternalValue<string>(this.USERNAME); }
  setUserName(userName: string): this { return this.setInternalValue(this.USERNAME, userName); }
}