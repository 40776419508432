import * as Immutable from "immutable";
import { BaseCommand } from "./baseCommand";
import { ConfResponseFormat } from "../confResponseFormat";

export abstract class AbstractConfDataCommand extends BaseCommand {

  configurationId: number;

  // Used for Accordion child configurations
  subConfigurationId?: number;

  confSessionId?: number;

  responseFormat?: ConfResponseFormat;

  /** Used in future to get limited response which includes these tabs only */
  tabs?: Array<number>;

  confParams?: Immutable.Map<string, string>;

}