import { Component, Input, Output, EventEmitter, ViewContainerRef, ComponentFactoryResolver, ViewChild, AfterContentInit } from "@angular/core";
import { InputViewModel } from "./models";
import { ValueChangeEventArgs, RestrictValueChangeEventArgs } from "./inputControlEventArgs";
import * as Immutable from "immutable";
import { ImageSetAccessor, ImageSets } from "../../utils";
import { GlobalServiceLocator } from "../../providers";

@Component({ template: '' })
export abstract class BaseInputComponent {

  @Input() view = new InputViewModel();

  @Input() id = "";

  @Input() title = "";

  @Input() name = "";

  @Input() icon = "";

  @Input() imageSet;

  @Input() helpImageSet;

  @Input() isVaultIcon = false;

  @Input() helpImage = "";

  @Input() width = "";

  @Input() height = "";

  @Input() iconWidth = '24px'; // Temporarily set

  @Input() iconHeight = '24px'; // Temporarily set

  @Input() helpIconWidth = "";

  @Input() helpIconHeight = "";

  @Input() enableHelp;

  @Input() floatableLabel = false;

  @Input() disabled = false;

  @Input() readOnly = false;

  @Input() hideIfDisabled = false;

  @Input() immediate = false;

  @Input() highlight = false;

  @Input() value = "";

  @Input() formattedValue = "";

  /** Use this to be able to CSS select the container when its input is focused.
      TODO We should change this to use ":focus-within" selector once it gets implemented http://caniuse.com/#feat=css-focus-within
  */
  @Input() hasFocus = false;

  // Note! We don't need this setter If we make the class object immutable.
  _errors: Immutable.List<string> = Immutable.List<string>();
  @Input() set errors(theErrors: Array<string>) {
    this._errors = Immutable.List<string>(theErrors);
  }
  
  // Placeholder attribute only works with textBox and dropDown.
  @Input() placeHolder = "";

  @Output()
  onValueChange = new EventEmitter<ValueChangeEventArgs>();

  @Output()
  onFocusOut = new EventEmitter<ValueChangeEventArgs>();

  @Output()
  onReadOnly = new EventEmitter<RestrictValueChangeEventArgs>();

  @Output()
  onDisabled = new EventEmitter<RestrictValueChangeEventArgs>();

  @Output()
  onHelpClick = new EventEmitter<InputViewModel>();

  ngAfterContentInit() {
    if (!this.imageSet)
      this.imageSet = this.getImageSet(ImageSets.Control);

    if (!this.helpImageSet)
      this.helpImageSet = this.getImageSet(ImageSets.Help);

    // Make the view If it is not provided.
    if (!this.view) {
      this.view = {
        id: this.id,
        title: this.title,
        name: this.name,
        icon: this.icon,
        isVaultIcon: this.isVaultIcon,
        helpImage: this.helpImage,
        width: this.width,
        height: this.height,
        iconWidth: this.iconWidth,
        iconHeight: this.iconHeight,
        helpIconWidth: this.helpIconWidth,
        helpIconHeight: this.helpIconHeight,
        enableHelp: this.enableHelp,
        floatableLabel: this.floatableLabel,
        disabled: this.disabled,
        readOnly: this.readOnly,
        hideIfDisabled: this.hideIfDisabled,
        immediate: this.immediate,
        highlight: this.highlight,
        value: this.value,
        errors: this._errors.toArray()
      } as InputViewModel;
    }
  }

  protected getImageSet(key) {
    return this.imageSetAccessor.get(key);
  }

  private _imageSetAccessor: ImageSetAccessor;
  public get imageSetAccessor() {
    if (!this._imageSetAccessor)
      this._imageSetAccessor = GlobalServiceLocator.injector.get(ImageSetAccessor);
    return this._imageSetAccessor;
  }

  notifyIfReadOnly(actionView: InputViewModel, newValue?: any) {
    // Raise invalid action If parameter is readOnly
    if (this.isReadOnly(newValue)) {
      this.onReadOnly.emit(this.restrictValueChangeEventArgs(actionView, newValue));

      return true;
    }

    return false;
  }

  notifyIfDisabled(actionView: InputViewModel, newValue?: any) {
    if (this.view == null) {
      console.log("Can't set the value prior to view initialization.");
      return;
    }

    // Raise invalid notification If parameter is disabled.
    if (this.isDisabled(newValue)) {
      this.onDisabled.emit(this.restrictValueChangeEventArgs(actionView, newValue));

      return true;
    }
    return false;
  }

  /**
   * Notifies the changes to listener.
   * @param actionView
   * @param newValue - could be different than actionView's value for textboxes, But for dropdown and radio buttons new value would be same as actionView's value.
   */
  notifyValueChange(actionView: InputViewModel, newValue?: any, element?: any) {
    // In radioButtonsGroup case, we provide the view for paramview/option and it will never be null in this case.
    if (actionView == null)
      actionView = this.view;

    let args: ValueChangeEventArgs = new ValueChangeEventArgs();

    args.element = element;
    args.component = this;
    args.value = newValue;
    args.actionView = actionView;
    args.prevValue = this.currentValue();

    // Note! It is noticed that there is some delay when changing the value and updating the view on server response.
    // To fix this issue we are updating the view value immediately before sending the request to server.
    actionView.value = newValue;

    // Set the value to property so that for simple control value could be accessed in an easy way.
    this.value = newValue;
    this.view.value = newValue;

    this.beforeNotifyToListener(actionView);

    this.onValueChange.emit(args);
  }

  public restrictValueChangeEventArgs(actionView: InputViewModel, invalidValue: any): RestrictValueChangeEventArgs {
    let args: RestrictValueChangeEventArgs = new RestrictValueChangeEventArgs();
    args.actionView = actionView;
    args.currentValue = this.currentValue();
    args.inValidValue = invalidValue;
    return args;
  }

  beforeNotifyToListener(actionView: InputViewModel): void {

  }

  /**
   * Returns true, if view is readOnly. Note! This property can be overriden for RadioButtonGroups.
   */
  public isReadOnly(newValue: any) {
    return this.view.readOnly;
  }

  /**
   * Returns the current view value, this property can be overriden for RadioButtonGroups.
   */
  public currentValue(): any {
    return this.view.value;
  }

  public selectedChildItemView(): InputViewModel {
    return this.view;
  }

  /**
   * Returns true, if view is disabled. Note! This property can be overriden for RadioButtonGroups.
   */
  public isDisabled(newValue: any) {
    return this.view.disabled;
  }

  helpClick(view: InputViewModel): void {
    this.onHelpClick.emit(view);
  }

  readOnlyClick(actionView?: InputViewModel): void {
    let v: InputViewModel = actionView == null ? this.view : actionView;
    this.notifyIfReadOnly(v, null);
  }

  cancelValue(value: any) {

    this.view.value = value;
    this.value = value;

    // Create a new instance to detect the changes.
    this.view = { ...this.view };

  }

}