import { ImmutableObject } from "../../../../shared/immutableObject"

import { ConfResponseFormat } from "../requests/confResponseFormat";

export class WebSettings extends ImmutableObject {

  protected readonly BASE_URL = "baseUrl";
  protected readonly VAULT_URL = "vaultUrl";
  protected readonly IS_VAULT_DIRECTORY_REMOTE = "isVaultDirectoryRemote";
  protected readonly DATABASE_CONNECTION_INFO = "databaseConnectionInfo";
  protected readonly EDITORS_CONF_RESPONSE_FORMAT = "editorConfResponseFormat";
  protected readonly SUMMARY_CONF_RESPONSE_FORMAT = "summaryConfResponseFormat";
  protected readonly SEARCH_CONF_RESPONSE_FORMAT = "searchConfResponseFormat";
  protected readonly START_CONF_RESPONSE_FORMAT = "startConfResponseFormat";
  protected readonly START_PARTIAL_CONF_RESPONSE_FORMAT = "startPartialConfResponseFormat";

  get baseUrl(): string { return this.getInternalValue<string>(this.BASE_URL); }
  setBaseUrl(baseUrl: string): this { return this.setInternalValue(this.BASE_URL, baseUrl); }

  get vaultUrl(): string { return this.getInternalValue<string>(this.VAULT_URL); }
  setVaultUrl(vaultUrl: string): this { return this.setInternalValue(this.VAULT_URL, vaultUrl); }

  get isVaultDirectoryRemote(): boolean { return this.getInternalValue<boolean>(this.IS_VAULT_DIRECTORY_REMOTE); }
  setIsVaultDirectoryRemote(isVaultDirectoryRemote: boolean): this { return this.setInternalValue(this.IS_VAULT_DIRECTORY_REMOTE, isVaultDirectoryRemote); }

  get databaseConnectionInfo(): string { return this.getInternalValue<string>(this.DATABASE_CONNECTION_INFO); }
  setDatabaseConnectionInfo(databaseConnectionInfo: string): this { return this.setInternalValue(this.DATABASE_CONNECTION_INFO, databaseConnectionInfo); }

  get editorConfResponseFormat(): ConfResponseFormat { return this.getInternalValue<ConfResponseFormat>(this.EDITORS_CONF_RESPONSE_FORMAT); }
  setEditorConfResponseFormat(editorConfResponseFormat: ConfResponseFormat): this { return this.setInternalValue(this.EDITORS_CONF_RESPONSE_FORMAT, editorConfResponseFormat); }

  get summaryConfResponseFormat(): ConfResponseFormat { return this.getInternalValue<ConfResponseFormat>(this.SUMMARY_CONF_RESPONSE_FORMAT); }
  setSummaryConfResponseFormat(summaryConfResponseFormat: ConfResponseFormat): this { return this.setInternalValue(this.SUMMARY_CONF_RESPONSE_FORMAT, summaryConfResponseFormat); }

  get searchConfResponseFormat(): ConfResponseFormat { return this.getInternalValue<ConfResponseFormat>(this.SEARCH_CONF_RESPONSE_FORMAT); }
  setSearchConfResponseFormat(searchConfResponseFormat: ConfResponseFormat): this { return this.setInternalValue(this.SEARCH_CONF_RESPONSE_FORMAT, searchConfResponseFormat); }

  get startConfResponseFormat(): ConfResponseFormat { return this.getInternalValue<ConfResponseFormat>(this.START_CONF_RESPONSE_FORMAT); }
  setStartConfResponseFormat(startConfResponseFormat: ConfResponseFormat): this { return this.setInternalValue(this.START_CONF_RESPONSE_FORMAT, startConfResponseFormat); }

  get startPartialConfResponseFormat(): ConfResponseFormat { return this.getInternalValue<ConfResponseFormat>(this.START_PARTIAL_CONF_RESPONSE_FORMAT); }
  setStartPartialConfResponseFormat(startPartialConfResponseFormat: ConfResponseFormat): this { return this.setInternalValue(this.START_PARTIAL_CONF_RESPONSE_FORMAT, startPartialConfResponseFormat); }
}