<ng-container *ngIf="clickable; else unclickable">
  <ng-container *ngIf="isRemote; else localLink">
    <a class="row w-100 m-0 custom-link {{extraStyles}} flex-nowrap" href="{{url}}" target="{{linkTarget}}" role="button">
      <ng-container *ngTemplateOutlet="linkContents"></ng-container>
    </a>
  </ng-container>
  <ng-template #localLink>
    <div class="row w-100 m-0 custom-link {{extraStyles}} flex-nowrap" (click)="openLink()" role="button">
      <ng-container *ngTemplateOutlet="linkContents"></ng-container>
    </div>
  </ng-template>
</ng-container>

<ng-template #unclickable>
  <div class="row w-100 m-0 custom-link {{extraStyles}} flex-nowrap">
    <ng-container *ngTemplateOutlet="linkContents"></ng-container>
  </div>
</ng-template>

<ng-template #linkContents>
  <div *ngIf="showIcon" class="col-auto pr-0">
    <co-image width="24" height="24" type="svg" key="{{icon}}" (imageError)="icon = defaultIcon" imageSet="{{imageSet}}"></co-image>
  </div>
  <div class="col">
    {{title}}
  </div>
</ng-template>