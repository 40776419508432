import * as Immutable from "immutable"
import { AbstractOperationMessage } from "./abstractOperationMessage"

export class ImportOperationMessage extends AbstractOperationMessage {

  protected readonly IMPORT_CONF_ID = "importConfId";

  get importConfId(): number { return this.getInternalValue<number>(this.IMPORT_CONF_ID); }
  setImportConfId(importConfId: number): this { return this.setInternalValue(this.IMPORT_CONF_ID, importConfId); }

}