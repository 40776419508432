import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { ImageModule } from "../image";
import { RegularPopupComponent } from "./regularPopupComponent";
import { SimplePopperComponent } from "./simplePopperComponent";
import { SidebarPopupComponent } from "./sidebarPopupComponent";
import { PopupService } from "./popupService";
import { BackdropComponent } from "./backdropComponent";
import { SideBarExtensionComponent } from "./sidebarExtensionComponent";
import { LightboxPopupComponent } from "./lightboxPopupComponent";

export * from "./popupService";
export * from "./regularPopupComponent";
export * from "./lightboxPopupComponent";
export * from "./sidebarPopupComponent";
export * from "./sidebarExtensionComponent";
export * from "./basePopupComponent";
export * from "./backdropComponent";
export * from "./popupInfo";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ImageModule
  ],
  declarations: [
    RegularPopupComponent,
    SidebarPopupComponent,
    SideBarExtensionComponent,
    LightboxPopupComponent,
    SimplePopperComponent,
    BackdropComponent    
  ],
  providers: [
    PopupService
  ],
  exports: [
    RegularPopupComponent,
    SidebarPopupComponent,
    SideBarExtensionComponent,
    LightboxPopupComponent,
    SimplePopperComponent,
    BackdropComponent
  ]
})
export class PopupModule {
}