<div class="container">
  <div class="row">
    <div class="col">
      You are seeing this page because you are using a unsupported browser. <br />
      Currently we support the following browsers.
    </div>
  </div>
  
  <div class="row">
    <div class="col-auto"><co-image key="chrome"  width="48px" height="48px"></co-image></div>
    <div class="col">
      <span><strong>Chrome</strong> current version</span>
    </div>
  </div>

</div>