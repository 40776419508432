import * as Immutable from "immutable";

import { BaseEntity } from "../../baseEntity";

/** 
 * TODO: Should we inherit from BaseEntity ?? LongId is not needed in this class.
 * **/
export abstract class ConfSearchCriteria extends BaseEntity {

  protected readonly CONTROL_ID = "controlId";  
  protected readonly NAME = "name";
  protected readonly VALUE = "value";

  get controlId(): string { return this.getInternalValue<string>(this.CONTROL_ID); }
  setControlId(controlId: string): this { return this.setInternalValue(this.CONTROL_ID, controlId); }

  get name(): string { return this.getInternalValue<string>(this.NAME); }
  setName(name: string): this { return this.setInternalValue(this.NAME, name); }

  get value(): string { return this.getInternalValue<string>(this.VALUE); }
  setValue(value: string): this { return this.setInternalValue(this.VALUE, value); }

}