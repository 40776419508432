<!-- It must be aligned to company logo in small size screens -->
<div class="detail-container pl-3 ml-4" *ngIf="_assortment != null">
  <header>
    <h2>{{_assortment.title}} </h2>
  </header>
  <section>
    <div *ngIf="isProduct || image != DEFAULT_IMAGE" class="detail-image">
      <co-image [type]="imageType" [imageSet]="imageSet" [isVault]="isVault" [key]="image"></co-image>
    </div>
    <div id="notes">
      <span [innerHTML]="_assortment.notes | safeHtml"></span>
    </div>
    <div *ngIf="isProduct" class="my-3">
      <button type="button" class="btn btn-primary text-uppercase" (click)="onConfigure()">{{strings.Create}}</button>
    </div>
  </section>
</div>