import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, RouteNames } from "../shared/providers"
import { ConfiguratorComponent, EditorViewComponent, ConfViewRedirectorComponent, SummaryViewComponent } from "./";
import { ConfPageIdResolver } from "./providers/resolvers/confPageIdResolver";
import { ConfDataResolver } from "./providers/resolvers/confDataResolver";
import { ConfDataLoadedGuard } from "./providers/confDataLoadedGuard";
import { ConfDataDeactivateGuard } from "./providers/confDataDeactivateGuard";
import { ComparisonComponent, ComparisonResultsResolver } from './comparison';

export const ConfiguratorRoutes: Routes = [
  {
    path: RouteNames.Configurator,
    component: ConfiguratorComponent,
    canDeactivate: [ConfDataDeactivateGuard],
    resolve: { pageId: ConfPageIdResolver },
    children: [
      {
        path: '', redirectTo: RouteNames.Redirector, pathMatch: 'prefix'
      },
      {
        path: RouteNames.Editor, component: EditorViewComponent, canActivate: [ConfDataLoadedGuard]
      },
      {
        path: RouteNames.Redirector, component: ConfViewRedirectorComponent, runGuardsAndResolvers: 'always', resolve: [ConfDataResolver], canActivate: [AuthGuard]
      },
      {
        path: RouteNames.Summary, component: SummaryViewComponent, canActivate: [ConfDataLoadedGuard]
      }      
    ]
  }
];