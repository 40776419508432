import { Inject, Component, Input, ViewContainerRef, ViewChild } from "@angular/core";
import { BaseComponent } from "../../shared";
import { RouteRedirector } from "../../shared/providers";
import { ConfRouteParams } from "../providers";

@Component({
  selector: 'configuration-link',
  templateUrl: './configurationLinkComponent.html'
})
export class ConfigurationLinkComponent extends BaseComponent {

  @Input()
  configurationId: number;

  @Input()
  sessionId: number;

  // View could be summary, compare or configurator. 
  @Input()
  view: string;

  constructor(
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector) {
    super();
  }

  public onConfigurationClick() {
    switch (this.view) {
      case "summary":
        this.routeRedirector.redirectToSummary(<ConfRouteParams>{ id: this.configurationId, confSessionId: this.sessionId });
        return;
      case "configurator":
        this.routeRedirector.redirectToEditor(<ConfRouteParams>{ id: this.configurationId, confSessionId: this.sessionId });
        return;
      default:
        throw this.strings.ErrorInvalidView;
    }
  }
}