import { Component, Input, Inject, SimpleChanges, ChangeDetectorRef } from "@angular/core";
import { BaseComponent } from "../../shared";
import { QuickSearchItemUIModel } from "./quickSearchUIModel";
import { RouteRedirector } from "../../shared/providers";
import { ImageSets } from "../../../shared/utils";

@Component({
  selector: 'search-navigator',
  templateUrl: './searchNavigatorComponent.html'
})
export class SearchNavigatorComponent extends BaseComponent {

  @Input() public quickSearch: QuickSearchItemUIModel;

  public imageSet: string;

  constructor(
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
  ) {
    super();
  }

  ngOnInit() {
    this.imageSet = this.getImageSet(ImageSets.StartConfiguration);
  }

  public showMore() {
    this.routeRedirector.redirectToSearchResult({ confSearchId: this.quickSearch.search.longId });
  }
}