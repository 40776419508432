import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { ConfPageSessionService } from "./confPageSessionService";
import { ConfiguratorStore } from "./configuratorStore";
import { ConfiguratorComponent } from "../configuratorComponent";
import { Strings } from '../../shared/providers/globalData';

@Injectable()
export class ConfDataDeactivateGuard implements CanDeactivate<ConfiguratorComponent> {

  constructor(public confPageSession: ConfPageSessionService, public configuratorStore: ConfiguratorStore, protected strings: Strings) {
  }

  canDeactivate(configuratorComponent: ConfiguratorComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean {

    // If the ConfiguratorSession is closed then it is ok to deactivate
    if (this.configuratorStore.isConfiguratorSessionClosed(this.confPageSession.confSessionId))
      return true;

    // TODO fix translation and add popup
    if (!configuratorComponent.canExitConfigurator) {

      // Mark it for closing. If the user decides to stay on this page then the server will unmark it
      this.configuratorStore.markConfSessionForClosing(this.confPageSession.activeConfigurationId, this.confPageSession.confSessionId);

      if (!confirm(this.strings.UnsavedChangesConfirmation))
        return false;      
    }

    return true;
  }
}