<div class="selector-nav">
  <selector-header [displayReturnButton]="selectedAssortment !== null"
                   (returnButtonClicked)="returnToSelector()"></selector-header>
  <div class="selector-treeview">
    <tree-view (nodeSelected)="onNodeSelected($event)"
               (nodeExpanded)="onNodeExpanded($event)"
               [treeDataProvider]="treeDataProvider"
               [imageSet]="imageSet">
    </tree-view>
  </div>

</div>
<div *ngIf="selectedAssortment != null" id="product-detail">
  <product-detail [product]="selectedAssortment"></product-detail>
</div>