<div #visualObjectContainer *ngIf="confIntValue">
  <div class="visual-object-margin">
    <text-box [view]="viewModel"
              [errors]="errors"
              [stepValue]="this.parameter.stepValue"
              type="number"
              integer="true"
              (onValueChange)="onValueChange($event)"
              (onReadOnly)="onReadOnly($event)"
              (onDisabled)="onDisallowed($event)"
              (onHelpClick)="onHelpClick($event)"></text-box>
  </div>
</div>