import { NodeData } from './nodeData';

export let NodeActions = {

  // Action when select is made.
  BeforeSelect: 'beforeSelect', 

  // Action when select is made.
  AfterSelect: 'afterSelect', 

  // Action for composite structure length changed.
  LengthChanged: 'lengthChanged',  

  // Collapse expand action
  CollapseSibling: 'collapseSibling', 

  // Action to refresh the whole tree.
  TreeRefresh: 'refresh',   

  // Action core data is changed e.g Title.
  DataChanged: 'dataChanged',

  // Action - If menu is selected
  MenuClicked: 'menuClicked',

  // Add action
  AddClicked: 'addClicked',

  // Action - Deactivates the menu.
  DeactivateMenu: 'deactivateMenu',

  // Action - Deactivates the menu.
  ActivateMenu: 'activateMenu',

  // Action - Add
  ActivateAddMenu: 'activateAddMenu',

  DeactivateAddMenu: 'deactivateAddMenu',

  DeactivateAny: 'deactivateAny',

  None: 'none'
}

export let EventSource = {

  MenuClick: 'menuClicked',

  NodeSelected: 'nodeSelected',

  AddClick: 'addClicked',

  Unknown: 'unknown'

}

export class NodeEvent {
  node: NodeData;
  action: string = NodeActions.None;
  eventSource: string = EventSource.Unknown;
}