import { ChangeDetectorRef, Component, Inject, Input } from "@angular/core";
import { Tab } from "../../../shared/models";
import { ConfiguratorUIStore, ConfPageSessionService } from "../../providers";
import { VisualObjectNavigatorService } from "../../shared/visualObjectNavigatorService";
import { TabAvailabilityService } from "./tabAvailabilityService";
import { TabContainerComponent } from "./tabContainerComponent";
import { TabDisplayStyle } from "../../../shared/providers";

export let Direction = {
  Horizontal: 'horizontal',
  Vertical: 'vertical',
}

@Component({
  selector: 'accordion-tabs',
  templateUrl: './accordionTabsComponent.html'
})
export class AccordionTabsComponent extends TabContainerComponent {

  constructor(
    @Inject(ConfiguratorUIStore) public confUIStore: ConfiguratorUIStore,
    @Inject(ConfPageSessionService) public confPageSession: ConfPageSessionService,
    @Inject(TabAvailabilityService) public tabAvailableService: TabAvailabilityService,
    @Inject(VisualObjectNavigatorService) public visualObjectNavigatorService: VisualObjectNavigatorService,   
    public cd: ChangeDetectorRef) {
    super(confUIStore, cd, tabAvailableService);
  }
  
  @Input()
  public tabs: Tab[];
  
  @Input()
  public selectedTabId: number;

  @Input()
  public keepAllTabsOpen: boolean = false;

  @Input()
  public configurationId: number;

  public tabDisplayStyle = TabDisplayStyle;

  ngOnInit() {

    this.keepAllTabsOpen = this.uiSettings.configurator.tab.keepAccordionTabsOpen;

    //this.confUIStore.onActiveConfChange(this.confPageSession.pageId, (confId: number) => {
    //  this.configurationId = confId;
    //}).unsubscribeOn(this.unsubscribeSubject);

    if (!this.selectedTabId)
      this.selectedTabId = this.tabs[0].longId;

    // No need to set to false because the contents are already available.
    this.domReady = true;
  }

  onSelectedTabId(id: number) {
    this.selectedTabId = id;

    this.visualObjectNavigatorService.sendMessage(id);
  }

  get pageId(): number {
    return this.confPageSession.pageId;
  }

  public activateTab(tab: Tab) {

    if (this.tabs.findIndex(x => x.longId == tab.longId) > -1)
      this.selectedTabId = tab.longId;

  }

  public trackTab(index: number, tab: Tab): any {
    return tab.longId;
  }

}