import { InputViewModel, Positions } from "../../../../shared/components/shared";
import { DisplayCountValueModel } from "../../../../shared/components/shared/models/displayCountValueModel";
import { Strings } from "../../providers/globalData";
import { PaginationInfo } from "../../models";

export class PagerModel {

  public pageSizeViews: Array<InputViewModel>;

  public displayCountView: DisplayCountValueModel;

  public resultCount: number;

  public noOfPages: number;

  public currentPage: number;

}