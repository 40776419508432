<div id="settings">
  <div class="page-margin-top page-margin-bottom">
    <gridview classes="w-100 m-0">
      <gridview-row>
        <columntemplate classes="pl-0 page-margin-left">
          <h1 class="text-uppercase">{{strings.Settings}}</h1>
        </columntemplate>
      </gridview-row>
      <gridview-row classes="">
        <columntemplate classes="pl-0">
          <gridview-row classes="ml-0">
            <columntemplate classes="column-style pl-0 page-margin-left mr-4" size="auto">
              <user-profile></user-profile>
            </columntemplate>
            <columntemplate classes="settings-border hide-spacer" size="auto">
              <gridview-row classes="">
                <div class="spacer page-margin-left"></div>
                <columntemplate classes="column-style pl-0">
                  <user-settings></user-settings>
                  <div id="administration" *ngIf="showAdminSection">
                    <h3 class="text-uppercase">{{strings.Administration}}</h3>
                    <div class="label-control text-break">
                      <span class="pm-color-darker">{{strings.Database}}:</span>
                      <span class="pl-2 font-italic"> {{databaseConnectionInfo}}</span>
                    </div>
                    <div class="container-fluid">
                      <div class="row">
                        <button class="col-auto btn btn-primary d-block" (click)="reloadCaches()">{{strings.ReloadCaches}}</button>
                      </div>
                      <div class="row">
                        <button class="col-auto btn btn-primary" (click)="reloadUserCaches()">{{strings.ReloadUserData}}</button>
                      </div>
                    </div>
                  </div>
                </columntemplate>
              </gridview-row>
            </columntemplate>
          </gridview-row>
        </columntemplate>
      </gridview-row>
    </gridview>
  </div>
</div>