import { Injectable, Inject } from "@angular/core";
import * as Immutable from "immutable";
import { combineReducers, ReducersMapObject } from "redux";

import { ConfDataResponse, RequestViews} from "../../models";
import { AppStore } from "../appStore";
import { AppAction } from "../shared/appAction";
import { UIAction } from "../shared/uiAction";
import { CompositeUIData } from "../shared/compositeUIData";
import { ConfUIData } from "../shared/confUIData";
import { AccordionUIData } from "../shared/accordionUIData";
import { PageActions } from "./pageActions";
import { Actions } from "../shared/actions";
import { ConfiguratorPageUIData } from "../shared/configuratorPageUIData";
import { PageConfIdentifier } from "../shared/pageConfIdentifier";
import { ConfUIReducer } from "./confUIReducer";
import { ReducersCombiner } from "../../../../shared/providers/reducersCombiner";

@Injectable()
export class ConfiguratorUIReducer {

  constructor(
    @Inject(ConfUIReducer) public confUIReducer: ConfUIReducer,
    @Inject(ReducersCombiner) public reducersCombiner: ReducersCombiner
  ) { }

  public getReducer() {

    let defaultPageUIState = new ConfiguratorPageUIData();

    const confUiReducer = this.reducersCombiner.combine<ConfiguratorPageUIData>({
      confUIDataById: this.confUIReducer.getReducer(),
      pageConfIdentifier: this.pageConfIdentifierReducer(),
      compositeUI: this.compositeUIReducer(),
      accordionUI: this.accordionUIReducer(),
      includeMandatoryInfo: this.includeMandatoryInfoReducer(),      
      isNextStateIncluded: this.isNextStateIncludedReducer()
    });

    return (stateById: Immutable.Map<string, ConfiguratorPageUIData> = Immutable.Map<string, ConfiguratorPageUIData>(), action: AppAction<any>): Immutable.Map<string, ConfiguratorPageUIData> => {

      let uiAction = action as UIAction<any>;
      //if (uiAction.type == PageActions.CREATE_CONFIGURATOR_UI_DATA) {

      // Temporary check.
      if (uiAction.pageId > -1) {

        let pageUIState = defaultPageUIState;

        // Use the default ConfiguratorPageUIData state if does not exist.
        if (!stateById.has(uiAction.pageId.toString())) {
          stateById = stateById.set(uiAction.pageId.toString(), pageUIState);
        }
        else {
          // Existing state
          pageUIState = stateById.get(uiAction.pageId.toString());
        }

        let result = confUiReducer(pageUIState, action);

        // Update if the results are not same.
        if (result != pageUIState)
          stateById = stateById.set(uiAction.pageId.toString(), result);
        //}
      }
      return stateById;
    }
  }

  /**
   * Updates the configuration page identifiers, e.g configurationId and sessionId.
   * @param stateById
   * @param uiAction
   */
  public pageConfIdentifierReducer() {
    // Default state
    const defaultState: PageConfIdentifier = new PageConfIdentifier()

    // Reducer
    return (identifierState: PageConfIdentifier = defaultState, uiAction: UIAction<any>): PageConfIdentifier => {

      //if confDataResponse and activeId does not exists in ConfInfo then set to null

      switch (uiAction.type) {

        case Actions.CONFIGURATION_LOADED:
          {
            let response: ConfDataResponse = uiAction.payload as ConfDataResponse;
            if (response.confSessionId == identifierState.confSessionId) {
              if (identifierState.selectedConfId && !response.confInfos.entities.has(identifierState.selectedConfId))
                identifierState = identifierState.setSelectedConfId(null);
            }
            break;
          }
        case PageActions.CONFIGURATION_SELECT:
          {
            identifierState = <PageConfIdentifier>uiAction.payload;
            break;
          }
      }

      return identifierState;
    }
  }

  public compositeUIReducer() {
    const defaultState = new CompositeUIData();

    // Reducer
    return (state: CompositeUIData = defaultState, uiAction: UIAction<any>): CompositeUIData => {

      switch (uiAction.type) {
        case PageActions.COMPOSITE_UI_CHANGE:
          state = <CompositeUIData>uiAction.payload;
      }
      return state;
    }
  }

  public accordionUIReducer() {
    const defaultState = new AccordionUIData();

    // Reducer
    return (state: AccordionUIData = defaultState, uiAction: UIAction<any>): AccordionUIData => {
      switch (uiAction.type) {
        case PageActions.TABS_ACCORDION_UI_CHANGE:
          state = <AccordionUIData>uiAction.payload;
      }
      return state;
    }
  }

  public includeMandatoryInfoReducer() {
    const defaultState = false;

    // Reducer
    return (state: boolean = defaultState, uiAction: UIAction<any>): boolean => {
      switch (uiAction.type) {
        case PageActions.MANDATORY_INCLUDE_INFO:
          state = <boolean>uiAction.payload;
      }
      return state;
    }
  }

  public isNextStateIncludedReducer() {
    const defaultState = false;

    // Reducer
    return (state: boolean = defaultState, uiAction: UIAction<any>): boolean => {
      switch (uiAction.type) {
        case PageActions.MANDATORY_IS_NEXT_STATE_INCLUDED:
          state = <boolean>uiAction.payload;
      }
      return state;
    }
  }
}