import { Directive, ElementRef, Input, HostListener, Renderer2 } from "@angular/core";
import { VisualObject, Decoration } from "../../shared/models";
import { VisualObjectHelper } from "../parameters/shared";

@Directive({
  selector: '[visualStyle]'
})
export class VisualObjectGroupDirective {

  @Input('visualStyle')
  public visualObject: VisualObject;
  
  constructor(public element: ElementRef, public renderer: Renderer2, public visualObjectHelper: VisualObjectHelper) {

  }

  ngOnInit() {

    this.setStyles();

  }

  setStyles(): void {
    
    let maxWidth = this.visualObjectHelper.getMaxWidth(this.visualObject);    
    this.renderer.setStyle(this.element.nativeElement, 'width', '100%');
    this.renderer.setStyle(this.element.nativeElement, 'max-width', maxWidth);
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setStyles();
  }

}