<action-box *ngIf="visible" textLeft="{{strings.Attachments}}" [showHeader]="showHeader">
  <ng-container *ngFor="let attachment of attachments">
    <div class="menu-item justify-content-between" title="{{attachment.attachment.title}}">
      <div class="row w-100 m-0 attachment btn-link">
        <custom-link [url]="attachment.link" [title]="attachment.attachment.title" [icon]="attachment.attachment.icon" class="col px-0 py-2" [imageSet]="imageSet"></custom-link>
        <div class="col-auto clickable align-middle mobile-flex delete-button" role="button" title="{{strings.Delete}}" *ngIf="canDelete" (click)="onDeleteClick(attachment.attachment)">
          <co-image width="24" height="24" type="svg" key="delete" imageSet="red"></co-image>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="text-right" *ngIf="canAddFiles">
    <button class="btn btn-light mt-2 text-uppercase" (click)="onUploadClick()">{{strings.AddFiles}}</button>
  </div>
</action-box>
<attachments-upload-popup></attachments-upload-popup>
<delete-attachment-popup></delete-attachment-popup>