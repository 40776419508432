import { NgModule, InjectionToken } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule } from '@angular/common/http';
import { InputDatepickerFieldComponent } from './inputs/inputDatepickerFieldComponent';
import { DateRangePickerComponent } from './range/dateRangeOverlayComponent';
import { DecoratedCalenderComponent } from './calendar/decoratedCalendarComponent';
import { DropDownDateRangesComponent } from './range/dropDownDateRangesComponent';
import { OverlayDatepickerService } from './providers/overlayDatepickerService';
import { RangeDatepickerService, DATE } from './providers/rangeDatepickerService';
import { ConfigDatepickerService } from './providers/configDatepickerService';
import { PresetService } from './providers/presetService';
import { ImageModule } from "../image";
import { TextBoxModule } from "../textBox";


export * from './models/model';
export * from './inputs/inputDatepickerFieldComponent';
export * from './range/dateRangeOverlayComponent';
export * from './calendar/decoratedCalendarComponent';
export * from './range/dropDownDateRangesComponent';
export * from './providers/overlayDatepickerService';
export * from './providers/rangeDatepickerService';
export * from './providers/configDatepickerService';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatDividerModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    OverlayModule,
    ImageModule,
    TextBoxModule    
  ],
  declarations: [
    InputDatepickerFieldComponent,
    DecoratedCalenderComponent,
    DateRangePickerComponent,
    DropDownDateRangesComponent
  ],
  providers: [
    //OverlayDatepickerService,
    //RangeDatepickerService,
    //PresetService,
    //ConfigDatepickerService,
    //DatePipe,
    //{ provide: DATE, useValue: new Date() }
  ],
  
  entryComponents: [DateRangePickerComponent],
  exports: [InputDatepickerFieldComponent]
})
export class DateRangePickerModule { }