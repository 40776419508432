import { Component, Inject, ChangeDetectorRef, ViewChild, Input, Output, EventEmitter } from "@angular/core";

import { InputViewModel, PopupService, NotificationService, NotificationInfo, NotificationType, BackdropComponent } from "../../../../shared/components";
import { BaseComponent } from "../..";
import { AccountDataStore, AccountDataMessageProvider } from "../../providers/accountData";
import { PasswordChangeMessage } from "../../models";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import { ManagedSubscription } from "../../../../shared/managedSubscription";

@Component({
  selector: 'change-password-popup',
  templateUrl: './changePasswordPopupComponent.html',
})
export class ChangePasswordPopupComponent extends AbstractPopupComponent {

  public id: string = "change-password-popup";
  public currentPasswordView: InputViewModel;
  public newPasswordView: InputViewModel;
  public confirmNewPasswordView: InputViewModel;

  public message: string;

  public serverErrors: Array<string> = [];
  public passwordChangeMessage: ManagedSubscription;

  @Input()
  username: string;

  @Output()
  passwordChanged = new EventEmitter();

  @ViewChild(BackdropComponent)
  public backdropComponent: BackdropComponent;

  constructor(
    @Inject(AccountDataStore) public accountStore: AccountDataStore,
    public accountMessageProvider: AccountDataMessageProvider,
    public popupService: PopupService,
    public notificationService: NotificationService,
    public cdr: ChangeDetectorRef,
  ) {
    super(cdr, popupService);
  }

  ngOnInit() {
    this.currentPasswordView = new InputViewModel(this.strings.OldPassword);
    this.newPasswordView = new InputViewModel(this.strings.NewPassword);
    this.confirmNewPasswordView = new InputViewModel(this.strings.ConfirmNewPassword);

    this.currentPasswordView.name = this.newPasswordView.name = this.confirmNewPasswordView.name = "password";
    this.currentPasswordView.icon = this.newPasswordView.icon = this.confirmNewPasswordView.icon = "password";

    super.ngOnInit();
  }

  public open(tag: any) {
    this.show(tag);
  }

  show(username?: string) {
    this.username = username;

    this.showInternal();
  }

  public listenOnChange() {
    this.unsubscribe();

    this.passwordChangeMessage = this.accountMessageProvider.onMessages<PasswordChangeMessage>(PasswordChangeMessage.name, {
      next: (messages) => {
        let message = messages.first();

        if (message.success) {
          this.passwordChanged.emit(this.newPasswordView.value);

          this.resetValues();
          this.backdropComponent.close();
          this.onPopupClosed();

          this.notificationService.notify(<NotificationInfo>{
            title: this.strings.Success,
            message: this.strings.ChangePassword_SaveSuccess,
            type: NotificationType.Success,
            selfClose: true
          });
        }
        else {
          // Don't close popup and show the errors.
          this.serverErrors = message.errors;
        }

        this.unsubscribe();
      },
      listenNewEventsOnly: true
    });
  }

  public resetValues() {
    this.currentPasswordView.value = this.newPasswordView.value = this.confirmNewPasswordView.value = "";
  }

  public validatePassword(): boolean {
    // Perform some validation on client side. Will also be done on server side.

    // Reset any old errors
    this.currentPasswordView.errors = this.newPasswordView.errors = this.confirmNewPasswordView.errors = [];

    // Old password is mandatory
    if (!this.currentPasswordView.value) {
      this.currentPasswordView.errors = [this.strings.ChangePasswordErrorMessage_SpecifyOldPassword];
      return false;
    }

    // New password is mandatory
    if (!this.newPasswordView.value) {
      this.newPasswordView.errors = [this.strings.ChangePasswordErrorMessage_SpecifyNewPassword];
      return false;
    }

    // Confirm new password is mandatory
    if (!this.confirmNewPasswordView.value) {
      this.confirmNewPasswordView.errors = [this.strings.ChangePasswordErrorMessage_SpecifyNewPassword];
      return false;
    }

    // New passwords must match
    if (this.newPasswordView.value !== this.confirmNewPasswordView.value) {
      this.confirmNewPasswordView.errors = [this.strings.ChangePasswordErrorMessage_NewPasswordsNoMatch];
      return false;
    }

    return true;
  }

  public changePassword() {
    this.serverErrors = [];

    if (this.validatePassword()) {
      this.listenOnChange();

      let username = this.username || this.accountStore.getUser().username;

      this.accountStore.changeUserPassword(username, this.currentPasswordView.value, this.newPasswordView.value);
    }
  }

  public unsubscribe() {
    if (this.passwordChangeMessage)
      this.passwordChangeMessage.unsubscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();

    super.ngOnDestroy();
  }
}