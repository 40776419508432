import { Injectable } from '@angular/core';
import { Subscription } from "rxjs";
import { ManagedSubscription, ManagedSubject } from "../../../shared";
import { HttpRequest, HttpResponse } from '@angular/common/http';


export enum HttpStatus {

  Started,
  WaitingState,
  Completed

}

export interface IHttpDataInfo {
  status: HttpStatus;
  request: HttpRequest<any>;  
}

@Injectable()
export class HttpLifeCycleService  {


  private subject = new ManagedSubject<any>(null, false, 0, false, false);

  public send(status: HttpStatus, request: HttpRequest<any>): void {

    let info: IHttpDataInfo = <IHttpDataInfo> {
      status: status,
      request: request
    }

    this.subject.nextValue(info);
  }

  public getMessage(): ManagedSubject<any> {
    return this.subject;
  }


}