import { Inject, Component, ViewChild, SimpleChanges, ChangeDetectorRef } from "@angular/core";
import { ConfigurationSessionManager, ConfiguratorStore, ConfPageSessionService, ConfMessageHandler } from "./providers";
import { SummaryBaseComponent } from "./summary/summaryBaseComponent";
import { BreakPointAccessor, Breakpoints } from "../../shared/utils";
import { BackdropComponent, MessageBoxConfig, MessageBoxComponent } from "../../shared/components";
import { ProductDataStore } from "../shared/providers/productData";
import { Product, ConfInfo, RequestViews } from "../shared/models";
import { BaseViewComponent } from "./baseViewComponent";

@Component({
  selector: 'summary-view',
  templateUrl: './summaryViewComponent.html',
  providers: [ConfMessageHandler]
})
export class SummaryViewComponent extends BaseViewComponent {

  @ViewChild(BackdropComponent)
  public backdrop: BackdropComponent;

  @ViewChild(MessageBoxComponent)
  public recoverInfoMessage: MessageBoxComponent;


  public showComposite: boolean = false;
  public showSideBar: boolean = false;  
  
  constructor(
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public pageSession: ConfPageSessionService,
    @Inject(BreakPointAccessor) public breakPointAccessor: BreakPointAccessor,
    @Inject(ProductDataStore) public productStore: ProductDataStore,   
    @Inject(ConfMessageHandler) public confMessageHandler: ConfMessageHandler,
    public cdr: ChangeDetectorRef
  ) {

    super();

  }

  ngOnInit() {
    this.pageSession.activeRoute = RequestViews.Summary;
    this.confMessageHandler.subscribeMessagesForSummary();
  }

  ngAfterViewInit() {
    if (this.pageSession.activeConfiguration.authorization.canRecover) {
      let info: MessageBoxConfig<[number, number]> = <MessageBoxConfig<[number, number]>>{
        headerText: this.strings.YourConfigurationHasUnsavedChanges,//"Your configuration has unsaved changes.",
        description: this.strings.RecoverConfigurationPopupDescription, //"To continue editing press the Recover button. To discard changes close this page.",
        icon: "info",
      }

      this.recoverInfoMessage.show(info);
    }
  }

  ngOnDestroy() {
    this.confMessageHandler.onDestroy();
    super.ngOnDestroy();
  }

  public get largeUp() {
    return this.breakPointAccessor.activeBreakPoint.min > Breakpoints.lg.max;
  }

  public openActions() {
    this.showSideBar = true;
    this.cdr.detectChanges();
    this.backdrop.popup.open();
  }

  public onActionsClosed(): void {
    if (this.backdrop.visible)
      this.backdrop.popup.close();
    this.showSideBar = false;
  }
}