<backdrop-container>
  <regular-popup *ngIf="visible"
                 minHeight="94%"
                 width="100%"
                 [id]="id"
                 [showTitleBar]="false"
                 [showCloseButton]="true"
                 [okButtonText]="strings.Ok"
                 [cancelButtonText]="strings.Cancel"
                 (onClosed)="onPopupClosed()">
    <div card-type="body" class="h-100 d-flex">
      <graphics-renderer [confId]="configurationId" [sessionId]="confSessionId" [decoration]="decoration" [paddingLeft]="10" [paddingTop]="10" [height]="'100%'" [width]="'100%'"></graphics-renderer>
    </div>
  </regular-popup>
</backdrop-container>