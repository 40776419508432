export * from './summaryActionsBoxesComponent';
export * from './summaryDetailComponent';
export * from './confPropertiesDetail/confPropertiesDetailComponent';
export * from './tab/tabDetailComponent';
export * from './tab/summaryParamDetailComponent';
export * from './attributes';
export * from './bom/bomDetailComponent';
export * from './graphics/graphicsDetailComponent';
export * from './prices';
export * from './documents/documentsDetailComponent';
export * from './code/codeDetailComponent';
export * from './image/imageDetailComponent';
export * from './visualization/visualizationDetailComponent';