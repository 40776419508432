import { Inject, Component, OnInit, Host, AfterContentInit, ChangeDetectorRef, Input, EventEmitter, Output } from "@angular/core";

import { GenericDropdownGroup } from "../genericDropdownGroup";
import { AbstractDropdownGroup } from "../abstractDropdownGroup";
import { AbstractDropdownItem } from "../abstractDropdownItem";

@Component({
  selector: "standard-dropdown",
  templateUrl: './standardDropdownGroupComponent.html',
})

  // TODO: NOT DONE! Selection is not implemented yet
export class StandardDropdownGroupComponent extends AbstractDropdownGroup {

  constructor( @Host() @Inject(GenericDropdownGroup) public parent: GenericDropdownGroup, public cdr: ChangeDetectorRef) {
    super(parent, cdr);
  }

  processEvent(event, item: AbstractDropdownItem): void {
    super.processEvent(event, item);
  }

  selectItem($event) {
  }
}