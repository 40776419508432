<div id="login" class="{{rootStyles}}">
  <div class="account-box">
    <!-- Note: max-width is not working for I.E 11 that's why only style.width is applied. -->
    <div class="align-middle justify-content-center">
      <co-image [style.width]="maxWidth" [type]="logoType" [imageSet]="imageSet" class="alignment logo py-3" key="loginlogo"></co-image>
    </div>

    <!-- Sub routing -->
    <router-outlet></router-outlet>

    <div class="alignment pb-3">
      <a *ngIf="showPasswordRecoveryLink" role="button" (click)="accountRecoverClick()" class="link-decorate alignment mt-3">{{strings.ForgotPassword}}</a>
      <a *ngIf="showLoginLink" role="button" href="#/account/login" class="alignment mt-3">{{strings.Login}}</a>
      <a *ngIf="showSignUpLink" class="link-decorate alignment mt-3" role="button" (click)="signUpClick()"> {{strings.SignUp}}</a>
      <a *ngIf="showAnonymousAccessLoginLink" class="link-decorate alignment mt-3" role="button" (click)="loginAnonymously()">{{strings.LoginAnonymously}}</a>
    </div>
  </div>

  <anonymous-access-entry></anonymous-access-entry>  
  <change-password-popup [username]="userNameView.value" (passwordChanged)="passwordChangedSuccess($event)"></change-password-popup>
</div>
