import { Component, Input, Output, ElementRef, Directive, OnInit, HostListener, EventEmitter } from "@angular/core";

@Directive({
  selector: "header-columntemplate"
})
export class HeaderColumnTemplate implements OnInit {

  /** Bootstrap column sizes from smallest (1 -> 1/12~8.3%) to largest (12 -> 12/12=100%),
  or auto. */
  @Input()
  size: number | string;

  @Input()
  classes: string = "";

  oldClasses: string = "";

  //#region "Sorting related"

  @Input()
  useSorting: boolean = false;

  @Input()
  name: string;

  @Input()
  defaultDirection: string = ""; // TODO enum?

  @Output()
  onSort = new EventEmitter();

  public direction: string; // TODO enum?

  @Input()
  public autoCell: boolean = false;

  //#endregion "Sorting related"

  constructor(public element: ElementRef) {
  }

  ngOnInit() {
    this.direction = this.defaultDirection;
    this.setClass();
  }

  public setClass() {
    this.element.nativeElement.className.replace(this.oldClasses, "");
    this.element.nativeElement.className += " " + this.classes;
    this.oldClasses = this.classes;

    if (this.autoCell)
      this.element.nativeElement.className += ' col-auto';
    else 
      this.element.nativeElement.className += ` col${this.size == null ? '' : '-' + this.size}`;

    if (this.useSorting)
      this.element.nativeElement.className += " sorting " + this.direction;
  }

  public toggleDirection() {
    this.direction = this.direction == "up" ? "down" : "up";
    this.setClass();
  }

  @HostListener('click', ['$event'])
  click($event) {
    if (this.useSorting) {
      this.toggleDirection();
      if (this.onSort != null)
        this.onSort.emit({ name: this.name, direction: this.direction });
    }
  }
}