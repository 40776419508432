import { BaseComponent } from "../../shared";
import { Component, Input, ChangeDetectorRef, ChangeDetectionStrategy, Inject } from "@angular/core";
import * as Immutable from "immutable";
import { ConfInfo } from "../../shared/models";
import { GlobalDataStore } from "../../shared/providers/globalData";
import { SystemInformation } from "../../shared/models/entities/systemInformation";

@Component({
  selector: 'start-welcome',
  templateUrl: './startWelcomeComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartWelcomeComponent extends BaseComponent {

  @Input()
  configurationsByInterval: Immutable.OrderedMap<string, Immutable.OrderedMap<number, ConfInfo>>;

  public welcomeMessage: string;
  public welcomeTitle: string;

  constructor(@Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(ChangeDetectorRef) protected cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {

    this.welcomeTitle = this.strings.DefaultWelcomeTitle;
    this.welcomeMessage = this.strings.DefaultWelcomeMessage; 

    this.globalDataStore.getEntities<SystemInformation>(SystemInformation.name).subscribe((storeResponse) => {
      storeResponse.data.toArray().forEach((systemInfo) => {
        if (systemInfo.isWelcomeMessage) {
          this.welcomeTitle = systemInfo.title;          
          this.welcomeMessage = systemInfo.message;
          this.cdr.markForCheck();     
        }        
      });

    }).unsubscribeOn(this.unsubscribeSubject);
  }   
}