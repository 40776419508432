import { Injectable, forwardRef, Inject } from '@angular/core';

import { ConfDataActionCreator } from "../../providers/configurationData";
import { AppAsyncAction, HttpAction, ActionStatus } from "../../state";
import { ConfDataResponse } from "../../models/responses";
import { ConfDataRequest } from "../../models";
import { StateController } from "./stateController";

export class StateActions {
  static PROMOTE = "@STATE_PROMOTE";
  static DEMOTE = "@STATE_DEMOTE";
}

@Injectable()
export class StateActionCreator {

  constructor(
    @Inject(forwardRef(() => StateController)) public stateController: StateController,
    @Inject(forwardRef(() => ConfDataActionCreator)) public configurationActionCreator: ConfDataActionCreator
  ) {
  }

  promote(model: ConfDataRequest): HttpAction<ConfDataResponse> {
    let asyncAction = <HttpAction<ConfDataResponse>>{
      type: StateActions.PROMOTE,
      executeAsync: (dispatch, state, callback) => {
        return this.stateController.promote(model)
          .subscribe(configurationDataResult => {
            dispatch(this.configurationActionCreator.configurationLoaded(configurationDataResult));

            callback(new ActionStatus(true, configurationDataResult));
          });
      }
    }
    return asyncAction;
  }

  demote(model: ConfDataRequest): HttpAction<ConfDataResponse> {
    let asyncAction = <HttpAction<ConfDataResponse>>{
      type: StateActions.PROMOTE,
      executeAsync: (dispatch, state, callback) => {
        return this.stateController.demote(model)
          .subscribe(configurationDataResult => {
            dispatch(this.configurationActionCreator.configurationLoaded(configurationDataResult));

            callback(new ActionStatus(true, configurationDataResult));
          });
      }
    }
    return asyncAction;
  }
}
