import { Component } from "@angular/core";
import { BaseCodeDisplayStyleComponent } from "./baseCodeDisplayStyleComponent";
import { ProductDataStore } from "../../../providers/productData";
import { ConfCodeRequest } from "../../../models";
import { ModelFactory } from "../../../providers";
import { PageStore } from "../../../providers/page";
import { ConfPageSessionService } from "../../../../configurator/providers";
import { ConfDataController } from "../../../providers/configurationData";

@Component({
  selector: 'code-download-link-style',
  templateUrl: './codeDownloadLinkStyleComponent.html'
})
export class CodeDownloadLinkStyleComponent extends BaseCodeDisplayStyleComponent {

  public downloadLinkUrl: string;

  constructor(public productStore: ProductDataStore,
    public modelFactory: ModelFactory,
    public pageStore: PageStore,
    public confPageSession: ConfPageSessionService,
    public controller: ConfDataController) {
    super(productStore, pageStore, confPageSession);
  }

  ngOnInit() {

    super.ngOnInit();

    this.downloadFileLink();
  }


  downloadFileLink() {

    let model: ConfCodeRequest = this.modelFactory.createAny(ConfCodeRequest.name) as any;

    model.getStored = this.isStored;
    model.client = this.pageStore.getActiveClientType();
    model.codeFileId = this.codeValue.longId;
    model.configurationId = this.configurationId;
    model.confSessionId = this.confSessionId;
    this.downloadLinkUrl = this.controller.getConfigurationCodeUrl(model);

  }
}