import { SimpleObject } from "../../../shared/simpleObject";
import { ParamMap } from "@angular/router";

export abstract class RouteParams  {

  public internalMap: Map<any, any> = new Map<any, any>();
  constructor(public paramMap?: ParamMap) {

    // Copy the value to internal map. As paramMap is not map itself, It has internally "params" property.    
    if (paramMap)
      paramMap.keys.forEach(key => { this.internalMap.set(key, paramMap.get(key)); });

  }
  
}