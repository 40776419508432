import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { Routing } from "../../shared/route/routeDecorator";
import { BaseComponent } from "../shared";
import { LogoutMessage } from "../shared/models";
import { AppStoreSubscriptionManager, LocalStorageService, RouteRedirector } from "../shared/providers";
import { AccountDataMessageProvider, AccountDataStore } from "../shared/providers/accountData";


@Routing({ path: 'logout' })
@Component({
  encapsulation: ViewEncapsulation.None,
  templateUrl: './logoutComponent.html',
  providers: [AppStoreSubscriptionManager]
})
export class LogoutComponent extends BaseComponent {

  constructor(
    @Inject(AccountDataStore) public accountStore: AccountDataStore,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    public appStoreSubscriptionManager: AppStoreSubscriptionManager,
    public accountMessageProvider: AccountDataMessageProvider,
    public localStorageService: LocalStorageService
  ) {
    super();
  }

  ngOnInit() {        
    // Listen for logout message
    this.accountMessageProvider.onMessages<LogoutMessage>(LogoutMessage.name, {
      next: (messages) => {

        let logoutMessage = messages.get(0);
        if (!logoutMessage.success) {
          // print logout errors
          console.log(logoutMessage.errors);
        }
        
      },
      listenNewEventsOnly: true

    }).unsubscribeOn(this.unsubscribeSubject);

    this.logout();
    this.routeRedirector.redirectAfterLogout();
  }

  logout() {
    this.accountStore.endUserSession();
    this.accountStore.logOutUser();

    sessionStorage.removeItem("cookieConsent");
  }

  ngOnDestroy() {
    this.appStoreSubscriptionManager.dispose();
    super.ngOnDestroy();
  }
}