import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ImageComponent } from './imageComponent';

export * from './imageComponent';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [    
    ImageComponent
  ],
  exports: [
    ImageComponent
  ]
})
export class ImageModule {

}