import { RequestStatus } from "./shared/requestStatus";
import { AppAction } from "./shared/appAction";

export class ActionInfo
{
  id: number;
  action: AppAction<any>;
  status: RequestStatus; // complete, pending, error, abort
  payload?: any;
  completed: boolean = false;
}