import { Injectable, Inject } from "@angular/core";
import { ReselectorService, ReselectorResult } from "../reselectorService";
import { ConfiguratorPageUIData } from "../../state";

@Injectable()
export class PageDataMemorizer {

  constructor( @Inject(ReselectorService) public reselectorService: ReselectorService) {
    
  }

  /**
   * Configurator UI
   */
  public getConfiguratorUIDataMemorizer(pageId: number): () => ConfiguratorPageUIData
  {
    return this.reselectorService.createMemorizer((state) => state.uiData.configuratorUI.get(pageId.toString()));
  }
}