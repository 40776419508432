import * as Immutable from "immutable";
import { PushMessage } from "./pushMessage";
import { UiAction } from "./uiAction";

export class ServerPushMessage extends PushMessage {

  protected readonly START_UI_ACTION = "startUiAction";
  protected readonly UI_ACTIONS_BY_ID = "uiActionsById";

  get startUiAction(): string { return this.getInternalValue<string>(this.START_UI_ACTION); }
  setStartUiAction(startUiAction: string): this { return this.setInternalValue(this.START_UI_ACTION, startUiAction); }

  get uiActionsById(): Immutable.Map<string, UiAction[]> { return this.getInternalValue<Immutable.Map<string, UiAction[]>>(this.UI_ACTIONS_BY_ID); }
  setUiActionsById(uiActionsById: Immutable.Map<string, UiAction[]>): this { return this.setInternalValue(this.UI_ACTIONS_BY_ID, uiActionsById); }

}