import { BaseRequest } from "./baseRequest";
import { AssortmentCommand } from "./commands/assortmentCommand";
import { ProductCommand } from "./commands/productCommand";
import { TabCommand } from "./commands/tabCommand";

export class ProductDataRequest extends BaseRequest {

  assortment?: AssortmentCommand;
  product?: ProductCommand;
  tab?: TabCommand;  
}