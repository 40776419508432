import { Component, Inject } from "@angular/core";
import { PopupService } from "../../../../shared/components";
import { Delay, sleep } from "../../../../shared/utils";
import { BomDecoration, Conf, ConfBomValue } from "../../../shared/models";
import { ConfiguratorStore, ConfPageSessionService, PopupIdentifiers } from "../../providers";
import { DecorationComponent } from "../../shared/decorationComponent";
import { BomActionArgs } from "./bomActionArgs";
import { BomStore } from "./bomStore";
import { VisualObjectHelper } from "../../parameters/shared";


@Component({
  selector: 'bom-decoration',
  templateUrl: './bomDecorationComponent.html'  
})
export class BomDecorationComponent extends DecorationComponent<BomDecoration> {

  public confBomValue: ConfBomValue;
  public compositeVisible: boolean = false;
  
  constructor(
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(BomStore) public bomStore: BomStore,
    @Inject(VisualObjectHelper) public visualObjectHelper: VisualObjectHelper,
    @Inject(PopupService) public popupService: PopupService
  ) {
    super(confStore, confPageSessionService, visualObjectHelper);
  }

  ngOnInit() {

    // Initial value on load.
    this.confBomValue = this.configuration.bomValues.find((x) => x.longId == this.decoration.longId);    
    super.ngOnInit();

  }
  
  public listenChanges(): void {
    this.confStore.onConfigurationChange(this.configurationId, this.confPageSessionService.confSessionId, (configuration: Conf): void => {
      this.confBomValue = this.configuration.bomValues.find((x) => x.longId == this.decoration.longId);      
    }).unsubscribeOn(this.unsubscribeSubject);
  }

  public onBomCompositeClick(): void {  
    this.compositeVisible = true;
    sleep(Delay.MS_100).then(x => { this.popupService.open<BomActionArgs>(PopupIdentifiers.BomComposite, <BomActionArgs>{ configurationId: this.configurationId, decorationId: this.decoration.longId }); });
  }

  public closedEvent($event) {    
    this.compositeVisible = false;
  }
}