import { Injectable, Inject } from "@angular/core";
import { combineReducers, Reducer } from "redux";

import { AppAction } from "../shared/appAction";
import { PageState } from "../shared/pageState";
import { SelectorUIReducer } from "./selectorPageUIReducer";
import { ConfiguratorUIReducer } from "./configuratorPageUIReducer";
import { PageActions } from "./pageActions";
import { RequestViews } from "../../models";
import { UIAction } from "..";

@Injectable()
export class PageReducer {

  constructor(
    @Inject(SelectorUIReducer) public selectorUIReducer: SelectorUIReducer,
    @Inject(ConfiguratorUIReducer) public configuratorUIReducer: ConfiguratorUIReducer
  ) { }

  public getReducer(): Reducer<PageState> {
    return combineReducers<any>({
      selectorUI: this.selectorUIReducer.getReducer(),
      configuratorUI: this.configuratorUIReducer.getReducer(),
      nextPageIdentifier: this.pageIdentifierReducer(),
      activeRouteName: this.activeViewReducer(),
    })
  }

  public pageIdentifierReducer() {
    return (state: number = 1, action: AppAction<any>): number => {
      if (action.type == PageActions.INCREMENT_PAGE_IDENTITY)
        state = ++state;

      return state;
    }
  }

  public activeViewReducer() {
    const defaultState = RequestViews.Editor;

    // Reducer
    return (state: RequestViews = defaultState, uiAction: UIAction<RequestViews>): RequestViews => {
      switch (uiAction.type) {
        case PageActions.CONF_ACTIVE_ROUTE_NAME:
          state = uiAction.payload;
      }
      return state;
    }
  }
}


