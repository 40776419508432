import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ImageModule } from '../image';
import { TreeViewSimpleComponent } from './treeViewSimpleComponent';
import { TreeNodeSimpleComponent } from './treeNodeSimpleComponent';

export * from './treeViewSimpleComponent';
export * from './treeNodeSimpleComponent';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ImageModule    
  ],
  declarations: [
    TreeViewSimpleComponent,
    TreeNodeSimpleComponent
  ],
  exports: [
    TreeViewSimpleComponent,
    TreeNodeSimpleComponent
  ]
})
export class TreeViewSimpleModule {

}