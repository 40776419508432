import * as Immutable from "immutable"

import { ImmutableObject } from "../../../../shared/immutableObject"

export class SortingInfo extends ImmutableObject {

    protected readonly KEY = "key";
    protected readonly DESCENDING = "descending";

    get key(): string { return this.getInternalValue<string>(this.KEY); }
    setKey(key: string): this { return this.setInternalValue(this.KEY, key); }

    get descending(): boolean { return this.getInternalValue<boolean>(this.DESCENDING); }
    setDescending(descending: boolean): this { return this.setInternalValue(this.DESCENDING, descending); }
}