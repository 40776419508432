import { ImmutableObject } from "../../../../shared/immutableObject";

export class ConfSearchResultItem extends ImmutableObject {

  protected readonly CONFIGURATION_ID = "configurationId";
  protected readonly CONF_SESSION_ID = "confSessionId";
  protected readonly INTERVAL_TYPE = "intervalType";

  get configurationId(): number { return this.getInternalValue<number>(this.CONFIGURATION_ID); }
  setConfigurationId(configurationId: number): this { return this.setInternalValue(this.CONFIGURATION_ID, configurationId); }

  get confSessionId(): number { return this.getInternalValue<number>(this.CONF_SESSION_ID); }
  setConfSessionId(confSessionId: number): this { return this.setInternalValue(this.CONF_SESSION_ID, confSessionId); }

  get intervalType(): IntervalType { return this.getInternalValue<IntervalType>(this.INTERVAL_TYPE, IntervalType); }
  setIntervalType(intervalType: IntervalType): this { return this.setInternalValue(this.INTERVAL_TYPE, intervalType); }
}

export enum IntervalType {
  Unspecified = 0,
  Today,
  ThisWeek,
  ThisMonth,
  LastMonth,
  Older
}