import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Event } from '@angular/router';
import { Subscription } from 'rxjs';
import { PopupIdentifiers } from '../pages/configurator/providers';
import { IEmitDataInfo } from '../pages/configurator/shared';
import { BaseComponent, PageComponent } from "../pages/shared";
import { ApiException, ApiMessage, ApiMessageScope, CacheReloadMessage, ComplexSequentialPopups, LocalStorageData, RedirectMessage, RequestViews, User, UiAction, UiPopup, UiEventOperation, OperationType, UiNotification, PushMessage, ServerPushMessage } from "../pages/shared/models";
import { ApiMessageProvider, HttpErrorCodeHandler, LocalStorageService, RouteRedirector } from "../pages/shared/providers";
import { AccountDataStore } from "../pages/shared/providers/accountData";
import { ExceptionHandler } from "../pages/shared/providers/exceptionHandler";
import { GlobalDataStore } from '../pages/shared/providers/globalData';
import { MessageBoxComponent, MessageBoxConfig, PopupService } from "../shared/components";
import { NotificationInfo, NotificationService, NotificationType } from "../shared/components/notification";
import { ErrorCodes } from "../shared/errorCodes";
import { Exception } from "../shared/exception";
import { HttpException } from "../shared/httpException";
import { JsonConvert } from "../shared/providers/jsonConvert";
import { Routing } from "../shared/route/routeDecorator";
import { BreakPointAccessor } from "../shared/utils";
import { PushMessageStore, PushMessageController } from '../pages/shared/providers/pushMessage';
import * as Immutable from "immutable";
import { PushMessageSelection } from '../pages/shared/providers/pushMessage/pushMessageSelection';
import { PushMessageComponent } from '../pages/shared/components/push/pushMessageComponent';
import { SignalRService } from '../pages/shared/providers/pushMessage/signalRService';
import { HttpService } from "../shared/providers/httpService";

/*
 * App Component
 * Top Level Component
 */
@Routing({ path: '', redirectTo: '/start', pathMatch: 'full' })
@Component({
  selector: 'combinum-app',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './appComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent extends BaseComponent implements OnInit {

  @ViewChild(MessageBoxComponent)
  protected navigateMessageBox: MessageBoxComponent;

  @ViewChild('pushMessagePopup')
  private pushMessagePopup: PushMessageComponent;

  public reloadCacheCounter: number = 0;
  public isMobile: boolean;
  public isLimitedBrowserSupport: boolean = false;
  public limitedBrowserSupportInfo: string;
  public extraStyles: string = "";
  public notificationDetailString: string;
  public notificationMessageString: string;
  public blockUIStyle: string;
  public containerId: string;
  public isUserLoggedIn: boolean;
  public subscription: Subscription;
  public routeSubscription: Subscription;
  private pageComponent: PageComponent;
  private isReload: boolean = false;

  pushMessageQueue = [];

  public uiPopup: UiPopup;
  public sessionId: number;
  actionsById: Immutable.Map<string, UiAction[]>;

  constructor(
    @Inject(BreakPointAccessor) public breakPointAccessor: BreakPointAccessor,
    @Inject(ExceptionHandler) public exceptionHandler: ExceptionHandler,
    @Inject(NotificationService) public notificationService: NotificationService,
    @Inject(JsonConvert) public jsonConvert: JsonConvert,
    @Inject(RouteRedirector) public routeRedirector: RouteRedirector,
    @Inject(AccountDataStore) public accountStore: AccountDataStore,
    @Inject(ApiMessageProvider) public apiMessageProvider: ApiMessageProvider,
    @Inject(PopupService) public popupService: PopupService,
    @Inject(SignalRService) public signalRService: SignalRService,
    @Inject(PushMessageStore) public pushMessageStore: PushMessageStore,
    @Inject(PushMessageController) public pushMessageController: PushMessageController,
    // MsCrm ->
    @Inject(HttpService) public httpService: HttpService,
    // MsCrm <-
    public globalDataStore: GlobalDataStore,
    public localStorageService: LocalStorageService,
    public httpErrorCodeHandler: HttpErrorCodeHandler,
    public router: Router,
    public cd: ChangeDetectorRef,
    public activeRoute: ActivatedRoute
  ) {
    super();

    this.routeSubscription = this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {

          this.blockUI();

          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {

          this.unblockUI();

          break;
        }
        default: {
          break;
        }
      }
    });

  }

  ngOnInit() {

    let globalSettings = this.globalDataStore.globalSettings;
    this.isMobile = this.browserInfo.isMobile;

    this.isLimitedBrowserSupport = globalSettings.limitedBrowsersSupport.contains(this.browserInfo.name);
    this.limitedBrowserSupportInfo = this.format(this.strings.LimitedBrowserSupportInfo, this.browserInfo.fullName, this.browserInfo.getSupportedBrowsersInfo(globalSettings.limitedBrowsersSupport.toArray()));

    this.notificationDetailString = this.strings.Detail;
    this.notificationMessageString = this.strings.Message;

    this.listenOnException();
    this.listenOnHttpException();
    this.listenOnRedirectMessage();
    this.listenOnCacheMessage();
    this.listenOnStorageChanged();
    this.listenOnUIBlockerStart();
    this.listenOnApiMessage();
    // MsCrm ->
    this.listenOnPopupMessage();
    // MsCrm <-

    this.breakPointAccessor.initialize();
    this.extraStyles = this.browserInfo.name;

    this.subscription = this.router.events.subscribe((val) => {

      if (this.isAppInsideIFrame) {
        let currentNavigation = this.router.getCurrentNavigation();
        if (currentNavigation) {

          if (currentNavigation.extras) {
            currentNavigation.extras.skipLocationChange = true;
          }
          else {
            currentNavigation.extras = { skipLocationChange: true };
          }
        }
      }

      this.isUserLoggedIn = this.accountStore.isUserLoggedIn();

      if (this.isUserLoggedIn && !this.signalRService.isConnected()) {
        this.signalRService.startConnection();
      }

      // If the user is not logged in, then header goes away and body contents starts from top position 0.
      this.containerId = this.isUserLoggedIn ? 'body-contents' : 'body-contents-clean';
      this.extraStyles = this.browserInfo.name + (this.isUserLoggedIn ? '' : ' anonymous');

      this.cd.markForCheck();
    });

    this.signalRService.signalReceived.subscribe((data: any) => {

      this.pushMessageStore.setData(data).subscribe((storeResponse) => {

        this.pushMessageQueue.push(storeResponse.data);

        if (this.sessionId != null)
          return;

        this.processNextPushMessage();

      }).unsubscribeOn(this.unsubscribeSubject);

    });


  }

  private processNextPushMessage() {

    this.sessionId = null;
    this.uiPopup = null;
    this.actionsById = null;

    if (this.pushMessageQueue.length == 0)
      return;

    let message: ServerPushMessage = this.pushMessageQueue.shift();

    this.sessionId = message.interactionSessionId;

    if (message && message.uiActionsById) {
      this.actionsById = message.uiActionsById;

      let startAction = message.startUiAction;

      this.processAction(startAction, message.interactionSessionId);

      if (!this.uiPopup)
        this.processNextPushMessage();
    }
  }

  private processAction(action: string, interactionSessionId: number) {
    let actions = this.actionsById.get(action);

    if (!actions)
      return;

    actions.forEach((x) => {
      switch (x.className) {
        case "UiPopup":
          this.uiPopup = x as UiPopup;
          this.sessionId = interactionSessionId;
          this.showPopup();
          break;
        case "UiEventOperation":

          let operation = x as UiEventOperation;

          if (operation.operationType == OperationType.ClientPushMessage) {

            let model = this.pushMessageStore.createRequest();

            let valuesById = this.pushMessageStore.getValuesById(interactionSessionId);

            model.interactionSessionId = this.sessionId;
            model.triggeringControlId = action;
            model.valuesById = valuesById;

            this.pushMessageController.send(model);
          }
          else if (operation.operationType == OperationType.ClosePopup) {

            (this.pushMessagePopup as PushMessageComponent).close();
            this.processNextPushMessage();
          }
          else if (operation.operationType == OperationType.Redirect) {

          }

          break;
        case "UiNotification":

          let notification = x as UiNotification;

          this.notificationService.notify(<NotificationInfo>{
            title: notification.title,
            message: notification.message,
            detail: notification.detail,
            rawInfo: notification.rawInfo,
            type: notification.type,
            selfClose: notification.selfClose,
            delay: notification.delay,
            identifier: notification.identifier,
            sync: notification.sync
          });

          this.cd.detectChanges();

          break;
        default:
          // 
          console.log("Action not implemented: " + x)
          break;
      }
    })
  }

  public onAction(event: PushMessageSelection) {

    this.pushMessageStore.setValue(event);
    this.processAction(event.id, event.sessionId);

  }

  public showPopup() {

    (this.pushMessagePopup as PushMessageComponent).show();
    this.cd.detectChanges();

  }

  private _isAppInsideIFrame: boolean = null;
  protected get isAppInsideIFrame() {

    if (this._isAppInsideIFrame != null)
      return this._isAppInsideIFrame;

    try {
      this._isAppInsideIFrame = window.self !== window.top;
    }
    catch {
      this._isAppInsideIFrame = true;
    }

    return this._isAppInsideIFrame;
  }

  public listenOnUIBlockerStart(): void {

    this.emitterService.getMessage().subscribe((info: IEmitDataInfo<boolean>) => {

      if (info.id == PopupIdentifiers.PlainUIBlock) {

        // If tag is true then start plain ui blocking without spinner.
        this.blockUIStyle = info.tag ? 'plain-ui-block' : '';
      }

    }).unsubscribeOn(this.unsubscribeSubject);

  }

  public listenOnException() {

    this.exceptionHandler.onException((exception: Exception) => {
      this.unblockUI();
      let type = NotificationType.Error;
      let title = this.strings.Error;
      let selfClose = false;
      let identifier = "error-message";
      let sync = false;

      // Handle error codes to show different messages      
      if (exception instanceof ApiException) {

        if (ErrorCodes.isSuppressNotification(exception.code))
          return;

        if (ErrorCodes.isInformationCode(exception.code)) {
          type = NotificationType.Info;
          title = this.strings.Info;
          selfClose = true;
          identifier = "error-info";
        }
        else if (ErrorCodes.isWarningCode(exception.code)) {
          type = NotificationType.Warning;
          title = this.strings.Warning;
          selfClose = true;
          identifier = "error-warning";
          sync = true;
        }
      }

      this.notificationService.notify(<NotificationInfo>{
        title: title,
        message: exception.message,
        detail: `${exception.url ? (exception.url + " - ") : ""}${exception.detail ? exception.detail : ""}`,
        rawInfo: exception.originalError,
        type: type,
        selfClose: selfClose,
        identifier: identifier,
        sync: sync
      });

      // If error is configuration not found then redirect to start
      if (exception instanceof ApiException) {
        if (ErrorCodes.SEARCH_SESSION_NOT_FOUND == exception.code)
          this.routeRedirector.redirectToStart();
      }

    }, true);
  }

  public listenOnHttpException() {
    this.exceptionHandler.onHttpException((exception: HttpException) => {

      this.unblockUI();
      this.httpErrorCodeHandler.handle(exception);

      if (exception.errorResponse.status == 400) {
        let badRequestException = exception.exceptions.find((ex) => ex instanceof ApiException && ex.code == ErrorCodes.CLIENT_VERSION_INCOMPATIBLE) as ApiException;
        if (badRequestException)
          this.showMessageAndRunCallBack(badRequestException.message, null);
      }

    }, true);
  }

  public listenOnRedirectMessage() {
    this.apiMessageProvider.onMessages<RedirectMessage>(RedirectMessage.name,
      (messages) => {

        let redirectMessage = messages.first();

        // MsCrm ->
        if (redirectMessage.externalUrl) {
          if (redirectMessage.extraArgs && redirectMessage.extraArgs.get('upn')) {
            let upn = redirectMessage.extraArgs.get('upn');
            let url = escape(window.location.href);
            this.httpService.get('mscrm/registerLatestUrl?upn=' + upn + '&url=' + url).subscribe(
              _ => {
                window.location.href = redirectMessage.externalUrl;
              }
            );
          }
          return;
        }
        // MsCrm <-

        let queryString = "";
        if (redirectMessage.queryString) {
          queryString = "?" + redirectMessage.queryString;
        }

        let routeArgs = {};
        if (redirectMessage.parameters) {
          routeArgs = redirectMessage.parameters.toObject()
        }

        let view = redirectMessage.view.toLowerCase();
        if (view == RequestViews.None) {
          this.showMessageAndRunCallBack(redirectMessage.message, null);
          return;
        }
        else if (view == RequestViews.Summary || view == RequestViews.Editor) {
          view = "configurator/" + view;
        }

        let redirectUrl = `${view}/${queryString}`;
        this.showMessageAndRunCallBack(redirectMessage.message, () => { this.routeRedirector.redirect([redirectUrl, routeArgs]) });

      });
  }

  listenOnApiMessage() {
    this.apiMessageProvider.onMessages<ApiMessage>(ApiMessage.name, {
      next: (messages) => {
        let apiMessage = messages.first();

        // Close it automatically if scope is temporary.
        let selfClose = apiMessage.scope == ApiMessageScope.Temp;

        if (apiMessage.innerMessage) {
          this.notificationService.notify(<NotificationInfo>{ selfClose: selfClose, title: apiMessage.innerMessage.title, message: apiMessage.innerMessage.message, type: apiMessage.displayStyle ? apiMessage.displayStyle : NotificationType.Info });
        }

      }, listenNewEventsOnly: true
    }).unsubscribeOn(this.unsubscribeSubject);
  }

  // MSCRM begin
  public listenOnPopupMessage() {
    this.apiMessageProvider.onMessages<ComplexSequentialPopups>(ComplexSequentialPopups.name,
      {
        next: (messages) => {
          messages.forEach(apiMessage => {

            if (apiMessage.popupSequences)
              this.popupService.open(PopupIdentifiers.MsCrmIntegrationPopup, apiMessage);
          });
        }
      });
  }
  // MSCRM end
  public listenOnCacheMessage() {
    this.apiMessageProvider.onMessages<CacheReloadMessage>(CacheReloadMessage.name,
      (messages) => {

        let cacheReloadMessage = messages.first();
        if (cacheReloadMessage.success && cacheReloadMessage.serverTriggered) {
          this.showMessageAndRunCallBack(this.strings.ProductDataUpdatedAndVerifyConfiguration, null);
        }

        // Update the counter, other tabs will reload as well when user accesses them
        let localStorageData = this.localStorageService.getData();
        localStorageData = localStorageData.setReloadCacheCounter(localStorageData.reloadCacheCounter + 1);

        // Update reload cache counter to avoid unneccessary reload of the current tab
        this.reloadCacheCounter = localStorageData.reloadCacheCounter;

        this.localStorageService.setData(localStorageData);
      });
  }

  public listenOnStorageChanged() {

    this.reloadCacheCounter = this.localStorageService.getData().reloadCacheCounter;

    this.localStorageService.onLocalStorageChanged((localStorageData: LocalStorageData) => {

      if (!this.accountStore.hasUserSession() && this.router.getCurrentNavigation() == null)
        this.routeRedirector.redirectToLogin();

      let user: User = this.accountStore.getUser();
      let isAnonymous = !user || user.systemAuthorization.hasAnonymousAccess;

      if (isAnonymous && this.globalDataStore.getGlobalData().globalSettings && this.globalDataStore.getGlobalData().globalSettings.showCookieInformation && !sessionStorage.getItem("cookieConsent")) {
        this.notificationService.notify(<NotificationInfo>{
          title: this.strings.CookieInformation,
          message: " ",
          detail: this.strings.CookieInformationMessage,
          rawInfo: this.strings.CookieInformationMessageDetails,
          type: NotificationType.Info,
          identifier: "cookieInformation",
          selfClose: false
        });

        sessionStorage.setItem("cookieConsent", "true");
      }

      if (localStorageData.reloadCacheCounter && this.reloadCacheCounter != localStorageData.reloadCacheCounter)
        this.showMessageAndRunCallBack(this.strings.ProductDataUpdatedAndVerifyConfiguration, null);

      this.reloadCacheCounter = localStorageData.reloadCacheCounter;
    }).unsubscribeOn(this.unsubscribeSubject);
  }

  public showMessageAndRunCallBack(message: string, callBack: () => void) {

    let isReload = !callBack;
    callBack = callBack || (() => {
      this.isReload = true;

      window.location.reload(true);

      this.isReload = false;
    });

    if (message && message.length > 0) {

      let info: MessageBoxConfig<() => void> = <MessageBoxConfig<() => void>>{
        description: message,
        icon: "info",
        tag: callBack
      }

      if (this.navigateMessageBox) {
        this.navigateMessageBox.title = this.navigateMessageBox.okButtonText = isReload ? this.strings.Reload : this.strings.Navigate;
        this.navigateMessageBox.show(info);
      }

    }
    else {
      callBack();
    }
  }

  public navigateMessageBoxOkClick($event: () => void) {
    if ($event)
      $event();
  }

  public onComponentActivate($component): void {
    if ($component && $component instanceof PageComponent)
      this.pageComponent = $component;
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload($event) {
    if (this.pageComponent)
      return this.pageComponent.onBeforeUnload($event, this.isReload);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.breakPointAccessor.initialize(event.target.innerWidth);
  }

  @HostListener('window:storage', ['$event'])
  initStorageEvent(event: StorageEvent) {
    this.localStorageService.storageChange(event);
  }

  ngOnDestroy() {

    if (this.subscription)
      this.subscription.unsubscribe();

    if (this.routeSubscription)
      this.routeSubscription.unsubscribe();

    super.ngOnDestroy();
  }

}