<div>
  <div class="tab-body">
    <h3 class="header text-uppercase">News</h3>
    <div class="my-2">
      <h2>System maintence</h2>
      <span>The system is down for maintenance between 12.00 and 13.00 CEST. Don’t forget to save your current work!</span>
      <p>
        <span class="warning">NEW</span>
        <span>2017-10-02</span>
      </p>
    </div>
    <div class="my-2">
      <h2>Visit us at the Trade Fairly event the 11th of November</h2>
      <span>Come and visit us at the Trade Fairly event in New York bla la bla</span>
      <p>
        <span class="warning">NEW</span>
        <span>2017-10-02</span>
      </p>
    </div>
    <div class="my-2">
      <h2>The Product A can now be ordered in green color</h2>
      <span>Click on the link below to see an example.</span>
      <span>Product a in green color</span>
      <p>
        <span>2017-10-02</span>
      </p>
    </div>
    <div class="my-2">
      <h2>Welcome Company X</h2>
      <span>Company X is a distributor for Australia and New Zeeland. We give them a warm welcome and wish them good luck!</span>
      <p>
        <span>2017-10-02</span>
      </p>
    </div>
    <h4 class="float-right clickable text-uppercase">More news...</h4>
  </div>
</div>