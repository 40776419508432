import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ImageModule } from "../../../../shared/components/image";
import { ActionBoxComponent } from "./actionBoxComponent";

export * from "./actionBoxComponent";

@NgModule({
  imports: [
    CommonModule,
    ImageModule
  ],
  declarations: [
    ActionBoxComponent    
  ],
  exports: [
    ActionBoxComponent
  ],

  providers: []
})
export class ActionBoxModule {

}