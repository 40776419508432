import * as Immutable from "immutable";
import { BaseEntity } from "../../baseEntity";

export class PushMessage extends BaseEntity {

  protected readonly INTERACTION_SESSION_ID = "interactionSessionId";
  protected readonly EXTRA_ARGS = "extraArgs";

  get interactionSessionId(): number { return this.getInternalValue<number>(this.INTERACTION_SESSION_ID); }
  setInteractionSessionId(interactionSessionId: number): this { return this.setInternalValue(this.INTERACTION_SESSION_ID, interactionSessionId); }

  get extraArgs(): Immutable.Map<string, string> { return this.getInternalValue<Immutable.Map<string, string>>(this.EXTRA_ARGS); }
  setExtraArgs(extraArgs: Immutable.Map<string, string>): this { return this.setInternalValue(this.EXTRA_ARGS, extraArgs); }

}