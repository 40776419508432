import { Injectable, Inject } from '@angular/core';
import * as Immutable from "immutable";

import { AccountDataResponse, AbstractAccountMessage } from "../../models/responses";
import { AccountDataState } from "../shared/accountDataState";
import { AccountDataActions } from "./accountDataActions";
import { AppAction } from "../shared/appAction";
import { BaseEntity } from "../../baseEntity";
import { Actions } from "../shared/actions";

@Injectable()
export class AccountDataReducer {

  public getReducer() {

    let defaultState = new AccountDataState();
    defaultState = defaultState.setEntities(Immutable.Map<number, BaseEntity>());
    defaultState = defaultState.setEntityIdsByClassName(Immutable.Map<string, Immutable.List<number>>());
    defaultState = defaultState.setMessages(Immutable.Map<string, Immutable.List<AbstractAccountMessage>>());

    return (state: AccountDataState = defaultState, action: AppAction<any>): AccountDataState => {

      if (action.type == Actions.CLEAR_CLIENT_CACHE) {

        // Clear everything except the user data

        state = state.setEntities(Immutable.Map<number, BaseEntity>());
        state = state.setEntityIdsByClassName(Immutable.Map<string, Immutable.List<number>>());
        state = state.setMessages(Immutable.Map<string, Immutable.List<AbstractAccountMessage>>());

        return state;
      }

      if (!action.payload || !(action.payload.data instanceof AccountDataResponse))
        return state;

      let accountResponse = action.payload.data as AccountDataResponse;

      state = state.setShowRecaptcha(accountResponse.showRecaptcha);

      switch (action.type) {

        case AccountDataActions.USER_LOGIN_RESULT:
        case AccountDataActions.USER_UPDATE_SETTINGS_RESULT:
        case AccountDataActions.USER_UPDATE_PROFILE_RESULT:
        case AccountDataActions.LOGGED_IN_USER_LOADED:
          {
            state = state.setUser(accountResponse.user);
            break;
          }
        case AccountDataActions.USER_LOGOUT_RESULT:
          {
            state = state.setUser(null);
            break;
          }
        case AccountDataActions.ACCOUNT_ENTITIES_LOADED: {

          if (accountResponse.entities && accountResponse.entities.size > 0) {
                                                
            accountResponse.entityIdsByClassName.forEach((newIds, className) => {

              let existingIds = state.entityIdsByClassName.get(className);
              state = state.setEntityIdsByClassName(state.entityIdsByClassName.set(className, this.mergeIds(existingIds, newIds)));

            })

            let entities: Immutable.Map<number, BaseEntity> = this.mergeEntities(state.entities, accountResponse.entities);
            state = state.setEntities(entities);
          }
        }
          break;
      }

      if (accountResponse.messages && accountResponse.messages.size > 0)
        state = state.setMessages(this.createMessagesMap(accountResponse.messages));

      return state;
    }
  }

  protected mergeIds(existingIds: Immutable.List<number>, newIds: Immutable.List<number>) {
    if (existingIds && existingIds.count() > 0)
      return existingIds.merge(newIds).toSet().toList();

    return newIds;
  }

  protected mergeEntities(existingEntities: Immutable.Map<number, BaseEntity>, newEntities: Immutable.Map<number, BaseEntity>): Immutable.Map<number, BaseEntity> {

    let resultedEntities = existingEntities;

    newEntities.forEach((newEntity: BaseEntity, key: number) => {

      // Convert to number, needed to make sure the key is number in runtime
      key = parseInt(key.toString());

      if (!existingEntities.has(key)) {
        resultedEntities = resultedEntities.set(key, newEntity);
      }
      else {
        let existingEntity = existingEntities.get(key);
        resultedEntities = resultedEntities.set(key, Object.assign(existingEntity, newEntity));
      }
    });

    return resultedEntities;
  }

  protected createMessagesMap(messages: Immutable.List<AbstractAccountMessage>): Immutable.Map<string, Immutable.List<AbstractAccountMessage>> {

    let messagesMap = Immutable.Map<string, Immutable.List<AbstractAccountMessage>>();

    messages.forEach((message) => {

      if (!messagesMap.has(message.className))
        messagesMap = messagesMap.set(message.className, Immutable.List<AbstractAccountMessage>());

      let messagesList = messagesMap.get(message.className);
      messagesMap = messagesMap.set(message.className, messagesList.push(message));
    });

    return messagesMap;
  }
}