import { Injectable, Inject } from '@angular/core';
import { Subscription, ReplaySubject, Subject } from 'rxjs';

export class UIEvent
{
  identifier: string;
  source: any;
}

@Injectable()
export class UIEventService {

  public uiSubject: Subject<UIEvent> = new Subject<UIEvent>();

  public register(callBack: (confEvent: UIEvent) => void): Subscription  {
    return this.uiSubject.subscribe(callBack);
  }

  public publishEvent(source: any, identifier: string)
  {
    let event = new UIEvent();
    event.identifier = identifier;
    event.source = source;

    this.uiSubject.next(event);
  }
  
  public destroy() {
    this.uiSubject.complete();
  }
  
}