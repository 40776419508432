import * as Immutable from "immutable"

import { BaseEntity } from "../../baseEntity"

export abstract class Assortment extends BaseEntity {

  protected readonly ICON = "icon";
  protected readonly IMAGE = "image";
  protected readonly ICON_RELATIVE_URL = "iconRelativeUrl";
  protected readonly IMAGE_RELATIVE_URL = "imageRelativeUrl";
  protected readonly NOTES = "notes";
  protected readonly TITLE = "title";
  protected readonly CHILDREN = "childrenIds";

  get icon(): string { return this.getInternalValue<string>(this.ICON); }
  setIcon(icon: string): this { return this.setInternalValue(this.ICON, icon); }

  get image(): string { return this.getInternalValue<string>(this.IMAGE); }
  setImage(image: string): this { return this.setInternalValue(this.IMAGE, image); }

  get iconRelativeUrl(): string { return this.getInternalValue<string>(this.ICON_RELATIVE_URL); }
  setIconRelativeUrl(iconRelativeUrl: string): this { return this.setInternalValue(this.ICON_RELATIVE_URL, iconRelativeUrl); }

  get imageRelativeUrl(): string { return this.getInternalValue<string>(this.IMAGE_RELATIVE_URL); }
  setImageRelativeUrl(helpImage: string): this { return this.setInternalValue(this.IMAGE_RELATIVE_URL, helpImage); }

  get notes(): string { return this.getInternalValue<string>(this.NOTES); }
  setNotes(notes: string): this { return this.setInternalValue(this.NOTES, notes); }

  get title(): string { return this.getInternalValue<string>(this.TITLE); }
  setTitle(title: string): this { return this.setInternalValue(this.TITLE, title); }

  get children(): Immutable.List<number> { return this.getInternalValue<Immutable.List<number>>(this.CHILDREN); }
  setChildren(children: Immutable.List<number>): this { return this.setInternalValue(this.CHILDREN, children); }
}